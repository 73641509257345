import React, { useEffect, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useLocation, useHistory } from 'react-router';
import { cashBalanceRequest } from '../privData/actions';
import VerticalNavbar from '../../components/navbar/verticalNavbar';
import { v4 as uuidv4 } from 'uuid';
import UngTruocTienBan from './components/CashAdvance';
import ChuyenTienTieuKhoan from './components/cashInternalTransfer';
import ExecutedGainLoss from './statements/executedGainLoss';
import ConditionalOrder from './statements/conditionalOrder';
import {
  RightInfor,
  StockInternalTransfer,
  MarginList,
  MarginDebt,
  DepositWithdrawalMoney,
  depositWithdrawalMoneyDerivative,
} from './pages';
import BankHold from './components/BankHold';
import SecuritiesStatement from './statements/securitiesStatement';
import CashStatement from './statements/cashStatement';
import OrderStatement from './statements/orderStatement';
import { Mixpanel } from '../../lib/mixpanel';
import { constants } from '../../util/constant';

const nav = [
  {
    name: 'personal.nav.asset.name',
    class: 'icon iAsset1',
    onClick: () =>
      Mixpanel.track('View Asset', {
        'Button Location': 'Tab Asset',
        'Asset Detail': 'Overview Asset',
      }),
    child: [
      {
        name: 'personal.nav.asset.overview',
        path: '../personal/assets',
        onClick: () =>
          Mixpanel.track('View Asset', {
            'Button Location': 'Tab Asset',
            'Asset Detail': 'Overview Asset',
          }),
      },
      {
        name: 'personal.nav.asset.portfolio',
        path: '../personal/assets-portfolio',
        onClick: () =>
          Mixpanel.track('View Asset', {
            'Button Location': 'Tab Asset',
            'Asset Detail': 'Portfolio',
          }),
      },
    ],
  },
  {
    name: 'personal.nav.margin.name',
    class: 'icon iUnion',
    child: [
      {
        name: 'personal.nav.margin.marginDebt',
        path: 'margin-debt',
        component: MarginDebt,
        onClick: () => Mixpanel.track('View Margin Debt'),
      },
      {
        name: 'personal.nav.margin.marginList',
        path: 'margin-list',
        component: MarginList,
        onClick: '',
      },
    ],
  },
  {
    name: 'transaction.name',
    class: 'icon iCard',
    child: [
      {
        name: 'personal.nav.payment.depositAndWithdrawMoney',
        path: 'deposit-withdraw-money',
        component: DepositWithdrawalMoney,
        onClick: '',
      },
      {
        name: 'personal.nav.payment.depositAndWithdrawMoneyDerivative',
        path: 'deposit-withdraw-money-derivative',
        component: depositWithdrawalMoneyDerivative,
        onClick: '',
      },
      {
        name: 'personal.nav.payment.cashAdvance',
        path: 'cash-advance',
        component: UngTruocTienBan,
        onClick: '',
      },
      {
        name: 'personal.nav.payment.cashInternalTransfer',
        path: 'cash-internal-transfer',
        onClick: '',
        component: ChuyenTienTieuKhoan,
      },
      {
        name: 'personal.nav.payment.stockInternalTransfer',
        path: 'stock-internal-transfer',
        onClick: '',
        component: StockInternalTransfer,
      },
      {
        name: 'personal.nav.payment.rightInfo',
        path: 'right-info',
        onClick: '',
        component: RightInfor,
      },
      {
        name: 'personal.nav.payment.holdUnholdBank',
        path: 'hold-unhold-bank',
        onClick: '',
        component: BankHold,
      },
    ],
  },
  {
    name: 'personal.nav.statement.name',
    class: 'icon iStatement',
    child: [
      {
        name: 'personal.nav.statement.orderStatement',
        path: 'order-statement',
        onClick: () =>
          Mixpanel.track('View Statement', {
            'Statement Type': 'Order Statement',
          }),
        component: OrderStatement,
      },
      {
        name: 'personal.nav.statement.conditionalOrderStatement',
        path: 'conditional-order-statement',
        onClick: () =>
          Mixpanel.track('View Statement', {
            'Statement Type': 'Conditional Order Statement',
          }),
        component: ConditionalOrder,
        condition: (currentAccount) =>
          currentAccount.subAcntClss != constants.subAccount.Derivative,
        fallbackRedirectUrl: '/home/transaction/order-statement',
      },
      {
        name: 'personal.nav.statement.cashStatement',
        path: 'cash-statement',
        onClick: () =>
          Mixpanel.track('View Statement', {
            'Statement Type': 'Cash Statement',
          }),
        component: CashStatement,
      },
      {
        name: 'personal.nav.statement.securitiesStatement',
        path: 'securities-statement',
        onClick: () =>
          Mixpanel.track('View Statement', {
            'Statement Type': 'Securities Statement',
          }),
        component: SecuritiesStatement,
        condition: (currentAccount) =>
          currentAccount.subAcntClss != constants.subAccount.Derivative,
        fallbackRedirectUrl: '/home/transaction/order-statement',
      },
      {
        name: 'personal.nav.statement.executedGainLossStatement',
        path: 'gain-loss-statement',
        onClick: () =>
          Mixpanel.track('View Statement', {
            'Statement Type': 'Executed Gain Loss Statement',
          }),
        component: ExecutedGainLoss,
        condition: (currentAccount) =>
          currentAccount.subAcntClss != constants.subAccount.Derivative,
        fallbackRedirectUrl: '/home/transaction/order-statement',
      },
    ],
  },
];

const Content = withNamespaces('translations')(
  ({ t, params, loadBalanceHandle, nav }) => {
    const { typeId } = params;
    const listHasAllAccount = ['assets', 'assets-portfolio'];
    const [tabActive, setTabActive] = useState(0);
    const [account, setAccount] = useState({});
    const [refresh, setRefresh] = useState(false);

    const { listAccount, defaultAccount } = useSelector(
      (state) => state.socket
    );
    const showUserModel = useSelector(
      (state) => state.layoutReducer.showUserModel
    );

    let routes = [];

    nav.map((x) => {
      if (x.child && x.child.length > 0) {
        x.child.map((item) => {
          routes.push(item);
        });
      }
    });

    const page = routes.find((x) => x.path == typeId);

    useEffect(() => {
      if (
        tabActive === 0 &&
        listAccount &&
        !listHasAllAccount.includes(typeId)
      ) {
        setTabActive(1);
        setAccount(listAccount[0]);
      }
    }, [typeId]);

    useEffect(() => {
      if (showUserModel && defaultAccount) {
        setAccount(defaultAccount);
        setTabActive(
          listAccount.findIndex(
            (account) => account.subAcntNo == defaultAccount.subAcntNo
          ) + 1
        );
      }
    }, [showUserModel, defaultAccount]);

    return (
      <div className="transaction-content">
        {page &&
          page.component &&
          React.createElement(
            page.component,
            { loadBalanceHandle, account, tabActive, refresh, params },
            null
          )}
      </div>
    );
  }
);

const Index = withNamespaces('translations')(({ t, match: { params } }) => {
  const dispatch = useDispatch();
  const [menu, setMenu] = useState([]);
  const location = useLocation();
  const history = useHistory();
  const { token } = useSelector((state) => state.client);
  const { defaultAccount } = useSelector((state) => state.socket);
  const _accounts = token?.custInfo.normal;
  const account = _accounts && _accounts.length > 0 ? _accounts[0] : {};
  const loadCashBalanceHandle = (subAccount = null) => {
    const uuid = uuidv4();
    const resData = {
      group: 'CORE',
      user: token.user,
      session: token.session,
      cmd: 'getTotalAssetAll',
      rqId: uuid,
      channel: 'WTS',
      data: {
        acntNo: account.acntNo,
        subAcntNo: subAccount,
      },
    };
    dispatch(cashBalanceRequest(resData));
  };

  useEffect(() => {
    let fallbackRedirectFncs = [];

    setMenu(
      nav.map((item) => ({
        ...item,
        child: item.child.filter((i) => {
          if (
            i.condition &&
            typeof i.condition == 'function' &&
            defaultAccount
          ) {
            const result = i.condition(defaultAccount); // result of condition callback func

            // if false && any fallback then add to queues fallbackRedirectFncs and excute later
            if (!result && i.fallbackRedirectUrl) {
              // fallbackRedirectFnc
              const fn = (fallbackRedirectUrl) => {
                if (fallbackRedirectUrl) {
                  const currentPath = location.pathname.split('/').pop();
                  if (currentPath == i.path) history.push(fallbackRedirectUrl);
                }
              };

              fallbackRedirectFncs.push(() => fn(i.fallbackRedirectUrl));
            }

            return result;
          }

          return true;
        }),
      }))
    );

    if (defaultAccount && location?.pathname) {
      //excute fallbackRedirect
      if (!!fallbackRedirectFncs?.length > 0) {
        fallbackRedirectFncs.forEach((fn) => {
          if (fn && typeof fn == 'function') fn();
        });
      }
    }
  }, [defaultAccount?.value, location.pathname]);

  if (!token) return <Redirect to="/" />;

  return (
    <div className="personal-page">
      <VerticalNavbar params={params} data={menu} />
      <Content
        params={params}
        loadBalanceHandle={loadCashBalanceHandle}
        nav={menu}
      />
    </div>
  );
});

export default Index;
