import React, { useContext, useEffect, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import PerfectScrollBar from 'react-perfect-scrollbar';
import { ReactReduxContext, useDispatch, useSelector } from 'react-redux';
import { handleApiErrors } from '../../../lib/api-errors';
import { getMessage } from '../../../lib/selector';
import { setToast } from '../../client/actions';
import { getBriefs, toogleVideoModal } from '../action';
import VideoDetailModal from './videoDetailModal';
import { debounce } from 'lodash';
import noImage from '../../../assets/img/noImage.svg';
import { useDebounce } from '../../../util';
import { constants } from '../../../util/constant';
import { Mixpanel } from '../../../lib/mixpanel';

const appUrl = `${process.env.REACT_APP_API_URL_2}`;
const VideoPanel = withNamespaces('translations')(({ t }) => {
  const PAGE_SIZE = 5;
  const dispatch = useDispatch();
  const lang = useSelector((state) => state?.client?.setting?.lang || 'vi');
  const briefs = useSelector((state) => state?.news?.briefs);
  const [offset, setOffset] = useState(0);
  const [topVideos, setTopVideos] = useState([]);
  const [tabActive, setTabActive] = useState(constants.news.VIDEO_TAB);
  const [videoId, setVideoId] = useState(null);
  const [keyword, setKeyword] = useState('');
  const keywordDebounce = useDebounce(keyword, 1000);
  const { store } = useContext(ReactReduxContext);

  useEffect(() => {
    dispatch(getBriefs(12, 1, 0));
  }, []);

  const selectVideoHandle = (id) => {
    dispatch(toogleVideoModal(true));
    setVideoId(id);
  };

  const handleGetVideo = () => {
    const url = `${appUrl}/api/Videos/GetLastestVideos?lang=${
      lang == 'vi' ? 1 : 2
    }&top=20&offset=${offset}&keyword=${keywordDebounce}`;
    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
    })
      .then(handleApiErrors)
      .then((response) => response.json())
      .then((res) => {
        if (res.http == 200) {
          const videos = res.data;
          if (offset == 0) {
            setTopVideos(videos);
          } else {
            const _video = [...topVideos].concat(videos);
            const _listTopVideo = _video.filter(
              (v, i, a) => a.findIndex((v2) => v2.id === v.id) === i
            );
            setTopVideos(_listTopVideo);
          }
          setOffset(offset + videos.length);
        } else {
          const msgErr = getMessage(res.data.message, store.getState);
          const toastMsg = {
            id: Math.random(),
            msg: msgErr,
            title: t('txt-notice'),
          };
          dispatch(setToast(toastMsg));
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const loadMore = debounce(() => {
    if (tabActive == constants.news.VIDEO_TAB) {
      handleGetVideo();
    } else {
      dispatch(getBriefs(12, lang == 'vi' ? 1 : 2, briefs.length));
    }
  }, 1000);

  useEffect(() => {
    handleGetVideo();
  }, [keywordDebounce]);

  useEffect(() => {
    handleGetVideo();
    dispatch(getBriefs(12, lang == 'vi' ? 1 : 2, 0));
  }, [lang]);

  return (
    <div className="card-panel h-100 w-100">
      <div className="card-panel-header">
        <div className="d-flex justify-content-between align-items-center w-100">
          <div className="d-flex card-panel-header__left">
            <span
              className={`card-panel-header__title  ${
                tabActive == constants.news.VIDEO_TAB && 'active'
              }`}
              onClick={() => setTabActive(constants.news.VIDEO_TAB)}
            >
              {t('news.video')}
            </span>
            <span
              className={`card-panel-header__title  ${
                tabActive == constants.news.MARKET_BRIEF_TAB && 'active'
              }`}
              onClick={() => setTabActive(constants.news.MARKET_BRIEF_TAB)}
            >
              {t('news.marketBrief')}
            </span>
          </div>
          {tabActive == constants.news.VIDEO_TAB && (
            <div className="d-flex card-panel-header__right">
              <div className="input-text-search d-flex align-items-center">
                <input
                  placeholder="Symbol"
                  onChange={(e) => {
                    setKeyword(e.target.value);
                    setOffset(0);
                  }}
                />
                <span class="icon iSearch"></span>
              </div>
            </div>
          )}
        </div>
      </div>
      {tabActive == constants.news.VIDEO_TAB && (
        <div className="card-panel-body">
          {videoId && (
            <VideoDetailModal videoId={videoId} setVideoId={setVideoId} />
          )}
          <PerfectScrollBar
            className="w-100"
            style={{ height: 'calc(100%)' }}
            options={{
              wheelSpeed: 1,
              wheelPropagation: true,
              minScrollbarLength: 20,
            }}
          >
            <div className="video-list">
              {topVideos &&
                topVideos.length > 0 &&
                topVideos.map((video, i) => (
                  <div className="video-container" key={i}>
                    <div className="video__thumbnail">
                      <span
                        style={{
                          width: '120px',
                          height: '80px',
                          backgroundImage: `url('${
                            video?.thumbnails?.high?.url
                          }'), url('${''}')`,
                        }}
                      ></span>
                    </div>
                    <div className="video__desc" title={video.title}>
                      <span className="video__title fz_13 fw-500 d-flex text--light">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: video.title,
                          }}
                        />
                      </span>
                      <span className="video__action">
                        <button
                          className="btn btn-icon btn--primary2"
                          onClick={() => {
                            Mixpanel.news.readNews(
                              'Pinetree',
                              'Video',
                              'News',
                              video?.title,
                              video?.path,
                              video?.publishedAt
                            );

                            selectVideoHandle(video.id);
                          }}
                        >
                          <span className="icon iPlay"></span>
                          <span>{t('news.watchNow')}</span>
                        </button>
                      </span>
                    </div>
                  </div>
                ))}
            </div>
            <div
              className="btn--loadMore text--light"
              onClick={() => loadMore()}
            >
              <span>{t('news.loadmore')}</span>
            </div>
          </PerfectScrollBar>
        </div>
      )}
      {tabActive == constants.news.MARKET_BRIEF_TAB && (
        <div className="card-panel-body">
          <PerfectScrollBar className="w-100" style={{ height: 'calc(100%)' }}>
            <div className="brief-list ">
              {(briefs &&
                briefs.length > 0 &&
                briefs.map((item) => (
                  <div
                    className="brief-container"
                    key={item.id}
                    onClick={() => {
                      Mixpanel.news.readNews(
                        'Pinetree',
                        'Market Brief',
                        'News',
                        item?.title,
                        item?.url,
                        item?.publishTime
                      );

                      window.open(item.url);
                    }}
                  >
                    <div className="bief-content">
                      <div className="brief-item morning-brief cursor-pointer">
                        <div className="brief__img">
                          <span
                            style={{
                              backgroundImage: `url('${
                                item.thumbnail || noImage
                              }')`,
                            }}
                          ></span>
                        </div>
                        <span className="brief__title fz_13 fw-500 ">
                          {item.title}
                        </span>
                      </div>
                    </div>
                  </div>
                ))) ||
                ''}
              <div
                className="btn--loadMore text--light"
                onClick={() => loadMore()}
              >
                <span>{t('news.loadmore')}</span>
              </div>
            </div>
          </PerfectScrollBar>
        </div>
      )}
    </div>
  );
});

export default VideoPanel;
