import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import { makeGetSummaryCash } from '../../lib/selector';

import PerfectScrollBar from 'react-perfect-scrollbar';
import { Col } from 'react-bootstrap';

import {
  numberFormat,
  _convertTpToPrice,
  _getOrderStatus,
  _formatDate2,
} from '../../util';

import Page from '../print/Page';
import PrintButton from '../print/PrintButton';
import FormSearchSKT from './layout/formSearchSKT';
import { Link } from 'react-router-dom';

import { IoIosArrowBack } from 'react-icons/io';
import PaginationTable from '../PaginationTable';
import logo from '../../assets/img/pinetreeSecurities.svg';

import ExportSaoKeTien from '../../lib/exports/SaoKeTien';

import { translate } from 'react-i18next';

class SaoKeTien extends React.Component {
  state = {
    params: null,
  };

  _saveParams = (params) => {
    // console.log(params)
    this.setState({ params });
  };

  render() {
    const { account, cashList, t } = this.props;
    const { params } = this.state;
    console.log('cashList', cashList);

    const columns = [
      {
        key: '_index',
        text: t('txt-stt'),
        className: 'text-center',
        align: 'center',
      },
      {
        key: 'tranDate',
        text: t('txt-time'),
        className: 'text-left',
        align: 'left',
        cell: (record) => {
          return _formatDate2(record.tranDate);
        },
      },
      {
        key: 'tranKndNm',
        text: t('txt-label-major'),
        className: 'text-left',
        align: 'left',
      },
      {
        key: 'beginAmt',
        text: t('txt-label-opening-balance'),
        className: 'text-right',
        align: 'right',
        cell: (record) => {
          return numberFormat(record.beginAmt, 0, '-');
        },
      },
      {
        key: 'inAmt',
        text: t('txt-label-increase'),
        className: 'text-right',
        align: 'right',
        cell: (record) => {
          return numberFormat(record.inAmt, 0, '-');
        },
      },
      {
        key: 'outAmt',
        text: t('txt-label-decrease'),
        className: 'text-right',
        align: 'right',
        cell: (record) => {
          return numberFormat(record.outAmt, 0, '-');
        },
      },
      {
        key: 'afterAmt',
        text: t('txt-label-closing-balance'),
        className: 'text-right',
        align: 'right',
        cell: (record) => {
          return numberFormat(record.afterAmt, 0, '-');
        },
      },
      {
        key: 'rmrk',
        text: t('txt-label-remark'),
        className: 'text-left',
        align: 'left',
        // cell: (record) => {
        //   return truncate(record.rmrk, 50);
        // },
      },
    ];

    return (
      <Fragment>
        <PerfectScrollBar style={{ height: 'calc( 100% - 105px )' }}>
          <div className="d-flex">
            <Col className="d-flex flex-column">
              <div className="d-flex mt-3 mb-2 position-relative">
                <Link
                  to="tai-san"
                  className="color-lightning fz_14 position-absolute"
                  style={{ top: '2px' }}
                >
                  <IoIosArrowBack />
                  {` ${t('txt-label-return-asset')}`}
                </Link>
                <span
                  className="text-white mx-auto"
                  style={{ fontSize: '18px' }}
                >
                  {t('txt-label-cash')}
                </span>
              </div>
              <FormSearchSKT account={account} saveParams={this._saveParams} />
              <div className="d-flex justify-content-between align-items-center mt-3 mb-2">
                <label className="text-white ml-2 fz_14 text-uppercase">
                  {t('txt-detail')}
                </label>
                <div className="d-flex align-items-baseline">
                  <ExportSaoKeTien
                    csvData={cashList ? cashList.list : []}
                    fileName="sao-ke-giao-dich-tien"
                    fromDate={params && _formatDate2(params.data.fromDate)}
                    toDate={params && _formatDate2(params.data.toDate)}
                    accountCode={account && account.subAcntNo}
                    accountName={account && account.vsdAcntNo}
                  />
                  <PrintButton id={'sao-ke-giao-dich-tien'} />
                </div>
              </div>
              <PaginationTable
                tblClassName="tbl-list"
                divClassName=" "
                pageSize={10}
                columns={columns}
                source={cashList && cashList.list ? cashList.list : []}
              />
              <Page id="sao-ke-giao-dich-tien" style={{ display: 'none' }}>
                <div className="d-flex flex-column bg-light">
                  <div
                    className="position-relative d-flex justify-content-center align-items-center"
                    style={{ height: '75px' }}
                  >
                    <img
                      src={logo}
                      alt="logo"
                      className="position-absolute"
                      style={{ top: '0', left: '0' }}
                    />
                    <span className="fz_16 fw-500 text-uppercase">
                      {'SAO KÊ GIAO DỊCH TIỀN'}
                    </span>
                  </div>
                  <div className="d-flex mt-2">
                    <Col className="d-flex flex-column">
                      <div className="d-flex">
                        <span className="fz_14 w_125">{'Tên BC:'}</span>
                        <span className="fz_14 f_i">
                          {'Sao kê giao dịch tiền'}
                        </span>
                      </div>
                      <div className="d-flex">
                        <span className="fz_14 w_125">{'Số tiểu khoản:'}</span>
                        <span className="fz_14 f_i">
                          {account && account.subAcntNo}
                        </span>
                      </div>
                      <div className="d-flex">
                        <span className="fz_14 w_125">{'Số VSD:'}</span>
                        <span className="fz_14 f_i">
                          {account && account.vsdAcntNo}
                        </span>
                      </div>
                    </Col>
                    <Col className="d-flex flex-column">
                      <div className="d-flex">
                        <span className="fz_14 w_125">{'Từ ngày:'}</span>
                        <span className="fz_14 f_i">
                          {params && _formatDate2(params.data.fromDate)}
                        </span>
                      </div>
                      <div className="d-flex">
                        <span className="fz_14 w_125">{'Đến ngày:'}</span>
                        <span className="fz_14 f_i">
                          {params && _formatDate2(params.data.toDate)}
                        </span>
                      </div>
                    </Col>
                  </div>
                </div>
                <div className="d-flex bg-light">
                  <table className="table table-bordered mt-2 table-light">
                    <thead>
                      <tr>
                        <th className="text-center text-white">{'STT'}</th>
                        <th className="text-left text-white">{'Thời gian'}</th>
                        <th className="text-left text-white">{'Nghiệp vụ'}</th>
                        <th className="text-right text-white">
                          {'Số dư đầu kỳ'}
                        </th>
                        <th className="text-right text-white">
                          {'Phát sinh tăng'}
                        </th>
                        <th className="text-right text-white">
                          {'Phát sinh giảm'}
                        </th>
                        <th className="text-right text-white">
                          {'Số dư cuối kỳ'}
                        </th>
                        <th className="text-left text-white">{'Mô tả'}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {cashList &&
                        cashList.list &&
                        !!cashList.list.length &&
                        cashList.list.map((item, i) => {
                          return (
                            <tr key={i} style={{ border: '1px solid #e8e8e8' }}>
                              <td className="text-center">{i + 1}</td>
                              <td className="text-left">
                                {_formatDate2(item.tranDate)}
                              </td>
                              <td className="text-left">{item.tranKndNm}</td>
                              <td className="text-right">
                                {numberFormat(item.beginAmt, 0, '0')}
                              </td>
                              <td className="text-right">
                                {numberFormat(item.inAmt, 0, '0')}
                              </td>
                              <td className="text-right">
                                {numberFormat(item.outAmt, 0, '0')}
                              </td>
                              <td className="text-right">
                                {numberFormat(item.afterAmt, 0, '0')}
                              </td>
                              <td className="text-left word-beak-all">
                                {item.rmrk}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </Page>
            </Col>
          </div>
        </PerfectScrollBar>
      </Fragment>
    );
  }
}

const makeMapStateToProps = () => {
  const getSummaryCash = makeGetSummaryCash();

  const mapStateToProps = (state) => {
    return {
      cashList: getSummaryCash(state),
    };
  };
  return mapStateToProps;
};

export default connect(makeMapStateToProps)(
  translate('translations')(SaoKeTien)
);
