import React, { useState, useEffect, useContext } from 'react';
import { connect, useSelector } from 'react-redux';
import {
  _getStatusIndex,
  numberFormat,
  convertToInternationalCurrencySystem,
  convertToInternationalCurrencyCharacter,
  _processMapDataIndex,
} from '../../../../util';
import { withNamespaces } from 'react-i18next';
import { Mixpanel } from '../../../../lib/mixpanel';
import { WebSocketContext } from '../../../../containers/socket/webSocket';
import MarketChart from '../../../hightchart/marketChart';

const CardChart = withNamespaces('translations')(
  ({ idata, typeIndex, t, width, setExchangeCode, setExchangeModal }) => {
    const [data, setData] = useState(idata);
    const theme = useSelector((state) => state.client.currentTheme);
    const { socket } = useContext(WebSocketContext);

    useEffect(() => {
      const realtimeHandle = (msg) => {
        const payload = msg?.data;
        if (payload && payload?.id === 1101 && payload?.mc == idata?.mc) {
          const _data = _processMapDataIndex(payload);
          setData(_data);
        }
      };

      if (socket) {
        socket.on('public', realtimeHandle);
      }

      return () => {
        if (socket) socket.off('public', realtimeHandle);
      };
    }, [socket]);

    const mapChartSymbol = (data) => {
      switch (data.name) {
        case 'VNI':
          return 'VNINDEX';
        case 'HNX':
          return 'HNXINDEX';
        case 'UPCOM':
          return 'UPCOMINDEX';
        case 'VN30':
          return 'VN30';
        default:
          return 'VNINDEX';
      }
    };

    if (!data) return null;
    if (typeIndex == 1)
      return (
        <div
          className="market-panel cursor-pointer"
          style={{ width: width ? `${width}%` : 'auto' }}
          onClick={() => {
            Mixpanel.viewTicketInfo({
              Ticker: data.name,
              'Ticker Type': 'Index',
              'Ticker Location': 'Home',
              'Ticker Location Detail': '',
            });
            setExchangeCode(mapChartSymbol(data));
            setExchangeModal(true);
          }}
        >
          <div className="market-panel-infor">
            <div className="market-panel-header cursor-pointer">
              <div className="market-panel-header__name">
                <span>{data.name}</span>
              </div>
              <div className={data.cl}>
                <span className="market-panel-header__index">
                  {numberFormat(data.cIndex, 2)}
                </span>
                <span className="market-panel-header__change">
                  {data.change}
                </span>
                <span className="market-panel-header__changePercent">
                  {data.changePc}
                </span>
              </div>
            </div>
            <div className="market-panel-body">
              <div className="d-flex align-items-center">
                <span className="icon iVolume"></span>
                <span className="value">
                  {' '}
                  {convertToInternationalCurrencySystem(data.vol)}
                </span>
                <span className="unit">
                  {convertToInternationalCurrencyCharacter(data.vol)}
                </span>
              </div>
              <div className="d-flex align-items-center">
                <span className="icon iMoney"></span>
                <span className="value">
                  {numberFormat(data.value / 1000, 0)}
                </span>
                <span className="unit">{t('txt-label-billion')}</span>
              </div>
            </div>
            <div className="market-panel-footer">
              <div className="market-session-state">
                <span>{_getStatusIndex(data.status, data.mc)}</span>
              </div>
              <div className="market-session-numbers">
                <div className="d-flex align-items-center i market-session-number">
                  <span className="icon iUp"></span>
                  <span>{data.up}</span>
                  <span className="c plus">({data.cei})</span>
                </div>
                <div className="d-flex align-items-center r market-session-number">
                  <span className="icon iRef"></span>
                  <span>{data.rel}</span>
                </div>
                <div className="d-flex align-items-center d market-session-number">
                  <span className="icon iDown"></span>
                  <span>{data.down}</span>
                  <span className="f plus">({data.flo})</span>
                </div>
              </div>
            </div>
          </div>
          <div className="market-panel-chart">
            <MarketChart mc={data.mc} width="100%" height="100%" />
          </div>
          <div className="market-panel__overlay">
            <span className="icon iZoomFull"></span>
          </div>
        </div>
      );
    else
      return (
        <div className="market-panel market-panel--small">
          <span className="market-panel-header__name">{data.name}</span>
          <span className={`market-panel-header__index ${data.cl}`}>
            {numberFormat(data.cIndex, 2)}
          </span>
          <span className={`market-panel-header__change ${data.cl}`}>
            {data.change}
          </span>
          <span className={`market-panel-header__changePercent ${data.cl}`}>
            {data.changePc}
          </span>
        </div>
      );
  }
);

export default CardChart;
