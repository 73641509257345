import { call, put, takeLatest, all, select } from 'redux-saga/effects';
import i18n from '../../i18n';
import { handleApiErrors } from '../../lib/api-errors';
import { getMessage } from '../../lib/selector';
import { getCookie, removeCookie, setCookie } from '../../lib/storages';
import { setClient, setNotiPopup, setToast } from '../client/actions';
import { updateListAccount } from '../socket/actions';
import {
  cancelPinefolioOrderSuccess,
  cancelPineOrderError,
  depositPFIPError,
  depositPFIPSuccess,
  getEstPFPlanError,
  getEstPFPlanOverError,
  getEstPFPlanOverSuccess,
  getEstPFPlanSuccess,
  getPfAnaRptCatTpError,
  getPfAnaRptCatTpSuccess,
  getPfAnaRptError,
  getPfAnaRptSuccess,
  getPFInterateMstError,
  getPFInterateMstSuccess,
  getPfIpListDtlError,
  getPfIpListDtlSuccess,
  getPFIPOneError,
  getPFIPOneSuccess,
  getPfListBondForSellSuccess,
  getPfTdListError,
  getPfTdListSuccess,
  getPfValueInfoSuccess,
  getPinefilioOrderDtlError,
  getPinefilioOrderDtlStError,
  getPinefilioOrderDtlStSuccess,
  getPinefilioOrderDtlSuccess,
  getPinefilioOrderListEror,
  getPinefilioOrderListSuccess,
  getPinefolioInvestmentPlanError,
  getPinefolioInvestmentPlanHistoryError,
  getPinefolioInvestmentPlanHistorySuccess,
  getPinefolioInvestmentPlanSuccess,
  getPlanModifyInfoRequestSuccess,
  getPlanUpdateContractSuccess,
} from './action';
import {
  GET_LIST_PINEFOLIO_REQUEST,
  GET_LIST_PINEFOLIO_SUCCESS,
  GET_PINEFOLIO_ORDER_LIST_ERROR,
  GET_PINEFOLIO_ORDER_LIST_SUCCESS,
  GET_PINEFOLIO_ORDER_LIST_REQUEST,
  GET_PINEFOLIO_ORDER_DTL_REQUEST,
  GET_PINEFOLIO_ORDER_DTL_ST_REQUEST,
  CANCEL_PINEFOLIO_ORDER_REQUEST,
  GET_THEME_DETAIL,
  GET_THEME_DETAIL_SUCCESS,
  REGIST_PINEFOLIO_SUBACCOUNT,
  REGIST_PINEFOLIO_SUBACCOUNT_SUCCESS,
  GET_PINEFOLIO_PURCHASE_POWER_REQUEST_SUCCESS,
  GET_PINEFOLIO_PURCHASE_POWER_REQUEST,
  REGIST_PINEFOLIO_SUBACCOUNT_CONTRACT,
  REGIST_PINEFOLIO_SUBACCOUNT_CONTRACT_SUCCESS,
  ORDER_PF_REQUEST,
  ORDER_PF_SUCCESS,
  ORDER_PF_CONTRACT_REQUEST,
  GET_PINEFOLIO_INVESTMENT_PLAN_REQUEST,
  GET_PINEFOLIO_INVESTMENT_PLAN_HISTORY_REQUEST,
  GET_PF_ANA_REPORT_REQUEST,
  GET_PF_ANA_REPORT_CAT_TP_REQUEST,
  GET_PF_TD_LIST_REQUESTING,
  GET_PF_IP_LIST_DTL_REQUESTING,
  GET_PF_IP_ONE_REQUESTING,
  DEPOSIT_PF_IP_REQUESTING,
  GET_PF_PORTFOLIO_REQUEST,
  GET_PF_PORTFOLIO_DETAIL_REQUEST,
  GET_PF_PORTFOLIO_DETAIL_REQUEST_SUCCESS,
  GET_PF_PORTFOLIO_REQUEST_SUCCESS,
  GET_PF_LIST_REQUEST,
  GET_PF_LIST_REQUEST_SUCCESS,
  GET_PF_CHART_REQUEST,
  GET_PF_CHART_REQUEST_SUCCESS,
  GET_PF_INTERATE_MST_REQUESTING,
  GET_EST_PF_PLAN_REQUESTING,
  GET_EST_PF_PLAN_OVER_REQUESTING,
  SELL_PF_ODD_ORDER,
  SELL_PF_ODD_ORDER_SUCCESS,
  GET_POSITION_OUTSIDE_PF,
  GET_POSITION_OUTSIDE_PF_SUCCESS,
  RESET_THEME_DETAIL,
  UPDATE_PLAN_REMINDER,
  GET_UPDATE_PLAN_CONTRACT,
  GET_PLAN_MODIFY_INFO,
  UPDATE_PF_PLAN_REQUEST,
  GET_PF_VALUE_INFO_REQUEST,
  GET_PF_LIST_BOND_FOR_SELL_REQUEST,
} from './constants';
import { LOCK_RESENT_OTP } from '../../components/modal/auth/constants';

const appUrl = `${process.env.REACT_APP_API_URL}`;
const t = i18n.t;

function handleRequest(request) {
  return request
    .then(handleApiErrors)
    .then((response) => response.json())
    .then((json) => json)
    .catch((error) => {
      throw error;
    });
}

function toastHandle(message, title = 'txt-notice') {
  const toastMsg = {
    id: Math.random(),
    msg: message,
    time: new Date(),
    title: i18n.t(`${title}`),
  };
  return put(setToast(toastMsg));
}

function getPinefolioApi(data) {
  const url = `${appUrl}/PortfolioServlet.pt`;
  const request = fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
    body: JSON.stringify(data),
  });
  return handleRequest(request);
}

function* getPinefolioOrderListFlow({ params }) {
  console.log('params', params);
  try {
    const resData = yield call(getPinefolioApi, params);
    if (resData.http !== 200) {
      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');
        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }
      const msgErr = yield select(getMessage, resData);
      throw Error(msgErr);
    }
    yield put(getPinefilioOrderListSuccess(resData.data));
  } catch (error) {
    yield put(getPinefilioOrderListEror(error));
  }
}

function* getPinefolioOrderDtlFlow({ params }) {
  console.log('params', params);
  try {
    const resData = yield call(getPinefolioApi, params);
    if (resData.http !== 200) {
      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');
        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }
      const msgErr = yield select(getMessage, resData);
      throw Error(msgErr);
    }
    yield put(getPinefilioOrderDtlSuccess(resData.data));
  } catch (error) {
    yield put(getPinefilioOrderDtlError(error));
  }
}

function* getPinefolioOrderDtlStFlow({ params }) {
  console.log('params', params);
  try {
    const resData = yield call(getPinefolioApi, params);
    if (resData.http !== 200) {
      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');
        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }
      const msgErr = yield select(getMessage, resData);
      throw Error(msgErr);
    }
    yield put(getPinefilioOrderDtlStSuccess(resData.data));
  } catch (error) {
    yield put(getPinefilioOrderDtlStError(error));
  }
}

function* cancelOrderRequestFlow(action) {
  try {
    const resData = yield call(getPinefolioApi, action.data);
    if (resData.http !== 200) {
      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');

        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }
      const msgErr = yield select(getMessage, resData);
      throw Error(msgErr);
    }
    yield put(cancelPinefolioOrderSuccess(resData));
  } catch (error) {
    yield put(cancelPineOrderError(error));
  }
}

function* getPinefolioInvestmentPlanFlow(action) {
  try {
    const resData = yield call(getPinefolioApi, action.data);
    if (resData.http != 200) {
      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');
        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }
      const msgErr = yield select(getMessage, resData);
      throw Error(msgErr);
    }
    yield put(getPinefolioInvestmentPlanSuccess(resData.data));
  } catch (error) {
    yield put(getPinefolioInvestmentPlanError(error));
  }
}

function* getPinefolioInvestmentPlanHistoryFlow(action) {
  try {
    const resData = yield call(getPinefolioApi, action.data);
    if (resData.http != 200) {
      if (resData === 401) {
        removeCookie('token');
        removeCookie('authen');
        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }
      const msgErr = yield select(getMessage, resData);
      throw Error(msgErr);
    }
    yield put(getPinefolioInvestmentPlanHistorySuccess(resData.data));
  } catch (error) {
    yield put(getPinefolioInvestmentPlanHistoryError(error));
  }
}

function* getPfAnaRptFlow(action) {
  try {
    const resData = yield call(getPinefolioApi, action.data);
    if (resData.http != 200) {
      if (resData === 401) {
        removeCookie('token');
        removeCookie('authen');
        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }
      const msgErr = yield select(getMessage, resData);
      throw Error(msgErr);
    }
    yield put(getPfAnaRptSuccess(resData.data));
  } catch (error) {
    yield put(getPfAnaRptError(error));
  }
}

function* getPfAnaRptCatTpFlow(action) {
  try {
    const resData = yield call(getPinefolioApi, action.data);
    if (resData.http != 200) {
      if (resData === 401) {
        removeCookie('token');
        removeCookie('authen');
        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }
      const msgErr = yield select(getMessage, resData);
      throw Error(msgErr);
    }
    yield put(getPfAnaRptCatTpSuccess(resData.data));
  } catch (error) {
    yield put(getPfAnaRptCatTpError(error));
  }
}

function* getPfTdListFlow(action) {
  try {
    const resData = yield call(getPinefolioApi, action.data);
    if (resData.http != 200) {
      if (resData === 401) {
        removeCookie('token');
        removeCookie('authen');
        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }
      const msgErr = yield select(getMessage, resData);
      throw Error(msgErr);
    }
    yield put(getPfTdListSuccess(resData.data));
  } catch (error) {
    yield put(getPfTdListError(error));
  }
}

function* getPfIpDtlFlow(action) {
  try {
    const resData = yield call(getPinefolioApi, action.data);
    if (resData.http != 200) {
      if (resData === 401) {
        removeCookie('token');
        removeCookie('authen');
        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }
      const msgErr = yield select(getMessage, resData);
      throw Error(msgErr);
    }
    yield put(getPfIpListDtlSuccess(resData.data));
  } catch (error) {
    yield put(getPfIpListDtlError(error));
  }
}

function* getPinefolioListFlow(action) {
  try {
    const resData = yield call(getPinefolioApi, action.params);
    if (resData.http !== 200) {
      const msgErr = yield select(getMessage, resData);
      throw Error(msgErr);
    } else yield put({ type: GET_LIST_PINEFOLIO_SUCCESS, data: resData.data });
  } catch (error) {
    yield toastHandle(error?.toString());
  }
}

function* getPineThemeDetailFlow(action) {
  try {
    const resData = yield call(getPinefolioApi, action.params);
    if (resData.http !== 200) {
      const msgErr = yield select(getMessage, resData);
      yield put({ type: RESET_THEME_DETAIL, data: null });
      throw Error(msgErr);
    } else yield put({ type: GET_THEME_DETAIL_SUCCESS, data: resData.data });
  } catch (error) {
    yield toastHandle(error?.toString());
    yield put({ type: RESET_THEME_DETAIL, data: null });
  }
}

function* registeNewPinefolioAccountFlow(action) {
  try {
    const resData = yield call(getPinefolioApi, action.params);
    if (resData.http !== 200) {
      if (resData.data.messageNo === 99991) {
        yield put({ type: LOCK_RESENT_OTP });
      }

      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');
        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      } else {
        const msgErr = yield select(getMessage, resData);
        yield toastHandle(msgErr, 'txt-error');
      }
    } else {
      // success update current list account and excute action success

      /**
       *      
       * const currentAccountList = listAccount;
          const newAccount = resData.data;
          const newAccountList = [...currentAccountList, newAccount];

          yield put(updateListAccount(newAccountList));
          const currentLocation = window.location?.hash || '';

          if (
            newAccount.subAcntClss == constants.subAccount.Derivative &&
            currentLocation == '#/home/derivative'
          ) {
            yield put(setDefaultAccount(newAccount));
          }

          const token = yield select((state) => state.client.token);
          const newToken = token;
          if (newToken && newToken.custInfo && newToken.custInfo.normal) {
            newToken.custInfo.normal = newAccountList;
          }

          // set client
          yield put(setClient(newToken));

          let tokenCookie = getCookie('token');
          // tokenCookie = tokenCookie ? JSON.parse(tokenCookie) : null;

          if (tokenCookie) {
            if (
              tokenCookie &&
              tokenCookie.custInfo &&
              tokenCookie.custInfo.normal
            ) {
              tokenCookie.custInfo.normal = newAccountList;
            }
            setCookie('token', JSON.stringify(tokenCookie));
          }
       */

      const currentAccountList = action.currentListAccounts;
      const newAccount = resData.data;
      const newAccountList = [...currentAccountList, newAccount];

      yield put(updateListAccount(newAccountList));
      const token = yield select((state) => state.client.token);
      const newToken = token;
      if (newToken && newToken.custInfo && newToken.custInfo.normal) {
        newToken.custInfo.normal = newAccountList;
      }
      // set client
      yield put(setClient(newToken));

      // save to cookie
      let tokenCookie = getCookie('token');
      if (tokenCookie) {
        if (
          tokenCookie &&
          tokenCookie.custInfo &&
          tokenCookie.custInfo.normal
        ) {
          tokenCookie.custInfo.normal = newAccountList;
        }
        setCookie('token', JSON.stringify(tokenCookie));
      }

      // toast success
      yield toastHandle(
        i18n.t('pinefolio.account.regisNewSucess'),
        'txt-success'
      );
      yield put({ type: REGIST_PINEFOLIO_SUBACCOUNT_SUCCESS });
    }
  } catch (error) {
    yield toastHandle(error?.toString(), 'txt-error');
  }
}

function* getPinefolioPurchasePowerRequestFlow(action) {
  try {
    const resData = yield call(getPinefolioApi, action.params);
    if (resData.http !== 200) {
      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');
        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      } else {
        const msgErr = yield select(getMessage, resData);
        yield toastHandle(msgErr, i18n.t('txt-error'));
      }
    } else {
      // toast success
      yield put({
        type: GET_PINEFOLIO_PURCHASE_POWER_REQUEST_SUCCESS,
        purchasePower: resData.data,
      });
    }
  } catch (error) {
    yield toastHandle(error?.toString());
  }
}

function* getRegistPinefolioContractFlow(action) {
  try {
    const resData = yield call(getPinefolioApi, action.params);
    if (resData.http !== 200) {
      const msgErr = yield select(getMessage, resData);
      throw Error(msgErr);
    } else
      yield put({
        type: REGIST_PINEFOLIO_SUBACCOUNT_CONTRACT_SUCCESS,
        data: resData.data,
      });
  } catch (error) {
    yield toastHandle(error?.toString(), 'txt-error');
  }
}

function* orderPFRequestFlow(action) {
  try {
    const callback = action.onOrderSuccess;
    const resData = yield call(getPinefolioApi, action.params);
    if (resData.http !== 200) {
      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');
        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      } else {
        const msgErr = yield select(getMessage, resData);
        yield toastHandle(msgErr, 'txt-error');
      }
    } else {
      // toast success
      if (callback && typeof callback == 'function') {
        yield callback();
      }
      yield toastHandle(
        `${i18n.t('txt-orderNo')}: ${resData.data.aplcNo}`,
        'txt-title-neworder-success'
      );

      yield put({ type: ORDER_PF_SUCCESS });
    }
  } catch (error) {
    yield toastHandle(error?.toString(), 'txt-title-neworder-unsuccess');
  }
}

function* getPFIPOneFlow(action) {
  try {
    const resData = yield call(getPinefolioApi, action.data);
    if (resData.http != 200) {
      if (resData === 401) {
        removeCookie('token');
        removeCookie('authen');
        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }
      const msgErr = yield select(getMessage, resData);
      throw Error(msgErr);
    }
    yield put(getPFIPOneSuccess(resData.data));
  } catch (error) {
    yield put(getPFIPOneError(error));
  }
}

function* depositPFIPFlow(action) {
  try {
    const resData = yield call(getPinefolioApi, action.data);
    if (resData.http != 200) {
      if (resData === 401) {
        removeCookie('token');
        removeCookie('authen');
        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }
      const msgErr = yield select(getMessage, resData);
      yield toastHandle(msgErr, 'txt-error');
    }
    yield put(depositPFIPSuccess(resData.data));
  } catch (error) {
    yield put(depositPFIPError(error));
  }
}
function* getPFPortfolioFlow(action) {
  try {
    const resData = yield call(getPinefolioApi, action.params);
    if (resData.http !== 200) {
      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');
        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      } else {
        const msgErr = yield select(getMessage, resData);
        yield toastHandle(msgErr, 'txt-error');
      }
    } else {
      yield put({ type: GET_PF_PORTFOLIO_REQUEST_SUCCESS, data: resData.data });
    }
  } catch (error) {
    yield toastHandle(error?.toString(), 'txt-error');
  }
}

function* getPFPortfolioDetailFlow(action) {
  try {
    const resData = yield call(getPinefolioApi, action.params);
    if (resData.http !== 200) {
      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');
        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      } else {
        const msgErr = yield select(getMessage, resData);
        yield toastHandle(msgErr, 'txt-error');
      }
    } else {
      yield put({
        type: GET_PF_PORTFOLIO_DETAIL_REQUEST_SUCCESS,
        data: resData.data,
      });
    }
  } catch (error) {
    yield toastHandle(error?.toString(), 'txt-error');
  }
}

function* getPFListRequestFlow(action) {
  try {
    const resData = yield call(getPinefolioApi, action.params);
    if (resData.http !== 200) {
      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');
        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      } else {
        const msgErr = yield select(getMessage, resData);
        yield toastHandle(msgErr, 'txt-error');
      }
    } else {
      yield put({
        type: GET_PF_LIST_REQUEST_SUCCESS,
        data: resData.data,
      });
    }
  } catch (error) {
    yield toastHandle(error?.toString(), 'txt-error');
  }
}

function* getPFChartRequestFlow(action) {
  try {
    const resData = yield call(getPinefolioApi, action.params);
    if (resData.http !== 200) {
      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');
        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      } else {
        const msgErr = yield select(getMessage, resData);
        yield toastHandle(msgErr, 'txt-error');
      }
    } else {
      yield put({
        type: GET_PF_CHART_REQUEST_SUCCESS,
        data: resData.data,
      });
    }
  } catch (error) {
    yield toastHandle(error?.toString(), 'txt-error');
  }
}

function* getPFInterateMstFlow(action) {
  try {
    const resData = yield call(getPinefolioApi, action.data);
    if (resData.http != 200) {
      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');
        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }
      const msgErr = yield select(getMessage, resData);
      yield toastHandle(msgErr, 'txt-error');
    }
    yield put(getPFInterateMstSuccess(resData.data));
  } catch (error) {
    yield put(getPFInterateMstError(error));
  }
}
function* getEstPFPlanFlow(action) {
  try {
    const resData = yield call(getPinefolioApi, action.data);
    if (resData.http != 200) {
      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');
        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }
      const msgErr = yield select(getMessage, resData);
      yield toastHandle(msgErr, 'txt-error');
    }
    yield put(getEstPFPlanSuccess(resData.data));
  } catch (error) {
    yield put(getEstPFPlanError(error));
  }
}

function* getEstPFPlanOverFlow(action) {
  try {
    const resData = yield call(getPinefolioApi, action.data);
    if (resData.http != 200) {
      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');
        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }
      const msgErr = yield select(getMessage, resData);
      yield toastHandle(msgErr, 'txt-error');
    }
    yield put(getEstPFPlanOverSuccess(resData.data));
  } catch (error) {
    yield put(getEstPFPlanOverError(error));
  }
}

function* sellOddSymbolPFFlow(action) {
  const callback = action.getPfOddSellable;
  try {
    const resData = yield call(getPinefolioApi, action.params);
    if (resData.http != 200) {
      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');
        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }
      const msgErr = yield select(getMessage, resData);
      yield toastHandle(msgErr, 'txt-error');
    } else {
      const orderNo = resData.data?.ordrNo;
      if (callback && typeof callback == 'function') {
        yield callback();
      }
      yield toastHandle(
        `${i18n.t('txt-orderNo')} ${orderNo}`,
        i18n.t('txt-title-neworder-success')
      );
    }
  } catch (error) {
    yield toastHandle(error.toString(), i18n.t('txt-error'));
  }
}

function* getPosOutSidePFFlow(action) {
  try {
    const resData = yield call(getPinefolioApi, action.params);
    if (resData.http != 200) {
      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');
        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }
      const msgErr = yield select(getMessage, resData);
      yield toastHandle(msgErr, 'txt-error');
    } else {
      yield put({ type: GET_POSITION_OUTSIDE_PF_SUCCESS, data: resData.data });
    }
  } catch (error) {
    yield toastHandle(error.toString(), i18n.t('txt-error'));
  }
}

function* updatePlanReminderFlow(action) {
  try {
    const resData = yield call(getPinefolioApi, action.params);
    // const callback = action.callback;
    if (resData.http != 200) {
      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');
        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }
      const msgErr = yield select(getMessage, resData);
      console.log('msgErr', msgErr);
      yield toastHandle(msgErr, i18n.t('txt-error'));
    } else {
      yield toastHandle(
        i18n.t('pinefolio.plan.toastMsgEditSucess'),
        i18n.t('txt-notice')
      );
      // callback();
    }
  } catch (error) {
    yield toastHandle(error.toString(), i18n.t('txt-error'));
  }
}

function* getUpdatePlanContractFlow(action) {
  try {
    const resData = yield call(getPinefolioApi, action.params);
    const callback = action.callback;
    if (resData.http != 200) {
      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');
        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }
      const msgErr = yield select(getMessage, resData);
      yield toastHandle(msgErr, i18n.t('txt-error'));
    } else {
      yield put(getPlanUpdateContractSuccess(resData.data));
      if (callback && typeof callback == 'function') {
        yield callback();
      }
    }
  } catch (error) {
    yield toastHandle(error.toString(), i18n.t('txt-error'));
  }
}

function* getPlanModifyInfoFlow(action) {
  try {
    const resData = yield call(getPinefolioApi, action.params);
    if (resData.http != 200) {
      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');
        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }
      const msgErr = yield select(getMessage, resData);
      yield toastHandle(msgErr, i18n.t('txt-error'));
    } else {
      yield put(getPlanModifyInfoRequestSuccess(resData.data));
    }
  } catch (error) {
    yield toastHandle(error.toString(), i18n.t('txt-error'));
  }
}

function* updatePfPlanFlow(action) {
  try {
    const resData = yield call(getPinefolioApi, action.params);
    const callback = action.callback;
    if (resData.http != 200) {
      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');
        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }
      const msgErr = yield select(getMessage, resData);
      yield toastHandle(msgErr, i18n.t('txt-error'));
    } else {
      yield toastHandle(
        i18n.t('pinefolio.plan.toastMsgEditSucess'),
        i18n.t('txt-notice')
      );
      yield callback();
    }
  } catch (error) {
    yield toastHandle(error.toString(), i18n.t('txt-error'));
  }
}

function* getPfValueInfoFlow(action) {
  try {
    const resData = yield call(getPinefolioApi, action.params);
    if (resData.http != 200) {
      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');
        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }
      const msgErr = yield select(getMessage, resData);
      yield toastHandle(msgErr, i18n.t('txt-error'));
    } else {
      yield put(getPfValueInfoSuccess(resData.data));
    }
  } catch (error) {
    yield toastHandle(error.toString(), i18n.t('txt-error'));
  }
}

function* getPfListBondForSellFlow(action) {
  try {
    const resData = yield call(getPinefolioApi, action.params);
    if (resData.http != 200) {
      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');
        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }
      const msgErr = yield select(getMessage, resData);
      yield toastHandle(msgErr, i18n.t('txt-error'));
    } else {
      yield put(getPfListBondForSellSuccess(resData.data));
    }
  } catch (error) {
    yield toastHandle(error.toString(), i18n.t('txt-error'));
  }
}

function* pinefolioWatcher() {
  yield all([
    takeLatest(GET_LIST_PINEFOLIO_REQUEST, getPinefolioListFlow),
    takeLatest(GET_THEME_DETAIL, getPineThemeDetailFlow),
    takeLatest(GET_PINEFOLIO_ORDER_LIST_REQUEST, getPinefolioOrderListFlow),
    takeLatest(GET_PINEFOLIO_ORDER_DTL_REQUEST, getPinefolioOrderDtlFlow),
    takeLatest(GET_PINEFOLIO_ORDER_DTL_ST_REQUEST, getPinefolioOrderDtlStFlow),
    takeLatest(CANCEL_PINEFOLIO_ORDER_REQUEST, cancelOrderRequestFlow),
    takeLatest(
      GET_PINEFOLIO_INVESTMENT_PLAN_REQUEST,
      getPinefolioInvestmentPlanFlow
    ),
    takeLatest(
      GET_PINEFOLIO_INVESTMENT_PLAN_HISTORY_REQUEST,
      getPinefolioInvestmentPlanHistoryFlow
    ),
    takeLatest(GET_PF_ANA_REPORT_REQUEST, getPfAnaRptFlow),
    takeLatest(GET_PF_ANA_REPORT_CAT_TP_REQUEST, getPfAnaRptCatTpFlow),
    takeLatest(GET_PF_TD_LIST_REQUESTING, getPfTdListFlow),
    takeLatest(GET_PF_IP_LIST_DTL_REQUESTING, getPfIpDtlFlow),
    takeLatest(REGIST_PINEFOLIO_SUBACCOUNT, registeNewPinefolioAccountFlow),
    takeLatest(
      GET_PINEFOLIO_PURCHASE_POWER_REQUEST,
      getPinefolioPurchasePowerRequestFlow
    ),
    takeLatest(
      REGIST_PINEFOLIO_SUBACCOUNT_CONTRACT,
      getRegistPinefolioContractFlow
    ),
    takeLatest(ORDER_PF_REQUEST, orderPFRequestFlow),
    takeLatest(GET_PF_IP_ONE_REQUESTING, getPFIPOneFlow),
    takeLatest(DEPOSIT_PF_IP_REQUESTING, depositPFIPFlow),
    takeLatest(GET_PF_PORTFOLIO_REQUEST, getPFPortfolioFlow),
    takeLatest(GET_PF_PORTFOLIO_DETAIL_REQUEST, getPFPortfolioDetailFlow),
    takeLatest(GET_PF_LIST_REQUEST, getPFListRequestFlow),
    takeLatest(GET_PF_CHART_REQUEST, getPFChartRequestFlow),
    takeLatest(GET_PF_INTERATE_MST_REQUESTING, getPFInterateMstFlow),
    takeLatest(GET_EST_PF_PLAN_REQUESTING, getEstPFPlanFlow),
    takeLatest(GET_EST_PF_PLAN_OVER_REQUESTING, getEstPFPlanOverFlow),
    takeLatest(SELL_PF_ODD_ORDER, sellOddSymbolPFFlow),
    takeLatest(GET_POSITION_OUTSIDE_PF, getPosOutSidePFFlow),
    takeLatest(UPDATE_PLAN_REMINDER, updatePlanReminderFlow),
    takeLatest(GET_UPDATE_PLAN_CONTRACT, getUpdatePlanContractFlow),
    takeLatest(GET_PLAN_MODIFY_INFO, getPlanModifyInfoFlow),
    takeLatest(UPDATE_PF_PLAN_REQUEST, updatePfPlanFlow),
    takeLatest(GET_PF_VALUE_INFO_REQUEST, getPfValueInfoFlow),
    takeLatest(GET_PF_LIST_BOND_FOR_SELL_REQUEST, getPfListBondForSellFlow),
  ]);
}

export default pinefolioWatcher;
