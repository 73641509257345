import { constants } from '../../util/constant';
import {
  ALL_STOCK_REQUESTING,
  ALL_STOCK_REQUEST_SUCCESS,
  ALL_STOCK_REQUEST_ERROR,
  SNAPSHOT_REQUESTING,
  SNAPSHOT_REQUEST_SUCCESS,
  SNAPSHOT_REQUEST_ERROR,
  STOCK_INFO_REQUESTING,
  STOCK_INFO_REQUEST_SUCCESS,
  STOCK_INFO_REQUEST_ERROR,
  ALL_ORD_REQUESTING,
  ALL_ORD_REQUEST_SUCCESS,
  ALL_ORD_REQUEST_ERROR,
  SNAPSHOT_PART_REQUESTING,
  SNAPSHOT_PART_REQUEST_SUCCESS,
  SNAPSHOT_PART_REQUEST_ERROR,
  CLEAR_PART_REQUEST,
  PT_LIST_REQUESTING,
  PT_LIST_REQUEST_SUCCESS,
  PT_LIST_REQUEST_ERROR,
  CW_LIST_REQUEST_SUCCESS,
  CW_LIST_REQUEST_ERROR,
  CW_LIST_REQUESTING,
  LIST_30_REQUESTING,
  LIST_30_REQUEST_SUCCESS,
  LIST_30_REQUEST_ERROR,
  LIST_MESSAGE_REQUESTING,
  LIST_MESSAGE_REQUEST_SUCCESS,
  LIST_MESSAGE_REQUEST_ERROR,
  BRANCH_CODE_REQUEST_SUCCESS,
  BRANCH_CODE_REQUESTING,
  BRANCH_CODE_REQUEST_ERROR,
  SUMMARY_REQUESTING,
  SUMMARY_REQUEST_SUCCESS,
  SUMMARY_REQUEST_ERROR,
  NOI_CAP_REQUESTING,
  NOI_CAP_REQUEST_SUCCESS,
  NOI_CAP_REQUEST_ERROR,
  GET_STOCK_BY_ID_REQUESTING,
  GET_STOCK_BY_ID_REQUEST_SUCCESS,
  GET_STOCK_BY_ID_REQUEST_ERROR,
  INDEX_REQUESTING,
  INDEX_REQUEST_SUCCESS,
  INDEX_REQUEST_ERROR,
  WORLD_INDEX_REQUESTING,
  WORLD_INDEX_REQUEST_SUCCESS,
  WORLD_INDEX_REQUEST_ERROR,
  PRICE_TABLE_SORT_FIELD_CHANGE,
  PRICE_TABLE_SORT_DIRECTION_CHAGE,
  SET_PRICE_TABLE_SYMBOLS,
  ODD_LOT_SETUP,
  COMMODITY_REQUESTING,
  COMMODITY_REQUESTING_ERROR,
  COMMODITY_REQUESTING_SUCCESS,
  GET_PRICEBOARD_COMMON_REQUEST,
  SET_CW_SEARCH_FILTER,
  SET_CW_SNAPSHOT,
  GET_CW_SNAPSHOT,
} from './constants';

export const allStockRequest = function allStockRequest() {
  return {
    type: ALL_STOCK_REQUESTING,
  };
};

export const allStockRequestSuccess = function allStockRequestSuccess(
  allStock
) {
  return {
    type: ALL_STOCK_REQUEST_SUCCESS,
    allStock,
  };
};

export const allStockRequestError = function allStockRequestError(error) {
  return {
    type: ALL_STOCK_REQUEST_ERROR,
    error,
  };
};

export const listMessageRequest = function listMessageRequest() {
  return {
    type: LIST_MESSAGE_REQUESTING,
  };
};

export const listMessageRequestSuccess = function listMessageRequestSuccess(
  listMessage
) {
  return {
    type: LIST_MESSAGE_REQUEST_SUCCESS,
    listMessage,
  };
};

export const listMessageRequestError = function listMessageRequestError(error) {
  return {
    type: LIST_MESSAGE_REQUEST_ERROR,
    error,
  };
};

export const snapshotRequest = function snapshotRequest(data, dataType = null) {
  return {
    type: SNAPSHOT_REQUESTING,
    data,
    dataType,
  };
};

export const snapshotRequestSuccess = function snapshotRequestSuccess(
  dataList
) {
  return {
    type: SNAPSHOT_REQUEST_SUCCESS,
    dataList,
  };
};

export const snapshotRequestError = function snapshotRequestError(error) {
  return {
    type: SNAPSHOT_REQUEST_ERROR,
    error,
  };
};

export const clearPartRequest = function clearPartRequest(data) {
  return {
    type: CLEAR_PART_REQUEST,
  };
};

export const snapshotPartRequest = function snapshotPartRequest(data) {
  return {
    type: SNAPSHOT_PART_REQUESTING,
    data,
  };
};

export const snapshotPartRequestSuccess = function snapshotPartRequestSuccess(
  dataList
) {
  return {
    type: SNAPSHOT_PART_REQUEST_SUCCESS,
    dataList,
  };
};

export const snapshotPartRequestError = function snapshotPartRequestError(
  error
) {
  return {
    type: SNAPSHOT_PART_REQUEST_ERROR,
    error,
  };
};

export const stockInfoRequest = function stockInfoRequest(data) {
  return {
    type: STOCK_INFO_REQUESTING,
    data,
  };
};

export const stockInfoRequestSuccess = function stockInfoRequestSuccess(
  stockInfo
) {
  return {
    type: STOCK_INFO_REQUEST_SUCCESS,
    stockInfo,
  };
};

export const stockInfoRequestError = function stockInfoRequestError(error) {
  return {
    type: STOCK_INFO_REQUEST_ERROR,
    error,
  };
};

export const allOrdRequest = function allOrdRequest(data) {
  return {
    type: ALL_ORD_REQUESTING,
    data,
  };
};

export const allOrdRequestSuccess = function allOrdRequestSuccess(allOrder) {
  return {
    type: ALL_ORD_REQUEST_SUCCESS,
    allOrder,
  };
};

export const allOrdRequestError = function allOrdRequestError(error) {
  return {
    type: ALL_ORD_REQUEST_ERROR,
    error,
  };
};

export const ptListRequest = function ptListRequest() {
  return {
    type: PT_LIST_REQUESTING,
  };
};

export const ptListRequestSuccess = function ptListRequestSuccess(ptList) {
  return {
    type: PT_LIST_REQUEST_SUCCESS,
    ptList,
  };
};

export const ptListRequestError = function ptListRequestError(error) {
  return {
    type: PT_LIST_REQUEST_ERROR,
    error,
  };
};

export const list30Request = function list30Request(data) {
  return {
    type: LIST_30_REQUESTING,
    data,
  };
};

export const list30RequestSuccess = function list30RequestSuccess(list30) {
  return {
    type: LIST_30_REQUEST_SUCCESS,
    list30,
  };
};

export const list30RequestError = function list30RequestError(error) {
  return {
    type: LIST_30_REQUEST_ERROR,
    error,
  };
};

export const cwListRequest = function cwListRequest() {
  return {
    type: CW_LIST_REQUESTING,
  };
};

export const cwListRequestSuccess = function cwListRequestSuccess(cwList) {
  return {
    type: CW_LIST_REQUEST_SUCCESS,
    cwList,
  };
};

export const cwListRequestError = function cwListRequestError(error) {
  return {
    type: CW_LIST_REQUEST_ERROR,
    error,
  };
};

export const branchCodeRequest = function branchCodeRequest() {
  return {
    type: BRANCH_CODE_REQUESTING,
  };
};

export const branchCodeRequestSuccess = function branchCodeRequestSuccess(
  branchList
) {
  return {
    type: BRANCH_CODE_REQUEST_SUCCESS,
    branchList,
  };
};

export const branchCodeRequestError = function branchCodeRequestError(error) {
  return {
    type: BRANCH_CODE_REQUEST_ERROR,
    error,
  };
};

export const noicapRequest = function noicapRequest() {
  return {
    type: NOI_CAP_REQUESTING,
  };
};

export const noicapRequestSuccess = function noicapRequestSuccess(noicap) {
  return {
    type: NOI_CAP_REQUEST_SUCCESS,
    noicap,
  };
};

export const noicapRequestError = function noicapRequestError(error) {
  return {
    type: NOI_CAP_REQUEST_ERROR,
    error,
  };
};

export const indSumRequest = function indSumRequest() {
  return {
    type: SUMMARY_REQUESTING,
  };
};

export const indSumRequestSuccess = function indSumRequestSuccess(indSum) {
  return {
    type: SUMMARY_REQUEST_SUCCESS,
    indSum,
  };
};

export const indSumRequestError = function indSumRequestError(error) {
  return {
    type: SUMMARY_REQUEST_ERROR,
    error,
  };
};

export const getStockByIdRequest = function getStockByIdRequest(
  data,
  board = constants.G.DEFAULT
) {
  return {
    type: GET_STOCK_BY_ID_REQUESTING,
    data,
    board,
  };
};

export const getStockByIdRequestSuccess = function getStockByIdRequestSuccess(
  dataList
) {
  return {
    type: GET_STOCK_BY_ID_REQUEST_SUCCESS,
    dataList,
  };
};

export const getStockByIdRequestError = function getStockByIdRequestError(
  error
) {
  return {
    type: GET_STOCK_BY_ID_REQUEST_ERROR,
    error,
  };
};

export const indexRequest = function indexRequest(data, callback = null) {
  return {
    type: INDEX_REQUESTING,
    data,
    callback,
  };
};

export const indexRequestSuccess = function indexRequestSuccess(indexList) {
  return {
    type: INDEX_REQUEST_SUCCESS,
    indexList,
  };
};

export const indexRequestError = function indexRequestError(error) {
  return {
    type: INDEX_REQUEST_ERROR,
    error,
  };
};

export const worldIndexRequest = function worldIndexRequest() {
  return {
    type: WORLD_INDEX_REQUESTING,
  };
};

export const worldIndexRequestSuccess = function worldIndexRequestSuccess(
  worldList
) {
  return {
    type: WORLD_INDEX_REQUEST_SUCCESS,
    worldList,
  };
};

export const worldIndexRequestError = function worldIndexRequestError(error) {
  return {
    type: WORLD_INDEX_REQUEST_ERROR,
    error,
  };
};

export const changeSortField = function changeSortField(sortField) {
  return {
    type: PRICE_TABLE_SORT_FIELD_CHANGE,
    sortField,
  };
};

export const changeSortDirection = function changeSortDirection() {
  return {
    type: PRICE_TABLE_SORT_DIRECTION_CHAGE,
  };
};

export const setPriceTableSymbols = (symbols) => {
  return {
    type: SET_PRICE_TABLE_SYMBOLS,
    symbols,
  };
};

export const setOddlot = (status = false) => {
  return {
    type: ODD_LOT_SETUP,
    status,
  };
};

export const commodityRequest = function commodityRequest(lang) {
  return {
    type: COMMODITY_REQUESTING,
    lang: lang,
  };
};

export const commodityRequestSuccess = function commodityRequestSuccess(
  commodityList
) {
  return {
    type: COMMODITY_REQUESTING_SUCCESS,
    commodityList,
  };
};

export const commodityRequestError = function commodityRequestError(error) {
  return {
    type: COMMODITY_REQUESTING_ERROR,
    error,
  };
};

export const getPriceboardCommonRequest = (
  params,
  callback,
  isProtect = false
) => {
  return {
    type: GET_PRICEBOARD_COMMON_REQUEST,
    params,
    callback,
    isProtect,
  };
};

export const setCWSearchRequest = (filter) => {
  return {
    type: SET_CW_SEARCH_FILTER,
    filter,
  };
};

export const getCWSnapshotRequest = (data, callback = null) => {
  return {
    type: GET_CW_SNAPSHOT,
    data,
    callback,
  };
};
