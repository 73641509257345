import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { withNamespaces } from 'react-i18next';
import '../index.scss';
import TextMask from 'react-text-mask';
import { createNumberMask } from 'text-mask-addons';
import {
  DERIVATIVE_ORDER_TYPE,
  numberFormat,
  StringToInt,
} from '../../../../util';
import { v4 as uuidv4 } from 'uuid';
import { handleApiErrors } from '../../../../lib/api-errors';
import {
  Logout,
  logoutRequest,
} from '../../../../components/modal/login/actions';
import { setToast, unsetClient } from '../../../client/actions';
import { removeCookie } from '../../../../lib/storages';
import { getMessage } from '../../../../lib/selector';
import Select from 'react-select';
import ReactSelect from 'react-select';
import AuthenStep from '../../../../components/common/auth';
import {
  PARTNER_FEE_POLICY,
  PARTNER_REQUEST_TYPE,
  PARTNER_SERVICE_TYPE,
  PARTNER_SIDE_REQUEST,
} from '../../../../util/constant';
import { getCommonRequest } from '../../../customer/actions';
import ViewFeeDefault from '../partnership/viewFeeDefault';

const ModalAddNewCustomer = withNamespaces('translations')(
  ({ t, showModal, handleClose, brkId }) => {
    const theme = useSelector((state) => state.client.currentTheme);
    const {
      token,
      setting: { lang = 'vi' },
    } = useSelector((state) => state.client);
    const { broker: brokers } = useSelector((state) => state.customer);
    const [brkerId, setBrkerId] = useState(brkId || brokers[0]?.brokerId);
    const [oneTimeToken, setOneTimeToken] = useState();
    const [vsdAccount, setVsdAccount] = useState();
    const [custPhone, setCustPhone] = useState();
    const dispatch = useDispatch();
    const [listFee, setListFee] = useState();
    const defaultAccount = useSelector((state) => state.socket.defaultAccount);
    const _handleGetFeeDefault = () => {
      if (!token) return;
      const uuid = uuidv4();
      const resData = {
        group: 'CORE',
        user: token.user,
        session: token.session,
        cmd: 'getFeePolicy',
        rqId: uuid,
        channel: 'WTS',
        data: {
          cif: token.cif,
          acntNo: defaultAccount.acntNo,
          grpAcctTp: String(PARTNER_FEE_POLICY.defaultForCustomer),
          serTp: PARTNER_SERVICE_TYPE.all,
          langTp: lang,
        },
      };

      const callback = (data) => {
        setListFee(data);
      };

      dispatch(getCommonRequest(resData, callback, true));
    };

    const toastHandle = (msg, title = t('txt-notice')) => {
      const toastMsg = {
        id: Math.random(),
        msg: msg,
        title: title,
      };
      dispatch(setToast(toastMsg));
    };

    useEffect(() => {
      setBrkerId(brkId);
    }, [brkId]);

    const handleCreateRequest = () => {
      if (vsdAccount === '' || !vsdAccount) {
        toastHandle(t('partner.validate.vsdAccount'));
        return;
      }
      if (custPhone === '' || !custPhone) {
        toastHandle(t('partner.validate.custPhone'));
      }
      const uuid = uuidv4();
      const params = {
        group: 'CORE',
        user: token.user,
        session: token.session,
        cmd: 'regUnregRef',
        rqId: uuid,
        channel: 'WTS',
        type: '6',
        token: oneTimeToken,
        data: {
          cif: token.cif,
          acntNo: defaultAccount.acntNo,
          side: PARTNER_SIDE_REQUEST.broker + '',
          brkId: brkerId,
          reqTp: PARTNER_REQUEST_TYPE.addBroker + '',
          vsdAcntNo: vsdAccount,
          custPhone: custPhone,
        },
      };

      const callback = (data) => {
        toastHandle(t('partner.createRequestSuccess'));
        handleClose();
      };

      dispatch(getCommonRequest(params, callback, true));
    };

    useEffect(() => {
      if (showModal) {
        _handleGetFeeDefault();
      }
    }, [showModal]);
    return (
      <Modal
        onHide={handleClose}
        onEscapeKeyDown={handleClose}
        show={showModal}
        dialogClassName="wts-modal"
        backdropClassName="wts-modal-backdrop"
        contentClassName={`${
          theme?.name || 'dark'
        } modal-add-customer wts-modal-content `}
        centered
        style={{ zIndex: 11114 }}
      >
        <div className={`modal-add-customer__header p-2 position-relative`}>
          <div className="text-center w-100">{t('partner.addNewCustomer')}</div>
          <div
            className="position-absolute icon-close"
            onClick={() => {
              handleClose();
            }}
          >
            <span className={'icon iClose'}></span>
          </div>
        </div>
        <div className="modal-add-customer__body">
          <div className="d-flex gap-16 p-3">
            <div className="w-100">
              <div className="text text--sm text--light3">
                {t('partner.referralCode')}
              </div>
              <ReactSelect
                options={brokers
                  .filter((el) => el.status === '0')
                  .map((el) => ({
                    value: el.brokerId,
                  }))}
                defaultValue={{
                  value: brkId || brokers[0]?.brokerId,
                }}
                className="filter-control-select"
                classNamePrefix="filter-control-select"
                isSearchable={false}
                getOptionLabel={(option) => option.value}
                getOptionValue={(option) => option.value}
                onChange={(value) => setBrkerId(value.value)}
                components={{
                  IndicatorSeparator: () => null,
                }}
              />
            </div>
            <div className="w-100">
              <div className="text text--sm text--light3">
                {t('partner.vsdNumber')}
              </div>
              <input
                className="_input"
                onChange={(e) => {
                  setVsdAccount(e.target.value);
                }}
              />
            </div>
            <div className="w-100">
              <div className="text text--sm text--light3">
                {t('partner.phoneNumber')}
              </div>

              <input
                className="_input"
                onChange={(e) => {
                  setCustPhone(e.target.value);
                }}
              />
            </div>
          </div>
          <div className={'p-3'}>
            <ViewFeeDefault t={t} listFee={listFee} viewFull />
            <div className="mt-4">
              <AuthenStep setOneTimeToken={setOneTimeToken} trackMixpanel />
            </div>
            <button
              className={'btn btn--primary w-100 h-32 mt-4'}
              disabled={!oneTimeToken}
              onClick={() => {
                handleCreateRequest();
              }}
            >
              {t('txt-confirm')}
            </button>
          </div>
        </div>
      </Modal>
    );
  }
);

export default ModalAddNewCustomer;
