import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { withNamespaces } from 'react-i18next';
import '../index.scss';
import { v4 as uuidv4 } from 'uuid';
import { handleApiErrors } from '../../../../lib/api-errors';
import AuthenStep from '../../../../components/common/auth';
import PDFViewer from '../../../bond/components/Contract/pdfViewer';
import {
  PARTNER_FEE_POLICY,
  PARTNER_REQUEST_ADD_REMOVE_TYPE,
  PARTNER_REQUEST_APPROVE_REJECT_TYPE,
  PARTNER_SERVICE_TYPE,
} from '../../../../util/constant';
import { getCommonRequest } from '../../../customer/actions';
import { setToast } from '../../../client/actions';
import Checkbox from '../../../../components/common/checkbox';
import { numberFormat } from '../../../../util';
import ViewFeeDefault from '../partnership/viewFeeDefault';

const ModalConfirmAddBroker = withNamespaces('translations')(
  ({ t, showModal, handleClose, dataRequest, handleLoadData }) => {
    const theme = useSelector((state) => state.client.currentTheme);
    const {
      token,
      setting: { lang = 'vi' },
    } = useSelector((state) => state.client);
    const [oneTimeToken, setOneTimeToken] = useState();
    const dispatch = useDispatch();
    const defaultAccount = useSelector((state) => state.socket.defaultAccount);
    const [confirmContract, setConfirmContract] = useState();
    const [listFee, setListFee] = useState();
    const [dataContract, setDataContract] = useState();

    const toastHandle = (msg, title = t('txt-notice')) => {
      const toastMsg = {
        id: Math.random(),
        msg: msg,
        title: title,
      };
      dispatch(setToast(toastMsg));
    };

    const _handleGetFeeDefault = () => {
      if (!token) return;
      const uuid = uuidv4();
      const resData = {
        group: 'CORE',
        user: token.user,
        session: token.session,
        cmd: 'getFeePolicy',
        rqId: uuid,
        channel: 'WTS',
        data: {
          cif: token.cif,
          acntNo: defaultAccount.acntNo,
          grpAcctTp: String(PARTNER_FEE_POLICY.defaultForCustomer),
          serTp: PARTNER_SERVICE_TYPE.all,
          langTp: lang,
        },
      };

      const callback = (data) => {
        setListFee(data);
      };

      dispatch(getCommonRequest(resData, callback, true));
    };

    const _handleContract = () => {
      if (!token) return;
      const uuid = uuidv4();
      const resData = {
        group: 'CORE',
        user: token.user,
        session: token.session,
        cmd: 'getCustBrkCtrFile',
        rqId: uuid,
        channel: 'WTS',
        data: {
          cif: token.cif,
          acntNo: defaultAccount.acntNo,
          langTp: lang,
          brokerId: dataRequest?.brokerId,
        },
      };

      const callback = (data) => {
        setDataContract(data?.fileUrl);
      };

      dispatch(getCommonRequest(resData, callback, true));
    };

    const handleConfirmRequest = (type) => {
      const uuid = uuidv4();
      const params = {
        group: 'CORE',
        user: token.user,
        session: token.session,
        cmd: 'confRejReq',
        rqId: uuid,
        channel: 'WTS',
        token: oneTimeToken,
        type: '6',
        data: {
          cif: token.cif,
          acntNo: defaultAccount.acntNo,
          reqDt: dataRequest.reqDate + '',
          reqSeq: dataRequest.reqSeq + '',
          reqTp: PARTNER_REQUEST_ADD_REMOVE_TYPE.ADD,
          cfrmTp: String(type),
        },
      };

      const callback = (data) => {
        toastHandle(
          (type === PARTNER_REQUEST_APPROVE_REJECT_TYPE.APPROVE
            ? t('transaction.marginDebt.approved')
            : t('transaction.marginDebt.rejected')) +
            ' ' +
            t('txt-success')
        );
        handleClose();
        handleLoadData();
      };

      dispatch(getCommonRequest(params, callback, true));
    };

    useEffect(() => {
      if (showModal) {
        _handleGetFeeDefault();
        _handleContract();
      }
    }, [showModal]);
    return (
      <Modal
        onHide={handleClose}
        onEscapeKeyDown={handleClose}
        show={showModal}
        dialogClassName="wts-modal"
        backdropClassName="wts-modal-backdrop"
        contentClassName={`${
          theme?.name || 'dark'
        } modal-add-customer modal-add-customer-confirm wts-modal-content`}
        centered
        style={{ zIndex: 11114 }}
      >
        <div className={`modal-add-customer__header p-2 position-relative`}>
          <div className="text-center w-100">{t('partner.inviteReferral')}</div>
          <div
            className="position-absolute icon-close"
            onClick={() => {
              handleClose();
            }}
          >
            <span className={'icon iClose'}></span>
          </div>
        </div>
        <div className="d-flex">
          <div className="modal-add-customer__body page-partner-list">
            <div className="partner-border-bottom p-3 ">
              <div className="w-100 d-flex">
                <div className="text text--sm text--light2 w-120">
                  {t('partner.referralCode')}
                </div>
                <div className="font-weight-bold text text--sm text--light">
                  {dataRequest?.brokerId}
                </div>
              </div>
              <div className="w-100 d-flex mt-2">
                <div className="text text--sm text--light2 w-120">
                  {t('partner.referralName')}
                </div>
                <div className="font-weight-bold text text--sm text--light">
                  {dataRequest?.brokerId}
                </div>
              </div>
            </div>
            <div className={'p-3'}>
              <ViewFeeDefault t={t} listFee={listFee} />
              <Checkbox
                onChange={(e) => {
                  setConfirmContract(e.target.checked);
                }}
                title={t('partner.textConfirmAddBroker')}
                type="checkbox"
                checked={confirmContract}
                className="checkbox-group partner-checkbox mt-3"
              />
              <div className="mt-4">
                <AuthenStep setOneTimeToken={setOneTimeToken} trackMixpanel />
              </div>
              <div className="d-flex gap-16">
                <button
                  disabled={!oneTimeToken || !confirmContract}
                  className={'btn btn--primary w-100 h-32 mt-4'}
                  onClick={() => {
                    handleConfirmRequest(
                      PARTNER_REQUEST_APPROVE_REJECT_TYPE.APPROVE
                    );
                  }}
                >
                  {t('partner.approve')}
                </button>
                <button
                  disabled={!oneTimeToken}
                  onClick={() => {
                    handleConfirmRequest(
                      PARTNER_REQUEST_APPROVE_REJECT_TYPE.REJECT
                    );
                  }}
                  className={'btn btn-danger-secondary w-100 h-32 mt-4 d'}
                >
                  {t('partner.reject')}
                </button>
              </div>
            </div>
          </div>
          <div className="w-100 view-pdf">
            {dataContract && <PDFViewer src={dataContract || ''} scale={1.3} />}
          </div>
        </div>
      </Modal>
    );
  }
);

export default ModalConfirmAddBroker;
