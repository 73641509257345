import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { filter, some, find, map, remove } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { Formik, Form, Field } from 'formik';
import { maxLength, required } from '../../../../util/formikValidation';
import {
  setCategory,
  saveCategoryRequest,
  setToast,
} from '../../../../containers/client/actions';
import { saveState } from '../../../../lib/storages';
import PerfectScrollBar from 'react-perfect-scrollbar';
import { Mixpanel } from '../../../../lib/mixpanel';

const WatchListActions = withNamespaces('translations')(({ categoryId, t }) => {
  const dispatch = useDispatch();
  const {
    category,
    token,
    categoryRequesting,
    categorySuccessful,
    categoryErrors,
  } = useSelector((state) => state.client);
  const [watchlist, setWatchlist] = useState();
  const [hoverIndex, setHoverIndex] = useState(-1);
  const [editIndex, setEditIndex] = useState(-1);
  const [delIndex, setDeleteIndex] = useState(-1);
  const [name, setName] = useState('');
  const elRef = useRef();

  //   useEffect(() => {
  //     if (elRef) elRef.current.focus();
  //   }, []);

  useEffect(() => {
    if (category) {
      const categoryWatchList = filter(category, (o) => o.type === 'watchlist');
      setWatchlist(categoryWatchList);
    }
  }, [category]);

  useEffect(() => {
    if (!categoryRequesting) {
      if (categorySuccessful) {
        console.log('categorySuccessful');
        const categoryWatchList = filter(
          category,
          (o) => o.type === 'watchlist'
        );
        setWatchlist(categoryWatchList);
      }
    }
  }, [categoryRequesting]);

  const cutText = (str) => {
    if (str && str.length > 20) return `${str.substring(0, 20)}...`;
    else return str;
  };

  const pinHandle = (item, unPin = false) => {
    if (unPin) {
      // unpin category
      const _newCategory = map(category, (element) => {
        if (element.id !== item.id) return element;
        let _element = JSON.parse(JSON.stringify(element));
        // fix bug: Cannot assign to read only property 'name' of object
        _element.isPin = false;
        return _element;
      });

      const wlSelected = find(
        _newCategory,
        (o) => o.id === item.id && o.type === 'watchlist'
      );

      if (wlSelected) {
        dispatch(setCategory(_newCategory));
        if (!token) {
          saveState('category', _newCategory);
        }
        saveHandle(wlSelected);
      }

      setEditIndex(null);
    } else {
      const _newCategory = map(category, (element) => {
        if (element.id !== item.id) return element;

        let _element = JSON.parse(JSON.stringify(element));
        // fix bug: Cannot assign to read only property 'name' of object
        _element.isPin = true;
        return _element;
      });

      const wlSelected = find(
        _newCategory,
        (o) => o.id === item.id && o.type === 'watchlist'
      );

      if (wlSelected) {
        dispatch(setCategory(_newCategory));
        if (!token) {
          saveState('category', _newCategory);
        }
        saveHandle(wlSelected);
      }

      setEditIndex(null);
    }
  };

  const hoverHandle = (index, isLeave = false) => {
    if (!isLeave) setHoverIndex(index);
    else setHoverIndex(null);
  };

  const saveHandle = (newCategory) => {
    if (!token) return;
    const uuid = uuidv4();
    const resData = {
      group: 'BACK',
      user: token.user,
      session: token.session,
      cmd: 'SET_WAT',
      rqId: uuid,
      channel: 'WTS',
      data: newCategory,
    };

    dispatch(saveCategoryRequest(resData));
  };

  const addHandle = (values, reset) => {
    const { wlName } = values;
    if (!wlName) {
      const toastMsg = {
        id: Math.random(),
        msg: t('txt-valid-category-not-null'),
        title: t('txt-notice'),
      };
      dispatch(setToast(toastMsg));
      return;
    }

    if (watchlist && watchlist.length >= 15) {
      const toastMsg = {
        id: Math.random(),
        msg: t('txt-valid-5-catalog'),
        title: t('txt-notice'),
      };
      dispatch(setToast(toastMsg));
      return;
    }

    if (watchlist && some(watchlist, (o) => o.name === wlName)) {
      const toastMsg = {
        id: Math.random(),
        msg: `${t('txt-valid-exist-category')} ${wlName}`,
        title: t('txt-notice'),
      };
      dispatch(setToast(toastMsg));
      return;
    }

    // add category
    const uuid = uuidv4();
    const catalog = {
      id: uuid,
      name: wlName,
      path: '/home/bang-gia/' + uuid,
      pinnedRow: [],
      value: [],
      type: 'watchlist',
      isPin: false,
    };

    const _newCategory = [...category, catalog];
    dispatch(setCategory(_newCategory));
    saveHandle(catalog);
    if (!token) {
      saveState('category', _newCategory);
    }
    reset();
    // setShowDrop(false);
  };

  const editHandle = (index, oldValue, isSubmit = false) => {
    if (!isSubmit) {
      setEditIndex(index);
      setName(oldValue.name);
    } else {
      if (watchlist && some(watchlist, (o) => o.name == name)) {
        const toastMsg = {
          id: Math.random(),
          msg: `${t('txt-valid-exist-category')} ${name}`,
          title: t('txt-notice'),
        };
        dispatch(setToast(toastMsg));
        return;
      }

      if (oldValue.name == name) return; // no changing in name

      // update category
      const _newCategory = map(category, (element) => {
        if (element.id !== oldValue.id) return element;
        let _element = JSON.parse(JSON.stringify(element));
        // fix bug: Cannot assign to read only property 'name' of object
        _element.name = name;
        return _element;
      });

      const wlSelected = find(
        _newCategory,
        (o) => o.id === oldValue.id && o.type === 'watchlist'
      );

      if (wlSelected) {
        dispatch(setCategory(_newCategory));
        if (!token) {
          saveState('category', _newCategory);
        }
        saveHandle(wlSelected);
      }

      setEditIndex(null);
    }
  };

  const deleteHandle = (index, item, isConfirm = false) => {
    if (!isConfirm) setDeleteIndex(index);
    else {
      // const _categoryRemove = filter(category, (x) => x.id != item.id);
      const _category = [...category];
      const _categoryRemove = remove(_category, (o) => o.id === item.id);

      dispatch(setCategory(_category));
      setDeleteIndex(null);

      if (!token) {
        saveState('category', _category);
      } else {
        const uuid = uuidv4();
        const resData = {
          group: 'BACK',
          user: token.user,
          session: token.session,
          cmd: 'DEL_WAT',
          rqId: uuid,
          channel: 'WTS',
          data: _categoryRemove[0],
        };

        dispatch(saveCategoryRequest(resData));
      }
    }
  };

  const trackHandle = (item) => {
    if (item) {
      console.log('wl item ===> ', item);
      Mixpanel.viewWatchlist({
        'Watchlist Name': item.name,
        'Watchlist Type': 'Normal Watchlist',
        'Ticker List': item.value,
        'Number of Ticker': item.value?.length || 0,
        'Watchlist View Location': 'Home',
      });
    }
  };

  return (
    <NavDropdown
      title={
        watchlist &&
        !!watchlist.length &&
        watchlist.some((x) => x.id == categoryId && !x.isPin)
          ? watchlist.find((x) => x.id == categoryId && !x.isPin)?.name
          : t('txt-my-watchlist')
      }
      className={`watchlist-menu ${
        watchlist &&
        !!watchlist.length &&
        watchlist.some((x) => x.id == categoryId && !x.isPin)
          ? 'active'
          : ''
      }`}
      id="basic-nav-dropdown"
    >
      {watchlist && !!watchlist.length > 0 && (
        <PerfectScrollBar
          style={{
            maxHeight: '250px',
          }}
        >
          {watchlist.map((item, i) => (
            <div
              className="watchlist-menu__item"
              key={`watchlist-menu__item_${i}`}
              onMouseLeave={() => hoverHandle(i, true)}
              onMouseEnter={() => hoverHandle(i, false)}
            >
              <div className="watchlist-menu__left">
                <span className="watchlist-menu__pin">
                  {!item?.isPin && (
                    <span
                      className="icon iStar"
                      onClick={() => pinHandle(item)}
                    ></span>
                  )}
                  {item?.isPin && (
                    <span
                      className="icon iStar active"
                      onClick={() => pinHandle(item, true)}
                    ></span>
                  )}
                </span>

                {editIndex != i && (
                  <Link
                    key={'cgr_' + item.groupName + '_' + i}
                    to={item.path}
                    className={
                      'nav-link  ' +
                      (item.path.endsWith('/bang-gia/' + categoryId)
                        ? 'active'
                        : '')
                    }
                    onClick={() => trackHandle(item)}
                    title={item.name}
                  >
                    {cutText(item.name)}
                  </Link>
                )}

                {editIndex == i && (
                  <input
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                  />
                )}
              </div>
              {hoverIndex == i && (
                <div className="watchlist-menu__right">
                  {editIndex != i && delIndex != i && (
                    <>
                      <span
                        className="icon iEdit"
                        onClick={() => editHandle(i, item)}
                      ></span>
                      <span
                        className="icon iBin"
                        onClick={() => deleteHandle(i, item)}
                      ></span>
                    </>
                  )}
                  {(editIndex == i || delIndex == i) && (
                    <>
                      <span
                        className="icon iCheck"
                        onClick={() => {
                          if (editIndex == i) editHandle(i, item, true);
                          else deleteHandle(i, item, true);
                        }}
                      ></span>
                      <span
                        className="icon iClose"
                        onClick={() => {
                          if (editIndex == i) setEditIndex(null);
                          else setDeleteIndex(null);
                        }}
                      ></span>
                    </>
                  )}
                </div>
              )}
            </div>
          ))}
        </PerfectScrollBar>
      )}
      <Formik
        initialValues={{
          wlName: name,
        }}
        onSubmit={(values, { resetForm }) => {
          addHandle(values, resetForm);
        }}
      >
        {({ errors, touched }) => (
          <Form className="watchlist-menu__form" autoComplete="off">
            <div
              className="watchlist-menu__item"
              key={`watchlist-menu__item_-99`}
            >
              <div className="watchlist-menu__left">
                <Field
                  className="input-wl-add"
                  type="text"
                  name="wlName"
                  placeholder={t('navbar.watchListName')}
                  autoComplete="off"
                  maxLength={20}
                />
              </div>
              <div className="watchlist-menu__right">
                <button className="btn btn-wl-add" type="submit"></button>
              </div>
            </div>

            {touched.wlName && errors.wlName && (
              <div className="error">{errors.wlName}</div>
            )}
          </Form>
        )}
      </Formik>
    </NavDropdown>
  );
});

export default WatchListActions;
