import React from 'react';
import './style.scss';
import { v4 as uuidv4 } from 'uuid';

function SwitchCustom({ title, checked, onChange, rtl = false }) {
  const id = 'pine-cb-' + uuidv4();
  return (
    <>
      <div className={`switch-custom ${rtl ? 'switch-custom--rtl' : ''}`}>
        <section className="model-2">
          <div className="checkbox">
            <input
              type="checkbox"
              id={id}
              className="custom-checkbox"
              checked={checked}
              onChange={onChange}
            />
            <label></label>
          </div>
        </section>
        {title && (
          <span style={{ paddingLeft: '8px' }} className="text text--sm">
            {title}
          </span>
        )}
      </div>
    </>
  );
}

export default SwitchCustom;
