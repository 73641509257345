import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Portal } from 'react-overlays';
import DatePicker, { registerLocale } from 'react-datepicker';
import PerfectScrollBar from 'react-perfect-scrollbar';
import { ReactReduxContext, useDispatch, useSelector } from 'react-redux';
import {
  getPfIpListDtlRequest,
  getPFIPOneRequest,
  getPfValueInfoRequest,
  getPlanModifyInfoRequest,
  getPlanUpdateContract,
  handleDepositPinefolio,
  handleEditPlanMonitor,
  setOrder,
  updatePFPlanRequest,
  setPFBondPopup,
  updatePlanReminder,
} from '../../../action';
import { v4 as uuidv4 } from 'uuid';
import {
  dragDiv,
  getMessageError,
  mapStatus,
  moveDiv,
  numberFormat,
  packDeposit,
  StringToInt,
  _formatDate2,
} from '../../../../../util';
import {
  setAuth,
  setLogin,
  setToast,
  unsetClient,
} from '../../../../client/actions';
import StopPlanModal from '../stopPlanModal';
import EditPack from '../component/editPack';
import BuyOrder from '../../buyOrder';
import RadioButton from '../../../../../components/radioButton';
import moment from 'moment';
import { handleApiErrors } from '../../../../../lib/api-errors';
import {
  Logout,
  logoutRequest,
} from '../../../../../components/modal/login/actions';
import { removeCookie } from '../../../../../lib/storages';
import OderBuyNow from './oderBuyNow';
import Contract from '../../contract';
import ChangePlanTicket from './changePlanTicket';
import ScrollTable from '../../../../../components/ScrollTable';
import { withNamespaces } from 'react-i18next';
import PaginationTable from '../../../../../components/PaginationTable';
import ReactSelect from 'react-select';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const appUrl = `${process.env.REACT_APP_API_URL}`;

const WEEK_DAY = [
  { value: 1, label: 'time.dayOfWeek.monday' },
  { value: 2, label: 'time.dayOfWeek.tuesday' },
  { value: 3, label: 'time.dayOfWeek.wednesday' },
  { value: 4, label: 'time.dayOfWeek.thursday' },
  { value: 5, label: 'time.dayOfWeek.friday' },
  { value: 6, label: 'time.dayOfWeek.saturday' },
  { value: 7, label: 'time.dayOfWeek.sunday' },
];

const ViewPlanDetail = ({ t, detail, pf, getPinefolioInvestmentPlan }) => {
  const dispatch = useDispatch();
  const { store } = useContext(ReactReduxContext);
  /** */
  const { token, auth } = useSelector((state) => state.client);
  const theme = useSelector((state) => state.client?.currentTheme);
  const showModal = useSelector((state) => state.pinefolio.showEditPlanModal);
  const lang = useSelector((state) => state.client?.setting?.lang || 'vi');
  const { pfOrder, planUpdateContract } = useSelector(
    (state) => state.pinefolio
  );
  const { pfIpOne, planData } = useSelector((state) => state.pinefolio);
  const [showModalStopPlan, setShowModalStopPlan] = useState(false);
  const [reminder, setReminder] = useState('N');
  const [dayReminder, setDayReminder] = useState('');
  const [showModalOrderBuyNow, setShowModalOrderBuyNow] = useState();
  const [tabActive, setTabActive] = useState(0);
  const [endDt, setEndDt] = useState();
  const [showContract, setShowContract] = useState(false);
  const [dayOfWeekSelected, setDayOfWeekSelected] = useState();
  const [packEditing, setPackEditing] = useState('');
  const [packsData, setPacksData] = useState([]);
  const [reload, setReload] = useState(false);
  const [enableSaveChange, setEnableSaveChange] = useState(false);
  /** ---- */

  /** */
  const listAccounts = token?.custInfo.normal;
  const account = listAccounts?.find(
    (item) => item.subAcntNo.startsWith('P') && item.subAcntStat == 1
  );

  /** ----  */
  const onCloseModal = () => {
    getPinefolioInvestmentPlan();
    const params = {
      showEditPlanModal: false,
      planData: {},
    };
    setDayOfWeekSelected();
    dispatch(handleEditPlanMonitor(params));
    setPackEditing('');
  };

  const handleGetPfIpListDtl = (planData) => {
    if (!token || (!auth && !auth.isVerified)) return;
    const uuid = uuidv4();
    const params = {
      group: 'CORE',
      user: token.user,
      session: token.session,
      cmd: 'getPFIPOne',
      rqId: uuid,
      channel: 'WTS',
      data: {
        cif: token.cif,
        acntNo: account?.acntNo,
        subAcntNo: account?.subAcntNo,
        planId: planData.planId,
      },
    };
    dispatch(getPFIPOneRequest(params));
  };

  const handleStopPlan = () => {
    setShowModalStopPlan(true);
  };

  useEffect(() => {
    if (showModal) {
      handleGetPfIpListDtl(planData);
    }
  }, [showModal]);

  useEffect(() => {
    if (pfIpOne) {
      setPacksData(pfIpOne?.packs);
      setReminder(pfIpOne.rmdTp);
      switch (pfIpOne.rmdTp) {
        case 'M':
          setDayReminder(pfIpOne.rmdVal);
          break;
        case 'W':
          setDayOfWeekSelected(WEEK_DAY[pfIpOne.rmdVal - 1]);
          break;
        default:
          setDayReminder('');
          setDayOfWeekSelected(setDayReminder[0]);
          break;
      }
    }
  }, [pfIpOne]);

  function handleUpdatePlanReminder() {
    if (!token || (!auth && !auth.isVerified)) return;
    const uuid = uuidv4();
    const params = {
      group: 'CORE',
      user: token.user,
      session: token.session,
      token: auth.token,
      type: auth.type,
      cmd: 'updatePFIPRmd',
      rqId: uuid,
      channel: 'WTS',
      data: {
        cif: token.cif,
        acntNo: account?.acntNo,
        subAcntNo: account?.subAcntNo,
        planId: planData.planId,
        rmdTp: reminder,
        rmdVal:
          reminder == 'N'
            ? '0'
            : reminder == 'M'
            ? StringToInt(dayReminder)
            : dayOfWeekSelected.value,
      },
    };
    dispatch(updatePlanReminder(params));
  }

  const handleOpenOrderBuyNow = () => {
    setShowModalOrderBuyNow(true);
  };

  function getUpdatePFIPCntr() {
    if (!token || (!auth && !auth.isVerified)) return;
    const uuid = uuidv4();
    const params = {
      group: 'CORE',
      user: token.user,
      session: token.session,
      cmd: 'getPFUpdPlanCntr',
      rqId: uuid,
      channel: 'WTS',
      data: {
        cif: token.cif,
        acntNo: account?.acntNo,
        subAcntNo: account?.subAcntNo,
        planId: planData.planId,
        packNo: packEditing.toString(),
        enDt: moment(endDt).format('YYYYMMDD'),
      },
    };
    const callback = () => {
      getPlanModifyInfo();
      setShowContract(true);
    };
    dispatch(getPlanUpdateContract(params, () => callback()));
  }

  function getPlanModifyInfo() {
    if (!token || (!auth && !auth.isVerified)) return;
    const uuid = uuidv4();
    const params = {
      group: 'CORE',
      user: token.user,
      session: token.session,
      cmd: 'getPlanModifyInfo',
      rqId: uuid,
      channel: 'WTS',
      data: {
        cif: token.cif,
        acntNo: account?.acntNo,
        subAcntNo: account?.subAcntNo,
        planId: planData.planId,
        packNo: packEditing.toString(),
        enDt: moment(endDt).format('YYYYMMDD'),
      },
    };
    dispatch(getPlanModifyInfoRequest(params));
  }

  function handleConfirmUpdatePlan() {
    if (!token || (!auth && !auth.isVerified)) return;
    const uuid = uuidv4();
    const params = {
      group: 'CORE',
      user: token.user,
      session: token.session,
      token: auth.token,
      type: auth.type,
      cmd: 'updatePFIP',
      rqId: uuid,
      channel: 'WTS',
      data: {
        cif: token.cif,
        acntNo: account?.acntNo,
        subAcntNo: account?.subAcntNo,
        planId: planData.planId,
        packNo: packEditing.toString(),
        rmdTp: reminder,
        rmdVal:
          reminder == 'N'
            ? ' 0'
            : reminder == 'M'
            ? StringToInt(dayReminder)
            : dayOfWeekSelected.value,
        enDt: moment(endDt).format('YYYYMMDD'),
      },
    };
    const callback = () => {
      handleGetPfIpListDtl(planData);
      setShowContract(false);
    };
    dispatch(updatePFPlanRequest(params, () => callback()));
  }

  function clearChange() {
    setReload(!reload);
  }

  return (
    <div>
      <Modal
        show={showModal}
        dialogClassName="wts-modal"
        backdropClassName="wts-modal-backdrop"
        contentClassName={`${
          theme ? theme.name : 'dark'
        } plan-modal  plan-modal--md wts-modal-content wts-modal-content--lg2`}
        onEscapeKeyDown={() => onCloseModal()}
        centered
        onHide={() => onCloseModal()}
        style={{ zIndex: 11111 }}
      >
        <div className="plan-modal-layout">
          <div className="plan-modal__header">
            <div className="plan-modal__header--left">
              <div className="d-flex gap-4 align-items-center">
                <span className="icon iPinefolio bg--light"></span>
                <span className="fz_16 fw-500 text--light fw-500">
                  {`${planData?.pfCode} - ${planData?.pfName} - ${t(
                    'pinefolio.plan.planId'
                  )} ${planData?.planId}`}
                </span>
              </div>
            </div>
            <div className="edit-plan__header--right">
              <div className="d-flex btn--icon">
                <span
                  className="icon iClose bg--light"
                  onClick={() => onCloseModal()}
                ></span>
              </div>
            </div>
          </div>
          <div className="plan-modal__body">
            <div className="plan-modal__body--left">
              <div className="header-tabs">
                <div
                  className={`header-tab ${
                    tabActive == 0 ? 'active text--light' : 'text--light3'
                  }`}
                  onClick={() => setTabActive(0)}
                >
                  {t('pinefolio.plan.name')}
                </div>
                <div
                  className={`header-tab ${
                    tabActive == 1 ? 'active text--light' : 'text--light3'
                  }`}
                  onClick={() => setTabActive(1)}
                >
                  {t('txt-label-history')}
                </div>
              </div>
              {tabActive == 0 && (
                <>
                  <PerfectScrollBar>
                    <div
                      className="d-flex flex-column gap-48 mr-3"
                      key={reload}
                    >
                      {packsData &&
                        packsData.length > 0 &&
                        packsData.map((pack, index) => (
                          <>
                            <EditPack
                              key={index}
                              pack={pack}
                              activePack={pfIpOne.activePack}
                              packDepoAmt={pfIpOne.packDepoAmt}
                              packRemainAmt={pfIpOne.packRmnAmt}
                              packEditing={packEditing}
                              setPackEditing={setPackEditing}
                              clearChange={clearChange}
                              setEndDt={setEndDt}
                              endDt={endDt}
                              setEnableSaveChange={setEnableSaveChange}
                            />
                          </>
                        ))}
                      <div className="pack-action">
                        <div className="d-flex flex-column gap-3 w-328"></div>
                      </div>
                    </div>
                  </PerfectScrollBar>
                  {!pfIpOne ||
                    (!pfIpOne.packs && (
                      <div className="pack-detail">
                        <span className="text-center w-100 text--light3">
                          {t('txt-no-data-found')}
                        </span>
                      </div>
                    ))}
                </>
              )}
              {tabActive == 1 && (
                <HistoryTab
                  t={t}
                  token={token}
                  auth={auth}
                  account={account}
                  pfId={planData.planId}
                />
              )}
            </div>
            <div className="break-line--verticle"></div>
            <div className="plan-modal__body--right">
              <div className="reminder-block">
                <div className="d-flex gap-4 flex-column">
                  <span className="fz_13 fw-500 text--light">
                    {t('pinefolio.plan.createPlan.getReminder.name')}
                  </span>
                  <span className="fz_13 text--light3 d-flex justify-content-between w-100">
                    <div
                      className="d-flex gap-8 align-items-center"
                      onClick={() => {
                        setReminder('N');
                      }}
                    >
                      <div className="text-center w-32p">
                        <RadioButton isReadonly isActive={reminder == 'N'} />
                      </div>
                      <span>
                        {t('pinefolio.plan.createPlan.getReminder.none')}
                      </span>
                    </div>
                    <div
                      className="d-flex gap-8 align-items-center"
                      onClick={() => {
                        setReminder('W');
                      }}
                    >
                      <div className="text-center w-32p">
                        <RadioButton isReadonly isActive={reminder == 'W'} />
                      </div>
                      <span>
                        {t('pinefolio.plan.createPlan.getReminder.weekly')}
                      </span>
                    </div>
                    <div
                      className="d-flex gap-8 align-items-center"
                      onClick={() => {
                        setReminder('M');
                      }}
                    >
                      <div className="text-center w-32p">
                        <RadioButton isReadonly isActive={reminder == 'M'} />
                      </div>
                      <span>
                        {t('pinefolio.plan.createPlan.getReminder.monthly')}
                      </span>
                    </div>
                  </span>
                </div>
                <div className="d-flex gap-4 flex-column">
                  {reminder != 'N' &&
                    (reminder == 'M' ? (
                      <>
                        <span className="fz_13 fw-500 text--light">
                          {t('pinefolio.plan.createPlan.monthlyDay.name')}
                        </span>
                        <div className="input-text-search">
                          <input
                            type="text"
                            maxlength="2"
                            placeholder={t(
                              'pinefolio.plan.createPlan.monthlyDay.placeHolder'
                            )}
                            onChange={(event) =>
                              setDayReminder(event.target.value)
                            }
                            value={dayReminder}
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        <span className="fz_13 fw-500 text--light">
                          {t('pinefolio.plan.createPlan.weeklyDay.name')}
                        </span>
                        <div className="input-text-search">
                          <ReactSelect
                            defaultValue={
                              pfIpOne?.rmdTp == 'M' || pfIpOne?.rmdVal == 0
                                ? WEEK_DAY[0]
                                : WEEK_DAY[pfIpOne?.rmdVal - 1]
                            }
                            className="filter-control-select"
                            classNamePrefix="filter-control-select"
                            options={WEEK_DAY}
                            getOptionLabel={(option) => t(option.label)}
                            getOptionValue={(option) => option.value}
                            value={dayOfWeekSelected}
                            onChange={(value) => setDayOfWeekSelected(value)}
                            isSearchable={false}
                            components={{
                              IndicatorSeparator: () => null,
                            }}
                          />
                        </div>
                      </>
                    ))}
                </div>
                <div>
                  <span className="pinefolio-actions flex-column">
                    {pfIpOne?.status == 1 || pfIpOne?.status == 2 ? (
                      <div
                        className="btn btn--cancel pinefolio-action"
                        onClick={() => handleUpdatePlanReminder()}
                      >
                        {t('pinefolio.plan.createPlan.saveChangeReminder')}
                      </div>
                    ) : (
                      <div className="btn btn--cancel pinefolio-action disabled">
                        {t('pinefolio.plan.createPlan.saveChangeReminder')}
                      </div>
                    )}
                    {enableSaveChange ? (
                      <div
                        className="btn btn--demo2 pinefolio-action"
                        onClick={() => getUpdatePFIPCntr()}
                      >
                        {t('pinefolio.plan.createPlan.saveChangePlan')}
                      </div>
                    ) : (
                      <div className="btn btn--demo2 pinefolio-action disabled">
                        {t('pinefolio.plan.createPlan.saveChangePlan')}
                      </div>
                    )}
                  </span>
                </div>
              </div>
              <div className="pinefolio-actions flex-column gap-12">
                <span className="d-flex gap-12 w-100 justify-content-between">
                  <span className="fz_13 text--light fw-500">
                    {t('pinefolio.plan.createPlan.bondAccumulatedValue')}
                  </span>
                  <span className="fz_13 i">
                    {numberFormat(pfIpOne?.depoBndAmt, 0, '0')}
                  </span>
                </span>
                <div className="d-flex  gap-12">
                  {pfIpOne?.status == 1 || pfIpOne?.status == 2 ? (
                    <div
                      className="btn btn--buy2 pinefolio-action w-100"
                      onClick={() => handleOpenOrderBuyNow()}
                    >
                      {t('pinefolio.plan.orderBuyNow')}
                    </div>
                  ) : (
                    <div className="btn btn--authenticated2 pinefolio-action w-100 disabled">
                      {t('pinefolio.plan.orderBuyNow')}
                    </div>
                  )}
                  {pfIpOne?.status == 1 || pfIpOne?.status == 2 ? (
                    <div
                      className="btn btn--sell2 pinefolio-action w-100"
                      onClick={handleStopPlan}
                    >
                      {t('pinefolio.plan.createPlan.cancelPlan')}
                    </div>
                  ) : (
                    <div className="btn btn--sell2 pinefolio-action w-100 disabled">
                      {t('pinefolio.plan.createPlan.cancelPlan')}
                    </div>
                  )}
                </div>
                <div className="d-flex  gap-12">
                  {pfIpOne?.status == 1 || pfIpOne?.status == 2 ? (
                    <div
                      className="btn btn--primary pinefolio-action w-100"
                      onClick={() => dispatch(setPFBondPopup(true))}
                    >
                      {t('pinefolio.plan.createPlan.buyBond')}
                    </div>
                  ) : (
                    <div className="btn btn--authen2 pinefolio-action w-100 disabled">
                      {t('pinefolio.plan.createPlan.buyBond')}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {showModalOrderBuyNow && (
        <OderBuyNow
          onCloseModalViewPlan={onCloseModal}
          t={t}
          showModalOrderBuyNow={showModalOrderBuyNow}
          setShowModalOrderBuyNow={setShowModalOrderBuyNow}
          pfData={pfIpOne}
          handleGetPfIpListDtl={handleGetPfIpListDtl}
        />
      )}
      {showModalStopPlan && (
        <StopPlanModal
          t={t}
          _account={account}
          showModal={showModalStopPlan}
          setShowModal={setShowModalStopPlan}
          pfPlan={pfIpOne}
          onCloseEditModal={onCloseModal}
        />
      )}
      {showContract && (
        <ChangePlanTicket
          contracts={planUpdateContract}
          handleConfirmUpdatePlan={handleConfirmUpdatePlan}
          setShowContract={setShowContract}
        />
      )}
    </div>
  );
};

const HistoryTab = ({ t, token, auth, account, pfId }) => {
  const [historyBondList, setHistoryBondList] = useState();
  const { store } = useContext(ReactReduxContext);
  const dispatch = useDispatch();
  function handleGetBondHistory() {
    if (!token) return;
    const uuid = uuidv4();
    const params = {
      group: 'CORE',
      user: token.user,
      session: token.session,
      cmd: 'getBondDealList',
      rqId: uuid,
      channel: 'WTS',
      data: {
        cif: token.cif,
        acntNo: account?.acntNo,
        subAcntNo: account?.subAcntNo,
        fromDate: '00000000',
        toDate: '99999999',
        pfFilter: 'D',
        planId: pfId + '',
      },
    };

    const url = `${appUrl}/BondServlet.pt`;
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
      body: JSON.stringify(params),
    })
      .then(handleApiErrors)
      .then((response) => response.json())
      .then((res) => {
        if (res.http == 200) {
          setHistoryBondList(res.data);
        } else {
          if (res.http === 401) {
            if (token) dispatch(logoutRequest(token));
            dispatch(unsetClient());
            removeCookie('token');
            removeCookie('authen');
            dispatch(Logout());
            const toastMsg = {
              id: Math.random(),
              msg: t('txt-valid-loss-session'),
              title: t('txt-notice'),
            };
            dispatch(setToast(toastMsg));
          } else {
            const msgErr = getMessageError(res, store.getState);
            const toastMsg = {
              id: Math.random(),
              msg: msgErr,
              title: t('txt-notice'),
            };
            dispatch(setToast(toastMsg));
          }
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  useEffect(() => {
    handleGetBondHistory();
  }, []);

  const columns = [
    {
      key: 'tranDate',
      className: 'text-center position-relative',
      text: t('bond.tableLabel.tradeDate'),
      cell: (record) => {
        return _formatDate2(record.tranDate);
      },
      sortable: true,
    },
    {
      key: 'prodCode',
      text: t('bond.tableLabel.productCode'),
      className: 'text-center position-relative text--light',
      sortable: true,
    },
    {
      key: 'dealId',
      text: t('bond.tableLabel.dealID'),
      className: 'text-center position-relative text--light',
      cell: (record) => <span>{record?.dealId}</span>,

      sortable: true,
    },
    {
      key: 'dlQty',
      className: 'text-right position-relative text--light',
      text: t('txt-label-purchased-quantity'),
      sortable: true,
    },
    {
      key: 'sellQty',
      text: t('bond.tableLabel.soldQuantity'),
      className: 'text-right position-relative text--light',
      cell: (record) => {
        return numberFormat(record.sellQty, 0, '0');
      },
      sortable: true,
    },
    {
      key: 'currPrc',
      text: t('bond.tableLabel.currentSellQuantity'),
      className: 'text-right position-relatives',
      cell: (record) => {
        return numberFormat(record.currPrc, 0, '0');
      },
      sortable: true,
    },
    {
      key: 'rmnAmt',
      text: (
        <span className="d-flex justify-content-end w-100">
          <span>{t('bond.tableLabel.remainValue.name')}</span>
          <OverlayTrigger
            trigger={['hover', 'focus']}
            placement="top"
            className="portfolio-overlay"
            overlay={
              <Tooltip style={{ 'z-index': '99999' }}>
                {t('bond.tableLabel.remainValue.desc')}
              </Tooltip>
            }
          >
            <span className="icon iInfor iTooltip ml-1 mr-1"></span>
          </OverlayTrigger>
        </span>
      ),
      className: 'text-right position-relative',
      cell: (record) => {
        return numberFormat(record.rmnAmt, 0, '0');
      },
      sortable: true,
    },
  ];

  return (
    <>
      <PaginationTable
        t
        pageSize={15}
        columns={columns}
        source={historyBondList?.list}
        hasPaging={historyBondList?.list.length > 15}
      />
    </>
  );
};

export default ViewPlanDetail;
