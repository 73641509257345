import React, { useEffect, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import ReactSelect from 'react-select';
import PerfectScrollBar from 'react-perfect-scrollbar';
import { useDispatch, useSelector } from 'react-redux';
import { eventDetailRequest, getListEventRequest } from '../action';
import {
  getSymbolInfor,
  getTickerType,
  _diff2Date,
  _formatDate,
} from '../../../util';
import { format } from 'date-fns';
import DatePicker from '../../../components/datePicker2';
import { setSymbolActive, setToast } from '../../client/actions';
import 'react-virtualized/styles.css'; // only needs to be imported once
import { Modal } from 'react-bootstrap';
import moment from 'moment';
import { Mixpanel } from '../../../lib/mixpanel';

const Events = withNamespaces('translations')(({ t }) => {
  const dispatch = useDispatch();
  const { listEvent, eventDetail } = useSelector((state) => state?.news);
  const lang = useSelector((state) => state?.client?.setting?.lang || 'vi');
  const theme = useSelector((state) => state?.client?.currentTheme);
  const { cwList, allStock } = useSelector((state) => state.priceBoard);
  const [listEventShow, setListEventShow] = useState();
  const [listFitlerSelect, setListFilterSelect] = useState([
    {
      key: 0,
      value: t('txt-all'),
    },
  ]);
  const [symbol, setSymbol] = useState('');
  const [type, setType] = useState({ key: 0, value: '' });
  const currentDate = new Date();
  const [fromDate, setFromDate] = useState(
    new Date(currentDate.setDate(currentDate.getDate() - 15))
  );
  const [toDate, setToDate] = useState(new Date());
  const [showDetailModal, setShowDetailModal] = useState(false);

  const handleFilter = () => {
    let _listEventShow = [...listEvent];
    if (_listEventShow && _listEventShow.length && symbol) {
      _listEventShow = _listEventShow.filter(
        (event) =>
          event.symbol.includes(symbol) ||
          event.symbol.includes(symbol.toUpperCase())
      );
    }
    if (_listEventShow && _listEventShow.length && type.key != 0) {
      _listEventShow = _listEventShow.filter(
        (event) => event.name == type.value
      );
    }
    setListEventShow(_listEventShow);
  };

  const handleGetListType = (list) => {
    if (list && list.length) {
      const listFilter = list.map((item) => item.name);
      function onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
      }

      var unique = listFilter.filter(onlyUnique);
      const _listType = unique.map((item, index) => ({
        key: index + 1,
        value: item,
      }));
      setListFilterSelect([{ key: 0, value: t('txt-all') }, ..._listType]);
    }
  };

  const handleToast = (msg) => {
    const toastMsg = {
      id: Math.random(),
      msg: msg,
      title: t('txt-notice'),
    };
    dispatch(setToast(toastMsg));
  };

  useEffect(() => {
    handleFilter();
  }, [symbol, type]);

  useEffect(() => {
    const _diff = _diff2Date(toDate, fromDate);
    if (_diff < 0) {
      handleToast(t('txt-valid-time'));
      return;
    }
    const params = {
      fromDate: fromDate,
      toDate: toDate,
      lang: lang == 'vi' ? 1 : 2,
    };
    setType(listFitlerSelect[0]);
    dispatch(getListEventRequest(params));
  }, [fromDate, toDate, lang]);

  useEffect(() => {
    dispatch(getListEventRequest());
  }, []);

  useEffect(() => {
    if (listEvent && listEvent.length) {
      handleFilter();
      handleGetListType(listEvent);
    }
  }, [listEvent]);

  return (
    <div className="card-panel tabs h-100 w-100">
      <div className="card-panel-header">
        <div className="card-panel-header__bound">
          <div className="d-flex card-panel-header__left">
            <span className="card-panel-header__title  active">
              {t('txt-label-events')}
            </span>
          </div>
          <div className="d-flex card-panel-header__right">
            <DatePicker
              startDate={fromDate}
              endDate={toDate}
              callback={(from, to) => {
                setFromDate(_formatDate(from));
                setToDate(_formatDate(to));
              }}
            />
          </div>
        </div>
      </div>
      <div className="card-panel-body">
        <div
          className="filter-control text--light3 pb-3 d-flex justify-content-between"
          key={lang}
        >
          <div className="input-text-search d-flex align-items-center mr-3">
            <input
              placeholder="Symbol"
              onChange={(e) => setSymbol(e.target.value)}
            />
            <span class="icon iSearch"></span>
          </div>
          <div className="w-100">
            <ReactSelect
              defaultValue={listFitlerSelect[0]}
              options={listFitlerSelect}
              className="filter-control-select"
              classNamePrefix="filter-control-select"
              getOptionLabel={(option) => option.value}
              getOptionValue={(option) => option.key}
              onChange={(value) => setType(value)}
              isSearchable={false}
              components={{
                IndicatorSeparator: () => null,
              }}
            />
          </div>
        </div>
        <PerfectScrollBar
          className="w-100"
          style={{ height: 'calc(100% - 32px)' }}
        >
          <div className="event-list">
            {listEventShow &&
              listEventShow.length > 0 &&
              listEventShow.map((event, i) => (
                <div className="event-item" key={i}>
                  <span className="event-time text--gray">
                    <span className="icon iCalendar"></span>
                    <span className="text--gray">
                      {format(
                        new Date(
                          event.gdkhqDate
                            ? event.gdkhqDate.slice(0, 10)
                            : event.ndkccDate
                            ? event.ndkccDate.slice(0, 10)
                            : event.time.slice(0, 10)
                        ),
                        'dd/MM/yyyy'
                      )}
                    </span>
                  </span>
                  <div className="event__content">
                    <div className="content__title">
                      <span
                        className="symbol fw-500 fz_13 text--light cursor-pointer"
                        onClick={() => {
                          Mixpanel.viewTicketInfo({
                            Ticker: event.symbol,
                            'Ticker Type': getTickerType(event.symbol, cwList),
                            'Ticker Location': 'News',
                            'Ticker Location Detail': 'Ticker Event',
                          });
                          dispatch(setSymbolActive(event.symbol));
                        }}
                      >
                        {event.symbol}
                      </span>
                      <span
                        className="text--light fw-500 cursor-pointer"
                        onClick={() => {
                          Mixpanel.news.readNews(
                            'Ticker Event',
                            'Event',
                            'News',
                            event?.title,
                            '',
                            event?.time
                          );

                          dispatch(eventDetailRequest(event.id));
                          setShowDetailModal(true);
                        }}
                      >
                        {event.name}
                      </span>
                    </div>
                    <span
                      className="content__desc text--light3 cursor-pointer"
                      onClick={() => {
                        Mixpanel.news.readNews(
                          'Ticker Event',
                          'Event',
                          'News',
                          event?.title,
                          '',
                          event?.time
                        );

                        dispatch(eventDetailRequest(event.id));
                        setShowDetailModal(true);
                      }}
                    >
                      {event.title}
                    </span>
                  </div>
                </div>
              ))}
          </div>
        </PerfectScrollBar>
      </div>
      <Modal
        show={showDetailModal}
        dialogClassName="noti-detail-modal"
        backdropClassName="noti-detail-backdrop"
        contentClassName={`${theme?.name || 'dark'} noti-detail-content`}
        onEscapeKeyDown={() => setShowDetailModal(false)}
        centered
        onHide={() => setShowDetailModal(false)}
        style={{ zIndex: 11111 }}
      >
        {
          <div className="noti-detail">
            <div className="noti-detail__header">
              <div className="noti-detail__title">
                {
                  <span className={`cbadge cbadge-infor`}>
                    {eventDetail?.code}
                  </span>
                }
                <span
                  className="icon iClose"
                  onClick={() => setShowDetailModal(false)}
                ></span>
              </div>
              <p className="text-title">{}</p>
              <p className="text-desc">
                <div
                  dangerouslySetInnerHTML={{
                    __html: eventDetail?.content || eventDetail?.title,
                  }}
                />
              </p>
              <p className="text-time">
                {moment(eventDetail.time).format('DD-MM-YYYY HH:mm')}
              </p>
            </div>
            <div className="noti-detail__body"></div>
          </div>
        }
      </Modal>
    </div>
  );
});

export default Events;
