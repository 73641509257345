import {
  GET_LIST_EVENT,
  GET_LIST_EVENT_ERROR,
  GET_LIST_EVENT_SUCCESS,
  GET_LIST_NEWS,
  GET_LIST_NEWS_SUCCESS,
  GET_LIST_NEWS_ERROR,
  GET_NEWS_CATEGORIES,
  GET_NEWS_CATEGORIES_SUCCESS,
  GET_NEWS_CATEGORIES_ERROR,
  NEWS_LIST_REQUESTING,
  NEWS_LIST_REQUEST_SUCCESS,
  NEWS_LIST_REQUEST_FAIL,
  NEWS_DETAIL_REQUESTING,
  NEWS_DETAIL_REQUEST_FAIL,
  NEWS_DETAIL_REQUEST_SUCCESS,
  TOPNEWS_LIST_REQUESTING,
  TOPNEWS_LIST_REQUEST_SUCCESS,
  TOPNEWS_LIST_REQUEST_FAIL,
  TOPVIDEO_LIST_REQUESTING,
  TOPVIDEO_LIST_REQUEST_SUCCESS,
  TOPVIDEO_LIST_REQUEST_FAIL,
  EVENTS_LIST_REQUESTING,
  EVENTS_LIST_REQUEST_SUCCESS,
  EVENTS_LIST_REQUEST_FAIL,
  VIDEO_DETAIL_REQUEST,
  VIDEO_DETAIL_REQUEST_SUCCESS,
  VIDEO_DETAIL_REQUEST_FAIL,
  VIDEO_RELATED_LIST_REQUEST,
  VIDEO_RELATED_LIST_SUCCESS,
  VIDEO_RELATED_LIST_FAIL,
  NEWS_CATEGORY_REQUESTING,
  NEWS_CATEGORY_REQUEST_SUCCESS,
  NEWS_CATEGORY_REQUEST_FAIL,
  TOOGLE_VIDEO_DETAIL_MODAL,
  TOOGLE_EVENT_DETAIL_MODAL,
  TOOGLE_NEW_DETAIL_MODAL,
  CURRENT_NEWS,
  NEWS_RELATED_LIST_REQUEST,
  NEWS_RELATED_LIST_SUCCESS,
  NEWS_RELATED_LIST_FAIL,
  NEWS_SYMBOL_REQUEST,
  NEWS_SYMBOL_REQUEST_SUCCESS,
  NEWS_SYMBOL_REQUEST_FAIL,
  EVENTS_DETAIL_REQUESTING,
  EVENTS_DETAIL_REQUEST_SUCESS,
  EVENTS_DETAIL_REQUEST_FAIL,
  GLOBAL_INDEX_REQUEST,
  GLOBAL_INDEX_REQUEST_SUCCESS,
  GLOBAL_INDEX_REQUEST_FAIL,
  GET_BRIEFS_REQUESTING,
  GET_BRIEFS_REQUEST_SUCCESS,
  GET_BRIEFS_REQUEST_FAIL,
  GET_NEWS_SORT,
  GET_NEWS_SORT_SUCCESS,
  GET_NEWS_SORT_ERROR,
} from './constants';

const initialState = {
  listEvent: [],
  listEventSuccess: false,
  listEventErrors: [],
  listNews: [],
  listNewsSuccess: false,
  listNewsErrors: [],
  listNewsCategory: [],
  listNewsCategorySuccess: false,
  listNewsCategoryErrors: [],
  newsList: [],
  newsListRequesting: false,
  newsListRequestSuccess: false,
  newsListRequestErrors: [],

  newsCategories: [],
  newsCategoriesRequesting: false,
  newsCategoriesRequestSuccess: false,
  newsCategoriesRequestErrors: [],

  newsSort: [],
  newsSortRequesting: false,
  newsSortRequestSuccess: false,
  newsSortRequestErrors: [],

  newModalShow: false,
  newsDetail: {},
  newsDetailRequesting: false,
  newsDetailRequestSuccess: false,
  newsDetailRequestErrors: [],

  topNews: [],
  topNewsRequesting: false,
  topNewsRequestSuccess: false,
  topNewsRequestErrors: [],

  newModalShow: false,
  eventModalShow: false,
  videoModalShow: false,

  topVideos: [],
  topVideosRequest: false,
  topVideosRequestSuccess: false,
  topVideosRequestErrors: [],

  events: [],
  eventDetail: {},
  eventsRequest: false,
  eventsRequestSuccess: false,
  eventsRequestErrors: [],

  videos: {},
  videosRequest: false,
  videosRequestSuccess: false,
  videosRequestErrors: [],

  currentNews: null,

  videoDetail: {},
  videoDetailRequest: false,
  videoDetailRequestSuccess: false,
  videoDetailRequestErrors: [],

  relatedVideos: [],
  relatedVideosRequest: false,
  relatedVideosRequestSuccess: false,
  relatedVideosRequestErrors: [],

  relatedNews: [],
  relatedNewsRequest: false,
  relatedNewsRequestSuccess: false,
  relatedNewsRequestFail: [],

  newsSymbol: [],
  newsSymbolRequest: false,
  newsSymbolRequestSuccess: false,
  newsSymbolRequestFail: [],

  globalIndex: [],
  globalIndexRequest: false,
  globalIndexRequestSuccess: false,
  globalIndexRequestFail: [],

  briefs: [],
  briefsRequest: false,
  briefsRequestSuccess: false,
  briefsRequestErrors: [],
};

const reducer = function newsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_LIST_EVENT: {
      return {
        ...state,
        listEvent: [],
        listEventSuccess: true,
        listEventErrors: [],
      };
    }

    case GET_LIST_EVENT_SUCCESS: {
      return {
        ...state,
        listEvent: action.listEvent,
        listEventSuccess: false,
        listEventErrors: [],
      };
    }

    case GET_LIST_EVENT_ERROR: {
      return {
        ...state,
        listEvent: [],
        listEventSuccess: false,
        listEventErrors: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };
    }
    case GET_LIST_NEWS: {
      return {
        ...state,
        listNews: [],
        listNewsSuccess: true,
        listNewsErrors: [],
      };
    }

    case GET_LIST_NEWS_SUCCESS: {
      return {
        ...state,
        listNews: action.listNews,
        listNewsSuccess: false,
        listNewsErrors: [],
      };
    }

    case GET_LIST_NEWS_ERROR: {
      return {
        ...state,
        listNews: [],
        listNewsSuccess: false,
        listNewsErrors: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };
    }

    case GET_NEWS_CATEGORIES: {
      return {
        ...state,
        listNewsCategory: [],
        listNewsCategorySuccess: true,
        listNewsCategoryErrors: [],
      };
    }

    case GET_NEWS_CATEGORIES_SUCCESS: {
      return {
        ...state,
        listNewsCategory: action.newsCategories,
        listNewsCategorySuccess: false,
        listNewsCategoryErrors: [],
      };
    }

    case GET_NEWS_CATEGORIES_ERROR: {
      return {
        ...state,
        listNewsCategory: [],
        listNewsCategorySuccess: false,
        listNewsErrors: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };
    }

    case GET_NEWS_SORT: {
      return {
        ...state,
        newsSort: [],
        newsSortRequestSuccess: true,
        newsSortRequestErrors: [],
      };
    }

    case GET_NEWS_SORT_SUCCESS: {
      return {
        ...state,
        newsSort: action.newsSort,
        newsSortRequestSuccess: false,
        newsSortRequestErrors: [],
      };
    }

    case GET_NEWS_SORT_ERROR: {
      return {
        ...state,
        newsSort: [],
        newsSortRequestSuccess: false,
        newsSortRequestErrors: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };
    }
    case NEWS_LIST_REQUESTING:
      return {
        ...state,
        newsList: [],
        newsListRequesting: true,
        newsListRequestSuccess: false,
        newsListRequestErrors: [],
      };

    case NEWS_LIST_REQUEST_SUCCESS:
      return {
        ...state,
        newsList: action.data,
        newsListRequesting: false,
        newsListRequestSuccess: true,
        newsListRequestErrors: [],
      };

    case NEWS_LIST_REQUEST_FAIL:
      return {
        ...state,
        newsList: [],
        newsListRequesting: false,
        newsListRequestSuccess: false,
        newsListRequestErrors: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };

    case NEWS_CATEGORY_REQUESTING:
      return {
        ...state,
        newsCategories: [],
        newsCategoriesRequesting: true,
        newsCategoriesRequestSuccess: false,
        newsCategoriesRequestErrors: [],
      };

    case NEWS_CATEGORY_REQUEST_SUCCESS:
      return {
        ...state,
        newsCategories: action.data,
        newsCategoriesRequesting: false,
        newsCategoriesRequestSuccess: true,
        newsCategoriesRequestErrors: [],
      };

    case NEWS_CATEGORY_REQUEST_FAIL:
      return {
        ...state,
        newsCategories: [],
        newsCategoriesRequesting: false,
        newsCategoriesRequestSuccess: false,
        newsCategoriesRequestErrors: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };

    case TOPNEWS_LIST_REQUESTING:
      return {
        ...state,
        topNews: [],
        topNewsRequesting: true,
        topNewsRequestSuccess: false,
        topNewsRequestErrors: [],
      };

    case TOPNEWS_LIST_REQUEST_SUCCESS:
      return {
        ...state,
        topNews: action.data,
        topNewsRequesting: false,
        topNewsRequestSuccess: true,
        topNewsRequestErrors: [],
      };

    case TOPNEWS_LIST_REQUEST_FAIL:
      return {
        ...state,
        topNews: [],
        topNewsRequesting: false,
        topNewsRequestSuccess: false,
        topNewsRequestErrors: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };

    case EVENTS_LIST_REQUESTING:
      return {
        ...state,
        events: [],
        eventsRequest: true,
        eventsRequestSuccess: false,
        eventsRequestErrors: [],
      };

    case EVENTS_LIST_REQUEST_SUCCESS:
      return {
        ...state,
        events: action.data,
        eventsRequest: false,
        eventsRequestSuccess: true,
        eventsRequestErrors: [],
      };

    case EVENTS_LIST_REQUEST_FAIL:
      return {
        ...state,
        events: [],
        eventsRequestSuccess: false,
        eventsRequestErrors: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };

    case EVENTS_DETAIL_REQUESTING:
      return {
        ...state,
        eventDetail: {},
      };

    case EVENTS_DETAIL_REQUEST_SUCESS:
      return {
        ...state,
        eventDetail: action.data,
      };

    case EVENTS_DETAIL_REQUEST_FAIL:
      return {
        ...state,
        eventDetail: {},
      };

    case NEWS_DETAIL_REQUESTING:
      return {
        ...state,
        newsDetail: {},
        newsListRequesting: true,
        newsListRequestSuccess: false,
        newsListRequestErrors: [],
      };

    case NEWS_DETAIL_REQUEST_SUCCESS:
      return {
        ...state,
        newsDetail: action.data,
        newsListRequesting: false,
        newsListRequestSuccess: true,
        newsListRequestErrors: [],
      };

    case NEWS_DETAIL_REQUEST_FAIL:
      return {
        ...state,
        newsDetail: {},
        newsDetailRequesting: false,
        newsDetailRequestSuccess: false,
        newsDetailRequestErrors: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };

    case TOPVIDEO_LIST_REQUESTING:
      return {
        ...state,
        topVideos: [],
        topVideosRequest: true,
        topVideosRequestSuccess: false,
        topVideosRequestErrors: [],
      };

    case TOPVIDEO_LIST_REQUEST_SUCCESS:
      return {
        ...state,
        topVideos: action.data,
        topVideosRequest: false,
        topVideosRequestSuccess: true,
        topVideosRequestErrors: [],
      };

    case TOPVIDEO_LIST_REQUEST_FAIL:
      return {
        ...state,
        topVideos: [],
        topVideosRequest: false,
        topVideosRequestSuccess: false,
        topVideosRequestErrors: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };

    case CURRENT_NEWS:
      return {
        ...state,
        currentNews: action.id,
      };

    case TOOGLE_VIDEO_DETAIL_MODAL:
      return {
        ...state,
        videoModalShow: action.isOpen,
      };

    case TOOGLE_NEW_DETAIL_MODAL:
      return {
        ...state,
        newModalShow: action.isOpen,
      };

    case TOOGLE_EVENT_DETAIL_MODAL:
      return {
        ...state,
        eventModalShow: action.isOpen,
      };

    case VIDEO_DETAIL_REQUEST:
      return {
        ...state,
        videoDetail: {},
        videoDetailRequest: true,
        videoDetailRequestSuccess: false,
        videoDetailRequestErrors: [],
      };

    case VIDEO_DETAIL_REQUEST_SUCCESS:
      return {
        ...state,
        videoDetail: action.data,
        videoDetailRequest: false,
        videoDetailRequestSuccess: true,
        videoDetailRequestErrors: [],
      };

    case VIDEO_DETAIL_REQUEST_FAIL:
      return {
        ...state,
        videoDetail: {},
        videoDetailRequest: false,
        videoDetailRequestSuccess: false,
        videoDetailRequestErrors: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };

    case VIDEO_RELATED_LIST_REQUEST:
      return {
        ...state,
        relatedVideos: [],
        relatedVideosRequest: true,
        relatedVideosSuccess: false,
        relatedVideosErrors: [],
      };

    case VIDEO_RELATED_LIST_SUCCESS:
      return {
        ...state,
        relatedVideos: action.data,
        relatedVideosRequest: false,
        relatedVideosSuccess: true,
        relatedVideosErrors: [],
      };

    case VIDEO_RELATED_LIST_FAIL:
      return {
        ...state,
        relatedVideos: [],
        relatedVideosRequest: true,
        relatedVideosSuccess: false,
        relatedVideosErrors: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };

    case NEWS_RELATED_LIST_REQUEST:
      return {
        ...state,
        relatedNews: [],
        relatedNewsRequest: true,
        relatedNewsRequestSuccess: false,
        relatedNewsRequestFail: [],
      };

    case NEWS_RELATED_LIST_SUCCESS:
      return {
        ...state,
        relatedNews: action.data,
        relatedNewsRequest: false,
        relatedNewsRequestSuccess: true,
        relatedNewsRequestFail: [],
      };

    case NEWS_RELATED_LIST_FAIL:
      return {
        ...state,
        relatedNews: [],
        relatedNewsRequest: false,
        relatedNewsRequestSuccess: false,
        relatedNewsRequestFail: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };

    case NEWS_SYMBOL_REQUEST:
      return {
        ...state,
        newsSymbols: [],
        newsSymbolsRequest: true,
        newsSymbolsRequestSuccess: false,
        newsSymbolsRequestErrors: [],
      };

    case NEWS_SYMBOL_REQUEST_SUCCESS:
      return {
        ...state,
        newsSymbols: action.data,
        newsSymbolsRequest: false,
        newsSymbolsRequestSuccess: true,
        newsSymbolsRequestErrors: [],
      };

    case NEWS_SYMBOL_REQUEST_FAIL:
      return {
        ...state,
        newsSymbols: [],
        newsSymbolsRequest: false,
        newsSymbolsRequestSuccess: false,
        newsSymbolsRequestErrors: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };

    case GLOBAL_INDEX_REQUEST:
      return {
        ...state,
        globalIndex: [],
        globalIndexRequest: true,
        globalIndexRequestSuccess: false,
        globalIndexRequestErrors: [],
      };

    case GLOBAL_INDEX_REQUEST_SUCCESS:
      return {
        ...state,
        globalIndex: action.data,
        globalIndexRequest: false,
        globalIndexRequestSuccess: true,
        globalIndexRequestErrors: [],
      };

    case GLOBAL_INDEX_REQUEST_FAIL:
      return {
        ...state,
        globalIndex: [],
        globalIndexRequest: false,
        globalIndexRequestSuccess: false,
        globalIndexRequestErrors: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };
    case GET_BRIEFS_REQUESTING:
      return {
        ...state,
        briefs: [],
        briefsRequest: true,
        briefsRequestSuccess: false,
        briefsRequestErrors: [],
      };

    case GET_BRIEFS_REQUEST_SUCCESS:
      return {
        ...state,
        briefs: action.data,
        briefsRequest: false,
        briefsRequestSuccess: true,
        briefsRequestErrors: [],
      };

    case GET_BRIEFS_REQUEST_FAIL:
      return {
        ...state,
        briefs: [],
        briefsRequest: false,
        briefsRequestSuccess: false,
        briefsRequestErrors: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };
    default:
      return state;
  }
};

export default reducer;
