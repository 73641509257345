import React, { useContext, useEffect, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import { Portal } from 'react-overlays';
import {
  durationFrom2Dates,
  formatToNewDate,
  getMessageError,
  mapPfOrderStat,
  numberFormat,
  _formatDate2,
} from '../../../../../util';
import PerfectScrollBar from 'react-perfect-scrollbar';
import DatePicker, { registerLocale } from 'react-datepicker';
import moment from 'moment';
import { ReactReduxContext, useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { handleApiErrors } from '../../../../../lib/api-errors';
import {
  Logout,
  logoutRequest,
} from '../../../../../components/modal/login/actions';
import { setToast, unsetClient } from '../../../../client/actions';
import { removeCookie } from '../../../../../lib/storages';
import { getPFIPOneRequest } from '../../../action';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import ConfirmEditBuyingDate from './confirmEditBuyingDate';
const appUrl = `${process.env.REACT_APP_API_URL}`;

const CalendarContainer = ({ children }) => {
  const el = document.getElementById('calendar-portal');
  return <Portal container={el}>{children}</Portal>;
};

const colgroup = (
  <colgroup>
    <col width="32px"></col>
    <col width="92px"></col>
    <col width="92px"></col>
    <col width="92px"></col>
  </colgroup>
);

const EditPack = withNamespaces('translations')(
  ({
    t,
    pack,
    setEndDt,
    packEditing = '',
    setPackEditing,
    clearChange,
    endDt,
    setEnableSaveChange,
  }) => {
    const [buyingOrderDate, setBuyingOrderDate] = useState();
    const [showModal, setShowModal] = useState(false);

    const handleChangeBuyingOrderDate = (date) => {
      setBuyingOrderDate(date);
      if (pack?.packNo != packEditing && packEditing != '') {
        setShowModal(true);
      } else {
        setEndDt(date);
        setPackEditing(pack?.packNo);
        if (moment(date).format('YYYYMMDD') != pack.enDt) {
          setEnableSaveChange(true);
        } else {
          setEnableSaveChange(false);
        }
      }
    };

    function onConfirmSwitchPack() {
      setEndDt(buyingOrderDate);
      setPackEditing(pack?.packNo);
      clearChange();
    }

    useEffect(() => {
      if (packEditing === pack?.packNo) {
        setBuyingOrderDate(endDt);
      } else {
        setBuyingOrderDate(formatToNewDate(pack?.enDt));
      }
    }, [endDt]);

    return (
      <>
        <div className="pack-item">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center gap-4">
              <span className="fz_20 fw-500 text--light">{`${
                pack.packNo == 0
                  ? t('pinefolio.plan.fullSet')
                  : t('pinefolio.plan.pack') + ' ' + pack.packNo
              } = ${numberFormat(pack.trgtAmt, 0, '0')}`}</span>
              <OverlayTrigger
                trigger={['hover', 'focus']}
                placement="top"
                className="portfolio-overlay"
                overlay={
                  <Tooltip style={{ 'z-index': '99999' }}>
                    {t('pinefolio.plan.setValue.explain')}
                  </Tooltip>
                }
              >
                <span className="icon iInfor iTooltip"></span>
              </OverlayTrigger>
            </div>
            <span
              className={`fz_13 fw-500 ${mapPfOrderStat(pack.status).color}`}
            >
              {t(mapPfOrderStat(pack.status).label)}
            </span>
          </div>
          <div className="d-flex">
            {pack.symbols &&
              pack.symbols.length &&
              pack.symbols.map((symbol, index) => (
                <>
                  <span className="fz_13 fw-500 text--light mr-1">
                    {symbol.symbol}
                  </span>
                  <span className="fz_13 fw-500 text--light3 mr-1">
                    {numberFormat(symbol.qty, 0, '')}
                  </span>
                  {index < pack.symbols.length - 1 && (
                    <span className="fz_13 fw-500 text--light3 mr-1">+</span>
                  )}
                </>
              ))}
          </div>
          <div className="invest-calendar">
            <div className="invest-calendar__item">
              <div className="invest-calendar__item--label text--light3">
                <span>{t('txt-label-start-date')}</span>
              </div>
              <div className="invest-calendar__item--value">
                <div className="filter-control-calendar filter-control-calendar--readOnly flex-1">
                  <DatePicker
                    selected={formatToNewDate(pack.stDt)}
                    dateFormat="dd/MM/yyyy"
                    popperContainer={CalendarContainer}
                    readOnly={true}
                  />
                  <span className="icon iCalendar"></span>
                </div>
              </div>
            </div>
            <div className="invest-calendar__item">
              <div className="invest-calendar__item--label text--light3">
                <span className="mr-1">
                  {t('pinefolio.plan.buyingOrderDate.name')}
                </span>
                <OverlayTrigger
                  trigger={['hover', 'focus']}
                  placement="top"
                  className="portfolio-overlay"
                  overlay={
                    <Tooltip style={{ 'z-index': '99999' }}>
                      {t('pinefolio.plan.createPlan.buyingOrderDateExplain')}
                    </Tooltip>
                  }
                >
                  <span className="icon iInfor iTooltip"></span>
                </OverlayTrigger>
              </div>
              <div className="viewPlan-setting__item--value">
                <div
                  className={`filter-control-calendar flex-1 ${
                    pack.status != 1 && pack.status != 2
                      ? 'filter-control-calendar--readOnly'
                      : ''
                  }`}
                >
                  <DatePicker
                    selected={buyingOrderDate}
                    onChange={(date) => handleChangeBuyingOrderDate(date)}
                    dateFormat="dd/MM/yyyy"
                    popperContainer={CalendarContainer}
                    placeholderText="dd/mm/yyyy"
                    minDate={formatToNewDate(pack?.enDt)}
                    maxDate={
                      new Date(
                        formatToNewDate(pack.stDt).setDate(
                          formatToNewDate(pack.stDt).getDate() + 90
                        )
                      )
                    }
                    readOnly={pack.status != 1 && pack.status != 2}
                  />
                  <span className="icon iCalendar"></span>
                </div>
              </div>
            </div>
          </div>
          {showModal && (
            <ConfirmEditBuyingDate
              t={t}
              setShowModal={setShowModal}
              showModal={showModal}
              onConfirmSwitchPack={onConfirmSwitchPack}
              clearChange={clearChange}
              packEditing={packEditing}
            />
          )}
        </div>
      </>
    );
  }
);

export default EditPack;
