import { Button, Modal } from 'react-bootstrap';
import { withNamespaces } from 'react-i18next';
import { useSelector } from 'react-redux';

const GenInfoKey = withNamespaces()(({ item, onClose, t }) => {
  const theme = useSelector((state) => state.client.currentTheme);
  const handleDownloadTxt = () => {
    const fileData = JSON.stringify(
      {
        clientId: item.clientId,
        secretToken: item.secretToken,
        privateKey: item.privateKey,
      },
      null,
      2
    );
    const blob = new Blob([fileData], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'aplication.json';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Modal
      show={true}
      dialogClassName="wts-modal"
      backdropClassName="wts-modal-backdrop"
      contentClassName={`${
        theme.name || 'dark'
      } wts-modal-content wts-modal-content--xs2`}
      centered
      style={{ zIndex: 11112 }}
    >
      <div className="gen-openapi-key">
        <div className="gen-openapi-key__head">
          <div className="text--md fw-500">{t('openApi.keyInfo')}</div>
          <div className="right-section">
            <span
              className="btn-icon btn-icon--delete3"
              onClick={() => onClose()}
            >
              <span className="icon iClose cursor-pointer"></span>
            </span>
          </div>
        </div>
        <div className="gen-openapi-key__body">
          <div className="container">
            <div className="title">{t('openApi.clientId')}</div>
            <div className="value">{item.clientId}</div>
          </div>
          <div className="container">
            <div className="title">{t('openApi.secretToken')}</div>
            <div className="value">{item.secretToken}</div>
          </div>
          <div className="container">
            <div className="title">{t('openApi.privateKey')}</div>
            <div className="value">{item.privateKey}</div>
          </div>
          <Button
            onClick={handleDownloadTxt}
            className="fw-500 fz_13 btn btn-submit btn--primary h-32"
          >
            {t('openApi.downloadKey')}
          </Button>
        </div>
      </div>
    </Modal>
  );
});

export default GenInfoKey;
