import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { Button } from 'react-bootstrap';

import { StringToInt } from '../../../../util';
import RenderInputMask from '../../../../components/input/renderInputMask';
import RenderArea from '../../../../components/input/renderArea';
import {
  makeGetToken,
  makeGetSourceBalance,
  makeGetAuth,
} from '../../../../lib/selector';
import { cashTransferRequest } from '../../../cash/actions';

import { required } from '../../../../util/validation';
import { v4 as uuidv4 } from 'uuid';
import { setAuth } from '../../../client/actions';
import { translate } from 'react-i18next';

class FormChuyenTien extends Component {
  submit = (values) => {
    const { auth } = this.props;
    if (!auth || !auth.isVerified) {
      this._handleAuthen();
    } else {
      const { amount, content } = values;
      const { token, _defSource, _defTarget, matchAmount, auth } = this.props;

      if (!_defTarget || !matchAmount) return;

      const _amount = StringToInt(amount);
      if (_amount > 0) {
        const uuid = uuidv4();
        const resData = {
          group: 'CORE',
          user: token.user,
          session: token.session,
          cmd: 'createCashTransfer',
          rqId: uuid,
          channel: 'WTS',
          type: auth.type,
          token: auth.token,
          data: {
            acntNo: _defSource.acntNo,
            subAcntNo: _defSource.subAcntNo,
            toAcntNo: _defTarget.subAcntNo,
            trdAmt: _amount + '',
            feeType: 1, // bao gom phi
            desc: content,
            transType: 1, // chuyen khoan noi bo
          },
        };

        this.props.dispatch(cashTransferRequest(resData));

        this.props.reset();
      }
    }
  };

  _handleAuthen = () => {
    this.props.dispatch(setAuth());
  };

  render() {
    const {
      handleSubmit,
      pristine,
      submitting,
      _defTarget,
      cashBalance,
      amount,
      matchAmount,
      auth,
      t,
    } = this.props;

    return (
      <Fragment>
        <form className="form-trade" onSubmit={handleSubmit(this.submit)}>
          <div className="mt-2">
            <div className="p-0 row-hug">
              <Field
                name="content"
                component={RenderArea}
                className="form-control fz_14"
                rows="1"
                validate={required}
              />
            </div>
            <div className="transfer-amount">
              <label className="transfer-amount-label">
                {t('transaction.cashInternalTransfer.transferAmount')}
              </label>
              <div className="transfer-amount-input">
                <Field
                  name="amount"
                  classname="form-control text-right form-input fz_14"
                  component={RenderInputMask}
                  validate={required}
                />
                {amount && !matchAmount && (
                  <div
                    style={{
                      color: 'rgb(255, 85, 85)',
                      margin: '0px 0px 10px',
                      fontSize: '0.75rem',
                    }}
                  >
                    {t('txt-valid-excess-amount')}
                  </div>
                )}
              </div>
              <div className="pb-0 pr-0 align-bottom transfer-amount-button">
                {auth && auth.isVerified ? (
                  <button
                    type="submit"
                    variant="info"
                    disabled={!(StringToInt(amount) > 0) || !matchAmount}
                    className="fw-500 btn btn--primary"
                  >
                    {`${t('txt-label-transfer-to')} ${
                      _defTarget.subAcntNo ? _defTarget.subAcntNo : ''
                    }`}
                  </button>
                ) : (
                  <button
                    onClick={() => this._handleAuthen()}
                    className="fw-500 btn btn--authen"
                  >
                    {`${t('txt-label-transfer-to')} ${
                      _defTarget.subAcntNo ? _defTarget.subAcntNo : ''
                    }`}
                  </button>
                )}
              </div>
            </div>
          </div>
        </form>
      </Fragment>
    );
  }
}

FormChuyenTien = reduxForm({
  form: 'chuyentien',
  enableReinitialize: true,
})(FormChuyenTien);

const selector = formValueSelector('chuyentien');

const makeMapStateToProps = () => {
  const getToken = makeGetToken();
  const getAuth = makeGetAuth();
  const getCashBal = makeGetSourceBalance();

  const mapStateToProps = (state, props) => {
    const { amount, content } = selector(state, 'amount', 'content');
    const { _defSource, _defTarget, t } = props;
    // console.log(_defSource, _defTarget)
    const token = getToken(state);
    const auth = getAuth(state);
    const cashBalance = getCashBal(state);
    const wdrawAvail = cashBalance ? cashBalance.wdrawAvail : 0;

    return {
      token,
      auth,
      amount,
      content,
      _defSource,
      _defTarget,
      cashBalance,
      matchAmount:
        selector(state, 'amount') && StringToInt(amount) <= wdrawAvail,
      initialValues: {
        amount: '0',
        content:
          t('txt-account-acronym') +
          ' ' +
          (token ? token.vsd : '') +
          ' - ' +
          (token ? token.name : '') +
          ' ' +
          t('txt-label-online-transfer') +
          ' ' +
          (_defSource && _defSource.subAcntNo ? _defSource.subAcntNo : '') +
          ' ' +
          t('txt-to') +
          ' ' +
          (_defTarget && _defTarget.subAcntNo ? _defTarget.subAcntNo : ''),
      },
    };
  };
  return mapStateToProps;
};

FormChuyenTien = connect(makeMapStateToProps)(FormChuyenTien);

export default translate('translations')(FormChuyenTien);
