import React from 'react';

import TextMask from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

const RenderInputMask = ({
  input,
  classname,
  placeholder,
  disabled,
  allowNegative,
  meta: { touched, error },
}) => (
  <div>
    <TextMask
      {...input}
      maxLength="15"
      mask={createNumberMask({
        prefix: '',
        allowNegative: allowNegative || false,
      })}
      placeholder={placeholder || ''}
      className={classname}
      onFocus={(e) => e.currentTarget.select()}
      disabled={disabled}
      style={{
        borderColor: `${touched && error ? '#ff5555' : '#c6cbd2'}`,
      }}
    />
  </div>
);

export default RenderInputMask;
