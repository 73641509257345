import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import ReactModal from 'react-modal';
import {
  setBondDetailModalRequest,
  getBondIssuers,
  setRegistBondTradingRetailModal,
  getBondCommonRequest2,
} from '../action';
import BondDetail from './bondDetail';
import { withNamespaces } from 'react-i18next';
import { _formatDate2 } from '../../../util';
import BondDemo from './Order/bondDemo';
import { useLocation, useParams } from 'react-router-dom';
import ProInvestorAlert from './Order/proInvestorAlert';
import { Mixpanel } from '../../../lib/mixpanel';
import { bondConstants, constants } from '../../../util/constant';
import FBond from './Type/fBond';
import VBond from './Type/vBond';
import GBond from './Type/gBond';
import { v4 as uuidv4 } from 'uuid';

const Product = withNamespaces('translations')(({ t }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const location = useLocation();
  const typeBond = new URLSearchParams(location.search).get('type');
  const { defaultAccount, listAccount } = useSelector((state) => state.socket);
  const { bondDetailModalType } = useSelector((state) => state.bond);
  const [tab, setTab] = useState(constants.bondProductType.Fix);
  const [modalSize, setModalSize] = useState('');
  const [limitInfor, setLimitInfor] = useState();

  const {
    token,
    setting: { lang = 'vi' },
  } = useSelector((state) => state.client);

  useEffect(() => {
    if (id) {
      if (
        typeBond == constants.bondProductType.Fix ||
        typeBond == constants.bondProductType.Var ||
        typeBond == constants.bondProductType.Growth
      ) {
        setTab(typeBond);
        Mixpanel.bond.viewProductList(typeBond);
      } else {
        setTab(constants.bondProductType.Var);
        Mixpanel.bond.viewProductList(constants.bondProductType.Var);
      }
    }
  }, [typeBond]);

  const bondDetailModalTypeHandle = (state) => {
    dispatch(setBondDetailModalRequest(state));
  };

  const getBondLmtVal = (lang, listAccount) => {
    const uuid = uuidv4();
    const normalSubAccount = listAccount.find(
      (x) => x.subAcntClss == constants.subAccount.Normal && x.subAcntStat == 1
    );

    if (normalSubAccount) {
      const params = {
        group: 'CORE',
        cmd: 'getBondLmtVal',
        rqId: uuid,
        channel: 'WTS',
        user: token?.user,
        session: token?.session,
        data: {
          cif: token?.cif,
          acntNo: normalSubAccount?.acntNo,
          subAcntNo: normalSubAccount?.subAcntNo,
          langTp: lang,
        },
      };

      const callback = (data) => {
        setLimitInfor(data);
      };

      dispatch(getBondCommonRequest2(params, callback, true));
    }
  };

  useEffect(() => {
    if (lang) {
      dispatch(getBondIssuers(lang));
    }
  }, [lang]);

  useEffect(() => {
    if (lang && !!listAccount?.length > 0) getBondLmtVal(lang, listAccount);
  }, [listAccount, lang]);

  useEffect(() => {
    if (bondDetailModalType == 0) setModalSize('wts-modal-content--sm');
    else setModalSize('wts-modal-content--xl2');
  }, [bondDetailModalType]);

  return (
    <>
      <div className="bond-product">
        <div className="d-flex gap-8">
          {[
            { id: constants.bondProductType.Fix, label: 'PineB Fix' },
            { id: constants.bondProductType.Var, label: 'PineB Flex' },
            { id: constants.bondProductType.Growth, label: 'PineB Growth' },
          ].map((bond, i) => (
            <span
              key={i}
              className={`bond-product-type ${tab == bond.id ? 'active' : ''}`}
              onClick={() => {
                setTab(bond.id);
                Mixpanel.bond.viewProductList(bond.id);
              }}
            >
              <span className="text fw-500 text--lg">{bond.label}</span>
            </span>
          ))}
        </div>
        {tab == constants.bondProductType.Fix && (
          <FBond setModalSize={setModalSize} limitInfor={limitInfor} />
        )}
        {tab == constants.bondProductType.Var && (
          <VBond setModalSize={setModalSize} limitInfor={limitInfor} />
        )}
        {tab == constants.bondProductType.Growth && (
          <GBond setModalSize={setModalSize} limitInfor={limitInfor} />
        )}
      </div>

      <BondDetail
        typeBond={tab}
        modalSize={modalSize}
        closeHandle={() =>
          bondDetailModalTypeHandle(bondConstants.modals.CLOSE)
        }
        resizeModal={setModalSize}
      />
      <BondDemo />
      <ProInvestorAlert />
    </>
  );
});

export default Product;
