export const GET_PROFILE_REQUESTING = 'GET_PROFILE_REQUESTING';
export const GET_PROFILE_REQUEST_SUCCESS = 'GET_PROFILE_REQUEST_SUCCESS';
export const GET_PROFILE_REQUEST_ERROR = 'GET_PROFILE_REQUEST_ERROR';

export const GET_PROFILE_STATE_REQUEST = 'GET_PROFILE_STATE_REQUEST';
export const GET_PROFILE_STATE_REQUEST_SUCCESS =
  'GET_PROFILE_STATE_REQUEST_SUCCESS';
export const GET_PROFILE_STATE_REQUEST_ERROR =
  'GET_PROFILE_STATE_REQUEST_ERROR';

export const UPDATE_PROFILE_RESET = 'UPDATE_PROFILE_RESET';
export const UPDATE_PROFILE_REQUESTING = 'UPDATE_PROFILE_REQUESTING';
export const UPDATE_PROFILE_REQUEST_SUCCESS = 'UPDATE_PROFILE_REQUEST_SUCCESS';
export const UPDATE_PROFILE_REQUEST_ERROR = 'UPDATE_PROFILE_REQUEST_ERROR';

export const OTP_SENDING_REQUESTING = 'OTP_SENDING_REQUESTING';
export const OTP_VERITY_REQUESTING = 'OTP_VERITY_REQUESTING';

export const BANK_ACC_REQUESTING = 'BANK_ACC_REQUESTING';
export const BANK_ACC_REQUEST_SUCCESS = 'BANK_ACC_REQUEST_SUCCESS';
export const BANK_ACC_REQUEST_ERROR = 'BANK_ACC_REQUEST_ERROR';

export const REMOVE_BANK_REQUESTING = 'REMOVE_BANK_REQUESTING';
export const REMOVE_BANK_REQUEST_SUCCESS = 'REMOVE_BANK_REQUEST_SUCCESS';
export const REMOVE_BANK_REQUEST_ERROR = 'REMOVE_BANK_REQUEST_ERROR';

export const SET_DEFAULT_REQUESTING = 'SET_DEFAULT_REQUESTING';
export const SET_DEFAULT_REQUEST_SUCCESS = 'SET_DEFAULT_REQUEST_SUCCESS';
export const SET_DEFAULT_REQUEST_ERROR = 'SET_DEFAULT_REQUEST_ERROR';
export const RESET_DEFAULT_REQUEST = 'RESET_DEFAULT_REQUEST';

export const GET_SMS_SERVICES_REQUESTING = 'GET_SMS_SERVICES_REQUESTING';
export const GET_SMS_SERVICES_REQUEST_SUCCESS =
  'GET_SMS_SERVICES_REQUEST_SUCCESS';
export const GET_SMS_SERVICES_REQUEST_ERROR = 'GET_SMS_SERVICES_REQUEST_ERROR';

export const SET_SMS_SERVICES_REQUESTING = 'SET_SMS_SERVICES_REQUESTING';
export const SET_SMS_SERVICES_REQUEST_SUCCESS =
  'SET_SMS_SERVICES_REQUEST_SUCCESS';
export const SET_SMS_SERVICES_REQUEST_ERROR = 'SET_SMS_SERVICES_REQUEST_ERROR';
export const RESET_SMS_SERVICES_REQUEST = 'RESET_SMS_SERVICES_REQUEST';

export const GET_EXT_SETTING_REQUESTING = 'GET_EXT_SETTING_REQUESTING';
export const GET_EXT_SETTING_REQUEST_SUCCESS =
  'GET_EXT_SETTING_REQUEST_SUCCESS';
export const GET_EXT_SETTING_REQUEST_ERROR = 'GET_EXT_SETTING_REQUEST_ERROR';

export const SET_EXT_SETTING_REQUESTING = 'SET_EXT_SETTING_REQUESTING';
export const SET_EXT_SETTING_REQUEST_SUCCESS =
  'SET_EXT_SETTING_REQUEST_SUCCESS';
export const SET_EXT_SETTING_REQUEST_ERROR = 'SET_EXT_SETTING_REQUEST_ERROR';
export const RESET_EXT_SETTING_REQUEST = 'RESET_EXT_SETTING_REQUEST';
export const VC_BOOKING_DATE_REQUESTING = 'VC_BOOKING_DATE_REQUESTING';
export const VC_BOOKING_DATE_SUCCESS = 'VC_BOOKING_DATE_SUCCESS';
export const VC_BOOKING_DATE_ERROR = 'VC_BOOKING_DATE_ERROR';
export const VC_BOOKING_TIME_REQUESTING = 'VC_BOOKING_TIME_REQUESTING';
export const VC_BOOKING_TIME_SUCCESS = 'VC_BOOKING_TIME_SUCCESS';
export const VC_BOOKING_TIME_ERROR = 'VC_BOOKING_TIME_ERROR';

export const VC_BOOKING_REQUESTING = 'VC_BOOKING_REQUESTING';
export const VC_BOOKING_SUCCESS = 'VC_BOOKING_SUCCESS';
export const VC_BOOKING_ERROR = 'VC_BOOKING_ERROR';

export const REGIST_VC_BOOKING_REQUESTING = 'REGIST_VC_BOOKING_REQUESTING';
export const REGIST_VC_BOOKING_SUCCESS = 'REGIST_VC_BOOKING_SUCCESS';
export const REGIST_VC_BOOKING_ERROR = 'REGIST_VC_BOOKING_ERROR';

export const CANCEL_VC_BOOKING_REQUESTING = 'CANCEL_VC_BOOKING_REQUESTING';
export const CANCEL_VC_BOOKING_SUCCESS = 'CANCEL_VC_BOOKING_SUCCESS';
export const CANCEL_VC_BOOKING_ERROR = 'CANCEL_VC_BOOKING_ERROR';

export const OPEN_SUBACCOUNT_CONTRACT_REQUEST =
  'OPEN_SUBACCOUNT_CONTRACT_REQUEST';
export const OPEN_SUBACCOUNT_CONTRACT_SUCCESS =
  'GET_OPEN_SUBACCOUNT_CONTRACT_SUCCESS';
export const CONFIRM_SUBACCOUNT_CONTRACT_REQUEST =
  'CONFIRM_SUBACCOUNT_CONTRACT_REQUEST';
export const OPEN_BOOKING_VIDEO_MODAL = 'OPEN_BOOKING_VIDEO_MODAL';
export const GET_CREDIT_LIMIT_LEVEL = 'GET_CREDIT_LIMIT_LEVEL';
export const GET_CREDIT_LIMIT_LEVEL_SUCCESS = 'GET_CREDIT_LIMIT_LEVEL_SUCCESS';

export const OPEN_CREDIT_LIMIT_MODAL = 'OPEN_CREDIT_LIMIT_MODAL';

export const GET_LIST_ACCOUNTS = 'GET_LIST_ACCOUNTS';

export const GET_BROKER_INFOR_REQUEST = 'GET_BROKER_INFOR_REQUEST';
export const GET_BROKER_INFOR_SUCCESS = 'GET_BROKER_INFOR_SUCCESS';

export const GET_ACCOUNT_COMMON_REQUEST = 'GET_ACCOUNT_COMMON_REQUEST';
export const SET_PARTNER_SHIP_REQUEST = 'SET_PARTNER_SHIP_REQUEST';
export const SUBMIT_PARTNER_SHIP_REQUEST = 'SUBMIT_PARTNER_SHIP_REQUEST';
export const SUBMIT_PARTNER_SHIP_SUCCESS = 'SUBMIT_PARTNER_SHIP_SUCCESS';

export const GET_SER_LIST_4_CUST_REQUEST = 'GET_SER_LIST_4_CUST_REQUEST';
export const GET_SER_LIST_4_CUST__SUCCESS = 'GET_SER_LIST_4_CUST__SUCCESS';

export const CHANGE_FEE_MODAL_SET = 'CHANGE_FEE_MODAL_SET';
export const GET_API_SERVICES_REQUESTING = 'GET_API_SERVICES_REQUESTING';
export const GET_API_SERVICES_REQUEST_SUCCESS =
  'GET_API_SERVICES_REQUEST_SUCCESS';
export const GET_API_SERVICES_REQUEST_ERROR = 'GET_API_SERVICES_REQUEST_ERROR';

export const GEN_API_SERVICES_KEY = 'GEN_API_SERVICES_KEY';