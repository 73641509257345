import React, { useContext, useEffect, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import { ReactReduxContext, useDispatch, useSelector } from 'react-redux';
import { setToast, unsetClient } from '../../../client/actions';
import {
  Logout,
  logoutRequest,
} from '../../../../components/modal/login/actions';
import { v4 as uuidv4 } from 'uuid';
import { removeCookie } from '../../../../lib/storages';
import { _formatDate2, getMessageError, numberFormat } from '../../../../util';
import { handleApiErrors } from '../../../../lib/api-errors';
import CheckboxButton from '../../../../components/checkbox/checkBoxButton';
import PDFViewer from '../../../bond/components/Contract/pdfViewer';
import { getPFPortfolio } from '../../action';
import AuthenStep from '../../../../components/common/auth';
import { LOCK_RESENT_OTP } from '../../../../components/modal/auth/constants';
import { lockResendOtp } from '../../../../components/modal/auth/actions';
const appUrl = `${process.env.REACT_APP_API_URL}`;

const RebalancePortfolioModal = withNamespaces('translations')(
  ({ t, show, closeHandle, pfCode }) => {
    const [rebalance, setRebalance] = useState();
    const [confirm, setConfirm] = useState(false);

    const theme = useSelector((state) => state.client?.currentTheme);
    const { currentPinefolio } = useSelector((state) => state.pinefolio);
    const { token, auth } = useSelector((state) => state.client);
    const lang = useSelector((state) => state.client?.setting?.lang || 'vi');
    const { defaultAccount, listAccount } = useSelector(
      (state) => state.socket
    );
    const { store } = useContext(ReactReduxContext);
    const dispatch = useDispatch();

    useEffect(() => {
      if (pfCode) {
        // call get Pf rebalance inside
        getPFRebalanceSchedule(pfCode);
      }
    }, [pfCode]);

    const handleCloseModal = () => {
      if (closeHandle && typeof closeHandle == 'function') {
        closeHandle();
        setConfirm(false);
      }
    };
    const toastHandle = (msg) => {
      const toastMsg = {
        id: Math.random(),
        msg: msg,
        title: t('txt-notice'),
      };
      dispatch(setToast(toastMsg));
    };

    const excuteApi = (params, callback) => {
      if (token) {
        const url = `${appUrl}/PortfolioServlet.pt`;
        fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          },
          body: JSON.stringify(params),
        })
          .then(handleApiErrors)
          .then((response) => response.json())
          .then((res) => {
            if (res.http !== 200) {
              if (res.data.messageNo === 99991) {
                dispatch(lockResendOtp());
              }

              if (res.http === 401) {
                if (token) dispatch(logoutRequest(token));
                dispatch(unsetClient());
                removeCookie('token');
                removeCookie('authen');
                dispatch(Logout());
                toastHandle(t('txt-valid-loss-session'));
              } else {
                toastHandle(getMessageError(res, store.getState));
              }
            } else {
              // success
              const data = res.data;
              callback(data);
            }
          })
          .catch((error) => {
            console.error('Error: ', error);
          });
      }
    };

    const getPFRebalanceSchedule = (pfCode) => {
      const uuid = uuidv4();
      const params = {
        group: 'CORE',
        cmd: 'getPFRblSchd',
        rqId: uuid,
        channel: 'WTS',
        session: token.session,
        user: token.user,

        data: {
          langTp: lang,
          cif: token.cif,
          acntNo: defaultAccount?.acntNo,
          subAcntNo: defaultAccount?.subAcntNo,
          pfCode: pfCode,
        },
      };

      const callback = (data) => {
        if (data && !!data.length > 0) {
          setRebalance(data[0]);
        }
      };

      excuteApi(params, callback);
    };

    const rebalanceConfirm = (data) => {
      handleCloseModal();
      toastHandle(t('pinefolio.rebalance.success'));
      // reload PF portfolio
      const uuid = uuidv4();
      const params = {
        group: 'CORE',
        cmd: 'getPFPortfolioMst',
        rqId: uuid,
        channel: 'WTS',
        session: token.session,
        user: token.user,

        data: {
          cif: token.cif,
          acntNo: defaultAccount?.acntNo,
          subAcntNo: defaultAccount?.subAcntNo,
          langTp: lang,
        },
      };

      dispatch(getPFPortfolio(params));
    };

    return (
      <div>
        <Modal
          show={show}
          onHide={handleCloseModal}
          onEscapeKeyDown={handleCloseModal}
          dialogClassName="wts-modal"
          backdropClassName="wts-modal-backdrop"
          contentClassName={`${
            theme.name || 'dark'
          } wts-modal-content wts-modal-content--sm2`}
          centered
          style={{ zIndex: 11112 }}
        >
          <div className="wts-modal2 pinefolio-rebalance">
            <div className="wts-modal2__header">
              <span className="btn-icon btn--transparent"></span>
              <span className="text--light text--md">{`${t(
                'pinefolio.rebalance.name'
              )} ${currentPinefolio?.pfThemeNm} - ${
                currentPinefolio?.pfCode
              }`}</span>
              <button
                className="btn-icon btn--transparent"
                onClick={() => handleCloseModal()}
              >
                <span className="icon iClose"></span>
              </button>
            </div>
            <div className="wts-modal2__body pinefolio-rebalance__body">
              <div className="rebalance-icon">
                <span className="icon iRebalance"></span>
              </div>

              <div className="rebalance-infor">
                <span className="text--sm text--light3">
                  {t('pinefolio.rebalance.title')}
                </span>
                <span className="text--sm text-light fw-500">
                  {t('pinefolio.rebalance.desc', {
                    fromDate: _formatDate2(rebalance?.stDt),
                    toDate: _formatDate2(rebalance?.enDt),
                  })}
                </span>
                {rebalance?.rptFileUrl && (
                  <span
                    className="text--sm text-light-blue3 fw-500"
                    onClick={() => window.open(rebalance?.rptFileUrl)}
                  >
                    Rebalance info.pdf
                  </span>
                )}
              </div>

              <div className="rebalance-content">
                <div className="rebalance-content__infors" key={0}>
                  <div className="rebalance-content__infor rebalance-content__symbol text--md text--light3">
                    {t('txt-symbol')}
                  </div>
                  <div className="rebalance-content__infor rebalance-content__rate text--sm text--light3">
                    {t('pinefolio.rebalancePortfolio.rebalanceRate')}
                  </div>
                  <div className="rebalance-content__infor rebalance-content__vol text--sm text--light3">
                    {t('pinefolio.rebalancePortfolio.volumeInPortfolio')}
                  </div>
                  <div className="rebalance-content__infor rebalance-content__volInPlan text--sm text--light3">
                    {t('pinefolio.rebalancePortfolio.volumeInPlan')}
                  </div>
                </div>

                {rebalance && rebalance.symbolOut && (
                  <div className="rebalance-content__infors" key={2}>
                    <div className="rebalance-content__infor rebalance-content__symbol text--md text--light">
                      <span className="icon iOut d"></span>
                      <span>{rebalance.symbolOut}</span>
                    </div>
                    <div className="rebalance-content__infor rebalance-content__rate text--sm text--light">
                      {numberFormat(rebalance.untQtyOut, 0, '0')}
                    </div>
                    <div className="rebalance-content__infor rebalance-content__vol text--sm text--light">
                      {numberFormat(rebalance.qtyOut, 0, '0')}
                    </div>
                    <div className="rebalance-content__infor rebalance-content__volInPlan text--sm text--light">
                      {rebalance?.planOut}
                    </div>
                  </div>
                )}

                {rebalance && rebalance.symbolIn && (
                  <div className="rebalance-content__infors" key={1}>
                    <div className="rebalance-content__infor rebalance-content__symbol text--md text--light">
                      <span className="icon iIn i"></span>
                      <span>{rebalance.symbolIn}</span>
                    </div>
                    <div className="rebalance-content__infor rebalance-content__rate text--sm text--light">
                      {numberFormat(rebalance.untQtyIn, 0, '0')}
                    </div>
                    <div className="rebalance-content__infor rebalance-content__vol text--sm text--light">
                      {numberFormat(rebalance.qtyIn, 0, '0')}
                    </div>
                    <div className="rebalance-content__infor rebalance-content__volInPlan text--sm text--light">
                      {rebalance?.planIn}
                    </div>
                  </div>
                )}
              </div>

              <div className="rebalance-actions">
                <button
                  className="btn btn--primary"
                  onClick={() => setConfirm(true)}
                >
                  {t('pinefolio.rebalancePortfolio.reblanceConfirm')}
                </button>

                <button
                  className="btn btn--cancel"
                  onClick={() => handleCloseModal()}
                >
                  {t('pinefolio.rebalancePortfolio.later')}
                </button>
              </div>
            </div>
          </div>
        </Modal>
        {rebalance && rebalance.rblId && (
          <ConfirmRebalanceContract
            rebalanceId={rebalance.rblId}
            callback={rebalanceConfirm}
            show={confirm}
            closeHandle={() => setConfirm(false)}
            excuteApi={excuteApi}
          />
        )}
      </div>
    );
  }
);

const ConfirmRebalanceContract = withNamespaces('translations')(
  ({ t, callback, show, closeHandle, rebalanceId, excuteApi }) => {
    const theme = useSelector((state) => state.client?.currentTheme);
    const { token, auth } = useSelector((state) => state.client);
    const lang = useSelector((state) => state.client?.setting?.lang || 'vi');
    const { defaultAccount } = useSelector((state) => state.socket);

    const [oneTimeToken, setOneTimeToken] = useState('');
    const [contract, setContract] = useState();
    const [check, setCheck] = useState(false);

    const getRblContract = () => {
      if (token && defaultAccount) {
        const uuid = uuidv4();
        const params = {
          group: 'CORE',
          cmd: 'getPFRblCntr',
          rqId: uuid,
          channel: 'WTS',
          session: token.session,
          user: token.user,
          data: {
            langTp: lang,
            cif: token.cif,
            acntNo: defaultAccount?.acntNo,
            subAcntNo: defaultAccount?.subAcntNo,
            rblId: rebalanceId,
          },
        };

        excuteApi(params, (data) => {
          setContract(data);
        });
      }
    };

    const submitHandle = () => {
      if (!disableHandle() && token && defaultAccount) {
        const uuid = uuidv4();
        const params = {
          group: 'CORE',
          cmd: 'confirmPFRbl',
          rqId: uuid,
          channel: 'WTS',
          session: token.session,
          user: token.user,
          type: '6',
          token: oneTimeToken ?? '',
          data: {
            langTp: lang,
            cif: token.cif,
            acntNo: defaultAccount?.acntNo,
            subAcntNo: defaultAccount?.subAcntNo,
            rblId: rebalanceId,
          },
        };

        excuteApi(params, callback);
      }
    };

    const disableHandle = () => {
      if (!oneTimeToken || !check) return true;
      else return false;
    };

    useEffect(() => {
      getRblContract();
    }, []);

    return (
      <Modal
        show={show}
        onEscapeKeyDown={closeHandle}
        backdrop="static"
        dialogClassName="wts-modal"
        backdropClassName="wts-modal-backdrop"
        contentClassName={`${
          theme.name || 'dark'
        } wts-modal-content wts-modal-content--xl`}
        centered
        style={{ zIndex: 11115 }}
      >
        <div className="wts-modal2 pinefolio-rebalance-contract">
          <div className="pinefolio-rebalance-contract__left">
            <div className="pinefolio-rebalance-contract__header">
              <span className="text--lg fw-500 pinefolio-rebalance-contract__title">
                {t('pinefolio.rebalance.contractHeader')}
              </span>
              <CheckboxButton
                description={t('pinefolio.rebalance.confirm')}
                onClick={() => setCheck(!check)}
              />
            </div>
            <div className="pinefolio-rebalance-contract__body">
              <div className="2fa-authentication">
                <AuthenStep setOneTimeToken={setOneTimeToken} trackMixpanel />
              </div>
              <div className="pinefolio-rebalance-contract__actions">
                <button
                  className="btn btn--buy pinefolio-rebalance-contract__action"
                  onClick={() => submitHandle()}
                  disabled={disableHandle()}
                >
                  {t('txt-confirm')}
                </button>
                <button
                  className="btn btn--cancel pinefolio-rebalance-contract__action"
                  onClick={() => closeHandle()}
                >
                  {t('txt-cancel')}
                </button>
              </div>
            </div>
          </div>
          <div className="pinefolio-rebalance-contract__right">
            {contract && <PDFViewer src={contract.fileUrl} />}
          </div>
        </div>
      </Modal>
    );
  }
);

export default RebalancePortfolioModal;
