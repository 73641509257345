import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';

// import { required } from '../../../util/validation';
// import RenderInputOrder from '../../input/renderInputOrder';

import ReactModal from 'react-modal';
import { Card } from 'react-bootstrap';
import { FaTimes } from 'react-icons/fa';
import {
  makeGetToken,
  makeGetDefaultAccount,
  makeGetAuth,
} from '../../../lib/selector';
import { v4 as uuidv4 } from 'uuid';
import { translate } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import './deleteOrder.scss';

class FormDeleteOrder extends Component {
  submit = (values) => {
    const { order, defaultAccount, token, auth } = this.props;

    const uuid = uuidv4();
    const params = {
      group: 'CORE',
      user: token.user,
      session: token.session,
      cmd: 'CancelOrder',
      type: auth.type,
      token: auth.token,
      rqId: uuid,
      channel: 'WTS',
      data: {
        origOrdrNo: order.ordrNo + '',
        acntNo: defaultAccount.acntNo,
        subAcntNo: order.subAcntNo,
      },
    };

    this.props.handleSubmitOrder(params);
    // this.props.reset();
  };

  render() {
    const {
      handleSubmit,
      themes,
      submitting,
      showModal,
      handleCloseModal,
      order,
      t,
    } = this.props;

    return (
      <Modal
        show={showModal}
        dialogClassName="delete-order-modal"
        backdropClassName="delete-order-modal-backdrop"
        contentClassName={`${
          themes?.name || 'dark'
        } delete-order-modal-content`}
        onEscapeKeyDown={handleCloseModal}
        centered
        style={{ zIndex: 11115 }}
      >
        <div className="delete-order">
          <div className="delete-order-header">
            {t('txt-title-delete-confirm')}
          </div>
          <form
            className="delete-order-body"
            onSubmit={handleSubmit(this.submit)}
          >
            <div className="delete-order-body__infor">
              <span className="delete-order-body__infor-title">
                <p>{t('txt-symbol')}</p>
                <p>{t('txt-type')}</p>
                <p>{t('txt-orderNo')}</p>
              </span>
              <span className="delete-order-body__infor-value">
                <p>{order && order.symbol}</p>
                <p
                  className={`${order && (order.buySelTp === '1' ? 'i' : 'd')}`}
                >
                  {order &&
                    (order.buySelTp === '1' ? t('txt-buy') : t('txt-sell'))}
                </p>
                <p>{order && order.ordrNo}</p>
              </span>
            </div>

            <div className="delete-order-body__action">
              <button
                type="submit"
                className="btn-confirm"
                disabled={submitting}
              >
                {t('txt-accept')}
              </button>
              <button
                className="btn-cancel"
                disabled={submitting}
                onClick={handleCloseModal}
              >
                {t('txt-cancel')}
              </button>
            </div>
          </form>
        </div>
      </Modal>
    );
  }
}

FormDeleteOrder = reduxForm({
  form: 'deleteOrder',
  enableReinitialize: true,
})(FormDeleteOrder);

const makeMapStateToProps = () => {
  const getToken = makeGetToken();
  const getDefaultAccount = makeGetDefaultAccount();
  const getAuth = makeGetAuth();

  const mapStateToProps = (state) => {
    return {
      token: getToken(state),
      defaultAccount: getDefaultAccount(state),
      auth: getAuth(state),
      themes: state.client.currentTheme,
    };
  };
  return mapStateToProps;
};

FormDeleteOrder = connect(makeMapStateToProps)(FormDeleteOrder);

export default translate('translations')(FormDeleteOrder);
