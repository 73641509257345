export const SET_BOND_TAB = 'SET_BOND_TAB';
export const GET_BOND_ORDERLIST_REQUEST = 'GET_BOND_ORDERLIST_REQUESTING';
export const GET_BOND_ORDERLIST_SUCCESS = 'GET_BOND_ORDERLIST_SUCCESS';
export const GET_BOND_ORDERLIST_ERROR = 'GET_BOND_ORDERLIST_ERROR';
export const SET_BOND_DETAIL_MODAL = 'SET_BOND_DETAIL_MODAL';
export const GET_BOND_DETAIL_GUARANTEE_MODAL =
  'GET_BOND_DETAIL_GUARANTEE_MODAL';
export const GET_BOND_DETAIL_GUARANTEE_MODAL_SUCCESS =
  'GET_BOND_DETAIL_GUARANTEE_MODAL_SUCCESS';
export const GET_BOND_DETAIL_REQUEST = 'GET_BOND_DETAIL_REQUEST';
export const GET_BOND_DETAIL_REQUEST_SUCCESS =
  'GET_BOND_DETAIL_REQUEST_SUCCESS';
export const GET_BOND_DETAIL_REQUEST_ERROR = 'GET_BOND_DETAIL_REQUEST_ERROR';

export const GET_BOND_PRODUCTS_REQUEST = 'GET_BOND_PRODUCTS_REQUEST';
export const GET_BOND_PRODUCTS_REQUEST_SUCCESS =
  'GET_BOND_PRODUCTS_REQUEST_SUCCESS';
export const GET_BOND_PRODUCTS_REQUEST_ERROR =
  'GET_BOND_PRODUCTS_REQUEST_ERROR';

export const SET_BOND_PRODUCT_DETAIL = 'SET_BOND_PRODUCT_DETAIL';

export const GET_BOND_ISSUERS_REQUEST = 'GET_BOND_ISSUERS_REQUEST';
export const GET_BOND_ISSUERS_REQUEST_SUCCESS =
  'GET_BOND_ISSUERS_REQUEST_SUCCESS';
export const GET_BOND_ISSUERS_REQUEST_ERROR = 'GET_BOND_ISSUERS_REQUEST_ERROR';

export const GET_BOND_DEALLIST_REQUEST = 'GET_BOND_DEALLIST_REQUEST';

export const GET_BOND_PORTFOLIO_REQUEST = 'GET_BOND_PORTFOLIO_REQUEST';
export const GET_BOND_PORTFOLIO_SUCCESS = 'GET_BOND_PORTFOLIO_SUCCESS';
export const GET_BOND_PORTFOLIO_ERROR = 'GET_BOND_PORTFOLIOT_ERROR';

export const GET_BOND_PREORDER_REQUEST = 'GET_BOND_PREORDER_REQUEST';
export const GET_BOND_PREORDER_SUCCESS = 'GET_BOND_PREORDER_SUCCESS';
export const GET_BOND_PREORDER_ERROR = 'GET_BOND_PREORDER_ERROR';

export const GET_PROD_IMCOME_FLOW_REQUEST = 'GET_PROD_IMCOME_FLOW_REQUEST';
export const GET_PROD_IMCOME_FLOW_SUCCESS = 'GET_PROD_IMCOME_FLOW_SUCCESS';
export const GET_PROD_IMCOME_FLOW_ERROR = 'GET_PROD_IMCOME_FLOW_ERROR';
export const GET_BOND_PREORDER_REQUEST_SUCCESS =
  'GET_BOND_PREORDER_REQUEST_SUCCESS';
export const GET_BOND_PREORDER_REQUEST_ERROR =
  'GET_BOND_PREORDER_REQUEST_ERROR';

export const GET_DEAL4SALE_REQUEST = 'GET_DEAL4SALE_REQUEST';
export const GET_DEAL4SALE_REQUEST_SUCCESS = 'GET_DEAL4SALE_REQUEST_SUCCESS';
export const GET_DEAL4SALE_REQUEST_ERROR = 'GET_DEAL4SALE_REQUEST_ERROR';

export const GET_BOND_RATE_CHART_REQUEST = 'GET_BOND_RATE_CHART_REQUEST';
export const GET_BOND_RATE_CHART_REQUEST_SUCCESS =
  'GET_BOND_RATE_CHART_REQUEST_SUCCESS';
export const GET_BOND_RATE_CHART_REQUEST_ERROR =
  'GET_BOND_RATE_CHART_REQUEST_ERROR';

export const SET_BOND_ORDER = 'SET_BOND_ORDER';

export const SELL_ORDER_REQUEST = 'SELL_ORDER_REQUEST';
export const SELL_ORDER_REQUEST_SUCCESS = 'SELL_ORDER_REQUEST_SUCCESS';
export const SELL_ORDER_REQUEST_ERROR = 'SELL_ORDER_REQUEST_ERROR';
export const SELL_ORDER_REQUEST_RESET = 'SELL_ORDER_REQUEST_RESET';
export const BUY_ORDER_REQUEST = 'BUY_ORDER_REQUEST';
export const BUY_ORDER_REQUEST_SUCCESS = 'BUY_ORDER_REQUEST_SUCCESS';
export const BUY_ORDER_REQUEST_ERROR = 'BUY_ORDER_REQUEST_ERROR';

export const PRO_INVESTOR_REQUIRED = 'PRO_INVESTOR_REQUIRED';
export const SET_DEAL_DETAIL = 'SET_DEAL_DETAIL';

export const GET_BOND_FIX_REQUEST = 'GET_BOND_FIX_REQUEST';
export const GET_BOND_FIX_REQUEST_SUCCESS = 'GET_BOND_FIX_REQUEST_SUCCESS';

export const GET_BOND_COMMON_REQUEST = 'GET_BOND_COMMON_REQUEST';
export const GET_BOND_COMMON_REQUEST2 = 'GET_BOND_COMMON_REQUEST2';
export const GET_BOND_COMMON_REQUEST3 = 'GET_BOND_COMMON_REQUEST3';
export const SET_BOND_REGIST_RETAIL_TRADING_MODAL =
  'SET_BOND_REGIST_RETAIL_TRADING_MODAL';
export const SET_BOND_REGIST_RETAIL_TRADING_SUCCESS_MODAL =
  'SET_BOND_REGIST_RETAIL_TRADING_SUCCESS_MODAL';
