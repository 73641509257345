import React, { useMemo, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { withNamespaces } from 'react-i18next';
import { useSelector } from 'react-redux';
import { makeTradingviewConfig } from '../../../../lib/selector';
import { toQueryString } from '../../../../util';

const TRADING_VIEW_CHART_URL = process.env.REACT_APP_TRADING_VIEW_CHART_URL;
const ExchangeModal = withNamespaces('translations')(
  ({ t, symbol, exchangeModal, setExchangeModal }) => {
    const [zoom, setZoom] = useState(false);
    const widgetOptions = useSelector(makeTradingviewConfig());

    return (
      <>
        <Modal
          show={exchangeModal}
          dialogClassName="wts-modal h-75"
          backdropClassName="wts-modal-backdrop"
          contentClassName={`${
            widgetOptions.theme
          } market-index-modal wts-modal-content--${
            zoom ? 'full' : 'xl'
          } w-75 h-75`}
          centered
          onHide={() => setExchangeModal(false)}
          style={{ zIndex: 11111 }}
        >
          <div className="d-flex flex-column h-100 w-100">
            <div className="market-index-modal__header">
              <span>
                {t('txt-market-index')}: {symbol}
              </span>
              <span className="d-flex align-items-center cursor-pointer gap-8">
                <span
                  className={`icon ${zoom ? 'iZoomIn' : 'iZoomOut'}`}
                  onClick={() => setZoom(!zoom)}
                ></span>

                <span
                  className="icon iClose"
                  onClick={() => setExchangeModal(false)}
                ></span>
              </span>
            </div>
            <div className="market-index-modal__body">
              {symbol ? (
                <iframe
                  className="chart"
                  title="exchangeChart"
                  src={`${TRADING_VIEW_CHART_URL}?${toQueryString({
                    symbol,
                    ...widgetOptions,
                  })}`}
                  scrolling="no"
                  allowFullScreen={true}
                />
              ) : (
                <p>loading...</p>
              )}
            </div>
          </div>
        </Modal>
      </>
    );
  }
);

export default ExchangeModal;
