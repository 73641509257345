import React, { useContext, useEffect, useState } from 'react';
import { ReactReduxContext, useDispatch, useSelector } from 'react-redux';
import { makeGetCashBalance } from '../../../../lib/selector';
import {
  formatDate,
  numberFormat,
  StringToInt,
  _formatDate2,
} from '../../../../util';
import { v4 as uuidv4 } from 'uuid';
import { withNamespaces } from 'react-i18next';
import { getBondCommonRequest, setBondOrder } from '../../action';
import { cashBalanceRequest } from '../../../privData/actions';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import TextMask from 'react-text-mask';
import PerfectScrollbar from 'react-perfect-scrollbar';
import RadioButton from '../../../../components/radioButton';
import { constants } from '../../../../util/constant';
import { OverlayTrigger, Tooltip, Popover } from 'react-bootstrap';

const SellOrder = withNamespaces('translations')(
  ({ t, closeHandle, defaultDeal = null }) => {
    const dispatch = useDispatch();
    const { pfBondOrder, planData } = useSelector((state) => state.pinefolio);
    const { token } = useSelector((state) => state.client);
    const { bondProductDetail, bondDetailSubAccount } = useSelector(
      (state) => state.bond
    );
    const { defaultAccount, listAccount } = useSelector(
      (state) => state.socket
    );
    const cashBalance = useSelector(makeGetCashBalance());
    const bondAccounts = token?.custInfo.normal.filter(
      (x) => x.isBondOTCTrade == 'Y'
    );

    const [account, setAccount] = useState(null);
    const [quantity, setQuantity] = useState(0);
    const [error, setError] = useState('');
    const [preOrder, setPreOrder] = useState();
    const [deals, setDeals] = useState([]);
    const [maxDealQuantity, setMaxDealQuantity] = useState('');
    const [choosenDeal, setChoosenDeal] = useState(null);
    const [isAuto, setAuto] = useState(true);
    const tradeDate = formatDate(new Date(), '');

    // Pinefolio
    const [hideManualOpt, setHideManual] = useState(false);
    const [hideAutoManual, setAutoManualHide] = useState(false);
    const [disableQuantityIpt, setDisableQuantity] = useState(false);

    const getBondPreOrderHandle = (_quantity = 0) => {
      if (account && bondProductDetail?.productCode) {
        const uuid = uuidv4();
        let params = {
          group: 'CORE',
          cmd: 'getBondPreOrder',
          rqId: uuid,
          channel: 'WTS',
          user: token?.user,
          session: token?.session,
          data: {
            cif: token?.cif,
            acntNo: account?.acntNo,
            subAcntNo: account?.subAcntNo,
            prdCode: bondProductDetail.productCode,
            quantity: StringToInt(_quantity) + '',
            tranDt: tradeDate,
            side: '1',
            planId: pfBondOrder?.planId,
          },
        };

        if (
          bondProductDetail.productTp == constants.bondProductType.Fix &&
          choosenDeal
        )
          params.data.dealId = choosenDeal.dealId;

        const callback = (data) => {
          setPreOrder(data);
        };

        dispatch(getBondCommonRequest(params, callback, true));
      }
    };

    const getDealsHandle = (quantity = 0) => {
      if (account && bondProductDetail) {
        const uuid = uuidv4();
        const params = {
          group: 'CORE',
          cmd: 'getDeals4Sell',
          rqId: uuid,
          channel: 'WTS',
          user: token?.user,
          session: token?.session,
          data: {
            cif: token?.cif,
            acntNo: account?.acntNo,
            subAcntNo: account?.subAcntNo,
            prdCode: bondProductDetail.productCode,
            quantity: StringToInt(quantity) + '',
            planId: pfBondOrder?.planId,
          },
        };

        const callback = (data) => {
          let _deals = [];
          if (data && data.length > 0) {
            if (bondProductDetail.productTp == constants.bondProductType.Fix) {
              // focus only one for bond fix
              if (defaultDeal) {
                const _deal = data.find((x) => x.dealId == defaultDeal.dealId);
                _deals = [_deal];
              }
            } else _deals = data;

            setDeals(_deals);

            if (!isAuto) {
              let max = 0;
              max = Math.max(..._deals.map((x) => x.availQty));
              setMaxDealQuantity(max);
            }
          }
        };

        dispatch(getBondCommonRequest(params, callback, true));
      }
    };

    const loadCashBalanceHandle = () => {
      if (token && account) {
        const uuid = uuidv4();

        const resData = {
          group: 'CORE',
          user: token.user,
          session: token.session,
          cmd: 'getTotalAssetAll',
          rqId: uuid,
          channel: 'WTS',
          data: {
            acntNo: account?.acntNo,
            subAcntNo: account?.subAcntNo,
          },
        };
        dispatch(cashBalanceRequest(resData));
      }
    };

    const orderHandle = () => {
      if (!error && StringToInt(quantity) > 0) {
        const newOrder = {
          quantity: StringToInt(quantity),
          side: '1', // sell: 1 | buy: 2
          dealId:
            bondProductDetail.productTp == constants.bondProductType.Var &&
            isAuto
              ? null
              : choosenDeal?.dealId,
          account: account,
          pdCode: bondProductDetail.productCode,
          pfPlanId: pfBondOrder?.planId || planData?.planId,
          productTp: bondProductDetail.productTp, // FIX or VAR

          // pfBondOrder?.pfPlanBreakYN == 'Y'
          //   ? null
          //   : pfBondOrder?.planId || planData?.planId, //Plan Id for pinefolio
        };

        dispatch(setBondOrder(newOrder));
        // dispatch(setBondDetailModalRequest(4));
      } else {
        return;
      }
    };

    useEffect(() => {
      if (!isAuto) {
        getBondPreOrderHandle(quantity);
        getDealsHandle(0);
      } else {
        getBondPreOrderHandle(quantity);
        loadCashBalanceHandle();
        getDealsHandle(quantity);
      }

      // setChoosenDeal(null);
    }, [isAuto, account]);

    useEffect(() => {
      if (defaultDeal) {
        setChoosenDeal({
          dealId: defaultDeal.dealId,
          dueDate: defaultDeal.dueDate,
          availQty: defaultDeal.availQty,
          allocatedQty: quantity,
          totalValue: bondProductDetail?.leg2Prc * quantity,
        });
      }
    }, [defaultDeal]);

    useEffect(() => {
      if (pfBondOrder) {
        const { quantity, hideManual, disableQuantity, callback } = pfBondOrder;
        if (quantity) {
          setQuantity(quantity);
        }

        if (hideManual) {
          setHideManual(hideManual);
          setAuto(true);
        }

        if (disableQuantity) {
          setDisableQuantity(disableQuantity);
        }

        getBondPreOrderHandle(quantity || 0);
        getDealsHandle(quantity || 0);
      }
    }, [pfBondOrder]);

    useEffect(() => {
      if (choosenDeal) {
        const _value = StringToInt(quantity);
        if (_value > maxDealQuantity || _value > choosenDeal?.availQty)
          setError('bond.maxSell.overDeal');
        else setError('');
      }
    }, [choosenDeal]);

    useEffect(() => {
      if (bondDetailSubAccount) {
        const _account = listAccount?.find(
          (x) => x.subAcntNo == bondDetailSubAccount
        );
        setAccount(_account);

        // remove select auto/manual
        if (bondProductDetail?.productTp == constants.bondProductType.Fix) {
          setAutoManualHide(true);
          setAuto(false);
        }
      } else {
        if (defaultAccount && defaultAccount.isBondOTCTrade == 'Y') {
          setAccount(defaultAccount);
        } else {
          if (bondAccounts && !!bondAccounts.length > 0) {
            setAccount(bondAccounts[0]);
          }
        }
      }
    }, [defaultAccount, bondAccounts, bondDetailSubAccount]);

    const disableHandle = () => {
      if (error || StringToInt(quantity) <= 0 || (!isAuto && !choosenDeal))
        return true;
      else return false;
    };

    const quantityOnChange = (e) => {
      setQuantity(e.target.value);
      const _value = StringToInt(e.target.value);
      if (_value <= preOrder.maxSelQty) {
        if (isAuto) {
          getDealsHandle(e.target.value);
          getBondPreOrderHandle(e.target.value);
          setError('');
        } else {
          getDealsHandle(e.target.value);
          getBondPreOrderHandle(e.target.value);

          if (_value > maxDealQuantity || _value > choosenDeal?.availQty)
            setError('bond.maxSell.overDeal');
          else {
            setError('');
          }
        }
      } else {
        setError('bond.maxSell.overError');
      }
    };

    const getDealTotal = (deal) => {
      if (bondProductDetail.productTp == constants.bondProductType.Fix) {
        return numberFormat(preOrder.sellAmt, 0, '0');
      } else {
        if (isAuto) {
          return numberFormat(
            bondProductDetail.leg2Prc * deal.allocatedQty,
            0,
            '0'
          );
        }

        if (!isAuto && deal.dealId == choosenDeal?.dealId) {
          return numberFormat(bondProductDetail.leg2Prc * quantity, 0, '0');
        }

        return '';
      }
    };

    if (!preOrder) return <span>Loading...</span>;

    return (
      <>
        <div className="bond-detail-order bond-detail-panel">
          <div className="bond-detail-header">
            <span className="bond-detail__title">{t('bond.order.infor')}</span>
          </div>

          <div className="bond-detail-body">
            <div className="bond-detail-order__infor">
              <div className="order-infors">
                <div className="order-infor">
                  <div className="order-infor__title">
                    {t('bond.customerAccountNo')}
                  </div>
                  <div className="order-infor__desc">{account?.subAcntNo}</div>
                </div>
                <div className="order-infor">
                  <div className="order-infor__title">
                    {t('bond.tradeDate')}
                  </div>
                  <div className="order-infor__desc">
                    {_formatDate2(tradeDate)}
                  </div>
                </div>
                <div className="order-infor">
                  <div className="order-infor__title">
                    {t('txt-label-settlement-date')}
                  </div>
                  <div className="order-infor__desc">
                    {_formatDate2(preOrder.setlDate)}
                  </div>
                </div>
                <div className="order-infor">
                  <div className="order-infor__title">
                    {bondProductDetail.productTp !=
                    constants.bondProductType.Fix
                      ? t('txt-stt-due-date')
                      : t('bond.dealList.label.dueDate')}
                  </div>
                  <div className="order-infor__desc">
                    {_formatDate2(preOrder.dueDate)}
                  </div>
                </div>
                <div className="order-infor">
                  <div className="order-infor__title">
                    {t('bond.tableLabel.order')}
                  </div>
                  <div className="order-infor__desc d">{t('txt-sell')}</div>
                </div>
                <div className="order-infor">
                  <div
                    className="order-infor__title"
                    style={{ marginRight: '-8px' }}
                  >
                    {t('bond.tableLabel.quantity')}
                  </div>
                  <div className="order-infor__desc position-relative">
                    <TextMask
                      type="text"
                      className={`${
                        error ? 'input-error' : ''
                      } input-text-search`}
                      maxLength={15}
                      value={quantity}
                      placeholder={t('bond.bondQuantity.placeHolder')}
                      mask={createNumberMask({
                        prefix: '',
                        allowNegative: false,
                      })}
                      onFocus={(e) => e.currentTarget.select()}
                      onChange={(e) => {
                        if (!disableQuantityIpt) quantityOnChange(e);
                      }}
                      disabled={disableQuantityIpt}
                    />
                    {error && (
                      <span className="text-error mt-2">{t(error)}</span>
                    )}
                  </div>
                </div>
                {!hideAutoManual && (
                  <div className="order-infor">
                    <div className="order-infor__title">
                      {t('bond.allocationQuantity')}
                    </div>
                    <div className="order-infor__desc allocation-group">
                      <div
                        className="allocation-radio"
                        onClick={() => setAuto(true)}
                      >
                        <RadioButton isReadonly isActive={isAuto} />
                        <label>{t('bond.auto')}</label>
                      </div>

                      {!hideManualOpt && (
                        <div
                          className="allocation-radio"
                          onClick={() => setAuto(false)}
                        >
                          <RadioButton isReadonly isActive={!isAuto} />
                          <label>{t('bond.manual')}</label>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                <div className="order-infor">
                  <div className="order-infor__title">
                    {t('bond.totalValue.sell')}
                  </div>
                  <div className="order-infor__desc">
                    {numberFormat(preOrder.sellAmt, 0, '0')}
                  </div>
                </div>

                {bondProductDetail?.productTp ==
                  constants.bondProductType.Fix && (
                  <>
                    <div className="order-infor border-top-dashed">
                      <div className="order-infor__title d-flex align-items-center tool-tip">
                        {t('bond.fix.rate')}
                        <ToolTips msg={t('bond.fix.rateNote')} />
                      </div>
                      <div className="order-infor__desc">
                        {numberFormat(preOrder?.intRt, 2, '0')}%
                      </div>
                    </div>
                    <div className="order-infor">
                      <div className="order-infor__title d-flex align-items-center tool-tip">
                        {t('bond.fix.penaltyRate')}
                        <ToolTips msg={t('bond.fix.penaltyRateNote')} />
                      </div>
                      <div className="order-infor__desc">
                        {numberFormat(preOrder?.peltyRt, 2, '0')}%
                      </div>
                    </div>
                    <div className="order-infor">
                      <div className="order-infor__title">
                        {t('bond.fix.promotionByNav')}
                      </div>
                      <div className="order-infor__desc">
                        {numberFormat(preOrder?.proRt, 2, '0')}%
                      </div>
                    </div>
                    <div
                      className={`order-infor ${
                        bondProductDetail.productTp !=
                        constants.bondProductType.Fix
                          ? 'border-bottom-dashed'
                          : ''
                      }`}
                    >
                      <div className="order-infor__title d-flex align-items-center tool-tip">
                        {t('bond.fix.rateBeforeTaxFee')}
                        <ToolTips msg={t('bond.fix.rateBeforeTaxFeeNote')} />
                      </div>
                      <div className="order-infor__desc r fw-500">
                        {numberFormat(preOrder?.realRt, 2, '0')}%
                      </div>
                    </div>
                    {bondProductDetail.productTp ==
                      constants.bondProductType.Fix && (
                      <div className="order-infor border-bottom-dashed">
                        <div className="order-infor__title">
                          {t('bond.fix.rateExtension')}
                        </div>
                        <div className="order-infor__desc">
                          {numberFormat(preOrder?.extRt, 2, '0')}%
                        </div>
                      </div>
                    )}
                  </>
                )}
                <div className="order-infor">
                  <div className="order-infor__title">{t('bond.fee')}</div>
                  <div className="order-infor__desc">
                    {numberFormat(preOrder.sellFee, 0, '0')}
                  </div>
                </div>
                <div className="order-infor">
                  <div className="order-infor__title">
                    {t('bond.tableLabel.tax')}
                  </div>
                  <div className="order-infor__desc">
                    {numberFormat(preOrder.tax, 0, '0')}
                  </div>
                </div>
                <div className="order-infor">
                  <div className="order-infor__title">
                    {t('bond.totalPayment')}
                  </div>
                  <div className="order-infor__desc text-big r">
                    {numberFormat(preOrder.ttlSellValue, 0, '0')}
                  </div>
                </div>
              </div>
            </div>

            <div className="bond-detail-order__orderPlace">
              <div className="asset-avaiable">
                <span>
                  <span className="text--light3 mx-3">{t('txt-pp')}</span>
                  <span className="text--light fw-500">
                    {cashBalance && numberFormat(cashBalance.pp0, 0, '0')}
                  </span>
                </span>
                <span>
                  <span className="text--light3">{t('bond.maxSell.name')}</span>
                  <span className="mx-3 text--light fw-500">
                    {preOrder.maxSelQty} {t('bond.name')}
                  </span>
                </span>
              </div>
              <div className="order-actions">
                <button
                  className={`btn btn--sell ${
                    disableHandle() ? 'disabled' : ''
                  }`}
                  onClick={() => orderHandle()}
                  disabled={disableHandle()}
                >
                  {t('txt-order')}
                </button>

                <button
                  className="btn btn--cancel"
                  onClick={() => closeHandle()}
                >
                  {t('txt-cancel')}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="bond-detail-panel">
          <div className="bond-detail-header">
            <span className="bond-detail__title">
              {t('bond.order.dealForSale')}
            </span>
            <span className="modal-close" onClick={() => closeHandle()}>
              <span className="icon iClose"></span>
            </span>
          </div>

          <div className="bond-detail-body">
            <div className="deal-for-sale">
              <PerfectScrollbar style={{ height: '100%' }}>
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th className="text-center w-32p"></th>
                      <th className="text-center position-relative filterIcon fw-400 text--light3">
                        {t('bond.tableLabel.dealID')}
                      </th>
                      <th className="text-center position-relative filterIcon fw-400 text--light3">
                        {t('bond.tableLabel.tradeDate')}
                      </th>
                      <th className="text-center position-relative filterIcon fw-400 text--light3">
                        {t('bond.order.remainQuantityToSell')}
                      </th>
                      <th className="text-center position-relative filterIcon fw-400 text--light3">
                        {t('bond.order.allocateQuantity')}
                      </th>
                      <th className="text-center position-relative filterIcon fw-400 text--light3">
                        {t('bond.tableLabel.totalValue.name')}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {!!deals &&
                      deals.map((deal, i) => (
                        <tr key={'deals-' + i}>
                          <td
                            className={`text-center ${
                              !isAuto ? 'cursor-pointer' : ''
                            } w-32p`}
                          >
                            {!isAuto && deal.availQty >= quantity && (
                              <RadioButton
                                isReadonly={!isAuto}
                                isActive={choosenDeal?.dealId == deal.dealId}
                                onClick={() =>
                                  setChoosenDeal({
                                    dealId: deal.dealId,
                                    dueDate: deal.dueDate,
                                    availQty: deal.availQty,
                                    allocatedQty: quantity,
                                    totalValue:
                                      bondProductDetail.leg2Prc * quantity,
                                  })
                                }
                              />
                            )}
                            {isAuto && (
                              <RadioButton
                                isReadonly={!isAuto}
                                isActive={isAuto && deal.allocatedQty > 0}
                              />
                            )}
                          </td>
                          <td className="text-center text--light3">
                            {deal.dealId}
                          </td>
                          <td className="text-center text--light">
                            {_formatDate2(deal.tradeDate)}
                          </td>
                          <td className="text-center text--light">
                            {numberFormat(deal.availQty, 0, '0')}
                          </td>
                          <td className="text-center text--light">
                            {isAuto && numberFormat(deal.allocatedQty, 0, '0')}
                            {!isAuto &&
                              choosenDeal &&
                              deal.dealId == choosenDeal.dealId &&
                              numberFormat(quantity, 0, '0')}
                          </td>
                          <td className="text-center text--light fw-500">
                            {getDealTotal(deal)}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                <div style={{ height: '10px' }}></div>
              </PerfectScrollbar>
              <div className="d-flex">
                {bondProductDetail?.productTp !=
                  constants.bondProductType.Fix && (
                  <span className="r fz_12 fw-100 text-justify font-italic">{`${t(
                    'txt-note'
                  )}: ${t('bond.contract.note')}`}</span>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
);

const ToolTips = ({ msg }) => {
  const [open, setOpen] = useState(false);
  return (
    <OverlayTrigger
      trigger={['hover', 'focus']}
      onToggle={() => setOpen(!open)}
      show={open}
      rootClose
      overlay={
        <Popover>
          <Popover.Content className="d-flex">
            <span>{msg}</span>
          </Popover.Content>
        </Popover>
      }
    >
      <span className="icon iWarming ml-1 mr-1"></span>
    </OverlayTrigger>
  );
};

export default SellOrder;
