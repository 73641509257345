import {
  LOGIN_REQUESTING,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOG_OUT,
  RESET_LOGIN,
} from './constants';

const initialState = {
  token: null,
  requesting: false,
  successful: false,
  messages: [],
  errors: [],
};

const reducer = function loginReducer(state = initialState, action) {
  // console.log(action)
  switch (action.type) {
    // Set the requesting flag and append a message to be shown
    case 'INVALID_SESSION':
    // case 'CLIENT_UNSET':
    case LOG_OUT:
      return initialState;
    case RESET_LOGIN:
      return {
        errors: [],
        messages: [],
        requesting: false,
        successful: false,
      };
    case LOGIN_REQUESTING:
      return {
        token: null,
        requesting: true,
        successful: false,
        messages: [{ body: 'Logging in...', time: new Date() }],
        errors: [],
      };

    // Successful?  Reset the login state.
    case LOGIN_SUCCESS:
      return {
        token: action.token,
        errors: [],
        messages: [],
        requesting: false,
        successful: true,
      };

    // Append the error returned from our api
    // set the success and requesting flags to false
    case LOGIN_ERROR:
      return {
        token: null,
        errors: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
        messages: [],
        requesting: false,
        successful: false,
      };

    default:
      return state;
  }
};

export default reducer;
