import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { withNamespaces } from 'react-i18next';
import { ReactReduxContext, useDispatch, useSelector } from 'react-redux';
import ReactSelect from 'react-select';
import ProductInfo from '../productInfo';
import { OverlayTrigger, Popover, Button, Tooltip } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import {
  Logout,
  logoutRequest,
} from '../../../../components/modal/login/actions';
import { setToast, unsetClient } from '../../../client/actions';
import { removeCookie } from '../../../../lib/storages';
import {
  getMessageError,
  numberFormat,
  StringToDouble,
  StringToInt,
  _processMapDataCS,
} from '../../../../util';
import { handleApiErrors } from '../../../../lib/api-errors';
import { useContext } from 'react';
import { makeGetAllStock } from '../../../../lib/selector';
import { filter, find } from 'lodash';
import { createNumberMask } from 'text-mask-addons';
import TextMask from 'react-text-mask';
import FormConfirmOrder from '../../../../components/modal/order/confirmOrder';
import { newOrderRequest } from '../../../privData/actions';
import Switch from 'react-switch';
import { sellSymbolPFOdd } from '../../action';

// const symbols = [
//   { name: 'ACB', value: 'ACB' },
//   { name: 'MBB', value: 'MBB' },
//   { name: 'HPG', value: 'HPG' },
//   { name: 'TAR', value: 'TAR' },
// ];
const appUrl = `${process.env.REACT_APP_API_URL}`;

const SellModal = withNamespaces('translations')(({ t, show, closeHandle }) => {
  const [isOdd, setIsOdd] = useState(false);
  const [isOddlot, setOddlot] = useState(false); // for price
  const handleCloseModal = () => {
    closeHandle();
  };
  const { token, auth } = useSelector((state) => state.client);
  const theme = useSelector((state) => state.client?.currentTheme);
  const { currentPinefolio } = useSelector((state) => state.pinefolio);

  if (!currentPinefolio) return <></>;
  return (
    <div>
      <Modal
        show={show}
        onHide={handleCloseModal}
        onEscapeKeyDown={handleCloseModal}
        dialogClassName="wts-modal"
        backdropClassName="wts-modal-backdrop"
        contentClassName={`${
          theme.name || 'dark'
        } wts-modal-content wts-modal-content--sm2`}
        centered
        style={{ zIndex: 11112 }}
      >
        <div className="wts-modal2 sellPortfolio-modal">
          <div className="wts-modal2__header">
            <span className="btn-icon btn--transparent"></span>
            <span className="text--light text--md">{`${t('txt-sell')} ${
              currentPinefolio?.pfThemeNm
            }`}</span>
            <span
              className="btn-icon btn--transparent"
              onClick={() => handleCloseModal()}
            >
              <span className="icon iClose"></span>
            </span>
          </div>
          <div className="wts-modal2__body sellPortfolio-modal__body text--sm">
            <div className="order-type">
              <div className="order-type-switches">
                <span
                  class={`order-type-switch d-flex justify-content-center align-items-center ${
                    !isOdd ? 'active' : ''
                  }`}
                  onClick={() => setIsOdd(false)}
                >
                  <span>{t('txt-set')}</span>
                  <OverlayTrigger
                    trigger={['hover', 'focus']}
                    placement="top"
                    className="portfolio-overlay"
                    overlay={
                      <Tooltip style={{ 'z-index': '99999' }}>
                        {t('pinefolio.portfolio.setExplain')}
                      </Tooltip>
                    }
                  >
                    <span className="icon iInfor iTooltip ml-1 mr-1"></span>
                  </OverlayTrigger>
                </span>
                <span
                  class={`order-type-switch d-flex justify-content-center align-items-center ${
                    isOdd ? 'active' : ''
                  }`}
                  onClick={() => setIsOdd(true)}
                >
                  <span>{t('pinefolio.portfolio.odd')}</span>
                  <OverlayTrigger
                    trigger={['hover', 'focus']}
                    placement="top"
                    className="portfolio-overlay"
                    overlay={
                      <Tooltip style={{ 'z-index': '99999' }}>
                        {t('pinefolio.portfolio.oddExplain')}
                      </Tooltip>
                    }
                  >
                    <span className="icon iInfor iTooltip ml-1 mr-1"></span>
                  </OverlayTrigger>
                </span>
              </div>
              {isOdd && (
                <div className="order-odd-price">
                  <Switch
                    onChange={() => setOddlot(!isOddlot)}
                    checked={isOddlot}
                    className="card-panel-header__switch mr-2"
                    uncheckedIcon={false}
                    checkedIcon={false}
                    height={18}
                    width={28}
                    onColor={`${theme?.name == 'dark' ? '#00ff57' : '#2db35b'}`}
                    // offColor="#1a1d1f"
                  />
                  <span className="desc ml-1">
                    {t('orderPanel.displayOddlot')}
                  </span>
                </div>
              )}
            </div>

            {isOdd && <SellOdd close={handleCloseModal} isOddlot={isOddlot} />}
            {!isOdd && <ProductInfo orderType={2} close={handleCloseModal} />}
          </div>
        </div>
      </Modal>
    </div>
  );
});

const SellOdd = withNamespaces('translations')(({ t, close, isOddlot }) => {
  const dispatch = useDispatch();
  const [current, setCurrent] = useState();
  const [symbols, setSymbols] = useState();
  const [stockDetail, setStockDetail] = useState();
  const [stockInfor, setStockInfor] = useState();
  const [open, setOpen] = useState(false);
  const [orderPrice, setPrice] = useState();
  const [orderQuantity, setOrderQuantity] = useState();
  const [orderType, setOrderType] = useState('01');
  // const [isOddlot, setIsOddlot] = useState(false);
  const [preOrder, setPreOrder] = useState();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [order, setOrder] = useState(null);

  const allStock = useSelector(makeGetAllStock());
  const { defaultAccount, listAccount } = useSelector((state) => state.socket);
  const { store } = useContext(ReactReduxContext);
  const { token, auth } = useSelector((state) => state.client);
  const { currentPinefolio } = useSelector((state) => state.pinefolio);
  const theme = useSelector((state) => state.client?.currentTheme);

  useEffect(() => {
    getPfOddSellable();
  }, []);

  useEffect(() => {
    // get symbol infor
    if (current) {
      stockDetailRequestApi(current.symbol, isOddlot);
      getStockInfor(current.symbol);
    }
    // todo: regist socket
  }, [current, isOddlot]);

  useEffect(() => {
    if (orderPrice && orderQuantity && current?.symbol) {
      getPreOrder(current.symbol, orderType, orderQuantity, orderPrice);
    }
  }, [orderPrice, orderQuantity, current]);

  const toggle = () => {
    setOpen(!open);
  };

  const cancelHandle = () => {
    if (close && typeof close == 'function') {
      close();
    }
  };

  const toastHandle = (msg) => {
    const toastMsg = {
      id: Math.random(),
      msg: msg,
      title: t('txt-notice'),
    };
    dispatch(setToast(toastMsg));
  };

  const apiExcute = (params, successCallback) => {
    const url = `${appUrl}/PortfolioServlet.pt`;
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
      body: JSON.stringify(params),
    })
      .then(handleApiErrors)
      .then((response) => response.json())
      .then((res) => {
        if (res.http !== 200) {
          if (res.http === 401) {
            if (token) dispatch(logoutRequest(token));
            dispatch(unsetClient());
            removeCookie('token');
            removeCookie('authen');
            dispatch(Logout());
            toastHandle(t('txt-valid-loss-session'));
          } else {
            toastHandle(getMessageError(res, store.getState));
          }
        } else {
          // success
          if (successCallback && typeof successCallback == 'function') {
            successCallback(res);
          }
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const getPfOddSellable = () => {
    if (!token || !currentPinefolio) return;
    const uuid = uuidv4();
    const params = {
      group: 'CORE',
      session: token.session,
      user: token.user,
      cmd: 'getPfOddSellable',
      rqId: uuid,
      channel: 'WTS',
      data: {
        cif: token.cif,
        acntNo: defaultAccount?.acntNo,
        subAcntNo: defaultAccount?.subAcntNo,
        pfCode: currentPinefolio.pfCode,
      },
    };

    const callback = (res) => {
      const data = res.data;
      setSymbols(data);

      console.log('data  ====> ', data);

      if (data && !!data.length > 0) {
        const _symbol = current?.symbol;
        if (_symbol) {
          const _data = data.find((x) => x.symbol == _symbol);
          setCurrent(_data);
        } else {
          setCurrent(data[0]);
        }
      }
    };

    apiExcute(params, callback);
  };

  const getPreOrder = (symbol, ordType, quantt, price) => {
    if (!token || !currentPinefolio) return;

    if (symbol && quantt && price) {
      const uuid = uuidv4();
      const params = {
        group: 'CORE',
        session: token.session,
        user: token.user,
        cmd: 'GetPFPreOrder',
        rqId: uuid,
        channel: 'WTS',
        data: {
          acntNo: defaultAccount?.acntNo,
          subAcntNo: defaultAccount?.subAcntNo,
          symbol: symbol,
          ordrQty: StringToInt(quantt) + '',
          ordrUntprc:
            ordType == '01'
              ? (StringToDouble(price) * 1000).toFixed(0) + ''
              : '0',
          ordrTrdTp: ordType,
          buySelTp: '2',
          oddOrdrYn: isOddlot ? 'Y' : 'N',
        },
      };

      const callback = (res) => {
        const data = res.data;
        setPreOrder(data);
      };

      apiExcute(params, callback);
    }
  };

  const stockDetailRequestApi = (symbol, isOddlot = false) => {
    if (!symbol) return;
    const url = `${appUrl}/getliststockdata/${symbol.toUpperCase()}?board=${
      !isOddlot ? 'G1' : 'G4'
    }`;
    fetch(url)
      .then((response) => response.json())
      .then((json) => {
        const stock = _processMapDataCS(json[0]);
        stock.json = json[0];
        console.log('stock ===> ', stock);
        setStockDetail(stock);
      })
      .catch((error) => {
        throw error;
      });
  };

  const getStockInfor = (symbol) => {
    const sym = find(allStock, (o) => o.stock_code === symbol);
    setStockInfor(sym);
  };

  const tabHandle = (e) => {
    var code = e.keyCode ? e.keyCode : e.which;
    if (code == 9 || e.key == 'Tab') {
      setOpen(false);
    }
  };

  const disableHandle = () => {
    if (!orderQuantity || !orderPrice) {
      return true;
    }

    return false;
  };

  const preOrderHandle = () => {
    if (StringToInt(orderQuantity + '') > current?.sellAvlQty) {
      toastHandle(t('bond.maxSell.overError'));
      return;
    }
    const uuid = uuidv4();
    const params = {
      group: 'CORE',
      user: token.user,
      session: token.session,
      cmd: 'sellPFOddOrdr',
      rqId: uuid,
      channel: 'WTS',
      type: auth.type,
      token: auth.token,
      data: {
        acntNo: defaultAccount?.acntNo,
        subAcntNo: defaultAccount?.subAcntNo,
        symbol: current?.symbol,
        ordrQty: StringToInt(orderQuantity) + '',
        ordrUntprc:
          orderType === '01'
            ? (StringToDouble(orderPrice) * 1000).toFixed(0) + ''
            : '0',
        ordrTrdTp: orderType,
        oddOrdrYn: isOddlot ? 'Y' : 'N',
        pfCode: currentPinefolio?.pfCode,
      },
    };

    setOrder(params);
    setShowConfirmModal(true);
  };

  const orderHandle = (params) => {
    dispatch(sellSymbolPFOdd(params, () => getPfOddSellable()));
    setOrder(null);
    setShowConfirmModal(false);
  };

  const totalBV =
    stockDetail &&
    StringToInt(stockDetail.bV1) +
      StringToInt(stockDetail.bV2) +
      StringToInt(stockDetail.bV3);
  const totalOV =
    stockDetail &&
    StringToInt(stockDetail.oV1) +
      StringToInt(stockDetail.oV2) +
      StringToInt(stockDetail.oV3);
  const total = stockDetail && totalBV + totalOV;
  const perTotalBV = stockDetail && ~~((totalBV * 100) / total).toFixed(2);
  const perTotalOV = stockDetail && 100 - perTotalBV;

  const perBV1 =
    stockDetail && ~~((StringToInt(stockDetail.bV1) * 100) / totalBV);
  const perBV2 =
    stockDetail && ~~((StringToInt(stockDetail.bV2) * 100) / totalBV);
  const perBV3 =
    stockDetail && ~~((StringToInt(stockDetail.bV3) * 100) / totalBV);

  const perOV1 =
    stockDetail && ~~((StringToInt(stockDetail.oV1) * 100) / totalOV);
  const perOV2 =
    stockDetail && ~~((StringToInt(stockDetail.oV2) * 100) / totalOV);
  const perOV3 =
    stockDetail && ~~((StringToInt(stockDetail.oV3) * 100) / totalOV);

  if (!symbols || !!symbols.length <= 0)
    return (
      <div className="odd-order">
        <div className="odd-order__empty">
          <div className="odd-order__icon">
            <span className="icon iEmpty"></span>
          </div>

          <p className="text--sm text--light3">{t('pinefolio.noOdd')}</p>
        </div>
      </div>
    );
  else
    return (
      <>
        <div className="odd-order">
          <div className="symbol-section">
            <div className="symbol-information">
              <span className="text--lg">{current?.symbol}</span>
              <span className="text--sm text--gray">
                {stockInfor &&
                  (stockInfor.post_to === 'STO'
                    ? 'HOSE'
                    : stockInfor.post_to === 'STX'
                    ? 'HNX'
                    : stockInfor.post_to === 'UPX'
                    ? 'UPCOM'
                    : stockInfor.post_to)}
              </span>
            </div>
            <div className="symbol-price">
              <div className="symbol-price__current f">
                <span
                  title={t('lbl-double-click-to-order')}
                  id={`pf_${current?.symbol}_lastPrice`}
                  className="cursor-pointer text--lg"
                  onDoubleClick={(e) => setPrice(e.target.innerHTML)}
                >
                  {numberFormat(stockDetail?.lastPrice, 2, '0')}
                </span>
                <span className="text--sm" id={`pf_${current?.symbol}_change`}>
                  {numberFormat(stockDetail?.change, 2, '0')}
                </span>
                <span
                  className="text--sm"
                  id={`pf_${current?.symbol}_changePc`}
                >
                  {numberFormat(stockDetail?.changePc, 2, '0')}%
                </span>
              </div>
              <div className="symbol-price__open text--sm">
                <div className="d-flex align-items-center gap-4">
                  <span className="icon iCeil"></span>
                  <span
                    className="cursor-pointer c"
                    onDoubleClick={(e) => setPrice(e.target.innerHTML)}
                    title={t('lbl-double-click-to-order')}
                  >
                    {numberFormat(stockDetail?.c, 2)}
                  </span>
                </div>
                <div className="d-flex align-items-center gap-4">
                  <span className="icon iRef"></span>
                  <span
                    className="cursor-pointer r"
                    onDoubleClick={(e) => setPrice(e.target.innerHTML)}
                    title={t('lbl-double-click-to-order')}
                  >
                    {numberFormat(stockDetail?.r, 2)}
                  </span>
                </div>
                <div className="d-flex align-items-center gap-4">
                  <span className="icon iFloor"></span>
                  <span
                    className="cursor-pointer f"
                    onDoubleClick={(e) => setPrice(e.target.innerHTML)}
                    title={t('lbl-double-click-to-order')}
                  >
                    {numberFormat(stockDetail?.f, 2)}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="price-section symbol-price-list">
            <div className="d-flex price-list-step">
              <div className="price-list-step__buy">
                <span
                  id={`pf_${current?.symbol}_bV3`}
                  className="text--light1 text--sm"
                >
                  {numberFormat(stockDetail?.bV3, 0, '0')}
                </span>
                <span
                  id={`pf_${current?.symbol}_bP3`}
                  title={t('lbl-double-click-to-order')}
                  className={`cursor-pointer price ${stockDetail?.bCl3}`}
                  onDoubleClick={(e) => {
                    const price = e.target.innerHTML;
                    setPrice(
                      price
                        ? numberFormat(price, 2)
                        : numberFormat(stockDetail?.bP3, 2)
                    );
                  }}
                >
                  {numberFormat(stockDetail?.bP3, 2, '0')}
                </span>
                <div
                  id={`pf_${current?.symbol}_perBV3`}
                  className="value i"
                  style={{ width: `${perBV3}%` }}
                ></div>
              </div>
              <div className="price-list-step__sell">
                <div className="i">
                  <span
                    id={`pf_${current?.symbol}_oP3`}
                    title={t('lbl-double-click-to-order')}
                    className={`cursor-pointer price ${stockDetail?.oCl3}`}
                    onDoubleClick={(e) => {
                      const price = e.target.innerHTML;
                      setPrice(
                        price
                          ? numberFormat(price, 2)
                          : numberFormat(stockDetail?.oP3, 2)
                      );
                    }}
                  >
                    {numberFormat(stockDetail?.oP3, 2, '0')}
                  </span>
                  <div
                    id={`pf_${current?.symbol}_perOV3`}
                    className="value"
                    style={{ width: `${perOV3}%` }}
                  ></div>
                </div>
                <span
                  className="text--light1 text--sm"
                  id={`pf_${current?.symbol}_oV3`}
                >
                  {numberFormat(stockDetail?.oV3, 0, '0')}
                </span>
              </div>
            </div>
            <div className="d-flex price-list-step">
              <div className="price-list-step__buy">
                <span
                  className="text--light1 text--sm"
                  id={`pf_${current?.symbol}_bV2`}
                >
                  {numberFormat(stockDetail?.bV2, 0, '0')}
                </span>
                <span
                  id={`pf_${current?.symbol}_bP2`}
                  title={t('lbl-double-click-to-order')}
                  className={`cursor-pointer price ${stockDetail?.bCl2}`}
                  onDoubleClick={(e) => {
                    const price = e.target.innerHTML;
                    setPrice(
                      price
                        ? numberFormat(price, 2)
                        : numberFormat(stockDetail?.bP2, 2)
                    );
                  }}
                >
                  {numberFormat(stockDetail?.bP2, 2, '0')}
                </span>
                <div
                  id={`pf_${current?.symbol}_perBV2`}
                  className="value i"
                  style={{ width: `${perBV2}%` }}
                ></div>
              </div>
              <div className="price-list-step__sell">
                <div className="d">
                  <span
                    title={t('lbl-double-click-to-order')}
                    id={`pf_${current?.symbol}_oP2`}
                    className={`cursor-pointer price ${stockDetail?.oCl2}`}
                    onDoubleClick={(e) => {
                      const price = e.target.innerHTML;
                      setPrice(
                        price
                          ? numberFormat(price, 2)
                          : numberFormat(stockDetail?.oP2, 2)
                      );
                    }}
                  >
                    {numberFormat(stockDetail?.oP2, 2, '0')}
                  </span>
                  <div
                    id={`pf_${current?.symbol}_perOV2`}
                    className="value"
                    style={{ width: `${perOV2}%` }}
                  ></div>
                </div>
                <span
                  id={`pf_${current?.symbol}_oV2`}
                  className="text--light1 text--sm"
                >
                  {numberFormat(stockDetail?.oV2, 0, '0')}
                </span>
              </div>
            </div>
            <div className="d-flex price-list-step">
              <div className="price-list-step__buy">
                <span
                  className="text--light1 text--sm"
                  id={`pf_${current?.symbol}_bV1`}
                >
                  {numberFormat(stockDetail?.bV1, 0, '0')}
                </span>
                <span
                  title={t('lbl-double-click-to-order')}
                  id={`pf_${current?.symbol}_bP1`}
                  className={`cursor-pointer price ${stockDetail?.bCl2}`}
                  onDoubleClick={(e) => {
                    const price = e.target.innerHTML;
                    setPrice(
                      price
                        ? numberFormat(price, 2)
                        : numberFormat(stockDetail?.bP1, 2)
                    );
                  }}
                >
                  {numberFormat(stockDetail?.bP1, 2, '0')}
                </span>
                <div
                  id={`pf_${current?.symbol}_perBV1`}
                  className="value i"
                  style={{ width: `${perBV1}%` }}
                ></div>
              </div>
              <div className="price-list-step__sell">
                <div className="d">
                  <span
                    title={t('lbl-double-click-to-order')}
                    id={`pf_${current?.symbol}_oP1`}
                    className={`cursor-pointer price ${stockDetail?.oCl1}`}
                    onDoubleClick={(e) => {
                      const price = e.target.innerHTML;
                      setPrice(
                        price
                          ? numberFormat(price, 2)
                          : numberFormat(stockDetail?.oP1, 2)
                      );
                    }}
                  >
                    {numberFormat(stockDetail?.oP1, 2, '0')}
                  </span>
                  <div
                    id={`pf_${current?.symbol}_perOV1`}
                    className="value"
                    style={{ width: `${perOV1}%` }}
                  ></div>
                </div>
                <span
                  className="text--light1 text--sm"
                  id={`pf_${current?.symbol}_oV1`}
                >
                  {numberFormat(stockDetail?.oV1, 0, '0')}
                </span>
              </div>
            </div>
            <div className="price-list-total">
              <span
                className="price-list-total__buy"
                id={`pf_${current?.symbol}_perTotalBV`}
                style={{ width: `${perTotalBV}%` }}
              ></span>
              <span
                id={`pf_${current?.symbol}_perTotalOV`}
                className="price-list-total__sell"
              ></span>
            </div>
          </div>

          <div className="info-section">
            <div className="enter-section">
              <div className="order-text w-25">
                {/* <input type="text" placeholder="Symbol" maxlength="12" value="" />
            <span className="icon iDown"></span> */}

                <ReactSelect
                  defaultValue={symbols[0]}
                  options={symbols}
                  className="filter-control-select"
                  classNamePrefix="filter-control-select"
                  getOptionLabel={(option) => option.symbol}
                  getOptionValue={(option) => option.symbol}
                  onChange={(value) => setCurrent(value)}
                  isSearchable={false}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>
              <OverlayTrigger
                trigger="click"
                placement="bottom-end"
                onToggle={toggle}
                show={open}
                rootClose
                className="price-overlay"
                overlay={
                  <Popover
                    style={{
                      display:
                        stockDetail && stockDetail?.mc == '03'
                          ? 'none'
                          : 'initial',
                    }}
                    className={`${
                      theme?.name || 'dark'
                    } price-overlay__popover`}
                  >
                    <Popover.Content className="d-flex">
                      <>
                        {stockDetail && stockDetail?.mc !== '02' && (
                          <>
                            {[
                              { id: '02', type: 'ATO' },
                              { id: '03', type: 'ATC' },
                              { id: '04', type: 'MTL' },
                            ].map((item, i) => (
                              <Button
                                key={i}
                                variant="info"
                                style={{ padding: '2px 5px' }}
                                onClick={() => {
                                  setPrice(item.type);
                                  setOrderType(item.id);
                                }}
                              >
                                {item.type}
                              </Button>
                            ))}
                          </>
                        )}
                        {stockDetail && stockDetail?.mc !== '10' && (
                          <>
                            {[
                              { id: '03', type: 'ATC' },
                              { id: '05', type: 'MTL' },
                              { id: '06', type: 'MOK' },
                              { id: '07', type: 'MAK' },
                              { id: '08', type: 'PLO' },
                            ].map((item, i) => (
                              <Button
                                key={i}
                                variant="info"
                                style={{ padding: '2px 5px' }}
                                onClick={() => {
                                  setPrice(item.type);
                                  setOrderType(item.id);
                                }}
                              >
                                {item.type}
                              </Button>
                            ))}
                          </>
                        )}
                      </>
                    </Popover.Content>
                  </Popover>
                }
              >
                <div className="order-text w-25">
                  <span className="icon iMoney"></span>
                  <input
                    className="text--light"
                    type="text"
                    maxlength="15"
                    placeholder="Price x1000"
                    value={orderPrice}
                    onChange={(e) => {
                      const re = /^[0-9a-zA-Z.]+$/;

                      if (
                        e.target.value &&
                        e.target.value.length > 0 &&
                        !re.test(e.target.value)
                      ) {
                        e.preventDefault();
                      } else {
                        setPrice(e.target.value);
                        setOrderType('01');
                      }
                    }}
                  />
                </div>
              </OverlayTrigger>

              <div className="order-text w-25">
                <span className="icon iVolume"></span>
                <TextMask
                  className="text--light"
                  type="text"
                  maxLength={15}
                  value={orderQuantity}
                  mask={createNumberMask({
                    prefix: '',
                    allowNegative: false,
                  })}
                  placeholder={t('formTrade.enterVolume')}
                  onFocus={(e) => e.currentTarget.select()}
                  onKeyDown={(e) => tabHandle(e)}
                  onChange={(e) => {
                    setOrderQuantity(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="info-rows">
              <div className="info-row">
                <span className="info-row__key text--sm text--light3">
                  {t('txt-giatri')}
                </span>
                {!(orderPrice && orderQuantity) && (
                  <span className="info-row__value text--sm text--light fw-500">
                    0
                  </span>
                )}
                {orderPrice && orderQuantity && (
                  <span className="info-row__value text--sm text--light fw-500">
                    {numberFormat(preOrder?.ordValue, 0, '0')}
                  </span>
                )}
              </div>
              <div className="info-row">
                <span className="info-row__key text--sm text--light3">
                  {t('txt-label-fee')}
                </span>
                <span className="info-row__value text--sm text--light fw-500">
                  {numberFormat(preOrder?.tradingFee, 0, '0')}
                </span>
              </div>
              <div className="info-row">
                <span className="info-row__key text--sm text--light3">
                  {t('txt-label-tax')}
                </span>
                <span className="info-row__value text--sm text--light fw-500">
                  {numberFormat(preOrder?.tax, 0, '0')}
                </span>
              </div>
              <div className="info-row">
                <span className="info-row__key text--sm text--light3">
                  {t('txt-total-payment')}
                </span>
                <span className="info-row__value text--md fw-500 r">
                  {numberFormat(preOrder?.ordrAmt, 0, '0')}
                </span>
              </div>
            </div>
          </div>

          <div className="order-summaries">
            <span className="order-summary">
              <span className="text--sm text--light3">
                {t('txt-sub-account')}
              </span>
              <span className="text--sm text--light">
                {defaultAccount.subAcntNo}
              </span>
            </span>
            <span className="order-summary">
              <span className="text--sm text--light3">{t('txt-sell-max')}</span>
              <span className="text--sm text--light">
                {numberFormat(current?.sellAvlQty, 0, '0')}
              </span>
            </span>
          </div>
        </div>
        <div className="order-actions">
          <button
            className={`btn btn--sell ${disableHandle() ? 'disabled' : ''} `}
            disabled={disableHandle()}
            onClick={() => preOrderHandle()}
          >
            {t('pinefolio.order.sell')}
          </button>
          <button className="btn btn--cancel" onClick={() => cancelHandle()}>
            {t('txt-cancel')}
          </button>
        </div>
        {showConfirmModal && (
          <FormConfirmOrder
            isOddlot={isOddlot}
            order={order}
            showModal={showConfirmModal}
            handleCloseModal={() => {
              setOrder(null);
              setShowConfirmModal(false);
            }}
            handleSubmitOrder={orderHandle}
          />
        )}
      </>
    );
});

export default SellModal;
