import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setAuth, setChangeBank } from '../../../client/actions';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';

import { withNamespaces } from 'react-i18next';
import swal from 'sweetalert2';
import { v4 as uuidv4 } from 'uuid';
import nologo from '../../../../assets/img/icons/utilities/nologo.svg';
import { removeBankRequest } from '../../../customer/actions';
import PerfectScrollbar from 'react-perfect-scrollbar';
import '../index.scss';
import { useState } from 'react';

const BankItem = withNamespaces('translations')(({ t }) => {
  const { defaultAccount } = useSelector((state) => state.socket);
  const { auth, token } = useSelector((state) => state.client);
  const bankAcc = useSelector((state) => state.customer.bankAcc);
  const dispatch = useDispatch();
  const { bankInfor } = useSelector((state) => state.cash);
  const [showModal, setShowModal] = useState(false);
  const [bankRemove, setBankRemove] = useState({});
  const theme = useSelector((state) => state.client.currentTheme);

  const handleDelete = () => {
    const { bankAcntNo, bankCd } = bankRemove;
    if (!auth || !auth.isVerified) {
      dispatch(setAuth());
    } else {
      {
        const uuid = uuidv4();
        if (token) {
          const params = {
            group: 'CORE',
            user: token.user,
            rqId: uuid,
            session: token.session,
            token: auth.token,
            type: auth.type,
            cmd: 'RegistBankAcnt',
            channel: 'WTS',
            data: {
              acntNo: defaultAccount?.acntNo,
              bankAcntNo: bankAcntNo,
              bankAcntStat: 9,
              bankCode: bankCd,
            },
          };
          dispatch(removeBankRequest(params));
          setShowModal(false);
        }
      }
    }
  };

  return (
    <div>
      <Modal
        onHide={() => setShowModal(false)}
        onEscapeKeyDown={() => setShowModal(false)}
        show={showModal}
        dialogClassName="wts-modal"
        backdropClassName="wts-modal-backdrop changeBank-modal-backdrop"
        contentClassName={`${
          theme?.name || 'dark'
        }  wts-modal-content wts-modal-content--sm`}
        centered
        style={{ zIndex: 11114 }}
      >
        <div className="confirm-order">
          <div className="confirm-order-header text-center text text--light fz_14 fw-500">
            {t('txt-title-remove-bank-account')}
          </div>
          <div className="confirm-order-body">
            <span className="d-flex confirm-icon mb-3">
              <span className="icon iWarming"></span>
            </span>
            <div className="confirm-message d-flex flex-column align-items-center">
              <span className="confirm-message__title fz_16 text--light mb-3">
                {t('lbl-are-you-sure')}
              </span>
              <span className="confirm-message__message fz_13 text--light3">
                {t('lbl-remove-the-bank')}
              </span>
            </div>
            <div className="p-0 panel-cash-advance ">
              <div className="d-flex align-items-center pb-3 pr-3 pl-3 pt-3 justify-content-center">
                <div className="d-flex justify-content-between w-100">
                  <button
                    onClick={() => setShowModal(false)}
                    className="fw-500 fz_14 btn btn--cancel"
                    style={{ border: 'none' }}
                  >
                    {t('txt-cancel')}
                  </button>
                  <button
                    onClick={() => handleDelete()}
                    style={{ border: 'none' }}
                    className="fw-500 fz_14 btn btn--primary ml-2"
                  >
                    {t('txt-accept')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <PerfectScrollbar>
        <div className="d-flex w-100 flex-column align-items-center mb-2">
          <div className="d-flex bank-account-item-list">
            {bankAcc &&
              bankAcc.bankAccount &&
              !!bankAcc.bankAccount.length &&
              bankAcc.bankAccount.map((item, index) => (
                <div className="bank-item-container">
                  <div className="bank-item-content">
                    <div className="bank-infor">
                      <div
                        className="bank-logo"
                        style={{
                          backgroundImage: `url('/images/${item.bankName}.jpg'), url('${nologo}')`,
                        }}
                      ></div>
                      <span className="bank-name fw-500 text-light">
                        {item.bankName}
                      </span>
                      <span className="bank-branch light-3">
                        {item.branchName}
                      </span>
                      <span className="bank-account-no fw-500 text-light">
                        {item.bankAcntNo}
                      </span>
                      <span className="bank-user-name fw-500">
                        {item.bankAcntNm}
                      </span>
                    </div>
                    <div className="delete-account">
                      <div>
                        {
                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>{t('lbl-remove')}</Tooltip>}
                          >
                            {!auth || !auth.isVerified ? (
                              <div
                                className="icon iTrash"
                                onClick={() => dispatch(setAuth())}
                              ></div>
                            ) : (
                              <div
                                className="icon iTrash"
                                onClick={() => (
                                  setBankRemove({
                                    bankAcntNo: item.bankAcntNo,
                                    bankCd: item.bankCd,
                                  }),
                                  setShowModal(true)
                                )}
                              ></div>
                            )}
                          </OverlayTrigger>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            {bankAcc &&
              bankAcc.bankAccount &&
              bankAcc.bankAccount.length < 5 &&
              (auth && auth.isVerified ? (
                <div className="bank-item-container">
                  <div className="bank-item-content">
                    <div className="bank-infor register-account">
                      <div
                        className="btn-icon btn-icon--light cursor-pointer"
                        onClick={() => dispatch(setChangeBank())}
                      >
                        <span className="icon iAdd"></span>
                        <span className="fw-500">
                          {t('txt-regis-bank-account')}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div className="bank-item-container">
                    <div className="bank-item-content">
                      <div
                        className="btn-icon btn-icon--light cursor-pointer"
                        onClick={() => dispatch(setAuth())}
                      >
                        <span className="icon iAdd"></span>
                        <span className="fw-500">
                          {t('txt-regis-bank-account')}
                        </span>
                      </div>
                    </div>
                  </div>
                </>
              ))}
          </div>
        </div>
      </PerfectScrollbar>
    </div>
  );
});

export default BankItem;
