import React, { useEffect, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import {
  getCptPerChartRequest,
  getCptPersonalInforRequest,
  getCptRoundInfoRequest,
  getCptStockBalRequest,
  getRegistCptStatRequest,
} from '../actions';
import HighchartsReact from 'highcharts-react-official';
import Highcharts, { color } from 'highcharts';
import { cloneDeep } from 'lodash';
import { _formatDate2, numberFormat } from '../../../util';
import PerfectScrollBar from 'react-perfect-scrollbar';
import { setLogin } from '../../client/actions';
import { constants } from '../../../util/constant';
import { OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';

const Performance = withNamespaces('translations')(
  ({ t, refresh, setTabActive }) => {
    const { token } = useSelector((state) => state.client);
    const theme = useSelector((state) => state.client.currentTheme);
    const lang = useSelector((state) => state.client.setting?.lang) || 'vi';
    const {
      cptStockBal,
      cptPerChart,
      cptPersonalInfor,
      cptRoundInfo,
      registCptStat,
    } = useSelector((state) => state.marginCompetition);
    const [roundCode, setRoundCode] = useState('');
    const [allowcation, setAllowcation] = useState();
    const [vniRate, setVniRate] = useState([]);
    const [perRate, setPerRate] = useState([]);

    const dispatch = useDispatch();

    const toltalMarketValue =
      cptStockBal && cptStockBal.length > 0
        ? cptStockBal.reduce(
            (accumulator, cur) => accumulator + cur.marketVal,
            0
          )
        : 1;

    function handleGetCptStockBalRequest() {
      const uuid = uuidv4();
      const params = {
        group: 'CORE',
        cmd: 'getCptStockBal',
        rqId: uuid,
        user: token?.user,
        session: token?.session,
        channel: 'WTS',
        data: {
          cif: token?.cif,
          rndCd: roundCode,
        },
      };
      dispatch(getCptStockBalRequest(params));
    }

    function handleGetCptPerChart() {
      const uuid = uuidv4();
      const params = {
        group: 'CORE',
        cmd: 'getCptPerChart',
        rqId: uuid,
        user: token?.user,
        session: token?.session,
        channel: 'WTS',
        data: {
          cif: token?.cif,
          rndCd: roundCode,
        },
      };
      dispatch(getCptPerChartRequest(params));
    }

    function handleDataPieChart(stockBal) {
      let stockBalSort = stockBal.sort((a, b) => {
        const valA = a.marketVal;
        const valB = b.marketVal;
        if (valA < valB) {
          return 1;
        }
        if (valA > valB) {
          return -1;
        }
        return 0;
      });

      let _stockBalSort = [...stockBalSort];
      let _listAllocation = [];

      if (_stockBalSort.length > 15) {
        const stocks = _stockBalSort.splice(0, 10);
        const sumOther = _stockBalSort.reduce(
          (accumulator, cur) => accumulator + cur.marketVal,
          0
        );
        _listAllocation = stocks;
        _listAllocation.push({ symbol: 'other', marketVal: sumOther });
      } else {
        _listAllocation = stockBalSort;
      }

      const sum = stockBalSort.reduce(
        (accumulator, cur) => accumulator + cur.marketVal,
        0
      );

      let allow = new Array(_listAllocation.length);
      if (_listAllocation) {
        _listAllocation.map(
          (item, index) =>
            (allow[index] = {
              name: item.symbol,
              y: (item.marketVal / sum) * 100,
              color: COLORS_LIST[index],
            })
        );
      }
      setAllowcation(allow);
    }

    function getCptPersonalInfor() {
      const uuid = uuidv4();
      const params = {
        group: 'CORE',
        cmd: 'getCptInfo',
        rqId: uuid,
        user: token?.user,
        session: token?.session,
        channel: 'WTS',
        data: {
          cif: token?.cif,
          rndCd: roundCode,
        },
      };
      dispatch(getCptPersonalInforRequest(params));
    }

    function getCptRoundInfo() {
      if (token) {
        const uuid = uuidv4();
        const params = {
          group: 'CORE',
          cmd: 'getCptRoundInfo',
          rqId: uuid,
          channel: 'WTS',
          data: {
            langTp: lang,
          },
        };
        dispatch(getCptRoundInfoRequest(params));
      } else {
        dispatch(setLogin());
      }
    }

    const options = {
      chart: {
        type: 'line',
        height: 400,
        marginTop: 40,
        marginRight: 50,
      },
      title: {
        text: '',
      },
      credits: { enabled: false },
      legend: { enabled: false },
      xAxis: {
        gridLineWidth: 0,
        tickInterval: 1,
        visible: true,
        categories:
          cptPerChart && cptPerChart.length > 0
            ? cptPerChart.map((x) => _formatDate2(x.trdDt)?.slice(0, 5))
            : [],
      },
      yAxis: {
        title: '',
        gridLineWidth: 1,
        lineColor: '#FF0000',
        visible: true,
        opposite: true,
        labels: {
          align: 'right',
          enabled: true,
          x: 50,
          formatter: function () {
            return this.value + ' %';
          },
        },
      },
      tooltip: {
        enabled: true,
        shared: true,
        useHTML: true,
        pointFormatter: function (e) {
          console.log('e ===> ', e);
          console.log('this ===> ', this);
          return `<strong style="color: ${this.series.color}">${
            this.series.name
          } </strong> ${t('txt-change')}: ${Highcharts.numberFormat(
            this.y,
            2,
            '.',
            ''
          )} %
        <br/>`;
        },
      },
      series: [
        {
          name: t('marginCompetition.performance.investmenteffect'),
          data: perRate.map((x) => x.value) ?? [],
          color: theme.name == 'dark' ? '#00ff57' : '#2db35b',
          marker: {
            enabled: vniRate.length > 1 ? false : true,
            symbol: 'circle',
          },
        },
        {
          name: 'VNI',
          data: vniRate.map((x) => x.value) ?? [],
          color: theme.name == 'dark' ? '#6D6E71' : '#6D6E71',
          marker: {
            enabled: vniRate.length > 1 ? false : true,
            symbol: 'circle',
          },
        },
      ],
    };

    useEffect(() => {
      getCptRoundInfo();
    }, []);

    useEffect(() => {
      if (cptStockBal && cptStockBal.length > 0) {
        handleDataPieChart(cptStockBal);
      } else {
        setAllowcation([]);
      }
    }, [cptStockBal]);

    useEffect(() => {
      if (cptPerChart && cptPerChart.length > 0) {
        const _pinefolioRate = cptPerChart.map((x) => ({
          date: x.trdDt,
          begin: 0,
          value: x.pnl || 0,
        }));

        setPerRate(_pinefolioRate);

        const _vniRate = cptPerChart.map((x) => ({
          date: x.trdDt,
          begin: 0,
          value: x.idx || 0,
        }));

        setVniRate(_vniRate);
      }
    }, [cptPerChart]);

    useEffect(() => {
      if (roundCode) {
        getCptPersonalInfor();
        handleGetCptStockBalRequest();
        handleGetCptPerChart();
      }
    }, [refresh, roundCode]);

    useEffect(() => {
      if (registCptStat && registCptStat.perRnd?.length > 0) {
        if (registCptStat.perRnd?.length == 1) {
          setRoundCode(registCptStat.perRnd[0].rndCd);
        } else {
          if (registCptStat.perRnd.findIndex((x) => x.stat == '2') != -1) {
            setRoundCode(registCptStat.perRnd.find((x) => x.stat == '2').rndCd);
          } else if (
            registCptStat.perRnd.findIndex((x) => x.stat == '1') != -1
          ) {
            setRoundCode(registCptStat.perRnd.find((x) => x.stat == '1').rndCd);
          } else {
            setRoundCode(
              registCptStat.perRnd.findLast((x) => x.stat == '3').rndCd
            );
          }
        }
      }
    }, [registCptStat]);

    const COLORS_LIST = [
      '#FF5733',
      '#FFBD33',
      '#33FF57',
      '#57FF33',
      '#33FFBD',
      '#33BDFF',
      '#FF33BD',
      '#BD33FF',
      '#FF3366',
      '#33FF66',
      '#6633FF',
      '#66FF33',
      '#33FF33',
      '#FF33FF',
    ];

    return (
      <div className="performance">
        <div className="layout-page__body layout__body performance__body">
          {registCptStat && registCptStat.perRnd?.length > 0 ? (
            <>
              <div className="performance__body--left">
                <PerfectScrollBar>
                  <div className="d-flex flex-column w-100 gap-24">
                    <div className="performance-chart">
                      <div className="chart-explain">
                        <div className="chart-tab">
                          {registCptStat.perRnd.map((round, index) => (
                            <div
                              key={index}
                              className={`cursor-pointer ${
                                round.rndCd == roundCode
                                  ? 'active'
                                  : 'text--light3'
                              }`}
                              onClick={() => setRoundCode(round.rndCd)}
                            >{`${t('marginCompetition.leaderBoard.round')} ${
                              round.rndNo
                            }`}</div>
                          ))}
                        </div>
                        <div className="chart-metrics">
                          <span className="metric__value investment-effect">
                            <span className="circle"></span>
                            <span className="metric__key">
                              {t(
                                'marginCompetition.performance.investmenteffect'
                              )}
                            </span>
                          </span>
                          <span className="metric__value index">
                            <span className="circle"></span>
                            <span>{t('pinefolio.vniChart')}</span>
                          </span>
                        </div>
                      </div>
                      <div className="main-chart">
                        <div className="performance-chart">
                          {cptPerChart?.length > 0 &&
                            ((vniRate && !!vniRate.length > 0) ||
                              (perRate && !!perRate.length > 0)) && (
                              <HighchartsReact
                                highcharts={Highcharts}
                                options={options}
                              />
                            )}
                          {(cptPerChart?.length <= 0 ||
                            (!!vniRate?.length == 0 &&
                              !!perRate?.length == 0)) && (
                            <div className="performance-chart__empty">
                              <div className="performance-chart__icon">
                                <span className="icon iEmpty"></span>
                              </div>

                              <p className="text--sm text--light3">
                                {t('txt-no-data-found')}
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    {cptPersonalInfor?.noticeMsgs && (
                      <div className="performance-warning">
                        <span className="icon iWarning"></span>
                        <span>{cptPersonalInfor?.noticeMsgs}</span>
                      </div>
                    )}
                    {Object.keys(cptPersonalInfor).length !== 0 &&
                      cptPersonalInfor.constructor === Object && (
                        <div
                          className={`performance-detail ${
                            cptPersonalInfor.brdCode == 'BRD01'
                              ? 'whale-group'
                              : cptPersonalInfor.brdCode == 'BRD02'
                              ? 'koi-group'
                              : 'carp-group'
                          }`}
                        >
                          <div className={`detail-item`}>
                            <span className="icon group-icon d-flex">
                              <span>
                                {cptPersonalInfor.brdImg && (
                                  <img
                                    className="w-100 h-100"
                                    src={cptPersonalInfor.brdImg}
                                  />
                                )}
                              </span>
                            </span>
                            <div className="detail-item__infor">
                              <span className="text--light">
                                {cptPersonalInfor?.nickNm || '-'}
                              </span>
                              <span className="text--light3">
                                {`${
                                  cptPersonalInfor?.registAcntType == 'N'
                                    ? t('txt-normal')
                                    : t('txt-margin')
                                }`}
                              </span>
                            </div>
                          </div>
                          <div className="detail-item">
                            <div className="detail-item__infor">
                              <span className="brd-name fw-500">
                                {cptPersonalInfor?.brdNm || '-'}
                              </span>
                              <span className="text--light3 d-flex align-items-center">
                                <span className="pr-1">
                                  {`${t(
                                    'marginCompetition.ruleAndAwards.rank'
                                  )}`}
                                </span>
                                {cptPersonalInfor?.rnkNo && (
                                  <>
                                    <span className="text--light pr-1">{`${numberFormat(
                                      cptPersonalInfor?.rnkNo,
                                      0,
                                      '-'
                                    )}`}</span>
                                    <span
                                      className={`text-center d-flex justify-content-center align-items-center ${
                                        cptPersonalInfor.rnkChg > 0
                                          ? 'i'
                                          : cptPersonalInfor.rnkChg == 0
                                          ? 'r'
                                          : 'd'
                                      }`}
                                    >
                                      <span
                                        className={`${
                                          cptPersonalInfor.rnkChg > 0
                                            ? 'iArrowUp icon'
                                            : cptPersonalInfor.rnkChg < 0
                                            ? 'iArrowDown icon'
                                            : ''
                                        }`}
                                      ></span>
                                      <span>{`${numberFormat(
                                        Math.abs(cptPersonalInfor?.rnkChg),
                                        0,
                                        '-'
                                      )}`}</span>
                                    </span>
                                  </>
                                )}
                              </span>
                            </div>
                          </div>
                          <div className="detail-item">
                            <div className="detail-item__infor">
                              <span className="text--light3">
                                {`${t(
                                  'marginCompetition.performance.profit'
                                )}/${t('marginCompetition.performance.loss')}`}
                              </span>
                              <span
                                className={`${
                                  cptPersonalInfor.pnl > 0
                                    ? 'i'
                                    : cptPersonalInfor.pnl == 0
                                    ? 'r'
                                    : 'd'
                                }`}
                              >
                                {numberFormat(cptPersonalInfor?.pnl, 0, '-')}
                              </span>
                            </div>
                          </div>
                          <div className="detail-item">
                            <div className="detail-item__infor">
                              <span className="text--light3">
                                {`% ${t(
                                  'marginCompetition.performance.profit'
                                )}/${t('marginCompetition.performance.loss')}`}
                              </span>
                              <span
                                className={`${
                                  cptPersonalInfor.pnlPer > 0
                                    ? 'i'
                                    : cptPersonalInfor.pnlPer == 0
                                    ? 'r'
                                    : 'd'
                                }`}
                              >
                                {`${numberFormat(
                                  cptPersonalInfor?.pnlPer,
                                  4,
                                  '-'
                                )}%`}
                              </span>
                            </div>
                          </div>
                          <div className="detail-item">
                            <div className="detail-item__infor">
                              <span className="text--light3">
                                {t(
                                  'marginCompetition.performance.initialCapital'
                                )}
                              </span>
                              <span className="text--light">
                                {`${numberFormat(
                                  cptPersonalInfor?.initNav,
                                  0,
                                  '-'
                                )}`}
                              </span>
                            </div>
                          </div>
                          <div className="detail-item">
                            <div className="detail-item__infor">
                              <span className="text--light3">
                                {t(
                                  'marginCompetition.performance.currentAverageCapital'
                                )}
                              </span>
                              <span className="text--light">
                                {`${numberFormat(
                                  cptPersonalInfor?.avgNav,
                                  0,
                                  '-'
                                )}`}
                              </span>
                            </div>
                          </div>
                          <div className="detail-item">
                            <div className="detail-item__infor">
                              <span className="text--light3">
                                {t(
                                  'marginCompetition.performance.totalWithdrawalAmount'
                                )}
                              </span>
                              <span className="text--light">{`${numberFormat(
                                cptPersonalInfor?.totWidrAmt,
                                0,
                                '-'
                              )}`}</span>
                            </div>
                          </div>
                          <div className="detail-item">
                            <div className="detail-item__infor">
                              <span className="text--light3">
                                {t(
                                  'marginCompetition.performance.totalAmountDeposited'
                                )}
                              </span>
                              <span className="text--light">{`${numberFormat(
                                cptPersonalInfor?.totDepoAmt,
                                0,
                                '-'
                              )}`}</span>
                            </div>
                          </div>
                          <div className="detail-item">
                            <div className="detail-item__infor">
                              <span className="text--light3">
                                <span className="d-flex justify-content-end align-items-center">
                                  {t(
                                    'marginCompetition.performance.assetTurnoverRate'
                                  )}
                                  <OverlayTrigger
                                    trigger={['hover', 'focus']}
                                    placement="top"
                                    className="portfolio-overlay"
                                    overlay={
                                      <Tooltip style={{ 'z-index': '99999' }}>
                                        {t(
                                          'marginCompetition.performance.tooltip.assetTurnoverRate'
                                        )}
                                      </Tooltip>
                                    }
                                  >
                                    <span className="icon iInfor iTooltip ml-1"></span>
                                  </OverlayTrigger>
                                </span>
                              </span>
                              <span
                                className={`${
                                  cptPersonalInfor?.turnoverRt >= 200
                                    ? 'i'
                                    : 'd'
                                }`}
                              >{`${numberFormat(
                                cptPersonalInfor?.turnoverRt,
                                4,
                                '-'
                              )}%`}</span>
                            </div>
                          </div>
                          <div className="detail-item">
                            <div className="detail-item__infor">
                              <span className="text--light3">
                                <span className="d-flex justify-content-end align-items-center">
                                  {t(
                                    'marginCompetition.performance.totalDepositedNAV'
                                  )}
                                  <OverlayTrigger
                                    trigger={['hover', 'focus']}
                                    placement="top"
                                    className="portfolio-overlay"
                                    overlay={
                                      <Tooltip style={{ 'z-index': '99999' }}>
                                        {t(
                                          'marginCompetition.performance.tooltip.totalDepositedNAV'
                                        )}
                                      </Tooltip>
                                    }
                                  >
                                    <span className="icon iInfor iTooltip ml-1"></span>
                                  </OverlayTrigger>
                                </span>
                              </span>
                              <span
                                className={`${
                                  cptPersonalInfor?.depoPerNav <= 500
                                    ? 'i'
                                    : 'd'
                                }`}
                              >{`${numberFormat(
                                cptPersonalInfor?.depoPerNav,
                                4,
                                '-'
                              )}%`}</span>
                            </div>
                          </div>
                        </div>
                      )}
                  </div>
                </PerfectScrollBar>
              </div>
              <span className="horizon-y"></span>
              <div className="performance__body--right">
                <div className="allocation">
                  <div className="allocation-chart">
                    {allowcation && allowcation.length > 0 && (
                      <div className="section-chart__chart">
                        <PieChart key={lang} data={allowcation} />
                      </div>
                    )}
                  </div>
                  {cptStockBal && cptStockBal.length > 0 && (
                    <div className="allocation-asset">
                      <div className="fz_16 fw-500 text--light3 lh-24">
                        {t('personal.asset.assetAllocation')}
                      </div>
                      <table className="table-fixed">
                        <thead>
                          <tr>
                            <th
                              className="text--gray w-30"
                              style={{ paddingLeft: '24px' }}
                            >
                              {t('txt-symbol')}
                            </th>
                            <th className="text--gray text-right w-30">
                              <span className="d-flex justify-content-end align-items-center">
                                {t(
                                  'marginCompetition.performance.distribution'
                                )}
                                <OverlayTrigger
                                  trigger={['hover', 'focus']}
                                  placement="top"
                                  className="portfolio-overlay"
                                  overlay={
                                    <Tooltip style={{ 'z-index': '99999' }}>
                                      {t(
                                        'marginCompetition.performance.tooltip.distribution'
                                      )}
                                    </Tooltip>
                                  }
                                >
                                  <span className="icon iInfor iTooltip ml-1"></span>
                                </OverlayTrigger>
                              </span>
                            </th>
                            <th className="text--gray text-right w-30">
                              {t('txt-label-vol')}
                            </th>
                          </tr>
                        </thead>
                      </table>
                      <PerfectScrollBar style={{ width: '100%' }}>
                        <table className="table-fixed">
                          <tbody>
                            {cptStockBal.map((stock, index) => (
                              <tr key={index}>
                                <td className="text--light d-flex align-items-center h-100">
                                  {cptStockBal.length > 15 ? (
                                    <span
                                      className="dot"
                                      style={{
                                        width: '12px',
                                        backgroundColor:
                                          index < 10
                                            ? COLORS_LIST[index]
                                            : COLORS_LIST[10],
                                      }}
                                    ></span>
                                  ) : (
                                    <span
                                      className="dot"
                                      style={{
                                        width: '12px',
                                        backgroundColor: COLORS_LIST[index],
                                      }}
                                    ></span>
                                  )}
                                  <span>{stock.symbol}</span>
                                </td>
                                <td className="text--light text-right">
                                  <span>{`${numberFormat(
                                    (stock.marketVal / toltalMarketValue) * 100,
                                    2,
                                    0
                                  )}%`}</span>
                                </td>
                                <td className="text--light text-right">
                                  {numberFormat(stock.avaiQty, 0, '0')}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </PerfectScrollBar>
                    </div>
                  )}
                </div>
              </div>
            </>
          ) : (
            <div className="d-flex justify-content-center w-100">
              <span className="fw-500 fz_26">{t('txt-no-data-found')}</span>
            </div>
          )}
        </div>
      </div>
    );
  }
);

const PieChart = withNamespaces('translations')(({ t, data }) => {
  console.log('Pie chart', data);
  if (!data || data.length == 0) return null;
  const _data = cloneDeep(data);
  _data.map((item) => {
    item.name = t(item.name);
    item.y = Number(numberFormat(item.y, 2, '0'));
  });

  const [chartOptions, setChartOptions] = useState({});

  const mapDataToState = () => {
    const chartOptions = {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
        height: 250,
        width: 250,
        spacingLeft: 10,
        backgroundColor: 'transparent',
      },
      title: { text: ' ' },
      credits: {
        enabled: false,
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: false,
          },
          showInLegend: false,
          borderWidth: 0,
          // colors: COLORS,
        },
      },
      legend: {
        align: 'right',
        layout: 'vertical',
        verticalAlign: 'middle',
        itemStyle: { color: '#ffffff' },
      },
      series: [
        {
          name: 'Tỷ lệ',
          colorByPoint: true,
          innerSize: '50%',
          data: _data,
        },
      ],
    };
    setChartOptions(chartOptions);
  };

  useEffect(() => {
    if (data) mapDataToState();
  }, [data]);

  console.log('chartOptions', chartOptions);
  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={chartOptions}
      //   containerProps={{ style: { height: '200px', width: '200px' } }}
    />
  );
});

export default Performance;
