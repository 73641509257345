import {
  AUTH_LIST_REQUESTING,
  AUTH_REQUESTING,
  CHANGE_BANK_REQUESTING,
  CHANGE_BANK_RESET,
  CHANGE_PASS_REQUESTING,
  CHANGE_PASS_RESET,
  CHANGE_TELPIN_REQUESTING,
  CHANGE_TELPIN_RESET,
  CHANGE_USER_REQUESTING,
  CHANGE_USER_RESET,
  GENERATE_AUTH_REQUESTING,
  SET_TYPE_AUTH_REQUESTING,
  ONE_TIME_TOKEN_REQUESTING,
  LOCK_RESENT_OTP,
  UNLOCK_RESENT_OTP,
  LOCK_REFRESH_MATRIX,
  UNLOCK_REFRESH_MATRIX,
} from './constants';

export function lockResendOtp(params) {
  return {
    type: LOCK_RESENT_OTP,
  };
}

export function unlockResendOtp() {
  return {
    type: UNLOCK_RESENT_OTP,
  };
}

export function lockRefreshMatrix(params) {
  return {
    type: LOCK_REFRESH_MATRIX,
  };
}

export function unlockRefreshMatrix() {
  return {
    type: UNLOCK_REFRESH_MATRIX,
  };
}

export function authRequest(params) {
  console.log(params);
  return {
    type: AUTH_REQUESTING,
    params,
  };
}

export function authListRequest(params) {
  return {
    type: AUTH_LIST_REQUESTING,
    params,
  };
}

export function generateAuthRequest(params) {
  return {
    type: GENERATE_AUTH_REQUESTING,
    params,
  };
}

export function setTypeAuthRequest(params) {
  return {
    type: SET_TYPE_AUTH_REQUESTING,
    params,
  };
}

export function resetAuthRequest(params) {
  return {
    type: 'RESET_AUTH',
  };
}

export function clearAuth() {
  return {
    type: 'CLEAR_AUTH',
  };
}

//FIXME: Handle error
export function changePassRequest(params) {
  return {
    type: CHANGE_PASS_REQUESTING,
    params,
  };
}

export function resetPassRequest() {
  return {
    type: CHANGE_PASS_RESET,
  };
}

export function changeTelPINRequest(params) {
  return {
    type: CHANGE_TELPIN_REQUESTING,
    params,
  };
}

export function resetTelPINRequest() {
  return {
    type: CHANGE_TELPIN_RESET,
  };
}

export function changeUserRequest(params) {
  return {
    type: CHANGE_USER_REQUESTING,
    params,
  };
}

export function resetChangeUserRequest(params) {
  return {
    type: CHANGE_USER_RESET,
    params,
  };
}

//FIXME: Handle error
export function changeBankRequest(params) {
  return {
    type: CHANGE_BANK_REQUESTING,
    params,
  };
}

export function changeBankReset() {
  return {
    type: CHANGE_BANK_RESET,
  };
}

export const onTimeTokenRequest = (params) => {
  return {
    type: ONE_TIME_TOKEN_REQUESTING,
    params,
  };
};
