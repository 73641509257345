import React, { useEffect } from 'react';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

const PDFViewer = ({ src, scale = 1 }) => {
  const toolbarPluginInstance = toolbarPlugin();
  const { renderDefaultToolbar, Toolbar, zoomPluginInstance } =
    toolbarPluginInstance;
  const { zoomTo } = zoomPluginInstance;

  const transform = (slot) => {
    const { EnterFullScreen } = slot;
    return {
      ...slot,
      // These slots will be empty
      Download: () => <></>,
      DownloadMenuItem: () => <></>,
      GoToNextPage: () => <></>,
      GoToLastPage: () => <></>,
      Open: () => <></>,
      Print: () => <></>,
      Rotate: () => <></>,
      SwitchScrollMode: () => <></>,
      Search: () => <></>,
      SwitchTheme: () => <></>,
      SwitchThemeMenuItem: () => <></>,
      GoToPreviousPage: () => <></>,
      MoreActionsPopover: () => <></>,
      NumberOfPages: () => <></>,
      ShowSearchPopover: () => <></>,
      CurrentPageInput: () => <></>,
      ShowProperties: () => <></>,
      ShowPropertiesMenuItem: () => <></>,
      EnterFullScreen: () => (
        <EnterFullScreen>
          {(props) => (
            <button
              style={{
                border: 'unset',
                outline: 'none',
              }}
              onClick={() => {
                zoomTo(1.3);
              }}
            >
              <EnterFullScreen />
            </button>
          )}
        </EnterFullScreen>
      ),
    };
  };

  useEffect(() => {
    if (scale) zoomTo(scale);
  }, [scale]);

  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js">
      <div
        className="rpv-core__viewer"
        style={{
          border: '1px solid rgba(0, 0, 0, 0.3)',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          width: '100%',
        }}
      >
        <div
          style={{
            alignItems: 'center',
            backgroundColor: '#eeeeee',
            borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
            display: 'flex',
            padding: '0.25rem',
          }}
        >
          <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
        </div>
        <div
          style={{
            flex: 1,
            overflow: 'hidden',
          }}
        >
          <Viewer
            fileUrl={src}
            plugins={[toolbarPluginInstance]}
            // withCredentials={true}
            // httpHeaders={{
            //   'Access-Control-Allow-Credentials': true,
            //   'Access-Control-Allow-Origin': '*',
            //   'Access-Control-Allow-Headers': 'Content-Type',
            //   'Access-Control-Allow-Methods': 'GET, POST, PUT',
            //   'Cache-Control': 'no-cache',
            // }}
          />
        </div>
      </div>
    </Worker>
  );
};
export default PDFViewer;
