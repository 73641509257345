import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { setToast } from '../../client/actions';
import QRCode from 'qrcode.react';
import logo from '../../../assets/img/logo.svg';
import ModalAddNewCustomer from '../components/modal/modalAddNewCustomer';
import RequestList from '../components/partnership/referrer/requestList';
import Dashboard from '../components/partnership/customer/dashboard';
import RequestListForCustomer from '../components/partnership/customer/requestList';

const PartnershipCustomer = withNamespaces('translations')(({ t }) => {
  const { broker } = useSelector((state) => state.customer);
  const [showModal, setShowModal] = useState(false);
  const [tabActive, setTabActive] = useState(0);
  const {
    token,
    setting: { lang = 'vi' },
    appsetting,
  } = useSelector((state) => state.client);
  const dispatch = useDispatch();
  const qrRef = useRef();

  return (
    <div className="partner-container account-panel">
      <div className="personal-assets-header card-panel-2-header">
        <span className="personal-assets-header__left">
          <span
            className={`card-panel-2__tab ${tabActive == 0 ? 'active' : ''}`}
            onClick={() => {
              setTabActive(0);
            }}
          >
            {t('partner.referrerInformation')}
          </span>
          <span
            className={`card-panel-2__tab ${tabActive == 2 ? 'active' : ''}`}
            onClick={() => {
              setTabActive(2);
            }}
          >
            {t('partner.requestList')}
          </span>
        </span>
      </div>
      {tabActive === 0 && <Dashboard t={t} />}
      {tabActive === 2 && <RequestListForCustomer t={t} />}
    </div>
  );
});

export default PartnershipCustomer;
