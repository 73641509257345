import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from '../../../components/datePicker2';
import PerfectScrollBar from 'react-perfect-scrollbar';
import { Col } from 'react-bootstrap';

import {
  numberFormat,
  _convertTpToPrice,
  _getOrderStatus,
  StringToInt,
  _formatDate2,
  _diff2Date,
  _formatDate,
} from '../../../util';
import { v4 as uuidv4 } from 'uuid';

import Page from '../../../components/print/Page';
import PrintButton from '../../../components/print/PrintButton';
import PagingCustom from '../../../components/common/paging/PagingCustom';

import { take, takeRight } from 'lodash';
import logo from '../../../assets/img/pinetreeSecurities.svg';
import ExportSecuritiesStatement from '../components/exports/securitiesStatementExcel';

import { withNamespaces } from 'react-i18next';
import { useEffect } from 'react';
import { useState } from 'react';
import AssetHeader from '../components/header/assetHeader';
import OverviewMetric from '../components/header/overviewMetric';
import { summaryStockRequest } from '../../summary/actions';
import { setToast } from '../../client/actions';
import moment from 'moment';
import { Mixpanel } from '../../../lib/mixpanel';

const PAGE_SIZES_OPTIONS = [
  { value: 15, label: '15' },
  { value: 30, label: '30' },
  { value: 50, label: '50' },
];

const SecuritiesStatement = withNamespaces('translations')(
  ({ t, loadBalanceHandle, params }) => {
    const dispatch = useDispatch();
    const stockList = useSelector((state) => state.summary.stockList.list);
    const { defaultAccount } = useSelector((state) => state.socket);

    const [refresh, setRefresh] = useState(false);
    const [tabActive, setTabActive] = useState(0);
    const [account, setAccount] = useState();

    useEffect(() => {
      setAccount(defaultAccount);
      setRefresh(!refresh);
    }, [defaultAccount]);

    return (
      <div className="card-panel-2 executed-gainloss statement-container h-100">
        <AssetHeader
          account={account}
          setAccount={setAccount}
          params={params}
          setRefresh={setRefresh}
          tabActive={tabActive}
          refresh={refresh}
        />
        <div className="card-panel-2-body">
          <OverviewMetric
            key={refresh}
            loadBalanceHandle={loadBalanceHandle}
            account={account}
          />
        </div>
        <Filter
          refresh={refresh}
          account={account}
          stockList={stockList}
          key={refresh}
        />
      </div>
    );
  }
);

const Filter = withNamespaces('translations')(
  ({ t, refresh, account, stockList }) => {
    const dispatch = useDispatch();

    const currentDate = new Date();
    const [fromDate, setFromDate] = useState(
      new Date(currentDate.setDate(currentDate.getDate() - 90))
    );
    const [toDate, setToDate] = useState(new Date());

    const { token, auth } = useSelector((state) => state.client);
    const [contentShow, setContentShow] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [continued, setContinued] = useState(false);
    const [pageSizeOption, setPageSizeOption] = useState(PAGE_SIZES_OPTIONS[0]);

    const onFilter = () => {
      getStockListHandle(fromDate, toDate);
    };

    const getStockListHandle = (fromDate, toDate) => {
      if (!token || !account) return;
      const maxDay = token && token['config'] ? token['config']['maxDay'] : 90;

      const _diff = _diff2Date(toDate, fromDate);
      if (_diff < 0) {
        handleToast(t('txt-valid-time'));
        return;
      }
      if (_diff > maxDay) {
        handleToast(`${t('txt-valid-range-time')} ${maxDay} ${t('txt-day')}`);
        return;
      }

      const uuid = uuidv4();

      const params = {
        group: 'CORE',
        user: token.user,
        session: token.session,
        cmd: 'GetStockStatement',
        rqId: uuid,
        channel: 'WTS',
        data: {
          acntNo: account ? account?.acntNo : '',
          subAcntNo: account ? account.subAcntNo : '',
          fromDate: fromDate,
          toDate: toDate,
          nextKey: '1',
          reqCount: 1000,
        },
      };

      dispatch(summaryStockRequest(params));
    };

    useEffect(() => {
      handleQueryPage(1);
    }, [stockList, pageSizeOption]);

    useEffect(() => {
      if (!token) return;
      getStockListHandle(
        moment(new Date(new Date().setDate(new Date().getDate() - 90))).format(
          'YYYYMMDD'
        ),
        moment(new Date()).format('YYYYMMDD')
      );
    }, [account, refresh]);

    const handleToast = (msg) => {
      const toastMsg = {
        id: Math.random(),
        msg: msg,
        title: t('txt-notice'),
      };
      dispatch(setToast(toastMsg));
    };

    const handleNextPage = (step) => {
      handleQueryPage(page + step);
    };

    const handleQueryPage = (page) => {
      if (!stockList) return;
      const _stockList = [...stockList].reverse();
      const _pageSize = pageSizeOption?.value ?? PAGE_SIZES_OPTIONS[0].value;
      const totalPage =
        _pageSize != 0
          ? _stockList && _stockList.length > 0
            ? Math.ceil(_stockList.length / _pageSize)
            : 1
          : 1;

      const _takeContent = take(_stockList, _pageSize * page);
      const _contentShow = takeRight(
        _takeContent,
        _takeContent.length - _pageSize * (page - 1)
      );

      if (_pageSize == 0) {
        setContentShow(_stockList);
      } else {
        setContentShow(_contentShow);
      }
      setPage(page);
      setContinued(page < totalPage);
      setTotalPage(totalPage);
    };

    const pageSizeOptions = [
      ...PAGE_SIZES_OPTIONS,
      { value: 0, label: 'txt-all' },
    ];
    return (
      <PerfectScrollBar className="mt-36 h-100">
        <Col className="d-flex flex-column h-100">
          <div className="d-flex justify-content-between align-items-center statement__filters">
            <label className="mr-auto text-white text-title">
              {t('txt-label-securities-statement')}
            </label>
            <div className="d-flex">
              <div className="mr-1">
                <DatePicker
                  startDate={fromDate}
                  endDate={toDate}
                  callback={(from, to) => {
                    setFromDate(_formatDate(from));
                    setToDate(_formatDate(to));
                  }}
                />
              </div>
              <button
                className="btn btn--primary h-24 mr-1"
                onClick={onFilter}
              >{`${t('txt-search')}`}</button>
            </div>
            <div className="d-flex align-items-center">
              <ExportSecuritiesStatement
                csvData={stockList ? [...stockList].reverse() : []}
                fileName="sao-ke-giao-dich-chung-khoan"
                fromDate={_formatDate2(fromDate)}
                toDate={_formatDate2(toDate)}
                accountCode={account && account.subAcntNo}
                accountName={account && account.vsdAcntNo}
                symbol={t('txt-all')}
              />
              <PrintButton
                id={'sao-ke-giao-dich-chung-khoan'}
                callback={() =>
                  Mixpanel.track('Export Statement', {
                    'Statement Type': 'Securities Statement',
                  })
                }
              />
            </div>
          </div>
          <Page id="sao-ke-chung-khoan">
            <table className="table table-bordered tbl-list">
              <thead>
                <tr>
                  <th className="text-center" rowSpan={2}>
                    {t('txt-stt')}
                  </th>
                  <th className="text-center" rowSpan={2}>
                    {t('txt-time')}
                  </th>
                  <th className="text-center" rowSpan={2}>
                    {t('txt-symbol')}
                  </th>
                  <th className="text-center" rowSpan={2}>
                    {t('txt-label-major')}
                  </th>
                  <th className="text-center" rowSpan={2}>
                    {t('txt-label-opening-balance')}
                  </th>
                  <th className="text-center" colSpan="3" rowSpan={1}>
                    {t('txt-change')}
                  </th>
                  <th className="text-center" rowSpan={2}>
                    {t('txt-label-closing-balance')}
                  </th>
                </tr>
                <tr>
                  <th>{t('txt-label-vol')}</th>
                  <th>{t('txt-avarage')}</th>
                  <th>{t('txt-giatri')}</th>
                </tr>
              </thead>
              <tbody>
                {contentShow &&
                  !!contentShow.length &&
                  contentShow.map((item, i) => (
                    <tr key={i}>
                      <td className="text-center text--light3">
                        {(page - 1) * pageSizeOption?.value + i + 1}
                      </td>
                      <td className="text-center text--light3">
                        {_formatDate2(item.trdDate)}
                      </td>
                      <td className="text-center fw-500 text--light">
                        {item.symbol}
                      </td>
                      <td className="text-left text--light3">
                        {item.descTranKnd}
                      </td>
                      <td className="text-right fw-500 text--light">
                        {numberFormat(item.beginQty, 0, '0')}
                      </td>
                      {item.inQty > 0 ? (
                        <td className="text-right fw-500 i">
                          {numberFormat(item.inQty, 0, '0')}
                        </td>
                      ) : (
                        <td className="text-right fw-500 d">
                          {'-' + numberFormat(item.outQty, 0, '0')}
                        </td>
                      )}

                      <td className="text-right fw-500 text--light">
                        {numberFormat(item.purchasePrc, 0, '-')}
                      </td>
                      <td className="text-right fw-500 text--light">
                        {item.inQty > 0
                          ? numberFormat(item.inQty * item.purchasePrc, 0, '-')
                          : numberFormat(
                              item.outQty * item.purchasePrc,
                              0,
                              '-'
                            )}
                      </td>
                      <td className="text-right fw-500 text--light">
                        {numberFormat(item.endQty, 0, '0')}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            {(!stockList || !stockList.length) && (
              <p className="text-center fz_14 mt-2 title-time">
                {t('txt-no-data-found')}
              </p>
            )}
          </Page>
          {stockList && (
            <div className="pb-5">
              <PagingCustom
                handleNextPage={() => handleNextPage(1)}
                handlePrevPage={() => handleNextPage(-1)}
                page={page}
                totalPage={totalPage}
                continued={continued}
                t
                hasPageSize={true}
                pageSizeOption={pageSizeOption}
                _handleSetPageSize={setPageSizeOption}
                pageSizeOptions={pageSizeOptions}
              />
            </div>
          )}
        </Col>
        <Page id="sao-ke-giao-dich-chung-khoan" style={{ display: 'none' }}>
          <div className="d-flex flex-column bg-light">
            <div
              className="position-relative d-flex justify-content-center align-items-center"
              style={{ height: '75px' }}
            >
              <img
                src={logo}
                alt="logo"
                className="position-absolute"
                style={{ top: '0', left: '0' }}
              />
              <span className="fz_16 fw-500 text-uppercase">
                {'SAO KÊ GIAO DỊCH CHỨNG KHOÁN'}
              </span>
            </div>
            <div className="d-flex mt-2">
              <Col className="d-flex flex-column">
                <div className="d-flex">
                  <span className="fz_14 w_125">{'Tên BC:'}</span>
                  <span className="fz_14 f_i">
                    {'Sao kê giao dịch chứng khoán'}
                  </span>
                </div>
                <div className="d-flex">
                  <span className="fz_14 w_125">{'Số tiểu khoản:'}</span>
                  <span className="fz_14 f_i">
                    {account && account.subAcntNo}
                  </span>
                </div>
                <div className="d-flex">
                  <span className="fz_14 w_125">{'Số VSD:'}</span>
                  <span className="fz_14 f_i">
                    {account && account.vsdAcntNo}
                  </span>
                </div>
              </Col>
              <Col className="d-flex flex-column">
                <div className="d-flex">
                  <span className="fz_14 w_125">{'Mã CK:'}</span>
                  <span className="fz_14 f_i">Tất cả</span>
                </div>
                <div className="d-flex">
                  <span className="fz_14 w_125">{'Từ ngày:'}</span>
                  <span className="fz_14 f_i">
                    {fromDate && moment(fromDate).format('DD/MM/YYYY')}
                  </span>
                </div>
                <div className="d-flex">
                  <span className="fz_14 w_125">{'Đến ngày:'}</span>
                  <span className="fz_14 f_i">
                    {toDate && moment(toDate).format('DD/MM/YYYY')}
                  </span>
                </div>
              </Col>
            </div>
          </div>
          <div className="d-flex bg-light">
            <table className="table table-bordered mt-2 table-light">
              <thead>
                <tr>
                  <th className="text-center">{'STT'}</th>
                  <th className="text-left">{'Thời gian'}</th>
                  <th className="text-left">{'Mã CK'}</th>
                  <th className="text-left">{'Nghiệp vụ'}</th>
                  <th className="text-right">{'Số dư đầu kỳ'}</th>
                  <th className="text-center high-light" colSpan="3">
                    {'Phát sinh tăng'}
                  </th>
                  <th className="text-center high-light-down" colSpan="3">
                    {'Phát sinh giảm'}
                  </th>
                  <th className="text-right">{'Số dư cuối kỳ'}</th>
                </tr>
                <tr>
                  <th colSpan="5"></th>
                  <th className="high-light">KL tăng</th>
                  <th className="high-light">Giá TB</th>
                  <th className="high-light">Tổng giá trị</th>
                  <th className="high-light-down">KL giảm</th>
                  <th className="high-light-down">Giá TB</th>
                  <th className="high-light-down">Tổng giá trị</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {stockList &&
                  stockList &&
                  !!stockList.length &&
                  [...stockList].reverse().map((item, i) => {
                    return (
                      <tr key={i}>
                        <td className="text-center">{i + 1}</td>
                        <td className="text-left">
                          {_formatDate2(item.trdDate)}
                        </td>
                        <td className="text-left">{item.symbol}</td>
                        <td className="text-left">{item.descTranKnd}</td>
                        <td className="text-right">
                          {numberFormat(item.beginQty, 0, '0')}
                        </td>
                        <td className="text-right high-light">
                          {numberFormat(item.inQty, 0, '0')}
                        </td>
                        <td className="text-right high-light">
                          {item.inQty > 0
                            ? numberFormat(item.purchasePrc, 0, '-')
                            : '-'}
                        </td>
                        <td className="text-right high-light">
                          {item.inQty > 0
                            ? numberFormat(
                                item.inQty * item.purchasePrc,
                                0,
                                '-'
                              )
                            : '-'}
                        </td>
                        <td className="text-right high-light-down">
                          {numberFormat(item.outQty, 0, '0')}
                        </td>
                        <td className="text-right high-light-down">
                          {item.outQty > 0
                            ? numberFormat(item.purchasePrc, 0, '-')
                            : '-'}
                        </td>
                        <td className="text-right high-light-down">
                          {item.outQty > 0
                            ? numberFormat(
                                item.outQty * item.purchasePrc,
                                0,
                                '-'
                              )
                            : '-'}
                        </td>
                        <td className="text-right">
                          {numberFormat(item.endQty, 0, '0')}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </Page>
      </PerfectScrollBar>
    );
  }
);
export default SecuritiesStatement;
