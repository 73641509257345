import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import {
  makeGetToken,
  makeGetListAccount,
  makeGetAuth,
  makeGetGainLossList,
} from '../../lib/selector';
import PerfectScrollBar from 'react-perfect-scrollbar';
import {
  numberFormat,
  _convertTpToPrice,
  _getOrderStatus,
  _formatDate2,
} from '../../util';

import { translate } from 'react-i18next';
import CongNo from './layout/formCongNo';
import { v4 as uuidv4 } from 'uuid';
import { Col } from 'react-bootstrap';
import FormSearchSKLL from './layout/formSearchSKLL';
import PrintButton from '../print/PrintButton';
import PaginationTable from '../PaginationTable';
import { take, takeRight, isEqual, find, sortBy, sumBy, isEmpty } from 'lodash';
import Page from '../print/Page';
import logo from '../../assets/img/pinetreeSecurities.svg';
import ExportSaoKeLaiLo from '../../lib/exports/SaoKeLaiLo';

class SaoKeLaiLo extends React.Component {
  state = {
    params: null,
  };

  saveParams = (params) => {
    // console.log(params)
    this.setState({ params });
  };

  render() {
    const { account, t, gainLossList } = this.props;
    const { params } = this.state;
    const columns = [
      {
        key: '_index',
        text: t('txt-stt'),
        className: 'text-center',
        align: 'center',
      },
      {
        key: 'trdDate',
        text: t('txt-time'),
        className: 'text-left',
        align: 'center',
        cell: (record) => {
          return _formatDate2(record.trdDate);
        },
      },
      {
        key: 'ordrNo',
        text: t('txt-order-no'),
        className: 'text-left',
        align: 'center',
      },
      {
        key: 'buySelTp',
        text: `${t('txt-buy')}/${t('txt-sell')}`,
        className: 'text-center',
        align: 'center',
        cell: (record) => {
          if (record.buySelTp === '1')
            return (
              <div
                className={`text-uppercase mx-auto trade_side_${record.buySelTp}`}
              >
                {t('txt-buy')}
              </div>
            );
          else
            return (
              <div
                className={`text-uppercase mx-auto trade_side_${record.buySelTp}`}
              >
                {t('txt-sell')}
              </div>
            );
        },
      },
      {
        key: 'symbol',
        text: t('txt-symbol'),
        className: 'text-left',
        align: 'center',
      },
      {
        key: 'matchedQty',
        text: t('txt-match-vol'),
        className: 'text-right',
        align: 'center',
        cell: (record) => {
          return numberFormat(record.matchedQty, 0, '0');
        },
      },
      {
        key: 'matchedPrc',
        text: t('txt-match-price'),
        className: 'text-right',
        align: 'center',
        cell: (record) => {
          return numberFormat(record.matchedPrc, 0, '0');
        },
      },
      {
        key: 'matchedFee',
        text: t('txt-label-trading-fee'),
        className: 'text-right',
        align: 'center',
        cell: (record) => {
          return numberFormat(record.matchedFee, 0, '0');
        },
      },
      {
        key: 'selFee',
        text: t('txt-label-sell-fee'),
        className: 'text-right',
        align: 'center',
        cell: (record) => {
          return numberFormat(record.selFee, 0, '0');
        },
      },
      {
        key: 'matchedTax',
        text: t('txt-label-tax'),
        className: 'text-right',
        align: 'center',
        cell: (record) => {
          return numberFormat(record.matchedTax, 0, '0');
        },
      },
      {
        key: 'divdDltx',
        text: t('txt-label-dividend-tax'),
        className: 'text-right',
        align: 'center',
        cell: (record) => {
          return numberFormat(record.divdDltx, 0, '0');
        },
      },
      {
        key: 'costPrice', // giá TB
        text: t('lbl-cost-at-the-time-of-sale'),
        className: 'text-right',
        align: 'center',
        cell: (record) => {
          return numberFormat(record.costPrice, 0, '0');
        },
      },
      {
        key: 'purcAmt', // giá vốn
        text: t('lbl-buy-amount'),
        className: 'text-right',
        align: 'center',
        style: { width: '10%' },
        cell: (record) => {
          return numberFormat(record.purcAmt, 0, '0');
        },
      },
      {
        key: 'setlAmt', // giá trị bán khớp sau trừ thuế phí
        text: t('txt-match-price-after-tax'),
        className: 'text-right',
        align: 'center',
        cell: (record) => {
          return numberFormat(record.setlAmt, 0, '0');
        },
      },
      {
        key: 'plRate',
        text: '%' + t('lbl-gain-loss'),
        className: (record) => {
          if (record.plRate > 0) return 'text-right i';
          else if (record.plRate == 0) return 'text-right r';
          else return 'text-right d';
        },
        align: 'center',
        style: { width: '10%' },
        cell: (record) => {
          return numberFormat(record.plRate, 2, '0') + ' %';
        },
      },
      {
        key: 'plAmt',
        text: t('lbl-gain-loss'),
        className: (record) => {
          if (record.plAmt > 0) return 'text-right i';
          else if (record.plAmt == 0) return 'text-right r';
          else return 'text-right d';
        },
        style: { width: '10%' },
        align: 'center',
        cell: (record) => {
          return numberFormat(record.plAmt, 0, '0');
        },
      },
    ];

    const totalColumns = [
      {
        text: t('txt-label-total'), // tổng cộng
        className: 'text-center high-light fw-500 fz_16',
        align: 'center',
        colSpan: '12',
      },
      {
        key: 'totPurcAmt', // Tổng giá trị vốn
        className: 'text-right high-light fw-500 fz_16',
        align: 'center',
        cell: (record) => {
          return numberFormat(record.totPurcAmt, 0, '0');
        },
      },
      {
        key: 'totSetlAmt', // Tổng giá trị khớp đã trừ thuế phí
        className: 'text-right high-light fw-500 fz_16',
        align: 'center',
        cell: (record) => {
          return numberFormat(record.totSetlAmt, 0, '0');
        },
      },
      {
        key: 'totplRate', // Tổng % lãi lỗ
        className: (record) => {
          if (record.totplRate > 0)
            return 'text-right high-light fw-500 fz_16 i';
          else if (record.totplRate == 0)
            return 'text-right high-light fw-500 fz_16 r';
          else return 'text-right high-light fw-500 fz_16 d';
        },
        align: 'center',
        cell: (record) => {
          return numberFormat(record.totplRate, 2, '0') + ' %';
        },
      },
      {
        key: 'totplAmt', // Tổng lãi lỗ
        className: (record) => {
          if (record.totplAmt > 0)
            return 'text-right high-light fw-500 fz_16 i';
          else if (record.totplAmt == 0)
            return 'text-right high-light fw-500 fz_16 r';
          else return 'text-right high-light fw-500 fz_16 d';
        },
        align: 'center',
        cell: (record) => {
          return numberFormat(record.totplAmt, 0, '0');
        },
      },
    ];

    console.log('gainLossList =====> ', gainLossList);

    const totalShow = find(gainLossList, (x) => x.symbol == 'TOTAL');
    const contentShow = gainLossList
      ? gainLossList.filter((x) => x.symbol != 'TOTAL')
      : [];

    return (
      <>
        <PerfectScrollBar style={{ height: 'calc( 100% - 105px )' }}>
          <div className="d-flex">
            <Col className="d-flex flex-column">
              <div className="d-flex mt-3 mb-2 position-relative">
                <span
                  className="text-white mx-auto"
                  style={{ fontSize: '18px' }}
                >
                  {t('txt-label-gain-loss')}
                </span>
              </div>
              <FormSearchSKLL account={account} saveParams={this.saveParams} />
              <div className="d-flex justify-content-between align-items-center mt-3 mb-2">
                <label className="text-white ml-2 fz_14 text-uppercase">
                  {t('txt-detail')}
                </label>
                <div className="d-flex align-items-baseline">
                  <ExportSaoKeLaiLo
                    csvData={contentShow}
                    fileName={t('txt-label-gain-loss')}
                    fromDate={params && _formatDate2(params.data.fromDate)}
                    toDate={params && _formatDate2(params.data.toDate)}
                    accountCode={account && account.subAcntNo}
                    accountName={account && account.vsdAcntNo}
                    symbol={params && (params.data.symbol || t('txt-all'))}
                  />
                  <PrintButton id={t('txt-label-gain-loss')} />
                </div>
              </div>
              <PaginationTable
                tblClassName="tbl-list"
                divClassName=""
                pageSize={15}
                columns={columns}
                source={gainLossList}
                totalColumns={totalColumns}
              />

              <Page id={t('txt-label-gain-loss')} style={{ display: 'none' }}>
                <div className="d-flex flex-column bg-light">
                  <div
                    className="position-relative d-flex justify-content-center align-items-center"
                    style={{ height: '75px' }}
                  >
                    <img
                      src={logo}
                      alt="logo"
                      className="position-absolute"
                      style={{ top: '0', left: '0' }}
                    />
                    <span className="fz_16 fw-500 text-uppercase">
                      {t('txt-label-gain-loss')}
                    </span>
                  </div>
                  <div className="d-flex mt-2">
                    <Col className="d-flex flex-column">
                      <div className="d-flex">
                        <span className="fz_14 w_125">
                          {t('lbl-report-name') + ': '}
                        </span>
                        <span className="fz_14 f_i">
                          {t('txt-label-gain-loss')}
                        </span>
                      </div>
                      <div className="d-flex">
                        <span className="fz_14 w_125">
                          {t('lbl-subAccount') + ': '}
                        </span>
                        <span className="fz_14 f_i">
                          {account && account.subAcntNo}
                        </span>
                      </div>
                      <div className="d-flex">
                        <span className="fz_14 w_125">
                          {t('txt-label-vsd-no') + ': '}
                        </span>
                        <span className="fz_14 f_i">
                          {account && account.vsdAcntNo}
                        </span>
                      </div>
                    </Col>
                    <Col className="d-flex flex-column">
                      <div className="d-flex">
                        <span className="fz_14 w_125">
                          {t('txt-label-from') + ': '}
                        </span>
                        <span className="fz_14 f_i">
                          {params && _formatDate2(params.data.fromDate)}
                        </span>
                      </div>
                      <div className="d-flex">
                        <span className="fz_14 w_125">
                          {t('txt-label-to') + ': '}
                        </span>
                        <span className="fz_14 f_i">
                          {params && _formatDate2(params.data.toDate)}
                        </span>
                      </div>
                      <div className="d-flex">
                        <span className="fz_14 w_125">
                          {t('txt-symbol') + ': '}
                        </span>
                        <span className="fz_14 f_i">
                          {params && (params.data.symbol || t('txt-all'))}
                        </span>
                      </div>
                    </Col>
                  </div>
                </div>
                <div className="d-flex bg-light">
                  <table className="table table-bordered mt-2 table-light">
                    <thead>
                      <tr style={{ border: '1px solid #e8e8e8' }}>
                        <th className="text-center text-white">
                          {t('txt-stt')}
                        </th>
                        <th className="text-center text-white">
                          {t('txt-label-tran-date')}
                        </th>
                        <th className="text-center text-white">
                          {t('txt-order-no')}
                        </th>
                        <th className="text-center text-white">{`${t(
                          'txt-buy'
                        )}/${t('txt-sell')}`}</th>
                        <th className="text-center text-white">
                          {t('txt-symbol')}
                        </th>
                        <th className="text-center text-white">
                          {t('txt-match-vol')}
                        </th>
                        <th className="text-center text-white">
                          {t('txt-match-price')}
                        </th>
                        <th className="text-center text-white">
                          {t('txt-label-trading-fee')}
                        </th>
                        <th className="text-center text-white">
                          {t('txt-label-sell-fee')}
                        </th>
                        <th className="text-center text-white">
                          {t('txt-label-tax')}
                        </th>
                        <th className="text-center text-white">
                          {t('txt-label-dividend-tax')}
                        </th>
                        <th className="text-center text-white">
                          {t('lbl-cost-at-the-time-of-sale')}
                        </th>
                        <th
                          className="text-center text-white"
                          style={{ width: '10%' }}
                        >
                          {t('lbl-buy-amount')}
                        </th>
                        <th className="text-center text-white">
                          {t('txt-match-price-after-tax')}
                        </th>
                        <th
                          className="text-center text-white"
                          style={{ width: '10%' }}
                        >
                          {'% ' + t('txt-gain-loss')}
                        </th>
                        <th
                          className="text-center text-white"
                          style={{ width: '10%' }}
                        >
                          {t('txt-gain-loss')}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {contentShow &&
                        contentShow.length &&
                        contentShow.map((item, i) => {
                          return (
                            <tr style={{ border: '1px solid #e8e8e8' }} key={i}>
                              <td className="text-center">{i + 1}</td>
                              <td className="text-left">
                                {_formatDate2(item.trdDate)}
                              </td>
                              <td className="text-right">{item.ordrNo}</td>
                              <td className="text-center">
                                {item.buySelTp === '1' ? (
                                  <div
                                    className={`text-uppercase mx-auto trade_side_${item.buySelTp}`}
                                  >
                                    {t('txt-buy')}
                                  </div>
                                ) : (
                                  <div
                                    className={`text-uppercase mx-auto trade_side_${item.buySelTp}`}
                                  >
                                    {t('txt-sell')}
                                  </div>
                                )}
                              </td>
                              <td className="text-right">{item.symbol}</td>
                              <td className="text-right">
                                {numberFormat(item.matchedQty, 0, '0')}
                              </td>
                              <td className="text-right">
                                {numberFormat(item.matchedPrc, 0, '0')}
                              </td>
                              <td className="text-right">
                                {numberFormat(item.matchedFee, 0, '0')}
                              </td>
                              <td className="text-right">
                                {numberFormat(item.selFee, 0, '0')}
                              </td>
                              <td className="text-right">
                                {numberFormat(item.matchedTax, 0, '0')}
                              </td>
                              <td className="text-right">
                                {numberFormat(item.divdDltx, 0, '0')}
                              </td>
                              <td className="text-right">
                                {numberFormat(item.costPrice, 0, '0')}
                              </td>
                              <td className="text-right">
                                {numberFormat(item.purcAmt, 0, '0')}
                              </td>
                              <td className="text-right">
                                {numberFormat(item.setlAmt, 0, '0')}
                              </td>
                              <td className="text-right">
                                {numberFormat(item.plRate, 2, '0') + ' %'}
                              </td>
                              <td className="text-right">
                                {numberFormat(item.plAmt, 0, '0')}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                    {totalShow && (
                      <tfoot>
                        <tr style={{ border: '1px solid #e8e8e8 ' }}>
                          <td className="fw-500 text-center" colSpan="12">
                            {t('txt-label-total')}
                          </td>
                          <td className="text-right fw-500">
                            {numberFormat(totalShow.totPurcAmt, 0, '0')}
                          </td>
                          <td className="text-right fw-500">
                            {numberFormat(totalShow.totSetlAmt, 0, '0')}
                          </td>
                          <td className="text-right fw-500">
                            {numberFormat(totalShow.totplRate, 2, '0') + ' %'}
                          </td>
                          <td className="text-right fw-500">
                            {numberFormat(totalShow.totplAmt, 0, '0')}
                          </td>
                        </tr>
                      </tfoot>
                    )}
                  </table>
                </div>

                {(!gainLossList || !gainLossList.length) && (
                  <p className="text-center fz_14 mt-2 title-time">
                    {t('txt-no-data-found')}
                  </p>
                )}
              </Page>
            </Col>
          </div>
        </PerfectScrollBar>
      </>
    );
  }
}

const makeMapStateToProps = () => {
  const getGainLossList = makeGetGainLossList();

  const mapStateToProps = (state) => {
    return {
      gainLossList: getGainLossList(state),
    };
  };
  return mapStateToProps;
};

export default connect(makeMapStateToProps)(
  translate('translations')(SaoKeLaiLo)
);
