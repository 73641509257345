import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { ReactReduxContext, useDispatch, useSelector } from 'react-redux';
import {
  formatToNewDate,
  getMessageError,
  numberFormat,
  StringToInt,
} from '../../../../../util';
import { constants } from '../../../../../util/constant';
import PackItem from './packItem';
import { v4 as uuidv4 } from 'uuid';
import { getEstPFPlanRequest, openCreatePlan } from '../../../action';
import moment from 'moment';
import RadioButton from '../../../../../components/radioButton';
import { setAuth, setToast, unsetClient } from '../../../../client/actions';
import { handleApiErrors } from '../../../../../lib/api-errors';
import {
  Logout,
  logoutRequest,
} from '../../../../../components/modal/login/actions';
import { removeCookie } from '../../../../../lib/storages';
import Contract from '../../contract';
import PerfectScrollBar from 'react-perfect-scrollbar';
import ReactSelect from 'react-select';
const appUrl = `${process.env.REACT_APP_API_URL}`;

const WEEK_DAY = [
  { value: 1, label: 'time.dayOfWeek.monday' },
  { value: 2, label: 'time.dayOfWeek.tuesday' },
  { value: 3, label: 'time.dayOfWeek.wednesday' },
  { value: 4, label: 'time.dayOfWeek.thursday' },
  { value: 5, label: 'time.dayOfWeek.friday' },
  { value: 6, label: 'time.dayOfWeek.saturday' },
  { value: 7, label: 'time.dayOfWeek.sunday' },
];

const CreateBondPlan = ({ t, openCreatePlanData }) => {
  const dispatch = useDispatch();
  const currentDate = new Date();
  const { estPFPlan, currentPinefolio } = useSelector(
    (state) => state.pinefolio
  );
  const { store } = useContext(ReactReduxContext);
  const { token, auth } = useSelector((state) => state.client);
  const theme = useSelector((state) => state.client.currentTheme);
  const { defaultAccount } = useSelector((state) => state.socket);
  const listAccounts = token?.custInfo.normal;
  const account = listAccounts?.find(
    (item) => item.subAcntNo.startsWith('P') && item.subAcntStat == 1
  );
  const [tabActive, setTabActive] = useState(
    constants.pinefolio.createPlan.FULL_SET
  );
  const [showModal, setShowModal] = useState();
  const [reminder, setReminder] = useState('N');
  const [dayReminder, setDayReminder] = useState();
  const [contracts, setContracts] = useState();
  const [confirmCreatePlan, setConfirmCreatePlan] = useState(false);
  const [dayOfWeekSelected, setDayOfWeekSelected] = useState(WEEK_DAY[0]);
  const [estPlan, setEstPlan] = useState([]);
  const [reload, setReload] = useState(false);

  function onCloseModal() {
    setShowModal(false);
    dispatch(openCreatePlan({ openModal: false }));
  }

  function getPlanContracts() {
    const uuid = uuidv4();
    let stDate = '';
    const invtPlanList = estPlan.map((est, index) => {
      const params = {
        packNo: est.packNo,
        startDt: stDate == '' ? moment(new Date()).format('YYYYMMDD') : stDate,
        endDt: est.buyDt,
      };
      stDate = moment(formatToNewDate(est.buyDt)).format('YYYYMMDD');
      return params;
    });
    if (token && auth) {
      const params = {
        group: 'CORE',
        cmd: 'getPFPlanCntr',
        rqId: uuid,
        channel: 'WTS',
        session: token.session,
        user: token.user,
        type: auth.type,
        token: auth.token,

        data: {
          cif: token.cif,
          acntNo: defaultAccount?.acntNo,
          subAcntNo: defaultAccount?.subAcntNo,
          pfCode: currentPinefolio?.pfCode,
          invtPlanList: invtPlanList,
          depoTp: 'B',
          rmdTp: reminder,
          rmdVal:
            reminder == 'N'
              ? 0
              : reminder == 'M'
              ? StringToInt(dayReminder)
              : dayOfWeekSelected.value,
        },
      };

      const url = `${appUrl}/PortfolioServlet.pt`;
      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
        body: JSON.stringify(params),
      })
        .then(handleApiErrors)
        .then((response) => response.json())
        .then((res) => {
          if (res.http !== 200) {
            if (res.http === 401) {
              if (token) dispatch(logoutRequest(token));
              dispatch(unsetClient());
              removeCookie('token');
              removeCookie('authen');
              dispatch(Logout());
              toastHandle(t('txt-valid-loss-session'));
            } else {
              toastHandle(getMessageError(res, store.getState));
            }
          } else {
            // success
            const data = res.data;
            if (data && data.length > 0) {
              setContracts(data);
              setConfirmCreatePlan(true);
            }
          }
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }
  }

  const handleGetEstPlanDirect = (
    packId = '0',
    stDepoDt = moment(new Date()).format('YYYYMMDD'),
    buyDt = moment(
      new Date(currentDate.setDate(currentDate.getDate() + 30))
    ).format('YYYYMMDD')
  ) => {
    if (!token) return;
    const uuid = uuidv4();
    const params = {
      group: 'CORE',
      user: token.user,
      session: token.session,
      cmd: 'getEstPFPlan',
      rqId: uuid,
      channel: 'WTS',
      data: {
        pfCode: currentPinefolio.pfCode,
        packNo: tabActive == 1 ? packId.toString() : '0',
        stDepoDt: stDepoDt,
        buyDt: buyDt,
        depoTm: '1',
      },
    };
    const url = `${appUrl}/PortfolioServlet.pt`;
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
      body: JSON.stringify(params),
    })
      .then(handleApiErrors)
      .then((response) => response.json())
      .then((res) => {
        if (res.http == 200) {
          if (packId > 0 && tabActive == 1) {
            let _params = [...estPlan.slice(0, packId - 1), ...res.data];
            setEstPlan(_params);
          } else {
            setEstPlan(res.data);
          }
          setReload(!reload);
        } else {
          if (res.http === 401) {
            if (token) dispatch(logoutRequest(token));
            dispatch(unsetClient());
            removeCookie('token');
            removeCookie('authen');
            dispatch(Logout());
            const toastMsg = {
              id: Math.random(),
              msg: t('txt-valid-loss-session'),
              title: t('txt-notice'),
            };
            dispatch(setToast(toastMsg));
          } else {
            const msgErr = getMessageError(res, store.getState);
            const toastMsg = {
              id: Math.random(),
              msg: msgErr,
              title: t('txt-notice'),
            };
            dispatch(setToast(toastMsg));
          }
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  useEffect(() => {
    if (openCreatePlanData.openModal == true) {
      setShowModal(true);
    }
  }, [openCreatePlanData]);

  const toastHandle = (msg) => {
    const toastMsg = {
      id: Math.random(),
      msg: msg,
      title: t('txt-notice'),
    };
    dispatch(setToast(toastMsg));
  };

  const handleCreatePlan = () => {
    let stDate = '';
    const invtPlanList = estPlan.map((est, index) => {
      const params = {
        packNo: est.packNo,
        startDt: stDate == '' ? moment(new Date()).format('YYYYMMDD') : stDate,
        endDt: est.buyDt,
      };
      stDate = moment(formatToNewDate(est.buyDt)).format('YYYYMMDD');
      return params;
    });

    if (!token) return;
    const uuid = uuidv4();
    const params = {
      group: 'CORE',
      user: token.user,
      session: token.session,
      type: auth.type,
      token: auth.token,
      cmd: 'createPFIP',
      rqId: uuid,
      channel: 'WTS',
      data: {
        cif: token.cif,
        acntNo: account?.acntNo,
        subAcntNo: account?.subAcntNo,
        pfCode: currentPinefolio?.pfCode,
        invtPlanList: invtPlanList,
        depoTp: 'B',
        rmdTp: reminder,
        rmdVal:
          reminder == 'N'
            ? 0
            : reminder == 'M'
            ? StringToInt(dayReminder)
            : dayOfWeekSelected.value,
      },
    };
    const url = `${appUrl}/PortfolioServlet.pt`;
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
      body: JSON.stringify(params),
    })
      .then(handleApiErrors)
      .then((response) => response.json())
      .then((res) => {
        if (res.http == 200) {
          const toastMsg = {
            id: Math.random(),
            msg: `${t('pinefolio.plan.planId')} ${res.data.planId} ${t(
              'txt-title-neworder-success'
            )}`,
            title: t('txt-notice'),
          };
          setConfirmCreatePlan(false);
          dispatch(setToast(toastMsg));
        } else {
          if (res.http === 401) {
            if (token) dispatch(logoutRequest(token));
            dispatch(unsetClient());
            removeCookie('token');
            removeCookie('authen');
            dispatch(Logout());
            const toastMsg = {
              id: Math.random(),
              msg: t('txt-valid-loss-session'),
              title: t('txt-notice'),
            };
            dispatch(setToast(toastMsg));
          } else {
            const msgErr = getMessageError(res, store.getState);
            const toastMsg = {
              id: Math.random(),
              msg: msgErr,
              title: t('txt-notice'),
            };
            dispatch(setToast(toastMsg));
          }
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  useEffect(() => {
    if (tabActive == constants.pinefolio.createPlan.SPLIT_SET) {
      handleGetEstPlanDirect(1);
    } else {
      handleGetEstPlanDirect(0);
    }
  }, [showModal, tabActive]);

  return (
    <>
      <Modal
        show={showModal}
        dialogClassName="wts-modal"
        backdropClassName="wts-modal-backdrop z-index--lv2"
        contentClassName={`${
          theme ? theme.name : 'dark'
        } plan-modal plan-modal--hsm wts-modal-content wts-modal-content--lg2`}
        onEscapeKeyDown={() => onCloseModal()}
        centered
        onHide={() => onCloseModal()}
        className="z-index--lv2"
      >
        <div className="plan-modal-layout">
          <div className="plan-modal__header">
            <div className="plan-modal__header--left">
              <div className="title">
                <div className="d-flex btn--icon">
                  <span
                    className="icon iPrevious"
                    onClick={() => onCloseModal()}
                  ></span>
                </div>
                <span className="fz_16 text--light fw-500">
                  {`${t('pinefolio.plan.createPlan.createPlanTitle')} ${
                    currentPinefolio.pfCode
                  } ${currentPinefolio.pfThemeNm}`}
                </span>
              </div>
              <div className="header__tabs header__tabs--type1">
                <div
                  className={`header__tab header__tab--hug ${
                    tabActive == constants.pinefolio.createPlan.FULL_SET
                      ? 'active'
                      : ''
                  }`}
                  onClick={() =>
                    setTabActive(constants.pinefolio.createPlan.FULL_SET)
                  }
                >
                  <span>{t('pinefolio.plan.fullSet')}</span>
                </div>
                <div
                  className={`header__tab header__tab--hug ${
                    tabActive == constants.pinefolio.createPlan.SPLIT_SET
                      ? 'active'
                      : ''
                  }`}
                  onClick={() =>
                    setTabActive(constants.pinefolio.createPlan.SPLIT_SET)
                  }
                >
                  <span>{t('pinefolio.plan.splitSet')}</span>
                </div>
              </div>
            </div>
            <div className="plan-modal__header--right">
              <div className="table-interest-rate">
                <span className="fz_13 text--light3 text-center">
                  {' '}
                  {t('pinefolio.plan.supportInterestRate')}
                </span>
              </div>
            </div>
          </div>
          <div className="plan-modal__body">
            <div className="plan-modal__body--left">
              <PerfectScrollBar>
                <div className="d-flex flex-column gap-48 mr-3" key={reload}>
                  {estPlan &&
                    estPlan.length > 0 &&
                    estPlan.map((item, index) => (
                      <PackItem
                        pf={currentPinefolio}
                        pack={item}
                        packIndex={index}
                        isFullPack={tabActive == 0}
                        getEstPFPlanFlow={handleGetEstPlanDirect}
                        key={index}
                      />
                    ))}
                </div>
              </PerfectScrollBar>
            </div>
            <div className="plan-modal__body--right">
              <div className="reminder-block">
                <div className="d-flex gap-4 flex-column">
                  <span className="fz_13 fw-500 text--light">
                    {t('pinefolio.plan.createPlan.getReminder.name')}
                  </span>
                  <span className="fz_13 text--light3 d-flex justify-content-between w-100">
                    <div
                      className="d-flex gap-8 align-items-center"
                      onClick={() => {
                        setReminder('N');
                        setDayReminder('');
                      }}
                    >
                      <div className="text-center w-32p">
                        <RadioButton isReadonly isActive={reminder == 'N'} />
                      </div>
                      <span>
                        {t('pinefolio.plan.createPlan.getReminder.none')}
                      </span>
                    </div>
                    <div
                      className="d-flex gap-8 align-items-center"
                      onClick={() => setReminder('W')}
                    >
                      <div className="text-center w-32p">
                        <RadioButton isReadonly isActive={reminder == 'W'} />
                      </div>
                      <span>
                        {t('pinefolio.plan.createPlan.getReminder.weekly')}
                      </span>
                    </div>
                    <div
                      className="d-flex gap-8 align-items-center"
                      onClick={() => setReminder('M')}
                    >
                      <div className="text-center w-32p">
                        <RadioButton isReadonly isActive={reminder == 'M'} />
                      </div>
                      <span>
                        {t('pinefolio.plan.createPlan.getReminder.monthly')}
                      </span>
                    </div>
                  </span>
                </div>
                <div className="d-flex gap-4 flex-column">
                  {reminder != 'N' &&
                    (reminder == 'M' ? (
                      <>
                        <span className="fz_13 fw-500 text--light">
                          {t('pinefolio.plan.createPlan.monthlyDay.name')}
                        </span>
                        <div className="input-text-search">
                          <input
                            type="text"
                            maxlength="2"
                            placeholder={t(
                              'pinefolio.plan.createPlan.monthlyDay.placeHolder'
                            )}
                            onChange={(event) =>
                              setDayReminder(event.target.value)
                            }
                            value={dayReminder}
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        <span className="fz_13 fw-500 text--light">
                          {t('pinefolio.plan.createPlan.weeklyDay.name')}
                        </span>
                        <div className="input-text-search">
                          <ReactSelect
                            defaultValue={dayOfWeekSelected}
                            className="filter-control-select"
                            classNamePrefix="filter-control-select"
                            options={WEEK_DAY}
                            getOptionLabel={(option) => t(option.label)}
                            getOptionValue={(option) => option.value}
                            value={dayOfWeekSelected}
                            onChange={(value) => setDayOfWeekSelected(value)}
                            isSearchable={false}
                            components={{
                              IndicatorSeparator: () => null,
                            }}
                          />
                        </div>
                      </>
                    ))}
                </div>
              </div>
              <div className="pinefolio-actions">
                {auth?.isVerified ? (
                  <div
                    className="btn btn--primary pinefolio-action w-75"
                    onClick={() => getPlanContracts()}
                  >
                    {t('pinefolio.plan.createPlan.name')}
                  </div>
                ) : (
                  <div
                    className="btn btn--primary pinefolio-action w-75"
                    onClick={() => dispatch(setAuth())}
                  >
                    {t('pinefolio.plan.createPlan.name')}
                  </div>
                )}
                <div
                  className="btn btn--cancel pinefolio-action w-25"
                  onClick={() => onCloseModal()}
                >
                  {t('txt-cancel')}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {confirmCreatePlan && (
        <Contract
          close={() => setConfirmCreatePlan(false)}
          isCreatePlan={true}
          orderType={1}
          _contracts={contracts}
          handleCreatePlan={handleCreatePlan}
        />
      )}
    </>
  );
};

export default CreateBondPlan;
