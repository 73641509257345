import React, { useState } from 'react';

import { FaEye, FaEyeSlash } from 'react-icons/fa';

function RenderFieldInput({
  input,
  type,
  className,
  disabled,
  placeholder,
  meta: { touched, error },
  style,
  maxLength,
  regex,
}) {
  const [typeInput, setTypeInput] = useState(type);

  const onPress = (event) => {
    if (regex) {
      var key = String.fromCharCode(
        !event.charCode ? event.which : event.charCode
      );
      if (!regex.test(key)) {
        event.preventDefault();
        return false;
      }
    }
  };

  return (
    <div className="position-relative">
      <input
        {...input}
        type={typeInput}
        disabled={disabled}
        className={className}
        placeholder={placeholder}
        style={{
          paddingRight: type === 'password' ? '2rem' : '.75rem',
          ...style,
        }}
        onKeyPress={(e) => onPress(e)}
      />
      {type === 'password' && (
        <span
          className="position-absolute"
          style={{ top: '5px', right: '10px' }}
          onClick={() =>
            setTypeInput(typeInput === 'text' ? 'password' : 'text')
          }
        >
          {typeInput === 'text' ? <FaEye /> : <FaEyeSlash />}
        </span>
      )}
      {touched && error && (
        <div
          style={{
            color: '#ff5555',
            margin: '0 0 10px',
            fontSize: '0.75rem',
          }}
        >
          {error}
        </div>
      )}
    </div>
  );
}

export default RenderFieldInput;
