import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import { Col, ListGroup } from 'react-bootstrap';
import {
  makeGetToken,
  makeGetListAccount,
  makeGetDefaultAccount,
  makeGetCashInBank,
  makeGetHoldBank,
  makeGetHoldList,
} from '../../lib/selector';

import {
  cashInBankRequest,
  holdListRequest,
} from '../../containers/cash/actions';

import { setToast, unsetClient } from '../../containers/client/actions';
import {
  numberFormat,
  formatDate,
  _formatDate2,
  getMessageError,
} from '../../util';

import { v4 as uuidv4 } from 'uuid';
import { ReloadButton } from '../btnReload';
import { WebSocketContext } from '../../containers/socket/webSocket';

import FormHoldBank from './form/formHoldBank';
import FormUnHoldBank from './form/formUnHoldBank';
import PaginationTable from '../PaginationTable';
import { translate } from 'react-i18next';
import { Logout, logoutRequest } from '../modal/login/actions';
import { removeCookie } from '../../lib/storages';
import { isEqual } from 'lodash';

class BankHold extends React.PureComponent {
  state = {
    transType: '1',
  };

  componentDidMount() {
    const { defaultAccount } = this.props;
    if (defaultAccount) {
      this._handleLoadCashInBank();
      this._handleLoadHoldList();
    }
  }

  componentDidUpdate(preProps) {
    const { defaultAccount, holdBank, t, token } = this.props;
    if (defaultAccount && !isEqual(defaultAccount, preProps.defaultAccount)) {
      this._handleLoadCashInBank();
      this._handleLoadHoldList();
    }

    if (holdBank && holdBank !== preProps.holdBank) {
      console.log(holdBank);
      if (holdBank.http !== 200) {
        if (holdBank.http === 401) {
          if (token) this.props.dispatch(logoutRequest(token));

          this.props.dispatch(unsetClient());
          removeCookie('token');
          removeCookie('authen');

          this.props.dispatch(Logout());
          this._handleToast(t('txt-valid-loss-session'));
        } else {
          this._handleToast(
            getMessageError(holdBank, this.props.store.getState)
          );
        }
      } else {
        // success
        this._handleToast(t('txt-title-hold-success'));
        this._handleLoadCashInBank();
        this._handleLoadHoldList();
      }
    }
  }

  _handleToast = (msg) => {
    const toastMsg = {
      id: Math.random(),
      msg: msg,
      title: this.props.t('txt-notice'),
    };
    this.props.dispatch(setToast(toastMsg));
  };

  _handleLoadCashInBank = () => {
    const { token, defaultAccount } = this.props;
    if (!defaultAccount) return;

    const uuid = uuidv4();
    const params = {
      group: 'CORE',
      user: token.user,
      session: token.session,
      cmd: 'InquiryCashInCoreBank',
      rqId: uuid,
      channel: 'WTS',
      data: {
        acntNo: defaultAccount.acntNo,
      },
    };

    this.props.dispatch(cashInBankRequest(params));
  };

  _handleLoadHoldList = () => {
    const { token, defaultAccount } = this.props;

    if (!defaultAccount) return;

    const uuid = uuidv4();

    const d = new Date();
    d.setDate(d.getDate() - 30);

    const params = {
      group: 'CORE',
      user: token.user,
      session: token.session,
      cmd: 'GetHoldList',
      rqId: uuid,
      channel: 'WTS',
      data: {
        acntNo: defaultAccount.acntNo,
        subAcntNo: defaultAccount.subAcntNo,
        fromDate: formatDate(d, ''),
        toDate: formatDate(new Date(), ''),
      },
    };
    this.props.dispatch(holdListRequest(params));
  };

  render() {
    const { holdList, cashInBank, defaultAccount, t } = this.props;
    console.log('holdList', holdList);
    const columns = [
      {
        key: 'subAcntNo',
        text: t('txt-account'),
        className: 'text-left',
        align: 'left',
      },
      {
        key: 'tranDate',
        text: t('txt-label-tran-date'),
        className: 'text-left',
        align: 'left',
        cell: (record) => {
          return _formatDate2(record.tranDate);
        },
      },
      {
        key: 'tranAmt',
        text: t('txt-label-tran-amount'),
        className: 'text-right',
        align: 'right',
        cell: (record) => {
          return numberFormat(record.tranAmt, 0, '-');
        },
      },
      {
        key: 'trdType',
        text: t('txt-label-tran-type'),
        className: 'text-right',
        align: 'right',
        cell: (record) => {
          return record.trdType === '1'
            ? t('txt-label-deposit')
            : t('txt-label-withdrawal');
        },
      },
      {
        text: t('txt-status'),
        className: 'text-right',
        align: 'right',
        cell: (record) => {
          return t('txt-success');
        },
      },
      {
        key: 'desc',
        text: t('txt-label-remark'),
        className: 'text-left',
        align: 'left',
      },
    ];
    return (
      <Fragment>
        <div className="d-flex flex-row">
          <Col className="d-flex flex-column pr-0">
            <div className="d-flex pl-3 mt-3">
              <label className="text-white fz_16 mr-auto">
                {t('txt-label-hold-bank')}
              </label>
              <ReloadButton
                type="button"
                className="btn-reload"
                onClick={this._handleLoadCashInBank}
              />
            </div>
            {!cashInBank ? (
              <p className="text-center text-white fz_14">
                {t('txt-no-data-found')}
              </p>
            ) : (
              <Fragment>
                <ListGroup>
                  <ListGroup.Item className="d-flex justify-content-between pl-4 pr-4">
                    <span className="fz_14 text-content">
                      {t('txt-label-bank-link')}
                    </span>
                    <span className="fz_14 text-content fw-500">
                      {cashInBank.bankCode}
                    </span>
                  </ListGroup.Item>
                  <ListGroup.Item className="d-flex justify-content-between pl-4 pr-4">
                    <span className="fz_14 text-content">
                      {t('txt-label-account-link')}
                    </span>
                    <span className="fz_14 text-content fw-500">
                      {cashInBank.bankAcntNo}
                    </span>
                  </ListGroup.Item>
                  <ListGroup.Item className="d-flex justify-content-between pl-4 pr-4">
                    <span className="fz_14 text-content">
                      {t('txt-balance')}
                    </span>
                    <span className="fz_14 text-content fw-500">
                      {numberFormat(cashInBank.totBal, 0, '0')}
                    </span>
                  </ListGroup.Item>
                  <ListGroup.Item className="d-flex justify-content-between pl-4 pr-4">
                    <span className="fz_14 text-content">
                      {t('txt-label-bank-hold-amount')}
                    </span>
                    <span className="fz_14 text-content fw-500">
                      {numberFormat(cashInBank.holdAmt, 0, '0')}
                    </span>
                  </ListGroup.Item>
                </ListGroup>
                <FormHoldBank holdType="1" handleToast={this._handleToast} />
              </Fragment>
            )}
          </Col>
          <Col className="d-flex flex-column">
            <div className="d-flex pl-3 mt-3">
              <label className="text-white fz_16 mr-auto">
                {t('txt-label-withdrawal-bank')}
              </label>
              <ReloadButton
                type="button"
                className="btn-reload"
                onClick={this._handleLoadCashInBank}
              />
            </div>
            {!cashInBank ? (
              <p className="text-center text-white fz_14">
                {t('txt-no-data-found')}
              </p>
            ) : (
              <Fragment>
                <ListGroup>
                  <ListGroup.Item className="d-flex justify-content-between pl-4 pr-4">
                    <span className="fz_14 text-content">
                      {t('txt-label-bank-link')}
                    </span>
                    <span className="fz_14 text-content fw-500">
                      {cashInBank.bankCode}
                    </span>
                  </ListGroup.Item>
                  <ListGroup.Item className="d-flex justify-content-between pl-4 pr-4">
                    <span className="fz_14 text-content">
                      {t('txt-label-account-link')}
                    </span>
                    <span className="fz_14 text-content fw-500">
                      {cashInBank.bankAcntNo}
                    </span>
                  </ListGroup.Item>
                  <ListGroup.Item className="d-flex justify-content-between pl-4 pr-4">
                    <span className="fz_14 text-content">
                      {t('txt-balance')}
                    </span>
                    <span className="fz_14 text-content fw-500">
                      {numberFormat(cashInBank.totBal, 0, '0')}
                    </span>
                  </ListGroup.Item>
                  <ListGroup.Item className="d-flex justify-content-between pl-4 pr-4">
                    <span className="fz_14 text-content">
                      {t('txt-label-bank-hold-amount')}
                    </span>
                    <span className="fz_14 text-content fw-500">
                      {numberFormat(cashInBank.holdAmt, 0, '0')}
                    </span>
                  </ListGroup.Item>
                </ListGroup>
                <FormUnHoldBank holdType="2" handleToast={this._handleToast} />
              </Fragment>
            )}
          </Col>
        </div>
        <div className="d-flex justify-content-between align-items-center mt-4">
          <label className="text-white text-uppercase ml-2 fz_14">
            {t('txt-label-history')}
          </label>
          <ReloadButton
            type="button"
            className="btn-reload mr-2"
            onClick={this._handleLoadHoldList}
          />
        </div>
        <PaginationTable
          defaultAccount={defaultAccount}
          pageSize={15}
          columns={columns}
          source={holdList && holdList.list ? holdList.list : []}
        />
      </Fragment>
    );
  }
}

BankHold.contextType = WebSocketContext;

const makeMapStateToProps = () => {
  const getToken = makeGetToken();
  const getListAccount = makeGetListAccount();
  const getDefaultAccount = makeGetDefaultAccount();

  const getCashInBank = makeGetCashInBank();
  const getHoldBank = makeGetHoldBank();
  const getHoldList = makeGetHoldList();

  const mapStateToProps = (state) => {
    return {
      token: getToken(state),
      listAccount: getListAccount(state),
      defaultAccount: getDefaultAccount(state),

      cashInBank: getCashInBank(state),
      holdBank: getHoldBank(state),
      holdList: getHoldList(state),
    };
  };
  return mapStateToProps;
};

export default connect(makeMapStateToProps)(
  translate('translations')(BankHold)
);
