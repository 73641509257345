import React, { useEffect, useMemo, useState } from 'react';
import ReactSelect from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import {
  setBondDetailModalRequest,
  getBondProducts,
  getBondDetail,
  setBondProductDetail,
  setProInvRequire,
  setRegistBondTradingRetailModal,
  getBondCommonRequest2,
} from '../../action';
import ScrollTable from '../../../../components/ScrollTable';
import { withNamespaces } from 'react-i18next';
import {
  numberFormat,
  _formatDate2,
  StringToInt,
  BOND_TYPE,
  INVESTOR_TYPE,
} from '../../../../util';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import TextMask from 'react-text-mask';
import { setAuth, setLogin, setToast } from '../../../client/actions.js';
import { useHistory } from 'react-router-dom';
import { requiredProInvestor, retailTradingRegisted } from '../../utils';
import { makeGetBondInfor, makeGetBondToolTip } from '../../../../lib/selector';
import { Mixpanel } from '../../../../lib/mixpanel';
import { bondConstants, constants } from '../../../../util/constant';
import { v4 as uuidv4 } from 'uuid';
import Collaterals from './collaterals';

const GBond = withNamespaces('translations')(({ t, limitInfor }) => {
  const dispatch = useDispatch();
  const {
    token,
    auth,
    setting: { lang = 'vi' },
    setting,
    currentTheme: theme,
  } = useSelector((state) => state.client);
  const { bondProducts } = useSelector((state) => state.bond);
  const bondInfor = useSelector(makeGetBondInfor());
  const bondToolTip = useSelector(makeGetBondToolTip());

  const [issuer, setIssuer] = useState({ item: 'txt-all', id: '' });
  const [bondType, setBondType] = useState({ name: 'txt-all', id: '' });
  const [investorType, setInvestorType] = useState({
    name: 'txt-all',
    id: '',
  });
  const [minInvestAmount, setMinInvestAmount] = useState('');
  const [bondIssuers, setBondIssuers] = useState([]);

  const history = useHistory();

  const bondDetailModalTypeHandle = (state) =>
    dispatch(setBondDetailModalRequest(state));

  const registBondRetailTrading = () =>
    dispatch(setRegistBondTradingRetailModal(true));

  const filterHandle = () => {
    dispatch(
      getBondProducts(
        minInvestAmount ? StringToInt(minInvestAmount) + '' : '',
        investorType.id == 1 ? 'Y' : investorType.id == 2 ? 'N' : '',
        bondType.id,
        issuer.id,
        '',
        lang,
        '0',
        constants.bondProductType.Growth
      )
    );

    Mixpanel.bond.filter(
      minInvestAmount,
      t(bondType?.id),
      '',
      t(investorType?.name),
      t(issuer?.item),
      '',
      '',
      '',
      '',
      ''
    );
  };

  const resetHandle = () => {
    setIssuer({ item: t('txt-all'), id: '' });
    setBondType({ name: t('txt-all'), id: '' });
    setInvestorType({ name: t('txt-all'), id: '' });
    setMinInvestAmount('');
    dispatch(
      getBondProducts(
        '',
        '',
        '',
        '',
        '',
        lang,
        '0',
        constants.bondProductType.Growth
      )
    );
    Mixpanel.bond.resetFilter('Product List');
  };

  const demoHandle = (product) => {
    dispatch(setBondProductDetail(product));
    history.push(
      `/home/bond/${product.bondCode}?type=${constants.bondProductType.Growth}`
    );

    Mixpanel.bond.demo(product.bondCode, product.productTp);
    // todo: call api get bond detail by id and then open demo modal by id
  };

  const buyHandle = (product) => {
    if (token) {
      if (auth && auth.isVerified) {
        const isRegistedTradingRetailStatus = setting?.pcbMapYN;
        const proIns = requiredProInvestor(token, product);

        if (
          product?.listTp == '4' &&
          (!isRegistedTradingRetailStatus ||
            isRegistedTradingRetailStatus == 'N')
        ) {
          dispatch(setRegistBondTradingRetailModal(true));
          return;
        }

        if (proIns) {
          dispatch(setProInvRequire(true));
          return;
        }

        if (product?.listTp == '4' && isRegistedTradingRetailStatus == 'P') {
          const toastMsg = {
            id: Math.random(),
            msg: t('bond.retailTrading.pendingNoti'),
            title: t('txt-notice'),
          };

          dispatch(setToast(toastMsg));
          return;
        }

        bondDetailModalTypeHandle(bondConstants.modals.BUY_ORDER);
        dispatch(getBondDetail(product.bondCode, lang));
        dispatch(setBondProductDetail(product));
        Mixpanel.bond.startOrder(
          product.bondCode,
          'Buy',
          product.productTp,
          'Bond Product List'
        );
      } else {
        dispatch(setAuth());
      }
    } else {
      dispatch(setLogin());
    }
  };

  const detailHandle = (product) => {
    Mixpanel.bond.viewTickerInfo(
      product.bondCode,
      product.productTp,
      'Bond',
      'Bond Product List'
    );
    bondDetailModalTypeHandle(bondConstants.modals.INFOR);
    dispatch(getBondDetail(product.bondCode, lang));
    dispatch(setBondProductDetail(product));
  };

  const registBondRetailTradingHandle = () => {
    if (token) {
      if (auth && auth.isVerified) {
        if (setting?.pcbMapYN == 'N') registBondRetailTrading();
        else if (setting?.pcbMapYN == 'P') {
          const toastMsg = {
            id: Math.random(),
            msg: t('bond.retailTrading.pendingNoti'),
            title: t('txt-notice'),
          };

          dispatch(setToast(toastMsg));
        } else return;
      } else {
        dispatch(setAuth());
      }
    } else {
      dispatch(setLogin());
    }
  };

  const getIssuerHandle = () => {
    const uuid = uuidv4();
    const params = {
      group: 'CORE',
      cmd: 'getBondIssuerList',
      channel: 'WTS',
      rqId: uuid,
      data: {
        langTp: lang,
        prodTp: constants.bondProductType.Growth,
      },
    };

    const callback = (data) => {
      setBondIssuers(data?.list || []);
    };

    dispatch(getBondCommonRequest2(params, callback, false));
  };

  useEffect(() => {
    if (lang) {
      dispatch(
        getBondProducts(
          minInvestAmount,
          investorType.id == 1 ? 'Y' : investorType.id == 2 ? 'N' : '',
          bondType.id,
          issuer.id,
          '',
          lang,
          '0',
          constants.bondProductType.Growth
        )
      );

      getIssuerHandle();
    }
  }, [lang]);

  const colgroup = (
      <colgroup>
        <col width="12%"></col>
        <col width="16%"></col>
        <col width="100px"></col>
        <col width="100px"></col>
        <col width="80px"></col>
        <col></col>
        <col></col>
        <col></col>
        <col></col>
        <col></col>
        <col width="150px"></col>
      </colgroup>
    ),
    columns = [
      {
        key: 'bondCode',
        text: t('txt-label-bond-code'),
        className: 'text-left position-relative fw-500 text--light',
        align: 'left',
        sortable: true,
        cell: (record) => {
          return (
            <span
              className="text-left position-relative fw-500 text--light cursor-pointer d-flex align-items-center"
              onClick={() => detailHandle(record)}
            >
              {record.bondCode}
              {record.proInvtYN == 'Y' && (
                <span className="iStar icon--sx ml-1"></span>
              )}
            </span>
          );
        },
      },
      {
        key: 'issuerNm',
        text: t('bond.issuer'),
        className: 'text-left position-relative fw-500 text--light',
        align: 'left',
        sortable: true,
      },
      {
        key: 'guaranteeYN',
        text: t('bond.tableLabel.collateral'),
        className: 'text-center position-relative text--light3 fw-500',
        sortable: true,
        cell: (record) => {
          return record.guaranteeYN == 'Y' ? (
            <Collaterals bondCode={record.bondCode} />
          ) : (
            t('txt-no')
          );
        },
      },
      {
        key: 'issueTpNm',
        text: t('bond.formOfOffering'),
        className: 'text-center position-relative text--light3 fw-500',
        sortable: true,
      },
      {
        key: 'dueDate',
        text: `${t('bond.tableLabel.maturityDate')}/${t(
          'txt-label-call-date'
        )}`,
        className: 'text-center position-relative text--light fw-500',
        sortable: true,
        cell: (record) => {
          return _formatDate2(record.dueDate);
        },
      },
      {
        key: 'intRate',
        text: (
          <div className="d-flex">{t('bond.tableLabel.yieldToMaturity')}</div>
        ),
        cell: (record) => {
          return `${record.intRate}%/${t('time.long.y')}`;
        },
        className: 'text-center position-relative r fw-500',
        sortable: true,
      },
      {
        key: 'leg1Prc',
        text: t('bond.tableLabel.buyPrice'),
        cell: (record) => {
          return numberFormat(record.leg1Prc, 0);
        },
        className: 'text-right position-relative i fw-500',
        sortable: true,
      },
      {
        key: 'minInvtQtyPerOrdr',
        text: t('bond.tableLabel.minQuantityOrder'),
        cell: (record) => {
          return numberFormat(record.minInvtQtyPerOrdr, 0);
        },
        className: 'text-right position-relative text--light3',
        sortable: true,
      },
      {
        key: 'maxInvtQtyPerCust',
        text: t('bond.tableLabel.maxInvtQtyPerCust'),
        cell: (record) => {
          return numberFormat(record.maxInvtQtyPerCust, 0);
        },
        className: 'text-right position-relative text--light3',
        sortable: true,
      },
      {
        key: 'selRemain',
        text: t('bond.tableLabel.availQuantity'),
        className: 'text-right position-relative text--light',
        sortable: true,
        cell: (record) => {
          return numberFormat(record?.selRemain, 0, '0');
        },
      },
      {
        text: t('bond.tableLabel.actions'),
        className: 'text-center',
        cell: (record) => {
          return (
            <>
              <span
                className="btn btn--demo2"
                onClick={() => demoHandle(record)}
              >
                <span>{t('bond.bondDemo.name')}</span>
              </span>
              {!token || auth?.isVerified ? (
                <span
                  className="btn btn--buy2"
                  onClick={() => buyHandle(record)}
                >
                  <span>{t('txt-buy')}</span>
                </span>
              ) : (
                <button
                  className="btn btn--authen"
                  onClick={() => dispatch(setAuth())}
                >
                  {t('txt-buy')}
                </button>
              )}
            </>
          );
        },
        sortable: false,
      },
    ];

  const proInvt = useMemo(() => {
    if (!!bondProducts?.length > 0)
      return bondProducts?.find((x) => x.proInvtYN == 'Y') || false;
    else return false;
  }, [bondProducts]);

  return (
    <>
      <div className="bond-product__header"></div>
      <div className="bond-product__body bond-layout__body">
        <div className="bond-product__body--left">
          <div className="bond-layout__filters">
            <div className="bond-layout__filter">
              <label>{t('bond.minInvestAmount.name')}</label>
              <div className="input-text-search-outer">
                <TextMask
                  className="input-text-search"
                  type="text"
                  maxLength={15}
                  value={minInvestAmount}
                  mask={createNumberMask({
                    prefix: '',
                    allowNegative: false,
                  })}
                  placeholder={t('bond.minInvestAmount.placeHolder')}
                  onFocus={(e) => e.currentTarget.select()}
                  onChange={(e) => setMinInvestAmount(e.target.value)}
                />
              </div>
            </div>
            <div className="bond-layout__filter">
              <label>{t('bond.investor.name')}</label>
              <ReactSelect
                placeholder={t('bond.select')}
                className="filter-control-select"
                classNamePrefix="filter-control-select"
                options={[
                  ...INVESTOR_TYPE.map((x) => ({
                    name: x.name,
                    id: x.id,
                  })),
                  { name: 'txt-all', id: '' },
                ]}
                getOptionLabel={(option) => t(option.name)}
                getOptionValue={(option) => option.id}
                isSearchable={false}
                key={lang}
                components={{
                  IndicatorSeparator: () => null,
                }}
                defaultValue={investorType}
                value={investorType}
                onChange={(value) => setInvestorType(value)}
              />
            </div>
            <div className="bond-layout__filter">
              <label>{t('bond.tableLabel.bondType')}</label>
              <ReactSelect
                placeholder={t('bond.select')}
                className="filter-control-select"
                classNamePrefix="filter-control-select"
                options={[...BOND_TYPE, { name: 'txt-all', id: '' }]}
                getOptionLabel={(option) => t(option.name)}
                getOptionValue={(option) => option.id}
                isSearchable={false}
                components={{
                  IndicatorSeparator: () => null,
                }}
                defaultValue={investorType}
                value={bondType}
                onChange={(value) => setBondType(value)}
              />
            </div>
            <div className="bond-layout__filter">
              <label>{t('bond.issuer')}</label>
              <ReactSelect
                placeholder={t('bond.select')}
                className="filter-control-select"
                classNamePrefix="filter-control-select"
                value={issuer}
                defaultValue={investorType}
                options={[...bondIssuers, { item: 'txt-all', id: '' }]}
                getOptionLabel={(option) => t(option.item)}
                getOptionValue={(option) => option.id}
                isSearchable={false}
                components={{
                  IndicatorSeparator: () => null,
                }}
                onChange={(e) => setIssuer(e)}
              />
            </div>
            <div className="bond-layout__filter button-group">
              <span
                className="iBtn iBtn--primary"
                onClick={() => filterHandle()}
              >
                <span>{t('bond.filter')}</span>
              </span>
              <div
                className="iBtn reload-button iBtn--secondary"
                onClick={() => resetHandle()}
              >
                <span className="icon iReload"></span>
              </div>
            </div>
          </div>
          <div
            className="show-data"
            style={{
              height: `calc(100% - 185px + ${token ? '0px' : '32px'} + ${
                proInvt ? '0px' : '32px'
              })`,
            }}
          >
            {bondProducts && (
              <ScrollTable
                colgroup={colgroup}
                columns={columns}
                source={bondProducts}
                tooltip={bondToolTip}
              />
            )}
          </div>
          <div class="investor-infors">
            {proInvt && (
              <div className="investor-infor investor-note">
                <span className="d-flex align-items-center">
                  <span className="icon icon--sm iStar mr-2"></span>
                  <span>{t('bond.productForProInvestor')}</span>
                </span>
              </div>
            )}
            {token && (
              <>
                <div className="line"></div>
                <div className="investor-infor d-flex">
                  <span>
                    <span className="text text--light3 fw-400 mr-2">
                      {t('bond.maxBuy.value')}
                    </span>
                    <span className="text text--light fw-500">
                      {numberFormat(limitInfor?.buyLmt, 0, '-')}
                    </span>
                  </span>
                  <span>
                    <span className="text text--light3 fw-400 mr-2">
                      {t('bond.maxBuy.valueLeft')}
                    </span>
                    <span className="text text--light fw-500">
                      {numberFormat(limitInfor?.buyLmtRm, 0, '-')}
                    </span>
                  </span>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="bond-product__body--right">
          {bondInfor && (
            <>
              <div className="bond-introduce">
                <span className="text-title text--light fw-500">
                  {t('bond.introduce')}
                </span>
                <p className="text-desc mt-1 text--light3">
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        (bondInfor &&
                          bondInfor.find((x) => x.key == 'INTRO_GROWTH')
                            ?.value) ||
                        '',
                    }}
                  />
                </p>
              </div>
              <div>
                {setting?.pcbMapYN != 'Y' && (
                  <div
                    key={-1}
                    className="bond-introduce bond-introduce--faq d-flex justify-content-between align-items-center"
                  >
                    <span
                      className="text--light text-desc cursor-pointer"
                      onClick={() => {
                        registBondRetailTradingHandle();
                      }}
                    >
                      {t('bond.retailTrading.label')}
                    </span>
                    {token && setting?.pcbMapYN == 'P' && (
                      <span className="r">
                        {t('bond.retailTrading.pendingStatus')}
                      </span>
                    )}
                  </div>
                )}
                {bondInfor
                  .filter((x) => x.type == 'faq-growth')
                  ?.map((faq, i) => (
                    <div key={i} className="bond-introduce bond-introduce--faq">
                      <a
                        className="text--light text-desc"
                        href={faq.url}
                        target="_blank"
                        onClick={() => {
                          Mixpanel.bond.getMoreInfor(
                            'Bond Product List',
                            'Product Introduction',
                            faq.name
                          );
                        }}
                      >
                        {faq.name}
                      </a>
                    </div>
                  ))}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
});

export default GBond;
