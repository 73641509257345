import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { withNamespaces } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import PDFViewer from '../../bond/components/Contract/pdfViewer';
import CheckboxButton from '../../../components/checkbox/checkBoxButton';
import {
  confirmOpenAccount,
  getCreditLimitLevel,
  getOpenAccountContract,
  setCreditLimitLevelModal,
} from '../../customer/actions';
import { subAccountTypeDetect } from '../../../util';
import { constants } from '../../../util/constant';
import { Mixpanel } from '../../../lib/mixpanel';
import AuthenStep from '../../../components/common/auth';

const OpenSubAccountModal = withNamespaces('translations')(
  ({ t, subAccountType, closeModalhandle }) => {
    const [show, setShow] = useState(false);
    const [authType, setAuthType] = useState('SmartOTP');
    const [oneTimeToken, setOneTimeToken] = useState('');
    const [check, setCheck] = useState(false);
    const [confirmState, setConfirmState] = useState(false);
    const theme = useSelector((state) => state.client.currentTheme);
    const { token } = useSelector((state) => state.client);
    const { openSubAccountContract, creditLimitLevels } = useSelector(
      (state) => state.customer
    );

    const { defaultAccount, listAccount } = useSelector(
      (state) => state.socket
    );

    const dispatch = useDispatch();

    useEffect(() => {
      if (subAccountType) {
        setShow(true);
        getContract();
        if (subAccountType == constants.subAccount.Derivative)
          handleGetCreditLimitLevel();
      }
    }, [subAccountType]);

    const handleGetCreditLimitLevel = () => {
      const uuid = uuidv4();
      const resData = {
        group: 'CORE',
        cmd: 'getCreditLimitLvl',
        channel: 'WTS',
        rqId: uuid,
        data: {},
      };
      dispatch(getCreditLimitLevel(resData));
    };

    const getContract = () => {
      if (token) {
        const uuid = uuidv4();
        const params = {
          group: 'CORE',
          user: token.user,
          session: token.session,
          cmd: 'getOpenSubAcntCntr',
          rqId: uuid,
          channel: 'WTS',
          data: {
            cif: token.cif,
            acntNo: defaultAccount?.acntNo,
            subAcntClss: subAccountType,
          },
        };

        dispatch(getOpenAccountContract(params));
      }
    };

    const closeModal = () => {
      // dispatch(openAccountPopup(false));
      if (closeModalhandle && typeof closeModalhandle === 'function')
        closeModalhandle();
      setShow(false);
      setCheck(false);
      setOneTimeToken('');
    };

    const disableHandle = () => {
      if (!oneTimeToken || !check) return true;
      else return false;
    };

    const confirm = () => {
      const uuid = uuidv4();
      const validate = disableHandle();

      if (!validate) {
        const params = {
          group: 'CORE',
          user: token.user,
          session: token.session,
          cmd: 'openSubAccount',
          rqId: uuid,
          channel: 'WTS',
          type: '6',
          token: oneTimeToken ?? '',
          data: {
            cif: token.cif,
            acntNo: defaultAccount.acntNo,
            subAcntClss: subAccountType,
          },
        };

        const callback = (account) => {
          if (account.subAcntClss == constants.subAccount.Margin) {
            const currentLimit = account.marginLimit;
            const rest = creditLimitLevels.filter(
              (x) =>
                x.subAcntClss == account.subAcntClss &&
                x.crdLmlAmt > currentLimit
            );

            Mixpanel.track('Confirm OTP', {
              'OTP Type': authType || '',
              'OTP Reason': 'Margin Account',
              'Submit Status': 'Success',
              'Error Code': '200',
              'Error Message': '',
            });

            Mixpanel.track('eContract Agreement', {
              'Contract Type': 'Margin',
              'Submit Status': 'Success',
              'Error Code': '200',
              'Error Message': '',
            });

            Mixpanel.people.set({
              'Margin Account': 'Has Margin Account',
              'Margin Registration Platform': 'WTS',
            });

            closeModal();
            dispatch(
              setCreditLimitLevelModal({
                isCreate: true,
                data: rest,
                subAccountType: subAccountType,
                max: currentLimit,
              })
            );
            return;
          }

          if (account.subAcntClss == constants.subAccount.Derivative) {
            const currentLimit = account.futureLimit;
            const rest = creditLimitLevels.filter(
              (x) =>
                x.subAcntClss == account.subAcntClss &&
                x.crdLmlAmt > currentLimit
            );

            closeModal();
            dispatch(
              setCreditLimitLevelModal({
                isCreate: true,
                data: rest,
                subAccountType: subAccountType,
                max: currentLimit,
              })
            );
            return;
          }
        };
        const fallback = (error) => {
          Mixpanel.track('Confirm OTP', {
            'OTP Type': authType || '',
            'OTP Reason': 'Margin Account',
            'Submit Status': 'Failed',
            'Error Code': error?.msgNo,
            'Error Message': error?.msg,
          });

          Mixpanel.track('eContract Agreement', {
            'Contract Type': 'Margin',
            'Submit Status': 'Failed',
            'Error Code': error?.msgNo,
            'Error Message': error?.msg,
          });

          Mixpanel.people.set({
            'Margin Account': 'Interested',
            'Margin Registration Platform': 'WTS',
          });

          setConfirmState(false);
        };

        dispatch(
          confirmOpenAccount({ params, listAccount, callback, fallback })
        );
      }
    };

    return (
      <>
        <Modal
          show={show}
          dialogClassName="wts-modal"
          backdropClassName="wts-modal-backdrop"
          contentClassName={`${
            theme ? theme.name : 'dark'
          } open-account wts-modal-content wts-modal-content--4/5`}
          onEscapeKeyDown={() => closeModal()}
          centered
          onHide={() => closeModal()}
          style={{ zIndex: 11114 }}
        >
          <div className="open-account-contract">
            <div className="open-account-contract__left">
              <div className="open-account-contract__header">
                <span className="text text--md fw-500 open-account-contract__title">
                  {t('user.openAccount', {
                    accType: t(`${subAccountTypeDetect(subAccountType)}`),
                  })}
                </span>
                <CheckboxButton
                  description={
                    subAccountType?.startsWith(constants.subAccount.Margin)
                      ? t('user.bookingMarginVideoCall.termAndCondition')
                      : subAccountType?.startsWith(
                          constants.subAccount.Derivative
                        )
                      ? t('user.bookingDerivativeVideoCall.termAndCondition')
                      : ''
                  }
                  onClick={() => setCheck(!check)}
                />
              </div>
              <div className="open-account-contract__body">
                <div className="2fa-authentication">
                  <AuthenStep
                    setOneTimeToken={setOneTimeToken}
                    setAuthType={(value) => {
                      setAuthType(value);
                    }}
                    trackMixpanel
                  />
                </div>
                <div className="open-account-contract__actions">
                  <button
                    className="btn btn--buy open-account-contract__action"
                    onClick={() => confirm()}
                    disabled={disableHandle()}
                  >
                    {t('txt-confirm')}
                  </button>
                  <button
                    className="btn btn--cancel open-account-contract__action"
                    onClick={() => closeModal()}
                  >
                    {t('txt-cancel')}
                  </button>
                </div>
              </div>
            </div>
            <div className="open-account-contract__right">
              {openSubAccountContract && openSubAccountContract.fileUrl && (
                <PDFViewer src={openSubAccountContract.fileUrl} scale={1.3} />
              )}
            </div>
          </div>
        </Modal>
      </>
    );
  }
);

export default OpenSubAccountModal;
