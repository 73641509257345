import { categoryDefault, saveState } from '../../lib/storages';
import {
  ADVERTISE_REQUESTING,
  ADVERTISE_REQUESTING_ERROR,
  ADVERTISE_REQUESTING_SUCCESS,
  APPSETTING_REQUESTING,
  APPSETTING_REQUESTING_ERROR,
  APPSETTING_REQUESTING_RESET,
  APPSETTING_REQUESTING_SUCCESS,
  AUTHEN_SET,
  AUTH_SET,
  AUTH_UNSET,
  CASH_OUT_RESET,
  CASH_OUT_SET,
  CATEGORY_REQUESTING,
  CATEGORY_REQUEST_ERROR,
  CATEGORY_REQUEST_SUCCESS,
  CATEGORY_SET,
  CHANGE_BANK_SET,
  CHANGE_BANK_UNSET,
  CHANGE_PASS_SET,
  CHANGE_PASS_UNSET,
  CHANGE_TELPIN_SET,
  CHANGE_TELPIN_UNSET,
  CHANGE_USER_SET,
  CHANGE_USER_UNSET,
  CLIENT_SET,
  CLIENT_UNSET,
  CONFIG_REQUESTING,
  CONFIG_REQUEST_ERROR,
  CONFIG_REQUEST_SUCCESS,
  CTLG_ID_EDIT_SET,
  DBL_PRICE_SET,
  FULL_TRADING_SET,
  FULL_TRADING_UNSET,
  INDEX_HIDE_SET,
  INDUSTRY_CATEGORY_APPEND,
  INDUSTRY_CATEGORY_REQUESTING,
  INDUSTRY_CATEGORY_REQUEST_ERROR,
  INDUSTRY_CATEGORY_REQUEST_SUCCESS,
  LOGIN_SET,
  LOGIN_UNSET,
  OBJ_TRADING_SET,
  OBJ_TRADING_UNSET,
  ORD_COND_SET,
  RESET_SET,
  RESET_UNSET,
  SAVE_CATEGORY_REQUESTING,
  SAVE_CATEGORY_REQUEST_ERROR,
  SAVE_CATEGORY_REQUEST_SUCCESS,
  SETTING_SET,
  SET_CATEGORY_SELECTED,
  SET_NOTI_POPUP,
  SET_THEME,
  SYMBOL_ACTIVE_SET,
  SYMBOL_ADD,
  SYMBOL_MINIMIZE_SET,
  SYMBOL_SCROLL,
  TYPE_INDEX_SET,
  SET_FEEDBACK_POPUP_SHOW,
} from './constants';

const initialSate = {
  id: null,
  token: null,
  reqLogin: false,
  reqAuth: false,
  reqReset: false,
  reqChangePass: false,
  reqChangeTelpin: false,
  reqChangeUser: false,
  reqChangeBank: false,
  reqFullTrading: false,
  reqLostSession: false,
  objTrading: null,
  toast: null,
  setting: { symbolMinimize: [], indHid: [] },
  symbolActive: null,
  symbolDel: null,
  symbolAdd: null,
  symbolScroll: null,
  ctlgIdEdit: null,
  auth: {
    isVerified: false,
    type: null,
    token: null,
  },
  pin: null,
  savePin: false,
  cashOut: false,
  ordCond: false,
  dblPri: null,

  config: null,
  configRequesting: false,
  configSuccessful: false,
  configErrors: [],

  category: null,
  categorySelected: null,
  categoryRequesting: false,
  categorySuccessful: false,
  categoryErrors: [],

  saveCategoryRequesting: false,
  saveCategorySuccessful: false,
  saveCategoryErrors: [],

  industry: null,
  industryRequest: false,
  industrySuccessful: false,
  industryErrors: [],

  advertise: null,
  advertiseRequesting: false,
  advertiseSuccessful: false,
  advertiseErrors: [],

  appsetting: null,
  appsettingRequesting: false,
  appsettingSuccessful: false,
  appsettingErrors: [],

  themes: [
    { id: 1, name: 'dark' },
    { id: 2, name: 'light' },
  ],
  currentTheme: { id: 1, name: 'dark' },
  notiPopup: null,
  feedbackPopupShow: false,
};

const reducer = function clientReducer(state = initialSate, action) {
  const industryHandle = (
    initIndustry = [],
    initCategory = [...state.category]
  ) => {
    let _industry = initIndustry;
    let _category = initCategory;
    if (Array.isArray(_industry) && _industry.length > 0) {
      _category = _category.filter((x) => x.groupName != 'CPNGANH');
      _industry.map((item, i) => {
        if (!item.industryName) return;
        let _value = [];
        if (item.symbols) {
          _value = item.symbols.split(',');
        }

        if (!_category.some((x) => x.name == item.industryName))
          _category.push({
            name: item.industryName,
            path: `/home/bang-gia/cp-nganh-${i}`,
            groupName: 'CPNGANH',
            type: 'group',
            value: _value,
            pinnedRow: [],
            total: item.total,
          });
      });
    }

    return _category;
  };

  const additionalCategoryHandle = (initCategory = [...state.category]) => {
    const additionalCat = [
      {
        id: 'odd-lot-hsx',
        name: 'txt-odd-lot-hsx',
        path: '/home/bang-gia/lo-le-hsx',
        groupName: 'OddLot',
        type: 'group',
        value: [],
        pinnedRow: [],
      },
      {
        id: 'odd-lot-hnx',
        name: 'txt-odd-lot-hnx',
        path: '/home/bang-gia/lo-le-hnx',
        groupName: 'OddLot',
        type: 'group',
        value: [],
        pinnedRow: [],
      },
      // {
      //   id: 'odd-lot-upcom',
      //   name: 'txt-odd-lot-upcom',
      //   path: '/home/bang-gia/lo-le-upcom',
      //   groupName: 'OddLot',
      //   type: 'group',
      //   value: [],
      //   pinnedRow: [],
      // },
      // {
      //   id: 'post-close-hnx',
      //   name: 'txt-post-close',
      //   path: '/home/bang-gia/post-close-hnx',
      //   groupName: 'HNX',
      //   type: 'group',
      //   value: [],
      //   pinnedRow: [],
      // },
      // {
      //   id: 'buy-in-hsx',
      //   name: 'txt-buy-in-hsx',
      //   path: '/home/bang-gia/buy-in-hsx',
      //   groupName: 'Buyin',
      //   type: 'group',
      //   value: [],
      //   pinnedRow: [],
      // },
      // {
      //   id: 'buy-in-hnx',
      //   name: 'txt-buy-in-hnx',
      //   path: '/home/bang-gia/buy-in-hnx',
      //   groupName: 'Buyin',
      //   type: 'group',
      //   value: [],
      //   pinnedRow: [],
      // },
      // {
      //   id: 'buy-in-upcom',
      //   name: 'txt-buy-in-upcom',
      //   path: '/home/bang-gia/buy-in-upcom',
      //   groupName: 'Buyin',
      //   type: 'group',
      //   value: [],
      //   pinnedRow: [],
      // },
    ];

    let _category = initCategory;

    additionalCat.map((item) => {
      if (!_category.some((x) => x.id == item.id)) _category.push(item);
    });

    return _category;
  };

  switch (action.type) {
    case 'REQUEST_PRIVATE_FALSE':
    case 'SHOW_TOAST':
      return {
        ...state,
        toast: action.resData,
      };

    case 'SYMBOL_DEL':
      return {
        ...state,
        symbolDel: action.symbol,
      };

    case SYMBOL_ADD:
      return {
        ...state,
        symbolAdd: action.symbol,
      };

    case SYMBOL_SCROLL:
      return {
        ...state,
        symbolScroll: action.symbol,
      };

    case 'CLEAR_LOST_SESSION':
      return {
        ...state,
        reqLostSession: false,
      };

    case 'INVALID_SESSION':
      return {
        ...state,
        id: null,
        token: null,
        reqLostSession: true,
        pin: null,
        savePin: false,
        auth: {
          isVerified: false,
          type: null,
          token: null,
        },
        config: null,
        configRequesting: false,
        configSuccessful: false,
        configErrors: [],
      };
    case 'LOG_OUT':
    case CLIENT_UNSET:
      return {
        ...state,
        id: null,
        token: null,
        pin: null,
        savePin: false,
        auth: {
          isVerified: false,
          type: null,
          token: null,
        },
        config: null,
        configRequesting: false,
        configSuccessful: false,
        configErrors: [],
      };

    case CLIENT_SET:
      return {
        ...state,
        id: action.token.user,
        token: action.token,
      };

    case AUTHEN_SET:
      return {
        ...state,
        auth: action.auth,
      };

    case CATEGORY_SET: {
      let _industry = state.industry;
      let _category =
        Array.isArray(action.category) && action.category.length > 0
          ? action.category
          : categoryDefault;

      let category = additionalCategoryHandle(_category);
      category = industryHandle(_industry, _category);
      if (!state.token) {
        // If non-authentication -> save to localstorage
        saveState('category', category);
      }

      return {
        ...state,
        category: category,
      };
    }

    case SETTING_SET:
      return {
        ...state,
        setting: action.setting,
      };

    case LOGIN_SET:
      return {
        ...state,
        reqLogin: true,
      };

    case LOGIN_UNSET:
      return {
        ...state,
        reqLogin: false,
      };

    case AUTH_SET:
      return {
        ...state,
        reqAuth: true,
      };

    case AUTH_UNSET:
      return {
        ...state,
        reqAuth: false,
      };

    case RESET_SET:
      return {
        ...state,
        reqReset: true,
      };

    case RESET_UNSET:
      return {
        ...state,
        reqReset: false,
      };

    case CHANGE_PASS_SET:
      return {
        ...state,
        reqChangePass: true,
      };

    case CHANGE_PASS_UNSET:
      return {
        ...state,
        reqChangePass: false,
      };

    case CHANGE_TELPIN_SET:
      return {
        ...state,
        reqChangeTelpin: true,
      };

    case CHANGE_TELPIN_UNSET:
      return {
        ...state,
        reqChangeTelpin: false,
      };

    case CHANGE_USER_SET:
      return {
        ...state,
        reqChangeUser: true,
      };

    case CHANGE_USER_UNSET:
      return {
        ...state,
        reqChangeUser: false,
      };

    case CHANGE_BANK_SET:
      return {
        ...state,
        reqChangeBank: true,
      };

    case CHANGE_BANK_UNSET:
      return {
        ...state,
        reqChangeBank: false,
      };

    case FULL_TRADING_SET:
      return {
        ...state,
        reqFullTrading: true,
      };

    case FULL_TRADING_UNSET:
      return {
        ...state,
        reqFullTrading: false,
      };

    case OBJ_TRADING_SET:
      return {
        ...state,
        objTrading: action.data,
      };

    case OBJ_TRADING_UNSET:
      return {
        ...state,
        objTrading: null,
      };

    case TYPE_INDEX_SET:
      return {
        ...state,
        setting: { ...state.setting, typeIndex: action.data },
      };

    case INDEX_HIDE_SET:
      return {
        ...state,
        setting: { ...state.setting, indHid: action.data },
      };

    case CASH_OUT_SET:
      return {
        ...state,
        cashOut: action.data,
      };

    case CASH_OUT_RESET:
      return {
        ...state,
        cashOut: false,
      };

    case ORD_COND_SET:
      return {
        ...state,
        ordCond: action.data,
      };

    case DBL_PRICE_SET:
      return {
        ...state,
        dblPri: action.data,
      };

    case CTLG_ID_EDIT_SET:
      return {
        ...state,
        ctlgIdEdit: action.data,
      };

    case SYMBOL_MINIMIZE_SET:
      return {
        ...state,
        setting: { ...state.setting, symbolMinimize: [...action.data] },
        // symbolMinimize: action.data,
      };

    case SYMBOL_ACTIVE_SET:
      return {
        ...state,
        symbolActive: action.data,
      };

    case CONFIG_REQUESTING:
      return {
        ...state,
        configRequesting: false,
        configSuccessful: false,
        configErrors: [],
      };

    case CONFIG_REQUEST_SUCCESS:
      return {
        ...state,
        config: action.config,
        configRequesting: false,
        configSuccessful: true,
        configErrors: [],
      };

    case CONFIG_REQUEST_ERROR:
      return {
        ...state,
        configRequesting: false,
        configSuccessful: false,
        configErrors: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };

    case CATEGORY_REQUESTING:
      return {
        ...state,
        categoryRequesting: false,
        categorySuccessful: false,
        categoryErrors: [],
      };

    case CATEGORY_REQUEST_SUCCESS: {
      let _industry = state.industry;
      let _category =
        Array.isArray(action.category) && action.category.length > 0
          ? action.category
          : categoryDefault;

      let category = additionalCategoryHandle(_category);
      category = industryHandle(_industry, _category);

      return {
        ...state,
        category: category,
        categoryRequesting: false,
        categorySuccessful: true,
        categoryErrors: [],
      };
    }

    case CATEGORY_REQUEST_ERROR:
      return {
        ...state,
        categoryRequesting: false,
        categorySuccessful: false,
        categoryErrors: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };

    case INDUSTRY_CATEGORY_REQUESTING: {
      const _cat =
        state.category?.filter((x) => x.groupName != 'CPNGANH') ?? [];

      return {
        ...state,
        category: _cat,
        industry: [],
        industryRequest: true,
        industrySuccessful: false,
        industryErrors: [],
      };
    }

    case INDUSTRY_CATEGORY_REQUEST_SUCCESS: {
      let _industry = [];
      if (action.data.http == 200) _industry = action.data.data;
      let _category = [...state.category];

      const category = industryHandle(_industry, _category);

      return {
        ...state,
        category: category,
        industry: action.data.data,
        industryRequest: false,
        industrySuccessful: true,
        industryErrors: [],
      };
    }
    case INDUSTRY_CATEGORY_REQUEST_ERROR:
      return {
        ...state,
        industry: action.data,
        industryRequest: false,
        industrySuccessful: false,
        industryErrors: [
          ...[
            {
              body: action?.error ? action.error.toString() : '',
              time: new Date(),
            },
          ],
        ],
      };

    case ADVERTISE_REQUESTING: {
      return {
        ...state,
        advertise: null,
        advertiseRequesting: true,
        advertiseSuccessful: false,
        advertiseErrors: [],
      };
    }

    case ADVERTISE_REQUESTING_SUCCESS: {
      return {
        ...state,
        advertise: action.resData,
        advertiseRequesting: false,
        advertiseSuccessful: true,
        advertiseErrors: [],
      };
    }

    case ADVERTISE_REQUESTING_ERROR: {
      return {
        ...state,
        advertise: null,
        advertiseRequesting: false,
        advertiseSuccessful: false,
        advertiseErrors: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };
    }

    case SAVE_CATEGORY_REQUESTING: {
      return {
        ...state,
        saveCategoryRequesting: true,
        saveCategorySuccessful: false,
        saveCategoryErrors: [],
      };
    }

    case SAVE_CATEGORY_REQUEST_SUCCESS: {
      return {
        ...state,
        saveCategoryRequesting: false,
        saveCategorySuccessful: true,
        saveCategoryErrors: [],
      };
    }

    case SAVE_CATEGORY_REQUEST_ERROR: {
      return {
        ...state,
        saveCategoryRequesting: false,
        saveCategorySuccessful: false,
        saveCategoryErrors: [
          ...[{ body: action.error?.toString(), time: new Date() }],
        ],
      };
    }

    case APPSETTING_REQUESTING: {
      return {
        ...state,
        appsetting: [],
        appsettingRequesting: true,
      };
    }

    case APPSETTING_REQUESTING_SUCCESS: {
      return {
        ...state,
        appsetting: action.data,
        appsettingRequesting: false,
        appsettingSuccessful: true,
      };
    }

    case APPSETTING_REQUESTING_ERROR: {
      return {
        ...state,
        appsetting: [],
        appsettingRequesting: false,
        appsettingSuccessful: true,
        appsettingErrors: [
          ...[{ body: action.error?.toString(), time: new Date() }],
        ],
      };
    }

    case APPSETTING_REQUESTING_RESET: {
      return {
        ...state,
        appsetting: [],
        appsettingRequesting: false,
        appsettingSuccessful: false,
        appsettingErrors: [],
      };
    }

    case SET_THEME: {
      return {
        ...state,
        currentTheme: action.id,
      };
    }

    case SET_NOTI_POPUP: {
      return {
        ...state,
        notiPopup: action.data,
      };
    }

    case SET_CATEGORY_SELECTED: {
      return {
        ...state,
        categorySelected: action.data,
      };
    }

    case SET_FEEDBACK_POPUP_SHOW: {
      return {
        ...state,
        feedbackPopupShow: action.show,
      };
    }

    default:
      return state;
  }
};

export default reducer;
