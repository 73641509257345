import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPFPurchasePower, setOrder } from '../action';
import Modal from 'react-bootstrap/Modal';
import { withNamespaces } from 'react-i18next';
import PinefolioLevels from './pinefolioLevels';
import ProductInfo from './productInfo';
import { numberFormat } from '../../../util';
import { v4 as uuidv4 } from 'uuid';

const SellOrder = withNamespaces('translations')(({ t, callback }) => {
  const [show, setShow] = useState(false);
  const [quantity, setQuantity] = useState(0);
  const theme = useSelector((state) => state.client.currentTheme);
  const { pineThemeDetail, pfOrder, purchasePower } = useSelector(
    (state) => state.pinefolio
  );
  const { defaultAccount } = useSelector((state) => state.socket);
  const { token } = useSelector((state) => state.client);

  const dispatch = useDispatch();

  const closeModal = () => {
    setShow(false);
    dispatch(setOrder(null));
    if (callback && typeof callback == 'function') {
      callback();
    }
  };

  useEffect(() => {
    if (pfOrder && pfOrder.orderType == 2) {
      setShow(true);
    }
  }, [pfOrder]);

  useEffect(() => {
    getPurchasePower();
  }, []);

  const getPurchasePower = () => {
    const uuid = uuidv4();

    const params = {
      group: 'CORE',
      user: token.user,
      session: token.session,
      cmd: 'getPFPowerPurchase',
      rqId: uuid,
      channel: 'WTS',
      data: {
        acntNo: defaultAccount.acntNo,
        subAcntNo: defaultAccount.subAcntNo,
        cif: token.cif,
        pfCode: pineThemeDetail?.pfCode,
        ordrQty: '',
        buySelTp: '2', // 1: Buy || 2: Sell
      },
    };
    dispatch(getPFPurchasePower(params));
  };

  const quantityOnChange = (e) => {
    setQuantity(e.target.value);
  };

  return (
    <Modal
      show={show}
      dialogClassName="wts-modal"
      backdropClassName="wts-modal-backdrop pinefolio-sell-backdrop"
      contentClassName={`${
        theme ? theme.name : 'dark'
      } pinefolio-sell wts-modal-content wts-modal-content--sm2`}
      onEscapeKeyDown={() => closeModal()}
      centered
      onHide={() => closeModal()}
      style={{ zIndex: 11115 }}
    >
      <div className="pinefolio-sell__header">
        <div className="pinefolio-sell__action">
          <span className="icon iBack"></span>
        </div>
        <div className="pinefolio-sell__title text--lg text--light">
          {t('txt-sell')} {pineThemeDetail?.thsNm}
        </div>
        <div className="pinefolio-sell__action"></div>
      </div>
      <div className="pinefolio-sell__body">
        <div className="order-main text--sm">
          <div className="product-type">
            <PinefolioLevels sources={pineThemeDetail?.pfList} />
          </div>
          <ProductInfo orderType={2} close={closeModal} />
        </div>
      </div>
    </Modal>
  );
});

export default SellOrder;
