import { constants } from '../../util/constant';
import {
  START_CHANNEL,
  STOP_CHANNEL,
  REGISTER_SOCKET_DATA,
  LEAVE_SOCKET_DATA,
  DefaultAccount,
  SERVER_ON,
  SERVER_OFF,
  SET_REG_SYMBOL,
  UNSET_REG_SYMBOL,
  SET_LIST_ACCOUNT,
  SET_CURRENT_G,
  SET_SID,
  REGISTER_PRIVATE_DONE,
  FORCE_PRIVATE_REGISTER,
} from './constants';

export function startSocketChanel() {
  return {
    type: START_CHANNEL,
  };
}

export function serverOn() {
  return {
    type: SERVER_ON,
  };
}

export function serverOff() {
  return {
    type: SERVER_OFF,
  };
}

export function stopSocketChanel() {
  return {
    type: STOP_CHANNEL,
  };
}

export function setRegSymbol(symbol) {
  return {
    type: SET_REG_SYMBOL,
    symbol,
  };
}

export function unsetRegSymbol() {
  return {
    type: UNSET_REG_SYMBOL,
  };
}

export function regisSocketData(params) {
  return {
    type: REGISTER_SOCKET_DATA,
    params,
  };
}

export function leaveSocketData(params) {
  return {
    type: LEAVE_SOCKET_DATA,
    params,
  };
}

export function setDefaultAccount(resData) {
  return {
    type: DefaultAccount,
    resData,
  };
}

export const updateListAccount = (data) => {
  return {
    type: SET_LIST_ACCOUNT,
    data,
  };
};

export const setCurrentG = (data = constants.G.DEFAULT) => {
  return {
    type: SET_CURRENT_G,
    data,
  };
};

export const setSID = (sid) => {
  return {
    type: SET_SID,
    sid,
  };
};

export const registerPrivateDone = () => {
  return {
    type: REGISTER_PRIVATE_DONE,
  };
};

export const forcePrivateRegisterHandle = (status) => {
  return {
    type: FORCE_PRIVATE_REGISTER,
    status,
  };
};
