import React, { Component } from 'react';
import './style.css';
import { v4 as uuidv4 } from 'uuid';

class Checkbox extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { title, checked, onChange, className, isDisable } = this.props;
    const id = 'pine-cb-' + uuidv4();

    return (
      <div className={className ? className : 'checkbox-group ml-3'}>
        <input
          disabled={isDisable}
          type="checkbox"
          id={id}
          className="custom-checkbox"
          checked={checked}
          onChange={onChange}
        />
        <span className="custom-checkbox-span" tabIndex="0"></span>
        <label
          htmlFor={id}
          className="custom-checkbox-label text-white ml-2 fz_14"
        >
          {' '}
          {title}{' '}
        </label>
      </div>
    );
  }
}

export default Checkbox;
