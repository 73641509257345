import React, { useEffect, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import { Profile, Security, Authorization, Bank, Setting } from './pages';
import VerticalNavbar from '../../components/navbar/verticalNavbar';
import { useDispatch, useSelector } from 'react-redux';
import {
  bankAccRequest,
  getBrokerInforSuccess,
  getCommonRequest,
  setRegistPartnershipShow,
} from '../customer/actions';
import { getProfileState, profileRequest } from '../customer/actions';
import { v4 as uuidv4 } from 'uuid';
import { Redirect, useHistory, useLocation } from 'react-router';
import Partnership from './pages/partnership';
import PartnershipCustomer from './pages/partnershipCustomer';

const nav = [
  {
    name: 'txt-label-profile',
    class: 'icon iAccount',
    child: [],
    path: 'profile',
    component: Profile,
  },
  {
    name: 'txt-label-bank-account',
    class: 'icon iBankCard',
    child: [],
    path: 'bank',
    component: Bank,
  },
  {
    name: 'txt-label-authorization',
    class: 'icon iUserAuth',
    child: [],
    path: 'authorization',
    component: Authorization,
  },
  {
    name: 'account.accountAuthorization',
    class: 'icon iLock',
    child: [],
    path: 'security',
    component: Security,
  },
  {
    name: 'lbl-setting-services',
    class: 'icon iSetting',
    child: [],
    path: 'setting',
    component: Setting,
  },
];

const Index = withNamespaces('translations')(({ t, match: { params } }) => {
  const dispatch = useDispatch();
  const { defaultAccount, listAccount } = useSelector((state) => state.socket);
  const {
    token,
    setting: { lang = 'vi' },
  } = useSelector((state) => state.client);
  const { broker } = useSelector((state) => state.customer);
  const [menu, setMenu] = useState([]);

  useEffect(() => {
    if (defaultAccount) {
      loadProfileHandle();
      loadBankAccountHandle();
    }
  }, [defaultAccount]);

  useEffect(() => {
    if (broker && broker[0]?.brokerId) {
      setMenu(
        [
          {
            name: 'partner.name',
            class: 'icon iPartner c active',
            className: 'c',
            child: [
              {
                name: 'partner.forReferrer',
                class: 'icon iNext',
                child: [],
                path: 'partnership-referrer',
                component: Partnership,
              },
              {
                name: 'partner.forReferee',
                class: 'icon iNext',
                child: [],
                path: 'partnership-referee',
                component: PartnershipCustomer,
              },
            ],
          },
        ].concat(nav)
      );
    } else {
      setMenu(
        [
          {
            name: 'partner.name',
            class: 'icon iPartner c active',
            className: 'c',
            child: [
              {
                name: 'partner.forReferee',
                class: 'icon iNext',
                child: [],
                path: 'partnership-referee',
                component: PartnershipCustomer,
              },
            ],
          },
        ].concat(nav)
      );
    }
  }, [broker]);

  useEffect(() => {
    if (token && broker) {
      const pathName = window.location.hash;
      if (
        pathName === '#/home/account/partnership-referrer' &&
        broker.length === 0
      ) {
        dispatch(setRegistPartnershipShow(true));
      }
    }
  }, [token, broker]);

  const loadProfileHandle = () => {
    if (!token) return;

    const uuid = uuidv4();
    const params = {
      group: 'CORE',
      user: token.user,
      session: token.session,
      cmd: 'GetProfiles',
      rqId: uuid,
      channel: 'WTS',
      data: {
        cif: token.cif,
      },
    };

    dispatch(profileRequest(params));
  };

  const loadBankAccountHandle = () => {
    if (!defaultAccount) return;

    const uuid = uuidv4();
    const params = {
      group: 'CORE',
      user: token.user,
      session: token.session,
      cmd: 'getBankAcntList',
      rqId: uuid,
      channel: 'WTS',
      data: {
        acntNo: defaultAccount.acntNo,
        subAcntNo: defaultAccount.subAcntNo,
      },
    };

    dispatch(bankAccRequest(params));
  };

  if (!token) return <Redirect to="/" />;

  return (
    <div className="account-page">
      <VerticalNavbar params={params} data={menu} />
      <Content params={params} data={menu} />
    </div>
  );
});

const Content = withNamespaces('translations')(({ t, params, data }) => {
  const { typeId } = params;
  let routes = [];

  data.map((x) => {
    if (x.child && x.child.length > 0) {
      x.child.map((item) => {
        routes.push(item);
      });
    } else {
      if (x.component) {
        routes.push(x);
      }
    }
  });

  const page = routes.find((x) => x.path == typeId);

  return (
    <div className="account-page-content">
      {page &&
        page.component &&
        React.createElement(page.component, { params }, null)}
    </div>
  );
});

export default Index;
