import { Mixpanel } from '../../lib/mixpanel';
import { constants } from '../../util/constant';
import {
  SERVER_OFF,
  SERVER_ON,
  SET_REG_SYMBOL,
  UNSET_REG_SYMBOL,
  DefaultAccount,
  NewOrder,
  PurchasePower,
  StockBalance,
  OrderList,
  CancelOrder,
  EditOrder,
  CashBalance,
  ShareBalance,
  getAvailStockList,
  getPositions,
  ListOrder,
  GetMatchOrder,
  Event,
  getSymbolInfo,
  SET_LIST_ACCOUNT,
  SET_CURRENT_G,
  REGISTER_OK,
  REGISTER_NOT_OK,
  REGISTER_REQUESTING,
  SET_SID,
  REGISTER_PRIVATE_DONE,
  FORCE_PRIVATE_REGISTER,
} from './constants';

const initialState = {
  // channelStatus: 'off',
  serverStatus: 'unknown',
  socketReady: false,

  regPrivateRequesting: false,
  regPrivate: 'unknown',

  listAccount: null,
  defaultAccount: null,
  newOrder: null,
  purchasePower: null,
  stockBalance: null,
  orderList: null,
  listOrder: null,
  getMatchOrder: null,
  cancelOrder: null,
  editOrder: null,

  cashBalance: null,
  shareBalance: null,
  availStockList: null,
  positions: null,
  event: null,
  regSym: null,
  currentG: constants.G.DEFAULT,
};

const reducer = function socketReducer(state = initialState, action) {
  switch (action.type) {
    /***************************** LOG_OUT ***************************/
    case 'LOG_OUT':
    case 'CLIENT_UNSET':
    case 'INVALID_SESSION':
      return {
        ...state,
        listAccount: null,
        defaultAccount: null,
        newOrder: null,
        purchasePower: null,
        stockBalance: null,
        orderList: null,
        listOrder: null,
        getMatchOrder: null,
        cancelOrder: null,
        editOrder: null,
        cashBalance: null,
        positions: null,
        shareBalance: null,
        availStockList: null,
        event: null,
        currentSid: null,
        forcePrivateRegister: false,
      };

    /***************************** PRIVATE ***************************/
    case SET_LIST_ACCOUNT: {
      return { ...state, listAccount: action.data };
    }

    case DefaultAccount:
      return { ...state, defaultAccount: action.resData };

    case NewOrder:
      return { ...state, newOrder: action.resData };

    case PurchasePower:
      return { ...state, purchasePower: action.resData };

    case StockBalance:
      return { ...state, stockBalance: action.resData };

    case OrderList:
      return { ...state, orderList: action.resData };

    case ListOrder:
      return { ...state, listOrder: action.resData };

    case GetMatchOrder:
      return { ...state, getMatchOrder: action.resData };

    case CancelOrder:
      return { ...state, cancelOrder: action.resData };

    case EditOrder:
      return { ...state, editOrder: action.resData };

    case CashBalance:
      return { ...state, cashBalance: action.resData };

    case getPositions:
      return { ...state, positions: action.resData };

    case ShareBalance:
      return { ...state, shareBalance: action.resData };

    case getAvailStockList:
      return { ...state, availStockList: action.resData };

    case Event:
      return { ...state, event: action.resData };

    case getSymbolInfo:
      const _symbolInfo =
        action.resData && action.resData.list && action.resData.list.length > 0
          ? action.resData.list[0]
          : null;
      const _symbolMargin =
        action.resData && action.resData.dataMargin
          ? action.resData.dataMargin
          : null;
      return { ...state, symbolInfo: _symbolInfo, symbolMargin: _symbolMargin };

    /***************************** PUBLIC ***************************/
    case SET_REG_SYMBOL: {
      return {
        ...state,
        regSym: state.regSym
          ? state.regSym.concat(',').concat(action.symbol)
          : action.symbol,
      };
    }

    case UNSET_REG_SYMBOL:
      return { ...state, regSym: '' };

    case SERVER_OFF:
      return { ...state, socketReady: false, serverStatus: 'off' };

    case SERVER_ON:
      return { ...state, socketReady: true, serverStatus: 'on' };

    case REGISTER_OK:
      return { ...state, regPrivate: 'ok', regPrivateRequesting: false };

    case REGISTER_NOT_OK:
      return { ...state, regPrivate: 'not', regPrivateRequesting: false };

    case REGISTER_REQUESTING: {
      return { ...state, regPrivateRequesting: true };
    }

    case REGISTER_PRIVATE_DONE: {
      return { ...state, regPrivateRequesting: false };
    }

    case SET_CURRENT_G:
      return {
        ...state,
        currentG: action.data,
      };

    case SET_SID:
      return {
        ...state,
        currentSid: action.sid,
      };

    case FORCE_PRIVATE_REGISTER:
      return {
        ...state,
        forcePrivateRegister: action.status,
      };

    default:
      return state;
  }
};

export default reducer;
