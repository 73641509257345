import { useState } from 'react';
import { Field, Form, Formik } from 'formik';
import { Button, Modal } from 'react-bootstrap';
import * as Yup from 'yup';
import { v4 as uuidv4 } from 'uuid';
import AuthenStep from '../../../../components/common/auth';
import { withNamespaces } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setToast } from '../../../client/actions';
import { genOpenApiSrvKey } from '../../../customer/actions';

const Input = ({ field, form, ...props }) => {
  return <input {...field} {...props} />;
};

const GenPrivateKey = withNamespaces()(({ items, onClose, t, onSuccess }) => {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.client);
  const theme = useSelector((state) => state.client.currentTheme);
  const [oneTimeToken, setOneTimeToken] = useState('');
  const schema = Yup.object().shape({
    deviceId: Yup.string().required(t('txt-valid-input-required')),
  });

  const handleSubmit = (form) => {
    if (!oneTimeToken) {
      const toastMsg = {
        id: Math.random(),
        msg: t('txt-chua-xacthuc'),
        title: t('txt-notice'),
      };

      return dispatch(setToast(toastMsg));
    }
    const uuid = uuidv4();
    const params = {
      group: 'CORE',
      user: token.user,
      session: token.session,
      cmd: 'createOpenApiToken',
      rqId: uuid,
      channel: 'WTS',
      type: '6',
      token: oneTimeToken,
      data: {
        cif: token.cif,
        acntNo: items.acntNo,
        subAcntNo: items.subAcntNo,
        ...form,
      },
    };

    dispatch(
      genOpenApiSrvKey(params, (response) => {
        onSuccess(response);
      })
    );
  };

  return (
    <Modal
      show={true}
      dialogClassName="wts-modal"
      backdropClassName="wts-modal-backdrop"
      contentClassName={`${
        theme.name || 'dark'
      } wts-modal-content wts-modal-content--xs2`}
      centered
      style={{ zIndex: 11112 }}
    >
      <div className="gen-openapi-key">
        <div className="gen-openapi-key__head">
          <div className="text--md fw-500">
            {t('openApi.createConnectionKey')}
          </div>
          <div className="right-section">
            <span
              className="btn-icon btn-icon--delete3"
              onClick={() => onClose()}
            >
              <span className="icon iClose cursor-pointer"></span>
            </span>
          </div>
        </div>
        <div className="gen-openapi-key__body">
          <Formik
            initialValues={{
              deviceId: '',
              ip: '',
            }}
            validationSchema={schema}
            onSubmit={async (values) => {
              handleSubmit(values);
            }}
          >
            {({ errors, touched, dirty, isValid }) => (
              <Form>
                <div className="form-item">
                  <label htmlFor="deviceId">
                    {t('openApi.deviceId')} <span className="d">*</span>
                  </label>
                  <Field
                    id="deviceId"
                    name="deviceId"
                    placeholder="00:00:00:00:00:00"
                    component={Input}
                  />
                  {touched.deviceId && errors.deviceId && (
                    <div className="d-flex fz_13 d mt-2">{errors.deviceId}</div>
                  )}
                </div>
                <div className="form-item">
                  <label htmlFor="ip">{t('openApi.ipAddress')}</label>
                  <Field
                    id="ip"
                    name="ip"
                    placeholder="0.0.0.0"
                    component={Input}
                  />
                  {touched.ip && errors.ip && (
                    <div className="d-flex fz_13 d mt-2">{errors.ip}</div>
                  )}
                </div>
                <div>
                  <span className="fz_13">{t('openApi.openApiNote')}</span>
                </div>
                <AuthenStep
                  setOneTimeToken={(value) => {
                    setOneTimeToken(value);
                  }}
                  trackMixpanel
                  isBorder={false}
                />

                <Button
                  type="submit"
                  className="fw-500 fz_13 btn btn-submit btn--primary h-32"
                >
                  {t('openApi.create')}
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  );
});
export default GenPrivateKey;
