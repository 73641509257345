import React, { useState, useEffect, useRef, useContext } from 'react';
import { makeGetCashBalance } from '../../../lib/selector';
import {
  hisTransferRequest,
  bankListRequest,
  cashOutReset,
  listBankVARequest,
} from '../../../containers/cash/actions';
import {
  setCashOut,
  setToast,
  unsetClient,
  setAuth,
} from '../../../containers/client/actions';
import {
  numberFormat,
  formatDate,
  getTransferStatus,
  removeAccent,
  getMessageError,
  replaceAll,
} from '../../../util';
import { cashBalanceRequest } from '../../../containers/privData/actions';

import FormHisNapRutTien from '../../../components/cash/form/formHisNapRutTien';
import { v4 as uuidv4 } from 'uuid';
import PaginationTable from '../../../components/PaginationTable';
import { Logout, logoutRequest } from '../../../components/modal/login/actions';
import { removeCookie } from '../../../lib/storages';
import { ReactReduxContext, useDispatch, useSelector } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import PerfectScrollBar from 'react-perfect-scrollbar';
import nologo from '../../../assets/img/icons/utilities/nologo.svg';
import ReactSelect from 'react-select';
import { getCommonRequest } from '../../customer/actions';

const Index = withNamespaces('translations')(({ t }) => {
  const dispatch = useDispatch();
  const { token, auth } = useSelector((state) => state.client);
  const { defaultAccount } = useSelector((state) => state.socket);
  const lang = useSelector((state) => state.client.setting.lang || 'vi');
  const { hisTransfer, cashOut, bankList, bankInfor, listBankVA } = useSelector(
    (state) => state.cash
  );
  const cashBalance = useSelector(makeGetCashBalance());
  const [tab, setTab] = useState(0);
  const [transType, setTransType] = useState('1');
  const [va, setVa] = useState([]);
  const [quickTransferMore, setQuickTransferMore] = useState(false);
  const [more, setMore] = useState(false);
  const quickTransfer =
    bankList && bankList.list?.length > 0
      ? bankList.list.filter((x) => x.bankDepoTp == 2)
      : [];

  const normalTransfer =
    bankList && bankList.list?.length > 0
      ? bankList.list.filter((x) => x.bankDepoTp == 1)
      : [];

  const ctck = bankList && bankList?.ctck;
  const { store } = useContext(ReactReduxContext);

  const columns = [
    {
      key: 'defaultAccount',
      text: t('txt-label-source'),
      className: 'text-center text--light',
      align: 'center',
      cell: (record) => {
        return <span>{record && record.subAcntNo}</span>;
      },
    },
    {
      key: 'toAcntNo',
      text: t('txt-label-target'),
      className: 'text-right',
      align: 'center',
    },
    // {
    //   text: 'Số dư nguồn',
    //   className: 'text-right',
    //   align: 'right',
    // },
    {
      key: 'trdAmt',
      text: t('txt-label-amount-want-transfer'),
      className: 'text-right  fw-500 text--light',
      align: 'center',
      cell: (record) => {
        return numberFormat(record.trdAmt, 0, '0');
      },
      sortable: true,
    },
    {
      key: 'desc',
      text: t('txt-label-cash-transfer-content'),
      className: 'text-left text--light',
      align: 'center',
    },
    {
      key: 'status',
      text: t('txt-status'),
      className: (record) => {
        return (
          'text-left text--light ' +
          (record.status === '1' ? 'r' : record.status === '2' ? 'i' : 'd')
        );
      },
      align: 'center',
      cell: (record) => {
        return getTransferStatus(record.status);
      },
    },
    {
      key: 'trdDt',
      text: t('txt-label-create-date'),
      className: 'text-right text--light ',
      align: 'center',
      sortable: true,
    },
  ];

  const loadListTransferHandle = (type = '1') => {
    if (!defaultAccount || !token) return;
    setTransType(type);
    const numDay = token && token['config'] ? token['config']['numDay'] : 30;

    const d = new Date();
    d.setDate(d.getDate() - numDay);

    const uuid = uuidv4();
    const resData = {
      group: 'CORE',
      user: token.user,
      session: token.session,
      cmd: 'getCashTransferHist',
      rqId: uuid,
      channel: 'WTS',
      data: {
        acntNo: defaultAccount.acntNo,
        subAcntNo: defaultAccount.subAcntNo,
        fromDate: formatDate(d, ''),
        toDate: formatDate(new Date(), ''),
        transType: type,
      },
    };

    dispatch(hisTransferRequest(resData));
  };

  const loadListBankHandle = () => {
    if (!token) return;
    const uuid = uuidv4();
    const resData = {
      group: 'BACK',
      user: token.user,
      session: token.session,
      cmd: 'BANK_LIST',
      rqId: uuid,
      channel: 'WTS',
    };

    dispatch(bankListRequest(resData));
  };

  const getListVaAccount = () => {
    const uuid = uuidv4();
    const params = {
      group: 'CORE',
      cmd: 'ListAccount',
      user: token.user,
      session: token.session,
      rqId: uuid,
      channel: 'WTS',
      data: { cif: token.cif },
    };
    const callback = (data) => {
      const vaAcnt = data?.custInfo?.vaAcnt;
      setVa(vaAcnt);
    };

    dispatch(getCommonRequest(params, callback, true));
  };

  function getListBankVaHandle() {
    const uuid = uuidv4();
    const resData = {
      group: 'CORE',
      user: token.user,
      session: token.session,
      cmd: 'GetBankListVA',
      rqId: uuid,
      channel: 'WTS',
    };

    dispatch(listBankVARequest(resData));
  }

  const loadCashBalanceHandle = () => {
    if (defaultAccount) {
      const uuid = uuidv4();

      const resData = {
        group: 'CORE',
        user: token.user,
        session: token.session,
        cmd: 'getTotalAssetAll',
        rqId: uuid,
        channel: 'WTS',
        data: {
          acntNo: defaultAccount.acntNo,
          subAcntNo: defaultAccount.subAcntNo,
        },
      };
      dispatch(cashBalanceRequest(resData));
    }
  };

  const toastHandle = (msg) => {
    const toastMsg = {
      id: Math.random(),
      msg: msg,
      title: t('txt-notice'),
    };
    dispatch(setToast(toastMsg));
  };

  const copyToClipboardHandle = (e) => {
    if (!e) return;

    const textToCopy = e.trim();

    var tempInputElement = document.createElement('input');
    tempInputElement.type = 'text';
    tempInputElement.value = textToCopy;
    document.body.appendChild(tempInputElement);
    tempInputElement.select();
    document.execCommand('Copy');
    document.body.removeChild(tempInputElement);
    toastHandle('Copied to clipboard');
  };

  const handleClickQRCode = (index, btnId) => {
    let element = document.getElementById(index);
    let btn_element = document.getElementById(btnId);
    if (element) {
      if (element.style.display == 'flex') {
        element.style.display = 'none';
      } else {
        element.style.display = 'flex';
      }
    }
    if (btn_element) {
      if (btn_element.classList.contains('active')) {
        btn_element.classList.remove('active');
      } else {
        btn_element.classList.add('active');
      }
    }
  };

  const getLogo = (bankCode) => {
    const path = bankInfor.find((x) => x.bankCode == bankCode)?.logo ?? '';
    return path;
  };

  const handleSaveQrImage = (url) => {
    window.open(url + '&langTp=' + lang + '&imgTp=png');
  };

  useEffect(() => {
    if (token) {
      getListVaAccount();
      loadListBankHandle();
      loadListTransferHandle();
      loadCashBalanceHandle();
      getListBankVaHandle();
    }
  }, [token]);

  useEffect(() => {
    if (defaultAccount) loadListTransferHandle(transType);
  }, [defaultAccount]);

  return (
    <div className="deposite-withdraw">
      <div className="deposite-withdraw-main">
        <div className="deposite-withdraw-main-header">
          <div className="deposite-withdraw-main-header__left">
            <span
              className={`deposite-withdraw-main-tab cursor-pointer ${
                tab == 0 ? 'active' : ''
              }`}
              onClick={() => setTab(0)}
            >
              {t('txt-label-quick-deposit')}
            </span>
            <span
              className={`deposite-withdraw-main-tab cursor-pointer ${
                tab == 1 ? 'active' : ''
              }`}
              onClick={() => setTab(1)}
            >
              {t('txt-label-normal-deposit')}
            </span>
            <span
              className={`deposite-withdraw-main-tab cursor-pointer ${
                tab == 2 ? 'active' : ''
              }`}
              onClick={() => setTab(2)}
            >
              {t('txt-label-history')}
            </span>
          </div>
          <div className="deposite-withdraw-main-header__right">
            {auth && auth.isVerified ? (
              <button
                onClick={() => dispatch(setCashOut(true))}
                className="btn btn--primary br-5 fw-500"
              >
                {t('txt-label-withdrawal')}
              </button>
            ) : (
              <button
                className="btn btn--active br-5 fw-500"
                onClick={() => dispatch(setAuth())}
              >
                {t('txt-label-withdrawal')}
              </button>
            )}
          </div>
        </div>
        <div className="deposite-withdraw-main-body">
          {tab == 0 && (
            <>
              <PerfectScrollBar>
                <>
                  <div className="deposite-withdraw-main-bankGroup">
                    <div className="deposite-withdraw-main-bankGroup__title">
                      <span className="text-title">
                        {t('txt-label-deposit-stock')}
                        <span className="text-highlight ml-2">
                          ({t('txt-support-247')})
                        </span>
                      </span>
                    </div>
                    <div className="deposite-withdraw-main-bankGroup__body">
                      <div className="deposite-withdraw-main-bankGroup__items">
                        {quickTransferMore &&
                          quickTransfer &&
                          !!quickTransfer.length &&
                          quickTransfer.map((item, index) => (
                            <>
                              <div
                                className="deposite-withdraw-main-bankItem flex-column"
                                key={index}
                              >
                                <div className="deposite-withdraw-main-bankItem w-100 p-0">
                                  <div className="bank-infor">
                                    <div
                                      className="bank-logo"
                                      style={{
                                        backgroundImage: `url('${getLogo(
                                          item.bankCode
                                        )}')`,
                                      }}
                                    ></div>
                                    <div className="bank-desc">
                                      <span>{item.bankCode}</span>
                                      <span className="text-desc">
                                        {item.bankName}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="bank-beneficiary">
                                    <span className="text-desc">
                                      {t('txt-label-beneficiary')}
                                    </span>
                                    <span>{ctck}</span>
                                  </div>
                                  <div className="account-beneficiary">
                                    <div className="d-flex flex-column gap-4 w-100">
                                      <span className="text-desc">
                                        {t('txt-label-account-beneficiary')}
                                      </span>
                                      <span>
                                        <span>{item.bankAcc}</span>
                                        {item.qrCodeUrl && (
                                          <span
                                            className="btn btn--light fw-500 br-5 float-right ml-2"
                                            id={`btn-${
                                              item.bankCode + item.bankAcc
                                            }`}
                                            onClick={() =>
                                              handleClickQRCode(
                                                `quick-transfer-${
                                                  item.bankCode + item.bankAcc
                                                }`,
                                                `btn-${
                                                  item.bankCode + item.bankAcc
                                                }`
                                              )
                                            }
                                          >
                                            {t(
                                              'transaction.withdraw.qrCode.name'
                                            )}
                                          </span>
                                        )}
                                        <span
                                          className="btn btn--light fw-500 br-5 float-right"
                                          onClick={() =>
                                            copyToClipboardHandle(item.bankAcc)
                                          }
                                        >
                                          Copy
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                {item.qrCodeUrl && (
                                  <div
                                    className="bank-qr"
                                    style={{ display: 'none' }}
                                    id={`quick-transfer-${
                                      item.bankCode + item.bankAcc
                                    }`}
                                  >
                                    <div
                                      className="qr-img"
                                      style={{
                                        backgroundImage: `url(${item.qrCodeUrl}&langTp=${lang})`,
                                      }}
                                    ></div>
                                    <div className="qr-infor">
                                      <span className="text--light fw-500">
                                        {t('transaction.withdraw.qrCode.text1')}
                                      </span>
                                      <span className="text--light3">
                                        {t('transaction.withdraw.qrCode.text2')}
                                      </span>
                                      <span
                                        className="btn btn--primary"
                                        onClick={() =>
                                          handleSaveQrImage(item.qrCodeUrl)
                                        }
                                      >
                                        {t(
                                          'transaction.withdraw.qrCode.saveQR'
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </>
                          ))}
                        {!quickTransferMore &&
                          !!quickTransfer?.length > 0 &&
                          quickTransfer.map(
                            (item, index) =>
                              index <= 1 && (
                                <>
                                  <div
                                    className="deposite-withdraw-main-bankItem flex-column"
                                    key={index}
                                  >
                                    <div className="deposite-withdraw-main-bankItem w-100 p-0">
                                      <div className="bank-infor">
                                        <div
                                          className="bank-logo"
                                          style={{
                                            backgroundImage: `url('${getLogo(
                                              item.bankCode
                                            )}')`,
                                          }}
                                        ></div>
                                        <div className="bank-desc">
                                          <span>{item.bankCode}</span>
                                          <span className="text-desc">
                                            {item.bankName}
                                          </span>
                                        </div>
                                      </div>
                                      <div className="bank-beneficiary">
                                        <span className="text-desc">
                                          {t('txt-label-beneficiary')}
                                        </span>
                                        <span>{ctck}</span>
                                      </div>
                                      <div className="account-beneficiary">
                                        <div className="d-flex flex-column gap-4 w-100">
                                          <span className="text-desc">
                                            {t('txt-label-account-beneficiary')}
                                          </span>
                                          <span>
                                            <span>{item.bankAcc}</span>
                                            {item.qrCodeUrl && (
                                              <span
                                                className="btn btn--light fw-500 br-5 float-right ml-2"
                                                id={`btn-${
                                                  item.bankCode + item.bankAcc
                                                }`}
                                                onClick={() =>
                                                  handleClickQRCode(
                                                    `quick-transfer-${
                                                      item.bankCode +
                                                      item.bankAcc
                                                    }`,
                                                    `btn-${
                                                      item.bankCode +
                                                      item.bankAcc
                                                    }`
                                                  )
                                                }
                                              >
                                                {t(
                                                  'transaction.withdraw.qrCode.name'
                                                )}
                                              </span>
                                            )}
                                            <span
                                              className="btn btn--light fw-500 br-5 float-right"
                                              onClick={() =>
                                                copyToClipboardHandle(
                                                  item.bankAcc
                                                )
                                              }
                                            >
                                              Copy
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    {item.qrCodeUrl && (
                                      <div
                                        className="bank-qr"
                                        style={{ display: 'none' }}
                                        id={`quick-transfer-${
                                          item.bankCode + item.bankAcc
                                        }`}
                                      >
                                        <div
                                          className="qr-img"
                                          style={{
                                            backgroundImage: `url(${item.qrCodeUrl}&langTp=${lang})`,
                                          }}
                                        ></div>
                                        <div className="qr-infor">
                                          <span className="text--light fw-500">
                                            {t(
                                              'transaction.withdraw.qrCode.text1'
                                            )}
                                          </span>
                                          <span className="text--light3">
                                            {t(
                                              'transaction.withdraw.qrCode.text2'
                                            )}
                                          </span>
                                          <span
                                            className="btn btn--primary"
                                            onClick={() =>
                                              handleSaveQrImage(item.qrCodeUrl)
                                            }
                                          >
                                            {t(
                                              'transaction.withdraw.qrCode.saveQR'
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </>
                              )
                          )}
                        {!quickTransferMore && quickTransfer?.length > 2 && (
                          <div
                            className="deposite-withdraw-main-bankItem-showmore cursor-pointer"
                            onClick={() => setQuickTransferMore(true)}
                          >
                            <span>{t('txt-showmore')}</span>
                            <span className="icon iMore ml-1"></span>
                          </div>
                        )}
                      </div>
                      <div className="deposite-withdraw-main-bankGroup__content">
                        <span className="text-desc text-desc--bigger">
                          {t('txt-label-transfer-content')}
                        </span>
                        <span className="d-flex justify-content-between align-items-center gap-4">
                          <span className="fw-500">
                            {token &&
                              defaultAccount &&
                              `${defaultAccount.vsdAcntNo} ${removeAccent(
                                token.name
                              )} ${t('txt-transfer-content')}`}
                          </span>
                          <span
                            className="btn btn--light fw-500 br-5"
                            onClick={() => {
                              if (token && defaultAccount) {
                                copyToClipboardHandle(
                                  `${defaultAccount.vsdAcntNo} ${removeAccent(
                                    token.name
                                  )} ${t('txt-transfer-content')}`
                                );
                              }
                            }}
                          >
                            Copy
                          </span>
                        </span>
                        <span className="text-desc">{t('txt-gd')}</span>
                      </div>
                    </div>
                  </div>
                  <div className="deposite-withdraw-main-bankGroup">
                    <div className="deposite-withdraw-main-bankGroup__title">
                      <span className="text-title">
                        {t('txt-label-va-deposit-stock')}
                        <span className="text-highlight ml-2">
                          ({t('txt-support-247')})
                        </span>
                      </span>
                    </div>
                    <div className="deposite-withdraw-main-bankGroup__body">
                      <div className="deposite-withdraw-main-bankGroup__items">
                        {!more && listBankVA && listBankVA.length > 0 && (
                          <>
                            <div
                              className="deposite-withdraw-main-bankItem flex-column"
                              key={0}
                            >
                              <div className="deposite-withdraw-main-bankItem w-100 p-0">
                                <div className="bank-infor">
                                  <div
                                    className="bank-logo"
                                    style={{
                                      backgroundImage: `url('${getLogo(
                                        listBankVA[0].bankCode
                                      )}')`,
                                    }}
                                  ></div>
                                  <div className="bank-desc">
                                    <span>{listBankVA[0].bankCode}</span>
                                  </div>
                                </div>
                                <div className="bank-beneficiary">
                                  <span className="text-desc">
                                    {t('txt-label-beneficiary')}
                                  </span>
                                  <span>{listBankVA[0].bankAcntNm}</span>
                                </div>
                                <div className="account-beneficiary">
                                  <div className="d-flex flex-column gap-4 w-100">
                                    <span className="text-desc">
                                      {t('txt-label-va-account-beneficiary')}
                                    </span>
                                    <span>
                                      <span>{listBankVA[0].vaAccnt}</span>
                                      {listBankVA[0].qrCodeUrl && (
                                        <span
                                          className="btn btn--light fw-500 br-5 float-right ml-2 active"
                                          id={`btn-${
                                            listBankVA[0].bankCode +
                                            listBankVA[0].bankAcc
                                          }`}
                                          onClick={() =>
                                            handleClickQRCode(
                                              `va-${
                                                listBankVA[0].bankCode +
                                                listBankVA[0].bankAcc
                                              }`,
                                              `btn-${
                                                listBankVA[0].bankCode +
                                                listBankVA[0].bankAcc
                                              }`
                                            )
                                          }
                                        >
                                          {t(
                                            'transaction.withdraw.qrCode.name'
                                          )}
                                        </span>
                                      )}
                                      <span
                                        className="btn btn--light fw-500 br-5 float-right"
                                        onClick={() =>
                                          copyToClipboardHandle(
                                            listBankVA[0].vaAccnt
                                          )
                                        }
                                      >
                                        Copy
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              </div>
                              {listBankVA[0].qrCodeUrl && (
                                <div
                                  className="bank-qr"
                                  style={{ display: 'flex' }}
                                  id={`va-${
                                    listBankVA[0].bankCode +
                                    listBankVA[0].bankAcc
                                  }`}
                                >
                                  <div
                                    className="qr-img"
                                    style={{
                                      backgroundImage: `url(${listBankVA[0].qrCodeUrl}&langTp=${lang})`,
                                    }}
                                  ></div>
                                  <div className="qr-infor">
                                    <span className="text--light fw-500">
                                      {t('transaction.withdraw.qrCode.text1')}
                                    </span>
                                    <span className="text--light3">
                                      {t('transaction.withdraw.qrCode.text2')}
                                    </span>
                                    <span
                                      className="btn btn--primary"
                                      onClick={() =>
                                        handleSaveQrImage(
                                          listBankVA[0].qrCodeUrl
                                        )
                                      }
                                    >
                                      {t('transaction.withdraw.qrCode.saveQR')}
                                    </span>
                                  </div>
                                </div>
                              )}
                            </div>
                            <div
                              className="deposite-withdraw-main-bankItem-showmore cursor-pointer"
                              onClick={() => setMore(true)}
                            >
                              <span>{t('txt-showmore')}</span>
                              <span className="icon iMore ml-1"></span>
                            </div>
                          </>
                        )}

                        {more &&
                          listBankVA &&
                          !!listBankVA.length &&
                          listBankVA.map((item, index) => (
                            <div
                              className="deposite-withdraw-main-bankItem flex-column"
                              key={index}
                            >
                              <div className="deposite-withdraw-main-bankItem w-100 p-0">
                                <div className="bank-infor">
                                  <div
                                    className="bank-logo"
                                    style={{
                                      backgroundImage: `url('${getLogo(
                                        item.bankCode
                                      )}')`,
                                    }}
                                  ></div>
                                  <div className="bank-desc">
                                    <span>{item.bankCode}</span>
                                  </div>
                                </div>
                                <div className="bank-beneficiary">
                                  <span className="text-desc">
                                    {t('txt-label-beneficiary')}
                                  </span>
                                  <span>{item.bankAcntNm}</span>
                                </div>
                                <div className="account-beneficiary">
                                  <div className="d-flex flex-column gap-4 w-100">
                                    <span className="text-desc">
                                      {t('txt-label-va-account-beneficiary')}
                                    </span>
                                    <span>
                                      <span>{item.vaAccnt}</span>
                                      {item.qrCodeUrl && (
                                        <span
                                          className={`btn btn--light fw-500 br-5 float-right ml-2 ${
                                            index == 0 ? 'active' : ''
                                          }`}
                                          id={`btn-${
                                            item.bankCode + item.bankAcc
                                          }`}
                                          onClick={(e) => {
                                            handleClickQRCode(
                                              `va-${
                                                item.bankCode + item.bankAcc
                                              }`,
                                              `btn-${
                                                item.bankCode + item.bankAcc
                                              }`
                                            );
                                          }}
                                        >
                                          {t(
                                            'transaction.withdraw.qrCode.name'
                                          )}
                                        </span>
                                      )}
                                      <span
                                        className="btn btn--light fw-500 br-5 float-right"
                                        onClick={() =>
                                          copyToClipboardHandle(item.vaAccnt)
                                        }
                                      >
                                        Copy
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              </div>
                              {item.qrCodeUrl && (
                                <div
                                  className="bank-qr"
                                  style={{
                                    display: index == 0 ? 'flex' : 'none',
                                  }}
                                  id={`va-${item.bankCode + item.bankAcc}`}
                                >
                                  <div
                                    className="qr-img"
                                    style={{
                                      backgroundImage: `url(${item.qrCodeUrl}&langTp=${lang})`,
                                    }}
                                  ></div>
                                  <div className="qr-infor">
                                    <span className="text--light fw-500">
                                      {t('transaction.withdraw.qrCode.text1')}
                                    </span>
                                    <span className="text--light3">
                                      {t('transaction.withdraw.qrCode.text2')}
                                    </span>
                                    <span
                                      className="btn btn--primary"
                                      onClick={() =>
                                        handleSaveQrImage(item.qrCodeUrl)
                                      }
                                    >
                                      {t('transaction.withdraw.qrCode.saveQR')}
                                    </span>
                                  </div>
                                </div>
                              )}
                            </div>
                          ))}
                      </div>
                      <div className="deposite-withdraw-main-bankGroup__content">
                        <span className="text-highlight text-desc--bigger">
                          {t('txt-note')}
                        </span>
                        <span className="text-desc multiple">
                          <span>{t('txt-gd-va')}</span>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: t('txt-gd-va1'),
                            }}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </>
              </PerfectScrollBar>

              <div
                className="mt-3"
                dangerouslySetInnerHTML={{ __html: t('txt-gd-note') }}
              />
            </>
          )}
          {tab == 1 && (
            <>
              <PerfectScrollBar>
                <div className="deposite-withdraw-main-bankGroup">
                  <div className="deposite-withdraw-main-bankGroup__title">
                    <span className="text-title">
                      {t('txt-label-deposit-stock')}
                    </span>
                  </div>
                  <div className="deposite-withdraw-main-bankGroup__body">
                    <div className="deposite-withdraw-main-bankGroup__items">
                      {normalTransfer &&
                        !!normalTransfer.length &&
                        normalTransfer.map((item, index) => (
                          <div
                            className="deposite-withdraw-main-bankItem flex-column"
                            key={index}
                          >
                            <div className="deposite-withdraw-main-bankItem w-100 p-0">
                              <div className="bank-infor">
                                <div
                                  className="bank-logo"
                                  style={{
                                    backgroundImage: `url('${getLogo(
                                      item.bankCode
                                    )}')`,
                                  }}
                                ></div>
                                <div className="bank-desc">
                                  <span>{item.bankCode}</span>
                                  <span className="text-desc">
                                    {item.bankName}
                                  </span>
                                </div>
                              </div>
                              <div className="bank-beneficiary">
                                <span className="text-desc">
                                  {t('txt-label-beneficiary')}
                                </span>
                                <span>{ctck}</span>
                              </div>
                              <div className="account-beneficiary">
                                <div className="d-flex flex-column gap-4 w-100">
                                  <span className="text-desc">
                                    {t('txt-label-account-beneficiary')}
                                  </span>
                                  <span>
                                    <span>{item.bankAcc}</span>
                                    {item.qrCodeUrl && (
                                      <span
                                        className="btn btn--light fw-500 br-5 float-right ml-2"
                                        id={`btn-${
                                          item.bankCode + item.bankAcc
                                        }`}
                                        onClick={() =>
                                          handleClickQRCode(
                                            `normal-transfer-${
                                              item.bankCode + item.bankAcc
                                            }`,
                                            `btn-${
                                              item.bankCode + item.bankAcc
                                            }`
                                          )
                                        }
                                      >
                                        {t('transaction.withdraw.qrCode.name')}
                                      </span>
                                    )}
                                    <span
                                      className="btn btn--light fw-500 br-5 float-right"
                                      onClick={() =>
                                        copyToClipboardHandle(item.bankAcc)
                                      }
                                    >
                                      Copy
                                    </span>
                                  </span>
                                </div>
                              </div>
                            </div>
                            {item.qrCodeUrl && (
                              <div
                                className="bank-qr"
                                style={{
                                  display: 'none',
                                }}
                                id={`normal-transfer-${
                                  item.bankCode + item.bankAcc
                                }`}
                              >
                                <div
                                  className="qr-img"
                                  style={{
                                    backgroundImage: `url(${item.qrCodeUrl}&langTp=${lang})`,
                                  }}
                                ></div>
                                <div className="qr-infor">
                                  <span className="text--light fw-500">
                                    {t('transaction.withdraw.qrCode.text1')}
                                  </span>
                                  <span className="text--light3">
                                    {t('transaction.withdraw.qrCode.text2')}
                                  </span>
                                  <span
                                    className="btn btn--primary"
                                    onClick={() =>
                                      handleSaveQrImage(item.qrCodeUrl)
                                    }
                                  >
                                    {t('transaction.withdraw.qrCode.saveQR')}
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>
                        ))}
                    </div>
                    <div className="deposite-withdraw-main-bankGroup__content">
                      <span className="text-desc text-desc--bigger">
                        {t('txt-label-transfer-content')}
                      </span>
                      <span className="d-flex justify-content-between align-items-center gap-4">
                        <span className="fw-500">
                          {token &&
                            defaultAccount &&
                            `${defaultAccount.vsdAcntNo} ${removeAccent(
                              token.name
                            )} ${t('txt-transfer-content')}`}
                        </span>
                        <span
                          className="btn btn--light fw-500 br-5"
                          onClick={() => {
                            if (token && defaultAccount) {
                              copyToClipboardHandle(
                                `${defaultAccount.vsdAcntNo} ${removeAccent(
                                  token.name
                                )} ${t('txt-transfer-content')}`
                              );
                            }
                          }}
                        >
                          Copy
                        </span>
                      </span>
                      <span className="text-desc">{t('txt-gd')}</span>
                    </div>
                  </div>
                </div>
              </PerfectScrollBar>
              <div
                className="mt-3"
                dangerouslySetInnerHTML={{ __html: t('txt-gd-note') }}
              />
            </>
          )}
          {tab == 2 && (
            <>
              <PerfectScrollBar>
                <div className="deposite-withdraw-data">
                  <div className="deposite-withdraw-data__header">
                    <span className="text-title text--light">
                      {t('txt-label-history')}
                    </span>
                    <Filter callback={loadListTransferHandle} />
                  </div>
                  <PaginationTable
                    title=""
                    pageSize={10}
                    columns={columns}
                    hasPaging
                    defaultAccount={defaultAccount}
                    source={
                      hisTransfer && hisTransfer.list ? hisTransfer.list : []
                    }
                  />
                </div>
              </PerfectScrollBar>
            </>
          )}
        </div>
      </div>
    </div>
  );
});

const Filter = withNamespaces('translations')(({ t, callback }) => {
  const TYPE = [
    { value: '1', label: 'txt-stt-internal' },
    { value: '2', label: 'txt-stt-bank' },
  ];

  const [type, setType] = useState(TYPE[0]);
  useEffect(() => {
    if (type && callback && typeof callback == 'function') {
      callback(type.value);
    }
  }, [type]);

  return (
    <div className="deposite-withdraw-main__filters">
      <ReactSelect
        placeholder={t('txt-all')}
        className="transaction-filters__select"
        classNamePrefix="filter-control-select"
        options={TYPE}
        isSearchable={false}
        components={{
          IndicatorSeparator: () => null,
        }}
        value={type}
        onChange={(value) => setType(value)}
        getOptionLabel={(option) => t(option.label)}
        getOptionValue={(option) => option.value}
      />
    </div>
  );
});

export default Index;
