import React from 'react';

import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { numberFormat } from '../../util';
import { translate } from 'react-i18next';
class InputMask extends React.Component {
  state = {
    _value: '',
  };

  componentDidMount() {
    const { value } = this.props;
    if (!value && value > 0) this.setState({ _value: value + '' });
  }
  _handleChange = (e) => {
    const { name, value } = e.target;

    // this.setState({ _value: value });
    if (this.props.handleChange) {
      this.props.handleChange(value, name);
    }
  };

  _handleBlur = (e) => {
    // console.log(e.target.name, e.target.value);
    if (this.props.handleBlur) this.props.handleBlur(e.target.name);
  };

  render() {
    const { _placeholder, _prefix, maxVal, name, value, disabled, t } =
      this.props;
    // const { _value } = this.state;
    // console.log(disabled)
    return (
      <MaskedInput
        name={name}
        disabled={disabled}
        placeholder={
          _placeholder ||
          `${numberFormat(maxVal, 0, '0')} ${t('txt-title-max')}`
        }
        value={value}
        maxLength="15"
        mask={createNumberMask({ prefix: _prefix || '' })}
        className="form-control text-right form-input fz_14"
        onFocus={(e) => e.currentTarget.select()}
        onChange={this.props.handleChange}
        onBlur={this._handleBlur}
      />
    );
  }
}

export default translate('translations')(InputMask);
