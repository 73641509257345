import React, { useState, useEffect } from 'react';

const MaskInput = ({ value, inputRef, showLabels = false, ...props }) => {
  const [mask, setMask] = useState('');

  useEffect(() => {
    setMask(value);
  }, [value]);

  useEffect(() => {
    if (mask) {
      const timeoutId = setTimeout(() => {
        setMask(mask ? '•' : '');
      }, 1000);

      return () => clearTimeout(timeoutId);
    }
  }, [mask]);

  return <input {...props} ref={inputRef} value={mask} />;
};

export default MaskInput;
