import React from 'react';
import { connect } from 'react-redux';
import { Nav } from 'react-bootstrap';
import { Link, Redirect } from 'react-router-dom';
import PerfectScrollBar from 'react-perfect-scrollbar';
import DepositWithdrawalMoney from '../../components/cash/DepositWithdrawalMoney';
import ChuyenCoPhieu from '../../components/cash/ChuyenCoPhieu';
import UngTruocTienBan from '../../components/cash/UngTruocTienBan';
import ChuyenTienTieuKhoan from '../../components/cash/ChuyenTienTieuKhoan';
import CongNoMargin from '../../components/cash/CongNoMargin';
import DangKyQuyen from '../../components/cash/DangKyQuyen';
import XacNhanLenh from '../../components/cash/XacNhanLenh';
import GDLoLe from '../../components/cash/GDLoLe';

import { ReactComponent as SvgChuyenCoPhieu } from '../../assets/img/_chuyen-co-phieu.svg';
import { ReactComponent as SvgChuyenTienTieuKhoan } from '../../assets/img/_chuyen-tien-tieu-khoan.svg';
import { ReactComponent as SvgNapRutTien } from '../../assets/img/_nap-rut-tien.svg';
import { ReactComponent as SvgUngTruocTienBan } from '../../assets/img/_ung-truoc-tien-ban.svg';
import { ReactComponent as SvgDangKyQuyen } from '../../assets/img/_dang_ky_quyen.svg';
import { ReactComponent as SvgBankHold } from '../../assets/img/_bank_hold.svg';
import { WebSocketContext } from '../socket/webSocket';
import { makeGetToken, makeGetDefaultAccount } from '../../lib/selector';

import { v4 as uuidv4 } from 'uuid';
import BankHold from '../../components/cash/BankHold';
import { cashBalanceRequest } from '../privData/actions';
import { BsCircleHalf } from 'react-icons/bs';
import { translate } from 'react-i18next';
import MarginList from '../../components/cash/MarginList';

import { isEqual } from 'lodash';

class Cash extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { defaultAccount } = this.props;
    if (defaultAccount) this._handleLoadCashBalance();
  }

  componentDidUpdate(preProps) {
    const { defaultAccount } = this.props;
    if (defaultAccount && !isEqual(defaultAccount, preProps.defaultAccount)) {
      this._handleLoadCashBalance();
    }
  }

  // get cash balance
  _handleLoadCashBalance = () => {
    const { defaultAccount, token } = this.props;

    if (defaultAccount) {
      const uuid = uuidv4();

      const resData = {
        group: 'CORE',
        user: token.user,
        session: token.session,
        cmd: 'getTotalAssetAll',
        rqId: uuid,
        channel: 'WTS',
        data: {
          acntNo: defaultAccount.acntNo,
          subAcntNo: defaultAccount.subAcntNo,
        },
      };
      this.props.dispatch(cashBalanceRequest(resData));
    }
  };

  render() {
    const {
      match: { params },
      token,
      t,
    } = this.props;

    // console.log(token);
    if (!token) return <Redirect to="/" />;
    return (
      <div className="page-asset">
        <div className="page-content d-flex flex-row">
          <PerfectScrollBar>
            <Nav as="ul" className="d-flex flex-column">
              <li
                className="asset-header d-flex align-items-center"
                style={{ width: '319px', borderBottom: '1px solid #2F3134' }}
              >
                <span className="fz_14 text-uppercase color-half-brown">
                  {t('txt-utilities')}
                </span>
              </li>
              <Nav.Item
                as="li"
                className={
                  params.typeId === 'ung-truoc-tien-ban' ? 'active' : ''
                }
              >
                <Link to="ung-truoc-tien-ban">
                  <SvgUngTruocTienBan />
                  {t('txt-cash-advance')}
                </Link>
              </Nav.Item>
              <Nav.Item
                as="li"
                className={params.typeId === 'chuyen-co-phieu' ? 'active' : ''}
              >
                <Link to="chuyen-co-phieu">
                  <SvgChuyenCoPhieu />
                  {t('txt-stock-transfer')}
                </Link>
              </Nav.Item>
              <Nav.Item
                as="li"
                className={
                  params.typeId === 'chuyen-tien-giua-cac-tieu-khoan'
                    ? 'active'
                    : ''
                }
              >
                <Link to="chuyen-tien-giua-cac-tieu-khoan">
                  <SvgChuyenTienTieuKhoan />
                  {t('txt-money-transfer')}
                </Link>
              </Nav.Item>
              {/**
              <Nav.Item
                as="li"
                className={params.typeId === 'cong-no-margin' ? 'active' : ''}
              >
                <Link to="cong-no-margin">
                  <SvgCongNoMargin />
                  {'Công nợ Margin'}
                </Link>
              </Nav.Item>
              */}
              <Nav.Item
                as="li"
                className={params.typeId === 'nap-rut-tien' ? 'active' : ''}
              >
                <Link to="nap-rut-tien">
                  <SvgNapRutTien />
                  {t('txt-deposit-withdrawal')}
                </Link>
              </Nav.Item>
              <Nav.Item
                as="li"
                className={params.typeId === 'dang-ky-quyen' ? 'active' : ''}
              >
                <Link to="dang-ky-quyen">
                  <SvgDangKyQuyen />
                  {t('txt-reference-subcription')}
                </Link>
              </Nav.Item>
              {/**
              <Nav.Item
                as="li"
                className={params.typeId === 'xac-nhan-lenh' ? 'active' : ''}
              >
                <Link to="xac-nhan-lenh">
                  <SvgXacNhanLenh />
                  {'Xác nhận lệnh'}
                </Link>
              </Nav.Item>
              <Nav.Item
                as="li"
                className={params.typeId === 'lo-le' ? 'active' : ''}
              >
                <Link to="lo-le">
                  <SvgLoLe />
                  {'GD cổ phiếu lẻ'}
                </Link>
              </Nav.Item>
              */}
              <Nav.Item
                as="li"
                className={params.typeId === 'bank-hold' ? 'active' : ''}
              >
                <Link to="bank-hold">
                  <SvgBankHold />
                  {t('txt-hold-unhold')}
                </Link>
              </Nav.Item>
              <Nav.Item
                as="li"
                className={params.typeId === 'margin-list' ? 'active' : ''}
              >
                <Link to="margin-list">
                  <BsCircleHalf style={{ transform: 'rotate(45deg)' }} />
                  {t('txt-label-margin-list')}
                </Link>
              </Nav.Item>
            </Nav>
          </PerfectScrollBar>
          <div
            className="d-flex flex-column"
            style={{ width: 'calc( 100% - 320px )', height: '100%' }}
          >
            {params.typeId === 'ung-truoc-tien-ban' ? (
              <UngTruocTienBan store={this.props.store} />
            ) : params.typeId === 'chuyen-co-phieu' ? (
              <ChuyenCoPhieu />
            ) : params.typeId === 'chuyen-tien-giua-cac-tieu-khoan' ? (
              <ChuyenTienTieuKhoan />
            ) : params.typeId === 'cong-no-margin' ? (
              <CongNoMargin />
            ) : params.typeId === 'dang-ky-quyen' ? (
              <DangKyQuyen store={this.props.store} />
            ) : params.typeId === 'xac-nhan-lenh' ? (
              <XacNhanLenh />
            ) : params.typeId === 'lo-le' ? (
              <GDLoLe />
            ) : params.typeId === 'bank-hold' ? (
              <BankHold store={this.props.store} />
            ) : params.typeId === 'margin-list' ? (
              <MarginList />
            ) : (
              <DepositWithdrawalMoney store={this.props.store} />
            )}
          </div>
        </div>
      </div>
    );
  }
}

Cash.contextType = WebSocketContext;

const makeMapStateToProps = () => {
  const getToken = makeGetToken();
  const getDefaultAccount = makeGetDefaultAccount();

  const mapStateToProps = (state) => {
    return {
      token: getToken(state),
      defaultAccount: getDefaultAccount(state),
    };
  };
  return mapStateToProps;
};

export default connect(makeMapStateToProps)(translate('translations')(Cash));
