import React, { useState, useEffect, useContext } from 'react';
import { Switch, Route, Link, Redirect } from 'react-router-dom';
import { useHistory } from 'react-router';
import { withNamespaces } from 'react-i18next';
import { ReactReduxContext, useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { cashBalanceRequest, positionsRequest } from '../privData/actions';

import Assets from './components/assets';
import Portfolio from './components/portfolio';
import {
  setCashOut,
  setAuth,
  unsetClient,
  setToast,
} from '../../containers/client/actions';
import { setDefaultAccount } from '../../containers/socket/actions';
import VerticalNavbar from '../../components/navbar/verticalNavbar';
import { handleApiErrors } from '../../lib/api-errors';
import { Logout, logoutRequest } from '../../components/modal/login/actions';
import { removeCookie } from '../../lib/storages';
import { getMessage } from '../../lib/selector';
import { accountTypeDetect } from '../../util';
import { Mixpanel } from '../../lib/mixpanel';
import { depositWithdrawalMoneyDerivative } from '../transaction/pages';

const appUrl = `${process.env.REACT_APP_API_URL}`;

const nav = [
  {
    name: 'personal.nav.asset.name',
    class: 'icon iAsset1',
    onClick: () =>
      Mixpanel.track('View Asset', {
        'Button Location': 'Tab Asset',
        'Asset Detail': 'Overview Asset',
      }),
    child: [
      {
        name: 'personal.nav.asset.overview',
        path: 'assets',
        onClick: () =>
          Mixpanel.track('View Asset', {
            'Button Location': 'Tab Asset',
            'Asset Detail': 'Overview Asset',
          }),
        component: Assets,
      },
      {
        name: 'personal.nav.asset.portfolio',
        path: 'assets-portfolio',
        onClick: () =>
          Mixpanel.track('View Asset', {
            'Button Location': 'Tab Asset',
            'Asset Detail': 'Portfolio',
          }),
        component: Portfolio,
      },
    ],
  },
  {
    name: 'personal.nav.margin.name',
    class: 'icon iUnion',
    child: [
      {
        name: 'personal.nav.margin.marginDebt',
        path: '../transaction/margin-debt',
        onClick: () => Mixpanel.track('View Margin Debt'),
      },
      {
        name: 'personal.nav.margin.marginList',
        path: '../transaction/margin-list',
        onClick: '',
      },
    ],
  },
  {
    name: 'transaction.name',
    class: 'icon iCard',
    child: [
      {
        name: 'personal.nav.payment.depositAndWithdrawMoney',
        path: '../transaction/deposit-withdraw-money',
        onClick: '',
      },
      {
        name: 'personal.nav.payment.depositAndWithdrawMoneyDerivative',
        path: '../transaction/deposit-withdraw-money-derivative',
        component: depositWithdrawalMoneyDerivative,
        onClick: () => {},
      },
      {
        name: 'personal.nav.payment.cashAdvance',
        path: '../transaction/cash-advance',
        onClick: '',
      },
      {
        name: 'personal.nav.payment.cashInternalTransfer',
        path: '../transaction/cash-internal-transfer',
        onClick: '',
      },
      {
        name: 'personal.nav.payment.stockInternalTransfer',
        path: '../transaction/stock-internal-transfer',
        onClick: '',
      },
      {
        name: 'personal.nav.payment.rightInfo',
        path: '../transaction/right-info',
        onClick: '',
      },
      {
        name: 'personal.nav.payment.holdUnholdBank',
        path: '../transaction/hold-unhold-bank',
        onClick: '',
      },
    ],
  },
  {
    name: 'personal.nav.statement.name',
    class: 'icon iStatement',
    child: [
      {
        name: 'personal.nav.statement.orderStatement',
        path: '../transaction/order-statement',
        onClick: () =>
          Mixpanel.track('View Statement', {
            'Statement Type': 'Order Statement',
          }),
      },
      {
        name: 'personal.nav.statement.conditionalOrderStatement',
        path: '../transaction/conditional-order-statement',
        onClick: () =>
          Mixpanel.track('View Statement', {
            'Statement Type': 'Conditional Order Statement',
          }),
      },
      {
        name: 'personal.nav.statement.cashStatement',
        path: '../transaction/cash-statement',
        onClick: () =>
          Mixpanel.track('View Statement', {
            'Statement Type': 'Cash Statement',
          }),
      },
      {
        name: 'personal.nav.statement.securitiesStatement',
        path: '../transaction/securities-statement',
        onClick: () =>
          Mixpanel.track('View Statement', {
            'Statement Type': 'Securities Statement',
          }),
      },
      {
        name: 'personal.nav.statement.executedGainLossStatement',
        path: '../transaction/gain-loss-statement',
        onClick: () =>
          Mixpanel.track('View Statement', {
            'Statement Type': 'Executed Gain Loss Statement',
          }),
      },
    ],
  },
];

const Content = withNamespaces('translations')(({ t, params }) => {
  const { token, auth } = useSelector((state) => state.client);
  const dispatch = useDispatch();
  const { typeId } = params;
  const listHasAllAccount = ['assets', 'assets-portfolio'];
  const [tabActive, setTabActive] = useState(0);
  const [account, setAccount] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [cashBalance, setCashBalance] = useState();

  const { listAccount, defaultAccount } = useSelector((state) => state.socket);
  const { showUserModel, showAssetPortfolioModal } = useSelector(
    (state) => state.layoutReducer
  );

  const bondAccounts = token?.custInfo.normal;
  const _account =
    bondAccounts && bondAccounts.length > 0 ? bondAccounts[0] : {};
  const { store } = useContext(ReactReduxContext);

  let routes = [];

  nav.map((x) => {
    if (x.child && x.child.length > 0) {
      x.child.map((item) => {
        routes.push(item);
      });
    }
  });

  const page = routes.find((x) => x.path == typeId);

  // paging
  const handleRefresh = () => {
    setRefresh(!refresh);
    loadCashBalanceHandle(account ? account.subAcntNo : 'null');
  };

  const withdrawHandle = () => {
    if (auth && auth.isVerified) {
      dispatch(setCashOut(true));
    } else {
      dispatch(setAuth());
    }
  };

  useEffect(() => {
    if (tabActive === 0 && listAccount && !listHasAllAccount.includes(typeId)) {
      setTabActive(1);
      setAccount(listAccount[0]);
    }
  }, [typeId]);

  useEffect(() => {
    loadCashBalanceHandle(account ? account.subAcntNo : 'null');
  }, [tabActive]);

  useEffect(() => {
    if ((showUserModel || showAssetPortfolioModal) && defaultAccount) {
      setAccount(defaultAccount);
      setTabActive(
        listAccount.findIndex(
          (account) => account.subAcntNo == defaultAccount.subAcntNo
        ) + 1
      );
    }
  }, [showUserModel, defaultAccount, showAssetPortfolioModal]);

  const loadCashBalanceHandle = (subAccount = null) => {
    if (!token) return;
    const uuid = uuidv4();
    const resData = {
      group: 'CORE',
      user: token.user,
      session: token.session,
      cmd: 'getTotalAssetAll',
      rqId: uuid,
      channel: 'WTS',
      data: {
        acntNo: _account.acntNo,
        subAcntNo: subAccount,
      },
    };
    const url = `${appUrl}/CoreServlet.pt`;
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
      body: JSON.stringify(resData),
    })
      .then(handleApiErrors)
      .then((response) => response.json())
      .then((res) => {
        if (res.http == 200) {
          const _cashBalance = res.data;
          setCashBalance(_cashBalance);
        } else {
          if (res.http === 401) {
            if (token) dispatch(logoutRequest(token));
            dispatch(unsetClient());
            removeCookie('token');
            removeCookie('authen');
            dispatch(Logout());
            const toastMsg = {
              id: Math.random(),
              msg: t('txt-valid-loss-session'),
              title: t('txt-notice'),
            };
            dispatch(setToast(toastMsg));
          } else {
            const msgErr = getMessage(res.data.message, store.getState);
            const toastMsg = {
              id: Math.random(),
              msg: msgErr,
              title: t('txt-notice'),
            };
            dispatch(setToast(toastMsg));
          }
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  useEffect(() => {
    loadCashBalanceHandle(account ? account.subAcntNo : 'null');
  }, []);

  return (
    <div className="personal-content">
      <div className="personal-assets-header card-panel-2-header">
        <span className="personal-assets-header__left">
          {listHasAllAccount.includes(typeId) && (
            <span
              className={`card-panel-2__tab ${tabActive == 0 ? 'active' : ''}`}
              onClick={() => {
                setTabActive(0);
                setAccount(null);
              }}
            >
              {t('personal.asset.totalAccount')}
            </span>
          )}
          {listAccount &&
            listAccount.map((account, i) => (
              <span
                key={i + 1}
                className={`card-panel-2__tab ${
                  tabActive == i + 1 ? 'active' : ''
                }`}
                onClick={() => {
                  setTabActive(i + 1);
                  setAccount(account);
                  dispatch(setDefaultAccount(account));
                }}
              >
                {`${account.subAcntNo} - ${t(accountTypeDetect(account))}`}
              </span>
            ))}
        </span>
        <div className="personal-assets-header__right">
          <div className="refresh" onClick={handleRefresh}>
            <span className="icon iRefresh mr-1"></span>
            <span>{t('txt-label-refresh-data')}</span>
          </div>
          <span
            onClick={() => withdrawHandle()}
            className={`btn br-5 fw-500 ${
              auth && auth.isVerified ? 'btn--primary' : 'btn--authen'
            }`}
          >
            {t('txt-label-withdrawal')}
          </span>
        </div>
      </div>
      {page &&
        page.component &&
        React.createElement(
          page.component,
          { cashBalance, account, tabActive, refresh },
          null
        )}
    </div>
  );
});

const Index = withNamespaces('translations')(({ t, match: { params } }) => {
  const history = useHistory();
  const { token } = useSelector((state) => state.client);

  useEffect(() => {
    if (!token) {
      history.push('/');
    }
  }, [token]);

  return (
    <div className="personal-page">
      <VerticalNavbar params={params} data={nav} />
      <Content params={params} />
    </div>
  );
});

export default Index;
