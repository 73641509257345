import React, { useState, useEffect, useRef, useContext } from 'react';
import {
  makeGetListAccount,
  makeGetDefaultAccount,
  makeGetHisTransfer,
  makeGetCashBalance,
  makeGetCashOut,
  makeGetBankList,
  makeGetAuth,
} from '../../lib/selector';
import {
  hisTransferRequest,
  bankListRequest,
  cashOutReset,
} from '../../containers/cash/actions';
import {
  setCashOut,
  setToast,
  unsetClient,
  setAuth,
} from '../../containers/client/actions';
import {
  numberFormat,
  formatDate,
  getTransferStatus,
  removeAccent,
  getMessageError,
  replaceAll,
} from '../../util';
import { cashBalanceRequest } from '../../containers/privData/actions';

import FormHisNapRutTien from './form/formHisNapRutTien';
import { v4 as uuidv4 } from 'uuid';
import PaginationTable from '../PaginationTable';
import { Logout, logoutRequest } from '../modal/login/actions';
import { removeCookie } from '../../lib/storages';
import { ReactReduxContext, useDispatch, useSelector } from 'react-redux';
import './index.scss';
import { withNamespaces } from 'react-i18next';
import PerfectScrollBar from 'react-perfect-scrollbar';
import nologo from '../../assets/img/icons/utilities/nologo.svg';
import { getCommonRequest } from '../../containers/customer/actions';

const DepositWithdrawalMoney = withNamespaces('translations')(({ t }) => {
  const dispatch = useDispatch();
  const { token, auth } = useSelector((state) => state.client);
  const { listAccount, defaultAccount } = useSelector((state) => state.socket);
  const { hisTransfer, cashOut, bankList, bankInfor } = useSelector(
    (state) => state.cash
  );
  const cashBalance = useSelector(makeGetCashBalance());
  const [tab, setTab] = useState(0);
  const [transType, setTransType] = useState('1');
  const [va, setVa] = useState([]);
  const [more, setMore] = useState(false);
  const quickTransfer =
    bankList && bankList.list?.length > 0
      ? bankList.list.filter((x) => x.bankDepoTp == 2)
      : [];

  const normalTransfer =
    bankList && bankList.list?.length > 0
      ? bankList.list.filter((x) => x.bankDepoTp == 1)
      : [];

  const ctck = bankList && bankList?.ctck;
  const { store } = useContext(ReactReduxContext);

  const columns = [
    {
      key: 'defaultAccount',
      text: t('txt-label-source'),
      className: 'text-center',
      align: 'center',
      cell: (record) => {
        return <span>{record && record.subAcntNo}</span>;
      },
    },
    {
      key: 'toAcntNo',
      text: t('txt-label-target'),
      className: 'text-right',
      align: 'center',
    },
    {
      key: 'trdAmt',
      text: t('txt-label-amount-want-transfer'),
      className: 'text-right',
      align: 'center',
      cell: (record) => {
        return numberFormat(record.trdAmt, 0, '0');
      },
    },
    {
      key: 'desc',
      text: t('txt-label-cash-transfer-content'),
      className: 'text-left',
      align: 'center',
    },
    {
      key: 'status',
      text: t('txt-status'),
      className: (record) => {
        return (
          'text-left ' +
          (record.status === '1' ? 'r' : record.status === '2' ? 'i' : 'd')
        );
      },
      align: 'center',
      cell: (record) => {
        return getTransferStatus(record.status);
      },
    },
    {
      key: 'trdDt',
      text: t('txt-label-create-date'),
      className: 'text-right',
      align: 'center',
    },
  ];

  const loadListTransferHandle = (type = '1') => {
    if (!defaultAccount || !token) return;
    setTransType(type);
    const numDay = token && token['config'] ? token['config']['numDay'] : 30;

    const d = new Date();
    d.setDate(d.getDate() - numDay);

    const uuid = uuidv4();
    const resData = {
      group: 'CORE',
      user: token.user,
      session: token.session,
      cmd: 'getCashTransferHist',
      rqId: uuid,
      channel: 'WTS',
      data: {
        acntNo: defaultAccount.acntNo,
        subAcntNo: defaultAccount.subAcntNo,
        fromDate: formatDate(d, ''),
        toDate: formatDate(new Date(), ''),
        transType: type,
      },
    };

    dispatch(hisTransferRequest(resData));
  };

  const getListVaAccount = () => {
    const uuid = uuidv4();
    const params = {
      group: 'CORE',
      cmd: 'ListAccount',
      user: token.user,
      session: token.session,
      rqId: uuid,
      channel: 'WTS',
      data: { cif: token.cif },
    };
    const callback = (data) => {
      const vaAcnt = data?.custInfo?.vaAcnt;
      setVa(vaAcnt);
    };

    dispatch(getCommonRequest(params, callback, true));
  };

  const loadListBankHandle = () => {
    const uuid = uuidv4();
    const resData = {
      group: 'BACK',
      user: token.user,
      session: token.session,
      cmd: 'BANK_LIST',
      rqId: uuid,
      channel: 'WTS',
    };

    dispatch(bankListRequest(resData));
  };

  const loadCashBalanceHandle = () => {
    if (defaultAccount) {
      const uuid = uuidv4();

      const resData = {
        group: 'CORE',
        user: token.user,
        session: token.session,
        cmd: 'getTotalAssetAll',
        rqId: uuid,
        channel: 'WTS',
        data: {
          acntNo: defaultAccount.acntNo,
          subAcntNo: defaultAccount.subAcntNo,
        },
      };
      dispatch(cashBalanceRequest(resData));
    }
  };

  const toastHandle = (msg) => {
    const toastMsg = {
      id: Math.random(),
      msg: msg,
      title: t('txt-notice'),
    };
    dispatch(setToast(toastMsg));
  };

  const copyToClipboardHandle = (e) => {
    if (!e) return;

    const textToCopy = e.trim();

    var tempInputElement = document.createElement('input');
    tempInputElement.type = 'text';
    tempInputElement.value = textToCopy;
    document.body.appendChild(tempInputElement);
    tempInputElement.select();
    document.execCommand('Copy');
    document.body.removeChild(tempInputElement);
    toastHandle('Copied to clipboard');
  };

  const getLogo = (bankCode) => {
    const path = bankInfor.find((x) => x.bankCode == bankCode)?.logo ?? '';
    return path;
  };

  useEffect(() => {
    if (token) {
      getListVaAccount();
      loadListBankHandle();
      loadListTransferHandle();
      loadCashBalanceHandle();
    }
  }, [token]);

  useEffect(() => {
    if (defaultAccount) loadListTransferHandle(transType);
  }, [defaultAccount]);

  return (
    <div className="DW">
      <div className="DW-header">
        <div className="DW-header__left">
          <div className="DW-header-metric">
            <span className="text-desc">{t('txt-total-asset')}</span>
            <span className="text-value">
              {cashBalance && numberFormat(cashBalance.totAsst, 0, '0')}
            </span>
          </div>
          <div className="DW-header-metric">
            <span className="text-desc">{t('txt-nav')}</span>
            <span className="text-value">
              {cashBalance && numberFormat(cashBalance.realAsst, 0, '0')}
            </span>
          </div>
          <div className="DW-header-metric">
            <span className="text-desc">{t('txt-wdrawAvail')}</span>
            <span className="text-value">
              {cashBalance && numberFormat(cashBalance.wdrawAvail, 0, '0')}
            </span>
          </div>
        </div>
        <div className="DW-header__right">
          {auth && auth.isVerified ? (
            <a
              onClick={() => dispatch(setCashOut(true))}
              className="btn btn--primary br-5 fw-500"
            >
              {t('txt-label-withdrawal')}
            </a>
          ) : (
            <a
              className="btn btn--authen br-5 fw-500"
              onClick={() => dispatch(setAuth())}
            >
              {t('txt-label-withdrawal')}
            </a>
          )}
        </div>
      </div>
      <div className="DW-main">
        <div className="DW-main-header">
          <span
            className={`DW-main-tab cursor-pointer ${tab == 0 ? 'active' : ''}`}
            onClick={() => setTab(0)}
          >
            {t('txt-label-quick-deposit')}
          </span>
          <span
            className={`DW-main-tab cursor-pointer ${tab == 1 ? 'active' : ''}`}
            onClick={() => setTab(1)}
          >
            {t('txt-label-normal-deposit')}
          </span>
          <span
            className={`DW-main-tab cursor-pointer ${tab == 2 ? 'active' : ''}`}
            onClick={() => setTab(2)}
          >
            {t('txt-label-history')}
          </span>
        </div>
        <div className="DW-main-body">
          {tab == 0 && (
            <>
              <PerfectScrollBar>
                <>
                  <div className="DW-main-bankGroup">
                    <div className="DW-main-bankGroup__title">
                      <span className="text-title">
                        {t('txt-label-deposit-stock')}
                        <span className="text-highlight ml-2">
                          ({t('txt-support-247')})
                        </span>
                      </span>
                    </div>
                    <div className="DW-main-bankGroup__body">
                      <div className="DW-main-bankGroup__items">
                        {quickTransfer &&
                          !!quickTransfer.length &&
                          quickTransfer.map((item, index) => (
                            <div className="DW-main-bankItem" key={index}>
                              <div className="bank-infor">
                                <div
                                  className="bank-logo"
                                  style={{
                                    backgroundImage: `url('${getLogo(
                                      item.bankCode
                                    )}'), url('${nologo}')`,
                                  }}
                                ></div>
                                <div className="bank-desc">
                                  <span>{item.bankCode}</span>
                                  <span className="text-desc">
                                    {item.bankName}
                                  </span>
                                </div>
                              </div>
                              <div className="bank-beneficiary">
                                <span className="text-desc">
                                  {t('txt-label-beneficiary')}
                                </span>
                                <span>{ctck}</span>
                              </div>
                              <div className="account-beneficiary">
                                <div className="d-flex flex-column gap-4 w-100">
                                  <span className="text-desc">
                                    {t('txt-label-account-beneficiary')}
                                  </span>
                                  <span>
                                    <span>{item.bankAcc}</span>
                                    <span
                                      className="btn btn-copy float-right"
                                      onClick={() =>
                                        copyToClipboardHandle(item.bankAcc)
                                      }
                                    >
                                      Copy
                                    </span>
                                  </span>
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                      <div className="DW-main-bankGroup__content">
                        <span className="text-desc text-desc--bigger">
                          {t('txt-label-transfer-content')}
                        </span>
                        <span className="d-flex justify-content-between align-items-center gap-4">
                          <span>
                            {token &&
                              defaultAccount &&
                              `${defaultAccount.vsdAcntNo} ${removeAccent(
                                token.name
                              )} ${t('txt-transfer-content')}`}
                          </span>
                          <span
                            className="btn btn-copy"
                            onClick={() => {
                              if (token && defaultAccount) {
                                copyToClipboardHandle(
                                  `${defaultAccount.vsdAcntNo} ${removeAccent(
                                    token.name
                                  )} ${t('txt-transfer-content')}`
                                );
                              }
                            }}
                          >
                            Copy
                          </span>
                        </span>
                        <span className="text-desc">{t('txt-gd')}</span>
                      </div>
                    </div>
                  </div>
                  <div className="DW-main-bankGroup">
                    <div className="DW-main-bankGroup__title">
                      <span className="text-title">
                        {t('txt-label-va-deposit-stock')}
                        <span className="text-highlight ml-2">
                          ({t('txt-support-247')})
                        </span>
                      </span>
                    </div>
                    <div className="DW-main-bankGroup__body">
                      <div className="DW-main-bankGroup__items">
                        {!more && va && va.length > 0 && (
                          <>
                            <div className="DW-main-bankItem" key={0}>
                              <div className="bank-infor">
                                <div
                                  className="bank-logo"
                                  style={{
                                    backgroundImage: `url('${getLogo(
                                      va[0].bankCode
                                    )}')`,
                                  }}
                                ></div>
                                <div className="bank-desc">
                                  <span>{va[0].bankCode}</span>
                                </div>
                              </div>
                              <div className="bank-beneficiary">
                                <span className="text-desc">
                                  {t('txt-label-beneficiary')}
                                </span>
                                <span>{va[0].bankAcntNm}</span>
                              </div>
                              <div className="account-beneficiary">
                                <div className="d-flex flex-column gap-4 w-100">
                                  <span className="text-desc">
                                    {t('txt-label-va-account-beneficiary')}
                                  </span>
                                  <span>
                                    <span>{va[0].vaAccnt}</span>
                                    <span
                                      className="btn btn-copy float-right"
                                      onClick={() =>
                                        copyToClipboardHandle(va[0].vaAccnt)
                                      }
                                    >
                                      Copy
                                    </span>
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div
                              className="DW-main-bankItem-showmore cursor-pointer"
                              onClick={() => setMore(true)}
                            >
                              <span>{t('txt-showmore')}</span>
                              <span className="icon iMore ml-1"></span>
                            </div>
                          </>
                        )}

                        {more &&
                          va &&
                          !!va.length &&
                          va.map((item, index) => (
                            <div className="DW-main-bankItem" key={index}>
                              <div className="bank-infor">
                                <div
                                  className="bank-logo"
                                  style={{
                                    backgroundImage: `url('${getLogo(
                                      item.bankCode
                                    )}')`,
                                  }}
                                ></div>
                                <div className="bank-desc">
                                  <span>{item.bankCode}</span>
                                </div>
                              </div>
                              <div className="bank-beneficiary">
                                <span className="text-desc">
                                  {t('txt-label-beneficiary')}
                                </span>
                                <span>{item.bankAcntNm}</span>
                              </div>
                              <div className="account-beneficiary">
                                <div className="d-flex flex-column gap-4 w-100">
                                  <span className="text-desc">
                                    {t('txt-label-va-account-beneficiary')}
                                  </span>
                                  <span>
                                    <span>{item.vaAccnt}</span>
                                    <span
                                      className="btn btn-copy float-right"
                                      onClick={() =>
                                        copyToClipboardHandle(item.vaAccnt)
                                      }
                                    >
                                      Copy
                                    </span>
                                  </span>
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                      <div className="DW-main-bankGroup__content">
                        <span className="text-desc text-desc--bigger">
                          {t('txt-note')}
                        </span>
                        <span className="text-desc">{t('txt-gd-va')}</span>
                      </div>
                    </div>
                  </div>
                </>
              </PerfectScrollBar>

              <div
                className="mt-3"
                dangerouslySetInnerHTML={{ __html: t('txt-gd-note') }}
              />
            </>
          )}
          {tab == 1 && (
            <>
              <PerfectScrollBar>
                <div className="DW-main-bankGroup">
                  <div className="DW-main-bankGroup__title">
                    <span className="text-title">
                      {t('txt-label-deposit-stock')}
                    </span>
                  </div>
                  <div className="DW-main-bankGroup__body">
                    <div className="DW-main-bankGroup__items">
                      {normalTransfer &&
                        !!normalTransfer.length &&
                        normalTransfer.map((item, index) => (
                          <div className="DW-main-bankItem" key={index}>
                            <div className="bank-infor">
                              <div
                                className="bank-logo"
                                style={{
                                  backgroundImage: `url('${getLogo(
                                    item.bankCode
                                  )}')`,
                                }}
                              ></div>
                              <div className="bank-desc">
                                <span>{item.bankCode}</span>
                                <span className="text-desc">
                                  {item.bankName}
                                </span>
                              </div>
                            </div>
                            <div className="bank-beneficiary">
                              <span className="text-desc">
                                {t('txt-label-beneficiary')}
                              </span>
                              <span>{ctck}</span>
                            </div>
                            <div className="account-beneficiary">
                              <div className="d-flex flex-column gap-4 w-100">
                                <span className="text-desc">
                                  {t('txt-label-account-beneficiary')}
                                </span>
                                <span>
                                  <span>{item.bankAcc}</span>
                                  <span
                                    className="btn btn-copy float-right"
                                    onClick={() =>
                                      copyToClipboardHandle(item.bankAcc)
                                    }
                                  >
                                    Copy
                                  </span>
                                </span>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                    <div className="DW-main-bankGroup__content">
                      <span className="text-desc text-desc--bigger">
                        {t('txt-label-transfer-content')}
                      </span>
                      <span className="d-flex justify-content-between align-items-center gap-4">
                        <span>
                          {token &&
                            defaultAccount &&
                            `${defaultAccount.vsdAcntNo} ${removeAccent(
                              token.name
                            )} ${t('txt-transfer-content')}`}
                        </span>
                        <span
                          className="btn btn-copy"
                          onClick={() => {
                            if (token && defaultAccount) {
                              copyToClipboardHandle(
                                `${defaultAccount.vsdAcntNo} ${removeAccent(
                                  token.name
                                )} ${t('txt-transfer-content')}`
                              );
                            }
                          }}
                        >
                          Copy
                        </span>
                      </span>
                      <span className="text-desc">{t('txt-gd')}</span>
                    </div>
                  </div>
                </div>
              </PerfectScrollBar>
              <div
                className="mt-3"
                dangerouslySetInnerHTML={{ __html: t('txt-gd-note') }}
              />
            </>
          )}
          {tab == 2 && (
            <>
              <PerfectScrollBar>
                <div className="d-flex justify-content-between align-items-center mt-1">
                  <label className="text-white text-uppercase ml-2 fz_14">
                    {t('txt-label-history')}
                  </label>
                  <FormHisNapRutTien handleQuery={loadListTransferHandle} />
                </div>
                <PaginationTable
                  defaultAccount={defaultAccount}
                  pageSize={15}
                  columns={columns}
                  source={
                    hisTransfer && hisTransfer.list ? hisTransfer.list : []
                  }
                />
              </PerfectScrollBar>
            </>
          )}
        </div>
      </div>
    </div>
  );
});

export default DepositWithdrawalMoney;
