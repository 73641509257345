export const GET_LIST_EVENT = 'GET_LIST_EVENT';
export const GET_LIST_EVENT_SUCCESS = 'GET_LIST_EVENT_SUCCESS';
export const GET_LIST_EVENT_ERROR = 'GET_LIST_EVENT_ERROR';

export const GET_LIST_NEWS = 'GET_LIST_NEWS';
export const GET_LIST_NEWS_SUCCESS = 'GET_LIST_NEWS_SUCCESS';
export const GET_LIST_NEWS_ERROR = 'GET_LIST_NEWS_ERROR';

export const GET_NEWS_CATEGORIES = 'GET_NEWS_CATEGORIES';
export const GET_NEWS_CATEGORIES_SUCCESS = 'GET_NEWS_CATEGORIES_SUCCESS';
export const GET_NEWS_CATEGORIES_ERROR = 'GET_NEWS_CATEGORIES_ERROR';

export const GET_NEWS_SORT = 'GET_NEWS_SORT';
export const GET_NEWS_SORT_SUCCESS = 'GET_NEWS_SORT_SUCCESS';
export const GET_NEWS_SORT_ERROR = 'GET_NEWS_SORT_ERROR';

export const NEWS_LIST_REQUESTING = 'NEWS_LIST_REQUESTING';
export const NEWS_LIST_REQUEST_SUCCESS = 'NEWS_LIST_REQUEST_SUCCESS';
export const NEWS_LIST_REQUEST_FAIL = 'NEWS_LIST_REQUEST_FAIL';

export const NEWS_CATEGORY_REQUESTING = 'NEWS_CATEGORY_REQUESTING';
export const NEWS_CATEGORY_REQUEST_SUCCESS = 'NEWS_CATEGORY_REQUEST_SUCCESS';
export const NEWS_CATEGORY_REQUEST_FAIL = 'NEWS_CATEGORY_REQUEST_FAIL';

export const NEWS_DETAIL_REQUESTING = 'NEWS_DETAIL_REQUESTING';
export const NEWS_DETAIL_REQUEST_FAIL = 'NEWS_DETAIL_REQUEST_FAIL';
export const NEWS_DETAIL_REQUEST_SUCCESS = 'NEWS_DETAIL_REQUEST_SUCCESS';

export const NEWS_RELATED_LIST_REQUEST = 'NEWS_RELATED_LIST_REQUEST';
export const NEWS_RELATED_LIST_SUCCESS = 'NEWS_RELATED_LIST_SUCCESS';
export const NEWS_RELATED_LIST_FAIL = 'NEWS_RELATED_LIST_FAIL';

export const NEWS_DETAIL_UP_VIEW = 'NEWS_DETAIL_UP_VIEW';

export const TOPNEWS_LIST_REQUESTING = 'TOPNEWS_LIST_REQUESTING';
export const TOPNEWS_LIST_REQUEST_SUCCESS = 'TOPNEWS_LIST_REQUEST_SUCCESS';
export const TOPNEWS_LIST_REQUEST_FAIL = 'TOPNEWS_LIST_REQUEST_FAIL';

export const TOPVIDEO_LIST_REQUESTING = 'TOPVIDEO_LIST_REQUESTING';
export const TOPVIDEO_LIST_REQUEST_SUCCESS = 'TOPVIDEO_LIST_REQUEST_SUCCESS';
export const TOPVIDEO_LIST_REQUEST_FAIL = 'TOPVIDEO_LIST_REQUEST_FAIL';

export const CURRENT_NEWS = 'CURRENT_NEWS';
export const VIDEO_DETAIL_REQUEST = 'VIDEO_DETAIL_REQUEST';
export const VIDEO_DETAIL_REQUEST_SUCCESS = 'VIDEO_DETAIL_REQUEST_SUCCESS';
export const VIDEO_DETAIL_REQUEST_FAIL = 'VIDEO_DETAIL_REQUEST_FAIL';

export const EVENTS_LIST_REQUESTING = 'EVENTS_LIST_REQUESTING';
export const EVENTS_LIST_REQUEST_SUCCESS = 'EVENTS_LIST_REQUEST_SUCCESS';
export const EVENTS_LIST_REQUEST_FAIL = 'EVENTS_LIST_REQUEST_FAIL';

export const EVENTS_DETAIL_REQUESTING = 'EVENTS_DETAIL_REQUESTING';
export const EVENTS_DETAIL_REQUEST_SUCESS = 'EVENTS_DETAIL_REQUEST_SUCESS';
export const EVENTS_DETAIL_REQUEST_FAIL = 'EVENTS_DETAIL_REQUESTI';

export const TOOGLE_VIDEO_DETAIL_MODAL = 'TOOGLE_VIDEO_DETAIL_MODAL';
export const TOOGLE_NEW_DETAIL_MODAL = 'TOOGLE_NEW_DETAIL_MODAL';
export const TOOGLE_EVENT_DETAIL_MODAL = 'TOOGLE_EVENT_DETAIL_MODAL';

export const VIDEO_RELATED_LIST_REQUEST = 'VIDEO_RELATED_LIST_REQUEST';
export const VIDEO_RELATED_LIST_SUCCESS = 'VIDEO_RELATED_LIST_SUCCESS';
export const VIDEO_RELATED_LIST_FAIL = 'VIDEO_RELATED_LIST_FAIL';

export const NEWS_SYMBOL_REQUEST = 'NEWS_SYMBOL_REQUEST';
export const NEWS_SYMBOL_REQUEST_SUCCESS = 'NEWS_SYMBOL_REQUEST_SUCCESS';
export const NEWS_SYMBOL_REQUEST_FAIL = 'NEWS_SYMBOL_REQUEST_FAIL';

export const GLOBAL_INDEX_REQUEST = 'GLOBAL_INDEX_REQUEST';
export const GLOBAL_INDEX_REQUEST_SUCCESS = 'GLOBAL_INDEX_REQUEST_SUCCESS';
export const GLOBAL_INDEX_REQUEST_FAIL = 'GLOBAL_INDEX_REQUEST_FAIL';

export const GET_BRIEFS_REQUESTING = 'GET_BRIEFS_REQUESTING';
export const GET_BRIEFS_REQUEST_SUCCESS = 'GET_BRIEFS_REQUEST_SUCCESS';
export const GET_BRIEFS_REQUEST_FAIL = 'GET_BRIEFS_REQUEST_FAIL';
