import React, { useState, useEffect } from 'react';
import { withNamespaces } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import PerfectScrollBar from 'react-perfect-scrollbar';
import PrintButton from '../../../components/print/PrintButton';
import {
  numberFormat,
  _convertActTypeToStr,
  _formatDate,
  _formatDate2,
  stringToDate,
  _getOrdCondStatus,
  _getOrdCondStr,
  formatDate,
  accountTypeDetect,
} from '../../../util';
import ExportConditionalOrder from '../components/exports/conditionalOrderExcel';
import DatePicker from '../../../components/datePicker2';
import ReactSelect from 'react-select';
import logo from '../../../assets/img/pinetreeSecurities.svg';
import { makeGetSummaryOrderCond } from '../../../lib/selector';
import Page from '../../../components/print/Page';
import { Col } from 'react-bootstrap';
import PaginationTable from '../../../components/PaginationTable';
import { summaryOrdCondRequest } from '../../../containers/summary/actions';
import { v4 as uuidv4 } from 'uuid';
import { setDefaultAccount } from '../../socket/actions';
import { Mixpanel } from '../../../lib/mixpanel';
import AssetHeader from '../components/header/assetHeader';

const Index = withNamespaces('translations')(({ t, params }) => {
  const { typeId } = params;
  const listOrder = useSelector(makeGetSummaryOrderCond());
  const { defaultAccount, listAccount } = useSelector((state) => state.socket);
  const { token, auth } = useSelector((state) => state.client);
  const [account, setAccount] = useState(defaultAccount);
  const [tabActive, setTabActive] = useState(0);
  const [reload, setReload] = useState(false);
  const dispatch = useDispatch();

  const getConditionalOrder = (
    startDate,
    endDate,
    orderType,
    symbol = '',
    status = ''
  ) => {
    if (account) {
      const uuid = uuidv4();
      const maxDay = 90;
      const d = new Date();
      d.setDate(d.getDate() - maxDay);

      const params = {
        group: 'ORS',
        user: token.user,
        session: token.session,
        cmd: 'getOrsOrderList',
        rqId: uuid,
        channel: 'WTS',
        data: {
          acntNo: account.acntNo,
          subAcntNo: account.subAcntNo,
          ordertype: orderType,
          createddt: startDate
            ? formatDate(stringToDate(startDate, 'dmy'), '')
            : formatDate(d, ''),
          expireddt: endDate
            ? formatDate(stringToDate(endDate, 'dmy'), '')
            : formatDate(new Date(), ''),
          symbol: symbol,
          status: status,
        },
      };

      dispatch(summaryOrdCondRequest(params));
    }
  };

  const columns = [
    {
      key: 'loanSeq',
      text: '#',
      className: 'text-center text--light3',
      align: 'center',
      sortable: true,
      cell: (record, action, rowIndex) => {
        return rowIndex + 1;
      },
    },
    {
      key: 'ordrNo',
      text: t('txt-order-no'),
      className: 'text-center text--light3',
      align: 'center',
      sortable: true,
    },
    {
      key: 'timeQuote',
      text: t('txt-label-tran-date'),
      className: 'text-left text--light3',
      align: 'left',
    },
    {
      key: 'exprDt',
      text: t('txt-trade-acronym'),
      className: 'text-center',
      align: 'center',
      cell: (record) => {
        return (
          <div
            className={`
              mx-auto fw-500 ${record.side === 'B' ? 'i' : 'd'}
            `}
          >
            {record.side === 'B' ? t('txt-buy') : t('txt-sell')}
          </div>
        );
      },
    },
    {
      key: 'symbol',
      text: t('txt-symbol'),
      className: 'text-center fw-500 text-light',
      align: 'center',
      sortable: true,
    },
    {
      key: 'createddt',
      text: t('txt-label-from'),
      className: 'text-center text--light3',
      align: 'center',
      sortable: true,
    },
    {
      key: 'expireddt',
      text: t('txt-label-to'),
      className: 'text-center text--light3',
      align: 'center',
      sortable: true,
    },
    {
      text: t('txt-label-condition'),
      className: 'text-center text-light',
      align: 'center',
      cell: (record) => {
        return _convertActTypeToStr(record.activetype);
      },
      sortable: true,
    },
    {
      key: 'qtty',
      text: t('txt-vol'),
      className: 'text-right text-light',
      align: 'right',
      cell: (record) => {
        return numberFormat(record.qtty, 0, '0');
      },
      sortable: true,
    },
    {
      key: 'execQtty',
      text: t('txt-match-vol'),
      className: 'text-right text-light',
      align: 'right',
      cell: (record) => {
        return numberFormat(record.execQtty, 0, '0');
      },
      sortable: true,
    },
    {
      key: 'status',
      text: t('txt-status'),
      align: 'center',
      cell: (record) => {
        return (
          <span className={`d-block text-center ordStt_${record.status}`}>
            {_getOrdCondStatus(record.status)}
          </span>
        );
      },
      sortable: true,
    },
  ];

  useEffect(() => {
    if (defaultAccount) {
      setAccount(defaultAccount);
      setReload(!reload);
    }
  }, [defaultAccount, typeId]);

  return (
    <div className="personal-assets card-panel-2">
      <AssetHeader
        account={account}
        setAccount={setAccount}
        params={params}
        setRefresh={setReload}
        tabActive={tabActive}
        refresh={reload}
      />
      <div className="conditional-order-statement personal-assets-body card-panel-2-body pb-3">
        <PerfectScrollBar style={{ height: '100%', width: '100%' }}>
          <div className="conditional-order-statement__header">
            <span className="text-title text--light">
              {t('transaction.conditionalOrderStatement.name')}
            </span>
            <Filters
              account={account}
              key={reload}
              listOrder={listOrder}
              callback={getConditionalOrder}
            />
          </div>
          <div className="conditional-order-statement__body">
            <PaginationTable
              pageSize={10}
              columns={columns}
              hasPaging
              source={listOrder || []}
            />
          </div>
        </PerfectScrollBar>
      </div>
    </div>
  );
});

const ExportPDF = withNamespaces('translations')(
  ({ t, account, listOrder, status, fromDate, toDate, symbol, orderType }) => {
    return (
      <Page id="sao-ke-giao-dich" style={{ display: 'none' }}>
        <div className="d-flex flex-column bg-light">
          <div
            className="position-relative d-flex justify-content-center align-items-center"
            style={{ height: '75px' }}
          >
            <img
              src={logo}
              alt="logo"
              className="position-absolute"
              style={{ top: '0', left: '0' }}
            />
            <span className="fz_16 fw-500 text-uppercase">
              {t('transaction.conditionalOrderStatement.name').toUpperCase()}
            </span>
          </div>
          <div className="d-flex mt-2">
            <Col className="d-flex flex-column">
              <div className="d-flex">
                <span className="fz_14 w_125">
                  {t('transaction.conditionalOrderStatement.reportName')}
                </span>
                <span className="fz_14 f_i">
                  {t('transaction.conditionalOrderStatement.name')}
                </span>
              </div>
              <div className="d-flex">
                <span className="fz_14 w_125">{t('lbl-subAccount')}: </span>
                <span className="fz_14 f_i">
                  {account && account.subAcntNo}
                </span>
              </div>
              <div className="d-flex">
                <span className="fz_14 w_125">{t('txt-label-vsd-no')}: </span>
                <span className="fz_14 f_i">
                  {account && account.vsdAcntNo}
                </span>
              </div>
            </Col>
            <Col className="d-flex flex-column">
              <div className="d-flex">
                <span className="fz_14 w_125">{t('txt-label-from')}: </span>
                {fromDate && (
                  <span className="fz_14 f_i">
                    {formatDate(fromDate, '-', 'dmy')}
                  </span>
                )}
              </div>
              <div className="d-flex">
                <span className="fz_14 w_125">{t('txt-label-to')}: </span>
                {toDate && (
                  <span className="fz_14 f_i">
                    {formatDate(toDate, '-', 'dmy')}
                  </span>
                )}
              </div>
              <div className="d-flex">
                <span className="fz_14 w_125">{t('txt-symbol')}: </span>
                <span className="fz_14 f_i">
                  {symbol && (symbol || t('txt-all'))}
                </span>
              </div>
              <div className="d-flex">
                <span className="fz_14 w_125">{t('txt-order-type')}: </span>
                <span className="fz_14 f_i">
                  {orderType &&
                    (orderType === 'CNL'
                      ? t('txt-all')
                      : _getOrdCondStr(orderType))}
                </span>
              </div>
              <div className="d-flex">
                <span className="fz_14 w_125">{t('txt-status')}: </span>
                <span className="fz_14 f_i">
                  {!status || status === ''
                    ? t('txt-all')
                    : _getOrdCondStatus(status)}
                </span>
              </div>
            </Col>
          </div>
        </div>
        <div className="d-flex bg-light">
          <table className="table table-bordered mt-2 table-light">
            <thead>
              <tr>
                <th className="text-center text-white">{t('txt-stt')}</th>
                <th className="text-center text-white">
                  {t('txt-label-tran-date')}
                </th>
                <th className="text-center text-white">
                  {t('txt-trade-acronym')}
                </th>
                <th className="text-center text-white">{t('txt-symbol')}</th>
                <th className="text-center text-white">{t('txt-order-no')}</th>
                <th className="text-center text-white">
                  {t('txt-label-from')}
                </th>
                <th className="text-center text-white">{t('txt-label-to')}</th>
                <th className="text-center text-white">
                  {t('txt-label-condition')}
                </th>
                <th className="text-center text-white">{t('txt-vol')}</th>
                <th className="text-center text-white">{t('txt-status')}</th>
                <th className="text-center text-white">{t('txt-match-vol')}</th>
              </tr>
            </thead>
            <tbody>
              {listOrder &&
                !!listOrder.length &&
                listOrder.map((item, i) => {
                  if (item.modiCnclTp === '3') return null;
                  return (
                    <tr key={i} style={{ border: '1px solid #e8e8e8' }}>
                      <td className="text-center">{i + 1}</td>
                      <td className="text-center">{item.timeQuote}</td>
                      <td className="text-center mx-auto">
                        <div
                          className={
                            'text-uppercase mx-auto trade_side_' +
                            (item.side === 'B' ? '1' : '2')
                          }
                        >
                          {item.side === 'B' ? t('txt-buy') : t('txt-sell')}
                        </div>
                      </td>
                      <td className="text-center">{item.symbol}</td>
                      <td className="text-center">{item.ordrNo}</td>
                      <td className="text-center">{item.createddt}</td>
                      <td className="text-center">{item.expireddt}</td>
                      <td className="text-left">
                        {_convertActTypeToStr(item.activetype)}
                      </td>
                      <td className="text-right">
                        {numberFormat(item.qtty, 0, '0')}
                      </td>
                      <td className={'text-center ordStt_' + item.status}>
                        {_getOrdCondStatus(item.status)}
                      </td>
                      <td className="text-right">
                        {numberFormat(item.execQtty, 0, '0')}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </Page>
    );
  }
);

const Filters = withNamespaces('translations')(
  ({ t, account, reload, callback, listOrder }) => {
    const ORDER_TYPES = [
      {
        value: '',
        label: 'transaction.conditionalOrderStatement.allOrderType',
      },
      { value: 'GTC', label: 'txt-out-time' },
      { value: 'TSO', label: 'txt-trend-order' },
      { value: 'TPO', label: 'txt-take-profit' },
      { value: 'STO', label: 'txt-stop-loss' },
      { value: 'PCO', label: 'txt-purchase-order' },
    ];

    const ORDER_STATUSES = [
      {
        value: '',
        label: 'transaction.conditionalOrderStatement.allStatus',
      },
      { value: 'A', label: 'txt-cond-activated' },
      { value: 'I', label: 'txt-cond-active' },
      { value: 'C', label: 'txt-cond-cancelling' },
      { value: 'E', label: 'txt-cond-expired' },
      { value: 'F', label: 'txt-cond-final' },
      { value: 'P', label: 'txt-cond-pending' },
      { value: 'R', label: 'txt-cond-wait-react' },
    ];

    const currentDate = new Date();
    const [fromDate, setFromDate] = useState(
      new Date(currentDate.setDate(currentDate.getDate() - 90))
    );
    const [toDate, setToDate] = useState(new Date());
    const [symbol, setSymbol] = useState('');
    const [orderType, setOrderType] = useState(ORDER_TYPES[0]);
    const [status, setStatus] = useState(ORDER_STATUSES[0]);

    const onFilter = () => {
      if (callback && typeof callback == 'function')
        callback(fromDate, toDate, orderType.value, symbol, status.value);
    };

    useEffect(() => {
      onFilter();
    }, [account]);

    return (
      <div className="conditional-order-statement__filters">
        <div className="input-text-search d-flex align-items-center">
          <input
            placeholder={t('txt-symbol')}
            onChange={(e) => setSymbol(e.target.value)}
            maxLength={12}
          />
          <span className="icon iSearch"></span>
        </div>
        <DatePicker
          startDate={fromDate}
          endDate={toDate}
          callback={(from, to) => {
            console.log(`from: ${fromDate} || to: ${toDate}`);
            setFromDate(from);
            setToDate(to);
          }}
        />
        <ReactSelect
          placeholder={t('txt-all')}
          className="transaction-filters__select"
          classNamePrefix="filter-control-select"
          options={ORDER_TYPES}
          isSearchable={false}
          components={{
            IndicatorSeparator: () => null,
          }}
          value={orderType}
          onChange={(value) => setOrderType(value)}
          getOptionLabel={(option) => t(option.label)}
          getOptionValue={(option) => option.value}
        />
        <ReactSelect
          placeholder={t('txt-all')}
          className="transaction-filters__select"
          classNamePrefix="filter-control-select"
          options={ORDER_STATUSES}
          isSearchable={false}
          components={{
            IndicatorSeparator: () => null,
          }}
          value={status}
          onChange={(value) => setStatus(value)}
          getOptionLabel={(option) => t(option.label)}
          getOptionValue={(option) => option.value}
        />
        <button className="btn btn--primary" onClick={() => onFilter()}>{`${t(
          'txt-search'
        )}`}</button>
        <ExportConditionalOrder
          csvData={listOrder}
          fileName={t('transaction.conditionalOrderStatement.name')}
          fromDate={_formatDate2(fromDate)}
          toDate={_formatDate2(toDate)}
          accountCode={account && account.subAcntNo}
          accountName={account && account.vsdAcntNo}
          symbol={symbol || t('txt-all')}
        />
        <PrintButton
          id="sao-ke-giao-dich"
          callback={() =>
            Mixpanel.track('Export Statement', {
              'Statement Type': 'Conditional Order Statement',
            })
          }
        />
        <ExportPDF
          account={account}
          listOrder={listOrder}
          fromDate={fromDate}
          toDate={toDate}
          symbol={symbol}
          orderType={orderType}
          status={status}
        />
      </div>
    );
  }
);

export default Index;
