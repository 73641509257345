import React, { useEffect, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ReactSelect from 'react-select';
import { filter, find, map, sum, sumBy } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import {
  _convertTpToPrice,
  _getOrderStatus,
  numberFormat,
  _convertActTypeToStr,
  _getOrdCondStatus,
  _getOrdCondStr,
  _convertformatDate2,
  ordStatus,
  mapNegoOrdrStatTp,
  mapOrderChanel,
} from '../../../util';
import DatePicker from '../../../components/datePicker2';
import moment from 'moment';
import PerfectScrollBar from 'react-perfect-scrollbar';
import { listPutThroughRequest } from '../../privData/actions';
import PaginationTable from '../../../components/PaginationTable';
import ScrollTable from '../../../components/ScrollTable';

const PutThroughOrderHistory = withNamespaces('translations')(({ t }) => {
  const { listAccount, defaultAccount } = useSelector((state) => state.socket);
  const { token } = useSelector((state) => state.client);
  const listPutThrough = useSelector(
    (state) => state.privData.listPutThrough?.list
  );
  const dispatch = useDispatch();
  const [account, setAccount] = useState();
  const [symbolSearch, setSymbolSearch] = useState();
  const [orderType, setOrderType] = useState();
  const [orderStatus, setOrderStatus] = useState();
  const [isReload, setIsReload] = useState(false);
  const [_listOrder, setListOrder] = useState();
  const _handleLoadListOrder = (orderType, symbolSearch) => {
    if (account) {
      const uuid = uuidv4();
      const params = {
        group: 'CORE',
        cmd: 'getPTOrderList',
        user: token.user,
        session: token.session,
        rqId: uuid,
        channel: 'WTS',
        data: {
          cif: token.cif,
          acntNo: account.acntNo,
          subAcntNo: account.subAcntNo,
          symbol: symbolSearch,
          buySelTp: orderType?.id,
        },
      };
      dispatch(listPutThroughRequest(params));
    }
  };
  const accounts = listAccount?.filter((x) => x.subAcntStat != 9) || [];

  useEffect(() => {
    if (defaultAccount) setAccount(defaultAccount);
  }, [defaultAccount]);

  useEffect(() => {
    _handleLoadListOrder(orderType, symbolSearch);
  }, [account, orderType, symbolSearch]);

  useEffect(() => {}, []);

  const columns = [
    {
      key: '_index',
      text: t('txt-stt'),
      className: 'text-center text--light3',
      align: 'center',
    },
    {
      key: 'trdDate',
      text: t('txt-label-trade-date'),
      className: 'text-center text--light3',
      align: 'center',
      cell: (record) => {
        return record.trdDate && moment(record.trdDate).format('DD/MM/yyyy');
      },
    },
    {
      key: 'buySelTp',
      text: t('txt-trade-acronym'),
      className: 'text-center',
      align: 'center',
      cell: (record) => {
        return (
          <div className={record.buySelTp === '1' ? 'i' : 'd'}>
            {record.buySelTp === '1' ? t('txt-buy') : t('txt-sell')}
          </div>
        );
      },
    },

    {
      key: 'symbol',
      text: t('txt-symbol'),
      className: 'text-center text-light fw-500',
      align: 'center',
    },
    {
      key: 'ordrQty',
      text: t('txt-vol'),
      className: 'text-right text--light3',
      align: 'center',
      cell: (record) => {
        return numberFormat(record.ordrQty, 0, '0');
      },
    },
    {
      key: 'ordrUntprc',
      text: t('txt-price'),
      className: 'text-center text-light fw-500',
      align: 'center',
      cell: (record) => {
        return numberFormat(record.ordrUntprc, 0, '');
      },
    },
    {
      key: 'ordrAmt ',
      text: t('txt-match-value'),
      className: 'text-right text--light3',
      align: 'center',
      cell: (record) => {
        return numberFormat(record.ordrAmt, 0, '0');
      },
    },

    {
      key: 'ordrFee',
      text: t('txt-label-fee'),
      className: 'text-right text--light3',
      align: 'center',
      cell: (record) => {
        return numberFormat(record.ordrFee, 0, '0');
      },
    },
    {
      key: 'selFee',
      text: t('txt-label-sell-fee'),
      className: 'text-right text--light3',
      align: 'center',
      cell: (record) => {
        return numberFormat(record.selFee, 0, '0');
      },
    },
    {
      key: 'ordrDltx',
      text: t('txt-label-tax'),
      className: 'text-right text--light3',
      align: 'center',
      cell: (record) => {
        return numberFormat(record.ordrDltx, 0, '0');
      },
    },
    {
      key: 'ordrDivdtx',
      text: t('txt-label-dividend-tax'),
      className: 'text-right text--light3',
      align: 'center',
      cell: (record) => {
        return numberFormat(record.ordrDivdtx, 0, '0');
      },
    },
    {
      key: 'recvAmt',
      text: t('txt-label-real-amount'),
      className: 'text-right text--light3',
      align: 'center',
      cell: (record) => {
        return numberFormat(record.recvAmt, 0, '0');
      },
    },
    {
      key: 'negoOrdrStatTp',
      text: t('txt-status'),
      className: 'text-center text--light3',
      align: 'center',
      cell: (record) => {
        return `${t(mapNegoOrdrStatTp(record.negoOrdrStatTp).label)}`;
      },
    },
    {
      key: 'ordrNo',
      text: t('txt-orderNo'),
      className: 'text-right text--light3',
      align: 'center',
    },
    {
      key: 'ordrChnl',
      text: t('txt-label-channel'),
      className: 'text-center text--light3',
      align: 'center',
      cell: (record) => {
        return mapOrderChanel(record.ordrChnl);
      },
    },
  ];

  return (
    <div className="card-panel-body position-relative put-through-order h-100">
      <Filter
        t={t}
        reload={isReload}
        setOrderType={setOrderType}
        orderType={orderType}
        setOrderStatus={setOrderStatus}
        orderStatus={orderStatus}
        symbol={symbolSearch}
        setSymbol={setSymbolSearch}
        account={account}
        setAccount={setAccount}
        listAccount={accounts}
      />
      <PerfectScrollBar style={{ height: 'calc(100% - 24px)' }}>
        <ScrollTable
          tblClassName="tbl-list"
          divClassName="mt-0"
          columns={columns}
          source={listPutThrough}
        />
        <div style={{ height: '50px' }}></div>
      </PerfectScrollBar>
    </div>
  );
});

export default PutThroughOrderHistory;

const Filter = ({
  t,
  reload,
  orderType,
  setOrderType,
  symbol,
  setSymbol,
  account,
  setAccount,
  listAccount,
}) => {
  const _orderTypes = [
    { id: '', value: 'transaction.conditionalOrderStatement.allOrderType' },
    { id: '1', value: 'txt-buy' },
    { id: '2', value: 'txt-sell' },
  ];

  useEffect(() => {
    setSymbol('');
    setOrderType('');
  }, [reload]);

  return (
    <div className="filter">
      <div className="filter-control d-flex justify-content-between">
        <div className="d-flex justify-content-start align-items-center gap-8 ">
          <ReactSelect
            placeholder={t('txt-all')}
            className="mw-150 fz_14"
            classNamePrefix="filter-control-select"
            options={listAccount}
            isSearchable={false}
            components={{
              IndicatorSeparator: () => null,
            }}
            value={account}
            onChange={(value) => setAccount(value)}
            getOptionLabel={(option) => t(option.value)}
            getOptionValue={(option) => option.value}
          />
        </div>
        <div className="d-flex justify-content-end align-items-center gap-8">
          <div className="input-text-search d-flex align-items-center fz_13 ">
            <input
              name="txtSearch"
              maxLength={8}
              type="text"
              className="form-control form-input fz_13"
              placeholder={t('txt-symbol')}
              value={symbol}
              onChange={(e) =>
                e.target.value.length > 0
                  ? setSymbol(e.target.value.toUpperCase())
                  : setSymbol(e.target.value)
              }
            />
            <span className="icon iSearch"></span>
          </div>
          <ReactSelect
            name="orderType"
            className="mw-150 fz_13"
            placeholder={t(
              'transaction.conditionalOrderStatement.allOrderType'
            )}
            classNamePrefix="filter-control-select"
            options={_orderTypes}
            isSearchable={false}
            components={{
              IndicatorSeparator: () => null,
            }}
            value={orderType}
            onChange={(value) => setOrderType(value)}
            getOptionLabel={(option) => t(option.value)}
            getOptionValue={(option) => option.id}
          />
        </div>
      </div>
    </div>
  );
};
