import React, { useState, useEffect, useContext, useRef } from 'react';
import { withNamespaces } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import DatagridGroup from '../../../../components/table/DatagridGroup';
// import TblGroup from '../../../../../components/banggia/priceTable/layout/tblGroup';
import PerfectScrollbar from 'react-perfect-scrollbar';

import {
  BsCaretLeftFill,
  BsCaretRightFill,
  BsFillCaretDownFill,
  BsFillCaretUpFill,
} from 'react-icons/bs';
import ImgLoading from '../../../../assets/img/loading.gif';
import {
  getDerivativeDetailRequest,
  getDerivativeSymbol,
  leaveSocketDrvx,
  registSocketDrvx,
  setCurrentSymbol,
  setShowDetailModal,
  switchDvrxList,
} from '../../actions';
import { WebSocketContext } from '../../../socket/webSocket';
import { makeGetTypeIndex } from '../../../../lib/selector';
import { _processMapDataDv, mergeAndDistinct } from '../../../../util';
import { DERIVATIVE_CONSTANTS } from '../../../../util/constant';
import { joinSocketHandle, leaveSocketHandle } from '../common';
import { SOCKET_COMPONENTS } from '../../constants';
import { MixConstants, Mixpanel } from '../../../../lib/mixpanel';

const Pricetable = withNamespaces('translations')(() => {
  const { symbols, drvxRegSyms } = useSelector((state) => state.derivative);
  const { currentSid } = useSelector((state) => state.socket);
  const ws = useContext(WebSocketContext);
  const dispatch = useDispatch();

  const fetchData = () => {
    dispatch(getDerivativeSymbol());
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    let regSyms;
    if (symbols && !!symbols.length > 0) {
      // regist socket
      regSyms = symbols.map((x) => x.sym);
      joinSocketHandle(ws, regSyms);
      console.log('re join in symbols');

      dispatch(registSocketDrvx(regSyms, SOCKET_COMPONENTS.PRICEBOARD));
    }

    return () => {
      // leave socket
      if (regSyms && !!regSyms?.length > 0) {
        // leaveSocketHandle(ws, regSyms.join(','));

        if (drvxRegSyms?.length > 0) {
          const restRegistSocketSyms = mergeAndDistinct(
            drvxRegSyms.filter((d) => d.key != SOCKET_COMPONENTS.PRICEBOARD),
            'contracts'
          );

          const leaveSyms = regSyms.filter((x) =>
            restRegistSocketSyms.includes(x)
          );

          dispatch(leaveSocketDrvx(leaveSyms, SOCKET_COMPONENTS.PRICEBOARD));
        }
      }
    };
  }, [symbols]);

  useEffect(() => {
    if (currentSid) {
      // re join drvx contract socket
      if (drvxRegSyms?.length > 0) {
        const _drvxRegSyms = mergeAndDistinct(drvxRegSyms, 'contracts');
        joinSocketHandle(ws, _drvxRegSyms);
        console.log('re join in currentSid');
      }
    }
  }, [currentSid]);

  return <TblGroup partSnap={symbols} />;
});

const isTextFunction = (text) => typeof text === 'function' && text !== null;

const Thead = withNamespaces('translations')(
  ({ sortHandle, changePcHandle, t }) => {
    const [sortField, setSortField] = useState('sym');
    const [isAsc, setIsAsc] = useState(true);
    const [isPc, setIsPC] = useState(false);
    const { seltp, listDvx } = useSelector((state) => state.derivative);
    const dispatch = useDispatch();
    const switchDvrxHandle = (currentSeltp) => {
      // const types = [
      //   DERIVATIVE_CONSTANTS.DRVX_TYPE.INDEX,
      //   DERIVATIVE_CONSTANTS.DRVX_TYPE.BOND5Y,
      // ];

      // const next =
      //   currentSeltp == DERIVATIVE_CONSTANTS.DRVX_TYPE.BOND10Y
      //     ? DERIVATIVE_CONSTANTS.DRVX_TYPE.INDEX
      //     : types[types.indexOf(currentSeltp) + 1];

      const next =
        currentSeltp == DERIVATIVE_CONSTANTS.DRVX_TYPE.INDEX
          ? DERIVATIVE_CONSTANTS.DRVX_TYPE.BOND5Y
          : DERIVATIVE_CONSTANTS.DRVX_TYPE.INDEX;

      dispatch(switchDvrxList(next));
    };

    const row1 = [
      {
        key: 'sym',
        text: (e) => {
          return (
            <div
              className="btn--switch"
              onClick={() => switchDvrxHandle(seltp)}
            >
              <span
                title={
                  seltp == DERIVATIVE_CONSTANTS.DRVX_TYPE.INDEX
                    ? t('txt-index-future')
                    : t('txt-bond-future')
                }
                className="btn--switch-text"
              >
                {seltp == DERIVATIVE_CONSTANTS.DRVX_TYPE.INDEX
                  ? t('txt-index-future')
                  : t('txt-bond-future')}
              </span>
              <span className="icon iNext bg--light"></span>
            </div>
          );
        },
        // Mã CK
        sortable: false,
        rowSpan: '2',
        colSpan: '1',
      },
      {
        key: 'exprDt',
        text: t('derivative.expireDate'), // T.C
        className: 'filterIcon',
        rowSpan: '2',
        colSpan: '1',
      },
      {
        key: 'r',
        text: t('txt-ref'), // TC
        className: 'filterIcon',
        rowSpan: '2',
        colSpan: '1',
      },
      {
        key: 'c',
        text: t('txt-ceil'), // Trần
        className: 'filterIcon',
        rowSpan: '2',
        colSpan: '1',
      },
      {
        key: 'f',
        text: t('txt-floor'), // Sàn
        className: 'filterIcon',
        rowSpan: '2',
        colSpan: '1',
      },
      {
        key: 'gb1',
        text: t('derivative.label.bas'), // Basis
        className: 'filterIcon',
        rowSpan: '2',
        colSpan: '1',
      },
      {
        key: 'oi',
        text: 'OI', // OI
        className: 'filterIcon',
        rowSpan: '2',
        colSpan: '1',
      },
      {
        text: 'Long', // Long
        rowSpan: '1',
        colSpan: '6',
      },
      {
        key: 'lastPrice',
        text: t('txt-match-price'), // Last match price
        className: 'filterIcon',
        rowSpan: '2',
        colSpan: '1',
      },
      {
        key: 'lastVolume',
        text: t('txt-match-vol'), // Last match volumn
        className: 'filterIcon',
        rowSpan: '2',
        colSpan: '1',
      },
      {
        key: `${isPc ? 'changePc' : 'change'}`,
        text: `${isPc ? '%' : '+/-'}`,
        className: 'po-relative filterIcon text-right',
        rowSpan: '2',
        colSpan: '1',
      },
      {
        text: 'Short',
        rowSpan: '1',
        colSpan: '6',
      },
      {
        key: 'lot',
        className: 'filterIcon',
        text: t('txt-total-vol'), // Total vol
        rowSpan: '2',
        colSpan: '1',
      },
      {
        key: 'avePrice',
        text: t('txt-ave'), // M Price
        className: 'filterIcon',
        rowSpan: '2',
        colSpan: '1',
      },
      {
        key: 'highPrice',
        text: t('txt-high'), // +/-
        className: 'filterIcon',
        rowSpan: '2',
        colSpan: '1',
      },
      {
        key: 'lowPrice',
        className: 'filterIcon',
        text: t('txt-low'), // +/-
        rowSpan: '2',
        colSpan: '1',
      },
      {
        text: t('txt-foreign'), // Khối ngoại
        rowSpan: '1',
        colSpan: '2',
      },
    ];

    const row2 = [
      {
        key: 'gb3_p',
        text: t('derivative.label.p3'), // Giá 3
        className: 'filterIcon fw-500',
        rowSpan: '1',
        colSpan: '1',
      },
      {
        key: 'gb3_v',
        text: t('derivative.label.v3'), // KL 3
        className: 'filterIcon',
        rowSpan: '1',
        colSpan: '1',
      },
      {
        key: 'gb2_p',
        text: t('derivative.label.p2'), // Giá 2
        className: 'filterIcon fw-500 ',
        rowSpan: '1',
        colSpan: '1',
      },
      {
        key: 'gb2_v',
        text: t('derivative.label.v2'), // KL 2
        className: 'filterIcon',
        rowSpan: '1',
        colSpan: '1',
      },
      {
        key: 'gb1_p',
        text: t('derivative.label.p1'), // Giá 1
        className: 'filterIcon fw-500 ',
        rowSpan: '1',
        colSpan: '1',
      },
      {
        key: 'gb1_v',
        text: t('derivative.label.v1'), // KL 1
        className: 'filterIcon',
        rowSpan: '1',
        colSpan: '1',
      },
      {
        key: 'gs1_p',
        text: t('derivative.label.p1'), // Giá 1
        className: 'filterIcon fw-500 ',
        rowSpan: '1',
        colSpan: '1',
      },
      {
        key: 'gs1_v',
        text: t('derivative.label.v1'), // KL 1
        className: 'filterIcon',
        rowSpan: '1',
        colSpan: '1',
      },
      {
        key: 'gs2_p',
        text: t('derivative.label.p2'), // Giá 2
        className: 'filterIcon fw-500',
        rowSpan: '1',
        colSpan: '1',
      },
      {
        key: 'gs2_v',
        text: t('derivative.label.v2'), // KL 2
        className: 'filterIcon',
        rowSpan: '1',
        colSpan: '1',
      },
      {
        key: 'gs3_p',
        text: t('derivative.label.p3'), // Giá 3
        className: 'filterIcon fw-500',
        rowSpan: '1',
        colSpan: '1',
      },
      {
        key: 'gs3_v',
        text: t('derivative.label.v3'), // KL 3
        className: 'filterIcon',
        rowSpan: '1',
        colSpan: '1',
      },
      {
        key: 'fBVol',
        text: t('txt-buy'), // Mua
        className: 'filterIcon',
        rowSpan: '1',
        colSpan: '1',
      },
      {
        key: 'fSVolume',
        text: t('txt-sell'), // Bán
        className: 'filterIcon',
        rowSpan: '1',
        colSpan: '1',
      },
    ];

    const showSortIcon = (field) => {
      if (sortField == field) {
        return (
          <span onClick={() => sortHandle(field)}>
            {!isAsc ? (
              <BsFillCaretUpFill
                className="fz_10 position-relative"
                style={{ marginLeft: '0.15rem' }}
              />
            ) : (
              <BsFillCaretDownFill
                className="fz_10 position-relative"
                style={{ marginLeft: '0.15rem' }}
              />
            )}
          </span>
        );
      } else return null;
    };

    const _sortHandle = (fieldName) => {
      if (sortHandle && typeof sortHandle == 'function') {
        sortHandle(fieldName, isAsc);
        setSortField(fieldName);
        setIsAsc(!isAsc);
      }
    };

    const _changePcHandle = () => {
      const value = !isPc;
      setIsPC(value);
      if (changePcHandle && typeof changePcHandle == 'function')
        changePcHandle(value);
    };

    return (
      <>
        <tr>
          {row1.map((r, i) => {
            if (r.key == 'change' || r.key == 'changePc')
              return (
                <th rowSpan={r.rowSpan} colSpan={r.colSpan} key={`r1_${i}`}>
                  <div className="d-flex justify-content-between">
                    <div style={{ marginLeft: '-6px' }}>
                      <BsCaretLeftFill
                        className="fz_10 position-relative"
                        onClick={() => _changePcHandle()}
                      />
                      <BsCaretRightFill
                        className="fz_10 position-relative"
                        onClick={() => _changePcHandle()}
                      />
                    </div>
                    <div
                      className={r.className}
                      onClick={() => r.key && _sortHandle(r.key)}
                    >
                      {typeof r.text === 'string' ? (
                        <span>{r.text}</span>
                      ) : undefined}
                      {isTextFunction(r.text) &&
                        r.text({
                          sort: {
                            type: isAsc ? 'asc' : 'desc',
                            field: sortField,
                          },
                        })}
                      {r.sortable != false && showSortIcon(r.key)}
                    </div>
                  </div>
                </th>
              );
            else
              return (
                <th
                  rowSpan={r.rowSpan}
                  colSpan={r.colSpan}
                  className={r.className}
                  key={`r1_${i}`}
                  onClick={() =>
                    r.key && r.sortable != false && _sortHandle(r.key)
                  }
                >
                  {typeof r.text === 'string' ? (
                    <span>{r.text}</span>
                  ) : undefined}
                  {isTextFunction(r.text) && r.text()}
                  {r.sortable != false && showSortIcon(r.key)}
                </th>
              );
          })}
        </tr>
        <tr>
          {row2.map((r, i) => (
            <th
              rowSpan={r.rowSpan}
              colSpan={r.colSpan}
              className={r.className}
              key={`r2_${i}`}
              onClick={() =>
                r.key &&
                !(r.key == 'changePc' || r.key == 'change') &&
                _sortHandle(r.key)
              }
            >
              <span>{r.text}</span>
              {showSortIcon(r.key)}
            </th>
          ))}
        </tr>
      </>
    );
  }
);

const TblGroup = withNamespaces()(({ t, partSnap }) => {
  // const { sortField, isAsc } = useSelector((state) => state.derivative);
  const typeIndex = useSelector(makeGetTypeIndex());
  const { seltp } = useSelector((state) => state.derivative);
  const [isPc, setIsPc] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [partSnapList, setPartSnapList] = useState([]);
  const dispatch = useDispatch();

  const sortHandle = (fieldName, isAsc = false) => {
    if (partSnapList && !!partSnapList.length > 0) {
      let _partSnapList = [...partSnapList];

      const mySort = (field) => {
        const rs = _partSnapList.sort(function (a, b) {
          const isANumber = !isNaN(parseFloat(a[field])) && isFinite(a[field]);
          const isBNumber = !isNaN(parseFloat(b[field])) && isFinite(b[field]);

          if (isANumber && isBNumber) {
            return parseFloat(a[field]) - parseFloat(b[field]);
          } else if (isANumber) {
            return -1;
          } else if (isBNumber) {
            return 1;
          } else {
            return a[field] - b[field];
          }
        });

        return rs;
      };

      const _sort = mySort(fieldName);
      _partSnapList = isAsc ? _sort : _sort.reverse();

      setPartSnapList(_partSnapList);
    }
  };

  const changePcHandle = (value) => {
    setIsPc(value);
  };
  const handleLoading = () => {};

  const colgroup = (
    <colgroup>
      <col width="4.5%"></col>
      {/** sym */}
      <col width="4.5%"></col>
      {/** expired date */}
      <col width="3.5%"></col>
      {/** tc */}
      <col width="3.5%"></col>
      {/** tran */}
      <col width="3.5%"></col>
      {/** san */}
      <col width="3.5%"></col>
      {/** gia 3 */}
      <col width="3.5%"></col>
      {/** gia 3 */}
      <col width="3.5%"></col>
      {/** gia 3 */}
      <col width="3.5%"></col>
      {/** kl 3 */}
      <col width="3.5%"></col>
      {/** giá 2 */}
      <col width="3.5%"></col>
      {/** kl 2 */}
      <col width="3.5%"></col>
      {/** gia 1 */}
      <col width="3.5%"></col>
      {/** kl 1 */}
      <col width="3.5%"></col>
      {/** gia khop */}
      <col width="3.5%"></col>
      {/** kl khop */}
      <col width="3.5%"></col>
      {/** +/- */}
      <col width="3.5%"></col>
      {/** gia 1 */}
      <col width="3.5%"></col>
      {/** kl 1 */}
      <col width="3.5%"></col>
      {/** gia 2 */}
      <col width="3.5%"></col>
      {/** kl 2 */}
      <col width="3.5%"></col>
      {/** gia 3 */}
      <col width="3.5%"></col>
      {/** kl 3 */}
      <col width="4.5%"></col>
      {/** tong kl */}
      <col width="3.5%"></col>
      {/** cao */}
      <col width="3.5%"></col>
      {/** thap */}
      <col width="3.5%"></col>
      {/** tb */}
      <col width="3.5%"></col>
      {/** nn mua */}
      <col width="3.5%"></col>
      {/** nn ban */}
    </colgroup>
  );

  const heightTb = 155 + (typeIndex === 1 ? 130 : 70);

  useEffect(() => {
    if (!partSnap) {
      setIsLoading(true);
    } else {
      const _partSnap = partSnap
        .filter((x) =>
          seltp == DERIVATIVE_CONSTANTS.DRVX_TYPE.BOND5Y
            ? x.seTp == DERIVATIVE_CONSTANTS.DRVX_TYPE.BOND5Y ||
              x.seTp == DERIVATIVE_CONSTANTS.DRVX_TYPE.BOND10Y
            : x.seTp == DERIVATIVE_CONSTANTS.DRVX_TYPE.INDEX
        )
        .map((item) => _processMapDataDv(item, 3)); // Derivative record
      setPartSnapList(_partSnap);
    }
  }, [partSnap, seltp]);

  return (
    <>
      <div className="drvx-price-board">
        <DatagridGroup
          resource={[]}
          classname="table table-bordered table-fix"
          isPc={isPc}
          colgroup={colgroup}
        >
          <Thead
            handleLoading={handleLoading}
            sortHandle={sortHandle}
            changePcHandle={changePcHandle}
          />
        </DatagridGroup>

        {partSnapList && (
          <PerfectScrollbar style={{ height: `calc(100vh - ${heightTb}px)` }}>
            <DatagridGroup
              resource={partSnapList}
              classname="table table-fix price-table border-top-0"
              isPc={isPc}
              isDerivative={true}
              colgroup={colgroup}
              customOpenModal={(sym) => {
                dispatch(getDerivativeDetailRequest(sym));
                dispatch(setCurrentSymbol(sym));
                dispatch(setShowDetailModal(true));
                Mixpanel.derivative.viewTicketInfor(
                  sym,
                  MixConstants.LOCATIONS.PRICE_BOARD
                );
              }}
            />
            <div
              style={{
                height: '200px',
                marginTop: '10px',
                color: '#c5c5c6',
                textAlign: 'center',
                fontSize: '13px',
                lineHeight: '16px',
              }}
            >
              <span className="text-tb-copyright">{t('txt-copyright2')}</span>
            </div>

            {isLoading && (
              <div className="position-absolute loading-bottom">
                <img
                  src={ImgLoading}
                  alt="loading"
                  style={{ height: '22px' }}
                />
                Loading
              </div>
            )}
          </PerfectScrollbar>
        )}
      </div>
    </>
  );
});

export default Pricetable;
