import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { ReactReduxContext, useDispatch, useSelector } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import PinefolioLevels from '../pinefolioLevels';
import { v4 as uuidv4 } from 'uuid';
import { getPFChart } from '../../action';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import {
  formatDate,
  numberFormat,
  stringToDate,
  _formatDate2,
} from '../../../../util';
import { sub } from 'date-fns';
import {
  Logout,
  logoutRequest,
} from '../../../../components/modal/login/actions';
import { setToast, unsetClient } from '../../../client/actions';
import { removeCookie } from '../../../../lib/storages';
import { handleApiErrors } from '../../../../lib/api-errors';
import { getMessage } from '../../../../lib/selector';

const appUrl = `${process.env.REACT_APP_API_URL}`;
const PerformanceChart = withNamespaces('translations')(
  ({ t, closeCallback, state, sources }) => {
    const periods = [
      { id: 0, label: `1 ${t('time.short.m')}` },
      { id: 1, label: `3 ${t('time.short.m')}` },
      { id: 2, label: `6 ${t('time.short.m')}` },
      { id: 3, label: `9 ${t('time.short.m')}` },
      { id: 4, label: `1 ${t('time.short.y')}` },
      { id: 5, label: `3 ${t('time.short.y')}` },
      { id: 6, label: `5 ${t('time.short.y')}` },
      { id: -1, label: t('txt-all') },
    ];
    console.log('sourcessourcessources', sources);
    const [show, setShow] = useState(false);
    const [pinefolioRate, setPinefolioRate] = useState([]);
    const [vniRate, setVNI] = useState([]);
    const [period, setPeriod] = useState(periods[0]);
    const theme = useSelector((state) => state.client?.currentTheme);
    const { currentPinefolio, currentPinefolioChart } = useSelector(
      (state) => state.pinefolio
    );
    const [pfPerform, setPfPerform] = useState();
    const { store } = useContext(ReactReduxContext);
    const { token, auth } = useSelector((state) => state.client);
    const dispatch = useDispatch();

    useEffect(() => {
      if (state != undefined) setShow(state);
    }, [state]);

    useEffect(() => {
      if (currentPinefolioChart) {
        console.log('currentPinefolioChart', currentPinefolioChart);
        const _pinefolioRate = currentPinefolioChart.map((x) => ({
          date: x.trdDate,
          begin: x.beginEvltAmt,
          value: x.pfEvltAmt || 0,
          change: x.evltChange || 0,
        }));

        setPinefolioRate(_pinefolioRate);
        console.log('_pinefolioRate', _pinefolioRate);

        const _vniRate = currentPinefolioChart.map((x) => ({
          date: x.trdDate,
          begin: x.beginIdx,
          value: x.idx || 0,
          change: x.idxChange,
        }));

        setVNI(_vniRate);
        console.log('_vniRate', _vniRate);
      }
    }, [currentPinefolioChart]);

    useEffect(() => {
      // get pinefolio theme chart
      if (currentPinefolio) {
        getPFPerform();
        getChart();
        let itv = setInterval(() => {
          getPFPerform();
        }, 5000);
        return () => {
          if (itv) {
            clearInterval(itv);
          }
        };
      }
    }, [currentPinefolio]);

    useEffect(() => {
      if (period) {
        getChart();
      }
    }, [period]);

    const getChart = () => {
      const uuid = uuidv4();
      if (token) {
        const eDate = new Date();
        let sDate;
        switch (period?.id) {
          case -1:
            sDate = sub(eDate, { years: 100 });
            break;
          case 0:
            sDate = sub(eDate, { months: 1 });
            break;
          case 1:
            sDate = sub(eDate, { months: 3 });
            break;
          case 2:
            sDate = sub(eDate, { months: 6 });
            break;
          case 3:
            sDate = sub(eDate, { months: 9 });
            break;
          case 4:
            sDate = sub(eDate, { years: 1 });
            break;
          case 5:
            sDate = sub(eDate, { years: 3 });
            break;
          case 6:
            sDate = sub(eDate, { years: 5 });
            break;
          default:
            sDate = sub(eDate, { months: 1 });
            break;
        }

        const resData = {
          group: 'CORE',
          user: token.user,
          session: token.session,
          cmd: 'getPFPerChart',
          rqId: uuid,
          channel: 'WTS',
          data: {
            frDt: formatDate(sDate, ''),
            toDt: formatDate(eDate, ''),
            pfCode: currentPinefolio?.pfCode,
          },
        };
        dispatch(getPFChart(resData));
      }
    };

    function getPFPerform() {
      const uuid = uuidv4();
      const resData = {
        group: 'CORE',
        cmd: 'getPFPerform',
        rqId: uuid,
        channel: 'WTS',
        data: {
          pfCode: currentPinefolio?.pfCode,
        },
      };
      const url = `${appUrl}/PortfolioServlet.pt`;
      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
        body: JSON.stringify(resData),
      })
        .then(handleApiErrors)
        .then((response) => response.json())
        .then((res) => {
          if (res.http == 200) {
            let data = res.data;
            if (data) {
              setPfPerform(data);
            }
          } else {
            if (res.http === 401) {
              dispatch(unsetClient());
              removeCookie('token');
              removeCookie('authen');
              dispatch(Logout());
              const toastMsg = {
                id: Math.random(),
                msg: t('txt-valid-loss-session'),
                title: t('txt-notice'),
              };
              dispatch(setToast(toastMsg));
            } else {
              const msgErr = getMessage(res.data.message, store.getState);
              const toastMsg = {
                id: Math.random(),
                msg: msgErr,
                title: t('txt-notice'),
              };
              dispatch(setToast(toastMsg));
            }
          }
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }
    const closeModal = () => {
      setShow(false);
      if (closeCallback && typeof closeCallback == 'function') {
        closeCallback();
      }
    };

    const pfCallback = () => {};

    const options = {
      chart: {
        type: 'line',
        height: 400,
        marginTop: 40,
      },
      title: {
        text: '',
      },
      credits: { enabled: false },
      legend: { enabled: false },

      xAxis: {
        gridLineWidth: 0,
        tickInterval: 1,
        visible: false,
        categories: pinefolioRate.map((x) => _formatDate2(x.date)),
      },

      yAxis: {
        title: '',
        gridLineWidth: 1,
        lineColor: '#FF0000',
        visible: true,
        opposite: false,
        labels: {
          align: 'right',
          enabled: true,
          formatter: function () {
            return this.value + ' %';
          },
        },
      },
      tooltip: {
        enabled: true,
        shared: true,
        useHTML: true,

        pointFormatter: function (e) {
          console.log('e ===> ', e);
          console.log('this ===> ', this);
          return `<strong style="color: ${this.series.color}">${
            this.series.name
          } </strong> ${t('txt-change')}: ${Highcharts.numberFormat(
            this.y,
            2,
            '.',
            ''
          )} %
          <br/>`;
        },
      },
      series: [
        {
          name: 'Pinefolio',
          data: pinefolioRate.map((x) => x.change) ?? [],
          color: theme.name == 'dark' ? '#00ff57' : '#2db35b',
          marker: {
            enabled: false,
            symbol: 'circle',
          },
        },
        {
          name: 'VNI',
          data: vniRate.map((x) => x.change) ?? [],
          color: theme.name == 'dark' ? '#6D6E71' : '#6D6E71',
          marker: {
            enabled: false,
            symbol: 'circle',
          },
        },
      ],
    };

    console.log('vniRate  ===> ', vniRate);
    console.log('pinefolioRate  ===> ', pinefolioRate);

    return (
      <Modal
        show={show}
        dialogClassName="wts-modal"
        backdropClassName="wts-modal-backdrop pinefolio-performance-backdrop"
        contentClassName={`${
          theme ? theme.name : 'dark'
        } pinefolio-performance wts-modal-content wts-modal-content--md`}
        onEscapeKeyDown={() => closeModal()}
        centered
        onHide={() => closeModal()}
        style={{ zIndex: 11115 }}
      >
        <div className="pinefolio-performance__header">
          <span className="text--lg text--light pinefolio-performance__title">
            {t('pinefolio.performance')}
          </span>
          <PinefolioLevels
            sources={sources}
            onClickCallback={pfCallback}
            showPFName={true}
          />
        </div>
        <div className="pinefolio-performance__body">
          <div className="performance-index d-flex justify-content-around p-2">
            <div className="d-flex flex-column justify-content-between align-items-center">
              <span
                className={`fz_16 ${
                  pfPerform?.effYear == 0
                    ? 'r'
                    : pfPerform?.effYear > 0
                    ? 'i'
                    : 'd'
                }`}
              >
                {`${numberFormat(pfPerform?.effYear, 2, 0)}%`}
              </span>
              <span className="fz_13 text--light3">
                {t('pinefolio.portfolio.performance.perYear')}
              </span>
            </div>
            <div className="d-flex flex-column">
              <span
                className={`fz_16 ${
                  pfPerform?.effMonth == 0
                    ? 'r'
                    : pfPerform?.effMonth > 0
                    ? 'i'
                    : 'd'
                }`}
              >
                {`${numberFormat(pfPerform?.effMonth, 2, 0)}%`}
              </span>
              <span className="fz_13 text--light3">
                {' '}
                {t('pinefolio.portfolio.performance.perMonth')}
              </span>
            </div>
            <div className="d-flex flex-column">
              <span
                className={`fz_16 ${
                  pfPerform?.effDay == 0
                    ? 'r'
                    : pfPerform?.effDay > 0
                    ? 'i'
                    : 'd'
                }`}
              >
                {`${numberFormat(pfPerform?.effDay, 2, 0)}%`}
              </span>
              <span className="fz_13 text--light3">
                {' '}
                {t('pinefolio.portfolio.performance.perDay')}
              </span>
            </div>
          </div>
          <div className="performance-chart">
            {((vniRate && !!vniRate.length > 0) ||
              (pinefolioRate && !!pinefolioRate.length > 0)) && (
              <HighchartsReact highcharts={Highcharts} options={options} />
            )}
            {!!vniRate.length == 0 && !!pinefolioRate.length == 0 && (
              <div className="performance-chart__empty">
                <div className="performance-chart__icon">
                  <span className="icon iEmpty"></span>
                </div>

                <p className="text--sm text--light3">
                  {t('txt-no-data-found')}
                </p>
              </div>
            )}
          </div>

          <div className="chart-explain">
            <div className="chart-explain__navigations">
              {periods &&
                periods.map((per, i) => (
                  <div
                    key={i}
                    className={`btn btn--transparent br-5 chart-explain__navigation ${
                      per?.id == period?.id ? 'active' : ''
                    }`}
                    onClick={() => setPeriod(per)}
                  >
                    {per.label}
                  </div>
                ))}
            </div>
            <div className="chart-explain__desc chart-explain__items">
              <span className="i chart-explain__item">
                <span className="dot"></span>
                <span className="text--sm">
                  {t('pinefolio.pinefolioChart')}
                </span>
              </span>
              <span className="chart-explain__item">
                <span className="dot"></span>
                <span className="text--sm">{t('pinefolio.vniChart')}</span>
              </span>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
);

export default PerformanceChart;
