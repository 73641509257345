import {
  BANK_ACC_REQUESTING,
  GET_PROFILE_REQUESTING,
  SET_DEFAULT_REQUESTING,
  GET_SMS_SERVICES_REQUESTING,
  SET_SMS_SERVICES_REQUESTING,
  GET_EXT_SETTING_REQUESTING,
  SET_EXT_SETTING_REQUESTING,
  REMOVE_BANK_REQUESTING,
  UPDATE_PROFILE_REQUESTING,
  UPDATE_PROFILE_REQUEST_SUCCESS,
  UPDATE_PROFILE_REQUEST_ERROR,
  OTP_SENDING_REQUESTING,
  GET_PROFILE_STATE_REQUEST,
  GET_PROFILE_STATE_REQUEST_SUCCESS,
  GET_PROFILE_STATE_REQUEST_ERROR,
  VC_BOOKING_DATE_REQUESTING,
  VC_BOOKING_DATE_ERROR,
  VC_BOOKING_DATE_SUCCESS,
  VC_BOOKING_TIME_REQUESTING,
  VC_BOOKING_TIME_ERROR,
  VC_BOOKING_TIME_SUCCESS,
  VC_BOOKING_REQUESTING,
  VC_BOOKING_ERROR,
  VC_BOOKING_SUCCESS,
  REGIST_VC_BOOKING_REQUESTING,
  REGIST_VC_BOOKING_ERROR,
  REGIST_VC_BOOKING_SUCCESS,
  CANCEL_VC_BOOKING_REQUESTING,
  CANCEL_VC_BOOKING_ERROR,
  CANCEL_VC_BOOKING_SUCCESS,
  OPEN_SUBACCOUNT_CONTRACT_REQUEST,
  OPEN_SUBACCOUNT_CONTRACT_SUCCESS,
  CONFIRM_SUBACCOUNT_CONTRACT_REQUEST,
  GET_CREDIT_LIMIT_LEVEL,
  GET_CREDIT_LIMIT_LEVEL_SUCCESS,
  GET_LIST_ACCOUNTS,
  GET_ACCOUNT_COMMON_REQUEST,
  GET_BROKER_INFOR_REQUEST,
  SUBMIT_PARTNER_SHIP_REQUEST,
  GET_SER_LIST_4_CUST_REQUEST,
  GET_SER_LIST_4_CUST__SUCCESS,
  GET_API_SERVICES_REQUESTING,
  GEN_API_SERVICES_KEY,
} from './constants';
import {
  profileRequestSuccess,
  profileRequestError,
  bankAccRequestSuccess,
  bankAccRequestError,
  setDefaultRequestError,
  setDefaultRequestSuccess,
  getsMSSettingRequestSuccess,
  getsMSSettingRequestError,
  setsMSSettingRequestSuccess,
  setsMSSettingRequestError,
  getExtSettingRequestSuccess,
  getExtSettingRequestError,
  setExtSettingRequestSuccess,
  setExtSettingRequestError,
  removeBankRequestSuccess,
  removeBankRequestError,
  getOpenApiSrvListRequestSuccess,
  getOpenApiSrvListRequestError,
} from './actions';
import {
  all,
  takeLatest,
  put,
  call,
  select,
  takeLeading,
  takeEvery,
} from 'redux-saga/effects';
import { handleApiErrors } from '../../lib/api-errors';

import { getMessage, makeGetDefaultAccount } from '../../lib/selector';
import { getCookie, removeCookie, setCookie } from '../../lib/storages';
import { useSelector } from 'react-redux';
import { setClient, setToast, unsetClient } from '../client/actions';
import i18n from '../../i18n';
import { Mixpanel } from '../../lib/mixpanel';
import {
  stringToDate,
  subAccountProcess,
  subAccountTypeDetect,
} from '../../util';
import { setDefaultAccount, updateListAccount } from '../socket/actions';
import { LOCK_RESENT_OTP } from '../../components/modal/auth/constants';
import { constants } from '../../util/constant';
import { Logout, logoutRequest } from '../../components/modal/login/actions';
import { lockResendOtp } from '../../components/modal/auth/actions';

const appUrl = `${process.env.REACT_APP_API_URL}`;

function handleRequest(request) {
  return request
    .then(handleApiErrors)
    .then((response) => response.json())
    .then((json) => json)
    .catch((error) => {
      throw error;
    });
}

function toastHandle(message, title = i18n.t('txt-notice')) {
  const toastMsg = {
    id: Math.random(),
    msg: message,
    title: title,
  };
  return put(setToast(toastMsg));
}

function portfolioApi(data) {
  const url = `${appUrl}/PortfolioServlet.pt`;
  const request = fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
    body: JSON.stringify(data),
  });
  return handleRequest(request);
}

function customerApi(data) {
  const url = `${appUrl}/CoreServlet.pt`;
  const request = fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data; charset=UTF-8',
    },
    body: JSON.stringify(data),
  });
  return handleRequest(request);
}

function publicApi(data) {
  const url = `${appUrl}/PublicServlet.pt`;
  const request = fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
    body: JSON.stringify(data),
  });
  return handleRequest(request);
}

function* profileRequestFlow({ params }) {
  try {
    const resData = yield call(customerApi, params);

    if (resData.http !== 200) {
      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');

        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }
      const msgErr = yield select(getMessage, resData);
      throw Error(msgErr);
    }

    yield put(profileRequestSuccess(resData.data));
  } catch (error) {
    // console.log(error)
    yield put(profileRequestError(error));
  }
}

function* bankAccRequestFlow({ params }) {
  try {
    const resData = yield call(customerApi, params);

    if (resData.http !== 200) {
      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');

        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }
      const msgErr = yield select(getMessage, resData);
      throw Error(msgErr);
    }

    yield put(bankAccRequestSuccess(resData.data));
  } catch (error) {
    // console.log(error)
    yield put(bankAccRequestError(error));
  }
}

function* setDefaultRequestFlow({ params }) {
  try {
    const resData = yield call(customerApi, params);

    if (resData.http !== 200) {
      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');

        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }
      const msgErr = yield select(getMessage, resData);
      throw Error(msgErr);
    }

    yield put(setDefaultRequestSuccess());
  } catch (error) {
    // console.log(error)
    yield put(setDefaultRequestError(error));
  }
}

function* getsMSSettingRequestFlow({ params }) {
  try {
    const resData = yield call(customerApi, params);

    if (resData.http !== 200) {
      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');

        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }
      const msgErr = yield select(getMessage, resData);
      throw Error(msgErr);
    }

    yield put(getsMSSettingRequestSuccess(resData.data));
  } catch (error) {
    yield put(getsMSSettingRequestError(error));
  }
}

function* getOpenApiSrvListRequestFlow({ payload }) {
  try {
    const resData = yield call(customerApi, payload);

    if (resData.http !== 200) {
      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');

        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }
      const msgErr = yield select(getMessage, resData);
      throw Error(msgErr);
    }

    yield put(getOpenApiSrvListRequestSuccess(resData.data));
  } catch (error) {
    yield put(getOpenApiSrvListRequestError(error));
  }
}

function* setsMSSettingRequestFlow({ params }) {
  try {
    const resData = yield call(customerApi, params);

    if (resData.http !== 200) {
      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');

        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }
      const msgErr = yield select(getMessage, resData);
      throw Error(msgErr);
    }

    yield put(setsMSSettingRequestSuccess(resData.data, params.data));
  } catch (error) {
    yield put(setsMSSettingRequestError(error));
  }
}

function* getExtSettingRequestFlow({ params }) {
  try {
    const resData = yield call(customerApi, params);

    if (resData.http !== 200) {
      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');

        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }
      const msgErr = yield select(getMessage, resData);
      throw Error(msgErr);
    }

    yield put(getExtSettingRequestSuccess(resData.data));
  } catch (error) {
    yield put(getExtSettingRequestError(error));
  }
}

function* setExtSettingRequestFlow({ params }) {
  try {
    const resData = yield call(customerApi, params);

    if (resData.http !== 200) {
      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');

        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }
      const msgErr = yield select(getMessage, resData);
      throw Error(msgErr);
    }

    yield put(setExtSettingRequestSuccess(resData.data, params.data));
  } catch (error) {
    yield put(setExtSettingRequestError(error));
  }
}

function* removeBankRequestFlow({ params }) {
  try {
    const resData = yield call(customerApi, params);

    if (resData.http !== 200) {
      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');

        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }
      const msgErr = yield select(getMessage, resData);
      throw Error(msgErr);
    }

    yield put(removeBankRequestSuccess(params));
  } catch (error) {
    yield put(removeBankRequestError(error));
  }
}

function* updateProfileRequestFlow({ params }) {
  try {
    const resData = yield call(customerApi, params);
    if (resData.http !== 200) {
      if (resData.data.messageNo === 99991) {
        yield put({ type: LOCK_RESENT_OTP });
      }

      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');
        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }
      const msgErr = yield select(getMessage, resData);
      yield put({ type: UPDATE_PROFILE_REQUEST_ERROR, error: msgErr });
    } else yield put({ type: UPDATE_PROFILE_REQUEST_SUCCESS });
    // getProfileStateFlow();
  } catch (error) {
    yield put({ type: UPDATE_PROFILE_REQUEST_ERROR, error });
  }
}

function* getProfileStateFlow({ params }) {
  try {
    const resData = yield call(customerApi, params);
    if (resData.http !== 200) {
      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');
        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }
      const msgErr = yield select(getMessage, resData);
      yield put({ type: UPDATE_PROFILE_REQUEST_ERROR, msgErr });
    }

    yield put({
      type: GET_PROFILE_STATE_REQUEST_SUCCESS,
      data: resData.data,
    });
  } catch (error) {
    yield put({ type: GET_PROFILE_STATE_REQUEST_ERROR, error });
  }
}

function* getVcBookingDateFlow({ params }) {
  try {
    const resData = yield call(customerApi, params);
    if (resData.http !== 200) {
      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');
        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }
      const msgErr = yield select(getMessage, resData);
      yield put({ type: VC_BOOKING_DATE_ERROR, msgErr });
    }

    yield put({
      type: VC_BOOKING_DATE_SUCCESS,
      data: resData.data,
    });
  } catch (error) {
    yield put({ type: VC_BOOKING_DATE_ERROR, error });
  }
}

function* getVcBookingTimeFlow({ params }) {
  try {
    const resData = yield call(customerApi, params);
    if (resData.http !== 200) {
      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');
        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }
      const msgErr = yield select(getMessage, resData);
      yield put({ type: VC_BOOKING_TIME_ERROR, msgErr });
    }

    yield put({
      type: VC_BOOKING_TIME_SUCCESS,
      data: resData.data,
    });
  } catch (error) {
    yield put({ type: VC_BOOKING_TIME_ERROR, error });
  }
}

function* getVcBookingFlow({ params }) {
  try {
    const resData = yield call(customerApi, params);
    if (resData.http !== 200) {
      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');
        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }
      const msgErr = yield select(getMessage, resData);
      yield put({ type: VC_BOOKING_ERROR, msgErr });
    } else
      yield put({
        type: VC_BOOKING_SUCCESS,
        data: resData.data,
      });
  } catch (error) {
    yield put({ type: VC_BOOKING_ERROR, error });
  }
}

function* getRegistVcBookingFlow({ params, startTime, callback, fallback }) {
  try {
    const resData = yield call(customerApi, params);
    if (resData.http !== 200) {
      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');
        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }
      const msgErr = yield select(getMessage, resData);
      yield put(
        setToast({
          id: Math.random(),
          msg: msgErr,
          title: i18n.t('txt-notice'),
        })
      );
      yield put({ type: REGIST_VC_BOOKING_ERROR, msgErr });
      yield fallback({ msg: msgErr, msgNo: resData.http });
    } else {
      yield put({
        type: REGIST_VC_BOOKING_SUCCESS,
        data: resData.data,
      });

      yield put(
        setToast({
          id: Math.random(),
          msg: i18n.t(
            'user.bookingMarginVideoCall.messageRegistBookingSuccess'
          ),
          title: i18n.t('txt-notice'),
        })
      );

      if (callback && typeof callback == 'function') {
        yield callback({ msg: resData.msg, msgNo: resData.http });
      }
    }
  } catch (error) {
    yield put({ type: REGIST_VC_BOOKING_ERROR, error });
    yield fallback({ msg: error, msgNo: -1 });
  }
}

function* getCancelVcBookingFlow({ params }) {
  try {
    const resData = yield call(customerApi, params);
    if (resData.http !== 200) {
      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');
        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }
      const msgErr = yield select(getMessage, resData);
      yield put(
        setToast({
          id: Math.random(),
          msg: msgErr,
          title: i18n.t('txt-notice'),
        })
      );
      yield put({ type: CANCEL_VC_BOOKING_ERROR, msgErr });
    } else {
      yield put({
        type: CANCEL_VC_BOOKING_SUCCESS,
        data: resData.data,
      });
      yield put(
        setToast({
          id: Math.random(),
          msg: i18n.t(
            'user.bookingMarginVideoCall.messageCancelBookingSuccess'
          ),
          title: i18n.t('txt-notice'),
        })
      );
    }
  } catch (error) {
    yield put({ type: CANCEL_VC_BOOKING_ERROR, error });
  }
}

function* getOpenSubAccountContractFlow({ params }) {
  try {
    const resData = yield call(portfolioApi, params);
    if (resData.http !== 200) {
      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');
        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }
      const msgErr = yield select(getMessage, resData);
      const toastMsg = {
        id: Math.random(),
        msg: msgErr,
        title: i18n.t('txt-notice'),
      };
      return yield put(setToast(toastMsg));
    }

    yield put({
      type: OPEN_SUBACCOUNT_CONTRACT_SUCCESS,
      data: resData.data,
    });
  } catch (error) {
    yield put({ type: VC_BOOKING_TIME_ERROR, error });
  }
}

function* confirmOpenSubAccountFlow(action) {
  const { params, callback, fallback, listAccount } = action.params;
  try {
    const resData = yield call(customerApi, params);
    console.log('resData ', resData);

    if (resData.http !== 200) {
      if (resData.data.messageNo === 99991) {
        yield put({ type: LOCK_RESENT_OTP });
      }

      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');
        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }

      const msgErr = yield select(getMessage, resData);
      const toastMsg = {
        id: Math.random(),
        msg: msgErr,
        title: i18n.t('txt-error'),
      };
      yield put(setToast(toastMsg));

      if (fallback && typeof fallback == 'function')
        yield fallback({
          msgNo: resData.data?.messageNo || resData.http,
          msg: resData.data?.message || msgErr,
        });
    } else {
      const currentAccountList = listAccount;
      const newAccount = resData.data;
      const newAccountList = [...currentAccountList, newAccount];

      yield put(updateListAccount(newAccountList));
      const currentLocation = window.location?.hash || '';

      if (
        newAccount.subAcntClss == constants.subAccount.Derivative &&
        currentLocation == '#/home/derivative'
      ) {
        yield put(setDefaultAccount(newAccount));
      }

      const token = yield select((state) => state.client.token);
      const newToken = token;
      if (newToken && newToken.custInfo && newToken.custInfo.normal) {
        newToken.custInfo.normal = newAccountList;
      }

      // set client
      yield put(setClient(newToken));

      let tokenCookie = getCookie('token');
      // tokenCookie = tokenCookie ? JSON.parse(tokenCookie) : null;

      if (tokenCookie) {
        if (
          tokenCookie &&
          tokenCookie.custInfo &&
          tokenCookie.custInfo.normal
        ) {
          tokenCookie.custInfo.normal = newAccountList;
        }
        setCookie('token', JSON.stringify(tokenCookie));
      }

      const subAccountType = params.data.subAcntClss;
      const toastMsg = {
        id: Math.random(),
        title: i18n.t('txt-notice'),
        msg: i18n.t('user.success', {
          ActionType: subAccountType?.startsWith('M')
            ? i18n.t('user.bookingMarginVideoCall.open')
            : i18n.t('user.bookingDerivativeVideoCall.open'),
        }),
      };

      yield put(setToast(toastMsg));

      if (callback && typeof callback == 'function') yield callback(newAccount);
    }
  } catch (error) {
    yield fallback({
      msgNo: '-1',
      msg: error,
    });
    yield put({ type: VC_BOOKING_TIME_ERROR, error });
  }
}

function* getCreditLimitLevelFlow({ params }) {
  try {
    const resData = yield call(publicApi, params);
    if (resData.http !== 200) {
      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');
        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }
      const msgErr = yield select(getMessage, resData);
      const toastMsg = {
        id: Math.random(),
        msg: msgErr,
        title: i18n.t('txt-notice'),
      };
      return yield put(setToast(toastMsg));
    }

    yield put({
      type: GET_CREDIT_LIMIT_LEVEL_SUCCESS,
      data: resData.data,
    });
  } catch (error) {
    const toastMsg = {
      id: Math.random(),
      msg: error?.toString() || '',
      title: i18n.t('txt-error'),
    };
    yield put(setToast(toastMsg));
  }
}

function* getListAccountsFlow({ params, callback }) {
  try {
    const resData = yield call(customerApi, params);
    const defaultAccount = yield select((state) => state.socket.defaultAccount);

    if (resData.http !== 200) {
      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');
        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }
      const msgErr = yield select(getMessage, resData);
      const toastMsg = {
        id: Math.random(),
        msg: msgErr,
        title: i18n.t('txt-notice'),
      };
      return yield put(setToast(toastMsg));
    } else {
      const data = resData.data;
      if (callback && typeof callback == 'function') {
        yield callback(data);
      }
    }
  } catch (error) {
    const toastMsg = {
      id: Math.random(),
      msg: error?.toString() || '',
      title: i18n.t('txt-error'),
    };
    yield put(setToast(toastMsg));
  }
}

/**
 * This func try to aim for covering almost call account api cases
 * @param {isProtect} param0 // this param help to protect private api (user data - must to authentication)
 */
function* getAccountCommonRequestFlow({ params, callback, isProtect }) {
  try {
    const resData = yield call(customerApi, params);
    const token = yield select((state) => state.client.token);

    if (resData.http !== 200) {
      let toastMsg = '';

      if (resData.http === 401) {
        if (isProtect) {
          if (token) {
            put(logoutRequest(token));
            put(unsetClient());
            put(Logout());
          }
          removeCookie('token');
          removeCookie('authen');
          yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
        }

        toastMsg = i18n.t('txt-valid-loss-session');
      } else {
        if (resData.data.messageNo === 99991) {
          put(lockResendOtp());
        } else {
          const msgErr = yield select(getMessage, resData);
          toastMsg = msgErr;
        }
      }

      yield toastHandle(toastMsg);
    } else {
      if (callback && typeof callback == 'function') {
        callback(resData.data);
      }
    }
  } catch (error) {
    console.error(error);
  }
}

function* getSerList4CustFlow({ payload }) {
  try {
    const resData = yield call(customerApi, payload);

    if (resData.http !== 200) {
      let toastMsg = '';

      if (resData.data.messageNo === 99991) {
        put(lockResendOtp());
      } else {
        const msgErr = yield select(getMessage, resData);
        toastMsg = msgErr;
      }

      yield toastHandle(toastMsg);
    } else {
      yield put({
        type: GET_SER_LIST_4_CUST__SUCCESS,
        data: resData.data,
      });
    }
  } catch (error) {
    console.error(error);
  }
}

function* getOpenApiSrvKeyFlow({ payload, callback }) {
  try {
    const resData = yield call(customerApi, payload);

    if (resData.http !== 200) {
      let toastMsg = '';

      if (resData.data.messageNo === 99991) {
        put(lockResendOtp());
      } else {
        const msgErr = yield select(getMessage, resData);
        toastMsg = msgErr;
      }

      yield toastHandle(toastMsg);
    } else {
      if (callback && typeof callback == 'function') {
        callback(resData.data);
      }
    }
  } catch (error) {
    console.error(error);
  }
}

function* customerWatcher() {
  yield all([
    takeLatest(GET_PROFILE_REQUESTING, profileRequestFlow),
    takeLatest(GET_PROFILE_STATE_REQUEST, getProfileStateFlow),
    takeLatest(UPDATE_PROFILE_REQUESTING, updateProfileRequestFlow),
    takeLatest(BANK_ACC_REQUESTING, bankAccRequestFlow),
    takeLatest(SET_DEFAULT_REQUESTING, setDefaultRequestFlow),
    takeLatest(GET_SMS_SERVICES_REQUESTING, getsMSSettingRequestFlow),
    takeLatest(GET_API_SERVICES_REQUESTING, getOpenApiSrvListRequestFlow),
    takeLatest(SET_SMS_SERVICES_REQUESTING, setsMSSettingRequestFlow),
    takeLatest(GET_EXT_SETTING_REQUESTING, getExtSettingRequestFlow),
    takeLatest(SET_EXT_SETTING_REQUESTING, setExtSettingRequestFlow),
    takeLatest(REMOVE_BANK_REQUESTING, removeBankRequestFlow),
    takeLatest(VC_BOOKING_DATE_REQUESTING, getVcBookingDateFlow),
    takeLatest(VC_BOOKING_TIME_REQUESTING, getVcBookingTimeFlow),
    takeLatest(VC_BOOKING_REQUESTING, getVcBookingFlow),
    takeLatest(REGIST_VC_BOOKING_REQUESTING, getRegistVcBookingFlow),
    takeLatest(CANCEL_VC_BOOKING_REQUESTING, getCancelVcBookingFlow),
    takeLatest(OPEN_SUBACCOUNT_CONTRACT_REQUEST, getOpenSubAccountContractFlow),
    takeLatest(CONFIRM_SUBACCOUNT_CONTRACT_REQUEST, confirmOpenSubAccountFlow),
    takeLatest(GET_CREDIT_LIMIT_LEVEL, getCreditLimitLevelFlow),
    takeLatest(GET_LIST_ACCOUNTS, getListAccountsFlow),
    takeLatest(GET_BROKER_INFOR_REQUEST, getAccountCommonRequestFlow),
    takeEvery(GET_ACCOUNT_COMMON_REQUEST, getAccountCommonRequestFlow),
    takeLeading(SUBMIT_PARTNER_SHIP_REQUEST, getAccountCommonRequestFlow),
    takeLatest(GET_SER_LIST_4_CUST_REQUEST, getSerList4CustFlow),
    takeLeading(GEN_API_SERVICES_KEY, getOpenApiSrvKeyFlow),
  ]);
}

export default customerWatcher;
