export const CLIENT_SET = 'CLIENT_SET';
export const CLIENT_UNSET = 'CLIENT_UNSET';
export const AUTHEN_SET = 'AUTHEN_SET';

export const CATEGORY_SET = 'CATEGORY_SET';
export const CATEGORY_UNSET = 'CATEGORY_UNSET';

export const SETTING_SET = 'SETTING_SET';
export const SETTING_UNSET = 'SETTING_UNSET';

export const LOGIN_SET = 'LOGIN_SET';
export const LOGIN_UNSET = 'LOGIN_UNSET';

export const AUTH_SET = 'AUTH_SET';
export const AUTH_UNSET = 'AUTH_UNSET';

export const RESET_SET = 'RESET_SET';
export const RESET_UNSET = 'RESET_UNSET';

export const CHANGE_PASS_SET = 'CHANGE_PASS_SET';
export const CHANGE_PASS_UNSET = 'CHANGE_PASS_UNSET';

export const CHANGE_TELPIN_SET = 'CHANGE_TELPIN_SET';
export const CHANGE_TELPIN_UNSET = 'CHANGE_TELPIN_UNSET';

export const CHANGE_USER_SET = 'CHANGE_USER_SET';
export const CHANGE_USER_UNSET = 'CHANGE_USER_UNSET';

export const CHANGE_BANK_SET = 'CHANGE_BANK_SET';
export const CHANGE_BANK_UNSET = 'CHANGE_BANK_UNSET';

export const FULL_TRADING_SET = 'FULL_TRADING_SET';
export const FULL_TRADING_UNSET = 'FULL_TRADING_UNSET';

export const OBJ_TRADING_SET = 'OBJ_TRADING_SET';
export const OBJ_TRADING_UNSET = 'OBJ_TRADING_UNSET';

export const SYMBOL_MINIMIZE_SET = 'SYMBOL_MINIMIZE_SET';
export const SYMBOL_MINIMIZE_UNSET = 'SYMBOL_MINIMIZE_UNSET';
export const SYMBOL_ADD = 'SYMBOL_ADD';
export const SYMBOL_SCROLL = 'SYMBOL_SCROLL';

export const TYPE_INDEX_SET = 'TYPE_INDEX_SET';
export const INDEX_HIDE_SET = 'INDEX_HIDE_SET';
export const CASH_OUT_SET = 'CASH_OUT_SET';
export const CASH_OUT_RESET = 'CASH_OUT_RESET';
export const ORD_COND_SET = 'ORD_COND_SET';
export const DBL_PRICE_SET = 'DBL_PRICE_SET';
export const CTLG_ID_EDIT_SET = 'CTLG_ID_EDIT_SET';

export const SYMBOL_ACTIVE_SET = 'SYMBOL_ACTIVE_SET';
export const SYMBOL_ACTIVE_UNSET = 'SYMBOL_ACTIVE_UNSET';

export const CONFIG_REQUESTING = 'CONFIG_REQUESTING';
export const CONFIG_REQUEST_SUCCESS = 'CONFIG_REQUEST_SUCCESS';
export const CONFIG_REQUEST_ERROR = 'CONFIG_REQUEST_ERROR';

export const CATEGORY_REQUESTING = 'CATEGORY_REQUESTING';
export const CATEGORY_REQUEST_SUCCESS = 'CATEGORY_REQUEST_SUCCESS';
export const CATEGORY_REQUEST_ERROR = 'CATEGORY_REQUEST_ERROR';

export const SAVE_CATEGORY_REQUESTING = 'SAVE_CATEGORY_REQUESTING';
export const SAVE_CATEGORY_REQUEST_SUCCESS = 'SAVE_CATEGORY_REQUEST_SUCCESS';
export const SAVE_CATEGORY_REQUEST_ERROR = 'SAVE_CATEGORY_REQUEST_ERROR';

export const INDUSTRY_CATEGORY_REQUESTING = 'INDUSTRY_CATEGORY_REQUESTING';
export const INDUSTRY_CATEGORY_REQUEST_SUCCESS =
  'INDUSTRY_CATEGORY_REQUEST_SUCCESS';
export const INDUSTRY_CATEGORY_REQUEST_ERROR =
  'INDUSTRY_CATEGORY_REQUEST_ERROR';
export const INDUSTRY_CATEGORY_APPEND = 'INDUSTRY_CATEGORY_APPEND';

export const ADVERTISE_REQUESTING = 'ADVERTISE_REQUESTING';
export const ADVERTISE_REQUESTING_RESET = 'ADVERTISE_REQUESTING_RESET';
export const ADVERTISE_REQUESTING_SUCCESS = 'ADVERTISE_REQUESTING_SUCCESS';
export const ADVERTISE_REQUESTING_ERROR = 'ADVERTISE_REQUESTING_ERROR';

export const APPSETTING_REQUESTING = 'APPSETTING_REQUESTING';
export const APPSETTING_REQUESTING_RESET = 'APPSETTING_REQUESTING_RESET';
export const APPSETTING_REQUESTING_SUCCESS = 'APPSETTING_REQUESTING_SUCCESS';
export const APPSETTING_REQUESTING_ERROR = 'APPSETTING_REQUESTING_ERROR';

export const GET_THEMES = 'GET_THEMES'; // TODO: GET THEMES LIST FROM API
export const SET_THEME = 'SET_THEME'; // UPDATE CURRENT THEME

export const SET_NOTI_POPUP = 'SET_NOTI_POPUP';
export const SET_CATEGORY_SELECTED = 'SET_CATEGORY_SELECTED';

export const USER_SETTING_REQUEST = 'USER_SETTING_REQUEST';
export const GET_CHART_IN_MEM_DATA = 'GET_CHART_IN_MEM_DATA';

export const SET_FEEDBACK_POPUP_SHOW = 'SET_FEEDBACK_POPUP_SHOW';
export const GET_FEEDBACK_TYPE_REQUEST = 'GET_FEEDBACK_TYPE_REQUEST';
export const SUBMIT_FEEDBACK_REQUEST = 'SUBMIT_FEEDBACK_REQUEST';

export const CLIENT_COMMON_REQUEST = 'CLIENT_COMMON_REQUEST';
