import {
  RESET_PASS_REQUESTING,
  RESET_PASS_SUCCESS,
  RESET_PASS_ERROR,
  RESET_PASS_CLEAR,
} from './constants';

const initialState = {
  resetPassRequesting: false,
  resetPassSuccessful: false,
  resetPassMessages: [],
  resetPassErrors: [],
};

const reducer = function authReducer(state = initialState, action) {
  switch (action.type) {
    case 'INVALID_SESSION':
    case 'CLIENT_UNSET':
    case 'LOG_OUT':
      return initialState;

    case RESET_PASS_REQUESTING:
      return {
        ...state,
        resetPassRequesting: true,
        resetPassSuccessful: false,
        resetPassMessages: [{ body: 'Reset password in...', time: new Date() }],
        resetPassErrors: [],
      };

    case RESET_PASS_SUCCESS:
      return {
        ...state,
        resetPassRequesting: false,
        resetPassSuccessful: true,
        resetPassMessages: [],
        resetPassErrors: [],
      };

    case RESET_PASS_ERROR:
      return {
        ...state,
        resetPassRequesting: false,
        resetPassSuccessful: false,
        resetPassMessages: [],
        resetPassErrors: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };

    case RESET_PASS_CLEAR:
      return {
        ...state,
        resetPassRequesting: false,
        resetPassSuccessful: false,
        resetPassMessages: [],
        resetPassErrors: [],
      };

    default:
      return state;
  }
};

export default reducer;
