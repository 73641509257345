import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
  setAuth,
  setLogin,
  setToast,
} from '../../../containers/client/actions';
import {
  StringToInt,
  _convertformatDate2,
  _formatDate,
  _formatDate2,
  formatDate,
  getCurrentDateMinusDays,
  numberFormat,
  stringToDate,
} from '../../../util';
import { v4 as uuidv4 } from 'uuid';
import PaginationTable from '../../../components/PaginationTable';
import { useDispatch, useSelector } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import ReactSelect from 'react-select';
import { constants } from '../../../util/constant';
import {
  getDrvCommonRequest,
  getDrvCommonRequest2,
} from '../../derivative/actions';
import DatePicker from '../../../components/datePicker3';
import TextMask from 'react-text-mask';
import { createNumberMask } from 'text-mask-addons';
import PerfectScrollBar from 'react-perfect-scrollbar';
import { setDefaultAccount } from '../../socket/actions';
import { useHistory } from 'react-router';
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import OpenSubAccountModal from '../../account/components/openSubAccountModal';

/**
 * The function label name is deposit/withdrawl margin money but only used for derivative account (so silly)
 */

const TYPE = {
  Deposit: 0,
  Withdraw: 1,
};

const Index = withNamespaces('translations')(({ t }) => {
  const dispatch = useDispatch();
  const { defaultAccount, listAccount } = useSelector((state) => state.socket);
  const { token } = useSelector((state) => state.client);
  const lang = useSelector((state) => state.client.setting.lang || 'vi');
  const [tab, setTab] = useState(TYPE.Deposit);
  const [refresh, setRefresh] = useState(false);
  const [availAccount, setAvailAccount] = useState();
  const history = useHistory();

  // curently, only derivative is allow for this func
  useEffect(() => {
    if (!!listAccount?.length > 0) {
      const drvAccount = listAccount.find(
        (x) =>
          x.subAcntClss == constants.subAccount.Derivative && x.subAcntStat == 1
      );

      if (drvAccount) {
        setAvailAccount(drvAccount);
        dispatch(setDefaultAccount(drvAccount));
      }
    }
  }, [listAccount]);

  const hasDrAccount =
    token?.custInfo && !!token.custInfo?.normal.length > 0
      ? token.custInfo?.normal.some(
          (x) =>
            x.subAcntStat == 1 &&
            x.subAcntClss == constants.subAccount.Derivative
        )
      : false;

  if (!hasDrAccount)
    return (
      <OpenSubAccountModal
        subAccountType={constants.subAccount.Derivative}
        closeModalhandle={() => history.push('/home/transaction')}
      />
    );
  else
    return (
      <div className="deposite-withdraw">
        <div className="deposite-withdraw-main">
          <div className="deposite-withdraw-main-header">
            <div className="deposite-withdraw-main-header__left">
              <span className="deposite-withdraw-main-tab cursor-pointer active">
                {defaultAccount?.label}
              </span>
            </div>
            <div className="deposite-withdraw-main-header__right"></div>
          </div>
          <div className="deposite-withdraw-main-body">
            <div className="dwm">
              <div className="dwm-main">
                <div className="dwm-main--left">
                  <div className="dwm-tabs">
                    <div
                      className={`dwm-tab text text--sm fw-500 cursor-pointer ${
                        tab == TYPE.Deposit ? 'active' : ''
                      }`}
                      onClick={() => setTab(TYPE.Deposit)}
                    >
                      {t('txt-label-deposit')}
                    </div>
                    <div
                      className={`dwm-tab text text--sm fw-500 cursor-pointer ${
                        tab == TYPE.Withdraw ? 'active' : ''
                      }`}
                      onClick={() => setTab(TYPE.Withdraw)}
                    >
                      {t('txt-label-withdrawal')}
                    </div>
                  </div>
                  {tab == TYPE.Deposit && (
                    <Deposit
                      type={TYPE.Deposit}
                      account={availAccount}
                      depositCallback={() => setRefresh(!refresh)}
                    />
                  )}
                  {tab == TYPE.Withdraw && (
                    <Deposit
                      type={TYPE.Withdraw}
                      account={availAccount}
                      depositCallback={() => setRefresh(!refresh)}
                    />
                  )}
                </div>

                <div className="dwm-main--right dwm-note">
                  <span
                    className="text text--md fw-500 r"
                    style={{ paddingLeft: '20px' }}
                  >
                    {t('txt-note')}
                  </span>
                  <ul>
                    {[
                      t('derivative.depWdrawNotes.1'),
                      t('derivative.depWdrawNotes.2'),
                      t('derivative.depWdrawNotes.3'),
                      t('derivative.depWdrawNotes.4'),
                      t('derivative.depWdrawNotes.5'),
                    ].map((note, i) => {
                      if (
                        (tab == TYPE.Deposit && (i == 0 || i == 4)) ||
                        (tab == TYPE.Withdraw && (i == 1 || i == 2 || i == 3))
                      )
                        return (
                          <li
                            key={i}
                            className="text text--sm fw-400 text--light3"
                          >
                            {note}
                          </li>
                        );
                    })}
                  </ul>
                </div>
              </div>
            </div>
            {availAccount && <History account={availAccount} key={refresh} />}
          </div>
        </div>
      </div>
    );
});

export const Deposit = withNamespaces('translations')(
  ({ t, type, account, depositCallback }) => {
    const { token, auth } = useSelector((state) => state.client);
    const [amount, setAmount] = useState();
    const [error, setError] = useState();
    const [totalAsset, setTotalAsset] = useState({});
    const [showConfirm, setShowConfirm] = useState(false);

    const dispatch = useDispatch();

    const getTotalAssetHandle = () => {
      if (token && account) {
        let params = {
          group: 'CORE',
          cmd: 'getDvxTotalAsset',
          user: token.user,
          session: token.session,
          channel: 'WTS',
          data: {
            cif: token.cif,
            acntNo: account?.acntNo,
            subAcntNo: account?.subAcntNo,
          },
        };

        const callback = (data) => {
          setTotalAsset(data);
        };
        dispatch(getDrvCommonRequest(params, callback, true));
      }
    };

    const submitHandle = useCallback(() => {
      if (token) {
        const params = {
          group: 'CORE',
          cmd: type == TYPE.Deposit ? 'depositeDrvtVm' : 'withdrawDrvtVm',
          user: token.user,
          session: token.session,
          channel: 'WTS',
          data: {
            cif: token.cif,
            acntNo: account?.acntNo,
            subAcntNo: account?.subAcntNo,
            amt: StringToInt(amount),
          },
        };

        const callback = () => {
          getTotalAssetHandle();
          toastHandle(
            t('txt-success'),
            t('derivative.depWdrawRequestSuccess', {
              action: `${
                type == TYPE.Deposit
                  ? t('derivative.depWdrawAction.deposit')
                  : t('derivative.depWdrawAction.withdraw')
              }`,
            })
          );
          // TODO: refresh history
          if (depositCallback && typeof depositCallback == 'function') {
            depositCallback();
          }
          setShowConfirm(false);
          setAmount('');
        };
        dispatch(getDrvCommonRequest2(params, callback, true));
      }
    }, [type, amount]);

    const toastHandle = (title, message) => {
      const toastMsg = {
        id: Math.random(),
        msg: message,
        time: new Date(),
        title: title,
      };

      dispatch(setToast(toastMsg));
    };

    const amountChangeHandle = (e) => {
      const value = e.target.value;
      setAmount(value);

      if (
        (StringToInt(value) > totalAsset?.acImMax && type == TYPE.Deposit) ||
        (StringToInt(value) > totalAsset?.widrAmtVsd && type == TYPE.Withdraw)
      ) {
        setError(
          t('derivative.quantityOverError', {
            action: `${
              type == TYPE.Deposit
                ? t('derivative.depWdrawAction.deposit').toLowerCase()
                : t('derivative.depWdrawAction.withdraw').toLowerCase()
            }`,
          })
        );
      } else setError();
    };

    useEffect(() => {
      if (token && account) getTotalAssetHandle();
    }, [token, account]);

    const preOrder = useMemo(() => {
      console.log('amount ===> ', amount);
      return {
        account: account,
        totalAsset: totalAsset,
        amount: amount,
        type: type,
        callback: submitHandle,
      };
    }, [totalAsset, amount, account, type]);

    return (
      <>
        <div className="dwm-form">
          <div className="dwm-form-row">
            <span className="text text--light3 text--sm fw-400">
              {t('txt-sub-account')}
            </span>
            <span className="text text--light text--sm fw-500">
              {account?.subAcntNo}
            </span>
          </div>
          <div className="dwm-form-row">
            <span className="text text--light3 text--sm fw-400">
              {t('txt-margin-money-at-VSD')}
            </span>
            <span className="text text--light text--sm fw-500">
              {type == TYPE.Deposit
                ? numberFormat(totalAsset?.imVsd, 0, 0)
                : numberFormat(totalAsset?.imVsd, 0, 0)}
            </span>
          </div>
          <div className="dwm-form-row">
            <span className="text text--light3 text--sm fw-400">
              {type == TYPE.Deposit
                ? t('derivative.marginAmountCanBeDeposit')
                : t('derivative.marginAmountCanBeWithdrawn')}
            </span>
            <span className="text text--sm fw-500 i text--value">
              {type == TYPE.Deposit
                ? numberFormat(totalAsset?.acImMax, 0, 0)
                : numberFormat(totalAsset?.widrAmtVsd, 0, 0)}
            </span>
          </div>
          <div className="dwm-form-row">
            <span className="text text--light3 text--sm fw-400">
              {type == TYPE.Deposit
                ? t('derivative.marginDepositAmount')
                : t('derivative.marginWithdrawalAmount')}
            </span>
            <div className="d-flex flex-column flex-1">
              <TextMask
                type="text"
                className={error ? 'input-error' : ''}
                maxLength={15}
                value={amount}
                mask={createNumberMask({
                  prefix: '',
                  allowNegative: false,
                })}
                placeholder={t('personal.nav.payment.inputAmount')}
                onFocus={(e) => e.currentTarget.select()}
                onChange={(e) => amountChangeHandle(e)}
              />
              {error && <span className="d fw-400 text--xs mt-2">{error}</span>}
            </div>
          </div>
          <div className="dwm-form-row">
            <span className="text text--light3 text--sm fw-400">
              {t('txt-label-transfer-fee')}
            </span>
            <span className="text text--light text--sm fw-500">
              {numberFormat(totalAsset?.tranFee, 0, 0)}
            </span>
          </div>

          <button
            className={`w-100 btn  ${error ? 'disabled' : ''} ${
              auth && auth.isVerified ? 'btn--primary' : 'btn--authen'
            }`}
            disabled={
              auth && auth.isVerified && (error || StringToInt(amount) == 0)
            }
            onClick={() => {
              if (auth && auth.isVerified) {
                if (!error && StringToInt(amount) != 0) setShowConfirm(true);
              } else dispatch(setAuth());
            }}
          >
            {type == TYPE.Deposit
              ? t('txt-label-deposit')
              : t('txt-label-withdrawal')}
          </button>
        </div>

        <ConfirmModal
          show={showConfirm}
          closeCallback={() => setShowConfirm(false)}
          preOrder={preOrder}
        />
      </>
    );
  }
);

const History = withNamespaces('translations')(({ t, account }) => {
  // TODO: filter
  // reflector DatetimePicker2 or create new good one

  const { token, auth } = useSelector((state) => state.client);
  const numDay = token && token['config'] ? token['config']['numDay'] : 30;

  const [type, setType] = useState({ label: 'txt-all', value: '' });
  const [fromDate, setFromDate] = useState(
    new Date(getCurrentDateMinusDays(numDay))
  );
  const [toDate, setToDate] = useState(new Date());
  const [data, setData] = useState([]);

  const lang = useSelector((state) => state.client.setting.lang || 'vi');
  const dispatch = useDispatch();

  // excute when click Filter button
  const getHistoryData = (fromDate, toDate, type) => {
    if (token) {
      const uuid = uuidv4();
      const params = {
        group: 'CORE',
        user: token.user,
        session: token.session,
        cmd: 'getListDrvtVm',
        rqId: uuid,
        channel: 'WTS',
        data: {
          acntNo: account?.acntNo,
          cif: token.cif,
          subAcntNo: account?.subAcntNo,
          langTp: lang,
          frDate: _convertformatDate2(moment(fromDate).format('DD/MM/YYYY')),
          toDate: _convertformatDate2(moment(toDate).format('DD/MM/YYYY')),
          depWdrawTp: type.value,
        },
      };

      const callback = (data) => {
        setData(data?.list);
      };
      dispatch(getDrvCommonRequest(params, callback, true));
    } else {
      dispatch(setLogin());
    }
  };

  const datePickerChangeCallback = (fDate, eDate) => {
    setFromDate(fDate);
    setToDate(eDate);
  };

  useEffect(() => {
    if (lang) getHistoryData(fromDate, toDate, type);
  }, [lang]);

  const columns = [
    {
      key: '_index',
      text: '#',
      className: 'text-center text--light3',
      align: 'center',
      sortable: true,
    },
    {
      key: 'subAcntNo',
      text: t('txt-sub-account'),
      className: 'text-center text--light3',
      align: 'center',
      sortable: true,
    },
    {
      key: 'aplcDate',
      text: t('txt-time'),
      className: 'text-center text--light3',
      align: 'center',
      sortable: true,
      cell: (record) => {
        return _formatDate2(record.aplcDate);
      },
    },
    {
      key: 'depWdrawTp',
      text: t('txt-type'),
      className: 'text-right fw-500 text--light',
      align: 'right',
      sortable: true,
    },
    {
      key: 'tranAmt',
      text: t('txt-label-tran-amount'),
      className: 'text-right text--light',
      align: 'right',
      cell: (record) => {
        return numberFormat(record.tranAmt, 0, '0');
      },
      sortable: true,
    },
    {
      key: 'tranFee',
      text: t('txt-label-fee'),
      className: 'text-right text--light',
      align: 'right',
      cell: (record) => {
        return numberFormat(record.tranFee, 0, '0');
      },
      sortable: true,
    },
    {
      key: 'vsdAprvProcStat',
      text: t('txt-status'),
      className: 'text-right text--light ',
      align: 'right',
      sortable: true,
    },
    {
      key: 'rmrk',
      text: t('txt-note'),
      className: 'text-left text--light text-sm fw-500',
      align: 'left',
    },
  ];

  const types = [
    { label: 'txt-all', value: '' },
    { label: 'derivative.depWdrawTp.1', value: '1' },
    { label: 'derivative.depWdrawTp.2', value: '2' },
    { label: 'derivative.depWdrawTp.4', value: '4' },
    { label: 'derivative.depWdrawTp.5', value: '5' },
    { label: 'derivative.depWdrawTp.6', value: '6' },
  ];

  return (
    <div className="dwm-history">
      <div className="dwm-history-header">
        <div className="dwm-history--left">
          <span className="text text--md text--light">
            {t('txt-label-history')}
          </span>
        </div>
        <div className="dwm-history--right deposite-withdraw-main__filters d-flex">
          <ReactSelect
            className="transaction-filters__select"
            classNamePrefix="filter-control-select"
            options={types}
            isSearchable={false}
            components={{
              IndicatorSeparator: () => null,
            }}
            value={type}
            onChange={(value) => setType(value)}
            getOptionLabel={(option) => t(option.label)}
            getOptionValue={(option) => option.value}
          />

          <DatePicker
            startDate={fromDate}
            endDate={toDate}
            callback={datePickerChangeCallback}
          />

          <button
            className="btn btn--primary"
            onClick={() => getHistoryData(fromDate, toDate, type)}
          >
            {t('bond.filter')}
          </button>
        </div>
      </div>
      <div className="dwm-history-body">
        <PerfectScrollBar>
          <PaginationTable
            columns={columns}
            title=""
            pageSize={9}
            hasPaging
            source={data}
          />
        </PerfectScrollBar>
      </div>
    </div>
  );
});

const ConfirmModal = withNamespaces('translations')(
  ({ t, show, closeCallback, preOrder }) => {
    const { currentTheme } = useSelector((state) => state.client);
    const { account, totalAsset, amount, type, callback } = preOrder;

    const closeHandle = () => {
      if (closeCallback && typeof closeCallback == 'function') {
        closeCallback();
      }
    };

    return (
      <Modal
        show={show}
        dialogClassName="wts-modal"
        backdropClassName="dwm-modal-backdrop"
        contentClassName={`${
          currentTheme.name || 'dark'
        } dwm-modal wts-modal-content wts-modal-content--sm3`}
        overlayClassName={`dwm-modal-backdrop`}
        onEscapeKeyDown={() => closeHandle()}
        centered
        onHide={() => closeHandle()}
        style={{ zIndex: 11119 }}
      >
        <div className="dwm-modal__header wts-modal__header">
          <span className="btn-icon">
            <span className="icon iNone" onClick={() => {}}></span>
          </span>
          <span className="text-title text--light text--md">
            {t('derivative.dwmconfirm', {
              action: `${
                type == TYPE.Deposit
                  ? t('derivative.depWdrawAction.deposit').toLowerCase()
                  : t('derivative.depWdrawAction.withdraw').toLowerCase()
              }`,
            })}
          </span>
          <span className="btn-icon">
            <span className="icon iClose" onClick={() => closeHandle()}></span>
          </span>
        </div>
        <div className="dwm-modal__body w-100 p-3">
          <div className="dwm-modal-form">
            <div className="dwm-modal-form-row--group gap-4">
              <div className="dwm-modal-form-row">
                <span className="text text--light3 text--sm fw-400">
                  {t('txt-sub-account')}
                </span>
                <span className="text text--light text--sm fw-500">
                  {account?.subAcntNo}
                </span>
              </div>
              <div className="dwm-modal-form-row">
                <span className="text text--light3 text--sm fw-400">
                  {t('txt-margin-money-at-VSD')}
                </span>
                <span className="text text--light text--sm fw-500">
                  {type == TYPE.Deposit
                    ? numberFormat(totalAsset?.imVsd, 0, 0)
                    : numberFormat(totalAsset?.imVsd, 0, 0)}
                </span>
              </div>
              <div className="dwm-modal-form-row">
                <span className="text text--light3 text--sm fw-400">
                  {type == TYPE.Deposit
                    ? t('derivative.marginDepositAmount')
                    : t('derivative.marginWithdrawalAmount')}
                </span>
                <span className="text text--sm fw-500 i">
                  {numberFormat(StringToInt(amount), 0, 0)}
                </span>
              </div>
            </div>
            <div className="dwm-modal-form-row">
              <span className="text text--light3 text--sm fw-400">
                {t('txt-label-transfer-fee')}
              </span>
              <span className="text text--light text--sm fw-500">
                {numberFormat(totalAsset?.tranFee, 0, 0)}
              </span>
            </div>
            <div className="dwm-modal-form-row w-100 gap-6">
              <button
                className="btn btn--primary"
                onClick={() => {
                  if (callback && typeof callback == 'function') callback();
                }}
              >
                {t('txt-confirm')}
              </button>
              <button
                className="btn btn--cancel"
                onClick={() => {
                  closeHandle();
                }}
              >
                {t('txt-cancel')}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
);

export default Index;
