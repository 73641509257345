import React, { useState, useEffect, useContext } from 'react';
import { withNamespaces } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import PaginationTable from '../../../components/PaginationTable';
import { marginListRequest } from '../../cash/actions';
import { v4 as uuidv4 } from 'uuid';
import { setAuth, setDblPrice, setLogin } from '../../client/actions';
import { getSymbolInfor, getTickerType, numberFormat } from '../../../util';
import PerfectScrollBar from 'react-perfect-scrollbar';
import { Mixpanel, MixConstants } from '../../../lib/mixpanel';

const Index = withNamespaces('translations')(({ t }) => {
  const { marginList } = useSelector((state) => state.cash);
  const { defaultAccount } = useSelector((state) => state.socket);
  const { token, auth } = useSelector((state) => state.client);
  const { cwList, allStock } = useSelector((state) => state.priceBoard);
  const lang = useSelector((state) => state.client.setting?.lang) || 'vi';

  const dispatch = useDispatch();

  const loadMarginListHandle = () => {
    if (!defaultAccount || !token) return;

    const uuid = uuidv4();

    const params = {
      group: 'CORE',
      user: token.user,
      session: token.session,
      cmd: 'GetMarginStockList',
      rqId: uuid,
      channel: 'WTS',
      data: {
        acntNo: defaultAccount.acntNo,
        subAcntNo: defaultAccount.subAcntNo,
        langTp: lang,
      },
    };

    dispatch(marginListRequest(params));
  };

  useEffect(() => {
    loadMarginListHandle();
  }, [lang]);

  useEffect(() => {
    if (defaultAccount) {
      loadMarginListHandle();
    }
  }, [defaultAccount]);

  const authenHandle = () => {
    dispatch(setAuth());
  };

  const actionHandle = (type, symbol) => {
    if (!token) {
      dispatch(setLogin());
      return false;
    }

    if (!auth || !auth.isVerified) {
      dispatch(setAuth());
      return false;
    }

    Mixpanel.order({
      Ticker: symbol,
      'Order Side': type,
      'Order Type': 'Normal Order',
      'Ticker Type': getTickerType(symbol, cwList),
      'Ticker Description': getSymbolInfor(symbol, allStock)?.name_vn,
      Location: MixConstants.LOCATIONS.MARGIN_LIST,
    });

    const newOrder = {
      symbol: symbol,
      side: type,
      price: '',
    };

    dispatch(setDblPrice(newOrder));
  };

  const columns = [
    {
      key: 'symbol',
      text: t('txt-stock'),
      className: 'text-center text--light',
      align: 'center',
      sortable: true,
    },
    {
      key: 'symbolNm',
      text: t('transaction.marginList.symbolName'),
      className: 'text-left text--light',
      align: 'center',
      sortable: true,
    },
    {
      key: 'marginRt',
      text: t('txt-max-margin-rate'),
      className: 'text-center text--light',
      align: 'center',
      sortable: true,
      cell: (record) => {
        return (
          <span>{numberFormat((1 - record.marginRt) * 100, 2, '0')}%</span>
        );
      },
    },
    {
      text: t('txt-actions'),
      className: 'text-center',
      align: 'center',
      cell: (record) => {
        return (
          <>
            {auth && auth.isVerified ? (
              <div className="table-actions">
                <button
                  className="btn btn--buy2 cursor-pointer"
                  onClick={() => actionHandle('BUY', record.symbol)}
                >
                  {t('txt-buy')}
                </button>
                <button
                  className="btn btn--sell2 cursor-pointer"
                  onClick={() => actionHandle('SELL', record.symbol)}
                >
                  {t('txt-sell')}
                </button>
              </div>
            ) : (
              <div className="table-actions">
                <button
                  className="btn btn--authen cursor-pointer"
                  onClick={() => actionHandle('BUY', record.symbol)}
                >
                  {t('txt-buy')}
                </button>
                <button
                  className="btn btn--authen cursor-pointer"
                  onClick={() => actionHandle('SELL', record.symbol)}
                >
                  {t('txt-sell')}
                </button>
              </div>
            )}
          </>
        );
      },
    },
  ];

  return (
    <div className="margin-list personal-assets card-panel-2">
      <div className="personal-assets-body card-panel-2-body pb-3">
        <PerfectScrollBar>
          <PaginationTable
            t
            title={t('transaction.marginList.name')}
            pageSize={5}
            columns={columns}
            source={marginList && marginList.list ? marginList.list : []}
            hasPaging
            hasSearch
            searchField="symbol"
            hasSort="true"
          />
        </PerfectScrollBar>
      </div>
    </div>
  );
});

export default Index;
