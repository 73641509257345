import React, { PureComponent, useRef } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { translate } from 'react-i18next';
import Cel from './DatagridCell';
import {
  numberFormat,
  colorFix,
  formatVolume10,
  getTickerType,
  getSymbolInfor,
  formatDate,
  _formatDate2,
  _processMapDataDv,
} from '../../util';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import {
  makeGetAuth,
  makeGetSymbolState,
  makeGetToken,
  makeGetCategorySelected,
} from '../../lib/selector';
import {
  setAuth,
  setDblPrice,
  setLogin,
} from '../../containers/client/actions';
import { MixConstants, Mixpanel } from '../../lib/mixpanel';
import { useParams } from 'react-router';
import { setDerivativeOrder } from '../../containers/derivative/actions';
import { TRADING_TYPE } from '../../util/constant';

class DatagridRowGroup2 extends PureComponent {
  _handleTradeSym = (sym = '', side = 'BUY', price = '') => {
    const { auth, token, allStock, cwList } = this.props;

    if (!token) {
      this.props.dispatch(setLogin());
      return false;
    }

    if (!auth || !auth.isVerified) {
      this.props.dispatch(setAuth());
      return false;
    }

    Mixpanel.order({
      Ticker: sym,
      'Order Side': side,
      'Order Type': 'Normal Order',
      'Ticker Type': getTickerType(sym, cwList),
      'Ticker Description': getSymbolInfor(sym, allStock)?.name_vn,
      Location: MixConstants.LOCATIONS.PRICE_BOARD,
    });

    const newOrder = {
      symbol: sym,
      side: side,
      price: price,
      orderTp: TRADING_TYPE.LO,
    };
    this.props.dispatch(setDerivativeOrder(newOrder));
  };

  render() {
    const { isPc, record, symbol, t, categorySelected, cwList } = this.props;
    const cl = colorFix(
      record.lastPrice,
      record.r,
      record.c,
      record.f,
      record.r
    );
    const clHigh = colorFix(
      record.highPrice,
      record.r,
      record.c,
      record.f,
      record.r
    );
    const clLow = colorFix(
      record.lowPrice,
      record.r,
      record.c,
      record.f,
      record.r
    );
    const clAve = colorFix(
      record.avePrice,
      record.r,
      record.c,
      record.f,
      record.r
    );

    if (!record) return null;
    return (
      <tr
        id={'d_' + record.sym + 'row'}
        // ref={this.props.forwardRef}
      >
        <td
          className={'text-left cursor-pointer ' + cl}
          id={'d_' + record.sym + 'sym'}
        >
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id={`tooltip-top`}>
                {symbol && symbol.name_vn}{' '}
                {symbol &&
                  symbol.post_to &&
                  `${
                    symbol.post_to == 'STO'
                      ? '- HOSE'
                      : symbol.post_to == 'STX'
                      ? '- HNX'
                      : symbol.post_to == 'UPX'
                      ? '- UPCOM'
                      : symbol.post_to
                  }`}
              </Tooltip>
            }
          >
            <a
              className="w-100 d-block fw-500"
              onClick={() => {
                const detail = (grpName) => {
                  if (grpName == 'OddLot') return t('txt-industry');
                  if (grpName == 'CPNGANH') return t('txt-industry');
                  if (grpName == 'CHUNGQUYEN') return t('txt-cw');
                  return grpName;
                };

                Mixpanel.viewTicketInfo({
                  Ticker: record.sym,
                  'Ticker Type': getTickerType(record.sym, cwList),
                  'Ticker Location': 'Home',
                  'Ticker Location Detail': detail(categorySelected?.groupName),
                });

                this.props.handleOpenModal(record.sym);
              }}
            >
              {record.sym} {record.flag && record.flag == 1 ? '*' : null}
            </a>
          </OverlayTrigger>
        </td>
        <td className={'text-right cursor-pointer'}>
          <span className="w-100 d-block fw-500">
            {_formatDate2(record.exprDt, '/', 'dmy')}
          </span>
        </td>
        <td
          className="text-right high-light r cursor-pointer fw-500"
          id={'d_' + record.sym + 'ref'}
          onDoubleClick={() =>
            this._handleTradeSym(record.sym, 'BUY', numberFormat(record.r, 1))
          }
        >
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>{t('lbl-double-click-to-order')}</Tooltip>}
          >
            <span>{numberFormat(record.r, 1)}</span>
          </OverlayTrigger>
        </td>
        <td
          className="text-right high-light c cursor-pointer fw-500"
          id={'d_' + record.sym + 'ceil'}
          onDoubleClick={() =>
            this._handleTradeSym(record.sym, 'BUY', numberFormat(record.c, 1))
          }
        >
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>{t('lbl-double-click-to-order')}</Tooltip>}
          >
            <span>{numberFormat(record.c, 1)}</span>
          </OverlayTrigger>
        </td>
        <td
          className="text-right high-light f cursor-pointer fw-500"
          id={'d_' + record.sym + 'floor'}
          onDoubleClick={() =>
            this._handleTradeSym(record.sym, 'BUY', numberFormat(record.f, 1))
          }
        >
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>{t('lbl-double-click-to-order')}</Tooltip>}
          >
            <span>{numberFormat(record.f, 1)}</span>
          </OverlayTrigger>
        </td>
        <td
          className={'text-right cursor-pointer'}
          id={'d_' + record.sym + 'bas'}
        >
          <span
            className={`w-100 d-block fw-500 ${
              record.bas > 0 ? 'i' : record.bas == 0 ? 'r' : 'd'
            }`}
          >
            {numberFormat(record.bas, 2)}
          </span>
        </td>
        <td
          className={'text-right cursor-pointer'}
          id={'d_' + record.sym + 'oi'}
        >
          <span className="w-100 d-block fw-500">
            {numberFormat(record.oi, 0)}
          </span>
        </td>

        {Array.from({ length: 3 }, (_, i) => {
          const index = 3 - i;
          return (
            <PriceVol
              key={i}
              record={record}
              index={index}
              direction="BUY"
              cl={cl}
            />
          );
        })}

        <td
          onDoubleClick={() =>
            this._handleTradeSym(
              record.sym,
              'BUY',
              numberFormat(record.lastPrice, 1)
            )
          }
          className={`text-right high-light cursor-pointer fw-500 ${
            record.cl || cl
          }`}
          id={`d_${record.sym}lastPrice`}
        >
          <span data-for={`d_pri_${record.sym}`}>
            {numberFormat(record.lastPrice, 1)}
          </span>
        </td>

        <td
          className={`text-right high-light ${record.cl || cl}`}
          id={`d_${record.sym}lastVolume`}
        >
          <span data-for={`d_lastVolume_${record.sym}`}>
            {numberFormat(record.lastVolume, 0, '')}
          </span>
        </td>

        <td className={'text-right high-light ' + (record.cl || cl)}>
          <span id={`d_${record.sym}${!isPc ? 'change' : 'changePc'}`}>
            {record.lastPrice == 0
              ? ''
              : !isPc
              ? numberFormat(record.change, 1)
              : numberFormat(record.changePc, 2) + '%'}
          </span>
        </td>

        {Array.from({ length: 3 }, (_, i) => {
          const index = i + 1;
          return (
            <PriceVol
              key={i}
              record={record}
              index={index}
              direction="SELL"
              cl={cl}
            />
          );
        })}

        <td className="text-right" id={'d_' + record.sym + 'lot'}>
          {numberFormat(record.lot)}
        </td>
        <td
          className={'text-right high-light fw-500 ' + clAve}
          id={'d_' + record.sym + 'aveP'}
        >
          {numberFormat(record.avePrice, 1)}
        </td>
        <td
          className={'text-right high-light fw-500 ' + clHigh}
          id={'d_' + record.sym + 'highP'}
        >
          {numberFormat(record.highPrice, 1)}
        </td>
        <td
          className={'text-right high-light fw-500 ' + clLow}
          id={'d_' + record.sym + 'lowP'}
        >
          {numberFormat(record.lowPrice, 1)}
        </td>
        <td className="text-right " id={'d_' + record.sym + 'fBVol'}>
          {numberFormat(record.fBVol)}
        </td>
        <td className="text-right " id={'d_' + record.sym + 'fSVol'}>
          {numberFormat(record.fSVolume)}
        </td>
      </tr>
    );
  }
}

const makeMapStateToProps = () => {
  const getSymbolState = makeGetSymbolState();
  const getAuth = makeGetAuth();
  const getToken = makeGetToken();

  const mapStateToProps = (state, props) => {
    return {
      symbol: getSymbolState(state, props),
      auth: getAuth(state),
      token: getToken(state),
      categorySelected: state.client?.categorySelected,
      allStock: state.priceBoard.allStock,
      cwList: state.priceBoard.cwList,
    };
  };
  return mapStateToProps;
};

export default connect(makeMapStateToProps)(
  translate('translations')(DatagridRowGroup2)
);

const PriceVol = ({ record, index, direction, cl }) => {
  const { auth, token } = useSelector((state) => state.client);
  const { allStock, cwList } = useSelector((state) => state.priceBoard);
  const ref = useRef();
  const dispatch = useDispatch();

  const tradeSymHandle = (sym = '', price = '') => {
    if (!token) {
      dispatch(setLogin());
      return false;
    }

    if (!auth || !auth.isVerified) {
      dispatch(setAuth());
      return false;
    }

    Mixpanel.order({
      Ticker: sym,
      'Order Side': direction,
      'Order Type': 'Normal Order',
      'Ticker Type': getTickerType(sym, cwList),
      'Ticker Description': getSymbolInfor(sym, allStock)?.name_vn,
      Location: MixConstants.LOCATIONS.PRICE_BOARD,
    });

    const newOrder = {
      symbol: sym,
      side: direction,
      price: price,
      orderTp: TRADING_TYPE.LO,
    };
    dispatch(setDerivativeOrder(newOrder));
  };

  const recordDirection = direction == 'BUY' ? 'gb' : 'gs';
  const priceDirection = direction == 'BUY' ? 'bP' : 'oP';
  const volDirection = direction == 'BUY' ? 'bV' : 'oV';
  /**
   * gb -> gs
   * bP -> oP
   * bV -> oV
   */

  return (
    <>
      <td
        onDoubleClick={() => {
          const _val = numberFormat(ref.current.innerHTML, 1);
          tradeSymHandle(record.sym, _val);
        }}
        className={`text-right cursor-pointer fw-500 ${
          record[`${recordDirection}${index}_p`] == 'ATO' ||
          record[`${recordDirection}${index}_p`] == 'ATC'
            ? ''
            : record[`${recordDirection}${index}_cl`] || cl
        }`}
        id={`d_${record.sym}${priceDirection}${index}`}
      >
        <span ref={ref} data-for={`d_${priceDirection}${index}_${record.sym}`}>
          {numberFormat(record[`${recordDirection}${index}_p`], 1)}
        </span>
      </td>

      <td
        className={`text-right ${
          record[`${recordDirection}${index}_p`] == 'ATO' ||
          record[`${recordDirection}${index}_p`] == 'ATC'
            ? ''
            : record[`${recordDirection}${index}_cl`] || cl
        }`}
        id={`d_${record.sym}${volDirection}${index}`}
      >
        <span data-for={`d_${volDirection}${index}_${record.sym}`}>
          {numberFormat(record[`${recordDirection}${index}_v`])}
        </span>
      </td>
    </>
  );
};
