import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import ReactSelect from 'react-select';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import moment from 'moment';
import DatePicker, { registerLocale } from 'react-datepicker';
import vi from 'date-fns/locale/vi';
import uk from 'date-fns/locale/uk';
import { Portal } from 'react-overlays';
import {
  numberFormat,
  _formatDate,
  _formatDate2,
  _diff2Date,
  getCurrentDateMinusDays,
} from '../../../util';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { withNamespaces } from 'react-i18next';

import {
  getBondCommonRequest,
  getBondDealListRequest,
  getBondDetail,
  setBondDetailModalRequest,
  setBondProductDetail,
  setDeal,
} from '../action';
import { setAuth, setToast } from '../../../containers/client/actions';
import BondDetail from './bondDetail.js';
import { bondConstants, constants } from '../../../util/constant';
import PerfectScrollBar from 'react-perfect-scrollbar';
import PaginationTable from '../../../components/PaginationTable';
import { Mixpanel } from '../../../lib/mixpanel';

registerLocale('vi', vi);
registerLocale('en', uk);
const CalendarContainer = ({ children }) => {
  const el = document.getElementById('calendar-portal');
  return <Portal container={el}>{children}</Portal>;
};

const DealList = withNamespaces('translations')(({ t }) => {
  const dispatch = useDispatch();
  const { bondDetailModalType } = useSelector((state) => state.bond);
  const { setting, token, auth } = useSelector((state) => state.client);

  const [modalSize, setModalSize] = useState('');
  const [VDeals, setVDeals] = useState([]);
  const [FDeals, setFDeals] = useState([]);
  const [GDeals, setGDeals] = useState([]);
  const [bondProducts, setBondProducts] = useState([]);
  const [choosenDeal, setChoosenDeal] = useState(null);

  const lang = setting?.lang || 'vi';
  const numDay = token && token['config'] ? token['config']['numDay'] : 30;

  const bondAccounts = token?.custInfo.normal.filter(
    (x) => x.isBondOTCTrade == 'Y'
  );

  const listStatus = [
    { key: '1', value: t('bond.dealList.statusValue.dealed'), color: 'i' },
    { key: '2', value: t('bond.dealList.statusValue.paid'), color: 'i' },
    { key: '3', value: t('bond.dealList.statusValue.leg1'), color: 'i' },
    { key: '4', value: t('bond.dealList.statusValue.leg2'), color: 'i' },
    {
      key: '5',
      value: t('bond.dealList.statusValue.done'),
      color: 'i',
    },
  ];

  const mapStatus = (statusCode) => {
    return listStatus.find((item) => item.key === statusCode);
  };

  const hanldeDealIncomeFlow = (deal) => {
    deal.dealStatNm = mapStatus(deal.dealStat).value;
    dispatch(setBondDetailModalRequest(bondConstants.modals.INCOME_FLOW));
    dispatch(setDeal(deal, lang));
  };

  const handleSellDeal = (product) => {
    if (auth && auth.isVerified) {
      // minHoldDayYN
      if (
        product?.prodTp == constants.bondProductType.Fix &&
        product?.minHoldDayYN == 'N'
      ) {
        toastHandle(t('bond.dealList.notableToSell'));
      } else {
        const productDetail = bondProducts?.find(
          (item) => item.productCode == product.prodCode
        );

        dispatch(
          setBondDetailModalRequest(
            bondConstants.modals.SELL_ORDER,
            product.subAcntNo
          )
        );
        dispatch(setBondProductDetail(productDetail));
        dispatch(getBondDetail(product.bondCode, lang));
        setChoosenDeal({
          dealId: product.dealId,
          dueDate: product.dueDate,
          availQty: product.availQty,
        });

        Mixpanel.bond.startOrder(
          product.bondCode,
          'Sell',
          product.productTp,
          'Deal List'
        );
      }
    } else {
      dispatch(setAuth());
    }
  };

  const getBondProducts = () => {
    const uuid = uuidv4();
    const params = {
      data: {
        invtAmt: '',
        proInvtYN: '',
        langTp: lang,
        rateSort: '',
        prodTp: '100', // ignore enableDisplayYN
      },
      group: 'CORE',
      cmd: 'getBondProductList',
      rqId: uuid,
      channel: 'WTS',
    };

    const callback = (data) => {
      setBondProducts(data);
    };
    dispatch(getBondCommonRequest(params, callback));
  };

  useEffect(() => {
    if (bondDetailModalType == 0 || bondDetailModalType == 5)
      setModalSize('wts-modal-content--sm');
    else setModalSize('wts-modal-content--xl2');
    if (!bondProducts) {
      dispatch(getBondProducts('', '', '', '', '', lang));
    }
  }, [bondDetailModalType]);

  useEffect(() => {
    if (lang) {
      const tDate = new Date(),
        fDate = getCurrentDateMinusDays(numDay);

      getDealHandle('', fDate, tDate, '', constants.bondProductType.Fix);
      getDealHandle('', fDate, tDate, '', constants.bondProductType.Var);
      getDealHandle('', fDate, tDate, '', constants.bondProductType.Growth);
      getBondProducts();
    }
  }, [lang]);

  const getDealHandle = (
    bondCode = '',
    from,
    to,
    subAcntNo,
    prdType = constants.bondProductType.Var
  ) => {
    if (!token) return;
    if (prdType == constants.bondProductType.Fix) setFDeals([]);
    if (prdType == constants.bondProductType.Var) setVDeals([]);
    if (prdType == constants.bondProductType.Growth) setGDeals([]);

    const _fromDate = _formatDate(moment(from).format('DD/MM/YYYY'));
    const _toDate = _formatDate(moment(to).format('DD/MM/YYYY'));
    const uuid = uuidv4();
    const params = {
      group: 'CORE',
      user: token.user,
      session: token.session,
      cmd: 'getBondDealList',
      rqId: uuid,
      channel: 'WTS',
      data: {
        cif: token.cif,
        acntNo: bondAccounts[0]?.acntNo,
        subAcntNo: subAcntNo,
        fromDate: _fromDate,
        toDate: _toDate,
        bndCode: bondCode,
        prodTp: prdType,
      },
    };

    const callback = (data) => {
      if (prdType == constants.bondProductType.Fix) setFDeals(data?.list);
      if (prdType == constants.bondProductType.Var) setVDeals(data?.list);
      if (prdType == constants.bondProductType.Growth) setGDeals(data?.list);
    };

    dispatch(getBondDealListRequest(params, callback));

    // missing filter by sub account
    Mixpanel.bond.filter(
      '',
      '',
      '',
      '',
      '',
      bondCode,
      _fromDate,
      _toDate,
      prdType,
      ''
    );
  };

  const colgroup = (
    <colgroup>
      <col width="7.5%"></col> {/** Deal ID **/}
      <col width="6.5%"></col> {/** Total account **/}
      <col width="8.5%"></col> {/** Product code **/}
      <col width="13.0%"></col> {/** Bond Name **/}
      <col width="6.0%"></col> {/** Trade date **/}
      <col width="6.0%"></col> {/** Maturity date **/}
      <col width="4.5%"></col> {/** Rate **/}
      <col width="4.5%"></col> {/** Quantity **/}
      <col width="5%"></col> {/** Price **/}
      <col width="6.5%"></col> {/** Total value **/}
      <col width="4%"></col> {/** Sold Quantity **/}
      <col width="5%"></col> {/** Sold Av. Price **/}
      <col width="6%"></col> {/** Total Sell Value **/}
      <col width="5%"></col> {/** Tax **/}
      <col width="3%"></col> {/** Fee **/}
      <col width="100px"></col> {/** Status **/}
      <col width="60px"></col> {/** Actions **/}
    </colgroup>
  );

  const columns = [
    {
      key: 'subAcntNo',
      text: t('bond.tableLabel.subAccount'),
      className: 'text-center position-relative text--light fw-500',
      sortable: true,
    },
    {
      key: 'dealId',
      text: t('bond.tableLabel.dealID'),
      className:
        'text-center position-relative text--light3 fw-500 cursor-pointer',
      cell: (record) => {
        return (
          <>
            {
              <OverlayTrigger
                trigger={['hover', 'focus']}
                placement="top-start"
                overlay={
                  <Tooltip style={{ 'z-index': '99999' }}>
                    {t('bond.dealList.tooltip')}
                  </Tooltip>
                }
              >
                <span onClick={() => hanldeDealIncomeFlow(record)}>
                  {record.dealId}
                </span>
              </OverlayTrigger>
            }
          </>
        );
      },
      sortable: true,
    },
    {
      key: 'bondCode',
      text: t('bond.tableLabel.bondCode'),
      className: 'text-center position-relative text--light fw-500',
      sortable: true,
    },
    {
      key: 'issuerNm',
      text: t('bond.issuer'),
      className: 'text-left position-relative text--light fw-500',
      sortable: true,
      align: 'left',
    },
    {
      key: 'tranDate',
      className: 'text-center text--light3 position-relative',
      text: t('bond.tableLabel.tradeDate'),
      cell: (record) => {
        return _formatDate2(record.tranDate);
      },
      sortable: true,
    },
    {
      key: 'dueDate',
      text: t('bond.dealList.label.dueDate'),
      className: 'text-center text--light3 position-relative',
      cell: (record) => {
        return _formatDate2(record.dueDate);
      },
      sortable: true,
    },
    {
      key: 'cpnrt',
      text: t('bond.dealList.label.ratePerYear'),
      className: 'text-center position-relative r fw-500',
      align: 'center',
      cell: (record) => {
        return numberFormat(record.cpnrt, 2, '0') + '%';
      },
      sortable: true,
    },
    {
      key: 'dlQty',
      className: 'text-right position-relative text--light',
      text: t('bond.tableLabel.quantity'),
      sortable: true,
      align: 'right',
    },
    {
      key: 'buyPrc',
      text: t('bond.tableLabel.price'),
      className: 'text-right position-relative i fw-500',
      align: 'right',
      cell: (record) => {
        return numberFormat(record.buyPrc, 0, '0');
      },
      sortable: true,
    },
    {
      key: 'buyAmt',
      text: t('bond.tableLabel.totalValue.includedFee'),
      className: 'text-right position-relative fw-500 text--light',
      align: 'right',
      cell: (record) => {
        return numberFormat(record.buyAmt + record.buyFee, 0, '0');
      },
      sortable: true,
    },
    {
      align: 'right',
      key: 'sellQty',
      text: t('bond.tableLabel.soldQuantity'),
      className: 'text-right position-relative text--light',
      cell: (record) => {
        return numberFormat(record.sellQty, 0, '0');
      },
      sortable: true,
    },
    {
      key: 'sellPrc',
      text: t('bond.tableLabel.sellPrc'),
      className: 'text-right position-relative d fw-500',
      cell: (record) => {
        return numberFormat(record.sellPrc, 0, '0');
      },
      sortable: true,
    },
    {
      key: 'sellAmt',
      text: t('bond.tableLabel.sellAmt'),
      className: 'text-right position-relative text--light fw-500',
      cell: (record) => {
        return numberFormat(record.sellAmt, 0, '0');
      },
      sortable: true,
    },
    {
      align: 'right',
      key: 'tax',
      text: t('bond.tableLabel.tax'),
      className: 'text-right text--light3 position-relative fw-500',
      cell: (record) => {
        return numberFormat(record.tax, 0, '0');
      },
      sortable: true,
    },
    {
      align: 'right',
      key: 'sellFee',
      text: t('bond.tableLabel.fee'),
      className: 'text-right text--light3 position-relative fw-500',
      cell: (record) => {
        return numberFormat(record.sellFee, 0, '0');
      },
      sortable: true,
    },
    {
      key: 'dealStat',
      align: 'right',
      text: t('bond.tableLabel.status'),
      className: (record) => {
        if (!record.dealStat) return;
        return 'text-right ' + mapStatus(record.dealStat).color;
      },
      cell: (record) => {
        return mapStatus(record.dealStat).value;
      },
      sortable: true,
    },
    {
      text: t('bond.tableLabel.actions'),
      className: 'text-center ',
      cell: (record) => {
        return (
          record.selAblYN == 'Y' && (
            <>
              <span
                className="btn btn--sell2"
                onClick={() => handleSellDeal(record)}
              >
                {t('txt-sell')}
              </span>
            </>
          )
        );
      },
    },
  ];

  const vColumns = [...columns].map((item) => {
    if (item.key == 'dueDate') {
      return {
        ...item,
        text: `${t('bond.dueDate')}/${t('txt-label-call-date')}`,
      };
    }
    return item;
  });

  const refreshHandle = useCallback((prdType) => {
    const tDate = new Date(),
      fDate = getCurrentDateMinusDays(numDay);
    getDealHandle('', fDate, tDate, '', prdType);
    Mixpanel.bond.resetFilter('Deal List');
  }, []);

  const toastHandle = (msg, title = t('txt-notice')) => {
    const toastMsg = {
      id: Math.random(),
      msg: msg,
      title: title,
    };
    dispatch(setToast(toastMsg));
  };

  return (
    <div className="bond-deal-list">
      <div className="bond-deal-list__body bond-layout__body">
        <PerfectScrollBar>
          <Deal
            colgroup={colgroup}
            columns={columns}
            data={FDeals}
            title="PineB Fix"
            filterHandler={getDealHandle}
            prdType={constants.bondProductType.Fix}
            refreshHandle={() => refreshHandle(constants.bondProductType.Fix)}
          />

          <Deal
            colgroup={colgroup}
            columns={vColumns}
            data={VDeals}
            title="PineB Flex"
            classname="mt-3"
            filterHandler={getDealHandle}
            prdType={constants.bondProductType.Var}
            refreshHandle={() => refreshHandle(constants.bondProductType.Var)}
          />

          <Deal
            colgroup={colgroup}
            columns={vColumns}
            data={GDeals}
            title="PineB Growth"
            classname="mt-3"
            filterHandler={getDealHandle}
            prdType={constants.bondProductType.Growth}
            refreshHandle={() =>
              refreshHandle(constants.bondProductType.Growth)
            }
          />
          <div style={{ height: '50px' }}></div>
        </PerfectScrollBar>
      </div>

      <BondDetail
        modalSize={modalSize}
        closeHandle={() =>
          dispatch(setBondDetailModalRequest(bondConstants.modals.CLOSE))
        }
        resizeModal={setModalSize}
        defaultDeal={choosenDeal}
      />
    </div>
  );
});

const Deal = withNamespaces('translations')(
  ({
    t,
    colgroup,
    columns,
    title,
    data,
    filterHandler,
    classname,
    prdType,
    refreshHandle,
  }) => {
    return (
      <div className={classname}>
        <span className="text text--light text--md">{title}</span>
        <DealFilter
          callback={filterHandler}
          prdType={prdType}
          refreshHandle={refreshHandle}
        />
        <div className="show-data">
          {/* <ScrollTable colgroup={colgroup} columns={columns} source={data} /> */}
          <PaginationTable
            pageSize={5}
            columns={columns}
            source={data}
            title=""
            hasPaging
          />
        </div>
      </div>
    );
  }
);

const DealFilter = withNamespaces('translations')(
  ({ t, callback, refreshHandle, prdType }) => {
    const { token, auth, setting } = useSelector((state) => state.client);
    const [bondCode, setBondCode] = useState();
    const lang = setting?.lang || 'vi';

    const numDay = token && token['config'] ? token['config']['numDay'] : 30;
    const [from, setFrom] = useState(getCurrentDateMinusDays(numDay));
    const [to, setTo] = useState(new Date());

    const bondAccounts = token?.custInfo.normal.filter(
      (x) => x.isBondOTCTrade == 'Y'
    );

    const listSelectSubAccount = bondAccounts
      ? [{ subAcntNo: 'txt-all', key: 0 }, ...bondAccounts]
      : [{ subAcntNo: 'txt-all', key: 0 }];

    const [account, setAccount] = useState(
      listSelectSubAccount && listSelectSubAccount.length > 0
        ? listSelectSubAccount[0]
        : {}
    );

    const handleFilter = () => {
      if (callback && typeof callback == 'function') {
        callback(
          bondCode,
          from,
          to,
          account.key == 0 ? '' : account?.subAcntNo,
          prdType
        );
      }
    };

    return (
      <div className="bond-layout__filters">
        <div className="bond-layout__filter">
          <label>{t('bond.tableLabel.bondCode')}</label>
          <div className="input-text-search-outer">
            <input
              className="input-text-search"
              placeholder={t('bond.tableLabel.inputCode')}
              value={bondCode}
              onChange={(e) => setBondCode(e.target.value)}
            />
          </div>
        </div>
        <div className="bond-layout__filter">
          <label>{t('bond.dealList.label.from')}</label>
          <div className="filter-control-calendar">
            <DatePicker
              name="from"
              selected={from}
              onChange={(date) => setFrom(date)}
              dateFormat="dd/MM/yyyy"
              popperContainer={CalendarContainer}
              locale={lang}
            />
            <span className="icon iCalendar"></span>
          </div>
        </div>
        <div className="bond-layout__filter">
          <label>{t('bond.dealList.label.to')}</label>
          <div className="filter-control-calendar">
            <DatePicker
              name="to"
              selected={to}
              onChange={(date) => setTo(date)}
              dateFormat="dd/MM/yyyy"
              popperContainer={CalendarContainer}
              locale={lang}
            />
            <span className="icon iCalendar"></span>
          </div>
        </div>
        <div className="bond-layout__filter">
          <label>{t('txt-sub-account')}</label>
          <ReactSelect
            defaultValue={account}
            className="filter-control-select"
            classNamePrefix="filter-control-select"
            options={listSelectSubAccount}
            getOptionLabel={(option) => t(option.subAcntNo)}
            getOptionValue={(option) => option.key}
            value={account}
            onChange={(value) => setAccount(value)}
            isSearchable={false}
            components={{
              IndicatorSeparator: () => null,
            }}
          />
        </div>
        <div className="bond-layout__filter button-group">
          <div onClick={() => handleFilter()} className="iBtn iBtn--primary">
            <span>{t('bond.dealList.label.filter')}</span>
          </div>
          <span
            onClick={() => {
              if (refreshHandle && typeof refreshHandle == 'function')
                refreshHandle();

              setBondCode('');
              setFrom(getCurrentDateMinusDays(numDay));
              setTo(new Date());
              setAccount(
                listSelectSubAccount && listSelectSubAccount.length > 0
                  ? listSelectSubAccount[0]
                  : {}
              );
            }}
            className="iBtn reload-button iBtn--secondary"
          >
            <span className="icon iRefresh"></span>
          </span>
        </div>
      </div>
    );
  }
);

export default DealList;
