import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import vi from 'date-fns/locale/vi';
import uk from 'date-fns/locale/uk';
import { Portal } from 'react-overlays';
import {
  stringToDate,
  numberFormat,
  _formatDate,
  _formatDate2,
  _diff2Date,
  formatDate,
} from '../../../util';
import { v4 as uuidv4 } from 'uuid';
import { withNamespaces, translate } from 'react-i18next';

import { getBondPortfolioRequest } from '../action';
import PaginationTable from '../../../components/PaginationTable';
import { debounce } from 'lodash';
import { setToast } from '../../../containers/client/actions';
import ScrollTable from '../../../components/ScrollTable.js';
import { useHistory } from 'react-router';
import { getBondListTpType } from '../utils';

const Portfolio = withNamespaces('translations')(({ t }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { token, auth } = useSelector((state) => state.client);
  const bondAccounts = token?.custInfo.normal.filter(
    (x) => x.isBondOTCTrade == 'Y'
  );

  const account =
    bondAccounts && bondAccounts.length > 0 ? bondAccounts[0] : {};

  const listBondPortfolio = useSelector(
    (state) => state.bond.listBondPortfolioList
  );

  const _listBondPortfolio = listBondPortfolio?.filter(
    (item) => item.totalQty != 0
  );

  const getBondPortfolioList = () => {
    if (!token) return;
    const uuid = uuidv4();
    const params = {
      group: 'CORE',
      user: token.user,
      session: token.session,
      cmd: 'getBondPortfolio',
      rqId: uuid,
      channel: 'WTS',
      data: {
        cif: token.cif,
        acntNo: account.acntNo,
      },
    };
    dispatch(getBondPortfolioRequest(params));
  };

  useEffect(() => {
    getBondPortfolioList();
  }, []);

  useEffect(() => {
    if (!token) {
      history.push('/');
    }
  }, [token]);

  const columns = [
    {
      key: 'subAcntNo',
      text: t('bond.portfolio.tableLabel.subAccount'),
      className: 'text-center position-relative text--light fw-500',
      sortable: true,
    },

    {
      key: ['bondName', 'bondCode'],
      text: [
        t('bond.portfolio.tableLabel.bondName'),
        t('bond.portfolio.tableLabel.bondCode'),
      ],

      className: 'text-left position-relative text--light fw-500',
      sortable: true,
    },

    {
      key: 'listTp',
      text: t('bond.portfolio.tableLabel.bondType'),
      className: 'text-center position-relative fw-500',
      cell: (record) => {
        return t(getBondListTpType(record?.listTp));
      },
      sortable: true,
    },

    {
      key: 'balQty',
      text: t('bond.portfolio.tableLabel.availableQuantity'),
      className: 'text-right position-relative i',
      cell: (record) => {
        return numberFormat(record.balQty, 0, '0');
      },
      sortable: true,
    },

    {
      key: 'rcvBondQty',
      text: t('bond.portfolio.tableLabel.receivingQuantity'),
      className: 'text-right position-relative r',
      cell: (record) => {
        return numberFormat(record.rcvBondQty, 0, '0');
      },
      sortable: true,
    },
    {
      key: 'totalQty',
      text: t('bond.portfolio.tableLabel.totalQuantity'),
      className: 'text-right position-relative text--light fw-500',
      cell: (record) => {
        return numberFormat(record.totalQty, 0, '0');
      },
      sortable: true,
    },
    {
      key: 'investedAmount',
      text: t('bond.portfolio.tableLabel.buyValueTotal'),
      className: 'text-right position-relative text--light fw-500',
      cell: (record) => {
        return numberFormat(record.investedAmount, 0, '0');
      },
      sortable: true,
    },
    ``, // {
    //   key: 'currentAmount',
    //   text: t('bond.portfolio.tableLabel.totalNow'),
    //   className: 'text-right position-relative text--light fw-500',
    //   cell: (record) => {
    //     return numberFormat(record.currentAmount, 0, '0');
    //   },
    //   sortable: true,
    // },
  ];

  return (
    <div className="bond-portfolio">
      <div className="bond-portfolio__body bond-layout__body">
        <div className="show-data">
          <ScrollTable columns={columns} source={_listBondPortfolio} />
        </div>
      </div>
    </div>
  );
});

export default Portfolio;
