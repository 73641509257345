import React, { PureComponent } from 'react';
import { colorFix, formatVolume10, numberFormat } from '../../util';

class DatagridCell extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      txtCl: '',
      bgCl: '',
      val: '',
      valOriginal: '',
      txtClAfter: '',
    };

    this.timer = null;
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    let txtCl, val, valOriginal, txtClAfter;
    const { data, type } = this.props;
    switch (type) {
      case 'lastPrice':
        txtCl =
          data.cl || colorFix(data.lastPrice, data.r, data.c, data.f, data.r);
        txtClAfter = txtCl;
        val = numberFormat(data.lastPrice, 2);
        valOriginal = data.lastPrice;
        this.setState({ txtCl, val, valOriginal, txtClAfter });
        break;
      case 'lastVolume':
        txtCl =
          data.cl || colorFix(data.lastPrice, data.r, data.c, data.f, data.r);
        txtClAfter = txtCl;
        val = formatVolume10(data.lastVolume);
        this.setState({ txtCl, val, txtClAfter });
        break;
      case 'bP1':
        txtCl = data.bVC1;
        txtClAfter = txtCl;
        val = numberFormat(data.bP1, 2);
        valOriginal = data.bP1;
        this.setState({ txtCl, val, valOriginal, txtClAfter });
        break;
      case 'bV1':
        txtCl = data.bVC1;
        txtClAfter = data.bCl1;
        val = formatVolume10(data.bV1);
        this.setState({ txtCl, val, txtClAfter });
        break;
      case 'bP2':
        txtCl = data.bVC2;
        txtClAfter = txtCl;
        val = numberFormat(data.bP2, 2);
        valOriginal = data.bP2;
        this.setState({ txtCl, val, valOriginal, txtClAfter });
        break;
      case 'bV2':
        txtCl = data.bVC2;
        txtClAfter = data.bCl2;
        val = formatVolume10(data.bV2);
        this.setState({ txtCl, val, txtClAfter });
        break;
      case 'bP3':
        txtCl = data.bVC3;
        txtClAfter = txtCl;
        val = numberFormat(data.bP3, 2);
        valOriginal = data.bP3;
        this.setState({ txtCl, val, valOriginal, txtClAfter });
        break;
      case 'bV3':
        txtCl = data.bVC3;
        txtClAfter = data.bCl3;
        val = formatVolume10(data.bV3);
        this.setState({ txtCl, val, txtClAfter });
        break;
      case 'oP1':
        txtCl = data.oVC1;
        txtClAfter = txtCl;
        val = numberFormat(data.oP1, 2);
        valOriginal = data.oP1;
        this.setState({ txtCl, val, valOriginal, txtClAfter });
        break;
      case 'oV1':
        txtCl = data.oVC1;
        txtClAfter = data.oCl1;
        val = formatVolume10(data.oV1);
        this.setState({ txtCl, val, txtClAfter });
        break;
      case 'oP2':
        txtCl = data.oVC2;
        txtClAfter = txtCl;
        val = numberFormat(data.oP2, 2);
        valOriginal = data.oP2;
        this.setState({ txtCl, val, valOriginal, txtClAfter });
        break;
      case 'oV2':
        txtCl = data.oVC2;
        txtClAfter = data.oCl2;
        val = formatVolume10(data.oV2);
        this.setState({ txtCl, val, txtClAfter });
        break;
      case 'oP3':
        txtCl = data.oVC3;
        txtClAfter = txtCl;
        val = numberFormat(data.oP3, 2);
        valOriginal = data.oP3;
        this.setState({ txtCl, val, valOriginal, txtClAfter });
        break;
      case 'oV3':
        txtCl = data.oVC3;
        txtClAfter = data.oCl3;
        val = formatVolume10(data.oV3);
        this.setState({ txtCl, val, txtClAfter });
        break;
      default: {
        break;
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    return;
    let bgCl, txtCl, val, valOriginal, txtClAfter;
    const { data } = nextProps;

    switch (this.props.type) {
      case 'lastPrice':
        // console.log(this.props.data.lastPrice !== nextProps.data.lastPrice)
        if (this.props.data.lastPrice !== data.lastPrice) {
          bgCl = 'active';
          txtCl =
            data.cl || colorFix(data.lastPrice, data.r, data.c, data.f, data.r);
          txtClAfter = txtCl;
          val = numberFormat(data.lastPrice, 2);
          valOriginal = data.lastPrice;
          if (this._isMounted) {
            this.setState({ bgCl, txtCl, val, valOriginal, txtClAfter });
            this.changeBackground();
          }
        }
        break;
      case 'lastVolume': {
        if (this.props.data.lastVolume !== data.lastVolume) {
          bgCl = 'active';
          txtCl =
            data.cl || colorFix(data.lastPrice, data.r, data.c, data.f, data.r);
          txtClAfter = txtCl;
          val = formatVolume10(data.lastVolume);
          if (this._isMounted) {
            this.setState({ bgCl, txtCl, val, txtClAfter });
            this.changeBackground();
          }
        }
        break;
      }
      case 'bP1': {
        if (this.props.data.bP1 !== data.bP1) {
          bgCl = 'active';
          txtCl = data.bVC1;
          txtClAfter = txtCl;
          val = numberFormat(data.bP1, 2);
          valOriginal = data.bP1;
          if (this._isMounted) {
            this.setState({ bgCl, txtCl, val, valOriginal, txtClAfter });
            this.changeBackground();
          }
        }
        break;
      }
      case 'bV1': {
        if (this.props.data.bV1 !== data.bV1) {
          bgCl = 'active';
          txtCl = data.bVC1;
          txtClAfter = data.bCl1;
          val = formatVolume10(data.bV1);
          if (this._isMounted) {
            this.setState({ bgCl, txtCl, val, txtClAfter });
            this.changeBackground();
          }
        }
        break;
      }
      case 'bP2': {
        if (this.props.data.bP2 !== data.bP2) {
          bgCl = 'active';
          txtCl = data.bVC2;
          txtClAfter = txtCl;
          val = numberFormat(data.bP2, 2);
          valOriginal = data.bP2;
          if (this._isMounted) {
            this.setState({ bgCl, txtCl, val, valOriginal, txtClAfter });
            this.changeBackground();
          }
        }
        break;
      }
      case 'bV2': {
        if (this.props.data.bV2 !== data.bV2) {
          bgCl = 'active';
          txtCl = data.bVC2;
          txtClAfter = data.bCl2;
          val = formatVolume10(data.bV2);
          if (this._isMounted) {
            this.setState({ bgCl, txtCl, val, txtClAfter });
            this.changeBackground();
          }
        }
        break;
      }
      case 'bP3': {
        if (this.props.data.bP3 !== data.bP3) {
          bgCl = 'active';
          txtCl = data.bVC3;
          txtClAfter = txtCl;
          val = numberFormat(data.bP3, 2);
          valOriginal = data.bP3;
          if (this._isMounted) {
            this.setState({ bgCl, txtCl, val, valOriginal, txtClAfter });
            this.changeBackground();
          }
        }
        break;
      }
      case 'bV3': {
        if (this.props.data.bV3 !== data.bV3) {
          bgCl = 'active';
          txtCl = data.bVC3;
          txtClAfter = data.bCl3;
          val = formatVolume10(data.bV3);
          if (this._isMounted) {
            this.setState({ bgCl, txtCl, val, txtClAfter });
            this.changeBackground();
          }
        }
        break;
      }
      case 'oP1': {
        if (this.props.data.oP1 !== data.oP1) {
          bgCl = 'active';
          txtCl = data.oVC1;
          txtClAfter = txtCl;
          val = numberFormat(data.oP1, 2);
          valOriginal = data.oP1;
          if (this._isMounted) {
            this.setState({ bgCl, txtCl, val, valOriginal, txtClAfter });
            this.changeBackground();
          }
        }
        break;
      }
      case 'oV1': {
        if (this.props.data.oV1 !== data.oV1) {
          bgCl = 'active';
          txtCl = data.oVC1;
          txtClAfter = data.oCl1;
          val = formatVolume10(data.oV1);
          if (this._isMounted) {
            this.setState({ bgCl, txtCl, val, txtClAfter });
            this.changeBackground();
          }
        }
        break;
      }
      case 'oP2': {
        if (this.props.data.oP2 !== data.oP2) {
          bgCl = 'active';
          txtCl = data.oVC2;
          txtClAfter = txtCl;
          val = numberFormat(data.oP2, 2);
          valOriginal = data.oP2;
          if (this._isMounted) {
            this.setState({ bgCl, txtCl, val, valOriginal, txtClAfter });
            this.changeBackground();
          }
        }
        break;
      }
      case 'oV2': {
        if (this.props.data.oV2 !== data.oV2) {
          bgCl = 'active';
          txtCl = data.oVC2;
          txtClAfter = data.oCl2;
          val = formatVolume10(data.oV2);
          if (this._isMounted) {
            this.setState({ bgCl, txtCl, val, txtClAfter });
            this.changeBackground();
          }
        }
        break;
      }
      case 'oP3': {
        if (this.props.data.oP3 !== data.oP3) {
          bgCl = 'active';
          txtCl = data.oVC3;
          txtClAfter = txtCl;
          val = numberFormat(data.oP3, 2);
          valOriginal = data.oP3;
          if (this._isMounted) {
            this.setState({ bgCl, txtCl, val, valOriginal, txtClAfter });
            this.changeBackground();
          }
        }
        break;
      }
      case 'oV3': {
        if (this.props.data.oV3 !== data.oV3) {
          bgCl = 'active';
          txtCl = data.oVC3;
          txtClAfter = data.oCl3;
          val = formatVolume10(data.oV3);
          if (this._isMounted) {
            this.setState({ bgCl, txtCl, val, txtClAfter });
            this.changeBackground();
          }
        }
        break;
      }
      default: {
        break;
      }
    }
  }

  changeBackground = () => {
    this.timer = setTimeout(() => {
      if (this._isMounted)
        this.setState({ bgCl: '', txtCl: this.state.txtClAfter });
    }, 500);
  };

  componentWillUnmount() {
    this._isMounted = false;
    if (this.timer) clearTimeout(this.timer);
  }

  render() {
    const { bgCl, txtCl, val, valOriginal } = this.state;
    const { type, sym, doubleClick, prefixId } = this.props;
    return (
      <td
        onDoubleClick={() => {
          if (
            !doubleClick ||
            !val ||
            ['bP3', 'bP2', 'bP1', 'lastPrice', 'oP1', 'oP2', 'oP3'].indexOf(
              type
            ) < 0
          )
            return null;

          const _val = this.ref.innerHTML;
          return doubleClick(sym, type.startsWith('o') ? 'SELL' : 'BUY', _val);
        }}
        className={this.props.className + ' ' + txtCl + ' ' + bgCl}
        id={!prefixId ? sym + type : prefixId + sym + type}
      >
        <span
          ref={(r) => (this.ref = r)}
          data-for={
            !prefixId
              ? (type === 'lastPrice' ? 'pri' : type) + '_' + sym
              : prefixId + (type === 'lastPrice' ? 'pri' : type) + '_' + sym
          }
        >
          {val}
        </span>
      </td>
    );
  }
}

export default DatagridCell;
