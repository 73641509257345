import React, { useEffect, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import OTPInput from '../../modal/auth/layout/OTPInput';
import { v4 as uuidv4 } from 'uuid';
import MaskInput from './MaskInput';
import { useSelector, useDispatch } from 'react-redux';
import { makeGetToken } from '../../../lib/selector';
import { generateAuthRequest, unlockResendOtp } from '../../modal/auth/actions';
import Countdown from './Countdown';
import Base64 from 'crypto-js/enc-base64';
import sha256 from 'crypto-js/sha256';
import ResendButton from './ResendButton';

const numInputs = 3;
const Matrix = withNamespaces('translations')(
  ({ t, value = '', onChange, tempToken }) => {
    const dispatch = useDispatch();
    const [otp, setOtp] = useState(value);
    const token = useSelector(makeGetToken());
    const { generateAuth, isLockResendOtp } = useSelector(
      (state) => state.authen
    );

    useEffect(() => {
      generateAuthHandle();
    }, []);

    useEffect(() => {
      if (otp.length === numInputs) {
        const hashOTP = sha256(otp);
        const _base64HashOTP = Base64.stringify(hashOTP);
        onChange(_base64HashOTP);
      } else {
        onChange('');
      }
    }, [otp]);

    const generateAuthHandle = () => {
      const _token = tempToken || token;
      if (_token) {
        const uuid = uuidv4();
        const resData = {
          group: 'CORE',
          user: _token.user,
          session: _token.session,
          cmd: 'generateAUTH',
          rqId: uuid,
          channel: 'WTS',
          data: {
            acntNo: _token.acntNo,
            subAcntNo: _token.subAcntNo,
            trdType: '2', // 1:authentication for trading session, 2: authentication for transfer cash. expired after 60 seconds.
            authType: 2 + '',
            positionNo: '3',
          },
        };
        dispatch(generateAuthRequest(resData));
      }
    };
    return (
      <React.Fragment>
        <span>{t('account.authen.matrixDesc')}</span>
        <div className="authen-input">
          <OTPInput
            value={otp}
            numInputs={numInputs}
            shouldAutoFocus
            containerStyle={{
              display: 'flex',
              alignItems: 'center',
              gap: 8,
            }}
            onChange={(value) => {
              setOtp(value);
            }}
            skipDefaultStyles
            renderInput={({ ref, cell, ...props }) => (
              <span key={cell} className="matrix-section">
                <span className="matrix-label">
                  {generateAuth[`cell_${cell + 1}`]}
                </span>
                <MaskInput {...props} inputRef={ref} />
              </span>
            )}
          />
        </div>
        <div className="d-flex flex-column align-items-center">
          {isLockResendOtp ? (
            <Countdown
              renderContent={(seconds) => (
                <span className="d mx-auto text-error">
                  {t('txt-countdown-refresh', { time: seconds })}
                </span>
              )}
              onCountdownEnd={() => {
                dispatch(unlockResendOtp());
              }}
            />
          ) : (
            <ResendButton onClick={generateAuthHandle}>
              {t('txt-refresh-matrix')}
            </ResendButton>
          )}
        </div>
      </React.Fragment>
    );
  }
);

export default Matrix;
