import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { Form } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';

import { makeGetToken, makeGetDefaultAccount } from '../../../lib/selector';

import { summaryStockRequest } from '../../../containers/summary/actions';

const _loailenh = ['Mua', 'Bán'];
const _san = ['HSX', 'HNX', 'UPCOM'];
const _status = [];

class FormXacNhanLenh extends Component {
  submit = (values) => {
    const { txtSearch, typeSan, typeGD, typeStatus } = values;
    const { token, defaultAccount } = this.props;

    const _typeGD = typeGD ? (typeGD === 'Mua' ? 'B' : 'S') : '';

    const _txtSearch = txtSearch ? txtSearch.toUpperCase() : '';

    const uuid = uuidv4();

    // const params = {
    //   group: 'CORE',
    //   user: token.user,
    //   session: token.session,
    //   cmd: 'GetStockStatement',
    //   rqId: uuid,
    //   channel: 'WTS',
    //   data: {
    //     acntNo: account.acntNo,
    //     subAcntNo: account.subAcntNo,
    //     fromDate: _formatDate(startDate),
    //     toDate: _formatDate(endDate),
    //     symbol: _txtSearch,
    //     nextKey: '1',
    //     reqCount: '20',
    //   },
    // };

    // this.props.dispatch(summaryStockRequest(params));
  };

  render() {
    const { handleSubmit, submitting, pristine, reset, startDate, endDate } =
      this.props;

    return (
      <form
        className="p-2 mt-2 form-search-head"
        onSubmit={handleSubmit(this.submit)}
      >
        <Form.Group className="d-flex mb-0 p-2" controlId="formPlaintextSymbol">
          <label className="text-white-50 fz_14" style={{ width: '20mm' }}>
            {'Tìm kiếm'}
          </label>
          <div style={{ width: '30mm' }}>
            <Field
              name="txtSearch"
              component="input"
              maxLength="8"
              type="text"
              className="form-control form-input text-uppercase fz_14"
              placeholder="Nhập mã CK"
            />
          </div>
          <label
            className="text-white-50 fz_14 text-center"
            style={{ width: '20mm' }}
          >
            {'Sàn'}
          </label>
          <div style={{ width: '30mm' }}>
            <Field
              name="typeSan"
              component="select"
              className="form-control form-input fz_14"
            >
              <option value="">{'--All--'}</option>
              {_san.map((statusOption) => (
                <option value={statusOption} key={statusOption}>
                  {statusOption}
                </option>
              ))}
            </Field>
          </div>
          <label
            className="text-white-50 fz_14 text-center"
            style={{ width: '20mm' }}
          >
            {'GD'}
          </label>
          <div style={{ width: '30mm' }}>
            <Field
              name="typeGD"
              component="select"
              className="form-control form-input fz_14"
            >
              <option value="">{'--All--'}</option>
              {_loailenh.map((statusOption) => (
                <option value={statusOption} key={statusOption}>
                  {statusOption}
                </option>
              ))}
            </Field>
          </div>
          <label className="text-white-50 fz_14 ml-2" style={{ width: '20mm' }}>
            {'Trạng thái'}
          </label>
          <div style={{ width: '30mm' }}>
            <Field
              name="typeStatus"
              component="select"
              className="form-control form-input fz_14"
            >
              <option value="">{'--All--'}</option>
              {_status.map((statusOption) => (
                <option value={statusOption} key={statusOption}>
                  {statusOption}
                </option>
              ))}
            </Field>
          </div>
          <button
            ref={(el) => (this.refSubmit = el)}
            type="submit"
            disabled={submitting}
            className="btn-reload fz_14 fw-500 text-white ml-2"
          >{`OK`}</button>
        </Form.Group>
      </form>
    );
  }
}

FormXacNhanLenh = reduxForm({
  form: 'xacnhanlenh',
  enableReinitialize: true,
})(FormXacNhanLenh);

const selector = formValueSelector('xacnhanlenh');

const makeMapStateToProps = () => {
  const getToken = makeGetToken();
  const getDefaultAccount = makeGetDefaultAccount();

  const mapStateToProps = (state, props) => {
    const token = getToken(state);
    const defaultAccount = getDefaultAccount(state);

    const { txtSearch, typeSan, typeGD, typeStatus } = selector(
      state,
      'txtSearch',
      'typeSan',
      'typeGD',
      'typeStatus'
    );

    return {
      token,
      defaultAccount,
      txtSearch,
      typeSan,
      typeGD,
      typeStatus,
      initialValues: {
        txtSearch: '',
        typeSan: '',
        typeGD: '',
        typeStatus: '',
      },
    };
  };
  return mapStateToProps;
};

FormXacNhanLenh = connect(makeMapStateToProps)(FormXacNhanLenh);

export default FormXacNhanLenh;
