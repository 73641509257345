import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PerfectScrollBar from 'react-perfect-scrollbar';
import Page from '../../../components/print/Page';
import {
  numberFormat,
  _convertTpToPrice,
  _getOrderStatus,
  _formatDate2,
  _formatDate,
  _diff2Date,
} from '../../../util';

import { withNamespaces } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { Col } from 'react-bootstrap';
import PrintButton from '../../../components/print/PrintButton';
import PaginationTable from '../../../components/PaginationTable';
import { find } from 'lodash';
import logo from '../../../assets/img/pinetreeSecurities.svg';
import ExportExecutedGainLoss from '../components/exports/executedGainLossExcel';
import AssetHeader from '../components/header/assetHeader';
import OverviewMetric from '../components/header/overviewMetric';
import DatePicker from '../../../components/datePicker2';
import { gainLossRequest } from '../../cash/actions';
import moment from 'moment';
import { setToast } from '../../client/actions';
import { Mixpanel } from '../../../lib/mixpanel';

const ExecutedGainLoss = withNamespaces('translations')(
  ({ t, params, loadBalanceHandle }) => {
    const { defaultAccount } = useSelector((state) => state.socket);
    const gainLossList = useSelector((state) => state.cash.gainLossList);
    const [refresh, setRefresh] = useState(false);
    const [tabActive, setTabActive] = useState(0);
    const [account, setAccount] = useState();

    useEffect(() => {
      setAccount(defaultAccount);
      setRefresh(!refresh);
    }, [defaultAccount]);

    return (
      <div className="card-panel-2 executed-gainloss statement-container h-100">
        <AssetHeader
          account={account}
          setAccount={setAccount}
          params={params}
          setRefresh={setRefresh}
          tabActive={tabActive}
          refresh={refresh}
        />
        <div class="card-panel-2-body">
          <OverviewMetric
            key={refresh}
            loadBalanceHandle={loadBalanceHandle}
            account={account}
          />
        </div>
        <Filter
          refresh={refresh}
          account={account}
          key={refresh}
          gainLossList={gainLossList ? [...gainLossList].reverse() : []}
        />
      </div>
    );
  }
);

const Filter = withNamespaces('translations')(
  ({ t, refresh, account, gainLossList }) => {
    const { token, auth } = useSelector((state) => state.client);
    const dispatch = useDispatch();

    const currentDate = new Date();
    const [fromDate, setFromDate] = useState(
      new Date(currentDate.setDate(currentDate.getDate() - 90))
    );
    const [toDate, setToDate] = useState(new Date());
    const [symbol, setSymbol] = useState('');

    const columns = [
      {
        key: '_index',
        text: t('txt-stt'),
        className: 'text-center text--light3',
        align: 'center',
        rowSpan: '2',
        style: { width: '3%' },
      },
      {
        key: 'ordrNo',
        text: t('txt-order-no'),
        className: 'text-center text--light3',
        align: 'center',
        rowSpan: '2',
        style: { width: '3%' },
      },
      {
        key: 'trdDate',
        text: t('txt-time'),
        className: 'text-center text--light3',
        align: 'center',
        cell: (record) => {
          return _formatDate2(record.trdDate);
        },
        style: { width: '7%' },
        rowSpan: '2',
      },
      {
        key: 'buySelTp',
        text: `${t('txt-order')}`,
        className: 'text-center fw-500',
        align: 'center',
        cell: (record) => {
          if (record.buySelTp === '1') {
            return <div className="i">{t('txt-buy')}</div>;
          } else return <div className={`d`}>{t('txt-sell')}</div>;
        },
        style: { width: '4%' },
        rowSpan: '2',
      },
      {
        key: 'symbol',
        text: t('txt-symbol'),
        className: 'text-center fw-500 text--light',
        align: 'center',
        style: { width: '4%' },
        rowSpan: '2',
      },
      {
        key: 'matchedPrc',
        text: t('txt-match-price'),
        className: 'text-right text--light fw-500',
        align: 'center',
        cell: (record) => {
          return numberFormat(record.matchedPrc, 0, '0');
        },
        style: { width: '5%' },
        rowSpan: '2',
      },
      {
        key: 'matchedQty',
        text: t('txt-match-vol'),
        className: 'text-right text--light',
        align: 'center',
        cell: (record) => {
          return numberFormat(record.matchedQty, 0, '0');
        },
        style: { width: '5%' },
        rowSpan: '2',
      },
      {
        key: 'matchedFee',
        text: t('txt-label-trading-fee'),
        className: 'text-right text--light3 fw-500',
        align: 'center',
        cell: (record) => {
          return numberFormat(record.matchedFee, 0, '0');
        },
        style: { width: '5%' },
        rowSpan: '2',
      },
      {
        key: 'selFee',
        text: t('txt-label-sell-fee'),
        className: 'text-right text--light3 fw-500',
        align: 'center',
        cell: (record) => {
          return numberFormat(record.selFee, 0, '0');
        },
        style: { width: '5%' },
        rowSpan: '2',
      },
      {
        key: 'matchedTax',
        text: t('txt-label-tax'),
        className: 'text-right text--light3 fw-500',
        align: 'center',
        cell: (record) => {
          return numberFormat(record.matchedTax, 0, '0');
        },
        style: { width: '5%' },
        rowSpan: '2',
      },
      {
        key: 'divdDltx',
        text: t('txt-label-dividend-tax'),
        className: 'text-right text--light3 fw-500',
        align: 'center',
        cell: (record) => {
          return numberFormat(record.divdDltx, 0, '0');
        },
        style: { width: '5%' },
        rowSpan: '2',
      },
      {
        key: 'costPrice', // giá TB
        text: t('lbl-cost-at-the-time-of-sale'),
        className: 'text-right fw-500 text--light',
        align: 'center',
        cell: (record) => {
          return numberFormat(record.costPrice, 0, '0');
        },
        style: { width: '5%' },
        rowSpan: '2',
      },
      {
        key: 'purcAmt', // giá vốn
        text: t('lbl-buy-amount'),
        className: 'text-right fw-500 text--light',
        align: 'center',
        style: { width: '8%' },
        cell: (record) => {
          return numberFormat(record.purcAmt, 0, '0');
        },
      },
      {
        key: 'setlAmt', // giá trị bán khớp sau trừ thuế phí
        text: t('txt-match-price-after-tax'),
        className: 'text-right fw-500 text--light',

        align: 'center',
        style: { width: '8%' },
        cell: (record) => {
          return numberFormat(record.setlAmt, 0, '0');
        },
      },
      {
        key: 'plAmt',
        text: t('lbl-gain-loss'),
        className: (record) => {
          if (record.plAmt > 0) return 'text-right fw-500 i';
          else if (record.plAmt == 0) return 'text-right fw-500 r';
          else return 'text-right fw-500 d';
        },
        align: 'center',
        style: { width: '8%' },
        cell: (record) => {
          return numberFormat(record.plAmt, 0, '0');
        },
      },
      {
        key: 'plRate',
        text: '%' + t('lbl-gain-loss'),
        className: (record) => {
          if (record.plRate > 0) return 'text-right fw-500 i';
          else if (record.plRate == 0) return 'text-right fw-500 r';
          else return 'text-right  fw-500 d';
        },
        align: 'center',
        style: { width: '8%' },
        cell: (record) => {
          return numberFormat(record.plRate, 2, '0') + ' %';
        },
      },
    ];

    const totalColumns = [
      {
        key: 'totPurcAmt', // Tổng giá trị vốn
        className: 'text-right high-light fw-500 fz_16',
        align: 'center fw-500',
        cell: ({ _totalShow }) => {
          return numberFormat(_totalShow.totPurcAmt, 0, '0');
        },
      },
      {
        key: 'totSetlAmt', // Tổng giá trị khớp đã trừ thuế phí
        className: 'text-right high-light fw-500 fz_16',
        align: 'center',
        cell: ({ _totalShow }) => {
          return numberFormat(_totalShow.totSetlAmt, 0, '0');
        },
      },
      {
        key: 'totplAmt', // Tổng lãi lỗ
        className: ({ _totalShow }) => {
          if (_totalShow.totplAmt > 0)
            return 'text-right high-light fw-500 fz_16 i';
          else if (_totalShow.totplAmt == 0)
            return 'text-right high-light fw-500 fz_16 r';
          else return 'text-right high-light fw-500 fz_16 d';
        },
        align: 'center',
        cell: ({ _totalShow }) => {
          return numberFormat(_totalShow.totplAmt, 0, '0');
        },
      },
      {
        key: 'totplRate', // Tổng % lãi lỗ
        className: ({ _totalShow }) => {
          if (_totalShow.totplRate > 0)
            return 'text-right high-light fw-500 fz_16 i';
          else if (_totalShow.totplRate == 0)
            return 'text-right high-light fw-500 fz_16 r';
          else return 'text-right high-light fw-500 fz_16 d';
        },
        align: 'center',
        cell: ({ _totalShow }) => {
          return numberFormat(_totalShow.totplRate, 2, '0') + ' %';
        },
      },
    ];

    const totalShow = find(gainLossList, (x) => x.symbol == 'TOTAL');
    const contentShow = gainLossList
      ? gainLossList.filter((x) => x.symbol != 'TOTAL')
      : [];

    const getGainLossHandle = (fromDate, endDate, symbol) => {
      if (!token || !account) return;
      const maxDay = token && token['config'] ? token['config']['maxDay'] : 90;
      const minDate = new Date('2021-07-01 00:00:00');

      if (_diff2Date(fromDate, minDate) < 0) {
        handleToast(
          t('txt-valid-time-2') + moment(minDate).format('DD/MM/YYYY')
        );
        return;
      }

      const _diff = _diff2Date(_formatDate2(endDate), _formatDate2(fromDate));
      if (_diff < 0) {
        this._handleToast(t('txt-valid-time'));
        return;
      }
      if (_diff > maxDay) {
        handleToast(`${t('txt-valid-range-time')} ${maxDay} ${t('txt-day')}`);
        return;
      }
      const params = {
        group: 'CORE',
        user: token.user,
        session: token.session,
        cmd: 'getProfitLossList',
        rqId: uuidv4,
        channel: 'WTS',
        data: {
          acntNo: account ? account?.acntNo : '',
          subAcntNo: account ? account.subAcntNo : '',
          symbol: symbol?.toUpperCase(),
          fromDate: fromDate,
          toDate: endDate,
          reqCount: 1000,
        },
      };

      dispatch(gainLossRequest(params));
    };

    const handleSymbol = (value) => {
      setSymbol(value);
    };

    const handleToast = (msg) => {
      const toastMsg = {
        id: Math.random(),
        msg: msg,
        title: t('txt-notice'),
      };
      dispatch(setToast(toastMsg));
    };

    useEffect(() => {
      getGainLossHandle(fromDate, toDate, symbol);
    }, [account]);

    useEffect(() => {
      if (!token) return;
      getGainLossHandle(
        moment(new Date().setDate(new Date().getDate() - 90)).format(
          'YYYYMMDD'
        ),
        moment(new Date()).format('YYYYMMDD')
      );
    }, [refresh]);

    const onFilter = () => {
      getGainLossHandle(fromDate, toDate, symbol);
    };
    return (
      <>
        <PerfectScrollBar className="mt-36 h-100">
          <Col className="d-flex flex-column">
            <div className="d-flex justify-content-between align-items-center statement__filters">
              <label className="mr-auto text-white text-title">
                {t('personal.nav.statement.executedGainLossStatement')}
              </label>
              <div className="d-flex">
                <div className="input-text-search d-flex align-items-center mr-1">
                  <input
                    placeholder="Symbol"
                    onChange={(e) => handleSymbol(e.target.value)}
                    value={symbol}
                  />
                  <span className="icon iSearch"></span>
                </div>
                <div className="mr-1">
                  <DatePicker
                    startDate={fromDate}
                    endDate={toDate}
                    callback={(from, to) => {
                      setFromDate(_formatDate(from));
                      setToDate(_formatDate(to));
                    }}
                  />
                </div>
                <button
                  className="btn btn--primary h-24 mr-1"
                  onClick={() => onFilter()}
                >{`${t('txt-search')}`}</button>
              </div>
              <div className="d-flex align-items-center">
                <ExportExecutedGainLoss
                  csvData={contentShow}
                  fileName={t('txt-label-gain-loss')}
                  fromDate={_formatDate2(fromDate)}
                  toDate={_formatDate2(toDate)}
                  accountCode={account && account.subAcntNo}
                  accountName={account && account.vsdAcntNo}
                  symbol={symbol || t('txt-all')}
                />
                <PrintButton
                  id={t('txt-label-gain-loss')}
                  callback={() =>
                    Mixpanel.track('Export Statement', {
                      'Statement Type': 'Executed Gain Loss Statement',
                    })
                  }
                />
              </div>
            </div>
            <PaginationTable
              t
              tblClassName="tbl-list"
              columns={columns}
              totalColumns={totalColumns}
              hasPaging
              source={gainLossList}
            />
            <div className="pb-5"></div>
          </Col>
        </PerfectScrollBar>
        <Page id={t('txt-label-gain-loss')} style={{ display: 'none' }}>
          <div className="d-flex flex-column bg-light">
            <div
              className="position-relative d-flex justify-content-center align-items-center"
              style={{ height: '75px' }}
            >
              <img
                src={logo}
                alt="logo"
                className="position-absolute"
                style={{ top: '0', left: '0' }}
              />
              <span className="fz_16 fw-500 text-uppercase">
                {t('txt-label-gain-loss')}
              </span>
            </div>
            <div className="d-flex mt-2">
              <Col className="d-flex flex-column">
                <div className="d-flex">
                  <span className="fz_14 w_125">
                    {t('lbl-report-name') + ': '}
                  </span>
                  <span className="fz_14 f_i">{t('txt-label-gain-loss')}</span>
                </div>
                <div className="d-flex">
                  <span className="fz_14 w_125">
                    {t('lbl-subAccount') + ': '}
                  </span>
                  <span className="fz_14 f_i">
                    {account && account.subAcntNo}
                  </span>
                </div>
                <div className="d-flex">
                  <span className="fz_14 w_125">
                    {t('txt-label-vsd-no') + ': '}
                  </span>
                  <span className="fz_14 f_i">
                    {account && account.vsdAcntNo}
                  </span>
                </div>
              </Col>
              <Col className="d-flex flex-column">
                <div className="d-flex">
                  <span className="fz_14 w_125">
                    {t('txt-label-from') + ': '}
                  </span>
                  <span className="fz_14 f_i">
                    {fromDate && moment(fromDate).format('DD/MM/YYYY')}
                  </span>
                </div>
                <div className="d-flex">
                  <span className="fz_14 w_125">
                    {t('txt-label-to') + ': '}
                  </span>
                  <span className="fz_14 f_i">
                    {toDate && moment(toDate).format('DD/MM/YYYY')}
                  </span>
                </div>
                <div className="d-flex">
                  <span className="fz_14 w_125">{t('txt-symbol') + ': '}</span>
                  <span className="fz_14 f_i">{symbol || t('txt-all')}</span>
                </div>
              </Col>
            </div>
          </div>
          <div className="d-flex bg-light">
            <table className="table table-bordered mt-2 table-light">
              <thead>
                <tr style={{ border: '1px solid #e8e8e8' }}>
                  <th className="text-center text-white">{t('txt-stt')}</th>
                  <th className="text-center text-white">
                    {t('txt-label-tran-date')}
                  </th>
                  <th className="text-center text-white">
                    {t('txt-order-no')}
                  </th>
                  <th className="text-center text-white">{`${t('txt-buy')}/${t(
                    'txt-sell'
                  )}`}</th>
                  <th className="text-center text-white">{t('txt-symbol')}</th>
                  <th className="text-center text-white">
                    {t('txt-match-vol')}
                  </th>
                  <th className="text-center text-white">
                    {t('txt-match-price')}
                  </th>
                  <th className="text-center text-white">
                    {t('txt-label-trading-fee')}
                  </th>
                  <th className="text-center text-white">
                    {t('txt-label-sell-fee')}
                  </th>
                  <th className="text-center text-white">
                    {t('txt-label-tax')}
                  </th>
                  <th className="text-center text-white">
                    {t('txt-label-dividend-tax')}
                  </th>
                  <th className="text-center text-white">
                    {t('lbl-cost-at-the-time-of-sale')}
                  </th>
                  <th
                    className="text-center text-white"
                    style={{ width: '10%' }}
                  >
                    {t('lbl-buy-amount')}
                  </th>
                  <th className="text-center text-white">
                    {t('txt-match-price-after-tax')}
                  </th>
                  <th
                    className="text-center text-white"
                    style={{ width: '10%' }}
                  >
                    {t('txt-gain-loss')}
                  </th>
                  <th
                    className="text-center text-white"
                    style={{ width: '10%' }}
                  >
                    {'% ' + t('txt-gain-loss')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {contentShow &&
                  contentShow.length &&
                  contentShow.map((item, i) => {
                    return (
                      <tr style={{ border: '1px solid #e8e8e8' }} key={i}>
                        <td className="text-center">{i + 1}</td>
                        <td className="text-left">
                          {_formatDate2(item.trdDate)}
                        </td>
                        <td className="text-right">{item.ordrNo}</td>
                        <td className="text-center">
                          {item.buySelTp === '1' ? (
                            <div
                              className={`text-uppercase mx-auto trade_side_${item.buySelTp}`}
                            >
                              {t('txt-buy')}
                            </div>
                          ) : (
                            <div
                              className={`text-uppercase mx-auto trade_side_${item.buySelTp}`}
                            >
                              {t('txt-sell')}
                            </div>
                          )}
                        </td>
                        <td className="text-right">{item.symbol}</td>
                        <td className="text-right">
                          {numberFormat(item.matchedQty, 0, '0')}
                        </td>
                        <td className="text-right">
                          {numberFormat(item.matchedPrc, 0, '0')}
                        </td>
                        <td className="text-right">
                          {numberFormat(item.matchedFee, 0, '0')}
                        </td>
                        <td className="text-right">
                          {numberFormat(item.selFee, 0, '0')}
                        </td>
                        <td className="text-right">
                          {numberFormat(item.matchedTax, 0, '0')}
                        </td>
                        <td className="text-right">
                          {numberFormat(item.divdDltx, 0, '0')}
                        </td>
                        <td className="text-right">
                          {numberFormat(item.costPrice, 0, '0')}
                        </td>
                        <td className="text-right">
                          {numberFormat(item.purcAmt, 0, '0')}
                        </td>
                        <td className="text-right">
                          {numberFormat(item.setlAmt, 0, '0')}
                        </td>
                        <td className="text-right">
                          {numberFormat(item.plAmt, 0, '0')}
                        </td>
                        <td className="text-right">
                          {numberFormat(item.plRate, 2, '0') + ' %'}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
              {totalShow && (
                <tfoot>
                  <tr style={{ border: '1px solid #e8e8e8 ' }}>
                    <td className="fw-500 text-center" colSpan="12">
                      {t('txt-label-total')}
                    </td>
                    <td className="text-right fw-500">
                      {numberFormat(totalShow.totPurcAmt, 0, '0')}
                    </td>
                    <td className="text-right fw-500">
                      {numberFormat(totalShow.totSetlAmt, 0, '0')}
                    </td>
                    <td className="text-right fw-500">
                      {numberFormat(totalShow.totplAmt, 0, '0')}
                    </td>
                    <td className="text-right fw-500">
                      {numberFormat(totalShow.totplRate, 2, '0') + ' %'}
                    </td>
                  </tr>
                </tfoot>
              )}
            </table>
          </div>

          {(!gainLossList || !gainLossList.length) && (
            <p className="text-center fz_14 mt-2 title-time">
              {t('txt-no-data-found')}
            </p>
          )}
        </Page>
      </>
    );
  }
);

export default ExecutedGainLoss;
