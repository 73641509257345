import React from 'react';

class Loading extends React.Component {
  render() {
    return (
      <div className="animated fadeIn pt-1 text-center text-white fz_12">
        Loading...
      </div>
    );
  }
}

export default Loading;
