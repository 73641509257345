import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';

import { required } from '../../../util/validation';
import RenderInputOrder from '../../input/renderInputOrder';

import { Card } from 'react-bootstrap';
import { FaTimes } from 'react-icons/fa';
import {
  makeGetToken,
  makeGetDefaultAccount,
  makeGetAuth,
} from '../../../lib/selector';
import { numberFormat, _convertTpToPrice, StringToDouble } from '../../../util';
import { translate } from 'react-i18next';
import { getCookie } from '../../../lib/storages';
import './confirmOrder.scss';
import Modal from 'react-bootstrap/Modal';
import { constants } from '../../../util/constant';

class FormConfirmOrder extends Component {
  submit = (values) => {
    const { order, auth } = this.props;
    order.type = auth.type;
    order.token = auth.token;

    this.props.handleSubmitOrder(order);
    // reset();
  };

  render() {
    const {
      handleSubmit,
      submitting,
      showModal,
      handleCloseModal,
      order,
      orderG,
      isOddlot,
      t,
      themes,
    } = this.props;

    let vsdId = '';

    const token = getCookie('token');
    if (token && token.vsd) {
      vsdId = token.vsd;
    }

    return (
      <Modal
        show={showModal}
        dialogClassName="confirm-order-modal"
        backdropClassName="confirm-order-modal-backdrop"
        contentClassName={`${
          themes?.name || 'dark'
        } delete-order-modal-content`}
        onEscapeKeyDown={handleCloseModal}
        centered
        style={{ zIndex: 11115 }}
      >
        <div className="confirm-order">
          <div className="confirm-order-header">
            {t('txt-title-new-confirm')}
          </div>
          <form
            className="confirm-order-body"
            onSubmit={handleSubmit(this.submit)}
          >
            <div className="confirm-order-body__infor">
              <span className="confirm-order-body__infor-title">
                <p>{t('txt-label-vsd-no')}</p>
                <p>{t('txt-label-account-type')}</p>
              </span>
              <span className="confirm-order-body__infor-value">
                <p>{vsdId}</p>
                <p>{order && order.data.subAcntNo}</p>
              </span>
            </div>

            <div className="confirm-order-body__detail">
              <span className={`order order-type`}>
                <span
                  className={`${
                    order && (order.data.buySelTp === '1' ? 'i' : 'd')
                  }`}
                >
                  {order &&
                    (order.data.buySelTp === '1'
                      ? t('txt-buy')
                      : t('txt-sell'))}
                </span>
              </span>
              <span className="order order-symbol">
                {order && order.data.symbol}
              </span>
              <span className="order order-price">
                {order &&
                  (_convertTpToPrice(order.data.ordrTrdTp) === 'LO'
                    ? numberFormat(
                        StringToDouble(order.data.ordrUntprc) / 1000,
                        2
                      )
                    : _convertTpToPrice(order.data.ordrTrdTp))}
              </span>
              <span className="order order-quantity">
                {order && numberFormat(order.data.ordrQty, 0, '0')}
              </span>
            </div>

            {order && isOddlot && order.data.ordrQty >= 100 && (
              <div
                className="pt-3 r text-center"
                style={{ whiteSpace: 'pre-line', lineHeight: '20px' }}
              >
                {t('orderPanel.warningOddlot')}
              </div>
            )}
            {orderG != constants.G.BUY_IN &&
              order &&
              !isOddlot &&
              order.data.ordrQty < 100 && (
                <div
                  className="pt-3 r text-center"
                  style={{ whiteSpace: 'pre-line' }}
                >
                  {t('orderPanel.warningLot')}
                </div>
              )}

            <div className="confirm-order-body__action">
              <button
                type="submit"
                className={`btn-confirm ${
                  order?.data.buySelTp === '1'
                    ? 'btn-confirm--buy'
                    : 'btn-confirm--sell'
                }`}
                disabled={submitting}
              >
                {t('txt-accept')}
              </button>
              <button
                className="btn-cancel"
                disabled={submitting}
                onClick={handleCloseModal}
              >
                {t('txt-cancel')}
              </button>
            </div>
          </form>
        </div>
      </Modal>
    );
  }
}

FormConfirmOrder = reduxForm({
  form: 'confirmOrder',
  enableReinitialize: true,
})(FormConfirmOrder);

const makeMapStateToProps = () => {
  const getToken = makeGetToken();
  const getDefaultAccount = makeGetDefaultAccount();
  const getAuth = makeGetAuth();

  const mapStateToProps = (state) => {
    return {
      token: getToken(state),
      defaultAccount: getDefaultAccount(state),
      auth: getAuth(state),
      themes: state.client.currentTheme,
    };
  };
  return mapStateToProps;
};

FormConfirmOrder = connect(makeMapStateToProps)(FormConfirmOrder);

export default translate('translations')(FormConfirmOrder);
