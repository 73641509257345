import {
  SUMMARY_ORD_REQUESTING,
  SUMMARY_ORD_REQUEST_SUCCESS,
  SUMMARY_ORD_REQUEST_ERROR,
  SUMMARY_CASH_REQUESTING,
  SUMMARY_CASH_REQUEST_SUCCESS,
  SUMMARY_CASH_REQUEST_ERROR,
  SUMMARY_STOCK_REQUESTING,
  SUMMARY_STOCK_REQUEST_SUCCESS,
  SUMMARY_STOCK_REQUEST_ERROR,
  SUMMARY_EVENT_REQUESTING,
  SUMMARY_EVENT_REQUEST_SUCCESS,
  SUMMARY_EVENT_REQUEST_ERROR,
  SUMMARY_RIGHT_REQUESTING,
  SUMMARY_RIGHT_REQUEST_SUCCESS,
  SUMMARY_RIGHT_REQUEST_ERROR,
  SUMMARY_ORD_COND_REQUESTING,
  SUMMARY_ORD_COND_REQUEST_SUCCESS,
  SUMMARY_ORD_COND_REQUEST_ERROR,
  SUMMARY_NOTIFICATION_REQUESTING,
  SUMMARY_NOTIFICATION_REQUEST_SUCCESS,
  SUMMARY_NOTIFICATION_REQUEST_ERROR,
  NOTIFICATION_UPDATE_REQUEST,
  NOTIFICATION_UPDATE_REQUEST_SUCCESS,
} from './constants';

const initialState = {
  ordList: [],
  ordListRequesting: false,
  ordListErrors: [],

  ordCondList: [],
  ordCondListRequesting: false,
  ordCondListErrors: [],

  cashList: [],
  cashListRequesting: false,
  cashListErrors: [],

  stockList: [],
  stockListRequesting: false,
  stockListErrors: [],

  event: [],
  eventRequesting: false,
  eventErrors: [],

  rightList: null,

  notification: [],
  notificationRequesting: false,
  notificationErrors: [],
};

const reducer = function summaryReducer(state = initialState, action) {
  switch (action.type) {
    case SUMMARY_ORD_REQUESTING:
      return {
        ...state,
        ordList: [],
        ordListRequesting: true,
        ordListErrors: [],
      };

    case SUMMARY_ORD_REQUEST_SUCCESS:
      return {
        ...state,
        ordList: action.ordList,
        ordListRequesting: false,
        ordListErrors: [],
      };

    case SUMMARY_ORD_REQUEST_ERROR:
      return {
        ...state,
        ordList: [],
        ordListRequesting: false,
        ordListErrors: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };

    case SUMMARY_ORD_COND_REQUESTING:
      return {
        ...state,
        ordCondList: [],
        ordCondListRequesting: true,
        ordCondListErrors: [],
      };

    case SUMMARY_ORD_COND_REQUEST_SUCCESS:
      return {
        ...state,
        ordCondList: action.ordCondList,
        ordCondListRequesting: false,
        ordCondListErrors: [],
      };

    case SUMMARY_ORD_COND_REQUEST_ERROR:
      return {
        ...state,
        ordCondList: [],
        ordCondListRequesting: false,
        ordCondListErrors: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };

    case SUMMARY_CASH_REQUESTING:
      return {
        ...state,
        cashList: [],
        cashListRequesting: true,
        cashListErrors: [],
      };

    case SUMMARY_CASH_REQUEST_SUCCESS:
      return {
        ...state,
        cashList: action.cashList,
        cashListRequesting: false,
        cashListErrors: [],
      };

    case SUMMARY_CASH_REQUEST_ERROR:
      return {
        ...state,
        cashList: [],
        cashListRequesting: false,
        cashListErrors: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };

    case SUMMARY_STOCK_REQUESTING:
      return {
        ...state,
        stockList: [],
        stockListRequesting: true,
        stockListErrors: [],
      };

    case SUMMARY_STOCK_REQUEST_SUCCESS:
      return {
        ...state,
        stockList: action.stockList,
        stockListRequesting: false,
        stockListErrors: [],
      };

    case SUMMARY_STOCK_REQUEST_ERROR:
      return {
        ...state,
        stockList: [],
        stockListRequesting: false,
        stockListErrors: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };

    case SUMMARY_EVENT_REQUESTING:
      return {
        ...state,
        event: [],
        eventRequesting: true,
        eventErrors: [],
      };

    case SUMMARY_EVENT_REQUEST_SUCCESS:
      return {
        ...state,
        event: action.event,
        eventRequesting: false,
        eventErrors: [],
      };

    case SUMMARY_EVENT_REQUEST_ERROR:
      return {
        ...state,
        event: [],
        eventRequesting: false,
        eventErrors: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };

    case SUMMARY_RIGHT_REQUESTING:
      return {
        ...state,
        rightList: null,
      };

    case SUMMARY_RIGHT_REQUEST_SUCCESS:
      return {
        ...state,
        rightList: action.rightList,
      };

    case SUMMARY_RIGHT_REQUEST_ERROR:
      return {
        ...state,
        rightList: null,
      };

    case SUMMARY_NOTIFICATION_REQUESTING:
      return {
        ...state,
        notification: [],
        notificationRequesting: true,
        notificationErrors: [],
      };

    case SUMMARY_NOTIFICATION_REQUEST_SUCCESS:
      return {
        ...state,
        notification: action.notification,
        notificationRequesting: false,
        notificationErrors: [],
      };

    case SUMMARY_NOTIFICATION_REQUEST_ERROR:
      return {
        ...state,
        notification: [],
        notificationRequesting: false,
        notificationErrors: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };

    case NOTIFICATION_UPDATE_REQUEST: {
      const notifications = [...state.notification];
      const noti = notifications.find(
        (x) => x.notiID == action.params.data.notiID
      );
      if (noti) noti.isRead = 'Y';
      return {
        ...state,
        notification: notifications,
      };
    }

    case NOTIFICATION_UPDATE_REQUEST_SUCCESS: {
      const notifications = [...state.notification];
      const noti = notifications.find(
        (x) => x.notiID == action.params.data.notiID
      );
      if (noti) noti.isRead = 'Y';
      return {
        ...state,
        notification: notifications,
      };
    }

    default:
      return state;
  }
};

export default reducer;
