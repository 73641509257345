import React from 'react';

const RenderArea = ({
  input,
  type,
  className,
  rows,
  meta: { touched, error },
}) => (
  <div>
    <textarea
      {...input}
      type={type}
      className={className}
      rows={rows}
      style={{
        borderColor: `${touched && error ? '#ff5555' : '#c6cbd2'}`,
      }}
    />
  </div>
);

export default RenderArea;
