import {
  call,
  put,
  takeLatest,
  all,
  select,
  takeEvery,
  takeLeading,
} from 'redux-saga/effects';
import { handleApiErrors } from '../../lib/api-errors';
import {
  CONFIG_REQUESTING,
  CATEGORY_REQUESTING,
  SAVE_CATEGORY_REQUESTING,
  INDUSTRY_CATEGORY_REQUESTING,
  ADVERTISE_REQUESTING,
  ADVERTISE_REQUESTING_SUCCESS,
  ADVERTISE_REQUESTING_ERROR,
  APPSETTING_REQUESTING_ERROR,
  APPSETTING_REQUESTING,
  APPSETTING_REQUESTING_SUCCESS,
  USER_SETTING_REQUEST,
  GET_CHART_IN_MEM_DATA,
  SET_FEEDBACK_POPUP_SHOW,
  GET_FEEDBACK_TYPE_REQUEST,
  CLIENT_COMMON_REQUEST,
} from './constants';

import {
  configRequestSuccess,
  configRequestError,
  categoryRequestSuccess,
  categoryRequestError,
  industryRequestSuccess,
  industryRequestError,
  unsetClient,
  setToast,
} from './actions';
import moment from 'moment';
import { setOddlot } from '../banggia/actions';
import { getMessage } from '../../lib/selector';
import { Logout, logoutRequest } from '../../components/modal/login/actions';
import { removeCookie } from '../../lib/storages';
import i18n from '../../i18n';

const apiUrl = `${process.env.REACT_APP_API_URL}`;
const apiUrl2 = `${process.env.REACT_APP_API_URL_2}`;
const feedBackUrl = 'https://api.pinetree.com.vn/AuthService/public/feedback';

function handleRequest(request) {
  return request
    .then(handleApiErrors)
    .then((response) => response.json())
    .then((json) => json)
    .catch((error) => {
      throw error;
    });
}

function toastHandle(message, title = 'txt-notice') {
  const toastMsg = {
    id: Math.random(),
    msg: message,
    time: new Date(),
    title: i18n.t(`${title}`),
  };
  return put(setToast(toastMsg));
}

function configRequestApi({ user, session }) {
  const url = `${apiUrl}/config.pt?user=${user}&session=${session}`;
  const request = fetch(url);

  return handleRequest(request);
}

function coreRequestApi(data) {
  const url = `${apiUrl}/CoreServlet.pt`;
  const request = fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
    body: JSON.stringify(data),
  });

  return handleRequest(request);
}

function industryRequestApi(lang) {
  const url = `${apiUrl2}/api/Market/GetGroupByIndustry?lang=${lang}`;
  const request = fetch(url);
  return handleRequest(request);
}

function advertiseRequestApi(lang) {
  const url = `${apiUrl2}/api/Application/FeedbackContent?version=2&lang=${lang}`;
  const request = fetch(url);
  return handleRequest(request);
}

function appsettingRequestApi(action) {
  const { module, key, ver, lang } = action.data;
  const url = `${apiUrl2}/api/Application?version=${ver}&key=${key}&module=${module}&lang=${lang}`;
  const request = fetch(url);
  return handleRequest(request);
}

function getchartInmemData(mc) {
  const url = `${apiUrl}/getchartInmemData/${mc}`;
  const request = fetch(url);
  return handleRequest(request);
}

function getFeedbackTypeApi(lang) {
  const url = `${feedBackUrl}/categories?language=${lang}`;
  const request = fetch(url, {
    method: 'GET',
    headers: {
      'Accept-Language': lang,
    },
  });
  return handleRequest(request);
}

function submitFeedbackApi() {
  const url = feedBackUrl;
  const request = fetch(url);
  return handleRequest(request);
}

function* categoryRequestFlow(action) {
  try {
    const category = yield call(coreRequestApi, action.data);
    yield put(categoryRequestSuccess(category.data));
  } catch (error) {
    yield put(categoryRequestError(error));
  }
}

function* saveCategoryRequestFlow(action) {
  try {
    const category = yield call(coreRequestApi, action.data);
    console.log('category', category);
    // yield put(categoryRequestSuccess(category.data));
  } catch (error) {
    // yield put(categoryRequestError(error));
  }
}

function* configRequestFlow(action) {
  try {
    const config = yield call(configRequestApi, action.data);

    yield put(configRequestSuccess(config));
  } catch (error) {
    yield put(configRequestError(error));
  }
}

function* industryRequestFlow(action) {
  try {
    const industry = yield call(industryRequestApi, action.lang);
    yield put(industryRequestSuccess(industry));
    yield put(setOddlot(false));
  } catch (error) {
    yield put(industryRequestError(error));
  }
}

function* advertiseRequestFlow(action) {
  try {
    const resData = yield call(advertiseRequestApi, action.lang);
    if (resData.http !== 200) {
      yield put({
        type: ADVERTISE_REQUESTING_ERROR,
        error: resData.message,
      });
    }

    const advertise = resData.data;
    if (advertise && advertise.start && advertise.end) {
      const startDt = moment(advertise.start, 'YYYY-MM-DD hh:mm:ss');
      const endDt = moment(advertise.end, 'YYYY-MM-DD hh:mm:ss');
      const now = moment(new Date(), 'YYYY-MM-DD hh:mm:ss');
      const isBetween = now.isBetween(startDt, endDt);
      if (isBetween) {
        yield put({
          type: ADVERTISE_REQUESTING_SUCCESS,
          resData: advertise,
        });
      } else {
        yield put({
          type: ADVERTISE_REQUESTING_ERROR,
          error: 'The program is expired!',
        });
      }
    }
  } catch (exception) {
    yield put({ type: ADVERTISE_REQUESTING_ERROR, error: exception });
  }
}

function* appsettingRequestFlow(action) {
  try {
    const resData = yield call(appsettingRequestApi, action);
    if (resData.http !== 200) {
      const msgErr = yield select(getMessage, resData);

      yield put({
        type: APPSETTING_REQUESTING_ERROR,
        error: msgErr,
      });
    } else {
      yield put({
        type: APPSETTING_REQUESTING_SUCCESS,
        data: resData.data,
      });
    }
  } catch (exception) {
    yield put({ type: APPSETTING_REQUESTING_ERROR, error: exception });
  }
}

/**
 * This func try to aim for covering almost call core servlet api cases
 * @param {isProtect} param0
 */
function* coreCommonRequestFlow({ params, callback, isProtect }) {
  try {
    const resData = yield call(coreRequestApi, params);
    const token = yield select((state) => state.client.token);

    if (resData.http !== 200) {
      let toastMsg = '';

      if (resData.http === 401) {
        if (isProtect) {
          if (token) {
            put(logoutRequest(token));
            put(unsetClient());
            put(Logout());
          }
          removeCookie('token');
          removeCookie('authen');
          yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
        }

        toastMsg = i18n.t('txt-valid-loss-session');
      } else {
        const msgErr = yield select(getMessage, resData);
        toastMsg = msgErr;
      }

      yield toastHandle(toastMsg);
    } else {
      if (callback && typeof callback == 'function') {
        callback(resData.data);
      }
    }
  } catch (error) {
    console.log(error);
    // yield toastHandle(error, 'txt-error');
  }
}

function* getChartInMemRequestFlow(action) {
  try {
    const { callback, mc } = action;
    const resData = yield call(getchartInmemData, mc);

    if (callback && typeof callback == 'function') {
      callback(resData);
    }
  } catch (exception) {
    console.error(exception);
  }
}

function* getFeedbackPopupFlow(action) {
  try {
    const { lang, callback } = action;
    const resData = yield call(getFeedbackTypeApi, lang);

    if (callback && typeof callback == 'function') callback(resData);
  } catch (error) {
    console.error(error);
  }
}

function* clientWatcher() {
  yield all([
    takeLatest(CONFIG_REQUESTING, configRequestFlow),
    takeLatest(CATEGORY_REQUESTING, categoryRequestFlow),
    takeLatest(SAVE_CATEGORY_REQUESTING, saveCategoryRequestFlow),
    takeLatest(INDUSTRY_CATEGORY_REQUESTING, industryRequestFlow),
    takeLatest(ADVERTISE_REQUESTING, advertiseRequestFlow),
    takeLatest(APPSETTING_REQUESTING, appsettingRequestFlow),
    takeLatest(USER_SETTING_REQUEST, coreCommonRequestFlow),
    takeEvery(GET_CHART_IN_MEM_DATA, getChartInMemRequestFlow),
    takeEvery(GET_FEEDBACK_TYPE_REQUEST, getFeedbackPopupFlow),
    takeLeading(CLIENT_COMMON_REQUEST, coreCommonRequestFlow),
  ]);
}

export default clientWatcher;
