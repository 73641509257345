import { constants } from '../../util/constant';
import {
  GET_BOND_ORDERLIST_REQUEST,
  GET_BOND_ORDERLIST_SUCCESS,
  GET_BOND_ORDERLIST_ERROR,
  SET_BOND_DETAIL_MODAL,
  GET_BOND_PRODUCTS_REQUEST,
  GET_BOND_ISSUERS_REQUEST,
  GET_BOND_DETAIL_REQUEST,
  GET_BOND_DETAIL_GUARANTEE_MODAL,
  SET_BOND_PRODUCT_DETAIL,
  GET_BOND_DEALLIST_REQUEST,
  GET_BOND_PORTFOLIO_REQUEST,
  GET_BOND_PORTFOLIO_SUCCESS,
  GET_BOND_PORTFOLIO_ERROR,
  GET_BOND_PREORDER_REQUEST,
  GET_PROD_IMCOME_FLOW_REQUEST,
  GET_PROD_IMCOME_FLOW_SUCCESS,
  GET_PROD_IMCOME_FLOW_ERROR,
  GET_DEAL4SALE_REQUEST,
  GET_BOND_RATE_CHART_REQUEST,
  SELL_ORDER_REQUEST,
  SET_BOND_ORDER,
  SELL_ORDER_REQUEST_RESET,
  BUY_ORDER_REQUEST,
  PRO_INVESTOR_REQUIRED,
  SET_DEAL_DETAIL,
  GET_BOND_FIX_REQUEST,
  GET_BOND_COMMON_REQUEST,
  SET_BOND_TAB,
  GET_BOND_COMMON_REQUEST2,
  GET_BOND_COMMON_REQUEST3,
  SET_BOND_REGIST_RETAIL_TRADING_MODAL,
  SET_BOND_REGIST_RETAIL_TRADING_SUCCESS_MODAL,
} from './constants';
import { v4 as uuidv4 } from 'uuid';

const GROUP = 'CORE',
  CHANNEL = 'WTS';

const buildParams = (cmd, data) => {
  const uuid = uuidv4();

  return {
    group: GROUP,
    cmd: cmd,
    rqId: uuid,
    channel: CHANNEL,
    data: data,
  };
};

export const setBondTabActive = (tabActive) => {
  return {
    type: SET_BOND_TAB,
    tabActive,
  };
};

export const getBondOrderListRequest = function getBondOrderListRequest(
  params
) {
  return {
    type: GET_BOND_ORDERLIST_REQUEST,
    params,
  };
};

export const getBondOrderListSucces = function getBondOrderListSucces(
  listBondOrderList
) {
  return {
    type: GET_BOND_ORDERLIST_SUCCESS,
    listBondOrderList,
  };
};

export const getBondOrderListError = function getBondOrderListError(error) {
  return {
    type: GET_BOND_ORDERLIST_ERROR,
    error,
  };
};

export const setBondDetailModalRequest = (
  state = true,
  subAccountNo = null
) => {
  return {
    type: SET_BOND_DETAIL_MODAL,
    state,
    subAccountNo,
  };
};

export const getBondProducts = (
  invtAmt = '',
  proInvtYN = '',
  listTp = '',
  issrCode = '',
  bndCode = '',
  langTp = 'vi',
  rateSort = '0',
  prodTp = constants.bondProductType.Var + ''
) => {
  debugger;
  const params = buildParams('getBondProductList', {
    invtAmt,
    proInvtYN,
    listTp,
    issrCode,
    bndCode,
    langTp,
    rateSort,
    prodTp,
  });

  return {
    type: GET_BOND_PRODUCTS_REQUEST,
    params,
  };
};

export const getBondIssuers = (langTp = 'vi') => {
  const params = buildParams('getBondIssuerList', {
    langTp,
  });

  return {
    type: GET_BOND_ISSUERS_REQUEST,
    params,
  };
};

export const getBondDetail = (
  bndCode,
  langTp = 'vi',
  issrCode = '',
  proInvtYN = '',
  listTp = ''
) => {
  const params = buildParams('getBondList', {
    bndCode,
    langTp,
    issrCode,
    proInvtYN,
    listTp,
  });

  return {
    type: GET_BOND_DETAIL_REQUEST,
    params,
  };
};

export const setBondProductDetail = (data) => {
  return {
    type: SET_BOND_PRODUCT_DETAIL,
    data,
  };
};

export const getBondDealListRequest = function getBondDealListRequest(
  params,
  callback
) {
  return {
    type: GET_BOND_DEALLIST_REQUEST,
    params,
    callback,
  };
};

export const getCollateralDetail = function getCollateralDetail(data) {
  return {
    type: GET_BOND_DETAIL_GUARANTEE_MODAL,
    data,
  };
};

export const getBondPortfolioRequest = function getBondPortfolioSuccess(
  params
) {
  return {
    type: GET_BOND_PORTFOLIO_REQUEST,
    params,
  };
};

export const getBondPortfolioSuccess = function getBondPortfolioSuccess(
  listBondPortfolio
) {
  return {
    type: GET_BOND_PORTFOLIO_SUCCESS,
    listBondPortfolio,
  };
};

export const getBondPortfolioError = function getBondPortfolioError(error) {
  return {
    type: GET_BOND_PORTFOLIO_ERROR,
    error,
  };
};

export const getBondPreOrder = (
  cif,
  acntNo,
  subAcntNo,
  prdCode,
  quantity,
  tranDt,
  side
) => {
  const params = buildParams('getBondPreOrder', {
    cif,
    acntNo,
    subAcntNo,
    prdCode,
    quantity,
    tranDt,
    side,
  });

  return {
    type: GET_BOND_PREORDER_REQUEST,
    params,
  };
};

export const getProIncomeFlowRequest = function getProIncomeFlowRequest(
  params
) {
  return {
    type: GET_PROD_IMCOME_FLOW_REQUEST,
    params,
  };
};

export const getProIncomeFlowSuccess = function getProIncomeFlowSuccess(
  proIncomeFlow
) {
  return {
    type: GET_PROD_IMCOME_FLOW_SUCCESS,
    proIncomeFlow,
  };
};

export const getProIncomeFlowError = function getProIncomeFlowError(error) {
  return {
    type: GET_PROD_IMCOME_FLOW_ERROR,
    error,
  };
};

export const getDeal4Sale = (data) => {
  const params = buildParams('getDeals4Sell', data);
  return {
    type: GET_DEAL4SALE_REQUEST,
    params,
  };
};

export const setBondOrder = (data) => {
  return {
    type: SET_BOND_ORDER,
    data,
  };
};

export const sellBondOrder = (params, callback = null) => {
  return {
    type: SELL_ORDER_REQUEST,
    params,
    callback,
  };
};

export const buyBondOrder = (params, callback = null) => {
  return {
    type: BUY_ORDER_REQUEST,
    params,
    callback,
  };
};

export const resetBondOrder = () => {
  return {
    type: SELL_ORDER_REQUEST_RESET,
  };
};

export const getBondRateChartRequest = (prdCode, tranDt) => {
  const params = buildParams('getBondRateChart', {
    prdCode,
    tranDt,
  });

  return {
    type: GET_BOND_RATE_CHART_REQUEST,
    params,
  };
};

export const setProInvRequire = (state = false) => {
  return {
    type: PRO_INVESTOR_REQUIRED,
    state,
  };
};

export const setDeal = (deal) => {
  return {
    type: SET_DEAL_DETAIL,
    deal,
  };
};

export const getFixBondProductRequest = (params) => {
  return {
    type: GET_BOND_FIX_REQUEST,
    params,
  };
};

export const getBondCommonRequest = (params, callback, isProtect = false) => {
  return {
    type: GET_BOND_COMMON_REQUEST,
    params,
    callback,
    isProtect,
  };
};

export const getBondCommonRequest2 = (params, callback, isProtect = false) => {
  return {
    type: GET_BOND_COMMON_REQUEST2,
    params,
    callback,
    isProtect,
  };
};

export const getBondCommonRequest3 = (params, callback, isProtect = false) => {
  return {
    type: GET_BOND_COMMON_REQUEST3,
    params,
    callback,
    isProtect,
  };
};

export const setRegistBondTradingRetailModal = (show = false) => {
  return {
    type: SET_BOND_REGIST_RETAIL_TRADING_MODAL,
    show,
  };
};

export const setRegistBondTradingRetailSuccessModal = (show = false) => {
  return {
    type: SET_BOND_REGIST_RETAIL_TRADING_SUCCESS_MODAL,
    show,
  };
};
