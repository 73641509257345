import React, { useEffect, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import DatePicker, { registerLocale } from 'react-datepicker';
import PerfectScrollBar from 'react-perfect-scrollbar';
import { Portal } from 'react-overlays';
import ReactSelect from 'react-select';
import { formatToNewDate, numberFormat } from '../../../../../util';
import CustomDatePicker from '../component/datePicker';
import CustomInputText from '../component/inputText';
import moment from 'moment';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const CalendarContainer = ({ children }) => {
  const el = document.getElementById('calendar-portal');
  return <Portal container={el}>{children}</Portal>;
};

const colgroup = (
  <colgroup>
    <col width="92px"></col>
    <col width="92px"></col>
  </colgroup>
);

const PackItem = withNamespaces('translations')(
  ({
    t,
    pf,
    overView,
    packIndex,
    isFullPack,
    pack,
    handleChangeDateDepoItem,
    handleChangeAmount,
    getEstPFPlanFlow,
  }) => {
    console.log('pf', pf);
    const [startDepositDate, setStartDepositDate] = useState(
      formatToNewDate(pack.stDepoDt)
    );
    const [buyingOrderDate, setBuyingOrderDate] = useState(
      formatToNewDate(pack.buyDt)
    );

    const handleChangeStartDepositDate = (date) => {
      setStartDepositDate(date);
      getEstPFPlanFlow(
        (packIndex + 1).toString(),
        moment(date).format('YYYYMMDD'),
        moment(buyingOrderDate).format('YYYYMMDD')
      );
    };

    const handleChangeBuyingOrderDate = (date) => {
      setBuyingOrderDate(date);
      getEstPFPlanFlow(
        (packIndex + 1).toString(),
        moment(startDepositDate).format('YYYYMMDD'),
        moment(date).format('YYYYMMDD')
      );
    };

    return (
      <div className={`pack-item`}>
        <div className="d-flex justify-content-between align-items-center">
          {pack && (
            <div className="d-flex gap-4 align-items-center">
              <span className="fz_20 fw-500 text--light">{`${
                pack.packNo !== 0
                  ? t('pinefolio.plan.pack') + ' ' + pack.packNo
                  : t('pinefolio.plan.fullSet')
              } = ${numberFormat(pack.targetPlanAmt, 0, '0')}`}</span>
              <OverlayTrigger
                trigger={['hover', 'focus']}
                placement="top"
                className="portfolio-overlay"
                overlay={
                  <Tooltip style={{ 'z-index': '99999' }}>
                    {t('pinefolio.plan.setValue.explain')}
                  </Tooltip>
                }
              >
                <span className="icon iInfor iTooltip"></span>
              </OverlayTrigger>
            </div>
          )}
        </div>
        <div className="pack-symbols">
          {pf &&
            pf.symbols &&
            pf.symbols
              .filter((item) => item.packNo == packIndex + 1 || isFullPack)
              .map((symbol, index1) => (
                <>
                  {index1 != 0 && (
                    <span className="fz_13 fw-500 text--light3 mr-1">+</span>
                  )}
                  <span className="fz_13 fw-500 text--light mr-1">
                    {symbol.symbol}
                  </span>
                  <span className="fz_13 fw-500 text--light3 mr-1">
                    {numberFormat(symbol.qty, 0, '')}
                  </span>
                </>
              ))}
        </div>
        <div className="invest-calendar fz_13">
          <div className="invest-calendar__item">
            <span className="invest-calendar__item--label text--light3">
              {t('txt-label-start-date')}
            </span>
            <span className="invest-calendar__item--value">
              <div className="filter-control-calendar filter-control-calendar--readOnly">
                <DatePicker
                  selected={startDepositDate}
                  onChange={(date) =>
                    handleChangeStartDepositDate(date, packIndex)
                  }
                  dateFormat="dd/MM/yyyy"
                  popperContainer={CalendarContainer}
                  placeholderText="dd/mm/yyyy"
                  minDate={new Date()}
                  readOnly={true}
                />
                <span className="icon iCalendar"></span>
              </div>
            </span>
          </div>
          <div className="invest-calendar__item">
            <span className="invest-calendar__item--label text--light3">
              <span className="text--light3">
                {t('pinefolio.plan.buyingOrderDate.name')}
              </span>
              <OverlayTrigger
                trigger={['hover', 'focus']}
                placement="top"
                className="portfolio-overlay"
                overlay={
                  <Tooltip style={{ 'z-index': '99999' }}>
                    {t('pinefolio.plan.createPlan.buyingOrderDateExplain')}
                  </Tooltip>
                }
              >
                <span className="icon iInfor iTooltip"></span>
              </OverlayTrigger>
            </span>
            <span className="invest-calendar__item--value text--light">
              <div className="filter-control-calendar">
                <DatePicker
                  selected={buyingOrderDate}
                  onChange={(date) =>
                    handleChangeBuyingOrderDate(date, packIndex)
                  }
                  dateFormat="dd/MM/yyyy"
                  popperContainer={CalendarContainer}
                  placeholderText="dd/mm/yyyy"
                  minDate={new Date()}
                  maxDate={
                    new Date(
                      formatToNewDate(pack.stDepoDt).setDate(
                        formatToNewDate(pack.stDepoDt).getDate() + 90
                      )
                    )
                  }
                />
                <span className="icon iCalendar"></span>
              </div>
            </span>
          </div>
        </div>
      </div>
    );
  }
);

export default PackItem;
