import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import AuthenStep from '../../../../components/common/auth';

import {
  makeGetBrokerInfo,
  makeGetDefaultAccount,
  makeGetListAccount,
} from '../../../../lib/selector';
import { v4 as uuidv4 } from 'uuid';
import {
  getCommonRequest,
  setChangeFeeOpenModal,
} from '../../../customer/actions';
import {
  PARTNER_REQUEST_TYPE,
  PARTNER_SIDE_REQUEST,
} from '../../../../util/constant';
import { setAuth, setToast } from '../../../client/actions';
import ViewFeeDefault from '../partnership/viewFeeDefault';

const ModalRegisterService = withNamespaces('translations')(
  ({ t, service, onSuccess }) => {
    const dispatch = useDispatch();
    const theme = useSelector((state) => state.client.currentTheme);
    const {
      auth,
      token,
      setting: { lang = 'vi' },
    } = useSelector((state) => state.client);
    const defaultAccount = useSelector(makeGetDefaultAccount());
    const [defaultFee, setDefaultFee] = useState([]);
    const [serviceToRegister, setServiceToRegister] = useState(false);
    const [oneTimeToken, setOneTimeToken] = useState();
    const { serList4Cus } = useSelector((state) => state.customer);

    const toastHandle = (msg, title = t('txt-notice')) => {
      const toastMsg = {
        id: Math.random(),
        msg: msg,
        title: title,
      };
      dispatch(setToast(toastMsg));
    };

    const handleCancelRegister = () => {
      setServiceToRegister(false);
    };

    const handleSubmit = () => {
      if (!oneTimeToken) {
        toastHandle(t('account.authen.letEnterOTPCorrect'));
        return;
      }
      const uuid = uuidv4();
      const params = {
        group: 'CORE',
        user: token.user,
        session: token.session,
        cmd: 'regUnregRef',
        rqId: uuid,
        channel: 'WTS',
        type: '6',
        token: oneTimeToken,
        data: {
          cif: token.cif,
          acntNo: defaultAccount.acntNo,
          vsdAcntNo: defaultAccount.vsdAcntNo,
          side: PARTNER_SIDE_REQUEST.customer,
          brkId: serList4Cus.brokerId,
          reqTp: PARTNER_REQUEST_TYPE.registerService,
          serTp: service.serTp,
          custPhone: token.cellPhone,
        },
      };
      const callback = (data) => {
        toastHandle(t('partner.createRequestSuccess'));
        handleCancelRegister();
        onSuccess();
      };
      dispatch(getCommonRequest(params, callback, true));
    };

    useEffect(() => {
      if (!serviceToRegister) return;
      const uuid = uuidv4();
      const query = {
        group: 'CORE',
        user: token.user,
        session: token.session,
        cmd: 'getFeePolicy',
        rqId: uuid,
        channel: 'WTS',
        data: {
          cif: token.cif,
          acntNo: defaultAccount.acntNo,
          grpAcctTp: '1',
          serTp: `${service.serTp}`,
        },
      };

      dispatch(
        getCommonRequest(
          query,
          (res) => {
            setDefaultFee(res);
          },
          true
        )
      );
    }, [serviceToRegister]);

    const generateFree = useCallback((value = []) => {
      if (value.length === 1) {
        return (
          <div className="service__item">
            <div className="text text--sm text--light3">
              {t('partner.feePolicyApplied')}
            </div>
            <div className="text text--sm text--light">{`${value[0].fee} ${value[0].levyTp}`}</div>
          </div>
        );
      }
      return value.map((p, i) => (
        <div className="service__item" key={i}>
          <div className="text text--sm text--light3">{`${t('partner.rateD')} ${
            p.frVal
          } - ${p.toVal}`}</div>
          <div className="text text--sm text--light">{`${p.fee} ${p.levyTp}`}</div>
        </div>
      ));
    }, []);

    return (
      <>
        <button
          className={`btn fw-500 ${
            auth && auth.isVerified ? 'btn-register' : 'btn--authen'
          }`}
          onClick={() => {
            if (auth && auth.isVerified) {
              setServiceToRegister(true);
            } else {
              dispatch(setAuth());
            }
          }}
        >
          <span>{t('txt-register')}</span>
        </button>
        <Modal
          show={serviceToRegister}
          onHide={handleCancelRegister}
          onEscapeKeyDown={handleCancelRegister}
          dialogClassName="wts-modal"
          backdropClassName="wts-modal-backdrop"
          contentClassName={`${
            theme.name || 'dark'
          } wts-modal-content wts-modal-content--sm2`}
          centered
          style={{ zIndex: 11114 }}
        >
          <div className="modal-partnership-service">
            <div className="modal-partnership-service__head">
              <div className="title">
                {t('partner.requestTypeOption.serviceRegistration')}
              </div>
              <div className="right-section" onClick={handleCancelRegister}>
                <span className={'icon iClose'}></span>
              </div>
            </div>
            <div className="modal-partnership-service__body">
              <div className="partner">
                <div className="partner__item">
                  <div className="text text--sm text--light3">
                    {t('partner.referralCode')}
                  </div>
                  <div className="text text--sm text--light">
                    {serList4Cus.brokerId}
                  </div>
                </div>
                <div className="partner__item">
                  <div className="text text--sm text--light3">
                    {t('partner.referralName')}
                  </div>
                  <div className="text text--sm text--light">
                    {serList4Cus.brokerNm}
                  </div>
                </div>
              </div>
              <div className="service">
                <ViewFeeDefault t={t} listFee={defaultFee} />
              </div>
              <AuthenStep
                setOneTimeToken={setOneTimeToken}
                trackMixpanel
                isBorder={false}
              />
              <div className="action">
                <button
                  className={'btn btn--primary w-100 h-32'}
                  onClick={handleSubmit}
                >
                  {t('txt-confirm')}
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  }
);
export default ModalRegisterService;
