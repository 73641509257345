import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';

import { makeGetToken } from '../../../lib/selector';

import { GiAnticlockwiseRotation } from 'react-icons/gi';
import RenderFieldInput from '../../input/renderFieldInput';
import { v4 as uuidv4 } from 'uuid';
import { translate } from 'react-i18next';

const _status = ['Đã về', 'Chưa trả'];

class FormQuyenCoPhieu extends Component {
  constructor() {
    super();

    this.state = {
      account: null,
    };
  }

  submit = (values) => {
    const { token } = this.props;
  };

  _handleReset = () => {};

  render() {
    const { handleSubmit, pristine, submitting, reset, t } = this.props;
    const _rights = [
      { value: '1', label: t('txt-stt-right-buy') },
      { value: '2', label: t('txt-stt-right-cash') },
      { value: '3', label: t('txt-stt-right-stock') },
    ];
    return (
      <form
        className="form-hisMargin d-flex"
        onSubmit={handleSubmit(this.submit)}
      >
        <Field
          name="symbol"
          type="text"
          id="username"
          className="form-control form-input fz_14 text-uppercase"
          autocomplete="off"
          placeholder={t('txt-symbol')}
          component={RenderFieldInput}
        />
        <Field
          name="right"
          component="select"
          className="ml-2 form-control form-input fz_14"
          style={{ width: '165px' }}
        >
          <option value="0">{t('txt-all')}</option>
          {_rights.map((rightOption) => (
            <option value={rightOption.value} key={rightOption.value}>
              {rightOption.label}
            </option>
          ))}
        </Field>
        {/* <Field
          name="status"
          component="select"
          className="ml-2 form-control form-input fz_14"
          style={{ width: '165px' }}
        >
          <option value="all">{'Tất cả trạng thái'}</option>
          {_status.map((statusOption) => (
            <option value={statusOption} key={statusOption}>
              {statusOption}
            </option>
          ))}
        </Field> */}
        <button
          className="ml-2 btn-reload mr-2"
          disabled={pristine || submitting}
          onClick={reset}
        >
          <GiAnticlockwiseRotation className="fz_14 text-white" />
        </button>
      </form>
    );
  }
}

FormQuyenCoPhieu = reduxForm({
  form: 'quyenCoPhieu',
  enableReinitialize: true,
})(FormQuyenCoPhieu);

const selector = formValueSelector('quyenCoPhieu');

const makeMapStateToProps = () => {
  const getToken = makeGetToken();

  const mapStateToProps = (state, props) => {
    const { symbol, right, status } = selector(
      state,
      'symbol',
      'right',
      'status'
    );

    const token = getToken(state);
    return {
      token,
      symbol,
      right,
      status,
      initialValues: {
        symbol: '',
        right: 'all',
        status: 'all',
      },
    };
  };
  return mapStateToProps;
};

FormQuyenCoPhieu = connect(makeMapStateToProps)(FormQuyenCoPhieu);

export default translate('translations')(FormQuyenCoPhieu);
