import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Product from './components/product';
import DealList from './components/dealList';
import OrderList from './components/orderList';
import Portfolio from './components/portfolio';
import { withNamespaces } from 'react-i18next';
import { setLogin } from '../client/actions';
import { useLocation } from 'react-router-dom';
import { setBondTabActive } from './action';
import { bondConstants, constants } from '../../util/constant';
import { Mixpanel } from '../../lib/mixpanel';

const Index = withNamespaces('translations')(({ t }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const myparam = location.state;
  const { token, auth } = useSelector((state) => state.client);
  const { tabActive } = useSelector((state) => state.bond);

  useEffect(() => {
    if (tabActive) Mixpanel.bond.viewFunctionTab(tabActive);
    // exclue product list
  }, [tabActive]);

  useEffect(() => {
    if (myparam) {
      dispatch(setBondTabActive(myparam.tabActive));
    }
  }, [myparam]);

  return (
    <div className="bond-page">
      <div className="bond-content">
        <div className="bond card-panel-2">
          <div className="bond-header card-panel-2-header">
            <div className="bond-header__left">
              {[
                {
                  id: bondConstants.tabs.PRODUCT_LIST,
                  name: t('bond.bondProduct.name'),
                  needLogin: false,
                },
                {
                  id: bondConstants.tabs.ORDER_LIST,
                  name: t('bond.orderList.name'),
                  needLogin: true,
                },
                {
                  id: bondConstants.tabs.DEAL_LIST,
                  name: t('bond.dealList.name'),
                  needLogin: true,
                },
                {
                  id: bondConstants.tabs.PORTFOLIO,
                  name: t('bond.portfolio.name'),
                  needLogin: true,
                },
              ].map((item, i) => (
                <span
                  key={i}
                  className={`card-panel-2__tab ${
                    tabActive == item.id ? 'active' : ''
                  }`}
                  onClick={() => {
                    if (!item.needLogin) dispatch(setBondTabActive(item.id));
                    else {
                      if (token) dispatch(setBondTabActive(item.id));
                      else dispatch(setLogin());
                    }
                  }}
                >
                  {item.name}
                </span>
              ))}
            </div>
            {token?.isProInvt == 'Y' && (
              <div className="bond-header__right">
                <span className="icon iStar"></span>
                <span className="r ml-2">{t('bond.professional.name')}</span>
              </div>
            )}
          </div>
          <div className="bond-body card-panel-2-body">
            {tabActive == bondConstants.tabs.PRODUCT_LIST && <Product />}
            {tabActive == bondConstants.tabs.ORDER_LIST && <OrderList />}
            {tabActive == bondConstants.tabs.DEAL_LIST && <DealList />}
            {tabActive == bondConstants.tabs.PORTFOLIO && <Portfolio />}
          </div>
        </div>
      </div>
    </div>
  );
});

export default Index;
