import React, { Fragment } from 'react';
import { connect, useSelector } from 'react-redux';
import { Col, ListGroup } from 'react-bootstrap';

import PerfectScrollBar from 'react-perfect-scrollbar';

import { MdVerifiedUser, MdPhone } from 'react-icons/md';
import { GoUnverified } from 'react-icons/go';
import { translate, withNamespaces } from 'react-i18next';
import './../index.scss';

const Authorization = withNamespaces('translations')(({ t }) => {
  const { defaultAccount } = useSelector((state) => state.socket);
  const { auth, token } = useSelector((state) => state.client);

  return (
    <PerfectScrollBar>
      <div className="authorization-container mb-3">
        <div className="w-100 d-flex flex-column align-items-center">
          <div className="authorization-detail account-panel col-md-8">
            <div className="account-panel-header">
              <label className="text-white fz_16">
                {t('txt-label-authorization-person')}
              </label>
              <span className={'fz_13 ' + (auth.isVerified ? 'i' : 'd')}>
                {auth && auth.isVerified ? (
                  <Fragment>
                    <MdVerifiedUser className="mr-2" />
                    {t('txt-da-xacthuc')}
                  </Fragment>
                ) : (
                  <Fragment>
                    <GoUnverified className="mr-2" />
                    {t('txt-chua-xacthuc')}
                  </Fragment>
                )}
              </span>
            </div>
            <div className="account-panel-body">
              <div className="account-panel-items">
                <div className="account-panel-item">
                  <span className="account-panel-item__name">
                    {t('txt-label-fullname')}
                  </span>
                  <span className="account-panel-item__value">
                    {' '}
                    {token && token.name}
                  </span>
                  <span className="account-panel-item__action"></span>
                </div>
                <div className="account-panel-item">
                  <span className="account-panel-item__name">
                    {t('txt-label-identification')}
                  </span>
                  <span className="account-panel-item__value">{'-'}</span>
                  <span className="account-panel-item__action"></span>
                </div>
                <div className="account-panel-item">
                  <span className="account-panel-item__name">
                    {t('txt-label-issued-on')}
                  </span>
                  <span className="account-panel-item__value">{'-'}</span>
                  <span className="account-panel-item__action"></span>
                </div>
                <div className="account-panel-item">
                  <span className="account-panel-item__name">
                    {t('txt-label-place')}
                  </span>
                  <span className="account-panel-item__value">{'-'}</span>
                  <span className="account-panel-item__action"></span>
                </div>
                <div className="account-panel-item">
                  <span className="account-panel-item__name">
                    {t('txt-label-gender')}
                  </span>
                  <span className="account-panel-item__value">{'-'}</span>
                  <span className="account-panel-item__action"></span>
                </div>
                <div className="account-panel-item">
                  <span className="account-panel-item__name">
                    {t('txt-label-contact-info')}
                  </span>
                  <span className="account-panel-item__value">{'-'}</span>
                  <span className="account-panel-item__action"></span>
                </div>
                <div className="account-panel-item">
                  <span className="account-panel-item__name">
                    {t('txt-label-authorization-type')}
                  </span>
                  <span className="account-panel-item__value">{'-'}</span>
                  <span className="account-panel-item__action"></span>
                </div>
                <div className="account-panel-item">
                  <span className="account-panel-item__name">
                    {t('txt-label-start-date')}
                  </span>
                  <span className="account-panel-item__value">{'-'}</span>
                  <span className="account-panel-item__action"></span>
                </div>
                <div className="account-panel-item">
                  <span className="account-panel-item__name">
                    {t('txt-label-end-date')}
                  </span>
                  <span className="account-panel-item__value">{'-'}</span>
                  <span className="account-panel-item__action"></span>
                </div>
              </div>
            </div>
          </div>
          <div className="authorization-detail account-panel col-md-8">
            <div className="account-panel-header">
              <label className="text-white fz_16">
                {t('txt-label-authorization-person-other')}
              </label>
            </div>
            <div className="account-panel-body">
              <div className="account-panel-items">
                <div className="account-panel-item">
                  <span className="account-panel-item__name">
                    {t('txt-label-fullname')}
                  </span>
                  <span className="account-panel-item__value">
                    {token && token.name}
                  </span>
                  <span className="account-panel-item__action"></span>
                </div>
                <div className="account-panel-item">
                  <span className="account-panel-item__name">
                    {t('txt-label-identification')}
                  </span>
                  <span className="account-panel-item__value">{'-'}</span>
                  <span className="account-panel-item__action"></span>
                </div>
                <div className="account-panel-item">
                  <span className="account-panel-item__name">
                    {t('txt-label-issued-on')}
                  </span>
                  <span className="account-panel-item__value">{'-'}</span>
                  <span className="account-panel-item__action"></span>
                </div>
                <div className="account-panel-item">
                  <span className="account-panel-item__name">
                    {t('txt-label-place')}
                  </span>
                  <span className="account-panel-item__value">{'-'}</span>
                  <span className="account-panel-item__action"></span>
                </div>
                <div className="account-panel-item">
                  <span className="account-panel-item__name">
                    {t('txt-label-gender')}
                  </span>
                  <span className="account-panel-item__value">{'-'}</span>
                  <span className="account-panel-item__action"></span>
                </div>
                <div className="account-panel-item">
                  <span className="account-panel-item__name">
                    {t('txt-label-contact-info')}
                  </span>
                  <span className="account-panel-item__value">{'-'}</span>
                  <span className="account-panel-item__action"></span>
                </div>
                <div className="account-panel-item">
                  <span className="account-panel-item__name">
                    {t('txt-label-authorization-type')}
                  </span>
                  <span className="account-panel-item__value">{'-'}</span>
                  <span className="account-panel-item__action"></span>
                </div>
                <div className="account-panel-item">
                  <span className="account-panel-item__name">
                    {t('txt-label-start-date')}
                  </span>
                  <span className="account-panel-item__value">{'-'}</span>
                  <span className="account-panel-item__action"></span>
                </div>
                <div className="account-panel-item">
                  <span className="account-panel-item__name">
                    {t('txt-label-end-date')}
                  </span>
                  <span className="account-panel-item__value">{'-'}</span>
                  <span className="account-panel-item__action"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PerfectScrollBar>
  );
});

export default Authorization;
