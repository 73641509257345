import i18n from '../i18n';

export const required = (value) => {
  return value ? undefined : i18n.t('txt-valid-input-required');
};

export const uploadFileRequired = (value) => {
  return value ? undefined : i18n.t('txt-valid-upload-file-required');
};

export const maxLength = (max, value) => {
  return value && value.length > max
    ? `${i18n.t('txt-valid-enter')} ${i18n.t('txt-title-max')} ${max} ${i18n.t(
        'txt-valid-characters'
      )}`
    : undefined;
};

export const minLength = (min, value) =>
  value && value.length < min
    ? `${i18n.t('txt-valid-enter')} ${i18n.t('txt-title-min')} ${min} ${i18n.t(
        'txt-valid-characters'
      )}`
    : undefined;

export const number = (value) => {
  return value && isNaN(Number(value)) ? 'Hãy nhập số' : undefined;
};

export const validatePhone = (value) => {
  let vnf_regex = /((09|03|07|08|05)+([0-9]{8})\b)/g;
  const _value = value.replace(/ /g, '');
  if (!_value || (vnf_regex.test(_value) && _value.indexOf('_') < 0))
    return undefined;

  return i18n.t('txt-valid-phone-incorrect-format');
};

export const validateBirth = (value) => {
  let vnf_regex =
    /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;
  // console.log(vnf_regex.test(value))
  if (!value || (vnf_regex.test(value) && value.indexOf('_') < 0))
    return undefined;

  return i18n.t('txt-valid-date-incorrect-format');
};

export const validateEmail = (value) => {
  var re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (!value || re.test(value)) return undefined;

  return i18n.t('txt-valid-email-incorrect-format');
};

export const validateByRegex = (regex) => (value) => {
  if (!value || (regex.test(value) && value.indexOf('_') < 0)) return undefined;
  return i18n.t('txt-valid-input-not-allow-special-character');
};
