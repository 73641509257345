import React, { useEffect, useRef, useState } from 'react';
import './_index.scss';
import { useSelector } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import { useHistory } from 'react-router';

const appUrl = `${process.env.REACT_APP_API_URL}`;
function handleRequest(request) {
  return (
    request
      // .then(handleApiErrors)
      .then((response) => response)
      .then((json) => json)
      .catch((error) => {
        throw error;
      })
  );
}

function fetcher(data) {
  const url = `${appUrl}/CoreServlet.pt`;
  const request = fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
    body: JSON.stringify(data),
  });
  return handleRequest(request);
}

const TradingPersional = () => {
  const [htmlContent, setHtmlContent] = useState(null);
  const { token } = useSelector((state) => state.client);
  const theme = useSelector((state) => state.client.currentTheme);
  const { lang } = useSelector((state) => state.client?.setting);
  const iframeRef = useRef(null);

  const history = useHistory();

  useEffect(() => {
    if (!token) {
      history.push('/');
    }
  }, [token]);

  useEffect(() => {
    if (!token) return;
    const paramms = {
      group: 'CORE',
      user: token.user,
      session: token.session,
      cmd: 'getTradingPersonal',
      channel: 'WTS',
      data: {
        cif: token.cif,
        theme: theme ? theme.name : 'dark',
        language: lang,
      },
    };
    fetcher(paramms).then(async (response) => {
      const html = await response.text();
      setHtmlContent(html);
    });
  }, [token, theme?.name, lang]);

  return (
    <div className="trading-personal-container">
      {htmlContent ? (
        <iframe
          title="TradingPersonal"
          ref={iframeRef}
          srcDoc={htmlContent}
          width="100%"
        />
      ) : (
        <Spinner animation="border" size="" />
      )}
    </div>
  );
};
export default TradingPersional;
