import React, { useRef } from 'react';
import { GiAnticlockwiseRotation } from 'react-icons/gi';

export function ReloadButton(props) {
  const btnReloadRefs = useRef(null);
  return (
    <button
      ref={btnReloadRefs}
      disabled={props.disabled}
      className={props.className}
      onClick={() => {
        props.onClick();
        btnReloadRefs && btnReloadRefs.current.blur();
      }}
      type={props.type || 'submit'}
    >
      {props.label || <GiAnticlockwiseRotation className="fz_14 text-white" />}
    </button>
  );
}
