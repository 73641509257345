import React from 'react';
import { withNamespaces } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';

const Popup = withNamespaces('translations')(
  ({
    t,
    showModal,
    dialogClassName,
    backdropClassName,
    contentClassName,
    headerText,
    title,
    description,
    type,
    onConfirm,
    onCancel,
    txtConfirm,
    txtCancel,
    infor,
    className,
    onClose,
  }) => {
    return (
      <Modal
        show={showModal}
        dialogClassName={dialogClassName}
        backdropClassName={backdropClassName}
        contentClassName={contentClassName}
        centered
        onHide={onClose}
        className={className}
      >
        <div className="popup-header">
          {infor && (
            <span
              className="icon iInfor cursor-pointer"
              onClick={() => window.open(infor)}
            ></span>
          )}
          <span className="popup-header__title fw-500">{t(headerText)}</span>
          {!!onClose && (
            <span
              className="icon iClose cursor-pointer"
              onClick={onClose}
            ></span>
          )}
        </div>
        <div className="popup-body">
          {(type == 1 && (
            <div className="popup-body__icon icon--notificaton">
              <span className={`icon iBell`}></span>
            </div>
          )) ||
            (type == 2 && (
              <div className="popup-body__icon icon--warning">
                <span className={`icon iWarming`}></span>
              </div>
            )) ||
            (type == 3 && (
              <div className="popup-body__icon icon--success">
                <span className={`icon iSuccess`}></span>
              </div>
            ))}
          <div className="popup-body__content">
            <span className="popup-body__title text--light fw-500">
              {t(title)}
            </span>
            <span className="popup-body__description text--light3">
              {t(description)}
            </span>
          </div>
        </div>
        <div className="popup-footer">
          <span className="popup-footer__button">
            {!!onConfirm && (
              <button className="btn btn--primary" onClick={onConfirm}>
                {t(txtConfirm)}
              </button>
            )}
            {!!onCancel && (
              <button className="btn btn--cancel" onClick={onCancel}>
                {t(txtCancel)}
              </button>
            )}
          </span>
        </div>
      </Modal>
    );
  }
);

export default Popup;
