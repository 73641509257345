import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';

import './index.scss';
import moment from 'moment';

const Toast = (props) => {
  const {
    type,
    detail,
    toastList,
    position,
    autoDelete,
    dismissTime,
    t,
    clearAll,
  } = props;
  const [list, setList] = useState(toastList);

  useEffect(() => {
    setList([...toastList]);
  }, [toastList]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (autoDelete && toastList.length && list.length) {
        deleteToast(toastList[0].id);
      }
    }, dismissTime);

    return () => {
      clearInterval(interval);
    };
  }, [toastList, autoDelete, dismissTime, list]);

  const deleteToast = (id) => {
    const listItemIndex = list.findIndex((e) => e.id === id);
    const toastListItem = toastList.findIndex((e) => e.id === id);
    list.splice(listItemIndex, 1);
    toastList.splice(toastListItem, 1);
    setList([...list]);
  };

  if (!list || !!list?.length === false) return <></>;
  else
    return (
      <>
        <div className={`notification-container ${position}`}>
          {list.length > 1 && (
            <div
              className="close-all-toast"
              onClick={(e) => {
                e.preventDefault();
                if (clearAll) {
                  clearAll();
                }
              }}
            >
              <button className="btn btn--cancel">
                <span className="icon iClose"></span>
              </button>
            </div>
          )}

          {list.map((toast, i) => {
            return (
              <div
                key={i}
                style={{
                  zIndex: i + 10,
                  boxShadow: '0px 0px 12px 11px rgba(0, 0, 0, 0.21)',
                  top: i * 10 + 'px',
                }}
                className={`notification toast ${
                  list.length > 1 ? 'toast-stacked' : ''
                } ${position}`}
              >
                <div className="toast-body">
                  <div className="toast-icon">
                    {type == 1 ? (
                      <div className="icon iFail"></div>
                    ) : type == 2 ? (
                      <div className="icon iSuccess"></div>
                    ) : (
                      <div className="icon iNotice"></div>
                    )}
                  </div>
                  <div className="toast-content">
                    <div className="toast-title">
                      {toast?.title || t('txt-notice')}
                    </div>
                    {toast?.htmlContent && (
                      <div
                        className="toast-description"
                        dangerouslySetInnerHTML={{
                          __html: toast?.description,
                        }}
                      />
                    )}

                    {!toast?.htmlContent && (
                      <div
                        className={`toast-description ${
                          list.length > 1 ? 'reduce-text' : ''
                        }`}
                      >
                        {toast?.description}
                      </div>
                    )}
                  </div>
                </div>
                <div className="toast-action">
                  <div
                    className="icon iClose"
                    onClick={() => deleteToast(toast?.id)}
                  ></div>
                  {detail && (
                    <div className="btn btn--primary2 text-white">
                      {t('txt-label-details')}
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </>
    );
};

Toast.propTypes = {
  toastList: PropTypes.array.isRequired,
  position: PropTypes.string,
  autoDelete: PropTypes.bool,
  dismissTime: PropTypes.number,
  clearAll: PropTypes.func,
};

export default translate('translations')(Toast);
