import {
  ORD_COND_REQUESTING,
  LIST_COND_REQUESTING,
  ORD_COND_DETAIL_REQUESTING,
  ORD_COND_CANCEL_REQUESTING,
} from './constants';

export function ordCondRequest(params) {
  return {
    type: ORD_COND_REQUESTING,
    params,
  };
}

export function listCondRequest(params) {
  return {
    type: LIST_COND_REQUESTING,
    params,
  };
}

export function detailCondRequest(params) {
  return {
    type: ORD_COND_DETAIL_REQUESTING,
    params,
  };
}

export function cancelCondRequest(params) {
  return {
    type: ORD_COND_CANCEL_REQUESTING,
    params,
  };
}
