import './radioButton.scss';

const RadioButton = ({ isReadonly = false, isActive = false, onClick }) => {
  if (isReadonly) {
    return (
      <span
        onClick={onClick}
        className={`radio-button ${isActive ? 'active' : ''}`}
      ></span>
    );
  } else {
    return (
      <span
        onClick={onClick}
        className={`radio-button radio-button--readonly ${
          isActive ? 'active' : ''
        }`}
      ></span>
    );
  }
};

export default RadioButton;
