import { constants } from '../../util/constant';
import {
  CANCEL_ORDER_REQUESTING,
  CANCEL_ORDER_REQUEST_ERROR,
  CANCEL_ORDER_REQUEST_SUCCESS,
  CASH_BALANCE_REQUESTING,
  CASH_BALANCE_REQUEST_ERROR,
  CASH_BALANCE_REQUEST_SUCCESS,
  EDIT_ORDER_REQUESTING,
  EDIT_ORDER_REQUEST_ERROR,
  EDIT_ORDER_REQUEST_SUCCESS,
  MATCH_ORDER_REQUESTING,
  MATCH_ORDER_REQUEST_ERROR,
  MATCH_ORDER_REQUEST_SUCCESS,
  NEW_ORDER_REQUESTING,
  NEW_ORDER_REQUEST_ERROR,
  NEW_ORDER_REQUEST_SUCCESS,
  POSITION_REQUESTING,
  POSITION_REQUEST_ERROR,
  POSITION_REQUEST_SUCCESS,
  PURCHASE_POWER_REQUESTING,
  PURCHASE_POWER_REQUEST_ERROR,
  PURCHASE_POWER_REQUEST_SUCCESS,
  SHARE_BALANCE_REQUESTING,
  SHARE_BALANCE_REQUEST_ERROR,
  SHARE_BALANCE_REQUEST_SUCCESS,
  STOCK_BALANCE_REQUESTING,
  STOCK_BALANCE_REQUEST_ERROR,
  STOCK_BALANCE_REQUEST_SUCCESS,
  SYMBOL_INFO_REQUESTING,
  SYMBOL_INFO_REQUEST_ERROR,
  SYMBOL_INFO_REQUEST_SUCCESS,
  SET_ORDER_SYMBOL,
  SET_ORDER_TYPE,
  SET_ORDER_PRICE,
  SET_ORDER_QUANTITY,
  SYMBOL_INFO_RESETING,
  LIST_PUT_THROUGH_REQUEST_ERROR,
  LIST_PUT_THROUGH_REQUEST_SUCCESS,
  LIST_PUT_THROUGH_REQUESTING,
  SET_ORDER_G,
} from './constants';

const initialSate = {
  orderList: null,
  listOrder: null,
  getMatchOrder: null,

  newOrder: null,
  newOrderSuccess: false,
  newOrderErrors: [],

  cancelOrder: null,
  cancelOrderSuccess: false,
  cancelOrderErrors: [],

  editOrder: null,
  editOrderSuccess: false,
  editOrderErrors: [],

  availStockList: null,
  symbolInfo: null,
  matchOrder: null,

  cashBalance: null,
  shareBalance: null,
  purchasePower: null,
  stockBalance: null,
  positions: null,

  orderSymbol: '', // current symbol in trade panel
  orderType: 'BUY',
  orderPrice: '',
  orderQuantity: '',
  orderG: constants.G.DEFAULT,
};

const reducer = function clientReducer(state = initialSate, action) {
  switch (action.type) {
    case 'INVALID_SESSION':
    case 'LOG_OUT':
    case 'CLIENT_UNSET':
      return {
        ...state,
        newOrder: null,
        newOrderSuccess: false,
        newOrderErrors: [],
        orderList: null,
        listOrder: null,
        getMatchOrder: null,
        cancelOrder: null,
        cancelOrderSuccess: false,
        cancelOrderErrors: [],
        editOrder: null,
        editOrderSuccess: false,
        editOrderErrors: [],
        availStockList: null,
        cashBalance: null,
        shareBalance: null,
        purchasePower: null,
        stockBalance: null,
        positions: null,
        symbolInfo: null,
        orderSymbol: '',
      };

    case CASH_BALANCE_REQUESTING:
    case CASH_BALANCE_REQUEST_ERROR:
      return {
        ...state,
        cashBalance: null,
      };

    case CASH_BALANCE_REQUEST_SUCCESS:
      return {
        ...state,
        cashBalance: action.cashBalance,
      };

    case SHARE_BALANCE_REQUESTING:
    case SHARE_BALANCE_REQUEST_ERROR:
      return {
        ...state,
        shareBalance: null,
      };

    case SHARE_BALANCE_REQUEST_SUCCESS:
      return {
        ...state,
        shareBalance: action.shareBalance,
      };

    case PURCHASE_POWER_REQUESTING:
    case PURCHASE_POWER_REQUEST_ERROR:
      return {
        ...state,
        purchasePower: null,
      };

    case PURCHASE_POWER_REQUEST_SUCCESS:
      return {
        ...state,
        purchasePower: action.purchasePower,
      };

    case STOCK_BALANCE_REQUESTING:
    case STOCK_BALANCE_REQUEST_ERROR:
      return {
        ...state,
        stockBalance: null,
      };

    case STOCK_BALANCE_REQUEST_SUCCESS:
      return {
        ...state,
        stockBalance: action.stockBalance,
      };

    case POSITION_REQUESTING:
    case POSITION_REQUEST_ERROR:
      return {
        ...state,
        positions: null,
      };

    case POSITION_REQUEST_SUCCESS:
      return {
        ...state,
        positions: action.positions,
      };

    case SYMBOL_INFO_REQUESTING:

    case SYMBOL_INFO_RESETING:
      return {
        ...state,
        symbolInfo: null,
      };

    case SYMBOL_INFO_REQUEST_ERROR:
      return {
        ...state,
        symbolInfo: null,
      };

    case SYMBOL_INFO_REQUEST_SUCCESS:
      return {
        ...state,
        symbolInfo: action.symbolInfo,
      };

    case MATCH_ORDER_REQUESTING:
    case MATCH_ORDER_REQUEST_ERROR:
      return {
        ...state,
        matchOrder: null,
      };

    case MATCH_ORDER_REQUEST_SUCCESS:
      return {
        ...state,
        matchOrder: action.matchOrder,
      };

    case NEW_ORDER_REQUESTING:
      return {
        ...state,
        newOrder: null,
        newOrderSuccess: false,
        newOrderErrors: [],
      };

    case NEW_ORDER_REQUEST_SUCCESS:
      return {
        ...state,
        newOrder: action.newOrder,
        newOrderSuccess: true,
        newOrderErrors: [],
      };

    case NEW_ORDER_REQUEST_ERROR:
      return {
        ...state,
        newOrder: null,
        newOrderSuccess: false,
        newOrderErrors: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };

    case EDIT_ORDER_REQUESTING:
      return {
        ...state,
        editOrder: null,
        editOrderSuccess: false,
        editOrderErrors: [],
      };

    case EDIT_ORDER_REQUEST_SUCCESS:
      return {
        ...state,
        editOrder: action.editOrder,
        editOrderSuccess: true,
        editOrderErrors: [],
      };

    case EDIT_ORDER_REQUEST_ERROR:
      return {
        ...state,
        editOrder: null,
        editOrderSuccess: false,
        editOrderErrors: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };

    case CANCEL_ORDER_REQUESTING:
      return {
        ...state,
        cancelOrder: null,
        cancelOrderSuccess: false,
        cancelOrderErrors: [],
      };

    case CANCEL_ORDER_REQUEST_SUCCESS:
      return {
        ...state,
        cancelOrder: action.cancelOrder,
        cancelOrderSuccess: true,
        cancelOrderErrors: [],
      };

    case CANCEL_ORDER_REQUEST_ERROR:
      return {
        ...state,
        cancelOrder: null,
        cancelOrderSuccess: false,
        cancelOrderErrors: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };
    case SET_ORDER_SYMBOL:
      return {
        ...state,
        orderSymbol: action.symbol,
      };

    case SET_ORDER_TYPE:
      return {
        ...state,
        orderType: action.orderType,
      };

    case SET_ORDER_PRICE:
      return {
        ...state,
        orderPrice: action.price,
      };

    case SET_ORDER_QUANTITY:
      return {
        ...state,
        orderQuantity: action.quantity,
      };

    case LIST_PUT_THROUGH_REQUESTING:
      return {
        ...state,
        requesting: true,
        successful: false,
        errors: [],
      };

    case LIST_PUT_THROUGH_REQUEST_SUCCESS:
      return {
        ...state,
        listPutThrough: action.listPutThrough.data,
        requesting: false,
        successful: true,
        errors: [],
      };

    case LIST_PUT_THROUGH_REQUEST_ERROR:
      return {
        ...state,
        requesting: false,
        successful: false,
        errors: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };

    case SET_ORDER_G:
      return {
        ...state,
        orderG: action.data,
      };
    default:
      return state;
  }
};

export default reducer;
