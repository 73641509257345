export default {
  'text-multi-chart': 'Multi Chart',
  'text-valid-password-lenth':
    'Have at least 8 characters and at most 32 characters',
  'text-password-must-one-letter': 'Have at least 1 letter (A-Z, a-z)',
  'text-password-must-one-digit': 'Have at least 1 digit (0-9)',
  'text-password-must-one-special':
    'Have at least 1 special character in @$!%*#?&_)(;\'".,/[]{}+-',
  'text-password-must-diff_old_pass': "Don't reuse old passwords",
  'text-password-matches-confirm-pass': 'New password confirm must match',
  'text-hint-content': '*Avoid use ID, Phone number in your password',

  'txt-board': 'Price board',
  'txt-trade': 'Trading',
  'txt-trade-acronym': 'Order',
  'txt-cash-transfer': 'Cash Transfer',
  'txt-asset': 'Asset',
  'txt-reports': 'Reports',
  'txt-total-asset': 'Total Asset',
  'txt-cash-advance': 'Cash Advance',
  'txt-stock-transfer': 'Stock Internal Transfer',
  'txt-money-transfer': 'Cash Internal Transfer',
  'txt-margin-debt': 'Margin debt',
  'txt-deposit-withdrawal': 'Deposit / Withdrawn Money',
  'txt-reference-subcription': 'Rights Info',
  'txt-hold-unhold': 'Hold / unhold bank',

  'txt-order': 'Order',
  'txt-quickOrder': 'Quick Order',
  'txt-login': 'Login',
  'txt-xacthuc': 'Authenticate',
  'txt-cancel': 'Cancel',
  'txt-re-login': 'Re-login',
  'txt-order-confirm': 'Confirm Order',
  'txt-ignore': 'Ignore',
  'txt-move': 'Move',
  'txt-moved': 'Moved',
  'txt-registration': 'Registration',
  'txt-accept': 'Accept',
  'txt-clear': 'Clear',
  'txt-regis-bank-account': 'Register bank account',
  'txt-change': 'Change',
  'txt-change-per': '% Change',
  'txt-change-pass': 'Change Password',
  'txt-change-telpin': 'Change Telpin',
  'txt-change-account': 'Change Account',
  'txt-change-login-name': 'Change Login name',
  'txt-register': 'Register',
  'txt-btn-order-cond': 'Order Condition',

  'txt-triggerPriceTP-long':
    'The trigger price must be greater than the market price',
  'txt-triggerPriceTP-short':
    'The trigger price must be less than the market price',
  'txt-triggerPriceSL-long':
    'The trigger price must be less than the market price',
  'txt-triggerPriceSL-short':
    'The trigger price must be greater than the market price',
  'txt-estProfit': 'Est Profit',
  'txt-estLoss': 'Est Loss',
  'txt-validDate': 'Valid Date',
  'txt-condition-trigger': 'Trigger Condition',
  'txt-TP': 'TP',
  'txt-OP': 'OP',
  'txt-conditional-type': 'Conditional Type',
  'txt-trigger-date': 'Trigger Date',
  'txt-trigger-order-no': 'Trigger Order NO.',
  'txt-executed': 'Executed',
  'txt-condition-orders-list': 'Conditional Order List',
  'txt-so': 'Stop limit',
  'txt-order-cancel-confirmation': 'Order cancel confirmation',
  'txt-order-edit': 'Edit order',

  'txt-day': 'days',
  'txt-equities': 'Equities',
  'txt-derivative': 'Derivatives',
  'txt-notice': 'Notifications',
  'txt-search': 'Search',
  'txt-add-symbol': 'Add Symbol',
  'txt-da-xacthuc': 'Authentication OTP/Matrix',
  'txt-chua-xacthuc': 'Un-authentication OTP/Matrix',
  'txt-chua-xacthuc-intro': 'Please click <0>here</0> to authenticate',
  'txt-chua-xacthuc-link': 'here',
  'txt-sub-account': 'Sub Account',
  'txt-normal': 'Normal',
  'txt-margin': 'Margin',
  'txt-pinefolio': 'PineFolio',
  'txt-warning': 'Warning',
  'txt-utilities': 'Utilities',
  'txt-personal': 'Personal',
  'txt-bond': 'Bond',
  'txt-contact': 'Contact',
  'txt-guide': 'Guide',
  'txt-sign-out': 'Sign Out',
  'txt-update': 'Update',
  'txt-nav': 'NAV',
  'txt-stock-val': 'Stock value',
  'txt-pp': 'PP',
  'txt-pp-owner': 'pp0',
  'txt-pp-margin': 'Account Topup PP',
  'txt-cashbalance': 'Cash balance',
  'txt-wdrawAvail': 'Withdraw available',
  'txt-advanceAvail': 'Advance available',
  'txt-AdvanceDebt': 'Advance debt',
  'txt-BoughtT0-normal': 'Bought T0 by cash',
  'txt-BoughtT0-margin': 'Bought T0 by loan',
  'txt-CashDividence': 'Cash Dividence',
  'txt-BoughtT0': 'Total bought T0',
  'txt-funds-accrued-in-plan': 'Funds accrued in the plan',
  'txt-UnMatchedBoughtT0': 'Un-match Bought T0',
  'txt-TotalStocks': 'Total Stocks',
  'txt-AvailTradingStock': 'Avail Trading Stock',
  'txt-PTAStock': 'Avail Trading Stock',
  'txt-TARestric': 'TA Restric',
  'txt-PTARestric': 'PTA Restric',
  'txt-RightStock': 'Right stock',
  'txt-ReceivingStockQtty': 'Receiving Stock Qtty',
  'txt-SoldT0': 'Sold T0',
  'txt-TotalDebt': 'Debt',
  'txt-MarginDebt': 'Margin debt',
  'txt-Principal': 'Principal',
  'txt-Interest': 'Interest',
  'txt-ExpectedDisbursement': 'Expected Margin Disbursement',
  'txt-detail': 'Detail',

  'txt-stock-waiting': 'Stock waiting',
  'txt-collateral': 'Collateral',
  'txt-debt': 'Debt',
  'txt-depoFee': 'Deposit Fee',
  'txt-total-depoFee': 'Total Deposit Fee',
  'txt-margin-limit': 'Margin limit',
  'txt-buy-max': 'Buy max',
  'txt-sell-max': 'Sell max',
  'txt-margin-rate': 'Margin rate',

  'txt-cw': 'Covered Warrants',
  'txt-industry': 'Industry',
  'txt-pt': 'Put Through',

  'txt-portfolio': 'Portfolio',
  'txt-balance': 'Balance',

  'txt-symbol': 'Symbol',
  'txt-symbol-equity': 'Base symbol',
  'txt-enter-symbol-or-keyword': 'Enter symbol, keyword',
  'txt-symbol-full': 'Symbol',
  'txt-stock': 'Symbol',
  'txt-info': 'Info',
  'txt-max-margin-rate': 'Margin rate',
  'txt-ref': 'Ref',
  'txt-index-future': 'Index Future',
  'txt-bond-future': 'Bond Future',
  'txt-ceil': 'Ceil',
  'txt-floor': 'Floor',
  'txt-bid': 'Bid',
  'txt-ask': 'Ask',
  'txt-price': 'Price',
  'txt-stick-size': 'Tick size',
  'txt-vol': 'Vol',
  'txt-origin-vol': 'Original Vol',
  'txt-match': 'Matched',
  'txt-total-vol': 'T.Vol',
  'txt-total-dividend-vol': 'T.Dividend',
  'txt-total-val': 'T.Val',
  'txt-high': 'High',
  'txt-low': 'Low',
  'txt-ave': 'Avg',
  'txt-avarage': 'Avarage',
  'txt-price-ave': 'Avg Price',
  'txt-price-avg': 'Avg. Price',
  'txt-total-num': 'Total',
  'txt-foreign': 'Foreign',
  'txt-buy': 'Buy',
  'txt-sell': 'Sell',
  'txt-order-no': 'Order No.',
  'txt-origin-order-no': 'Origin Order No.',
  'txt-type': 'Type',
  // 'txt-price': 'Price',
  'txt-price-order': 'Order Price',
  'txt-match-vol': 'Matched Vol',
  'txt-dividend-match-vol': 'Matched Dividend Vol',
  'txt-match-price': 'Matched Price',
  'txt-match-price-after-tax': 'After fee, tax Matched Value',
  'txt-cancel-vol': 'Canceled Vol',
  'txt-total-match-value': 'T.Matched Value',
  'txt-match-value': 'Matched Value',
  'txt-status': 'Status',
  'txt-actions': 'Actions',
  'txt-market-price': 'MP',
  'txt-change-in-day': 'Change in day',
  'txt-set': 'Set',
  'txt-odd': 'Odd',
  'txt-gain-loss': 'Gain/Loss',
  'txt-orders': 'Order list',
  'txt-help': 'Help',
  'txt-to': 'to',
  'txt-add-to': 'Add to',
  'txt-vol-order': 'Order vol',
  'txt-vol-buy': 'Buy Vol.',
  'txt-vol-sell': 'Sell Vol.',
  'txt-total-match-vol': 'T.Match Vol.',
  'txt-total-match-val': 'T.Match Val.',
  'txt-lowest': 'Lowest',
  'txt-highest': 'Highest',

  'txt-order-normal': 'Normal Order',
  'txt-order-margin': 'Margin Order',
  'txt-order-cond': 'Conditional Order',
  'txt-order-type': 'Order Type',

  'txt-stt': 'No.',
  'txt-time': 'Time',
  'txt-giatri': 'Value',

  'txt-login-account': "Login with Pinetree's account",
  'txt-account': 'Account',
  'txt-account-acronym': 'Acc',
  'txt-account-number': 'Account number',
  'txt-login-name': 'Login name',
  'txt-new-login-name': 'New Login name',
  'txt-old-login-name': 'Old Login name',
  'txt-pass': 'Password',
  'txt-all': 'All',
  'txt-closed': 'Closed',
  'txt-account-closed': 'Closed',
  'txt-opened': 'Open',
  'txt-intermission': 'Intermission',
  'txt-wait-open': 'Wait',
  'txt-preopen': 'ATO',
  'txt-atc': 'ATC',
  'txt-c': 'Put Through',
  'txt-plo': 'PLO',
  'txt-offline': 'Offline',
  'txt-confirm': 'Confirm',
  'txt-create': 'Create',
  'txt-reserved': 'Reserved',
  'txt-sent': 'Sent',
  'txt-waitingConfirm': 'Confirm',
  'txt-matched': 'Matched',
  'txt-part-matched': 'Match Partial',
  'txt-canceled': 'Canceled',
  'txt-modifyed': 'Modifyed',
  'txt-negotiation': 'Negotiation',
  'txt-not-accept': 'Not Accept',
  'txt-match-canceled': 'Match Canceled',
  'txt-matched-pending': 'Matched Pending',
  'txt-pending': 'Pending',
  'txt-transfer-pending': 'Pending',
  'txt-complete': 'Complete',
  'txt-bank-error': 'Bank error',
  'txt-admended': 'Admended',
  'txt-rejected': 'Rejected',
  'txt-error': 'Error',
  'txt-error-order': 'Error Order',
  'txt-expired': 'Expired',
  'txt-calculating-fee': 'Calculating Fee...',

  'txt-success': 'Success',
  'txt-unsuccessful': 'Unsuccessful',

  'txt-match-order-vol': 'Match order vol',
  'txt-match-one-time': 'Match one time',
  'txt-out-time': 'Order out time',
  'txt-trend-order': 'Trend Order',
  'txt-take-profit': 'Take profit order',
  'txt-confirm-TnC':
    'I agree with the Terms and Conditions for using Derivative Conditional Orders above',
  'txt-confirm-TnC-header': 'Terms and Conditions',
  'txt-stop-loss': 'Stop loss order',
  'txt-purchase-order': 'Purchase order',
  'txt-transfer-content': 'Deposit money into stock account',
  'txt-warning-sl-tp':
    'At the time of order activation, if the number of account positions changes compared to when setting the conditional order, the system will still generate an order on the floor',

  'txt-cond-pending': 'Pending',
  'txt-cond-active': 'Active',
  'txt-cond-activated': 'Activated',
  'txt-cond-final': 'Close',
  'txt-cond-expired': 'Expired',
  'txt-cond-cancelling': 'Cancelling',
  'txt-cond-wait-react': 'Wait for reactivation',
  'txt-cond-new': 'New',
  'txt-cond-effective-time': 'Effective time',
  'txt-cond-setting-condition': 'Setting condition',
  'txt-cond-setting-price': 'Setting price',
  'txt-cond-method-trend': 'Method of determining the trend',
  'txt-cond-pause-value': 'Pause in value',
  'txt-cond-pause-per': 'Pause in %',
  'txt-cond-choose-price': 'Choose price',
  'txt-cond-diff-price': 'Price difference TP',
  'txt-cond-lowest-price': 'Lowest purchase price',
  'txt-cond-highest-price': 'Highest purchase price',
  'txt-cond-activation-conditions': 'Activation-conditions',
  'txt-cond-avg': 'Avg price',
  'txt-cond-diff-for-avg': 'Price difference Avg',
  'txt-cond-diff-for-avg-per': 'Value difference',
  'txt-cond-diff-for-cur-price': 'Price difference Current price',
  'txt-cond-total-cond': 'Summary of activation conditions',

  'txt-open-account': 'Open new account',
  'txt-forgot-pass': 'Forgot password?',
  'txt-no-data-found': 'No data found!',
  'txt-please-try-again': 'Please try again!',
  'txt-no-add-catalog': 'Do not add stock to category ',
  'txt-symbol-has-in-catalog': 'The stock is already in category: ',
  'txt-price-not-match': '{{side}} Price not match',
  'txt-price-please-check': 'Please check the order price',
  'txt-triggerPrice-please-check': 'Please check the trigger condition',
  'txt-vol-not-match': '{{side}} Volume not match',
  'txt-cash-not-match': 'Cash not match',
  'txt-no-beneficiary-data': 'No beneficiary data',
  'txt-symbol-not-match': 'Symbol not match',
  'txt-price-not-over': 'Price should not over ceil price',
  'txt-price-not-less': 'Price should not less than floor price: ',
  'txt-hsx-price': 'HSX no pricing MTL/MOK/MAK/PLO',
  'txt-hnx-price': 'HNX no pricing MP',
  'txt-upcom-price': 'UPCOM no pricing market price',
  'txt-valid-time': 'Time invalid',
  'txt-valid-time-2': 'System only support searching data from ',
  'txt-valid-range-time': 'Reports are only allowed within ',
  'txt-valid-5-catalog': 'Add max 15 category in watchlist',
  'txt-valid-category-not-null': 'The category name is invalid',
  'txt-valid-exist-category': 'Already exists ',
  'txt-valid-not-del': 'Not delete ',
  'txt-valid-not-edit': 'Not edit ',
  'txt-valid-excess-amount':
    'The amount is in excess of the withdrawable amount',
  'txt-valid-max-amount': 'Max amount',
  'txt-valid-min-amount': 'Min amount',
  'txt-valid-send-otp': 'Do not send more than 05 OTPs within 2 minutes',
  'txt-valid-same-pass': 'New password and old password are the same',
  'txt-valid-pass-confirm-not-match':
    'New password and confirm password do not match',
  'txt-valid-input-required': 'Input required',
  'txt-valid-upload-file-required': 'File upload is required',
  'txt-valid-characters': 'characters',
  'txt-valid-enter': 'Enter',
  'txt-valid-phone-incorrect-format':
    'Your phone number is not in the correct format',
  'txt-valid-date-incorrect-format':
    'Your date of birth is not in the correct format',
  'txt-valid-email-incorrect-format': 'Email invalidate',
  'txt-valid-enter-verification-code': 'Please enter the verification code',
  'txt-valid-loss-session': 'Lost session. You need to re-login to continue.',
  'txt-valid-old-account-not-match':
    'Old account is not the same Login account',
  'txt-valid-same-new-account': 'New account and Old account overlap',
  'txt-valid-input-not-allow-special-character':
    'Please do not enter special characters like [!, @, #, $,%, ^, &, * (,)] ... and vietnamese characters',
  'txt-valid-input-password-must-contain-at-least-one-number-and-one-special-character':
    'Password must be at least 8 characters long, including at least 1 letter, at least 1 digit and at least 1 special character',
  'txt-valid-input-min-length-character':
    'Enter $t(txt-title-min) {{value}} characters',
  'txt-valid-input-max-length-character':
    'Enter $t(txt-title-max) {{value}} characters',
  'txt-show': 'Show',
  'txt-showmore': 'Show more',
  'txt-seemore': 'See more',
  'txt-note': 'Note',
  'txt-note-text': 'Note',
  'text-order': 'orders',
  'txt-overview': 'Overview (B VND)',
  'txt-all-market': 'All market',
  'txt-total-transaction': 'Total Transaction',
  'txt-total-putThrough': 'Total Put Through',
  'txt-total-foreign': 'Foreign',
  'txt-international': 'International',

  'txt-orderNo': 'Order No.',
  'txt-lost-connect': 'Lost connect',
  'txt-regis-unsuccessful': 'Register private unsuccessful',

  'txt-title-order-cond': 'Condition Order',
  'txt-title-authentication-success': 'Authentication successful',
  'txt-title-neworder-success': 'New order success',
  'txt-title-neworder-unsuccess': 'New order fail',
  'txt-title-delorder-success': 'Cancel order success',
  'txt-title-editorder-success': 'Edit order success',
  'txt-title-hold-success': 'Hold/Unhold bank success!',
  'txt-title-asset-manage': 'Asset management',
  'txt-title-debt-manage': 'Debt management',
  'txt-title-transfer-stock-fail': 'Stock transfer fail',
  'txt-title-transfer-success': 'Stock transfer success',
  'txt-title-login-success': 'Login success',
  'txt-title-login-fail': 'Login fail due to',
  'txt-title-changepass-success':
    'Password changed successfully. You need to re-login to continue',
  'txt-title-changetelpin-success': 'The telPIN changed successfully',
  'txt-title-max': 'Max',
  'txt-title-min': 'Min',
  'txt-title-withdrawal-confirmation': 'Withdrawal confirmation',
  'txt-title-withdrawal-verification': 'Withdrawal verification',
  'txt-title-edit-confirm': 'Confirm order modification',
  'txt-title-new-confirm': 'Confirm new order',
  'txt-title-delete-confirm': 'Confirm delete order',
  'txt-title-fill-info-regis':
    'Fill out the information you registered with PineTree',
  'txt-title-fill-info-reset':
    'Fill out the information you registered with PineTree to reset password',
  'txt-title-login-no-cif': 'No Account Information',

  'txt-stt-in-date': 'In due date',
  'txt-stt-due-date': 'Due date',
  'txt-stt-out-date': 'Out of date',
  'txt-stt-right-cash': 'Right cash',
  'txt-stt-right-buy': 'Right buy',
  'txt-stt-right-stock': 'Right stock',
  'txt-stt-internal': 'Internal',
  'txt-stt-bank': 'Bank',

  'txt-stt-right-01': 'Cash Dividend',
  'txt-stt-right-02': 'Stock Dividend',
  'txt-stt-right-03': 'Paid-in Capital Increase',
  'txt-stt-right-04': 'Bonus Issue',
  'txt-stt-right-05': 'Paid Capital Reduction',
  'txt-stt-right-06': 'Capital Reduction',
  'txt-stt-right-07': 'Merge',
  'txt-stt-right-08': 'Spin-Off',
  'txt-stt-right-09': 'Symbol Change',
  'txt-stt-right-11': 'Stock Split',
  'txt-stt-right-12': 'Reverse Stock Split',

  'txt-asset-total': 'Total Assets',
  'txt-asset-nav': 'Net Asset Value',
  'txt-asset-withdraw': 'Withdrawable Amount',
  'txt-asset-receivable': 'Available adv. amount',
  'txt-asset-withdrawal': 'Withdrawable Amount',
  'txt-asset-receivable-1': 'Available Advance',
  'txt-asset-balance': 'Account balance',
  'txt-asset-actual-balance-account': 'Actual balance on account',
  'txt-asset-cash-balance': 'Money available',
  'txt-asset-receivable-amount': 'Receivable Amount',
  'txt-asset-buy-mount': 'Secure Amount',
  'txt-asset-unmatch-amount': 'Blocked Amount',
  'txt-asset-collateral': 'Collateral',
  'txt-asset-collateral-stock': 'Stock Dividends',
  'txt-asset-stocks': 'Stocks',
  'txt-asset-market-stocks': 'Market Value of stocks',
  'txt-asset-guaranteed': 'Value of assets to be guaranteed',
  'txt-asset-outstanding-debt': 'Outstanding Debt',
  'txt-asset-adding': 'Adding amount',
  'txt-asset-remain-margin': 'Remain margin amount',
  'txt-asset-day-loan': 'Margin loan in day',
  'txt-asset-principal': 'Principal Margin',
  'txt-asset-month-depo-fee': 'Total Deposit Fee',
  'txt-asset-other-fee': 'Other Fee',
  'txt-asset-SMS-fee': 'SMS Fee',
  'txt-asset-cash-can-transfer': 'Cash can transfer',
  'txt-asset-margin-loan-interest': 'Margin loan interest',

  'txt-label-cash': 'Cash',
  'txt-label-avail': 'Avail',
  'txt-label-position': 'Position',
  'txt-label-page-size': 'Display',
  'txt-label-page': 'Page',
  'txt-label-from': 'From',
  'txt-label-to': 'To',
  'txt-label-right': 'Right',
  'txt-label-total-loan': 'Total loans',
  'txt-label-loan-date': 'Loan date',
  'txt-label-expire-date': 'Expire date',
  'txt-label-extend-expire-date': 'Extended expiry date',
  'txt-label-extend-registration-date': 'Extension registration date',
  'txt-label-expired-date': 'Expire date',
  'txt-label-interest': 'Interest',
  'txt-label-fee': 'Fee',
  'txt-label-tax': 'Tax',
  'txt-label-dividend-tax': 'Dividend tax',
  'txt-label-free': 'Free',
  'txt-label-defer-interest': 'Deferred interest',
  'txt-label-repay-interest': 'Repay Interest',
  'txt-label-remain-interest': 'Debt remain',
  'txt-label-disbursement-date': 'Disbursement date',
  'txt-label-maturity-date': 'Maturity date',
  'txt-label-call-date': 'Call date',
  'txt-label-interest-rate': 'Interest rate',
  'txt-label-loan-amount': 'Loan amount',
  'txt-label-penalty-delay': 'Penalty',
  'txt-label-paid': 'Paid',
  'txt-label-unpaid': 'Unpaid',
  'txt-label-repay': 'Repaid',
  'txt-label-unrepay': 'Unrepay',
  'txt-label-in-due-amount': 'In due amount',
  'txt-label-pay': 'Pay',
  'txt-label-total-amount': 'Total amount',
  'txt-label-real-amount': 'Real amt',
  'txt-label-payment-history': 'Payment history',
  'txt-label-return-asset': 'Return Assets',
  'txt-label-major': 'Major',
  'txt-label-opening-balance': 'Opening balance',
  'txt-label-closing-balance': 'Closing balance',
  'txt-label-increase': 'Increase',
  'txt-label-decrease': 'Decrease',
  'txt-label-vol-up': 'Vol up',
  'txt-label-vol': 'Volume',
  'txt-label-vol-down': 'Vol down',
  'txt-label-remark': 'Remark',
  'txt-label-overview': 'Overview',
  'txt-label-history-price': 'Trading Data',
  'txt-label-finance': 'Financials',
  'txt-label-events': 'Events',
  'txt-label-news': 'News',
  'txt-label-documents': 'Profile',
  'txt-label-details': 'Details',
  'txt-label-amount': 'Amount',
  'txt-label-expected-date': 'Expected exercise date',
  'txt-label-registration-date': 'Registration date',
  'txt-label-quantity-record-date': 'Quantity at record date',
  'txt-label-ratio': 'Ratio',
  'txt-label-pending-transfer': 'Pending Transfer',
  'txt-label-pending-delivery': 'Waiting Delivery',
  'txt-label-pending-trading': 'Waiting Trade',
  'txt-label-tran-date': 'Transaction date',
  'txt-label-trade-date': 'Trade Date', // TODO: 02/12/2020 update ngày đặt
  'txt-label-tran-amount': 'Amount',
  'txt-label-tran-type': 'Type',
  'txt-label-withdrawal': 'Withdraw',
  'txt-label-hold-bank': 'Hold in bank',
  'txt-label-withdrawal-bank': 'Unhold from bank',
  'txt-label-bank-link': 'Linked bank',
  'txt-label-account-link': 'Linked account',
  'txt-label-bank-name': "Bank's name",
  'txt-label-branch-name': "Branch's name",
  'txt-register-bank-account-success': 'Registered bank account successfully.',
  'txt-label-bank-hold-amount': 'Hold amount',
  'txt-label-history': 'History',
  'txt-label-transfer-to': 'Transfer to',
  'txt-label-transfer-fee': 'Transfer fees',
  'txt-label-source': 'Source',
  'txt-label-target': 'Target',
  'txt-label-create-date': 'Create date',
  'txt-label-stock-transfer': 'Stock transfer',
  'txt-label-stock-holding': 'Stock holding',
  'txt-label-vol-holding': 'Volume holding',
  'txt-label-cash-transfer-amount': 'Amount',
  'txt-label-cash-transfer-content': 'Content',
  'txt-label-internal-transfer': 'Cash Internal Transfer',
  'txt-label-online-transfer': 'transfer online from',
  'txt-label-right-subscription': 'Right subscription',
  'txt-label-max-subscription': 'Maximum amount subscription: ',
  'txt-label-owned-quantity': 'Quantity at record date',
  'txt-label-remaining-quantity': 'Remaining quantity for buying	',
  'txt-label-purchased-quantity': 'Purchased quantity	',
  'txt-label-maximum-quantity': 'Maximum quantity for buying',
  'txt-label-start-date': 'Start date',
  'txt-label-end-date': 'End date',
  'txt-label-base-date': 'Base date',
  'txt-label-market-value': 'Market value',
  'txt-label-buy-amt': 'Buy amount',
  'txt-label-billion': 'B',
  'txt-label-million': 'M',
  'txt-label-thousand': 'K',
  'txt-label-deposit-stock': 'Deposit money into stock account',
  'txt-label-va-deposit-stock':
    "Deposit money into stock account via Bank's identification account",
  'txt-label-beneficiary': 'Beneficiary',
  'txt-label-account-beneficiary': 'Beneficiary Account',
  'txt-label-va-account-beneficiary': 'Identification account',
  'txt-label-choose': 'Choose',
  'txt-label-transfer-content': 'Transfer content',
  'txt-label-amount-want-transfer': 'Amount want to transfer',
  'txt-label-source-advance': 'Source Advance',
  'txt-label-amount-advance': 'Amount Advance',
  'txt-label-advance-request': 'Request advance',
  'txt-label-amount-deducted': 'Amount deducted',
  'txt-label-fee-service': 'Service fee',
  'txt-label-fee-management': 'Management fee',
  'txt-label-total': 'Total',
  'txt-label-sell-date': 'Sell date',
  'txt-label-trade-amount-af-fee': 'Trade amount after fee',
  'txt-label-settlement-date': 'Settlement date',
  'txt-label-calculator-fee': 'Calculate Fee',
  'txt-label-deposit': 'Deposit',
  'txt-label-quick-deposit': 'Quick Deposit',
  'txt-label-normal-deposit': 'Normal Deposit',
  'txt-label-hold': 'Hold',
  'txt-label-unHold': 'Unhold',
  'txt-label-recharge': 'Recharge',
  'txt-label-profile': 'Profile',
  'txt-label-security': 'Security',
  'txt-label-bank-account': "Bank's Account",
  'txt-label-authorization': 'Authorization',
  'txt-label-authorization-person': 'Authorized Person',
  'txt-label-authorization-person-other': 'Other Authorized Person',
  'txt-label-authorization-type': 'Authorized transaction type',
  'txt-label-order-statement': 'Order Statement',
  'txt-label-order-cond-statement': 'Conditional Order Statement',
  'txt-label-cash-statement': 'Cash Statement',
  'txt-label-securities-statement': 'Securities Statement',
  'txt-label-gain-loss': 'Executed GainLoss Statement',
  'txt-label-order-del': 'Delete',
  'txt-label-order-edit': 'Edit',
  'txt-label-trading-fee': 'Trading fee',
  'txt-label-sell-fee': 'Sell fee',
  'txt-label-condition': 'Condition',
  'txt-label-order-history': 'Order history',
  'txt-label-order-cond-history': 'Order condition history',
  'txt-label-channel': 'Channel',
  'txt-label-account-info': 'Contact info',
  'txt-label-general-info': 'General info',
  'txt-label-fullname': 'Full name',
  'txt-label-identification': 'Identification',
  'txt-label-issued-on': 'Issued on',
  'txt-label-place': 'Place',
  'txt-label-phone-num': 'Phone number',
  'txt-label-phone': 'Phone number',
  'txt-label-date-birth': 'Date of birth',
  'txt-label-gender': 'Gender',
  'txt-label-cif-no': 'CIF No (Base on system policy)',
  'txt-label-vsd-no': 'VSD No',
  'txt-label-male': 'Male',
  'txt-label-female': 'Female',
  'txt-label-nationality': 'Nationality',
  'txt-label-contact-info': 'Contact information',
  'txt-label-permanent-address': 'Permanent Address',
  'txt-label-address': 'Address',
  'txt-label-ip-address': 'Address',
  'txt-label-province': 'Province',
  'txt-label-branch': 'Branch',
  'txt-label-beneficiary-bank': 'Beneficiary bank',
  'txt-label-account-holder': 'Account holder',
  'txt-label-account-num': 'Account number',
  'txt-label-account-type': 'Account type',
  'txt-label-account-second': 'Second account',
  'txt-label-login-info': 'Login Information',
  'txt-label-login-account': 'Login name',
  'txt-label-customer-name': 'Customer name',
  'txt-label-date-of-birth': 'Date of birth',
  'txt-label-current-password': 'Current password',
  'txt-label-current-telpin': 'Current telpin',
  'txt-label-default-account': 'Default Account',
  'txt-label-authentication-registered': 'Authentication registered',
  'txt-label-change-pass-pinetree': "Change password Pinetree's account",
  'txt-label-old-pass': 'Old password',
  'txt-label-new-pass': 'New password',
  'txt-label-new-pass-confirm': 'New password confirm',
  'txt-label-old-telpin': 'Old telpin',
  'txt-label-new-telpin': 'New telpin',
  'txt-label-new-telpin-confirm': 'New telpin confirm',
  'txt-label-authen-otp': 'OTP authentication',
  'txt-label-cash-advance': 'Cash advance',
  'txt-label-recipient-account': 'Recipient account',
  'txt-label-amount-withdraw': 'Amount withdraw',
  'txt-label-margin-list': 'Margin list',
  'txt-label-look-right-info': 'Look up rights implementation information',
  'txt-label-default-watchlist': 'My watchlist',
  'txt-label-cash-advance-payment': 'Cash advance',
  'txt-label-old-account': 'Old Account',
  'txt-label-new-account': 'New Account',
  'txt-label-quarterly': 'Quarterly',
  'txt-label-annual': 'Annual',
  'txt-label-income-statement': 'Income Statement',
  'txt-label-balance-sheet': 'Balance Sheet',
  'txt-label-ratios': 'Ratios',
  'txt-label-items': 'Items',
  'txt-label-date': 'Date',
  'txt-label-today': 'Today',
  'txt-label-month': 'Month',
  'txt-label-months': 'Months',
  'txt-label-monday': 'Mo',
  'txt-label-tuesday': 'Tu',
  'txt-label-wednesday': 'We',
  'txt-label-thursday': 'Th',
  'txt-label-friday': 'Fr',
  'txt-label-saturday': 'Sa',
  'txt-label-sunday': 'Su',
  'txt-label-enter-date': 'Enter date',
  'txt-label-major-shareholders': 'Major Shareholders',
  'txt-label-legal-representative': 'Legal Representative',
  'txt-label-customer-supporter-name': 'Customer Service',
  'txt-label-customer-supporter-phone': 'Phone',
  'txt-label-read-more': 'Read more',
  'txt-label-others': 'Others',

  'txt-export-excel': 'Export Excel',
  'txt-export-pdf': 'Export PDF',

  'txt-verification-methods': 'Verification methods',
  'txt-choose-verification-methods':
    'Please select your desired verification method',
  'txt-smartotp-pinetree': 'Use Pinetree OTP generator',
  'txt-matrix': 'Matrix',
  'txt-regis-counter': 'Register at counter',
  'txt-otp-sent-to': '',
  'txt-lost-verification': 'Lost your verification?',

  'txt-enter-otp': 'Please enter OTP to confirm',
  'txt-sub-smartotp':
    'Please enter Smart OTP code has been generated from SmartOTP apps',
  'txt-sub-otp': 'Enter SMS OTP code has been sent to',
  'txt-learn-more': 'Learn more',
  'txt-cant-access': 'Can’t access your favourite device now?',
  'txt-change-method': 'Change confirmation method',
  'txt-resend': 'Resend',
  'txt-refresh-matrix': 'Refresh Matrix',
  'txt-countdown-refresh':
    'You have entered the wrong OTP 5 times. Please wait for {{time}}s to retrieve the OTP again',
  txt_send_successfully: 'Sent successfully',
  'txt-countdown-resend':
    'You have entered the wrong OTP 5 times. Please wait for {{time}}s to retrieve the OTP again',
  'txt-resend-after': 'Resend after',
  'txt-enter-matrix': 'Please enter Matrix to confirm',
  'txt-hotline-support':
    'Do you want to change the above profile information? Please contact Pinetree hotline for support.',
  'txt-make-sure-can-access':
    'Make sure you can access the current authentication method to make changes to your account security information',
  'txt-make-sure-can-access-settings':
    'Make sure you can access the current authentication method to make changes to your account settings information',
  'txt-make-verify-otp-correct':
    'Verify OTP after entering both passwords correctly',
  'txt-code-smartotp-to-confirm':
    'Please enter the security code from SmartOTP app to confirm transaction',
  'txt-code-otp-to-confirm': 'Please enter the OTP number sent to phone number',
  'txt-confirm-transaction': 'to confirm transaction',
  'txt-code-matrix-to-confirm':
    'Please enter MATRIX code to confirm transaction',
  'txt-gd':
    'To automatically deposit to the Securities account, please make a Bank transfer to 1 of your Bank accounts with the EXACT information as shown in the sample.',
  'txt-gd-va':
    'To automatically deposit to the Securities account, please make a Bank transfer to the customer INDENTIFICATION ACCOUNT.',
  'txt-gd-va1':
    "The method of deposit money through VIB's VA account is only applicable for the deposit amount <span class='text-highlight'>LESS than 500 MILLION</span> and follows the 24/7 fast payment route through the NAPAS system.",
  'txt-gd-note':
    '<span class=\'text-light fz_13\'><strong>Notes: </strong>Customers can see detailed guidance on depositing money into securities accounts at<a class="text-warning fw-500" target="_blank" href="https://pinetree.vn/en/post/dich-vu/deposit"> HERE</a></span>',
  'txt-warning-cashout-fee': 'SMS OTP fee excluded (if any)',
  'txt-warning-cashout':
    'At the moment, online transfer only support from 08h00 to 15h55, please retry again in suitable time',
  'txt-warning-cashout-0':
    'According to Napas regulations, the minimum withdrawal amount is 2,000 VND',
  'txt-warning-cashout-1':
    'For each money transfer transaction less than 500 million, the system supports FAST withdrawal 24/7.',
  'txt-warning-cashout-2':
    'For each money transfer transaction greater than 500 million, the system only supports normal withdrawals from 8:00 am to 3:25 pm on working days.',
  'txt-warning-cashout-3':
    'Please make withdrawals within the above noticed time period and limit',
  'txt-warning-relogin': 'Your session is closed, please relogin.',
  'txt-label-system': 'Base on system policy',
  'txt-urgent-announcement':
    'HOSE change boards lot from 10 to 100 from 04-Jan-2021. Please take care your portfolio',
  'txt-symbols-sold-out-hide': 'Hide sold out symbols',
  'txt-symbols-odd-lot-hide': 'Hide odd symbols',
  'txt-unregis-authen-method':
    'You have not registered this authentication method yet, please contact for support',
  'txt-enter-authen-matrix': 'Please enter the MATRIX code for authentication',
  'txt-enter-authen-smartotp':
    'Please enter the OTP number from Pinetree SmartOTP application',
  'txt-enter-authen-smsotp':
    'Please enter the OTP number sent to phone number ',
  'txt-copyright':
    'Price x 1000 VND. Volume x 10 shares. Copyright ©2022 Pinetree. All rights reserved.',
  'txt-copyright2':
    'Price x 1 VND. Volume x 1 contract. Copyright ©2022 Pinetree. All rights reserved.',
  'lbl-need-VSD-case-1':
    'Please complete the account-opening contract. Withdrawal function will be locked until the contract is completed.',
  'lbl-need-VSD-case-2':
    'Please login Alphatrading or PineX to complete the contract. Please do not deposit money until completing the contract.',
  'lbl-need-VSD-case-3': 'Please submit the account-opening contract.',
  'lbl-ok': 'Ok',
  'lbl-contract-code': 'Contract code',
  'lbl-gain-loss': 'Gain/loss',
  'lbl-buy-amount': 'Buy amount',
  'lbl-company': 'Pinetree Securities Corporation',
  'lbl-company-address':
    '20th Floor, ROX Tower, 54A Nguyen Chi Thanh, Lang Thuong Ward, Dong Da District, Hanoi City, Vietnam',
  'lbl-company-contact':
    'Tel: 024 6282 3535 | Fax: 024 6275 0077 | Email: contact@pinetree.vn',
  'lbl-report-name': 'Report name',
  'lbl-subAccount': 'SubAccount No.',
  'lbl-cost-at-the-time-of-sale': 'Cost price',
  'lbl-activity': 'Activities',
  'txt-fail': 'fail',
  'txt-login-notification':
    'Address: {{address}} - login {{status}} on device: {{device}}',
  'txt-order-notification':
    'Subaccount: {{subAccount}} {{orderType}} {{orderSymbol}}',
  'lbl-next': 'Next',
  'lbl-previous': 'Previous',
  'lbl-finish': 'Done',
  'lbl-close': 'Close',
  'lbl-notification-slider-pagecount': '{{current}} of {{total}} notifications',
  'lbl-setting-services': 'Services setting',
  'lbl-sms-reg-service': 'SMS services',
  'lbl-other-reg-services': 'Register services',
  'lbl-other-reg-date': 'Registration date',
  'lbl-other-api-services': 'API Services',
  'lbl-partner-services': 'Register services',
  'lbl-regis-service-telpin': 'Regist telpin service',
  'lbl-regis-service-matrix': 'Regist matrix service',
  'lbl-regis-service-autoAdvice': 'Regist auto advice service',
  'lbl-regis-service-other': 'Regist another service',
  'lbl-un-reg-service': 'No registration',
  'lbl-reg-service': 'Registered',
  'lbl-are-you-sure': 'Are you sure?',
  'lbl-remove-the-bank': 'You want to remove the bank account!',
  'lbl-remove': 'Remove',
  'txt-regis-bank-account-confirm': 'Confirm register bank account',
  'txt-title-regis-bank-verification': 'Confirm register bank account',
  'txt-matched-order': 'Matched',
  'txt-matched-detail': 'Matched',
  'txt-price-chart': 'Price chart',
  'txt-price-analytic': 'Price Analysis',
  'txt-open-price': 'Open',
  'lbl-double-click-to-order': 'Double click to order',
  'txt-trading-data': 'Trading data',
  'txt-reset': 'Reset',
  'txt-PersonalID': 'Personal ID',
  'txt-personalID-type': 'ID kind',
  'txt-personalID-1': 'CMND',
  'txt-personalID-2': 'CCCD',
  'txt-personalID-number': 'ID number',
  'txt-residence-addr': 'Residence Address',
  'txt-personalID-IssuedPlace': 'Issued Place',
  'txt-personalID-IssuedDate': 'Issued Date',
  'txt-personalID-ExpireDate': 'Expire Date',
  'txt-feedback-get-gift': 'Feedback & get gift',

  'txt-odd-lot': 'Odd lot',
  'txt-odd-lot-hsx': 'Odd lot (HSX)',
  'txt-odd-lot-hnx': 'Odd lot (HNX)',
  'txt-odd-lot-upcom': 'Odd lot (UPCOM)',

  'txt-buy-in': 'Buy in',
  'txt-buy-in-hsx': 'Buy in (HSX)',
  'txt-buy-in-hnx': 'Buy in (HNX)',
  'txt-buy-in-upcom': 'Buy in (UPCOM)',
  'txt-post-close': 'Post Close',

  'txt-my-watchlist': 'My watchlist',
  'txt-support-247': 'Support 24/7',
  HOSE: 'HOSE',
  VN30: 'VN30 ',
  HNX: 'HNX',
  UPCOM: 'UPCOM',
  'txt-label-bond-code': 'Bond Code',
  'txt-label-available-quantity': 'Available Quantity',
  'txt-label-receiving-quantity': 'Receiving Quantity',
  'txt-label-block-quantity': 'Block Quantity',
  'txt-label-total-quantity': 'Total Quantity',
  'txt-label-buy-value-total': 'Buy value total',
  'txt-label-total-now': 'Total now',
  'txt-label-listed-security': 'Listed securities',
  'txt-label-bond-otc': 'Bond OTC',
  'txt-label-refresh-data': 'Refresh data',
  'txt-no': 'No',
  'txt-yes': 'Yes',
  'txt-clickhere': 'Click here',
  'txt-view-full-chart': 'View full chart',
  'txt-market-index': 'Market index',
  'txt-title-remove-bank-account': 'Confirm remove bank account',
  'txt-quantity': 'Quantity',
  'txt-trigger-price': 'Trigger Price',
  'txt-position': 'Position',
  'txt-current-p/l': 'Current P/L',
  'txt-order-side': 'Order Side',
  'txt-total-payment': 'Total payment',
  'txt-total-value': 'Total Value',
  'txt-performance': 'Performance',
  'txt-accumulation': 'Sold set',
  'txt-cash-internal-transfer': 'Cash internal tranfer',
  'txt-missing': 'Missing',
  'txt-available-balance': 'Available Balance',
  'txt-transfer-money': 'Tranfer money to',
  'txt-analysis-report': 'Analysis Report',
  'txt-tax': 'Tax',
  'txt-feedback': 'Feedback',
  'txt-feedback-success': 'Your feedback sent successfully',
  'txt-feedback-unsuccess': 'Your response has been sent unsuccessfully',
  'txt-feedback-choosen-category': 'Please choose a feedback category at least',
  'txt-feedback-type-message': 'Please type messages',
  'txt-theme': 'Theme mode',
  'txt-personality': 'Personality',
  'txt-upgrade': 'Upgrade',
  'txt-upgrade-now': 'Upgrade Now',
  'txt-upgrade-later': 'Upgrade later',
  'txt-choose-this': 'Choose this',
  'txt-many': 's',
  'txt-sell-bond-note':
    'The fee of selling PineB is 1.5% of total trading value.',
  'txt-login-to-place-order': 'Login to place order',
  'txt-open-account-to-place-order': 'Open derivative account',
  'txt-derivatives-Fee-Tax-T0': 'Derivatives Fee Tax delivery T0',
  'txt-loss-vm-delivery': 'Loss VM delivery',
  'txt-derivatives-debt-pay-late': 'Derivatives debt pay late',
  'txt-position-management-fee': 'Position management fee',
  'txt-asset-management-fee': 'Asset management fee',
  'txt-val-after-tax-fee': 'T.Val after Tax, Fee',
  'txt-margin-money-at-VSD': 'Margin money at VSD',
  'txt-derivative-fee-tax-deb': 'Derivative Trading Fee & Tax Debt',
  'txt-save': 'Save',
  'txt-copy': 'Copy',
  'txt-copied-to-clipboard': 'Copied {{object}}to clipboard',
  'txt-copied-fail': 'Copy fail',
  'txt-deactive': 'Deactive',
  'txt-active': 'Active',
  'txt-submit': 'Submit',
  'txt-file-attach': 'File Attachment',
  'txt-type-content': 'Type content',
  'txt-new': 'New',
  'txt-old': 'Old',
  'txt-waiting-executed': 'Waiting executed',

  'txt-new-order-sent-success':
    'Order ({{orderNo}}) <strong>{{orderSide}} {{symbol}}</strong> has been successfully sent',
  'txt-cancel-order-success':
    'Order ({{orderNo}}) has been successfully cancelled ',
  'txt-new-order-rejected':
    'Order ({{orderNo}}) has been successfully rejected',
  'txt-adjust-order-success':
    'Order ({{orderNo}}) has been successfully edited',

  'txt-matched-order-success':
    'Order <strong>{{orderSide}}</strong> ({{orderNo}}) matched {{matchedQuantity}} {{symbolType}} with code <strong>{{symbol}}</strong>.',
  'txt-waiting-match': 'Waiting Match',
  'txt-action': 'Action',
  'txt-remain-quantity': 'Remain Vol',
  'txt-order-drvx-page-invalid':
    'Symbol is invalid, please place derivative order on Derivatives page',
  'txt-market': 'Market',
  'txt-important': 'Important',
  'txt-alert': 'Alert',
  'txt-notifications-board': 'Notifications',
  'txt-notifications-filter-order': 'Update status of trading orders',
  'txt-notifications-filter-alert':
    'Alerts on the status of margin accounts and derivative accounts',
  'txt-mark-read-all': 'Mark read all',
  'txt-loading': 'Loading...',
  'txt-reregist-fcm-note':
    'The notification will be reactivated after you login again.',
  'txt-last-trading-date': 'Last Trading Date',
  'txt-underlying': 'Underlying',
  'txt-breakeven-point': 'Break-even point',
  'txt-strike-price': 'Strike Price',
  'txt-conversion-ratio': 'Conversion Ratio',
  'txt-leverage': 'Leverage',
  'txt-no-change-note': 'Please change service fee price',
  'txt-request-change-sent': 'Request change sent success',
  'txt-vsd-number': 'VSD Number',
  object: {
    file: 'File',
    url: 'Url',
    img: 'Image',
    text: 'Text',
  },
  order: {
    normal: 'Normal',
    quick: 'Quick',
    conditional: 'Conditional Order',
  },
  navbar: {
    maxItemToAdd: 'Add max 15 categories in watchlist',
    maxItemToPin: 'Maximum pinned watchlist is 5',
    watchListName: 'Watchlist name',
    symbolExisted: 'The symbol is existed already',
  },
  orderChannel: {
    offline: 'Offline',
    cc: 'Contact Center',
    co: 'Conditional Order',
    app: 'App',
    bloomberg: 'Bloomberg',
    web: 'Web',
    pist: 'PineX',
    stk: 'Stock123',
    nab: 'Nam A Bank',
    openApi: 'Open Api',
    bnsNAB: 'Bonus of Nam A Bank',
  },
  orderPanel: {
    displayOddlot: 'Display odd lot price',
    warningOddlot:
      'You’re choosing displaying odd lot price. \n Please check the price before confirm order.',
    warningLot:
      'You’re placing odd lot order. \n Please check odd lot market price again before confirm order.',
  },

  symbolType: {
    long: {
      symbol: 'symbol',
      contract: 'contract',
    },
    short: {
      symbol: 'sym',
      contract: 'cont',
    },
  },
  quantity: {
    short: {
      b: 'B',
      m: 'M',
      k: 'K',
    },
    long: {
      b: 'Billion',
      m: 'Million',
      k: 'Thousand',
    },
    many: {
      b: 'Billions',
      m: 'Millions',
      k: 'Thousands',
    },
  },
  time: {
    short: {
      d: 'D',
      w: 'W',
      m: 'M',
      y: 'Y',
    },
    long: {
      d: 'Day',
      w: 'Week',
      m: 'Month',
      y: 'Year',
    },
    many: {
      d: 'Days',
      w: 'Weeks',
      m: 'Months',
      y: 'Years',
    },

    dayOfWeekShort: {
      monday: 'Mon',
      tuesday: 'Tue',
      wednesday: 'Wed',
      thursday: 'Thu',
      friday: 'Fri',
      saturday: 'Sat',
      sunday: 'Sun',
    },

    dayOfWeek: {
      monday: 'Mon',
      tuesday: 'Tue',
      wednesday: 'Wed',
      thursday: 'Thu',
      friday: 'Fri',
      saturday: 'Sat',
      sunday: 'Sun',
    },

    months: {
      january: 'January',
      february: 'February',
      march: 'March',
      april: 'April',
      may: 'May',
      june: 'June',
      july: 'July',
      august: 'August',
      september: 'September',
      october: 'October',
      november: 'November',
      december: 'December',
    },
    toDateLessThanFromDate: 'To date is greater than from date',
    fromDateGreaterThanToDate: 'From date can not greater than to date',
  },
  contract: {
    short: {
      c: 'Cont',
    },
    long: {
      c: 'Contract',
    },
    many: {
      c: 'Contracts',
    },
  },
  formValidation: {
    required: 'Field is required.',
    maxLength: 'Allows maximum {{length}} characters.',
    minLength: 'Allows minimum {{length}} characters.',
    patternLetterNumber: 'Allows letters (a-z, A-Z) and numbers (0-9).',
  },
  formTrade: {
    enterSymbol: 'Enter symbol',
    enterPrice: 'Price x1000',
    enterVolume: 'Volume x1',
    availableBalances: 'Available balances',
    portfolio: 'Portfolio',
    symbolInformation: 'Symbol information',
    priceList: 'Price list',
    chartDay: 'Day chart',
    available: 'Available',
    volume: 'Volume',
    ap: 'Avg price',
  },
  inday: {
    allSubAcc: 'All sub-accounts',
    order: 'order in day',
    orders: 'orders in day',
  },
  formForgotPass: {
    errorReason: 'Failure to reset password due to: ',
  },
  setting: {
    unReg: 'Unregist',
    reg: 'Regist',
    reset: 'Reset',
    service: 'Service',
  },
  account: {
    updateProcessing: 'The account has a pending information change request',
    accountAuthorization: 'Password & authenticate',
    defaultReg: 'Regist default',
    personal: {
      id: 'Personal ID',
      number: 'ID number',
      kind: 'ID kind',
      type1: 'CMND',
      type2: 'CCCD',
      residenceAddr: 'Residence Address',
      issuedPlace: 'Issued Place',
      issuedDate: 'Issued Date',
      expireDate: 'Expire Date',
      fullname: 'Full name',
      taxNumber: 'Tax number',
    },
    emailPhoneModal: {
      changeTitle: 'Change information',
      newEmail: 'New email',
      newPhone: 'New phone number',
      confirmNewOTP: 'Input OTP have sent your new phone number.',
    },
    addressModal: {
      newContact: 'New contact address',
      changeTitle: 'Change contact address',
      changeDesc: 'We will review your contact address before update',
    },
    personalModal: {
      changeTitle: 'Change ID infomation',
      frontImage: 'Front image',
      backImage: 'Back image',
      upload: 'Upload',
      uploadMaxSize: 'Upload file tải lên chỉ được tối đa 4MB',
      uploadDesc: 'Upload CCCD image (<4MB)',
      uploadInvalid: 'Image format is invalid',
      updateSuccess: 'Information change request sent successfully',
      compare: 'please change information',
      issuedPlaceWrong: 'Please choose the place of issue',
    },
    authen: {
      inputOTP: 'Input OTP',
      inputMatrix: 'Input Matrix',
      smartOTPDesc:
        "Search and Download {{highlight}} app on Appstore, CH Play if you haven't",
      smsOTPDesc:
        'Please enter the OTP number sent to phone number {{phonenum}}',
      matrixDesc: 'Please enter MATRIX code to confirm transaction',
      back: 'Back',
      confirm: 'Confirm',
      title: 'Choose method verify OTP',
      help: 'In case you do not use the above authentication method, please see the guidance <a class="text-highlight" target="_blank" href="https://pinetree.vn/en/post/dich-vu/update-information">here</a></span>',
      confirmPassIsNotMatch:
        'The confirm password is not match to the new pass',
      oldNewPassNotAllowSame: 'The new password is same the old password',
      letEnterOTPCorrect: 'Please enter correct authentication code',
      smartCA: {
        name: 'CA',
        payFee: 'Pay fee for VNPT',
        registerMethod: 'Register at VNPT',
        smartCADesc: 'Please verify over VNPT app',
        completed: 'Complete Digital signature on VNPT app to verify.',
        authenRejected: 'Authentication request denied',
        authenTimeout: 'Authentication request has expired',
      },
      rightToUseInfor: {
        warningTitle: 'Denied use information',
        warningDesc1:
          'Please kindly confirm the Acceptance on Data Processing before July 22nd, 2023. Otherwise, online trading features will be suspended due to the restriction on trading data processing. Pinetree shall not be responsible for any issues arising from such restriction.',
        warningDesc2:
          'Please kindly confirm the Acceptance on Data Processing. Online trading features on your account will be suspended due to the restriction on trading data processing. Pinetree shall not be responsible for any issues arising from such restriction. For further information, please contact our Customer Service at (+84) 24 6282 3535',
        contract: {
          note: "<p class='p-0 fw-400 text text--sm text--light3'>To continue using Pinetree's online platforms, please kindly accept the <a class='text text--light-blue3' href='{{link}}' target='_blanks'>data processing</a> by clicking the following checkbox:</p>",
          header: 'Dear Valued Customer',
          agree: 'I agree',
          later: 'Later',
          back: 'Back',
          continue: 'Countinue',
          signOut: 'Sign out',
          checkboxText: 'I agree to the Acceptance on Data Processing',
          successMsg: 'Confirm of data processing consent successful',
        },
      },
    },
    security: {
      changeDefaultAccSuccess: 'Default account changing is success',
      changeDefaultAccFail: 'Default account changing is fail',
    },
  },
  personal: {
    nav: {
      asset: {
        name: 'Asset',
        portfolio: 'Portfolio',
        assetManagement: 'Asset Management',
        overview: 'Overview',
      },
      margin: {
        name: 'Margin',
        marginList: 'Margin List',
        marginDebt: 'Margin Debt',
        marginExtending: 'Margin Extending',
      },
      payment: {
        name: 'Payment',
        depositAndWithdrawMoney: 'Deposit / Withdraw Money',
        depositAndWithdrawMoneyDerivative: 'Deposit / Withdraw Margin',
        cashInternalTransfer: 'Cash Internal Transfer',
        cashAdvance: 'Cash Advance',
        stockInternalTransfer: 'Stock internal Transfer',
        rightInfo: 'Right Info',
        holdUnholdBank: 'Hold Unhold Bank',
        inputAmount: 'Input Amount',
      },
      statement: {
        name: 'Statement',
        orderStatement: 'Order Statement',
        conditionalOrderStatement: 'Conditional Order Statement',
        cashStatement: 'Cash Statement',
        securitiesStatement: 'Securities Statement',
        executedGainLossStatement: 'Executed Gain Loss Statement',
      },
      account: {
        name: 'Account',
        accountInformation: 'Account Information',
        bankAccount: "Bank's Account",
        authorization: 'Authorization',
        paswordAuthenticate: 'Pasword & Authenticate',
      },
      setting: {
        name: 'Services Setting',
        SMSServices: 'SMS Services',
        registerServices: 'Register Services',
      },
    },
    asset: {
      totalAccount: 'Total account',
      totalOverview: {
        totalAsset: 'Total Asset',
        netAssetValue: 'Net Asset Value',
        withdrawable: 'Withdrawable',
        availableAdvance: 'Available Advance',
        marginDebt: 'Margin debt',
        rTT: 'RTT',
        pP: 'PP',
      },
      assetAllocation: 'Asset allocation',
      debtToAssetRatio: 'Debt to asset ratio',
      cash: 'Cash',
      listedSecurity: 'Listed securities',
      bondOTC: {
        name: 'Bond OTC',
        availBond: 'Avail Bond',
        receiving: 'Receiving',
      },
      netAssetValue: 'Net Asset Value',
      debt: 'Debt',
      derivative: {
        cashAtVSD: 'Cash at VSD',
        valueAtVSD: 'Value of security at VSD',
        usedMargin: 'Margin required',
        profitWaitForSettlement: 'Profit VM',
        liability: 'Liability',
        lossSettlement: 'Loss VM',
      },
    },
  },

  bond: {
    fix: {
      penaltyRate: 'Penalty Rate',
      penaltyRateNote:
        'Penalty rate is applied because of selling before due date',
      rate: 'Nearest term int. rate',
      rateNote: "Nearest to the bond's actual holding days of bond",
      promotionByNav: 'Promotion by NAV',
      rateBeforeTaxFee: 'Rate before tax & fee',
      rateBeforeTaxFeeNote:
        'Rate before tax & fee = Nearest term int. rate + Bonus rate - Penalty rate',
      rateExtension: 'Extension Rate',
    },
    moneyFlowWarning: 'Coupon value is after tax',
    name: 'Bond',
    bondOTC: 'OTC',
    collateral: 'Collateral',
    bondListed: 'Public',
    bondPrivate: 'Private',
    viewDetail: 'View detail',
    collateralDetail: {
      title: 'View collateral detail',
      type: 'Type',
      ValuationDate: 'Valuation date',
      rate: 'Rate',
      value: 'Value',
    },
    introduce: 'PRODUCT INTRODUCTION',
    select: 'Select...',
    issuer: 'Issuer',
    totalProvisionalValue: 'Total provisional value',
    selAvgPrc: 'Avarage Sell price',
    filter: 'Filter',
    brochure: 'Brochure',
    couponFrequency: 'Coupon Frequency',
    coupon: 'Coupon',
    currentCoupon: 'Current coupon',
    parValue: 'Par Value',
    forProfessionalInvestors: 'For Professional Investors',
    issueDate: 'Issue date',
    information: 'Bond information',
    tradeDate: 'Trade Date',
    allocationQuantity: 'Allocation quantity',
    manual: 'Manual',
    auto: 'Auto',
    orderConfirm: 'Order confirm',
    terms: 'Term',
    formOfOffering: 'Type of offering',
    professional: {
      name: 'Professional Investor',
      requiredTitle: 'This product is for professional investors',
      requiredDesc:
        'Register to become a professional investor. More details here',
    },
    totalValue: {
      proRate: 'Promotion by NAV',
      realRate: 'Real rate (after tax & fee)',
      extendRate: 'Extension rate',
      name: 'Total value',
      sell: 'Total sell value',
      buy: 'Total buy value',
    },
    totalPayment: 'Total payment',
    maxSell: {
      name: 'Max sell',
      overError: 'Exceeded the maximum selling volume',
      overDeal: 'Insufficient minimum purchase volume',
    },
    maxBuy: {
      name: 'Max buy left',
      value: 'Max buy value',
      valueLeft: 'Max buy value left',
      overError: 'Exceeded the maximum buying volume',
      minError: 'Insufficient minimum purchase volume',
      minMoneyError:
        'The minimum investment amount must be greater than the purchase price',
    },
    investAmount: 'Invest amount',
    bondQuantity: {
      name: 'Bond Quantity',
      placeHolder: 'Input quantity',
    },
    bondTotalSellMoney: 'Bond sell money',
    sellMoney: 'Sell money',
    principal: 'Principal',
    netProfit: 'Expected net profit',
    rate: 'Expected interest rate',
    totalAmountReceive: 'Expected total amount receive',
    settlementDate: 'Settlement Date',
    dueDate: 'Due date',
    quantity: 'Quantity',
    fee: 'Fee',
    otcFee: 'Regulated by the Depository Agent',
    otcTotalPayment: 'Total payment (not included fee)',
    otcTooltip:
      '0.1%/total transaction value at face value, min 500,000 VND, max 5,000,000 VND.',
    otcNoticeTitle:
      'Pinetree has received your order request. Pinetree will contact you soon to provide instructions on procedures of transferring bonds through the Depository Agent.',
    otcNoticeTitle1:
      'Your account does not have enough cash to place a bond order. Please contact Pinetree for support.',
    otcNoticeDesc:
      "<span class='r fw-500'>Note:</span> BCMBond_20.08 is private bond issued in 2020, which is not traded on HNX. With very attractive interest rate, BCMBond_20.08 is suitable for customers who choose to invest and hold to maturity. Customers who want to sell before the maturity can find their partners and carry out transfer procedures through the Depository Agent. Please refer to the bond documents <a class='text text--light-blue3 fw-500 cursor-pointer' target='_blank' href='https://pinetree.vn/en/post/20240202/bond-bcm/'>here</a>.",
    contact: 'Contact Pinetree',
    previewMoneyFlow: 'Preview money flow',
    cancel: 'Cancel',
    customerAccountNo: 'Customer Account No',
    dayIntendedToSell: 'Day intended to sell',
    bondOrder: 'Bond Order',
    intendedMoneyFlow: 'Intended Money Flow',
    dealMoneyFlow: 'Deal Money Flow',
    today: 'To day',
    contract: {
      name: 'Bond contract',
      sign: 'Bond transaction documents Sign',
      confirm:
        'By performing the confirmation below. The customer confirms to agree with the entire content of all above the bond transaction documents and accepts the conclusion of these bond transaction documents with Pinetree.',
      view: 'View transaction documents',
      desc: 'Customers read, understand and perform the following bond transaction documents in the form of electronic transactions',
      note: ' Pinetree only provides liquidity assistance for the bonds that Pinetree distributes and investors purchase from Pinetree. The liquidity assistance provided by Pinetree will be at an appropriate ask price under market conditions at times during the maturity of the Bonds.',
    },
    order: {
      infor: 'Order',
      type: 'Order',
      success: 'Order successful',
      fail: 'Order unsuccessful',
      dealForSale: 'List deal for sale',
      allocateQuantity: 'Allocate Quantity',
      remainQuantityToSell: 'Remain Quantity To Sell',
    },
    minInvestAmount: {
      name: 'Invest Amount',
      placeHolder: 'Input money amount',
    },
    productForProInvestor: 'Exclusive product for Professional Investors.',
    nav: {
      promotion: 'Promotion by NAV',
      name: 'NAV (dong)',
      value: 'Promotion (%/year)',
      asset: {
        name: 'Asset',
        portfolio: 'Portfolio',
      },
    },
    investor: {
      name: 'Investor type',
      perInvestor: '/Investor',
      type: {
        professional: 'Professtional',
        normal: 'Normal',
        unProfessional: 'Unprofessional',
      },
    },
    tableLabel: {
      totalSubAccount: 'Total account',
      subAccount: 'Sub account',
      dealID: 'Deal ID',
      orderID: 'Order ID',
      productCode: 'Product code',
      inputCode: 'Input code',
      productType: 'Product type',
      bondName: 'Bond Name',
      bondCode: 'Bond Code',
      bondType: 'Bond Type',
      tradeDate: 'Trade Date',
      maturityDate: 'Maturity date',
      rate: 'Rate %/ year',
      quantity: 'Quantity',
      order: 'Order',
      price: 'Price',
      maturityDuedate: 'Maturity/Due date',
      totalValue: {
        name: 'Total value',
        sell: 'Total sell value',
        buy: 'Total buy value',
        includedFee: 'Total buy value (Buy fee included)',
      },
      tax: 'Tax',
      fee: 'Fee',
      status: 'Status',
      rateAfterTaxAndFee: 'Rate (after tax & fee)',
      yieldToMaturity: 'Yield to Maturity',
      buyPrice: 'Buy Price',
      sellPrice: 'Sell Price',
      minQuantityOrder: 'Minimum Buy Quantity / Order',
      maxInvtQtyPerCust: 'Maximum Quantity / Client',
      collateral: 'Collateral',
      minQuantity: 'Minimum quantity',
      maxQuantityOrder: 'Maximum Quantity / Investor',
      availQuantity: 'Available Quantity',
      actions: 'Actions',
      soldQuantity: 'Sold Quantity',
      sellPrc: 'Sold Av. Price',
      sellAmt: 'Total Sell Value',
      quantityHasProfit: 'Quantity has profit',
      sellQuantity: 'Sell quantity',
      currentSellQuantity: 'Current selling price',
      remainValue: {
        name: 'Remaining value',
        desc: 'Tax fee not included',
      },
    },
    orderList: {
      name: 'Order list',
      label: {
        from: 'From',
        to: 'To',
        filter: 'Filter',
        placeHolder: 'Input bond name',
      },
      statusValue: {
        pending: 'Pending',
        confirmed: 'Confirmed',
        matched: 'Matched',
        canceled: 'Canceled',
        rejected: 'Rejected',
      },
    },
    dealList: {
      name: 'Deal list',
      label: {
        from: 'From',
        to: 'To',
        filter: 'Filter',
        placeHolder: 'Input bond name',
        dueDate: 'Due date',
        ratePerYear: 'Rate/year',
      },
      statusValue: {
        dealed: 'Dealed',
        paid: 'Paid',
        leg1: 'Leg 1',
        leg2: 'Leg 2',
        done: 'Done',
      },
      tooltip: 'Click to view money flow',
      notableToSell:
        'You need to hold bonds for a minimum number of days in order to sell before due date.',
    },
    portfolio: {
      name: 'Portfolio',
      tableLabel: {
        subAccount: 'Sub Account',
        bondName: 'Bond Name',
        bondCode: 'Bond Code',
        bondType: 'Bond Type',
        availableQuantity: 'Available Quantity',
        receivingQuantity: 'Receiving Quangtity',
        blockQuantity: 'Block Quantity',
        totalQuantity: 'Total Quantity',
        buyValueTotal: 'Buy Amount',
        totalNow: 'Current Value',
      },
    },
    bondDemo: {
      name: 'Demo',
      bank: 'Bank',
      suggest: 'Preferential interest rates are included',
      var: {
        bondRateLegend:
          'Expected interest rate per year when investing the bond',
        bankRateLegend: 'Interest rate per year when bank saving',
      },
      fix: {
        bondRateLegend:
          'Expected interest rate per year when investing the bond',
        bankRateLegend: 'Interest rate per year when bank saving',
      },
      titleChartFBond:
        'Table illustrating interest rates according to holding term',
      titleChart:
        'Table illustrating the expected interest rate in 1 year of holding',
      selectProduct: 'Select product',
      sellDate: 'Sell date',
      chooseExptSellDate: 'Choose an expected sale date',
      purchaseDate: 'Purchase date',
      investmentMoney: 'Investment money',
      note: 'Note: You will receive the depicted amount when the Issuer pays the principal and coupon of the bond in full and on time. For coupon periods where the coupon rate has not been determined, the illustrative cash flows are calculated on the basis of the current period coupon rate. The expected interest rate is calculated after taxes and transaction fees.',
    },

    bondProduct: {
      name: 'Bond product',
      year: 'year',
      time: 'time',
      times: 'times',
    },

    popUp: {
      chooseSellType: 'Please select the sell method',
      quickSell: 'Quick Order',
      sellFromDealList: 'Sell ​​from bond contract',
    },
    retailTrading: {
      desc: 'Customers read, understand and perform the following contracts and documents/documents in the form of electronic transactions',
      title: 'Private Bond Trading Registration',
      confirm:
        'I have fully read and agreed with the entire content of the registration form, and clearly understood the risks associated with this product.',
      label: 'Private Bond Trading Registration',
      registSuccess: 'Register Private Bond Trading Sucessful',
      registSuccessDetail:
        'You can trade private bonds on the next working day after the service is successfully activated at VSDC. If you have not yet been a Professional Investor, please contact our Customer Service to verify your Professtional status according to <a class="active r" href="https://pinetree.vn/en/post/dich-vu/professional-investor-confirmation" target="_blank">the following instructions.</a>',
      pendingNoti:
        'Your private bond transaction registration is pending approval by VSD',
      pendingStatus: 'Pending approval by VSD',
    },
  },
  cashAdvance: {
    name: 'cashAdvance',
    settlementAmount: 'Settlement amount',
    advanceMaxAmount: 'Advance Max Amount',
  },

  transaction: {
    name: 'Transaction',
    input: {
      maxExceeded: 'Maximum',
      minExceeded: 'Minimum',
    },
    cashInternalTransfer: {
      name: 'Cash Internal Transfer',
      moneyAvailable: 'Money available',
      withdrawable: 'Withdrawable',
      transferAmount: 'Transfer Amount',
    },

    holdUnholdBank: {
      name: 'Hold / Unhold Bank',
      currentBalance: 'Current balance',
      amountOnHold: 'Amount on hold',
      code: 'Code',
    },
    stockInternalTransfer: {
      name: 'Stock Internal Transfer',
      holdingVolume: 'Holding Volume',
      maxTransfer: 'Max Transfer',
      transferVolume: 'Transfer Volume',
      source: 'Source',
      target: 'Target',
      inputAmount: 'Input amount',
    },
    right: {
      name: 'Right subscription',
      history: 'Look up rights implementation information',
    },
    marginList: {
      name: 'Margin list',
      symbolName: 'Symbol name',
    },
    marginDebt: {
      name: 'Margin debt',
      pay: 'Pay',
      extend: 'Extend',
      extenHis: 'Extend history',
      extendRequestSuccess: 'Your debt extension registration is sent',
      extendRequestFail: 'Your debt extension registration is rejected',
      extendRequestSuccessMessage:
        'Your request to extend your margin loan has been received. Pinetree will review and notify the result to you within 05 working days.',
      wait: 'Waiting',
      approved: 'Approved',
      rejected: 'Rejected',
      enterValue: 'In amount',
      oldExprdt: 'Old expire date',
      newExprdt: 'New expire date',
      extendingTime: 'Extending time',
      extendingConfirm: 'Extending confirm',
      remainingPrincipalDebt: 'Remaining principal debt',
      extendingFee: 'Extending fee',
      remainingInterest: 'Remaining Interest',
      interestRatAfterExtend: 'Interest Rate After Extension',
    },
    conditionalOrderStatement: {
      name: 'Conditional Order Statement',
      allOrderType: 'All order type',
      allConditionType: 'All Condition type',
      allStatus: 'All status',
    },

    withdraw: {
      add: 'Add',
      subAccount: 'Sub account',
      bankRecipient: 'Bank Recipient',
      qrCode: {
        name: 'QR Code',
        text1:
          'Use the QR code generated by your beneficiary account at Pinetree',
        text2:
          'Open the banking app to scan the code directly, or save the photo and use later',
        saveQR: 'Save QR code image',
      },
    },
  },
  trading: {
    allStatus: 'All status',
    method: 'Method',
    matchedVol: 'Matched vol',
    activeCondition: 'Active Condition',
    orderCondition: 'Order Condition',
    orderPutThrough: {
      name: 'Put Through Order',
    },
  },

  user: {
    homepage: 'Homepage',
    helpCenter: 'Help center',
    bankAccount: 'Bank Account',
    passwordAndAuth: 'Pasword & Authenticate',
    authenticated: 'Authenticated',
    skypeLink: 'Skype video call at',
    createPortFolioAcc: 'Create portfolio account',
    openAccount: 'Open {{accType}} Account',
    success: '{{ActionType}} Derivative account success',
    fail: '{{ActionType}} Derivative account fail',
    registSuccess: 'Successfully Registered',
    videoCallState: {
      pending: 'Wating for processing booking video call at {{time}}',
      confirm: 'Link skype for video call at {{time}}',
      reject: 'Reject booking video call at {{time}}. Reason ',
    },
    bookingMarginVideoCall: {
      open: 'Open Margin Account',
      title: 'Upgrade margin account',
      upgrade: 'Upgrade margin limit up to {{max}} {{unit}}',
      name: 'Booking a video call to upgrade margin limit of up to {{max}} {{unit}}',
      selectDayTitle: 'Select your available day to call video',
      selectTimeFrameTitle: 'Select time frame',
      booking: 'Book now',
      pending: 'Pending',
      success: 'Successful',
      rejected: 'Rejected',
      messageRegistBookingSuccess: 'Send booking success',
      messageCancelBookingSuccess: 'Cancel booking success',
      loading: 'Loading',
      notiSuccess:
        "Customer's margin account was opended successful. Please log out, then login again.",
      wantUpgrade: 'Do you want upgrade account?',
      upgradeDesc: 'to use margin lending limit of up to',
      registDesc:
        "Your account's margin lending limit is {{max}} {{unit}} VND.",
      termAndCondition:
        'I have read and agreed to the Margin Trading Terms and Conditions at Pinetree.',
      registSuccess: 'You have opened margin account successfully',
    },
    bookingDerivativeVideoCall: {
      open: 'Open Derivative Account',
      name: 'Booking a video call to upgrade derivative limit up to {{max}} {{unit}}',
      upgrade: 'Upgrade Derivative Account {{max}} {{unit}}',
      wantUpgrade:
        'Do you want to upgrade your account to unlimited trading limit?',
      registDesc: 'Your account trading limit is {{max}} contracts.',
      termAndCondition:
        'I have read and agreed to the Derivative Trading Terms and Conditions at Pinetree.',
      registSuccess: 'Successfully Registered',
    },
  },
  news: {
    newsfeed: 'Newsfeed',
    hotnews: 'HotNews',
    hotArticle: 'Hot Article',
    lastestArticle: 'Lastest Article',
    allWatchlist: 'News by watchlist',
    allCategory: 'News by category',
    loadmore: 'Load more...',
    relatedVideo: 'Related Video',
    watchNow: 'Watch now',
    allMarket: 'All Market',
    usIndices: 'USIndices',
    globalIndices: 'Global Indices',
    commodity: 'Commodity',
    video: 'Video',
    marketBrief: 'Market brief',
    morningBrief: 'Morning brief',
    marketNews: 'Market News',
    attachment: 'Attachment',
  },
  pinefolio: {
    name: 'PineFolio',
    list: 'All PineFolio List',
    investPlan: 'Invest plan',
    code: 'Code',
    performance: 'Price Movements Portfolio',
    vniChart: 'VNI',
    pinefolioChart: 'PineFolio',
    stockOutside: 'Stock outside PineFolio',
    stockOutsideSellNotice: 'Please sell bonds in the plan management section.',
    noOdd: 'Has not symbol in odd set to sell',
    pf: 'PF',
    pfCode: 'PF code',
    risk: {
      aggressive: 'Aggressive',
      balanced: 'Balanced',
      defensive: 'Defensive',
      simple: 'Simple',
      other: 'Other',
    },
    nav: {
      productList: 'Product list',
      orderList: 'Order list',
      portfolio: 'Portfolio',
      planManagement: 'Plan management',
      analysisReport: 'Analysis report',
    },
    theme: {
      report: {
        date: 'Date',
        type: 'Report type',
        fileName: 'File name',
      },
      performanceExplain:
        'Is the % of price movement matched against the Portfolio reference price of the current trading day. The matched price of the Portfolio is calculated according to the matching price of each stock in the portfolio according to the standard volume of 1 set, with a delay of 5 seconds from reality.',
      value: {
        desc: 'The market value of the portfolio is calculated at the current matching price of the securities and has a delay of 5 seconds from the actual price.',
      },
      allocationRate: {
        desc: 'The allocation ratio is calculated according to the value of securities in the portfolio based on the reference price of the trading day.',
      },
    },
    rebalancePortfolio: {
      name: 'Rebalance Portfolio',
      rebalanceRate: 'Rebalance rate',
      volumeInPortfolio: 'Volume in portfolio',
      volumeInPlan: 'Volume in plan',
      reblanceConfirm: 'Yes! Rebalance',
      reblanceConfirm2: 'Confirm rebalance',
      later: 'Later',
    },
    plan: {
      name: 'Plan',
      depositTo: 'Deposit to',
      rebalance: 'Rebalance',
      planRunning: 'Effective plan',
      depositRemain: 'Target accumulation value',
      deposited: 'Accumulated',
      running: 'Running',
      messageComplete: 'You completed deposit',
      messageCompleteDesc:
        'I agree to move the “Start Date”, “Purchase Date” of all subsequent “Investment Periods” accordingly.',
      orderBuyNow: 'Order buy now',
      buyFollowPlanAt: 'Buy follow plan at',
      depositConfirm: 'Deposit confirm',
      remain: 'Remain',
      depositAmount: 'Accumulated amount',
      amountNextPeriod: 'Amount next period',
      cashAvailable: 'Available Cash',
      completedPlan: 'Completed plan',
      startDepositDate: 'Start deposit date',
      buyingOrderDate: {
        name: 'Buying order date',
        explain:
          'Is the expected purchase date according to the original creation or adjustment plan of the relevant Investment Plan.',
      },
      numberOfPeriod: 'Number of period',
      depositDate: 'Accumulation date',
      depositType: {
        name: 'Deposit type',
        schedule: 'Schedule',
        manual: 'Manual',
      },
      dayOfInterestRate: 'Days of interest rate',
      dayOfInterestRateDesc:
        'Calculated from the deposit date  to the expected end date or actual end date of the Investment Period.',
      estimateInterest: 'Estimate interest',
      estimateInterestDesc:
        'Interest period is Calculated from the deposit date to the expected end date or actual end date of the Investment Period.',
      stopPlan: 'Stop plan',
      pack: 'Pack',
      planId: 'Plan ID',
      portfolioCode: 'Portfolio code',
      planType: 'Plan type',
      depositStartDate: 'Start date',
      totalAmountOfPlan: 'Total amount of plan',
      stopPlanDate: {
        name: 'End date',
        explain:
          'Is the Completion or Cancellation Date of the relevant Investment Plan.',
      },
      receptedInterest: {
        name: 'Recepted interest',
        explain:
          'Is the sum of real profits received of all accruals in the relevant Investment Plan.',
      },
      fullSet: 'Set',
      depositSuccess: 'Deposit success',
      stopPlanNotice:
        "The system will stop all unfinished investment periods. The plan's unfinished bond contracts will continue to be valid until the customer sells out.",
      depositedValue: 'Cumulative bond value',
      yesStopPlan: 'Yes! stop plan',
      stopPlanSuccess: 'Stop plan success',
      splitSet: 'Pack',
      confirmCreatePlan:
        'I agree with the entire content of all the contracts and accepts the conclusion of contracts and transaction documents/documents with Pinetree',
      submit: 'Submit',
      confirmEditPlanMsgBf:
        'The system will automatically move the start date, end date and recharge schedule of the next periods',
      confirmEditPlanMsgafter:
        'days later. If the closing date after the rollback falls on a holiday, the closest trading day to that holiday will automatically be taken.',
      toastMsgEditSucess: 'Edit plan success',
      viewPlanTicket: 'View plan ticket',
      depositAmountIntended: 'Accumulation goal',
      nontermInterestRate: 'Support interest rate',
      supportInterestRate: 'Interest rate up to 11%/year',
      supportInterestRateTable: 'Interest rate support table',
      createPlan: {
        name: 'Create plan',
        contract: {
          name: 'Investment plan registration form',
        },
        createPlanTitle: 'Create a bond accumulation plan to buy PF',
        createPlanTitleSm:
          "Each investment period doesn't exceed 90 days from the start date to the buying order date.",
        confirmCreatePlan:
          'I agree with the entire content of all the contracts and accepts the conclusion of contracts and transaction documents/documents with Pinetree',
        buyingOrderDateExplain:
          'Buying order date: Optional but must not exceed 90 days from the creation/start date of the investment period. If it is not a business day, the order will be placed on the next business day.',
        createPlanNote:
          'The target value of the Investment Plan/Term is temporarily calculated based on the ceiling price of the corresponding securities at the date of establishment plus the provision for price fluctuations.',
        createPlanNoteSplitSet:
          'The start date of the following investment period is the day immediately following the end date of the previous investment period.',
        createPlanNote1:
          'An Investment Plan may be divided into one investment period ("Full Set") or several investment periods ("Split Pack"). When selecting multiple investment periods, the number of investment periods will correspond to the number of parts (“Pack”) of the Portfolio, each investment period can only buy 1 pack of the Portfolio.',
        supportRateExplain:
          'The interest rate is calculated on the actual accumulation date and is paid on the closing date of the investment period.',
        lastDepositDate: 'Deposit deadline',
        createPlanNote2:
          "Order date is adjusted backwards from the plan but must not exceed 90 days from the date of creation/start of the investment period. If it's not a business day, an automatic buy order will be placed the next business day.",
        monthlyDay: { name: 'Monthly day', placeHolder: 'Input from 1 to 31' },
        weeklyDay: { name: 'Weekly day', placeHolder: 'Input from 2 to 6' },
        getReminder: {
          name: 'Get reminder',
          none: 'None',
          monthly: 'Monthly',
          weekly: 'Weekly',
        },
        bondAccumulatedValue: 'Bond accumulated value',
        saveChangeReminder: 'Save change reminder',
        saveChangePlan: 'Save change plan',
        cancelPlan: 'Cancel plan',
        buyBond: 'Buy bond',
      },
      buyDateBefore: 'Old buy date',
      buyDateAfter: 'New buy date',
      depositHistory: 'Accumulated history',
      planHistory: {
        name: 'Plan history',
        explain:
          'Displays information of all investment plans that have ended (completed or canceled).',
      },
      endDateExplain:
        'Is the Expected Purchase Date or Completion Date or Cancellation Date of the relevant Investment Period/Investment Plan.',
      accumulateMoney: 'Accumulate money',
      setValue: {
        name: '',
        explain:
          'The target value of the Investment Plan/Term is temporarily calculated based on the ceiling price of the corresponding securities at the date of establishment plus the provision for price fluctuations.',
      },
      bondOrderList: {
        name: 'Bond order list',
      },
      bondDealList: {
        name: 'Bond deal list',
        pfYN: {
          name: 'PF YN',
          yes: 'Y',
          no: 'N',
          desc: 'Y: the bond contract has been separated from the plan \n N: The bond contract is under plan',
        },
      },
      noticeChangePack:
        'Only support to edit the purchase date for each pack one by one. \n You are correcting the purchase date of pack {{packEditing}}. \n You are correcting the purchase date of pack.',
    },
    orderList: {
      name: 'Name',
      riskLevel: 'Risk level',
      pinefolioCode: 'PineFolio code',
      tradeDateFrom: 'Trade date from',
      dateFrom: 'Date from',
      dateTo: 'Date to',
      tradeDateTo: 'Trade date to',
      inputFileName: 'Input file name',
      matchAvgPrice: 'Match Avg.Price',
      matchedVol: 'Matched Volume',
      totalValueAfterTaxFee: 'T.Val after Tax Fee',
      orderPrice: 'Order price',
      orderVolume: 'Order Volume',
      quantity: 'Quantity',
      matched: {
        name: 'Matched',
        desc: 'Only show the matched volume for each complete set',
      },
      expireDate: 'Expiration date',
      expireDateExplain:
        'Buy orders are valid within 5 working days. Sell ​​orders take effect within 1 business day.',
      investType: 'Investment type',
      investTypeExplain:
        'Indicates the source of the orders placed. From Customers who order Directly or from Investment Plans or from Restructuring.',
      orderAmp: {
        name: 'Order value',
        explain:
          'The order value is calculated according to the rule: buy orders follow the ceiling price and sell orders follow the floor price',
      },
      orderDate: 'Order date',
      status: {
        new: 'New',
        executing: 'Processing',
        completed: 'Completed',
        cancel: 'Cancel',
        break: 'Break',
        expired: 'Expired',
        processing: 'Processing',
        canceled: 'Canceled',
        waitingForExecuting: 'Waiting for executing',
      },

      source: {
        direct: 'Direct',
        accumulation: 'accumulation',
        rebalance: 'Rebalance',
      },

      value: {
        desc: 'The market value of the portfolio is calculated at the current matching price of the securities and has a delay of 5 seconds from the actual price.',
      },
      matchValue: {
        name: 'Matched Value',
        explain:
          'Calculated according to the matched volume and actual matched price of securities codes in the portfolio. Same with fees and taxes.',
      },
    },
    account: {
      openNew: 'Open PineFolio Sub Account to use the service',
      confirm:
        'I have read and agree to the Portfolios Trading Terms and Conditions at Pinetree.',
      regisNewSucess: 'Create PineFolio account sucess',
      regisNewFail: 'Create PineFolio account fail',
    },
    order: {
      confirm:
        'By making the confirmation below, the Customer confirms that it has read and agreed to the full content of all of the above Portfolios Transaction Documents.',
      buy: 'Order Buy',
      sell: 'Order Sell',
      viewOrderTicket: 'View Order Ticket',
      overMaxSell: 'Exceeded the maximum selling volume',
      overPP: 'Exceeded the PP',
      buyOrderExplain:
        'The payment value is temporarily calculated according to the ceiling price of the trading day, according to the rules of order types ATO, ATC, MP, MTL. The actual payment value will be based on the matching price announced by the Exchange.',
      sellOrderExplain:
        'Order value is temporarily calculated according to the floor price of the trading day, according to the rules of order types ATO, ATC, MP, MTL.',
      value: {
        desc: 'The market value of the portfolio is calculated at the current matching price of the securities and has a delay of 5 seconds from the actual price.',
      },
      totalMoneyTemporary: 'Total money temporary',
      feeTemporary: 'Fee temporary',
      taxTemporary: 'Tax temporary',
      explain1:
        'Customer can complete before maturity or adjust the Purchase Order Date of the Investment Plan according to the Terms and Conditions of the product.',
      sellOrderExplain1:
        'Order to sell PF takes effect within 01 working day. Cannot edit/cancel during trading hours.',
      sellOrderExplain2:
        'Types of orders to sell securities pushed to the Exchange include ATO, ATC, MP, MTL, depending on the session and the Exchange.',
      buyOrderExplain1:
        'Order to buy PF is valid within 05 working days. Cannot edit/cancel during trading hours.',
      buyOrderExplain2:
        'Types of orders to buy securities pushed to the Exchange include ATO, ATC, MP, MTL, depending on the session and the Exchange.',
      note: 'Note',
      referenceValues: {
        name: 'Reference values',
        desc: "Price Ceiling - 'The cap value of the portfolio is calculated according to the ceiling price of the securities in the portfolio according to the standard volume of 1 set' \n Price Floor - 'The floor value of the portfolio is calculated according to the floor price of the securities in the Portfolio according to the standard volume of 1 set' \n Reference Price - 'The reference value of the portfolio is calculated according to the reference price of the securities in the List according to the standard volume of 1 set'",
      },
      sellingBondValue: 'Selling Bond value',
      expectedToSellBond: 'Expected to sell Bond',
      expectedToUseOfPP: 'Expected use of PP',
      viewChangePlanTicket: 'View change plan ticket',
      changePlanTicket: 'Change plan ticket',
      buyingOrderNewDate: 'Buying oder new date',
      buyingOrderOldDate: 'Buying oder old date',
      listBondForSell: 'List bond for sell',
    },
    rebalance: {
      name: 'Rebalance',
      title: 'We have a new rebalance to optimize for Portfolio.',
      desc: 'Rebalance execution time from {{fromDate}} to {{toDate}}',
      contractHeader: 'Rebalance Pinefolio confirm',
      confirm:
        'I have read and agree to the Pinefolio Trading Terms and Conditions at Pinetree.',
      success: 'Rebalance success!',
    },
    portfolio: {
      warning:
        'The sellable volume is based on the balance of securities outside the PF Portfolio only',
      view: 'Go to PineFolio',
      name: 'Name',
      riskLevel: 'Risk Level',
      avgValue: 'Average Value / Set',
      avgValueExplain:
        'Average purchase price of each securrity symbol  x  standard volume of each securrity symbol in a portlios set.',
      mrkValue: 'Market Value / Set',
      performance: {
        name: 'Performance',
        perYear: 'In year',
        perMonth: 'In month',
        perDay: 'In day',
        explain:
          'Is the % variation between the market value and the average base value of a standard portfolio.',
      },
      avail: 'Avail Quantity',
      availQty: 'Avail',
      availExplain:
        'Is the volume of the set of Tradable Portfolio for the day.',
      totalQ: {
        name: 'Total Quantity',
        explain: 'Is the volume of the current portfolio set (complete set).',
      },
      totalV: 'Total Value',
      allocation: 'Allocation',
      action: 'Action',
      hideSoldOutPinefolio: 'Hide sold out PineFolio',
      volatility: 'Volatility during the day',
      marketPrice: 'Market price',
      set: 'Set',
      setExplain:
        'Is the volume of each stock ticker that is set according to the standard volume of a portfolio set.',
      odd: 'Odd',
      oddExplain:
        'Is the volume of each security code that is not round (odd) set according to the standard volume of 1 set of portfolios.',
    },
    analysisReport: {
      note: 'The in-depth reports are only available to portfolio holders.',
    },
  },
  marginCompetition: {
    name: 'Trading Competition',
    noRoundStarted: "The round hasn't started yet",
    generalIntroduction: {
      name: 'General introduction',
      coHostedBy: 'Co-hosted by',
      diversifiedGroups: 'Diversified groups',
      superPrize: 'Super prize Master Investor 500 million VND',
      totalAward: 'Total award up to 1.5 Billion VND',
      fairAndRule: 'Fair and transparent rule',
      eazyToReg: 'Easy to register',
      zeroFee: 'Free with lifetime 0 transaction fee',
      fixedMargin: 'Enjoy 9.9% fixed margin',
      roundStartIn: 'Round {{round}} start in',
      registrationPeriod: 'Registration period',
      competingPeriod: 'Competing period',
      roundFinished: 'Round {{round}} is finished.',
      roundIncoming: 'Round {{round}} is coming',
      roundCompeting: ' Round {{round}} is competing',
      agreeWithTC:
        '<a class="text--light-blue3" target="_blank" href="{{link}}">I agree to the Terms and Conditions of the contest</a>',
      viewResult: 'View result',
      viewInfo: 'View info',
      selectAccount: 'Select account type for competition',
      selectAccountForCompetition: 'Select account type for competition',
      content:
        'Co-organized by Pinetree Securities and Cafef (Cafef.vn), "Chứng Trường Bạc Tỷ" was first launched on a large scale nationwide, in order to provide an interesting playground for investors to experience securities investment, accumulate investment skills and win attractive prize',
      messageRegisCptSuccess:
        '<span>Sign up for the contest successfully.</span><span> Your competition nickname is {{nickName}} </span>',
      textConfirmUnReg: 'Do you want unregister',
      registed: 'Registed',
      stopRace: 'Cancel registration',
      unRegisterSuccess: 'Unsubscribe successfully',
      openMarginAccount:
        'If you have not opened a margin account. Please refer to the activation instructions <a class="text-warning fw-500" target="_blank" href="{{link}}">here</a>',
      days: 'd',
      hours: 'h',
      min: 'm',
      sec: 's',
    },

    ruleAndAwards: {
      name: 'Rule and Award',
      award: {
        title: 'Award',
        superPrize: 'Super prize',
        masterInvestor: 'Master Investor',
        billion: 'billion VND',
        million: 'million VND',
      },
      awardRule: {
        title: 'Award rules',
        rule1:
          'Prizes are given by Group. In case a prize has more than 1 legible winning participant, the priority order of prize consideration is based on: Larger trading amount -> More trading days -> Larger average investment capital -> Early registration date.',
        rule2:
          'In case there are more than 1 participant with same rate of return and priority categories, the prize will be shared: ',
        rule3:
          'Prize = (Total prize value of co-resulting positions/Number of co-resulting participant)',
      },
      receptAward: {
        title: 'Recpet award',
        rule1:
          'Gift will be given directly to customer or send to customer within 30 days from the date of eligibility for gift',
        rule2:
          "Cash for early securities transferring customers will be transferred directly to customers' competing sub account within 30 days from the date Pinetree receives valid proof of the securities transfer service fee/fee provided by the customer",
        rule3:
          'Cash will be transferred directly to the winners’ competing sub account within 45 days from the ending date of each round. The actual received value must comply with the provisions of the Government’s Tax law',
        rule4:
          "For Master Investor prize, winners will receive cash to competing sub account or other sub account. The prize could only be used to invest, trade securities on the sub account within 01 year from the date of receiving prize and cannot be used for other purposes. During this period, customers may add additional cash/securities to the sub account, but may not withdraw/transfer any cash or securities from that account. After 01 year, customer can withdraw cash/securities as normal and the result on that account is solely responsible by the customer. If the customer commits any violations during this period, the prize will be forfeited at Pinetree's discretion",
      },
      seeMore: 'See more',
      rank: 'Rank',
      competingRule: 'Competition rule',
      competitionAccountRule: {
        title: 'Regulation on Competing account:',
        rule1:
          'Normal sub account or Margin sub account opened at Pinetree Securities',
        rule2:
          'Eligibility: Initial investment capital of competing sub account is at least 10 million dong',
      },
      conditionsForConsideration: {
        title: 'Award consideration conditions:',
        rule1:
          'Return on investment on competing sub account is positive (>0%)',
        rule2:
          'Trading amount is at least 200% of the average investment capital',
        rule3: 'Have matched orders in at least 3 business days',
      },
      tableDivision: {
        title: 'Grouping:',
        rule1: 'Competing account will be dividied into 3 groups:',
        carpGroup:
          'Carb group: Initial investment capital from 10 million to below 100 million dong',
        koiGroup:
          'Koi group: Initial investment capital from 100 million to below 1 billion dong',
        whaleGroup:
          'Whale group: Initial investment capital from 1 billion dong or more',
      },
      ruleForCompetitionSecurities: {
        title: 'Regulation on stock to trade in the competition:',
        rule1: 'Only allow trading stock on underlying securities market',
        rule2:
          'All stock codes on HOSE/HNX/UPCOM which are allowed to trade as Pinetree listed',
      },
      actsOfViolatingTheRule: {
        title: 'Rule violating activities:',
        rule1:
          'The accumulated additional investment capital must not exceed 5 times the initial investment capital',
        rule2:
          'Transfer/Withdraw more stock to/from competing sub account (including securities depository, stock transfer from other sub account to competing sub account and vice versa) during the competing period or the effective date of stock recorded in the competing sub account in the competing period',
        rule3: 'Have deals and odd lot transactions',
      },
      caculationFomula: {
        title: 'Formula:',
        fomula:
          'Return on Investment (%) = Total return/Average investment capital',
      },
      otherGifts: {
        title: 'Other gifts',
        gift1: '2000 gifts for early registers or joining event',
        gift2:
          'Pay 100% securities transferring fee (maximum 1 million VND/account)',
        gift3: 'Opportunity to work at Pinetree',
        gift4: 'Opportunity to cooperate with Pinetree',
        gift5: 'Enjoy special privileges',
      },
      seeMoreRuleDetail: 'See more rule detail',
    },
    performance: {
      name: 'Performance',
      profit: 'Profit',
      loss: 'Loss',
      initialCapital: 'Initial capital',
      currentAverageCapital: 'Current average capital',
      totalWithdrawalAmount: 'Total withdrawal amount',
      totalAmountDeposited: 'Total amount deposited',
      assetTurnoverRate: 'Capital turnover ratio',
      totalDepositedNAV: 'Total net deposit/Initial capital',
      distribution: 'Distribution',
      investmenteffect: 'Invest effects',
      tooltip: {
        distribution:
          'Allocation ratio = market value of each securities / total market value of all securities.',
        assetTurnoverRate:
          'Capital turnover ratio = (Total value of matched purchases + accumulated sales)/ Current average capital.',
        totalDepositedNAV:
          'Total net deposit = total deposit - total withdrawal',
      },
    },
    leaderBoard: {
      name: 'Leaderboard',
      round: 'Round',
      nickname: 'Nickname',
      pnl: ' PnL',
      topTrading: 'Top trading',
    },
    QA: {
      name: 'Q&A',
    },
  },
  derivative: {
    name: 'Derivatives',
    contracts: 'Contracts',
    long: 'Long',
    short: 'Short',
    volLong: 'Long volume',
    volShort: 'Short volume',
    maxLong: 'Max long',
    maxShort: 'Max short',
    expireDate: 'Expired date',
    only: 'This function is only for derivative sub account',
    marginAmountCanBeDeposit: 'Margin amount that can be deposit',
    marginAmountCanBeWithdrawn: 'Margin amount that can be withdrawn',
    marginDepositAmount: 'Margin deposit amount',
    marginWithdrawalAmount: 'Margin withdrawal amount',
    dwmconfirm: 'Derivative margin {{action}} confirm',
    depWdrawAction: {
      deposit: 'Deposit',
      withdraw: 'Withdraw',
    },
    depWdrawRequestSuccess: '{{action}} success',
    quantityOverError: 'Exceeded the maximum amount can be {{action}}',
    label: {
      contractCode: 'Contract Code',
      closedPrice: 'Closed Price',
      closedQuantity: 'Closed Quantity',
      multiplier: 'Multiplier',
      matchedTime: 'Matching Time',
      matchedPrice: 'Matched Price',
      matchedVolume: 'Matched Volume',
      vn30Index: 'VN30 index',
      p1: 'P 1',
      p2: 'P 2',
      p3: 'P 3',
      v1: 'Vol 1',
      v2: 'Vol 2',
      v3: 'Vol 3',
      bas: 'Basis',
      quantity: 'Quantity',
      longVol: 'Long Vol',
      shortVol: 'Short Vol',
      longPrice: 'Long Price',
      shortPrice: 'Short Price',
    },
    closedPosition: {
      name: 'Closed Position',
    },
    openOrders: {
      name: 'Order List',
      originalNumber: 'Origin Number',
      cancelAll: 'Cancel all',
      cancelOrder: {
        title: 'Confirm cancel order',
        textConfirm: 'Do you agree to cancel order No.{{orderNo}}',
      },
      cancelAllOrder: {
        title: 'Confirm cancel all order',
        textConfirm: 'Do you agree to close all orders?',
      },
      messageCancelOrderSuccess:
        'Order no {{orderNo}} was cancelled successfully',
      messageEditedOrderSuccess: 'Order no {{orderNo}} was edited successfully',
    },
    totalAsset: {
      name: 'Total Asset',
      totalMoney: 'Total money',
      interestHeldForDebt: 'Total principal and interest debt',
      deliveryValue: 'Delivery value',
      totalProfitAndLoss: 'Total profit/ loss',
      cashAvailable: 'Available Cash',
      withdrawableSecurity: 'Withdrawable security',
      originalDebt: 'Original debt',
      latePaymentInterest: 'Late payment interest',
      odFeeEx: 'T0 Trading fee for Exch',
      odFeePT: 'T0 Trading fee for Pinetree',
      tax: 'T0 Tax',
      poFee: 'POFee',
      imFee: 'IMFee',
      feeTaxLoanAmt: 'Trading fee & Tax debt',
    },
    assetDetail: {
      name: 'Asset Detail',
      cash: 'Cash',
      valueOfMarginalSecurities: 'Value of marginal securities',
      valueOfValidMarginaSecurities: 'Value of valid margin securities',
      totalValidDepositedValue: 'Total valid deposited value',
      withdrawableMoney: 'Withdrawable money',
      availableMarginalAssetValue: 'Available marginal asset value',
      forceSell: 'Force Sell',
    },
    useOfMargin: {
      name: 'Use of margin',
      valueOfInitialMargin: 'Value of initial margin',
      valueOfDeliveryMargin: 'Value of delivery margin',
      useOfMarginalAssetRatio: 'Use of marginal asset ratio',
      totalValueOfRequiredMargin: 'Total value of required margin',
      requiredAdditionalMoney: 'Required additional money',
      warningThreshold: 'Warning threshold',
    },
    orderHistory: {
      remainQty: 'Remain Quantity',
      name: 'Order History',
      tradeDate: 'Trade Date',
      orderTime: 'Order Time',
      originalNumber: 'Original Number',
      orderNumber: 'Order Number',
      orderPrice: 'Order Price',
      matchedAvgPrice: 'Matched Avg Price',
      orderQuantity: 'Order Quantity',
      matchedQuantity: 'Matched Quantity',
    },
    plReport: {
      name: 'P/L Report',
      holdingPosition: 'Holding Position',
      releasePLInDay: 'Realised P/L in day',
      pl: 'P/L',
      qty: 'Qty',
      vmEndDay: 'VM (2)',
      closedQty: 'Closed Qty',
      closedPrice: 'Closed Price',
      totalQty: 'Total Qty',
      totalPL: 'Total P/L (1)',
      totalVM: 'Total (1+2)',
      vm: 'VM',
    },
    position: {
      title: 'Position',
      open: 'Open Position',
      openQuantity: 'Open Quantity',
      longPending: 'Long Pending',
      shortPending: 'Short Pending',
      marketPrice: 'Market Price',
      profitPerLost: 'Profit / Loss',
      closeAll: 'Close all',
      closeAllPosition: {
        title: 'Confirm close positions',
        textConfirm: 'Do you agree to close all positions',
      },
      closePosition: {
        success: 'The position reversal request has been sent successfully',
      },
      reversePosition: {
        title: 'Confirm reverse positions',
        success: 'The position reversal request has been sent successfully',
      },
    },
    placeOrder: {
      name: 'Place Order',
      closeALlPositionSuccess:
        'The order to close the position of symbol {{symbol}} was successfully placed',
    },
    topIndex: {
      label: 'Top Index',
    },
    confirmationSetting: {
      name: 'Order confirmation mode',
      alwayShow: 'Alway show',
      hideUntilRelogin: 'Hide until relogin',
      hideUntilTurnOn: 'Hide until turn on.',
    },
    depWdrawTp: {
      1: 'Manual deposit',
      2: 'Manual withdraw',
      4: 'Withdraw(end of month)',
      5: 'Auto deposit',
      6: 'Autho withdraw',
    },
    depWdrawNotes: {
      1: 'Margin deposit time from 8:00 to 16:30',
      2: 'Margin widthdraw time from 8:00 to 16:00',
      3: 'Margin withdrawal amount >= 5500 VNĐ',
      4: 'Margin money at VSD has left min 10,000 VNĐ',
      5: 'Margin deposit amount ≥ 20.000 VNĐ',
    },
  },
  survey: {
    derivative: 'Derivatives survey',
    drvxPromotion: 'Derivative promotion',
  },
  partner: {
    normalTradingFee: 'Normal trading fee',
    derivativeTradingFee: 'Derivative trading fee',
    marginLoanRate: 'Margin loan rate',
    derivativeFeeNote:
      'Derivatives Trading Fee included 2,700 VND/contract paid to the Exchanges.',
    changeTaxNoSuccess: 'Change tax number success',
    allStatus: 'All status',
    rateUsedN: 'Applicable Fee Rate',
    rateUsedM: 'Applicable Interest Rate',
    rateUsedD: 'Applicable Fee Rate',
    rateN: 'Trade Value from',
    rateM: 'Loan Value from',
    rateD: 'Quantity from',
    approve: 'Approve',
    reject: 'Reject',
    createRequestSuccess: 'Create request success',
    equitiesTradingFee: 'Equities Trading Fee',
    derivativesTradingFee: 'Derivatives Trading Fee',
    marginLoanInt: 'Margin Loan Int',
    validate: {
      vsdAccount: 'The VSD number of the customer must not be left blank',
      custPhone: 'The customer phone number must not be left blank',
    },
    cancelRequest: 'Cancel request',
    pending: 'Pending',
    canceled: 'Canceled',
    rejected: 'Rejected',
    completed: 'completed',
    registerDate: 'Register Date',
    requestTypeOption: {
      allRequest: 'All request',
      referral: 'Add referral',
      unreferral: 'Unreferral',
      serviceRegistration: 'Service registration',
      cancelServiceRegistration: 'Cancel service registration',
      changeReferral: 'Change referral',
      changeTheFeeGroup: 'Change the fee group',
    },
    cfmDate: 'Confirm Date',
    customerDescription:
      'The request will take effect on the next business day after the Customer/Partner confirms.',
    requestList: 'Request List',
    referrerInformation: 'Referrer Information',
    inviteReferral: 'Invite Referral',
    createdBy: 'Created By',
    referrerRequest: 'Referrer Request',
    textConfirmAddBroker:
      'I have read and agreed to the Partnership Terms and Conditions at Pinetree',
    referralName: 'Referral Name',
    removeBroker: 'Remove Broker',
    removeCustomer: 'Remove Customer',
    changeFeeDescription: 'Default fee schedule for all services',
    changeFee: 'Change Fee Policy',
    changeFeeDetail: 'Change Fee Policy Detail',
    detailRequest: 'Detail Request',
    approveMultipleRequest: 'Approve Multiple Request',
    cancelMultipleRequest: 'Cancel Multiple Request',
    requestType: 'Request Type',
    createdDate: 'Created Date',
    customerRequest: 'Customer Request',
    myRequest: 'My Request',
    tradingFee: 'Trading Fee',
    VSDAccount: 'VSD Account',
    phoneNumber: 'Phone number customer',
    vsdNumber: 'VSD Number customer',
    addNewCustomer: 'Add new customer',
    addNewBroker: 'Register Referrer',
    customer: 'Customer',
    customerList: 'Customer List',
    removeMultipleCustomer: 'Remove Multiple Customer',
    totalRemuneration: 'Total Remuneration',
    currentPeriodRemuneration: 'Current Period Remuneration',
    remunerationRate: 'Remuneration Rate',
    currentFeePolicy: 'Current Fee Policy',
    commissionType: 'Commission Type',
    referralCode: 'Referral Code',
    forReferrer: 'For Referrer',
    forReferee: 'For Referee',
    name: 'Partnership',
    infor: 'Partner information',
    id: 'Partner code',
    status: 'Status',
    link: 'Invitation link',
    qr: 'Invitation QR code',
    register: 'Register Partnership',
    receipt: 'Receipt',
    commission: 'Commission',
    successDesc: 'You have successfully registered to becom a partnership.',
    goto: 'Go to Partnership information',
    tnc: 'I have read and agreed to the Partnership Terms and Conditions at Pinetree.',
    npt: "I commit that I am not an employee of Pinetree and/or relatives, related persons of Party B's employees.",
    serviceType: 'Service Type',
    change_fee: 'Change Fee',
    subAccount: 'Sub account',
    cancelService: 'Cancel Service',
    feePolicyApplied: 'Fee policy applied',
    serviceStatus: {
      A: 'Active',
      P: 'Pending',
      E: 'Expired',
      N: 'Not Registered',
    },
    serviceLabel: {
      type: 'Type',
      feePolicy: 'Fee Policy',
      activeDate: 'Active Date',
      status: 'Status',
    },
  },
  cw: {
    vol: 'Vol(K)',
    openPrice: 'Open Price',
    highestPrice: 'Highest Price',
    lowestPrice: 'Lowest Price',
    totalVolume: 'Total Volume',
    totalValue: 'Total Value',
    ceilingPrice: 'Ceiling Price',
    floorPrice: 'Floor Price',
    refPrice: 'Ref Price',
    fSell: 'F.Sell',
    fBuy: 'F.Buy',
    issuer: 'Issuer',
    type: 'CW Type',
    style: 'CW Style',
    strikePrice: 'Strike Price',
    ratio: 'CVN Ratio',
    underlying: 'Underlying',
    underlyingPrice: 'Underlying Price',
    status: 'Status',
    intrinsic: 'Intrinsic',
    breakEven: 'Break even (Difference)',
    maturityDate: 'Maturity Date',
    lastTradingDate: 'Last Trading Date',
    timeToMaturity: 'Time to maturity',
    listedShare: 'Listed share',
    cwStatus: {
      trading: 'Trading',
      expiry: 'Expiry',
    },
  },
  openApi: {
    generateNewKey: 'Generate New Key',
    deviceId: 'Device ID',
    ipAddress: 'IP Address',
    downloadSource: 'Download client installation file and manual',
    createConnectionKey: 'Create Connection Key',
    openApiNote: 'Connection key is generated up to 3 times/day',
    create: 'Create',
    keyInfo: 'Key Info',
    clientId: 'Client ID',
    secretToken: 'Secret Token',
    privateKey: 'Private Key',
    downloadKey: 'Download Key',
  },
};
