import {
  SUMMARY_ORD_REQUESTING,
  SUMMARY_ORD_REQUEST_SUCCESS,
  SUMMARY_ORD_REQUEST_ERROR,
  SUMMARY_CASH_REQUESTING,
  SUMMARY_CASH_REQUEST_SUCCESS,
  SUMMARY_CASH_REQUEST_ERROR,
  SUMMARY_STOCK_REQUESTING,
  SUMMARY_STOCK_REQUEST_SUCCESS,
  SUMMARY_STOCK_REQUEST_ERROR,
  SUMMARY_EVENT_REQUESTING,
  SUMMARY_EVENT_REQUEST_SUCCESS,
  SUMMARY_EVENT_REQUEST_ERROR,
  SUMMARY_RIGHT_REQUESTING,
  SUMMARY_RIGHT_REQUEST_SUCCESS,
  SUMMARY_RIGHT_REQUEST_ERROR,
  SUMMARY_ORD_COND_REQUESTING,
  SUMMARY_ORD_COND_REQUEST_SUCCESS,
  SUMMARY_ORD_COND_REQUEST_ERROR,
  SUMMARY_NOTIFICATION_REQUESTING,
  SUMMARY_NOTIFICATION_REQUEST_SUCCESS,
  SUMMARY_NOTIFICATION_REQUEST_ERROR,
  NOTIFICATION_UPDATE_REQUEST,
  NOTIFICATION_UPDATE_REQUEST_SUCCESS,
} from './constants';

export const summaryOrdRequest = function summaryOrdRequest(params) {
  return {
    type: SUMMARY_ORD_REQUESTING,
    params,
  };
};

export const summaryOrdRequestSuccess = function summaryOrdRequestSuccess(
  ordList
) {
  return {
    type: SUMMARY_ORD_REQUEST_SUCCESS,
    ordList,
  };
};

export const summaryOrdRequestError = function summaryOrdRequestError(error) {
  return {
    type: SUMMARY_ORD_REQUEST_ERROR,
    error,
  };
};

export const summaryOrdCondRequest = function summaryOrdCondRequest(params) {
  return {
    type: SUMMARY_ORD_COND_REQUESTING,
    params,
  };
};

export const summaryOrdCondRequestSuccess =
  function summaryOrdCondRequestSuccess(ordCondList) {
    return {
      type: SUMMARY_ORD_COND_REQUEST_SUCCESS,
      ordCondList,
    };
  };

export const summaryOrdCondRequestError = function summaryOrdCondRequestError(
  error
) {
  return {
    type: SUMMARY_ORD_COND_REQUEST_ERROR,
    error,
  };
};

export const summaryCashRequest = function summaryCashRequest(params) {
  return {
    type: SUMMARY_CASH_REQUESTING,
    params,
  };
};

export const summaryCashRequestSuccess = function summaryCashRequestSuccess(
  cashList
) {
  return {
    type: SUMMARY_CASH_REQUEST_SUCCESS,
    cashList,
  };
};

export const summaryCashRequestError = function summaryCashRequestError(error) {
  return {
    type: SUMMARY_CASH_REQUEST_ERROR,
    error,
  };
};

export const summaryStockRequest = function summaryStockRequest(params) {
  return {
    type: SUMMARY_STOCK_REQUESTING,
    params,
  };
};

export const summaryStockRequestSuccess = function summaryStockRequestSuccess(
  stockList
) {
  return {
    type: SUMMARY_STOCK_REQUEST_SUCCESS,
    stockList,
  };
};

export const summaryStockRequestError = function summaryStockRequestError(
  error
) {
  return {
    type: SUMMARY_STOCK_REQUEST_ERROR,
    error,
  };
};

export const summaryEventRequest = function summaryEventRequest(params) {
  return {
    type: SUMMARY_EVENT_REQUESTING,
    params,
  };
};

export const summaryEventRequestSuccess = function summaryEventRequestSuccess(
  event
) {
  return {
    type: SUMMARY_EVENT_REQUEST_SUCCESS,
    event,
  };
};

export const summaryEventRequestError = function summaryEventRequestError(
  error
) {
  return {
    type: SUMMARY_EVENT_REQUEST_ERROR,
    error,
  };
};

export const rightEventRequest = function rightEventRequest(params) {
  return {
    type: SUMMARY_RIGHT_REQUESTING,
    params,
  };
};

export const rightEventRequestSuccess = function rightEventRequestSuccess(
  rightList
) {
  return {
    type: SUMMARY_RIGHT_REQUEST_SUCCESS,
    rightList,
  };
};

export const rightEventRequestError = function rightEventRequestError(error) {
  return {
    type: SUMMARY_RIGHT_REQUEST_ERROR,
    error,
  };
};

export const summaryNotificationRequest = function summaryNotificationRequest(
  params
) {
  return {
    type: SUMMARY_NOTIFICATION_REQUESTING,
    params,
  };
};

export const summaryNotificationRequestSuccess =
  function summaryNotificationRequestSuccess(notification) {
    return {
      type: SUMMARY_NOTIFICATION_REQUEST_SUCCESS,
      notification,
    };
  };

export const summaryNotificationRequestError =
  function summaryNotificationRequestError(error) {
    return {
      type: SUMMARY_NOTIFICATION_REQUEST_ERROR,
      error,
    };
  };

export const notificationUpdateRequest = function notificationUpdateRequest(
  params
) {
  return {
    type: NOTIFICATION_UPDATE_REQUEST,
    params,
  };
};

export const notificationUpdateRequestSuccess =
  function notificationUpdateRequestSuccess(params) {
    return {
      type: NOTIFICATION_UPDATE_REQUEST_SUCCESS,
      params,
    };
  };
