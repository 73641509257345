import React, { useState, useEffect } from 'react';
import { numberFormat } from '../../../util';
// import '../../../assets/scss/components/pinefolio/pinefolioAllocation.scss';

const PinefolioAllocations = ({ symbols, hiddenNoVol = true }) => {
  const PAGE_SIZE = 5;
  const [totalPage, setTotalPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [datas, setDatas] = useState([]);

  useEffect(() => {
    if (symbols && !!symbols.length > 0) {
      const _symbols = [...symbols];
      const syms = _symbols.slice(0, PAGE_SIZE);
      setDatas(syms);

      const count = symbols.length;
      const _totalPage = Math.ceil(count / PAGE_SIZE);
      setTotalPage(_totalPage);
    }
  }, [symbols]);

  const nextPage = () => {
    if (currentPage < totalPage) {
      changePage(currentPage + 1);
      // setSymbols
    }
  };
  const backPage = () => {
    if (currentPage > 1) {
      changePage(currentPage - 1);
    }
  };

  const changePage = (page) => {
    const _symbols = [...symbols];
    const syms = _symbols.slice((page - 1) * PAGE_SIZE, page * PAGE_SIZE);
    console.log('from =>', (page - 1) * PAGE_SIZE);
    console.log('to =>', page * PAGE_SIZE);

    setDatas(syms);
    setCurrentPage(page);
  };

  // todos: changePage function when currentPage is change

  if (!symbols || !!symbols.length == 0) return <></>;
  return (
    <div className="pinefolio-allocation">
      <div className="pinefolio-allocation__symbols">
        {datas &&
          !!datas.length > 0 &&
          datas.map((data, i) => (
            <div className="pinefolio-allocation__symbol" key={i}>
              <span className="text--sm text--light fw-500">{data.symbol}</span>
              <span className="text--sm text--gray fw-500">
                {hiddenNoVol
                  ? numberFormat(data.qty, 0, '')
                  : numberFormat(data.qty, 0, '0')}
              </span>
            </div>
          ))}
      </div>
      {totalPage > 1 && (
        <div className="pinefolio-allocation__actions">
          <div className="pinefolio-allocation__action">
            <span
              className={`btn-icon btn--light ${
                currentPage == 1 ? `disabled` : ''
              }`}
              onClick={() => backPage()}
            >
              <span className="icon iBack"></span>
            </span>
          </div>
          <div className="pinefolio-allocation__action">
            <span
              className={`btn-icon btn--light ${
                currentPage == totalPage ? `disabled` : ''
              } `}
              onClick={() => nextPage()}
            >
              <span className="icon iNext"></span>
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default PinefolioAllocations;
