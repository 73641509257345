import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import PerfectScrollBar from 'react-perfect-scrollbar';
import { Col, Card, Button } from 'react-bootstrap';
import {
  makeGetToken,
  makeGetListAccount,
  makeGetDebtMargin,
  makeGetRepayLoan,
  makeGetHisDebtMargin,
  makeGetCashBalance,
} from '../../lib/selector';

import { debtRequest, debtHisRequest } from '../../containers/cash/actions';

import { find, isEqual } from 'lodash';

import { numberFormat, formatDate, getTransferStatus } from '../../util';

import FormHisMargin from './form/formHisMargin';

import { ReloadButton } from '../btnReload';
import { v4 as uuidv4 } from 'uuid';

class CongNoMargin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      accountMargin: null,
      msg: [],
    };
  }

  componentDidMount() {
    const { listAccount } = this.props;
    console.log('cong no margin', listAccount);
    if (listAccount) {
      const accountMargin = find(listAccount, (o) =>
        o.subAcntNo.startsWith('M')
      );
      this.setState({ accountMargin }, () => {
        this._handleLoadDebt();
        this._handleLoadListDebt();
      });
    }
  }

  componentDidUpdate(preProps) {
    const { listAccount, repayLoan } = this.props;
    console.log('cong no margin', listAccount);
    if (listAccount && !isEqual(listAccount, preProps.listAccount)) {
      const accountMargin = find(listAccount, (o) =>
        o.subAcntNo.startsWith('M')
      );
      this.setState({ accountMargin }, () => {
        this._handleLoadDebt();
        this._handleLoadListDebt();
      });
    }

    if (repayLoan && !isEqual(repayLoan, preProps.repayLoan)) {
      console.log(repayLoan);
    }
  }

  _handleLoadDebt = () => {
    const { token } = this.props;
    const { accountMargin } = this.state;

    if (!accountMargin) return;

    const uuid = uuidv4();
    const resData = {
      group: 'CORE',
      user: token.user,
      session: token.session,
      cmd: 'getTotalDebt',
      rqId: uuid,
      channel: 'WTS',
      data: {
        acntNo: accountMargin.acntNo,
        subAcntNo: accountMargin.subAcntNo,
      },
    };

    this.props.dispatch(debtRequest(resData));
  };

  _handleLoadListDebt = () => {
    const { accountMargin } = this.state;
    const { token } = this.props;
    if (!accountMargin) return;

    const d = new Date();
    d.setDate(d.getDate() - 30);

    const uuid = uuidv4();
    const resData = {
      group: 'CORE',
      user: token.user,
      session: token.session,
      cmd: 'getMarginLoanList',
      rqId: uuid,
      channel: 'WTS',
      data: {
        acntNo: accountMargin.acntNo,
        subAcntNo: accountMargin.subAcntNo,
        fromDate: formatDate(d, ''),
        toDate: formatDate(new Date(), ''),
        status: '0',
      },
    };

    this.props.dispatch(debtHisRequest(resData));
  };

  handleLoadHistory = () => {};

  render() {
    const { accountMargin, msg } = this.state;

    const { hisDebt, debt } = this.props;

    console.log('debt', debt);

    return (
      <Fragment>
        <div className="mt-3 mb-2">
          <span
            className="mr-auto ml-2 text-white"
            style={{ fontSize: '18px' }}
          >
            {'Công nợ Margin'}
          </span>
        </div>
        <PerfectScrollBar>
          {!accountMargin && (
            <p className="text-white text-center fz_14">
              Quý khách chưa có tài khoản Margin
            </p>
          )}
          {accountMargin && (
            <Fragment>
              <div className="d-flex flex-column mt-2 mr-3 ml-3">
                <Card className="col-12 p-0">
                  <Card.Body style={{ borderRadius: '5px' }}>
                    <div className="d-flex align-items-center">
                      <Col
                        xs
                        lg="2"
                        className="p-3 d-flex flex-column"
                        style={{ borderRight: '1px solid #42465b' }}
                      >
                        <span className="text-content fz_14">Tổng dư nợ</span>
                        <span className="d fz_14 fw-500">{'8.000.000'}</span>
                      </Col>
                      <Col
                        xs
                        lg="2"
                        className="p-3 d-flex flex-column"
                        style={{ borderRight: '1px solid #42465b' }}
                      >
                        <span className="text-content fz_14">Nợ đến hạn</span>
                        <span className="text-white fz_14 d">
                          {'8.000.000'}
                        </span>
                      </Col>
                      <Col
                        xs
                        lg="2"
                        className="p-3 d-flex flex-column"
                        style={{ borderRight: '1px solid #42465b' }}
                      >
                        <span className="text-content fz_14">Nợ quá hạn</span>
                        <span className="text-white fz_14">{'8.000.000'}</span>
                      </Col>
                      <Col
                        className="p-3 d-flex flex-column"
                        style={{ borderRight: '1px solid #42465b' }}
                      >
                        <span className="text-content fz_14">
                          Thanh toán tối thiểu
                        </span>
                        <span className="text-white fz_14">{'8.000.000'}</span>
                      </Col>
                      <Col md="auto" className="p-3">
                        <ReloadButton
                          type="button"
                          className="btn-reload"
                          onClick={this.handleLoadHistory}
                        />
                      </Col>
                    </div>
                  </Card.Body>
                </Card>
                <table className="table table-bordered mt-3">
                  <thead>
                    <tr>
                      <th className="text-left">{'ID'}</th>
                      <th className="text-left">{'Ngày giải ngân'}</th>
                      <th className="text-left">{'Hạn'}</th>
                      <th className="text-right">{'Lãi suất / năm'}</th>
                      <th className="text-right">{'Số tiền vay'}</th>
                      <th className="text-right">{'Lãi'}</th>
                      <th className="text-right">{'Phạt chậm hạn'}</th>
                      <th className="text-right">{'Phí'}</th>
                      <th className="text-right">{'Đã trả'}</th>
                      <th className="text-right">{'Còn nợ'}</th>
                      <th className="text-left">{'Thanh toán'}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-left">{'MG123'}</td>
                      <td className="text-left">{'25/10/2020'}</td>
                      <td className="text-left">{'25/10/2020'}</td>
                      <td className="text-right">{'9%'}</td>
                      <td className="text-right">{'100,000,000'}</td>
                      <td className="text-right">{'900,000'}</td>
                      <td className="text-right">{'0'}</td>
                      <td className="text-right">{'0'}</td>
                      <td className="text-right">{'0'}</td>
                      <td className="text-right">{'100,900,000'}</td>
                      <td style={{ width: '150px' }}>
                        <input
                          type="text"
                          placeholder={'100,900,000 Tối đa'}
                          className="form-control form-input fz_14 text-right"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className="mt-2 d-flex flex-row-reverse text-white">
                  <span className="fz_14 fw-500">{'100,200'}</span>
                  <span className="fz_14">{`Thành tiền: `}&nbsp;</span>
                </div>
                <div className="mt-2 d-flex flex-row-reverse text-white">
                  <Button
                    className="btn btn-info fz_14 fw-500"
                    style={{ padding: '2px 28px' }}
                  >
                    {`Thanh toán`}
                  </Button>
                </div>
              </div>
              <div className="d-flex justify-content-between mt-3">
                <label className="text-white text-uppercase ml-3">
                  Lịch sử thanh toán
                </label>
                <FormHisMargin />
              </div>
              <div className="d-flex flex-row">
                <table className="table table-bordered mt-2">
                  <thead>
                    <tr>
                      <th className="text-right">{'Nguồn'}</th>
                      <th className="text-right">{'Đích'}</th>
                      <th className="text-right">{'Số tiền chuyển'}</th>
                      <th className="text-right">{'Phí'}</th>
                      <th className="text-left">{'Nội dung chuyển'}</th>
                      <th className="text-left">{'Trạng thái'}</th>
                      <th className="text-right">{'Ngày tạo'}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {hisDebt &&
                      hisDebt.list &&
                      !!hisDebt.list &&
                      hisDebt.list.map((item, idx) => (
                        <tr key={idx}>
                          <td className="text-right">
                            {accountMargin && accountMargin.subAcntNo}
                          </td>
                          <td className="text-right">{item.toAcntNo}</td>
                          <td className="text-right">
                            {numberFormat(item.trdAmt, 0, '0')}
                          </td>
                          <td className="text-right">
                            {numberFormat(item.trdFee, 0, '0')}
                          </td>
                          <td className="text-left">{'-'}</td>
                          <td
                            className={
                              'text-left ' +
                              (item.status === '1'
                                ? 'r'
                                : item.status === '2'
                                ? 'i'
                                : 'd')
                            }
                          >
                            {getTransferStatus(item.status)}
                          </td>
                          <td className="text-right">{item.trdDt}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </Fragment>
          )}
        </PerfectScrollBar>
      </Fragment>
    );
  }
}

const makeMapStateToProps = () => {
  const getToken = makeGetToken();
  const getListAccount = makeGetListAccount();

  const getDebt = makeGetDebtMargin();
  const getRepayLoan = makeGetRepayLoan();
  const getDebtHis = makeGetHisDebtMargin();
  const getCashBalance = makeGetCashBalance();

  const mapStateToProps = (state) => {
    return {
      token: getToken(state),
      listAccount: getListAccount(state),

      debt: getDebt(state),
      repayLoan: getRepayLoan(state),
      hisDebt: getDebtHis(state),
      cashBalance: getCashBalance(state),
    };
  };
  return mapStateToProps;
};

export default connect(makeMapStateToProps)(CongNoMargin);
