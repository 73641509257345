export const ORD_COND_REQUESTING = 'ORD_COND_REQUESTING';
export const ORD_COND_REQUEST_SUCCESS = 'ORD_COND_REQUEST_SUCCESS';
export const ORD_COND_REQUEST_ERROR = 'ORD_COND_REQUEST_ERROR';

export const LIST_COND_REQUESTING = 'LIST_COND_REQUESTING';
export const LIST_COND_REQUEST_SUCCESS = 'LIST_COND_REQUEST_SUCCESS';
export const LIST_COND_REQUEST_ERROR = 'LIST_COND_REQUEST_ERROR';

export const ORD_COND_DETAIL_REQUESTING = 'ORD_COND_DETAIL_REQUESTING';
export const ORD_COND_DETAIL_REQUEST_SUCCESS =
  'ORD_COND_DETAIL_REQUEST_SUCCESS';
export const ORD_COND_DETAIL_REQUEST_ERROR = 'ORD_COND_DETAIL_REQUEST_ERROR';

export const ORD_COND_CANCEL_REQUESTING = 'ORD_COND_CANCEL_REQUESTING';
export const ORD_COND_CANCEL_REQUEST_SUCCESS =
  'ORD_COND_CANCEL_REQUEST_SUCCESS';
export const ORD_COND_CANCEL_REQUEST_ERROR = 'ORD_COND_CANCEL_REQUEST_ERROR';
