import React, { useEffect, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import {
  getCptRoundInfoRequest,
  getCptTopRankRequest,
  getCptTopSymbolRequest,
  setRoundViewResult,
} from '../actions';
import PerfectScrollBar from 'react-perfect-scrollbar';
import { numberFormat } from '../../../util';

const LeaderBoard = withNamespaces('translations')(({ t, refresh }) => {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.client.setting?.lang) || 'vi';
  const { cptTopRank, cptRoundInfo, cptTopSymbol, roundViewResult } =
    useSelector((state) => state.marginCompetition);
  const [roundCode, setRoundCode] = useState('');
  const _roundActived = [...cptRoundInfo]?.filter((item) => item.stat !== '1');

  function handleGetCptTopRankRequest() {
    const uuid = uuidv4();
    const params = {
      group: 'CORE',
      cmd: 'getCptTopRank',
      rqId: uuid,
      channel: 'WTS',
      data: {
        langTp: lang,
        rndCd: roundCode,
      },
    };
    dispatch(getCptTopRankRequest(params));
  }

  function getCptRoundInfo() {
    const uuid = uuidv4();
    const params = {
      group: 'CORE',
      cmd: 'getCptRoundInfo',
      rqId: uuid,
      channel: 'WTS',
      data: {
        langTp: lang,
      },
    };

    dispatch(getCptRoundInfoRequest(params));
  }

  function handleGetCptSymbolRequest() {
    const uuid = uuidv4();
    const params = {
      group: 'CORE',
      cmd: 'getCptTopSymbol',
      rqId: uuid,
      channel: 'WTS',
      data: {
        rndCd: roundCode,
      },
    };
    dispatch(getCptTopSymbolRequest(params));
  }

  useEffect(() => {
    if (roundCode) {
      handleGetCptSymbolRequest();
      handleGetCptTopRankRequest();
    }
  }, [roundCode, refresh]);

  useEffect(() => {
    getCptRoundInfo();
  }, [lang]);

  useEffect(() => {
    if (!roundViewResult) {
      if (_roundActived && _roundActived.length > 0 && roundCode == '')
        setRoundCode(
          [..._roundActived].find((round) => round.stat == '2')?.rndCd ||
            [..._roundActived].findLast((round) => round.stat == '3')?.rndCd
        );
    } else {
      setRoundCode(roundViewResult);
      dispatch(setRoundViewResult(null));
    }
  }, [_roundActived]);

  const colgroup = (
    <colgroup>
      <col width="0px"></col>
      <col width="52px"></col>
      <col width="68px"></col>
      <col width="120px"></col>
      <col width="48px"></col>
    </colgroup>
  );

  return (
    <div className="leader-board">
      <div className="layout-page__body layout__body leader-board__body">
        {_roundActived && _roundActived.length > 0 ? (
          <PerfectScrollBar style={{ width: '100%' }}>
            <div className="d-flex justify-content-center flex-column align-items-center pb-3">
              <div className="round-tab">
                {_roundActived &&
                  _roundActived.length > 0 &&
                  _roundActived.map((round, roundIndex) => (
                    <div
                      key={roundIndex}
                      className={`cursor-pointer ${
                        round.rndCd == roundCode ? 'active' : 'text--light3'
                      }`}
                      onClick={() => setRoundCode(round.rndCd)}
                    >{`${t('marginCompetition.leaderBoard.round')} ${
                      round.rndNo
                    }`}</div>
                  ))}
              </div>
              <div className="groups-rank">
                {cptTopRank &&
                  cptTopRank.boards &&
                  cptTopRank.boards.length > 0 &&
                  cptTopRank.boards.map((item, boardIndex) => (
                    <div className="groups-rank__item" key={boardIndex}>
                      <div
                        className={`group-infor ${
                          item.brdCode == 'BRD01'
                            ? 'whale-group'
                            : item.brdCode == 'BRD02'
                            ? 'koi-group'
                            : 'carp-group'
                        }`}
                      >
                        <span className="d-flex icon">
                          <span
                            style={{ backgroundImage: `url(${item.brdImg})` }}
                          ></span>
                        </span>
                        <div className="groupName">{item.brdNm}</div>
                      </div>
                      <div className="group-board">
                        <table className="">
                          {colgroup}
                          <thead>
                            <tr>
                              <th className="text--gray text-center">
                                {t('marginCompetition.ruleAndAwards.rank')}
                              </th>
                              <th className="text--gray text-center">
                                {t('txt-change')}
                              </th>
                              <th className="text--gray text-left">
                                {t('marginCompetition.leaderBoard.nickname')}
                              </th>
                              <th className="text--gray text-right">
                                {t('marginCompetition.leaderBoard.pnl')}
                              </th>
                            </tr>
                          </thead>
                        </table>
                        <table className="">
                          {colgroup}
                          <tbody>
                            {item.ranks &&
                              item.ranks.length > 0 &&
                              item.ranks.map((rank, rankIndex) => (
                                <tr key={rankIndex}>
                                  <td className="text-center">
                                    <span className="stock-rank text--light">
                                      {numberFormat(rank.rnkNo, 0, '-')}
                                    </span>
                                  </td>
                                  <td
                                    className={`text-center d-flex justify-content-center align-items-center ${
                                      rank.rnkChg > 0
                                        ? 'i'
                                        : rank.rnkChg == 0
                                        ? 'r'
                                        : 'd'
                                    }`}
                                  >
                                    <span
                                      className={`${
                                        rank.rnkChg > 0
                                          ? 'iArrowUp icon'
                                          : rank.rnkChg < 0
                                          ? 'iArrowDown icon'
                                          : ''
                                      }`}
                                    ></span>
                                    <span>
                                      {numberFormat(
                                        Math.abs(rank.rnkChg),
                                        0,
                                        '-'
                                      )}
                                    </span>
                                  </td>
                                  <td className="text--light">{rank.nickNm}</td>
                                  <td
                                    className={`text-right ${
                                      rank.pnl > 0
                                        ? ' i'
                                        : rank.pnl < 0
                                        ? 'd'
                                        : 'r'
                                    }`}
                                  >{`${numberFormat(rank.pnl, 4, 0)}%`}</td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  ))}
              </div>
              <div className="top-trading">
                <span className="fz_20 fw-500 lh-32 text-center text--light3 pt-3">
                  {t('marginCompetition.leaderBoard.topTrading')}
                </span>
                <div className="list-symbol">
                  {cptTopSymbol &&
                    cptTopSymbol.length > 0 &&
                    cptTopSymbol.map((sym, symIndex) => (
                      <>
                        <div className="stock-infor" key={symIndex}>
                          <span className="stock-symbol text--light">
                            {sym.symbol}
                          </span>
                          <span className="text--light">{sym.rnkNo}</span>
                        </div>
                      </>
                    ))}
                </div>
              </div>
            </div>
          </PerfectScrollBar>
        ) : (
          <div className="d-flex justify-content-center">
            <span className="fw-500 fz_26">{t('txt-no-data-found')}</span>
          </div>
        )}
      </div>
    </div>
  );
});

export default LeaderBoard;
