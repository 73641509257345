import React, { useState } from 'react';
import { withNamespaces } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import { makeGetCashBalance, makeGetPositions } from '../../lib/selector';
import { setAuth, setCashOut, setOrdCond } from '../client/actions';
import { cashBalanceRequest, positionsRequest } from '../privData/actions';
import ConditionalOrder from './components/conditionalOrderHistory';
import NormalOrder from './components/normalOrderHistory';
import PutThrough from './components/putThroughOrderHistory';
import './index.scss';
import '../../util/constant';
import { constants } from '../../util/constant';
import { MixConstants, Mixpanel } from '../../lib/mixpanel';
import { getSymbolInfor, getTickerType } from '../../util';

const Trading = withNamespaces('translations')(({ t }) => {
  const { token, auth } = useSelector((state) => state.client);
  const [tab, setTab] = useState(0);
  const [refresh, setRefresh] = useState(0);
  const dispatch = useDispatch();
  const { cwList, allStock } = useSelector((state) => state.priceBoard);

  if (!token) return <Redirect to="/" />;

  const _handleAuthen = () => {
    dispatch(setAuth());
  };

  const _handleOpenOrdCond = () => {
    if (auth && auth.isVerified) {
      Mixpanel.order({
        Ticker: '',
        'Order Side': '',
        'Order Type': 'Conditional Order',
        'Ticker Type': '',
        'Ticker Description': '',
        Location: MixConstants.LOCATIONS.CONDITIONAL_ORDER,
      });

      dispatch(setOrdCond(true));
    } else {
      _handleAuthen();
    }
  };
  return (
    <div className="trading">
      <div className="trading-main">
        <div className="trading-main-header">
          <div className="trading-main-header__left">
            <span
              className={`trading-main-tab cursor-pointer ${
                tab == constants.trading.NORMAL_ORDER_TAB ? 'active' : ''
              }`}
              onClick={() => {
                Mixpanel.track('View Order List', {
                  'Button Location': 'Tab Trading',
                  'Order List Type': 'Normal Order',
                });
                setTab(constants.trading.NORMAL_ORDER_TAB);
              }}
            >
              {t('txt-order-normal')}
            </span>
            <span
              className={`trading-main-tab cursor-pointer ${
                tab == constants.trading.CONDITIONAL_ORDER_TAB ? 'active' : ''
              }`}
              onClick={() => {
                Mixpanel.track('View Order List', {
                  'Button Location': 'Tab Trading',
                  'Order List Type': 'Conditional Order',
                });
                setTab(constants.trading.CONDITIONAL_ORDER_TAB);
              }}
            >
              {t('txt-order-cond')}
            </span>
            <span
              className={`trading-main-tab cursor-pointer ${
                tab == constants.trading.PUT_THROUGH_ORDER_TAB ? 'active' : ''
              }`}
              onClick={() => {
                Mixpanel.track('View Order List', {
                  'Button Location': 'Tab Trading',
                  'Order List Type': 'Put-through Order',
                });
                setTab(constants.trading.PUT_THROUGH_ORDER_TAB);
              }}
            >
              {t('trading.orderPutThrough.name')}
            </span>
          </div>
          <div className="trading-main-header__right">
            <span
              className={`${
                tab == constants.trading.NORMAL_ORDER_TAB
                  ? 'btn--transparent'
                  : ''
              } btn-icon `}
            >
              <span
                className="icon iRefresh cursor-pointer"
                onClick={() => setRefresh(!refresh)}
              ></span>
            </span>
            {tab == 1 && (
              <div
                className={`btn cursor-pointer mr-2  ${
                  auth && auth.isVerified ? 'btn--primary' : 'btn--authen'
                }`}
                onClick={_handleOpenOrdCond}
              >
                <span className="font-700">{t('txt-btn-order-cond')}</span>
              </div>
            )}
            {/* <span className="btn-icon btn--transparent">
              <span className="icon iZoomIn cursor-pointer"></span>
            </span> */}
          </div>
        </div>
        <div className="card-panel-body position-relative">
          {tab == constants.trading.NORMAL_ORDER_TAB && (
            <NormalOrder key={refresh} />
          )}
          {tab == constants.trading.CONDITIONAL_ORDER_TAB && (
            <ConditionalOrder token={token} auth={auth} key={refresh} />
          )}
          {tab == constants.trading.PUT_THROUGH_ORDER_TAB && (
            <PutThrough token={token} auth={auth} key={refresh} />
          )}
        </div>
      </div>
    </div>
  );
});

export default Trading;
