import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  StringToInt,
  StringToDouble,
  _convertTradeTp,
  numberFormat,
  _convertTpToPrice,
} from '../../../util';
import { setToast } from '../../../containers/client/actions';
import {
  makeGetToken,
  makeGetDefaultAccount,
  makeGetAuth,
  makeGetSymbolInfo,
} from '../../../lib/selector';
import { v4 as uuidv4 } from 'uuid';
import { translate } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import { Formik, Form, Field } from 'formik';
import { required } from '../../../util/formikValidation';
import { useSelector, useDispatch } from 'react-redux';
import './editOrder.scss';
import { constants } from '../../../util/constant';

const FormEditOrder = ({
  pristine,
  submitting,
  showModal,
  handleCloseModal,
  order,
  t,
  handleSubmitOrder,
  reset,
}) => {
  const [newPrice, setNewPrice] = useState('');
  const [newVol, setNewVol] = useState('');
  const dispatch = useDispatch();

  const symbolInfo = useSelector(makeGetSymbolInfo());
  const token = useSelector(makeGetToken());
  const defaultAccount = useSelector(makeGetDefaultAccount());
  const auth = useSelector(makeGetAuth());
  const themes = useSelector((state) => state.client.currentTheme);
  const { orderG } = useSelector((state) => state.privData);

  const handleToast = (msg) => {
    const toastMsg = {
      id: Math.random(),
      msg: msg,
      title: t('txt-notice'),
    };
    dispatch(setToast(toastMsg));
  };

  const submit = (values) => {
    debugger;
    // valid
    if (order?.ordrTrdTp === '00') {
      handleToast(t('txt-price-not-match'));
      return;
    }

    let _validPrice = true,
      _validVol = true;

    const vVolume = StringToInt(values.newVol);
    if (symbolInfo.marketId === 'STO') {
      _validVol = validVolHose(vVolume);
    } else if (symbolInfo?.marketId === 'DVX') {
      handleToast(t('txt-order-drvx-page-invalid'));
      return false;
    } else {
      _validVol = validVolHnx(vVolume);
    }

    if (_validPrice && _validVol) {
      const uuid = uuidv4();
      const params = {
        group: 'CORE',
        user: token.user,
        session: token.session,
        cmd: 'EditOrder',
        type: auth.type,
        token: auth.token,
        rqId: uuid,
        channel: 'WTS',
        data: {
          origOrdrNo: order?.ordrNo + '',
          acntNo: defaultAccount.acntNo,
          subAcntNo: order?.subAcntNo,
          ordrQty: vVolume,
          ordrUntprc: (StringToDouble(values.newPrice) * 1000).toFixed(0),
          ordrTrdTp: order?.ordrTrdTp,
        },
      };

      handleSubmitOrder(params);
      reset();
    }
  };

  const validVolHose = (vol) => {
    let step = 10;
    if (vol < 1 || vol > 500000) {
      handleToast(t('txt-vol-not-match'));
      return false;
    }
    // if (vol % step !== 0) {
    //   handleToast(t('txt-vol-not-match'));
    //   return false;
    // }

    return true;
  };

  const validVolHnx = (vol) => {
    let step = 100;
    if (vol === 0) {
      handleToast(t('txt-vol-not-match'));
      return false;
    }
    // if (vol >= 100 && vol % step !== 0) {
    //   handleToast(t('txt-vol-not-match'));
    //   return false;
    // }

    return true;
  };

  useEffect(() => {
    const _newPrice =
      order &&
      (_convertTpToPrice(order.ordrTrdTp) === 'LO'
        ? numberFormat(StringToDouble(order.ordrUntprc) / 1000, 2)
        : _convertTpToPrice(order.ordrTrdTp));

    const _newVol = order && order.ordrQty;
  }, []);

  return (
    <Modal
      show={showModal}
      dialogClassName="confirm-order-modal"
      backdropClassName="confirm-order-modal-backdrop"
      contentClassName={`${themes?.name || 'dark'} delete-order-modal-content`}
      onEscapeKeyDown={handleCloseModal}
      centered
      style={{ zIndex: 11115 }}
    >
      <div className="confirm-order">
        <div className="confirm-order-header">
          {t('txt-title-edit-confirm')}
        </div>
        <Formik
          initialValues={{
            newPrice: (order ? order.ordrUntprc / 1000 : 0) + '',
            newVol: (order ? order.ordrQty : 0) + '',
          }}
          onSubmit={(values) => submit(values)}
        >
          <Form autoComplete="off" className="confirm-order-body">
            <div className="confirm-order-body__infor">
              <span className="confirm-order-body__infor-title">
                <p>{t('txt-orderNo')}</p>
                <p>{t('lbl-subAccount')}</p>
              </span>
              <span className="confirm-order-body__infor-value">
                <p>{order && order.ordrNo}</p>
                <p>{order && order.subAcntNo}</p>
              </span>
            </div>

            <div className="confirm-order-body__detail">
              <span className={`order order-type`}>
                <span
                  className={`${order && (order.buySelTp === '1' ? 'i' : 'd')}`}
                >
                  {order &&
                    (order.buySelTp === '1' ? t('txt-buy') : t('txt-sell'))}
                </span>
              </span>
              <span className="order order-symbol">
                {order && order.symbol}
              </span>
              <span className="order pt-0 pb-0">
                <Field
                  className="order-price"
                  type="text"
                  placeholder={t('txt-price')}
                  name="newPrice"
                  autoComplete="off"
                  validate={required}
                  style={{
                    outline: 'none',
                    border: 'none',
                    width: '80px',
                    textAlign: 'center',
                    borderRadius: '4px',
                    height: '24px',
                  }}
                />
              </span>
              <span className="order pt-0 pb-0">
                <Field
                  type="text"
                  className="order-quantity"
                  placeholder={t('txt-vol')}
                  name="newVol"
                  autoComplete="off"
                  validate={required}
                  style={{
                    outline: 'none',
                    border: 'none',
                    width: '80px',
                    textAlign: 'center',
                    borderRadius: '4px',
                    height: '24px',
                  }}
                />
              </span>
            </div>

            <div className="confirm-order-body__action">
              <button
                type="submit"
                className="btn-confirm"
                disabled={pristine || submitting}
              >
                {t('txt-accept')}
              </button>
              <button
                className="btn-cancel"
                disabled={pristine || submitting}
                onClick={handleCloseModal}
              >
                {t('txt-cancel')}
              </button>
            </div>
          </Form>
        </Formik>
      </div>
    </Modal>
  );
};

const makeMapStateToProps = () => {
  const getSymbolInfo = makeGetSymbolInfo();
  const getToken = makeGetToken();
  const getDefaultAccount = makeGetDefaultAccount();
  const getAuth = makeGetAuth();

  const mapStateToProps = (state, props) => {
    return {
      token: getToken(state),
      symbolInfo: getSymbolInfo(state),
      defaultAccount: getDefaultAccount(state),
      auth: getAuth(state),
    };
  };
  return mapStateToProps;
};

export default connect(makeMapStateToProps)(
  translate('translations')(FormEditOrder)
);
