import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { authListRequest } from '../../../components/modal/auth/actions';
import {
  setAuth,
  setChangePass,
  setChangeTelpin,
  setChangeUser,
  setClient,
  setToast,
} from '../../client/actions';
import {
  getExtSettingRequest,
  resetDefaultRequest,
  resetExtSettingRequest,
  setDefaultRequest,
  setExtSettingRequest,
} from '../../customer/actions';
import PerfectScrollBar from 'react-perfect-scrollbar';
import { withNamespaces } from 'react-i18next';
import { filter, find, sortBy } from 'lodash';
import { MdVerifiedUser } from 'react-icons/md';
import { GoUnverified } from 'react-icons/go';
import { Link } from 'react-router-dom';
import '../index.scss';
import ReactSelect from 'react-select';
import { setDefaultAccount, updateListAccount } from '../../socket/actions';
import { setCookie } from '../../../lib/storages';
import { Mixpanel } from '../../../lib/mixpanel';

const Security = withNamespaces('translations')(({ t }) => {
  const {
    token,
    auth,
    setting: { lang },
  } = useSelector((state) => state.client);
  const { actList } = useSelector((state) => state.authen);
  const { listAccount, defaultAccount } = useSelector((state) => state.socket);
  const {
    extSetting,
    setDefaultErrors,
    setDefaultSuccess,
    setExtSettingSuccess,
    setExtSettingErrors,
    lastExtSetting,
    setExtSettingRequesting,
  } = useSelector((state) => state.customer);

  let _actList =
    actList && actList.list && filter(actList.list, (o) => o.authYn !== '0');

  _actList = sortBy(_actList, 'authType').reverse();
  const telpinStatus = extSetting.find((x) => x.featureTp == 1);
  const matrixStatus = extSetting.find((x) => x.featureTp == 2);
  const [defAcc, setDefAcc] = useState(defaultAccount);
  const [accounts, setAccounts] = useState([]);
  const dispatch = useDispatch();

  console.log('token', token);

  useEffect(() => {
    if (defaultAccount) getExtSettingHandle();
  }, [defaultAccount]);

  useEffect(() => {
    if (!setExtSettingRequesting) {
      let _obj = null;
      if (extSetting && lastExtSetting) {
        _obj = extSetting.find((x) => x.featureTp == lastExtSetting.featureTp);
      }

      if (setExtSettingSuccess && _obj) {
        const toastMsg = {
          id: Math.random(),
          title: t('txt-success'),
          msg: `${
            _obj.regTp_note == 'R'
              ? t('setting.reset')
              : _obj.regTp == 'Y'
              ? t('setting.reg')
              : t('setting.unReg')
          } ${t('setting.service').toLowerCase()} ${_obj.featureName} ${t(
            'txt-success'
          ).toLowerCase()}`,
          time: new Date(),
        };

        dispatch(setToast(toastMsg));
        dispatch(resetExtSettingRequest());
      } else {
        if (setExtSettingErrors && setExtSettingErrors.length > 0) {
          const toastMsg = {
            id: Math.random(),
            title: t('txt-unsuccessful'),
            msg: setExtSettingErrors[0].body,
            time: setExtSettingErrors[0].time,
          };

          dispatch(setToast(toastMsg));
          dispatch(resetExtSettingRequest());
        }
      }
    }
  }, [setExtSettingRequesting]);

  useEffect(() => {
    if (token) getAuthListHandle();
  }, [token]);

  useEffect(() => {
    if (listAccount) {
      const _defSubAccount = find(listAccount, (o) => o.isDefSubAcnt === 1);
      if (_defSubAccount != defAcc) setDefAcc(_defSubAccount);
      getExtSettingHandle();
      setAccounts(listAccount);
    }
  }, [listAccount, lang]);

  useEffect(() => {
    if (setDefaultErrors && !!setDefaultErrors.length > 0) {
      toastHandle(setDefaultErrors[0].body);
      dispatch(resetDefaultRequest());
    }

    if (setDefaultSuccess) {
      toastHandle(t('account.security.changeDefaultAccSuccess'));
      dispatch(resetDefaultRequest());
      dispatch(setDefaultAccount(defAcc));

      const newListAccount = [...listAccount];
      newListAccount.forEach((account) => {
        if (account == defAcc) account.isDefSubAcnt = 1;
        else account.isDefSubAcnt = 0;
      });

      dispatch(updateListAccount(newListAccount));

      const newToken = token;
      if (newToken && newToken.custInfo && newToken.custInfo.normal) {
        newToken.custInfo.normal = newListAccount;
      }
      // set client
      dispatch(setClient(newToken));
      setCookie('token', JSON.stringify(newToken));
    }
  }, [setDefaultSuccess, setDefaultErrors]);

  const getExtSettingHandle = () => {
    const uuid = uuidv4();
    if (!defaultAccount) return;

    if (token) {
      const params = {
        group: 'CORE',
        user: token.user,
        rqId: uuid,
        session: token.session,
        cmd: 'getCustExtFeature',
        channel: 'WTS',
        data: {
          cif: token.cif,
          langTp: lang,
          subAcntNo: defaultAccount.subAcntNo,
        },
      };

      dispatch(getExtSettingRequest(params));
    }
  };

  const getAuthListHandle = () => {
    if (token) {
      const uuid = uuidv4();
      const params = {
        group: 'CORE',
        user: token.user,
        session: token.session,
        cmd: 'GetAuthList',
        rqId: uuid,
        channel: 'WTS',
        data: {
          cif: token.cif,
        },
      };
      dispatch(authListRequest(params));
    }
  };

  const setAccountDefault = () => {
    if (token) {
      const uuid = uuidv4();
      const params = {
        group: 'CORE',
        user: token.user,
        session: token.session,
        cmd: 'SetDefault',
        rqId: uuid,
        channel: 'WTS',
        type: auth.type,
        token: auth.token,
        data: {
          acntNo: defaultAccount.acntNo,
          subAcntNo: defAcc.subAcntNo,
        },
      };
      dispatch(setDefaultRequest(params));
    }
  };

  const authenHandle = () => {
    dispatch(setAuth());
  };

  const toastHandle = (msg) => {
    const toastMsg = {
      id: Math.random(),
      msg: msg,
      title: t('txt-notice'),
    };
    dispatch(setToast(toastMsg));
  };

  const resetMatrixHandle = () => {
    const uuid = uuidv4();

    if (token) {
      const params = {
        group: 'CORE',
        rqId: uuid,
        user: token.user,
        session: token.session,
        token: auth.token,
        type: auth.type,
        cmd: 'registCustExtFeature',
        channel: 'WTS',
        data: {
          featureTp: '2',
          custNo: token.cif,
          regTp: 'Y',
          subAcntNo: defaultAccount.subAcntNo,
        },
      };

      dispatch(setExtSettingRequest(params));
    }
  };

  console.log('listAccount', listAccount);
  console.log('defAcc', defAcc);

  return (
    <PerfectScrollBar>
      <div className="d-flex justify-content-md-center account-securities">
        <div className="account-panel account-security col-md-8">
          <div className="account-panel-header">
            <span className="account-panel-header__title">
              {t('txt-label-login-info')}
            </span>
            <span className={'fz_14 ' + (auth.isVerified ? 'i' : 'd')}>
              {auth && auth.isVerified ? (
                <>
                  <MdVerifiedUser className="mr-2" />
                  {t('txt-da-xacthuc')}
                </>
              ) : (
                <>
                  <GoUnverified className="mr-2" />
                  {t('txt-chua-xacthuc')}
                </>
              )}
            </span>
          </div>
          <div className="account-panel-body">
            <div className="account-panel-items">
              <div className="account-panel-item">
                <span className="account-panel-item__name">
                  {t('txt-login-name')}
                </span>
                <span className="account-panel-item__value">
                  {token && token.user}
                </span>
                <span className="account-panel-item__action">
                  {auth && auth.isVerified ? (
                    <span
                      className="btn btn--light fw-500 br-5"
                      onClick={() => dispatch(setChangeUser())}
                    >
                      {t('txt-change')}
                    </span>
                  ) : (
                    <button
                      className="btn btn--authen fw-500 br-5"
                      onClick={() => authenHandle()}
                    >
                      {t('txt-change')}
                    </button>
                  )}
                </span>
              </div>
              <div className="account-panel-item">
                <span className="account-panel-item__name">
                  {t('txt-pass')}
                </span>
                <span className="account-panel-item__value">******</span>
                <span className="account-panel-item__action">
                  {auth && auth.isVerified ? (
                    <button
                      className="btn btn--light fw-500 br-5"
                      onClick={() => dispatch(setChangePass())}
                    >
                      {t('txt-change')}
                    </button>
                  ) : (
                    <button
                      className="btn btn--authen fw-500 br-5"
                      onClick={() => authenHandle()}
                    >
                      {t('txt-change')}
                    </button>
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="account-panel account-security col-md-8">
          <div className="account-panel-header">
            <span className="account-panel-header__title">
              {t('txt-label-authentication-registered')}
            </span>
          </div>
          <div className="account-panel-body">
            <div className="account-panel-items">
              <div className="account-panel-item">
                <span className="account-panel-item__name">Smart OTP</span>
                <span className="account-panel-item__value">
                  {t('account.defaultReg')}
                </span>
                <span className="account-panel-item__action">
                  {auth && auth.isVerified ? (
                    <button
                      className="btn btn--light fw-500 br-5"
                      onClick={() => {
                        Mixpanel.getMoreInfor({
                          'Info Group': 'Guide',
                          'Info Detail': '2FA guide',
                        });

                        window.open(
                          'https://pinetree.vn/post/dich-vu/2-xac-thuc/',
                          '_blank'
                        );
                      }}
                    >
                      {t('txt-guide')}
                    </button>
                  ) : (
                    <button
                      className="btn btn--authen fw-500 br-5"
                      onClick={() => authenHandle()}
                    >
                      {t('txt-guide')}
                    </button>
                  )}
                </span>
              </div>
              <div className="account-panel-item">
                <span className="account-panel-item__name">SMS OTP</span>
                <span className="account-panel-item__value">
                  {t('account.defaultReg')}
                </span>
                <span className="account-panel-item__action">
                  {auth && auth.isVerified ? (
                    <Link to="setting" className="btn btn--light fw-500 br-5">
                      {t('txt-change')}
                    </Link>
                  ) : (
                    <button
                      className="btn btn--authen fw-500 br-5"
                      onClick={() => authenHandle()}
                    >
                      {t('txt-change')}
                    </button>
                  )}
                </span>
              </div>
              <div className="account-panel-item">
                <span className="account-panel-item__name">MATRIX</span>
                <span className="account-panel-item__value">
                  {matrixStatus && matrixStatus?.regTp == 'Y' ? (
                    <span>{t('lbl-reg-service')}</span>
                  ) : (
                    <span>{t('lbl-un-reg-service')}</span>
                  )}
                </span>
                <span className="account-panel-item__action">
                  {auth && auth.isVerified ? (
                    matrixStatus && matrixStatus?.regTp == 'Y' ? (
                      <button
                        className="btn btn--light fw-500 br-5"
                        onClick={() => resetMatrixHandle()}
                      >
                        {t('txt-reset')}
                      </button>
                    ) : (
                      <Link to="setting" className="btn btn--light fw-500 br-5">
                        {t('txt-register')}
                      </Link>
                    )
                  ) : matrixStatus && matrixStatus?.regTp == 'Y' ? (
                    <button
                      className="btn btn--authen fw-500 br-5"
                      onClick={() => authenHandle()}
                    >
                      {t('txt-reset')}
                    </button>
                  ) : (
                    <Link to="setting" className="btn btn--authen fw-500 br-5">
                      {t('txt-register')}
                    </Link>
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="account-panel account-security col-md-8">
          <div className="account-panel-header">
            <span className="account-panel-header__title">
              {t('txt-label-others')}
            </span>
          </div>
          <div className="account-panel-body">
            <div className="account-panel-items">
              <div className="account-panel-item">
                <span className="account-panel-item__name">Telpin</span>
                <span className="account-panel-item__value">
                  {telpinStatus && telpinStatus?.regTp == 'Y' ? (
                    <span>{'******'}</span>
                  ) : (
                    <span>{t('lbl-un-reg-service')}</span>
                  )}
                </span>
                <span className="account-panel-item__action">
                  {auth && auth.isVerified ? (
                    telpinStatus && telpinStatus?.regTp == 'Y' ? (
                      <button
                        className="btn btn--light fw-500 br-5"
                        onClick={() => dispatch(setChangeTelpin())}
                      >
                        {t('txt-change-telpin')}
                      </button>
                    ) : (
                      <Link
                        to="setting"
                        className="ml-2 btn btn--light fw-500 br-5"
                      >
                        {t('txt-register')}
                      </Link>
                    )
                  ) : (
                    <button
                      className="btn btn--authen  fw-500 br-5"
                      onClick={() => authenHandle()}
                    >
                      {t('txt-register')}
                    </button>
                  )}
                </span>
              </div>
              <div className="account-panel-item">
                <span className="account-panel-item__name">
                  {t('txt-label-default-account')}
                </span>
                <span className="account-panel-item__value">
                  {accounts && !!accounts.length && defAcc && (
                    <ReactSelect
                      className="account-filters__select"
                      classNamePrefix="filter-control-select"
                      options={accounts}
                      isSearchable={false}
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      value={defAcc}
                      onChange={(value) => setDefAcc(value)}
                    />
                  )}
                </span>
                <span className="account-panel-item__action">
                  {auth && auth.isVerified ? (
                    <span
                      className="btn btn--light fw-500 br-5 "
                      onClick={() => setAccountDefault()}
                    >
                      {t('txt-change')}
                    </span>
                  ) : (
                    <button
                      className="btn btn--authen fw-500 br-5"
                      onClick={() => authenHandle()}
                    >
                      {t('txt-change')}
                    </button>
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="company-contact">
          <span className="company-contact__note">
            {t('txt-make-sure-can-access')}
          </span>
          <div
            className="br-5 btn-icon btn-icon--primary2 cursor-pointer company-contact__hotline"
            onClick={() => window.open('tel:02462823535')}
          >
            <span className="icon iCall"></span>
            <span className="fw-500">0246.282.3535</span>
          </div>
        </div>
      </div>
    </PerfectScrollBar>
  );
});

export default Security;
