import React, { useState } from 'react';
import { withNamespaces } from 'react-i18next';
import { Portal } from 'react-overlays';
import DatePicker, { registerLocale } from 'react-datepicker';
import { formatToNewDate } from '../../../../../util';

const CalendarContainer = ({ children }) => {
  const el = document.getElementById('calendar-portal');
  return <Portal container={el}>{children}</Portal>;
};

const CustomDatePicker = withNamespaces('translation')(
  ({ t, callback, packIndex, index, defaultDate, minDate }) => {
    const [_value, setValue] = useState(
      defaultDate ? formatToNewDate(defaultDate) : new Date()
    );
    const handleChange = (date, packIndex, index) => {
      setValue(date);
      callback(date, packIndex, index);
    };
    return (
      <div className="filter-control-calendar flex-1">
        <DatePicker
          selected={_value}
          onChange={(date) => handleChange(date, packIndex, index)}
          dateFormat="dd/MM/yyyy"
          popperContainer={CalendarContainer}
          placeholderText="dd/mm/yyyy"
          minDate={minDate}
        />
        <span className="icon iCalendar"></span>
      </div>
    );
  }
);

export default CustomDatePicker;
