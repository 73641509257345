import React, { useCallback, useContext, useState } from 'react';
import StockSuggest from '../../../select/stockSuggest';
import { withNamespaces } from 'react-i18next';
import icSearch from '../../../../assets/img/icons/price/Search.svg';
import { chain, filter, find, some, zipObject, map } from 'lodash';
import { Link, Redirect, withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import WatchListActions from './watchListActions';
import { NavDropdown } from 'react-bootstrap';
import {
  setCWSearchRequest,
  snapshotRequest,
  snapshotRequestSuccess,
  stockInfoRequest,
} from '../../../../containers/banggia/actions';
import { v4 as uuidv4 } from 'uuid';
import {
  saveCategoryRequest,
  setCategory,
  setSymbolScroll,
  setToast,
} from '../../../../containers/client/actions';
import { WebSocketContext } from '../../../../containers/socket/webSocket';
import { Mixpanel } from '../../../../lib/mixpanel';
import { getTickerType, useDebounce } from '../../../../util';
import { saveState } from '../../../../lib/storages';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { setRegSymbol } from '../../../../containers/socket/actions';
import DatePicker, { registerLocale } from 'react-datepicker';
import vi from 'date-fns/locale/vi';
import uk from 'date-fns/locale/uk';
import { Portal } from 'react-overlays';

registerLocale('vi', vi);
registerLocale('en', uk);

const CalendarContainer = ({ children, id }) => {
  const el = document.getElementById(id);
  return <Portal container={el}>{children}</Portal>;
};

const Navbar2 = withNamespaces('translations')(({ t, categoryId }) => {
  const dispatch = useDispatch();
  let history = useHistory();

  const ws = useContext(WebSocketContext);

  const { allStock, cwList, snapshot } = useSelector(
    (state) => state.priceBoard
  );
  const {
    category,
    token,
    symbolDel,
    symbolAdd,
    categorySuccessful: getCategorySuccessful,
    categoryErrors: getCategoryUnsuccessful,
  } = useSelector((state) => state.client);

  const { regSym } = useSelector((state) => state.socket);

  const categoryWatchList = filter(category, (o) => o.type === 'watchlist');
  // _handleSaveCategory
  const saveCategoryHandle = (newCategory) => {
    if (!token) return;

    const uuid = uuidv4();
    const resData = {
      group: 'BACK',
      user: token.user,
      session: token.session,
      cmd: 'SET_WAT',
      rqId: uuid,
      channel: 'WTS',
      data: newCategory,
    };

    dispatch(saveCategoryRequest(resData));
  };

  // _handleDelStock
  const delStockHandle = (stock) => {
    let isUpdate = false;
    let _category = [...category];
    _category.map((element, index) => {
      if (element.id === categoryId) {
        const _value = element.value.filter((x) => x !== stock);
        const _pinnedRow = element.pinnedRow.filter((x) => x !== stock);

        Mixpanel.track('Remove Ticker', {
          Ticker: stock,
          'Ticker Type': getTickerType(stock, cwList),
          'Watchlist Name': element?.name,
        });

        const payload = {
          action: 'leave',
          data: stock,
        };
        ws.sendMessage(payload);
        let _element;
        _element = { ...element, value: _value, pinnedRow: _pinnedRow };
        _category[index] = _element;

        saveCategoryHandle(_element);
        isUpdate = true;
        return _element;
      }
    });

    if (isUpdate) {
      // save to action
      dispatch(setCategory(_category));
      if (!token) {
        saveState('category', _category);
      }

      fetchCategoryData(categoryId);
      const snapshotRemove = filter(snapshot, (o) => o.sym !== stock);
      dispatch(snapshotRequestSuccess(snapshotRemove));
    }
  };

  // _handleAddStock
  const addStockHandle = (_stock) => {
    const stock = _stock.stock_code;
    const categoryGroup = find(
      category,
      (o) => o.type === 'group' && o.path.endsWith('/bang-gia/' + categoryId)
    );

    let isUpdate = false;
    const _category = [...category];

    if (categoryGroup) {
      const inCurrentCategory = regSym.includes(stock);
      if (inCurrentCategory) {
        dispatch(setSymbolScroll(stock));

        const toastMsg = {
          id: Math.random(),
          msg: t('navbar.symbolExisted'),
          title: t('txt-notice'),
        };
        dispatch(setToast(toastMsg));
      } else {
        const stockPostTo = _stock.post_to;
        if (stockPostTo == 'STO')
          history.push({
            pathname: '/home/bang-gia/hsx',
            state: { stock },
          });
        else if (stockPostTo == 'STX')
          history.push({
            pathname: '/home/bang-gia/hnx',
            state: { stock },
          });
        else if (stockPostTo == 'UPX')
          history.push({
            pathname: '/home/bang-gia/upcom',
            state: { stock },
          });
      }
    } else {
      _category.forEach((element, index) => {
        if (element.id === categoryId) {
          if (element.value.indexOf(stock) > -1) {
            dispatch(setSymbolScroll(stock));
          } else {
            if (element.value.length > 100) {
              const toastMsg = {
                id: Math.random(),
                msg: 'Danh mục tối đa 100 mã',
                title: t('txt-notice'),
              };
              dispatch(setToast(toastMsg));
              return;
            }

            let _value = [...element.value];
            _value.push(stock);

            let _element = { ...element, value: _value };
            _category[index] = _element;

            Mixpanel.track('Add Ticker', {
              Ticker: stock,
              'Ticker Type': getTickerType(stock, cwList),
              'Watchlist Name': element?.name,
              'Number of Ticker': _value?.length || 0,
            });

            // regis socket data
            const payload = {
              action: 'join',
              data: stock,
            };
            ws.sendMessage(payload);
            saveCategoryHandle(_element);
            dispatch(setRegSymbol(stock));
            isUpdate = true;
          }
        }
      });

      if (isUpdate) {
        // save to action
        dispatch(setCategory(_category));
        if (!token) {
          saveState('category', _category);
        }
        fetchStockInfo(stock);
      }
    }
  };

  const trackHandle = (item) => {
    if (item) {
      Mixpanel.viewWatchlist({
        'Watchlist Name': item.name,
        'Watchlist Type': 'Normal Watchlist',
        'Ticker List': item.value,
        'Number of Ticker': item.value?.length || 0,
        'Watchlist View Location': 'Home',
      });
    }
  };

  const fetchStockInfo = (stock) => {
    dispatch(stockInfoRequest(stock));
  };

  const fetchCategoryData = (categoryId) => {
    const catalogSelected = find(
      category,
      (o) => o.path && o.path.endsWith('/bang-gia/' + categoryId)
    );

    if (catalogSelected?.value) {
      const value = catalogSelected.value;
      return dispatch(snapshotRequest(value.join(',')));
    } else {
      return null;
    }
  };

  useEffect(() => {
    if (symbolDel) {
      delStockHandle(symbolDel);
    }

    if (symbolAdd) {
      addStockHandle(symbolAdd);
    }
  }, [symbolDel, symbolAdd]);

  const checkCategoryId = category
    ? some(
        category,
        (o) => o.path && o.path.endsWith('/bang-gia/' + categoryId)
      )
    : true;
  const invalidCategoryUnauthUser = !token && !checkCategoryId;
  const invalidCategoryAuthUser =
    token &&
    (getCategorySuccessful || getCategoryUnsuccessful.length > 0) &&
    !checkCategoryId;
  if (invalidCategoryUnauthUser || invalidCategoryAuthUser) {
    return <Redirect to="/" />;
  }

  return (
    <nav className="navbar-priceboard">
      <div className="d-flex align-items-center" id="basic-navbar-nav">
        <div className="po-relative pr-2 form-inline">
          <StockSuggest
            suggestionData={allStock}
            selectStock={addStockHandle}
            inputProps={{
              className: 'form-add form-control fz_14',
              placeholder: t('txt-add-symbol'),
            }}
          />
          <img src={icSearch} className="form-add-img" />
        </div>
        {!!categoryWatchList.length &&
          categoryWatchList.map((item, i) => {
            if (item.isPin)
              return (
                <div className=" po-relative" key={i}>
                  <Link
                    to={item.path}
                    className={
                      'watchlist-item nav-link mr-2 ' +
                      (categoryId === item.id ? 'active' : '')
                    }
                    title={item.name}
                    onClick={() => trackHandle(item)}
                  >
                    {item.name === 'Mặc định'
                      ? t('txt-label-default-watchlist')
                      : item.name}
                  </Link>
                </div>
              );
          })}
        <WatchListActions categoryId={categoryId} />
        <div className="line"></div>
        <WatchlistGroup categoryId={categoryId} />
      </div>
    </nav>
  );
});

const WatchlistGroup = withNamespaces('translations')(({ t, categoryId }) => {
  const { category } = useSelector((state) => state.client);
  const categoryGroup = filter(category, (o) => o.type === 'group');
  const grCtl = chain(categoryGroup)
    .groupBy('groupName')
    .toPairs()
    .map((pair) => zipObject(['groupName', 'groupValue'], pair))
    .value();

  const grActive = find(grCtl, (o) =>
    some(o.groupValue, (ol) => ol.path.endsWith('/bang-gia/' + categoryId))
  );

  const pathActive = find(categoryGroup, (o) =>
    o.path.endsWith('/bang-gia/' + categoryId)
  );

  const grpTrackHandle = (name, item) => {
    if (item) {
      Mixpanel.viewStockList({
        'Preset Group': item.groupName,
        'Preset Name': name,
        'List Feature': 'Basic List',
        'List Location': 'Home',
      });
    }
  };

  const minimizeText = (text) => {
    const maxLength = 25;
    if (!text) return '';
    if (text.length < maxLength) return text;
    else return `${text.substring(0, maxLength)}...`;
  };

  if (!!grCtl?.length)
    return (
      <>
        {grCtl.map((item, index) => {
          if (item.groupName === 'CHUNGQUYEN') {
            return (
              <div style={{ position: 'relative' }}>
                <CoveredWarrantFilter
                  key={index}
                  path={item.groupValue[0].path}
                  categoryId={categoryId}
                  isActive={grActive && grActive.groupName === item.groupName}
                />
                <span className="special-badge">{t('txt-new')}</span>
              </div>
            );
          }

          if (item.groupName == 'CPNGANH') {
            return (
              <NavDropdown
                title={`${t('txt-industry')} ${
                  grActive && grActive.groupName === item.groupName
                    ? '(' + minimizeText(pathActive.name) + ')'
                    : ''
                }`}
                className="mr-2 industry-menu"
                active={grActive && grActive.groupName === item.groupName}
                id="basic-nav-dropdown"
                key={index}
              >
                {item.groupValue.map((element, i) => (
                  <Link
                    key={'gr_' + item.groupName + '_' + i}
                    to={element.path}
                    className={
                      'nav-link ' +
                      (element.path.endsWith('/bang-gia/' + categoryId)
                        ? 'active'
                        : '')
                    }
                    title={element.name}
                    onClick={() => grpTrackHandle(element.name, element)}
                  >
                    {element.name}
                  </Link>
                ))}
              </NavDropdown>
            );
          }

          if (item.groupName == 'OddLot') {
            return (
              <NavDropdown
                title={`${
                  grActive && grActive.groupName === item.groupName
                    ? t(pathActive.name)
                    : t('txt-odd-lot')
                }`}
                className="mr-2 oddlot-menu"
                active={grActive && grActive.groupName === item.groupName}
                id="basic-nav-dropdown"
                key={index}
              >
                {item.groupValue.map((element, i) => (
                  <Link
                    key={'gr_' + item.groupName + '_' + i}
                    to={element.path}
                    className={
                      'nav-link ' +
                      (element.path.endsWith('/bang-gia/' + categoryId)
                        ? 'active'
                        : '')
                    }
                    title={t(element.name)}
                    onClick={() => grpTrackHandle(t(element.name), element)}
                  >
                    {t(element.name)}
                  </Link>
                ))}
              </NavDropdown>
            );
          }

          return (
            <NavDropdown
              title={`${t(item.groupName)} ${
                grActive && grActive.groupName === item.groupName
                  ? '(' + minimizeText(t(pathActive.name)) + ')'
                  : ''
              }`}
              className={`mr-2`}
              active={grActive && grActive.groupName === item.groupName}
              id="basic-nav-dropdown"
              key={index}
            >
              {item.groupValue.map((element, i) => {
                return (
                  <Link
                    key={'gr_' + item.groupName + '_' + i}
                    to={element.path}
                    className={
                      'nav-link  ' +
                      (element.path.endsWith('/bang-gia/' + categoryId)
                        ? 'active'
                        : '')
                    }
                    title={t(element.name)}
                    onClick={() => grpTrackHandle(t(element.name), element)}
                  >
                    {t(element.name)}
                  </Link>
                );
              })}
            </NavDropdown>
          );
        })}
      </>
    );
  else return <></>;
});

const CoveredWarrantFilter = withNamespaces('translations')(
  ({ t, key, path, categoryId, isActive }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const {
      setting: { lang = 'vi' },
      category,
    } = useSelector((state) => state.client);
    const { cwList, snapshot, cwSearchFilter } = useSelector(
      (state) => state.priceBoard
    );

    const { symbol, issuer, fromDate, toDate } = cwSearchFilter;

    const onClearHandle = () => {
      // re call get full snapshot and clear filter

      dispatch(
        setCWSearchRequest({
          symbol: '',
          issuer: '',
          fromDate: null,
          toDate: null,
        })
      );
    };

    return (
      <NavDropdown
        title={t('txt-cw') + ' '}
        key={key}
        onClick={() => history.push(path)}
        className={`mr-2 ${
          path.endsWith('/bang-gia/' + categoryId) ? 'active' : ''
        }`}
        active={isActive}
      >
        <form className="cw-search__form" autoComplete="off">
          <div className="cw-search__row">
            <div className="cw-search__input flex-1">
              <div className="cw-search__icon">
                <span className="icon iSearch gray"></span>
              </div>
              <input
                placeholder={t('txt-symbol-equity')}
                value={symbol}
                onChange={(e) =>
                  dispatch(
                    setCWSearchRequest({
                      symbol: e.target.value.toUpperCase(),
                    })
                  )
                }
              />
            </div>
            <div className="cw-search__input flex-1">
              <input
                className=""
                placeholder={t('bond.issuer')}
                value={issuer}
                onChange={(e) =>
                  dispatch(
                    setCWSearchRequest({
                      issuer: e.target.value.toUpperCase(),
                    })
                  )
                }
              />
            </div>
          </div>
          <div>
            <span className="text text--sm text--light">
              {t('txt-last-trading-date')}
            </span>
          </div>
          <div className="cw-search__row">
            <div className="cw-search__input">
              <div className="cw-search__icon">
                <span className="icon iCalendar gray"></span>
              </div>
              <div
                className="cw-search__datePicker cw-search__datePicker-from"
                id="cw-search__datePicker-from"
              >
                <DatePicker
                  className="form-input"
                  dateFormat="dd/MM/yyyy"
                  value={fromDate}
                  onChange={(date) =>
                    dispatch(
                      setCWSearchRequest({
                        fromDate: date,
                      })
                    )
                  }
                  selectsStart
                  popperContainer={({ children }) => (
                    <CalendarContainer id="cw-search__datePicker-from">
                      {children}
                    </CalendarContainer>
                  )}
                  locale={lang}
                  selected={fromDate}
                  placeholderText={t('txt-label-from')}
                />
              </div>
            </div>

            <div className="cw-search__input">
              <div className="cw-search__icon">
                <span className="icon iCalendar gray"></span>
              </div>
              <div
                className="cw-search__datePicker cw-search__datePicker-to"
                id="cw-search__datePicker-to"
              >
                <DatePicker
                  className="form-input"
                  dateFormat="dd/MM/yyyy"
                  value={toDate}
                  onChange={(date) =>
                    dispatch(
                      setCWSearchRequest({
                        toDate: date,
                      })
                    )
                  }
                  selectsStart
                  popperContainer={({ children }) => (
                    <CalendarContainer id="cw-search__datePicker-to">
                      {children}
                    </CalendarContainer>
                  )}
                  locale={lang}
                  selected={toDate}
                  placeholderText={t('txt-label-to')}
                />
              </div>
            </div>

            <button
              className="btn btn--reset"
              type="button"
              onClick={() => onClearHandle()}
            >
              {t('txt-clear')}
            </button>
          </div>
        </form>
      </NavDropdown>
    );
  }
);

const debouncefn = (func, delay) => {
  let timeout;

  return function (...args) {
    const context = this;
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), delay);
  };
};
export default Navbar2;
