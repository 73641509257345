import React, { Children, Fragment } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import { stringToDate } from '../../util';
import { Portal } from 'react-overlays';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import vi from 'date-fns/locale/vi';
import uk from 'date-fns/locale/uk';
registerLocale('vi', vi);
registerLocale('en', uk);

const CalendarContainer = ({ children }) => {
  const el = document.getElementById('calendar-portal');

  return <Portal container={el}>{children}</Portal>;
};

const RenderDatePicker = ({
  input,
  placeholder,
  classname,
  containerClassName,
  type,
  startDate,
  endDate,
  maxDate,
  minDate,
  t,
  setting,
}) => {
  if (!startDate || !endDate) return null;
  const lang = setting.lang || 'vi';
  // console.log(type, startDate, endDate)
  return (
    <Fragment>
      {type === 'start' ? (
        <DatePicker
          // {...input}
          className={classname || 'form-input'}
          selected={
            input.value
              ? stringToDate(input.value, 'dmy')
              : stringToDate(startDate, 'dmy')
          }
          dateFormat="dd/MM/yyyy"
          onChange={(date) => {
            date && input.onChange(moment(date).format('DD/MM/YYYY'));
          }}
          selectsStart
          startDate={stringToDate(startDate, 'dmy')}
          endDate={stringToDate(endDate, 'dmy')}
          placeholder={placeholder || 'Nhập ngày'}
          minDate={minDate || null}
          popperContainer={CalendarContainer}
          locale={lang}
        />
      ) : (
        <DatePicker
          className={classname || 'form-input'}
          selected={
            input.value
              ? stringToDate(input.value, 'dmy')
              : stringToDate(endDate, 'dmy')
          }
          dateFormat="dd/MM/yyyy"
          onChange={(date) => {
            date && input.onChange(moment(date).format('DD/MM/YYYY'));
          }}
          selectsEnd
          startDate={stringToDate(startDate, 'dmy')}
          endDate={stringToDate(endDate, 'dmy')}
          minDate={stringToDate(startDate, 'dmy')}
          placeholder={placeholder || 'Nhập ngày'}
          maxDate={maxDate || null}
          popperClassName={containerClassName || ''}
          popperContainer={CalendarContainer}
          locale={lang}
        />
      )}
    </Fragment>
  );
};

const makeMapStateToProps = () => {
  const mapStateToProps = (state) => {
    return {
      setting: state.client.setting,
    };
  };
  return mapStateToProps;
};

export default connect(makeMapStateToProps)(
  translate('translations')(RenderDatePicker)
);
