import {
  numberFormat,
  StringToInt_2,
  StringToInt,
  StringToDouble,
} from '../util';

export function priceBoardProcessG(data, idPrice, idVol) {
  let _rootPrice = document.getElementById(idPrice);
  let _rootVol = document.getElementById(idVol);
  if (!data) {
    if (_rootPrice?.hasChildNodes()) _rootPrice.children[0].innerHTML = '';
    if (_rootVol?.hasChildNodes()) _rootVol.children[0].innerHTML = '';
    return;
  }

  if (!_rootPrice) return;

  const _g = data.split('|');
  const _oldVol = StringToInt_2(_rootVol?.children[0]?.innerHTML);

  const price = _g[0];

  const vol = StringToInt_2(_g[1]);
  const clp = price === 'ATO' || price === 'ATC' ? '' : _g[2];
  const clv = _oldVol < vol ? 'i' : _oldVol > vol ? 'd' : clp;
  const _oldPrice = _rootPrice?.children[0]?.innerHTML;
  const _newPrice = numberFormat(price, 1);

  if (_oldPrice !== _newPrice) {
    _rootPrice.classList.remove('d', 'e', 'i', 'r', 'c', 'f');
    if (_rootPrice.children[0]) _rootPrice.children[0].innerHTML = _newPrice;

    if (clp) _rootPrice.classList.add(clp, 'active');
  }

  if (_oldVol !== vol) {
    // const _newVol = formatVolume10(_g[1]);
    const _newVol = numberFormat(_g[1]);
    _rootVol.classList.remove('d', 'e', 'i', 'r', 'c', 'f');
    if (_rootVol.children[0]) _rootVol.children[0].innerHTML = _newVol;

    if (clv) _rootVol.classList.add(clv, 'active');
  }

  setTimeout(() => {
    if (clp) _rootPrice.classList.remove('active');
    if (clv) {
      _rootVol.classList.remove('d', 'e', 'i', 'r', 'c', 'f', 'active');
      if (clp) _rootVol.classList.add(clp);
    }
  }, 500);
}

export function processG(data, idPrice, idVol, idPerVol, total) {
  let _rootPrice = document.getElementById(idPrice);
  let _rootVol = document.getElementById(idVol);
  let _rootPerVol = document.getElementById(idPerVol);

  if (!data) {
    if (_rootPrice) _rootPrice.innerHTML = '';
    if (_rootVol) _rootVol.innerHTML = '';
    if (_rootPerVol) _rootPerVol.style.width = 0;
    return;
  }

  if (!_rootPrice) return;

  const _g = data.split('|');

  const _oldVol = numberFormat(_rootVol?.innerHTML);

  const price = _g[0];
  const vol = numberFormat(_g[1]);
  const perV = ~~((parseInt(_g[1]) * 100) / total);

  const clp = price === 'ATO' || price === 'ATC' ? '' : _g[2];
  const clv = _oldVol < vol ? 'i' : _oldVol > vol ? 'd' : clp;

  const _oldPrice = _rootPrice?.innerHTML;
  const _newPrice = numberFormat(price, 1);

  if (_oldPrice !== _newPrice) {
    _rootPrice.classList.remove('d', 'e', 'i', 'r', 'c', 'f');
    if (_rootPrice) _rootPrice.innerHTML = _newPrice;

    if (clp) _rootPrice.classList.add(clp, 'active');
  }

  if (_oldVol !== vol) {
    _rootVol.classList.remove('d', 'e', 'i', 'r', 'c', 'f');
    if (_rootVol) _rootVol.innerHTML = vol;

    if (clv) _rootVol.classList.add(clv);
  }

  setTimeout(() => {
    if (clp) _rootPrice.classList.remove('active');
    if (clv) {
      _rootVol.classList.remove('d', 'e', 'i', 'r', 'c', 'f', 'active');
      // if (clp) _rootVol.classList.add(clp);
    }
  }, 500);

  if (_rootPerVol) _rootPerVol.style.width = perV + '%';
}

// Process update 10 buy price steps and 10 sell price steps
export function process3410(data) {
  let totalBV = 0,
    totalSV = 0;

  Array.from({ length: 10 }, (_, i) => {
    const index = i + 1;
    const gRaw = data[`g${index}`];
    if (gRaw) {
      const g = gRaw.split('|');
      if (data.side == 'B') {
        totalBV += parseInt(g[1]) || 0;
      } else {
        totalSV += parseInt(g[1]) || 0;
      }
      // g[0] => price | g[1] => vol | g[2] => color
    }
  });

  if (data.side === 'B') {
    // Priceboard
    Array.from({ length: 10 }, (_, i) => {
      const index = i + 1;
      priceBoardProcessG(
        data[`g${index}`],
        `d_${data.sym}bP${index}`, //d_VN30F2403oV2
        `d_${data.sym}bV${index}`
      );
    });

    // Pricelist
    Array.from({ length: 10 }, (_, i) => {
      const index = i + 1;

      processG(
        data[`g${index}`],
        `d_${data.sym}_bP${index}`,
        `d_${data.sym}_bV${index}`,
        `d_${data.sym}_perBV${index}`,
        totalBV
      );
    });
  } else {
    Array.from({ length: 10 }, (_, i) => {
      const index = i + 1;

      priceBoardProcessG(
        data[`g${index}`],
        `d_${data.sym}oP${index}`,
        `d_${data.sym}oV${index}`
      );
    });
    Array.from({ length: 10 }, (_, i) => {
      const index = i + 1;

      processG(
        data[`g${index}`],
        `d_${data.sym}_sP${index}`,
        `d_${data.sym}_sV${index}`,
        `d_${data.sym}_perSV${index}`,
        totalSV
      );
    });
  }
}

// Process update last matched price, last matched vol, bas, OI, change, changePC, total vol, aveprice, hight-low price, time
// Using in pricelist, order panel, symbol detail
export function process3420(data) {
  let _rootLastPrice = document.getElementById('d_' + data.sym + 'lastPrice');
  let _symInfRootLastPrice = document.getElementById(
    'd_' + data.sym + '_inf_lastPrice'
  );
  let _symInfrootChange = document.getElementById(
    'd_' + data.sym + '_inf_change'
  );
  let _symInfrootChangePc = document.getElementById(
    'd_' + data.sym + '_inf_changePc'
  );

  let _rootLastVol = document.getElementById('d_' + data.sym + 'lastVolume');
  let _rootChange = document.getElementById('d_' + data.sym + 'change');
  let _rootChangePc = document.getElementById('d_' + data.sym + 'changePc');
  let _rootLot = document.getElementById('d_' + data.sym + 'lot');
  let _rootHighP = document.getElementById('d_' + data.sym + 'highP');
  let _rootLowP = document.getElementById('d_' + data.sym + 'lowP');
  let _rootAveP = document.getElementById('d_' + data.sym + 'aveP');
  let _rootSym = document.getElementById('d_' + data.sym + 'sym');
  let _rootBas = document.getElementById('d_' + data.sym + 'bas');
  let _rootPnL = document.getElementById('position_' + data.sym + '_pnl');
  let _rootRoi = document.getElementById('position_' + data.sym + '_roi');

  if (!_rootSym) return;
  const _oldPrice = _rootLastPrice.children[0]?.innerHTML;
  const _newPrice = numberFormat(data.lastPrice, 1);
  const _oldVol = _rootLastVol.children[0]?.innerHTML;
  const _newVol = numberFormat(data.lastVol);

  const _oldTotalVol = _rootLot?.innerHTML;
  const _newTotalVol = numberFormat(data.totalVol);
  const _oldhp = _rootHighP?.innerHTML;
  const _newhp = numberFormat(data.hp, 1);

  const _oldlp = _rootLowP?.innerHTML;
  const _newlp = numberFormat(data.lp, 1);

  const _oldap = _rootAveP?.innerHTML;
  const _newap = numberFormat(data.ap, 1);

  if (_rootHighP) _rootHighP.classList.remove('d', 'e', 'i', 'r', 'c', 'f');
  if (_rootLowP) _rootLowP.classList.remove('d', 'e', 'i', 'r', 'c', 'f');
  if (_rootAveP) _rootAveP.classList.remove('d', 'e', 'i', 'r', 'c', 'f');
  if (_rootSym) _rootSym.classList.remove('d', 'e', 'i', 'r', 'c', 'f');
  if (_rootLot) _rootLot.classList.remove('i');

  if (_oldPrice !== _newPrice) {
    if (_rootLastPrice) {
      _rootLastPrice.classList.remove('d', 'e', 'i', 'r', 'c', 'f');
      if (_rootLastPrice.children[0])
        _rootLastPrice.children[0].innerHTML = _newPrice;
      _rootLastPrice.classList.add(data.cl, 'active');
    }

    if (_symInfRootLastPrice) {
      _symInfRootLastPrice.classList.remove('d', 'e', 'i', 'r', 'c', 'f');
      if (_symInfRootLastPrice) _symInfRootLastPrice.innerHTML = _newPrice;
      _symInfRootLastPrice.classList.add(data.cl, 'active');
    }

    if (_rootPnL && _rootRoi) {
      let _rootLongSortType = document.getElementById(
        'position_' + data.sym + '_long_short_type'
      );
      let _rootQuantity = document.getElementById(
        'position_' + data.sym + '_open_qty'
      );
      let _rootAvgPrice = document.getElementById(
        'position_' + data.sym + '_avg_price'
      );

      let _rootMrkPrc = document.getElementById(
        'position_' + data.sym + '_mrkPrc'
      );

      if (_rootMrkPrc) {
        _rootMrkPrc.classList.remove('d', 'e', 'i', 'r', 'c', 'f');
        _rootMrkPrc.innerHTML = numberFormat(data.lastPrice, 1, 0);
        _rootMrkPrc.classList.add(data.cl);
      }

      if (_rootQuantity && _rootAvgPrice && _rootLongSortType) {
        let _newPnL =
          StringToInt(_rootQuantity.innerText) *
          (StringToDouble(data.lastPrice) -
            StringToDouble(_rootAvgPrice.innerText));
        let _newRoi =
          (_newPnL * 100) /
          (StringToInt(_rootQuantity.innerText) * StringToInt(data.lastPrice));

        _rootPnL.classList.remove('d', 'e', 'i', 'r', 'c', 'f');
        _rootRoi.classList.remove('d', 'e', 'i', 'r', 'c', 'f');
        let _rootUnit = document.getElementById(
          'position_' + data.sym + '_unit'
        );
        let _cl = 'r';
        if (_rootLongSortType == '1') {
          _cl =
            StringToInt(_newPnL) > 0
              ? 'i'
              : StringToInt(_newPnL) == 0
              ? 'r'
              : 'd';
          _rootPnL.innerHTML = numberFormat(
            _newPnL * StringToInt(_rootUnit.innerText),
            0,
            0
          );
          _rootRoi.innerHTML = numberFormat(_newRoi, 2, 0) + '%';
        } else {
          _cl =
            StringToInt(_newPnL) < 0
              ? 'i'
              : StringToInt(_newPnL) == 0
              ? 'r'
              : 'd';
          _rootPnL.innerHTML =
            '-' +
            numberFormat(_newPnL * StringToInt(_rootUnit.innerText), 0, 0);
          _rootRoi.innerHTML = '-' + numberFormat(_newRoi, 2, 0) + '%';
        }

        // console.log('new pnl from sk', _rootPnL.innerHTML);
        // console.log('new roi from sk', _rootRoi.innerHTML);
        _rootPnL.classList.add(_cl);
        _rootRoi.classList.add(_cl);
      }
    }
  }
  if (_oldVol !== _newVol && _rootLastVol) {
    _rootLastVol.classList.remove('d', 'e', 'i', 'r', 'c', 'f');
    if (_rootLastVol.children[0]) _rootLastVol.children[0].innerHTML = _newVol;
    _rootLastVol.classList.add(data.cl, 'active');
  }
  if (_oldTotalVol !== _newTotalVol && _rootLot) {
    _rootLot.classList.remove('i');
    _rootLot.innerHTML = _newTotalVol;
    _rootLot.classList.add('i', 'active');
  }
  if (_rootHighP) _rootHighP.innerHTML = numberFormat(data.hp, 1);
  if (_rootLowP) _rootLowP.innerHTML = numberFormat(data.lp, 1);
  if (_rootAveP) _rootAveP.innerHTML = numberFormat(data.ap, 1);

  if (_rootBas && _rootBas.children[0]) {
    const basCl = data?.bas > 0 ? 'i' : data?.bas < 0 ? 'd' : 'r' || '';
    _rootBas.children[0].innerHTML = numberFormat(data.bas, 2);
    _rootBas.children[0].classList.remove('d', 'e', 'i', 'r', 'c', 'f');
    _rootBas.children[0].classList.add(basCl);
  }

  if (_rootChange) {
    _rootChange.classList.remove('d', 'e', 'i', 'r', 'c', 'f');
    if (data.change != 0) {
      _rootChange.innerHTML =
        (data.cl === 'd' ? '-' : '') + numberFormat(data.change, 1);
      _rootChange.classList.add(data.cl);
    }
  }

  if (_rootChangePc) {
    _rootChangePc.classList.remove('d', 'e', 'i', 'r', 'c', 'f');

    _rootChangePc.innerHTML =
      (data.changePc != 0 && data.cl === 'd' ? '-' : '') +
      numberFormat(data.changePc, 2, 0) +
      '%';
    _rootChangePc.classList.add(data.cl);
  }

  if (_symInfrootChange) {
    _symInfrootChange.classList.remove('d', 'e', 'i', 'r', 'c', 'f');
    _symInfrootChange.innerHTML =
      (data.cl === 'd' ? '-' : '') + numberFormat(data.change, 1, 0);
    _symInfrootChange.classList.add(data.cl);
  }

  if (_symInfrootChangePc) {
    _symInfrootChangePc.classList.remove('d', 'e', 'i', 'r', 'c', 'f');
    _symInfrootChangePc.innerHTML =
      (data.cl === 'd' ? '-' : '') + numberFormat(data.changePc, 2, 0) + '%';
    _symInfrootChangePc.classList.add(data.cl);
  }

  if (_rootHighP) {
    if (_oldhp !== _newhp) _rootHighP.classList.add('active');
    _rootHighP.classList.add(data.ch);
  }

  if (_rootLowP) {
    if (_oldlp !== _newlp) _rootLowP.classList.add('active');
    _rootLowP.classList.add(data.lc);
  }

  if (_rootAveP) {
    if (_oldap !== _newap) _rootAveP.classList.add('active');
    _rootAveP.classList.add(data.ca);
  }

  if (_rootSym) _rootSym.classList.add(data.cl);
  setTimeout(() => {
    if (_rootLastPrice) _rootLastPrice.classList.remove('active');
    if (_symInfRootLastPrice) _symInfRootLastPrice.classList.remove('active');
    if (_rootLastVol) _rootLastVol.classList.remove('active');
    if (_rootHighP) _rootHighP.classList.remove('active');
    if (_rootLowP) _rootLowP.classList.remove('active');
    if (_rootAveP) _rootAveP.classList.remove('active');
    if (_rootLot) _rootLot.classList.remove('i', 'active');
  }, 500);
}

// Process foreigner room
export function process3450(data) {
  let _rootFBVol = document.getElementById('d_' + data.symbol + 'fBVol');
  let _rootFSVol = document.getElementById('d_' + data.symbol + 'fSVol');

  const _oldFBVol = _rootFBVol?.innerHTML;
  const _newFBVol = numberFormat(data.fBVol);

  const _oldFSVol = _rootFSVol?.innerHTML;
  const _newFSVol = numberFormat(data.fSVolume);

  if (!_rootFBVol || !_rootFSVol) return;
  if (_oldFBVol !== _newFBVol) _rootFBVol.classList.add('i', 'active');
  _rootFBVol.innerHTML = _newFBVol;
  if (_oldFSVol !== _newFSVol) _rootFSVol.classList.add('i', 'active');
  _rootFSVol.innerHTML = _newFSVol;

  setTimeout(() => {
    _rootFBVol.classList.remove('i', 'active');
    _rootFSVol.classList.remove('i', 'active');
  }, 500);
}
