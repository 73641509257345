import React, { useEffect } from 'react';
import { withNamespaces } from 'react-i18next';
import { useSelector } from 'react-redux';
import { numberFormat } from '../../../../util';
import './overviewMetric.scss';

const OverviewMetric = withNamespaces('translations')(
  ({ t, loadBalanceHandle, account }) => {
    const cashBalance = useSelector(
      (state) => state.privData.cashBalance?.data
    );

    const switchUser = (account) => {
      if (account) loadBalanceHandle(account.subAcntNo);
      else loadBalanceHandle();
    };

    useEffect(() => {
      switchUser(account);
    }, [account]);

    return (
      <div className="section-overview">
        <div className="overview-metric">
          <span className="overview-metric__key">
            {t('personal.asset.totalOverview.totalAsset')}
          </span>
          <span className="overview-metric__value">
            {cashBalance && numberFormat(cashBalance.totAsst, 0, '0')}
          </span>
        </div>
        <div className="overview-metric">
          <span className="overview-metric__key">
            {t('personal.asset.totalOverview.netAssetValue')}
          </span>
          <span className="overview-metric__value">
            {cashBalance && numberFormat(cashBalance.realAsst, 0, '0')}
          </span>
        </div>
        <div className="overview-metric">
          <span className="overview-metric__key">
            {t('personal.asset.totalOverview.withdrawable')}
          </span>
          <span className="overview-metric__value">
            {cashBalance && numberFormat(cashBalance.wdrawAvail, 0, '0')}
          </span>
        </div>
        <div className="overview-metric">
          <span className="overview-metric__key">
            {t('personal.asset.totalOverview.availableAdvance')}
          </span>
          <span className="overview-metric__value">
            {cashBalance && numberFormat(cashBalance.advanceAvail, 0, '0')}
          </span>
        </div>
        <div className="overview-metric">
          <span className="overview-metric__key">
            {t('personal.asset.totalOverview.marginDebt')}
          </span>
          <span className="overview-metric__value">
            {cashBalance && numberFormat(cashBalance.mgDebt, 0, '0')}
          </span>
        </div>
        <div className="overview-metric">
          <span className="overview-metric__key">
            {t('personal.asset.totalOverview.rTT')}
          </span>
          <span className="overview-metric__value">
            {cashBalance && numberFormat(cashBalance.marginRatio, 2, '0') + '%'}
          </span>
        </div>
        <div className="overview-metric">
          <span className="overview-metric__key">
            {t('personal.asset.totalOverview.pP')}
          </span>
          <span className="overview-metric__value">
            {cashBalance && numberFormat(cashBalance.pp0, 0, '0')}
          </span>
        </div>
      </div>
    );
  }
);

export default OverviewMetric;
