import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { Form } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';

import { formatDate, _formatDate, _diff2Date } from '../../../util';

import { makeGetToken } from '../../../lib/selector';

import RenderDatePicker from '../../input/renderDatePicker';
import { summaryStockRequest } from '../../../containers/summary/actions';
import { setToast } from '../../../containers/client/actions';

import { translate } from 'react-i18next';
import { isEqual } from 'lodash';
class FormSearchSKCK extends Component {
  submit = (values) => {
    const { txtSearch, typeOrder, startDate, endDate } = values;
    const { token, account, t } = this.props;

    console.log(account);
    if (!token) return;
    const maxDay = token && token['config'] ? token['config']['maxDay'] : 90;

    const _typeOrder = typeOrder ? (typeOrder === 'Mua' ? 'B' : 'S') : '';
    const _diff = _diff2Date(endDate, startDate);
    if (_diff < 0) {
      this._handleToast(t('txt-valid-time'));
      return;
    }
    if (_diff > maxDay) {
      this._handleToast(
        `${t('txt-valid-range-time')} ${maxDay} ${t('txt-day')}`
      );
      return;
    }

    const _txtSearch = txtSearch ? txtSearch.toUpperCase() : '';
    const uuid = uuidv4();

    const params = {
      group: 'CORE',
      user: token.user,
      session: token.session,
      cmd: 'GetStockStatement',
      rqId: uuid,
      channel: 'WTS',
      data: {
        acntNo: account.acntNo,
        subAcntNo: account.subAcntNo,
        fromDate: _formatDate(startDate),
        toDate: _formatDate(endDate),
        symbol: _txtSearch,
        nextKey: '1',
        reqCount: 1000,
      },
    };

    this.props.dispatch(summaryStockRequest(params));
    this.props.saveParams(params);
  };

  componentDidUpdate(preProps) {
    const { account, reset, refresh } = this.props;

    if (
      (account && !isEqual(account, preProps.account)) ||
      refresh !== preProps.refresh
    ) {
      reset();
      setTimeout(() => {
        if (this.refSubmit) this.refSubmit.click();
      }, 100);
    }
  }

  _handleToast = (msg) => {
    const toastMsg = {
      id: Math.random(),
      msg: msg,
      title: this.props.t('txt-notice'),
    };
    this.props.dispatch(setToast(toastMsg));
  };

  render() {
    const { handleSubmit, submitting, startDate, endDate, t } = this.props;

    return (
      <form
        className="p-2 mt-2 form-search-head"
        onSubmit={handleSubmit(this.submit)}
      >
        <Form.Group className="d-flex mb-0 p-2" controlId="formPlaintextSymbol">
          {/* <label className="text-white-50 fz_14" style={{ width: '20mm' }}>
            {t('txt-search')}
          </label>
          <div style={{ width: '50mm' }}>
            <Field
              name="txtSearch"
              component="input"
              maxLength="8"
              type="text"
              className="form-control form-input text-uppercase fz_14"
              placeholder={t('txt-symbol')}
            />
          </div> */}
          <label className="text-white-50 fz_14 " style={{ width: '20mm' }}>
            {t('txt-label-from')}
          </label>
          <div style={{ width: '50mm' }}>
            {startDate && (
              <Field
                classname="form-control form-input fz_14"
                name="startDate"
                type="start"
                startDate={startDate}
                endDate={endDate}
                component={RenderDatePicker}
              />
            )}
          </div>
          <label className="text-white-50 fz_14 ml-2" style={{ width: '20mm' }}>
            {t('txt-label-to')}
          </label>
          <div style={{ width: '50mm' }}>
            {endDate && (
              <Field
                classname="form-control form-input fz_14"
                name="endDate"
                type="end"
                startDate={startDate}
                endDate={endDate}
                component={RenderDatePicker}
              />
            )}
          </div>
          <button
            ref={(el) => (this.refSubmit = el)}
            type="submit"
            disabled={submitting}
            className="btn-reload px-3 fz_14 fw-500 text-white ml-2 w-auto"
          >
            {t('txt-search')}
          </button>
        </Form.Group>
      </form>
    );
  }
}

FormSearchSKCK = reduxForm({
  form: 'saokeck',
  enableReinitialize: true,
})(FormSearchSKCK);

const selector = formValueSelector('saokeck');

const makeMapStateToProps = () => {
  const getToken = makeGetToken();

  const mapStateToProps = (state, props) => {
    const token = getToken(state);

    const { txtSearch, startDate, endDate } = selector(
      state,
      'txtSearch',
      'startDate',
      'endDate'
    );

    const numDay = token && token['config'] ? token['config']['numDay'] : 30;

    const d = new Date();
    d.setDate(d.getDate() - numDay);

    return {
      token,
      txtSearch,
      startDate,
      endDate,
      initialValues: {
        txtSearch: '',
        endDate: formatDate(new Date(), '/', 'dmy'),
        startDate: formatDate(d, '/', 'dmy'),
      },
    };
  };
  return mapStateToProps;
};

FormSearchSKCK = connect(makeMapStateToProps)(FormSearchSKCK);

export default translate('translations')(FormSearchSKCK);
