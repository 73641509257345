import {
  GET_LIST_EVENT,
  GET_LIST_EVENT_ERROR,
  GET_LIST_EVENT_SUCCESS,
  GET_LIST_NEWS,
  GET_LIST_NEWS_SUCCESS,
  GET_LIST_NEWS_ERROR,
  GET_NEWS_CATEGORIES,
  GET_NEWS_CATEGORIES_SUCCESS,
  GET_NEWS_CATEGORIES_ERROR,
  NEWS_LIST_REQUESTING,
  TOPNEWS_LIST_REQUESTING,
  EVENTS_LIST_REQUESTING,
  NEWS_DETAIL_REQUESTING,
  NEWS_DETAIL_REQUEST_FAIL,
  NEWS_DETAIL_REQUEST_SUCCESS,
  VIDEO_DETAIL_REQUEST,
  VIDEO_RELATED_LIST_REQUEST,
  TOPVIDEO_LIST_REQUESTING,
  NEWS_CATEGORY_REQUESTING,
  TOOGLE_VIDEO_DETAIL_MODAL,
  TOOGLE_NEW_DETAIL_MODAL,
  TOOGLE_EVENT_DETAIL_MODAL,
  CURRENT_NEWS,
  NEWS_DETAIL_UP_VIEW,
  NEWS_RELATED_LIST_REQUEST,
  NEWS_SYMBOL_REQUEST,
  EVENTS_DETAIL_REQUESTING,
  GLOBAL_INDEX_REQUEST,
  GET_BRIEFS_REQUESTING,
  GET_NEWS_SORT,
  GET_NEWS_SORT_ERROR,
  GET_NEWS_SORT_SUCCESS,
} from './constants';

export const getListEventRequest = (params) => {
  return {
    type: GET_LIST_EVENT,
    params,
  };
};

export const getListEventSuccess = (listEvent) => {
  return {
    type: GET_LIST_EVENT_SUCCESS,
    listEvent,
  };
};

export const getListEventError = (error) => {
  return {
    type: GET_LIST_EVENT_ERROR,
    error,
  };
};

export const getListNewsRequest = (params) => {
  return {
    type: GET_LIST_NEWS,
    params,
  };
};

export const getListNewsSuccess = (listNews) => {
  return {
    type: GET_LIST_NEWS_SUCCESS,
    listNews,
  };
};

export const getListNewsError = (error) => {
  return {
    type: GET_LIST_NEWS_ERROR,
    error,
  };
};

export const getNewsCategoriesRequest = (params) => {
  return {
    type: GET_NEWS_CATEGORIES,
    params,
  };
};

export const getNewsCategoriesSuccess = (newsCategories) => {
  return {
    type: GET_NEWS_CATEGORIES_SUCCESS,
    newsCategories,
  };
};

export const getNewsCategoriesError = (error) => {
  return {
    type: GET_NEWS_CATEGORIES_ERROR,
    error,
  };
};

export const getNewsSortRequest = (params) => {
  return {
    type: GET_NEWS_SORT,
    params,
  };
};

export const getNewsSortSuccess = (newsSort) => {
  return {
    type: GET_NEWS_SORT_SUCCESS,
    newsSort,
  };
};

export const getNewsSortError = (error) => {
  return {
    type: GET_NEWS_SORT_ERROR,
    error,
  };
};

export function newsListRequest(
  lang = 1,
  offset = 0,
  top = 10,
  catId = -1,
  keyword = '',
  symbols = ''
) {
  return {
    type: NEWS_LIST_REQUESTING,
    lang,
    offset,
    top,
    catId,
    keyword,
    symbols,
  };
}

export function topNewsRequest(lang = 1, top = 5) {
  return {
    type: TOPNEWS_LIST_REQUESTING,
    top,
    lang,
  };
}

export function categoriesRequest(lang = 1) {
  return {
    type: NEWS_CATEGORY_REQUESTING,
    lang,
  };
}

export function eventsRequest(
  fromDate = new Date(),
  toDate = new Date(),
  lang = 1
) {
  return {
    type: EVENTS_LIST_REQUESTING,
    fromDate,
    toDate,
    lang,
  };
}

export function eventDetailRequest(id) {
  return {
    type: EVENTS_DETAIL_REQUESTING,
    id,
  };
}

export function newsDetailRequest(id) {
  return {
    type: NEWS_DETAIL_REQUESTING,
    id,
  };
}

export function newsDetailRequestSuccess(data) {
  return {
    type: NEWS_DETAIL_REQUEST_SUCCESS,
    data,
  };
}

export function newsDetailRequestFail(error) {
  return {
    type: NEWS_DETAIL_REQUEST_FAIL,
    error,
  };
}

export function setUpView(id) {
  return {
    type: NEWS_DETAIL_UP_VIEW,
    id,
  };
}

export function getTopVideo(top, lang) {
  return {
    type: TOPVIDEO_LIST_REQUESTING,
    lang,
    top,
  };
}

export function toogleNewsModal(isOpen = false) {
  return {
    type: TOOGLE_NEW_DETAIL_MODAL,
    isOpen,
  };
}

export function toogleEventsModal(isOpen = false) {
  return {
    type: TOOGLE_EVENT_DETAIL_MODAL,
    isOpen,
  };
}

export function toogleVideoModal(isOpen = false) {
  return {
    type: TOOGLE_VIDEO_DETAIL_MODAL,
    isOpen,
  };
}

export function setCurrentNews(id) {
  return {
    type: CURRENT_NEWS,
    id,
  };
}

export function getVideoDetail(id) {
  return {
    type: VIDEO_DETAIL_REQUEST,
    id,
  };
}

export function getRelatedVideos(id, top = 5, offset = 0) {
  return {
    type: VIDEO_RELATED_LIST_REQUEST,
    id,
    top,
    offset,
  };
}

export function getSameCategoryNews(id, top = 5, lang = 1) {
  return {
    type: NEWS_RELATED_LIST_REQUEST,
    id,
    top,
    lang,
  };
}

export function getCurrentPrice(symbols) {
  return {
    type: NEWS_SYMBOL_REQUEST,
    symbols,
  };
}

export function getGlobalIndex(date) {
  return {
    type: GLOBAL_INDEX_REQUEST,
    date,
  };
}

export function getBriefs(top, lang, offset) {
  return {
    type: GET_BRIEFS_REQUESTING,
    lang,
    top,
    offset,
  };
}
