import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import Cel from './DatagridCell';
import {
  numberFormat,
  colorFix,
  formatVolume10,
  getSymbolInfor,
  getTickerType,
} from '../../util';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
// import { find } from 'lodash';

import { FaTimes } from 'react-icons/fa';
import {
  makeGetAuth,
  makeGetSymbolState,
  makeGetToken,
} from '../../lib/selector';
import {
  setAuth,
  setDblPrice,
  setLogin,
} from '../../containers/client/actions';
import { translate } from 'react-i18next';
import { MixConstants, Mixpanel } from '../../lib/mixpanel';

class DatagridRow extends PureComponent {
  _handleDelSymbol = (symbol) => {
    this.props.dispatch({ type: 'SYMBOL_DEL', symbol: symbol });
  };

  _handleTradeSym = (sym = '', side = 'BUY', price = '') => {
    const { auth, token, allStock, cwList } = this.props;

    if (!token) {
      this.props.dispatch(setLogin());
      return false;
    }

    if (!auth || !auth.isVerified) {
      this.props.dispatch(setAuth());
      return false;
    }

    Mixpanel.order({
      Ticker: sym,
      'Order Side': side,
      'Order Type': 'Normal Order',
      'Ticker Type': getTickerType(sym, cwList),
      'Ticker Description': getSymbolInfor(sym, allStock)?.name_vn,
      Location: MixConstants.LOCATIONS.PRICE_BOARD,
    });

    const newOrder = {
      symbol: sym,
      side: side,
      price: price,
    };
    this.props.dispatch(setDblPrice(newOrder));
  };

  render() {
    const { isPc, record, symbol, t, categorySelected, cwList } = this.props;

    const cl = colorFix(
      record.lastPrice,
      record.r,
      record.c,
      record.f,
      record.r
    );
    const clHigh = colorFix(
      record.highPrice,
      record.r,
      record.c,
      record.f,
      record.r
    );
    const clLow = colorFix(
      record.lowPrice,
      record.r,
      record.c,
      record.f,
      record.r
    );
    const clAve = colorFix(
      record.avePrice,
      record.r,
      record.c,
      record.f,
      record.r
    );

    return (
      <tr
        id={record.sym + 'row'}
        // ref={this.props.forwardRef}
      >
        <td className="text-center">
          <input
            type="checkbox"
            onChange={this.props.handleChange}
            data-tag={record.sym}
            checked={this.props.checked}
          />
        </td>
        <td
          className={'text-left po-relative cursor-pointer fw-500 ' + cl}
          id={record.sym + 'sym'}
        >
          <OverlayTrigger
            placement="top"
            overlay={
              // <Tooltip id={`tooltip-top`}>{symbol && symbol.name_vn}</Tooltip>
              <Tooltip id={`tooltip-top`}>
                {symbol && symbol.name_vn}{' '}
                {symbol &&
                  symbol.post_to &&
                  `${
                    symbol.post_to == 'STO'
                      ? '- HOSE'
                      : symbol.post_to == 'STX'
                      ? '- HNX'
                      : symbol.post_to == 'UPX'
                      ? '- UPCOM'
                      : symbol.post_to
                  }`}
              </Tooltip>
            }
          >
            <a
              // className={cl}
              onClick={() => {
                Mixpanel.viewTicketInfo({
                  Ticker: record.sym,
                  'Ticker Type': getTickerType(record.sym, cwList),
                  'Ticker Location': 'Home',
                  'Ticker Location Detail': 'Personal Watchlist',
                });

                this.props.handleOpenModal(record.sym);
              }}
            >
              {record.sym} {record.flag && record.flag == 1 ? '*' : null}
            </a>
          </OverlayTrigger>
          <FaTimes
            className="txt-red fz_12 act-del"
            onClick={() => this._handleDelSymbol(record.sym)}
          />
        </td>
        <td
          className="text-right high-light r cursor-pointer fw-500"
          id={record.sym + 'ref'}
          onDoubleClick={() =>
            this._handleTradeSym(record.sym, 'BUY', numberFormat(record.r, 2))
          }
        >
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>{t('lbl-double-click-to-order')}</Tooltip>}
          >
            <span>{numberFormat(record.r, 2)}</span>
          </OverlayTrigger>
        </td>
        <td
          className="text-right high-light c cursor-pointer fw-500"
          id={record.sym + 'ceil'}
          onDoubleClick={() =>
            this._handleTradeSym(record.sym, 'BUY', numberFormat(record.c, 2))
          }
        >
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>{t('lbl-double-click-to-order')}</Tooltip>}
          >
            <span>{numberFormat(record.c, 2)}</span>
          </OverlayTrigger>
        </td>
        <td
          className="text-right high-light f cursor-pointer fw-500"
          id={record.sym + 'floor'}
          onDoubleClick={() =>
            this._handleTradeSym(record.sym, 'BUY', numberFormat(record.f, 2))
          }
        >
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>{t('lbl-double-click-to-order')}</Tooltip>}
          >
            <span>{numberFormat(record.f, 2)}</span>
          </OverlayTrigger>
        </td>
        <Cel
          data={{ bP3: record.bP3, bVC3: record.bCl3 }}
          sym={record.sym}
          type="bP3"
          className="text-right fw-500"
          doubleClick={this._handleTradeSym}
        ></Cel>
        <Cel
          data={{ bV3: record.bV3, bVC3: record.bVC3, bCl3: record.bCl3 }}
          sym={record.sym}
          type="bV3"
          className="text-right"
        ></Cel>
        <Cel
          data={{ bP2: record.bP2, bVC2: record.bCl2 }}
          sym={record.sym}
          type="bP2"
          className="text-right fw-500"
          doubleClick={this._handleTradeSym}
        ></Cel>
        <Cel
          data={{ bV2: record.bV2, bVC2: record.bVC2, bCl2: record.bCl2 }}
          sym={record.sym}
          type="bV2"
          className="text-right"
        ></Cel>
        <Cel
          data={{ bP1: record.bP1, bVC1: record.bCl1 }}
          sym={record.sym}
          type="bP1"
          className="text-right fw-500"
          doubleClick={this._handleTradeSym}
        ></Cel>
        <Cel
          data={{ bV1: record.bV1, bVC1: record.bVC1, bCl1: record.bCl1 }}
          sym={record.sym}
          type="bV1"
          className="text-right"
        ></Cel>
        <Cel
          data={{
            lastPrice: record.lastPrice,
            r: record.r,
            c: record.c,
            f: record.f,
            cl: record.cl,
          }}
          sym={record.sym}
          type="lastPrice"
          className="text-right high-light fw-500"
          doubleClick={this._handleTradeSym}
        ></Cel>
        <Cel
          data={{
            lastVolume: record.lastVolume,
            lastPrice: record.lastPrice,
            r: record.r,
            c: record.c,
            f: record.f,
            cl: record.cl,
          }}
          sym={record.sym}
          type="lastVolume"
          className="text-right high-light "
        ></Cel>
        <td
          // id={record.sym + (isPc ? 'changePc' : 'change')}
          className={'text-right high-light ' + (record.cl || cl)}
        >
          <span
            id={record.sym + 'change'}
            style={{ display: isPc ? 'none' : '' }}
          >
            {record.lastPrice == 0 ? '' : numberFormat(record.change, 2)}
          </span>
          <span
            id={record.sym + 'changePc'}
            style={{ display: isPc ? '' : 'none' }}
          >
            {record.lastPrice == 0 ? '' : numberFormat(record.changePc, 2)}
          </span>
        </td>
        <Cel
          data={{ oP1: record.oP1, oVC1: record.oCl1 }}
          sym={record.sym}
          type="oP1"
          className="text-right fw-500"
          doubleClick={this._handleTradeSym}
        ></Cel>
        <Cel
          data={{ oV1: record.oV1, oVC1: record.oVC1, oCl1: record.oCl1 }}
          sym={record.sym}
          type="oV1"
          className="text-right"
        ></Cel>
        <Cel
          data={{ oP2: record.oP2, oVC2: record.oCl2 }}
          sym={record.sym}
          type="oP2"
          className="text-right fw-500"
          doubleClick={this._handleTradeSym}
        ></Cel>
        <Cel
          data={{ oV2: record.oV2, oVC2: record.oVC2, oCl2: record.oCl2 }}
          sym={record.sym}
          type="oV2"
          className="text-right"
        ></Cel>
        <Cel
          data={{ oP3: record.oP3, oVC3: record.oCl3 }}
          sym={record.sym}
          type="oP3"
          className="text-right fw-500"
          doubleClick={this._handleTradeSym}
        ></Cel>
        <Cel
          data={{ oV3: record.oV3, oVC3: record.oVC3, oCl3: record.oCl3 }}
          sym={record.sym}
          type="oV3"
          className="text-right"
        ></Cel>
        <td className="text-right" id={record.sym + 'lot'}>
          {formatVolume10(record.lot)}
        </td>
        <td
          className={'text-right high-light fw-500 ' + clHigh}
          id={record.sym + 'highP'}
        >
          {numberFormat(record.highPrice, 2)}
        </td>
        <td
          className={'text-right high-light fw-500 ' + clLow}
          id={record.sym + 'lowP'}
        >
          {numberFormat(record.lowPrice, 2)}
        </td>
        <td
          className={'text-right high-light fw-500 ' + clAve}
          id={record.sym + 'aveP'}
        >
          {numberFormat(record.avePrice, 2)}
        </td>
        <td className="text-right " id={record.sym + 'fBVol'}>
          {formatVolume10(record.fBVol)}
        </td>
        <td className="text-right " id={record.sym + 'fSVol'}>
          {formatVolume10(record.fSVolume)}
        </td>
      </tr>
    );
  }
}

const makeMapStateToProps = () => {
  const getSymbolState = makeGetSymbolState();
  const getAuth = makeGetAuth();
  const getToken = makeGetToken();

  const mapStateToProps = (state, props) => {
    return {
      symbol: getSymbolState(state, props),
      auth: getAuth(state),
      token: getToken(state),
      categorySelected: state.client?.categorySelected,
      allStock: state.priceBoard.allStock,
      cwList: state.priceBoard.cwList,
    };
  };
  return mapStateToProps;
};

export default connect(makeMapStateToProps)(
  translate('translations')(DatagridRow)
);
