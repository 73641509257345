import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setNotiPopup } from '../../containers/client/actions';
import Modal from 'react-bootstrap/Modal';

const NotificationPopup = ({
  title,
  successCallback,
  cancelCallback,
  redirectCallback,
  iconClass,
}) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [contents, setContent] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);

  const { currentTheme, notiPopup } = useSelector((state) => state.client);
  const PAGE_SIZE = 1;

  useEffect(() => {
    if (!notiPopup) setShow(false);
    else setShow(true);
  }, [notiPopup]);

  const closeModal = () => {
    dispatch(setNotiPopup(null));
  };

  const nextPage = () => {
    if (currentPage < totalPage) {
      changePage(currentPage + 1);
      // setSymbols
    }
  };
  const backPage = () => {
    if (currentPage > 1) {
      changePage(currentPage - 1);
    }
  };

  const changePage = (page) => {
    const _contents = notiPopup.slice((page - 1) * PAGE_SIZE, page * PAGE_SIZE);

    setContent(_contents);
    setCurrentPage(page);
  };

  return (
    <Modal
      show={show}
      dialogClassName="wts-modal"
      backdropClassName="wts-modal-backdrop noti-popup__backdrop"
      contentClassName={`${
        currentTheme ? currentTheme.name : 'dark'
      } noti-popup wts-modal-content wts-modal-content--sm2`}
      onEscapeKeyDown={() => closeModal()}
      centered
      onHide={() => closeModal()}
      style={{ zIndex: 11115 }}
    >
      <div className="noti-popup__header">
        <span></span>
        <span className="text--md text--light">{title}</span>
        <span className="icon iClose"></span>
      </div>
      <div className="noti-popup__body">
        <span className="noti-popup__icon">
          <span className={`icon ${iconClass}`}></span>
        </span>
        <div className="noti-popup__content">
          {contents && contents.length == 1 && (
            <>
              <span className="text--md text--light">{contents[0]?.title}</span>
              <span className="text--sm text--light3">{contents[0]?.desc}</span>
            </>
          )}

          {contents &&
            !!contents.length > 1 &&
            contents.map((content, i) => (
              <>
                <span className="text--md text--light">{content?.title}</span>
                <span className="text--sm text--light3">{content?.desc}</span>

                <div className="content__actions">
                  <button
                    className="btn btn--cancel"
                    onClick={() => backPage()}
                  >
                    .
                  </button>
                  <span className="content__actions">
                    {currentPage}/{totalPage}
                  </span>
                  <button
                    className="btn btn--cancel"
                    onClick={() => nextPage()}
                  >
                    .
                  </button>
                </div>
              </>
            ))}
        </div>
        <div className="noti-popup__actions">
          {redirectCallback && <button className="btn btn--primary"></button>}
          {successCallback && <button className="btn btn--primary"></button>}
          {cancelCallback && <button className="btn btn--cancel"></button>}
        </div>
      </div>
      <div className="noti-popup__footer"></div>
    </Modal>
  );
};

export default NotificationPopup;
