export const REGISTER_COMPETITON_REQUEST = 'REGISTER_COMPETITON_REQUEST';
export const REGISTER_COMPETITON_SUCCESS = 'REGISTER_COMPETITON_SUCCESS';
export const REGISTER_COMPETITON_ERROR = 'REGISTER_COMPETITON_ERROR';

export const GET_CPT_ROUND_INFOR_REQUEST = 'GET_CPT_ROUND_INFOR_REQUEST';
export const GET_CPT_ROUND_INFOR_SUCCESS = 'GET_CPT_ROUND_INFOR_SUCCESS';
export const GET_CPT_ROUND_INFOR_ERROR = 'GET_CPT_ROUND_INFOR_ERROR';

export const GET_CPT_BOARD_INFOR_REQUEST = 'GET_CPT_BOARD_INFOR_REQUEST';
export const GET_CPT_BOARD_INFOR_SUCCESS = 'GET_CPT_BOARD_INFOR_SUCCESS';
export const GET_CPT_BOARD_INFOR_ERROR = 'GET_CPT_BOARD_INFOR_ERROR';

export const GET_REGIST_CPT_STAT_REQUEST = 'GET_REGIST_CPT_STAT_REQUEST';
export const GET_REGIST_CPT_STAT_SUCCESS = 'GET_REGIST_CPT_STAT_SUCCESS';
export const GET_REGIST_CPT_STAT_ERROR = 'GET_REGIST_CPT_STAT_ERROR';

export const UN_REGIST_CPT_REQUEST = 'UN_REGIST_CPT_REQUEST';

export const GET_CPT_STOCK_BAL_REQUEST = 'GET_CPT_STOCK_BAL_REQUEST';
export const GET_CPT_STOCK_BAL_SUCCESS = 'GET_CPT_STOCK_BAL_SUCCESS';
export const GET_CPT_STOCK_BAL_ERROR = 'GET_CPT_STOCK_BAL_ERROR';

export const GET_CPT_TOP_RANK_REQUEST = 'GET_CPT_TOP_RANK_REQUEST';
export const GET_CPT_TOP_RANK_SUCCESS = 'GET_CPT_TOP_RANK_SUCCESS';
export const GET_CPT_TOP_RANK_ERROR = 'GET_CPT_TOP_RANK_ERROR';

export const GET_CPT_TOP_SYMBOL_REQUEST = 'GET_CPT_TOP_SYMBOL_REQUEST';
export const GET_CPT_TOP_SYMBOL_SUCCESS = 'GET_CPT_TOP_SYMBOL_SUCCESS';
export const GET_CPT_TOP_SYMBOL_ERROR = 'GET_CPT_TOP_SYMBOL_ERROR';

export const GET_CPT_PER_CHART_REQUEST = 'GET_CPT_PER_CHART_REQUEST';
export const GET_CPT_PER_CHART_SUCCESS = 'GET_CPT_PER_CHART_SUCCESS';
export const GET_CPT_PER_CHART_ERROR = 'GET_CPT_PER_CHART_ERROR';

export const GET_CPT_PERSONAL_INFOR_REQUEST = 'GET_CPT_PERSONAL_INFOR_REQUEST';
export const GET_CPT_PERSONAL_INFOR_SUCCESS = 'GET_CPT_PERSONAL_INFOR_SUCCESS';
export const GET_CPT_PERSONAL_INFOR_ERROR = 'GET_CPT_PERSONAL_INFOR_ERROR';

export const SET_ROUND_VIEW_RESULT = 'SET_ROUND_VIEW_RESULT';
