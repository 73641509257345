import React from 'react';
import './index.scss';
import { withNamespaces } from 'react-i18next';
import Events from './components/events';
import MarketPanel from './components/marketPanel';
import Newsfeed from './components/newsfeed';
import VideoPanel from './components/videoPanel';

const News = withNamespaces('translations')(() => {
  return (
    <div className="news-container">
      <div className="body-panel">
        <div className="body-panel--left">
          <div className="left-top">
            <MarketPanel />
          </div>
          <div className="left-bottom">
            <VideoPanel />
          </div>
        </div>
        <div className="body-panel--center">
          <Newsfeed />
        </div>
        <div className="body-panel--right">
          <Events />
        </div>
      </div>
    </div>
  );
});

export default News;
