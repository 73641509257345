import { constants } from '../../../util/constant';
import {
  CDKT_REQUESTING,
  CDKT_REQUEST_ERROR,
  CDKT_REQUEST_SUCCESS,
  COMPANY_INFO_REQUESTING,
  COMPANY_INFO_REQUEST_ERROR,
  COMPANY_INFO_REQUEST_SUCCESS,
  CST_REQUESTING,
  CST_REQUEST_ERROR,
  CST_REQUEST_SUCCESS,
  KQKD_REQUESTING,
  KQKD_REQUEST_ERROR,
  KQKD_REQUEST_SUCCESS,
  STOCK_DETAIL_REQUESTING,
  STOCK_DETAIL_REQUEST_ERROR,
  STOCK_DETAIL_REQUEST_SUCCESS,
  STOCK_EVENTS_REQUESTING,
  STOCK_EVENTS_REQUEST_ERROR,
  STOCK_EVENTS_REQUEST_SUCCESS,
  STOCK_HIS_REQUESTING,
  STOCK_HIS_REQUEST_ERROR,
  STOCK_HIS_REQUEST_SUCCESS,
  STOCK_MANAGEMENT_REQUESTING,
  STOCK_MANAGEMENT_REQUEST_ERROR,
  STOCK_MANAGEMENT_REQUEST_SUCCESS,
  STOCK_NEWS_REQUESTING,
  STOCK_NEWS_REQUEST_ERROR,
  STOCK_NEWS_REQUEST_SUCCESS,
  STOCK_TRADE_REQUESTING,
  STOCK_TRADE_REQUEST_ERROR,
  STOCK_TRADE_REQUEST_SUCCESS,
  STOCK_DETAIL_REQUEST_RESET,
  GET_CW_DETAIL_REQUEST,
  GET_TRADE_HIS_COMMON_REQUEST,
} from './constants';

export const clearCache = function clearCache() {
  return {
    type: 'CLEAR_CACHE_STOCK_DETAIL',
  };
};

export const stockDetailReset = () => {
  return {
    type: STOCK_DETAIL_REQUEST_RESET,
  };
};

export const stockDetailRequest = function stockDetailRequest(
  data,
  board = constants.G.DEFAULT,
  callback = null
) {
  return {
    type: STOCK_DETAIL_REQUESTING,
    data,
    board,
    callback,
  };
};

export const stockDetailRequestSuccess = function stockDetailRequestSuccess(
  resData
) {
  return {
    type: STOCK_DETAIL_REQUEST_SUCCESS,
    resData,
  };
};

export const stockDetailRequestError = function stockDetailRequestError(error) {
  return {
    type: STOCK_DETAIL_REQUEST_ERROR,
    error,
  };
};

export const stockTradeRequest = function stockTradeRequest(
  data,
  board = constants.G.DEFAULT,
  callback = null
) {
  return {
    type: STOCK_TRADE_REQUESTING,
    data,
    board,
    callback,
  };
};

export const stockTradeRequestSuccess = function stockTradeRequestSuccess(
  resData
) {
  return {
    type: STOCK_TRADE_REQUEST_SUCCESS,
    resData,
  };
};

export const stockTradeRequestError = function stockTradeRequestError(error) {
  return {
    type: STOCK_TRADE_REQUEST_ERROR,
    error,
  };
};

export const cstRequest = function cstRequest(data) {
  return {
    type: CST_REQUESTING,
    data,
  };
};

export const cstRequestSuccess = function cstRequestSuccess(resData) {
  return {
    type: CST_REQUEST_SUCCESS,
    resData,
  };
};

export const cstRequestError = function cstRequestError(error) {
  return {
    type: CST_REQUEST_ERROR,
    error,
  };
};

export const kqkdRequest = function kqkdRequest(data) {
  return {
    type: KQKD_REQUESTING,
    data,
  };
};

export const kqkdRequestSuccess = function kqkdRequestSuccess(resData) {
  return {
    type: KQKD_REQUEST_SUCCESS,
    resData,
  };
};

export const kqkdRequestError = function kqkdRequestError(error) {
  return {
    type: KQKD_REQUEST_ERROR,
    error,
  };
};

export const cdktRequest = function cdktRequest(data) {
  return {
    type: CDKT_REQUESTING,
    data,
  };
};

export const cdktRequestSuccess = function cdktRequestSuccess(resData) {
  return {
    type: CDKT_REQUEST_SUCCESS,
    resData,
  };
};

export const cdktRequestError = function cdktRequestError(error) {
  return {
    type: CDKT_REQUEST_ERROR,
    error,
  };
};

export const stockHisRequest = function stockHisRequest(data) {
  return {
    type: STOCK_HIS_REQUESTING,
    data,
  };
};

export const stockHisRequestSuccess = function stockHisRequestSuccess(resData) {
  return {
    type: STOCK_HIS_REQUEST_SUCCESS,
    resData,
  };
};

export const stockHisRequestError = function stockHisRequestError(error) {
  return {
    type: STOCK_HIS_REQUEST_ERROR,
    error,
  };
};

export const stockNewsRequest = function stockNewsRequest(data) {
  return {
    type: STOCK_NEWS_REQUESTING,
    data,
  };
};

export const stockNewsRequestSuccess = function stockNewsRequestSuccess(
  resData
) {
  return {
    type: STOCK_NEWS_REQUEST_SUCCESS,
    resData,
  };
};

export const stockNewsRequestError = function stockNewsRequestError(error) {
  return {
    type: STOCK_NEWS_REQUEST_ERROR,
    error,
  };
};

export const stockEventsRequest = function stockEventsRequest(data) {
  return {
    type: STOCK_EVENTS_REQUESTING,
    data,
  };
};

export const stockEventsRequestSuccess = function stockEventsRequestSuccess(
  resData
) {
  return {
    type: STOCK_EVENTS_REQUEST_SUCCESS,
    resData,
  };
};

export const stockEventsRequestError = function stockEventsRequestError(error) {
  return {
    type: STOCK_EVENTS_REQUEST_ERROR,
    error,
  };
};

export const stockManagementRequest = function stockManagementRequest(data) {
  return {
    type: STOCK_MANAGEMENT_REQUESTING,
    data,
  };
};

export const stockManagementRequestSuccess =
  function stockManagementRequestSuccess(resData) {
    return {
      type: STOCK_MANAGEMENT_REQUEST_SUCCESS,
      resData,
    };
  };

export const stockManagementRequestError = function stockManagementRequestError(
  error
) {
  return {
    type: STOCK_MANAGEMENT_REQUEST_ERROR,
    error,
  };
};

export const companyInfoRequest = function companyInfoRequest(data) {
  return {
    type: COMPANY_INFO_REQUESTING,
    data,
  };
};

export const companyInfoRequestSuccess = function companyInfoRequestSuccess(
  resData
) {
  return {
    type: COMPANY_INFO_REQUEST_SUCCESS,
    resData,
  };
};

export const companyInfoRequestError = function companyInfoRequestError(error) {
  return {
    type: COMPANY_INFO_REQUEST_ERROR,
    error,
  };
};

export const getCWDetailRequest = (code, lang = 1, callback = null) => {
  return {
    type: GET_CW_DETAIL_REQUEST,
    code,
    lang,
    callback,
  };
};

export const getTradeHisCommonRequest = (symbol, board, callback) => {
  return {
    type: GET_TRADE_HIS_COMMON_REQUEST,
    symbol,
    board,
    callback,
  };
};
