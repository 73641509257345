import React, { Fragment } from 'react';
import { find, isEmpty, isEqual, sortBy, sumBy, take, takeRight } from 'lodash';
import { Button } from 'react-bootstrap';
import { translate } from 'react-i18next';
import { BsFillCaretDownFill, BsFillCaretUpFill } from 'react-icons/bs';
import { GiAnticlockwiseRotation } from 'react-icons/gi';
import PerfectScrollBar from 'react-perfect-scrollbar';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import icMagnifier from '../../assets/img/icons/header/Search.svg';
import {
  setAuth,
  setDblPrice,
  setLogin,
} from '../../containers/client/actions';
import { rightEventRequest } from '../../containers/summary/actions';
import {
  makeGetAuth,
  makeGetSummaryRightList,
  makeGetToken,
} from '../../lib/selector';
import {
  StringToDouble,
  StringToInt,
  _formatDate2,
  mapRightType,
  numberFormat,
} from '../../util';
import Checkbox from '../common/checkbox/index';
import PaginationTable from '../PaginationTable';
import { PagingSize } from '../PagingSize';

const PAGE_SIZES_OPTIONS = [
  { value: 15, label: '15' },
  { value: 30, label: '30' },
  { value: 50, label: '50' },
];

class TaiSan extends React.PureComponent {
  state = {
    contentShow: [],
    _totalShow: null,
    _totalOddLot: {},
    page: 1,
    pageSizeOption: PAGE_SIZES_OPTIONS[0],
    continued: false,
    totalPage: 1,
    sortType: 'asc',
    sortField: 'symbol',
    txtSymbol: '',
    hideSoldOut: false,
    hideOddSymbol: false,
    rightShow: [],
  };

  componentDidMount() {
    this._handleGetRightList();
    this.props.reload();
  }

  componentDidUpdate(preProps) {
    const { positions, account } = this.props;
    if (positions && !isEqual(positions, preProps.positions)) {
      this.setState({
        totalCount: positions ? positions.length : 0,
      });
      this.handleQueryPage(1);
    }

    if (account && !isEqual(account, preProps.account)) {
      this._handleGetRightList();
      this.setState({ first: true, hideOddSymbol: false, hideSoldOut: false });
    }
  }

  _handleGetRightList = () => {
    const { account, token } = this.props;
    console.log('taisan account', account);

    if (!token || !account) return;

    const uuid = uuidv4();
    const params = {
      group: 'CORE',
      user: token.user,
      session: token.session,
      cmd: 'getRightList',
      rqId: uuid,
      channel: 'WTS',
      data: {
        acntNo: account.acntNo,
        subAcntNo: account.subAcntNo,
      },
    };

    this.props.dispatch(rightEventRequest(params));
  };

  handleQueryPage = (page) => {
    const { positions } = this.props;
    const { pageSizeOption, sortType, sortField, hideSoldOut, hideOddSymbol } =
      this.state;

    if (!positions) return;

    const oddSymbols = positions.filter(
      (x) => x.balQty < 100 && x.symbol !== 'TOTAL'
    );
    if (oddSymbols && oddSymbols.length > 0) {
      const totBuyAmt = sumBy(oddSymbols, 'totBuyAmt');
      const totCurAmt = sumBy(oddSymbols, 'totCurAmt');

      this.setState({ _totalOddLot: { totBuyAmt, totCurAmt } });
    }

    let _positions = positions.filter(
      (position) => position.symbol !== 'TOTAL'
    );

    if (hideSoldOut) {
      _positions = _positions.filter((x) => x.balQty !== 0);
    }

    if (hideOddSymbol) {
      _positions = _positions.filter((x) => x.balQty >= 100);
    }

    if (sortType === 'desc') {
      _positions =
        _positions &&
        _positions.length > 0 &&
        sortBy(_positions, sortField).reverse();
    }
    if (sortType === 'asc') {
      _positions =
        _positions && _positions.length > 0 && sortBy(_positions, sortField);
    }

    const _pageSize = pageSizeOption?.value ?? PAGE_SIZES_OPTIONS[0].value;
    const totalPage =
      _positions && _positions.length > 0
        ? Math.ceil(_positions.length / _pageSize)
        : 1;

    const _takeContent = take(_positions, _pageSize * page);
    const _contentShow = takeRight(
      _takeContent,
      _takeContent.length - _pageSize * (page - 1)
    );

    const _totalShow = find(positions, (o) => o.symbol === 'TOTAL');

    this.setState({
      _totalShow,
      contentShow: _contentShow,
      page,
      continued: page < totalPage,
      totalPage,
      first: false,
    });
  };

  _handleBlurSymbol = () => {
    const { txtSymbol } = this.state;
    const { positions } = this.props;

    // clear paging, sort
    this.setState({ sortType: '', page: 1 });
    if (!positions || !positions.length) return;
    if (!txtSymbol) return this.handleQueryPage(1);

    const _sym = find(
      positions,
      (o) => o.symbol === txtSymbol.toLocaleUpperCase().trim()
    );
    if (_sym) {
      const _total = {
        symbol: 'TOTAL',
        totBuyAmt: _sym.totBuyAmt,
        totCurAmt: _sym.totCurAmt,
        gainLoss: _sym.gainLoss,
        gainLossPc: _sym.gainLossPc,
        change: _sym.change,
      };
      this.setState({ contentShow: [_sym], _totalShow: _total });
    }
  };

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this._handleBlurSymbol();
    }
  };

  _handleShort = (fieldName) => {
    const { sortType, sortField } = this.state;
    if (!fieldName) fieldName = sortField;

    this.setState(
      {
        sortType: sortType === 'desc' ? 'asc' : 'desc',
        page: 1,
        sortField: fieldName,
      },
      () => {
        this.handleQueryPage(1);
      }
    );
  };

  _showSortIcon = (field) => {
    const { sortType, sortField } = this.state;
    if (sortType === 'asc' && sortField == field) {
      return <BsFillCaretDownFill className="fz_10 headerSort" />;
    }

    if (sortType === 'desc' && sortField == field) {
      return <BsFillCaretUpFill className="fz_10 headerSort" />;
    }
  };

  _handleNextPage = (step) => {
    const { page } = this.state;
    this.setState({ txtSymbol: '' }, () => this.handleQueryPage(page + step));
  };

  _handleSetPageSize = (pageSize) => {
    this.setState({ pageSizeOption: pageSize }, () => this.handleQueryPage(1));
  };

  _handleHideSoldOut = () => {
    const { hideSoldOut } = this.state;

    this.setState({ hideSoldOut: !hideSoldOut }, () => {
      this.handleQueryPage(1);
    });
  };

  _handleHideOddSymbol = () => {
    const { hideOddSymbol } = this.state;
    this.setState({ hideOddSymbol: !hideOddSymbol }, () => {
      this.handleQueryPage(1);
    });
  };

  _handleSearchChange = (e) => {
    const { txtSymbol } = this.state;
    this.setState({ [e.target.name]: e.target.value });

    if (e.target.value && e.target.value.length >= 3) {
      this.setState({ txtSymbol: e.target.value }, () => {
        this._handleBlurSymbol();
      });
      return;
    }

    if (
      e.target.value !== null &&
      typeof e.target.value !== 'undefined' &&
      e.target.value.length === 0
    ) {
      this.handleQueryPage(1);
      return;
    }
  };

  _handleTradeSym = (sym = '') => {
    const { token, auth } = this.props;

    if (!token) {
      this.props.dispatch(setLogin());
      return false;
    }

    if (!auth || !auth.isVerified) {
      this.props.dispatch(setAuth());
      return false;
    }

    const newOrder = {
      symbol: sym,
      side: 'BUY',
      price: '',
    };
    console.log('Trade Symbol', newOrder);
    this.props.dispatch(setDblPrice(newOrder));
  };

  render() {
    const { cashBalance, positions, rightList, t } = this.props;
    const {
      contentShow,
      page,
      continued,
      totalPage,
      sortType,
      sortField,
      txtSymbol,
      _totalShow,
      _totalOddLot,
      hideSoldOut,
      hideOddSymbol,
    } = this.state;

    const columns = [
      {
        key: 'symbol',
        text: t('txt-symbol'),
        className: 'text-center',
        align: 'center',
      },
      {
        text: t('txt-type'),
        className: 'text-center',
        align: 'center',
        cell: (record) => {
          return mapRightType(record.rghtSubTp);
        },
      },
      {
        key: 'basdt',
        text: t('txt-label-expected-date'),
        className: 'text-center',
        align: 'center',
        cell: (record) => {
          return _formatDate2(record.basdt);
        },
      },
      {
        text: t('txt-label-registration-date'),
        className: 'text-center',
        align: 'center',
        cell: (record) => {
          if (record.rghtSubTp === '01') {
            if (record.dpstStockQty > 0) return _formatDate2(record.stockDate);

            if (record.dpstCashAmt > 0) return _formatDate2(record.cashDate);
          }

          return _formatDate2(record.stockDate);
        },
      },
      {
        key: 'balanceQuantity',
        text: t('txt-label-quantity-record-date'),
        className: 'text-right',
        align: 'center',
        cell: (record) => {
          return numberFormat(record.balanceQuantity, 0, '0');
        },
      },
      {
        key: 'rightRate',
        text: t('txt-label-ratio'),
        className: 'text-right',
        align: 'center',
        cell: (record) => {
          if (record.rghtSubTp === '01') {
            return numberFormat(StringToDouble(record.rightRate), 2, '0') + '%';
          }
          return record.rightRate;
        },
      },
      {
        text: t('txt-stock-waiting'),
        className: 'text-right',
        align: 'center',
        cell: (record) => {
          return numberFormat(record.dpstStockQty, 0, '0');
        },
      },
      {
        text: t('txt-asset-collateral'),
        className: 'text-right',
        align: 'center',
        cell: (record) => {
          return numberFormat(record.dpstCashAmt, 0, '0');
        },
      },
    ];

    const headerColumn = [
      {
        key: 'symbol',
        text: t('txt-symbol'),
        className: 'text-center position-relative filterIcon',
      },
      {
        key: 'balQty',
        text: t('txt-total-vol'),
        className: 'text-center position-relative filterIcon',
      },
      {
        key: 'trdAvailQty',
        text: t('txt-label-avail'),
        className: 'text-center position-relative filterIcon',
      },
      {
        key: 'devidendQty',
        text: t('txt-total-dividend-vol'),
        className: 'text-center position-relative filterIcon',
      },
      {
        key: 'sellTn',
        text: t('txt-label-pending-delivery'),
        className: 'text-center position-relative filterIcon',
      },
      {
        key: 'waitTrdQty',
        text: t('txt-label-pending-trading'),
        className: 'text-center position-relative filterIcon',
      },
      {
        key: 'buyT2',
        text: `${t('txt-buy')} T2`,
        className: 'text-center position-relative filterIcon',
      },
      {
        key: 'buyT1',
        text: `${t('txt-buy')} T1`,
        className: 'text-center position-relative filterIcon',
      },
      {
        key: 'buyT0',
        text: `${t('txt-buy')} T0`,
        className: 'text-center position-relative filterIcon',
      },
      {
        key: 'avgPrice',
        text: t('txt-ave'),
        className: 'text-center position-relative filterIcon',
      },
      {
        key: 'lastPrice',
        text: t('txt-market-price'),
        className: 'text-center position-relative filterIcon',
      },
      {
        key: 'change',
        text: t('txt-change'),
        className: 'text-center position-relative filterIcon',
      },
      {
        key: 'changePC',
        text: t('txt-change-per'),
        className: 'text-center position-relative filterIcon',
      },
      {
        key: 'totBuyAmt',
        text: t('txt-label-buy-amt'),
        className: 'text-center position-relative filterIcon',
      },
      {
        key: 'totCurAmt',
        text: t('txt-label-market-value'),
        className: 'text-center position-relative filterIcon',
      },
      {
        key: 'gainLoss',
        text: t('txt-gain-loss'),
        className: 'text-center position-relative filterIcon',
      },
      {
        key: 'gainLossPc',
        text: `% ${t('txt-gain-loss')}`,
        className: 'text-center position-relative filterIcon',
      },
    ];

    const pageSizeOptions = [
      ...PAGE_SIZES_OPTIONS,
      { value: positions?.length ?? 0, label: 'txt-all' },
    ];

    return (
      <Fragment>
        <div className="d-flex flex-row mt-3">
          <span
            className="mr-auto ml-2 text-white"
            style={{ fontSize: '18px' }}
          >
            {`${t('txt-detail')} ${t('txt-account')}`}
          </span>
          <a
            className="mr-3 btn-animation"
            onClick={() => {
              this._handleGetRightList();
              this.props.reload();
            }}
          >
            <GiAnticlockwiseRotation className="fz_14 text-white" />
          </a>
        </div>
        <PerfectScrollBar style={{ height: 'calc(100% - 155px)' }}>
          <label className="text-white mt-3 ml-2 fz_14">
            {t('txt-label-overview')}
          </label>
          <div className="d-flex m-2">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th className="text-right">{t('txt-asset-total')}</th>
                  <th className="text-right">{t('txt-asset-nav')}</th>
                  <th className="text-right">{t('txt-asset-withdrawal')}</th>
                  <th className="text-right">{t('txt-label-total-loan')}</th>
                  <th className="text-right">{'RTT'}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="text-right">
                    {cashBalance && numberFormat(cashBalance.totAsst, 0, '0')}
                  </td>
                  <td className="text-right">
                    {cashBalance && numberFormat(cashBalance.realAsst, 0, '0')}
                  </td>
                  <td className="text-right">
                    {cashBalance &&
                      numberFormat(cashBalance.wdrawAvail, 0, '0')}
                  </td>
                  <td className="text-right">
                    {cashBalance && numberFormat(cashBalance.debt, 0, '0')}
                  </td>
                  <td className="text-right">
                    {cashBalance && cashBalance.marginRatio}%
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="d-flex mt-4 ml-2 justify-content-between">
            <div className="d-flex">
              <label className="text-white fz_14">
                {t('txt-label-position')}
              </label>
              <input
                name="txtSymbol"
                type="text"
                className="form-control input-order text-uppercase w_150 ml-3 fz_14 ml-2 text-white"
                placeholder={t('txt-symbol')}
                value={txtSymbol}
                onChange={(e) => {
                  this.setState({ [e.target.name]: e.target.value });
                  this._handleSearchChange(e);
                }}
                onBlur={this._handleBlurSymbol}
                // onchange={(e) => this._handleSearchChange(e)}
                onKeyPress={this.handleKeyPress}
                maxLength={10}
                autoComplete="off"
                style={{
                  backgroundColor: '#2F3134',
                  borderRadius: '25px',
                  border: 0,
                  paddingLeft: '30px',
                  backgroundImage: `url(${icMagnifier})`,
                  backgroundPosition: '7px 5px',
                  backgroundRepeat: 'no-repeat',
                }}
              />
            </div>
            <div className="form-check-inline mr-3">
              {_totalOddLot && !isEmpty(_totalOddLot) ? (
                <Checkbox
                  title={t('txt-symbols-odd-lot-hide')}
                  checked={hideOddSymbol}
                  onChange={this._handleHideOddSymbol}
                />
              ) : null}

              <Checkbox
                title={t('txt-symbols-sold-out-hide')}
                checked={hideSoldOut}
                onChange={this._handleHideSoldOut}
              />
            </div>
          </div>
          <div className="d-flex flex-column m-2">
            <table className="table table-bordered">
              <thead>
                <tr style={{ height: '3.5rem' }}>
                  {headerColumn.map((item, index) => {
                    return (
                      <th
                        className={item.className}
                        key={index}
                        onClick={() => this._handleShort(item.key)}
                      >
                        {item.text}
                        {this._showSortIcon(item.key)}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {_totalShow && (
                  <tr>
                    <td
                      colSpan="13"
                      className="text-left fw-500 text-uppercase high-light"
                    >
                      {t('txt-total-num')}
                    </td>
                    <td className="text-right high-light">
                      {numberFormat(
                        !hideOddSymbol
                          ? _totalShow.totBuyAmt
                          : _totalShow.totBuyAmt - _totalOddLot.totBuyAmt,
                        0,
                        '0'
                      )}
                    </td>
                    <td className="text-right high-light">
                      {numberFormat(
                        !hideOddSymbol
                          ? _totalShow.totCurAmt
                          : _totalShow.totCurAmt - _totalOddLot.totCurAmt,
                        0,
                        '0'
                      )}
                    </td>
                    <td
                      className={
                        'text-right high-light ' +
                        ((!hideOddSymbol
                          ? _totalShow.gainLoss
                          : _totalShow.gainLoss -
                            (_totalOddLot.totCurAmt - _totalOddLot.totBuyAmt)) >
                        0
                          ? 'i'
                          : (!hideOddSymbol
                              ? _totalShow.gainLoss
                              : _totalShow.gainLoss -
                                (_totalOddLot.totCurAmt -
                                  _totalOddLot.totBuyAmt)) < 0
                          ? 'd'
                          : 'r')
                      }
                    >
                      {numberFormat(
                        !hideOddSymbol
                          ? _totalShow.gainLoss
                          : _totalShow.gainLoss -
                              (_totalOddLot.totCurAmt - _totalOddLot.totBuyAmt),
                        0,
                        '0'
                      )}
                    </td>
                    <td
                      className={
                        'text-right high-light ' +
                        ((!hideOddSymbol
                          ? _totalShow.gainLoss
                          : _totalShow.gainLoss -
                            (_totalOddLot.totCurAmt - _totalOddLot.totBuyAmt)) >
                        0
                          ? 'i'
                          : (!hideOddSymbol
                              ? _totalShow.gainLoss
                              : _totalShow.gainLoss -
                                (_totalOddLot.totCurAmt -
                                  _totalOddLot.totBuyAmt)) < 0
                          ? 'd'
                          : 'r')
                      }
                    >
                      {numberFormat(
                        !hideOddSymbol
                          ? _totalShow.gainLossPc
                          : ((_totalShow.gainLoss -
                              (_totalOddLot.totCurAmt -
                                _totalOddLot.totBuyAmt)) *
                              100) /
                              _totalShow.totBuyAmt,
                        2,
                        '-'
                      )}
                      %
                    </td>
                  </tr>
                )}
                {contentShow &&
                  !!contentShow.length &&
                  contentShow.map((item, index) => {
                    if (item.symbol === 'TOTAL') return null;

                    return (
                      <tr key={index}>
                        <td
                          className={
                            'text-right fw-500 cursor-pointer ' +
                            (item.gainLoss > 0
                              ? 'i'
                              : item.gainLoss < 0
                              ? 'd'
                              : 'r')
                          }
                          onDoubleClick={() => {
                            this._handleTradeSym(item.symbol);
                          }}
                        >
                          {item.symbol}
                        </td>
                        <td className="text-right">
                          {numberFormat(item.balQty, 0, '0')}
                        </td>
                        <td className="text-right">
                          {numberFormat(item.trdAvailQty, 0, '0')}
                        </td>
                        <td className="text-right">
                          {numberFormat(item.devidendQty, 0, '0')}
                        </td>
                        <td className="text-right">
                          {numberFormat(item.sellTn, 0, '0')}
                        </td>
                        <td className="text-right">
                          {numberFormat(item.waitTrdQty, 0, '0')}
                        </td>
                        <td className="text-right">
                          {numberFormat(item.buyT2, 0, '0')}
                        </td>
                        <td className="text-right">
                          {numberFormat(item.buyT1, 0, '0')}
                        </td>
                        <td className="text-right">
                          {numberFormat(item.buyT0, 0, '0')}
                        </td>
                        <td className="text-right">
                          {numberFormat(item.avgPrice, 3, '0')}
                        </td>
                        <td className="text-right">
                          {numberFormat(item.lastPrice, 2, '0')}
                        </td>
                        <td
                          className={
                            'text-right ' +
                            (StringToInt(item.change) > 0
                              ? 'i'
                              : StringToInt(item.change) < 0
                              ? 'd'
                              : 'r')
                          }
                        >
                          {numberFormat(item.change / 1000, 2, '0')}
                        </td>
                        <td
                          className={
                            'text-right ' +
                            (StringToInt(item.change) > 0
                              ? 'i'
                              : StringToInt(item.change) < 0
                              ? 'd'
                              : 'r')
                          }
                        >
                          {numberFormat(item.changePC, 2, '0')}
                        </td>
                        <td className="text-right">
                          {numberFormat(item.totBuyAmt, 0, '0')}
                        </td>
                        <td className="text-right">
                          {numberFormat(item.totCurAmt, 0, '0')}
                        </td>
                        <td
                          className={
                            'text-right ' +
                            (item.gainLoss > 0
                              ? 'i'
                              : item.gainLoss < 0
                              ? 'd'
                              : 'r')
                          }
                        >
                          {numberFormat(item.gainLoss, 0, '0')}
                        </td>
                        <td
                          className={
                            'text-right ' +
                            (item.gainLoss > 0
                              ? 'i'
                              : item.gainLoss < 0
                              ? 'd'
                              : 'r')
                          }
                        >
                          {numberFormat(item.gainLossPc, 2, '-')}%
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            {(!positions || !positions.length) && (
              <p className="text-center fz_14 mt-2 title-time">
                {t('txt-no-data-found')}
              </p>
            )}
            {positions && (
              <div className="d-flex flex-row-reverse align-items-center paging mt-3 mr-1">
                <div className="d-flex align-items-center">
                  <Button
                    onClick={() => this._handleNextPage(-1)}
                    disabled={page === 1}
                    className="d-flex align-items-center justify-content-center"
                  >
                    {'<'}
                  </Button>
                  <Button
                    onClick={() => this._handleNextPage(1)}
                    disabled={!continued}
                    className="d-flex align-items-center justify-content-center"
                  >
                    {'>'}
                  </Button>
                </div>
                <span className="fz_14 text-white mr-2">{`${t(
                  'txt-label-page'
                )} ${page + '/' + totalPage}`}</span>
                <PagingSize
                  getOptionLabel={(option) => t(option.label)}
                  value={this.state.pageSizeOption}
                  onChange={this._handleSetPageSize}
                  options={pageSizeOptions}
                />
                <span className="fz_14 text-white mr-2">
                  {t('txt-label-page-size')}
                </span>
              </div>
            )}
          </div>
          <label className="text-white mt-3 ml-2 fz_14">
            {t('txt-label-right')}
          </label>
          <PaginationTable
            pageSize={15}
            columns={columns}
            source={rightList && rightList.list ? rightList.list : []}
          />
        </PerfectScrollBar>
      </Fragment>
    );
  }
}

const makeMapStateToProps = () => {
  const getToken = makeGetToken();
  const getSummaryRightList = makeGetSummaryRightList();
  const getAuth = makeGetAuth();

  const mapStateToProps = (state, props) => {
    return {
      token: getToken(state),
      rightList: getSummaryRightList(state),
      auth: getAuth(state),
    };
  };
  return mapStateToProps;
};

export default connect(makeMapStateToProps)(translate('translations')(TaiSan));
