import { call, put, takeLatest, all, select } from 'redux-saga/effects';
import { handleApiErrors } from '../../lib/api-errors';
import {
  GET_LIST_EVENT,
  GET_LIST_NEWS,
  GET_NEWS_CATEGORIES,
  NEWS_LIST_REQUESTING,
  NEWS_LIST_REQUEST_SUCCESS,
  NEWS_LIST_REQUEST_FAIL,
  NEWS_DETAIL_REQUESTING,
  NEWS_DETAIL_REQUEST_FAIL,
  NEWS_DETAIL_REQUEST_SUCCESS,
  VIDEO_DETAIL_REQUEST,
  VIDEO_DETAIL_REQUEST_SUCCESS,
  VIDEO_DETAIL_REQUEST_FAIL,
  VIDEO_RELATED_LIST_REQUEST,
  VIDEO_RELATED_LIST_FAIL,
  VIDEO_RELATED_LIST_SUCCESS,
  TOPVIDEO_LIST_REQUESTING,
  TOPVIDEO_LIST_REQUEST_SUCCESS,
  TOPVIDEO_LIST_REQUEST_FAIL,
  TOPNEWS_LIST_REQUESTING,
  TOPNEWS_LIST_REQUEST_SUCCESS,
  TOPNEWS_LIST_REQUEST_FAIL,
  EVENTS_LIST_REQUESTING,
  EVENTS_LIST_REQUEST_SUCCESS,
  EVENTS_LIST_REQUEST_FAIL,
  NEWS_CATEGORY_REQUESTING,
  NEWS_CATEGORY_REQUEST_SUCCESS,
  NEWS_CATEGORY_REQUEST_FAIL,
  NEWS_DETAIL_UP_VIEW,
  NEWS_RELATED_LIST_REQUEST,
  NEWS_RELATED_LIST_SUCCESS,
  NEWS_RELATED_LIST_FAIL,
  NEWS_SYMBOL_REQUEST,
  NEWS_SYMBOL_REQUEST_SUCCESS,
  NEWS_SYMBOL_REQUEST_FAIL,
  EVENTS_DETAIL_REQUESTING,
  EVENTS_DETAIL_REQUEST_SUCESS,
  EVENTS_DETAIL_REQUEST_FAIL,
  GLOBAL_INDEX_REQUEST,
  GLOBAL_INDEX_REQUEST_SUCCESS,
  GLOBAL_INDEX_REQUEST_FAIL,
  GET_BRIEFS_REQUESTING,
  GET_BRIEFS_REQUEST_SUCCESS,
  GET_BRIEFS_REQUEST_FAIL,
  GET_NEWS_SORT,
} from './constants';

import {
  getListEventSuccess,
  getListEventError,
  getListNewsSuccess,
  getListNewsError,
  getNewsCategoriesSuccess,
  getNewsCategoriesError,
  getNewsSortSuccess,
  getNewsSortError,
} from './action';

import { getMessage } from '../../lib/selector';
import { removeCookie } from '../../lib/storages';

const appUrl = `${process.env.REACT_APP_API_URL_2}`;
const appUrlNews = `${process.env.REACT_APP_API_URL_2}`;
const appPriceUrl = `${process.env.REACT_APP_API_URL}`;

function handleRequest(request) {
  return request
    .then(handleApiErrors)
    .then((response) => response.json())
    .then((json) => json)
    .catch((error) => {
      throw error;
    });
}

function eventApi(fromDate, toDate, lang) {
  const url = `${appUrl}/api/Events/GetEvents?lang=${lang}${
    !fromDate ? '' : `&fromDate=${fromDate}`
  }${!toDate ? '' : `&toDate=${toDate}`}`;
  const request = fetch(url, {
    method: 'GET',
  });
  return handleRequest(request);
}

function newsApi(lang) {
  const url = `${appUrl}/api/News/GetNews?lang=${lang}&offset=5&top=10&catId=-1`;
  const request = fetch(url, {
    method: 'GET',
  });
  return handleRequest(request);
}

function newsCategoriesApi(lang) {
  const url = `${appUrl}/api/News/GetCategories?lang=${lang}`;
  const request = fetch(url, {
    method: 'GET',
  });
  return handleRequest(request);
}

function newsSortApi(lang) {
  const url = `${appUrl}/api/News/GetSortNews?lang=${lang}`;
  const request = fetch(url, {
    method: 'GET',
  });
  return handleRequest(request);
}

function* getListEventFlow({ params }) {
  try {
    const { fromDate, toDate, lang } = params;
    const resData = yield call(eventApi, fromDate, toDate, lang);
    if (resData.http !== 200) {
      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');
        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }
      const msgErr = yield select(getMessage, resData);
      throw Error(msgErr);
    }
    yield put(getListEventSuccess(resData.data));
  } catch (error) {
    yield put(getListEventError(error));
  }
}

function* getListNewsFlow({ params }) {
  const { lang } = params;
  try {
    const resData = yield call(newsApi, lang);
    if (resData.http !== 200) {
      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');
        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }
      const msgErr = yield select(getMessage, resData);
      throw Error(msgErr);
    }
    yield put(getListNewsSuccess(resData.data));
  } catch (error) {
    yield put(getListNewsError(error));
  }
}

function* getNewsCategoriesFlow({ params }) {
  const { lang } = params;
  try {
    const resData = yield call(newsCategoriesApi, lang);
    if (resData.http !== 200) {
      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');
        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }
      const msgErr = yield select(getMessage, resData);
      throw Error(msgErr);
    }
    yield put(getNewsCategoriesSuccess(resData.data));
  } catch (error) {
    yield put(getNewsCategoriesError(error));
  }
}

function* getNewsSortFlow({ params }) {
  const { lang } = params;
  try {
    const resData = yield call(newsSortApi, lang);
    if (resData.http !== 200) {
      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');
        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }
      const msgErr = yield select(getMessage, resData);
      throw Error(msgErr);
    }
    yield put(getNewsSortSuccess(resData.data));
  } catch (error) {
    yield put(getNewsSortError(error));
  }
}

function getNewsList(offset, top, catId, keyword, symbols, lang) {
  const url = `${appUrlNews}/api/News/GetNews?top=${top}&offset=${offset}&catId=${catId}&keyword=${keyword}&syms=${symbols}&lang=${lang}`;
  const request = fetch(url);
  return handleRequest(request);
}

function getTopNews(top, lang) {
  const url = `${appUrlNews}/api/News/GetHotArticle?top=${top}&lang=${lang}`;
  const request = fetch(url);
  return handleRequest(request);
}

function getNewsCategory(lang) {
  const url = `${appUrlNews}/api/News/GetCategories?lang=${lang}`;
  const request = fetch(url);
  return handleRequest(request);
}

function getEvents(fromDate, toDate, lang) {
  const url = `${appUrlNews}/api/Events/GetEvents?fromDate=${fromDate}&toDate=${toDate}&lang=${lang}`;
  const request = fetch(url);
  return handleRequest(request);
}

function getNewsDetail(id) {
  const url = `${appUrlNews}/api/News/${id}`;
  const request = fetch(url);
  return handleRequest(request);
}

function getTopVideos(top, lang) {
  const url = `${appUrlNews}/api/Videos/GetLastestVideos?top=${top}&lang=${lang}`;
  const request = fetch(url);
  return handleRequest(request);
}

function getBriefs(top, lang, offset) {
  const url = `${appUrlNews}/api/News/GetBriefs?top=${top}&lang=${lang}&offset=${offset}`;
  const request = fetch(url);
  return handleRequest(request);
}

function getVideosDetail(id) {
  const url = `${appUrlNews}/api/Videos/GetVideoDetail?id=${id}`;
  const request = fetch(url);
  return handleRequest(request);
}

function getRelatedVideos(id, top, offset) {
  const url = `${appUrlNews}/api/Videos/GetRelatedVideos?id=${id}&top=${top}&offset=${offset}`;
  const request = fetch(url);
  return handleRequest(request);
}

function getSameCategoryNews(id, top, lang) {
  const url = `${appUrlNews}/api/News/GetSameCategoryNews?newsId=${id}&top=${top}&lang=${lang}`;
  const request = fetch(url);
  return handleRequest(request);
}

function setUpView(id) {
  const url = `${appUrlNews}/api/News/UpView?id=${id}`;
  const request = fetch(url);
  return handleRequest(request);
}

function getCurrentPrices(symbols) {
  const url = `${appPriceUrl}/getliststockdata/${symbols}`;
  const request = fetch(url);
  return handleRequest(request);
}

function getEventDetail(id) {
  const url = `${appUrlNews}/api/Events/${id}`;
  const request = fetch(url);
  return handleRequest(request);
}

function getGlobalIndex(date) {
  const url = `${appUrlNews}/api/Market/GetGlobalIndex?tradingDate=${date}`;
  const request = fetch(url);
  return handleRequest(request);
}

function* getNewsListFlow({ lang, offset, top, catId, keyword, symbols }) {
  try {
    const resData = yield call(
      getNewsList,
      offset,
      top,
      catId,
      keyword,
      symbols,
      lang
    );

    if (resData.http !== 200) {
      const msgErr = yield select(getMessage, offset, top, catId);
      throw Error(msgErr);
    }

    yield put({ type: NEWS_LIST_REQUEST_SUCCESS, data: resData.data });
  } catch (error) {
    yield put({ type: NEWS_LIST_REQUEST_FAIL, error });
  }
}

function* getTopNewsFlow({ top, lang }) {
  try {
    const resData = yield call(getTopNews, top, lang);

    if (resData.http !== 200) {
      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');

        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }
      const msgErr = yield select(getMessage, resData);
      throw Error(msgErr);
    }

    yield put({ type: TOPNEWS_LIST_REQUEST_SUCCESS, data: resData.data });
  } catch (error) {
    yield put({ type: TOPNEWS_LIST_REQUEST_FAIL, error });
  }
}

function* getCategoryFlow({ lang }) {
  try {
    const resData = yield call(getNewsCategory, lang);

    if (resData.http !== 200) {
      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');

        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }
      const msgErr = yield select(getMessage, resData);
      throw Error(msgErr);
    }

    yield put({ type: NEWS_CATEGORY_REQUEST_SUCCESS, data: resData.data });
  } catch (error) {
    yield put({ type: NEWS_CATEGORY_REQUEST_FAIL, error });
  }
}

function* getEventsFlow({ fromDate, toDate, lang }) {
  try {
    const resData = yield call(getEvents, fromDate, toDate, lang);

    if (resData.http !== 200) {
      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');

        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }
      const msgErr = yield select(getMessage, resData);
      throw Error(msgErr);
    }

    yield put({ type: EVENTS_LIST_REQUEST_SUCCESS, data: resData.data });
  } catch (error) {
    yield put({ type: EVENTS_LIST_REQUEST_SUCCESS, error });
  }
}

function* getNewsDetailFlow({ id }) {
  try {
    const resData = yield call(getNewsDetail, id);

    if (resData.http !== 200) {
      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');

        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }
      const msgErr = yield select(getMessage, resData);
      throw Error(msgErr);
    }

    yield put({ type: NEWS_DETAIL_REQUEST_SUCCESS, data: resData.data });
  } catch (error) {
    yield put({ type: NEWS_DETAIL_REQUEST_FAIL, error });
  }
}

function* getTopVideosFlow({ top, lang }) {
  try {
    const resData = yield call(getTopVideos, top, lang);
    if (resData.http !== 200) {
      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');

        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }
      const msgErr = yield select(getMessage, resData);
      throw Error(msgErr);
    }

    yield put({ type: TOPVIDEO_LIST_REQUEST_SUCCESS, data: resData.data });
  } catch (error) {
    yield put({ type: TOPVIDEO_LIST_REQUEST_FAIL, error });
  }
}

function* getVideoDetailFlow({ id }) {
  try {
    const resData = yield call(getVideosDetail, id);
    console.log('resData ===>', resData);
    if (resData.http !== 200) {
      // public api dont need check authen
      const msgErr = yield select(getMessage, resData);
      throw Error(msgErr);
    }

    yield put({ type: VIDEO_DETAIL_REQUEST_SUCCESS, data: resData.data });
  } catch (error) {
    yield put({ type: VIDEO_DETAIL_REQUEST_FAIL, error });
  }
}

function* getRelateListFlow({ id, top, offset }) {
  try {
    const resData = yield call(getRelatedVideos, id, top, offset);
    if (resData.http !== 200) {
      // public api dont need check authen
      const msgErr = yield select(getMessage, resData);
      throw Error(msgErr);
    }
    yield put({ type: VIDEO_RELATED_LIST_SUCCESS, data: resData.data });
  } catch (error) {
    yield put({ type: VIDEO_RELATED_LIST_FAIL, error });
  }
}

function* getSameCategoryNewsFlow({ id, top, lang }) {
  try {
    const resData = yield call(getSameCategoryNews, id, top, lang);
    if (resData.http !== 200) {
      // public api dont need check authen
      const msgErr = yield select(getMessage, resData);
      throw Error(msgErr);
    }
    yield put({ type: NEWS_RELATED_LIST_SUCCESS, data: resData.data });
  } catch (error) {
    yield put({ type: NEWS_RELATED_LIST_FAIL, error });
  }
}

function* setUpViewFlow({ id }) {
  // fire and forget
  try {
    const resData = yield call(setUpView, id);
  } catch (error) {}
}

function* getCurrentPriceByPriceFlow({ symbols }) {
  try {
    const data = yield call(getCurrentPrices, symbols);
    yield put({ type: NEWS_SYMBOL_REQUEST_SUCCESS, data: data });
  } catch (error) {
    yield put({ type: NEWS_SYMBOL_REQUEST_FAIL, error });
  }
}

function* getEventDetailFlow({ id }) {
  try {
    const resData = yield call(getEventDetail, id);
    if (resData.http !== 200) {
      // public api dont need check authen
      const msgErr = yield select(getMessage, resData);
      throw Error(msgErr);
    }
    yield put({ type: EVENTS_DETAIL_REQUEST_SUCESS, data: resData.data });
  } catch (error) {
    yield put({ type: EVENTS_DETAIL_REQUEST_FAIL, error });
  }
}

function* getGlobalIndexFlow({ date }) {
  try {
    const resData = yield call(getGlobalIndex, date);
    if (resData.http !== 200) {
      // public api dont need check authen
      const msgErr = yield select(getMessage, resData);
      throw Error(msgErr);
    }
    yield put({ type: GLOBAL_INDEX_REQUEST_SUCCESS, data: resData.data });
  } catch (error) {
    yield put({ type: GLOBAL_INDEX_REQUEST_FAIL, error });
  }
}
function* getBriefsFlow({ top, lang, offset }) {
  try {
    const resData = yield call(getBriefs, top, lang, offset);
    if (resData.http !== 200) {
      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');

        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }
      const msgErr = yield select(getMessage, resData);
      throw Error(msgErr);
    }

    yield put({ type: GET_BRIEFS_REQUEST_SUCCESS, data: resData.data });
  } catch (error) {
    yield put({ type: GET_BRIEFS_REQUEST_FAIL, error });
  }
}

function* newsWatcher() {
  yield all([takeLatest(GET_LIST_EVENT, getListEventFlow)]);
  yield all([takeLatest(GET_LIST_NEWS, getListNewsFlow)]);
  yield all([takeLatest(GET_NEWS_CATEGORIES, getNewsCategoriesFlow)]);
  yield all([takeLatest(GET_NEWS_SORT, getNewsSortFlow)]);
  yield all([takeLatest(NEWS_LIST_REQUESTING, getNewsListFlow)]);
  yield all([takeLatest(TOPNEWS_LIST_REQUESTING, getTopNewsFlow)]);
  yield all([takeLatest(NEWS_CATEGORY_REQUESTING, getCategoryFlow)]);
  yield all([takeLatest(EVENTS_LIST_REQUESTING, getEventsFlow)]);
  yield all([takeLatest(EVENTS_DETAIL_REQUESTING, getEventDetailFlow)]);
  yield all([takeLatest(NEWS_DETAIL_REQUESTING, getNewsDetailFlow)]);
  yield all([takeLatest(TOPVIDEO_LIST_REQUESTING, getTopVideosFlow)]);
  yield all([takeLatest(VIDEO_DETAIL_REQUEST, getVideoDetailFlow)]);
  yield all([takeLatest(VIDEO_RELATED_LIST_REQUEST, getRelateListFlow)]);
  yield all([takeLatest(NEWS_DETAIL_UP_VIEW, setUpViewFlow)]);
  yield all([takeLatest(NEWS_RELATED_LIST_REQUEST, getSameCategoryNewsFlow)]);
  yield all([takeLatest(NEWS_SYMBOL_REQUEST, getCurrentPriceByPriceFlow)]);
  yield all([takeLatest(GLOBAL_INDEX_REQUEST, getGlobalIndexFlow)]);
  yield all([takeLatest(GET_BRIEFS_REQUESTING, getBriefsFlow)]);
}

export default newsWatcher;
