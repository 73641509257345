import {
  NAV_TOGGLE,
  TYPE_INDEX,
  SHOW_USER_MODEL,
  SHOW_ASSET_PORTFOLIO,
  CHANGE_CHART_LAYOUT,
} from './constants';
import { CHART_LAYOUT } from '../../util/constant';

const initialSate = {
  isToggle: false,
  typeIndex: 1, // 1: chart, 2: list, 3: hide
  chartLayout: CHART_LAYOUT[0],
};

const reducer = function layoutReducer(state = initialSate, action) {
  switch (action.type) {
    case CHANGE_CHART_LAYOUT:
      return {
        ...state,
        chartLayout: action.payload,
      };
    case NAV_TOGGLE:
      return {
        ...state,
        isToggle: action.isToggle,
      };
    case TYPE_INDEX:
      return {
        ...state,
        typeIndex: action.typeIndex,
      };

    case SHOW_USER_MODEL:
      return {
        ...state,
        showUserModel: action.isShow,
      };

    case SHOW_ASSET_PORTFOLIO:
      return {
        ...state,
        showAssetPortfolioModal: action.isShow,
      };

    default:
      return state;
  }
};

export default reducer;
