import React, { useState } from 'react';

const ResendButton = ({ onClick, children, containerStyle }) => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const handleClick = () => {
    if (isButtonDisabled) {
      return;
    }

    onClick();
    setLoading(true);
    setIsButtonDisabled(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);

    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 10000);
  };
  return (
    <div
      className={
        typeof containerStyle === 'string'
          ? `${containerStyle} ${
              isButtonDisabled ? 'disabled' : 'cursor-pointer'
            }`
          : `resend-section ${isButtonDisabled ? 'disabled' : 'cursor-pointer'}`
      }
      onClick={handleClick}
      // disabled={isButtonDisabled}
    >
      <span
        className={`icon iResend mr-1 ${isLoading ? 'loading' : ''}`}
      ></span>
      {children}
    </div>
  );
};
export default ResendButton;
