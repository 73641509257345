import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { ReactReduxContext, useDispatch, useSelector } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import PDFViewer from '../../../../bond/components/Contract/pdfViewer';
import { v4 as uuidv4 } from 'uuid';

import PerfectScrollBar from 'react-perfect-scrollbar';
import { orderPF } from '../../../action';
import { handleApiErrors } from '../../../../../lib/api-errors';
import { setToast, unsetClient } from '../../../../client/actions';
import {
  Logout,
  logoutRequest,
} from '../../../../../components/modal/login/actions';
import { removeCookie } from '../../../../../lib/storages';
import { getMessageError, _formatDate2 } from '../../../../../util';

const appUrl = `${process.env.REACT_APP_API_URL}`;

const ChangePlanTicket = withNamespaces('translations')(
  ({
    t,
    close,
    handleConfirmUpdatePlan,
    contracts,
    setShowContract,
    viewOnly = false,
  }) => {
    const dispatch = useDispatch();
    const [check, setCheck] = useState();
    const theme = useSelector((state) => state.client.currentTheme);
    const { token, auth } = useSelector((state) => state.client);
    const lang = useSelector((state) => state.client?.setting?.lang || 'vi');
    const { defaultAccount } = useSelector((state) => state.socket);
    const { currentPinefolio, planModifyInfo } = useSelector(
      (state) => state.pinefolio
    );
    const { store } = useContext(ReactReduxContext);
    const [watched, setWatched] = useState([0]);
    const [currentContract, setCurrentContract] = useState(null); // first of list contracts for defaults
    const [showModal, setShowModal] = useState(true);

    const listAccounts = token?.custInfo.normal;
    const account = listAccounts?.find(
      (item) => item.subAcntNo.startsWith('P') && item.subAcntStat == 1
    );

    console.log('contracts ===> ', contracts);

    // Todos:
    // check icon green after click | default is gray
    // lighter icon while hover
    // disable confirm button if not check term and conditional

    const handleCancel = () => {
      setShowModal(false);
      setShowContract(false);
      close();
    };

    const toastHandle = (msg) => {
      const toastMsg = {
        id: Math.random(),
        msg: msg,
        title: t('txt-notice'),
      };
      dispatch(setToast(toastMsg));
    };

    const disableHandle = () => {
      if (!check) return true;
      return false;
    };

    const onOrderSuccess = () => {
      handleCancel();
    };

    useEffect(() => {
      if (contracts && contracts.length > 0) {
        setCurrentContract(contracts[0]);
      }
    }, [contracts]);

    return (
      <Modal
        show={showModal}
        dialogClassName="wts-modal"
        backdropClassName="wts-modal-backdrop pinefolio-order-confirm-backdrop"
        contentClassName={`${
          theme ? theme.name : 'dark'
        } pinefolio-order-confirm plan-modal pinefolio-change-plan-ticket wts-modal-content wts-modal-content--xl2`}
        onEscapeKeyDown={() => handleCancel()}
        centered
        onHide={() => handleCancel()}
        style={{ zIndex: 11120 }}
      >
        <div className="pinefolio-order-confirm__body">
          <div className="pinefolio-order-confirm__left">
            <div className="fz_20 fw-500 text--light text-center header">
              {t('pinefolio.order.changePlanTicket')}
            </div>
            <div className="pinefolio-order-confirm__orders h-100 mt-1 pt-2">
              <PerfectScrollBar style={{ maxHeight: '40vh' }}>
                {planModifyInfo &&
                  planModifyInfo.invtPlanList &&
                  planModifyInfo.invtPlanList.length > 0 &&
                  planModifyInfo.invtPlanList.map((contract, i) => (
                    <div className="fz_13 mb-1 pb-3">
                      <span className="d-flex flex-column">
                        {contract.packNo == 0 ? (
                          <span className="text--light">{t('txt-set')}</span>
                        ) : (
                          <span className="text--light">
                            {t('pinefolio.plan.pack')} {contract.packNo}
                          </span>
                        )}
                        <span className="d-flex">
                          <span className="text--light3 w-50">
                            {t('pinefolio.order.buyingOrderOldDate')}
                          </span>
                          <span className="text--light">
                            {_formatDate2(contract.endDt)}
                          </span>
                        </span>
                        <span className="d-flex mb-3 mt-2 pt-1">
                          <span className="text--light3 w-50">
                            {t('pinefolio.order.buyingOrderNewDate')}
                          </span>
                          <span className="text--light">
                            {_formatDate2(contract.afEndDt)}
                          </span>
                        </span>
                      </span>
                    </div>
                  ))}
                <div style={{ height: '15px' }}></div>
              </PerfectScrollBar>
            </div>
            <div className="d-flex pinefolio-actions">
              {!viewOnly && (
                <div
                  className="btn btn--buy  pinefolio-action w-100"
                  onClick={() => handleConfirmUpdatePlan()}
                >
                  {t('txt-confirm')}
                </div>
              )}
              <div
                className="btn btn--cancel pinefolio-action w-100"
                onClick={() => handleCancel()}
              >
                {t('account.authen.back')}
              </div>
            </div>
          </div>
          <div className="pinefolio-order-confirm__right">
            {currentContract && <PDFViewer src={currentContract.fileUrl} />}
          </div>
        </div>
      </Modal>
    );
  }
);

export default ChangePlanTicket;
