import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import ReactSelect from 'react-select';
import PerfectScrollBar from 'react-perfect-scrollbar';
import FormDeleteOrder from '../../../../components/modal/order/deleteOrder';
import moment from 'moment';
import DatePicker, { registerLocale } from 'react-datepicker';
import vi from 'date-fns/locale/vi';
import uk from 'date-fns/locale/uk';
import { Portal } from 'react-overlays';
import { filter, find, map, sum, sumBy } from 'lodash';

import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { withNamespaces, translate } from 'react-i18next';
import PaginationTable from '../../../../components/PaginationTable';
import { debounce } from 'lodash';
import ScrollTable from '../../../../components/ScrollTable';
import ScrollOrderList from './scrollOrderList';
import { useHistory } from 'react-router';
import {
  canDeleteOrder,
  canDeletePinefolioOrder,
  mapAplcSrcTp,
  mapPfOrderStat,
  numberFormat,
  ORDER_TYPES,
  _formatDate,
  _formatDate2,
  _getOrderStatus,
} from '../../../../util';
import { setAuth } from '../../../client/actions';
import { stockDetailRequest } from '../../../../components/modal/stockDetail/actions';
import {
  getPinefilioOrderDtlRequest,
  getPinefilioOrderDtlStRequest,
  getPinefilioOrderListRequest,
} from '../../action';
import DeletePineOrder from '../../../../components/modal/order/deletePineOrder';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

registerLocale('vi', vi);
registerLocale('en', uk);
const CalendarContainer = ({ children }) => {
  const el = document.getElementById('calendar-portal');

  return <Portal container={el}>{children}</Portal>;
};

const OrderList = withNamespaces('translations')(({ t }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { token, auth, currentTheme } = useSelector((state) => state.client);
  const { defaultAccount } = useSelector((state) => state.socket);
  const matchOrder = useSelector((state) => state.privData.matchOrder?.data);
  const [order, setOrder] = useState();
  const [showModalDel, setShowModalDel] = useState();
  const numDay = token && token['config'] ? token['config']['numDay'] : 30;
  const d = new Date();
  d.setDate(d.getDate() - numDay);

  const [srchText, setSrchText] = useState('');
  const [orderType, setOrderType] = useState(ORDER_TYPES[0]);
  const [from, setFrom] = useState(d);
  const [to, setTo] = useState(new Date());
  const setting = useSelector((state) => state.client.setting);
  const lang = setting.lang || 'vi';

  const [refresh, setRefresh] = useState(false);

  const pinefolioOrderList = useSelector(
    (state) => state.pinefolio.pinefolioOrderList
  );

  const pinefolioOrderDtl = useSelector(
    (state) => state.pinefolio.pinefolioOrderDtl
  );

  const pinefolioOrderDtlSt = useSelector(
    (state) => state.pinefolio.pinefolioOrderDtlSt
  );

  const getPinefolioOrderList = () => {
    if (!token) return;
    const _fromDate = _formatDate(moment(from).format('DD/MM/YYYY'));
    const _toDate = _formatDate(moment(to).format('DD/MM/YYYY'));
    const uuid = uuidv4();
    const params = {
      group: 'CORE',
      user: token.user,
      session: token.session,
      cmd: 'getPFOrderList',
      rqId: uuid,
      channel: 'WTS',
      data: {
        cif: token.cif,
        acntNo: defaultAccount?.acntNo,
        subAcntNo: defaultAccount?.subAcntNo,
        pfCode: '',
        prchsSaleTp: orderType.id == 0 ? '' : orderType.id,
        trdStdt: _fromDate,
        trdEndt: _toDate,
        srchText: srchText,
        pfAplcProcStat: '',
        aplcNo: '',
      },
    };

    dispatch(getPinefilioOrderListRequest(params));
  };

  const handleRefresh = () => {
    setOrderType(ORDER_TYPES[0]);
    setFrom(d);
    setTo(new Date());
    setSrchText('');
    setRefresh(!refresh);
  };

  const _handleAuthen = () => {
    dispatch(setAuth());
  };

  useEffect(() => {
    getPinefolioOrderList();
  }, []);

  useEffect(() => {
    getPinefolioOrderList();
  }, [refresh, defaultAccount]);

  useEffect(() => {
    if (!token) {
      history.push('/');
    }
  }, [token]);

  const _handleOrderDetail = (record) => {
    console.log('record', record);
    if (!record || !record.pfCode) return;
    if (defaultAccount) {
      const uuid = uuidv4();

      const resData = {
        group: 'CORE',
        cmd: 'getPFOrderDtl',
        user: token.user,
        session: token.session,
        rqId: uuid,
        channel: 'WTS',
        data: {
          cif: token.cif,
          acntNo: defaultAccount?.acntNo,
          subAcntNo: defaultAccount?.subAcntNo,
          pfCode: record.pfCode,
          aplcDate: record.aplcDate,
          aplcNo: record.aplcNo?.toString(),
        },
      };
      dispatch(getPinefilioOrderDtlRequest(resData));
    }
  };

  const _handleGetPFOrderDtlSt = (record) => {
    console.log('record', record);
    if (!record || !record.pfCode) return;
    if (defaultAccount) {
      const uuid = uuidv4();

      const resData = {
        group: 'CORE',
        cmd: 'getPFOrderDtlSt',
        user: token.user,
        session: token.session,
        rqId: uuid,
        channel: 'WTS',
        data: {
          cif: token.cif,
          acntNo: defaultAccount?.acntNo,
          subAcntNo: defaultAccount?.subAcntNo,
          pfCode: record.pfCode,
          aplcDate: record.aplcDate,
          pfOrderDate: record.pfOrderDate,
          pfOrderId: record.pfOrderID,
          aplcNo: record.aplcNo?.toString(),
        },
      };
      dispatch(getPinefilioOrderDtlStRequest(resData));
    }
  };

  const _handleDeleteOrder = (aplcNo) => {
    const _order = find(pinefolioOrderList, (o) => o.aplcNo === aplcNo);
    if (_order) {
      setShowModalDel(true);
      setOrder(_order);
    }
  };

  const _handleCloseModalDel = () => {
    setShowModalDel(false);
    setOrder('');
  };

  const handleConfirmDel = () => {
    setShowModalDel(false);
    setOrder('');
    dispatch(getPinefolioOrderList());
  };

  const handleChangeSearchText = (value) => {
    setSrchText(value);
  };

  const columns = [
    {
      key: 'aplcNo',
      text: t('bond.tableLabel.orderID'),
      className: 'text-center position-relative text--light3 fw-500',
    },
    {
      key: 'pfCode',
      text: t('pinefolio.plan.portfolioCode'),
      className: 'text-center position-relative fw-500 text--light',
    },
    {
      key: 'pfNm',
      text: t('pinefolio.orderList.name'),
      className: 'text-left position-relative text--light3 fw-400',
      align: 'left',
    },
    {
      key: 'prchsSaleTp',
      text: t('bond.order.type'),
      className: (record) => {
        return `text-center ${
          record.prchsSaleTp == '1'
            ? 'i'
            : record.prchsSaleTp == '2'
            ? 'd'
            : 'r'
        }`;
      },
      cell: (record) => {
        return record.prchsSaleTp == '1'
          ? t('txt-buy')
          : record.prchsSaleTp == '2'
          ? t('txt-sell')
          : t('pinefolio.rebalance.name');
      },
      sortable: true,
    },
    {
      key: 'trdDate',
      text: t('txt-label-trade-date'),
      className: 'text-center position-relative text--light3 fw-400',
      cell: (record) => {
        return _formatDate2(record.trdDate);
      },
      sortable: true,
    },
    {
      key: 'pfOrdrEndt',
      text: (
        <span className="d-flex justify-content-center w-100">
          <span>{t('pinefolio.orderList.expireDate')}</span>
          <OverlayTrigger
            trigger={['hover', 'focus']}
            placement="top"
            className="portfolio-overlay"
            overlay={
              <Tooltip style={{ 'z-index': '99999' }}>
                {t('pinefolio.orderList.expireDateExplain')}
              </Tooltip>
            }
          >
            <span className="icon iInfor iTooltip ml-1 mr-1"></span>
          </OverlayTrigger>
        </span>
      ),
      className: 'text-center position-relative text--light3 fw-400',
      cell: (record) => {
        return _formatDate2(record.pfOrdrEndt);
      },
      sortable: true,
    },
    {
      key: 'aplcAmt',
      text: (
        <span className="d-flex justify-content-end w-100">
          <span>{t('pinefolio.orderList.orderAmp.name')}</span>
          <OverlayTrigger
            trigger={['hover', 'focus']}
            placement="top"
            className="portfolio-overlay"
            overlay={
              <Tooltip style={{ 'z-index': '99999' }}>
                {t('pinefolio.orderList.orderAmp.explain')}
              </Tooltip>
            }
          >
            <span className="icon iInfor iTooltip ml-1 mr-1"></span>
          </OverlayTrigger>
        </span>
      ),
      className: 'text-right position-relative text--light fw-500',
      align: 'right',
      cell: (record) => {
        return numberFormat(record.aplcAmt, 0, '0');
      },
      sortable: true,
    },
    {
      key: 'aplcQtySum',
      text: t('pinefolio.orderList.quantity'),
      className: 'text-right position-relative text--light fw-400',
      align: 'right',
      cell: (record) => {
        return numberFormat(record.aplcQtySum, 0, '0');
      },
      sortable: true,
    },
    {
      key: 'cntrSetQty',
      text: (
        <span className="d-flex justify-content-end w-100">
          <span>{t('pinefolio.orderList.matched.name')}</span>
          <OverlayTrigger
            trigger={['hover', 'focus']}
            placement="top"
            className="portfolio-overlay"
            overlay={
              <Tooltip style={{ 'z-index': '99999' }}>
                {t('pinefolio.orderList.matched.desc')}
              </Tooltip>
            }
          >
            <span className="icon iInfor iTooltip ml-1 mr-1"></span>
          </OverlayTrigger>
        </span>
      ),
      className: 'text-right position-relative text--light fw-500',
      align: 'right',
      cell: (record) => {
        return numberFormat(record.cntrSetQty, 0, '0');
      },
      sortable: true,
    },
    {
      key: 'tax',
      text: t('txt-label-tax'),
      className: 'text-right position-relative text--light3 fw-500',
      align: 'right',
      cell: (record) => {
        return numberFormat(record.tax, 0, '0');
      },
      sortable: true,
    },
    {
      key: 'fee',
      text: t('txt-label-fee'),
      className: 'text-right position-relative text--light3 fw-500',
      align: 'right',
      cell: (record) => {
        return numberFormat(record.cntrFeeSum, 0, '0');
      },
    },
    {
      key: 'cntrValSum',
      text: (
        <span className="d-flex justify-content-end w-100">
          <span>{t('pinefolio.orderList.matchValue.name')}</span>
          <OverlayTrigger
            trigger={['hover', 'focus']}
            placement="top"
            className="portfolio-overlay"
            overlay={
              <Tooltip style={{ 'z-index': '99999' }}>
                {t('pinefolio.orderList.matchValue.explain')}
              </Tooltip>
            }
          >
            <span className="icon iInfor iTooltip ml-1 mr-1"></span>
          </OverlayTrigger>
        </span>
      ),
      className: 'text-right position-relative text--light fw-500',
      align: 'right',
      cell: (record) => {
        return numberFormat(record.cntrValSum, 0, '0');
      },
    },
    {
      key: 'pfOrderStat',
      text: t('txt-status'),
      className: (record) => {
        return 'text-right ordStt_' + record.pfOrderStat;
      },
      align: 'right',
      cell: (record) => {
        return t(mapPfOrderStat(record.pfOrderStat)?.label);
      },
    },
    {
      key: 'aplcSrcTp',
      text: (
        <span className="d-flex justify-content-center w-100">
          <span>{t('pinefolio.orderList.investType')}</span>
          <OverlayTrigger
            trigger={['hover', 'focus']}
            placement="top"
            className="portfolio-overlay"
            overlay={
              <Tooltip style={{ 'z-index': '99999' }}>
                {t('pinefolio.orderList.investTypeExplain')}
              </Tooltip>
            }
          >
            <span className="icon iInfor iTooltip ml-1 mr-1"></span>
          </OverlayTrigger>
        </span>
      ),
      className: 'text-center position-relative fw-500 text--light-blue3',
      cell: (record) => {
        return t(mapAplcSrcTp(record.aplcSrcTp)?.label);
      },
    },
    {
      key: 'action',
      text: t('txt-actions'),
      cell: (record) => {
        return auth && auth.isVerified ? (
          <div className="d-flex align-items-center justify-content-center">
            {canDeletePinefolioOrder(record.pfOrderStat) ? (
              <span
                className="d-flex align-items-center justify-content-center btn btn--delete ml-1"
                onClick={() => _handleDeleteOrder(record.aplcNo)}
              >
                <span className="icon iCancel text-muted d "></span>
              </span>
            ) : (
              <span className="d-flex align-items-center justify-content-center btn btn--delete disabled ml-1">
                <span className="icon iCancel text-muted d "></span>
              </span>
            )}
          </div>
        ) : (
          <div className="d-flex align-items-center justify-content-center">
            {canDeletePinefolioOrder(record.pfOrderStat) ? (
              <span
                className="d-flex align-items-center justify-content-center  btn btn--authen ml-1"
                onClick={() => _handleAuthen()}
              >
                <span className="icon iCancel text-muted"></span>
              </span>
            ) : (
              <span className="d-flex align-items-center justify-content-center  btn btn--delete disabled ml-1">
                <span className="icon iCancel text-muted"></span>
              </span>
            )}
          </div>
        );
      },
    },
  ];

  const colgroup = (
    <colgroup>
      <col width="7.5%"></col> {/* Order ID */}
      <col width="6.0%"></col> {/* total account*/}
      <col width="7.5%"></col> {/* deal id*/}
      <col width="8.0%"></col> {/* Product code*/}
      <col width="13.5%"></col> {/* Bond Name/Bond Code */}
      <col width="4.5%"></col> {/* Bond Type */}
      <col width="6%"></col> {/* Trade date */}
      <col width="6%"></col> {/* Maturity date */}
      <col width="3.5%"></col> {/* Rate */}
      <col width="6%"></col> {/* Quantity */}
      <col width="4%"></col> {/* Order */}
      <col width="5%"></col> {/* Price */}
      <col width="7%"></col> {/* Total value */}
    </colgroup>
  );

  return (
    <div className="pinefolio-order-list">
      <div className="layout-page__body layout__body">
        <div className="layout__filters">
          <div className="layout__filter">
            <label>{t('txt-search')}</label>
            <div className="input-text-search d-flex align-items-center mr-1">
              <input
                value={srchText}
                placeholder="Symbol"
                onChange={(e) => handleChangeSearchText(e.target.value)}
              />
              <span className="icon iSearch"></span>
            </div>
          </div>
          <div className="layout__filter">
            <label>{t('txt-order-type')}</label>
            <ReactSelect
              defaultValue={orderType}
              className="filter-control-select"
              classNamePrefix="filter-control-select"
              options={ORDER_TYPES}
              getOptionLabel={(option) => t(option.label)}
              getOptionValue={(option) => option.id}
              value={orderType}
              onChange={(value) => setOrderType(value)}
              isSearchable={false}
              components={{
                IndicatorSeparator: () => null,
              }}
            />
          </div>
          <div className="layout__filter">
            <label>{t('pinefolio.orderList.tradeDateFrom')}</label>
            <div className="filter-control-calendar">
              <DatePicker
                name="from"
                selected={from}
                onChange={(date) => setFrom(date)}
                dateFormat="dd/MM/yyyy"
                popperContainer={CalendarContainer}
                locale={lang}
              />
              <span className="icon iCalendar"></span>
            </div>
          </div>
          <div className="layout__filter">
            <label>{t('pinefolio.orderList.tradeDateTo')}</label>
            <div className="filter-control-calendar">
              <DatePicker
                name="to"
                selected={to}
                onChange={(date) => setTo(date)}
                dateFormat="dd/MM/yyyy"
                popperContainer={CalendarContainer}
                locale={lang}
              />
              <span className="icon iCalendar"></span>
            </div>
          </div>
          <div className="layout__filter button-group">
            <div onClick={getPinefolioOrderList} className="iBtn iBtn--primary">
              <span>{t('bond.orderList.label.filter')}</span>
            </div>
            <span
              onClick={handleRefresh}
              className="iBtn reload-button iBtn--secondary"
            >
              <span className="icon iRefresh"></span>
            </span>
          </div>
        </div>

        <PerfectScrollBar style={{ height: 'calc(100% - 12px)' }}>
          <ScrollOrderList
            colgroup={colgroup}
            columns={columns}
            source={pinefolioOrderList}
            pinefolioOrderDtlSt={pinefolioOrderDtlSt}
            tblClassName="tbl-list"
            divClassName="mt-3"
            onRowClicked={_handleOrderDetail}
            _handleGetPFOrderDtlSt={_handleGetPFOrderDtlSt}
            pageSize={15}
            ordSel={pinefolioOrderDtl}
            auth={auth}
            detail={matchOrder}
            handleDeleteOrder={_handleDeleteOrder}
            handleAuthen={_handleAuthen}
            expandColSpan={14}
          />
        </PerfectScrollBar>
        {showModalDel && (
          <DeletePineOrder
            themes={currentTheme}
            token={token}
            auth={auth}
            account={defaultAccount}
            order={order}
            showModal={showModalDel}
            handleCloseModal={_handleCloseModalDel}
            handleConfirmDel={handleConfirmDel}
          />
        )}
      </div>
    </div>
  );
});

export default translate('translations')(OrderList);
