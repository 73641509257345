import { setCategory, setSetting } from '../containers/client/actions';
import Cookies from 'universal-cookie';
import { useSelector } from 'react-redux';
const cookies = new Cookies();

export const categoryDefault = [
  {
    id: 'mac-dinh',
    name: 'Mặc định',
    path: '/home/bang-gia/mac-dinh',
    value: ['BID', 'CTG', 'TCB', 'SSI', 'MSN'],
    pinnedRow: [],
    type: 'watchlist',
  },
  {
    name: 'HSX',
    path: '/home/bang-gia/hsx',
    groupName: 'HSX',
    type: 'group',
    value: [],
    pinnedRow: [],
  },
  {
    name: 'VN30',
    path: '/home/bang-gia/vn30',
    groupName: 'HSX',
    type: 'group',
    value: [],
    pinnedRow: [],
  },
  {
    name: 'txt-c',
    path: '/home/bang-gia/thoa-thuan-hsx',
    groupName: 'HSX',
    type: 'group',
    value: [],
    pinnedRow: [],
  },
  {
    name: 'HNX',
    path: '/home/bang-gia/hnx',
    groupName: 'HNX',
    type: 'group',
    value: [],
    pinnedRow: [],
  },
  {
    name: 'HNX30',
    path: '/home/bang-gia/hnx30',
    groupName: 'HNX',
    type: 'group',
    value: [],
    pinnedRow: [],
  },
  {
    name: 'txt-c',
    path: '/home/bang-gia/thoa-thuan-hnx',
    groupName: 'HNX',
    type: 'group',
    value: [],
    pinnedRow: [],
  },
  // {
  //   name: 'txt-post-close',
  //   path: '/home/bang-gia/post-close-hnx',
  //   groupName: 'HNX',
  //   type: 'group',
  //   value: [],
  //   pinnedRow: [],
  // },
  {
    name: 'UPCOM',
    path: '/home/bang-gia/upcom',
    groupName: 'UPCOM',
    type: 'group',
    value: [],
    pinnedRow: [],
  },
  {
    name: 'txt-c',
    path: '/home/bang-gia/thoa-thuan-upcom',
    groupName: 'UPCOM',
    type: 'group',
    value: [],
    pinnedRow: [],
  },
  {
    name: 'Chứng quyền',
    path: '/home/bang-gia/chung-quyen',
    groupName: 'CHUNGQUYEN',
    type: 'group',
    value: [],
    pinnedRow: [],
  },
  {
    id: 'odd-lot-hsx',
    name: 'txt-odd-lot-hsx',
    path: '/home/bang-gia/lo-le-hsx',
    groupName: 'OddLot',
    type: 'group',
    value: [],
    pinnedRow: [],
  },
  {
    id: 'odd-lot-hnx',
    name: 'txt-odd-lot-hnx',
    path: '/home/bang-gia/lo-le-hnx',
    groupName: 'OddLot',
    type: 'group',
    value: [],
    pinnedRow: [],
  },
  // {
  //   id: 'odd-lot-upcom',
  //   name: 'txt-odd-lot-upcom',
  //   path: '/home/bang-gia/lo-le-upcom',
  //   groupName: 'OddLot',
  //   type: 'group',
  //   value: [],
  //   pinnedRow: [],
  // },
  // {
  //   id: 'buy-in-hsx',
  //   name: 'txt-buy-in-hsx',
  //   path: '/home/bang-gia/buy-in-hsx',
  //   groupName: 'Buyin',
  //   type: 'group',
  //   value: [],
  //   pinnedRow: [],
  // },
  // {
  //   id: 'buy-in-hnx',
  //   name: 'txt-buy-in-hnx',
  //   path: '/home/bang-gia/buy-in-hnx',
  //   groupName: 'Buyin',
  //   type: 'group',
  //   value: [],
  //   pinnedRow: [],
  // },
  // {
  //   id: 'buy-in-upcom',
  //   name: 'txt-buy-in-upcom',
  //   path: '/home/bang-gia/buy-in-upcom',
  //   groupName: 'Buyin',
  //   type: 'group',
  //   value: [],
  //   pinnedRow: [],
  // },
];

const setting = {
  typeIndex: 1,
  symbolMinimize: [],
};

const cookieOptions = {
  // httpOnly: true,
  secure: true,
  // sameSite: true,
};

export function getCategory({ dispatch }) {
  const storedCategory = loadState('category');

  if (storedCategory) {
    dispatch(setCategory(storedCategory));
  } else {
    // chưa có category
    dispatch(setCategory(categoryDefault));
    saveState('category', categoryDefault);
  }
  return true;
}

export const getSetting = ({ dispatch }) => {
  const storedSetting = loadState('setting');

  if (storedSetting) {
    dispatch(setSetting(storedSetting));
  } else {
    // chưa có category
    dispatch(setSetting(setting));
    saveState('setting', setting);
  }

  return true;
};

export const loadState = (key) => {
  try {
    let serializedState = null;
    if (key === 'category' || key === 'setting')
      serializedState = localStorage.getItem(key)
        ? JSON.parse(localStorage.getItem(key))
        : null;
    else {
      serializedState = getCookie(key);
      // Add industry
    }

    if (serializedState === null) {
      return undefined;
    }
    return serializedState;
  } catch (err) {
    return undefined;
  }
};

export const saveState = (key, state) => {
  try {
    const serializedState = JSON.stringify(state);
    if (key === 'category' || key === 'setting')
      localStorage.setItem(key, serializedState);
    else setCookie(key, serializedState);
  } catch (err) {
    // ...
    console.error('Set cookie errror', err);
  }
};

export const getCookie = (key) => {
  return cookies.get(key);
};

export const setCookie = (key, value) => {
  return cookies.set(key, value);
};

export const removeCookie = (key) => {
  return cookies.remove(key);
};
