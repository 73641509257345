export const SUMMARY_ORD_REQUESTING = 'SUMMARY_ORD_REQUESTING';
export const SUMMARY_ORD_REQUEST_SUCCESS = 'SUMMARY_ORD_REQUEST_SUCCESS';
export const SUMMARY_ORD_REQUEST_ERROR = 'SUMMARY_ORD_REQUEST_ERROR';

export const SUMMARY_ORD_COND_REQUESTING = 'SUMMARY_ORD_COND_REQUESTING';
export const SUMMARY_ORD_COND_REQUEST_SUCCESS =
  'SUMMARY_ORD_COND_REQUEST_SUCCESS';
export const SUMMARY_ORD_COND_REQUEST_ERROR = 'SUMMARY_ORD_COND_REQUEST_ERROR';

export const SUMMARY_CASH_REQUESTING = 'SUMMARY_CASH_REQUESTING';
export const SUMMARY_CASH_REQUEST_SUCCESS = 'SUMMARY_CASH_REQUEST_SUCCESS';
export const SUMMARY_CASH_REQUEST_ERROR = 'SUMMARY_CASH_REQUEST_ERROR';

export const SUMMARY_STOCK_REQUESTING = 'SUMMARY_STOCK_REQUESTING';
export const SUMMARY_STOCK_REQUEST_SUCCESS = 'SUMMARY_STOCK_REQUEST_SUCCESS';
export const SUMMARY_STOCK_REQUEST_ERROR = 'SUMMARY_STOCK_REQUEST_ERROR';

export const SUMMARY_EVENT_REQUESTING = 'SUMMARY_EVENT_REQUESTING';
export const SUMMARY_EVENT_REQUEST_SUCCESS = 'SUMMARY_EVENT_REQUEST_SUCCESS';
export const SUMMARY_EVENT_REQUEST_ERROR = 'SUMMARY_EVENT_REQUEST_ERROR';

export const SUMMARY_RIGHT_REQUESTING = 'SUMMARY_RIGHT_REQUESTING';
export const SUMMARY_RIGHT_REQUEST_SUCCESS = 'SUMMARY_RIGHT_REQUEST_SUCCESS';
export const SUMMARY_RIGHT_REQUEST_ERROR = 'SUMMARY_RIGHT_REQUEST_ERROR';

export const SUMMARY_NOTIFICATION_REQUESTING =
  'SUMMARY_NOTIFICATION_REQUESTING';
export const SUMMARY_NOTIFICATION_REQUEST_SUCCESS =
  'SUMMARY_NOTIFICATION_REQUEST_SUCCESS';
export const SUMMARY_NOTIFICATION_REQUEST_ERROR =
  'SUMMARY_NOTIFICATION_REQUEST_ERROR';
export const NOTIFICATION_UPDATE_REQUEST = 'NOTIFICATION_UPDATE_REQUEST';
export const NOTIFICATION_UPDATE_REQUEST_SUCCESS =
  'NOTIFICATION_UPDATE_REQUEST_SUCCESS';
