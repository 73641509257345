import React, { useEffect } from 'react';
import { useState } from 'react';
import { withNamespaces } from 'react-i18next';
import { ReactReduxContext, useDispatch, useSelector } from 'react-redux';
import SwitchCustom from '../../../../components/common/switch';
import PaginationTable from '../../../../components/PaginationTable';
import {
  getMessageError,
  numberFormat,
  StringToDouble,
} from '../../../../util';
import {
  setAuth,
  setDblPrice,
  setToast,
  unsetClient,
} from '../../../client/actions';
import { v4 as uuidv4 } from 'uuid';
import { useContext } from 'react';
import {
  Logout,
  logoutRequest,
} from '../../../../components/modal/login/actions';
import { removeCookie } from '../../../../lib/storages';
import { handleApiErrors } from '../../../../lib/api-errors';
import { getPosOutsidePf, reloadPosOutSidePF } from '../../action';
import { MixConstants, Mixpanel } from '../../../../lib/mixpanel';

const appUrl = `${process.env.REACT_APP_API_URL}`;
const StockOutSection = withNamespaces('translations')(({ t }) => {
  const dispatch = useDispatch();
  const [positions, setPositions] = useState([]);
  const { defaultAccount, listAccount } = useSelector((state) => state.socket);
  const { store } = useContext(ReactReduxContext);
  const { token, auth } = useSelector((state) => state.client);
  const { currentPinefolio, positionOutSidePF, positionOutSidePFReload } =
    useSelector((state) => state.pinefolio);
  const theme = useSelector((state) => state.client?.currentTheme);

  useEffect(() => {
    if (defaultAccount) getPFPostOut();
  }, [defaultAccount]);

  useEffect(() => {
    if (positionOutSidePFReload) {
      getPFPostOut();
      dispatch(reloadPosOutSidePF(false));
    }
  }, [positionOutSidePFReload]);

  const handleQuickOrder = (record) => {
    const newOrder = {
      symbol: record.symbol,
      side: 'SELL',
      price: '',
    };

    Mixpanel.order({
      Ticker: record.symbol,
      'Order Side': 'SELL',
      'Order Type': 'Normal Order',
      'Ticker Type': 'PF',
      Location: MixConstants.LOCATIONS.PINEFOLIO,
    });

    dispatch(setDblPrice(newOrder));
  };

  const toastHandle = (msg) => {
    const toastMsg = {
      id: Math.random(),
      msg: msg,
      title: t('txt-notice'),
    };
    dispatch(setToast(toastMsg));
  };

  const getPFPostOut = () => {
    if (!token) return;
    if (!defaultAccount?.subAcntNo.startsWith('P')) return;
    const uuid = uuidv4();
    const params = {
      group: 'CORE',
      session: token.session,
      user: token.user,
      cmd: 'getPFPosOut',
      rqId: uuid,
      channel: 'WTS',
      data: {
        acntNo: defaultAccount?.acntNo,
        subAcntNo: defaultAccount?.subAcntNo,
      },
    };

    dispatch(getPosOutsidePf(params));
  };

  const sellHandle = (record) => {
    if (record.seTp == 2) {
      toastHandle(t('pinefolio.stockOutsideSellNotice'));
      return;
    }
    const newOrder = {
      symbol: record.symbol,
      side: 'SELL',
      price: '',
      successCallback: getPFPostOut,
    };

    Mixpanel.order({
      Ticker: record.symbol,
      'Order Side': 'SELL',
      'Order Type': 'Normal Order',
      'Ticker Type': 'PF',
      Location: MixConstants.LOCATIONS.PINEFOLIO,
    });

    dispatch(setDblPrice(newOrder));
  };

  const headerColumn = [
    {
      key: 'symbol',
      text: t('txt-symbol'),
      className: (record) => {
        return (
          'text-center fw-500 cursor-pointer ' +
          (record.gainLoss > 0 ? 'i' : record.gainLoss < 0 ? 'd' : 'r')
        );
      },
      cell: (record) => {
        return (
          <>
            <span
              onDoubleClick={() => {
                sellHandle(record);
              }}
            >
              {record.symbol}
            </span>
          </>
        );
      },
      rowSpan: '2',
      sortable: true,
    },
    {
      key: 'balQty',
      text: t('txt-total-vol'),
      className: 'text-right text--light',
      cell: (record) => {
        return numberFormat(record.balQty, 0, '0');
      },
      rowSpan: '2',
      sortable: true,
    },
    {
      key: 'trdAvailQty',
      text: t('txt-label-avail'),
      className: 'text-right text--light',
      cell: (record) => {
        return numberFormat(record.trdAvailQty, 0, '0');
      },
      rowSpan: '2',
      sortable: true,
    },
    {
      key: 'devidendQty',
      text: t('txt-total-dividend-vol'),
      className: 'text-right position-relative text--light',
      cell: (record) => {
        return numberFormat(record.devidendQty, 0, '0');
      },
      rowSpan: '2',
      sortable: true,
    },
    {
      key: 'sellTn',
      text: t('txt-label-pending-delivery'),
      className: 'text-right position-relative text--light',
      cell: (record) => {
        return numberFormat(record.sellTn, 0, '0');
      },
      rowSpan: '2',
      sortable: true,
    },
    {
      key: 'waitTrdQty',
      text: t('txt-label-pending-trading'),
      className: 'text-right position-relative text--light',
      cell: (record) => {
        return numberFormat(record.waitTrdQty, 0, '0');
      },
      rowSpan: '2',
      sortable: true,
    },
    {
      key: 'buyT2',
      text: `${t('txt-buy')} T2`,
      className: 'text-right position-relative text--light',
      cell: (record) => {
        return numberFormat(record.buyT2, 0, '0');
      },
      rowSpan: '2',
      sortable: true,
    },
    {
      key: 'buyT1',
      text: `${t('txt-buy')} T1`,
      className: 'text-right position-relative text--light',
      cell: (record) => {
        return numberFormat(record.buyT1, 0, '0');
      },
      rowSpan: '2',
      sortable: true,
    },
    {
      key: 'buyT0',
      text: `${t('txt-buy')} T0`,
      className: 'text-right position-relative text--light',
      cell: (record) => {
        return numberFormat(record.buyT0, 0, '0');
      },
      rowSpan: '2',
      sortable: true,
    },
    {
      key: 'avgPrice',
      text: t('txt-ave'),
      className: 'text-right position-relative text--light fw-500',
      cell: (record) => {
        return numberFormat(record.avgPrice, 3, '0');
      },
      rowSpan: '2',
      sortable: true,
    },
    {
      key: 'lastPrice',
      text: t('txt-market-price'),
      className: 'text-right position-relative text--light fw-500 ',
      cell: (record) => {
        return numberFormat(record.lastPrice, 2, '0');
      },
      rowSpan: '2',
      sortable: true,
    },
    {
      key: 'change',
      text: t('txt-change-in-day'),
      className: (record) => {
        return (
          'text-right ' +
          (StringToDouble(record.change) > 0
            ? 'i'
            : StringToDouble(record.change) < 0
            ? 'd'
            : 'r')
        );
      },
      cell: (record) => {
        return numberFormat(record.change / 1000, 2, '0');
      },
      sortable: true,
      rowSpan: 2,
    },

    {
      key: 'changePC',
      text: `% ${t('txt-change-in-day')}`,
      className: (record) => {
        return (
          'text-right fw-500 ' +
          (StringToDouble(record.changePC) > 0
            ? 'i'
            : StringToDouble(record.changePC) < 0
            ? 'd'
            : 'r')
        );
      },
      cell: (record) => {
        return `${numberFormat(record.changePC, 2, '0')}%`;
      },
      sortable: true,
      rowSpan: 2,
    },
    {
      key: 'totBuyAmt',
      text: t('txt-label-buy-amt'),
      className: 'text-right text--light fw-500 ',
      cell: (record) => {
        return numberFormat(record.totBuyAmt, 0, '0');
      },
      sortable: true,
    },
    {
      key: 'totCurAmt',
      text: t('txt-label-market-value'),
      className: 'text-right text--light fw-500 ',
      cell: (record) => {
        return numberFormat(record.totCurAmt, 0, '0');
      },
      sortable: true,
    },
    {
      key: 'gainLoss',
      text: t('txt-gain-loss'),
      className: (record) => {
        return (
          'text-right fw-500 ' +
          (record.gainLoss > 0 ? 'i' : record.gainLoss < 0 ? 'd' : 'r')
        );
      },
      cell: (record) => {
        return numberFormat(record.gainLoss, 0, '0');
      },
      sortable: true,
    },
    {
      key: 'gainLossPc',
      text: `% ${t('txt-gain-loss')}`,
      className: (record) => {
        return (
          'text-right fw-500 ' +
          (record.gainLoss > 0 ? 'i' : record.gainLoss < 0 ? 'd' : 'r')
        );
      },
      cell: (record) => {
        return numberFormat(record.gainLossPc, 2, '-') + '%';
      },
      sortable: true,
    },
    {
      cell: (record) => {
        if (!token || !auth.isVerified) {
          return (
            <button
              className="btn btn--authen justify-content-center w-100"
              onClick={() => dispatch(setAuth())}
            >
              {t('txt-sell')}
            </button>
          );
        } else {
          return (
            <button
              className="btn btn--sell2 w-100"
              onClick={() => sellHandle(record)}
            >
              {t('txt-sell')}
            </button>
          );
        }
      },
      sortable: false,
    },
  ];

  const totalColumns = [
    {
      key: 'totCurAmt',
      className: 'text-right fw-500',
      cell: ({ hideOddSymbol, _totalShow, _totalOddLot }) => {
        return numberFormat(
          !hideOddSymbol
            ? _totalShow.totBuyAmt
            : _totalShow.totBuyAmt - _totalOddLot.totBuyAmt,
          0,
          '0'
        );
      },
      rowSpan: '1',
    },
    {
      key: 'totBuyAmt',
      className: 'text-right fw-500',
      cell: ({ hideOddSymbol, _totalShow, _totalOddLot }) => {
        return numberFormat(
          !hideOddSymbol
            ? _totalShow.totCurAmt
            : _totalShow.totCurAmt - _totalOddLot.totCurAmt,
          0,
          '0'
        );
      },
      rowSpan: '1',
    },
    {
      key: 'gainLoss',
      className: ({ hideOddSymbol, _totalShow, _totalOddLot }) => {
        return (
          'text-right high-light fw-500 ' +
          ((!hideOddSymbol
            ? _totalShow.gainLoss
            : _totalShow.gainLoss -
              (_totalOddLot.totCurAmt - _totalOddLot.totBuyAmt)) > 0
            ? 'i'
            : (!hideOddSymbol
                ? _totalShow.gainLoss
                : _totalShow.gainLoss -
                  (_totalOddLot.totCurAmt - _totalOddLot.totBuyAmt)) < 0
            ? 'd'
            : 'r')
        );
      },
      cell: ({ hideOddSymbol, _totalShow, _totalOddLot }) => {
        return numberFormat(
          !hideOddSymbol
            ? _totalShow.gainLoss
            : _totalShow.gainLoss -
                (_totalOddLot.totCurAmt - _totalOddLot.totBuyAmt),
          0,
          '0'
        );
      },
      rowSpan: '1',
    },
    {
      key: 'gainLossPc',
      className: ({ hideOddSymbol, _totalShow, _totalOddLot }) => {
        return (
          'text-right high-light fw-500 ' +
          ((!hideOddSymbol
            ? _totalShow.gainLoss
            : _totalShow.gainLoss -
              (_totalOddLot.totCurAmt - _totalOddLot.totBuyAmt)) > 0
            ? 'i'
            : (!hideOddSymbol
                ? _totalShow.gainLoss
                : _totalShow.gainLoss -
                  (_totalOddLot.totCurAmt - _totalOddLot.totBuyAmt)) < 0
            ? 'd'
            : 'r')
        );
      },
      cell: ({ hideOddSymbol, _totalShow, _totalOddLot }) => {
        return (
          numberFormat(
            !hideOddSymbol
              ? _totalShow.gainLossPc
              : ((_totalShow.gainLoss -
                  (_totalOddLot.totCurAmt - _totalOddLot.totBuyAmt)) *
                  100) /
                  _totalShow.totCurAmt,
            2,
            '-'
          ) + '%'
        );
      },
      rowSpan: '1',
    },
  ];

  return (
    <div className="portfolio-section stockout-section">
      <div className="portfolio-section__header">
        <span className="portfolio-section__title text--md text--light">
          {t('pinefolio.stockOutside')}
        </span>
        <div className="portfolio-section__actions"></div>
      </div>
      <div className="portfolio-section__body">
        <PaginationTable
          t
          pageSize={5}
          columns={headerColumn}
          source={
            positionOutSidePF && defaultAccount?.subAcntNo.startsWith('P')
              ? positionOutSidePF
              : []
          }
          hasPaging
          hasSearch
          hasSwitch
          searchField="symbol"
          totalColumns={totalColumns}
          hasSort="true"
          action={handleQuickOrder}
        />
      </div>
    </div>
  );
});

export default StockOutSection;
