import {
  GET_CPT_BOARD_INFOR_ERROR,
  GET_CPT_BOARD_INFOR_REQUEST,
  GET_CPT_BOARD_INFOR_SUCCESS,
  GET_CPT_PERSONAL_INFOR_ERROR,
  GET_CPT_PERSONAL_INFOR_REQUEST,
  GET_CPT_PERSONAL_INFOR_SUCCESS,
  GET_CPT_PER_CHART_ERROR,
  GET_CPT_PER_CHART_REQUEST,
  GET_CPT_PER_CHART_SUCCESS,
  GET_CPT_ROUND_INFOR_ERROR,
  GET_CPT_ROUND_INFOR_REQUEST,
  GET_CPT_ROUND_INFOR_SUCCESS,
  GET_CPT_STOCK_BAL_ERROR,
  GET_CPT_STOCK_BAL_REQUEST,
  GET_CPT_STOCK_BAL_SUCCESS,
  GET_CPT_TOP_RANK_ERROR,
  GET_CPT_TOP_RANK_REQUEST,
  GET_CPT_TOP_RANK_SUCCESS,
  GET_CPT_TOP_SYMBOL_ERROR,
  GET_CPT_TOP_SYMBOL_REQUEST,
  GET_CPT_TOP_SYMBOL_SUCCESS,
  GET_REGIST_CPT_STAT_ERROR,
  GET_REGIST_CPT_STAT_REQUEST,
  GET_REGIST_CPT_STAT_SUCCESS,
  REGISTER_COMPETITON_REQUEST,
  REGISTER_COMPETITON_SUCCESS,
  SET_ROUND_VIEW_RESULT,
} from './constants';

const initialState = {
  registerCompetitionRequesting: false,
  nickName: null,
  registerCompetitionRequestSuccess: false,
  registerCompetitionRequestError: false,

  getCptRoundInfoRequesting: false,
  cptRoundInfo: [],
  getCptRoundInfoSuccess: false,
  getCptRoundInfoError: [],

  getCptBoardInfoRequesting: false,
  cptBoardInfo: [],
  getCptBoardInfoSuccess: false,
  getCptBoardInfoError: [],

  getRegistCptStatRequesting: false,
  registCptStat: null,
  getRegistCptStatSuccess: false,
  getRegistCptStatError: [],

  getCptStockBalRequesting: false,
  cptStockBal: [],
  getCptStockBalSuccess: false,
  getCptStockBalError: [],

  getCptTopRankRequesting: false,
  cptTopRank: [],
  getCptTopRankSuccess: false,
  getCptTopRankError: [],

  getCptTopSymbolRequesting: false,
  cptTopSymbol: [],
  getCptTopSymbolSuccess: false,
  getCptTopSymbolError: [],

  getCptPerChartRequesting: false,
  cptPerChart: [],
  getCptPerChartSuccess: false,
  getCptPerChartError: [],

  getCptPersonalInforRequesting: false,
  cptPersonalInfor: [],
  getCptPersonalInforSuccess: false,
  getCptPersonnalInforError: [],

  roundViewResult: null,
};

const reducer = function marginCompetitionReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case 'LOG_OUT': {
      return {
        ...state,
        registCptStat: null,
      };
    }
    case REGISTER_COMPETITON_REQUEST:
      return {
        ...state,
        nickName: null,
        registerCompetitionRequesting: true,
        registerCompetitionRequestSuccess: false,
        registerCompetitionRequestError: false,
      };

    case REGISTER_COMPETITON_SUCCESS:
      return {
        ...state,
        nickName: action.nickName,
        registerCompetitionRequesting: false,
        registerCompetitionRequestSuccess: true,
        registerCompetitionRequestError: false,
      };
    case GET_CPT_ROUND_INFOR_REQUEST:
      return {
        ...state,
        getCptRoundInfoRequesting: true,
        cptRoundInfo: [],
        getCptRoundInfoSuccess: false,
        getCptRoundInfoError: [],
      };

    case GET_CPT_ROUND_INFOR_SUCCESS:
      return {
        ...state,
        getCptRoundInfoRequesting: false,
        cptRoundInfo: action.cptRoundInfo,
        getCptRoundInfoSuccess: false,
        getCptRoundInfoError: [],
      };

    case GET_CPT_ROUND_INFOR_ERROR:
      return {
        ...state,
        getCptRoundInfoRequesting: false,
        cptRoundInfo: [],
        getCptRoundInfoSuccess: false,
        getCptRoundInfoError: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };

    case GET_CPT_BOARD_INFOR_REQUEST:
      return {
        ...state,
        getCptBoardInfoRequesting: true,
        cptBoardInfo: [],
        getCptBoardInfoSuccess: false,
        getCptBoardInfoError: [],
      };

    case GET_CPT_BOARD_INFOR_SUCCESS:
      return {
        ...state,
        getCptBoardInfoRequesting: false,
        cptBoardInfo: action.cptBoardInfo,
        getCptBoardInfoSuccess: false,
        getCptBoardInfoError: [],
      };

    case GET_CPT_BOARD_INFOR_ERROR:
      return {
        ...state,
        getCptBoardInfoRequesting: false,
        cptBoardInfo: [],
        getCptBoardInfoSuccess: false,
        getCptBoardInfoError: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };

    case GET_REGIST_CPT_STAT_REQUEST:
      return {
        ...state,
        getRegistCptStatRequesting: true,
        registCptStat: null,
        getRegistCptStatSuccess: false,
        getRegistCptStatError: [],
      };

    case GET_REGIST_CPT_STAT_SUCCESS:
      return {
        ...state,
        getRegistCptStatRequesting: false,
        registCptStat: action.registCptStat,
        getRegistCptStatSuccess: false,
        getRegistCptStatError: [],
      };

    case GET_REGIST_CPT_STAT_ERROR:
      return {
        ...state,
        getRegistCptStatRequesting: false,
        registCptStat: null,
        getRegistCptStatSuccess: false,
        getRegistCptStatError: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };

    case GET_CPT_STOCK_BAL_REQUEST:
      return {
        ...state,
        getCptStockBalRequesting: true,
        cptStockBal: [],
        getCptStockBalSuccess: false,
        getCptStockBalError: [],
      };

    case GET_CPT_STOCK_BAL_SUCCESS:
      return {
        ...state,
        getCptStockBalRequesting: false,
        cptStockBal: action.cptStockBal,
        getCptStockBalSuccess: true,
        getCptStockBalError: [],
      };

    case GET_CPT_STOCK_BAL_ERROR:
      return {
        ...state,
        getCptStockBalRequesting: true,
        cptStockBal: [],
        getCptStockBalSuccess: false,
        getCptStockBalError: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };

    case GET_CPT_TOP_RANK_REQUEST:
      return {
        ...state,
        getCptTopRankRequesting: true,
        cptTopRank: [],
        getCptTopRankSuccess: false,
        getCptTopRankError: [],
      };

    case GET_CPT_TOP_RANK_SUCCESS:
      return {
        ...state,
        getCptTopRankRequesting: false,
        cptTopRank: action.cptTopRank,
        getCptTopRankSuccess: true,
        getCptTopRankError: [],
      };

    case GET_CPT_TOP_RANK_ERROR:
      return {
        ...state,
        getCptTopRankRequesting: false,
        cptTopRank: [],
        getCptTopRankSuccess: false,
        getCptTopRankError: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };
    case GET_CPT_TOP_SYMBOL_REQUEST:
      return {
        ...state,
        getCptTopSymbolRequesting: true,
        cptTopSymbol: [],
        getCptTopSymbolSuccess: false,
        getCptTopSymbolError: [],
      };

    case GET_CPT_TOP_SYMBOL_SUCCESS:
      return {
        ...state,
        getCptTopSymbolRequesting: false,
        cptTopSymbol: action.cptTopSymbol,
        getCptTopSymbolSuccess: true,
        getCptTopSymbolError: [],
      };

    case GET_CPT_TOP_SYMBOL_ERROR:
      return {
        ...state,
        getCptTopSymbolRequesting: true,
        cptTopSymbol: [],
        getCptTopSymbolSuccess: false,
        getCptTopSymbolError: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };

    case GET_CPT_PER_CHART_REQUEST:
      return {
        ...state,
        getCptPerChartRequesting: true,
        cptPerChart: [],
        getCptPerChartSuccess: false,
        getCptPerChartError: [],
      };

    case GET_CPT_PER_CHART_SUCCESS:
      return {
        ...state,
        getCptPerChartRequesting: false,
        cptPerChart: action.cptPerChart,
        getCptPerChartSuccess: true,
        getCptPerChartError: [],
      };

    case GET_CPT_PER_CHART_ERROR:
      return {
        ...state,
        getCptPerChartRequesting: true,
        cptPerChart: [],
        getCptPerChartSuccess: false,
        getCptPerChartError: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };

    case GET_CPT_PERSONAL_INFOR_REQUEST:
      return {
        ...state,
        getCptPersonalInforRequesting: true,
        cptPersonalInfor: [],
        getCptPersonalInforSuccess: false,
        getCptPersonnalInforError: [],
      };

    case GET_CPT_PERSONAL_INFOR_SUCCESS:
      return {
        ...state,
        getCptPersonalInforRequesting: false,
        cptPersonalInfor: action.cptPersonalInfor,
        getCptPersonalInforSuccess: true,
        getCptPersonnalInforError: [],
      };

    case GET_CPT_PERSONAL_INFOR_ERROR:
      return {
        ...state,
        getCptPersonalInforRequesting: false,
        cptPersonalInfor: [],
        getCptPersonalInforSuccess: false,
        getCptPersonnalInforError: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };

    case SET_ROUND_VIEW_RESULT:
      return {
        ...state,
        roundViewResult: action.params,
      };
    default:
      return state;
  }
};

export default reducer;
