import React, { useState, useEffect, useMemo, useContext } from 'react';
import ReactPlayer from 'react-player';
import { debounce, throttle } from 'lodash';

import Modal from 'react-bootstrap/Modal';
import { getVideoDetail, toogleVideoModal, getRelatedVideos } from '../action';
import { ReactReduxContext, useDispatch, useSelector } from 'react-redux';
import PerfectScrollBar from 'react-perfect-scrollbar';
import { OverlayTrigger, Tooltip, Popover } from 'react-bootstrap';
import { withNamespaces } from 'react-i18next';
import { handleApiErrors } from '../../../lib/api-errors';
import { getMessage } from '../../../lib/selector';
import { setToast } from '../../client/actions';

const appUrl = `${process.env.REACT_APP_API_URL_2}`;

const VideoDetailModal = withNamespaces('translations')(
  ({ show, lang, videoId, setVideoId, t }) => {
    const PAGE_SIZE = 10;
    const dispatch = useDispatch();
    const toogle = useSelector((state) => state?.news?.videoModalShow);
    const videoDetail = useSelector((state) => state?.news?.videoDetail);
    const theme = useSelector((state) => state.client.currentTheme);
    const [relatedVideos, setRelatedVideos] = useState([]);
    const [offset, setOffset] = useState(0);
    const [showToolTip, setShowToolTip] = useState(false);
    const [tooltipKey, setTooltipKey] = useState();
    const { store } = useContext(ReactReduxContext);

    const handleClose = (close) => {
      dispatch(toogleVideoModal(close));
      setVideoId(-1);
      setRelatedVideos([]);
    };

    const handleRelatedVideo = () => {
      const url = `${appUrl}/api/Videos/GetRelatedVideos?id=${videoId}&top=10&offset=${offset}`;
      fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
      })
        .then(handleApiErrors)
        .then((response) => response.json())
        .then((res) => {
          if (res.http == 200) {
            const videos = res.data;
            if (offset == 0) {
              setRelatedVideos(videos);
            } else {
              const _video = [...relatedVideos].concat(videos);
              const _listTopVideo = _video.filter(
                (v, i, a) => a.findIndex((v2) => v2.id === v.id) === i
              );
              setRelatedVideos(_listTopVideo);
            }
            setOffset(offset + videos.length);
          } else {
            const msgErr = getMessage(res.data.message, store.getState);
            const toastMsg = {
              id: Math.random(),
              msg: msgErr,
              title: t('txt-notice'),
            };
            dispatch(setToast(toastMsg));
          }
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    };

    const getVideoHandle = () => {
      if (videoId) dispatch(getVideoDetail(videoId));
    };

    useMemo(() => {
      if (videoId) {
        setOffset(0);
        getVideoHandle();
        handleRelatedVideo(0);
      }
    }, [videoId]);

    const changeVideos = (id) => {
      if (id) {
        dispatch(getVideoDetail(id));
        handleRelatedVideo(0);
      }
    };

    const loadMore = debounce(() => {
      handleRelatedVideo();
    }, 0);

    const toggle = () => {
      setShowToolTip(!showToolTip);
    };

    return (
      <Modal
        show={toogle}
        onHide={() => handleClose(false)}
        dialogClassName="wts-modal"
        backdropClassName="wts-modal-backdrop"
        contentClassName={`${
          theme ? theme.name : 'dark'
        } bond-detail wts-modal-content wts-modal-content--xl`}
        className="video__modal"
        keyboard={true}
        size={'xl'}
        centered
      >
        {!videoDetail ? (
          <span> Loading ...</span>
        ) : (
          <div>
            <Modal.Body className="video-popup">
              <div className="row">
                <div className="col-md-9">
                  <p className="market__text market__text--title fz_16 pt-1 pb-1">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: videoDetail.title,
                      }}
                    />
                  </p>
                  <ReactPlayer
                    url={videoDetail.path}
                    playing
                    volume={0.5}
                    width="100%"
                    height="505px"
                    controls={true}
                    playsinline={true}
                  />
                </div>
                <div className="col-md-3">
                  <h1 className="market__text fz_16 d-flex align-items-center">
                    {t('news.relatedVideo')}
                    {relatedVideos && relatedVideos.length > 0 && (
                      <sup className="badge bg-success fz_12 ml-2">
                        {relatedVideos.length}
                      </sup>
                    )}
                  </h1>
                  <PerfectScrollBar
                    style={{
                      maxHeight: '520px',
                      height: 'auto',
                      paddingRight: '1rem',
                    }}
                    options={{
                      wheelSpeed: 1,
                      wheelPropagation: true,
                      minScrollbarLength: 20,
                    }}
                  >
                    <div className="relatedVideos">
                      {relatedVideos &&
                        relatedVideos.length > 0 &&
                        relatedVideos.map((video, i) => (
                          <div
                            className="relatedVideo video__desc"
                            key={`relatedVideo-${i}`}
                            onClick={() => changeVideos(video.id)}
                          >
                            <div className="relatedVideo__thumbnail">
                              <span
                                style={{
                                  width: '120px',
                                  height: '80px',
                                  backgroundImage: `url('${video.thumbnails.high.url}')`,
                                }}
                              ></span>
                            </div>
                            <OverlayTrigger
                              trigger={['hover', 'focus']}
                              placement="top"
                              onToggle={() => {
                                toggle();
                                setTooltipKey(video.id);
                              }}
                              show={showToolTip && video.id == tooltipKey}
                              rootClose
                              overlay={
                                <Popover style={{ maxWidth: '440px' }}>
                                  <Popover.Content className="d-flex">
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: video.title,
                                      }}
                                    />
                                  </Popover.Content>
                                </Popover>
                              }
                            >
                              <span className="video__title fz_13 fw-500 d-flex text--light3">
                                {video.title}
                              </span>
                            </OverlayTrigger>
                          </div>
                        ))}
                      <div
                        className="btn--loadMore text--light"
                        onClick={() => loadMore()}
                      >
                        <span>{t('news.loadmore')}</span>
                      </div>
                    </div>
                  </PerfectScrollBar>
                </div>
              </div>
            </Modal.Body>
            {/* <HoverButton cStyle={hoverStyle} onClick={() => handleClose()}>
              <AiOutlineClose width={16} height={16} />
            </HoverButton> */}
          </div>
        )}
      </Modal>
    );
  }
);

export default VideoDetailModal;
