import React, { useState, useEffect } from 'react';
import { withNamespaces } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { setProInvRequire } from '../../action';
import { makeGetBondInfor } from '../../../../lib/selector';
const appUrl = `${process.env.REACT_APP_API_URL}`;

const ProInvestorAlert = withNamespaces('translations')(
  ({ t, showMoreCallback, doneCallback }) => {
    const dispatch = useDispatch();
    const { proInvRequire } = useSelector((state) => state.bond);
    const theme = useSelector((state) => state.client.currentTheme);
    const bondInfor = useSelector(makeGetBondInfor());
    const [proInvGuideUrl, setProInvGuideUrl] = useState();

    const showMoreHandle = () => {
      if (showMoreCallback && typeof showMoreCallback == 'function') {
        showMoreCallback();
      }

      dispatch(setProInvRequire());
      window.open(proInvGuideUrl ?? appUrl, '_blank');
    };
    const doneHandle = () => {
      if (doneCallback && typeof doneCallback == 'function') {
        doneCallback();
      }
      dispatch(setProInvRequire());
    };

    useEffect(() => {
      if (bondInfor) {
        const proInvGuide = bondInfor.find((x) => x.key == 'PRO_INV_GUIDE');
        if (proInvGuide) setProInvGuideUrl(proInvGuide.url);
      }
    }, [bondInfor]);

    return (
      <Modal
        show={proInvRequire}
        dialogClassName="wts-modal"
        backdropClassName="wts-modal-backdrop"
        contentClassName={`${
          theme.name || 'dark'
        } bond-notification wts-modal-content wts-modal-content--sm`}
        centered
        style={{ zIndex: 11120 }}
      >
        <div className="bond-notification__body">
          <div className="bond-notification__logo bond-notification__logo--r">
            <span className="icon iStar"></span>
          </div>
          <span className="text-title text--light">
            {t('bond.professional.requiredTitle')}
          </span>
          <span className="text-desc text--light3">
            {t('bond.professional.requiredDesc')}
          </span>
        </div>
        <div className="bond-notification__footer">
          <div className="bond-notification__actions">
            <button
              className="btn btn--primary mr-1"
              onClick={() => showMoreHandle()}
            >
              <span>{t('txt-guide')}</span>
            </button>
            <button className="btn btn--cancel" onClick={() => doneHandle()}>
              <span>{t('txt-cancel')}</span>
            </button>
          </div>
        </div>
      </Modal>
    );
  }
);

export default ProInvestorAlert;
