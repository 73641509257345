import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import {
  GET_CPT_BOARD_INFOR_REQUEST,
  GET_CPT_PERSONAL_INFOR_REQUEST,
  GET_CPT_PER_CHART_REQUEST,
  GET_CPT_ROUND_INFOR_REQUEST,
  GET_CPT_ROUND_INFOR_SUCCESS,
  GET_CPT_STOCK_BAL_REQUEST,
  GET_CPT_TOP_RANK_REQUEST,
  GET_CPT_TOP_SYMBOL_REQUEST,
  GET_REGIST_CPT_STAT_REQUEST,
  REGISTER_COMPETITON_REQUEST,
  REGISTER_COMPETITON_SUCCESS,
  UN_REGIST_CPT_REQUEST,
} from './constants';
import i18n from '../../i18n';
import { handleApiErrors } from '../../lib/api-errors';
import { getMessage } from '../../lib/selector';
import { setToast } from '../client/actions';
import {
  getCptBoardInfoError,
  getCptBoardInfoSuccess,
  getCptPerChartError,
  getCptPerChartSuccess,
  getCptPersonalInforError,
  getCptPersonalInforSuccess,
  getCptRoundInfoSuccess,
  getCptStockBalError,
  getCptStockBalSuccess,
  getCptTopRankError,
  getCptTopRankSuccess,
  getCptTopSymbolError,
  getCptTopSymbolSuccess,
  getRegistCptStatError,
  getRegistCptStatSuccess,
  registerCompetitonSuccess,
} from './actions';

const appUrl = `${process.env.REACT_APP_API_URL}`;
const t = i18n.t;

function handleRequest(request) {
  return request
    .then(handleApiErrors)
    .then((response) => response.json())
    .then((json) => json)
    .catch((error) => {
      throw error;
    });
}

function toastHandle(message, title = 'txt-notice') {
  const toastMsg = {
    id: Math.random(),
    msg: message,
    time: new Date(),
    title: i18n.t(`${title}`),
  };
  return put(setToast(toastMsg));
}

function competitionAPI(data) {
  const url = `${appUrl}/CompetitionServlet.pt`;
  const request = fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
    body: JSON.stringify(data),
  });
  return handleRequest(request);
}

function* registerCompetitionFlow(action) {
  try {
    const resData = yield call(competitionAPI, action.params);
    const callback = action.callback;

    if (resData.http !== 200) {
      const msgErr = yield select(getMessage, resData);
      throw Error(msgErr);
    } else {
      yield put(registerCompetitonSuccess(resData.data));
      if (callback && typeof callback == 'function') {
        callback();
      }
    }
  } catch (error) {
    yield toastHandle(error?.toString());
  }
}

function* getCptRoundInfoFlow(action) {
  try {
    const resData = yield call(competitionAPI, action.params);
    if (resData.http !== 200) {
      const msgErr = yield select(getMessage, resData);
      throw Error(msgErr);
    } else {
      yield put(getCptRoundInfoSuccess(resData.data));
    }
  } catch (error) {
    yield toastHandle(error?.toString());
  }
}

function* getCptBoardInfoFlow(action) {
  try {
    const resData = yield call(competitionAPI, action.params);
    if (resData.http !== 200) {
      const msgErr = yield select(getMessage, resData);
      throw Error(msgErr);
    } else {
      yield put(getCptBoardInfoSuccess(resData.data));
    }
  } catch (error) {
    yield put(getCptBoardInfoError(error));
    yield toastHandle(error?.toString());
  }
}

function* getRegistCptStatFlow(action) {
  try {
    const resData = yield call(competitionAPI, action.params);
    if (resData.http !== 200) {
      const msgErr = yield select(getMessage, resData);
      throw Error(msgErr);
    } else {
      yield put(getRegistCptStatSuccess(resData.data));
    }
  } catch (error) {
    yield put(getRegistCptStatError(error));
    yield toastHandle(error?.toString());
  }
}

function* unRegistCptFlow(action) {
  try {
    const resData = yield call(competitionAPI, action.params);
    const callback = action.callback;
    if (resData.http !== 200) {
      const msgErr = yield select(getMessage, resData);
      throw Error(msgErr);
    } else {
      if (callback && typeof callback == 'function') {
        callback();
      }
      yield toastHandle(
        i18n.t('marginCompetition.generalIntroduction.unRegisterSuccess')
      );
    }
  } catch (error) {
    yield toastHandle(error?.toString());
  }
}

function* getCptStockBalFlow(action) {
  try {
    const resData = yield call(competitionAPI, action.params);
    if (resData.http !== 200) {
      const msgErr = yield select(getMessage, resData);
      throw Error(msgErr);
    } else {
      yield put(getCptStockBalSuccess(resData.data));
    }
  } catch (error) {
    yield put(getCptStockBalError(error));
    yield toastHandle(error?.toString());
  }
}

function* getCptTopRankFlow(action) {
  try {
    const resData = yield call(competitionAPI, action.params);
    if (resData.http !== 200) {
      const msgErr = yield select(getMessage, resData);
      throw Error(msgErr);
    } else {
      yield put(getCptTopRankSuccess(resData.data));
    }
  } catch (error) {
    yield put(getCptTopRankError(error));
    yield toastHandle(error?.toString());
  }
}

function* getCptTopSymbolFlow(action) {
  try {
    const resData = yield call(competitionAPI, action.params);
    if (resData.http !== 200) {
      const msgErr = yield select(getMessage, resData);
      throw Error(msgErr);
    } else {
      yield put(getCptTopSymbolSuccess(resData.data));
    }
  } catch (error) {
    yield put(getCptTopSymbolError(error));
    yield toastHandle(error?.toString());
  }
}

function* getCptPerChartFlow(action) {
  try {
    const resData = yield call(competitionAPI, action.params);
    if (resData.http !== 200) {
      const msgErr = yield select(getMessage, resData);
      throw Error(msgErr);
    } else {
      yield put(getCptPerChartSuccess(resData.data));
    }
  } catch (error) {
    yield put(getCptPerChartError(error));
    yield toastHandle(error?.toString());
  }
}

function* getCptPersonalInforFlow(action) {
  try {
    const resData = yield call(competitionAPI, action.params);
    if (resData.http !== 200) {
      const msgErr = yield select(getMessage, resData);
      throw Error(msgErr);
    } else {
      yield put(getCptPersonalInforSuccess(resData.data));
    }
  } catch (error) {
    yield put(getCptPersonalInforError(error));
    yield toastHandle(error?.toString());
  }
}

function* marginCompetitionWatcher() {
  yield all([
    takeLatest(REGISTER_COMPETITON_REQUEST, registerCompetitionFlow),
    takeLatest(GET_CPT_ROUND_INFOR_REQUEST, getCptRoundInfoFlow),
    takeLatest(GET_CPT_BOARD_INFOR_REQUEST, getCptBoardInfoFlow),
    takeLatest(GET_REGIST_CPT_STAT_REQUEST, getRegistCptStatFlow),
    takeLatest(UN_REGIST_CPT_REQUEST, unRegistCptFlow),
    takeLatest(GET_CPT_STOCK_BAL_REQUEST, getCptStockBalFlow),
    takeLatest(GET_CPT_TOP_RANK_REQUEST, getCptTopRankFlow),
    takeLatest(GET_CPT_TOP_SYMBOL_REQUEST, getCptTopSymbolFlow),
    takeLatest(GET_CPT_PER_CHART_REQUEST, getCptPerChartFlow),
    takeLatest(GET_CPT_PERSONAL_INFOR_REQUEST, getCptPersonalInforFlow),
  ]);
}

export default marginCompetitionWatcher;
