export const AVAIL_STOCK_SOURCE_REQUESTING = 'AVAIL_STOCK_SOURCE_REQUESTING';
export const AVAIL_STOCK_SOURCE_REQUEST_SUCCESS =
  'AVAIL_STOCK_SOURCE_REQUEST_SUCCESS';
export const AVAIL_STOCK_SOURCE_REQUEST_ERROR =
  'AVAIL_STOCK_SOURCE_REQUEST_ERROR';

export const AVAIL_STOCK_TARGET_REQUESTING = 'AVAIL_STOCK_TARGET_REQUESTING';
export const AVAIL_STOCK_TARGET_REQUEST_SUCCESS =
  'AVAIL_STOCK_TARGET_REQUEST_SUCCESS';
export const AVAIL_STOCK_TARGET_REQUEST_ERROR =
  'AVAIL_STOCK_TARGET_REQUEST_ERROR';

export const STOCK_EXCHANGE_REQUESTING = 'STOCK_EXCHANGE_REQUESTING';
export const STOCK_EXCHANGE_REQUESTING_RESET =
  'STOCK_EXCHANGE_REQUESTING_RESET';
export const STOCK_EXCHANGE_REQUEST_SUCCESS = 'STOCK_EXCHANGE_REQUEST_SUCCESS';
export const STOCK_EXCHANGE_REQUEST_ERROR = 'STOCK_EXCHANGE_REQUEST_ERROR';

export const STOCK_EXCHANGE_HIS_REQUESTING = 'STOCK_EXCHANGE_HIS_REQUESTING';
export const STOCK_EXCHANGE_HIS_REQUEST_SUCCESS =
  'STOCK_EXCHANGE_HIS_REQUEST_SUCCESS';
export const STOCK_EXCHANGE_HIS_REQUEST_ERROR =
  'STOCK_EXCHANGE_HIS_REQUEST_ERROR';

export const ADVANCE_REQUESTING = 'ADVANCE_REQUESTING';
export const ADVANCE_REQUEST_SUCCESS = 'ADVANCE_REQUEST_SUCCESS';
export const ADVANCE_REQUEST_ERROR = 'ADVANCE_REQUEST_ERROR';

export const AVAIL_ADVANCE_REQUESTING = 'AVAIL_ADVANCE_REQUESTING';
export const AVAIL_ADVANCE_REQUEST_SUCCESS = 'AVAIL_ADVANCE_REQUEST_SUCCESS';
export const AVAIL_ADVANCE_REQUEST_ERROR = 'AVAIL_ADVANCE_REQUEST_ERROR';

export const ADVANCE_LOAN_REQUESTING = 'ADVANCE_LOAN_REQUESTING';
export const ADVANCE_LOAN_REQUEST_SUCCESS = 'ADVANCE_LOAN_REQUEST_SUCCESS';
export const ADVANCE_LOAN_REQUEST_ERROR = 'ADVANCE_LOAN_REQUEST_ERROR';

export const ADVANCE_LIST_REQUESTING = 'ADVANCE_LIST_REQUESTING';
export const ADVANCE_LIST_REQUEST_SUCCESS = 'ADVANCE_LIST_REQUEST_SUCCESS';
export const ADVANCE_LIST_REQUEST_ERROR = 'ADVANCE_LIST_REQUEST_ERROR';

export const ADVANCE_MATCH_REQUESTING = 'ADVANCE_MATCH_REQUESTING';
export const ADVANCE_MATCH_REQUEST_SUCCESS = 'ADVANCE_MATCH_REQUEST_SUCCESS';
export const ADVANCE_MATCH_REQUEST_ERROR = 'ADVANCE_MATCH_REQUEST_ERROR';

export const ADVANCE_FEE_REQUESTING = 'ADVANCE_FEE_REQUESTING';
export const ADVANCE_FEE_REQUEST_SUCCESS = 'ADVANCE_FEE_REQUEST_SUCCESS';
export const ADVANCE_FEE_REQUEST_ERROR = 'ADVANCE_FEE_REQUEST_ERROR';

export const BALANCE_SOURCE_REQUESTING = 'BALANCE_SOURCE_REQUESTING';
export const BALANCE_SOURCE_REQUEST_SUCCESS = 'BALANCE_SOURCE_REQUEST_SUCCESS';
export const BALANCE_SOURCE_REQUEST_ERROR = 'BALANCE_SOURCE_REQUEST_ERROR';

export const BALANCE_TARGET_REQUESTING = 'BALANCE_TARGET_REQUESTING';
export const BALANCE_TARGET_REQUEST_SUCCESS = 'BALANCE_TARGET_REQUEST_SUCCESS';
export const BALANCE_TARGET_REQUEST_ERROR = 'BALANCE_TARGET_REQUEST_ERROR';

export const CASH_TRANSFER_REQUESTING = 'CASH_TRANSFER_REQUESTING';
export const CASH_TRANSFER_REQUEST_SUCCESS = 'CASH_TRANSFER_REQUEST_SUCCESS';
export const CASH_TRANSFER_REQUEST_ERROR = 'CASH_TRANSFER_REQUEST_ERROR';

export const BANK_ACCOUNT_REQUESTING = 'BANK_ACCOUNT_REQUESTING';
export const BANK_ACCOUNT_REQUEST_SUCCESS = 'BANK_ACCOUNT_REQUEST_SUCCESS';
export const BANK_ACCOUNT_REQUEST_ERROR = 'BANK_ACCOUNT_REQUEST_ERROR';

export const CASH_OUT_REQUESTING = 'CASH_OUT_REQUESTING';
export const CASH_OUT_REQUEST_SUCCESS = 'CASH_OUT_REQUEST_SUCCESS';
export const CASH_OUT_REQUEST_ERROR = 'CASH_OUT_REQUEST_ERROR';
export const CASH_OUT_REQUEST_RESET = 'CASH_OUT_REQUEST_RESET';

export const HIS_TRANSFER_REQUESTING = 'HIS_TRANSFER_REQUESTING';
export const HIS_TRANSFER_REQUEST_SUCCESS = 'HIS_TRANSFER_REQUEST_SUCCESS';
export const HIS_TRANSFER_REQUEST_ERROR = 'HIS_TRANSFER_REQUEST_ERROR';

export const BANK_LIST_REQUESTING = 'BANK_LIST_REQUESTING';
export const BANK_LIST_REQUEST_SUCCESS = 'BANK_LIST_REQUEST_SUCCESS';
export const BANK_LIST_REQUEST_ERROR = 'BANK_LIST_REQUEST_ERROR';

export const BANK_INFOR_REQUESTING = 'BANK_INFOR_REQUESTING';
export const BANK_INFOR_REQUEST_SUCCESS = 'BANK_INFOR_REQUEST_SUCCESS';
export const BANK_INFOR_REQUEST_ERROR = 'BANK_INFOR_REQUEST_ERROR';

export const DEBT_REQUESTING = 'DEBT_REQUESTING';
export const DEBT_REQUEST_SUCCESS = 'DEBT_REQUEST_SUCCESS';
export const DEBT_REQUEST_ERROR = 'DEBT_REQUEST_ERROR';

export const REPAY_LOAN_REQUESTING = 'REPAY_LOAN_REQUESTING';
export const REPAY_LOAN_REQUEST_SUCCESS = 'REPAY_LOAN_REQUEST_SUCCESS';
export const REPAY_LOAN_REQUEST_ERROR = 'REPAY_LOAN_REQUEST_ERROR';

export const DEBT_HIS_REQUESTING = 'DEBT_HIS_REQUESTING';
export const DEBT_HIS_REQUEST_SUCCESS = 'DEBT_HIS_REQUEST_SUCCESS';
export const DEBT_HIS_REQUEST_ERROR = 'DEBT_HIS_REQUEST_ERROR';

export const DEBT_HIS2_REQUESTING = 'DEBT_HIS2_REQUESTING';
export const DEBT_HIS2_REQUEST_SUCCESS = 'DEBT_HIS2_REQUEST_SUCCESS';
export const DEBT_HIS2_REQUEST_ERROR = 'DEBT_HIS2_REQUEST_ERROR';

export const DEBT_HIS_EXTEND_REQUESTING = 'DEBT_HIS_EXTEND_REQUESTING';
export const DEBT_HIS_EXTEND_REQUEST_SUCCESS =
  'DEBT_HIS_EXTEND_REQUEST_SUCCESS';
export const DEBT_HIS_EXTEND_REQUEST_ERROR = 'DEBT_HIS_EXTEND_REQUEST_ERROR';

export const DEBT_EXTEND_REQUESTING = 'DEBT_EXTEND_REQUESTING';
export const DEBT_EXTEND_REQUEST_SUCCESS = 'DEBT_EXTEND_REQUEST_SUCCESS';
export const DEBT_EXTEND_REQUEST_ERROR = 'DEBT_EXTEND_REQUEST_ERROR';
export const DEBT_EXTEND_REQUEST_RESET = 'DEBT_EXTEND_REQUEST_RESET';

export const CASH_IN_BANK_REQUESTING = 'CASH_IN_BANK_REQUESTING';
export const CASH_IN_BANK_REQUEST_SUCCESS = 'CASH_IN_BANK_REQUEST_SUCCESS';
export const CASH_IN_BANK_REQUEST_ERROR = 'CASH_IN_BANK_REQUEST_ERROR';

export const HOLD_BANK_REQUESTING = 'HOLD_BANK_REQUESTING';
export const HOLD_BANK_REQUEST_SUCCESS = 'HOLD_BANK_REQUEST_SUCCESS';
export const HOLD_BANK_REQUEST_ERROR = 'HOLD_BANK_REQUEST_ERROR';

export const HOLD_LIST_REQUESTING = 'HOLD_LIST_REQUESTING';
export const HOLD_LIST_REQUEST_SUCCESS = 'HOLD_LIST_REQUEST_SUCCESS';
export const HOLD_LIST_REQUEST_ERROR = 'HOLD_LIST_REQUEST_ERROR';

export const RIGHT_LIST_REQUESTING = 'RIGHT_LIST_REQUESTING';
export const RIGHT_LIST_REQUEST_SUCCESS = 'RIGHT_LIST_REQUEST_SUCCESS';
export const RIGHT_LIST_REQUEST_ERROR = 'RIGHT_LIST_REQUEST_ERROR';

export const MARGIN_LIST_REQUESTING = 'MARGIN_LIST_REQUESTING';
export const MARGIN_LIST_REQUEST_SUCCESS = 'MARGIN_LIST_REQUEST_SUCCESS';
export const MARGIN_LIST_REQUEST_ERROR = 'MARGIN_LIST_REQUEST_ERROR';

export const GAIN_LOSS_RESET = 'GAIN_LOSS_RESET';
export const GAIN_LOSS_REQUESTING = 'GAIN_LOSS_REQUESTING';
export const GAIN_LOSS_REQUEST_SUCCESS = 'GAIN_LOSS_REQUEST_SUCCESS';
export const GAIN_LOSS_REQUEST_ERROR = 'GAIN_LOSS_REQUEST_ERROR';

export const GET_LIST_BANK_VA_REQUEST = 'GET_LIST_BANK_VA_REQUEST';
export const GET_LIST_BANK_VA_SUCCESS = 'GET_LIST_BANK_VA_SUCCESS';
export const GET_LIST_BANK_VA_ERROR = 'GET_LIST_BANK_VA_ERROR';
