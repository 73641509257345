import React, { useEffect } from 'react';

function Vchat() {
  useEffect(() => {
    var vChatCheck = document.getElementById('vnp_check');
    var vChatEyeChat = document.getElementById('vnp_eyechat');
    var vChat = document.getElementById('vnp_chat');

    if (!vChatCheck) {
      window.__vnp = {
        code: 18374,
        key: '',
        secret: '0be05ee097e8d455e0a380fc6361ea7c',
      };

      var ga = null;

      (function () {
        ga = document.createElement('script');
        ga.type = 'text/javascript';
        ga.async = true;
        ga.defer = true;
        ga.src = '//core.vchat.vn/code/tracking.js';
        var s = document.getElementsByTagName('script');
        s[0].parentNode.insertBefore(ga, s[0]);
      })();
    } else {
      vChatCheck.style.display = 'block';
      if (vChatEyeChat) vChatEyeChat.style.display = 'block';
      if (vChat) vChat.style.display = 'block';
    }

    return () => {
      if (ga) ga.parentNode.removeChild(ga);
      {
        var _vChatCheck = document.getElementById('vnp_check');
        var _vChatEyeChat = document.getElementById('vnp_eyechat');
        var _vChat = document.getElementById('vnp_chat');
        if (_vChatCheck) _vChatCheck.style.display = 'none';
        if (_vChatEyeChat) _vChatEyeChat.style.display = 'none';
        if (_vChat) _vChat.style.display = 'none';
      }
    };
  }, []);

  return null;
}

export default Vchat;
