import React, { useEffect, useState, Fragment } from 'react';
import { withNamespaces } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { Logout, logoutRequest } from '../../../components/modal/login/actions';
import PerfectScrollBar from 'react-perfect-scrollbar';
import { removeCookie } from '../../../lib/storages';
import {
  formatDate,
  getTransferStatus,
  numberFormat,
  _formatDate,
} from '../../../util';
import {
  cashBalanceSourceRequest,
  cashBalanceTargetRequest,
  hisTransferRequest,
  stockTargetRequest,
} from '../../cash/actions';
import { unsetClient } from '../../client/actions';
import { filter } from 'lodash';
import FormChuyenTien from './cashInternalTransfer/formChuyenTien';
import FormHisCashEx from '../components/cashInternalTransfer/formHisCashExchange';
import PaginationTable from '../../../components/PaginationTable';
import ReactSelect from 'react-select';

const ChuyenTienTieuKhoan = withNamespaces('translations')(({ t }) => {
  const { token, auth } = useSelector((state) => state.client);
  const listAccount = useSelector((state) => state.socket.listAccount);
  const defaultAccount = useSelector((state) => state.socket.defaultAccount);
  const { cashTransfer, targetBalance, hisTransfer, sourceBalance } =
    useSelector((state) => state.cash);
  const dispatch = useDispatch();

  const [listSource, setListSource] = useState([]);
  const [listTarget, setListTarget] = useState([]);
  const [defSource, setDefSource] = useState({});
  const [defTarget, setDefTarget] = useState({});
  const [msg, setMsg] = useState([]);

  const handleLoadCashBalanceSource = () => {
    if (!defSource) return;
    const uuid = uuidv4();
    const resData = {
      group: 'CORE',
      user: token.user,
      session: token.session,
      cmd: 'getTotalAssetAll',
      rqId: uuid,
      channel: 'WTS',
      data: {
        acntNo: defSource.acntNo,
        subAcntNo: defSource.subAcntNo,
      },
    };
    dispatch(cashBalanceSourceRequest(resData));
  };

  const handleLoadCashBalanceTarget = () => {
    if (!defTarget || !token) return;
    const uuid = uuidv4();
    const resData = {
      group: 'CORE',
      user: token.user,
      session: token.session,
      cmd: 'getTotalAssetAll',
      rqId: uuid,
      channel: 'WTS',
      data: {
        acntNo: defTarget.acntNo,
        subAcntNo: defTarget.subAcntNo,
      },
    };

    dispatch(cashBalanceTargetRequest(resData));
  };

  const handleLoadDataTarget = () => {
    if (!defTarget || !token) return;
    const uuid = uuidv4();
    const resData = {
      group: 'CORE',
      user: token.user,
      session: token.session,
      cmd: 'getAvailStockList',
      rqId: uuid,
      channel: 'WTS',
      data: {
        acntNo: defTarget.acntNo,
        subAcntNo: defTarget.subAcntNo,
      },
    };

    dispatch(stockTargetRequest(resData));
  };

  const handleLoadListTransfer = (fromDate, endDate) => {
    if (!defSource) return;

    const d = new Date();
    d.setDate(d.getDate() - 30);

    if (!fromDate) fromDate = formatDate(d, '');
    else fromDate = _formatDate(fromDate);

    if (!endDate) endDate = formatDate(new Date(), '');
    else endDate = _formatDate(endDate);

    const uuid = uuidv4();
    const resData = {
      group: 'CORE',
      user: token.user,
      session: token.session,
      cmd: 'getCashTransferHist',
      rqId: uuid,
      channel: 'WTS',
      data: {
        acntNo: defSource.acntNo,
        subAcntNo: defSource.subAcntNo,
        fromDate: fromDate,
        toDate: endDate,
        transType: '1',
      },
    };
    dispatch(hisTransferRequest(resData));
  };

  const handleChangeUserSource = (user) => {
    const _listSource = filter(listAccount, (o) => o.subAcntStat === 1);
    const _listTarget = filter(
      listAccount,
      (o) => o.subAcntNo !== user.subAcntNo && o.subAcntStat === 1
    );

    setListTarget(_listTarget);
    setDefTarget((_listTarget && !!_listTarget.length && _listTarget[0]) || {});
    setListSource(_listSource);
    setDefSource(user);
    setMsg([]);

    handleLoadDataTarget();
    handleLoadCashBalanceSource();
    handleLoadCashBalanceTarget();
    handleLoadListTransfer();
  };

  const handleChangeUserTarget = (user) => {
    setDefTarget(user);
    setMsg([]);

    handleLoadDataTarget();
    handleLoadCashBalanceTarget();
  };

  useEffect(() => {
    if (cashTransfer) {
      let _msg;
      if (cashTransfer.http !== 200) {
        if (cashTransfer.http === 401) {
          if (token) dispatch(logoutRequest(token));

          dispatch(unsetClient());
          removeCookie('token');
          removeCookie('authen');

          dispatch(Logout());
        }
        _msg = [...msg, { type: 'danger', msg: cashTransfer.data.message }];
      } else {
        _msg = [
          ...msg,
          { type: 'success', msg: t('txt-title-transfer-success') },
        ];
        handleLoadCashBalanceSource();
        handleLoadCashBalanceTarget();
        handleLoadListTransfer();
      }
      setMsg(_msg);
      setTimeout(() => {
        setMsg([]);
      }, 5000);
    }
  }, [cashTransfer]);

  useEffect(() => {
    if (listAccount) {
      const _listSource = filter(listAccount, (o) => o.subAcntStat === 1);
      if (defaultAccount) {
        const _listTarget = filter(
          listAccount,
          (o) => o.subAcntNo !== defaultAccount.subAcntNo && o.subAcntStat === 1
        );

        setListTarget(_listTarget);
        setDefTarget(
          (_listTarget && !!_listTarget.length && _listTarget[0]) || {}
        );

        setDefSource(defaultAccount);
        setListSource(_listSource);

        handleLoadDataTarget();
        handleLoadCashBalanceSource();
        handleLoadCashBalanceTarget();
        handleLoadListTransfer();
      }
    }
  }, []);

  useEffect(() => {
    if (listAccount && defaultAccount) {
      const _listSource = filter(listAccount, (o) => o.subAcntStat === 1);
      const _defSource = defaultAccount || _listSource[0];
      const _listTarget = filter(
        listAccount,
        (o) => o.subAcntNo !== _defSource.subAcntNo && o.subAcntStat === 1
      );

      setListTarget(_listTarget);
      setDefTarget(
        (_listTarget && !!_listTarget.length && _listTarget[0]) || {}
      );
      setListSource(_listSource);
      setDefSource(_defSource);
      setMsg([]);

      handleLoadDataTarget();
      handleLoadCashBalanceSource();
      handleLoadCashBalanceTarget();
      handleLoadListTransfer();
    }
  }, [defaultAccount]);

  useEffect(() => {
    if (defTarget && defTarget != undefined) {
      handleLoadDataTarget();
      handleLoadCashBalanceTarget();
      handleLoadListTransfer();
    }
  }, [defTarget]);

  useEffect(() => {
    if (defSource && defSource != undefined) {
      handleLoadCashBalanceSource();
      handleLoadListTransfer();
    }
  }, [defSource]);

  const columns = [
    {
      key: 'defaultAccount',
      text: t('txt-label-source'),
      className: 'text-center',
      align: 'center',
      cell: (record) => {
        return <span>{record && record.subAcntNo}</span>;
      },
    },
    {
      key: 'toAcntNo',
      text: t('txt-label-target'),
      className: 'text-center text--light',
      align: 'center',
    },
    {
      key: 'trdAmt',
      text: t('txt-label-cash-transfer-amount'),
      className: 'text-right text--light fw-500',
      align: 'center',
      cell: (record) => {
        return numberFormat(record.trdAmt, 0, '0');
      },
    },
    {
      key: 'trdFee',
      text: t('txt-label-fee'),
      className: 'text-right text--light',
      align: 'center',
      cell: (record) => {
        return numberFormat(record.trdFee, 0, '0');
      },
    },
    {
      key: 'desc',
      text: t('txt-label-cash-transfer-content'),
      className: 'text-left',
      align: 'center',
    },
    {
      key: 'trdDt',
      text: t('txt-label-create-date'),
      className: 'text-center',
      align: 'center',
    },
    {
      key: 'status',
      text: t('txt-status'),
      className: (record) => {
        return (
          'text-center ' +
          (record.status === '1' ? 'r' : record.status === '2' ? 'i' : 'd')
        );
      },
      align: 'center',
      cell: (record) => {
        return getTransferStatus(record.status);
      },
    },
  ];

  return (
    <div className="personal-assets card-panel-2">
      <div className="personal-assets-body card-panel-2-body cash-internal-transfer-container">
        <p className="d-flex flex-row high-label  mt-24">
          <span className="mr-auto text-white text-title">
            {t('txt-label-internal-transfer')}
          </span>
        </p>
        <div className="cash-transfer-block">
          <div className="">
            <div className="cash-transfer-account">
              <div className="account-detail">
                <div className="d-flex align-items-center row-hug justify-content-between">
                  <label className="text-content fz_14 text--light">
                    {t('txt-label-source')}
                  </label>
                  {listSource && !!listSource.length && (
                    <ReactSelect
                      onChange={handleChangeUserSource}
                      options={[...listSource]}
                      className="filter-control-select"
                      classNamePrefix="filter-control-select"
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.subAcntNo}
                      defaultValue={listSource[0]}
                      value={defSource}
                      isSearchable={false}
                    />
                  )}

                  <div className="d-flex reload-button btn--refresh">
                    <span
                      className="icon iReload"
                      onClick={handleLoadCashBalanceSource}
                    ></span>
                  </div>
                </div>
                <div className="row d-flex justify-content-between align-items-center">
                  <span>
                    {t('transaction.cashInternalTransfer.moneyAvailable')}
                  </span>
                  <span className="text--light fw-500">
                    {sourceBalance &&
                      numberFormat(sourceBalance?.balance, 0, '0')}
                  </span>
                </div>
                <div className="row d-flex justify-content-between align-items-center">
                  <span>
                    {t('transaction.cashInternalTransfer.withdrawable')}
                  </span>
                  <span className="text--light fw-500">
                    {sourceBalance &&
                      numberFormat(sourceBalance?.wdrawAvail, 0, '0')}
                  </span>
                </div>
              </div>
              <div className="d-flex transfer-button pr-3 pl-3 pt-3">
                <span className="icon iTransfer"></span>
              </div>
              <div className="account-detail">
                <div className="d-flex align-items-center row-hug justify-content-between align-items-center">
                  <label className="text-content fz_14 text--light">
                    {t('txt-label-target')}
                  </label>
                  {listTarget && !!listTarget.length && (
                    <ReactSelect
                      onChange={handleChangeUserTarget}
                      options={[...listTarget]}
                      className="filter-control-select"
                      classNamePrefix="filter-control-select"
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.subAcntNo}
                      defaultValue={listTarget[0]}
                      value={defTarget}
                      isSearchable={false}
                    />
                  )}
                  <div className="d-flex reload-button btn--refresh">
                    <span
                      className="icon iReload"
                      onClick={handleLoadCashBalanceTarget}
                    ></span>
                  </div>
                </div>
                <div className="row d-flex justify-content-between align-items-center">
                  <span>
                    {t('transaction.cashInternalTransfer.moneyAvailable')}
                  </span>
                  <span className="text--light fw-500">
                    {targetBalance &&
                      numberFormat(targetBalance?.balance, 0, '0')}
                  </span>
                </div>
                <div className="row d-flex justify-content-between align-items-center">
                  <span>
                    {t('transaction.cashInternalTransfer.withdrawable')}
                  </span>
                  <span className="text--light fw-500">
                    {sourceBalance &&
                      numberFormat(targetBalance?.wdrawAvail, 0, '0')}
                  </span>
                </div>
              </div>
            </div>
            <FormChuyenTien _defSource={defSource} _defTarget={defTarget} />
          </div>
        </div>
        <div className="cashTransfer-history mt-24">
          <div className="history-title high-label justify-content-between d-flex">
            <span className="text-white text-title">
              {t('txt-label-history')}
            </span>
            <div className="filter-control-calendar">
              <FormHisCashEx handleQuery={handleLoadListTransfer} />
            </div>
          </div>
          <div className="history-body">
            <PerfectScrollBar>
              <PaginationTable
                t
                defaultAccount={defSource}
                columns={columns}
                source={hisTransfer && hisTransfer.list ? hisTransfer.list : []}
                hasPaging
              />
            </PerfectScrollBar>
          </div>
        </div>
      </div>
    </div>
  );
});

export default ChuyenTienTieuKhoan;
