import {
  call,
  put,
  takeLatest,
  all,
  select,
  takeEvery,
  takeLeading,
} from 'redux-saga/effects';
import { handleApiErrors } from '../../lib/api-errors';
import {
  GET_BOND_ORDERLIST_REQUEST,
  SET_BOND_DETAIL_MODAL,
  GET_BOND_DEALLIST_REQUEST,
  GET_BOND_PORTFOLIO_REQUEST,
  GET_BOND_DETAIL_REQUEST,
  GET_BOND_DETAIL_GUARANTEE_MODAL,
  GET_BOND_DETAIL_GUARANTEE_MODAL_SUCCESS,
  GET_BOND_DETAIL_REQUEST_SUCCESS,
  GET_BOND_DETAIL_REQUEST_ERROR,
  GET_BOND_PRODUCTS_REQUEST,
  GET_BOND_PRODUCTS_REQUEST_SUCCESS,
  GET_BOND_PRODUCTS_REQUEST_ERROR,
  GET_BOND_ISSUERS_REQUEST,
  GET_BOND_ISSUERS_REQUEST_ERROR,
  GET_BOND_ISSUERS_REQUEST_SUCCESS,
  GET_BOND_PREORDER_REQUEST,
  GET_PROD_IMCOME_FLOW_REQUEST,
  GET_BOND_PREORDER_REQUEST_ERROR,
  GET_BOND_PREORDER_REQUEST_SUCCESS,
  GET_DEAL4SALE_REQUEST_SUCCESS,
  GET_DEAL4SALE_REQUEST_ERROR,
  GET_DEAL4SALE_REQUEST,
  GET_BOND_RATE_CHART_REQUEST_ERROR,
  GET_BOND_RATE_CHART_REQUEST_SUCCESS,
  GET_BOND_RATE_CHART_REQUEST,
  SELL_ORDER_REQUEST,
  SELL_ORDER_REQUEST_ERROR,
  SELL_ORDER_REQUEST_SUCCESS,
  BUY_ORDER_REQUEST,
  BUY_ORDER_REQUEST_SUCCESS,
  BUY_ORDER_REQUEST_ERROR,
  GET_BOND_FIX_REQUEST,
  GET_BOND_FIX_REQUEST_SUCCESS,
  GET_BOND_COMMON_REQUEST,
  GET_BOND_COMMON_REQUEST2,
  GET_BOND_COMMON_REQUEST3,
} from './constants';

import {
  getBondOrderListSucces,
  getBondOrderListError,
  getBondPortfolioSuccess,
  getBondPortfolioError,
  getProIncomeFlowSuccess,
  getProIncomeFlowError,
  getCollateralDetail,
} from './action';

import { getMessage } from '../../lib/selector';
import { removeCookie } from '../../lib/storages';
import { v4 as uuidv4 } from 'uuid';
import { setToast, unsetClient } from '../client/actions';
import i18n from '../../i18n';
import { LOCK_RESENT_OTP } from '../../components/modal/auth/constants';
import { Logout, logoutRequest } from '../../components/modal/login/actions';
import { lockResendOtp } from '../../components/modal/auth/actions';

const appUrl = `${process.env.REACT_APP_API_URL}`;

function handleRequest(request) {
  return request
    .then(handleApiErrors)
    .then((response) => response.json())
    .then((json) => json)
    .catch((error) => {
      throw error;
    });
}

function* paramsAppend(params) {
  const uuid = uuidv4();
  const token = yield select((state) => state.client.token);
  const _params = {
    ...params,
    user: token?.user,
    session: token?.session,
    group: 'CORE',
    channel: 'WTS',
    rqId: uuid,
  };
  return _params;
}

function toastHandle(message, title = i18n.t('txt-notice')) {
  const toastMsg = {
    id: Math.random(),
    msg: message,
    title: title,
  };
  return put(setToast(toastMsg));
}

function bondRequestApi(data) {
  const url = `${appUrl}/BondServlet.pt`;
  const request = fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
    body: JSON.stringify(data),
  });
  return handleRequest(request);
}

function getBondDealListApi(data) {
  const url = `${appUrl}/BondServlet.pt`;
  const request = fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
    body: JSON.stringify(data),
  });
  return handleRequest(request);
}

function getBondPortfolioListApi(data) {
  const url = `${appUrl}/BondServlet.pt`;
  const request = fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
    body: JSON.stringify(data),
  });
  return handleRequest(request);
}

function* getBondOrderListFlow({ params }) {
  console.log('params', params);
  try {
    const resData = yield call(bondRequestApi, params);
    if (resData.http !== 200) {
      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');
        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }
      const msgErr = yield select(getMessage, resData);
      throw Error(msgErr);
    }
    yield put(getBondOrderListSucces(resData.data));
  } catch (error) {
    yield put(getBondOrderListError(error));
  }
}

function* bondDetailRequestFlow(action) {
  try {
    const { params } = action;

    const bondDetails = yield call(bondRequestApi, params);
    if (bondDetails.http !== 200) {
      const msgErr = yield select(getMessage, bondDetails);
      yield put({ type: GET_BOND_DETAIL_REQUEST_ERROR, error: msgErr });
    } else {
      yield put({
        type: GET_BOND_DETAIL_REQUEST_SUCCESS,
        data: bondDetails.data,
      });
    }
  } catch (error) {
    yield put({ type: GET_BOND_DETAIL_REQUEST_ERROR, error });
  }
}

function* getBondGuaranteeFlow(params) {
  try {
    const resData = yield call(bondRequestApi, params.data);
    if (resData.http !== 200) {
      const msgErr = yield select(getMessage, resData);
      yield toastHandle(msgErr);
    } else {
      yield put({
        type: GET_BOND_DETAIL_GUARANTEE_MODAL_SUCCESS,
        data: resData.data,
      });
    }
  } catch (error) {
    console.error(error);
  }
}

function* bondProductRequestFlow(action) {
  try {
    //Todo: get list bond product
    const { params } = action;
    const bondProducts = yield call(bondRequestApi, params);

    if (bondProducts.http !== 200) {
      const msgErr = yield select(getMessage, bondProducts);
      yield put({ type: GET_BOND_PRODUCTS_REQUEST_ERROR, error: msgErr });
    }

    yield put({
      type: GET_BOND_PRODUCTS_REQUEST_SUCCESS,
      data: bondProducts.data,
    });
  } catch (error) {
    yield put({ type: GET_BOND_PRODUCTS_REQUEST_ERROR, error });
  }
}

function* bondIssuerRequestFlow(action) {
  try {
    const { params } = action;
    const bondIssuers = yield call(bondRequestApi, params);
    console.log('bondIssuers ===> ', bondIssuers);

    if (bondIssuers.http !== 200) {
      const msgErr = yield select(getMessage, bondIssuers);
      yield put({ type: GET_BOND_ISSUERS_REQUEST_ERROR, error: msgErr });
    }

    yield put({
      type: GET_BOND_ISSUERS_REQUEST_SUCCESS,
      data: bondIssuers.data,
    });
  } catch (error) {
    yield put({ type: GET_BOND_ISSUERS_REQUEST_ERROR, error });
  }
}

function* getBondDealListFlow({ params, callback }) {
  try {
    debugger;
    const resData = yield call(getBondDealListApi, params);
    if (resData.http !== 200) {
      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');
        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }
      const msgErr = yield select(getMessage, resData);
      yield toastHandle(msgErr);
    } else {
      if (callback && typeof callback == 'function') {
        callback(resData.data);
      }
    }
  } catch (error) {
    console.error(error);
  }
}

function* getBondPortfolioListFlow({ params }) {
  try {
    const resData = yield call(getBondPortfolioListApi, params);
    if (resData.http !== 200) {
      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');
        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }
      const msgErr = yield select(getMessage, resData);
      throw Error(msgErr);
    }
    yield put(getBondPortfolioSuccess(resData.data));
  } catch (error) {
    yield put(getBondPortfolioError(error));
  }
}

function* bondPreOrderRequestFlow({ params }) {
  try {
    const token = yield select((state) => state.client.token);
    const _params = { ...params, user: token?.user, session: token?.session };
    const preOrder = yield call(bondRequestApi, _params);

    if (preOrder.http !== 200) {
      const msgErr = yield select(getMessage, preOrder);
      yield put({ type: GET_BOND_PREORDER_REQUEST_ERROR, error: msgErr });
    }

    yield put({
      type: GET_BOND_PREORDER_REQUEST_SUCCESS,
      data: preOrder.data,
    });
  } catch (error) {
    yield put({ type: GET_BOND_PREORDER_REQUEST_ERROR, error });
  }
}

function* prodIncomeFlow({ params }) {
  try {
    const resData = yield call(bondRequestApi, params);
    if (resData.http !== 200) {
      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');
        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }
      const msgErr = yield select(getMessage, resData);
      throw Error(msgErr);
    }
    yield put(getProIncomeFlowSuccess(resData.data));
  } catch (error) {
    yield put(getProIncomeFlowError(error));
  }
}

function* getDeal4SaleFlow({ params }) {
  try {
    const token = yield select((state) => state.client.token);
    const _params = { ...params, user: token?.user, session: token?.session };

    const resData = yield call(bondRequestApi, _params);
    if (resData.http !== 200) {
      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');
        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }
      const msgErr = yield select(getMessage, resData);
      put({ type: GET_DEAL4SALE_REQUEST_ERROR, msgErr });
    }
    yield put({ type: GET_DEAL4SALE_REQUEST_SUCCESS, data: resData.data });
  } catch (error) {
    yield put({ type: GET_DEAL4SALE_REQUEST_ERROR, error });
  }
}

function* bondRateChartRequestFlow(action) {
  try {
    const { params } = action;
    const bondRateChart = yield call(bondRequestApi, params);

    if (bondRateChart.http !== 200) {
      const msgErr = yield select(getMessage, bondRateChart);
      yield put({ type: GET_BOND_RATE_CHART_REQUEST_ERROR, error: msgErr });
    }

    yield put({
      type: GET_BOND_RATE_CHART_REQUEST_SUCCESS,
      data: bondRateChart.data,
    });
  } catch (error) {
    yield put({ type: GET_BOND_RATE_CHART_REQUEST_ERROR, error });
  }
}

function* sellOrderRequestFlow({ params, callback }) {
  try {
    const resData = yield call(bondRequestApi, params);
    if (resData.http !== 200) {
      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');
        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }
      if (resData.data.messageNo === 99991) {
        yield put({ type: LOCK_RESENT_OTP });
        return;
      }
      const msgErr = yield select(getMessage, resData);
      yield put({ type: SELL_ORDER_REQUEST_ERROR, error: msgErr });
    } else {
      yield put({ type: SELL_ORDER_REQUEST_SUCCESS });
      if (callback && typeof callback == 'function') {
        callback();
      }
    }
  } catch (error) {
    yield put(
      setToast({
        id: Math.random(),
        msg: i18n.t('user.fail', { ActionType: i18n.t('txt-order') }),
        title: i18n.t('txt-notice'),
      })
    );
  }
}

function* buyOrderRequestFlow({ params }) {
  try {
    const resData = yield call(bondRequestApi, params);
    if (resData.http !== 200) {
      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');
        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }

      if (resData.data.messageNo === 99991) {
        yield put({ type: LOCK_RESENT_OTP });
        return;
      }

      const msgErr = yield select(getMessage, resData);
      yield put({ type: BUY_ORDER_REQUEST_ERROR, error: msgErr });
    } else {
      yield put({ type: BUY_ORDER_REQUEST_SUCCESS });
    }
  } catch (error) {
    console.error(error);
  }
}

function* bondFixRequestFlow({ params }) {
  try {
    const resData = yield call(bondRequestApi, params);
    if (resData.http !== 200) {
      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');
        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }

      if (resData.data.messageNo === 99991) {
        yield put({ type: LOCK_RESENT_OTP });
        return;
      }

      const msgErr = yield select(getMessage, resData);
      yield toastHandle(msgErr);
    } else {
      yield put({
        type: GET_BOND_FIX_REQUEST_SUCCESS,
        data: resData.data,
      });
    }
  } catch (error) {
    console.error(error);
  }
}

/**
 * This func try to aim for covering almost call bond api cases
 * @param {isProtect} param0 // this param help to protect private api (user data - must to authentication)
 */
function* getBondCommonRequestFlow({ params, callback, isProtect }) {
  try {
    const resData = yield call(bondRequestApi, params);
    const token = yield select((state) => state.client.token);

    if (resData.http !== 200) {
      let toastMsg = '';

      if (resData.http === 401) {
        if (isProtect) {
          if (token) {
            put(logoutRequest(token));
            put(unsetClient());
            put(Logout());
          }
          removeCookie('token');
          removeCookie('authen');
          yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
        }

        toastMsg = i18n.t('txt-valid-loss-session');
      } else {
        if (resData.data.messageNo === 99991) {
          put(lockResendOtp());
        } else {
          const msgErr = yield select(getMessage, resData);
          toastMsg = msgErr;
        }
      }

      yield toastHandle(toastMsg);
    } else {
      if (callback && typeof callback == 'function') {
        callback(resData.data);
      }
    }
  } catch (error) {
    console.error(error);
  }
}

function* bondWatcher() {
  yield all([takeLatest(GET_BOND_ORDERLIST_REQUEST, getBondOrderListFlow)]);
  yield all([takeEvery(GET_BOND_DEALLIST_REQUEST, getBondDealListFlow)]);
  yield all([takeLatest(GET_BOND_PORTFOLIO_REQUEST, getBondPortfolioListFlow)]);
  yield all([takeLatest(GET_BOND_DETAIL_REQUEST, bondDetailRequestFlow)]);
  yield all([
    takeLatest(GET_BOND_DETAIL_GUARANTEE_MODAL, getBondGuaranteeFlow),
  ]);
  yield all([takeLatest(GET_BOND_PRODUCTS_REQUEST, bondProductRequestFlow)]);
  yield all([takeLatest(GET_BOND_ISSUERS_REQUEST, bondIssuerRequestFlow)]);
  yield all([takeLatest(GET_BOND_PREORDER_REQUEST, bondPreOrderRequestFlow)]);
  yield all([takeLatest(GET_PROD_IMCOME_FLOW_REQUEST, prodIncomeFlow)]);
  yield all([takeLatest(GET_DEAL4SALE_REQUEST, getDeal4SaleFlow)]);
  yield all([
    takeLatest(GET_BOND_RATE_CHART_REQUEST, bondRateChartRequestFlow),
  ]);
  yield all([takeLeading(SELL_ORDER_REQUEST, sellOrderRequestFlow)]);
  yield all([takeLeading(BUY_ORDER_REQUEST, buyOrderRequestFlow)]);
  yield all([takeLatest(GET_BOND_FIX_REQUEST, bondFixRequestFlow)]);
  yield all([takeEvery(GET_BOND_COMMON_REQUEST, getBondCommonRequestFlow)]);
  yield all([takeLatest(GET_BOND_COMMON_REQUEST2, getBondCommonRequestFlow)]);
  yield all([takeLeading(GET_BOND_COMMON_REQUEST3, getBondCommonRequestFlow)]);
}

export default bondWatcher;
