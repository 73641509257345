import {
  CDKT_REQUESTING,
  CDKT_REQUEST_ERROR,
  CDKT_REQUEST_SUCCESS,
  COMPANY_INFO_REQUESTING,
  COMPANY_INFO_REQUEST_ERROR,
  COMPANY_INFO_REQUEST_SUCCESS,
  CST_REQUESTING,
  CST_REQUEST_ERROR,
  CST_REQUEST_SUCCESS,
  KQKD_REQUESTING,
  KQKD_REQUEST_ERROR,
  KQKD_REQUEST_SUCCESS,
  STOCK_DETAIL_REQUESTING,
  STOCK_DETAIL_REQUEST_ERROR,
  STOCK_DETAIL_REQUEST_RESET,
  STOCK_DETAIL_REQUEST_SUCCESS,
  STOCK_EVENTS_REQUESTING,
  STOCK_EVENTS_REQUEST_ERROR,
  STOCK_EVENTS_REQUEST_SUCCESS,
  STOCK_HIS_REQUESTING,
  STOCK_HIS_REQUEST_ERROR,
  STOCK_HIS_REQUEST_SUCCESS,
  STOCK_MANAGEMENT_REQUESTING,
  STOCK_MANAGEMENT_REQUEST_ERROR,
  STOCK_MANAGEMENT_REQUEST_SUCCESS,
  STOCK_NEWS_REQUESTING,
  STOCK_NEWS_REQUEST_ERROR,
  STOCK_NEWS_REQUEST_SUCCESS,
  STOCK_TRADE_REQUESTING,
  STOCK_TRADE_REQUEST_ERROR,
  STOCK_TRADE_REQUEST_SUCCESS,
} from './constants';

const initialState = {
  stockDetail: {},
  stockDetailRequesting: false,
  stockDetailSuccessful: false,
  stockDetailErrors: [],

  stockTrade: {},
  stockTradeRequesting: false,
  stockTradeSuccessful: false,
  stockTradeErrors: [],

  cst: {},
  cstRequesting: false,
  cstSuccessful: false,
  cstErrors: [],

  kqkd: {},
  kqkdRequesting: false,
  kqkdSuccessful: false,
  kqkdErrors: [],

  cdkt: {},
  cdktRequesting: false,
  cdktSuccessful: false,
  cdktErrors: [],

  stockHis: [],
  stockHisRequesting: false,
  stockHisSuccessful: false,
  stockHisErrors: [],

  stockNews: {},
  stockNewsRequesting: false,
  stockNewsSuccessful: false,
  stockNewsErrors: [],

  newsDetail: {},
  newsDetailRequesting: false,
  newsDetailSuccessful: false,
  newsDetailErrors: [],

  stockEvents: {},
  stockEventsRequesting: false,
  stockEventsSuccessful: false,
  stockEventsErrors: [],

  stockManage: {},
  stockManageRequesting: false,
  stockManageSuccessful: false,
  stockManageErrors: [],

  companyInfo: {},
  companyInfoRequesting: false,
  companyInfoSuccessful: false,
  companyInfoErrors: [],
};

const reducer = function widgetReducer(state = initialState, action) {
  switch (action.type) {
    case STOCK_DETAIL_REQUESTING:
      return {
        ...state,
        stockDetail: {},
        stockDetailRequesting: true,
        stockDetailSuccessful: false,
        stockDetailErrors: [],
      };

    case STOCK_DETAIL_REQUEST_SUCCESS:
      return {
        ...state,
        stockDetail: action.resData,
        stockDetailRequesting: false,
        stockDetailSuccessful: true,
        stockDetailErrors: [],
      };

    case STOCK_DETAIL_REQUEST_ERROR:
      return {
        ...state,
        stockDetail: {},
        stockDetailRequesting: false,
        stockDetailSuccessful: false,
        stockDetailErrors: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };
    case STOCK_DETAIL_REQUEST_RESET:
      return {
        ...state,
        stockDetail: {},
        stockDetailRequesting: false,
        stockDetailSuccessful: false,
        stockDetailErrors: [],
      };

    case STOCK_TRADE_REQUESTING:
      return {
        ...state,
        stockTradeRequesting: true,
        stockTradeSuccessful: false,
        stockTradeErrors: [],
      };

    case STOCK_TRADE_REQUEST_SUCCESS:
      return {
        ...state,
        stockTrade: action.resData,
        stockTradeRequesting: false,
        stockTradeSuccessful: true,
        stockTradeErrors: [],
      };

    case STOCK_TRADE_REQUEST_ERROR:
      return {
        ...state,
        stockTradeRequesting: false,
        stockTradeSuccessful: false,
        stockTradeErrors: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };

    case CST_REQUESTING:
      return {
        ...state,
        cstRequesting: true,
        cstSuccessful: false,
        cstErrors: [],
      };

    case CST_REQUEST_SUCCESS:
      return {
        ...state,
        cst: action.resData,
        cstRequesting: false,
        cstSuccessful: true,
        cstErrors: [],
      };

    case CST_REQUEST_ERROR:
      return {
        ...state,
        cstRequesting: false,
        cstSuccessful: false,
        cstErrors: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };

    case KQKD_REQUESTING:
      return {
        ...state,
        kqkdRequesting: true,
        kqkdSuccessful: false,
        kqkdErrors: [],
      };

    case KQKD_REQUEST_SUCCESS:
      return {
        ...state,
        kqkd: action.resData,
        kqkdRequesting: false,
        kqkdSuccessful: true,
        kqkdErrors: [],
      };

    case KQKD_REQUEST_ERROR:
      return {
        ...state,
        kqkdRequesting: false,
        kqkdSuccessful: false,
        kqkdErrors: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };

    case CDKT_REQUESTING:
      return {
        ...state,
        cdktRequesting: true,
        cdktSuccessful: false,
        cdktErrors: [],
      };

    case CDKT_REQUEST_SUCCESS:
      return {
        ...state,
        cdkt: action.resData,
        cdktRequesting: false,
        cdktSuccessful: true,
        cdktErrors: [],
      };

    case CDKT_REQUEST_ERROR:
      return {
        ...state,
        cdktRequesting: false,
        cdktSuccessful: false,
        cdktErrors: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };

    case STOCK_HIS_REQUESTING:
      return {
        ...state,
        stockHis: null,
        stockHisRequesting: true,
        stockHisSuccessful: false,
        stockHisErrors: [],
      };

    case STOCK_HIS_REQUEST_SUCCESS:
      return {
        ...state,
        stockHis: Array.isArray(action.resData) ? action.resData : [],
        stockHisRequesting: false,
        stockHisSuccessful: true,
        stockHisErrors: [],
      };

    case STOCK_HIS_REQUEST_ERROR:
      return {
        ...state,
        stockHis: [],
        stockHisRequesting: false,
        stockHisSuccessful: false,
        stockHisErrors: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };

    case STOCK_NEWS_REQUESTING:
      return {
        ...state,
        stockNewsRequesting: true,
        stockNewsSuccessful: false,
        stockNewsErrors: [],
      };

    case STOCK_NEWS_REQUEST_SUCCESS:
      return {
        ...state,
        stockNews: action.resData.data,
        stockNewsRequesting: false,
        stockNewsSuccessful: true,
        stockNewsErrors: [],
      };

    case STOCK_NEWS_REQUEST_ERROR:
      return {
        ...state,
        stockNewsRequesting: false,
        stockNewsSuccessful: false,
        stockNewsErrors: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };

    case STOCK_EVENTS_REQUESTING:
      return {
        ...state,
        stockEventsRequesting: true,
        stockEventsSuccessful: false,
        stockEventsErrors: [],
      };

    case STOCK_EVENTS_REQUEST_SUCCESS:
      const data = action.resData;
      function compare(a, b) {
        if (a.DateOrder > b.DateOrder) {
          return -1;
        }
        if (a.DateOrder < b.DateOrder) {
          return 1;
        }
        return 0;
      }

      data.sort(compare);

      return {
        ...state,
        stockEvents: data,
        stockEventsRequesting: false,
        stockEventsSuccessful: true,
        stockEventsErrors: [],
      };

    case STOCK_EVENTS_REQUEST_ERROR:
      return {
        ...state,
        stockEventsRequesting: false,
        stockEventsSuccessful: false,
        stockEventsErrors: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };

    case STOCK_MANAGEMENT_REQUESTING:
      return {
        ...state,
        stockManageRequesting: true,
        stockManageSuccessful: false,
        stockManageErrors: [],
      };

    case STOCK_MANAGEMENT_REQUEST_SUCCESS:
      return {
        ...state,
        stockManage: action.resData,
        stockManageRequesting: false,
        stockManageSuccessful: true,
        stockManageErrors: [],
      };

    case STOCK_MANAGEMENT_REQUEST_ERROR:
      return {
        ...state,
        stockManageRequesting: false,
        stockManageSuccessful: false,
        stockManageErrors: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };

    case COMPANY_INFO_REQUESTING:
      return {
        ...state,
        companyInfoRequesting: true,
        companyInfoSuccessful: false,
        companyInfoErrors: [],
      };

    case COMPANY_INFO_REQUEST_SUCCESS:
      return {
        ...state,
        companyInfo: action.resData,
        companyInfoRequesting: false,
        companyInfoSuccessful: true,
        companyInfoErrors: [],
      };

    case COMPANY_INFO_REQUEST_ERROR:
      return {
        ...state,
        companyInfoRequesting: false,
        companyInfoSuccessful: false,
        companyInfoErrors: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };

    case 'CLEAR_CACHE_STOCK_DETAIL':
      return initialState;

    default:
      return state;
  }
};

export default reducer;
