import React from 'react';
import { reduce, sumBy } from 'lodash';
import { Button, Card, Modal } from 'react-bootstrap';
import { translate } from 'react-i18next';
import { FaTimes } from 'react-icons/fa';
import ReactModal from 'react-modal';
import { connect } from 'react-redux';
import ClipLoader from 'react-spinners/ClipLoader';
import { v4 as uuidv4 } from 'uuid';
import { unsetClient } from '../../../containers/client/actions';
import { handleApiErrors } from '../../../lib/api-errors';
import { makeGetListAccount, makeGetToken } from '../../../lib/selector';
import { removeCookie } from '../../../lib/storages';
import {
  StringToInt,
  _formatDate,
  _formatDate2,
  numberFormat,
} from '../../../util';
import { Logout, logoutRequest } from '../../modal/login/actions';
import SelectUserCash from '../../select/selectUserCash';

ReactModal.setAppElement('#root');

const customStyles = {
  content: {
    top: '80px',
    left: '50%',
    width: '675px',
    transform: 'translate(-50%)',
    bottom: 'auto',
  },
  overlay: {
    backgroundColor: 'transparent',
  },
};

// const override = css`
//   display: block;
//   margin: 0 auto;
//   border-color: red;
// `;

const appUrl = `${process.env.REACT_APP_API_URL}`;
let controller = null;

class CashAdvance extends React.Component {
  state = {
    _totalFee: 0,
    _totalAdv: 0,
    isLoading: true,
    showData: {},
  };

  componentDidMount() {
    const { advMatch } = this.props;
    if (!controller) controller = new AbortController();
    if (advMatch) {
      this.setState({ showData: advMatch }, () => {
        this.handleAdvanceFee();
      });
    }
  }

  componentDidUpdate(preProps) {
    const { advMatch, showModal } = this.props;
    if (advMatch && advMatch !== preProps.advMatch) {
      if (controller) controller.abort();
      this.handleAdvanceFee();
    }

    if (showModal && showModal !== preProps.showModal) {
      if (controller) controller.abort();
    }
  }

  _handleCloseModal = () => {
    const { handleCloseModal } = this.props;
    if (controller) controller.abort();
    controller = null;
    handleCloseModal();
    document.body.style.cursor = 'default';
  };

  calTotal = () => {
    const { showData } = this.state;
    const _totalAdv = showData && sumBy(showData, (o) => StringToInt(o.amt));

    const _totalFee =
      showData &&
      reduce(
        showData,
        function (sum, o) {
          return sum + o._calcInt;
        },
        0
      );

    this.setState({ _totalFee, _totalAdv });
  };

  handleAdvanceFee = async () => {
    const { token, account, advMatch } = this.props;
    document.body.style.cursor = 'wait';

    if (!advMatch) return;
    try {
      advMatch.forEach((element) => {
        const _amt = StringToInt(element.amt);
        if (_amt > 0) {
          const uuid = uuidv4();
          const resData = {
            group: 'CORE',
            user: token.user,
            session: token.session,
            cmd: 'getAvailAdvance',
            rqId: uuid,
            channel: 'WTS',
            data: {
              acntNo: account.acntNo,
              subAcntNo: account.subAcntNo,
              ordrDt: _formatDate(element.ordrDt),
              amt: _amt + '',
              type: '2', // 1.include 2.exclude
            },
          };

          this.handleAdvFeeApi(resData, element.key);
        }
      });
      // await waitFor(200);
    } catch (error) {
      console.error(error);
    }
  };

  handleAdvFeeApi = (params, key) => {
    const url = `${appUrl}/CoreServlet.pt`;
    const signal = controller.signal;

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
      body: JSON.stringify(params),
      signal,
    })
      .then(handleApiErrors)
      .then((response) => response.json())
      .then((res) => {
        if (res.http !== 200) {
          if (res.http === 401) {
            const { token } = this.props;
            if (token) this.props.dispatch(logoutRequest(token));

            this.props.dispatch(unsetClient());
            removeCookie('token');
            removeCookie('authen');

            this.props.dispatch(Logout());
          }

          return;
        }
        // process res
        let { showData } = this.state;

        showData.map((item) => {
          if (item.key !== key) return item;

          item._calcInt = res.data.calcInt;
          item.leftAvailAdvc = res.data.leftAvailAdvc;
          return item;
        });
        this.setState({ showData, isLoading: false }, () => {
          document.body.style.cursor = 'default';
          this.calTotal();
        });
      })
      .catch((error) => {
        console.error('Error:', error);
      })
      .finally(() => {
        document.body.style.cursor = 'default';
      });
  };

  render() {
    const { showModal, handleAcceptLoan, account, listAccount, t, theme } =
      this.props;

    const { _totalFee, _totalAdv, isLoading, showData } = this.state;

    return (
      <Modal
        show={showModal}
        dialogClassName="wts-modal align-items-center"
        backdropClassName="wts-modal-backdrop"
        contentClassName={`${
          theme?.name || 'dark'
        } market-index-modal wts-modal-content--lg`}
        onEscapeKeyDown={this._handleCloseModal}
        onRequestClose={this._handleCloseModal}
        shouldCloseOnOverlayClick={true}
        style={customStyles}
      >
        <div className="confirm-order">
          <div className="confirm-order-header text text--light text-center fz_14 fw-500">
            {t('txt-cash-advance')}
          </div>
          <div className="confirm-order-body">
            <div className="p-0 panel-cash-advance ">
              <div className="p-3 d-flex justify-content-between align-items-center">
                <span>{t('txt-sub-account')}</span>
                <SelectUserCash
                  defVal={account}
                  grOptions={listAccount}
                  isDisabled={true}
                  // handleChangeUser={this._handleChangeUser}
                  clsContainer="selCashOut"
                />
              </div>
              <div className="mr-3 ml-3 pb-3">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th className="text-center">
                        {t('txt-label-sell-date')}
                      </th>
                      <th className="text-center">
                        {t('txt-label-settlement-date')}
                      </th>
                      <th className="text-center">
                        {t('txt-label-trade-amount-af-fee')}
                      </th>
                      <th className="text-center">{t('txt-label-fee')}</th>
                      <th className="text-center">
                        {t('txt-label-cash-advance')}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {showData &&
                      !!showData.length &&
                      showData.map((item) => (
                        <tr key={item.key}>
                          <td className="text-center text--light3">
                            {_formatDate2(item.ordrDt)}
                          </td>
                          <td className="text-center text--light3">
                            {_formatDate2(item.exprDt)}
                          </td>
                          <td className="text-right  text--light">
                            {numberFormat(item.advanceAvail, 0, '0')}
                          </td>
                          <td className="text-right fw-500  text--light">
                            {isLoading ? (
                              <span>
                                <ClipLoader
                                  color={'#082F5C'}
                                  loading={isLoading}
                                  size={10}
                                />{' '}
                                {t('txt-calculating-fee')}
                              </span>
                            ) : (
                              <span>{numberFormat(item._calcInt, 0, '0')}</span>
                            )}
                          </td>
                          <td className="text-right fw-500  text--light">
                            {item.amt}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              <div className="d-flex flex-row-reverse pb-3 pr-3 pl-3 pt-3">
                <div>
                  <span className="content-read fz_14 mr-2">
                    {t('txt-label-total')}
                  </span>
                  <span className="i fz_14 fw-500">
                    {isLoading ? (
                      <span>
                        <ClipLoader
                          color={'#082F5C'}
                          loading={isLoading}
                          size={10}
                        />
                      </span>
                    ) : (
                      numberFormat(_totalAdv, 0, '0')
                    )}
                  </span>
                </div>
                <div className="mr-3">
                  <span className="content-read fz_14 mr-2">{`${t(
                    'txt-label-total'
                  )} ${t('txt-label-fee')}`}</span>
                  <span className="fz_14 fw-500">
                    {isLoading ? (
                      <span>
                        <ClipLoader
                          color={'#082F5C'}
                          loading={isLoading}
                          size={10}
                        />
                      </span>
                    ) : (
                      numberFormat(_totalFee, 0, '0')
                    )}
                  </span>
                </div>
              </div>
              <div className="d-flex align-items-center pb-3 pr-3 pl-3 pt-3 justify-content-center">
                <div className="d-flex justify-content-between w-100">
                  <button
                    onClick={() => this._handleCloseModal()}
                    className="fw-500 fz_14 btn btn--cancel w-25"
                    style={{ border: 'none' }}
                  >
                    {t('txt-cancel')}
                  </button>
                  <button
                    disabled={isLoading}
                    onClick={() => handleAcceptLoan()}
                    style={{ border: 'none' }}
                    className="fw-500 fz_14 btn btn--primary w-75 ml-2"
                  >
                    {isLoading ? t('txt-calculating-fee') : t('txt-accept')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const makeMapStateToProps = () => {
  const getToken = makeGetToken();
  const getListAccount = makeGetListAccount();

  const mapStateToProps = (state, props) => {
    return {
      token: getToken(state),
      listAccount: getListAccount(state),
      theme: state.client.currentTheme,
    };
  };
  return mapStateToProps;
};

CashAdvance = connect(makeMapStateToProps)(CashAdvance);

export default translate('translations')(CashAdvance);
