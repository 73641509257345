import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { withNamespaces } from 'react-i18next';
import '../index.scss';
import { v4 as uuidv4 } from 'uuid';
import { handleApiErrors } from '../../../../lib/api-errors';
import AuthenStep from '../../../../components/common/auth';
import {
  PARTNER_FEE_POLICY,
  PARTNER_REQUEST_ADD_REMOVE_TYPE,
  PARTNER_REQUEST_APPROVE_REJECT_TYPE,
  PARTNER_REQUEST_TYPE,
  PARTNER_SERVICE_TYPE,
  PARTNER_SIDE_REQUEST,
} from '../../../../util/constant';
import { getCommonRequest } from '../../../customer/actions';
import { setToast } from '../../../client/actions';
import { numberFormat } from '../../../../util';
import ViewFeeDefault from '../partnership/viewFeeDefault';

const ModalRemovePartner = withNamespaces('translations')(
  ({
    t,
    showModal,
    handleClose,
    isView,
    isCustomer,
    isConfirm,
    dataPartner,
    handleLoadData,
  }) => {
    const dispatch = useDispatch();
    const theme = useSelector((state) => state.client.currentTheme);
    const {
      token,
      setting: { lang = 'vi' },
    } = useSelector((state) => state.client);
    const [oneTimeToken, setOneTimeToken] = useState();
    const defaultAccount = useSelector((state) => state.socket.defaultAccount);
    const [listFee, setListFee] = useState();

    const _handleGetFeeDefault = () => {
      if (!token) return;
      const uuid = uuidv4();
      const resData = {
        group: 'CORE',
        user: token.user,
        session: token.session,
        cmd: 'getFeePolicy',
        rqId: uuid,
        channel: 'WTS',
        data: {
          cif: token.cif,
          acntNo: isCustomer ? defaultAccount.acntNo : dataPartner?.acntNo,
          grpAcctTp: String(PARTNER_FEE_POLICY.currentForCustomer),
          serTp: PARTNER_SERVICE_TYPE.all,
          langTp: lang,
        },
      };

      const callback = (data) => {
        setListFee(data);
      };

      dispatch(getCommonRequest(resData, callback, true));
    };

    useEffect(() => {
      _handleGetFeeDefault();
    }, [dataPartner]);

    const toastHandle = (msg, title = t('txt-notice')) => {
      const toastMsg = {
        id: Math.random(),
        msg: msg,
        title: title,
      };
      dispatch(setToast(toastMsg));
    };

    const handleCreateRequest = () => {
      const uuid = uuidv4();
      const params = {
        group: 'CORE',
        user: token.user,
        session: token.session,
        cmd: 'regUnregRef',
        rqId: uuid,
        channel: 'WTS',
        token: oneTimeToken,
        type: '6',
        data: {
          cif: token.cif,
          acntNo: defaultAccount.acntNo,
          vsdAcntNo: isCustomer
            ? defaultAccount.vsdAcntNo
            : dataPartner?.vsdAcntNo,
          side: isCustomer
            ? PARTNER_SIDE_REQUEST.customer
            : PARTNER_SIDE_REQUEST.broker,
          brkId: dataPartner.brokerId || dataPartner.brokerID,
          reqTp: PARTNER_REQUEST_TYPE.cancelInviteBroker,
        },
      };
      const callback = (data) => {
        toastHandle(t('partner.createRequestSuccess'));
        handleClose();
        handleLoadData();
      };
      dispatch(getCommonRequest(params, callback, true));
    };

    const handleConfirmRequest = (type) => {
      const uuid = uuidv4();
      const params = {
        group: 'CORE',
        user: token.user,
        session: token.session,
        cmd: 'confRejReq',
        token: oneTimeToken,
        type: '6',
        rqId: uuid,
        channel: 'WTS',
        data: {
          cif: token.cif,
          acntNo: defaultAccount.acntNo,
          reqDt: dataPartner.reqDate,
          reqSeq: dataPartner.reqSeq,
          reqTp: PARTNER_REQUEST_ADD_REMOVE_TYPE.REMOVE,
          cfrmTp: type,
        },
      };

      const callback = (data) => {
        toastHandle(
          (type === PARTNER_REQUEST_APPROVE_REJECT_TYPE.APPROVE
            ? t('transaction.marginDebt.approved')
            : t('transaction.marginDebt.rejected')) +
            ' ' +
            t('txt-success').toLowerCase()
        );
        handleClose();
        handleLoadData();
      };

      dispatch(getCommonRequest(params, callback, true));
    };

    return (
      <Modal
        onHide={handleClose}
        onEscapeKeyDown={handleClose}
        show={showModal}
        dialogClassName="wts-modal"
        backdropClassName="wts-modal-backdrop"
        contentClassName={`${
          theme?.name || 'dark'
        } modal-add-customer wts-modal-content `}
        centered
        style={{ zIndex: 11114 }}
      >
        <div className={`modal-add-customer__header p-2 position-relative`}>
          <div className="text-center w-100">
            {isCustomer
              ? t('partner.removeBroker')
              : t('partner.removeCustomer')}
          </div>
          <div
            className="position-absolute icon-close"
            onClick={() => {
              handleClose();
            }}
          >
            <span className={'icon iClose'}></span>
          </div>
        </div>
        <div className="modal-add-customer__body page-partner-list">
          {isCustomer ? (
            <div className="partner-border-bottom p-3 ">
              <div className="w-100 d-flex">
                <div className="text text--sm text--light2 w-120">
                  {t('partner.referralCode')}
                </div>
                <div className="font-weight-bold text text--sm text--light">
                  {dataPartner?.brokerId}
                </div>
              </div>
              <div className="w-100 d-flex">
                <div className="text text--sm text--light2 w-120">
                  {t('partner.referralName')}
                </div>
                <div className="font-weight-bold text text--sm text--light">
                  {dataPartner?.makerNm}
                </div>
              </div>
            </div>
          ) : (
            <div className="partner-border-bottom p-3 ">
              <div className="w-100 d-flex">
                <div className="text text--sm text--light2 w-120">
                  {t('partner.VSDAccount')}
                </div>
                <div className="font-weight-bold text text--sm text--light">
                  {dataPartner?.vsdAcntNo}
                </div>
              </div>
              <div className="w-100 d-flex">
                <div className="text text--sm text--light2 w-120">
                  {t('txt-label-customer-name')}
                </div>
                <div className="font-weight-bold text text--sm text--light">
                  {dataPartner?.custNm}
                </div>
              </div>
            </div>
          )}
          <div className={'p-3'}>
            <ViewFeeDefault t={t} listFee={listFee} viewFull />
            {!isView && (
              <>
                <div className="mt-4">
                  <AuthenStep setOneTimeToken={setOneTimeToken} trackMixpanel />
                </div>
                {isConfirm ? (
                  <div className="d-flex gap-16">
                    <button
                      disabled={!oneTimeToken}
                      className={'btn btn--primary w-100 h-32 mt-4'}
                      onClick={() => {
                        handleConfirmRequest(
                          PARTNER_REQUEST_APPROVE_REJECT_TYPE.APPROVE
                        );
                      }}
                    >
                      Approve
                    </button>
                    <button
                      onClick={() => {
                        handleConfirmRequest(
                          PARTNER_REQUEST_APPROVE_REJECT_TYPE.REJECT
                        );
                      }}
                      className={'btn btn-danger-secondary w-100 h-32 mt-4 d'}
                    >
                      Reject
                    </button>
                  </div>
                ) : (
                  <div className="d-flex gap-16">
                    <button
                      onClick={() => {
                        handleCreateRequest();
                      }}
                      disabled={!oneTimeToken}
                      className={'btn btn--primary w-100 h-32 mt-4'}
                    >
                      {t('txt-confirm')}
                    </button>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </Modal>
    );
  }
);

export default ModalRemovePartner;
