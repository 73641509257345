import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { InputGroup } from 'react-bootstrap';

import { formatDate } from '../../../util';

import { makeGetToken } from '../../../lib/selector';

import { required } from '../../../util/validation';

import RenderDatePicker from '../../input/renderDatePicker';

import { ReactComponent as SvgTimePicker } from '../../../assets/img/_timerange.svg';
import { ReloadButton } from '../../btnReload';

const _status = ['Đến hạn', 'Chưa trả'];

class FormHisMargin extends Component {
  constructor() {
    super();

    this.state = {
      account: null,
    };
  }

  submit = (values) => {
    const { amount, content } = values;
    const { token } = this.props;

    // const _amount = StringToInt(amount.replace(',', ''));
    // if (_amount > 0) {
    //   const resData = {
    //     group: 'CORE',
    //     user: token.user,
    //     session: token.session,
    //     cmd: 'createCashTransfer',
    //     rqId: uuid,
    //     channel: 'WTS',
    //     data: {
    //       acntNo: _defSource.acntNo,
    //       subAcntNo: _defSource.subAcntNo,
    //       toAcntNo: _defTarget.subAcntNo,
    //       trdAmt: _amount + '',
    //       feeType: 1, // bao gom phi
    //       desc: content,
    //       transType: 1, // chuyen khoan noi bo
    //     },
    //   };

    //   this.props.dispatch(cashTransferRequest(resData));

    //   this.props.reset();
    // }
  };

  _handleReset = () => {
    const { reset } = this.props;

    reset();
    setTimeout(() => {
      if (this.refSubmit) this.refSubmit.click();
    }, 100);
  };

  render() {
    const {
      handleSubmit,
      pristine,
      submitting,
      reset,
      startDate,
      endDate,
    } = this.props;

    return (
      <form
        className="form-hisMargin d-flex"
        onSubmit={handleSubmit(this.submit)}
      >
        <InputGroup style={{ height: '28px', width: 'auto' }}>
          <InputGroup.Prepend>
            <InputGroup.Text>
              <SvgTimePicker />
            </InputGroup.Text>
          </InputGroup.Prepend>
          <Field
            className="form-control"
            name="startDate"
            type="start"
            startDate={startDate}
            endDate={endDate}
            component={RenderDatePicker}
            validate={required}
          />
          <InputGroup.Append>
            <InputGroup.Text>-</InputGroup.Text>
          </InputGroup.Append>
          <Field
            className="form-control"
            name="endDate"
            type="end"
            startDate={startDate}
            endDate={endDate}
            component={RenderDatePicker}
            validate={required}
          />
        </InputGroup>
        <Field
          name="status"
          component="select"
          className="ml-2 form-control form-input fz_14"
          style={{ width: '165px' }}
        >
          <option value="all">{'Tất cả trạng thái'}</option>
          {_status.map((statusOption) => (
            <option value={statusOption} key={statusOption}>
              {statusOption}
            </option>
          ))}
        </Field>
        <button
          ref={(el) => (this.refSubmit = el)}
          type="submit"
          style={{ display: 'none' }}
        ></button>
        <ReloadButton
          type="button"
          className="ml-2 btn-reload mr-2"
          disabled={submitting}
          onClick={this._handleReset}
        />
      </form>
    );
  }
}

FormHisMargin = reduxForm({
  form: 'hisMargin',
  enableReinitialize: true,
})(FormHisMargin);

const selector = formValueSelector('hisMargin');

const makeMapStateToProps = () => {
  const getToken = makeGetToken();

  const mapStateToProps = (state, props) => {
    const token = getToken(state);
    const { status, startDate, endDate } = selector(
      state,
      'status',
      'startDate',
      'endDate'
    );

    const numDay = token && token['config'] ? token['config']['numDay'] : 30;

    const d = new Date();
    d.setDate(d.getDate() - numDay);

    return {
      token,
      status,
      startDate,
      endDate,
      initialValues: {
        status: 'all',
        startDate: formatDate(d, '/', 'dmy'),
        endDate: formatDate(new Date(), '/', 'dmy'),
      },
    };
  };
  return mapStateToProps;
};

FormHisMargin = connect(makeMapStateToProps)(FormHisMargin);

export default FormHisMargin;
