import React, { useEffect, useState, useContext } from 'react';
import { ReactReduxContext, useDispatch, useSelector } from 'react-redux';
import PerfectScrollBar from 'react-perfect-scrollbar';
import DatePicker from 'react-datepicker';
import { Portal } from 'react-overlays';
import {
  formatDate,
  numberFormat,
  StringToInt,
  _formatDate2,
  formatToNewDate,
} from '../../../../util';
import { v4 as uuidv4 } from 'uuid';
import { withNamespaces } from 'react-i18next';
import {
  getBondCommonRequest,
  getBondCommonRequest2,
  setBondOrder,
} from '../../action';
import { cashBalanceRequest } from '../../../privData/actions';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import TextMask from 'react-text-mask';
import CashInternalTransfer from '../../../pinefolio/components/cashInternalTransfer';
import { setPFBondOrder } from '../../../pinefolio/action';
import { OverlayTrigger, Tooltip, Popover } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import { constants } from '../../../../util/constant';

const CalendarContainer = ({ children }) => {
  const el = document.getElementById('calendar-portal');

  return <Portal container={el}>{children}</Portal>;
};

const BuyOrder = withNamespaces('translations')(
  ({ closeHandle, modalSize, t, typeBond }) => {
    const dispatch = useDispatch();
    const { pfBondOrder, planData } = useSelector((state) => state.pinefolio);
    const {
      token,
      setting: { lang = 'vi' },
      setting,
    } = useSelector((state) => state.client);
    const { bondProductDetail } = useSelector((state) => state.bond);
    const cashBalance = useSelector(
      (state) => state.privData.cashBalance?.data
    );
    const bondAccounts = token?.custInfo.normal.filter(
      (x) => x.isBondOTCTrade == 'Y'
    );
    const { defaultAccount, listAccount } = useSelector(
      (state) => state.socket
    );
    const [account, setAccount] = useState(null);
    const [quantity, setQuantity] = useState('');
    const [preOrder, setPreOrder] = useState();
    const [moneyFlow, setMoneyFlow] = useState();
    const [transtDate, setTranstDate] = useState();
    const [isLoad, setIsLoad] = useState(true);
    const [error, setError] = useState('');
    const [reload, setReload] = useState(false);
    const [open, setOpen] = useState(false);
    const [showOtpPopup, setShowOtpPopup] = useState(false);

    const quantityDebounce = useDebounce(quantity, 200);

    const maxBuy = numberFormat(
      cashBalance &&
        preOrder &&
        cashBalance?.pp0 / preOrder?.leg1 < preOrder?.maxQty
        ? cashBalance?.pp0 / preOrder?.leg1
        : preOrder?.maxQty,
      0,
      '0'
    );

    const cashTransferCallback = () => {
      getBondPreOrderHandle(account, quantityDebounce);
      getCashBalance();
    };

    const getBondPreOrderHandle = (account, quantity) => {
      const _transtDate = formatDate(new Date(), '');
      if (account) {
        const uuid = uuidv4();
        const params = {
          group: 'CORE',
          cmd: 'getBondPreOrder',
          rqId: uuid,
          channel: 'WTS',
          user: token?.user,
          session: token?.session,
          data: {
            cif: token?.cif,
            acntNo: account?.acntNo,
            subAcntNo: account?.subAcntNo,
            prdCode: bondProductDetail.productCode,
            quantity: StringToInt(quantity) + 0 + '',
            tranDt: _transtDate,
            side: '2',
          },
        };

        const callback = (data) => {
          setPreOrder(data);
          if (data?.isufAmt == 0 && error === 'bond.maxBuy.overError') {
            setError(null);
          }
          getBondPreviewHandle(account, transtDate, data?.ttlBuyValue);
          if (
            (isLoad && !pfBondOrder) ||
            pfBondOrder?.dayIntendedToSell > data?.dueDate
          ) {
            setTranstDate(formatToNewDate(data?.dueDate));
            setIsLoad(false);
          }
        };
        dispatch(getBondCommonRequest(params, callback, true));
      }
    };

    const getBondPreviewHandle = (account, transtDate, ttlBuyValue) => {
      if (account && ttlBuyValue > 0) {
        const uuid = uuidv4();
        const prdCode = bondProductDetail.productCode;
        const _tradeDate = formatDate(new Date(), '');
        const _transtDate = formatDate(transtDate, '');
        const params = {
          group: 'CORE',
          user: token.user,
          session: token.session,
          cmd: 'getProdIncomeFlow',
          rqId: uuid,
          channel: 'WTS',
          data: {
            cif: token?.cif,
            acntNo: account?.acntNo,
            subAcntNo: account?.subAcntNo,
            prdCode: prdCode,
            investAmt: ttlBuyValue + '',
            tranDt: _tradeDate,
            xpctDueDate: _transtDate,
          },
        };

        const callback = (data) => setMoneyFlow(data);
        dispatch(getBondCommonRequest(params, callback, true));
      }
    };

    const getCashBalance = () => {
      if (account) {
        const uuid = uuidv4();
        const params = {
          group: 'CORE',
          user: token.user,
          session: token.session,
          cmd: 'getTotalAssetAll',
          rqId: uuid,
          channel: 'WTS',
          data: {
            acntNo: account.acntNo,
            subAcntNo: account.subAcntNo,
          },
        };
        dispatch(cashBalanceRequest(params));
      }
    };

    const getContract = (pdCode, quantity, dealId) => {
      if (account && token) {
        const uuid = uuidv4();
        const params = {
          group: 'CORE',
          cmd: 'getBondOrdContractFile',
          rqId: uuid,
          channel: 'WTS',
          user: token?.user,
          session: token?.session,
          data: {
            cif: token?.cif,
            acntNo: account?.acntNo,
            subAcntNo: account?.subAcntNo,
            prdCode: pdCode,
            quantity: StringToInt(quantity) + '',
            side: '2',
            dealId: dealId,
            langTp: setting?.lang || 'vi',
          },
        };

        const callback = () => {}; //fireandforget
        dispatch(getBondCommonRequest(params, callback, true));
      }
    };

    const handleOrderBuy = () => {
      if (bondProductDetail?.listTp != constants.bondProductType.Var) {
        if (!error && StringToInt(quantity) > 0) {
          const newOrder = {
            quantity: StringToInt(quantity),
            side: '2', // sell: 1 | buy: 2
            account: account,
            pdCode: bondProductDetail.productCode,
            pfPlanId: planData?.planId, //Plan Id for pinefolio
            productTp: bondProductDetail.productTp, // FIX or VAR
          };

          dispatch(setBondOrder(newOrder));
        } else {
          return;
        }
      } else {
        getContract(bondProductDetail?.productCode, quantity, '');
        setShowOtpPopup(true);
      }
    };

    const closeFlowHandle = () => {
      // reset pfbondOrder
      dispatch(setPFBondOrder(null));

      if (closeHandle && typeof closeHandle === 'function') {
        closeHandle();
      }
    };

    useEffect(() => {
      if (defaultAccount && defaultAccount.isBondOTCTrade == 'Y') {
        setAccount(defaultAccount);
      } else {
        if (bondAccounts && !!bondAccounts.length > 0) {
          setAccount(bondAccounts[0]);
        }
      }
    }, [defaultAccount, bondAccounts]);

    useEffect(() => {
      if (quantityDebounce && account) {
        getBondPreOrderHandle(account, quantityDebounce);
      } else {
        getBondPreOrderHandle(account, quantityDebounce);
        setMoneyFlow('');
      }
      getCashBalance();
    }, [account, quantityDebounce]);

    useEffect(() => {
      getBondPreviewHandle(account, transtDate, preOrder?.ttlBuyValue);
    }, [transtDate]);

    useEffect(() => {
      getBondPreOrderHandle(account, quantity);
      getCashBalance();
    }, []);

    useEffect(() => {
      if (pfBondOrder && pfBondOrder.dayIntendedToSell)
        setTranstDate(formatToNewDate(pfBondOrder.dayIntendedToSell));
    }, [pfBondOrder]);

    return (
      <>
        <div className="bond-detail-order bond-detai-order--buy bond-detail-panel">
          <div className="bond-detail-header">
            <span className="bond-detail__title">{t('bond.bondOrder')}</span>
          </div>
          <div className="bond-detail-order__infor">
            <div className="order-infors">
              <div className="order-infor">
                <div className="order-infor__title">
                  {t('bond.customerAccountNo')}
                </div>
                <div className="order-infor__desc">{account?.subAcntNo}</div>
              </div>
              <div className="order-infor">
                <div className="order-infor__title">{t('bond.tradeDate')}</div>
                <div className="order-infor__desc">
                  {_formatDate2(formatDate(new Date(), ''))}
                </div>
              </div>
              <div className="order-infor">
                <div className="order-infor__title">
                  {t('bond.settlementDate')}
                </div>
                <div className="order-infor__desc">
                  {_formatDate2(preOrder?.setlDate)}
                </div>
              </div>
              <div className="order-infor">
                <div className="order-infor__title">
                  {bondProductDetail.productTp != constants.bondProductType.Fix
                    ? t('txt-stt-due-date')
                    : t('bond.dealList.label.dueDate')}
                </div>
                <div className="order-infor__desc">
                  {_formatDate2(preOrder?.dueDate)}
                </div>
              </div>
              <div className="order-infor">
                <div className="order-infor__title">{t('bond.order.type')}</div>
                <div className="order-infor__desc i">{t('txt-buy')}</div>
              </div>
              <div className="order-infor">
                <div
                  className="order-infor__title"
                  style={{ marginRight: '-8px' }}
                >
                  {t('bond.quantity')}
                </div>
                <div className="order-infor__desc position-relative">
                  <TextMask
                    type="text"
                    className={`${
                      error ? 'input-error' : ''
                    } input-text-search`}
                    maxLength={15}
                    value={quantity}
                    mask={createNumberMask({
                      prefix: '',
                      allowNegative: false,
                    })}
                    onFocus={(e) => e.currentTarget.select()}
                    onChange={(e) => {
                      setQuantity(e.target.value);

                      if (e.target.value) {
                        if (
                          StringToInt(e.target.value) <= StringToInt(maxBuy)
                        ) {
                          if (
                            StringToInt(e.target.value) <
                            StringToInt(preOrder?.minQty)
                          )
                            setError('bond.maxBuy.minError');
                          else {
                            setError('');
                          }
                        } else {
                          setError('bond.maxBuy.overError');
                        }
                      } else {
                        setError('');
                      }
                    }}
                  />
                  {error && <span className="text-error mt-2">{t(error)}</span>}
                  <label className="min-quantity">
                    ≥ {numberFormat(preOrder?.minQty, 0, '0')}
                  </label>
                </div>
              </div>
              <div className="order-infor">
                <div className="order-infor__title">
                  {t('bond.totalValue.buy')}
                </div>
                <div className="order-infor__desc">
                  {numberFormat(preOrder?.buyAmt, 0, '0')}
                </div>
              </div>
              {typeBond == constants.bondProductType.Fix && (
                <div className="order-infor border-top-dashed">
                  <div className="order-infor__title">
                    {t('bond.totalValue.proRate')}
                  </div>
                  <div className="order-infor__desc">
                    {numberFormat(preOrder?.proRt, 2, '0') + '%'}
                  </div>
                </div>
              )}
              {typeBond == constants.bondProductType.Fix && (
                <>
                  <div className="order-infor border-bottom-dashed">
                    <div className="order-infor__title">
                      {t('bond.totalValue.realRate')}
                    </div>
                    <div className="order-infor__desc r">
                      {numberFormat(preOrder?.realRt, 2, '0') + '%'}
                    </div>
                  </div>
                </>
              )}
              <div className="order-infor">
                <div className="order-infor__title tool-tip d-flex align-items-center cursor-pointer">
                  <span>{t('bond.fee')}</span>
                  {bondProductDetail?.listTp == '2' && (
                    <OverlayTrigger
                      trigger={['hover', 'focus']}
                      onToggle={() => setOpen(!open)}
                      show={open}
                      rootClose
                      overlay={
                        <Popover>
                          <Popover.Content className="d-flex">
                            <span>{t('bond.otcTooltip')}</span>
                          </Popover.Content>
                        </Popover>
                      }
                    >
                      <span className="icon iWarming ml-1 mr-1"></span>
                    </OverlayTrigger>
                  )}
                </div>
                <div className="order-infor__desc">
                  {bondProductDetail?.listTp == '2'
                    ? t('bond.otcFee')
                    : numberFormat(preOrder?.buyFee, 0, '0')}
                </div>
              </div>
              <div className="order-infor">
                <div className="order-infor__title ">
                  {bondProductDetail?.listTp == '2'
                    ? t('bond.otcTotalPayment')
                    : t('bond.totalPayment')}
                </div>
                <div className="order-infor__desc r">
                  {numberFormat(preOrder?.ttlBuyValue, 0, '0')}
                </div>
              </div>
            </div>
          </div>
          {preOrder?.isufAmt > 0 && (
            <CashInternalTransfer
              className="mt-2"
              missing={preOrder?.isufAmt}
              target={account}
              callback={cashTransferCallback}
              key={reload}
              reloadCallback={() => setReload(!reload)}
            />
          )}

          <div className="bond-detail-order__orderPlace">
            <div className="asset-avaiable">
              <span className="d-flex gap-8">
                <span className="text--light3">{t('bond.maxBuy.value')}</span>
                <span className="fw-500">
                  {numberFormat(preOrder?.buyLmt, 0, '0')}
                </span>
              </span>
              <span className="d-flex gap-8">
                <span className="text--light3">
                  {t('bond.maxBuy.valueLeft')}
                </span>
                <span className="fw-500">
                  {numberFormat(preOrder?.buyLmtRm, 0, '0')}
                </span>
              </span>
            </div>
            <div className="asset-avaiable">
              <span className="d-flex gap-8">
                <span className="text--light3">{t('txt-pp')}</span>
                <span className="fw-500">
                  {numberFormat(cashBalance?.pp0, 0, '-')}
                </span>
              </span>
              <span className="d-flex gap-8">
                <span className="text--light3">{t('bond.maxBuy.name')}</span>
                <span className="fw-500">
                  {numberFormat(
                    cashBalance &&
                      preOrder &&
                      cashBalance?.pp0 / preOrder?.leg1 < preOrder?.maxQty
                      ? cashBalance?.pp0 / preOrder?.leg1
                      : preOrder?.maxQty,
                    0,
                    '0'
                  )}
                </span>
              </span>
            </div>
            <div className="order-actions">
              {(StringToInt(quantityDebounce) >=
                StringToInt(preOrder?.minQty) &&
                StringToInt(quantityDebounce) <=
                  StringToInt(preOrder?.maxQty) &&
                StringToInt(preOrder?.buyAmt) <=
                  StringToInt(cashBalance?.pp0)) ||
              (StringToInt(quantityDebounce) >= StringToInt(preOrder?.minQty) &&
                bondProductDetail?.listTp == '2') ? (
                <button className="btn btn--buy" onClick={handleOrderBuy}>
                  {t('txt-order')}
                </button>
              ) : (
                <button className="btn btn--buy disabled">
                  {t('txt-order')}
                </button>
              )}
              <button
                className="btn btn--cancel"
                onClick={() => closeFlowHandle()}
              >
                {t('bond.cancel')}
              </button>
            </div>
          </div>
        </div>
        <div
          className="bond-detail-order bond-detai-order--buy bond-detail-panel position-relative"
          style={{ justifyContent: 'normal' }}
        >
          <div className="bond-detail-header">
            <span className="bond-detail__title">
              {t('bond.intendedMoneyFlow')}
            </span>

            {modalSize == 'wts-modal-content--xl' && (
              <span className="modal-close" onClick={() => closeFlowHandle()}>
                <span className="icon iClose"></span>
              </span>
            )}
          </div>
          <div
            className="bond-detail-body"
            style={{ overflow: 'hidden', padding: '1px', maxHeight: '496px' }}
          >
            <div className="bond-detai-moneyFlow">
              <table className="table-border-radius w-100 table-fix mb-3 ">
                <tbody>
                  <tr style={{ display: 'none' }}></tr>
                  <tr>
                    <td>
                      <label className="text--light3">
                        {t('bond.dayIntendedToSell')}
                      </label>
                    </td>
                    <td className="">
                      <div className="filter-control-calendar flex-1 ">
                        <DatePicker
                          minDate={new Date()}
                          selected={transtDate}
                          onChange={(date) => setTranstDate(date)}
                          dateFormat="dd/MM/yyyy"
                          popperContainer={CalendarContainer}
                          placeholderText="Intended to sell"
                          readOnly={
                            bondProductDetail?.listTp == '2' ? true : false
                          }
                          disabled={
                            bondProductDetail?.listTp == '2' ? true : false
                          }
                        />
                        <span
                          className="position-absolute icon iCalendar"
                          style={{ right: '8px' }}
                        ></span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="d-flex flex-column" style={{ height: '100%' }}>
                <PerfectScrollBar className="table-border-radius">
                  <table className="table table-border-radius">
                    <tbody>
                      <tr>
                        <td>
                          <label className="text--light3">
                            {t('bond.investAmount')}
                          </label>
                        </td>
                        <td className="text-right" colSpan={2}>
                          <label className="text--light fw-500">
                            {moneyFlow &&
                              numberFormat(moneyFlow.invtAmt, 0, '0')}
                          </label>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <label className="text--light3">
                            {t('bond.bondQuantity.name')}
                          </label>
                        </td>
                        <td className="text-right" colSpan={2}>
                          <label className="text--light fw-500">
                            {moneyFlow &&
                              numberFormat(moneyFlow.quantity, 0, '0')}
                          </label>
                        </td>
                      </tr>

                      {moneyFlow &&
                        moneyFlow.couponAmt &&
                        moneyFlow.couponAmt.map((coupon) => (
                          <tr>
                            <td>
                              {coupon.times < 999 ? (
                                <label className="text--light3">
                                  {t('bond.coupon')}
                                </label>
                              ) : coupon.times == 999 ? (
                                <label className="text--light3">
                                  {t('bond.bondTotalSellMoney')}
                                </label>
                              ) : (
                                <label className="text--light3">
                                  {t('bond.principal')}
                                </label>
                              )}
                            </td>
                            <td>
                              <label className="text--light3 fw-500">
                                {_formatDate2(coupon.paydate)}
                              </label>
                            </td>
                            {coupon.times == 999 ? (
                              <td className="text-right text--light fw-500">
                                <span>
                                  {numberFormat(moneyFlow.selAmt, 0, '0')}
                                </span>
                              </td>
                            ) : (
                              <td className="text-right text--light fw-500">
                                <span>
                                  {numberFormat(coupon.amount, 0, '0')}
                                </span>
                              </td>
                            )}
                          </tr>
                        ))}
                      <tr>
                        <td>
                          <label className="text--light3">
                            {t('bond.netProfit')}
                          </label>
                        </td>
                        <td colSpan={2} className="text-right">
                          <span className="text--light fw-500">
                            {moneyFlow &&
                              numberFormat(moneyFlow.profit, 0, '0')}
                          </span>
                        </td>
                      </tr>
                      {typeBond == constants.bondProductType.Fix && (
                        <tr>
                          <td>
                            <label className="text--light3">
                              {t('bond.totalValue.proRate')}
                            </label>
                          </td>
                          <td colSpan={2} className="text-right">
                            <span className="text--light fw-500">
                              {moneyFlow &&
                                numberFormat(moneyFlow.proRt, 2, '0') + '%'}
                            </span>
                          </td>
                        </tr>
                      )}
                      <tr>
                        <td className="text-left d-flex ">
                          <span className="text--light3">{t('bond.rate')}</span>
                          <OverlayTrigger
                            trigger={['hover', 'focus']}
                            placement="top"
                            rootClose
                            overlay={
                              <Popover style={{ maxWidth: '440px' }}>
                                <Popover.Content className="d-flex">
                                  {t('bond.bondDemo.suggest')}
                                </Popover.Content>
                              </Popover>
                            }
                          >
                            <span className="tool-tip d-flex align-items-center cursor-pointer">
                              <span className="icon iWarming ml-1 mr-1"></span>
                            </span>
                          </OverlayTrigger>
                        </td>
                        <td colSpan={2} className="text-right r">
                          <span className="r fw-500">
                            {moneyFlow &&
                              numberFormat(moneyFlow.realRt, 2, '0') +
                                '%/' +
                                t('bond.bondProduct.year')}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <label className="text--light3">
                            {t('bond.totalAmountReceive')}
                          </label>
                        </td>
                        <td colSpan={2} className="text-right i">
                          <span className="text--light fw-500">
                            {moneyFlow &&
                              numberFormat(moneyFlow.totRecvAmt, 0, '0')}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="text--light3 p-2">
                    <span className="r">* </span>
                    {t('bond.moneyFlowWarning')}
                  </div>
                </PerfectScrollBar>
              </div>
            </div>
          </div>
        </div>
        <OTCPopUp
          isEnoughMoney={
            StringToInt(quantityDebounce) <= StringToInt(preOrder?.maxQty) &&
            StringToInt(preOrder?.buyAmt) <= StringToInt(cashBalance?.pp0)
          }
          show={showOtpPopup}
          setShow={setShowOtpPopup}
        />
      </>
    );
  }
);

export default BuyOrder;

const OTCPopUp = withNamespaces('translations')(
  ({ show, setShow, t, isEnoughMoney }) => {
    const { currentTheme, setting } = useSelector((state) => state.client);

    const closeHandle = () => setShow(false);
    const contactHandle = () => {
      const lang = setting?.lang || 'vi';

      if (lang == 'vi')
        window.open('https://pinetree.vn/dich-vu-khach-hang', '_blank');
      else window.open('https://pinetree.vn/en/customer-service', '_blank');
    };

    return (
      <Modal
        show={show}
        dialogClassName="wts-modal"
        backdropClassName="wts-modal-backdrop z-index--lv9"
        contentClassName={`${
          currentTheme?.name || 'dark'
        } wts-modal-content wts-modal-content--sm2 bond-otc-notice`}
        centered
        backdrop={'static'}
        onHide={closeHandle}
        className="z-index--lv9"
      >
        <div className="wts-modal-header">
          <div className="logo logo--circle logo--r">
            <span className="icon iStatement icon--r"></span>
          </div>
        </div>
        <div className="wts-modal-body">
          <span className="text text--md text--light1 fw-500">
            {isEnoughMoney
              ? t('bond.otcNoticeTitle')
              : t('bond.otcNoticeTitle1')}
          </span>
          <div className="text text--sm text--light3 fw-400">
            <div
              dangerouslySetInnerHTML={{
                __html: t('bond.otcNoticeDesc'),
              }}
            />
          </div>
        </div>
        <div className="wts-modal-action">
          <button className="btn btn--primary" onClick={contactHandle}>
            {t('bond.contact')}
          </button>
          <button className="btn btn--cancel" onClick={closeHandle}>
            {t('lbl-close')}
          </button>
        </div>
      </Modal>
    );
  }
);

function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);
  return debouncedValue;
}
