import React from 'react';

import { find, isEqual, sortBy } from 'lodash';
import {
  BsCaretLeftFill,
  BsCaretRightFill,
  BsFillCaretDownFill,
  BsFillCaretUpFill,
} from 'react-icons/bs';
import { translate } from 'react-i18next';
import icMagnifier from '../../../assets/img/icons/header/Search.svg';
import PerfectScrollBar from 'react-perfect-scrollbar';

class PaginationTable extends React.Component {
  state = {
    // paging
    continued: false,
    totalCount: 0,
    contentShow: [],
    totalShow: null,
    txtSymbol: '',
    _totalShow: '',
  };

  componentDidMount() {
    const { source } = this.props;

    if (source) {
      const _totalShow = source && find(source, (x) => x.symbol == 'TOTAL');
      this.setState({
        totalCount: source ? source.length : 0,
        totalShow: _totalShow,
        contentShow: source,
      });
      this.handleQueryPage(1);
    }
  }

  componentDidUpdate(preProps) {
    const { source } = this.props;

    if (!isEqual(source, preProps.source)) {
      const _totalShow = source && find(source, (x) => x.symbol == 'TOTAL');
      this.setState({
        totalCount: source ? source.length : 0,
        totalShow: _totalShow,
        contentShow: source,
      });
      this.handleQueryPage(1);
    }
  }

  handleQueryPage = (page) => {
    const { source } = this.props;
    const { sortType, sortField } = this.state;
    if (!source) return;
    let _source = source;
    if (sortType === 'desc') {
      _source =
        _source && _source.length > 0 && sortBy(_source, sortField).reverse();
    }
    if (sortType === 'asc') {
      _source = _source && _source.length > 0 && sortBy(_source, sortField);
    }

    const _totalShow = find(source, (o) => o.symbol === 'TOTAL');

    this.setState({
      _totalShow,
      contentShow: _source,
      page,
      first: false,
    });
  };

  _showSortIcon = (field) => {
    const { sortType, sortField } = this.state;
    if (sortType === 'asc' && sortField == field) {
      return (
        <span>
          <BsFillCaretUpFill
            className="fz_10 position-relative"
            style={{ marginLeft: '0.15rem' }}
          />
        </span>
      );
    }

    if (sortType === 'desc' && sortField == field) {
      return (
        <span>
          <BsFillCaretDownFill
            className="fz_10 position-relative"
            style={{ marginLeft: '0.15rem' }}
          />
        </span>
      );
    }
  };

  _handleShort = (fieldName) => {
    const { sortType, sortField } = this.state;
    if (!fieldName) fieldName = sortField;

    this.setState(
      {
        sortType: sortType === 'desc' ? 'asc' : 'desc',
        page: 1,
        sortField: fieldName,
      },
      () => {
        this.handleQueryPage(1);
      }
    );
  };

  render() {
    const {
      columns,
      defaultAccount,
      onRowClicked,
      tblClassName,
      t,
      totalColumns,
    } = this.props;
    const { contentShow, totalShow, _totalShow, isPc } = this.state;
    return (
      <>
        <div className={'d-flex flex-column mt-0'}>
          <table className={'table table-bordered ' + (tblClassName || '')}>
            <thead>
              <tr>
                {columns.map((column, index) => {
                  const _key = Array.isArray(column.key);
                  if (!_key) {
                    let classText = column.sortable
                        ? 'sortable filterIcon '
                        : '',
                      width = column.width ? column.width : '',
                      align = column.align ? column.align : '',
                      columnStyle = column.style;
                    classText += ' text-' + align;
                    if (column.TrOnlyClassName)
                      classText += ' ' + column.TrOnlyClassName;
                    return (
                      <th
                        key={`Panination_${column?.key}_${index}`}
                        className={classText}
                        width={width}
                        style={columnStyle}
                        rowSpan={column.rowSpan}
                        onClick={
                          column.sortable
                            ? () => this._handleShort(column.key)
                            : null
                        }
                      >
                        {column.text}
                        {this._showSortIcon(column.key)}
                      </th>
                    );
                  } else {
                    let classText = column.sortable
                        ? 'sortable filterIcon '
                        : '',
                      width = column.width ? column.width : '',
                      align = column.align ? column.align : '',
                      columnStyle = column.style;
                    classText += ' text-' + align;
                    if (column.TrOnlyClassName)
                      classText += ' ' + column.TrOnlyClassName;
                    return (
                      <th
                        key={`Panination_${
                          !isPc ? column?.key[0] : column?.key[1]
                        }_${index}`}
                        width={width}
                        style={columnStyle}
                        rowSpan={column.rowSpan}
                      >
                        <span className="d-flex align-items-center justify-content-between">
                          <span
                            style={{
                              position: 'relative',
                              width: '10px',
                              height: '10px',
                              top: '-9px',
                            }}
                          >
                            <BsCaretLeftFill
                              className="tbl-toggle-left toggle-color--light3"
                              onClick={() => this.setState({ isPc: !isPc })}
                            />
                          </span>
                          <span
                            className={classText}
                            onClick={
                              column.sortable
                                ? () =>
                                    this._handleShort(
                                      !isPc ? column?.key[0] : column?.key[1]
                                    )
                                : null
                            }
                          >
                            {!isPc ? column.text[0] : column.text[1]}
                            {this._showSortIcon(
                              !isPc ? column?.key[0] : column?.key[1]
                            )}
                          </span>
                          <span
                            style={{
                              position: 'relative',
                              width: '10px',
                              height: '10px',
                              top: '-9px',
                            }}
                          >
                            <BsCaretRightFill
                              className="tbl-toggle-right toggle-color--light3"
                              onClick={() => this.setState({ isPc: !isPc })}
                            />
                          </span>
                        </span>
                      </th>
                    );
                  }
                })}
              </tr>
              {/* Total section */}

              {totalColumns && totalShow && (
                <tr>
                  {totalColumns.map((column, colIndex) => {
                    const key = Array.isArray(column.key)
                      ? isPc
                        ? column.key[1]
                        : column.key[0]
                      : column.key;
                    const _className =
                      typeof column.className === 'function'
                        ? column.className({
                            _totalShow,
                          })
                        : column.className;

                    if (column.cell && typeof column.cell === 'function') {
                      // ưu tiên column có cell trước
                      return (
                        <td
                          className={_className}
                          key={key ? key : column.text + '_' + colIndex}
                          colSpan={column?.colSpan ?? 1}
                        >
                          {column.cell({
                            _totalShow,
                          })}
                        </td>
                      );
                    } else if (totalShow[column.key]) {
                      // tiếp theo là column có key
                      return (
                        <td
                          className={_className}
                          key={key ? key : column.text + '_' + colIndex}
                          colSpan={column?.colSpan ?? 1}
                        >
                          {totalShow[key]}
                        </td>
                      );
                    } else {
                      // cuối cùng là ko có cả cell lẫn key
                      return (
                        <td
                          className={_className}
                          key={column.text + '_' + colIndex}
                          colSpan={column?.colSpan ?? 1}
                        >
                          {column.text}
                        </td>
                      );
                    }
                  })}
                </tr>
              )}
            </thead>
            {contentShow && contentShow.length > 0 && (
              <tbody>
                {contentShow &&
                  contentShow.map((record, rowIndex) => {
                    return (
                      <tr
                        key={'record' + rowIndex}
                        onClick={() => {
                          if (onRowClicked) return onRowClicked(record);
                          return null;
                        }}
                      >
                        {columns.map((column, colIndex) => {
                          const key = Array.isArray(column.key)
                            ? isPc
                              ? column.key[1]
                              : column.key[0]
                            : column.key;
                          const _className =
                            typeof column.className === 'function'
                              ? column.className(record)
                              : column.className;

                          if (
                            column.cell &&
                            typeof column.cell === 'function'
                          ) {
                            if (key === 'defaultAccount') {
                              return (
                                <td
                                  className={_className}
                                  key={key ? key : column.text + '_' + colIndex}
                                >
                                  {column.cell(defaultAccount)}
                                </td>
                              );
                            }
                            return (
                              <td
                                className={_className}
                                key={key ? key : column.text + '_' + colIndex}
                              >
                                {column.cell(record, rowIndex)}
                              </td>
                            );
                          } else if (record[key]) {
                            return (
                              <td
                                className={_className}
                                key={key ? key : column.text + '_' + colIndex}
                              >
                                {record[key]}
                              </td>
                            );
                          } else {
                            if (key === '_index') {
                              return (
                                <td
                                  className={_className}
                                  key={key ? key : column.text + '_' + colIndex}
                                ></td>
                              );
                            }
                            return (
                              <td
                                className={_className}
                                key={key ? key : column.text + '_' + colIndex}
                              ></td>
                            );
                          }
                        })}
                      </tr>
                    );
                  })}
              </tbody>
            )}
          </table>
          {!contentShow ||
            (contentShow.length == 0 && (
              <p className="mt-2 text-center">{t('txt-no-data-found')}</p>
            ))}
        </div>
      </>
    );
  }
}

export default translate('translations')(PaginationTable);
