import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { Button } from 'react-bootstrap';

import { StringToInt, numberFormat } from '../../../util';
import {
  makeGetAuth,
  makeGetCashInBank,
  makeGetDefaultAccount,
  makeGetToken,
} from '../../../lib/selector';
import { holdBankRequest } from '../../../containers/cash/actions';
import { v4 as uuidv4 } from 'uuid';
import RenderInputMask from '../../input/renderInputMask';
import { required } from '../../../util/validation';
import { setAuth } from '../../../containers/client/actions';
import { translate } from 'react-i18next';
class FormHoldBank extends Component {
  submit = (values) => {
    const { amount } = values;
    const { token, auth, defaultAccount, cashInBank, holdType, t } = this.props;
    console.log('FormHoldBank', amount);
    if (!cashInBank || !token) return;
    const _amount = StringToInt(amount.replace(',', ''));

    if (holdType === '1') {
      // gui them
      if (_amount > cashInBank.avalBal) {
        this.props.handleToast(
          `${t('txt-label-hold')} ${t('txt-title-max')}: ${numberFormat(
            cashInBank.avalBal,
            0,
            '0'
          )}`
        );
        return;
      }
    } else {
      if (_amount > cashInBank.holdAmt) {
        this.props.handleToast(
          `${t('txt-label-unHold')} ${t('txt-title-max')}: ${numberFormat(
            cashInBank.avaholdAmtlBal,
            0,
            '0'
          )}`
        );
        return;
      }
    }

    if (_amount > 0) {
      const uuid = uuidv4();
      const resData = {
        group: 'CORE',
        user: token.user,
        session: token.session,
        cmd: 'RequestHold',
        rqId: uuid,
        channel: 'WTS',
        type: auth.type,
        token: auth.token,
        data: {
          acntNo: defaultAccount.acntNo,
          subAcntNo: defaultAccount.subAcntNo,
          holdType: holdType,
          amount: _amount,
        },
      };

      this.props.dispatch(holdBankRequest(resData));

      this.props.reset();
    }
  };

  _handleAuthen = () => {
    this.props.dispatch(setAuth());
  };

  render() {
    const {
      amount,
      handleSubmit,
      pristine,
      submitting,
      holdType,
      cashInBank,
      auth,
      t,
    } = this.props;

    console.log(amount);

    return (
      <form
        className="form-trade d-flex flex-row justify-content-end align-items-center mt-2"
        onSubmit={handleSubmit(this.submit)}
      >
        <span className="fz_13 text-content">
          {holdType === '1' ? t('txt-label-hold') : t('txt-label-unHold')}
        </span>
        <Field
          name="amount"
          placeholder={`${t('txt-title-max')} ${
            cashInBank &&
            (holdType === '1'
              ? numberFormat(cashInBank.avalBal, 0, '0')
              : numberFormat(cashInBank.holdAmt, 0, '0'))
          }`}
          classname="form-control text-right form-input fz_13 ml-2 mr-2"
          component={RenderInputMask}
          validate={required}
        />
        {auth && auth.isVerified ? (
          <Button
            type="submit"
            disabled={pristine || submitting}
            className="fz_13 fw-500"
            style={{
              backgroundColor: '#10547B',
              border: 'none',
              padding: '0px 20px',
              height: '32px',
              width: '120px',
            }}
          >
            {`${
              holdType === '1' ? t('txt-label-hold') : t('txt-label-unHold')
            }`}
          </Button>
        ) : (
          <Button
            variant="warning"
            className="fz_13 fw-500 text-white"
            onClick={() => this._handleAuthen()}
            style={{ width: '120px' }}
          >
            {t('txt-xacthuc')}
          </Button>
        )}
      </form>
    );
  }
}

FormHoldBank = reduxForm({
  form: 'holdbank',
})(FormHoldBank);

const selector = formValueSelector('holdbank');

const makeMapStateToProps = () => {
  const getToken = makeGetToken();
  const getAuth = makeGetAuth();
  const getCashInBank = makeGetCashInBank();
  const getDefaultAccount = makeGetDefaultAccount();

  const mapStateToProps = (state) => {
    const { amount, content } = selector(state, 'amount', 'content');
    return {
      token: getToken(state),
      auth: getAuth(state),
      cashInBank: getCashInBank(state),
      defaultAccount: getDefaultAccount(state),
      amount,
    };
  };
  return mapStateToProps;
};

FormHoldBank = connect(makeMapStateToProps)(FormHoldBank);

export default translate('translations')(FormHoldBank);
