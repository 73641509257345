import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { withNamespaces } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getPFPurchasePower, handleEditPlanMonitor, setOrder } from '../action';
import PinefolioLevels from './pinefolioLevels';
import ProductInfo from './productInfo';
import { dragDiv, numberFormat } from '../../../util';
import { v4 as uuidv4 } from 'uuid';

const BuyOrder = withNamespaces('translations')(({ t, callback }) => {
  const [show, setShow] = useState(false);
  const theme = useSelector((state) => state.client.currentTheme);
  const { pineThemeDetail, pfOrder } = useSelector((state) => state.pinefolio);
  const pfData = pfOrder.pfData || [];

  const dispatch = useDispatch();

  useEffect(() => {
    if (pfOrder && pfOrder.orderType == 1) {
      setShow(true);
    }
  }, [pfOrder]);

  const closeModal = () => {
    setShow(false);
    dispatch(setOrder(null));
    if (callback && typeof callback == 'function') {
      callback();
    }
  };

  return (
    <Modal
      show={show}
      dialogClassName="wts-modal"
      backdropClassName="wts-modal-backdrop pinefolio-buy-backdrop"
      contentClassName={`${
        theme ? theme.name : 'dark'
      } pinefolio-buy wts-modal-content wts-modal-content--sm2`}
      onEscapeKeyDown={() => closeModal()}
      centered
      onHide={() => closeModal()}
      style={{ zIndex: 11115 }}
    >
      <div className="pinefolio-buy__header">
        <div className="pinefolio-buy__action">
          <span className="icon iBack"></span>
        </div>
        {!pfOrder?.isBuyByPlan ? (
          <div className="pinefolio-buy__title text--lg text--light">
            {t('txt-buy')} {pineThemeDetail?.thsNm}
          </div>
        ) : (
          <div className="pinefolio-buy__title text--lg text--light">
            {` ${t('txt-buy')} ${pfData?.pfName} - ${
              pfData.packNo != 0
                ? t('pinefolio.plan.pack') + ' ' + pfData?.packNo
                : t('pinefolio.plan.fullSet')
            }`}
          </div>
        )}
        <div className="pinefolio-buy__action"></div>
      </div>
      <div className="pinefolio-buy__body">
        <div className="order-main text--sm">
          {!pfOrder?.isBuyByPlan && (
            <div className="product-type">
              <PinefolioLevels sources={pineThemeDetail?.pfList} />
            </div>
          )}
          <ProductInfo orderType={1} close={closeModal} pfData={pfData} />
        </div>
      </div>
    </Modal>
  );
});

export default BuyOrder;
