import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import ReactSelect from 'react-select';
import moment from 'moment';
import DatePicker, { registerLocale } from 'react-datepicker';
import vi from 'date-fns/locale/vi';
import uk from 'date-fns/locale/uk';
import { Portal } from 'react-overlays';
import {
  numberFormat,
  _formatDate,
  _formatDate2,
  _diff2Date,
} from '../../../util';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { withNamespaces, translate } from 'react-i18next';

import { getBondOrderListRequest } from '../action';
import { setToast } from '../../../containers/client/actions';
import ScrollTable from '../../../components/ScrollTable';
import { useHistory } from 'react-router';
import { Mixpanel } from '../../../lib/mixpanel';

registerLocale('vi', vi);
registerLocale('en', uk);
const CalendarContainer = ({ children }) => {
  const el = document.getElementById('calendar-portal');

  return <Portal container={el}>{children}</Portal>;
};

const OrderList = withNamespaces('translations')(({ t }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { token, auth } = useSelector((state) => state.client);
  const { listBondOrderList } = useSelector((state) => state.bond);

  const bondAccounts = token?.custInfo.normal.filter(
    (x) => x.isBondOTCTrade == 'Y'
  );

  const listSelectSubAccount = bondAccounts
    ? [{ subAcntNo: 'txt-all', key: 0 }, ...bondAccounts]
    : [{ subAcntNo: 'txt-all', key: 0 }];

  const numDay = token && token['config'] ? token['config']['numDay'] : 30;
  const d = new Date();
  d.setDate(d.getDate() - numDay);

  const [from, setFrom] = useState(d);
  const [to, setTo] = useState(new Date());
  const [bondCode, setBondCode] = useState();
  const setting = useSelector((state) => state.client.setting);
  const [account, setAccount] = useState(
    listSelectSubAccount && listSelectSubAccount.length > 0
      ? listSelectSubAccount[0]
      : {}
  );
  const [refresh, setRefresh] = useState(false);
  const lang = setting.lang || 'vi';

  const bondOrderList = useMemo(() => {
    const list = listBondOrderList?.list;
    return list?.reverse() || [];
  }, [listBondOrderList]);

  const hanldeSelectSubAccount = (value) => {
    setAccount(value);
  };

  const getBondOrderList = () => {
    if (!token) return;
    const _fromDate = _formatDate(moment(from).format('DD/MM/YYYY'));
    const _toDate = _formatDate(moment(to).format('DD/MM/YYYY'));
    const uuid = uuidv4();
    const params = {
      group: 'CORE',
      user: token.user,
      session: token.session,
      cmd: 'getBondOrderList',
      rqId: uuid,
      channel: 'WTS',
      data: {
        cif: token.cif,
        acntNo: bondAccounts[0]?.acntNo,
        subAcntNo: account.key == 0 ? '' : account.subAcntNo,
        bndCode: bondCode,
        fromDate: _fromDate,
        toDate: _toDate,
      },
    };

    dispatch(getBondOrderListRequest(params));

    // missing filter by sub account
    Mixpanel.bond.filter(
      '',
      '',
      '',
      '',
      '',
      bondCode,
      _fromDate,
      _toDate,
      '',
      ''
    );
  };

  const handleRefresh = () => {
    setRefresh(!refresh);
    setAccount(listSelectSubAccount[0]);
    setBondCode('');
    Mixpanel.bond.resetFilter('Order List');
  };

  useEffect(() => {
    getBondOrderList();
  }, []);

  useEffect(() => {
    getBondOrderList();
  }, [refresh]);

  useEffect(() => {
    if (!token) {
      history.push('/');
    }
  }, [token]);

  const listStatus = [
    { key: '1', value: t('bond.orderList.statusValue.pending'), color: 'r' },
    { key: '2', value: t('bond.orderList.statusValue.confirmed'), color: 'r' },
    { key: '3', value: t('bond.orderList.statusValue.matched'), color: 'i' },
    { key: '4', value: t('bond.orderList.statusValue.canceled'), color: 'd' },
    { key: '5', value: t('bond.orderList.statusValue.rejected'), color: 'd' },
  ];

  const mapStatus = (statusCode) => {
    return listStatus.find((item) => item.key === statusCode);
  };

  const columns = [
    {
      key: 'subAcntNo',
      text: t('bond.tableLabel.subAccount'),
      className: 'text-center position-relative fw-500',
      sortable: true,
    },
    {
      key: 'dealId',
      text: t('bond.tableLabel.dealID'),
      className: 'text-center text--light3 position-relative fw-500',
      sortable: true,
    },
    {
      key: 'orderId',
      text: t('bond.tableLabel.orderID'),
      className:
        'text-center text--light3 position-relative text--light fw-500',
      sortable: true,
    },
    {
      key: 'bondCode',
      text: t('bond.tableLabel.bondCode'),
      className: 'text-center position-relative text--light fw-500',
      sortable: true,
    },
    {
      key: 'issuerNm',
      text: t('bond.issuer'),
      className: 'text-left position-relative text--light fw-500',
      sortable: true,
      align: 'left',
    },
    {
      key: 'termNm',
      text: t('bond.terms'),
      className: 'text-left position-relative text--light fw-500',
      sortable: true,
      align: 'left',
    },
    {
      key: 'issueTpNm',
      text: t('bond.formOfOffering'),
      className:
        'text-center text--light3 position-relative text--light fw-500',
      sortable: true,
      align: 'center',
    },
    {
      key: 'tranDate',
      className: 'text-center text--light3 position-relative',
      text: t('bond.tableLabel.tradeDate'),
      cell: (record) => {
        return _formatDate2(record.tranDate);
      },
      sortable: true,
    },
    {
      key: 'dueDate',
      text: t('bond.tableLabel.maturityDuedate'),
      className: 'text-center text--light3 position-relative',
      cell: (record) => {
        return _formatDate2(record.dueDate);
      },
      sortable: true,
    },
    {
      key: 'cpnrt',
      text: t('bond.tableLabel.rate'),
      className: 'text-center position-relative r fw-500',
      cell: (record) => {
        return (
          numberFormat(record.cpnrt, 2, '-') + `%/${t('bond.bondProduct.year')}`
        );
      },
      sortable: true,
    },
    {
      key: 'dlQty',
      className: 'text-right position-relative',
      text: t('bond.tableLabel.quantity'),
      cell: (record) => {
        return numberFormat(record.dlQty, 0, '-');
      },
      sortable: true,
    },
    {
      key: 'bsSide',
      text: t('bond.tableLabel.order'),
      className: (record) => {
        return 'text-center fw-500 ' + (record.bsSide == 1 ? 'd' : 'i');
      },
      cell: (record) => {
        return record.bsSide == 1 ? t('txt-sell') : t('txt-buy');
      },
      sortable: true,
    },
    {
      key: 'buyPrc',
      text: t('bond.tableLabel.price'),
      className: 'text-right position-relative text--light fw-500',
      align: 'right',
      cell: (record) => {
        return numberFormat(
          record.bsSide == 2 ? record.buyPrc : record.sellPrc,
          0,
          '0'
        );
      },
      sortable: true,
    },
    {
      key: 'buyAmt',
      text: t('bond.totalValue.name'),
      className: 'text-right position-relative text--light fw-500',
      cell: (record) => {
        return numberFormat(
          record.bsSide == 2 ? record.buyAmt : record.sellAmt,
          0,
          '0'
        );
      },
      sortable: true,
      align: 'right',
    },
    {
      key: 'tax',
      text: t('bond.tableLabel.tax'),
      className: 'text-right text--light3 position-relative fw-500',
      cell: (record) => {
        return numberFormat(record.tax, 0, '0');
      },
      sortable: true,
    },
    {
      key: 'sellFee',
      text: t('bond.fee'),
      className: 'text-right text--light3 position-relative fw-500',
      cell: (record) => {
        return numberFormat(
          record.bsSide == 2 ? record.buyFee : record.sellFee,
          0,
          '0'
        );
      },
      sortable: true,
      align: 'right',
    },
    {
      key: 'orderStat',
      text: t('bond.tableLabel.status'),
      className: (record) => {
        return 'text-right ' + mapStatus(record.orderStat).color;
      },
      cell: (record) => {
        return mapStatus(record.orderStat).value;
      },
      sortable: true,
      align: 'right',
    },
  ];

  const colgroup = (
    <colgroup>
      <col width="80px"></col> {/* Order ID */}
      <col width="100px"></col> {/* total account*/}
      <col width="100px"></col> {/* deal id*/}
      <col width="90px"></col> {/* Product code*/}
      <col width="10%"></col> {/* Bond Name/Bond Code */}
      <col width="80px"></col> {/* Term */}
      <col width="90px"></col> {/* Type of offering */}
      <col width="90px"></col> {/* Trade date */}
      <col width="90px"></col> {/* Maturity date */}
      <col width="90px"></col> {/* Rate */}
      <col width="5%"></col> {/* Quantity */}
      <col width="60px"></col> {/* Order */}
      <col width="8%"></col> {/* Price */}
      <col width="8%"></col> {/* Total value */}
      <col width="40px"></col> {/* Tax */}
      <col width="60px"></col> {/* Fee */}
      <col width="80px"></col> {/* Status */}
    </colgroup>
  );

  return (
    <div className="bond-order-list">
      <div className="bond-order-list__body bond-layout__body">
        <div className="bond-layout__filters">
          <div className="bond-layout__filter">
            <label>{t('bond.tableLabel.bondCode')}</label>
            <div className="input-text-search-outer">
              <input
                className="input-text-search"
                placeholder={t('bond.tableLabel.inputCode')}
                value={bondCode}
                onChange={(e) => {
                  setBondCode(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="bond-layout__filter">
            <label>{t('bond.orderList.label.from')}</label>
            <div className="filter-control-calendar">
              <DatePicker
                name="from"
                selected={from}
                onChange={(date) => setFrom(date)}
                dateFormat="dd/MM/yyyy"
                popperContainer={CalendarContainer}
                locale={lang}
              />
              <span className="icon iCalendar"></span>
            </div>
          </div>
          <div className="bond-layout__filter">
            <label>{t('bond.orderList.label.to')}</label>
            <div className="filter-control-calendar">
              <DatePicker
                name="to"
                selected={to}
                onChange={(date) => setTo(date)}
                dateFormat="dd/MM/yyyy"
                popperContainer={CalendarContainer}
                locale={lang}
              />
              <span className="icon iCalendar"></span>
            </div>
          </div>
          <div className="bond-layout__filter">
            <label>{t('txt-sub-account')}</label>
            <ReactSelect
              defaultValue={account}
              className="filter-control-select"
              classNamePrefix="filter-control-select"
              options={listSelectSubAccount}
              getOptionLabel={(option) => t(option.subAcntNo)}
              getOptionValue={(option) => option.key}
              value={account}
              onChange={hanldeSelectSubAccount}
              isSearchable={false}
              components={{
                IndicatorSeparator: () => null,
              }}
            />
          </div>
          <div className="bond-layout__filter button-group">
            <div onClick={getBondOrderList} className="iBtn iBtn--primary">
              <span>{t('bond.orderList.label.filter')}</span>
            </div>
            <span
              onClick={handleRefresh}
              className="iBtn reload-button iBtn--secondary"
            >
              <span className="icon iRefresh"></span>
            </span>
          </div>
        </div>
        <div className="show-data">
          <ScrollTable
            colgroup={colgroup}
            columns={columns}
            source={bondOrderList}
          />
        </div>
      </div>
    </div>
  );
});

export default translate('translations')(OrderList);
