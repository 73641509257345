import React, { useContext, useEffect, useState } from 'react';
import CheckboxButton from '../../../components/checkbox/checkBoxButton';
import PinefolioAllocations from './allocations';
import TextMask from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { withNamespaces } from 'react-i18next';
import { ReactReduxContext, useDispatch, useSelector } from 'react-redux';
import {
  formatDate,
  getMessageError,
  numberFormat,
  StringToInt,
  _formatDate2,
} from '../../../util/index';
import { v4 as uuidv4 } from 'uuid';
import { handleApiErrors } from '../../../lib/api-errors';
import { Logout, logoutRequest } from '../../../components/modal/login/actions';
import { setToast, unsetClient } from '../../client/actions';
import { removeCookie } from '../../../lib/storages';
import {
  getPinefolioInvestmentPlanRequest,
  handleEditPlanMonitor,
  orderPF,
  setCurrentPinefolio,
  setOrder,
} from '../action';
import Contract from './contract';
import CashInternalTransfer from './cashInternalTransfer';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { getMessage } from '../../../lib/selector';

const appUrl = `${process.env.REACT_APP_API_URL}`;

const ProductInfo = withNamespaces('translations')(
  ({ t, orderType = 1, close, pfData }) => {
    const dispatch = useDispatch();
    const { currentPinefolio } = useSelector((state) => state.pinefolio);
    const { token, auth } = useSelector((state) => state.client);
    const lang = useSelector((state) => state.client?.setting?.lang || 'vi');
    const { defaultAccount, listAccount } = useSelector(
      (state) => state.socket
    );
    const { store } = useContext(ReactReduxContext);

    const targetAccount = listAccount?.find(
      (item) => item.subAcntNo.startsWith('P') && item.subAcntStat == 1
    );
    const [symbols, setSymbols] = useState([]);
    const [confirmOrder, setOrderConfirm] = useState(false);
    const [quantity, setQuantity] = useState('');
    const [purchasePower, setPurchasePower] = useState(null);
    const [error, setError] = useState(null);
    const [reload, setReload] = useState(false);
    const [sourceAccount, setSourceAccount] = useState();
    const [check, setCheck] = useState(false);
    const [currentPF, setCurrentPF] = useState();

    useEffect(() => {
      if (currentPinefolio) {
        setQuantity(0);
        setPurchasePower(null);
        setSymbols(currentPinefolio.symbols);
        setCurrentPF(currentPinefolio);

        caculate(0);
        getInforHandle(currentPinefolio);
        const itv = setInterval(() => {
          getInforHandle(currentPinefolio);
        }, 5000);

        return () => {
          clearInterval(itv);
        };
      }
    }, [currentPinefolio]);

    const getInforHandle = (pf) => {
      if (token) {
        const uuid = uuidv4();
        const params = {
          group: 'CORE',
          cmd: 'getPFList',
          rqId: uuid,
          channel: 'WTS',
          data: {
            langTp: lang,
            pfCode: pf.pfCode,
          },
        };

        const url = `${appUrl}/PortfolioServlet.pt`;
        fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          },
          body: JSON.stringify(params),
        })
          .then(handleApiErrors)
          .then((response) => response.json())
          .then((res) => {
            if (res.http == 200) {
              // update currentPF
              const list = res.data?.list;
              const _pf = list[0];
              console.log('ProductInfo infor ====> ', _pf);
              setCurrentPF(_pf);
            } else {
              if (res.http === 401) {
                if (token) dispatch(logoutRequest(token));
                dispatch(unsetClient());
                removeCookie('token');
                removeCookie('authen');
                dispatch(Logout());
                const toastMsg = {
                  id: Math.random(),
                  msg: t('txt-valid-loss-session'),
                  title: t('txt-notice'),
                };
                dispatch(setToast(toastMsg));
              } else {
                const msgErr = getMessage(res.data.message, store.getState);
                const toastMsg = {
                  id: Math.random(),
                  msg: msgErr,
                  title: t('txt-notice'),
                };
                dispatch(setToast(toastMsg));
              }
            }
          })
          .catch((error) => {
            console.error('Error:', error);
          });
      }
    };

    useEffect(() => {
      if (listAccount && !!listAccount.length > 0) {
        // get normal active subaccount
        const normalAcc = listAccount.find(
          (x) => x.subAcntNo.startsWith('N') && x.subAcntStat == 1
        );

        setSourceAccount(normalAcc);
      }
    }, [listAccount]);

    const quantityOnChange = (value) => {
      const _value = value + '';
      setQuantity(_value);
      caculate(_value);
    };

    const toastHandle = (msg) => {
      const toastMsg = {
        id: Math.random(),
        msg: msg,
        title: t('txt-notice'),
      };
      dispatch(setToast(toastMsg));
    };

    const caculate = (quantt) => {
      const _quantity = StringToInt(quantt);

      const uuid = uuidv4();
      if (_quantity >= 0) {
        let params = {};

        params = {
          group: 'CORE',
          cmd: 'getPFPowerPurchase',
          rqId: uuid,
          channel: 'WTS',
          session: token.session,
          user: token.user,

          data: {
            langTp: lang,
            cif: token.cif,
            acntNo: targetAccount?.acntNo,
            subAcntNo: targetAccount?.subAcntNo,
            pfCode: currentPinefolio?.pfCode,
            buySelTp: orderType + '',
            ordrQty: _quantity + '',
          },
        };

        const url = `${appUrl}/PortfolioServlet.pt`;
        fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          },
          body: JSON.stringify(params),
        })
          .then(handleApiErrors)
          .then((response) => response.json())
          .then((res) => {
            if (res.http !== 200) {
              if (res.http === 401) {
                if (token) dispatch(logoutRequest(token));
                dispatch(unsetClient());
                removeCookie('token');
                removeCookie('authen');
                dispatch(Logout());
                toastHandle(t('txt-valid-loss-session'));
              } else {
                toastHandle(getMessageError(res, store.getState));
              }
            } else {
              // success
              const data = res.data;
              setPurchasePower(data);
              validation(data, _quantity);
            }
          })
          .catch((error) => {
            console.error('Error:', error);
          });
      } else {
        setPurchasePower(null);
        setError('');
      }
    };

    const plusHandle = () => {
      const _quantity = StringToInt(quantity);
      quantityOnChange(_quantity + 1);
    };

    const minusHandle = () => {
      if (quantity > 0) {
        const _quantity = StringToInt(quantity);
        quantityOnChange(_quantity - 1);
      }
    };

    const cancelHandle = () => {
      getPinefolioInvestmentPlan();
      close();
    };

    const validation = (purchase, quantt) => {
      if (orderType == 2) {
        const maxSell = purchase?.selAbleQty;
        if (quantt > maxSell) {
          setError(t('pinefolio.order.overMaxSell'));
        } else {
          setError('');
        }
      } else {
        const missing = purchase?.isufAmt;

        if (missing > 0) {
          setError(t('pinefolio.order.overPP'));
        } else {
          setError('');
        }
      }
    };

    const disableHandle = () => {
      if (error || StringToInt(quantity) <= 0) return true;

      if (orderType == 1) {
        // validation for buy
      } else {
        // validation for sell
      }

      return false;
    };

    const cashTransferCallback = () => {
      const _quantt = quantity;
      caculate(_quantt);
    };

    const onCloseContract = () => {
      setOrderConfirm(false);
      if (orderType == 2) {
        setQuantity(0);
        caculate();
        return;
      }
      caculate(quantity);
    };

    const getPinefolioInvestmentPlan = () => {
      if (!token || (!auth && !auth.isVerified)) return;
      const uuid = uuidv4();
      const params = {
        group: 'CORE',
        user: token.user,
        session: token.session,
        cmd: 'getPFIPList',
        rqId: uuid,
        channel: 'WTS',
        data: {
          cif: token.cif,
          acntNo: defaultAccount?.acntNo,
          subAcntNo: defaultAccount?.subAcntNo,
          packNoYN: 'Y',
          planStat: '90',
        },
      };
      dispatch(getPinefolioInvestmentPlanRequest(params));
    };

    return (
      <div>
        <div className="product-info">
          <div className="product-info__order">
            {purchasePower && purchasePower.warnMsg && (
              <div className="infor-row">
                <span className="icon iWarning mr-1"></span>
                <span className="text--gray">{purchasePower.warnMsg}</span>
              </div>
            )}

            <div className="infor-row">
              <span className="infor-row__name text--light3 text--sm">
                <span>{t('pinefolio.code')}</span>
              </span>
              <span className="infor-row__value text--light fw-500">
                {currentPinefolio?.pfCode}
              </span>
            </div>
            <div className="infor-row">
              <span className="infor-row__name text--sm text--light3 d-flex align-items-center">
                <span>{t('txt-label-market-value')}</span>
                <OverlayTrigger
                  trigger={['hover', 'focus']}
                  placement="top"
                  className="portfolio-overlay"
                  overlay={
                    <Tooltip style={{ 'z-index': '99999' }}>
                      {t('pinefolio.order.value.desc')}
                    </Tooltip>
                  }
                >
                  <span className="icon iInfor iTooltip ml-1 mr-1"></span>
                </OverlayTrigger>
              </span>
              <span className="infor-row__value text--light text--sm fw-500">
                {numberFormat(currentPF?.currInvtAmt, 0, '0')}
              </span>
            </div>
            <div className="infor-row">
              <span className="infor-row__name text--light3 text--sm d-flex align-items-center ">
                <span>{t('pinefolio.order.referenceValues.name')}</span>
                <OverlayTrigger
                  trigger={['hover', 'focus']}
                  placement="top"
                  className="portfolio-overlay"
                  data-html={true}
                  overlay={
                    <Tooltip style={{ 'z-index': '99999' }}>
                      {t('pinefolio.order.referenceValues.desc')}
                    </Tooltip>
                  }
                >
                  <span className="icon iInfor iTooltip ml-1 mr-1"></span>
                </OverlayTrigger>
              </span>
              <span className="infor-row__value text--light fw-500 d-flex justify-content-between align-items-center">
                <span className="text--sm fw-500 r">
                  {numberFormat(currentPinefolio?.refValue, 0, '0')}
                </span>
                <span className="text--sm fw-500 c">
                  {numberFormat(currentPinefolio?.celVal, 0, '0')}
                </span>
                <span className="text--sm fw-500 f">
                  {numberFormat(currentPinefolio?.flrVal, 0, '0')}
                </span>
              </span>
            </div>

            <div className="infor-row">
              <div className="infor-row__name text--light3 text--sm">
                {t('txt-quantity')}
              </div>
              <div className="infor-row__value">
                <div className="order-quantity">
                  <TextMask
                    type="text"
                    className={`${
                      error ? 'input-error' : ''
                    } input-text-search`}
                    maxLength={15}
                    value={quantity}
                    placeholder={t('bond.bondQuantity.placeHolder')}
                    mask={createNumberMask({
                      prefix: '',
                      allowNegative: false,
                    })}
                    onFocus={(e) => e.currentTarget.select()}
                    onChange={(e) => quantityOnChange(e.target.value)}
                  />
                  <span
                    className="btn-icon btn--light cursor-pointer"
                    onClick={() => minusHandle()}
                  >
                    <span className="icon iMinus"></span>
                  </span>
                  <button
                    className="btn-icon btn--light cursor-pointer"
                    onClick={() => plusHandle()}
                  >
                    <span className="icon iPlus"></span>
                  </button>
                </div>
                {error && <span className="text-error mt-2">{t(error)}</span>}
              </div>
            </div>

            <div className="infor-row">
              <div className="infor-row__name text--light3 text--sm">
                {t('pinefolio.order.totalMoneyTemporary')}
              </div>
              <div className="infor-row__value text--light fw-500">
                {numberFormat(purchasePower?.ordrVal, 0, '0')}
              </div>
            </div>
            <div className="infor-row">
              <div className="infor-row__name text--light3 text--sm">
                {t('pinefolio.order.feeTemporary')}
              </div>
              <div className="infor-row__value">
                {numberFormat(purchasePower?.fee, 0, '0')}
              </div>
            </div>
            {orderType == 2 && (
              <div className="infor-row">
                <div className="infor-row__name text--light3 text--sm">
                  {t('pinefolio.order.taxTemporary')}
                </div>
                <div className="infor-row__value">
                  {numberFormat(purchasePower?.tax, 0, '0')}
                </div>
              </div>
            )}

            <div className="infor-row">
              <div className="infor-row__name text--sm text--light3 d-flex align-items-center">
                <div className=" text--light3 text--sm">
                  {t('txt-total-payment')}
                </div>
                <OverlayTrigger
                  trigger={['hover', 'focus']}
                  placement="top"
                  className="portfolio-overlay"
                  overlay={
                    <Tooltip style={{ 'z-index': '99999' }}>
                      {orderType == 1
                        ? t('pinefolio.order.buyOrderExplain')
                        : t('pinefolio.order.sellOrderExplain')}
                    </Tooltip>
                  }
                >
                  <span className="icon iInfor iTooltip ml-1 mr-1"></span>
                </OverlayTrigger>
              </div>

              <div className="infor-row__value r text--md fw-500">
                {numberFormat(purchasePower?.ordrAmt, 0, '0')}
              </div>
            </div>
            {orderType == 1 && error && (
              <div className="infor-row">
                <div className="infor-row__name text--light3 text--sm">
                  {t('txt-missing')}
                </div>
                <div className="infor-row__value d text--md fw-500">
                  {numberFormat(purchasePower?.isufAmt, 0, '0')}
                </div>
              </div>
            )}
          </div>

          <div className="product-info__cashTransfer">
            {orderType == 1 && error && (
              <>
                <div className="dash-line"></div>
                <CashInternalTransfer
                  missing={purchasePower?.isufAmt}
                  target={targetAccount}
                  callback={cashTransferCallback}
                  key={reload}
                  reloadCallback={() => setReload(!reload)}
                />
              </>
            )}
          </div>

          <div className="product-info__summaries">
            <span className="product-info__summary">
              <span className="text--sm text--light3">
                {t('txt-sub-account')}
              </span>
              <span className="text--sm text--light">
                {targetAccount?.subAcntNo}
              </span>
            </span>
            <span className="product-info__summary">
              {orderType == 1 && (
                <>
                  <span className="text--sm text--light3">{t('txt-pp')}</span>
                  <span className="text--sm text--light">
                    {numberFormat(
                      purchasePower?.acntTopupPp || purchasePower?.acntPp,
                      0,
                      '-'
                    )}
                  </span>
                </>
              )}

              {orderType == 2 && (
                <>
                  <span className="text--sm text--light3">
                    {t('txt-sell-max')}
                  </span>
                  <span className="text--sm text--light">
                    {numberFormat(purchasePower?.selAbleQty, 0, '0')}
                  </span>
                </>
              )}
            </span>
          </div>
        </div>
        <div>
          <div className="order-actions">
            <button
              className={`${
                orderType == 1 ? 'btn btn--buy' : 'btn btn--sell'
              } ${disableHandle() ? 'disabled' : ''}`}
              onClick={() => {
                if (!disableHandle()) {
                  setOrderConfirm(true);
                }
              }}
              // className={`btn btn--sell ${disableHandle() ? 'disabled' : ''}`}
              disabled={error && StringToInt(quantity) <= 0}
            >
              {orderType == 1
                ? t('pinefolio.order.buy')
                : t('pinefolio.order.sell')}
            </button>
            <button className="btn btn--cancel" onClick={() => cancelHandle()}>
              {t('txt-cancel')}
            </button>
          </div>
        </div>
        {confirmOrder && (
          <Contract
            orderType={orderType}
            quantity={quantity}
            close={onCloseContract}
          />
        )}
      </div>
    );
  }
);

export default ProductInfo;
