import React, { useState, useEffect, useMemo, useContext } from 'react';
import ReactPlayer from 'react-player';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { toogleVideoModal } from '../../../news/action';

const appUrl = `${process.env.REACT_APP_API_URL_2}`;

const ViewVideoModal = withNamespaces('translations')(
  ({ show, lang, video, setVideo, t }) => {
    const PAGE_SIZE = 10;
    const dispatch = useDispatch();
    const toogle = useSelector((state) => state?.news?.videoModalShow);
    const theme = useSelector((state) => state.client.currentTheme);

    const handleClose = (close) => {
      dispatch(toogleVideoModal(close));
      setVideo(null);
    };

    return (
      <Modal
        show={toogle}
        onHide={() => handleClose(false)}
        dialogClassName="wts-modal"
        backdropClassName="wts-modal-backdrop"
        contentClassName={`${
          theme ? theme.name : 'dark'
        } bond-detail wts-modal-content wts-modal-content--xl`}
        className="video__modal"
        keyboard={true}
        size={'xl'}
        centered
        z-index={11123}
      >
        {
          <div className="w-100">
            <Modal.Body className="video-popup">
              <div className="row">
                <div className="col-lg-12">
                  <p className="market__text market__text--title fz_16 pt-1 pb-1">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: video.flName,
                      }}
                    />
                  </p>
                  <ReactPlayer
                    url={video.flUrl}
                    playing
                    volume={0.5}
                    width="100%"
                    height="505px"
                    controls={true}
                    playsinline={true}
                  />
                </div>
              </div>
            </Modal.Body>
          </div>
        }
      </Modal>
    );
  }
);

export default ViewVideoModal;
