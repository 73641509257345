import {
  GET_CPT_BOARD_INFOR_ERROR,
  GET_CPT_BOARD_INFOR_REQUEST,
  GET_CPT_BOARD_INFOR_SUCCESS,
  GET_CPT_PERSONAL_INFOR_ERROR,
  GET_CPT_PERSONAL_INFOR_REQUEST,
  GET_CPT_PERSONAL_INFOR_SUCCESS,
  GET_CPT_PER_CHART_ERROR,
  GET_CPT_PER_CHART_REQUEST,
  GET_CPT_PER_CHART_SUCCESS,
  GET_CPT_ROUND_INFOR_ERROR,
  GET_CPT_ROUND_INFOR_REQUEST,
  GET_CPT_ROUND_INFOR_SUCCESS,
  GET_CPT_STOCK_BAL_ERROR,
  GET_CPT_STOCK_BAL_REQUEST,
  GET_CPT_STOCK_BAL_SUCCESS,
  GET_CPT_TOP_RANK_ERROR,
  GET_CPT_TOP_RANK_REQUEST,
  GET_CPT_TOP_RANK_SUCCESS,
  GET_CPT_TOP_SYMBOL_ERROR,
  GET_CPT_TOP_SYMBOL_REQUEST,
  GET_CPT_TOP_SYMBOL_SUCCESS,
  GET_REGIST_CPT_STAT_ERROR,
  GET_REGIST_CPT_STAT_REQUEST,
  GET_REGIST_CPT_STAT_SUCCESS,
  REGISTER_COMPETITON_ERROR,
  REGISTER_COMPETITON_REQUEST,
  REGISTER_COMPETITON_SUCCESS,
  SET_ROUND_VIEW_RESULT,
  UN_REGIST_CPT_REQUEST,
} from './constants';

export const registerCompetitonRequest = (params, callback) => {
  return {
    type: REGISTER_COMPETITON_REQUEST,
    params: params,
    callback,
  };
};

export const registerCompetitonSuccess = (nickName) => {
  return {
    type: REGISTER_COMPETITON_SUCCESS,
    nickName: nickName,
  };
};

export const registerCompetitonError = (error) => {
  return {
    type: REGISTER_COMPETITON_ERROR,
    error: error,
  };
};

export const getCptRoundInfoRequest = (params) => {
  return {
    type: GET_CPT_ROUND_INFOR_REQUEST,
    params,
  };
};

export const getCptRoundInfoSuccess = (cptRoundInfo) => {
  return {
    type: GET_CPT_ROUND_INFOR_SUCCESS,
    cptRoundInfo,
  };
};

export const getCptRoundInfoError = (error) => {
  return {
    type: GET_CPT_ROUND_INFOR_ERROR,
    error,
  };
};

export const getCptBoardInfoRequest = (params) => {
  return {
    type: GET_CPT_BOARD_INFOR_REQUEST,
    params,
  };
};

export const getCptBoardInfoSuccess = (cptBoardInfo) => {
  return {
    type: GET_CPT_BOARD_INFOR_SUCCESS,
    cptBoardInfo: cptBoardInfo,
  };
};

export const getCptBoardInfoError = (error) => {
  return {
    type: GET_CPT_BOARD_INFOR_ERROR,
    error,
  };
};

export const getRegistCptStatRequest = (params) => {
  return {
    type: GET_REGIST_CPT_STAT_REQUEST,
    params,
  };
};

export const getRegistCptStatSuccess = (registCptStat) => {
  return {
    type: GET_REGIST_CPT_STAT_SUCCESS,
    registCptStat,
  };
};

export const getRegistCptStatError = (error) => {
  return {
    type: GET_REGIST_CPT_STAT_ERROR,
    error,
  };
};

export const unRegistCpt = (params, callback) => {
  return {
    type: UN_REGIST_CPT_REQUEST,
    params,
    callback,
  };
};

export const getCptStockBalRequest = (params) => {
  return {
    type: GET_CPT_STOCK_BAL_REQUEST,
    params,
  };
};

export const getCptStockBalSuccess = (cptStockBal) => {
  return {
    type: GET_CPT_STOCK_BAL_SUCCESS,
    cptStockBal,
  };
};

export const getCptStockBalError = (error) => {
  return {
    type: GET_CPT_STOCK_BAL_ERROR,
    error,
  };
};

export const getCptTopRankRequest = (params) => {
  return {
    type: GET_CPT_TOP_RANK_REQUEST,
    params,
  };
};

export const getCptTopRankSuccess = (cptTopRank) => {
  return {
    type: GET_CPT_TOP_RANK_SUCCESS,
    cptTopRank,
  };
};

export const getCptTopRankError = (error) => {
  return {
    type: GET_CPT_TOP_RANK_ERROR,
    error,
  };
};

export const getCptTopSymbolRequest = (params) => {
  return {
    type: GET_CPT_TOP_SYMBOL_REQUEST,
    params,
  };
};

export const getCptTopSymbolSuccess = (cptTopSymbol) => {
  return {
    type: GET_CPT_TOP_SYMBOL_SUCCESS,
    cptTopSymbol,
  };
};

export const getCptTopSymbolError = (error) => {
  return {
    type: GET_CPT_TOP_SYMBOL_ERROR,
    error,
  };
};

export const getCptPerChartRequest = (params) => {
  return {
    type: GET_CPT_PER_CHART_REQUEST,
    params,
  };
};

export const getCptPerChartSuccess = (cptPerChart) => {
  return {
    type: GET_CPT_PER_CHART_SUCCESS,
    cptPerChart,
  };
};

export const getCptPerChartError = (error) => {
  return {
    type: GET_CPT_PER_CHART_ERROR,
    error,
  };
};

export const getCptPersonalInforRequest = (params) => {
  return {
    type: GET_CPT_PERSONAL_INFOR_REQUEST,
    params,
  };
};

export const getCptPersonalInforSuccess = (cptPersonalInfor) => {
  return {
    type: GET_CPT_PERSONAL_INFOR_SUCCESS,
    cptPersonalInfor,
  };
};

export const getCptPersonalInforError = (error) => {
  return {
    type: GET_CPT_PERSONAL_INFOR_ERROR,
    error,
  };
};

export const setRoundViewResult = (params) => {
  return {
    type: SET_ROUND_VIEW_RESULT,
    params,
  };
};
