import React from 'react';
import { withNamespaces } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { _formatDate2, numberFormat } from '../../../../util';
import { OverlayTrigger, Tooltip, Popover } from 'react-bootstrap';
import { useState } from 'react';
import { getBondCommonRequest2 } from '../../action';
import { v4 as uuidv4 } from 'uuid';

const Collaterals = withNamespaces('translations')(({ bondCode, t }) => {
  const [show, setShow] = useState(false);
  const [collateralDetail, setCollateralDetail] = useState([]);
  const dispatch = useDispatch();
  const {
    setting: { lang = 'vi' },
    currentTheme: theme,
  } = useSelector((state) => state.client);

  const getColleralsHandle = (bondCode) => {
    if (!show) {
      const uuid = uuidv4();
      const params = {
        group: 'CORE',
        cmd: 'getBondGuarantee',
        channel: 'WTS',
        rqId: uuid,
        data: {
          bndCode: bondCode,
          langTp: lang,
        },
      };

      const callback = (data) => {
        setShow(true);
        setCollateralDetail(data);
      };

      dispatch(getBondCommonRequest2(params, callback, false));
    } else {
      setShow(false);
    }
  };

  return (
    <OverlayTrigger
      trigger={['click']}
      placement="bottom"
      onToggle={() => getColleralsHandle(bondCode)}
      show={show}
      rootClose
      overlay={
        <Popover
          className={theme.name == 'light' ? 'arrow-light' : 'arrow-dark'}
          style={{
            maxWidth: '500px',
            background: 'unset',
          }}
        >
          <Popover.Content
            style={{
              padding: 'unset',
              borderRadius: '5px',
            }}
            className="box-shadown"
          >
            <Collateral
              data={collateralDetail?.length > 0 ? collateralDetail : []}
            />
          </Popover.Content>
        </Popover>
      }
    >
      <span className="cursor-pointer text text--light-blue3">
        {t('bond.viewDetail')}
      </span>
    </OverlayTrigger>
  );
});

const Collateral = withNamespaces('translations')(({ t, data }) => {
  const theme = useSelector((state) => state.client.currentTheme);
  return (
    <div
      className={`${
        theme ? theme.name : 'dark'
      } bond-detail wts-modal-content--sm rounded`}
    >
      <div className="bond-detail-panel">
        <div className="bond-detail-body">
          <div className="bond-detail-overview">
            <table className="table table-bordered">
              <thead>
                <th className="text-left text--light3">
                  <span>{t('bond.collateralDetail.type')}</span>
                </th>
                <th className="text-left text--light3">
                  <span>{t('bond.collateralDetail.ValuationDate')}</span>
                </th>
                <th className="text-left text--light3">
                  <span>{t('bond.collateralDetail.rate')}</span>
                </th>
                <th className="text-left text--light3">
                  <span>{t('bond.collateralDetail.value')}</span>
                </th>
              </thead>
              <tbody>
                {data.map((value, index) => {
                  return (
                    <tr key={index}>
                      <td className="text-left text--light fw-500">
                        <span>{value?.assetTp}</span>
                      </td>
                      <td className="text-left text--light fw-500">
                        <span>{_formatDate2(value?.valDate)}</span>
                      </td>
                      <td className="text-left text--light fw-500">
                        <span>{value?.rate}%</span>
                      </td>
                      <td className="text-left text--light fw-500">
                        <span>{numberFormat(value.valAmt, 0, '0')}</span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Collaterals;
