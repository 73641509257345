import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';

import { makeGetToken } from '../../../lib/selector';

import { GiAnticlockwiseRotation } from 'react-icons/gi';
import { translate } from 'react-i18next';
class FormHisNapRutTien extends Component {
  constructor() {
    super();

    this.state = {
      account: null,
    };
  }

  submit = (values) => {
    const { type } = values;
    const { token } = this.props;
  };

  _handleChange = (e) => {
    console.log(e.target.value);
    const { value } = e.target;
    this.props.handleQuery(value);
  };

  render() {
    const { handleSubmit, pristine, submitting, reset, t } = this.props;
    const _type = [
      { value: '1', label: t('txt-stt-internal') },
      { value: '2', label: t('txt-stt-bank') },
    ];
    return (
      <form
        className="form-hisMargin d-flex"
        onSubmit={handleSubmit(this.submit)}
      >
        <Field
          name="type"
          component="select"
          className="ml-2 form-control form-input fz_14"
          style={{ width: '165px' }}
          onChange={this._handleChange}
        >
          {_type.map((typeOption) => (
            <option value={typeOption.value} key={typeOption.value}>
              {typeOption.label}
            </option>
          ))}
        </Field>
        <button
          className="ml-2 btn-reload mr-2"
          disabled={pristine || submitting}
          onClick={() => {
            reset();
            this.props.handleQuery('1');
          }}
        >
          <GiAnticlockwiseRotation className="fz_14 text-white" />
        </button>
      </form>
    );
  }
}

FormHisNapRutTien = reduxForm({
  form: 'hisNapRutTien',
  enableReinitialize: true,
})(FormHisNapRutTien);

const selector = formValueSelector('hisNapRutTien');

const makeMapStateToProps = () => {
  const getToken = makeGetToken();

  const mapStateToProps = (state) => {
    const { type, status } = selector(state, 'type', 'status');

    const token = getToken(state);
    return {
      token,
      type,
      status,
      initialValues: {
        type: 'Nạp tiền',
      },
    };
  };
  return mapStateToProps;
};

FormHisNapRutTien = connect(makeMapStateToProps)(FormHisNapRutTien);

export default translate('translations')(FormHisNapRutTien);
