import { useState } from 'react';
import { withNamespaces } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  makeGetBrokerInfo,
  makeGetSerList4Cust,
} from '../../../../../lib/selector';
import { setAuth } from '../../../../client/actions';
import ModalRemovePartner from '../../modal/modalRemovePartner';

const ReferrerSection = withNamespaces('translations')(
  ({ t, onChange, handleLoadData }) => {
    const [brokerToRemove, setBrokerToRemove] = useState(false);
    const { serList4Cus } = useSelector((state) => state.customer);
    const { auth } = useSelector((state) => state.client);
    const dispatch = useDispatch();

    return (
      <div className="section referrer">
        <div className="title border-bottom">
          <span className="text text--md text--light">
            {t('partner.referrerInformation')}
          </span>
        </div>
        <div className="referrer_item">
          <div className="text text--sm text--light3">
            {t('partner.referralCode')}
          </div>
          <div className="text text--sm text--light">
            {serList4Cus.brokerId}
          </div>

          <div className="referrer_action">
            {serList4Cus.canEditYN === 'Y' && (
              <button
                className={`btn w-28 ${
                  auth && auth.isVerified ? 'btn-edit' : 'btn--authen'
                }`}
                onClick={() => {
                  if (auth && auth.isVerified) {
                    onChange();
                  } else {
                    dispatch(setAuth());
                  }
                }}
              >
                <span className="icon iEdit"></span>
              </button>
            )}
            {serList4Cus.canCancelYN === 'Y' && (
              <button
                className={`btn w-28 ${
                  auth && auth.isVerified ? 'btn-cancel' : 'btn--authen'
                }`}
                onClick={() => {
                  if (auth && auth.isVerified) {
                    setBrokerToRemove(true);
                  } else {
                    dispatch(setAuth());
                  }
                }}
              >
                <span className="icon iClose"></span>
              </button>
            )}
          </div>
        </div>
        <div className="referrer_item">
          <div className="text text--sm text--light3 ">
            {t('partner.referralName')}
          </div>
          <div className="text text--sm text--light">
            {serList4Cus.brokerNm}
          </div>
        </div>

        <ModalRemovePartner
          t={t}
          showModal={brokerToRemove}
          handleClose={() => setBrokerToRemove(false)}
          isCustomer={true}
          dataPartner={serList4Cus}
          handleLoadData={handleLoadData}
        />
      </div>
    );
  }
);

export default ReferrerSection;
