import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import PerfectScrollBar from 'react-perfect-scrollbar';
import { Col, Card, Alert } from 'react-bootstrap';
import {
  makeGetToken,
  makeGetListAccount,
  makeGetDefaultAccount,
  makeGetStockTarget,
  makeGetCashTransfer,
  makeGetSourceBalance,
  makeGetTargetBalance,
  makeGetHisTransfer,
} from '../../lib/selector';

import {
  stockTargetRequest,
  cashBalanceSourceRequest,
  cashBalanceTargetRequest,
  hisTransferRequest,
} from '../../containers/cash/actions';

import { filter, sumBy, isEqual } from 'lodash';

import SelectUser from '../select/selectUserCash';
import {
  numberFormat,
  formatDate,
  getTransferStatus,
  _formatDate,
} from '../../util';

import FormChuyenTien from './form/formChuyenTien';
import { v4 as uuidv4 } from 'uuid';
import FormHisCashEx from './form/formHisCashExchange';
import PaginationTable from '../PaginationTable';
import { ReloadButton } from '../btnReload';
import { translate } from 'react-i18next';
import { Logout, logoutRequest } from '../modal/login/actions';
import { unsetClient } from '../../containers/client/actions';
import { removeCookie } from '../../lib/storages';

class ChuyenTienTieuKhoan extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      listSource: [],
      listTarget: [],
      _defSource: {},
      _defTarget: {},
      msg: [],
    };
  }

  componentDidMount() {
    const { listAccount, defaultAccount } = this.props;
    const _listSource = filter(listAccount, (o) => o.subAcntStat === 1);
    if (listAccount && defaultAccount) {
      const listTarget = filter(
        listAccount,
        (o) => o.subAcntNo !== defaultAccount.subAcntNo && o.subAcntStat === 1
      );
      this.setState(
        {
          listTarget,
          _defTarget:
            (listTarget && !!listTarget.length && listTarget[0]) || {},
          _defSource: defaultAccount,
          listSource: _listSource,
        },
        () => {
          this._handleLoadDataTarget();
          this._handleLoadCashBalanceSource();
          this._handleLoadCashBalanceTarget();
          this._handleLoadListTransfer();
        }
      );
    }
  }

  componentDidUpdate(preProps) {
    const { listAccount, defaultAccount, cashTransfer, t, token } = this.props;
    const _listSource = filter(listAccount, (o) => o.subAcntStat === 1);
    if (listAccount && !isEqual(listAccount, preProps.listAccount)) {
      const _defSource = defaultAccount || _listSource[0];
      const listTarget = filter(
        listAccount,
        (o) => o.subAcntNo !== _defSource.subAcntNo && o.subAcntStat === 1
      );

      this.setState(
        {
          listTarget,
          _defTarget:
            (listTarget && !!listTarget.length && listTarget[0]) || {},
          listSource: _listSource,
          _defSource,
          msg: [],
        },
        () => {
          this._handleLoadDataTarget();
          this._handleLoadCashBalanceSource();
          this._handleLoadCashBalanceTarget();
          this._handleLoadListTransfer();
        }
      );
    }

    if (cashTransfer && !isEqual(cashTransfer, preProps.cashTransfer)) {
      console.log(cashTransfer);
      const { msg } = this.state;
      let _msg;
      if (cashTransfer.http !== 200) {
        if (cashTransfer.http === 401) {
          if (token) this.props.dispatch(logoutRequest(token));

          this.props.dispatch(unsetClient());
          removeCookie('token');
          removeCookie('authen');

          this.props.dispatch(Logout());
        }
        _msg = [...msg, { type: 'danger', msg: cashTransfer.data.message }];
      } else {
        _msg = [
          ...msg,
          { type: 'success', msg: t('txt-title-transfer-success') },
        ];
        this._handleLoadCashBalanceSource();
        this._handleLoadCashBalanceTarget();
        this._handleLoadListTransfer();
      }
      console.log(_msg);
      this.setState({ msg: _msg });
      setTimeout(() => {
        this.setState({ msg: [] });
      }, 5000);
    }
  }

  _handleLoadCashBalanceSource = () => {
    const { token } = this.props;
    const { _defSource } = this.state;

    if (!_defSource) return;

    const uuid = uuidv4();
    const resData = {
      group: 'CORE',
      user: token.user,
      session: token.session,
      cmd: 'getTotalAssetAll',
      rqId: uuid,
      channel: 'WTS',
      data: {
        acntNo: _defSource.acntNo,
        subAcntNo: _defSource.subAcntNo,
      },
    };

    this.props.dispatch(cashBalanceSourceRequest(resData));
  };

  _handleLoadCashBalanceTarget = () => {
    const { token } = this.props;
    const { _defTarget } = this.state;

    if (!_defTarget || !token) return;

    const uuid = uuidv4();
    const resData = {
      group: 'CORE',
      user: token.user,
      session: token.session,
      cmd: 'getTotalAssetAll',
      rqId: uuid,
      channel: 'WTS',
      data: {
        acntNo: _defTarget.acntNo,
        subAcntNo: _defTarget.subAcntNo,
      },
    };

    this.props.dispatch(cashBalanceTargetRequest(resData));
  };

  _handleLoadDataTarget = () => {
    const { _defTarget } = this.state;
    const { token } = this.props;
    if (!_defTarget || !token) return;

    const uuid = uuidv4();
    const resData = {
      group: 'CORE',
      user: token.user,
      session: token.session,
      cmd: 'getAvailStockList',
      rqId: uuid,
      channel: 'WTS',
      data: {
        acntNo: _defTarget.acntNo,
        subAcntNo: _defTarget.subAcntNo,
      },
    };

    this.props.dispatch(stockTargetRequest(resData));
  };

  _handleLoadListTransfer = (fromDate, endDate) => {
    const { _defSource } = this.state;
    const { token } = this.props;
    if (!_defSource) return;

    const d = new Date();
    d.setDate(d.getDate() - 30);

    if (!fromDate) fromDate = formatDate(d, '');
    else fromDate = _formatDate(fromDate);

    if (!endDate) endDate = formatDate(new Date(), '');
    else endDate = _formatDate(endDate);

    const uuid = uuidv4();
    const resData = {
      group: 'CORE',
      user: token.user,
      session: token.session,
      cmd: 'getCashTransferHist',
      rqId: uuid,
      channel: 'WTS',
      data: {
        acntNo: _defSource.acntNo,
        subAcntNo: _defSource.subAcntNo,
        fromDate: fromDate,
        toDate: endDate,
        transType: '1',
      },
    };

    this.props.dispatch(hisTransferRequest(resData));
  };

  _handleChangeUserSource = (user) => {
    // console.log(user)
    const { listAccount } = this.props;
    const _listSource = filter(listAccount, (o) => o.subAcntStat === 1);
    const listTarget = filter(
      listAccount,
      (o) => o.subAcntNo !== user.subAcntNo && o.subAcntStat === 1
    );

    this.setState(
      {
        listTarget,
        _defTarget: (listTarget && !!listTarget.length && listTarget[0]) || {},
        listSource: _listSource,
        _defSource: user,
        msg: [],
      },
      () => {
        this._handleLoadDataTarget();
        this._handleLoadCashBalanceSource();
        this._handleLoadCashBalanceTarget();
        this._handleLoadListTransfer();
      }
    );
  };

  _handleChangeUserTarget = (user) => {
    // console.log(user)
    this.setState({ _defTarget: user, msg: [] }, () => {
      this._handleLoadDataTarget();
      this._handleLoadCashBalanceTarget();
    });
  };

  render() {
    const { listTarget, listSource, _defSource, _defTarget, msg } = this.state;

    const { stockTarget, targetBalance, hisTransfer, t } = this.props;
    const _targetQuantity =
      stockTarget && stockTarget.list && sumBy(stockTarget.list, 'quantity');

    const columns = [
      {
        key: 'defaultAccount',
        text: t('txt-label-source'),
        className: 'text-center',
        align: 'center',
        cell: (record) => {
          return <span>{record && record.subAcntNo}</span>;
        },
      },
      {
        key: 'toAcntNo',
        text: t('txt-label-target'),
        className: 'text-center',
        align: 'center',
      },
      {
        key: 'trdAmt',
        text: t('txt-label-cash-transfer-amount'),
        className: 'text-right',
        align: 'center',
        cell: (record) => {
          return numberFormat(record.trdAmt, 0, '0');
        },
      },
      {
        key: 'trdFee',
        text: t('txt-label-fee'),
        className: 'text-right',
        align: 'center',
        cell: (record) => {
          return numberFormat(record.trdFee, 0, '0');
        },
      },
      {
        key: 'desc',
        text: t('txt-label-cash-transfer-content'),
        className: 'text-left',
        align: 'center',
      },
      {
        key: 'status',
        text: t('txt-status'),
        className: (record) => {
          return (
            'text-left ' +
            (record.status === '1' ? 'r' : record.status === '2' ? 'i' : 'd')
          );
        },
        align: 'center',
        cell: (record) => {
          return getTransferStatus(record.status);
        },
      },
      {
        key: 'trdDt',
        text: t('txt-label-create-date'),
        className: 'text-center',
        align: 'center',
      },
    ];

    return (
      <Fragment>
        <div className="d-flex flex-row mt-3 mb-2">
          <span
            className="mr-auto ml-2 text-white"
            style={{ fontSize: '18px' }}
          >
            {t('txt-label-internal-transfer')}
          </span>
        </div>
        <PerfectScrollBar>
          <div className="d-flex flex-row">
            <Col className="d-flex flex-column pr-0">
              <Card>
                <Card.Body style={{ borderRadius: '5px' }}>
                  <div className="d-flex align-items-center justify-content-between p-3">
                    <div className="d-flex align-items-center">
                      <label className="text-content fz_14">
                        {t('txt-label-source')}
                      </label>
                      {listSource && !!listSource.length && (
                        <SelectUser
                          defVal={_defSource}
                          grOptions={listSource}
                          handleChangeUser={this._handleChangeUserSource}
                        />
                      )}
                      <ReloadButton
                        type="button"
                        className="ml-2 btn-reload"
                        onClick={this._handleLoadCashBalanceSource}
                      />
                    </div>
                  </div>
                </Card.Body>
              </Card>
              <FormChuyenTien _defSource={_defSource} _defTarget={_defTarget} />
            </Col>
            <Col className="d-flex flex-column">
              <Card>
                <Card.Body style={{ borderRadius: '5px' }}>
                  <div className="d-flex align-items-center justify-content-between p-3">
                    <div className="d-flex align-items-center">
                      <label className="text-content fz_14">
                        {t('txt-label-target')}
                      </label>
                      {listTarget && !!listTarget.length && (
                        <SelectUser
                          defVal={_defTarget}
                          grOptions={listTarget}
                          handleChangeUser={this._handleChangeUserTarget}
                        />
                      )}
                      <ReloadButton
                        type="button"
                        className="ml-2 btn-reload"
                        onClick={this._handleLoadCashBalanceTarget}
                      />
                    </div>
                    {/* <span className="text-content fz_14">{`${t(
                      'txt-label-stock-holding'
                    )} ${
                      stockTarget && stockTarget.list
                        ? stockTarget.list.length
                        : ''
                    }`}</span>
                    <span className="text-content fz_14">{`${t(
                      'txt-label-vol-holding'
                    )} ${numberFormat(_targetQuantity, 0)}`}</span> */}
                  </div>
                </Card.Body>
              </Card>
              {msg && !!msg.length && (
                <PerfectScrollBar style={{ height: '60px' }}>
                  {msg.map((item, idx) => (
                    <Alert key={idx} variant={item.type} className="mt-2">
                      {item.msg}
                    </Alert>
                  ))}
                </PerfectScrollBar>
              )}
              <PerfectScrollBar style={{ height: '80px' }}>
                <table className="table mt-2">
                  <tbody>
                    <tr>
                      <th className="text-left">{t('txt-asset-withdraw')}</th>
                      <th className="text-right">
                        {targetBalance &&
                          numberFormat(targetBalance.wdrawAvail, 0, '0')}
                      </th>
                    </tr>
                  </tbody>
                </table>
              </PerfectScrollBar>
            </Col>
          </div>
          <div className="d-flex justify-content-between mt-3">
            <label className="text-white text-uppercase ml-3">
              {t('txt-label-history')}
            </label>
            <FormHisCashEx handleQuery={this._handleLoadListTransfer} />
          </div>
          <PaginationTable
            defaultAccount={_defSource}
            pageSize={15}
            columns={columns}
            source={hisTransfer && hisTransfer.list ? hisTransfer.list : []}
          />
        </PerfectScrollBar>
      </Fragment>
    );
  }
}

const makeMapStateToProps = () => {
  const getToken = makeGetToken();
  const getListAccount = makeGetListAccount();
  const getDefaultAccount = makeGetDefaultAccount();
  const getStockTarget = makeGetStockTarget();
  const getCashTransfer = makeGetCashTransfer();

  const getSourceBalance = makeGetSourceBalance();
  const getTargetBalance = makeGetTargetBalance();
  const getListTransfer = makeGetHisTransfer();

  const mapStateToProps = (state) => {
    return {
      token: getToken(state),
      listAccount: getListAccount(state),
      defaultAccount: getDefaultAccount(state),
      stockTarget: getStockTarget(state),
      cashTransfer: getCashTransfer(state),
      sourceBalance: getSourceBalance(state),
      targetBalance: getTargetBalance(state),
      hisTransfer: getListTransfer(state),
      stockExchange: state.cash.stockExchange,
      exHis: state.cash.exHis,
    };
  };
  return mapStateToProps;
};

export default connect(makeMapStateToProps)(
  translate('translations')(ChuyenTienTieuKhoan)
);
