import i18n from 'i18next';
import { reactI18nextModule } from 'react-i18next';
import { loadState } from '../lib/storages';

import en from './locales/en';
import vi from './locales/vi';

const resources = {
  en: {
    translations: en,
  },
  vi: {
    translations: vi,
  },
};

const settings = loadState('setting');
const lang = settings?.lang || 'vi';

i18n
  .use(reactI18nextModule) // passes i18n down to react-i18next
  .init({
    resources,
    lng: lang,
    fallbackLng: lang, // use en if detected lng is not available
    debug: false,

    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',

    keySeparator: '.',

    interpolation: {
      escapeValue: false, // react already safes from xss
      formatSeparator: ',',
    },

    react: {
      wait: true,
    },
  });

export default i18n;
