import React from 'react';

import { find, filter, isEqual, sortBy, sumBy, take, takeRight } from 'lodash';
import { Button } from 'react-bootstrap';
import { BsFillCaretDownFill, BsFillCaretUpFill } from 'react-icons/bs';
import { translate } from 'react-i18next';
import { numberFormat } from '../util';
import PagingCustom from '../components/common/paging/PagingCustom';
import SwitchCustom from '../components/common/switch/index';
import { getCookie, setCookie } from '../lib/storages';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import './PaginationTable.scss';

class PaginationTable extends React.Component {
  PAGE_SIZES_OPTIONS = [
    { value: 15, label: '15' },
    { value: 30, label: '30' },
    { value: 50, label: '50' },
  ];

  state = {
    // paging
    continued: false,
    totalCount: 0,
    page: 1,
    contentShow: [],
    totalShow: null,
    totalPage: 1,
    pageSizeOption: {},
    txtSymbol: '',
    _totalShow: '',
  };

  componentDidMount() {
    const { contentShow } = this.state;
    const { pageSize } = this.props;
    if (!this.PAGE_SIZES_OPTIONS.find((x) => pageSize && x.value == pageSize)) {
      this.PAGE_SIZES_OPTIONS.push({
        value: pageSize,
        label: pageSize + '',
      });

      this.PAGE_SIZES_OPTIONS.sort((a, b) => a.value - b.value);
    }

    const pageSizeOptions = [
      ...this.PAGE_SIZES_OPTIONS,
      { value: contentShow?.length ?? 0, label: 'txt-all' },
    ];

    const _hideOddSymbol = getCookie('hideOddSymbol') == 'true' ? true : false;
    const _hideSoldOut = getCookie('hideSoldOut') == 'true' ? true : false;

    const { source } = this.props;

    if (source) {
      const _totalShow = source && find(source, (x) => x.symbol == 'TOTAL');
      this.setState({
        hideOddSymbol: _hideOddSymbol,
        hideSoldOut: _hideSoldOut,
        totalCount: source ? source.length : 0,
        totalShow: _totalShow,
        contentShow: source,
        pageSizeOptions: pageSizeOptions,
        pageSizeOption: this.PAGE_SIZES_OPTIONS[0],
      });
      this.handleQueryPage(1);
    }
  }

  componentDidUpdate(preProps) {
    const { contentShow, source, pageSize } = this.props;

    if (!this.PAGE_SIZES_OPTIONS.find((x) => pageSize && x.value == pageSize)) {
      this.PAGE_SIZES_OPTIONS.push({
        value: pageSize,
        label: pageSize + '',
      });

      this.PAGE_SIZES_OPTIONS.sort((a, b) => a.value - b.value);
    }

    const pageSizeOptions = [
      ...this.PAGE_SIZES_OPTIONS,
      { value: contentShow?.length ?? 0, label: 'txt-all' },
    ];

    if (!isEqual(source, preProps.source)) {
      const _totalShow = source && find(source, (x) => x.symbol == 'TOTAL');
      this.setState({
        totalCount: source ? source.length : 0,
        totalShow: _totalShow,
        contentShow: source,
        pageSizeOptions: pageSizeOptions,
        pageSizeOption: this.PAGE_SIZES_OPTIONS[0],
      });
      this.handleQueryPage(1);
    }
  }

  handleQueryPage = (page) => {
    const { source, hasSwitch } = this.props;
    const { pageSizeOption, sortType, sortField, hideSoldOut, hideOddSymbol } =
      this.state;

    if (!source) return;

    const oddSymbols = source.filter(
      (x) => x.balQty < 100 && x.symbol !== 'TOTAL'
    );
    if (oddSymbols && oddSymbols.length > 0) {
      const totBuyAmt = sumBy(oddSymbols, 'totBuyAmt');
      const totCurAmt = sumBy(oddSymbols, 'totCurAmt');
      this.setState({ _totalOddLot: { totBuyAmt, totCurAmt } });
    } else {
      this.setState({ _totalOddLot: { totBuyAmt: 0, totCurAmt: 0 } });
    }

    let _source = source.filter((position) => position.symbol !== 'TOTAL');

    if (hideSoldOut && hasSwitch) {
      _source = _source.filter((x) => x.balQty !== 0);
    }

    if (hideOddSymbol && hasSwitch) {
      _source = _source.filter((x) => x.balQty >= 100);
    }

    if (sortType === 'desc') {
      _source =
        _source && _source.length > 0 && sortBy(_source, sortField).reverse();
    }
    if (sortType === 'asc') {
      _source = _source && _source.length > 0 && sortBy(_source, sortField);
    }

    const _pageSize = pageSizeOption?.value ?? this.PAGE_SIZES_OPTIONS[0].value;

    const totalPage =
      !!_pageSize && _source && _source.length > 0
        ? Math.ceil(_source.length / _pageSize)
        : 1;

    const _takeContent = take(_source, _pageSize * page);
    const _contentShow = _pageSize
      ? takeRight(_takeContent, _takeContent.length - _pageSize * (page - 1))
      : _source;

    const _totalShow = find(source, (o) => o.symbol === 'TOTAL');

    this.setState({
      _totalShow,
      contentShow: _contentShow,
      page,
      continued: page < totalPage,
      totalPage,
      first: false,
    });
  };

  _showSortIcon = (field) => {
    const { sortType, sortField } = this.state;
    if (sortType === 'asc' && sortField == field) {
      return (
        <span>
          <BsFillCaretUpFill
            className="fz_10 position-relative"
            style={{ marginLeft: '0.15rem' }}
          />
        </span>
      );
    }

    if (sortType === 'desc' && sortField == field) {
      return (
        <span>
          <BsFillCaretDownFill
            className="fz_10 position-relative"
            style={{ marginLeft: '0.15rem' }}
          />
        </span>
      );
    }
  };

  _handleShort = (fieldName) => {
    const { sortType, sortField } = this.state;
    if (!fieldName) fieldName = sortField;

    this.setState(
      {
        sortType: sortType === 'desc' ? 'asc' : 'desc',
        page: 1,
        sortField: fieldName,
      },
      () => {
        this.handleQueryPage(1);
      }
    );
  };

  _handleSetPageSize = (pageSize) => {
    this.setState({ pageSizeOption: pageSize }, () => this.handleQueryPage(1));
  };

  _handleNextPage = () => {
    const { page } = this.state;
    this.handleQueryPage(page + 1);
  };

  _handlePrevPage = () => {
    const { page } = this.state;
    this.handleQueryPage(page - 1);
  };

  _handleHideSoldOut = () => {
    const { hideSoldOut } = this.state;
    setCookie('hideSoldOut', !hideSoldOut);
    this.setState({ hideSoldOut: !hideSoldOut }, () => {
      this.handleQueryPage(1);
    });
  };

  _handleHideOddSymbol = () => {
    const { hideOddSymbol } = this.state;
    setCookie('hideOddSymbol', !hideOddSymbol);
    this.setState({ hideOddSymbol: !hideOddSymbol }, () => {
      this.handleQueryPage(1);
    });
  };

  _handleSearchChange = (e) => {
    const { txtSymbol } = this.state;

    this.setState({ [e.target.name]: e.target.value });

    if (e.target.value) {
      this.setState({ txtSymbol: e.target.value }, () => {
        this._handleBlurSymbol();
      });
      return;
    }

    if (
      e.target.value !== null &&
      typeof e.target.value !== 'undefined' &&
      e.target.value.length === 0
    ) {
      this.handleQueryPage(1);
      return;
    }
  };

  _handleBlurSymbol = () => {
    const { txtSymbol } = this.state;
    const { source, searchField } = this.props;
    // clear paging, sort
    this.setState({ sortType: '', page: 1 });
    if (!source || !source.length) return;
    if (!txtSymbol) return this.handleQueryPage(1);

    const result = filter(source, (o) =>
      o[searchField].includes(txtSymbol.toLocaleUpperCase().trim())
    );

    if (!!result.length) {
      let totBuyAmt = 0;
      let totCurAmt = 0;
      let gainLoss = 0;
      let gainLossPc = 0;
      let change = '';

      result.map((item) => {
        if (item.symbol !== 'TOTAL') {
          totBuyAmt += item.totBuyAmt * 1;
          totCurAmt += item.totCurAmt * 1;
          gainLoss += item.gainLoss * 1;
          change += item.change;
        }
      });

      const _total = {
        symbol: 'TOTAL',
        totBuyAmt: totBuyAmt,
        totCurAmt: totCurAmt,
        gainLoss: gainLoss,
        gainLossPc: (gainLoss / totBuyAmt) * 100,
        change: change,
      };
      this.setState({ contentShow: result, _totalShow: _total });
    }
  };

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this._handleBlurSymbol();
    }
  };

  render() {
    const {
      source,
      columns,
      columns2,
      defaultAccount,
      onRowClicked,
      tblClassName,
      divClassName,
      pageSize,
      t,
      sum,
      totalColumns,
      hasPaging,
      hasSearch,
      txtSymbol,
      hasSwitch,
      title,
      hasSort,
      action,
      tooltip,
    } = this.props;
    const {
      page,
      continued,
      totalPage,
      contentShow,
      totalShow,
      pageSizeOption,
      pageSizeOptions,
      hideSoldOut,
      hideOddSymbol,
      _totalOddLot,
      _totalShow,
    } = this.state;

    const _pageSize = pageSize ? pageSize : pageSizeOption?.value || 15;

    return (
      <>
        {title && (
          <div className="table-header-box">
            <div className="header-left">
              <span className="title-table">{title}</span>
              {hasSearch && (
                <div className="security-search">
                  <span className="d-flex">
                    <span className="icon iSearch"></span>
                  </span>
                  <input
                    name="txtSymbol"
                    type="search"
                    placeholder={t('txt-symbol')}
                    value={txtSymbol}
                    onChange={(e) => {
                      this.setState({ [e.target.name]: e.target.value });
                      this._handleSearchChange(e);
                    }}
                    onBlur={this._handleBlurSymbol}
                    onKeyPress={this.handleKeyPress}
                    maxLength={10}
                    autoComplete="off"
                    // style={{
                    //   paddingLeft: '30px',
                    //   backgroundImage: `url(${icMagnifier})`,
                    //   backgroundPosition: '7px 5px',
                    //   backgroundRepeat: 'no-repeat',
                    // }}
                  />
                </div>
              )}
            </div>
            {hasSwitch && (
              <div className="switch-filter mr-3">
                <div className="switch-item">
                  <SwitchCustom
                    title={t('txt-symbols-odd-lot-hide')}
                    onChange={this._handleHideOddSymbol}
                    checked={hideOddSymbol}
                  />
                </div>
                <div className="switch-item">
                  <SwitchCustom
                    title={t('txt-symbols-sold-out-hide')}
                    onChange={this._handleHideSoldOut}
                    checked={hideSoldOut}
                  />
                </div>
              </div>
            )}
          </div>
        )}
        <div
          className={`d-flex flex-column table-container ${
            !title ? 'mt-3' : '' + (tblClassName || '')
          }`}
        >
          <table className={'table table-bordered ' + (tblClassName || '')}>
            <thead>
              <tr>
                {columns.map((column, index) => {
                  let classText = column.sortable ? 'sortable filterIcon ' : '',
                    width = column.width ? column.width : '',
                    align = column.align ? column.align : '',
                    // sortOrder = "",
                    columnStyle = column.style;
                  // if (column.sortable && this.state.sort.column == column.key) {
                  //   sortOrder = this.state.sort.order;
                  //   classText += (sortOrder) ? " " + sortOrder : "";
                  //   columnStyle = (sortOrder == "asc") ? style.sort_asc : style.sort_desc;
                  // }

                  classText += ' text-' + align;
                  if (column.TrOnlyClassName)
                    classText += ' ' + column.TrOnlyClassName;
                  return (
                    <th
                      key={`Panination_${column?.key}_${index}`}
                      className={classText}
                      width={width}
                      style={columnStyle}
                      rowSpan={column.rowSpan}
                      colSpan={column.colSpan}
                      onClick={
                        column.sortable
                          ? () => this._handleShort(column.key)
                          : null
                      }
                    >
                      <span className={`d-inline-flex align-items-center`}>
                        <span>{column.text}</span>
                        {tooltip &&
                          tooltip.map(
                            (x) =>
                              x.key == column.key && (
                                <OverlayTrigger
                                  placement="top-start"
                                  overlay={<Tooltip>{x.value}</Tooltip>}
                                >
                                  <span className="tool-tip d-flex align-items-center">
                                    <span className="icon iWarming ml-1 mr-1"></span>
                                  </span>
                                </OverlayTrigger>
                              )
                          )}
                        <span>{this._showSortIcon(column.key)}</span>
                      </span>
                    </th>
                  );
                })}
              </tr>
              {columns2 && (
                <tr>
                  {columns2.map((column, index) => {
                    let classText = column.sortable
                        ? 'sortable filterIcon '
                        : '',
                      width = column.width ? column.width : '',
                      align = column.align ? column.align : '',
                      // sortOrder = "",
                      columnStyle = column.style;
                    // if (column.sortable && this.state.sort.column == column.key) {
                    //   sortOrder = this.state.sort.order;
                    //   classText += (sortOrder) ? " " + sortOrder : "";
                    //   columnStyle = (sortOrder == "asc") ? style.sort_asc : style.sort_desc;
                    // }

                    classText += ' text-' + align;
                    if (column.TrOnlyClassName)
                      classText += ' ' + column.TrOnlyClassName;
                    return (
                      <th
                        key={`Panination_${column?.key}_${index}`}
                        className={classText}
                        width={width}
                        style={columnStyle}
                        rowSpan={column.rowSpan}
                        colSpan={column.colSpan}
                        onClick={
                          column.sortable
                            ? () => this._handleShort(column.key)
                            : null
                        }
                      >
                        <span className={`d-inline-flex align-items-center`}>
                          <span>{column.text}</span>
                          {tooltip &&
                            tooltip.map(
                              (x) =>
                                x.key == column.key && (
                                  <OverlayTrigger
                                    placement="top-start"
                                    overlay={<Tooltip>{x.value}</Tooltip>}
                                  >
                                    <span className="tool-tip d-flex align-items-center">
                                      <span className="icon iWarming ml-1 mr-1"></span>
                                    </span>
                                  </OverlayTrigger>
                                )
                            )}
                          <span>{this._showSortIcon(column.key)}</span>
                        </span>
                      </th>
                    );
                  })}
                </tr>
              )}
              {/* Total section */}
              {totalColumns && totalShow && (
                <tr>
                  {totalColumns.map((column, colIndex) => {
                    const _className =
                      typeof column.className === 'function'
                        ? column.className({
                            hideOddSymbol,
                            _totalShow,
                            _totalOddLot,
                          })
                        : column.className;

                    if (column.cell && typeof column.cell === 'function') {
                      // ưu tiên column có cell trước
                      return (
                        <td
                          className={_className}
                          key={
                            column.key
                              ? column.key
                              : column.text + '_' + colIndex
                          }
                          colSpan={column?.colSpan ?? 1}
                        >
                          {column.cell({
                            hideOddSymbol,
                            _totalShow,
                            _totalOddLot,
                          })}
                        </td>
                      );
                    } else if (totalShow[column.key]) {
                      // tiếp theo là column có key
                      return (
                        <td
                          className={_className}
                          key={
                            column.key
                              ? column.key
                              : column.text + '_' + colIndex
                          }
                          colSpan={column?.colSpan ?? 1}
                        >
                          {totalShow[column.key]}
                        </td>
                      );
                    } else {
                      // cuối cùng là ko có cả cell lẫn key
                      return (
                        <td
                          className={_className}
                          key={column.text + '_' + colIndex}
                          colSpan={column?.colSpan ?? 1}
                        >
                          {column.text}
                          {this._showSortIcon(column.key)}
                        </td>
                      );
                    }
                  })}
                </tr>
              )}
            </thead>
            <tbody>
              {contentShow &&
                contentShow.map((record, rowIndex) => {
                  if (record.symbol && record.symbol == 'TOTAL') return null;
                  return (
                    <tr
                      key={'record' + rowIndex}
                      onClick={() => {
                        if (onRowClicked) return onRowClicked(record);
                        return null;
                      }}
                    >
                      {columns.map((column, colIndex) => {
                        const _className =
                          typeof column.className === 'function'
                            ? column.className(record)
                            : column.className;

                        if (column.cell && typeof column.cell === 'function') {
                          if (column.key === 'defaultAccount') {
                            return (
                              <td
                                className={_className}
                                key={
                                  column.key
                                    ? column.key
                                    : column.text + '_' + colIndex
                                }
                              >
                                {column.cell(defaultAccount)}
                              </td>
                            );
                          }
                          return (
                            <td
                              className={_className}
                              key={
                                column.key
                                  ? column.key
                                  : column.text + '_' + colIndex
                              }
                            >
                              {column.cell(record, action, rowIndex)}
                            </td>
                          );
                        } else if (record[column.key]) {
                          return (
                            <td
                              className={_className}
                              key={
                                column.key
                                  ? column.key
                                  : column.text + '_' + colIndex
                              }
                            >
                              {record[column.key]}
                            </td>
                          );
                        } else {
                          if (column.key === '_index') {
                            return (
                              <td
                                className={_className}
                                key={
                                  column.key
                                    ? column.key
                                    : column.text + '_' + colIndex
                                }
                              >
                                {(page - 1) * _pageSize + rowIndex + 1}
                              </td>
                            );
                          }
                          return (
                            <td
                              className={_className}
                              key={
                                column.key
                                  ? column.key
                                  : column.text + '_' + colIndex
                              }
                            ></td>
                          );
                        }
                      })}
                    </tr>
                  );
                })}
            </tbody>

            {sum && (
              <tfoot>
                <tr>
                  <td>{t('txt-label-total')}</td>
                  <td colSpan="6"></td>
                  <td className="text-right">
                    {numberFormat(sum.vol, 0, '0')}
                  </td>
                  <td className="text-right">
                    {numberFormat(sum.matchVol, 0, '0')}
                  </td>
                  <td className="text-right">
                    {numberFormat(sum.sumDivdselqty, 0, '0')}
                  </td>
                  <td></td>
                </tr>
              </tfoot>
            )}
          </table>
          {(!source || !source) && (
            <p className="text-center fz_14 mt-2 title-time">
              {t('txt-no-data-found')}
            </p>
          )}
          {!!hasPaging && !!source && (
            <PagingCustom
              t
              handleNextPage={this._handleNextPage}
              handlePrevPage={this._handlePrevPage}
              page={page}
              totalPage={totalPage}
              continued={continued}
              pageSizeOption={pageSizeOption}
              _handleSetPageSize={this._handleSetPageSize}
              pageSizeOptions={pageSizeOptions}
            />
          )}
        </div>
      </>
    );
  }
}

export default translate('translations')(PaginationTable);
