import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { withNamespaces } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ScrollTable from '../../../../components/ScrollTable';
import { makeGetBondToolTip } from '../../../../lib/selector';
import { numberFormat, _formatDate2 } from '../../../../util';
import {
  getBondDetail,
  getBondProducts,
  setBondDetailModalRequest,
  setBondProductDetail,
  setProInvRequire,
  setRegistBondTradingRetailModal,
} from '../../../bond/action';
import { setAuth, setLogin, setToast } from '../../../client/actions';
import PerfectScrollBar from 'react-perfect-scrollbar';
import { useHistory } from 'react-router';
import { setPFBondDemo, setPFBondOrder, setPFBondPopup } from '../../action';
import ProInvestorAlert from '../../../bond/components/Order/proInvestorAlert';
import { bondConstants, constants } from '../../../../util/constant';
import { Mixpanel } from '../../../../lib/mixpanel';
import { requiredProInvestor } from '../../../bond/utils';

const BondList = withNamespaces('translations')(({ t }) => {
  const { bondProducts } = useSelector((state) => state.bond);
  const { planData, openPFBondListPopup } = useSelector(
    (state) => state.pinefolio
  );
  const {
    token,
    auth,
    setting: { lang },
    setting,
    currentTheme: theme,
  } = useSelector((state) => state.client);
  const bondToolTip = useSelector(makeGetBondToolTip());

  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleCloseModal = () => {
    // setShow(false);
    dispatch(setPFBondPopup(false));
  };

  const getBondProductHandle = (lang) => {
    dispatch(getBondProducts('', '', '', '', '', lang, '1'));
  };

  useEffect(() => {
    setShow(openPFBondListPopup);
    if (openPFBondListPopup) {
      getBondProductHandle(lang || 'vi');
    }
  }, [openPFBondListPopup]);

  const demoHandle = (product) => {
    if (product) {
      dispatch(setPFBondDemo(product.bondCode));
      dispatch(setBondProductDetail(product));
      Mixpanel.bond.demo(product.bondCode);
    }
    // history.push(`/home/bond/${product.bondCode}`);
    // todo: call api get bond detail by id and then open demo modal by id
  };

  const buyHandle = (product) => {
    if (token) {
      if (auth && auth.isVerified) {
        const isRegistedTradingRetailStatus = setting?.pcbMapYN;
        const proIns = requiredProInvestor(token, product);

        if (
          product?.listTp == '4' &&
          (!isRegistedTradingRetailStatus ||
            isRegistedTradingRetailStatus == 'N')
        ) {
          dispatch(setRegistBondTradingRetailModal(true));
          return;
        }

        if (proIns) {
          dispatch(setProInvRequire(true));
          return;
        }

        if (product?.listTp == '4' && isRegistedTradingRetailStatus == 'P') {
          const toastMsg = {
            id: Math.random(),
            msg: t('bond.retailTrading.pendingNoti'),
            title: t('txt-notice'),
          };

          dispatch(setToast(toastMsg));
          return;
        }

        dispatch(setBondDetailModalRequest(bondConstants.modals.BUY_ORDER));
        dispatch(getBondDetail(product.bondCode, lang));
        dispatch(setBondProductDetail(product));

        const pfBondOrder = {
          bondCode: product.bondCode,
          productCode: product.productCode,
          planId: planData.id,
          dayIntendedToSell: planData.enDt,
        };

        dispatch(setPFBondOrder(pfBondOrder));
        Mixpanel.bond.startOrder(
          product.bondCode,
          'Buy',
          constants.bondProductType.Var,
          'Pinefolio'
        );
      } else {
        dispatch(setAuth());
      }
    } else {
      dispatch(setLogin());
    }
  };

  const colgroup = (
      <colgroup>
        <col width="12%"></col>
        <col width="16%"></col>
        <col width="100px"></col>
        <col width="100px"></col>
        <col width="80px"></col>
        <col></col>
        <col></col>
        <col></col>
        <col></col>
        <col width="135px"></col>
      </colgroup>
    ),
    columns = [
      {
        key: 'productCode',
        text: t('bond.tableLabel.productCode'),
        className: 'text-left position-relative text--light fw-500',
        sortable: true,
        cell: (record) => {
          return (
            <span className="d-flex align-items-center">
              {record.productCode}{' '}
              {record.proInvtYN == 'Y' && (
                <span className="icon iStar icon--xs ml-1"></span>
              )}
            </span>
          );
        },
      },
      {
        key: ['bondName', 'bondCode'],
        text: [t('bond.tableLabel.bondName'), t('bond.tableLabel.bondCode')],
        className: 'text-left position-relative fw-500 text--light',
        sortable: true,
      },
      {
        key: 'listTpNm',
        text: t('bond.tableLabel.bondType'),
        className: 'text-center position-relative text--light3 fw-500',
        sortable: true,
        // cell: (record) => {},
      },
      {
        key: 'dueDate',
        text: t('bond.tableLabel.maturityDate'),
        className: 'text-center position-relative text--light fw-500',
        sortable: true,
        cell: (record) => {
          return _formatDate2(record.dueDate);
        },
      },
      {
        key: 'intRate',
        text: (
          <div className="d-flex">{t('bond.tableLabel.yieldToMaturity')}</div>
        ),
        cell: (record) => {
          return `${record.intRate}%/${t('time.long.y')}`;
        },
        className: 'text-center position-relative r fw-500',
        sortable: true,
      },
      {
        key: 'leg1Prc',
        text: t('bond.tableLabel.buyPrice'),
        cell: (record) => {
          return numberFormat(record.leg1Prc, 0);
        },
        className: 'text-right position-relative i fw-500',
        sortable: true,
      },
      {
        key: 'leg2Prc',
        text: t('bond.tableLabel.sellPrice'),
        cell: (record) => {
          return numberFormat(record.leg2Prc, 0);
        },
        className: 'text-right position-relative d',
        sortable: true,
      },
      {
        key: 'minInvtQtyPerOrdr',
        text: t('bond.tableLabel.minQuantityOrder'),
        cell: (record) => {
          return numberFormat(record.minInvtQtyPerOrdr, 0);
        },
        className: 'text-right position-relative text--light3',
        sortable: true,
      },
      {
        key: 'selRemain',
        text: t('bond.tableLabel.availQuantity'),
        className: 'text-right position-relative text--light',
        sortable: true,
        cell: (record) => {
          return numberFormat(record?.selRemain, 0, '0');
        },
      },
      {
        text: t('bond.tableLabel.actions'),
        className: 'text-center',
        cell: (record) => {
          return (
            <span className="d-flex justify-content-center align-items-center gap-8">
              <span
                className="btn btn--demo2"
                onClick={() => demoHandle(record)}
              >
                <span>{t('bond.bondDemo.name')}</span>
              </span>
              {!token || auth?.isVerified ? (
                <span
                  className="btn btn--buy2"
                  onClick={() => buyHandle(record)}
                >
                  <span>{t('txt-buy')}</span>
                </span>
              ) : (
                <button
                  className="btn btn--authen"
                  onClick={() => dispatch(setAuth())}
                >
                  {t('txt-buy')}
                </button>
              )}
            </span>
          );
        },
        sortable: false,
      },
    ];
  return (
    <>
      <Modal
        show={show}
        dialogClassName="wts-modal2 justify-content-center"
        backdropClassName="wts-modal-backdrop pinefolio-bond-modal-backdrop"
        contentClassName={`${
          theme ? theme.name : 'dark'
        } pinefolio-bond-modal wts-modal-content wts-modal-content--xl`}
        onEscapeKeyDown={() => handleCloseModal()}
        centered
        onHide={() => handleCloseModal()}
        style={{ zIndex: 11115 }}
      >
        <div className="pinefolio-bond-list">
          <div className="pinefolio-bond-list__header wts-modal2__header">
            <button
              className="btn-icon btn--transparent"
              onClick={() => handleCloseModal()}
            >
              <span className="icon iBack"></span>
            </button>
            <span className="text--light text--md">
              {`${t('txt-buy')} ${t('bond.name').toLowerCase()}`}
            </span>
            <span className="btn-icon btn--transparent"></span>
          </div>
          <div className="pinefolio-bond-list__body wts-modal2__body p-0">
            <PerfectScrollBar style={{ height: '80vh' }}>
              <div className="show-data">
                {bondProducts && (
                  <ScrollTable
                    tblClassName="pinefolio-bond-table"
                    colgroup={colgroup}
                    columns={columns}
                    source={bondProducts}
                    tooltip={bondToolTip}
                  />
                )}
              </div>
            </PerfectScrollBar>
            <div className="investor-note">
              <span className="icon icon--sm iStar"></span>
              <span className="text--light3 text--sm">
                {t('bond.productForProInvestor')}
              </span>
            </div>
          </div>
        </div>
      </Modal>
      <ProInvestorAlert />
    </>
  );
});

export default BondList;
