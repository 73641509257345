import React, { useState, useEffect, useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PDFViewer from './pdfViewer';
import { withNamespaces } from 'react-i18next';
import { StringToInt } from '../../../../util';
import {
  buyBondOrder,
  getBondCommonRequest,
  sellBondOrder,
} from '../../action';
import { ReactReduxContext, useDispatch, useSelector } from 'react-redux';
import { setToast, unsetClient } from '../../../client/actions';
import { getMessage } from '../../../../lib/selector';
import { removeCookie } from '../../../../lib/storages';
import {
  Logout,
  logoutRequest,
} from '../../../../components/modal/login/actions';
import { handleApiErrors } from '../../../../lib/api-errors';
import { saveAs } from 'file-saver';
import CheckboxButton from '../../../../components/checkbox/checkBoxButton';
import AuthenStep from '../../../../components/common/auth';
import { LOCK_RESENT_OTP } from '../../../../components/modal/auth/constants';
import { lockResendOtp } from '../../../../components/modal/auth/actions';
import { constants } from '../../../../util/constant';

const appUrl = `${process.env.REACT_APP_API_URL}`;
const url = `${appUrl}/BondServlet.pt`;

const Index = withNamespaces('translations')(({ t, closeHandle }) => {
  const dispatch = useDispatch();
  const { token, setting } = useSelector((state) => state.client);
  const { bondOrder } = useSelector((state) => state.bond);
  const { pfBondOrder } = useSelector((state) => state.pinefolio);
  const [error, setError] = useState();
  const [check, setCheck] = useState();
  const [oneTimeToken, setOneTimeToken] = useState('');
  const [contracts, setContracts] = useState(null);
  const [choosenContract, setChoosenContract] = useState(null);
  const [viewed, setView] = useState([]);
  const { store } = useContext(ReactReduxContext);

  const orderSellHandle = () => {
    const uuid = uuidv4();
    if (bondOrder) {
      const { account, pdCode, quantity, dealId, side, pfPlanId } = bondOrder;
      const params = {
        cmd: side == '1' ? 'placeSellOdBondOTC' : 'placeBuyOdBondOTC',
        type: '6',
        token: oneTimeToken ?? '',
        user: token?.user,
        session: token?.session,
        group: 'CORE',
        channel: 'WTS',
        rqId: uuid,

        data: {
          cif: token.cif,
          acntNo: account.acntNo,
          subAcntNo: account.subAcntNo,
          bdProdCode: pdCode,
          dlQty: quantity,
          dealId: dealId,
          planId: pfPlanId,
        },
      };

      let callback = null;
      if (pfBondOrder && pfBondOrder.callback) {
        callback = pfBondOrder.callback;
      }

      dispatch(sellBondOrder(params, callback));
    }
  };

  const orderBuyHandle = () => {
    const uuid = uuidv4();
    if (bondOrder) {
      const { account, pdCode, quantity, pfPlanId } = bondOrder;
      const params = {
        cmd: 'placeBuyOdBondOTC',
        type: '6',
        token: oneTimeToken ?? '',
        user: token?.user,
        session: token?.session,
        group: 'CORE',
        channel: 'WTS',
        rqId: uuid,
        data: {
          cif: token.cif,
          acntNo: account.acntNo,
          subAcntNo: account.subAcntNo,
          bdProdCode: pdCode,
          dlQty: quantity,
          planId: pfPlanId,
        },
      };
      dispatch(buyBondOrder(params));
    }
  };

  const getContract = () => {
    const { account, pdCode, quantity, dealId, side } = bondOrder;
    if (account) {
      const uuid = uuidv4();
      const params = {
        group: 'CORE',
        cmd: 'getBondOrdContractFile',
        rqId: uuid,
        channel: 'WTS',
        user: token?.user,
        session: token?.session,
        data: {
          cif: token?.cif,
          acntNo: account?.acntNo,
          subAcntNo: account?.subAcntNo,
          prdCode: pdCode,
          quantity: StringToInt(quantity) + '',
          side: side,
          dealId: dealId,
          langTp: setting?.lang || 'vi',
        },
      };

      const callback = (data) => {
        console.log('data', data);
        if (!!data?.length > 0) {
          setContracts(data);
          setChoosenContract(data[0]);
          addViewHandle(0);
        }
      };

      dispatch(getBondCommonRequest(params, callback, true));
    }
  };

  const addViewHandle = (index) => {
    const _viewed = [...viewed];
    if (!_viewed.includes(index)) {
      _viewed.push(index);
      setView(_viewed);
    }
  };

  const downloadHandle = (url, fileName) => {
    saveAs(url, fileName + '.pdf');
  };

  const disableHandle = () => {
    if (!oneTimeToken || error || !check) return true;
    else return false;
  };

  useEffect(() => {
    getContract();
  }, []);

  return (
    <div className="bond-contract">
      <div className="bond-contract-infor">
        <PerfectScrollbar style={{ height: '100%' }}>
          <p className="text-bigger text-center">{t('bond.contract.sign')}</p>
          <p className="text--light3">{t('bond.contract.desc')}</p>
          <div className="bond-contract-steps">
            <div className="bond-contract-rec">
              <span className="text-big text--light fw-500">
                {t('bond.contract.view')}
              </span>
            </div>
            {contracts &&
              contracts.length > 0 &&
              contracts.map((item, i) => (
                <div
                  className="bond-contract-step"
                  onClick={() => {
                    addViewHandle(i);
                    setChoosenContract(item);
                  }}
                >
                  <span
                    className={
                      choosenContract && choosenContract.cntrNo == item.cntrNo
                        ? 'r'
                        : ''
                    }
                  >
                    {item.cntrName}
                  </span>
                  <div className="bond-contract-step__actions">
                    <span
                      className={viewed.includes(i) ? 'icon iCheck' : ''}
                    ></span>
                    <span
                      className={item.cntrUrl ? 'icon iDownload' : ''}
                      onClick={() =>
                        downloadHandle(
                          item.cntrUrl,
                          `Bond_${item.cntrCode}_${item.cntrNo}`
                        )
                      }
                    ></span>
                  </div>
                </div>
              ))}
          </div>

          <div className="confirm-term d-flex flex-column">
            <CheckboxButton
              onClick={(e) => setCheck(e)}
              description={t('bond.contract.confirm')}
            />
            {bondOrder.productTp != constants.bondProductType.Fix &&
              bondOrder.side == 2 && (
                <span className="d-flex">
                  <span className="r ml-3 pl-3 fz_12 fw-100 text-justify font-italic">{`${t(
                    'txt-note'
                  )}: ${t('bond.contract.note')}`}</span>
                </span>
              )}
          </div>

          {check && (
            <div className="2fa-authentication">
              <AuthenStep setOneTimeToken={setOneTimeToken} trackMixpanel />
            </div>
          )}

          <div className="order-actions">
            <button
              className={`btn ${
                bondOrder.side == 2 ? 'btn--buy' : 'btn--sell'
              } ${disableHandle() ? 'disabled' : ''}`}
              onClick={
                bondOrder.side == 2
                  ? () => orderBuyHandle()
                  : () => orderSellHandle()
              }
              disabled={disableHandle()}
            >
              {t('txt-order')}
            </button>

            <button className="btn btn--cancel" onClick={() => closeHandle()}>
              {t('txt-cancel')}
            </button>
          </div>
        </PerfectScrollbar>
      </div>
      <div className="bond-contract-preview">
        <div className="bond-contract-preview__title">
          <span className="text-big text--light fw-500">
            {choosenContract?.cntrName || t('bond.contract.name')}
          </span>
        </div>
        <div className="bond-contract-preview__body">
          {choosenContract && <PDFViewer src={choosenContract.cntrUrl} />}
        </div>
      </div>
    </div>
  );
});

export default Index;
