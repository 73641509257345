import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { dragDiv } from '../../../util';
import { setCurrentPinefolio } from '../action';
import { withNamespaces } from 'react-i18next';

const PinefolioLevels = withNamespaces('translations')(
  ({ t, onClickCallback, sources, showPFName = false }) => {
    const dispatch = useDispatch();
    const { currentPinefolio } = useSelector((state) => state.pinefolio);

    const setCurrent = (pinefolio) => {
      dispatch(setCurrentPinefolio(pinefolio));
    };

    useEffect(() => {
      dragDiv('.pinefolio-levels');
    }, []);

    if (!sources || sources.length <= 1) return <></>;

    return (
      <>
        <div className="pinefolio-levels">
          {sources.map((source, i) => (
            <div
              key={i}
              className={`btn btn--light br-5 pinefolio-level ${
                currentPinefolio?.pfCode == source.pfCode ? 'active' : ''
              }`}
              onClick={() => {
                if (onClickCallback && typeof onClickCallback === 'function') {
                  onClickCallback(source);
                }
                setCurrent(source);
              }}
            >
              {`${showPFName ? source.pfNm : source.riskLvlNm}`}
            </div>
          ))}
        </div>
      </>
    );
  }
);

export default PinefolioLevels;
