import React, { useEffect, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ReactSelect from 'react-select';
import { find, sortBy } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import {
  _convertTpToPrice,
  _getOrderStatus,
  numberFormat,
  _convertActTypeToStr,
  _getOrdCondStatus,
  _getOrdCondStr,
  formatDate,
  _convertformatDate2,
  ordStatus,
  canEditOrder,
  canDeleteOrder,
  formatToNewDate,
} from '../../../util';
import ScrollSKLDK from '../../../components/trading/components/ScrollSKLDK';
import { setAuth, setOrdCond } from '../../client/actions';
import {
  cancelCondRequest,
  detailCondRequest,
  listCondRequest,
} from '../../../components/modal/ordCond/actions';
import FormDeleteOrdCond from '../../../components/modal/order/deleteOrdCond';
import DatePicker from '../../../components/datePicker2';
import moment from 'moment';
import PerfectScrollBar from 'react-perfect-scrollbar';

const ConditionalOrderHistory = withNamespaces('translations')(({ t }) => {
  const listOrder = useSelector((state) => state.ordCond.listCond);
  const { token, auth } = useSelector((state) => state.client);
  const { listAccount, defaultAccount } = useSelector((state) => state.socket);
  const condOrderSuccessfull = useSelector((state) => state.ordCond.successful);

  const dispatch = useDispatch();
  const [listOrderToShow, setListOrderToShow] = useState([]);
  const [symbolSearch, setSymbolSearch] = useState();
  const [orderType, setOrderType] = useState();
  const [orderStatus, setOrderStatus] = useState();
  const [account, setAccount] = useState();
  const [showModalDel, setShowModalDel] = useState();
  const [order, setOrder] = useState();
  const currentDate = new Date();
  const [fromDate, setFromDate] = useState(
    new Date(currentDate.setDate(currentDate.getDate() - 90))
  );
  const [toDate, setToDate] = useState(new Date());
  const [reload, setReload] = useState(true);

  const accounts = listAccount?.filter((x) => x.subAcntStat != 9) || [];

  const _handleDeleteOrder = (orderNo) => {
    const _order = find(listOrder, (o) => o.ordrNo === orderNo);
    if (_order) {
      setShowModalDel(true);
      setOrder(_order);
    }
  };

  const _handleAuthen = () => {
    dispatch(setAuth());
  };

  const _handleSubmitDeleteOrder = (order) => {
    const uuid = uuidv4();

    const params = {
      group: 'ORS',
      user: token.user,
      session: token.session,
      cmd: 'cancelOrsOrder',
      type: auth.type,
      token: auth.token,
      rqId: uuid,
      channel: 'WTS',
      data: {
        acctno: account.acntNo,
        subAcntNo: account.subAcntNo,
        orderid: order.ordrNo + '',
        msgtype: 'CNL',
        symbol: order.symbol,
      },
    };

    dispatch(cancelCondRequest(params));
    setShowModalDel(false);
    setOrder(null);
    setReload(!reload);
  };

  const _handleCloseModalDel = () => {
    setShowModalDel(false);
    setOrder(null);
  };

  const _handleLoadListOrder = () => {
    let symbol = null,
      _expireddt = new Date(),
      _createddt = new Date();

    _expireddt.setDate(_expireddt.getDate() + 7);
    _createddt.setDate(_createddt.getDate() - 30);

    const expireddt =
      formatToNewDate(_convertformatDate2(toDate)) || _expireddt;
    const createddt =
      formatToNewDate(_convertformatDate2(fromDate)) || _createddt;

    if (symbolSearch) symbol = symbolSearch.toUpperCase();

    if (account) {
      const uuid = uuidv4();

      const params = {
        group: 'CORE',
        cmd: 'getCondOrderList',
        user: token.user,
        session: token.session,
        rqId: uuid,
        channel: 'WTS',
        data: {
          acntNo: account.acntNo,
          subAcntNo: account.subAcntNo,
          orderType: orderType ? orderType.value : null,
          createdDt: formatDate(createddt, '-'),
          expiredDt: formatDate(expireddt, '-'),
          status: orderStatus ? orderStatus.value : null,
          symbol,
        },
      };

      dispatch(listCondRequest(params));
    }
  };

  useEffect(() => {
    if (defaultAccount) setAccount(defaultAccount);
  }, [defaultAccount]);

  useEffect(() => {
    if (listOrder && listOrder.length) {
      setListOrderToShow(sortBy(listOrder, ['createddt', 'ordrNo']));
    } else {
      setListOrderToShow([]);
    }
  }, [listOrder]);

  useEffect(() => {
    if (account) _handleLoadListOrder(symbolSearch);
  }, [
    orderType,
    orderStatus,
    symbolSearch,
    account,
    fromDate,
    toDate,
    condOrderSuccessfull,
    reload,
  ]);

  const columns = [
    {
      key: 'ordrNo',
      text: t('txt-order-no'),
      className: 'text-center text--light3',
      align: 'center',
    },
    {
      key: 'orderType',
      text: t('txt-order-type'),
      className: 'text-center text-light fw-500',
      align: 'center',
      cell: (record) => {
        return _getOrdCondStr(record.orderType);
      },
    },
    {
      key: 'createdDt',
      text: t('txt-label-from'),
      className: 'text-center text--light3',
      align: 'center',
      cell: (record) => {
        return (
          record.createdDt && moment(record.createdDt).format('DD/MM/yyyy')
        );
      },
    },
    {
      key: 'expiredDt',
      text: t('txt-label-to'),
      className: 'text-center text--light3',
      align: 'center',
      cell: (record) => {
        return (
          record.expiredDt && moment(record.expiredDt).format('DD/MM/yyyy')
        );
      },
    },
    {
      key: 'activeType',
      text: t('trading.method'),
      className: 'text-left text--light3',
      align: 'center',
      cell: (record) => {
        return _convertActTypeToStr(record.activeType);
      },
    },
    {
      key: 'side',
      text: t('txt-trade-acronym'),
      className: 'text-center',
      align: 'center',
      cell: (record) => {
        return (
          <div className={record.side === 'B' ? 'i' : 'd'}>
            {record.side === 'B' ? t('txt-buy') : t('txt-sell')}
          </div>
        );
      },
    },
    {
      key: 'symbol',
      text: t('txt-symbol'),
      className: 'text-center text-light fw-500',
      align: 'center',
    },

    {
      key: 'price',
      text: t('txt-price'),
      className: 'text-center text-light fw-500',
      align: 'center',
      cell: (record) => {
        return numberFormat(record.price, 0, '');
      },
    },

    {
      key: ['activeCond', 'orderCond'],
      text: [t('trading.activeCondition'), t('trading.orderCondition')],
      className: 'text--light',
      sortable: false,
    },
    {
      key: 'qty',
      text: t('txt-vol'),
      className: 'text-right',
      align: 'center',
      cell: (record) => {
        return numberFormat(record.qty, 0, '0');
      },
    },
    {
      key: 'execQty',
      text: t('txt-matched-order'),
      className: 'text-right',
      align: 'center',
      cell: (record) => {
        return numberFormat(record.execQty, 0, '0');
      },
    },
    {
      key: 'status',
      text: t('txt-status'),
      className: (record) => {
        return 'text-center ordStt_' + record.status;
      },
      align: 'center',
      cell: (record) => {
        return _getOrdCondStatus(record.status);
      },
    },
    {
      key: 'action',
      text: t('txt-actions'),
      cell: (record) => {
        return auth && auth.isVerified ? (
          !(record.status == 'E' || record.status == 'I') ? (
            <div className="d-flex align-items-center justify-content-center">
              <span
                className="d-flex align-items-center justify-content-center btn btn--delete ml-1"
                onClick={() => _handleDeleteOrder(record.ordrNo)}
              >
                <span className="icon iCancel text-muted"></span>
              </span>
            </div>
          ) : (
            <div className="d-flex align-items-center justify-content-center">
              <span className="d-flex align-items-center justify-content-center btn btn--delete disabled ml-1">
                <span className="icon iCancel text-muted"></span>
              </span>
            </div>
          )
        ) : !(record.status == 'E' || record.status == 'I') ? (
          <div className="d-flex align-items-center justify-content-center">
            <span
              className="d-flex align-items-center justify-content-center btn btn--authen ml-1"
              onClick={() => _handleAuthen()}
            >
              <span className="icon iCancel text-muted"></span>
            </span>
          </div>
        ) : (
          <div className="d-flex align-items-center justify-content-center">
            <span className="d-flex align-items-center justify-content-center btn btn--delete disabled ml-1">
              <span className="icon iCancel text-muted"></span>
            </span>
          </div>
        );
      },
    },
  ];
  return (
    <div className="card-panel-body position-relative h-100">
      <Filter
        t={t}
        listAccount={accounts}
        orderType={orderType}
        setOrderType={setOrderType}
        orderStatus={orderStatus}
        setOrderStatus={setOrderStatus}
        symbol={symbolSearch}
        setSymbol={setSymbolSearch}
        account={account}
        setAccount={setAccount}
        fromDate={fromDate}
        setFromDate={setFromDate}
        toDate={toDate}
        setToDate={setToDate}
      />
      <PerfectScrollBar style={{ height: 'calc(100% - 24px)' }}>
        <ScrollSKLDK
          tblClassName="tbl-list"
          divClassName="mt-0"
          columns={columns}
          source={listOrderToShow}
        />
        <div style={{ height: '50px' }}></div>
      </PerfectScrollBar>
      {showModalDel && (
        <FormDeleteOrdCond
          order={order}
          showModal={showModalDel}
          handleCloseModal={_handleCloseModalDel}
          handleSubmitOrder={_handleSubmitDeleteOrder}
        />
      )}
    </div>
  );
});

export default ConditionalOrderHistory;

const Filter = ({
  t,
  reload,
  orderType,
  setOrderType,
  orderStatus,
  setOrderStatus,
  symbol,
  setSymbol,
  account,
  setAccount,
  listAccount,
  fromDate,
  setFromDate,
  toDate,
  setToDate,
}) => {
  const ORDER_TYPES = [
    {
      value: '',
      label: 'transaction.conditionalOrderStatement.allOrderType',
    },
    { value: 'GTC', label: 'txt-out-time' },
    { value: 'TSO', label: 'txt-trend-order' },
    { value: 'TPO', label: 'txt-take-profit' },
    { value: 'STO', label: 'txt-stop-loss' },
    { value: 'PCO', label: 'txt-purchase-order' },
  ];

  const ORDER_STATUSES = [
    {
      value: '',
      label: 'transaction.conditionalOrderStatement.allStatus',
    },
    { value: 'A', label: 'txt-cond-activated' },
    { value: 'I', label: 'txt-cond-active' },
    { value: 'C', label: 'txt-cond-cancelling' },
    { value: 'E', label: 'txt-cond-expired' },
    { value: 'F', label: 'txt-cond-final' },
    { value: 'P', label: 'txt-cond-pending' },
    { value: 'R', label: 'txt-cond-wait-react' },
  ];

  useEffect(() => {
    setSymbol('');
    setOrderType('');
    setOrderStatus('');
  }, [reload]);

  return (
    <div className="filter">
      <div className="filter-control d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center gap-8">
          <ReactSelect
            placeholder={t('txt-all')}
            className="mw-150 fz_14"
            classNamePrefix="filter-control-select"
            options={listAccount}
            isSearchable={false}
            components={{
              IndicatorSeparator: () => null,
            }}
            value={account}
            onChange={(value) => setAccount(value)}
            getOptionLabel={(option) => t(option.value)}
            getOptionValue={(option) => option.value}
          />
        </div>
        <div className="d-flex align-items-center justify-content-end gap-8">
          <div className="input-text-search d-flex align-items-center fz_13">
            <input
              name="txtSearch"
              maxLength={8}
              type="text"
              className="form-control form-input fz_13"
              placeholder={t('txt-symbol')}
              value={symbol}
              onChange={(e) =>
                e.target.value.length > 0
                  ? setSymbol(e.target.value.toUpperCase())
                  : setSymbol(e.target.value)
              }
            />
            <span className="icon iSearch"></span>
          </div>
          <DatePicker
            startDate={fromDate}
            endDate={toDate}
            callback={(from, to) => {
              console.log(`from: ${fromDate} || to: ${toDate}`);
              setFromDate(from);
              setToDate(to);
            }}
          />
          <ReactSelect
            name="orderType"
            className="mw-150 fz_13"
            placeholder={t(
              'transaction.conditionalOrderStatement.allOrderType'
            )}
            classNamePrefix="filter-control-select"
            options={ORDER_TYPES}
            isSearchable={false}
            components={{
              IndicatorSeparator: () => null,
            }}
            value={orderType}
            onChange={(value) => setOrderType(value)}
            getOptionLabel={(option) => t(option.label)}
            getOptionValue={(option) => option.id}
          />
          <ReactSelect
            name="orderStatus"
            className="mw-150 fz_13"
            placeholder={t('trading.allStatus')}
            classNamePrefix="filter-control-select"
            options={ORDER_STATUSES}
            isSearchable={false}
            components={{
              IndicatorSeparator: () => null,
            }}
            value={orderStatus}
            onChange={(value) => setOrderStatus(value)}
            getOptionLabel={(option) => t(option.label)}
            getOptionValue={(option) => option.id}
          />
        </div>
      </div>
    </div>
  );
};
