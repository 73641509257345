import {
  GET_LIST_POSITION_ALL,
  GET_LIST_POSITION_ALL_SUCCESS,
  GET_LIST_POSITION_ALL_ERROR,
  GET_BOND_PORTFOLIO,
  GET_BOND_PORTFOLIO_SUCCESS,
  GET_BOND_PORTFOLIO_ERROR,
  GET_INQUIRY_RIGHTLIST_ALL,
  GET_INQUIRY_RIGHTLIST_ALL_SUCCESS,
  GET_INQUIRY_RIGHTLIST_ALL_ERROR,
} from './constants';

const initialState = {
  listPositionAll: [],
  listPositionAllRequesting: false,
  listPositionAllErrors: [],

  bondPortfolio: [],
  bondPortfolioRequesting: false,
  bondPortfolioErrors: [],

  inquiryRightList: [],
  inquiryRightListRequesting: false,
  inquiryRightListErrors: [],
};

const reducer = function personalReducer(state = initialState, action) {
  switch (action.type) {
    case GET_LIST_POSITION_ALL: {
      return {
        ...state,
        listPositionAll: [],
        listPositionAllRequesting: true,
        listPositionAllErrors: [],
      };
    }

    case GET_LIST_POSITION_ALL_SUCCESS: {
      return {
        ...state,
        listPositionAll: action.listPositionAll,
        listPositionAllRequesting: false,
        listPositionAllErrors: [],
      };
    }

    case GET_LIST_POSITION_ALL_ERROR: {
      return {
        ...state,
        listPositionAll: [],
        listPositionAllRequesting: false,
        listPositionAllErrors: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };
    }
    //
    case GET_BOND_PORTFOLIO: {
      return {
        ...state,
        bondPortfolio: [],
        bondPortfolioRequesting: true,
        bondPortfolioErrors: [],
      };
    }

    case GET_BOND_PORTFOLIO_SUCCESS: {
      return {
        ...state,
        bondPortfolio: action.bondPortfolio,
        bondPortfolioRequesting: false,
        bondPortfolioErrors: [],
      };
    }

    case GET_BOND_PORTFOLIO_ERROR: {
      return {
        ...state,
        bondPortfolio: [],
        bondPortfolioRequesting: false,
        bondPortfolioErrors: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };
    }
    //
    case GET_INQUIRY_RIGHTLIST_ALL: {
      return {
        ...state,
        inquiryRightList: [],
        inquiryRightListRequesting: true,
        inquiryRightListErrors: [],
      };
    }

    case GET_INQUIRY_RIGHTLIST_ALL_SUCCESS: {
      return {
        ...state,
        inquiryRightList: action.inquiryRightList,
        inquiryRightListRequesting: false,
        inquiryRightListErrors: [],
      };
    }

    case GET_INQUIRY_RIGHTLIST_ALL_ERROR: {
      return {
        ...state,
        inquiryRightList: [],
        inquiryRightListRequesting: false,
        inquiryRightListErrors: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };
    }
    default:
      return state;
  }
};

export default reducer;
