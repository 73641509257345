import React, { useState, useEffect, useContext } from 'react';
import { forwardRef } from 'react';
import { Form } from 'react-bootstrap';
import { constants } from '../../../util/constant';

export const ContractToggle = forwardRef(({ children, onClick }, ref) => (
  <div
    className="symbol__infor"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    <>
      <div>{children}</div>
      <span className="icon iEdit"></span>
    </>
  </div>
));

export const ContractMenu = forwardRef(
  (
    {
      children,
      style,
      className,
      'aria-labelledby': labeledBy,
      onSelect,
      value: _contract,
      placeholder,
    },
    ref
  ) => {
    const [value, setValue] = useState('');

    const handleKeyDown = (e) => {
      if (e.key === 'Enter' && React.Children.toArray(children).length > 0) {
        onSelect(
          React.Children.toArray(children).filter(
            (child) => !value || child.props.children.includes(value)
          )[0].props.eventKey
        );
      }
    };

    useEffect(() => {
      if (_contract) {
        setValue('');
      }
    }, [_contract.id]);

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <div className="search-container">
          <Form.Control
            autoFocus
            className="contract-form"
            onChange={(e) => setValue(e.target.value)}
            value={value}
            onKeyDown={handleKeyDown}
            placeholder={placeholder}
          />
        </div>
        <ul className="list-unstyled">
          {React.Children.toArray(children).filter(
            (child) =>
              !value ||
              child.props.children.toLowerCase().includes(value.toLowerCase())
          )}
        </ul>
      </div>
    );
  }
);

/*
  this method help to join contracts that is array or single 
*/
export const joinSocketHandle = (ws, contracts) => {
  if (ws) {
    if (!contracts) return;
    if (!Array.isArray(contracts)) {
      contracts = [contracts];
    }

    const payload = {
      action: 'join',
      data: contracts.join(','),
      board: constants.G.DEFAULT, // Derivative use G1 as a default
    };

    ws.sendMessage(payload);
  } else {
    console.error('socket is not initialized');
  }
};

export const leaveSocketHandle = (ws, contracts) => {
  if (ws) {
    if (!contracts) return;
    if (!Array.isArray(contracts)) {
      contracts = [contracts];
    }

    const payload = {
      action: 'leave',
      data: contracts.join(','),
      board: constants.G.DEFAULT, // Derivative use G1 as a default
    };

    ws.sendMessage(payload);
  } else {
    console.error('socket is not initialized');
  }
};
