import {
  AUTH_ERROR,
  AUTH_LIST_ERROR,
  AUTH_LIST_REQUESTING,
  AUTH_LIST_SUCCESS,
  AUTH_REQUESTING,
  AUTH_SUCCESS,
  CHANGE_BANK_ERROR,
  CHANGE_BANK_REQUESTING,
  CHANGE_BANK_RESET,
  CHANGE_BANK_SUCCESS,
  CHANGE_PASS_ERROR,
  CHANGE_PASS_REQUESTING,
  CHANGE_PASS_RESET,
  CHANGE_PASS_SUCCESS,
  CHANGE_TELPIN_ERROR,
  CHANGE_TELPIN_REQUESTING,
  CHANGE_TELPIN_RESET,
  CHANGE_TELPIN_SUCCESS,
  CHANGE_USER_ERROR,
  CHANGE_USER_REQUESTING,
  CHANGE_USER_RESET,
  CHANGE_USER_SUCCESS,
  GENERATE_AUTH_ERROR,
  GENERATE_AUTH_REQUESTING,
  GENERATE_AUTH_SUCCESS,
  LOCK_RESENT_OTP,
  ONE_TIME_TOKEN_ERROR,
  ONE_TIME_TOKEN_REQUESTING,
  ONE_TIME_TOKEN_SUCCESS,
  UNLOCK_RESENT_OTP,
  LOCK_REFRESH_MATRIX,
  UNLOCK_REFRESH_MATRIX,
} from './constants';

const initialState = {
  requesting: false,
  successful: false,
  errors: [],

  isLockResendOtp: false,
  isLockRefreshMatrix: false,

  actList: [],
  generateAuth: {},

  changePassRequesting: false,
  changePassSuccessful: false,
  changePassMessages: [],
  changePassErrors: [],

  changeUserRequesting: false,
  changeUserSuccessful: false,
  changeUserMessages: [],
  changeUserErrors: [],

  changeBankRequesting: false,
  changeBankSuccessful: false,
  changeBankMessages: [],
  changeBankErrors: [],

  changeTelPINRequesting: false,
  changeTelPINSuccessful: false,
  changeTelPINMessages: [],
  changeTelPINErrors: [],

  oneTimeToken: null, // use for cashout and change user profile
  oneTimeTokenRequesting: false,
  oneTimeTokenSuccessful: false,
  oneTimeTokenError: [],
};

const reducer = function authReducer(state = initialState, action) {
  switch (action.type) {
    case LOCK_RESENT_OTP:
      return {
        ...state,
        isLockResendOtp: true,
      };
    case UNLOCK_RESENT_OTP:
      return {
        ...state,
        isLockResendOtp: false,
      };
    case LOCK_REFRESH_MATRIX:
      return {
        ...state,
        isLockRefreshMatrix: true,
      };
    case UNLOCK_REFRESH_MATRIX:
      return {
        ...state,
        isLockRefreshMatrix: false,
      };
    case 'INVALID_SESSION':
    case 'CLIENT_UNSET':
    case 'LOG_OUT':
      return initialState;
    case 'RESET_AUTH':
      return {
        ...state,
        errors: [],
      };

    case 'CLEAR_AUTH':
      return {
        ...state,
        requesting: false,
        successful: false,
        errors: [],
      };

    case AUTH_REQUESTING:
      return {
        ...state,
        requesting: true,
        successful: false,
        errors: [],
      };

    case AUTH_SUCCESS:
      return {
        ...state,
        errors: [],
        requesting: false,
        successful: true,
      };

    case AUTH_ERROR:
      return {
        ...state,
        errors: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
        requesting: false,
        successful: false,
      };

    case AUTH_LIST_REQUESTING:
    case AUTH_LIST_ERROR:
      return {
        ...state,
        actList: [],
      };

    case AUTH_LIST_SUCCESS:
      return {
        ...state,
        actList: action.resData,
      };

    case GENERATE_AUTH_REQUESTING:
    case GENERATE_AUTH_ERROR:
      return {
        ...state,
        generateAuth: {},
      };

    case GENERATE_AUTH_SUCCESS:
      return {
        ...state,
        generateAuth: action.resData,
      };

    case CHANGE_PASS_REQUESTING:
      return {
        ...state,
        changePassRequesting: true,
        changePassSuccessful: false,
        changePassMessages: [
          { body: 'Change password in...', time: new Date() },
        ],
        changePassErrors: [],
      };

    case CHANGE_PASS_SUCCESS:
      return {
        ...state,
        changePassRequesting: false,
        changePassSuccessful: true,
        changePassMessages: [],
        changePassErrors: [],
      };

    case CHANGE_PASS_ERROR:
      return {
        ...state,
        changePassRequesting: false,
        changePassSuccessful: false,
        changePassMessages: [],
        changePassErrors: [
          ...[
            {
              body: action.error?.toString(),
              time: new Date(),
            },
          ],
        ],
      };

    case CHANGE_PASS_RESET:
      return {
        ...state,
        changePassRequesting: false,
        changePassSuccessful: false,
        changePassMessages: [],
        changePassErrors: [],
      };

    case CHANGE_TELPIN_REQUESTING:
      return {
        ...state,
        changeTelPINRequesting: true,
        changeTelPINSuccessful: false,
        changeTelPINMessages: [
          { body: 'Change telpin in...', time: new Date() },
        ],
        changeTelPINErrors: [],
      };

    case CHANGE_TELPIN_SUCCESS:
      return {
        ...state,
        changeTelPINRequesting: false,
        changeTelPINSuccessful: true,
        changeTelPINMessages: [],
        changeTelPINErrors: [],
      };

    case CHANGE_TELPIN_ERROR:
      return {
        ...state,
        changeTelPINRequesting: false,
        changeTelPINSuccessful: false,
        changeTelPINMessages: [],
        changeTelPINErrors: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };

    case CHANGE_TELPIN_RESET:
      return {
        ...state,
        changeTelPINRequesting: true,
        changeTelPINSuccessful: false,
        changeTelPINMessages: [],
        changeTelPINErrors: [],
      };

    case CHANGE_USER_REQUESTING:
      return {
        ...state,
        changeUserRequesting: true,
        changeUserSuccessful: false,
        changeUserMessages: [
          { body: 'Change user login in...', time: new Date() },
        ],
        changeUserErrors: [],
      };

    case CHANGE_USER_SUCCESS:
      return {
        ...state,
        changeUserRequesting: false,
        changeUserSuccessful: true,
        changeUserMessages: [],
        changeUserErrors: [],
      };

    case CHANGE_USER_ERROR:
      return {
        ...state,
        changeUserRequesting: false,
        changeUserSuccessful: false,
        changeUserMessages: [],
        changeUserErrors: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };

    case CHANGE_USER_RESET:
      return {
        ...state,
        changeUserRequesting: false,
        changeUserSuccessful: false,
        changeUserMessages: [],
        changeUserErrors: [],
      };

    case CHANGE_BANK_REQUESTING:
      return {
        ...state,
        changeBankRequesting: true,
        changeBankSuccessful: false,
        changeBankMessages: [
          { body: 'Change user login in...', time: new Date() },
        ],
        changeBankErrors: [],
      };

    case CHANGE_BANK_SUCCESS:
      return {
        ...state,
        changeBankRequesting: false,
        changeBankSuccessful: true,
        changeBankMessages: [],
        changeBankErrors: [],
      };

    case CHANGE_BANK_ERROR:
      return {
        ...state,
        changeBankRequesting: false,
        changeBankSuccessful: false,
        changeBankMessages: [],
        changeBankErrors: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };

    case CHANGE_BANK_RESET:
      return {
        ...state,
        changeBankRequesting: false,
        changeBankSuccessful: false,
        changeBankMessages: [],
        changeBankErrors: [],
      };

    case ONE_TIME_TOKEN_REQUESTING:
      return {
        ...state,
        oneTimeToken: null,
        oneTimeTokenRequesting: true,
        oneTimeTokenError: [],
        oneTimeTokenSuccessful: false,
      };
    case ONE_TIME_TOKEN_SUCCESS:
      return {
        ...state,
        oneTimeToken: action.token,
        oneTimeTokenRequesting: false,
        oneTimeTokenError: [],
        oneTimeTokenSuccessful: true,
      };
    case ONE_TIME_TOKEN_ERROR:
      return {
        ...state,
        oneTimeToken: null,
        oneTimeTokenRequesting: false,
        oneTimeTokenSuccessful: false,
        oneTimeTokenError: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };

    default:
      return state;
  }
};

export default reducer;
