import React from 'react';
import { connect } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { filter } from 'lodash';
import { Col } from 'react-bootstrap';
import { makeGetPutThroughByGroup } from '../../../../lib/selector';
import { numberFormat } from '../../../../util';
import { translate } from 'react-i18next';

class TblThoaThuan extends React.Component {
  render() {
    const { typeIndex, ptList, t } = this.props;
    const heightTb = 155 + (typeIndex === 1 ? 130 : 70);
    const ptListBuy = filter(ptList, (o) => o.type === 'PTB');
    const ptListSell = filter(ptList, (o) => o.type === 'PTS');
    const ptListMatch = filter(ptList, (o) => o.type === 'PTM');

    return (
      <PerfectScrollbar
        className="d-flex flex-row mt-2"
        style={{ height: `calc(100vh - ${heightTb}px)` }}
      >
        <Col md="3">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th colSpan="5">{t('txt-bid')}</th>
              </tr>
              <tr>
                <th>{t('txt-stt')}</th>
                <th>{t('txt-symbol')}</th>
                <th>{t('txt-price')}</th>
                <th>{t('txt-vol')}</th>
                <th>{t('txt-time')}</th>
              </tr>
            </thead>
            <tbody>
              {ptListBuy &&
                ptListBuy.map((item, index) => (
                  <tr key={'buy_' + index}>
                    <td className="text-center">{index + 1}</td>
                    <td className={item.color}>{item.sym}</td>
                    <td className={'text-right ' + item.color}>
                      {numberFormat(item.price, 2)}
                    </td>
                    <td className="text-right">{numberFormat(item.volume)}</td>
                    <td className="text-center">{item.time}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </Col>

        <Col>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th colSpan="6">{t('txt-match')}</th>
              </tr>
              <tr>
                <th>{t('txt-stt')}</th>
                <th>{t('txt-symbol')}</th>
                <th>{t('txt-price')}</th>
                <th>{t('txt-vol')}</th>
                <th>{t('txt-giatri')}</th>
                <th>{t('txt-time')}</th>
              </tr>
            </thead>
            <tbody>
              {ptListMatch &&
                ptListMatch.map((item, index) => (
                  <tr key={'match_' + index}>
                    <td className="text-center">{index + 1}</td>
                    <td className={item.color}>{item.sym}</td>
                    <td className={'text-right fw-500 ' + item.color}>
                      {numberFormat(item.price, 2)}
                    </td>
                    <td className="text-right text--light">
                      {numberFormat(item.volume)}
                    </td>
                    <td className="text-right text--light">
                      {numberFormat(item.value)}
                    </td>
                    <td className="text-center text--light">{item.time}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </Col>

        <Col md="3">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th colSpan="5">{t('txt-ask')}</th>
              </tr>
              <tr>
                <th>{t('txt-stt')}</th>
                <th>{t('txt-symbol')}</th>
                <th>{t('txt-price')}</th>
                <th>{t('txt-vol')}</th>
                <th>{t('txt-time')}</th>
              </tr>
            </thead>
            <tbody>
              {ptListSell &&
                ptListSell.map((item, index) => (
                  <tr key={'sell_' + index}>
                    <td className="text-center">{index + 1}</td>
                    <td className={item.color}>{item.sym}</td>
                    <td className={'text-right ' + item.color}>
                      {numberFormat(item.price, 2)}
                    </td>
                    <td className="text-right">{numberFormat(item.volume)}</td>
                    <td className="text-center">{item.time}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </Col>
      </PerfectScrollbar>
    );
  }
}

const makeMapStateToProps = () => {
  const getPutThroughByGroup = makeGetPutThroughByGroup();

  const mapStateToProps = (state, props) => {
    return {
      ptList: getPutThroughByGroup(state, props),
    };
  };
  return mapStateToProps;
};

export default connect(makeMapStateToProps)(
  translate('translations')(TblThoaThuan)
);
