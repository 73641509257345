import { call, put, all, takeLatest, select } from 'redux-saga/effects';

import { handleApiErrors } from '../../../lib/api-errors';

import {
  RESET_PASS_REQUESTING,
  RESET_PASS_SUCCESS,
  RESET_PASS_ERROR,
} from './constants';

import { setAuthen } from '../../../containers/client/actions';
import { getMessage } from '../../../lib/selector';
import { removeCookie } from '../../../lib/storages';

const appUrl = `${process.env.REACT_APP_API_URL}`;

function handleRequest(request) {
  return request
    .then(handleApiErrors)
    .then((response) => response.json())
    .then((json) => json)
    .catch((error) => {
      throw error;
    });
}

function resetPassApi(data) {
  const url = `${appUrl}/ResetPassword.pt`;
  const request = fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
    body: JSON.stringify(data),
  });
  return handleRequest(request);
}

function* resetPassRequestFlow({ params }) {
  try {
    const resData = yield call(resetPassApi, params);

    console.log(resData);
    if (resData.http !== 200) {
      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');

        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }
      const msgErr = yield select(getMessage, resData);
      throw Error(msgErr);
    }

    yield put({ type: RESET_PASS_SUCCESS });
  } catch (error) {
    yield put({ type: RESET_PASS_ERROR, error });
  }
}

function* authWatcher() {
  yield all([takeLatest(RESET_PASS_REQUESTING, resetPassRequestFlow)]);
}

export default authWatcher;
