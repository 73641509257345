import React, { useState } from 'react';
import { withNamespaces } from 'react-i18next';
import TextMask from 'react-text-mask';
import { createNumberMask } from 'text-mask-addons';

const CustomInputText = withNamespaces('translations')(
  ({ t, callback, packIndex, index, defaultValue }) => {
    const [text, setText] = useState(defaultValue);
    const handleChangeText = (amount, packIndex, index) => {
      setText(amount);
      callback(amount, packIndex, index);
    };
    return (
      <>
        <TextMask
          type="text"
          className={`input-text-search`}
          maxLength={15}
          value={text}
          mask={createNumberMask({
            prefix: '',
            allowNegative: false,
          })}
          onFocus={(e) => e.currentTarget.select()}
          onChange={(e) => handleChangeText(e.target.value, packIndex, index)}
        />
      </>
    );
  }
);

export default CustomInputText;
