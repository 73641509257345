import { call, put, all, takeLatest, select } from 'redux-saga/effects';

import { handleApiErrors } from '../../../lib/api-errors';

import i18n from '../../../i18n';

import {
  AUTH_REQUESTING,
  AUTH_SUCCESS,
  AUTH_ERROR,
  AUTH_LIST_REQUESTING,
  AUTH_LIST_SUCCESS,
  AUTH_LIST_ERROR,
  GENERATE_AUTH_REQUESTING,
  GENERATE_AUTH_SUCCESS,
  GENERATE_AUTH_ERROR,
  CHANGE_PASS_REQUESTING,
  CHANGE_PASS_SUCCESS,
  CHANGE_PASS_ERROR,
  CHANGE_TELPIN_REQUESTING,
  CHANGE_TELPIN_SUCCESS,
  CHANGE_TELPIN_ERROR,
  SET_TYPE_AUTH_REQUESTING,
  SET_TYPE_AUTH_SUCCESS,
  SET_TYPE_AUTH_ERROR,
  CHANGE_USER_REQUESTING,
  CHANGE_USER_SUCCESS,
  CHANGE_USER_ERROR,
  CHANGE_BANK_REQUESTING,
  CHANGE_BANK_SUCCESS,
  CHANGE_BANK_ERROR,
  ONE_TIME_TOKEN_REQUESTING,
  ONE_TIME_TOKEN_SUCCESS,
  ONE_TIME_TOKEN_ERROR,
  LOCK_RESENT_OTP,
  LOCK_REFRESH_MATRIX,
} from './constants';

import { setAuthen, setToast } from '../../../containers/client/actions';
import { removeCookie, setCookie } from '../../../lib/storages';
import { getMessage } from '../../../lib/selector';

const appUrl = `${process.env.REACT_APP_API_URL}`;

function handleRequest(request) {
  return request
    .then(handleApiErrors)
    .then((response) => response.json())
    .then((json) => json)
    .catch((error) => {
      throw error;
    });
}

function authApi(data) {
  const url = `${appUrl}/CoreServlet.pt`;
  const request = fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
    body: JSON.stringify(data),
  });
  return handleRequest(request);
}

function changePassApi(data) {
  const url = `${appUrl}/ChangePasswordAdv.pt`;
  const request = fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
    body: JSON.stringify(data),
  });
  return handleRequest(request);
}

function* authRequestFlow({ params }) {
  try {
    const auth = yield call(authApi, params);

    console.log(auth);

    // yield put(setAuthen(auth));

    if (auth.http !== 200) {
      if (auth.data.messageNo === 99991) {
        if (params.data.type === '1') {
          yield put({ type: LOCK_RESENT_OTP });
        }
        if (params.data.type === '3') {
          yield put({ type: LOCK_REFRESH_MATRIX });
        }
        return;
      }

      const msgErr = yield select(getMessage, auth);
      throw Error(msgErr);
    }

    const _auth = {
      isVerified: true,
      type: params.data.type,
      token: auth.data.token,
    };

    // set authen to session storage
    setCookie('authen', JSON.stringify(_auth));

    yield put(setAuthen(_auth));
    yield put({ type: AUTH_SUCCESS });
  } catch (error) {
    yield put({ type: AUTH_ERROR, error });
  }
}

function* authListRequestFlow({ params }) {
  try {
    const authList = yield call(authApi, params);

    // console.log(authList);

    if (authList.http !== 200) {
      const msgErr = yield select(getMessage, authList);
      throw Error(msgErr);
    }

    yield put({ type: AUTH_LIST_SUCCESS, resData: authList.data });
  } catch (error) {
    yield put({ type: AUTH_LIST_ERROR, error });
  }
}

function* generateAuthRequestFlow({ params }) {
  try {
    const authList = yield call(authApi, params);

    // console.log(authList);

    if (authList.http !== 200) {
      const msgErr = yield select(getMessage, authList);
      const toastMsg = {
        id: Math.random(),
        msg: msgErr,
        title: i18n.t('txt-notice'),
      };
      yield put(setToast(toastMsg));
      throw Error(msgErr);
    }

    yield put({ type: GENERATE_AUTH_SUCCESS, resData: authList.data });
  } catch (error) {
    yield put({ type: GENERATE_AUTH_ERROR, error });
  }
}

function* setTypeAuthRequestFlow({ params }) {
  try {
    const authList = yield call(authApi, params);

    // console.log(authList);
    if (authList.http !== 200) {
      const msgErr = yield select(getMessage, authList);
      throw Error(msgErr);
    }

    yield put({ type: SET_TYPE_AUTH_SUCCESS });
  } catch (error) {
    yield put({ type: SET_TYPE_AUTH_ERROR, error });
  }
}

function* changePassRequestFlow({ params }) {
  try {
    const resData = yield call(changePassApi, params);

    if (resData.http !== 200) {
      if (resData.data.messageNo === 99991) {
        yield put({ type: LOCK_RESENT_OTP });
      }

      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');

        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }
      const msgErr = yield select(getMessage, resData);
      yield put({ type: CHANGE_PASS_ERROR, error: msgErr });
      return;
    }

    yield put({ type: CHANGE_PASS_SUCCESS });
    const toastMsg = {
      id: Math.random(),
      msg: i18n.t('txt-title-changepass-success'),
      title: i18n.t('txt-notice'),
    };
    yield put(setToast(toastMsg));
  } catch (error) {
    yield put({ type: CHANGE_PASS_ERROR, error });
  }
}

function* changeTelPINRequestFlow({ params }) {
  try {
    const resData = yield call(changePassApi, params);

    // console.log(changePass);
    if (resData.http !== 200) {
      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');

        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }
      const msgErr = yield select(getMessage, resData);
      throw Error(msgErr);
    }

    yield put({ type: CHANGE_TELPIN_SUCCESS });
  } catch (error) {
    yield put({ type: CHANGE_TELPIN_ERROR, error });
  }
}

function* changeUserRequestFlow({ params }) {
  try {
    const resData = yield call(authApi, params);

    // console.log(changePass);
    if (resData.http !== 200) {
      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');

        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }
      const msgErr = yield select(getMessage, resData);
      throw Error(msgErr);
    }

    yield put({ type: CHANGE_USER_SUCCESS });
  } catch (error) {
    yield put({ type: CHANGE_USER_ERROR, error });
  }
}

function* changeBankRequestFlow({ params }) {
  try {
    const resData = yield call(authApi, params);

    // console.log(changePass);
    if (resData.http !== 200) {
      if (resData.data.messageNo === 99991) {
        yield put({ type: LOCK_RESENT_OTP });
      }

      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');

        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }
      const msgErr = yield select(getMessage, resData);
      throw Error(msgErr);
    }

    yield put({ type: CHANGE_BANK_SUCCESS });
  } catch (error) {
    yield put({ type: CHANGE_BANK_ERROR, error });
  }
}

function* oneTimeTokenRequestFlow({ params }) {
  try {
    const resData = yield call(authApi, params);
    if (resData.http !== 200) {
      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');

        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }
      const msgErr = yield select(getMessage, resData);
      throw Error(msgErr);
    }

    yield put({ type: ONE_TIME_TOKEN_SUCCESS });
  } catch (error) {
    yield put({
      type: ONE_TIME_TOKEN_ERROR,
      error,
    });
  }
}

function* authWatcher() {
  yield all([
    takeLatest(AUTH_REQUESTING, authRequestFlow),
    takeLatest(AUTH_LIST_REQUESTING, authListRequestFlow),
    takeLatest(GENERATE_AUTH_REQUESTING, generateAuthRequestFlow),
    takeLatest(SET_TYPE_AUTH_REQUESTING, setTypeAuthRequestFlow),
    takeLatest(CHANGE_PASS_REQUESTING, changePassRequestFlow),
    takeLatest(CHANGE_TELPIN_REQUESTING, changeTelPINRequestFlow),
    takeLatest(CHANGE_USER_REQUESTING, changeUserRequestFlow),
    takeLatest(CHANGE_BANK_REQUESTING, changeBankRequestFlow),
    takeLatest(ONE_TIME_TOKEN_REQUESTING, oneTimeTokenRequestFlow),
  ]);
}

export default authWatcher;
