import React from 'react';
import { connect } from 'react-redux';

import {
  setSymbolActive,
  setSymbolScroll,
} from '../../containers/client/actions';
import DatagridRowGroup from './DatagridRowGroup';
import DatagridRowGroup2 from './DatagridRowGroup2';

class DatagridGroup extends React.Component {
  constructor(props) {
    super(props);

    // this.itemRefs = {};
  }

  scrollTo(id) {
    let _rootRow = document.getElementById(id + 'row');
    if (_rootRow) {
      _rootRow.scrollIntoView();
      _rootRow.classList.add('bg-active');
      setTimeout(() => {
        if (_rootRow) _rootRow.classList.remove('bg-active');
      }, 1500);
    }
  }

  componentDidUpdate(preProps) {
    const { symScroll } = this.props;

    if (symScroll && symScroll !== preProps.symScroll) {
      this.scrollTo(symScroll);
      this.props.dispatch(setSymbolScroll(null));
    }
  }

  handleOpenModal = (sym) => {
    const { customOpenModal } = this.props;
    if (customOpenModal && typeof customOpenModal == 'function') {
      customOpenModal(sym);
    } else this.props.dispatch(setSymbolActive(sym));
  };

  _handleChange = (e) => {
    if (e.target.checked && e.currentTarget.dataset.tag)
      this.props.handlePinStock(e.currentTarget.dataset.tag);
    else if (!e.target.checked && e.currentTarget.dataset.tag)
      this.props.handleUnPinStock(e.currentTarget.dataset.tag);
  };

  render() {
    const {
      resource,
      children,
      classname,
      isPc,
      isDerivative,
      isOddlot,
      colgroup,
      isCW,
    } = this.props;

    let _colgroup = !colgroup ? <DefaulColGroup /> : colgroup;
    return (
      <table className={classname + ' table-striped '}>
        {_colgroup}

        {children && (
          <thead style={{ borderBottom: '1px solid #42465b' }}>
            {children}
          </thead>
        )}
        <tbody>
          {resource &&
            !!resource?.length &&
            resource.map((item, index) => {
              if (!isDerivative)
                return (
                  <DatagridRowGroup
                    record={item}
                    key={`${item.sym}-${index}-${isOddlot}`}
                    isPc={isPc}
                    handleOpenModal={this.handleOpenModal}
                    handleChange={this._handleChange}
                    isCW={isCW}
                  />
                );
              else
                return (
                  <DatagridRowGroup2
                    record={item}
                    key={`${item.sym}-${index}-${isOddlot}`}
                    isPc={isPc}
                    handleOpenModal={this.handleOpenModal}
                    handleChange={this._handleChange}
                  />
                );
            })}
        </tbody>
      </table>
    );
  }
}

const DefaulColGroup = () => {
  return (
    <colgroup>
      <col width="5.5%"></col>
      {/** sym */}
      <col width="3.6%"></col>
      {/** tc */}
      <col width="3.6%"></col>
      {/** tran */}
      <col width="3.6%"></col>
      {/** san */}
      <col width="3.6%"></col>
      {/** gia 3 */}
      <col width="4.0%"></col>
      {/** kl 3 */}
      <col width="3.6%"></col>
      {/** giá 2 */}
      <col width="4.0%"></col>
      {/** kl 2 */}
      <col width="3.6%"></col>
      {/** gia 1 */}
      <col width="4.0%"></col>
      {/** kl 1 */}
      <col width="3.6%"></col>
      {/** gia khop */}
      <col width="4.4%"></col>
      {/** kl khop */}
      <col width="3.6%"></col>
      {/** +/- */}
      <col width="3.6%"></col>
      {/** gia 1 */}
      <col width="4.0%"></col>
      {/** kl 1 */}
      <col width="3.6%"></col>
      {/** gia 2 */}
      <col width="4.0%"></col>
      {/** kl 2 */}
      <col width="3.6%"></col>
      {/** gia 3 */}
      <col width="4.0%"></col>
      {/** kl 3 */}
      <col width="6.2%"></col>
      {/** tong kl */}
      <col width="3.8%"></col>
      {/** cao */}
      <col width="3.6%"></col>
      {/** thap */}
      <col width="3.6%"></col>
      {/** tb */}
      <col width="4.6%"></col>
      {/** nn mua */}
      <col width="4.6%"></col>
      {/** nn ban */}
    </colgroup>
  );
};

const mapStateToProps = (state) => {
  // console.log(state.client)
  return {
    symScroll: state.client.symbolScroll,
    isOddlot: state.priceBoard.isOddlot,
  };
};

export default connect(mapStateToProps)(DatagridGroup);
