import React, { useContext, useState } from 'react';
import { ReactReduxContext, useDispatch, useSelector } from 'react-redux';
import PerfectScrollBar from 'react-perfect-scrollbar';
import { useEffect } from 'react';
import { Portal } from 'react-overlays';
import {
  setBondDetailModalRequest,
  setProInvRequire,
  getCollateralDetail,
  setRegistBondTradingRetailModal,
} from '../action';
import moment from 'moment';
import {
  formatDate,
  formatToNewDate,
  numberFormat,
  _formatDate,
  _formatDate2,
} from '../../../util';
import { withNamespaces } from 'react-i18next';
import SellOrder from './Order/sellOrder';
import BuyOrder from './Order/buyOrder';
import BondContract from './Contract';
import { setAuth, setLogin, setToast, unsetClient } from '../../client/actions';
import Modal from 'react-bootstrap/Modal';
import {
  getBondProductByType,
  requiredProInvestor,
  retailTradingRegisted,
} from '../utils';
import { v4 as uuidv4 } from 'uuid';
import { handleApiErrors } from '../../../lib/api-errors';
import { Logout, logoutRequest } from '../../../components/modal/login/actions';
import { removeCookie } from '../../../lib/storages';
import { getMessage, makeGetBondToolTip } from '../../../lib/selector';
import DatePicker from 'react-datepicker';
import { OverlayTrigger, Tooltip, Popover } from 'react-bootstrap';
import { setPFBondOrder } from '../../pinefolio/action';
import { bondConstants, constants } from '../../../util/constant';
import Collaterals from './Type/collaterals';

const appUrl = `${process.env.REACT_APP_API_URL}`;
const CalendarContainer = ({ children }) => {
  const el = document.getElementById('calendar-portal');
  return <Portal container={el}>{children}</Portal>;
};

const BondDetail = ({
  typeBond,
  closeHandle,
  modalSize,
  resizeModal,
  defaultDeal,
}) => {
  const dispatch = useDispatch();
  const { bondDetailModalType, bondDetail, bondProductDetail } = useSelector(
    (state) => state.bond
  );
  const theme = useSelector((state) => state.client.currentTheme);
  const bondDetailModalTypeHandle = (state) => {
    dispatch(setBondDetailModalRequest(state));
    dispatch(setPFBondOrder(null));
  };

  const handleCloseModal = () => {
    bondDetailModalTypeHandle(bondConstants.modals.CLOSE);
  };

  return (
    <Modal
      show={bondDetailModalType >= 0}
      dialogClassName="wts-modal"
      backdropClassName="wts-modal-backdrop bond-detail-backdrop"
      contentClassName={`${
        theme ? theme.name : 'dark'
      } bond-detail wts-modal-content ${modalSize}`}
      onEscapeKeyDown={() =>
        bondDetailModalTypeHandle(bondConstants.modals.CLOSE)
      }
      centered
      onHide={() => handleCloseModal()}
      style={{ zIndex: 11116 }}
    >
      {(bondDetailModalType == 1 ||
        bondDetailModalType == 2 ||
        bondDetailModalType == 0) && (
        <BondInfor
          bondDetailModalType={bondDetailModalType}
          closeHandle={closeHandle}
          bondDetailModalTypeHandle={bondDetailModalTypeHandle}
          modalSize={modalSize}
          bondDetail={bondDetail}
          bondProduct={bondProductDetail}
        />
      )}

      {bondDetailModalType == 1 && (
        <BuyOrder
          typeBond={typeBond}
          resizeModal={resizeModal}
          closeHandle={closeHandle}
          bondDetailModalTypeHandle={bondDetailModalTypeHandle}
        />
      )}

      {bondDetailModalType == 2 && (
        <SellOrder defaultDeal={defaultDeal} closeHandle={closeHandle} />
      )}
      {bondDetailModalType == 4 && <BondContract closeHandle={closeHandle} />}
      {bondDetailModalType == 5 && <DealIncomeFlow closeHandle={closeHandle} />}
    </Modal>
  );
};

const BondInfor = withNamespaces('translations')(
  ({
    bondDetailModalTypeHandle,
    closeHandle,
    bondDetailModalType,
    modalSize,
    bondDetail,
    bondProduct,
    t,
  }) => {
    const dispatch = useDispatch();
    const { token, auth, setting } = useSelector((state) => state.client);
    const bondToolTip = useSelector(makeGetBondToolTip());
    const [open, setOpen] = useState();

    const buyHandle = () => {
      if (token) {
        if (auth && auth.isVerified) {
          const isRegistedTradingRetailStatus = setting?.pcbMapYN;
          const proIns = requiredProInvestor(token, bondDetail);

          if (
            bondDetail?.listTp == '4' &&
            (!isRegistedTradingRetailStatus ||
              isRegistedTradingRetailStatus == 'N')
          ) {
            dispatch(setRegistBondTradingRetailModal(true));
            return;
          }

          if (proIns) {
            dispatch(setProInvRequire(true));
            return;
          }

          if (
            bondDetail?.listTp == '4' &&
            isRegistedTradingRetailStatus == 'P'
          ) {
            const toastMsg = {
              id: Math.random(),
              msg: t('bond.retailTrading.pendingNoti'),
              title: t('txt-notice'),
            };

            dispatch(setToast(toastMsg));
            return;
          }

          bondDetailModalTypeHandle(bondConstants.modals.BUY_ORDER);
        } else dispatch(setAuth());
      } else {
        dispatch(setLogin());
      }
    };

    const sellHandle = () => {
      if (token) {
        if (auth && auth.isVerified) {
          bondDetailModalTypeHandle(bondConstants.modals.SELL_ORDER);
        } else dispatch(setAuth());
      } else {
        dispatch(setLogin());
      }
    };

    if (!bondDetail || !bondProduct) return <></>;
    return (
      <div className="bond-detail-panel">
        <div className="bond-detail-header">
          <span className="bond-detail__title text--light">
            {t('bond.information')}
          </span>
          {modalSize == 'wts-modal-content--sm' && (
            <span className="modal-close" onClick={() => closeHandle()}>
              <span className="icon iClose"></span>
            </span>
          )}
        </div>
        <div className="bond-detail-body">
          <div className="bond-detail-overview">
            <div className="bond-infor">
              <table className="table table-bordered">
                <tbody>
                  <tr>
                    <td className="text-left text--light3 w-50">
                      <span>{t('bond.tableLabel.bondCode')}</span>
                    </td>
                    <td className="text-left text--light fw-500 w-50">
                      {bondDetail.bondCode}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-left text--light3">
                      <span>{t('bond.issuer')}</span>
                    </td>
                    <td className="text-left text--light fw-500">
                      {bondDetail.issuerNm}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-left text--light3">
                      <span>{t('bond.collateral')}</span>
                    </td>
                    <td className="text-left fw-500">
                      {bondDetail.grntdYn == 'Y' ? (
                        <Collaterals bondCode={bondDetail.bondCode} />
                      ) : (
                        t('txt-no')
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-left text--light3">
                      <span>{t('bond.issueDate')}</span>
                    </td>
                    <td className="text-left text--light fw-500">
                      {_formatDate2(bondDetail.issudt)}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-left text--light3">
                      <span>{t('bond.tableLabel.maturityDate')}</span>
                    </td>
                    <td className="text-left text--light fw-500">
                      {_formatDate2(bondDetail.exprdt)}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-left text--light3 ">
                      <span>{t('bond.forProfessionalInvestors')}</span>
                    </td>
                    <td className="text-left text--light fw-500">
                      {bondDetail.proInvtYN == 'Y' ? t('txt-yes') : t('txt-no')}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-left text--light3">
                      <span>{t('bond.parValue')}</span>
                    </td>
                    <td className="text-left text--light fw-500">
                      {numberFormat(bondDetail.parValue, 0) + ' VNĐ'}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-left text--light3">
                      <span>{t('bond.currentCoupon')}</span>
                    </td>
                    <td className="text-left text--light fw-500">
                      {numberFormat(bondDetail.cpnrt, 2, '0') +
                        '%/' +
                        t('bond.bondProduct.year')}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-left text--light3">
                      <span>{t('bond.couponFrequency')}</span>
                    </td>
                    <td className="text-left text--light fw-500">
                      {bondDetail.freq < 2
                        ? bondDetail.freq +
                          ' ' +
                          t('bond.bondProduct.time') +
                          '/' +
                          t('bond.bondProduct.year')
                        : bondDetail.freq +
                          ' ' +
                          t('bond.bondProduct.times') +
                          '/' +
                          t('bond.bondProduct.year')}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-left text--light3">
                      <span>{t('bond.brochure')}</span>
                    </td>
                    <td
                      className="text-left r cursor-pointer"
                      onClick={() => {
                        window.open(bondDetail.url, '_blank');
                      }}
                    >
                      {t('txt-clickhere')}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="product-infor">
              <table className="table table-bordered">
                <tbody>
                  <tr>
                    <td className="text-left text--light3 w-50">
                      <span>{t('bond.tableLabel.productType')}</span>
                    </td>
                    <td className="text-left w-50 text--light fw-500 text-uppercase">
                      {getBondProductByType(bondProduct.productTp)}
                    </td>
                  </tr>
                  {bondProduct?.productTp == constants.bondProductType.Fix && (
                    <tr>
                      <td className="text-left text--light3">
                        <span>{t('bond.terms')}</span>
                      </td>
                      <td className="text-left text--light">
                        {bondProduct?.termName}
                      </td>
                    </tr>
                  )}
                  <tr>
                    {bondProduct?.productTp == constants.bondProductType.Fix ? (
                      <td className="text-left text--light3 d-flex align-items-center">
                        <span>{t('bond.tableLabel.rateAfterTaxAndFee')}</span>
                      </td>
                    ) : (
                      <td className="text-left text--light3 d-flex align-items-center">
                        <span>{t('bond.tableLabel.yieldToMaturity')}</span>
                        {bondToolTip &&
                          bondToolTip.map(
                            (x) =>
                              x.key == 'intRate' && (
                                <OverlayTrigger
                                  trigger={['hover', 'focus']}
                                  placement="top"
                                  onToggle={() => setOpen(!open)}
                                  show={open}
                                  rootClose
                                  overlay={
                                    <Popover style={{ maxWidth: '440px' }}>
                                      <Popover.Content className="d-flex">
                                        {x.value}
                                      </Popover.Content>
                                    </Popover>
                                  }
                                >
                                  <span className="tool-tip d-flex align-items-center cursor-pointer">
                                    <span className="icon iWarming ml-1 mr-1"></span>
                                  </span>
                                </OverlayTrigger>
                              )
                          )}
                      </td>
                    )}
                    <td className="text-left  r">
                      {numberFormat(bondProduct.intRate, 2, '0') +
                        '%/' +
                        t('bond.bondProduct.year')}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-left text--light3">
                      <span>{t('bond.tableLabel.buyPrice')}</span>
                    </td>
                    <td className="text-left  i">
                      {numberFormat(bondProduct.leg1Prc, 0)}
                    </td>
                  </tr>
                  {bondProduct?.productTp != constants.bondProductType.Fix && (
                    <tr>
                      <td className="text-left text--light3">
                        <span>{t('bond.tableLabel.sellPrice')}</span>
                      </td>
                      <td className="text-left d">
                        {bondDetail?.listTp == '2'
                          ? ''
                          : numberFormat(bondProduct.leg2Prc, 0)}
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td className="text-left text--light3">
                      <span>{t('bond.tableLabel.minQuantityOrder')}</span>
                    </td>
                    <td className="text-left text--light fw-500">
                      {`${bondProduct.minInvtQtyPerOrdr} ${t('bond.name')}${
                        bondProduct.minInvtQtyPerOrdr > 0 ? t('txt-many') : ''
                      }`}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            {bondDetailModalType == 0 && (
              <div className="bond-actions">
                <button className="btn btn--buy2" onClick={buyHandle}>
                  {t('txt-buy')}
                </button>
                {bondProduct?.productTp != constants.bondProductType.Fix &&
                  bondProduct?.productTp !=
                    constants.bondProductType.Growth && (
                    <button
                      className={`btn btn--sell2 ${
                        bondDetail.listTp == 2 && 'disabled'
                      }`}
                      onClick={() => {
                        if (bondDetail.listTp != 2) sellHandle();
                      }}
                    >
                      {t('txt-sell')}
                    </button>
                  )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
);

const DealIncomeFlow = withNamespaces('translations')(
  ({ t, closeHandle, modalSize }) => {
    const dispatch = useDispatch();
    const { token } = useSelector((state) => state.client);
    const { deal } = useSelector((state) => state.bond);
    const [xpctDueDate, setXpctDueDate] = useState(
      formatToNewDate(deal.dueDate),
      ''
    );
    const [moneyFlow, setMoneyFlow] = useState();
    const { store } = useContext(ReactReduxContext);

    const getDealIncomeFlow = (account) => {
      if (account) {
        const uuid = uuidv4();

        const _xpctDueDate = _formatDate(
          moment(xpctDueDate).format('DD/MM/YYYY')
        );
        const params = {
          group: 'CORE',
          user: token.user,
          session: token.session,
          cmd: 'getDealIncomeFlow',
          rqId: uuid,
          channel: 'WTS',
          data: {
            cif: token.cif,
            acntNo: deal.acntNo,
            subAcntNo: deal.subAcntNo,
            dealId: deal.dealId,
            xpctDueDate: _xpctDueDate,
          },
        };
        const url = `${appUrl}/BondServlet.pt`;
        fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          },
          body: JSON.stringify(params),
        })
          .then(handleApiErrors)
          .then((response) => response.json())
          .then((res) => {
            if (res.http == 200) {
              const _moneyFlow = res.data;
              setMoneyFlow(_moneyFlow);
              console.log('_moneyFlow', _moneyFlow);
            } else {
              if (res.http === 401) {
                if (token) dispatch(logoutRequest(token));
                dispatch(unsetClient());
                removeCookie('token');
                removeCookie('authen');
                dispatch(Logout());
                const toastMsg = {
                  id: Math.random(),
                  msg: t('txt-valid-loss-session'),
                  title: t('txt-notice'),
                };
                dispatch(setToast(toastMsg));
              } else {
                const msgErr = getMessage(res.data.message, store.getState);
                const toastMsg = {
                  id: Math.random(),
                  msg: msgErr,
                  title: t('txt-notice'),
                };
                dispatch(setToast(toastMsg));
              }
            }
          })
          .catch((error) => {
            console.error('Error:', error);
          });
      }
    };

    useEffect(() => {
      getDealIncomeFlow(deal);
    }, [deal, xpctDueDate]);

    return (
      <div
        className="bond-detail-order bond-detai-order--buy bond-detail-panel position-relative"
        style={{ justifyContent: 'normal' }}
      >
        <div className="bond-detail-header">
          <span className="bond-detail__title">{t('bond.dealMoneyFlow')}</span>

          {modalSize == 'wts-modal-content--xl' && (
            <span className="modal-close" onClick={() => closeHandle()}>
              <span className="icon iClose"></span>
            </span>
          )}
        </div>
        <div
          className="bond-detail-body"
          style={{ overflow: 'hidden', padding: '1px', maxHeight: '496px' }}
        >
          <div className="bond-detai-moneyFlow">
            <table className="table-border-radius w-100 table-fix mb-3 ">
              <tbody>
                <tr style={{ display: 'none' }}></tr>
                <tr>
                  <td>
                    <label className="text--light3">
                      {t('bond.dayIntendedToSell')}
                    </label>
                  </td>
                  <td className="">
                    <div className="filter-control-calendar flex-1 ">
                      <DatePicker
                        minDate={new Date()}
                        maxDate={formatToNewDate(deal.dueDate)}
                        selected={xpctDueDate}
                        onChange={(date) => setXpctDueDate(date)}
                        dateFormat="dd/MM/yyyy"
                        popperContainer={CalendarContainer}
                        placeholderText="Intended to sell"
                      />
                      <span
                        className="position-absolute icon iCalendar"
                        style={{ right: '8px' }}
                      ></span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="d-flex flex-column" style={{ height: '100%' }}>
              <PerfectScrollBar className="table-border-radius">
                <table className="table table-border-radius">
                  <tbody>
                    <tr>
                      <td>
                        <label className="text--light3">
                          {t('bond.tableLabel.dealID')}
                        </label>
                      </td>
                      <td className="text-right" colSpan={2}>
                        <label>{deal.dealId}</label>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label className="text--light3">
                          {t('bond.tableLabel.status')}
                        </label>
                      </td>
                      <td className="text-right i" colSpan={2}>
                        <label>{deal.dealStatNm}</label>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label className="text--light3">
                          {t('bond.tableLabel.bondCode')}
                        </label>
                      </td>
                      <td className="text-right" colSpan={2}>
                        <label>{deal.bondCode}</label>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label className="text--light3">
                          {t('bond.tableLabel.tradeDate')}
                        </label>
                      </td>
                      <td className="text-right" colSpan={2}>
                        <label>{_formatDate2(deal.tranDate)}</label>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label className="text--light3">
                          {t('bond.investAmount')}
                        </label>
                      </td>
                      <td className="text-right" colSpan={2}>
                        <label>
                          {moneyFlow && numberFormat(moneyFlow.invtAmt, 0, '0')}
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label className="text--light3">
                          {t('bond.bondQuantity.name')}
                        </label>
                      </td>
                      <td className="text-right" colSpan={2}>
                        <label>
                          {moneyFlow &&
                            numberFormat(moneyFlow.quantity, 0, '0')}
                        </label>
                      </td>
                    </tr>

                    {moneyFlow &&
                      moneyFlow.couponAmt &&
                      moneyFlow.couponAmt.map((coupon) => (
                        <tr
                          className={
                            formatToNewDate(coupon.paydate) > new Date() &&
                            'deal-money-flow'
                          }
                        >
                          <td>
                            {coupon.times < 999 ? (
                              <label className="text--light3">
                                {t('bond.coupon')}
                              </label>
                            ) : coupon.times == 999 ? (
                              <label className="text--light3">
                                {t('bond.sellMoney')}
                              </label>
                            ) : (
                              <label className="text--light3">
                                {t('bond.principal')}
                              </label>
                            )}
                          </td>
                          <td>
                            <label className="text--light3">
                              {_formatDate2(coupon.paydate)}
                            </label>
                          </td>
                          {
                            <td className="text-right">
                              <span>{numberFormat(coupon.amount, 0, '0')}</span>
                            </td>
                          }
                        </tr>
                      ))}
                    <tr>
                      <td>
                        <label className="text--light3">
                          {t('bond.netProfit')}
                        </label>
                      </td>
                      <td colSpan={2} className="text-right">
                        <span>
                          {moneyFlow && numberFormat(moneyFlow.profit, 0, '0')}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label className="text--light3">{t('bond.rate')}</label>
                      </td>
                      <td colSpan={2} className="text-right r">
                        <span>
                          {moneyFlow &&
                            numberFormat(moneyFlow.realRt, 2, '0') + '%'}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label className="text--light3">
                          {t('bond.totalAmountReceive')}
                        </label>
                      </td>
                      <td colSpan={2} className="text-right i">
                        <span>
                          {moneyFlow &&
                            numberFormat(moneyFlow.totRecvAmt, 0, '0')}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </PerfectScrollBar>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default BondDetail;
