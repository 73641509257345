import {
  GET_LIST_POSITION_ALL,
  GET_LIST_POSITION_ALL_SUCCESS,
  GET_LIST_POSITION_ALL_ERROR,
  GET_BOND_PORTFOLIO,
  GET_BOND_PORTFOLIO_SUCCESS,
  GET_BOND_PORTFOLIO_ERROR,
  GET_INQUIRY_RIGHTLIST_ALL,
  GET_INQUIRY_RIGHTLIST_ALL_SUCCESS,
  GET_INQUIRY_RIGHTLIST_ALL_ERROR,
} from './constants';

export const getListPositionAllRequest = function getListPositionAllRequest(
  params
) {
  return {
    type: GET_LIST_POSITION_ALL,
    params,
  };
};

export const getListPositionAllSuccess = function getListPositionAllSuccess(
  listPositionAll
) {
  return {
    type: GET_LIST_POSITION_ALL_SUCCESS,
    listPositionAll,
  };
};

export const getListPositionAllError = function getListPositionAllError(error) {
  return {
    type: GET_LIST_POSITION_ALL_ERROR,
    error,
  };
};

export const getBondPortfolioRequest = function getBondPortfolioRequest(
  params
) {
  return {
    type: GET_BOND_PORTFOLIO,
    params,
  };
};

export const getBondPortfolioSuccess = function getBondPortfolioSuccess(
  bondPortfolio
) {
  return {
    type: GET_BOND_PORTFOLIO_SUCCESS,
    bondPortfolio,
  };
};

export const getBondPortfolioError = function getBondPortfolioError(error) {
  return {
    type: GET_BOND_PORTFOLIO_ERROR,
    error,
  };
};

export const getInquiryRightListAllRequest =
  function getInquiryRightListAllRequest(params) {
    return {
      type: GET_INQUIRY_RIGHTLIST_ALL,
      params,
    };
  };

export const getInquiryRightListAllSuccess =
  function getInquiryRightListAllSuccess(inquiryRightList) {
    return {
      type: GET_INQUIRY_RIGHTLIST_ALL_SUCCESS,
      inquiryRightList,
    };
  };

export const getInquiryRightListAllError = function getInquiryRightListAllError(
  error
) {
  return {
    type: GET_INQUIRY_RIGHTLIST_ALL_ERROR,
    error,
  };
};
