import React from 'react';
import * as FileSaver from 'file-saver';
import * as Excel from 'exceljs';

import {
  mapOrderChanel,
  numberFormat,
  _convertActTypeToStr,
  _convertTpToPrice,
  _formatDate2,
  _getOrdCondStatus,
  _getOrderStatus,
} from '../../../../util';

import { translate, withNamespaces } from 'react-i18next';
import { Mixpanel } from '../../../../lib/mixpanel';

function nextColumn(current) {
  let alphabet = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
  ];
  return alphabet[alphabet.indexOf(current) + 1];
}

const Index = withNamespaces('translations')(
  ({
    csvData,
    fileName,
    fromDate,
    toDate,
    accountCode,
    accountName,
    symbol,
    typeOrder,
    status,
    t,
  }) => {
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const headers = [
      'STT',
      'Ngày GD',
      'GD',
      'Mã CK',
      'SHL',
      'Từ ngày',
      'Đến ngày',
      'Điều kiện',
      'KL',
      'Trạng thái',
      'KL khớp',
    ];

    const border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };

    const font = { size: 11, bold: true, name: 'Times New Roman' };

    const exportToCSV = async (
      csvData,
      fileName,
      fromDate,
      toDate,
      accountCode,
      accountName,
      symbol,
      typeOrder
    ) => {
      const workbook = new Excel.Workbook();
      workbook.creator = 'Pinetree';
      workbook.lastModifiedBy = '';
      workbook.created = new Date();
      workbook.modified = new Date();

      const worksheet = workbook.addWorksheet(
        t('transaction.conditionalOrderStatement.name'),
        {
          pageSetup: { paperSize: 9, orientation: 'landscape' },
        }
      );

      worksheet.getCell('A1').value = t('lbl-company');
      worksheet.getCell('A1').font = {
        size: 13,
        bold: true,
        name: 'Times New Roman',
      };
      worksheet.getCell('A2').value = t('lbl-company-address');
      worksheet.getCell('A3').value = t('lbl-company-contact');

      worksheet.mergeCells('A5:K5');
      worksheet.getCell('A5').value = t(
        'transaction.conditionalOrderStatement.name'
      );
      worksheet.getCell('A5').font = {
        size: 14,
        bold: true,
        name: 'Times New Roman',
      };
      worksheet.getCell('A5').alignment = {
        vertical: 'middle',
        horizontal: 'center',
      };

      worksheet.mergeCells('A6:K6');
      worksheet.getCell('A6').value = `${t('txt-label-from')} ${fromDate} ${t(
        'txt-label-to'
      ).toLowerCase()} ${toDate}`;
      worksheet.getCell('A6').alignment = {
        vertical: 'middle',
        horizontal: 'center',
      };

      worksheet.getCell('A7').value = t('personal.nav.account.name');
      worksheet.getCell('B7').value = accountCode;
      worksheet.getCell('B7').font = {
        size: 13,
        bold: true,
        name: 'Times New Roman',
      };
      worksheet.getCell('H7').value = t('txt-symbol');
      worksheet.getCell('I7').value = symbol;
      worksheet.getCell('I7').font = {
        size: 13,
        bold: true,
        name: 'Times New Roman',
      };

      worksheet.getCell('A8').value = t('txt-label-vsd-no');
      worksheet.getCell('B8').value = accountName;
      worksheet.getCell('B8').font = {
        size: 13,
        bold: true,
        name: 'Times New Roman',
      };
      worksheet.getCell('H8').value = t('txt-order-type');
      worksheet.getCell('I8').value = typeOrder;
      worksheet.getCell('I8').font = {
        size: 13,
        bold: true,
        name: 'Times New Roman',
      };
      worksheet.getCell('H9').value = t('txt-status');
      worksheet.getCell('I9').value = status;
      worksheet.getCell('I9').font = {
        size: 13,
        bold: true,
        name: 'Times New Roman',
      };

      let startCol = 'A';
      let col = startCol.toUpperCase();
      let rowNum = 11;

      let len = headers.length;
      for (let i = 0; i < len; i++) {
        let cell = worksheet.getCell(col + rowNum);
        cell.value = headers[i];
        cell.border = border;
        cell.font = font;
        cell.alignment = { horizontal: 'center' };
        cell.fill = {
          type: 'pattern',
          pattern: 'darkTrellis',
          fgColor: { argb: 'FF7ABBFF' },
          bgColor: { argb: 'FF7ABBFF' },
        };
        col = nextColumn(col);
      }

      let _arr = [];
      // console.log(csvData)
      csvData.forEach((element, index) => {
        _arr.push({
          A: index + 1,
          B: element.timeQuote,
          C:
            element.side === 'B'
              ? t('txt-buy').toUpperCase()
              : t('txt-sell').toUpperCase(),
          D: element.symbol,
          E: element.ordrNo,
          F: element.createddt,
          G: element.expireddt,
          H: _convertActTypeToStr(element.activetype),
          I: numberFormat(element.qtty, 0, '0'),
          J: _getOrdCondStatus(element.status),
          K: numberFormat(element.execQtty, 0, '0'),
        });
      });

      _arr.forEach((element, index) => {
        rowNum++;
        col = startCol;
        // console.log(element)
        for (let key in element) {
          // console.log(key, element[key])
          let cell = worksheet.getCell(col + rowNum);
          cell.value = element[key];
          cell.border = border;
          if (['I', 'K'].indexOf(key) > -1) {
            cell.alignment = { vertical: 'middle', horizontal: 'right' };
          }
          if (['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'J'].indexOf(key) > -1) {
            cell.alignment = { vertical: 'middle', horizontal: 'center' };
          }
          col = nextColumn(col);
        }
      });

      for (let i = 0; i < worksheet.columns.length; i += 1) {
        let dataMax = 0;
        const column = worksheet.columns[i];
        if (column) {
          for (let j = 11; j < column.values.length; j += 1) {
            const columnLength = column.values[j] ? column.values[j].length : 0;
            if (columnLength > dataMax) {
              dataMax = columnLength;
            }
          }
          column.width = (dataMax < 10 ? 10 : dataMax) + 2;
        }
      }

      // save file
      const excelBuffer = await workbook.xlsx.writeBuffer({ useStyles: true });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    };

    return (
      <div
        className="btn-icon btn-icon--light cursor-pointer"
        onClick={(e) => {
          Mixpanel.track('Export Statement', {
            'Statement Type': 'Conditional Order Statement',
          });
          exportToCSV(
            csvData,
            fileName,
            fromDate,
            toDate,
            accountCode,
            accountName,
            symbol,
            typeOrder,
            status
          );
        }}
      >
        <span className="icon iDownload"></span>
        <span className="fw-500">{t('txt-export-excel')}</span>
      </div>
    );
  }
);

export default Index;
