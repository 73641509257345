import React, { useState } from 'react';

const CheckboxButton = ({
  onClick,
  description = '',
  defaultValue = false, // dont use parent state to this props
}) => {
  const [checked, setChecked] = useState(defaultValue);

  return (
    <div
      className="checkbox-button"
      onClick={() => {
        if (onClick && typeof onClick == 'function') {
          onClick(!checked);
        }
        setChecked(!checked);
      }}
    >
      <span className={`cbb-box ${checked ? 'checked' : ''}`}></span>
      <span className="cbb-text">{description}</span>
    </div>
  );
};

export default CheckboxButton;
