import React, { useContext, useEffect, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import { ReactReduxContext, useDispatch, useSelector } from 'react-redux';
import TextMask from 'react-text-mask';
import {
  canDeletePinefolioOrder,
  getMessageError,
  numberFormat,
  StringToInt,
  useDebounce,
  _formatDate2,
} from '../../../../../util';
import CashInternalTransfer from '../../cashInternalTransfer';
import { v4 as uuidv4 } from 'uuid';
import { setToast, unsetClient } from '../../../../client/actions';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import {
  Logout,
  logoutRequest,
} from '../../../../../components/modal/login/actions';
import { removeCookie } from '../../../../../lib/storages';
import { handleApiErrors } from '../../../../../lib/api-errors';
import ChangePlanTicket from './changePlanTicket';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import {
  getPfListBondForSellRequest,
  getPfValueInfoRequest,
  getPlanModifyInfoRequest,
  getPlanUpdateContract,
  orderPF,
  setPFBondOrder,
  updatePFPlanRequest,
} from '../../../action';
import moment from 'moment';
import {
  getBondDetail,
  setBondDetailModalRequest,
  setBondProductDetail,
} from '../../../../bond/action';
import { bondConstants } from '../../../../../util/constant';
const appUrl = `${process.env.REACT_APP_API_URL}`;

const OderBuyNow = withNamespaces('translations')(
  ({
    t,
    setShowModalOrderBuyNow,
    orderType = 1,
    pfData,
    onCloseModalViewPlan,
    handleGetPfIpListDtl,
  }) => {
    const dispatch = useDispatch();
    const theme = useSelector((state) => state.client?.currentTheme);
    const { token, auth } = useSelector((state) => state.client);
    const { store } = useContext(ReactReduxContext);
    const { pfValueInfo, planUpdateContract, pfListBondForSell } = useSelector(
      (state) => state.pinefolio
    );

    const { defaultAccount, listAccount } = useSelector(
      (state) => state.socket
    );
    const targetAccount = listAccount?.find(
      (item) => item?.subAcntNo?.startsWith('P') && item.subAcntStat == 1
    );

    const lang = useSelector((state) => state.client?.setting?.lang || 'vi');

    const [quantity, setQuantity] = useState('');
    const [reload, setReload] = useState(false);
    const [error, setError] = useState(null);
    const [purchasePower, setPurchasePower] = useState(null);
    const [packSelected, setPackSelected] = useState(1);
    const [showContract, setShowContract] = useState(false);
    const [check, setCheck] = useState(true);
    const [expectedToSellBond, setExpectedToSellBond] = useState(0);

    console.log('pfData', expectedToSellBond);

    const onCloseModal = () => {
      setShowModalOrderBuyNow(false);
    };

    const validation = (purchase) => {
      const missing = purchase?.ordrAmt - purchase?.waitBndVal;
      if (missing > 0) {
        setError(t('pinefolio.order.overPP'));
      } else {
        setError('');
      }
    };

    const caculate = (quantt) => {
      const _quantity = StringToInt(quantt);

      const uuid = uuidv4();
      if (_quantity >= 0) {
        let params = {
          group: 'CORE',
          cmd: 'getPFPowerPurchase',
          rqId: uuid,
          channel: 'WTS',
          session: token.session,
          user: token.user,

          data: {
            langTp: lang,
            cif: token.cif,
            acntNo: targetAccount?.acntNo,
            subAcntNo: targetAccount?.subAcntNo,
            pfCode: pfData?.pfCode,
            planId: pfData?.id,
            buySelTp: orderType + '',
            packNo:
              pfData.activePack == 0
                ? '0'
                : returnPackNo(
                    findFirstPackSlected(pfData?.packs),
                    packSelected
                  ),
          },
        };

        const url = `${appUrl}/PortfolioServlet.pt`;
        fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          },
          body: JSON.stringify(params),
        })
          .then(handleApiErrors)
          .then((response) => response.json())
          .then((res) => {
            if (res.http !== 200) {
              if (res.http === 401) {
                if (token) dispatch(logoutRequest(token));
                dispatch(unsetClient());
                removeCookie('token');
                removeCookie('authen');
                dispatch(Logout());
                toastHandle(t('txt-valid-loss-session'));
              } else {
                toastHandle(getMessageError(res, store.getState));
              }
            } else {
              // success
              const data = res.data;
              setPurchasePower(data);
              validation(data);
            }
          })
          .catch((error) => {
            console.error('Error:', error);
          });
      } else {
        setPurchasePower(null);
        setError('');
      }
    };

    const cashTransferCallback = () => {
      const _quantt = quantity;
      caculate(_quantt);
    };

    const toastHandle = (msg) => {
      const toastMsg = {
        id: Math.random(),
        msg: msg,
        title: t('txt-notice'),
      };
      dispatch(setToast(toastMsg));
    };

    function hanldeGetPfListBondForSell() {
      const uuid = uuidv4();
      const params = {
        group: 'CORE',
        cmd: 'getPFBndListForSell',
        rqId: uuid,
        channel: 'WTS',
        session: token.session,
        user: token.user,
        data: {
          cif: token.cif,
          acntNo: targetAccount?.acntNo,
          subAcntNo: targetAccount?.subAcntNo,
          planId: pfData?.id,
          packNo:
            pfData.activePack == 0
              ? '0'
              : returnPackNo(findFirstPackSlected(pfData?.packs), packSelected),
          ordrAmt:
            purchasePower?.ordrAmt - purchasePower?.waitBndVal > 0
              ? purchasePower?.ordrAmt - purchasePower?.waitBndVal + ''
              : '0',
        },
      };
      dispatch(getPfListBondForSellRequest(params));
    }

    function handleConfirmBuyPlan() {
      if (!token) return;
      const uuid = uuidv4();
      const params = {
        group: 'CORE',
        user: token.user,
        session: token.session,
        type: auth.type,
        token: auth.token,
        cmd: 'placePFOrderByIP',
        rqId: uuid,
        channel: 'WTS',
        data: {
          cif: token.cif,
          acntNo: targetAccount?.acntNo,
          subAcntNo: targetAccount?.subAcntNo,
          planId: pfData?.planId,
          packNo:
            pfData?.activePack == 0
              ? '0'
              : returnPackNo(findFirstPackSlected(pfData?.packs), packSelected),
          replanYN: check ? 'Y' : 'N',
        },
      };

      const url = `${appUrl}/PortfolioServlet.pt`;
      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
        body: JSON.stringify(params),
      })
        .then(handleApiErrors)
        .then((response) => response.json())
        .then((res) => {
          if (res.http == 200) {
            const toastMsg = {
              id: Math.random(),
              msg: t('bond.order.success'),
              title: t('txt-notice'),
            };
            dispatch(setToast(toastMsg));
            onBuyOrderSuccess();
          } else {
            if (res.http === 401) {
              if (token) dispatch(logoutRequest(token));
              dispatch(unsetClient());
              removeCookie('token');
              removeCookie('authen');
              dispatch(Logout());
              const toastMsg = {
                id: Math.random(),
                msg: t('txt-valid-loss-session'),
                title: t('txt-notice'),
              };
              dispatch(setToast(toastMsg));
            } else {
              const msgErr = getMessageError(res, store.getState);
              const toastMsg = {
                id: Math.random(),
                msg: msgErr,
                title: t('txt-notice'),
              };
              dispatch(setToast(toastMsg));
            }
          }
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }

    function getUpdatePFIPCntr() {
      if (!token || (!auth && !auth.isVerified)) return;
      const uuid = uuidv4();
      const params = {
        group: 'CORE',
        user: token.user,
        session: token.session,
        cmd: 'getPFUpdPlanCntr',
        rqId: uuid,
        channel: 'WTS',
        data: {
          cif: token.cif,
          acntNo: targetAccount?.acntNo,
          subAcntNo: targetAccount?.subAcntNo,
          planId: pfData?.planId,
          packNo:
            pfData?.activePack == 0
              ? '0'
              : returnPackNo(findFirstPackSlected(pfData?.packs), packSelected),
          enDt: moment(new Date()).format('YYYYMMDD'),
        },
      };
      const callback = () => {
        getPlanModifyInfo();
        setShowContract(true);
      };
      dispatch(getPlanUpdateContract(params, () => callback()));
    }

    function getPlanModifyInfo() {
      if (!token || (!auth && !auth.isVerified)) return;
      const uuid = uuidv4();
      const params = {
        group: 'CORE',
        user: token.user,
        session: token.session,
        cmd: 'getPlanModifyInfo',
        rqId: uuid,
        channel: 'WTS',
        data: {
          cif: token.cif,
          acntNo: targetAccount?.acntNo,
          subAcntNo: targetAccount?.subAcntNo,
          planId: pfData?.planId,
          packNo:
            pfData?.activePack == 0
              ? '0'
              : returnPackNo(findFirstPackSlected(pfData?.packs), packSelected),
          enDt: moment(new Date()).format('YYYYMMDD'),
        },
      };
      dispatch(getPlanModifyInfoRequest(params));
    }

    function onBuyOrderSuccess() {
      handleGetPfIpListDtl(pfData);
      onCloseModal();
      setShowContract(false);
    }

    function handleSumExpectedToSellBond() {
      var elements = document.getElementsByClassName('bond-sell-quantity');
      var sum = 0;
      for (var i = 0; i < elements.length; i++) {
        var value = StringToInt(elements[i].innerText);
        if (!isNaN(value)) {
          sum += value;
        }
      }

      setExpectedToSellBond(sum);
    }

    useEffect(() => {
      if (!packSelected) return;
      caculate(1);
      handleGetPfValueInfo();
    }, [packSelected]);

    useEffect(() => {
      if (purchasePower?.ordrAmt) {
        hanldeGetPfListBondForSell();
      }
    }, [purchasePower]);

    useEffect(() => {
      if (pfData && pfData.packs && pfData.packs.length > 0) {
        setPackSelected(findFirstPackSlected(pfData.packs));
      }
    }, [pfData]);

    function returnPackNo(start, end) {
      let _packList = pfData?.packs.filter(
        (item) => item.packNo != 0 && (item.status == 1 || item.status == 2)
      );
      let packNoList = _packList.map((item) => item.packNo);

      let result = '';
      for (let i = start; i <= end; i++) {
        if (packNoList.includes(i)) result += i + ',';
      }
      return result.slice(0, -1);
    }

    const sellCallback = () => {
      setReload(!reload);
      hanldeGetPfListBondForSell();
      handleSumExpectedToSellBond();
      caculate(1);
    };

    useEffect(() => {
      handleSumExpectedToSellBond();
    }, [pfListBondForSell]);

    function handleGetPfValueInfo() {
      const uuid = uuidv4();
      const params = {
        group: 'CORE',
        cmd: 'getPFList',
        rqId: uuid,
        channel: 'WTS',
        data: {
          langTp: lang,
          pfCode: pfData?.pfCode,
          packNo:
            pfData?.activePack == 0
              ? '0'
              : returnPackNo(findFirstPackSlected(pfData?.packs), packSelected),
        },
      };
      dispatch(getPfValueInfoRequest(params));
    }

    const columns = [
      {
        key: 'aplcNo',
        text: t('bond.tableLabel.orderID'),
        className: 'text-center position-relative text--light3 fw-500',
      },
      {
        key: 'pfCode',
        text: t('pinefolio.plan.portfolioCode'),
        className: 'text-center position-relative fw-500 text--light',
      },
      {
        key: 'pfNm',
        text: t('pinefolio.orderList.name'),
        className: 'text-left position-relative text--light3 fw-400',
        align: 'left',
      },
      {
        key: 'prchsSaleTp',
        text: t('bond.order.type'),
        className: (record) => {
          return `text-center ${record.prchsSaleTp == '1' ? 'i' : 'd'}`;
        },
        cell: (record) => {
          return record.prchsSaleTp == '1' ? t('txt-buy') : t('txt-sell');
        },
        sortable: true,
      },
      {
        key: 'trdDate',
        text: t('txt-label-trade-date'),
        className: 'text-center position-relative text--light3 fw-400',
        cell: (record) => {
          return _formatDate2(record.trdDate);
        },
        sortable: true,
      },
      {
        key: 'action',
        text: t('txt-actions'),
        cell: (record) => {
          return auth && auth.isVerified ? (
            <div className="d-flex align-items-center justify-content-center">
              {canDeletePinefolioOrder(record.pfOrderStat) ? (
                <span
                  className="d-flex align-items-center justify-content-center btn btn--delete ml-1"
                  //   onClick={() => _handleDeleteOrder(record.aplcNo)}
                >
                  <span className="icon iCancel text-muted d "></span>
                </span>
              ) : (
                <span className="d-flex align-items-center justify-content-center btn btn--delete disabled ml-1">
                  <span className="icon iCancel text-muted d "></span>
                </span>
              )}
            </div>
          ) : (
            <div className="d-flex align-items-center justify-content-center">
              {canDeletePinefolioOrder(record.pfOrderStat) ? (
                <span
                  className="d-flex align-items-center justify-content-center  btn btn--authen ml-1"
                  //   onClick={() => _handleAuthen()}
                >
                  <span className="icon iCancel text-muted"></span>
                </span>
              ) : (
                <span className="d-flex align-items-center justify-content-center  btn btn--delete disabled ml-1">
                  <span className="icon iCancel text-muted"></span>
                </span>
              )}
            </div>
          );
        },
      },
    ];

    function findFirstPackSlected(packs) {
      let pack = 0;
      if (!packs || !packs.length > 0) {
        return 0;
      }
      pack = packs.find((item) => item.status == 1 || item.status == 2)?.packNo;
      return pack;
    }

    function getLastPackAvail(packs) {
      let pack = 0;
      if (!packs || !packs.length > 0) {
        return 0;
      }
      pack = packs.findLast(
        (item) => item.status == 1 || item.status == 2
      )?.packNo;
      return pack;
    }

    return (
      <div>
        <Modal
          show={true}
          dialogClassName="wts-modal"
          backdropClassName="wts-modal-backdrop"
          contentClassName={`${
            theme ? theme.name : 'dark'
          } plan-modal plan-modal--md wts-modal-content wts-modal-content--lg3 p-0`}
          onEscapeKeyDown={() => onCloseModal()}
          centered
          onHide={() => onCloseModal()}
          style={{ zIndex: 11111 }}
        >
          <div className="plan-modal-layout pinefolio-buy buy-now-layout">
            <div className="plan-modal__header buy-now-layout__header m-0">
              <div className="plan-modal__header--left order-buy-now-modal__header--left">
                <div className="title">
                  <div className="d-flex btn--icon">
                    <span
                      className="icon iPrevious"
                      onClick={() => onCloseModal()}
                    ></span>
                  </div>
                </div>
              </div>
              <div className="plan-modal__header--left">
                <span className="fz_16 text--light fw-500">
                  {`${t('txt-buy')} ${pfData?.pfName}`}
                </span>
              </div>
              <div className="edit-plan__header--right">
                <div className="d-flex"></div>
              </div>
            </div>
            <div className="plan-modal__body buy-now-layout__body gap-24">
              <div className="plan-modal__body--left wts-modal-content--sm2">
                <div className="pf-infor">
                  <span className="d-flex justify-content-start align-items-center flex-md-grow-1 gap-8">
                    <span className="text--light3">
                      {t('pinefolio.plan.planId')}
                    </span>
                    <span className="text--light">{pfData?.id}</span>
                  </span>
                  <span className="d-flex justify-content-start align-items-center flex-md-grow-1 gap-8">
                    <span className="text--light3">{t('pinefolio.code')}</span>
                    <span className="text--light">{pfData?.pfCode}</span>
                  </span>
                </div>
                <div className="muti-select-box d-flex gap-24">
                  {pfData &&
                    pfData.packs &&
                    pfData.packs.length > 0 &&
                    pfData.packs.map(
                      (item) =>
                        item.packNo != 0 &&
                        (item.status == 1 || item.status == 2) && (
                          <div
                            className={`checkbox-button p-0 d-flex gap-6 ${
                              item.packNo <= packSelected ? 'active' : ''
                            }`}
                            onClick={() => setPackSelected(item.packNo)}
                          >
                            <span className="cbb-box"></span>
                            <span
                              className={`cbb-text fz_13 ${
                                item.packNo <= pfData?.activePack
                                  ? 'text--light'
                                  : 'text--light3'
                              }`}
                            >
                              {`Pack ${item.packNo}`}
                            </span>
                          </div>
                        )
                    )}
                </div>
                <div className="product-info">
                  <div className="product-info__infors">
                    <div className="product-info__infor">
                      <div className="d-flex flex-column w-100">
                        <div className="d-flex align-items-center mb-3">
                          <span className="text--sm text--light3 d-flex align-items-center mr-2">
                            <span>{t('txt-label-market-value')}</span>
                            <span className="icon iInfor iTooltip ml-1 mr-1"></span>
                          </span>
                          <span className="text--light text--sm fw-500">
                            {pfValueInfo.totalCount > 0 &&
                              numberFormat(pfValueInfo.list[0].refValue, 0, 0)}
                          </span>
                        </div>
                        <div className="d-flex align-items-center justify-content-between w-100">
                          <span className="d-flex">
                            <span className="text--sm text--light3 d-flex align-items-center mr-2">
                              <span>
                                {t('pinefolio.order.referenceValues.name')}
                              </span>
                              <span className="icon iInfor iTooltip ml-1 mr-1"></span>
                            </span>
                            <span className="text--sm fw-500 r">
                              {pfValueInfo.totalCount > 0 &&
                                numberFormat(
                                  pfValueInfo.list[0].refValue,
                                  0,
                                  0
                                )}
                            </span>
                          </span>
                          <span className="text--sm fw-500 c">
                            {pfValueInfo.totalCount > 0 &&
                              numberFormat(pfValueInfo.list[0].celVal, 0, 0)}
                          </span>
                          <span className="text--sm fw-500 f">
                            {pfValueInfo.totalCount > 0 &&
                              numberFormat(pfValueInfo.list[0].flrVal, 0, 0)}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="product-info__order w-100">
                    <div className="infor-row">
                      <div className="infor-row__name text--light3 text--sm">
                        {t('txt-label-fee')}
                      </div>
                      <div className="infor-row__value text--light fw-500">
                        {numberFormat(purchasePower?.fee, 0, '0')}
                      </div>
                    </div>
                    <div className="infor-row">
                      <div className="d-flex infor-row__name text--light3 text--sm align-items-center">
                        <div className="">
                          {`${t('txt-total-payment')} (1)`}
                        </div>
                        <OverlayTrigger
                          trigger={['hover', 'focus']}
                          placement="top"
                          className="portfolio-overlay"
                          overlay={
                            <Tooltip style={{ 'z-index': '99999' }}>
                              {t('pinefolio.order.buyOrderExplain')}
                            </Tooltip>
                          }
                        >
                          <span className="icon iInfor iTooltip ml-1 mr-1"></span>
                        </OverlayTrigger>
                      </div>
                      <div className="infor-row__value text--md fw-500">
                        {numberFormat(purchasePower?.ordrAmt, 0, '0')}
                      </div>
                    </div>
                    <div className="infor-row">
                      <div className="infor-row__name text--light3 text--sm">
                        {`${t('pinefolio.order.sellingBondValue')} (2)`}
                      </div>
                      <div className="infor-row__value text--light fw-500">
                        {numberFormat(purchasePower?.waitBndVal, 0, '0')}
                      </div>
                    </div>
                    <div className="infor-row">
                      <div className="infor-row__name text--light3 text--sm">
                        {`${t('txt-missing')} (1-2)`}
                      </div>
                      <div className="infor-row__value d fw-500">
                        {numberFormat(
                          Math.max(
                            purchasePower?.ordrAmt - purchasePower?.waitBndVal,
                            0
                          ),
                          0,
                          '0'
                        )}
                      </div>
                    </div>
                    <div className="infor-row">
                      <div className="infor-row__name text--light3 text--sm">
                        {`${t('pinefolio.order.expectedToSellBond')}`}
                      </div>
                      <div className="infor-row__value text--light fw-500">
                        {numberFormat(expectedToSellBond, 0, '0')}
                      </div>
                    </div>
                    <div className="infor-row">
                      <div className="infor-row__name text--light3 text--sm">
                        {`${t('pinefolio.order.expectedToUseOfPP')}`}
                      </div>
                      <div className="infor-row__value text--light fw-500">
                        <div className="infor-row__value text--light fw-500">
                          {numberFormat(
                            Math.max(
                              purchasePower?.ordrAmt -
                                purchasePower?.waitBndVal -
                                expectedToSellBond,
                              0
                            ),
                            0,
                            '0'
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="plan-modal__body--right wts-modal-content--sm3">
                <div className="d-flex flex-column gap-12">
                  <div className="bond-for-sell">
                    <div className="pf-infor d-flex justify-content-between w-100">
                      <span className="fz_13 text--light fw-500">
                        {t('pinefolio.order.listBondForSell')}
                      </span>
                      <span className="fz_13 d-flex gap-8">
                        <span className="text--light3">
                          {`${t('pinefolio.order.expectedToSellBond')}`}
                        </span>
                        <span className="text--light">
                          {numberFormat(expectedToSellBond, 0, '0')}
                        </span>
                      </span>
                    </div>
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>{t('bond.tableLabel.productCode')}</th>
                          <th>{t('bond.tableLabel.availQuantity')}</th>

                          <th>{t('bond.tableLabel.quantityHasProfit')}</th>
                          <th>{t('bond.tableLabel.sellQuantity')}</th>

                          <th>{t('txt-giatri')}</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {pfListBondForSell &&
                          pfListBondForSell.length > 0 &&
                          pfListBondForSell.map((bond, i) => (
                            <BondForSellRow
                              key={i}
                              t={t}
                              rowData={bond}
                              token={token}
                              targetAccount={targetAccount}
                              handleSumExpectedToSellBond={
                                handleSumExpectedToSellBond
                              }
                              callback={sellCallback}
                            />
                          ))}
                      </tbody>
                    </table>
                  </div>
                  <CashInternalTransfer
                    missing={Math.max(
                      purchasePower?.ordrAmt -
                        purchasePower?.waitBndVal -
                        expectedToSellBond -
                        purchasePower?.acntPp,
                      0
                    )}
                    target={targetAccount}
                    callback={cashTransferCallback}
                    key={reload}
                    reloadCallback={() => setReload(!reload)}
                  />
                </div>
              </div>
            </div>
            <div className="plan-modal__footer w-100">
              <div className="d-flex flex-column gap-12 w-100">
                {!(
                  pfData?.activePack == 0 ||
                  packSelected == pfData?.packs?.length
                ) && (
                  <div className="w-50">
                    <div
                      className="checkbox-button p-0"
                      onClick={() => setCheck(!check)}
                    >
                      <span
                        className={`cbb-box ${check ? 'checked' : ''}`}
                      ></span>
                      <span className="cbb-text fz_13 text--light3">
                        {t('pinefolio.plan.messageCompleteDesc')}
                      </span>
                    </div>
                  </div>
                )}
                <div className="w-100 d-flex justify-content-between align-items-center">
                  <div className="d-flex gap-8">
                    <span className="fz_13 text--light3">
                      {t('txt-sub-account')}
                    </span>
                    <span className="fz_13 fw-500 text--light">
                      {targetAccount?.subAcntNo}
                    </span>
                  </div>
                  <div className="d-flex gap-8">
                    <span className="fz_13 text--light3">{t('txt-pp')}</span>
                    <span className="fz_13 fw-500 text--light">
                      {numberFormat(purchasePower?.acntPp, 0, '0')}
                    </span>
                  </div>
                </div>
                <div className="d-flex gap-12">
                  {pfData?.activePack != 0 &&
                  packSelected != getLastPackAvail(pfData?.packs) &&
                  check ? (
                    <div
                      className={`btn btn--primary w-25`}
                      onClick={() => getUpdatePFIPCntr()}
                    >
                      {t('pinefolio.order.viewChangePlanTicket')}
                    </div>
                  ) : (
                    <div className={`btn btn--primary w-25 disabled`}>
                      {t('pinefolio.order.viewChangePlanTicket')}
                    </div>
                  )}
                  {purchasePower?.acntPp + purchasePower?.waitBndVal >=
                  purchasePower?.ordrAmt ? (
                    <div
                      className={`btn btn--buy w-50`}
                      onClick={() => handleConfirmBuyPlan()}
                    >
                      {t('txt-order')}
                    </div>
                  ) : (
                    <div
                      className={`btn btn--buy w-50 disabled`}
                      onClick={() => handleConfirmBuyPlan()}
                    >
                      {t('txt-order')}
                    </div>
                  )}
                  <div
                    className={`btn btn--cancel w-25`}
                    onClick={() => onCloseModal()}
                  >
                    {t('txt-cancel')}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        {showContract && (
          <ChangePlanTicket
            viewOnly={true}
            contracts={planUpdateContract}
            handleConfirmUpdatePlan={handleConfirmBuyPlan}
            setShowContract={setShowContract}
          />
        )}
      </div>
    );
  }
);

const BondForSellRow = ({
  rowData,
  t,
  token,
  targetAccount,
  pfData,
  handleSumExpectedToSellBond,
  callback,
}) => {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.client.setting.lang || 'vi');
  const { planData } = useSelector((state) => state.pinefolio);
  const { bondProducts } = useSelector((state) => state.bond);

  const { store } = useContext(ReactReduxContext);
  const [soldQuantity, setSoldQuantity] = useState(rowData.availSoldQty);
  const [soldValue, setSoldValue] = useState(rowData.availSoldVal);
  const [bondPreOrder, setBondPreOrder] = useState();
  console.log('row data ===> ', rowData);
  const _soldValue = useDebounce(soldValue, 300);

  const toastHandle = (msg) => {
    const toastMsg = {
      id: Math.random(),
      msg: msg,
      title: t('txt-notice'),
    };
    dispatch(setToast(toastMsg));
  };

  function handleChangeSoldQuantity(_soldQuantity) {
    const __soldQuantity = StringToInt(_soldQuantity);
    if (__soldQuantity > rowData.availQty) {
      setSoldQuantity(rowData.availQty.toString());
      handleGetValueToSell(rowData.availQty.toString());
    } else {
      setSoldQuantity(__soldQuantity);
      handleGetValueToSell(__soldQuantity);
    }
  }

  function handleGetValueToSell(_soldQuantity) {
    const uuid = uuidv4();
    const params = {
      group: 'CORE',
      cmd: 'getBondPreOrder',
      rqId: uuid,
      channel: 'WTS',
      session: token.session,
      user: token.user,

      data: {
        cif: token.cif,
        acntNo: targetAccount?.acntNo,
        subAcntNo: targetAccount?.subAcntNo,
        prdCode: rowData.prdCode,
        planId: pfData?.id,
        side: '1',
        tranDt: moment(new Date()).format('YYYYMMDD'),
        quantity: _soldQuantity + '' || '0',
      },
    };

    const url = `${appUrl}/BondServlet.pt`;
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
      body: JSON.stringify(params),
    })
      .then(handleApiErrors)
      .then((response) => response.json())
      .then((res) => {
        if (res.http !== 200) {
          if (res.http === 401) {
            if (token) dispatch(logoutRequest(token));
            dispatch(unsetClient());
            removeCookie('token');
            removeCookie('authen');
            dispatch(Logout());
            toastHandle(t('txt-valid-loss-session'));
          } else {
            toastHandle(getMessageError(res, store.getState));
          }
        } else {
          // success
          const data = res.data;
          setBondPreOrder(data);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  const sellHandle = (data) => {
    const cb = () => {
      if (callback) callback();
      dispatch(setPFBondOrder(null));
    };

    const pfBondOrder = {
      bondCode: data.bndCode,
      productCode: data.prdCode,
      planId: planData.id,
      quantity: soldQuantity,
      hideManual: true,
      disableQuantity: true,
      callback: cb,
    };

    const productDetail = bondProducts?.find(
      (item) => item.productCode == data.prdCode
    );

    dispatch(setPFBondOrder(pfBondOrder));
    dispatch(getBondDetail(data.bondCode, lang));
    dispatch(setBondProductDetail(productDetail));
    dispatch(setBondDetailModalRequest(bondConstants.modals.SELL_ORDER));
  };

  useEffect(() => {
    if (bondPreOrder) {
      setSoldValue(bondPreOrder.ttlSellValue);
    }
  }, [bondPreOrder]);

  useEffect(() => {
    handleSumExpectedToSellBond();
  }, [_soldValue]);

  return (
    <tr>
      <td className="text--light">{rowData.prdCode}</td>
      <td className="text-right">{numberFormat(rowData.availQty, 0, '0')}</td>
      <td className="text-right">{numberFormat(rowData.gainQty, 0, 0)}</td>
      <td className="text-right">
        <div class="sit-input ">
          <div class="sit-input__value text--light">
            <TextMask
              maxLength={15}
              value={soldQuantity}
              mask={createNumberMask({
                prefix: '',
                allowNegative: false,
              })}
              placeholder={t('transaction.stockInternalTransfer.inputAmount')}
              onChange={(e) => handleChangeSoldQuantity(e.target.value)}
              type="text"
            />
          </div>
        </div>
      </td>
      <td className="bond-sell-quantity">{numberFormat(soldValue, 0, '0')}</td>
      <td>
        <span className="btn btn--sell2" onClick={() => sellHandle(rowData)}>
          {t('txt-sell')}
        </span>
      </td>
    </tr>
  );
};

export default OderBuyNow;
