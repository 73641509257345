export const AUTH_REQUESTING = 'AUTH_REQUESTING';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_ERROR = 'AUTH_ERROR';

export const AUTH_LIST_REQUESTING = 'AUTH_LIST_REQUESTING';
export const AUTH_LIST_SUCCESS = 'AUTH_LIST_SUCCESS';
export const AUTH_LIST_ERROR = 'AUTH_LIST_ERROR';

export const GENERATE_AUTH_REQUESTING = 'GENERATE_AUTH_REQUESTING';
export const GENERATE_AUTH_SUCCESS = 'GENERATE_AUTH_SUCCESS';
export const GENERATE_AUTH_ERROR = 'GENERATE_AUTH_ERROR';

export const SET_TYPE_AUTH_REQUESTING = 'SET_TYPE_AUTH_REQUESTING';
export const SET_TYPE_AUTH_SUCCESS = 'SET_TYPE_AUTH_SUCCESS';
export const SET_TYPE_AUTH_ERROR = 'SET_TYPE_AUTH_ERROR';

export const CHANGE_PASS_REQUESTING = 'CHANGE_PASS_REQUESTING';
export const CHANGE_PASS_SUCCESS = 'CHANGE_PASS_SUCCESS';
export const CHANGE_PASS_ERROR = 'CHANGE_PASS_ERROR';
export const CHANGE_PASS_RESET = 'CHANGE_PASS_RESET';

export const CHANGE_TELPIN_REQUESTING = 'CHANGE_TELPIN_REQUESTING';
export const CHANGE_TELPIN_SUCCESS = 'CHANGE_TELPIN_SUCCESS';
export const CHANGE_TELPIN_ERROR = 'CHANGE_TELPIN_ERROR';
export const CHANGE_TELPIN_RESET = 'CHANGE_TELPIN_RESET';

export const CHANGE_USER_REQUESTING = 'CHANGE_USER_REQUESTING';
export const CHANGE_USER_SUCCESS = 'CHANGE_USER_SUCCESS';
export const CHANGE_USER_ERROR = 'CHANGE_USER_ERROR';
export const CHANGE_USER_RESET = 'CHANGE_USER_RESET';

export const CHANGE_BANK_REQUESTING = 'CHANGE_BANK_REQUESTING';
export const CHANGE_BANK_SUCCESS = 'CHANGE_BANK_SUCCESS';
export const CHANGE_BANK_ERROR = 'CHANGE_BANK_ERROR';
export const CHANGE_BANK_RESET = 'CHANGE_BANK_RESET';

export const ONE_TIME_TOKEN_REQUESTING = 'ONE_TIME_TOKEN_REQUEST';
export const ONE_TIME_TOKEN_SUCCESS = 'ONE_TIME_TOKEN_SUCCESS';
export const ONE_TIME_TOKEN_ERROR = 'ONE_TIME_TOKEN_ERROR';

export const LOCK_RESENT_OTP = 'LOCK_RESENT_OTP';
export const UNLOCK_RESENT_OTP = 'UNLOCK_RESENT_OTP';

export const LOCK_REFRESH_MATRIX = 'LOCK_REFRESH_MATRIX';
export const UNLOCK_REFRESH_MATRIX = 'UNLOCK_REFRESH_MATRIX';
