import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import PerfectScrollBar from 'react-perfect-scrollbar';
import { Col, Card, Alert, Button } from 'react-bootstrap';
import {
  makeGetToken,
  makeGetListAccount,
  makeGetDefaultAccount,
  makeGetStockSource,
  makeGetStockTarget,
  makeGetAuth,
} from '../../lib/selector';

import {
  stockSourceRequest,
  stockTargetRequest,
  stockExchangeRequest,
  stockExchangeHisRequest,
} from '../../containers/cash/actions';

import { filter, sumBy, isEqual, some } from 'lodash';

import SelectUser from '../select/selectUserCash';
import InputMask from '../input';
import {
  numberFormat,
  StringToInt,
  formatDate,
  _formatDate,
  getStockTransferStatus,
} from '../../util';
import { v4 as uuidv4 } from 'uuid';
import FormHisStockEx from './form/formHisStockExchange';

import PaginationTable from '../PaginationTable';

import { ReloadButton } from '../btnReload';
import { setAuth, unsetClient } from '../../containers/client/actions';

import { translate } from 'react-i18next';
import { removeCookie } from '../../lib/storages';
import { Logout, logoutRequest } from '../modal/login/actions';

const waitFor = (ms) => new Promise((r) => setTimeout(r, ms));
const asyncForEach = async (array, callback) => {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
};

class ChuyenCoPhieu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      listSource: [],
      listTarget: [],
      _defSource: {},
      _defTarget: {},
      _stockSource: [],
      _count: 0,
      msgSuccess: [],
      msgError: [],
    };
  }

  componentDidMount() {
    const { listAccount, defaultAccount } = this.props;
    if (listAccount && defaultAccount) {
      const _listSource = filter(listAccount, (o) => o.subAcntStat === 1);
      const listTarget = filter(
        listAccount,
        (o) => o.subAcntNo !== defaultAccount.subAcntNo && o.subAcntStat === 1
      );
      this.setState(
        {
          listTarget,
          _defTarget:
            (listTarget && !!listTarget.length && listTarget[0]) || {},
          _defSource: defaultAccount,
          listSource: _listSource,
        },
        () => {
          this._handleLoadDataSource();
          this._handleLoadDataTarget();
          this._handleGetExchangeHis();
        }
      );
    }
  }

  componentDidUpdate(preProps) {
    const { listAccount, defaultAccount, stockSource, stockExchange, token } =
      this.props;

    if (listAccount && !isEqual(listAccount, preProps.listAccount)) {
      const _listSource = filter(listAccount, (o) => o.subAcntStat === 1);
      const _defSource = defaultAccount || _listSource[0];
      const listTarget = filter(
        listAccount,
        (o) => o.subAcntNo !== _defSource.subAcntNo && o.subAcntStat === 1
      );

      this.setState(
        {
          listTarget,
          _defTarget:
            (listTarget && !!listTarget.length && listTarget[0]) || {},
          listSource: _listSource,
          _defSource,
        },
        () => {
          this._handleLoadDataSource();
          this._handleLoadDataTarget();
          this._handleGetExchangeHis();
        }
      );
    }

    if (stockSource && !isEqual(stockSource, preProps.stockSource)) {
      console.log('stockSource', stockSource);
      let _stockSource = [];

      if (stockSource.list) {
        stockSource.list.forEach((element) => {
          const _stock = {
            symbol: element.symbol,
            quantity: element.quantity,
            amount: '',
            content: '',
          };
          _stockSource.push(_stock);
        });
        console.log(_stockSource);
      }
      this.setState({ _stockSource });
    }
    if (stockExchange && !isEqual(stockExchange, preProps.stockExchange)) {
      console.log(stockExchange);

      // let _msg;
      if (stockExchange.http !== 200) {
        if (stockExchange.http === 401) {
          if (token) this.props.dispatch(logoutRequest(token));

          this.props.dispatch(unsetClient());
          removeCookie('token');
          removeCookie('authen');

          this.props.dispatch(Logout());
        }
        this.setState((prevState) => ({
          msgError: [...prevState.msgError, ...[stockExchange.symbol]],
        }));
      } else {
        this.setState((prevState) => ({
          msgSuccess: [...prevState.msgSuccess, ...[stockExchange.symbol]],
        }));
      }
    }
  }

  _handleLoadDataSource = () => {
    const { _defSource } = this.state;
    const { token } = this.props;
    if (!_defSource) return;

    const uuid = uuidv4();
    const resData = {
      group: 'CORE',
      user: token.user,
      session: token.session,
      cmd: 'getAvailStockList',
      rqId: uuid,
      channel: 'WTS',
      data: {
        acntNo: _defSource.acntNo,
        subAcntNo: _defSource.subAcntNo,
      },
    };

    this.props.dispatch(stockSourceRequest(resData));
  };

  _handleLoadDataTarget = () => {
    const { _defTarget } = this.state;
    const { token } = this.props;
    if (!_defTarget) return;

    const uuid = uuidv4();
    const resData = {
      group: 'CORE',
      user: token.user,
      session: token.session,
      cmd: 'getAvailStockList',
      rqId: uuid,
      channel: 'WTS',
      data: {
        acntNo: _defTarget.acntNo,
        subAcntNo: _defTarget.subAcntNo,
      },
    };

    this.props.dispatch(stockTargetRequest(resData));
  };

  _handleChangeUserSource = (user) => {
    // console.log(user)
    const { listAccount } = this.props;
    const _listSource = filter(listAccount, (o) => o.subAcntStat === 1);
    const listTarget = filter(
      listAccount,
      (o) => o.subAcntNo !== user.subAcntNo && o.subAcntStat === 1
    );

    this.setState(
      {
        listTarget,
        _defTarget: (listTarget && !!listTarget.length && listTarget[0]) || {},
        listSource: _listSource,
        _defSource: user,
      },
      () => {
        this._handleLoadDataSource();
        this._handleLoadDataTarget();
        this._handleGetExchangeHis();
      }
    );
  };

  _handleChangeUserTarget = (user) => {
    // console.log(user)
    this.setState({ _defTarget: user }, () => {
      this._handleLoadDataTarget();
    });
  };

  _handleBlur = (name) => {
    console.log(name);
    let { _stockSource } = this.state;

    _stockSource.map((item) => {
      if (item.symbol !== name) return item;

      item.err =
        StringToInt(item.amount) > item.quantity
          ? `${this.props.t('txt-title-max')}: ${numberFormat(item.quantity)}`
          : '';
      return item;
    });
    this.setState({ _stockSource });
  };

  _handleChange = (e) => {
    const { value, name } = e.target;
    console.log(value, name);
    let { _stockSource, _defTarget, _defSource } = this.state;
    if (!_defTarget) return;
    console.log(_defSource);
    _stockSource.map((item) => {
      if (item.symbol !== name) {
        if (_defSource.subAcntNo.startsWith('M')) {
          // margin
          item.disabled = !!value;
        }
        return item;
      }

      item.amount = value;
      item.err = '';
      item.disabled = false;
      if (!item.content)
        item.content =
          this.props.t('txt-label-transfer-to') + ' ' + _defTarget?.subAcntNo ??
          '';
      console.log(item);
      return item;
    });
    // console.log(_stockSource);
    this.setState({ _stockSource });
  };

  _handleSubmit = async () => {
    const { _stockSource } = this.state;
    this.setState({ msgSuccess: [], msgError: [], _count: 0 });
    console.log(_stockSource);
    const _this = this;

    if (some(_stockSource, (o) => o.err && o.err.length > 0)) return;

    await asyncForEach(_stockSource, async (element) => {
      console.log(element, new Date().getTime());
      if (StringToInt(element.amount) > 0 && !element.err) {
        await waitFor(200);
        this.setState((prevState) => ({ _count: prevState._count + 1 }));
        _this._handlePost(element);
      }
    });

    await waitFor(200);

    _this.setState({ _stockSource: [] });
    _this._handleLoadDataSource();
    _this._handleLoadDataTarget();
    _this._handleGetExchangeHis();

    setTimeout(() => {
      this.setState({
        msgSuccess: [],
        msgError: [],
      });
    }, 4800);
  };

  _handleClear = () => {
    let { _stockSource } = this.state;

    _stockSource.map((item) => {
      item.amount = '';
      item.content = '';
      item.err = '';
      item.disabled = false;
      return item;
    });
    this.setState({ _stockSource });
  };

  _handlePost = (item) => {
    console.log('_handlePost', item, new Date().getTime());
    const { token, auth } = this.props;
    const { _defSource, _defTarget } = this.state;
    if (!item || !_defTarget) return;

    const uuid = uuidv4();
    const resData = {
      group: 'CORE',
      user: token.user,
      session: token.session,
      cmd: 'StockTransferInternal',
      rqId: uuid,
      channel: 'WTS',
      type: auth.type,
      token: auth.token,
      data: {
        acntNo: _defSource.acntNo,
        subAcntNo: _defSource.subAcntNo,
        symbol: item.symbol,
        trnsBalTp: '01',
        qty: StringToInt(item.amount) + '',
        toSubAcntNo: _defTarget.subAcntNo,
        desc: item.content,
      },
    };

    this.props.dispatch(stockExchangeRequest(resData));
  };

  _handleGetExchangeHis = (fromDate, endDate) => {
    const { token } = this.props;
    const { _defSource } = this.state;

    const d = new Date();
    d.setDate(d.getDate() - 30);

    if (!fromDate) fromDate = formatDate(d, '');
    else fromDate = _formatDate(fromDate);

    if (!endDate) endDate = formatDate(new Date(), '');
    else endDate = _formatDate(endDate);

    const uuid = uuidv4();
    const resData = {
      group: 'CORE',
      user: token.user,
      session: token.session,
      cmd: 'getStockTransferHist',
      rqId: uuid,
      channel: 'WTS',
      data: {
        acntNo: _defSource.acntNo,
        subAcntNo: _defSource.subAcntNo,
        fromDate: fromDate,
        toDate: endDate,
      },
    };

    this.props.dispatch(stockExchangeHisRequest(resData));
  };

  _handleAuthen = () => {
    this.props.dispatch(setAuth());
  };

  render() {
    const {
      listTarget,
      listSource,
      _defSource,
      _defTarget,
      _stockSource,
      // msg,
      msgSuccess,
      msgError,
      _count,
    } = this.state;
    const { stockTarget, stockSource, exHis, auth, t } = this.props;
    const _targetQuantity =
      stockTarget && stockTarget.list ? sumBy(stockTarget.list, 'quantity') : 0;
    const _sourceQuantity =
      stockSource && stockSource.list ? sumBy(stockSource.list, 'quantity') : 0;

    const _sourceAmount = _stockSource
      ? sumBy(_stockSource, (o) => StringToInt(o.amount))
      : 0;

    const _chkErr = some(_stockSource, (o) => o.err && o.err.length > 0);

    const columns = [
      {
        key: 'defaultAccount',
        text: t('txt-label-source'),
        className: 'text-center',
        align: 'center',
        cell: (record) => {
          return <span>{record && record.subAcntNo}</span>;
        },
      },
      {
        key: 'toSubAcntNo',
        text: t('txt-label-target'),
        className: 'text-center',
        align: 'center',
      },
      {
        key: 'symbol',
        text: t('txt-symbol'),
        className: 'text-center fw-500',
        align: 'center',
      },
      {
        key: 'quantity',
        text: t('txt-vol'),
        className: 'text-right',
        align: 'center',
        cell: (record) => {
          return numberFormat(record.quantity, 0, '0');
        },
      },
      {
        key: 'desc',
        text: t('txt-label-remark'),
        className: 'text-left',
        align: 'center',
      },
      {
        key: 'status',
        text: t('txt-status'),
        className: (record) => {
          return (
            'text-left ' +
            (record.status === '1' || record.status === '0' ? 'i' : 'd')
          );
        },
        align: 'center',
        cell: (record) => {
          return getStockTransferStatus(record.status);
        },
      },
      {
        key: 'trdDt',
        text: t('txt-label-create-date'),
        className: 'text-center',
        align: 'center',
      },
    ];

    return (
      <Fragment>
        <div className="d-flex flex-row mt-3 mb-2">
          <span
            className="mr-auto ml-2 text-white"
            style={{ fontSize: '18px' }}
          >
            {t('txt-label-stock-transfer')}
          </span>
        </div>
        <PerfectScrollBar>
          <div className="d-flex flex-row">
            <Col className="d-flex flex-column pr-0">
              <Card>
                <Card.Body style={{ borderRadius: '5px' }}>
                  <div className="d-flex align-items-center justify-content-between p-3">
                    <div className="d-flex align-items-center">
                      <label className="text-content fz_14">
                        {t('txt-label-source')}
                      </label>
                      {listSource && !!listSource.length && (
                        <SelectUser
                          defVal={_defSource}
                          grOptions={listSource}
                          handleChangeUser={this._handleChangeUserSource}
                        />
                      )}
                      <ReloadButton
                        type="button"
                        className="ml-2 btn-reload"
                        onClick={this._handleLoadDataSource}
                      />
                    </div>
                    <span className="text-content fz_14">{`${t(
                      'txt-label-stock-holding'
                    )} ${
                      stockSource && stockSource.list
                        ? stockSource.list.length
                        : ''
                    }`}</span>
                    <span className="text-content fz_14">{`${t(
                      'txt-label-vol-holding'
                    )} ${numberFormat(_sourceQuantity, 0, '0')}`}</span>
                  </div>
                </Card.Body>
              </Card>
              <PerfectScrollBar style={{ maxHeight: '200px' }}>
                <table className="table table-bordered mt-2">
                  <thead>
                    <tr>
                      <th className="text-left">{t('txt-symbol')}</th>
                      <th className="text-center">
                        {t('txt-label-vol-holding')}
                      </th>
                      <th className="text-center" style={{ width: '200px' }}>
                        {t('txt-vol')}
                      </th>
                      <th className="text-left">{t('txt-actions')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {_stockSource &&
                      !!_stockSource.length &&
                      _stockSource.map((item, index) => (
                        <tr key={'target_' + index}>
                          <td className="text-left">{item.symbol}</td>
                          <td className="text-right">
                            {numberFormat(item.quantity, 0, '0')}
                          </td>
                          <td className="text-right">
                            <InputMask
                              maxVal={item.quantity}
                              name={item.symbol}
                              value={item.amount}
                              disabled={item.disabled}
                              handleBlur={this._handleBlur}
                              handleChange={this._handleChange}
                            />
                            {item.err && (
                              <div
                                style={{
                                  color: 'rgb(255, 85, 85)',
                                  fontSize: '0.75rem',
                                }}
                              >
                                {item.err}
                              </div>
                            )}
                          </td>
                          <td className="text-right">
                            {item.amount ? item.content : ''}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </PerfectScrollBar>
              <div className="mt-2 d-flex justify-content-end">
                {auth && auth.isVerified ? (
                  <Fragment>
                    <Button
                      variant="light"
                      className="fz_14 fw-500 mr-2"
                      onClick={() => this._handleClear()}
                    >
                      {t('txt-cancel')}
                    </Button>
                    <Button
                      variant="secondary"
                      disabled={
                        !_sourceAmount || _sourceAmount === 0 || _chkErr
                      }
                      onClick={() => this._handleSubmit()}
                      className="fz_14 fw-500"
                    >
                      {`${t('txt-move')} ${
                        _sourceAmount > 0
                          ? _sourceAmount + ' CP sang ' + _defTarget.subAcntNo
                          : ''
                      }`}
                    </Button>
                  </Fragment>
                ) : (
                  <Button
                    variant="warning"
                    className="fz_14 fw-500 text-white w-140"
                    onClick={() => this._handleAuthen()}
                  >
                    {t('txt-xacthuc')}
                  </Button>
                )}
              </div>
            </Col>
            <Col className="d-flex flex-column">
              <Card>
                <Card.Body style={{ borderRadius: '5px' }}>
                  <div className="d-flex align-items-center justify-content-between p-3">
                    <div className="d-flex align-items-center">
                      <label className="text-content fz_14">
                        {t('txt-label-target')}
                      </label>
                      {listTarget && !!listTarget.length && (
                        <SelectUser
                          defVal={_defTarget}
                          grOptions={listTarget}
                          handleChangeUser={this._handleChangeUserTarget}
                        />
                      )}
                      <ReloadButton
                        type="button"
                        className="ml-2 btn-reload"
                        onClick={this._handleLoadDataTarget}
                      />
                    </div>
                    <span className="text-content fz_14">{`${t(
                      'txt-label-stock-holding'
                    )} ${
                      stockTarget && stockTarget.list
                        ? stockTarget.list.length
                        : ''
                    }`}</span>
                    <span className="text-content fz_14">{`${t(
                      'txt-label-vol-holding'
                    )} ${numberFormat(_targetQuantity, 0, '0')}`}</span>
                  </div>
                </Card.Body>
              </Card>
              {msgError && !!msgError.length && (
                <Alert variant="danger" className="mt-2 mb-0">
                  {_count !== msgSuccess.length
                    ? `${t('txt-move')} ${
                        '{ ' + msgError.join(', ') + ' }'
                      } ${t('txt-unsuccessful')}`
                    : t('txt-title-transfer-stock-fail')}
                </Alert>
              )}
              {msgSuccess && !!msgSuccess.length && (
                <Alert variant="success" className="mt-2 mb-0">
                  {_count !== msgSuccess.length
                    ? `${t('txt-moved')} ${
                        '{ ' + msgSuccess.join(', ') + ' }'
                      } ${t('txt-success')}`
                    : t('txt-title-transfer-success')}
                </Alert>
              )}
              <PerfectScrollBar style={{ height: '150px' }}>
                <table className="table table-bordered mt-2">
                  <thead>
                    <tr>
                      <th className="text-left">{t('txt-symbol')}</th>
                      <th className="text-right">
                        {t('txt-label-vol-holding')}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {stockTarget &&
                      stockTarget.list &&
                      !!stockTarget.list.length &&
                      stockTarget.list.map((item, index) => (
                        <tr key={'target_' + index}>
                          <td className="text-left">{item.symbol}</td>
                          <td className="text-right">
                            {numberFormat(item.quantity, 0, '0')}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </PerfectScrollBar>
            </Col>
          </div>
          <div className="d-flex justify-content-between mt-3">
            <label className="text-white text-uppercase ml-3">
              {t('txt-label-history')}
            </label>
            <FormHisStockEx handleQuery={this._handleGetExchangeHis} />
          </div>
          <PaginationTable
            defaultAccount={_defSource}
            pageSize={15}
            columns={columns}
            source={exHis && exHis.list ? exHis.list : []}
          />
        </PerfectScrollBar>
      </Fragment>
    );
  }
}

const makeMapStateToProps = () => {
  const getToken = makeGetToken();
  const getAuth = makeGetAuth();
  const getListAccount = makeGetListAccount();
  const getDefaultAccount = makeGetDefaultAccount();
  const getStockSource = makeGetStockSource();
  const getStockTarget = makeGetStockTarget();

  const mapStateToProps = (state) => {
    return {
      token: getToken(state),
      auth: getAuth(state),
      listAccount: getListAccount(state),
      defaultAccount: getDefaultAccount(state),
      stockSource: getStockSource(state),
      stockTarget: getStockTarget(state),
      stockExchange: state.cash.stockExchange,
      exHis: state.cash.exHis,
    };
  };
  return mapStateToProps;
};

export default connect(makeMapStateToProps)(
  translate('translations')(ChuyenCoPhieu)
);
