import React from 'react';
import * as FileSaver from 'file-saver';
import * as Excel from 'exceljs';

import {
  mapOrderChanel,
  numberFormat,
  _convertTpToPrice,
  _formatDate2,
  _getOrderStatus,
} from '../../../../util';

import { translate } from 'react-i18next';
import { Mixpanel } from '../../../../lib/mixpanel';

function nextColumn(current) {
  let alphabet = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
  ];
  return alphabet[alphabet.indexOf(current) + 1];
}

const ExportOrderStatement = ({
  csvData,
  fileName,
  fromDate,
  toDate,
  accountCode,
  accountName,
  symbol,
  typeOrder,
  t,
}) => {
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const headers = [
    t('txt-stt'),
    t('txt-label-trade-date'),
    t('txt-label-tran-date'),
    t('txt-trade-acronym'),
    t('txt-symbol'),
    t('txt-vol-order'),
    t('txt-price-order'),
    t('txt-match-vol'),
    t('txt-dividend-match-vol'),
    t('txt-match-price'),
    t('txt-cancel-vol'),
    t('txt-total-match-value'),
    t('txt-label-fee'),
    t('txt-label-sell-fee'),
    t('txt-label-tax'),
    t('txt-label-dividend-tax'),
    t('txt-label-real-amount'),
    t('txt-status'),
    t('txt-order-type'),
    t('txt-order-no'),
    t('txt-label-channel'),
  ];
  const border = {
    top: { style: 'thin' },
    left: { style: 'thin' },
    bottom: { style: 'thin' },
    right: { style: 'thin' },
  };
  const font = { size: 11, bold: true, name: 'Times New Roman' };
  const font13 = { size: 13, bold: true, name: 'Times New Roman' };

  const exportToCSV = async (
    csvData,
    fileName,
    fromDate,
    toDate,
    accountCode,
    accountName,
    symbol,
    typeOrder
  ) => {
    const workbook = new Excel.Workbook();
    workbook.creator = 'Pinetree trading web';
    workbook.lastModifiedBy = 'Pinetree trading web';
    workbook.created = new Date();
    workbook.modified = new Date();

    const worksheet = workbook.addWorksheet(t('txt-label-order-statement'), {
      pageSetup: { paperSize: 9, orientation: 'landscape' },
    });

    worksheet.getCell('A1').value = t('lbl-company');
    worksheet.getCell('A1').font = font13;
    worksheet.getCell('A2').value = t('lbl-company-address');
    worksheet.getCell('A3').value = t('lbl-company-contact');

    worksheet.mergeCells('A5:U5');
    worksheet.getCell('A5').value = t('txt-label-order-statement');
    worksheet.getCell('A5').font = {
      size: 14,
      bold: true,
      name: 'Times New Roman',
    };
    worksheet.getCell('A5').alignment = {
      vertical: 'middle',
      horizontal: 'center',
    };

    worksheet.mergeCells('A6:U6');
    worksheet.getCell('A6').value = `${t('txt-label-from')} ${fromDate} ${t(
      'txt-label-to'
    )} ${toDate}`;
    worksheet.getCell('A6').alignment = {
      vertical: 'middle',
      horizontal: 'center',
    };

    worksheet.getCell('A7').value = t('txt-account');
    worksheet.getCell('B7').value = accountCode;
    worksheet.getCell('B7').font = font13;
    worksheet.getCell('L7').value = t('txt-symbol');
    worksheet.getCell('M7').value = symbol;
    worksheet.getCell('M7').font = font13;

    worksheet.getCell('A8').value = t('txt-label-vsd-no');
    worksheet.getCell('B8').value = accountName;
    worksheet.getCell('B8').font = font13;
    worksheet.getCell('L8').value = `${t('txt-buy')}/${t('txt-sell')}`;
    worksheet.getCell('M8').value = typeOrder;
    worksheet.getCell('M8').font = font13;

    // worksheet.addRow({});

    let startCol = 'A';
    let col = startCol.toUpperCase();
    let rowNum = 10;

    let len = headers.length;
    for (let i = 0; i < len; i++) {
      let cell = worksheet.getCell(col + rowNum);
      cell.value = headers[i];
      cell.border = border;
      cell.font = font;
      cell.alignment = { horizontal: 'center' };
      cell.fill = {
        type: 'pattern',
        pattern: 'darkTrellis',
        fgColor: { argb: 'FF7ABBFF' },
        bgColor: { argb: 'FF7ABBFF' },
      };
      col = nextColumn(col);
    }

    let _arr = [],
      sumMatchVal = 0,
      sumMatchVol = 0,
      sumFee = 0,
      sumSellFee = 0,
      sumTax = 0,
      sumDivdDltx = 0,
      sumGTTT = 0;
    // console.log(csvData)
    csvData.forEach((element, index) => {
      const GTTT =
        element.buySelTp === '1'
          ? element.matchedQty * element.matchedPrc + element.matchedFee
          : element.matchedQty * element.matchedPrc -
            element.matchedTax -
            element.matchedFee -
            element.divdDltx -
            element.selFee;
      _arr.push({
        A: index + 1,
        B: element.wrkTime,
        C: _formatDate2(element.trdDate),
        D: element.buySelTp === '1' ? t('txt-buy') : t('txt-sell'),
        E: element.symbol,
        F: element.ordrQty,
        G: element.ordrUntprc,
        H: element.matchedQty,
        I: element.divdQty,
        J: element.matchedPrc,
        K: element.ordrRemnQty,
        L: element.matchedAmt,
        M: element.matchedFee,
        N: element.selFee,
        O: element.matchedTax,
        P: element.divdDltx,
        Q: GTTT,
        // O: Thuế cổ tức
        // P: GT thực tế
        R: _getOrderStatus(
          element.ordrStatTp,
          element.ordrQty,
          element.matchedQty
        ),
        S: _convertTpToPrice(element.ordrTrdTp),
        T: element.ordrNo,
        U: mapOrderChanel(element.ordrChnl),
      });
      sumMatchVal += element.matchedAmt;
      sumMatchVol += element.matchedQty;
      sumFee += element.matchedFee;
      sumTax += element.matchedTax;
      sumDivdDltx += element.divdDltx;
      sumGTTT += GTTT;
      sumSellFee += element.selFee;
    });

    _arr.forEach((element, index) => {
      rowNum++;
      col = startCol;
      // console.log(element)
      for (let key in element) {
        // console.log(key, element[key])
        let cell = worksheet.getCell(col + rowNum);
        cell.value = element[key];
        cell.border = border;
        if (
          ['A', 'F', 'G', 'H', 'K', 'L', 'M', 'I', 'J', 'O', 'Q'].indexOf(key) >
          -1
        ) {
          // cell.alignment = { vertical: 'middle', horizontal: 'right' };
          cell.numFmt = '#,##0';
        }
        if (['B', 'C', 'D', 'E', 'R', 'S', 'U'].indexOf(key) > -1) {
          cell.alignment = { vertical: 'middle', horizontal: 'left' };
        }
        col = nextColumn(col);
      }
    });

    rowNum++;
    const _arrCol = [
      'A',
      'B',
      'C',
      'D',
      'E',
      'F',
      'G',
      'H',
      'I',
      'J',
      'K',
      'L',
      'M',
      'N',
      'O',
      'P',
      'Q',
      'R',
      'S',
      'T',
      'U',
    ];
    _arrCol.forEach((element) => {
      let cell = worksheet.getCell(element + rowNum);
      cell.border = border;
      cell.font = font13;
      if (element === 'A') cell.value = t('txt-label-total');
      if (element === 'H') {
        cell.numFmt = '#,##0';
        cell.value = sumMatchVol;
        cell.alignment = { vertical: 'middle', horizontal: 'right' };
      }
      if (element === 'L') {
        cell.numFmt = '#,##0';
        cell.value = sumMatchVal;
        cell.alignment = { vertical: 'middle', horizontal: 'right' };
      }
      if (element === 'M') {
        cell.numFmt = '#,##0';
        cell.value = sumFee;
        cell.alignment = { vertical: 'middle', horizontal: 'right' };
      }
      if (element === 'N') {
        cell.numFmt = '#,##0';
        cell.value = sumSellFee;
        cell.alignment = { vertical: 'middle', horizontal: 'right' };
      }
      if (element === 'O') {
        cell.numFmt = '#,##0';
        cell.value = sumTax;
        cell.alignment = { vertical: 'middle', horizontal: 'right' };
      }
      if (element === 'P') {
        cell.value = sumDivdDltx;
        cell.numFmt = '#,##0';
      }
      if (element === 'Q') {
        cell.value = sumGTTT;
        cell.numFmt = '#,##0';
      }
    });

    for (let i = 0; i < worksheet.columns.length; i += 1) {
      let dataMax = 0;
      const column = worksheet.columns[i];
      if (column) {
        for (let j = 10; j < column.values.length; j += 1) {
          const columnLength = column.values[j] ? column.values[j].length : 0;
          if (columnLength > dataMax) {
            dataMax = columnLength;
          }
        }
        column.width = (dataMax < 10 ? 10 : dataMax) + 2;
      }
    }

    // save file
    const excelBuffer = await workbook.xlsx.writeBuffer({ useStyles: true });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <div
      className="text-white cursor-pointer"
      onClick={(e) => {
        Mixpanel.track('Export Statement', {
          'Statement Type': 'Order Statement',
        });
        exportToCSV(
          csvData,
          fileName,
          fromDate,
          toDate,
          accountCode,
          accountName,
          symbol,
          typeOrder
        );
      }}
    >
      <div className="btn-icon btn-icon--light cursor-pointer mr-1">
        <span className="icon iDownload"></span>
        <span className="fw-500">{t('txt-export-excel')}</span>
      </div>
    </div>
  );
};

export default translate('translations')(ExportOrderStatement);
