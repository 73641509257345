import React from 'react';
import { numberFormat, formatVolume10, StringToInt_2 } from '../util';
import { constants } from './constant';

const processG = (sym, data, idPrice, idVol) => {
  if (!sym) return;
  let _rootPrice = document.getElementById(sym + idPrice);
  let _rootVol = document.getElementById(sym + idVol);

  if (!_rootPrice) return;

  const _g = data.split('|');

  const _oldVol = StringToInt_2(_rootVol?.children[0]?.innerHTML);

  const price = _g[0];
  const vol = StringToInt_2(_g[1]);
  const clp = _g[0] === 'ATO' || _g[0] === 'ATC' ? '' : _g[2];
  const clv = _oldVol < vol ? 'i' : _oldVol > vol ? 'd' : clp;

  const _oldPrice = _rootPrice?.children[0]?.innerHTML;
  const _newPrice = numberFormat(price, 2);

  if (_oldPrice !== _newPrice) {
    _rootPrice.classList.remove('d', 'e', 'i', 'r', 'c', 'f');
    if (_rootPrice.children[0]) _rootPrice.children[0].innerHTML = _newPrice;

    if (clp) _rootPrice.classList.add(clp, 'active');
  }

  if (_oldVol !== vol) {
    const _newVol = formatVolume10(_g[1]); // Todo: Lỗi không nhận realtime khối lượng lẻ là ở đây
    _rootVol.classList.remove('d', 'e', 'i', 'r', 'c', 'f');
    if (_rootVol.children[0]) _rootVol.children[0].innerHTML = _newVol;

    if (clv) _rootVol.classList.add(clv, 'active');
  }

  setTimeout(() => {
    if (clp) _rootPrice.classList.remove('active');
    if (clv) {
      _rootVol.classList.remove('d', 'e', 'i', 'r', 'c', 'f', 'active');
      if (clp) _rootVol.classList.add(clp);
    }
  }, 500);
};

const process3210 = (data) => {
  process3210_trade(data);
  process3210_detail(data);
  process3210_pf(data);
  const priceboard_oddLot = document.getElementById('priceboard_oddlot');
  const data_oddLot = data?.brd == constants.G.ODD_LOT ? 'true' : 'false';
  if (priceboard_oddLot == undefined) return;
  if (priceboard_oddLot?.value != data_oddLot) return;

  if (data.side === 'B') {
    processG(data.sym, data.g1, 'bP1', 'bV1');
    processG(data.sym, data.g2, 'bP2', 'bV2');
    processG(data.sym, data.g3, 'bP3', 'bV3');
  } else {
    processG(data.sym, data.g1, 'oP1', 'oV1');
    processG(data.sym, data.g2, 'oP2', 'oV2');
    processG(data.sym, data.g3, 'oP3', 'oV3');
  }
};

// Keep 6 price step
const process3220 = (data) => {
  process3220_trade(data);
  const priceboard_oddLot = document.getElementById('priceboard_oddlot');
  const data_oddLot = data?.brd == constants.G.ODD_LOT ? 'true' : 'false';
  if (priceboard_oddLot == undefined) return;
  if (priceboard_oddLot?.value != data_oddLot) return;

  let _rootLastPrice = document.getElementById(data.sym + 'lastPrice');
  let _rootLastVol = document.getElementById(data.sym + 'lastVolume');
  let _rootChange = document.getElementById(data.sym + 'change');
  let _rootChangePc = document.getElementById(data.sym + 'changePc');
  let _rootLot = document.getElementById(data.sym + 'lot');
  let _rootHighP = document.getElementById(data.sym + 'highP');
  let _rootLowP = document.getElementById(data.sym + 'lowP');
  let _rootAveP = document.getElementById(data.sym + 'aveP');
  let _rootSym = document.getElementById(data.sym + 'sym');

  if (!_rootSym) return;
  const _oldPrice = _rootLastPrice.children[0]?.innerHTML;
  const _newPrice = numberFormat(data.lastPrice, 2);
  const _oldVol = _rootLastVol.children[0]?.innerHTML;
  const _newVol = formatVolume10(data.lastVol);
  const _oldTotalVol = _rootLot?.innerHTML;
  const _newTotalVol = formatVolume10(data.totalVol);
  const _oldhp = _rootHighP?.innerHTML;
  const _newhp = numberFormat(data.hp, 2);

  const _oldlp = _rootLowP?.innerHTML;
  const _newlp = numberFormat(data.lp, 2);

  const _oldap = _rootAveP?.innerHTML;
  const _newap = numberFormat(data.ap, 2);

  if (_rootHighP) _rootHighP.classList.remove('d', 'e', 'i', 'r', 'c', 'f');
  if (_rootLowP) _rootLowP.classList.remove('d', 'e', 'i', 'r', 'c', 'f');
  if (_rootAveP) _rootAveP.classList.remove('d', 'e', 'i', 'r', 'c', 'f');
  if (_rootSym) _rootSym.classList.remove('d', 'e', 'i', 'r', 'c', 'f');
  if (_rootLot) _rootLot.classList.remove('i');

  if (_oldPrice !== _newPrice && _rootLastPrice) {
    _rootLastPrice.classList.remove('d', 'e', 'i', 'r', 'c', 'f');
    if (_rootLastPrice.children[0])
      _rootLastPrice.children[0].innerHTML = _newPrice;

    _rootLastPrice.classList.add(data.cl, 'active');
  }
  if (_oldVol !== _newVol && _rootLastVol) {
    _rootLastVol.classList.remove('d', 'e', 'i', 'r', 'c', 'f');
    if (_rootLastVol.children[0]) _rootLastVol.children[0].innerHTML = _newVol;
    _rootLastVol.classList.add(data.cl, 'active');
  }

  if (_oldTotalVol !== _newTotalVol && _rootLot) {
    _rootLot.classList.remove('i');
    _rootLot.innerHTML = _newTotalVol;
    _rootLot.classList.add('i', 'active');
  }

  if (_rootHighP) _rootHighP.innerHTML = numberFormat(data.hp, 2);
  if (_rootLowP) _rootLowP.innerHTML = numberFormat(data.lp, 2);
  if (_rootAveP) _rootAveP.innerHTML = numberFormat(data.ap, 2);

  if (_rootChange) {
    _rootChange.classList.remove('d', 'e', 'i', 'r', 'c', 'f');
    _rootChange.innerHTML =
      (data.cl === 'd' ? '-' : '') + numberFormat(data.change, 2);
    _rootChange.classList.add(data.cl);
  }
  if (_rootChangePc) {
    _rootChangePc.classList.remove('d', 'e', 'i', 'r', 'c', 'f');
    _rootChangePc.innerHTML =
      (data.cl === 'd' ? '-' : '') + numberFormat(data.changePc, 2);
    _rootChangePc.classList.add(data.cl);
  }

  if (_rootHighP) {
    if (_oldhp !== _newhp) _rootHighP.classList.add('active');
    _rootHighP.classList.add(data.ch);
  }
  if (_rootLowP) {
    if (_oldlp !== _newlp) _rootLowP.classList.add('active');
    _rootLowP.classList.add(data.lc);
  }
  if (_rootAveP) {
    if (_oldap !== _newap) _rootAveP.classList.add('active');
    _rootAveP.classList.add(data.ca);
  }

  if (_rootSym) _rootSym.classList.add(data.cl);

  const _bEPoint = document.getElementById(data.sym + '_bEPoint');
  const _leverage = document.getElementById(data.sym + '_leverage');

  if (_bEPoint) _bEPoint.innerHTML = numberFormat(data?.bEPoint, 2);
  if (_leverage) _leverage.innerHTML = numberFormat(data?.leverage, 2);

  setTimeout(() => {
    if (_rootLastPrice) _rootLastPrice.classList.remove('active');
    if (_rootLastVol) _rootLastVol.classList.remove('active');

    if (_rootHighP) _rootHighP.classList.remove('active');
    if (_rootLowP) _rootLowP.classList.remove('active');
    if (_rootAveP) _rootAveP.classList.remove('active');
    if (_rootLot) _rootLot.classList.remove('i', 'active');
  }, 500);
};

const process3250 = (data) => {
  let _rootFBVol = document.getElementById(data.symbol + 'fBVol');
  let _rootFSVol = document.getElementById(data.symbol + 'fSVol');

  const _oldFBVol = _rootFBVol?.innerHTML;
  const _newFBVol = formatVolume10(data.fBVol);

  const _oldFSVol = _rootFSVol?.innerHTML;
  const _newFSVol = formatVolume10(data.fSVolume);

  if (!_rootFBVol || !_rootFSVol) return;
  if (_oldFBVol !== _newFBVol) _rootFBVol.classList.add('i', 'active');
  _rootFBVol.innerHTML = _newFBVol;
  if (_oldFSVol !== _newFSVol) _rootFSVol.classList.add('i', 'active');
  _rootFSVol.innerHTML = _newFSVol;

  setTimeout(() => {
    _rootFBVol.classList.remove('i', 'active');
    _rootFSVol.classList.remove('i', 'active');
  }, 500);
};

const process3260 = (data) => {
  if (!data?.sym) return;
  const _uPrice = document.getElementById(data.sym + '_uPrice');
  const _uChange = document.getElementById(data.sym + '_uChange');
  const _leverage = document.getElementById(data.sym + '_leverage');

  if (_uPrice) {
    _uPrice.innerHTML = numberFormat(data?.uPrice, 2, '');
    _uPrice.classList.remove('d', 'e', 'i', 'r', 'c', 'f', 'active');
    _uPrice.classList.add(data?.uColor || '');
  }

  if (_uChange) {
    _uChange.innerHTML =
      (data?.uColor == 'd' ? '-' : '') + numberFormat(data?.uChange, 2);
    _uChange.classList.remove('d', 'e', 'i', 'r', 'c', 'f', 'active');
    _uChange.classList.add(data?.uColor || '');
  }

  if (_leverage) _leverage.innerHTML = numberFormat(data?.leverage, 2);
};

// for trade panel and matched in priceboard
const process3220_trade = (data) => {
  // const trade_oddLot = document.getElementById('trade_oddLot');

  const trade_G = document.getElementById('trade_G'); // orderG
  const trade_sym = document.getElementById('trade_sym');
  const data_G = data?.brd; // G1 || G3 || G4 || G7

  if (trade_G == undefined) return;
  if (data?.sym != trade_sym?.value) return;
  if (data_G != trade_G?.value) return;

  let lastPriceDetail = document.getElementById(
    'trade_' + data.sym + '_lastPrice'
  );

  if (!lastPriceDetail) return;

  let changeDetail = document.getElementById('trade_' + data.sym + '_change');
  let changePcDetail = document.getElementById(
    'trade_' + data.sym + '_changePc'
  );

  const _oldPrice = lastPriceDetail.innerHTML;
  const _newPrice = numberFormat(data.lastPrice, 2);

  if (_oldPrice !== _newPrice && lastPriceDetail) {
    lastPriceDetail.classList.remove('d', 'e', 'i', 'r', 'c', 'f');
    if (lastPriceDetail.innerHTML) lastPriceDetail.innerHTML = _newPrice;
    lastPriceDetail.classList.add(data.cl, 'active');
  }

  if (changeDetail) {
    changeDetail.classList.remove('d', 'e', 'i', 'r', 'c', 'f');
    changeDetail.innerHTML =
      (data.cl === 'd' ? '-' : '') + numberFormat(data.change, 2);
    changeDetail.classList.add(data.cl);
  }

  if (changePcDetail) {
    changePcDetail.classList.remove('d', 'e', 'i', 'r', 'c', 'f');
    changePcDetail.innerHTML =
      (data.cl === 'd' ? '-' : '') + numberFormat(data.changePc, 2) + '%';
    changePcDetail.classList.add(data.cl);
  }

  setTimeout(() => {
    if (lastPriceDetail) lastPriceDetail.classList.remove('active');
  }, 500);
};

// for price list with 10 price step
const process3210_extend = (prefix, data) => {
  if (data.sym == 'GVR') debugger;
  let _rootPrice = document.getElementById(`${prefix}price-list`);
  if (!_rootPrice) return;

  let totalSideVol = 0, // total vol of the current side not both side
    g = {};
  //    example for g {
  //    v1: 1,
  //    perV1: .5,
  //    v2: 2,
  //    perV2: .5,
  //    v3: 3
  //    perV3: .5,
  // }

  Array.from({ length: 10 }, (_, i) => {
    if (data[`g${i + 1}`]) {
      const _g = data[`g${i + 1}`].split('|');
      const vol = StringToInt_2(_g[1]);
      g[`v${i + 1}`] = vol;
      totalSideVol += vol;

      if (data.side === 'B')
        processG_extend(prefix + data.sym, _g, `_bP${i + 1}`, `_bV${i + 1}`);
      else processG_extend(prefix + data.sym, _g, `_sP${i + 1}`, `_sV${i + 1}`);
    }
  });

  Array.from({ length: 10 }, (_, i) => {
    const _v = g[`v${i + 1}`];
    if (_v) {
      const _perV = ~~((_v * 100) / totalSideVol);

      // update DOM
      let sideName = '';
      if (data.side === 'B') sideName = `_perBV${i + 1}`;
      else sideName = `_perSV${i + 1}`;
      const target = document.getElementById(`${prefix}${data.sym}${sideName}`);
      if (target) target.style.width = _perV + '%';
    }
  });

  //#region TOTAL VOL PROCESSBAR
  let perTotalBV = 0;
  if (data.side == 'B') {
    // cal sell total vol via id of item dom
    const _v1 = document.getElementById(prefix + data.sym + '_sV1')?.innerHTML;
    const _v2 = document.getElementById(prefix + data.sym + '_sV2')?.innerHTML;
    const _v3 = document.getElementById(prefix + data.sym + '_sV3')?.innerHTML;
    const totalRestV =
      StringToInt_2(_v1) + StringToInt_2(_v2) + StringToInt_2(_v3); //

    const totalVol = totalRestV + totalSideVol;
    perTotalBV = ~~((totalSideVol * 100) / totalVol).toFixed(2);
  } else {
    // need cal buy total vol via id of item
    const _v1 = document.getElementById(prefix + data.sym + '_bV1')?.innerHTML;
    const _v2 = document.getElementById(prefix + data.sym + '_bV2')?.innerHTML;
    const _v3 = document.getElementById(prefix + data.sym + '_bV3')?.innerHTML;
    const totalRestV =
      StringToInt_2(_v1) + StringToInt_2(_v2) + StringToInt_2(_v3); //

    const totalVol = totalRestV + totalSideVol;
    perTotalBV = ~~((totalRestV * 100) / totalVol).toFixed(2);
  }

  const perTotalOV = 100 - perTotalBV;
  // set to total vol dom
  const totalbuyTarget = document.getElementById(
    prefix + data.sym + '_perTotalBV'
  );

  const totalsellTarget = document.getElementById(
    prefix + data.sym + '_perTotalOV'
  );

  if (totalbuyTarget) {
    totalbuyTarget.style.width = `${perTotalBV}%`;
    totalbuyTarget.innerHTML = `${perTotalBV}%`;
  }

  if (totalsellTarget) {
    totalsellTarget.style.width = `${perTotalOV}%`;
    totalsellTarget.innerHTML = `${perTotalOV}%`;
  }
  //#endregion
};

const processG_extend = (sym, data, idPrice, idVol) => {
  if (!sym) return;

  let _rootPrice = document.getElementById(sym + idPrice);
  let _rootVol = document.getElementById(sym + idVol);

  if (!_rootPrice) return;

  const _g = data;
  const _oldVol = StringToInt_2(_rootVol.innerHTML);

  const price = _g[0];
  const vol = StringToInt_2(_g[1]); // BV_123
  const clp = _g[0] === 'ATO' || _g[0] === 'ATC' ? '' : _g[2];
  const clv = _oldVol < vol ? 'i' : _oldVol > vol ? 'd' : clp;

  const _oldPrice = _rootPrice.innerHTML;
  const _newPrice = numberFormat(price, 2);

  if (_oldPrice !== _newPrice) {
    _rootPrice.classList.remove('d', 'e', 'i', 'r', 'c', 'f');
    if (_rootPrice) _rootPrice.innerHTML = _newPrice;

    if (clp) _rootPrice.classList.add(clp, 'active');
  }

  if (_oldVol !== vol) {
    const _newVol = formatVolume10(_g[1]);
    _rootVol.classList.remove('d', 'e', 'i', 'r', 'c', 'f');
    if (_rootVol) _rootVol.innerHTML = _newVol;

    if (clv) _rootVol.classList.add(clv);
  }

  setTimeout(() => {
    if (clp) _rootPrice.classList.remove('active');
    if (clv) {
      _rootVol.classList.remove('d', 'e', 'i', 'r', 'c', 'f', 'active');
    }
  }, 500);
};

const process3210_trade = (data) => {
  // const trade_oddLot = document.getElementById('trade_oddLot');
  const trade_G = document.getElementById('trade_G'); // orderG
  const trade_sym = document.getElementById('trade_sym');
  const data_G = data?.brd;

  if (trade_G == undefined) return;
  if (data?.sym != trade_sym?.value) return;
  if (data_G != trade_G?.value) return;

  process3210_extend('trade_', data);
};

const process3210_detail = (data) => {
  const detail_G =
    document.getElementById('stock-detail-oddlot')?.value == 'true'
      ? constants.G.ODD_LOT
      : constants.G.DEFAULT;
  const trade_sym = document.getElementById('stock-detail-sym');
  const data_G = data?.brd;

  if (detail_G == undefined) return;
  if (data?.sym != trade_sym?.value) return;
  if (data_G != detail_G) return;

  process3210_extend('detail_', data);
};

const process3210_pf = (data) => {
  const pf_oddLot = document.getElementById('pf_oddLot');
  const trade_sym = document.getElementById('pf_sym');
  const data_oddLot = data?.brd == constants.G.ODD_LOT ? 'true' : 'false';

  if (pf_oddLot == undefined) return;
  if (data?.sym != trade_sym?.value) return;
  if (data_oddLot != pf_oddLot?.value) return;

  process3210_extend('pf_', data);
};

export { process3210, process3250, process3220, process3260 };
