import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import PerfectScrollBar from 'react-perfect-scrollbar';
import { Col, Card, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import {
  makeGetToken,
  makeGetListAccount,
  makeGetDebtMargin,
  makeGetRepayLoan,
  makeGetHisDebtMargin,
  makeGetCashBalance,
  makeGetAuth,
  makeGetHis2DebtMargin,
} from '../../lib/selector';

import {
  debtRequest,
  debtHisRequest,
  debtHis2Request,
} from '../../containers/cash/actions';

import { isEqual, some, sumBy, filter } from 'lodash';

import {
  numberFormat,
  formatDate,
  stringToDate,
  StringToInt,
  _formatDate,
  _formatDate2,
  getMessageError,
} from '../../util';

import FormHisCongNo from './layout/formHisCongNo';
import CongNo from './layout/formCongNo';

import { ReloadButton } from '../btnReload';
import { v4 as uuidv4 } from 'uuid';
import {
  setAuth,
  setToast,
  unsetClient,
} from '../../containers/client/actions';
import InputMask from '../input';
import { handleApiErrors } from '../../lib/api-errors';
import PaginationTable from '../PaginationTable';

import { translate } from 'react-i18next';
import { Logout, logoutRequest } from '../modal/login/actions';
import { AiFillWarning } from 'react-icons/ai';
import { removeCookie } from '../../lib/storages';

const waitFor = (ms) => new Promise((r) => setTimeout(r, ms));
const asyncForEach = async (array, callback) => {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
};

const appUrl = `${process.env.REACT_APP_API_URL}`;

function CheckStatus(props) {
  const { item, t } = props;
  let status = 1; // 1.in due 2.due 3.over due
  if (item.dueLoanAmt > 0 && item.ovdLoanAmt === 0) status = 2;

  if (item.dueLoanAmt === 0 && item.ovdLoanAmt > 0) status = 3;

  return status > 1 ? (
    <>
      <OverlayTrigger
        placement="right"
        overlay={
          <Tooltip>
            {status === 3 ? t('txt-stt-out-date') : t('txt-stt-due-date')}
          </Tooltip>
        }
      >
        <span className="mx-2 float-right">
          <AiFillWarning
            className={status === 3 ? 'text-danger' : 'text-warning'}
          />
        </span>
      </OverlayTrigger>
    </>
  ) : (
    <></>
  );
}

class BaoCaoNo extends React.Component {
  state = {
    hisDebt: [],
  };

  componentDidMount() {
    // console.log(this.props.store.getState)
    this._handleLoadDebt();
    this._handleLoadListDebt();
  }

  componentDidUpdate(preProps) {
    const { token, account, repayLoan, hisDebt } = this.props;
    if (
      (account && !isEqual(account, preProps.account)) ||
      (token && !isEqual(token, preProps.token))
    ) {
      this._handleLoadDebt();
      this._handleLoadListDebt();
    }

    if (hisDebt && !isEqual(hisDebt, preProps.hisDebt)) {
      // indLoanAmt
      if (hisDebt.list && hisDebt.list.length > 0) {
        const _hisDebt = filter(
          hisDebt.list,
          (o) => o.indLoanAmt + o.ovdLoanAmt + o.dueLoanAmt + o.remnInt > 0
        );
        if (!!_hisDebt.length) {
          _hisDebt.map((item) => {
            const uuid = uuidv4();
            item.debt =
              item.indLoanAmt +
              item.ovdLoanAmt +
              item.dueLoanAmt +
              item.remnInt;
            item.amt = '';
            item._calcInt = 0;
            item.err = '';
            item.key = uuid;
          });
        }
        this.setState({ hisDebt: _hisDebt });
      } else {
        this.setState({ hisDebt: [] });
      }
    }
  }

  _handleLoadDebt = (startDate, endDate, status) => {
    const { token, account } = this.props;

    if (!account || !token) return;

    const uuid = uuidv4();
    const resData = {
      group: 'CORE',
      user: token.user,
      session: token.session,
      cmd: 'getTotalDebt',
      rqId: uuid,
      channel: 'WTS',
      data: {
        acntNo: account.acntNo,
        subAcntNo: account.subAcntNo,
      },
    };
    console.log(resData);
    this.props.dispatch(debtRequest(resData));

    this._handleLoadListDebt(startDate, endDate, status);
  };

  _handleLoadListDebt = (startDate, endDate, status) => {
    const { token, account } = this.props;
    if (!account || !token) return;

    // const maxDay = token && token['config'] ? token['config']['maxDay'] : 90;
    const maxDay = 180;

    const d = new Date();
    d.setDate(d.getDate() - maxDay);

    const uuid = uuidv4();
    const resData = {
      group: 'CORE',
      user: token.user,
      session: token.session,
      cmd: 'getMarginLoanList',
      rqId: uuid,
      channel: 'WTS',
      data: {
        acntNo: account.acntNo,
        subAcntNo: account.subAcntNo,
        fromDate: startDate ?? formatDate(d, ''),
        toDate: endDate ?? formatDate(new Date(), ''),
        status: status ?? '0', // 0.all 1.in due 2.due 3.over due
      },
    };

    this.props.dispatch(debtHisRequest(resData));
  };

  _handleQueryData = (startDate, endDate, status) => {
    const { token, account } = this.props;
    if (!account || !token) return;

    const uuid = uuidv4();
    const resData = {
      group: 'CORE',
      user: token.user,
      session: token.session,
      cmd: 'getMarginLoanList',
      rqId: uuid,
      channel: 'WTS',
      data: {
        acntNo: account.acntNo,
        subAcntNo: account.subAcntNo,
        fromDate: startDate,
        toDate: endDate,
        status: status, // 0.all 1.in due 2.due 3.over due
      },
    };

    this.props.dispatch(debtHis2Request(resData));
  };

  _handleAuthen = () => {
    this.props.dispatch(setAuth());
  };

  _handleChange = (e) => {
    const { value, name } = e.target;
    // console.log(value, name);
    let { hisDebt } = this.state;
    if (!hisDebt) return;

    hisDebt.map((item) => {
      if (item.key !== name) return item;

      item.amt = value;
      item.err = '';
      item._calcInt = 0;
      return item;
    });
    // console.log(advMatch);
    this.setState({ hisDebt });
  };

  _handleBlur = (name) => {
    console.log(name);
    let { hisDebt } = this.state;
    if (!hisDebt) return;

    hisDebt.map((item) => {
      if (item.key !== name) return item;

      item.err =
        StringToInt(item.amt) > item.debt
          ? 'Tối đa: ' + numberFormat(item.debt, 0, '0')
          : '';
      return item;
    });
    this.setState({ hisDebt });
  };

  _handleProcFee = () => {
    let { hisDebt } = this.state;
    if (!some(hisDebt, (o) => o.err && o.err.length > 0))
      this._handleAdvanceFee();
  };

  _handleAdvanceFee = async () => {
    const { token, auth, account } = this.props;

    let { hisDebt } = this.state;
    if (!hisDebt) return;
    try {
      await asyncForEach(hisDebt, async (element) => {
        if (StringToInt(element.amt) > 0) {
          await waitFor(200);

          const uuid = uuidv4();
          const params = {
            group: 'CORE',
            user: token.user,
            session: token.session,
            cmd: 'repayMarginLoan',
            rqId: uuid,
            channel: 'WTS',
            type: auth.type,
            token: auth.token,
            data: {
              acntNo: account.acntNo,
              subAcntNo: account.subAcntNo,
              loanDt: _formatDate(element.loanDt),
              loanSeq: element.loanSeq,
              amt: StringToInt(element.amt),
            },
          };

          this._handleNewMarginLoan(params);
        }
      });

      await waitFor(200);

      this._handleLoadDebt();
      this._handleLoadListDebt();
    } catch (error) {
      console.log(error);
    }
  };

  _handleNewMarginLoan = (params) => {
    const url = `${appUrl}/CoreServlet.pt`;
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
      body: JSON.stringify(params),
    })
      .then(handleApiErrors)
      .then((response) => response.json())
      .then((res) => {
        console.log(res, params);
        if (res.http !== 200) {
          if (res.http === 401) {
            const { token } = this.props;
            if (token) this.props.dispatch(logoutRequest(token));

            this.props.dispatch(unsetClient());
            removeCookie('token');
            removeCookie('authen');

            this.props.dispatch(Logout());
            this._handleToast(this.props.t('txt-valid-loss-session'));
          } else {
            this._handleToast(getMessageError(res, this.props.store.getState));
          }
        } else {
          this._handleToast(this.props.t('txt-success'));
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  _handleToast = (msg) => {
    const toastMsg = {
      id: Math.random(),
      msg: msg,
      title: this.props.t('txt-notice'),
    };
    this.props.dispatch(setToast(toastMsg));
  };

  render() {
    const { hisDebt } = this.state;
    const { debt, auth, account, hisDebt2, t } = this.props;
    const _totalAdv = sumBy(hisDebt, (o) => StringToInt(o.amt));
    const _chkErr = some(hisDebt, (o) => o.err && o.err.length > 0);

    const columns = [
      {
        key: 'loanDt',
        text: t('txt-label-loan-date'),
        className: 'text-left',
        align: 'left',
      },
      {
        key: 'exprDt',
        text: t('txt-label-expire-date'),
        className: 'text-right',
        align: 'right',
      },
      {
        key: 'totInt',
        text: t('txt-label-interest'),
        className: 'text-right',
        align: 'right',
        cell: (record) => {
          return numberFormat(record.totInt, 0, '0');
        },
      },
      {
        key: 'doubleovdIntRt',
        text: t('txt-label-defer-interest'),
        className: 'text-right',
        align: 'right',
        cell: (record) => {
          return record.doubleovdIntRt * 100 + '%';
        },
      },
      {
        key: 'repayInt',
        text: t('txt-label-repay-interest'),
        className: 'text-right',
        align: 'right',
        cell: (record) => {
          return numberFormat(record.repayAmt + record.repayInt, 0, '0');
        },
      },
      {
        key: 'remnInt',
        text: t('txt-label-remain-interest'),
        className: 'text-right',
        align: 'right',
        cell: (record) => {
          return numberFormat(record.indLoanAmt, 0, '0');
        },
      },
      {
        key: 'ovdLoanAmt',
        text: t('txt-status'),
        className: 'text-center',
        align: 'center',
        cell: (record) => {
          return record.ovdLoanAmt > 0
            ? t('txt-stt-out-date')
            : t('txt-stt-in-date');
        },
      },
    ];

    return (
      <div style={{ height: 'calc( 100% - 150px )' }}>
        <div className="mt-3 mb-2">
          <span
            className="mr-auto ml-2 text-white"
            style={{ fontSize: '18px' }}
          >
            {t('txt-margin-debt')}
          </span>
        </div>
        <PerfectScrollBar>
          <Fragment>
            <div className="d-flex flex-column mt-2 mr-3 ml-3">
              <Card className="col-12 p-0">
                <Card.Body style={{ borderRadius: '5px' }}>
                  <div className="d-flex align-items-center">
                    <Col
                      xs
                      lg="2"
                      className="p-3 d-flex flex-column"
                      style={{ borderRight: '1px solid #42465b' }}
                    >
                      <span className="text-content fz_14">
                        {t('txt-label-total-loan')}
                      </span>
                      <span className="d fz_14 fw-500">
                        {debt && numberFormat(debt.totMrgnLoan, 0, '0')}
                      </span>
                    </Col>

                    <Col
                      className="p-3 d-flex flex-column"
                      style={{ borderRight: '1px solid #42465b' }}
                    ></Col>
                    <Col md="auto" className="p-3">
                      <CongNo
                        handleQuery={this._handleLoadDebt}
                        account={account}
                      />
                    </Col>
                  </div>
                </Card.Body>
              </Card>
              <table className="table table-bordered mt-3">
                <thead>
                  <tr>
                    <th className="text-left">{'ID'}</th>
                    <th className="text-left">
                      {t('txt-label-disbursement-date')}
                    </th>
                    <th className="text-left">
                      {t('txt-label-maturity-date')}
                    </th>
                    <th className="text-right">
                      {t('txt-label-interest-rate')}
                    </th>
                    <th className="text-right">{t('txt-label-loan-amount')}</th>
                    <th className="text-right">{t('txt-label-interest')}</th>
                    <th className="text-right">
                      {t('txt-label-penalty-delay')}
                    </th>
                    <th className="text-right">{t('txt-label-fee')}</th>
                    <th className="text-right">{t('txt-label-paid')}</th>
                    <th className="text-right">
                      {t('txt-label-in-due-amount')}
                    </th>
                    <th className="text-left">{t('txt-label-pay')}</th>
                  </tr>
                </thead>
                <tbody>
                  {hisDebt &&
                    !!hisDebt.length &&
                    hisDebt.map((item, idx) => (
                      <tr key={item.key}>
                        <td className="text-left">{item.loanSeq}</td>
                        <td className="text-left">{item.loanDt}</td>
                        <td className="text-left">
                          <span className="float-left">{item.exprDt}</span>
                          <CheckStatus item={item} t={t} />
                        </td>
                        <td className="text-right">
                          {numberFormat(item.indIntRt * 100, 2, '0') + '%'}
                        </td>
                        <td className="text-right">
                          {numberFormat(item.loanAmt, 0, '0')}
                        </td>
                        <td className="text-right">
                          {numberFormat(item.remnInt, 0, '0')}
                        </td>
                        <td className="text-right">
                          {numberFormat(item.ovdLoanAmt, 0, '0')}
                        </td>
                        <td className="text-right">{'0'}</td>
                        <td className="text-right">
                          {numberFormat(item.repayInt + item.repayAmt, 0, '0')}
                        </td>
                        <td className="text-right">
                          {numberFormat(item.debt, 0, '0')}
                        </td>
                        <td style={{ width: '150px' }}>
                          <InputMask
                            maxVal={item.debt}
                            name={item.key}
                            value={item.amt}
                            handleChange={this._handleChange}
                            handleBlur={this._handleBlur}
                          />
                          {item.err && (
                            <div
                              style={{
                                color: 'rgb(255, 85, 85)',
                                fontSize: '0.75rem',
                              }}
                            >
                              {item.err}
                            </div>
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              <div className="mt-2 d-flex flex-row-reverse text-white">
                <span className="fz_14 fw-500">
                  {numberFormat(_totalAdv, '0', '0')}
                </span>
                <span className="fz_14">
                  {`${t('txt-label-total-amount')}: `}&nbsp;
                </span>
              </div>
              <div className="mt-2 d-flex flex-row-reverse">
                {auth && auth.isVerified ? (
                  <button
                    className="btn btn--primary fw-500"
                    style={{ padding: '2px 28px' }}
                    disabled={!_totalAdv || _totalAdv < 1 || _chkErr}
                    onClick={() => this._handleProcFee()}
                  >
                    {t('txt-label-pay')}
                  </button>
                ) : (
                  <button
                    className="btn btn--authen fw-500 w-140"
                    onClick={() => this._handleAuthen()}
                  >
                    {t('txt-label-pay')}
                  </button>
                )}
              </div>
            </div>
            <div className="d-flex justify-content-between mt-3 mx-1">
              <label className="text-white text-uppercase ml-3">
                {t('txt-label-payment-history')}
              </label>
              <FormHisCongNo
                handleQuery={this._handleQueryData}
                account={account}
              />
            </div>
            <PaginationTable
              pageSize={10}
              columns={columns}
              source={
                hisDebt2 && hisDebt2.list && !!hisDebt2.list.length
                  ? hisDebt2.list
                  : []
              }
            />
          </Fragment>
        </PerfectScrollBar>
      </div>
    );
  }
}

const makeMapStateToProps = () => {
  const getToken = makeGetToken();
  const getAuth = makeGetAuth();
  const getListAccount = makeGetListAccount();

  const getDebt = makeGetDebtMargin();
  const getRepayLoan = makeGetRepayLoan();
  const getDebtHis = makeGetHisDebtMargin();
  const getDebtHis2 = makeGetHis2DebtMargin();
  const getCashBalance = makeGetCashBalance();

  const mapStateToProps = (state) => {
    return {
      token: getToken(state),
      auth: getAuth(state),
      listAccount: getListAccount(state),

      debt: getDebt(state),
      repayLoan: getRepayLoan(state),
      hisDebt: getDebtHis(state),
      hisDebt2: getDebtHis2(state),
      cashBalance: getCashBalance(state),
    };
  };
  return mapStateToProps;
};

export default connect(makeMapStateToProps)(
  translate('translations')(BaoCaoNo)
);
