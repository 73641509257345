import React from 'react';
import { withNamespaces } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import { constants } from '../../../util/constant';
import { useSelector } from 'react-redux';

const PopupRegisterSuccess = withNamespaces('translations')(
  ({
    t,
    showModal,
    dialogClassName,
    backdropClassName,
    contentClassName,
    nickName,
    setTabActive,
    infor,
    className,
    onClose,
  }) => {
    const { appsetting } = useSelector((state) => state.client);
    const competitionInfor = appsetting?.find(
      (item) =>
        item.key == 'COMPETITION_INFOR' &&
        item.module == constants.modules.COMPETITION
    )?.values;

    const links =
      competitionInfor &&
      JSON.parse(competitionInfor)?.find((item) => item.key == 'link').value;
    console.log('links: ', links);
    const link = links?.find((x) => x.key == 'gi1')?.value || '#';

    return (
      <Modal
        show={showModal}
        dialogClassName={dialogClassName}
        backdropClassName={backdropClassName}
        contentClassName={contentClassName}
        centered
        onHide={onClose}
        className={className}
      >
        <div className="popup-header">
          {infor && (
            <span
              className="icon iInfor cursor-pointer"
              onClick={() => window.open(infor)}
            ></span>
          )}
          <span className="popup-header__title fw-500">{t('txt-notice')}</span>
          {!!onClose && (
            <span
              className="icon iClose cursor-pointer"
              onClick={onClose}
            ></span>
          )}
        </div>
        <div className="popup-body">
          <div className="popup-body__content ">
            <span className="popup-body__title text--light fw-500"></span>
            <span
              className="popup-body__description text--light3 d-flex flex-column align-items-center"
              dangerouslySetInnerHTML={{
                __html: `${t(
                  'marginCompetition.generalIntroduction.messageRegisCptSuccess',
                  { nickName: nickName }
                )}`,
              }}
            ></span>
            <span
              className="popup-body__description text--light3"
              dangerouslySetInnerHTML={{
                __html: `${t(
                  'marginCompetition.generalIntroduction.openMarginAccount',
                  { link: link }
                )}`,
              }}
            ></span>
          </div>
        </div>
        <div className="popup-footer">
          <span className="popup-footer__button">
            <button
              className="btn btn--primary"
              onClick={() =>
                setTabActive(constants.marginCompetition.RULE_AND_AWARD)
              }
            >
              {t('lbl-ok')}
            </button>
          </span>
        </div>
      </Modal>
    );
  }
);

export default PopupRegisterSuccess;
