import React from 'react';

const RenderInputOrder = ({
  input,
  type,
  className,
  placeholder,
  autoFocus = false,
  disabled,
  meta: { touched, error },
}) => (
  <div>
    <input
      {...input}
      type={type}
      className={className}
      placeholder={placeholder}
      autoComplete="off"
      autoFocus={autoFocus}
      disabled={disabled}
      style={{
        borderColor: `${touched && error ? '#ff5555' : '#c6cbd2'}`,
      }}
    />
  </div>
);

export default RenderInputOrder;
