import React, { useEffect, useState } from 'react';
import { translate, withNamespaces } from 'react-i18next';
import {
  numberFormat,
  _convertActTypeToStr,
  _getOrdCondStatus,
  _getOrderStatus,
  _getOrdCondStr,
  _convertTpToPrice,
} from '../../../util';
import { useSelector } from 'react-redux';
import PerfectScrollBar from 'react-perfect-scrollbar';

const ExpandRow = withNamespaces('translations')(
  ({ index, ordSel, t, detail, colSpan = 10 }) => {
    if (!ordSel) return null;

    const realAmount =
      detail &&
      detail.list &&
      !!detail.list.length &&
      detail.list.map((item) =>
        ordSel.buySelTp == 1
          ? item.matchQty * item.matchPrc + item.feeAmt
          : item.matchQty * item.matchPrc -
            item.tax -
            item.feeAmt -
            item.divdDltx -
            item.selFee
      );

    const total =
      realAmount &&
      realAmount?.reduce(
        (previousValue, currentValue) => previousValue + currentValue,
        0
      );

    return (
      <tr key={`expandRow_${index}`} className="expand-row">
        <td className="no-hover py-0" colSpan={colSpan}>
          <div className="py-2">
            {ordSel && (
              <>
                <table className="table table-bordered tbl-detail">
                  <thead>
                    <tr className="bg-dark3">
                      <th className="text-center" rowSpan={2}>
                        {t('derivative.label.matchedTime')}
                      </th>
                      <th className="text-center" rowSpan={2}>
                        {t('txt-match-price')}
                      </th>
                      <th className="text-center" rowSpan={2}>
                        {t('txt-match-vol')}
                      </th>
                      <th className="text-center" rowSpan={2}>
                        {t('txt-dividend-match-vol')}
                      </th>
                      <th className="text-center" rowSpan={2}>
                        {t('txt-label-total-amount')}
                      </th>
                      <th className="text-center" rowSpan={2}>
                        {t('txt-label-trading-fee')}
                      </th>

                      <th className="text-center" rowSpan={2}>
                        {t('txt-label-sell-fee')}
                      </th>
                      <th className="text-center" rowSpan={2}>
                        {t('txt-label-tax')}
                      </th>
                      <th className="text-center" rowSpan={2}>
                        {t('txt-label-dividend-tax')}
                      </th>
                      <th className="text-center">
                        {t('txt-label-real-amount')}
                      </th>
                    </tr>
                    <tr>
                      <th className="text-right text--light">
                        {numberFormat(total, 0, '0')}
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {detail &&
                      detail.list &&
                      !!detail.list.length &&
                      detail.list.map((item, i) => (
                        <tr key={i} className="bg-dark3">
                          <td className="text-center">
                            {item.matchTime && item.matchTime.split(' ')[1]}
                          </td>
                          <td className="text-right">
                            {numberFormat(item.matchPrc, 0, '0')}
                          </td>
                          <td className="text-right">
                            {numberFormat(item.matchQty, 0, '0')}
                          </td>
                          <td className="text-right">
                            {numberFormat(item.divdQty, 0, '0')}
                          </td>
                          <td className="text-right">
                            {numberFormat(
                              item.matchQty * item.matchPrc,
                              0,
                              '0'
                            )}
                          </td>
                          <td className="text-right">
                            {numberFormat(item.feeAmt, 0, '0')}
                          </td>
                          <td className="text-right">
                            {numberFormat(item.selFee, 0, '0')}
                          </td>
                          <td className="text-right">
                            {numberFormat(item.tax, 0, '0')}
                          </td>
                          <td className="text-right">
                            {numberFormat(item.divdDltx, 0, '0')}
                          </td>
                          <td className="text-right">
                            {numberFormat(realAmount[i], 0, '0')}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                {(!detail || !detail.list || !detail.list.length) && (
                  <p className="text-center fz_14 mt-2 title-time">
                    {t('txt-no-data-found')}
                  </p>
                )}
              </>
            )}
          </div>
        </td>
      </tr>
    );
  }
);

const ScrollSKL = withNamespaces('translations')(
  ({
    onRowClicked,
    divClassName,
    tblClassName,
    columns,
    defaultAccount,
    sum,
    pageSize,
    source,
    rowExpand = true,
    ordSel,
    t,
    detail,
    handleDeleteOrder,
    handleAuthen,
    expandColSpan = 10,
    totalColSpan,
    hasAction = true,
    SumComponent = null,
  }) => {
    const [index, setIndex] = useState(-1);

    const { auth } = useSelector((state) => state.client);

    useEffect(() => {
      setIndex(-1);
    }, [source]);

    return (
      <div className={'d-flex flex-column ' + (divClassName || 'm-2')}>
        <PerfectScrollBar style={{ height: 'calc(100% - 2px)' }}>
          <table className={'table table-bordered ' + (tblClassName || '')}>
            <thead>
              <tr>
                {columns.map((column, index) => {
                  let classText = column.sortable ? 'sortable ' : '',
                    width = column.width ? column.width : '',
                    align = column.align ? column.align : '',
                    columnStyle = column.columnStyle ? column.columnStyle : {};
                  classText += ' text-' + align;
                  if (column.TrOnlyClassName)
                    classText += ' ' + column.TrOnlyClassName;

                  return (
                    <th
                      key={column.key ? column.key : column.text}
                      className={classText}
                      width={width}
                      style={columnStyle}
                    >
                      {column.text}
                    </th>
                  );
                })}
                <th></th>
              </tr>
            </thead>
            <tbody>
              {source &&
                source.map((item, i) => {
                  return (
                    <>
                      <tr key={i} colSpan={6}>
                        {columns.map((column, colIndex) => {
                          const _className =
                            typeof column.className === 'function'
                              ? column.className(item)
                              : column.className;

                          if (
                            column.cell &&
                            typeof column.cell === 'function'
                          ) {
                            if (column.key === 'defaultAccount') {
                              return (
                                <td
                                  className={_className}
                                  key={
                                    column.key
                                      ? column.key
                                      : column.text + '_' + colIndex
                                  }
                                >
                                  {column.cell(defaultAccount)}
                                </td>
                              );
                            }
                            return (
                              <td
                                className={_className}
                                key={
                                  column.key
                                    ? column.key
                                    : column.text + '_' + colIndex
                                }
                              >
                                {column.cell(item, i)}
                              </td>
                            );
                          } else if (item[column.key]) {
                            return (
                              <td
                                className={_className}
                                key={
                                  column.key
                                    ? column.key
                                    : column.text + '_' + colIndex
                                }
                              >
                                {item[column.key]}
                              </td>
                            );
                          } else {
                            if (column.key === '_index') {
                              return (
                                <td
                                  className={_className}
                                  key={
                                    column.key
                                      ? column.key
                                      : column.text + '_' + colIndex
                                  }
                                >
                                  {i + 1}
                                </td>
                              );
                            }
                            return (
                              <td
                                className={_className}
                                key={
                                  column.key
                                    ? column.key
                                    : column.text + '_' + colIndex
                                }
                              ></td>
                            );
                          }
                        })}
                        <td
                          className="text-center py-1"
                          key={`action_${1}`}
                          onClick={() => {
                            if (i === index) return setIndex(-1);
                            setIndex(i);
                            return onRowClicked(item);
                          }}
                        >
                          {' '}
                          {i === index ? (
                            <div
                              className="icon iUp2"
                              style={{
                                marginLeft: '50%',
                                transform: 'translateX(-50%)',
                              }}
                            />
                          ) : (
                            <div
                              className="icon iDown2"
                              style={{
                                marginLeft: '50%',
                                transform: 'translateX(-50%)',
                              }}
                            />
                          )}
                        </td>
                      </tr>
                      {rowExpand && i === index && (
                        <ExpandRow
                          colSpan={expandColSpan}
                          index={i}
                          detail={detail}
                          ordSel={ordSel}
                          handleDeleteOrder={handleDeleteOrder}
                          handleAuthen={handleAuthen}
                        />
                      )}
                    </>
                  );
                })}
            </tbody>
            {sum && (
              <tfoot>
                <tr>
                  <td className="text-center" colSpan={4}>
                    {t('txt-label-total')}
                  </td>
                  <td colSpan={totalColSpan}></td>
                  <td className="text-right">
                    {numberFormat(sum.vol, 0, '0')}
                  </td>
                  <td className="text-right">
                    {numberFormat(sum.matchVol, 0, '0')}
                  </td>
                  <td className="text-right">
                    {numberFormat(sum.sumValAfterTaxFee, 0, '0')}
                  </td>
                  <td className="text-right">
                    {numberFormat(sum.sumDivdselqty, 0, '0')}
                  </td>
                  <td></td>
                </tr>
              </tfoot>
            )}
            {SumComponent && <SumComponent />}
          </table>
          {(!source || !source.length) && (
            <p className="text-center fz_14 mt-2 title-time">
              {t('txt-no-data-found')}
            </p>
          )}

          <div style={{ height: '50px' }}></div>
        </PerfectScrollBar>
      </div>
    );
  }
);

export default translate('translations')(ScrollSKL);
