import { withNamespaces } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ModalCancelService from '../../modal/modalCancelService';
import ModalChangeFee from '../../modal/modalChangeFee';
import ModalRegisterService from '../../modal/modalRegisterService';
import { _formatDate2 } from '../../../../../util';
import Page from '../../../../../components/print/Page';
import React, { useState } from 'react';
import { setAuth } from '../../../../client/actions';
import { OverlayTrigger, Popover } from 'react-bootstrap';

const ServiceSection = withNamespaces('translations')(({ t, onSuccess }) => {
  const { serList4Cus } = useSelector((state) => state.customer);
  const { auth } = useSelector((state) => state.client);
  const dispatch = useDispatch();
  const [changeFeeOpenModal, setChangeFeeOpenModal] = useState(false);
  const theme = useSelector((state) => state.client.currentTheme);

  if (!serList4Cus) {
    return <div />;
  }
  const { services, canChangeFeeYN } = serList4Cus;

  return (
    <>
      <div className="section service">
        <div className="title border-bottom">
          <span className="text text--md text--light">
            {t('partner.serviceType')}
          </span>
        </div>
        <Page
          style={{
            maxHeight: 'calc(100vh - 440px)',
            overflow: 'auto',
            position: 'relative',
          }}
        >
          <table className="table table-bordered tbl-list scroll-table">
            <thead>
              <tr>
                <th>{t('partner.serviceLabel.type')}</th>
                <th>{t('partner.serviceLabel.feePolicy')}</th>
                <th>{t('partner.serviceLabel.activeDate')}</th>
                <th>{t('partner.serviceLabel.status')}</th>
                <th style={{ width: 72 }}></th>
              </tr>
            </thead>
            <tbody>
              {(services || [])
                .sort((a, b) => a.serTp - b.serTp)
                .map((service, index) => (
                  <tr key={index}>
                    <td>
                      <div className="d-flex gap-2">
                        {service.serTpNm}
                        {service.subAcnt?.slice(0, 1) === 'D' && (
                          <OverlayTrigger
                            trigger={['click']}
                            placement="bottom"
                            rootClose
                            overlay={
                              <Popover
                                className={`popover-derivative-order ${
                                  theme?.name === 'dark' ? 'bg-dark ' : ''
                                }`}
                              >
                                <Popover.Content className="d-flex derivative-order">
                                  <div
                                    className={
                                      theme?.name === 'dark'
                                        ? 'text--light'
                                        : ''
                                    }
                                  >
                                    {t('partner.derivativeFeeNote')}
                                  </div>
                                </Popover.Content>
                              </Popover>
                            }
                          >
                            <div
                              className={'d-flex ml-2 align-items-center'}
                              style={{ width: '12px' }}
                            >
                              <span className="icon iWarming"> </span>
                            </div>
                          </OverlayTrigger>
                        )}
                      </div>
                    </td>
                    <td>{service.feePolicy}</td>
                    <td>{_formatDate2(service.startDate || '')}</td>
                    <td>{t(`partner.serviceStatus.${service.status}`)}</td>
                    <td>
                      {service.registYN === 'Y' && (
                        <ModalRegisterService
                          service={service}
                          onSuccess={onSuccess}
                        />
                      )}
                      {service.unregistYN === 'Y' && (
                        <ModalCancelService
                          service={service}
                          onSuccess={onSuccess}
                        />
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </Page>
        {canChangeFeeYN === 'Y' && (
          <button
            className={`btn w-28 w-100 ${
              auth && auth.isVerified ? 'btn-edit' : 'btn--authen'
            }`}
            onClick={() => {
              if (auth && auth.isVerified) {
                setChangeFeeOpenModal(true);
              } else {
                dispatch(setAuth());
              }
            }}
          >
            <span className="icon iEdit" />
            <span>{t('partner.change_fee')}</span>
          </button>
        )}
      </div>
      <ModalChangeFee
        t={t}
        showModal={changeFeeOpenModal}
        handleClose={() => {
          setChangeFeeOpenModal(false);
        }}
        isConfirm={false}
        isCompare
        isEdit
        isCustomer
        submitApproveCallback={() => {
          setChangeFeeOpenModal(false);
        }}
      />
    </>
  );
});

export default ServiceSection;
