import React, { useState, useEffect, useContext } from 'react';
import { withNamespaces } from 'react-i18next';
import { ReactReduxContext, useDispatch, useSelector } from 'react-redux';
import PaginationTable from '../../../components/PaginationTable';
import {
  debtHisRequest,
  debtRequest,
  debtHis2Request,
  debtHisExtendRequest,
  debtExtendRequest,
  resetDebtExtendRequest,
} from '../../cash/actions';
import { v4 as uuidv4 } from 'uuid';
import PerfectScrollBar from 'react-perfect-scrollbar';
import { makeGetCashBalance } from '../../../lib/selector';
import { some, sumBy, filter } from 'lodash';
import {
  numberFormat,
  formatDate,
  stringToDate,
  StringToInt,
  _formatDate,
  _formatDate2,
  getMessageError,
  accountTypeDetect,
} from '../../../util';

import {
  setAuth,
  setCashOut,
  setToast,
  unsetClient,
} from '../../client/actions';
import { Logout, logoutRequest } from '../../../components/modal/login/actions';
import { handleApiErrors } from '../../../lib/api-errors';
import { removeCookie } from '../../../lib/storages';

import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { AiFillWarning } from 'react-icons/ai';
import DatePicker from '../../../components/datePicker2';
import MaxInput from '../../../components/input/maxInput';
import ReactSelect from 'react-select';
import { cashBalanceRequest } from '../../privData/actions';
import Modal from 'react-bootstrap/Modal';
import { setDefaultAccount } from '../../socket/actions';
import MarginExtendingModal from '../../../components/modal/margin/marginExtending';
import AssetHeader from '../components/header/assetHeader';

const waitFor = (ms) => new Promise((r) => setTimeout(r, ms));
const asyncForEach = async (array, callback) => {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
};

const appUrl = `${process.env.REACT_APP_API_URL}`;
function CheckStatus(props) {
  const { item, t } = props;
  let status = 1; // 1.in due 2.due 3.over due
  if (item.dueLoanAmt > 0 && item.ovdLoanAmt === 0) status = 2;

  if (item.dueLoanAmt === 0 && item.ovdLoanAmt > 0) status = 3;

  return status > 1 ? (
    <>
      <OverlayTrigger
        placement="right"
        overlay={
          <Tooltip>
            {status === 3 ? t('txt-stt-out-date') : t('txt-stt-due-date')}
          </Tooltip>
        }
      >
        <span className="mx-2">
          <AiFillWarning
            className={status === 3 ? 'text-danger' : 'text-warning'}
          />
        </span>
      </OverlayTrigger>
    </>
  ) : (
    <></>
  );
}

const Index = withNamespaces('translations')(({ t, params }) => {
  const { defaultAccount, listAccount } = useSelector((state) => state.socket);
  const { token, auth } = useSelector((state) => state.client);
  const {
    hisDebt2,
    hisDebt,
    hisExtend,
    extendRequesting,
    extendRequestSuccess,
    extendRequestError,
  } = useSelector((state) => state.cash);
  const cashBalance = useSelector(makeGetCashBalance());
  const dispatch = useDispatch();
  const [hDebt, setHDebt] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [historyActiveTab, setHistoryActiveTab] = useState(0);
  const [account, setAccount] = useState(defaultAccount);
  const [reload, setReload] = useState(false);
  const [result, setResult] = useState(0); // 0: default | 1: success | 2: fail
  const [error, setError] = useState(''); // 0: default | 1: success | 2: fail
  const { store } = useContext(ReactReduxContext);
  const [showModalExtend, setShowModalExtend] = useState(false);
  const [extendRecord, setExtendRecord] = useState();

  const _chkErr = some(hDebt, (o) => o.err && o.err.length > 0);

  useEffect(() => {
    if (defaultAccount) {
      setAccount(defaultAccount);
    }
  }, [defaultAccount]);

  useEffect(() => {
    reloadHandle();
  }, [token, account]);

  useEffect(() => {
    if (!extendRequesting) {
      if (extendRequestSuccess) {
        setShowModalExtend(false);
        setResult(1);
        reloadHandle();
        dispatch(resetDebtExtendRequest());
      } else {
        if (extendRequestError && !!extendRequestError.length) {
          setResult(2);
          setError(extendRequestError[0]?.body);
          dispatch(resetDebtExtendRequest());
        }
      }
    }
  }, [extendRequesting]);

  useEffect(() => {
    if (hisDebt && hisDebt.list && hisDebt.list.length > 0) {
      const _hisDebt = filter(
        hisDebt.list,
        (o) => o.indLoanAmt + o.ovdLoanAmt + o.dueLoanAmt + o.remnInt > 0
      );

      if (!!_hisDebt.length) {
        _hisDebt.map((item) => {
          const uuid = uuidv4();
          item.debt =
            item.indLoanAmt + item.ovdLoanAmt + item.dueLoanAmt + item.remnInt;
          item.amt = '';
          item._calcInt = 0;
          item.err = '';
          item.key = uuid;
        });
      }

      setHDebt(_hisDebt);
    } else {
      setHDebt([]);
    }
  }, [hisDebt]);

  const loadCashBalanceHandle = () => {
    if (account) {
      const uuid = uuidv4();

      const resData = {
        group: 'CORE',
        user: token.user,
        session: token.session,
        cmd: 'getTotalAssetAll',
        rqId: uuid,
        channel: 'WTS',
        data: {
          acntNo: account.acntNo,
          subAcntNo: account.subAcntNo,
        },
      };
      dispatch(cashBalanceRequest(resData));
    }
  };

  const loadListDebtHandle = (startDate, endDate, status = '0') => {
    if (!account || !token) return;

    // const maxDay = token && token['config'] ? token['config']['maxDay'] : 90;
    const maxDay = 180;

    const d = new Date();
    d.setDate(d.getDate() - maxDay);

    const uuid = uuidv4();
    const resData = {
      group: 'CORE',
      user: token.user,
      session: token.session,
      cmd: 'getMarginLoanList',
      rqId: uuid,
      channel: 'WTS',
      data: {
        acntNo: account.acntNo,
        subAcntNo: account.subAcntNo,
        fromDate:
          formatDate(stringToDate(startDate, 'dmy'), '') ?? formatDate(d, ''),
        toDate:
          formatDate(stringToDate(endDate, 'dmy'), '') ??
          formatDate(new Date(), ''),
        status: status, // 0.all 1.in due 2.due 3.over due
      },
    };

    dispatch(debtHisRequest(resData));
  };

  const queryDataHandle = (startDate, endDate, status = '0') => {
    if (!account || !token) return;
    const maxDay = 180;
    const d = new Date();
    d.setDate(d.getDate() - maxDay);

    const uuid = uuidv4();
    const resData = {
      group: 'CORE',
      user: token.user,
      session: token.session,
      cmd: 'getMarginLoanList',
      rqId: uuid,
      channel: 'WTS',
      data: {
        acntNo: account.acntNo,
        subAcntNo: account.subAcntNo,
        fromDate:
          formatDate(stringToDate(startDate, 'dmy'), '') ?? formatDate(d, ''),
        toDate:
          formatDate(stringToDate(endDate, 'dmy'), '') ??
          formatDate(new Date(), ''),
        status: status, // 0.all 1.in due 2.due 3.over due
      },
    };

    dispatch(debtHis2Request(resData));
  };

  const queryExtendDataHandle = (startDate, endDate, status = '-1') => {
    if (!account || !token) return;
    const maxDay = 180;
    const d = new Date();
    d.setDate(d.getDate() - maxDay);

    const uuid = uuidv4();
    const resData = {
      group: 'CORE',
      user: token.user,
      session: token.session,
      cmd: 'getExtensionHist',
      rqId: uuid,
      channel: 'WTS',
      data: {
        cif: token.cif,
        acntNo: account.acntNo,
        subAcntNo: account.subAcntNo,
        frDate:
          formatDate(stringToDate(startDate, 'dmy'), '') ?? formatDate(d, ''),
        toDate:
          formatDate(stringToDate(endDate, 'dmy'), '') ??
          formatDate(new Date(), ''),
        status: status, // 0. pending, 1. approved, 2.rejected
      },
    };

    dispatch(debtHisExtendRequest(resData));
  };

  const authenHandle = () => {
    dispatch(setAuth());
  };

  const changeHandle = (value, name) => {
    if (!hDebt) return;
    console.log(`name: ${name} | value: ${value}`);

    const _hDebt = hDebt;
    _hDebt.map((item) => {
      if (item.key !== name) return item;

      item.amt = value;
      item.err = '';
      item._calcInt = 0;
      return item;
    });

    setHDebt(_hDebt);

    const _totalAmount = sumBy(_hDebt, (o) => StringToInt(o.amt));
    setTotalAmount(_totalAmount);
  };

  const blurHandle = (name) => {
    if (!hDebt) return;
    const _hDebt = hDebt;
    _hDebt.map((item) => {
      if (item.key !== name) return item;

      item.err =
        StringToInt(item.amt) > item.debt
          ? `${t('txt-title-max')}: ${numberFormat(item.debt, 0, '0')}`
          : '';
      return item;
    });

    setHDebt(_hDebt);
  };

  const procFeeHandle = () => {
    if (!some(hDebt, (o) => o.err && o.err.length > 0)) {
      advanceFeeHandle();
    }
  };

  const advanceFeeHandle = async () => {
    if (!hDebt || !token || !auth) return;
    try {
      await asyncForEach(hDebt, async (element) => {
        if (StringToInt(element.amt) > 0) {
          await waitFor(200);

          const uuid = uuidv4();
          const params = {
            group: 'CORE',
            user: token.user,
            session: token.session,
            cmd: 'repayMarginLoan',
            rqId: uuid,
            channel: 'WTS',
            type: auth.type,
            token: auth.token,
            data: {
              acntNo: account.acntNo,
              subAcntNo: account.subAcntNo,
              loanDt: _formatDate(element.loanDt),
              loanSeq: element.loanSeq,
              amt: StringToInt(element.amt),
            },
          };

          newMarginLoanHandle(params);
        }
      });

      await waitFor(200);
      reloadHandle();
    } catch (error) {
      console.log(error);
    }
  };

  const newMarginLoanHandle = (params) => {
    const url = `${appUrl}/CoreServlet.pt`;
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
      body: JSON.stringify(params),
    })
      .then(handleApiErrors)
      .then((response) => response.json())
      .then((res) => {
        if (res.http !== 200) {
          if (res.http === 401) {
            if (token) dispatch(logoutRequest(token));

            dispatch(unsetClient());
            removeCookie('token');
            removeCookie('authen');

            dispatch(Logout());
            toastHandle(t('txt-valid-loss-session'));
          } else {
            const { getState } = store;
            toastHandle(getMessageError(res, getState));
          }
        } else {
          toastHandle(t('txt-success'));
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const toastHandle = (msg) => {
    const toastMsg = {
      id: Math.random(),
      msg: msg,
      title: t('txt-notice'),
    };

    dispatch(setToast(toastMsg));
  };

  const withdrawHandle = () => {
    if (auth && auth.isVerified) {
      dispatch(setCashOut(true));
    } else {
      dispatch(setAuth());
    }
  };

  const handleCancel = () => {
    setExtendRecord('');
    setShowModalExtend(false);
  };

  const handleMarginExtend = () => {
    if (!account || !token || !auth) return;
    const uuid = uuidv4();
    const resData = {
      group: 'CORE',
      user: token.user,
      session: token.session,
      cmd: 'extendMarginLoan',
      rqId: uuid,
      channel: 'WTS',
      type: auth.type,
      token: auth.token,
      data: {
        cif: token.cif,
        acntNo: account.acntNo,
        subAcntNo: account.subAcntNo,
        loanTranSeq: extendRecord?.loanSeq,
        loanDate: formatDate(stringToDate(extendRecord?.loanDt, 'dmy'), ''),
        chngExprdt: formatDate(stringToDate(extendRecord?.exprDt, 'dmy'), ''),
      },
    };

    dispatch(debtExtendRequest(resData));
  };

  const extendHandle = (item) => {
    console.log('setShowModalExtend');
    setExtendRecord(item);
    setShowModalExtend(true);
  };

  const reloadHandle = (startDate, endDate) => {
    if (token && account) {
      queryExtendDataHandle(startDate, endDate, '-1');
      queryDataHandle(startDate, endDate, '0');
      loadListDebtHandle(startDate, endDate, '0');
      loadCashBalanceHandle();
      setReload(!reload);
      setTotalAmount(0);
    }
  };

  const STATUS = [
    { value: '0', label: t('txt-all') },
    { value: '1', label: t('txt-stt-in-date') },
    { value: '2', label: t('txt-stt-due-date') },
    { value: '3', label: t('txt-stt-out-date') },
  ];

  const EXTEND_HIS_STATUS = [
    { value: '-1', label: t('txt-all') },
    { value: '0', label: t('transaction.marginDebt.wait') },
    { value: '1', label: t('transaction.marginDebt.approved') },
    { value: '2', label: t('transaction.marginDebt.rejected') },
  ];

  const paymentColumns = [
    {
      key: 'loanSeq',
      text: 'ID',
      className: 'text-center text--light',
      align: 'center',
      sortable: true,
    },
    {
      key: 'loanDt',
      text: t('txt-label-loan-date'),
      className: 'text-center text--light3',
      align: 'center',
    },
    {
      key: 'exprDt',
      text: t('txt-label-expire-date'),
      className: 'text-center text--light3',
      align: 'center',
    },
    {
      key: 'totInt',
      text: t('txt-label-interest'),
      className: 'text-right text-light',
      align: 'right',
      cell: (record) => {
        return numberFormat(record.totInt, 0, '0');
      },
      sortable: true,
    },
    {
      key: 'doubleovdIntRt',
      text: t('txt-label-defer-interest'),
      className: 'text-right text-light',
      align: 'right',
      cell: (record) => {
        return record.doubleovdIntRt * 100 + '%';
      },
      sortable: true,
    },
    {
      key: 'repayInt',
      text: t('txt-label-repay-interest'),
      className: 'text-right text-light',
      align: 'right',
      cell: (record) => {
        return numberFormat(record.repayAmt + record.repayInt, 0, '0');
      },
      sortable: true,
    },
    {
      key: 'remnInt',
      text: t('txt-label-remain-interest'),
      className: 'text-right text-light',
      align: 'right',
      cell: (record) => {
        return numberFormat(record.indLoanAmt, 0, '0');
      },
      sortable: true,
    },
    {
      key: 'ovdLoanAmt',
      text: t('txt-status'),
      className: 'text-center text-light',
      align: 'center',
      cell: (record) => {
        return record.ovdLoanAmt > 0
          ? t('txt-stt-out-date')
          : t('txt-stt-in-date');
      },
      sortable: true,
    },
  ];

  const extendColumns = [
    {
      key: 'loanTranSeq',
      text: 'ID',
      className: 'text-center text-light',
      align: 'center ',
      sortable: true,
    },
    {
      key: 'loanDate',
      text: t('txt-label-loan-date'),
      className: 'text-center text--light3',
      align: 'center',
      cell: (record) => {
        return _formatDate2(record.loanDate);
      },
    },
    {
      key: 'exprdt',
      text: t('transaction.marginDebt.oldExprdt'),
      className: 'text-center text--light3',
      align: 'center',
      cell: (record) => {
        return _formatDate2(record.exprdt);
      },
    },
    {
      key: 'aplcDate',
      text: t('txt-label-extend-registration-date'),
      className: 'text-center text--light',
      align: 'center',
      cell: (record) => {
        return _formatDate2(record.aplcDate);
      },
    },
    {
      key: 'chngExprdt',
      text: t('transaction.marginDebt.newExprdt'),
      className: 'text-center text-light',
      align: 'center',
      cell: (record) => {
        return _formatDate2(record.chngExprdt);
      },
    },
    {
      key: 'exprExtTims',
      text: t('transaction.marginDebt.extendingTime'),
      className: 'text-center text-light',
      align: 'center',
      cell: (record) => {
        return numberFormat(record.exprExtTims, 0, 0);
      },
    },
    {
      key: 'principalAmt',
      text: t('transaction.marginDebt.remainingPrincipalDebt'),
      className: 'text-center text-light',
      align: 'center',
      cell: (record) => {
        return numberFormat(record.principalAmt, 0, 0);
      },
    },
    {
      key: 'feeAmt',
      text: t('transaction.marginDebt.extendingFee'),
      className: 'text-center text-light',
      align: 'center',
      cell: (record) => {
        return numberFormat(record.feeAmt, 0, 0);
      },
    },
    {
      key: 'status',
      text: t('txt-status'),
      className: 'text-center text-light',
      align: 'center',
      cell: (record) => {
        return (
          <span
            className={`text--${
              record.status == '0'
                ? 'active'
                : record.status == '1'
                ? 'success'
                : 'fail'
            }`}
          >
            {EXTEND_HIS_STATUS.find((x) => x.value == record.status)?.label}
          </span>
        );
      },
      sortable: true,
    },
  ];

  const debtColumns = [
    {
      key: 'loanSeq',
      text: 'ID',
      className: 'text-center text-light',
      align: 'center ',
    },
    {
      key: 'loanDt',
      text: t('txt-label-disbursement-date'),
      className: 'text-center text--light3',
      align: 'center',
    },
    {
      text: t('txt-label-maturity-date'),
      className: 'text-center text-light',
      align: 'center',
      cell: (record) => {
        return (
          <span className="d-flex justify-content-between">
            <span className="d-inline-block w-100">{record.exprDt}</span>
            <CheckStatus item={record} t={t} />
          </span>
        );
      },
    },
    {
      text: t('txt-label-interest-rate'),
      className: 'text-right text-light',
      align: 'right',
      cell: (record) => {
        return numberFormat(record.indIntRt * 100, 2, '0') + '%';
      },
    },
    {
      text: t('txt-label-loan-amount'),
      className: 'text-right text-light',
      align: 'right',
      cell: (record) => {
        return numberFormat(record.loanAmt, 0, '0');
      },
    },
    {
      text: t('txt-label-interest'),
      className: 'text-right',
      align: 'right',
      cell: (record) => {
        return numberFormat(record.remnInt, 0, '0');
      },
    },
    {
      text: t('txt-label-penalty-delay'),
      className: 'text-center text--light3',
      align: 'center',
      cell: (record) => {
        return numberFormat(record.ovdLoanAmt, 0, '0');
      },
    },
    {
      text: t('txt-label-fee'),
      className: 'text-center text--light3',
      align: 'center',
      cell: (record) => {
        return '0';
      },
    },
    {
      text: t('txt-label-paid'),
      className: 'text-center r',
      align: 'center',
      cell: (record) => {
        return numberFormat(record.repayInt + record.repayAmt, 0, '0');
      },
    },
    {
      text: t('txt-label-in-due-amount'),
      className: 'text-center d',
      align: 'center',
      cell: (record) => {
        return numberFormat(record.debt, 0, '0');
      },
    },
    {
      text: t('transaction.marginDebt.extend'),
      className: 'text-center',
      align: 'center',
      cell: (record) => {
        if (record.status == 'P')
          return (
            <span className="w-100 text--active">
              {t('transaction.marginDebt.wait')}
            </span>
          );
        else {
          if (auth && auth.isVerified)
            return (
              <button
                // disabled={record.status == 'N'}
                className="btn btn--edit w-100"
                onClick={() => extendHandle(record)}
              >
                {t('transaction.marginDebt.extend')}
              </button>
            );
          else
            return (
              <button
                className="btn btn--authen"
                onClick={() => authenHandle()}
              >
                {t('transaction.marginDebt.extend')}
              </button>
            );
        }
      },
    },
    {
      text: t('txt-label-pay'),
      className: 'text-center',
      align: 'center',
      cell: (record, action, rowIndex) => {
        return (
          <MaxInput
            max={record.debt}
            initValue={record.amt}
            index={rowIndex}
            callback={(amount = 0, e = rowIndex) => {
              changeHandle(amount, record.key);
            }}
            failback={() => blurHandle(record.key)}
            className="px-0"
            placeholder={t('transaction.marginDebt.enterValue')}
          />
        );
      },
    },
  ];

  return (
    <div className="margin-list personal-assets card-panel-2">
      <AssetHeader
        account={account}
        setAccount={setAccount}
        params={params}
        setRefresh={reloadHandle}
        refresh={reload}
        additionRightActions={[
          () => {
            return (
              <span
                onClick={() => withdrawHandle()}
                className={`btn br-5 fw-500 ${
                  auth && auth.isVerified ? 'btn--primary' : 'btn--authen'
                } `}
              >
                {t('txt-label-withdrawal')}
              </span>
            );
          },
        ]}
      />
      <div className="personal-assets-body card-panel-2-body pb-3">
        <div className="section-overview">
          <div className="overview-metric">
            <span className="overview-metric__key">
              {t('personal.asset.totalOverview.totalAsset')}
            </span>
            <span className="overview-metric__value">
              {cashBalance && numberFormat(cashBalance.totAsst, 0, '0')}
            </span>
          </div>
          <div className="overview-metric">
            <span className="overview-metric__key">
              {t('personal.asset.totalOverview.netAssetValue')}
            </span>
            <span className="overview-metric__value">
              {cashBalance && numberFormat(cashBalance.realAsst, 0, '0')}
            </span>
          </div>
          <div className="overview-metric">
            <span className="overview-metric__key">
              {t('personal.asset.totalOverview.withdrawable')}
            </span>
            <span className="overview-metric__value">
              {cashBalance && numberFormat(cashBalance.wdrawAvail, 0, '0')}
            </span>
          </div>
          <div className="overview-metric">
            <span className="overview-metric__key">
              {t('personal.asset.totalOverview.availableAdvance')}
            </span>
            <span className="overview-metric__value">
              {cashBalance && numberFormat(cashBalance.advanceAvail, 0, '0')}
            </span>
          </div>
          <div className="overview-metric">
            <span className="overview-metric__key">
              {t('personal.asset.totalOverview.marginDebt')}
            </span>
            <span className="overview-metric__value">
              {cashBalance && numberFormat(cashBalance.mgDebt, 0, '0')}
            </span>
          </div>
          <div className="overview-metric">
            <span className="overview-metric__key">
              {t('personal.asset.totalOverview.rTT')}
            </span>
            <span className="overview-metric__value">
              {cashBalance &&
                numberFormat(cashBalance.marginRatio, 2, '0') + '%'}
            </span>
          </div>
          <div className="overview-metric">
            <span className="overview-metric__key">
              {t('personal.asset.totalOverview.pP')}
            </span>
            <span className="overview-metric__value">
              {cashBalance && numberFormat(cashBalance.pp0, 0, '0')}
            </span>
          </div>
        </div>
        <PerfectScrollBar style={{ height: 'calc( 100% - 105px )' }}>
          <>
            <div className="d-flex flex-column mt-24">
              <Filter
                callback={loadListDebtHandle}
                title={t('txt-margin-debt')}
                statusSource={STATUS}
                reload={reload}
              />
              <PaginationTable
                pageSize={10}
                hasPaging
                columns={debtColumns}
                source={hDebt || []}
              />

              <div className="mt-3 d-flex justify-content-end align-items-center text-white gap-4">
                <span>
                  <span className="text--light3 text-name">
                    {`${t('txt-label-total-amount')}: `}&nbsp;
                  </span>
                  <span className="text--light text-name">
                    {numberFormat(totalAmount, 0, '0')}
                  </span>
                </span>
                {auth && auth.isVerified ? (
                  <button
                    className="btn btn-info fz_14 fw-500"
                    style={{ padding: '2px 28px' }}
                    disabled={!totalAmount || totalAmount < 1 || _chkErr}
                    onClick={() => procFeeHandle()}
                  >
                    {t('txt-label-pay')}
                  </button>
                ) : (
                  <button
                    className="btn btn--authen"
                    onClick={() => authenHandle()}
                  >
                    {t('txt-label-pay')}
                  </button>
                )}
              </div>
            </div>

            <div className="d-flex flex-column mt-24">
              {historyActiveTab == 0 && (
                <>
                  <Filter
                    callback={queryDataHandle}
                    titles={[
                      t('txt-label-payment-history'),
                      t('transaction.marginDebt.extenHis'),
                    ]}
                    titleAction={setHistoryActiveTab}
                    activeTitle={historyActiveTab}
                    statusSource={STATUS}
                    reload={reload}
                  />
                  <PaginationTable
                    pageSize={10}
                    columns={paymentColumns}
                    hasPaging
                    source={
                      hisDebt2 && hisDebt2.list && !!hisDebt2.list.length
                        ? hisDebt2.list
                        : []
                    }
                  />
                </>
              )}
              {historyActiveTab == 1 && (
                <>
                  <Filter
                    callback={queryExtendDataHandle}
                    titles={[
                      t('txt-label-payment-history'),
                      t('transaction.marginDebt.extenHis'),
                    ]}
                    titleAction={setHistoryActiveTab}
                    activeTitle={historyActiveTab}
                    statusSource={EXTEND_HIS_STATUS}
                    reload={reload}
                  />
                  <PaginationTable
                    pageSize={10}
                    hasPaging
                    columns={extendColumns}
                    source={hisExtend || []}
                  />
                </>
              )}
            </div>
          </>
        </PerfectScrollBar>
      </div>
      <MarginExtendingModal
        handleMarginExtend={handleMarginExtend}
        handleCancel={handleCancel}
        extendRecord={extendRecord}
        showModalExtend={showModalExtend}
        defaultAccount={defaultAccount}
      />

      <PopupMessage
        callback={() => {
          setResult(0);
          setError('');
        }}
        type={result}
        error={error}
      />
    </div>
  );
});

const Filter = withNamespaces('translations')(
  ({
    t,
    callback,
    title,
    titleAction,
    statusSource = [],
    titles = [],
    activeTitle = 0,
    reload,
  }) => {
    const currentDate = new Date();
    const _fromDate = new Date(
      currentDate.setDate(currentDate.getDate() - 180)
    );
    const [fromDate, setFromDate] = useState(_fromDate);
    const [toDate, setToDate] = useState(new Date());
    const [type, setType] = useState(statusSource[0]);

    useEffect(() => {
      if (callback && typeof callback == 'function') {
        callback(fromDate, toDate, type?.value);
      }
    }, [type, fromDate, toDate, reload]);

    const onChangeHandle = (fromDate, toDate) => {
      setFromDate(fromDate);
      setToDate(toDate);
    };

    return (
      <div className="transaction-content__header">
        <div className="transaction-content__title">
          {title && titles.length == 0 && (
            <span className="text-title mt-24 mb-0 text--light">{title}</span>
          )}
          {titles.length > 0 &&
            titles.map((t, i) => (
              <span
                className={`text-title mt-24 mb-0 cursor-pointer ${
                  activeTitle == i ? 'text--active' : 'text--light3'
                }`}
                onClick={() => {
                  if (titleAction && typeof titleAction == 'function')
                    titleAction(i);
                }}
              >
                {t}
              </span>
            ))}
        </div>
        <div className="transaction-filters">
          {statusSource && !!statusSource.length && (
            <ReactSelect
              placeholder={t('txt-all')}
              className="transaction-filters__select"
              classNamePrefix="filter-control-select"
              options={statusSource}
              isSearchable={false}
              components={{
                IndicatorSeparator: () => null,
              }}
              value={type}
              onChange={(value) => setType(value)}
            />
          )}

          <DatePicker
            startDate={fromDate}
            endDate={toDate}
            callback={onChangeHandle}
          />
        </div>
      </div>
    );
  }
);

const PopupMessage = withNamespaces('translations')(
  ({ t, type = 1, callback, error }) => {
    const theme = useSelector((state) => state?.client?.currentTheme);
    const [show, setShow] = useState(false);
    useEffect(() => {
      if (type < 1) {
        setShow(false);
      } else {
        setShow(true);
      }
    }, [type]);

    const closeModal = () => {
      if (callback && typeof callback == 'function') {
        callback();
      }
    };

    return (
      <Modal
        show={show}
        dialogClassName="wts-modal"
        backdropClassName="wts-modal-backdrop"
        contentClassName={`${
          theme?.name || 'dark'
        } wts-modal-content--sm  wts-modal-content transaction-notification`}
        onEscapeKeyDown={() => closeModal()}
        centered
        onHide={() => closeModal()}
        style={{ zIndex: 11111 }}
      >
        <div className="transaction-notification__header">
          <div
            className={`transaction-notification__logo ${
              type > 1 ? 'transaction-notification__logo--error' : ''
            }`}
          >
            <span className={`icon ${type == 1 ? 'iCheck' : 'iClose'}`}></span>
          </div>
        </div>
        <div className="transaction-notification__body">
          <span className="text-title text--light">
            {type == 1
              ? t('transaction.marginDebt.extendRequestSuccess')
              : t('transaction.marginDebt.extendRequestFail')}
          </span>
          <span className="text-desc text--light3">
            {type == 1 &&
              t('transaction.marginDebt.extendRequestSuccessMessage')}
            {type > 1 && error}
          </span>
        </div>
        <div className="transaction-notification__footer">
          <div className="transaction-notification__actions">
            <span className="btn btn--primary" onClick={() => closeModal()}>
              <span>{t('lbl-ok')}</span>
            </span>
          </div>
        </div>
      </Modal>
    );
  }
);

export default Index;
