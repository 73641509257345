import { CANCEL_ORDER_REQUEST_ERROR } from '../privData/constants';
import {
  GET_LIST_PINEFOLIO_REQUEST,
  GET_THEME_DETAIL_SUCCESS,
  RESET_THEME_DETAIL,
  GET_THEME_DETAIL,
  OPEN_PLAN_DETAIL_MODAL,
  HANDLE_DEPOSIT_PINEFOLIO,
  GET_PINEFOLIO_ORDER_LIST_REQUEST,
  GET_PINEFOLIO_ORDER_LIST_SUCCESS,
  GET_PINEFOLIO_ORDER_LIST_ERROR,
  GET_PINEFOLIO_ORDER_DTL_REQUEST,
  GET_PINEFOLIO_ORDER_DTL_SUCCESS,
  GET_PINEFOLIO_ORDER_DTL_ERROR,
  GET_PINEFOLIO_ORDER_DTL_ST_REQUEST,
  GET_PINEFOLIO_ORDER_DTL_ST_SUCCESS,
  GET_PINEFOLIO_ORDER_DTL_ST_ERROR,
  CANCEL_PINEFOLIO_ORDER_REQUEST,
  CANCEL_PINEFOLIO_ORDER_SUCCESS,
  CANCEL_PINEFOLIO_ORDER_ERROR,
  SET_CURRENT_PINEFOLIO,
  RESET_CURRENT_PINEFOLIO,
  REGIST_PINEFOLIO_SUBACCOUNT,
  GET_PINEFOLIO_PURCHASE_POWER_REQUEST,
  SET_PF_ORDER,
  REGIST_PINEFOLIO_SUBACCOUNT_CONTRACT,
  ORDER_PF_REQUEST,
  GET_PINEFOLIO_INVESTMENT_PLAN_REQUEST,
  GET_PINEFOLIO_INVESTMENT_PLAN_SUCCESS,
  GET_PINEFOLIO_INVESTMENT_PLAN_ERROR,
  GET_PINEFOLIO_INVESTMENT_PLAN_HISTORY_REQUEST,
  GET_PINEFOLIO_INVESTMENT_PLAN_HISTORY_SUCCESS,
  GET_PINEFOLIO_INVESTMENT_PLAN_HISTORY_ERROR,
  GET_PF_ANA_REPORT_REQUEST,
  GET_PF_ANA_REPORT_SUCCESS,
  GET_PF_ANA_REPORT_ERROR,
  GET_PF_ANA_REPORT_CAT_TP_REQUEST,
  GET_PF_ANA_REPORT_CAT_TP_SUCCESS,
  GET_PF_ANA_REPORT_CAT_TP_ERROR,
  GET_PF_TD_LIST_REQUESTING,
  GET_PF_TD_LIST_SUCCESS,
  GET_PF_TD_LIST_ERROR,
  GET_PF_IP_LIST_DTL_REQUESTING,
  GET_PF_IP_LIST_DTL_SUCCESS,
  GET_PF_IP_LIST_DTL_ERROR,
  HANDLE_EDIT_PLAN_MONITOR,
  HANDLE_CREATE_PLAN_MONITOR,
  GET_PF_IP_ONE_REQUESTING,
  GET_PF_IP_ONE_SUCCESS,
  GET_PF_IP_ONE_ERROR,
  DEPOSIT_PF_IP_REQUESTING,
  DEPOSIT_PF_IP_SUCCESS,
  DEPOSIT_PF_IP_ERROR,
  GET_PF_PORTFOLIO_REQUEST,
  GET_PF_PORTFOLIO_DETAIL_REQUEST,
  RESET_PF_PORTFOLIO_DETAIL_REQUEST,
  GET_PF_LIST_REQUEST,
  GET_PF_BY_CODE_REQUEST,
  GET_PF_CHART_REQUEST,
  GET_PF_INTERATE_MST_REQUESTING,
  GET_PF_INTERATE_MST_SUCCESS,
  GET_PF_INTERATE_MST_ERROR,
  GET_EST_PF_PLAN_REQUESTING,
  GET_EST_PF_PLAN_SUCCESS,
  GET_EST_PF_PLAN_ERROR,
  GET_EST_PF_PLAN_OVER_REQUESTING,
  GET_EST_PF_PLAN_OVER_SUCCESS,
  GET_EST_PF_PLAN_OVER_ERROR,
  SAVE_EST_PLAN,
  HANDLE_OPEN_CREATE_PLAN,
  SET_PF_REBALANCE,
  SET_HAD_PINE_ACCOUNT,
  SET_OPEN_PINE_ACCOUNT_POPUP,
  SELL_PF_ODD_ORDER,
  GET_POSITION_OUTSIDE_PF,
  RELOAD_POSITION_OUTSIDE_PF,
  SET_PF_BOND_DEMO,
  SET_PF_BOND_ORDER,
  UPDATE_PLAN_REMINDER,
  GET_UPDATE_PLAN_CONTRACT,
  GET_UPDATE_PLAN_CONTRACT_SUCCESS,
  GET_PLAN_MODIFY_INFO,
  GET_PLAN_MODIFY_INFO_SUCCESS,
  UPDATE_PF_PLAN_REQUEST,
  GET_PF_VALUE_INFO_REQUEST,
  GET_PF_VALUE_INFO_SUCCESS,
  GET_PF_LIST_BOND_FOR_SELL_REQUEST,
  GET_PF_LIST_BOND_FOR_SELL_SUCCESS,
  GET_PF_LIST_BOND_FOR_SELL_ERROR,
  SET_PF_BOND_POPUP,
} from './constants';

export const handleOpenPlanDetailModal = function handleOpenPlanDetailModal(
  params
) {
  return {
    type: OPEN_PLAN_DETAIL_MODAL,
    params,
  };
};

export const handleDepositPinefolio = function handleDepositPinefolio(data) {
  return {
    type: HANDLE_DEPOSIT_PINEFOLIO,
    data,
  };
};

export const getPThemes = (params) => {
  return {
    type: GET_LIST_PINEFOLIO_REQUEST,
    params,
  };
};

export const getThemeDetail = (params) => {
  return {
    type: GET_THEME_DETAIL,
    params,
  };
};

export const setThemeDetail = (data) => {
  return {
    type: GET_THEME_DETAIL_SUCCESS,
    data,
  };
};

export const resetThemeDetail = () => {
  return {
    type: RESET_THEME_DETAIL,
  };
};

export const setCurrentPinefolio = (data) => {
  return {
    type: SET_CURRENT_PINEFOLIO,
    data,
  };
};

export const resetPinefolioDetail = () => {
  return {
    type: RESET_CURRENT_PINEFOLIO,
  };
};

export const setOrder = (data) => {
  return {
    type: SET_PF_ORDER,
    data,
  };
};

export const getPinefilioOrderListRequest = (params) => {
  return {
    type: GET_PINEFOLIO_ORDER_LIST_REQUEST,
    params,
  };
};

export const getPinefilioOrderListSuccess = (pinefolioOrderList) => {
  return {
    type: GET_PINEFOLIO_ORDER_LIST_SUCCESS,
    pinefolioOrderList: pinefolioOrderList,
  };
};

export const getPinefilioOrderListEror = (data) => {
  return {
    type: GET_PINEFOLIO_ORDER_LIST_ERROR,
    data,
  };
};

export const getPinefilioOrderDtlRequest = (params) => {
  return {
    type: GET_PINEFOLIO_ORDER_DTL_REQUEST,
    params,
  };
};

export const getPinefilioOrderDtlSuccess = (pinefolioOrderDtl) => {
  return {
    type: GET_PINEFOLIO_ORDER_DTL_SUCCESS,
    pinefolioOrderDtl: pinefolioOrderDtl,
  };
};

export const getPinefilioOrderDtlError = (data) => {
  return {
    type: GET_PINEFOLIO_ORDER_DTL_ERROR,
    data,
  };
};

export const getPinefilioOrderDtlStRequest = (params) => {
  return {
    type: GET_PINEFOLIO_ORDER_DTL_ST_REQUEST,
    params,
  };
};

export const getPinefilioOrderDtlStSuccess = (pinefolioOrderDtlSt) => {
  return {
    type: GET_PINEFOLIO_ORDER_DTL_ST_SUCCESS,
    pinefolioOrderDtlSt: pinefolioOrderDtlSt,
  };
};

export const getPinefilioOrderDtlStError = (data) => {
  return {
    type: GET_PINEFOLIO_ORDER_DTL_ST_ERROR,
    data,
  };
};

export const cancelPinefolioOrderRequest = (data) => {
  return {
    type: CANCEL_PINEFOLIO_ORDER_REQUEST,
    data,
  };
};

export const cancelPinefolioOrderSuccess = (cancelOrder) => {
  return {
    type: CANCEL_PINEFOLIO_ORDER_SUCCESS,
    cancelOrder,
  };
};

export const cancelPineOrderError = (error) => {
  return {
    type: CANCEL_PINEFOLIO_ORDER_ERROR,
    error,
  };
};

export const getPinefolioInvestmentPlanRequest = (data) => {
  return {
    type: GET_PINEFOLIO_INVESTMENT_PLAN_REQUEST,
    data,
  };
};

export const getPinefolioInvestmentPlanSuccess = (investmentPlan) => {
  return {
    type: GET_PINEFOLIO_INVESTMENT_PLAN_SUCCESS,
    investmentPlan,
  };
};

export const getPinefolioInvestmentPlanError = (error) => {
  return {
    type: GET_PINEFOLIO_INVESTMENT_PLAN_ERROR,
    error,
  };
};

export const getPinefolioInvestmentPlanHistoryRequest = (data) => {
  return {
    type: GET_PINEFOLIO_INVESTMENT_PLAN_HISTORY_REQUEST,
    data,
  };
};

export const getPinefolioInvestmentPlanHistorySuccess = (
  investmentPlanHistory
) => {
  return {
    type: GET_PINEFOLIO_INVESTMENT_PLAN_HISTORY_SUCCESS,
    investmentPlanHistory,
  };
};

export const getPinefolioInvestmentPlanHistoryError = (error) => {
  return {
    type: GET_PINEFOLIO_INVESTMENT_PLAN_HISTORY_ERROR,
    error,
  };
};

export const getPfAnaRptRequest = (data) => {
  return {
    type: GET_PF_ANA_REPORT_REQUEST,
    data,
  };
};

export const getPfAnaRptSuccess = (pfAnaRpt) => {
  return {
    type: GET_PF_ANA_REPORT_SUCCESS,
    pfAnaRpt,
  };
};

export const getPfAnaRptError = (error) => {
  return {
    type: GET_PF_ANA_REPORT_ERROR,
    error,
  };
};

export const getPfAnaRptCatTpRequest = (data) => {
  return {
    type: GET_PF_ANA_REPORT_CAT_TP_REQUEST,
    data,
  };
};

export const getPfAnaRptCatTpSuccess = (pfAnaRptCatTp) => {
  return {
    type: GET_PF_ANA_REPORT_CAT_TP_SUCCESS,
    pfAnaRptCatTp,
  };
};

export const getPfAnaRptCatTpError = (error) => {
  return {
    type: GET_PF_ANA_REPORT_CAT_TP_ERROR,
    error,
  };
};

export const getPfTdListRequest = (data) => {
  return {
    type: GET_PF_TD_LIST_REQUESTING,
    data,
  };
};

export const getPfTdListSuccess = (pfTdList) => {
  return {
    type: GET_PF_TD_LIST_SUCCESS,
    pfTdList,
  };
};

export const getPfTdListError = (error) => {
  return {
    type: GET_PF_TD_LIST_ERROR,
    error,
  };
};

export const getPfIpListDtlRequest = (data) => {
  return {
    type: GET_PF_IP_LIST_DTL_REQUESTING,
    data,
  };
};

export const getPfIpListDtlSuccess = (pfIpListDtl) => {
  return {
    type: GET_PF_IP_LIST_DTL_SUCCESS,
    pfIpListDtl,
  };
};

export const getPfIpListDtlError = (error) => {
  return {
    type: GET_PF_IP_LIST_DTL_ERROR,
    error,
  };
};

export const handleEditPlanMonitor = (data) => {
  return {
    type: HANDLE_EDIT_PLAN_MONITOR,
    data,
  };
};

export const handleCreatePlanMonitor = (data) => {
  return {
    type: HANDLE_CREATE_PLAN_MONITOR,
    data,
  };
};
export const registPinefolioAccount = (params, currentListAccounts) => {
  return {
    type: REGIST_PINEFOLIO_SUBACCOUNT,
    params,
    currentListAccounts,
  };
};

export const getPFPurchasePower = (params) => {
  return {
    type: GET_PINEFOLIO_PURCHASE_POWER_REQUEST,
    params,
  };
};

export const getOpenPFAccountContract = (params) => {
  return {
    type: REGIST_PINEFOLIO_SUBACCOUNT_CONTRACT,
    params,
  };
};

export const orderPF = (params, onOrderSuccess) => {
  return {
    type: ORDER_PF_REQUEST,
    params,
    onOrderSuccess,
  };
};

export const getPFIPOneRequest = (data) => {
  return {
    type: GET_PF_IP_ONE_REQUESTING,
    data,
  };
};

export const getPFIPOneSuccess = (pfIpOne) => {
  return {
    type: GET_PF_IP_ONE_SUCCESS,
    pfIpOne,
  };
};

export const getPFIPOneError = (error) => {
  return {
    type: GET_PF_IP_ONE_ERROR,
    error,
  };
};

export const depositPFIPRequest = (data) => {
  return {
    type: DEPOSIT_PF_IP_REQUESTING,
    data,
  };
};

export const depositPFIPSuccess = (isDepositSuccess) => {
  return {
    type: DEPOSIT_PF_IP_SUCCESS,
    isDepositSuccess,
  };
};

export const depositPFIPError = (error) => {
  return {
    type: DEPOSIT_PF_IP_ERROR,
    error,
  };
};

export const getPFPortfolio = (params) => {
  return {
    type: GET_PF_PORTFOLIO_REQUEST,
    params,
  };
};

export const getPFPortfolioDetail = (params) => {
  return {
    type: GET_PF_PORTFOLIO_DETAIL_REQUEST,
    params,
  };
};

export const resetPFPortfolioDetail = () => {
  return {
    type: RESET_PF_PORTFOLIO_DETAIL_REQUEST,
  };
};

export const getPFList = (params) => {
  return {
    type: GET_PF_LIST_REQUEST,
    params,
  };
};

export const getPFByCode = (pfCode) => {
  return {
    type: GET_PF_BY_CODE_REQUEST,
    pfCode,
  };
};

export const getPFChart = (params) => {
  return {
    type: GET_PF_CHART_REQUEST,
    params,
  };
};

export const getPFInterateMstRequest = (data) => {
  return {
    type: GET_PF_INTERATE_MST_REQUESTING,
    data,
  };
};

export const getPFInterateMstSuccess = (pfInterateList) => {
  return {
    type: GET_PF_INTERATE_MST_SUCCESS,
    pfInterateList,
  };
};

export const getPFInterateMstError = (error) => {
  return {
    type: GET_PF_INTERATE_MST_ERROR,
    error,
  };
};

export const getEstPFPlanRequest = (data) => {
  return {
    type: GET_EST_PF_PLAN_REQUESTING,
    data,
  };
};

export const getEstPFPlanSuccess = (estPFPlan) => {
  return {
    type: GET_EST_PF_PLAN_SUCCESS,
    estPFPlan,
  };
};
export const getEstPFPlanError = (error) => {
  return {
    type: GET_EST_PF_PLAN_ERROR,
    error,
  };
};

export const getEstPFPlanOverRequest = (data) => {
  return {
    type: GET_EST_PF_PLAN_OVER_REQUESTING,
    data,
  };
};

export const getEstPFPlanOverSuccess = (estPFPlanOver) => {
  return {
    type: GET_EST_PF_PLAN_OVER_SUCCESS,
    estPFPlanOver,
  };
};
export const getEstPFPlanOverError = (error) => {
  return {
    type: GET_EST_PF_PLAN_OVER_ERROR,
    error,
  };
};

export const saveEstPlan = (data) => {
  return {
    type: SAVE_EST_PLAN,
    data,
  };
};

export const openCreatePlan = (openCreatePlanData) => {
  return {
    type: HANDLE_OPEN_CREATE_PLAN,
    openCreatePlanData,
  };
};
export const setPFRebalance = (pfCode) => {
  return {
    type: SET_PF_REBALANCE,
    pfCode,
  };
};
export const setHadPineAccount = (value) => {
  return {
    type: SET_HAD_PINE_ACCOUNT,
    value,
  };
};

export const openAccountPopup = (value) => {
  return {
    type: SET_OPEN_PINE_ACCOUNT_POPUP,
    value,
  };
};

export const sellSymbolPFOdd = (params, getPfOddSellable) => {
  return {
    type: SELL_PF_ODD_ORDER,
    params,
    getPfOddSellable,
  };
};

export const getPosOutsidePf = (params) => {
  return {
    type: GET_POSITION_OUTSIDE_PF,
    params,
  };
};

export const reloadPosOutSidePF = (value) => {
  return {
    type: RELOAD_POSITION_OUTSIDE_PF,
    value,
  };
};

export const setPFBondDemo = (data) => {
  return {
    type: SET_PF_BOND_DEMO,
    data,
  };
};

export const setPFBondOrder = (data) => {
  return {
    type: SET_PF_BOND_ORDER,
    data,
  };
};

export const updatePlanReminder = (params, callback) => {
  return {
    type: UPDATE_PLAN_REMINDER,
    params,
    callback,
  };
};
export const getPlanUpdateContract = (params, callback) => {
  return {
    type: GET_UPDATE_PLAN_CONTRACT,
    params,
    callback,
  };
};

export const getPlanUpdateContractSuccess = (data) => {
  return {
    type: GET_UPDATE_PLAN_CONTRACT_SUCCESS,
    data,
  };
};

export const getPlanModifyInfoRequest = (params) => {
  return {
    type: GET_PLAN_MODIFY_INFO,
    params,
  };
};

export const getPlanModifyInfoRequestSuccess = (data) => {
  return {
    type: GET_PLAN_MODIFY_INFO_SUCCESS,
    data,
  };
};

export const updatePFPlanRequest = (params, callback) => {
  return {
    type: UPDATE_PF_PLAN_REQUEST,
    params,
    callback,
  };
};

export const getPfValueInfoRequest = (params) => {
  return {
    type: GET_PF_VALUE_INFO_REQUEST,
    params,
  };
};

export const getPfValueInfoSuccess = (data) => {
  return {
    type: GET_PF_VALUE_INFO_SUCCESS,
    data,
  };
};

export const getPfListBondForSellRequest = (params) => {
  return {
    type: GET_PF_LIST_BOND_FOR_SELL_REQUEST,
    params,
  };
};

export const getPfListBondForSellSuccess = (data) => {
  return {
    type: GET_PF_LIST_BOND_FOR_SELL_SUCCESS,
    data,
  };
};

export const getPfListBondForSellError = (errors) => {
  return {
    type: GET_PF_LIST_BOND_FOR_SELL_ERROR,
    errors,
  };
};

export const setPFBondPopup = (data) => {
  return {
    type: SET_PF_BOND_POPUP,
    data,
  };
};
