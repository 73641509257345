import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import PerfectScrollBar from 'react-perfect-scrollbar';
import { Col, Card, Button } from 'react-bootstrap';
import {
  makeGetToken,
  makeGetDefaultAccount,
  makeGetRightList,
  makeGetAuth,
  makeGetSummaryRightList,
  makeGetCashBalance,
  makeGetListAccount,
} from '../../lib/selector';

import { rightListRequest } from '../../containers/cash/actions';

import { find, sumBy, filter, some, isEqual } from 'lodash';
// import { GiAnticlockwiseRotation } from 'react-icons/gi';

import {
  numberFormat,
  formatDate,
  getTransferStatus,
  _formatDate2,
  StringToInt,
  getMessageError,
  mapRightType,
  checkRightEndDate,
  StringToDouble,
} from '../../util';
import FormQuyenCoPhieu from './form/formQuyenCoPhieu';
import { v4 as uuidv4 } from 'uuid';
import PaginationTable from '../PaginationTable';
import {
  setAuth,
  setToast,
  unsetClient,
} from '../../containers/client/actions';
import { rightEventRequest } from '../../containers/summary/actions';
import InputMask from '../input';
import { handleApiErrors } from '../../lib/api-errors';

import SelectUserCash from '../select/selectUserCash';
import { setDefaultAccount } from '../../containers/socket/actions';
import { translate } from 'react-i18next';
import { Logout, logoutRequest } from '../modal/login/actions';
import { cashBalanceRequest } from '../../containers/privData/actions';
import { ReloadButton } from '../btnReload';
import { removeCookie } from '../../lib/storages';

const waitFor = (ms) => new Promise((r) => setTimeout(r, ms));
const asyncForEach = async (array, callback) => {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
};

const appUrl = `${process.env.REACT_APP_API_URL}`;

class DangKyQuyen extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      msg: [],
      rightList: [],
    };
  }

  componentDidMount() {
    const { defaultAccount } = this.props;
    if (defaultAccount) {
      this._handleReload();
    }
  }

  componentDidUpdate(preProps) {
    const { defaultAccount, rightList } = this.props;
    if (defaultAccount && !isEqual(defaultAccount, preProps.defaultAccount)) {
      this._handleReload();
    }

    if (rightList && rightList !== preProps.rightList) {
      if (!!rightList.list.length) {
        let _right = filter(
          rightList.list,
          (o) => o.rghtSubTp === '03' && checkRightEndDate(o.scrEndt)
        );
        if (_right) {
          // const year = str.substring(0, 4);
          // const month = str.substring(4, 6);
          // const day = str.slice(-2);

          _right.map((item) => {
            const uuid = uuidv4();
            item.key = uuid;
            item.amt = '';
            item.err = '';
            item.maxVol = item.stockQuantityRemain;
            // item.maxVol = item.rightQty - item.widrStockQty;

            return item;
          });
        }
        this.setState({ rightList: _right });
      } else {
        this.setState({ rightList: [] });
      }
    }
  }

  _handleGetRightList = () => {
    const { defaultAccount, token } = this.props;

    if (!token || !defaultAccount) return;

    const uuid = uuidv4();
    const params = {
      group: 'CORE',
      user: token.user,
      session: token.session,
      cmd: 'getRightList',
      rqId: uuid,
      channel: 'WTS',
      data: {
        acntNo: defaultAccount.acntNo,
        subAcntNo: defaultAccount.subAcntNo,
      },
    };

    this.props.dispatch(rightEventRequest(params));
  };

  _handleGetRightHisList = () => {
    const { token, defaultAccount } = this.props;

    if (!defaultAccount || !token) return;

    const numDay = token && token['config'] ? token['config']['numDay'] : 30;

    const d = new Date();
    d.setDate(d.getDate() - numDay);

    const uuid = uuidv4();

    console.log('_handleLoadRightList');

    const resData = {
      group: 'CORE',
      user: token.user,
      session: token.session,
      cmd: 'GetRegRightList',
      rqId: uuid,
      channel: 'WTS',
      data: {
        acntNo: defaultAccount.acntNo,
        subAcntNo: defaultAccount.subAcntNo,
        fromDate: formatDate(d, ''),
        toDate: formatDate(new Date(), ''),
      },
    };

    this.props.dispatch(rightListRequest(resData));
  };

  _handleLoadCashBalance = () => {
    const { defaultAccount, token } = this.props;
    if (!defaultAccount || !token) return;

    const uuid = uuidv4();

    const resData = {
      group: 'CORE',
      user: token.user,
      session: token.session,
      cmd: 'getTotalAssetAll',
      rqId: uuid,
      channel: 'WTS',
      data: {
        acntNo: defaultAccount.acntNo,
        subAcntNo: defaultAccount.subAcntNo,
      },
    };
    this.props.dispatch(cashBalanceRequest(resData));
  };

  _handleAuthen = () => {
    this.props.dispatch(setAuth());
  };

  _handleChange = (e) => {
    const { value, name } = e.target;
    let { rightList } = this.state;
    const { t } = this.props;

    if (!rightList || !rightList.length) return;

    rightList.map((item) => {
      if (item.key !== name) return item;

      item.amt = value;
      item.err =
        StringToInt(item.amt) > item.maxVol
          ? numberFormat(item.maxVol, 0, '0')
          : '';

      return item;
    });
    // console.log(advMatch);
    this.setState({ rightList });
  };

  _handleBlur = (name) => {
    let { rightList } = this.state;
    const { t } = this.props;

    if (!rightList || !rightList.length) return;

    rightList.map((item) => {
      if (item.key !== name) return item;

      item.err =
        StringToInt(item.amt) > item.maxVol
          ? numberFormat(item.maxVol, 0, '0')
          : '';
      return item;
    });
    this.setState({ rightList });
  };

  _handleProcFee = () => {
    let { rightList } = this.state;
    if (!some(rightList, (o) => o.err && o.err.length > 0))
      this._handleAdvanceFee();
  };

  _handleAdvanceFee = async () => {
    const { token, auth, defaultAccount } = this.props;

    let { rightList } = this.state;
    if (!rightList) return;
    try {
      await asyncForEach(rightList, async (element) => {
        if (StringToInt(element.amt) > 0) {
          await waitFor(200);

          const uuid = uuidv4();
          const params = {
            group: 'CORE',
            user: token.user,
            session: token.session,
            cmd: 'RegistrationRight',
            rqId: uuid,
            channel: 'WTS',
            type: auth.type,
            token: auth.token,
            data: {
              acntNo: defaultAccount.acntNo,
              subAcntNo: defaultAccount.subAcntNo,
              rghtCode: element.rghtSubTp,
              basdt: element.basdt,
              symbol: element.symbol + '',
              qty: StringToInt(element.amt),
              price: element.subscriptionPrice,
            },
          };

          this._handleNewRight(params);
        }
      });

      await waitFor(200);

      this._handleReload();
    } catch (error) {
      console.log(error);
    }
  };

  _handleNewRight = (params) => {
    const url = `${appUrl}/CoreServlet.pt`;
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
      body: JSON.stringify(params),
    })
      .then(handleApiErrors)
      .then((response) => response.json())
      .then((res) => {
        console.log(res, params);
        if (res.http !== 200) {
          if (res.http === 401) {
            const { token } = this.props;
            if (token) this.props.dispatch(logoutRequest(token));

            this.props.dispatch(unsetClient());
            removeCookie('token');
            removeCookie('authen');

            this.props.dispatch(Logout());
            this._handleToast(this.props.t('txt-valid-loss-session'));
          } else {
            this._handleToast(getMessageError(res, this.props.store.getState));
          }
        } else {
          this._handleToast(this.props.t('txt-success'));
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  _handleToast = (msg) => {
    const toastMsg = {
      id: Math.random(),
      msg: msg,
      title: this.props.t('txt-notice'),
    };
    this.props.dispatch(setToast(toastMsg));
  };

  _handleReload = () => {
    this._handleLoadCashBalance();
    this._handleGetRightList();
    this._handleGetRightHisList();
  };

  _handleChangeUser = (user) => {
    console.log(user);
    this.props.dispatch(setDefaultAccount(user));
  };

  render() {
    const { msg, rightList } = this.state;

    const { rightHisList, cashBalance, auth, defaultAccount, listAccount, t } =
      this.props;

    console.log(rightHisList);

    const _totalAdv = sumBy(
      rightList,
      (o) => StringToInt(o.amt) * o.subscriptionPrice
    );
    const _chkErr = some(rightList, (o) => o.err && o.err.length > 0);

    const columns = [
      {
        key: 'symbol',
        text: t('txt-symbol'),
        className: 'text-center',
        align: 'center',
      },
      {
        text: t('txt-type'),
        className: 'text-center',
        align: 'center',
        cell: (record) => {
          return mapRightType(record.rghtSubTp);
        },
      },
      {
        key: 'basdt',
        text: t('txt-label-expected-date'),
        className: 'text-center',
        align: 'center',
        cell: (record) => {
          return _formatDate2(record.basdt);
        },
      },
      {
        text: t('txt-label-registration-date'),
        className: 'text-center',
        align: 'center',
        cell: (record) => {
          if (record.rghtSubTp === '01') {
            if (record.dpstStockQty > 0) return _formatDate2(record.stockDate);

            if (record.dpstCashAmt > 0) return _formatDate2(record.cashDate);
          }

          return _formatDate2(record.stockDate);
        },
      },
      {
        key: 'balanceQuantity',
        text: t('txt-label-quantity-record-date'),
        className: 'text-right',
        align: 'center',
        cell: (record) => {
          return numberFormat(record.balanceQuantity, 0, '0');
        },
      },
      {
        key: 'rightRate',
        text: t('txt-label-ratio'),
        className: 'text-center',
        align: 'center',
        cell: (record) => {
          if (record.rghtSubTp === '01') {
            return numberFormat(StringToDouble(record.rightRate), 2, '0') + '%';
          }
          return record.rightRate;
        },
      },
      {
        text: t('txt-stock-waiting'),
        className: 'text-right',
        align: 'center',
        cell: (record) => {
          return numberFormat(record.dpstStockQty, 0, '0');
        },
      },
      {
        text: t('txt-asset-collateral'),
        className: 'text-right',
        align: 'center',
        cell: (record) => {
          return numberFormat(record.dpstCashAmt, 0, '0');
        },
      },
    ];

    return (
      <Fragment>
        <div className="d-flex flex-row mt-3 mb-2">
          <span
            className="mr-auto ml-2 text-white"
            style={{ fontSize: '18px' }}
          >
            {t('txt-label-right-subscription')}
          </span>
        </div>
        <PerfectScrollBar>
          <div className="d-flex flex-column mt-2 mr-3 ml-3">
            <Card className="col-12 p-0">
              <Card.Body style={{ borderRadius: '5px' }}>
                <div className="d-flex align-items-center">
                  <Col
                    md="auto"
                    className="p-3 d-flex align-items-center"
                    style={{ borderRight: '1px solid #42465b' }}
                  >
                    {/* <span className="text-content fz_14">Mã chứng khoán</span>
                    <input
                      type="text"
                      placeholder="Nhập mã CK"
                      className="form-control form-input fz_14 ml-2 fw-500"
                      style={{ width: '150px' }}
                    /> */}
                    <SelectUserCash
                      defVal={defaultAccount}
                      grOptions={listAccount}
                      handleChangeUser={this._handleChangeUser}
                      clsContainer="selCashOut"
                    />
                  </Col>
                  <Col
                    xs
                    className="d-flex flex-row"
                    style={{ borderRight: '1px solid #42465b' }}
                  >
                    <span className="text-content fz_14">
                      {t('txt-label-max-subscription')}
                    </span>
                    <span className="text-white fz_14 fw-500 ml-2">
                      {cashBalance &&
                        numberFormat(cashBalance.wdrawAvail, 0, '0')}
                    </span>
                  </Col>
                  <Col md="auto" className="p-3">
                    <ReloadButton
                      type="button"
                      className="ml-2 btn-reload"
                      onClick={this._handleReload}
                    />
                    {/* <button
                      className="ml-2 btn-reload"
                      onClick={() => this._handleReload()}
                    >
                      <GiAnticlockwiseRotation className="fz_14 text-white" />
                    </button> */}
                  </Col>
                </div>
              </Card.Body>
            </Card>
            <table className="table table-bordered mt-3">
              <thead>
                <tr>
                  <th className="text-center">{t('txt-symbol')}</th>
                  <th className="text-center">
                    {t('txt-label-owned-quantity')}
                  </th>
                  <th className="text-center">{t('txt-label-start-date')}</th>
                  <th className="text-center">{t('txt-label-base-date')}</th>
                  <th className="text-center">
                    {t('txt-label-remaining-quantity')}
                  </th>
                  <th className="text-center">
                    {t('txt-label-purchased-quantity')}
                  </th>
                  <th className="text-center">
                    {t('txt-label-maximum-quantity')}
                  </th>
                  <th className="text-center">{t('txt-price')}</th>
                  <th className="text-center">{t('txt-vol')}</th>
                </tr>
              </thead>
              <tbody>
                {rightList &&
                  !!rightList.length &&
                  rightList.map((item) => (
                    <tr key={item.key}>
                      <td className="text-center">{item.symbol}</td>
                      <td className="text-right">
                        {numberFormat(item.balanceQuantity, 0, '0')}
                      </td>
                      <td className="text-center">
                        {_formatDate2(item.scrStdt)}
                      </td>
                      <td className="text-center">
                        {_formatDate2(item.scrEndt)}
                      </td>
                      <td className="text-right">
                        {numberFormat(
                          item.dpstStockQty + item.stockQuantityRemain,
                          0,
                          '0'
                        )}
                      </td>
                      <td className="text-right">
                        {numberFormat(item.dpstStockQty, 0, '0')}
                      </td>
                      <td className="text-right">
                        {numberFormat(item.maxVol, 0, '0')}
                      </td>
                      <td className="text-right">
                        {numberFormat(item.subscriptionPrice, 0, '0')}
                      </td>
                      <td style={{ width: '150px' }}>
                        <InputMask
                          maxVal={item.maxVol}
                          name={item.key}
                          value={item.amt}
                          handleChange={this._handleChange}
                          handleBlur={this._handleBlur}
                        />
                        {item.err && (
                          <div
                            style={{
                              color: 'rgb(255, 85, 85)',
                              fontSize: '0.75rem',
                            }}
                          >
                            {`${t('txt-title-max')}: ${item.err}`}
                          </div>
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <div className="mt-2 d-flex flex-row-reverse text-white">
              <span className="fz_14 fw-500">{numberFormat(_totalAdv, 0)}</span>
              <span className="fz_14">
                {`${t('txt-label-total-amount')}: `}&nbsp;
              </span>
            </div>
            <div className="mt-2 d-flex flex-row-reverse text-white">
              {auth && auth.isVerified ? (
                <button
                  disabled={!_totalAdv || _totalAdv < 1 || _chkErr}
                  className="btn btn--primary"
                  style={{ padding: '2px 28px' }}
                  onClick={() => this._handleProcFee()}
                >
                  {t('txt-registration')}
                </button>
              ) : (
                <button
                  className="btn btn--authen fw-500 w-140"
                  onClick={() => this._handleAuthen()}
                >
                  {t('txt-registration')}
                </button>
              )}
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center mt-4">
            <label className="text-white text-uppercase ml-2 fz_14">
              {t('txt-label-look-right-info')}
            </label>
            <FormQuyenCoPhieu />
          </div>
          <PaginationTable
            pageSize={15}
            columns={columns}
            source={
              this.props.rightList && this.props.rightList.list
                ? this.props.rightList.list
                : []
            }
          />
        </PerfectScrollBar>
      </Fragment>
    );
  }
}

const makeMapStateToProps = () => {
  const getToken = makeGetToken();
  const getAuth = makeGetAuth();
  const getCashBalance = makeGetCashBalance();
  const getListAccount = makeGetListAccount();
  const getDefaultAccount = makeGetDefaultAccount();
  const getSummaryRightList = makeGetSummaryRightList();
  const getRightList = makeGetRightList();

  const mapStateToProps = (state) => {
    return {
      token: getToken(state),
      auth: getAuth(state),
      listAccount: getListAccount(state),
      defaultAccount: getDefaultAccount(state),
      cashBalance: getCashBalance(state),

      rightHisList: getRightList(state),
      rightList: getSummaryRightList(state),
    };
  };
  return mapStateToProps;
};

export default connect(makeMapStateToProps)(
  translate('translations')(DangKyQuyen)
);
