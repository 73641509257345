import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IndexIndicator from '../../components/banggia/indexIndicator';
import PriceTable from '../../components/banggia/priceTable';
import { makeGetToken } from '../../lib/selector';
import { getCategory } from '../../lib/storages';

const Home = (props) => {
  const dispatch = useDispatch();
  const token = useSelector(makeGetToken());

  useEffect(() => {
    if (!token) {
      getCategory({ dispatch });
    }
  }, []);

  const {
    match: { params },
    history,
  } = props;
  return (
    <>
      <IndexIndicator />
      <PriceTable categoryId={params.categoryId} history={history} />
    </>
  );
};

export default Home;
