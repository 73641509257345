import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

const Countdown = ({ value = 30, renderContent, onCountdownEnd }) => {
  const dispatch = useDispatch();
  const [seconds, setSeconds] = useState(value);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setSeconds((prevSeconds) =>
        prevSeconds > 0 ? prevSeconds - 1 : prevSeconds
      );
    }, 1000);

    return () => {
      onCountdownEnd();
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    if (seconds === 0) {
      onCountdownEnd();
    }
  }, [seconds]);

  return <React.Fragment>{renderContent(seconds)}</React.Fragment>;
};

export default Countdown;
