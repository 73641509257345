import { removeCookie } from './storages';

export function handleApiErrors(response) {
  if (!response.ok) throw Error(response.statusText);
  return response;
}

export function handlePrivateApiErrors(response, action) {
  let titleMsg = 'Thông báo';

  if (action === 'ping') return { status: true, data: action };
  if (action === 'logout')
    return {
      status: false,
      type: 'REQUEST_PRIVATE_FALSE',
      data: {
        id: Math.random(),
        msg: 'Quý khách cần đăng nhập lại để tiếp tục!',
        title: titleMsg,
      },
    };

  // console.log(response);

  if (response?.http) {
    if (response.http === 200) {
      if (action === 'getSymbolInfo') {
        return {
          status: true,
          data: response.data,
          dataMargin: response.dataMargin,
        };
      }

      return { status: true, data: response.data };
    } else {
      if (response.http === 401) {
        removeCookie('token');
        removeCookie('authen');
        return {
          status: false,
          type: 'INVALID_SESSION',
          data: { id: Math.random(), msg: response.data.message },
        };
      } else {
        if (action === 'NewOrder') titleMsg = 'Đặt lệnh không thành công';
        return {
          status: false,
          type: 'REQUEST_PRIVATE_FALSE',
          data: {
            id: Math.random(),
            msg: response.data.message || action + ' Unknown error!',
            title: titleMsg,
          },
        };
      }
    }
  } else {
    return {
      status: false,
      type: 'REQUEST_PRIVATE_FALSE',
      data: { msg: 'Unknown error!' },
    };
  }
}
