import React, { useState } from 'react';

const CheckboxButton = ({ onClick, description = '', check = false }) => {
  const [checked, setChecked] = useState(check);

  return (
    <div className="checkbox-button">
      <span
        onClick={() => {
          if (onClick && typeof onClick == 'function') {
            onClick(!checked);
          }
          setChecked(!checked);
        }}
        className={`cbb-box ${checked ? 'checked' : ''}`}
      ></span>
      <span
        className="cbb-text"
        dangerouslySetInnerHTML={{ __html: description }}
      ></span>
    </div>
  );
};

export default CheckboxButton;
