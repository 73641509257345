import React, { useEffect } from 'react';
import { withNamespaces } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import BankItem from '../components/bank/bankItem';
import { v4 as uuidv4 } from 'uuid';
import { bankAccRequest } from '../../customer/actions';
import { changeBankReset } from '../../../components/modal/auth/actions';
const Bank = withNamespaces('translations')(({ t }) => {
  const dispatch = useDispatch();

  const { defaultAccount } = useSelector((state) => state.socket);
  const { auth, token } = useSelector((state) => state.client);
  const changeBankSuccessful = useSelector(
    (state) => state.authen.changeBankSuccessful
  );

  useEffect(() => {
    if (changeBankSuccessful) {
      handleLoadBankAccount();
      dispatch(changeBankReset());
    }
  }, [changeBankSuccessful]);

  const handleLoadBankAccount = () => {
    if (!defaultAccount) return;

    const uuid = uuidv4();
    const params = {
      group: 'CORE',
      user: token.user,
      session: token.session,
      cmd: 'getBankAcntList',
      rqId: uuid,
      channel: 'WTS',
      data: {
        acntNo: defaultAccount.acntNo,
        subAcntNo: defaultAccount.subAcntNo,
      },
    };

    dispatch(bankAccRequest(params));
  };

  return (
    <>
      <BankItem />
    </>
  );
});

export default Bank;
