import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCommonRequest,
  getSerList4Cust,
} from '../../../../customer/actions';
import { v4 as uuidv4 } from 'uuid';
import {
  makeGetBrokerInfo,
  makeGetDefaultAccount,
  makeGetSerList4Cust,
} from '../../../../../lib/selector';
import { withNamespaces } from 'react-i18next';
import Loading from '../../../../../components/loading';
import ReferrerSection from './referrerSection';
import AddNewCustomerBroker from '../../modal/modalAddNewBroker';
import {
  PARTNER_FEE_POLICY,
  PARTNER_SERVICE_TYPE,
} from '../../../../../util/constant';
import ServiceSection from './serviceSection';

const Dashboard = withNamespaces('translations')(({ t }) => {
  const dispatch = useDispatch();
  const {
    token,
    setting: { lang = 'vi' },
  } = useSelector((state) => state.client);
  const [brokerToChange, setBrokerToChange] = useState(null);
  const defaultAccount = useSelector(makeGetDefaultAccount());

  const { serList4Cus, serList4CusRequesting } = useSelector(
    (state) => state.customer
  );

  const handleLoadData = () => {
    if (!defaultAccount) return;
    const uuid = uuidv4();
    const payload = {
      group: 'CORE',
      cmd: 'getSerList4Cust',
      user: token.user,
      session: token.session,
      rqId: uuid,
      channel: 'WTS',
      data: {
        cif: token.cif,
        acntNo: defaultAccount?.acntNo,
        langTp: lang,
      },
    };
    dispatch(getSerList4Cust(payload));
  };

  useEffect(() => {
    handleLoadData();
  }, [defaultAccount, lang]);

  if (serList4CusRequesting) return <Loading />;

  if (brokerToChange) {
    return (
      <AddNewCustomerBroker
        t={t}
        isChange
        handleLoadData={() => {
          handleLoadData();
          setBrokerToChange(false);
        }}
        handleClose={() => setBrokerToChange(false)}
      />
    );
  }

  if (serList4Cus && serList4Cus?.brokerId) {
    return (
      <div className="referrer-information page-partner-list">
        <ReferrerSection
          onChange={() => {
            setBrokerToChange(true);
          }}
          handleLoadData={handleLoadData}
        />
        <ServiceSection onSuccess={handleLoadData} />
      </div>
    );
  }
  return (
    <div className="partner-page">
      <AddNewCustomerBroker t={t} handleLoadData={handleLoadData} />
    </div>
  );
});

export default Dashboard;
