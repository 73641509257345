import React from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { translate, withNamespaces } from 'react-i18next';

const PrintButton = withNamespaces('translations')(({ t, id, callback }) => {
  const clickHandle = () => {
    const input = document.getElementById(id);
    input.style.cssText = 'display: block; margin-top: 1000px !important;';
    const HTML_Width = input.clientWidth;
    const HTML_Height = input.clientHeight;
    const top_left_margin = 15;
    const PDF_Width = HTML_Width + top_left_margin * 2;
    const PDF_Height = PDF_Width * 1.5 + top_left_margin * 2;
    const canvas_image_width = HTML_Width;
    const canvas_image_height = HTML_Height;
    const totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;

    html2canvas(input, { allowTaint: true }).then((canvas) => {
      // canvas.getContext('2d');
      let imgData = canvas.toDataURL('image/jpeg');
      let pdf = new jsPDF('p', 'pt', [PDF_Width, PDF_Height]);
      pdf.addImage(
        imgData,
        'JPG',
        top_left_margin,
        top_left_margin,
        canvas_image_width,
        canvas_image_height
      );

      for (let i = 1; i <= totalPDFPages; i++) {
        pdf.addPage([PDF_Width, PDF_Height]);
        pdf.addImage(
          imgData,
          'JPG',
          top_left_margin,
          -(PDF_Height * i) + top_left_margin * 4,
          canvas_image_width,
          canvas_image_height
        );
      }

      pdf.save(`${id}.pdf`);
      input.style.display = 'none';
    });

    if (callback && typeof callback == 'function') {
      callback();
    }
  };

  return (
    <div
      className="btn-icon btn-icon--light cursor-pointer"
      onClick={clickHandle}
    >
      <span className="icon iDownload"></span>
      <span className="fw-500">{t('txt-export-pdf')}</span>
    </div>
  );
});

export default translate('translations')(PrintButton);
