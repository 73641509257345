import React from 'react';
import { withNamespaces } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import { useSelector } from 'react-redux';
import PDFViewer from '../../../bond/components/Contract/pdfViewer';

const ViewReport = withNamespaces('translations')(({ file, setFile }) => {
  const theme = useSelector((state) => state.client.currentTheme);
  const closeModal = () => {
    setFile('');
  };
  return (
    <Modal
      show={true}
      dialogClassName="wts-modal"
      backdropClassName="wts-modal-backdrop pinefolio-order-confirm-backdrop"
      contentClassName={`${
        theme ? theme.name : 'dark'
      } pinefolio-order-confirm wts-modal-content wts-modal-content--xl`}
      onEscapeKeyDown={() => closeModal()}
      centered
      onHide={() => closeModal()}
      style={{ zIndex: 11120 }}
    >
      <div className="pinefolio-order-confirm__header d-flex justify-content-center">
        <span className="fz_16 fw_500 text-center">{file?.flName}</span>
      </div>
      <div className="pinefolio-order-confirm__body">
        {file?.flUrl && <PDFViewer src={file?.flUrl} />}
      </div>
    </Modal>
  );
});

export default ViewReport;
