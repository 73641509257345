import React, { Fragment, useState, useEffect } from 'react';
import {
  BsCaretLeftFill,
  BsCaretRightFill,
  BsFillCaretDownFill,
  BsFillCaretUpFill,
} from 'react-icons/bs';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Datagrid from '../../../table/Datagrid';
import { TiAdjustBrightness, TiPin } from 'react-icons/ti';
import Loading from '../../../loading';
import { filter, sortBy } from 'lodash';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { makeGetIsAsc, makeGetSortField } from '../../../../lib/selector';

import {
  changeSortField,
  changeSortDirection,
} from '../../../../containers/banggia/actions';

const Thead = ({
  row1,
  row2,
  isPc,
  handleSort,
  setIsPC,
  isAsc,
  setIsAsc,
  sortField,
  setSortField,
}) => {
  useEffect(() => {}, [isAsc]);
  useEffect(() => {}, [sortField]);

  const showSortIcon = (field) => {
    if (sortField == field) {
      if (isAsc)
        return (
          <span onClick={() => _handleSort(field)}>
            <BsFillCaretUpFill
              className="fz_10 position-relative"
              style={{ marginLeft: '0.1rem' }}
            />
          </span>
        );
      else
        return (
          <span onClick={() => _handleSort(field)}>
            <BsFillCaretDownFill
              className="fz_10 position-relative"
              style={{ marginLeft: '0.1rem' }}
            />
          </span>
        );
    } else return null;
  };

  const _handleSort = (fieldName) => {
    setSortField(fieldName);
    setIsAsc();
  };

  return (
    <>
      <tr>
        <th rowSpan="2">
          <TiPin style={{ fontSize: '17px' }} />
        </th>

        {row1.map((r, i) => (
          <th
            rowSpan={r.rowSpan}
            colSpan={r.colSpan}
            className={r.className}
            key={`r1_${i}`}
            onClick={() => r.key && _handleSort(r.key)}
          >
            <span>{r.text}</span>
            {showSortIcon(r.key)}
          </th>
        ))}
      </tr>
      <tr>
        {row2.map((r, i) => (
          <th
            rowSpan={r.rowSpan}
            colSpan={r.colSpan}
            className={r.className}
            key={`r2_${i}`}
            onClick={() =>
              r.key &&
              !(r.key == 'changePc' || r.key == 'change') &&
              _handleSort(r.key)
            }
          >
            {r.key == 'changePc' || r.key == 'change' ? (
              <>
                <BsCaretLeftFill
                  className="tbl-toggle-left"
                  onClick={() => setIsPC()}
                />
                <span className="filterIcon" onClick={() => _handleSort(r.key)}>
                  {isPc ? '%' : '+/-'}
                </span>
                {showSortIcon(r.key)}
                <BsCaretRightFill
                  className="tbl-toggle-right"
                  onClick={() => setIsPC()}
                />
              </>
            ) : (
              <>
                <span>{r.text}</span>
                {showSortIcon(r.key)}
              </>
            )}
          </th>
        ))}
      </tr>
    </>
  );
};

class TblWatchList extends React.Component {
  state = {
    isPc: false,
    snap: [],
  };

  componentDidMount() {
    const { snapshot } = this.props;
    this.setState(
      {
        snap: snapshot,
      },
      () => this.handleSort()
    );
  }

  componentDidUpdate(prevProps) {
    const { snapshot, sortField, isAsc } = this.props;
    if (prevProps.snapshot != snapshot) {
      this.setState(
        {
          snap: snapshot,
        },
        () => this.handleSort()
      );
    }

    if (sortField != prevProps.sortField) this.handleSort();

    if (isAsc != prevProps.isAsc) this.handleSort();
  }

  handleSort = () => {
    const { snap } = this.state;
    const { sortField, isAsc } = this.props;
    let _snap = [...snap];

    const mySort = (field) => {
      const rs = _snap.sort(function (a, b) {
        return a[field] - b[field];
      });

      return rs;
    };

    if (sortField == 'sym')
      _snap = isAsc ? sortBy(_snap, 'sym') : sortBy(_snap, 'sym').reverse();
    else {
      const _sort = mySort(sortField);
      _snap = isAsc ? _sort : _sort.reverse();
    }

    this.setState({
      snap: _snap,
    });
  };

  handleIsPC = () => {
    const { isPc } = this.state;
    this.setState({
      isPc: !isPc,
    });
  };

  setSortField = (fieldName) => {
    this.props.dispatch(changeSortField(fieldName));
  };

  setIsAsc = () => {
    this.props.dispatch(changeSortDirection());
  };

  render() {
    const { isPc, snap } = this.state;
    const {
      t,
      pinnedRow,
      typeIndex,
      isAsc,
      sortField,
      snapshotRequesting,
      snapshotSuccessful,
    } = this.props;

    const heightTb =
      195 +
      (pinnedRow && !!pinnedRow.length ? pinnedRow.length * 25 : 0) +
      (typeIndex === 1 ? 130 : 70);
    const pinSnapshot = pinnedRow
      ? filter(snap, (o) => pinnedRow.indexOf(o.sym) > -1)
      : [];

    const unpinSnapshot = pinnedRow
      ? filter(snap, (o) => pinnedRow.indexOf(o.sym) < 0)
      : [];

    const row1 = [
      {
        key: 'sym',
        text: t('txt-symbol'), // Mã CK
        className: 'filterIcon',
        rowSpan: '2',
        colSpan: '1',
      },
      {
        key: 'r',
        text: t('txt-ref'), // T.C
        className: 'filterIcon',
        rowSpan: '2',
        colSpan: '1',
      },
      {
        key: 'c',
        text: t('txt-ceil'), // Trần
        className: 'filterIcon',
        rowSpan: '2',
        colSpan: '1',
      },
      {
        key: 'f',
        text: t('txt-floor'), // Sàn
        className: 'filterIcon',
        rowSpan: '2',
        colSpan: '1',
      },
      {
        text: t('txt-bid'), // Bên mua
        rowSpan: '1',
        colSpan: '6',
      },
      {
        text: t('txt-match'), // Khớp lệnh
        className: '',
        rowSpan: '1',
        colSpan: '3',
      },
      {
        text: t('txt-ask'), // Bên bán
        rowSpan: '1',
        colSpan: '6',
      },
      {
        key: 'lot',
        text: t('txt-total-vol'), // Tổng KL
        className: 'filterIcon',
        rowSpan: '2',
        colSpan: '1',
      },
      {
        key: 'highPrice',
        text: t('txt-high'), // Cao
        className: 'filterIcon',
        rowSpan: '2',
        colSpan: '1',
      },
      {
        key: 'lowPrice',
        text: t('txt-low'), // Thấp
        className: 'filterIcon',
        rowSpan: '2',
        colSpan: '1',
      },
      {
        key: 'avePrice',
        text: t('txt-ave'), // Trung bình
        className: 'filterIcon',
        rowSpan: '2',
        colSpan: '1',
      },
      {
        text: t('txt-foreign'), // Khối ngoại
        rowSpan: '1',
        colSpan: '2',
      },
    ];

    const row2 = [
      {
        key: 'bP3',
        text: `${t('txt-price')} 3`, // Giá 3
        className: 'filterIcon',
        rowSpan: '1',
        colSpan: '1',
      },
      {
        key: 'bV3',
        text: `${t('txt-vol')} 3`, // KL 3
        className: 'filterIcon',
        rowSpan: '1',
        colSpan: '1',
      },
      {
        key: 'bP2',
        text: `${t('txt-price')} 2`, // Giá 2
        className: 'filterIcon',
        rowSpan: '1',
        colSpan: '1',
      },
      {
        key: 'bV2',
        text: `${t('txt-vol')} 2`, // KL 2
        className: 'filterIcon',
        rowSpan: '1',
        colSpan: '1',
      },
      {
        key: 'bP1',
        text: `${t('txt-price')} 1`, // Giá 1
        className: 'filterIcon',
        rowSpan: '1',
        colSpan: '1',
      },
      {
        key: 'bV1',
        text: `${t('txt-vol')} 1`, // KL 1
        className: 'filterIcon',
        rowSpan: '1',
        colSpan: '1',
      },
      {
        key: 'lastPrice',
        text: t('txt-price'), // Giá
        className: 'filterIcon',
        rowSpan: '1',
        colSpan: '1',
      },
      {
        key: 'lastVolume',
        text: t('txt-vol'), // KL
        className: 'filterIcon',
        rowSpan: '1',
        colSpan: '1',
      },
      {
        key: `${isPc ? 'changePc' : 'change'}`,
        text: `${isPc ? '%' : '+/-'}`, // +/-
        className: 'po-relative',
        rowSpan: '1',
        colSpan: '1',
      },
      {
        key: 'oP1',
        text: `${t('txt-price')} 1`, // Giá 1
        className: 'filterIcon',
        rowSpan: '1',
        colSpan: '1',
      },
      {
        key: 'oV1',
        text: `${t('txt-vol')} 1`, // KL 1
        className: 'filterIcon',
        rowSpan: '1',
        colSpan: '1',
      },
      {
        key: 'oP2',
        text: `${t('txt-price')} 2`, // Giá 2
        className: 'filterIcon',
        rowSpan: '1',
        colSpan: '1',
      },
      {
        key: 'oV2',
        text: `${t('txt-vol')} 2`, // KL 2
        className: 'filterIcon',
        rowSpan: '1',
        colSpan: '1',
      },
      {
        key: 'oP3',
        text: `${t('txt-price')} 3`, // Giá 3
        className: 'filterIcon',
        rowSpan: '1',
        colSpan: '1',
      },
      {
        key: 'oV3',
        text: `${t('txt-vol')} 3`, // KL 3
        className: 'filterIcon',
        rowSpan: '1',
        colSpan: '1',
      },
      {
        key: 'fBVol',
        text: t('txt-buy'), // Mua
        className: 'filterIcon',
        rowSpan: '1',
        colSpan: '1',
      },
      {
        key: 'fSVolume',
        text: t('txt-sell'), // Bán
        className: 'filterIcon',
        rowSpan: '1',
        colSpan: '1',
      },
    ];

    return (
      <Fragment>
        <div className="tbl-fix-header">
          <Datagrid
            resource={pinSnapshot}
            pinnedRow={pinnedRow}
            type="pin"
            handleUnPinStock={this.props.handleUnPinStock}
            classname="table table-fix watchlist-pin-table table-bordered"
            isPc={isPc}
          >
            <Thead
              handleSort={this.handleSort}
              setIsPC={this.handleIsPC}
              row1={row1}
              row2={row2}
              isPc={isPc}
              isAsc={isAsc}
              sortField={sortField}
              setIsAsc={this.setIsAsc}
              setSortField={this.setSortField}
            />
          </Datagrid>
        </div>
        {snapshotRequesting && <Loading />}
        {!snapshotRequesting && snapshotSuccessful && (
          <PerfectScrollbar style={{ height: `calc(100vh - ${heightTb}px)` }}>
            <Datagrid
              resource={unpinSnapshot}
              pinnedRow={pinnedRow}
              type="unpin"
              handlePinStock={this.props.handlePinStock}
              classname="table table-fix watchlist-table"
              isPc={isPc}
            />
            <div
              style={{
                height: '200px',
                marginTop: '10px',
                color: '#c5c5c6',
                textAlign: 'center',
                fontSize: '13px',
                lineHeight: '16px',
              }}
            >
              <span className="text-tb-copyright">{t('txt-copyright')}</span>
            </div>
          </PerfectScrollbar>
        )}
      </Fragment>
    );
  }
}

const makeMapStateToProps = () => {
  const getIsAsc = makeGetIsAsc();
  const getSortField = makeGetSortField();

  const mapStateToProps = (state) => {
    return {
      isAsc: getIsAsc(state),
      sortField: getSortField(state),
    };
  };
  return mapStateToProps;
};

export default connect(makeMapStateToProps)(
  translate('translations')(TblWatchList)
);
