export const GET_LIST_POSITION_ALL = 'GET_LIST_POSITION_ALL';
export const GET_LIST_POSITION_ALL_SUCCESS = 'GET_LIST_POSITION_ALL_SUCCESS';
export const GET_LIST_POSITION_ALL_ERROR = 'GET_LIST_POSITION_ALL_ERROR';

export const GET_BOND_PORTFOLIO = 'GET_BOND_PORTFOLIO';
export const GET_BOND_PORTFOLIO_SUCCESS = 'GET_BOND_PORTFOLIO_SUCCESS';
export const GET_BOND_PORTFOLIO_ERROR = 'GET_BOND_PORTFOLIO_ERROR';

export const GET_INQUIRY_RIGHTLIST_ALL = 'GET_INQUIRY_RIGHTLIST_ALL';
export const GET_INQUIRY_RIGHTLIST_ALL_SUCCESS =
  'GET_INQUIRY_RIGHTLIST_ALL_SUCCESS';
export const GET_INQUIRY_RIGHTLIST_ALL_ERROR =
  'GET_INQUIRY_RIGHTLIST_ALL_ERROR';
