import {
  GET_PROFILE_REQUESTING,
  GET_PROFILE_REQUEST_SUCCESS,
  GET_PROFILE_REQUEST_ERROR,
  BANK_ACC_REQUESTING,
  BANK_ACC_REQUEST_SUCCESS,
  BANK_ACC_REQUEST_ERROR,
  SET_DEFAULT_REQUEST_ERROR,
  SET_DEFAULT_REQUEST_SUCCESS,
  SET_DEFAULT_REQUESTING,
  GET_SMS_SERVICES_REQUESTING,
  GET_SMS_SERVICES_REQUEST_ERROR,
  GET_SMS_SERVICES_REQUEST_SUCCESS,
  SET_SMS_SERVICES_REQUESTING,
  SET_SMS_SERVICES_REQUEST_SUCCESS,
  SET_SMS_SERVICES_REQUEST_ERROR,
  GET_EXT_SETTING_REQUESTING,
  GET_EXT_SETTING_REQUEST_SUCCESS,
  GET_EXT_SETTING_REQUEST_ERROR,
  SET_EXT_SETTING_REQUEST_ERROR,
  SET_EXT_SETTING_REQUEST_SUCCESS,
  SET_EXT_SETTING_REQUESTING,
  REMOVE_BANK_REQUESTING,
  REMOVE_BANK_REQUEST_SUCCESS,
  REMOVE_BANK_REQUEST_ERROR,
  UPDATE_PROFILE_REQUESTING,
  GET_PROFILE_STATE_REQUEST,
  UPDATE_PROFILE_RESET,
  RESET_EXT_SETTING_REQUEST,
  RESET_SMS_SERVICES_REQUEST,
  RESET_DEFAULT_REQUEST,
  VC_BOOKING_DATE_REQUESTING,
  VC_BOOKING_DATE_SUCCESS,
  VC_BOOKING_TIME_REQUESTING,
  VC_BOOKING_TIME_SUCCESS,
  VC_BOOKING_SUCCESS,
  VC_BOOKING_REQUESTING,
  REGIST_VC_BOOKING_REQUESTING,
  REGIST_VC_BOOKING_SUCCESS,
  REGIST_VC_BOOKING_ERROR,
  CANCEL_VC_BOOKING_REQUESTING,
  CANCEL_VC_BOOKING_SUCCESS,
  OPEN_SUBACCOUNT_CONTRACT_REQUEST,
  CONFIRM_SUBACCOUNT_CONTRACT_REQUEST,
  OPEN_BOOKING_VIDEO_MODAL,
  GET_CREDIT_LIMIT_LEVEL,
  OPEN_CREDIT_LIMIT_MODAL,
  GET_LIST_ACCOUNTS,
  GET_ACCOUNT_COMMON_REQUEST,
  GET_BROKER_INFOR_REQUEST,
  SET_PARTNER_SHIP_REQUEST,
  SUBMIT_PARTNER_SHIP_REQUEST,
  GET_BROKER_INFOR_SUCCESS,
  SUBMIT_PARTNER_SHIP_SUCCESS,
  GET_SER_LIST_4_CUST_REQUEST,
  CHANGE_FEE_MODAL_SET,
  GET_API_SERVICES_REQUESTING,
  GET_API_SERVICES_REQUEST_SUCCESS,
  GET_API_SERVICES_REQUEST_ERROR,
  GEN_API_SERVICES_KEY,} from './constants';

export const profileRequest = function profileRequest(params) {
  return {
    type: GET_PROFILE_REQUESTING,
    params,
  };
};

export const profileRequestSuccess = function profileRequestSuccess(data) {
  return {
    type: GET_PROFILE_REQUEST_SUCCESS,
    data,
  };
};

export const profileRequestError = function profileRequestError(error) {
  return {
    type: GET_PROFILE_REQUEST_ERROR,
    error,
  };
};

export const bankAccRequest = function bankAccRequest(params) {
  return {
    type: BANK_ACC_REQUESTING,
    params,
  };
};

export const bankAccRequestSuccess = function bankAccRequestSuccess(data) {
  return {
    type: BANK_ACC_REQUEST_SUCCESS,
    data,
  };
};

export const bankAccRequestError = function bankAccRequestError(error) {
  return {
    type: BANK_ACC_REQUEST_ERROR,
    error,
  };
};

export const removeBankRequest = function removeBankRequest(params) {
  return {
    type: REMOVE_BANK_REQUESTING,
    params,
  };
};

export const removeBankRequestSuccess = function removeBankRequestSuccess(
  params
) {
  return {
    type: REMOVE_BANK_REQUEST_SUCCESS,
    params,
  };
};

export const removeBankRequestError = function removeBankRequestError(error) {
  return {
    type: REMOVE_BANK_REQUEST_ERROR,
    error,
  };
};

export const setDefaultRequest = function setDefaultRequest(params) {
  return {
    type: SET_DEFAULT_REQUESTING,
    params,
  };
};

export const setDefaultRequestSuccess = () => {
  return {
    type: SET_DEFAULT_REQUEST_SUCCESS,
  };
};

export const setDefaultRequestError = function setDefaultRequestError(error) {
  return {
    type: SET_DEFAULT_REQUEST_ERROR,
    error,
  };
};

export const resetDefaultRequest = () => {
  return {
    type: RESET_DEFAULT_REQUEST,
  };
};

export const getsMSSettingRequest = function getsMSSettingRequest(params) {
  return {
    type: GET_SMS_SERVICES_REQUESTING,
    params,
  };
};

export const getsMSSettingRequestSuccess = function getsMSSettingRequestSuccess(
  data
) {
  return {
    type: GET_SMS_SERVICES_REQUEST_SUCCESS,
    data,
  };
};

export const getsMSSettingRequestError = function getsMSSettingRequestError(
  error
) {
  return {
    type: GET_SMS_SERVICES_REQUEST_ERROR,
    error,
  };
};

export const getOpenApiSrvListRequest = function getOpenApiSrvListRequest(
  payload
) {
  return {
    type: GET_API_SERVICES_REQUESTING,
    payload,
  };
};

export const getOpenApiSrvListRequestSuccess =
  function getOpenApiSrvListRequestSuccess(data) {
    return {
      type: GET_API_SERVICES_REQUEST_SUCCESS,
      data,
    };
  };

export const getOpenApiSrvListRequestError =
  function getOpenApiSrvListRequestError(error) {
    return {
      type: GET_API_SERVICES_REQUEST_ERROR,
      error,
    };
  };

export const setsMSSettingRequest = function setsMSSettingRequest(params) {
  return {
    type: SET_SMS_SERVICES_REQUESTING,
    params,
  };
};

export const setsMSSettingRequestSuccess = function setsMSSettingRequestSuccess(
  data,
  params
) {
  return {
    type: SET_SMS_SERVICES_REQUEST_SUCCESS,
    data,
    params,
  };
};

export const setsMSSettingRequestError = function setsMSSettingRequestError(
  error
) {
  return {
    type: SET_SMS_SERVICES_REQUEST_ERROR,
    error,
  };
};

export const resetsMSSettingRequest = (error) => {
  return {
    type: RESET_SMS_SERVICES_REQUEST,
  };
};

export const getExtSettingRequest = function getExtSettingRequest(params) {
  return {
    type: GET_EXT_SETTING_REQUESTING,
    params,
  };
};

export const getExtSettingRequestSuccess = function getExtSettingRequestSuccess(
  data
) {
  return {
    type: GET_EXT_SETTING_REQUEST_SUCCESS,
    data,
  };
};

export const getExtSettingRequestError = function getExtSettingRequestError(
  error
) {
  return {
    type: GET_EXT_SETTING_REQUEST_ERROR,
    error,
  };
};

export const setExtSettingRequest = function setExtSettingRequest(params) {
  return {
    type: SET_EXT_SETTING_REQUESTING,
    params,
  };
};

export const setExtSettingRequestSuccess = function setExtSettingRequestSuccess(
  data,
  params
) {
  return {
    type: SET_EXT_SETTING_REQUEST_SUCCESS,
    data,
    params,
  };
};

export const setExtSettingRequestError = function setExtSettingRequestError(
  error
) {
  return {
    type: SET_EXT_SETTING_REQUEST_ERROR,
    error,
  };
};

export const resetExtSettingRequest = (error) => {
  return {
    type: RESET_EXT_SETTING_REQUEST,
  };
};

//FIXME: Handle error
export const updateProfileRequest = (params) => {
  return {
    type: UPDATE_PROFILE_REQUESTING,
    params,
  };
};

export const getProfileState = (params) => {
  return {
    type: GET_PROFILE_STATE_REQUEST,
    params,
  };
};

export const resetUpdateProfile = () => {
  return {
    type: UPDATE_PROFILE_RESET,
  };
};

export const requestVcBookingDate = (params) => {
  return {
    type: VC_BOOKING_DATE_REQUESTING,
    params,
  };
};

export const requestVcBookingDateSuccess = (data) => {
  return {
    type: VC_BOOKING_DATE_SUCCESS,
    data,
  };
};

export const requestVcBookingTime = (params) => {
  return {
    type: VC_BOOKING_TIME_REQUESTING,
    params,
  };
};

export const requestVcBookingTimeSuccess = (data) => {
  return {
    type: VC_BOOKING_TIME_SUCCESS,
    data,
  };
};

export const requestVcBooking = (params) => {
  return {
    type: VC_BOOKING_REQUESTING,
    params,
  };
};

export const requestVcBookingSuccess = (data) => {
  return {
    type: VC_BOOKING_SUCCESS,
    data,
  };
};

export const requestRegistVcBooking = (
  params,
  startTime,
  callback,
  fallback
) => {
  return {
    type: REGIST_VC_BOOKING_REQUESTING,
    params,
    startTime,
    callback,
    fallback,
  };
};

export const requestRegistVcBookingSuccess = (data) => {
  return {
    type: REGIST_VC_BOOKING_SUCCESS,
    data,
  };
};

export const cancelRegistVcBooking = (params) => {
  return {
    type: CANCEL_VC_BOOKING_REQUESTING,
    params,
  };
};

export const cancelRegistVcBookingSuccess = (data) => {
  return {
    type: CANCEL_VC_BOOKING_SUCCESS,
    data,
  };
};

export const getOpenAccountContract = (params) => {
  return {
    type: OPEN_SUBACCOUNT_CONTRACT_REQUEST,
    params,
  };
};

//FIXME: Handle error
export const confirmOpenAccount = (params) => {
  return {
    type: CONFIRM_SUBACCOUNT_CONTRACT_REQUEST,
    params,
  };
};

export const setOpenBookingVideoModal = (data) => {
  return {
    type: OPEN_BOOKING_VIDEO_MODAL,
    data,
  };
};

export const getCreditLimitLevel = (params) => {
  return {
    type: GET_CREDIT_LIMIT_LEVEL,
    params,
  };
};

export const setCreditLimitLevelModal = (data) => {
  return {
    type: OPEN_CREDIT_LIMIT_MODAL,
    data,
  };
};

export const getListAccount = (params, callback) => {
  return {
    type: GET_LIST_ACCOUNTS,
    params,
    callback,
  };
};

export const getCommonRequest = (params, callback, isProtect = false) => {
  return {
    type: GET_ACCOUNT_COMMON_REQUEST,
    params,
    callback,
    isProtect,
  };
};

export const getBrokerInfor = (params, callback) => {
  return {
    type: GET_BROKER_INFOR_REQUEST,
    params,
    callback,
    isProtect: true,
  };
};

export const getBrokerInforSuccess = (resData) => {
  return {
    type: GET_BROKER_INFOR_SUCCESS,
    resData,
  };
};

export const setRegistPartnershipShow = (show) => {
  return {
    type: SET_PARTNER_SHIP_REQUEST,
    show,
  };
};

//consider to change to takeLeading getCommonRequest
export const submitRegistPartnership = (params, callback, isProtect = true) => {
  return {
    type: SUBMIT_PARTNER_SHIP_REQUEST,
    params,
    callback,
    isProtect,
  };
};

export const submitRegistSuccessShow = (show) => {
  return {
    type: SUBMIT_PARTNER_SHIP_SUCCESS,
    show,
  };
};

export const getSerList4Cust = (payload) => {
  return {
    type: GET_SER_LIST_4_CUST_REQUEST,
    payload,
  };
};

export const setChangeFeeOpenModal = (status) => {
  return {
    type: CHANGE_FEE_MODAL_SET,
    status,
  };
};


export const genOpenApiSrvKey = function genOpenApiSrvKey(payload, callback) {
  return {
    type: GEN_API_SERVICES_KEY,
    payload,
    callback,
  };
};
