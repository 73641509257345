import React, { useContext, useEffect, useRef, useState } from 'react';
import Loading from '../loading';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { useDispatch, useSelector } from 'react-redux';
import { getDataInMemRequest } from '../../containers/client/actions';
import { WebSocketContext } from '../../containers/socket/webSocket';
import { _processMapDataIndex } from '../../util';

const MarketChart = ({ width, height, mc }) => {
  const theme = useSelector((state) => state.client.currentTheme);
  const dispatch = useDispatch();
  const { socket } = useContext(WebSocketContext);

  const [loading, setLoading] = useState(true);
  const [chartOptions, setChartOptions] = useState();
  const chartComponent = useRef();
  const oldData = useRef([]);
  const oldColor = useRef();

  const getDataHandle = (mc) => {
    const callback = (response) => {
      const data = response.data;
      if (data) {
        chartProcess(data);
        oldData.current = data;
      }
    };
    dispatch(getDataInMemRequest(mc, callback));
  };

  const chartProcess = (dt, color = null) => {
    if (!dt || !dt?.length) return;
    const lastData = dt[dt.length - 1];
    if (!lastData) return;

    let cl = '#ffe70b';
    const _color =
      color ||
      (lastData?.cIndex > lastData?.oIndex
        ? 'i'
        : lastData?.cIndex < lastData?.oIndex
        ? 'd'
        : 'r');

    if (_color == 'd')
      cl = theme?.name == 'dark' ? '#ff233d' : '#f15555'; // down
    else if (_color == 'i')
      cl = theme?.name == 'dark' ? '#00ff57' : '#2db35b'; // up
    else cl = theme?.name == 'dark' ? '#ffe70b' : '#f0ae03'; // ref

    let openIndex,
      dataSeries = [],
      dataVolume = [];
    let oVol = 0;
    let j = 0;
    let bYear, bMonth, bDay, eYear, eMonth, eDay;
    openIndex = lastData.oIndex;
    dt.forEach((idata, i) => {
      if (i === 0) {
        oVol = idata.vol;
        let date = new Date();
        // hight chart moi da xu ly +1 vao thang
        bYear = date.getFullYear();
        bMonth = date.getMonth();
        bDay = date.getDate();
        eYear = date.getFullYear();
        eMonth = date.getMonth();
        eDay = date.getDate();
      } else if (idata.time !== null && idata.time !== 'null') {
        let h = idata.time.split(':');
        let utc = Date.UTC(bYear, bMonth, bDay, h[0], h[1]);
        let indexData = { x: utc, y: idata.cIndex };
        let volumeData = { x: utc, y: idata.vol - oVol };
        if (
          ((h[0] === '13' && h[1] === '00') ||
            (h[0] === '12' && h[1] === '59')) &&
          j === 0
        ) {
          let nullUtc1 = Date.UTC(bYear, bMonth, bDay, 11, 31);
          let nullUtc2 = Date.UTC(bYear, bMonth, bDay, 12, 59);
          let indexDataP1 = { x: nullUtc1, y: idata.cIndex };
          let indexDataV1 = { x: nullUtc1, y: 0 };
          let indexDataP2 = { x: nullUtc2, y: idata.cIndex };
          let indexDataV2 = { x: nullUtc2, y: 0 };

          dataSeries.push(indexDataP1);
          dataSeries.push(indexDataP2);
          dataVolume.push(indexDataV1);
          dataVolume.push(indexDataV2);
          j++;
        }
        if (idata.vol - oVol > 0) {
          if (
            dataSeries.length === 0 ||
            utc - dataSeries[dataSeries.length - 1].x >= 60000
          ) {
            dataSeries.push(indexData);
            dataVolume.push(volumeData);
            oVol = idata.vol;
          } else {
            dataSeries[dataSeries.length - 1] = indexData;
            dataVolume[dataVolume.length - 1] = volumeData;
          }
        }
      }
    });

    const chartOptions = {
      chart: {
        backgroundColor: 'transparent',
        spacingBottom: -5,
        spacingTop: 0,
        marginLeft: 0,
        marginRight: 0,
        marginTop: 0,
        // marginBottom: 0,
      },
      title: { text: '' },
      credits: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      xAxis: {
        type: 'datetime',
        gridLineColor: '#3d4054',
        gridLineWidth: 0,
        labels: {
          enabled: false,
          style: {
            color: '#8e8e8e',
            fontSize: '8px',
          },
        },
      },
      yAxis: [
        {
          title: { text: '' },
          gridLineColor: 'transparent',
          gridLineWidth: 0,
          labels: { enabled: false },
        },
        {
          title: { text: '' },
          opposite: true,
          max: mc === '10' ? 15000000 : mc === '11' ? 5000000 : 2000000,
          gridLineColor: '#3d4054',
          gridLineWidth: 0,
          labels: { enabled: false },
        },
      ],
      plotOptions: {
        line: {
          animation: false,
          lineWidth: 1,
          marker: {
            enabled: false,
          },
          threshold: openIndex,
        },
        column: {
          animation: false,
          lineWidth: 1,
          borderColor: '#67d6fb',
          marker: {
            enabled: false,
          },
          shadow: false,
          states: {
            hover: {
              lineWidth: 1,
            },
          },
          series: {
            pointWidth: 2,
          },
        },
        area: {
          fillColor: {
            linearGradient: {
              x1: 0,
              y1: 0.1,
              x2: 0,
              y2: 1,
            },
            stops: [
              [0, cl],
              [
                1,
                Highcharts.color(Highcharts.getOptions().colors[0])
                  .setOpacity(0)
                  .get('rgba'),
              ],
            ],
          },
          marker: {
            radius: 2,
          },
          lineWidth: 1,
          states: {
            hover: {
              lineWidth: 1,
            },
          },
          threshold: null,
        },
        series: {
          connectNulls: false,
        },
      },
      legend: {
        enabled: false,
      },
      series: [
        {
          type: 'line',
          color: '#d1af54',
          data: [
            [Date.UTC(eYear, eMonth, eDay, 9, 0), openIndex],
            [Date.UTC(eYear, eMonth, eDay, 15, 0), openIndex],
          ],
          pointInterval: 30 * 10000,
          pointStart: Date.UTC(eYear, eMonth, eDay, 9, 0),
          pointEnd: Date.UTC(eYear, eMonth, eDay, 15, 0),
          marker: {
            enabled: false,
          },
          dashStyle: 'shortdash',
          enableMouseTracking: false,
          dataLabels: {
            enabled: false,
            align: 'right',
            style: {
              color: '#B6BDCD',
              fontSize: '2px',
            },
          },
        },
        {
          type: 'column',
          name: 'Volume',
          yAxis: 1,
          color: '#26abd8',
          pointInterval: 60 * 10000,
          pointStart: Date.UTC(eYear, eMonth, eDay, 9, 0),
          pointEnd: Date.UTC(eYear, eMonth, eDay, 15, 0),
          data: dataVolume,
          selected: false,
          connectNulls: false,
          enableMouseTracking: false,
        },
        {
          type: 'area',
          lineWidth: 1,
          lineColor: cl,
          color: { cl },
          name: 'Giá',
          pointInterval: 60 * 10000,
          pointStart: Date.UTC(eYear, eMonth, eDay, 9, 0),
          pointEnd: Date.UTC(eYear, eMonth, eDay, 15, 0),
          data: dataSeries,
          selected: false,
          connectNulls: false,
          enableMouseTracking: false,
        },
      ],
    };

    setChartOptions(chartOptions);
    oldColor.current = _color;
    setLoading(false);
  };

  useEffect(() => {
    // 1 - init interval to get chart data
    // 2 - listen socket to update chart color realtime
    const realtimeHandle = (msg) => {
      const payload = msg?.data;
      if (payload && payload?.id === 1101 && payload?.mc == mc) {
        const _data = _processMapDataIndex(payload);
        const _newColor = _data.cl;

        if (_newColor != oldColor.current) {
          chartProcess(oldData.current, _newColor);
        }
      }
    };

    let interval = null;

    if (mc) {
      getDataHandle(mc);
      interval = setInterval(() => getDataHandle(mc), 30 * 1000);
    }

    if (socket) {
      socket.on('public', realtimeHandle);
    }
    return () => {
      if (socket) socket.off('public', realtimeHandle);
      if (interval) clearInterval(interval);
    };
  }, [mc, socket]);

  if (loading) return <Loading />;
  else
    return (
      <HighchartsReact
        ref={chartComponent}
        highcharts={Highcharts}
        options={chartOptions}
        containerProps={{
          style: {
            height: `${height}` ?? '118px',
            width: `${width}` ?? '250px',
          },
        }}
        // allowChartUpdate = {false}
      />
    );
};

export default MarketChart;
