import React, { useEffect, useMemo, useState } from 'react';
import ReactSelect from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import {
  setBondDetailModalRequest,
  getBondDetail,
  setBondProductDetail,
  setProInvRequire,
  getFixBondProductRequest,
  getBondCommonRequest,
  setRegistBondTradingRetailModal,
  getBondCommonRequest2,
} from '../../action';
import { withNamespaces } from 'react-i18next';
import {
  numberFormat,
  _formatDate2,
  StringToInt,
  INVESTOR_TYPE,
} from '../../../../util';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import TextMask from 'react-text-mask';
import { makeGetBondInfor, makeGetBondToolTip } from '../../../../lib/selector';
import { v4 as uuidv4 } from 'uuid';
import { sortBy } from 'lodash';
import { setAuth, setLogin, setToast } from '../../../client/actions';
import { Mixpanel } from '../../../../lib/mixpanel';
import GroupTable from './groupTable';
import { constants } from '../../../../util/constant';

/**
 * Fix Bond
 */
const FBond = withNamespaces('translations')(
  ({ t, setModalSize, limitInfor }) => {
    const dispatch = useDispatch();
    const bondInfor = useSelector(makeGetBondInfor());
    const bondToolTip = useSelector(makeGetBondToolTip());
    const lang = useSelector((state) => state.client.setting.lang || 'vi');
    const { token, auth, setting } = useSelector((state) => state.client);

    // const { bondFixProducts, bondIssuers } = useSelector((state) => state.bond);
    const { bondFixProducts } = useSelector((state) => state.bond);

    const [issuer, setIssuer] = useState({ item: 'txt-all', id: '' });
    const [minInvestAmount, setMinInvestAmount] = useState('');
    const [terms, setTerms] = useState();
    const [term, setTerm] = useState({ label: 'txt-all', value: '' });
    const [investorType, setInvestorType] = useState({
      name: 'txt-all',
      id: '',
    });
    const [promotionByNav, setPromotionByNav] = useState([]);
    const [bondIssuers, setBondIssuers] = useState([]);

    const registBondRetailTrading = () =>
      dispatch(setRegistBondTradingRetailModal(true));

    const getProductsHandle = () => {
      const uuid = uuidv4();
      const params = {
        data: {
          invtAmt: minInvestAmount ? StringToInt(minInvestAmount) + '' : '',
          proInvtYN:
            investorType.id == 1 ? 'Y' : investorType.id == 2 ? 'N' : '',
          term: term.value,
          langTp: lang,
          issrCode: issuer.id,
          rateSort: '2',
          prodTp: '1',
        },
        group: 'CORE',
        cmd: 'getBondProductList',
        rqId: uuid,
        channel: 'WTS',
      };
      dispatch(getFixBondProductRequest(params));

      Mixpanel.bond.filter(
        minInvestAmount,
        '',
        t(term.label),
        t(investorType?.name),
        t(issuer?.item),
        '',
        '',
        '',
        '',
        ''
      );
    };

    const getPromotionByNav = () => {
      const uuid = uuidv4();
      const params = {
        data: {
          langTp: lang,
        },
        group: 'CORE',
        cmd: 'getBondProRt',
        rqId: uuid,
        channel: 'WTS',
      };

      const callback = (data) => {
        setPromotionByNav(data);
      };
      dispatch(getBondCommonRequest(params, callback));
    };

    const getBondTerms = () => {
      const uuid = uuidv4();
      const params = {
        data: {
          langTp: lang,
        },
        group: 'CORE',
        cmd: 'getBondTerms',
        rqId: uuid,
        channel: 'WTS',
      };

      const callback = (data) => {
        const _data = sortBy(data, (x) => parseInt(x.value, 10));
        setTerms(_data);
      };
      dispatch(getBondCommonRequest(params, callback));
    };

    const resetHandle = () => {
      setIssuer({ item: t('txt-all'), id: '' });
      setInvestorType({ name: t('txt-all'), id: '' });
      setMinInvestAmount('');
      setTerm({ label: 'txt-all', value: '' });
      getProductsHandle();
      Mixpanel.bond.resetFilter('Product List');
    };

    const registBondRetailTradingHandle = () => {
      if (token) {
        if (auth && auth.isVerified) {
          if (setting?.pcbMapYN == 'N') registBondRetailTrading();
          else if (setting?.pcbMapYN == 'P') {
            const toastMsg = {
              id: Math.random(),
              msg: t('bond.retailTrading.pendingNoti'),
              title: t('txt-notice'),
            };

            dispatch(setToast(toastMsg));
          } else return;
        } else {
          dispatch(setAuth());
        }
      } else {
        dispatch(setLogin());
      }
    };

    const getIssuerHandle = () => {
      const uuid = uuidv4();
      const params = {
        group: 'CORE',
        cmd: 'getBondIssuerList',
        channel: 'WTS',
        rqId: uuid,
        data: {
          langTp: lang,
          prodTp: constants.bondProductType.Fix,
        },
      };

      const callback = (data) => {
        setBondIssuers(data?.list || []);
      };

      dispatch(getBondCommonRequest2(params, callback, false));
    };

    useEffect(() => {
      if (lang) {
        getProductsHandle();
        getPromotionByNav();
        getBondTerms();
        getIssuerHandle();
      }
    }, [lang]);

    return (
      <div className="bond-product__body bond-layout__body">
        <div className="bond-product__body--left h-100">
          <div className="bond-layout__filters">
            <div className="bond-layout__filter">
              <label>{t('bond.minInvestAmount.name')}</label>
              <div className="input-text-search-outer">
                <TextMask
                  className="input-text-search"
                  type="text"
                  maxLength={15}
                  value={minInvestAmount}
                  mask={createNumberMask({
                    prefix: '',
                    allowNegative: false,
                  })}
                  placeholder={t('bond.minInvestAmount.placeHolder')}
                  onFocus={(e) => e.currentTarget.select()}
                  onChange={(e) => setMinInvestAmount(e.target.value)}
                />
              </div>
            </div>
            <div className="bond-layout__filter">
              <label>{t('bond.terms')}</label>
              <ReactSelect
                placeholder={t('bond.select')}
                className="filter-control-select"
                classNamePrefix="filter-control-select"
                options={
                  Array.isArray(terms)
                    ? [...terms, { label: 'txt-all', value: '' }]
                    : [{ name: 'txt-all', id: '' }]
                }
                getOptionLabel={(option) => t(option.label)}
                getOptionValue={(option) => option.value}
                isSearchable={false}
                components={{
                  IndicatorSeparator: () => null,
                }}
                defaultValue={term}
                value={term}
                onChange={(value) => setTerm(value)}
              />
            </div>
            <div className="bond-layout__filter">
              <label>{t('bond.investor.name')}</label>
              <ReactSelect
                placeholder={t('bond.select')}
                className="filter-control-select"
                classNamePrefix="filter-control-select"
                options={[
                  ...INVESTOR_TYPE.map((x) => ({
                    name: x.name,
                    id: x.id,
                  })),
                  { name: 'txt-all', id: '' },
                ]}
                getOptionLabel={(option) => t(option.name)}
                getOptionValue={(option) => option.id}
                isSearchable={false}
                key={lang}
                components={{
                  IndicatorSeparator: () => null,
                }}
                defaultValue={investorType}
                value={investorType}
                onChange={(value) => setInvestorType(value)}
              />
            </div>
            <div className="bond-layout__filter">
              <label>{t('bond.issuer')}</label>
              <ReactSelect
                placeholder={t('bond.select')}
                className="filter-control-select"
                classNamePrefix="filter-control-select"
                value={issuer}
                defaultValue={investorType}
                options={[...bondIssuers, { item: 'txt-all', id: '' }]}
                getOptionLabel={(option) => t(option.item)}
                getOptionValue={(option) => option.id}
                isSearchable={false}
                components={{
                  IndicatorSeparator: () => null,
                }}
                onChange={(e) => setIssuer(e)}
              />
            </div>
            <div className="bond-layout__filter button-group">
              <span
                className="iBtn iBtn--primary"
                onClick={() => getProductsHandle()}
              >
                <span>{t('bond.filter')}</span>
              </span>
              <div
                className="iBtn reload-button iBtn--secondary"
                onClick={() => resetHandle()}
              >
                <span className="icon iReload"></span>
              </div>
            </div>
          </div>
          {bondFixProducts && (
            <GroupTable
              data={bondFixProducts}
              setModalSize={setModalSize}
              limitInfor={limitInfor}
            />
          )}
        </div>
        <div className="bond-product__body--right pb-3">
          {bondInfor && (
            <>
              <div className="bond-introduce">
                <span className="text-title text--light fw-500">
                  {t('bond.introduce')}
                </span>
                <p className="text-desc mt-1 text--light3">
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        (bondInfor &&
                          bondInfor.find((x) => x.key == 'INTRO_FIXED')
                            ?.value) ||
                        '',
                    }}
                  />
                </p>
              </div>
              <div className="d-flex flex-column">
                <div className="bond-faq">
                  {setting?.pcbMapYN != 'Y' && (
                    <div
                      key={-1}
                      className="bond-introduce bond-introduce--faq d-flex justify-content-between align-items-center"
                    >
                      <span
                        className="text--light text-desc cursor-pointer"
                        onClick={() => {
                          registBondRetailTradingHandle();
                        }}
                      >
                        {t('bond.retailTrading.label')}
                      </span>

                      {token && setting?.pcbMapYN == 'P' && (
                        <span className="r">
                          {t('bond.retailTrading.pendingStatus')}
                        </span>
                      )}
                    </div>
                  )}

                  {bondInfor
                    .filter((x) => x.type == 'faq-fix')
                    ?.map((faq, i) => (
                      <div
                        key={i}
                        className="bond-introduce bond-introduce--faq"
                      >
                        <a
                          className="text--light text-desc"
                          href={faq.url}
                          target="_blank"
                          onClick={() => {
                            Mixpanel.bond.getMoreInfor(
                              'Bond Product List',
                              'Product Introduction',
                              faq.name
                            );
                          }}
                        >
                          {faq.name}
                        </a>
                      </div>
                    ))}

                  {/* <ScrollTable /> */}
                </div>
                <div className="bond-promotion">
                  <span className="text fw-500 text--light1">
                    {t('bond.nav.promotion')}
                  </span>
                  <table class="table table-bordered">
                    <thead className="h-32">
                      <th className="text text--light3 fw-500">
                        {t('bond.nav.name')}
                      </th>
                      <th className="text text--light3 fw-500">
                        {t('bond.nav.value')}
                      </th>
                    </thead>
                    <tbody>
                      {!!promotionByNav?.length > 0 &&
                        promotionByNav.map((item, i) => (
                          <tr key={i}>
                            <td class="text-center w-50 text text--light fw-400">
                              ≥ {numberFormat(item.frNav)}
                            </td>
                            <td class="text-center w-50 text--light">
                              {numberFormat(item.proRt, 2, '0')}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
);

export default FBond;
