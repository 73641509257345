import React from 'react';
import {
  chain,
  filter,
  find,
  remove,
  some,
  zipObject,
  cloneDeep,
} from 'lodash';
import { Nav, NavDropdown, Navbar } from 'react-bootstrap';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, Redirect, withRouter } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import icSearch from '../../../../assets/img/icons/price/Search.svg';
import {
  snapshotRequest,
  snapshotRequestSuccess,
  stockInfoRequest,
  setPriceTableSymbols,
} from '../../../../containers/banggia/actions';
import {
  saveCategoryRequest,
  setCategory,
  setSymbolScroll,
  setToast,
} from '../../../../containers/client/actions';
import { setRegSymbol } from '../../../../containers/socket/actions';
import { WebSocketContext } from '../../../../containers/socket/webSocket';
import {
  makeGetAllStock,
  makeGetCategory,
  makeGetSnapShot,
  makeGetToken,
} from '../../../../lib/selector';
import { saveState } from '../../../../lib/storages';
import StockSuggest from '../../../select/stockSuggest';
import WatchListActions from './watchListActions';
import { Mixpanel } from '../../../../lib/mixpanel';
import { getSymbolInfor, getTickerType } from '../../../../util';

class NavbarPriceboard extends React.PureComponent {
  static contextType = WebSocketContext;

  componentDidUpdate(preProps) {
    const { symbolDel, symbolAdd } = this.props;

    if (symbolDel && symbolDel !== preProps.symbolDel) {
      this._handleDelStock(symbolDel);
    }

    if (symbolAdd && symbolAdd !== preProps.symbolAdd) {
      this._handleAddStock(symbolAdd);
    }
  }

  _handleDelStock = (stock) => {
    let ws = this.context;
    const { category, categoryId, setCategory, token, cwList } = this.props;

    let isUpdate = false;
    let _category = [...category];
    _category.map((element, index) => {
      if (element.id === categoryId) {
        const _value = element.value.filter((x) => x !== stock);
        const _pinnedRow = element.pinnedRow.filter((x) => x !== stock);

        Mixpanel.track('Remove Ticker', {
          Ticker: stock,
          'Ticker Type': getTickerType(stock, cwList),
          'Watchlist Name': element?.name,
        });

        const payload = {
          action: 'leave',
          data: stock,
        };
        ws.sendMessage(payload);
        let _element;
        _element = { ...element, value: _value, pinnedRow: _pinnedRow };
        _category[index] = _element;

        this._handleSaveCategory(_element);
        isUpdate = true;
        return _element;
      }
    });

    if (isUpdate) {
      // save to action
      setCategory(_category);
      if (!token) {
        saveState('category', _category);
      }
      // this._handleSaveCategory(category);

      this.fetchCategoryData(categoryId);

      const { snapshot, snapshotRequestSuccess } = this.props;
      const snapshotRemove = filter(snapshot, (o) => o.sym !== stock);
      snapshotRequestSuccess(snapshotRemove);
    }
  };

  _handleAddStock = (_stock) => {
    const stock = _stock.stock_code;
    let ws = this.context;
    const {
      category,
      categoryId,
      setCategory,
      setToast,
      setRegSymbol,
      setSymbolScroll,
      token,
      t,
      regSym,
      cwList,
    } = this.props;

    const categoryGroup = find(
      category,
      (o) => o.type === 'group' && o.path.endsWith('/bang-gia/' + categoryId)
    );

    let isUpdate = false;
    const _category = [...category];

    if (categoryGroup) {
      const inCurrentCategory = regSym.includes(stock);
      if (inCurrentCategory) {
        setSymbolScroll(stock);
        const toastMsg = {
          id: Math.random(),
          msg: t('navbar.symbolExisted'),
          title: t('txt-notice'),
        };
        setToast(toastMsg);
      } else {
        const stockPostTo = _stock.post_to;
        if (stockPostTo == 'STO') {
          this.props.history.push({
            pathname: '/home/bang-gia/hsx',
            state: { stock },
          });
        } else if (stockPostTo == 'STX') {
          this.props.history.push({
            pathname: '/home/bang-gia/hnx',
            state: { stock },
          });
        } else if (stockPostTo == 'UPX') {
          this.props.history.push({
            pathname: '/home/bang-gia/upcom',
            state: { stock },
          });
        }
      }
    } else {
      _category.forEach((element, index) => {
        if (element.id === categoryId) {
          if (element.value.indexOf(stock) > -1) {
            setSymbolScroll(stock);
          } else {
            if (element.value.length > 100) {
              const toastMsg = {
                id: Math.random(),
                msg: 'Danh mục tối đa 100 mã',
                title: t('txt-notice'),
              };
              setToast(toastMsg);
              return;
            }
            let _value = [...element.value];
            _value.push(stock);

            let _element = { ...element, value: _value };
            _category[index] = _element;

            Mixpanel.track('Add Ticker', {
              Ticker: stock,
              'Ticker Type': getTickerType(stock, cwList),
              'Watchlist Name': element?.name,
              'Number of Ticker': _value?.length || 0,
            });

            // regis socket data
            const payload = {
              action: 'join',
              data: stock,
            };
            ws.sendMessage(payload);
            this._handleSaveCategory(_element);
            setRegSymbol(stock);
            isUpdate = true;
          }
        }
      });

      if (isUpdate) {
        // save to action
        setCategory(_category);
        if (!token) {
          saveState('category', _category);
        }
        this.fetchStockInfo(stock);
      }
    }
  };

  _handleSaveCategory = (_newCategory) => {
    const { token, saveCategoryRequest } = this.props;

    if (!token) return;

    const uuid = uuidv4();
    const resData = {
      group: 'BACK',
      user: token.user,
      session: token.session,
      cmd: 'SET_WAT',
      rqId: uuid,
      channel: 'WTS',
      data: _newCategory,
    };

    saveCategoryRequest(resData);
  };

  fetchStockInfo = (stock) => {
    const { stockInfoRequest } = this.props;
    return stockInfoRequest(stock);
  };

  fetchCategoryData = (categoryId) => {
    const { snapshotRequest, category, allStock } = this.props;

    const catalogSelected = find(
      category,
      (o) => o.path && o.path.endsWith('/bang-gia/' + categoryId)
    );

    if (catalogSelected && catalogSelected.value) {
      const value = catalogSelected.value;
      return snapshotRequest(catalogSelected.value.join(','));
    } else {
      return null;
    }
  };

  minimizeText = (text) => {
    const maxLength = 25;
    if (!text) return '';
    if (text.length < maxLength) return text;
    else return `${text.substring(0, maxLength)}...`;
  };

  render() {
    const {
      allStock,
      category,
      categoryId,
      token,
      getCategorySuccessful,
      getCategoryUnsuccessful,
      t,
    } = this.props;

    const checkCategoryId = category
      ? some(
          category,
          (o) => o.path && o.path.endsWith('/bang-gia/' + categoryId)
        )
      : true;
    const invalidCategoryUnauthUser = !token && !checkCategoryId;
    const invalidCategoryAuthUser =
      token &&
      (getCategorySuccessful || getCategoryUnsuccessful.length > 0) &&
      !checkCategoryId;
    if (invalidCategoryUnauthUser || invalidCategoryAuthUser) {
      return <Redirect to="/" />;
    }

    const categoryGroup = filter(category, (o) => o.type === 'group');
    const categoryWatchList = filter(category, (o) => o.type === 'watchlist');

    const _grCtl = chain(categoryGroup)
      .groupBy('groupName')
      .toPairs()
      .map((pair) => zipObject(['groupName', 'groupValue'], pair))
      .value();

    const _grActive = find(_grCtl, (o) =>
      some(o.groupValue, (ol) => ol.path.endsWith('/bang-gia/' + categoryId))
    );

    const _pathActive = find(categoryGroup, (o) =>
      o.path.endsWith('/bang-gia/' + categoryId)
    );

    const trackHandle = (item) => {
      if (item) {
        Mixpanel.viewWatchlist({
          'Watchlist Name': item.name,
          'Watchlist Type': 'Normal Watchlist',
          'Ticker List': item.value,
          'Number of Ticker': item.value?.length || 0,
          'Watchlist View Location': 'Home',
        });
      }
    };

    const grpTrackHandle = (name, item) => {
      if (item) {
        Mixpanel.viewStockList({
          'Preset Group': item.groupName,
          'Preset Name': name,
          'List Feature': 'Basic List',
          'List Location': 'Home',
        });
      }
    };

    return (
      <Navbar
        bg="dark"
        variant="dark"
        expand="md"
        className="navbar-priceboard"
      >
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="d-flex align-items-center mr-auto">
            <div className="po-relative pr-2 form-inline">
              <StockSuggest
                suggestionData={allStock}
                selectStock={this._handleAddStock}
                inputProps={{
                  className: 'form-add form-control fz_14',
                  placeholder: t('txt-add-symbol'),
                }}
              />
              <img src={icSearch} className="form-add-img" />
            </div>
            {categoryWatchList &&
              !!categoryWatchList.length &&
              categoryWatchList.map((item, i) => {
                if (item.isPin)
                  return (
                    <div
                      className=" po-relative"
                      key={item.type + '_' + item.id}
                    >
                      <Link
                        to={item.path}
                        className={
                          'watchlist-item nav-link mr-2 ' +
                          (categoryId === item.id ? 'active' : '')
                        }
                        title={item.name}
                        onClick={() => trackHandle(item)}
                      >
                        {item.name === 'Mặc định'
                          ? t('txt-label-default-watchlist')
                          : item.name}
                      </Link>
                    </div>
                  );
              })}
            <WatchListActions categoryId={categoryId} />
            <div className="line"></div>
            {_grCtl &&
              !!_grCtl.length &&
              _grCtl.map((item, index) => {
                if (item.groupName === 'CHUNGQUYEN') {
                  return (
                    <Link
                      key={'gr_' + item.groupName + '_' + index}
                      to={item.groupValue[0].path}
                      className={
                        'nav-link mr-2 ' +
                        (item.groupValue[0].path.endsWith(
                          '/bang-gia/' + categoryId
                        )
                          ? 'active'
                          : '')
                      }
                      onClick={() => grpTrackHandle(t('txt-cw'), item)}
                    >
                      {t('txt-cw')}
                    </Link>
                  );
                }

                if (item.groupName == 'CPNGANH') {
                  return (
                    <NavDropdown
                      title={`${t('txt-industry')} ${
                        _grActive && _grActive.groupName === item.groupName
                          ? '(' + this.minimizeText(_pathActive.name) + ')'
                          : ''
                      }`}
                      className="mr-2 industry-menu"
                      active={
                        _grActive && _grActive.groupName === item.groupName
                      }
                      id="basic-nav-dropdown"
                      key={'gr_' + index}
                    >
                      {item.groupValue.map((element, i) => (
                        <Link
                          key={'gr_' + item.groupName + '_' + i}
                          to={element.path}
                          className={
                            'nav-link  ' +
                            (element.path.endsWith('/bang-gia/' + categoryId)
                              ? 'active'
                              : '')
                          }
                          title={element.name}
                          onClick={() => grpTrackHandle(element.name, element)}
                        >
                          {element.name}
                        </Link>
                      ))}
                    </NavDropdown>
                  );
                }

                if (item.groupName == 'OddLot') {
                  return (
                    <NavDropdown
                      title={`${
                        _grActive && _grActive.groupName === item.groupName
                          ? t(_pathActive.name)
                          : t('txt-odd-lot')
                      }`}
                      className="mr-2 oddlot-menu"
                      active={
                        _grActive && _grActive.groupName === item.groupName
                      }
                      id="basic-nav-dropdown"
                      key={'gr_' + index}
                    >
                      {item.groupValue.map((element, i) => (
                        <Link
                          key={'gr_' + item.groupName + '_' + i}
                          to={element.path}
                          className={
                            'nav-link  ' +
                            (element.path.endsWith('/bang-gia/' + categoryId)
                              ? 'active'
                              : '')
                          }
                          title={t(element.name)}
                          onClick={() =>
                            grpTrackHandle(t(element.name), element)
                          }
                        >
                          {t(element.name)}
                        </Link>
                      ))}
                    </NavDropdown>
                  );
                }

                // if (item.groupName == 'Buyin') {
                //   return (
                //     <NavDropdown
                //       title={`${
                //         _grActive && _grActive.groupName === item.groupName
                //           ? t(_pathActive.name)
                //           : t('txt-buy-in')
                //       }`}
                //       className="mr-2"
                //       active={
                //         _grActive && _grActive.groupName === item.groupName
                //       }
                //       id="basic-nav-dropdown"
                //       key={'gr_' + index}
                //     >
                //       {item.groupValue.map((element, i) => (
                //         <Link
                //           key={'gr_' + item.groupName + '_' + i}
                //           to={element.path}
                //           className={
                //             'nav-link  ' +
                //             (element.path.endsWith('/bang-gia/' + categoryId)
                //               ? 'active'
                //               : '')
                //           }
                //           title={t(element.name)}
                //           onClick={() =>
                //             grpTrackHandle(t(element.name), element)
                //           }
                //         >
                //           {t(element.name)}
                //         </Link>
                //       ))}
                //     </NavDropdown>
                //   );
                // }

                return (
                  <NavDropdown
                    title={`${t(item.groupName)} ${
                      _grActive && _grActive.groupName === item.groupName
                        ? '(' + this.minimizeText(t(_pathActive.name)) + ')'
                        : ''
                    }`}
                    className={`mr-2`}
                    active={_grActive && _grActive.groupName === item.groupName}
                    id="basic-nav-dropdown"
                    key={'gr_' + index}
                  >
                    {item.groupValue.map((element, i) => {
                      return (
                        <Link
                          key={'gr_' + item.groupName + '_' + i}
                          to={element.path}
                          className={
                            'nav-link  ' +
                            (element.path.endsWith('/bang-gia/' + categoryId)
                              ? 'active'
                              : '')
                          }
                          title={t(element.name)}
                          onClick={() =>
                            grpTrackHandle(t(element.name), element)
                          }
                        >
                          {t(element.name)}
                        </Link>
                      );
                    })}
                  </NavDropdown>
                );
              })}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

const makeMapStateToProps = () => {
  const getToken = makeGetToken();
  const getAllStock = makeGetAllStock();
  const getCategory = makeGetCategory();
  const getSnapShot = makeGetSnapShot();

  const mapStateToProps = (state) => {
    return {
      token: getToken(state),
      allStock: getAllStock(state),
      category: getCategory(state),
      snapshot: getSnapShot(state),
      getCategorySuccessful: state.client.categorySuccessful,
      getCategoryUnsuccessful: state.client.categoryErrors,
      symbolDel: state.client.symbolDel,
      symbolAdd: state.client.symbolAdd,
      regSym: state.socket.regSym,
      cwList: state.priceBoard.cwList,
    };
  };
  return mapStateToProps;
};

export default connect(makeMapStateToProps, {
  snapshotRequest,
  stockInfoRequest,
  snapshotRequestSuccess,
  setCategory,
  setToast,
  saveCategoryRequest,
  setRegSymbol,
  setSymbolScroll,
  setPriceTableSymbols,
})(translate('translations')(withRouter(NavbarPriceboard)));
