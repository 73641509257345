import React, { useState, useEffect } from 'react';
import { withNamespaces } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';

const confirmSellType = withNamespaces('translations')(
  ({ t, handleQuickOrder, setShowSellType, handleSellBond, state = false }) => {
    const dispatch = useDispatch();
    const { currentTheme } = useSelector((state) => state.client);
    const handleClose = () => {
      setShowSellType(false);
    };
    return (
      <Modal
        show={state}
        dialogClassName="wts-modal"
        backdropClassName="wts-modal-backdrop"
        contentClassName={`${
          currentTheme?.name || 'dark'
        } bond-notification wts-modal-content wts-modal-content--sm`}
        onEscapeKeyDown={handleClose}
        centered
        onHide={handleClose}
        style={{ zIndex: 11112 }}
      >
        <div className="bond-notification__body">
          <span className="text-title text--light">
            {t('bond.popUp.chooseSellType')}
          </span>
        </div>
        <div className="bond-notification__footer">
          <div className="bond-notification__actions">
            <button
              className="btn btn--primary"
              onClick={() => {
                handleQuickOrder(state);
                setShowSellType(false);
              }}
            >
              <span>{t('bond.popUp.quickSell')}</span>
            </button>
            <button
              className="btn btn--cancel"
              onClick={() => {
                handleSellBond(state);
                setShowSellType(false);
              }}
            >
              <span>{t('bond.popUp.sellFromDealList')}</span>
            </button>
          </div>
        </div>
      </Modal>
    );
  }
);

export default confirmSellType;
