import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import { makeGetSummaryOrder } from '../../lib/selector';

import PerfectScrollBar from 'react-perfect-scrollbar';
import { Col } from 'react-bootstrap';

import { _convertTpToPrice, _getOrderStatus } from '../../util';

import Paging from '../paging';

class GDLoLe extends React.PureComponent {
  handleQueryPage = (page) => {};

  render() {
    const { account, listOrder } = this.props;

    return (
      <Fragment>
        <PerfectScrollBar style={{ height: 'calc( 100% - 50px )' }}>
          <div className="d-flex">
            <Col className="d-flex flex-column">
              <div className="d-flex flex-row mt-3 mb-2">
                <span className="text-white" style={{ fontSize: '18px' }}>
                  {`Giao dịch cổ phiếu lô lẻ`}
                </span>
              </div>
              <div className="d-flex justify-content-between align-items-center mt-3 mb-2">
                <label className="text-white ml-2 fz_14 text-uppercase">
                  {'Danh sách lệnh'}
                </label>
                <div className="d-flex">
                  <button className="btn btn-info fz_14 fw-500 text-white">
                    Đăng ký
                  </button>
                </div>
              </div>
              <table className="table table-bordered tbl-list mt-2">
                <thead>
                  <tr>
                    <th className="text-center">
                      <input type="checkbox" />
                    </th>
                    <th className="text-left">{'Mã CK'}</th>
                    <th className="text-left">{'Số dư GD'}</th>
                    <th className="text-left">{'SL lô lẻ'}</th>
                    <th className="text-left">{'Giá hiện tại'}</th>
                    <th className="text-left">{'Giá GD lô lẻ'}</th>
                  </tr>
                </thead>
              </table>
              {(!listOrder || !listOrder.list || !listOrder.list.length) && (
                <p className="text-center fz_14 mt-2 title-time">
                  {'Không có dữ liệu'}
                </p>
              )}
              {listOrder && (
                <Paging
                  handleQueryPage={this.handleQueryPage}
                  continued={listOrder.continued}
                  totalCount={listOrder.totalCount}
                  pageSize={15}
                />
              )}
            </Col>
          </div>
        </PerfectScrollBar>
      </Fragment>
    );
  }
}

const makeMapStateToProps = () => {
  const getSummaryOrder = makeGetSummaryOrder();

  const mapStateToProps = (state, props) => {
    return {
      listOrder: getSummaryOrder(state),
    };
  };
  return mapStateToProps;
};

export default connect(makeMapStateToProps)(GDLoLe);
