import React from 'react';

import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';

// import Loading from '../loading';

class PieChart extends React.Component {
  constructor(props) {
    super(props);

    this.chartComponent = React.createRef();

    this.state = {
      chartOptions: {},
    };
  }

  componentDidUpdate(preProps) {
    const { data1, data2 } = this.props;
    if (!data1 || !data2) return;

    if (data1 && data1 !== preProps.data1) this._mapDataToState();
  }

  _mapDataToState = () => {
    const { data1, data2 } = this.props;
    if (!data1 || !data2) return;
    const chartOptions = {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
        height: 200,
        spacingLeft: 10,
      },
      title: { text: ' ' },
      credits: {
        enabled: false,
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
      },
      // accessibility: {
      //     point: {
      //         valueSuffix: '%'
      //     }
      // },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: false,
          },
          showInLegend: true,
          borderWidth: 0,
          center: ['25%', '50%'],
        },
      },
      legend: {
        align: 'right',
        layout: 'vertical',
        verticalAlign: 'middle',
        itemStyle: { color: '#ffffff' },
      },
      series: [
        {
          name: 'Tỷ lệ',
          colorByPoint: true,
          data: [
            {
              name: data1.name,
              y: data1.value,
              sliced: false,
              selected: true,
              color: data1.color,
            },
            {
              name: data2.name,
              y: data2.value,
              color: data2.color,
            },
          ],
        },
      ],
    };
    this.setState({ chartOptions });
  };

  componentWillMount() {
    this._mapDataToState();
  }

  render() {
    return (
      <HighchartsReact
        ref={this.chartComponent}
        highcharts={Highcharts}
        options={this.state.chartOptions}
        // containerProps={{ style: { height: '200px' } }}
      />
    );
  }
}

export default PieChart;
