import React from 'react';
import Select from 'react-select';

const _Styles = {
  control: () => ({
    display: 'flex',
    height: '28px',
    minHeight: '28px',
    backgroundColor: '#16191B',
    borderRadius: '4px',
    position: 'relative',
    width: '236px',
  }),
  valueContainer: (styles) => ({ ...styles, padding: 0 }),
  indicatorContainer: () => ({}),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  menu: () => ({
    position: 'absolute',
    top: 30,
    backgroundColor: '#16191B',
    width: '236px',
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    zIndex: 2,
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? '#F2F2F3' : '#000',
    backgroundColor: state.isSelected ? '#16191B' : '#fff',
    padding: 5,
    fontSize: '14px',
  }),
  singleValue: (styles) => ({
    ...styles,
    color: '#F2F2F3',
    padding: '2px 5px',
    fontSize: '14px',
  }),
};

export default class ReactSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: '',
    };
  }

  componentDidMount() {
    const { defVal } = this.props;
    this.setState({ value: defVal });
  }

  _handleChange = (value) => {
    // console.log(value);
    this.setState({ value });
    this.props.handleChangeUser(value);
  };

  componentDidUpdate(preProps) {
    const { defVal } = this.props;
    if (defVal && defVal !== preProps.defVal) {
      this.setState({ value: defVal });
    }
  }

  render() {
    const { defVal, grOptions, clsContainer, isDisabled } = this.props;
    return (
      <Select
        classNamePrefix="filter-control-select"
        defaultValue={defVal}
        options={grOptions}
        styles={_Styles}
        isDisabled={isDisabled}
        isLoading={false}
        isClearable={false}
        isSearchable={true}
        className={(clsContainer || 'ml-2') + ' '}
        value={this.state.value}
        onChange={this._handleChange}
      />
    );
  }
}
