import React, { memo, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import AuthenStep from '../../../../components/common/auth';
import { withNamespaces } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import {
  PARTNER_FEE_POLICY,
  PARTNER_REQUEST_TYPE,
  PARTNER_SERVICE_TYPE,
  PARTNER_SIDE_REQUEST,
} from '../../../../util/constant';
import {
  getCommonRequest,
  setChangeFeeOpenModal,
} from '../../../customer/actions';
import { makeGetDefaultAccount } from '../../../../lib/selector';
import { setAuth, setToast } from '../../../client/actions';
import ViewFeeDefault from '../partnership/viewFeeDefault';

const ModalCancelService = withNamespaces('translations')(
  ({ service, t, onSuccess }) => {
    const dispatch = useDispatch();
    const theme = useSelector((state) => state.client.currentTheme);
    const {
      auth,
      token,
      setting: { lang = 'vi' },
    } = useSelector((state) => state.client);
    const defaultAccount = useSelector(makeGetDefaultAccount());
    const [defaultFee, setDefaultFee] = useState([]);
    const { serList4Cus } = useSelector((state) => state.customer);

    const [serviceToDelete, setServiceToDelete] = useState(null);
    const [oneTimeToken, setOneTimeToken] = useState();

    const handleDeleteService = (value) => {
      setServiceToDelete(value);
    };

    const handleCancelDelete = () => {
      setServiceToDelete(null);
    };

    const toastHandle = (msg, title = t('txt-notice')) => {
      const toastMsg = {
        id: Math.random(),
        msg: msg,
        title: title,
      };
      dispatch(setToast(toastMsg));
    };

    const handleSubmit = () => {
      if (!oneTimeToken) {
        toastHandle(t('account.authen.letEnterOTPCorrect'));
        return;
      }
      const uuid = uuidv4();
      const params = {
        group: 'CORE',
        user: token.user,
        session: token.session,
        cmd: 'regUnregRef',
        rqId: uuid,
        channel: 'WTS',
        type: '6',
        token: oneTimeToken,
        data: {
          cif: token.cif,
          acntNo: defaultAccount.acntNo,
          vsdAcntNo: defaultAccount.vsdAcntNo,
          side: PARTNER_SIDE_REQUEST.customer,
          brkId: serList4Cus.brokerId,
          reqTp: PARTNER_REQUEST_TYPE.cancelRegisterService,
          serTp: service.serTp,
          custPhone: token.cellPhone,
        },
      };
      const callback = (data) => {
        toastHandle(t('partner.createRequestSuccess'));
        handleCancelDelete();
        onSuccess();
      };
      dispatch(getCommonRequest(params, callback, true));
    };

    useEffect(() => {
      if (!serviceToDelete) return;
      const uuid = uuidv4();
      const query = {
        group: 'CORE',
        user: token.user,
        session: token.session,
        cmd: 'getFeePolicy',
        rqId: uuid,
        channel: 'WTS',
        data: {
          cif: token.cif,
          acntNo: defaultAccount.acntNo,
          grpAcctTp: '2',
          serTp: `${service.serTp}`,
        },
      };

      dispatch(
        getCommonRequest(
          query,
          (res) => {
            setDefaultFee(res);
          },
          true
        )
      );
    }, [serviceToDelete]);

    return (
      <>
        <button
          className={`btn w-28 ${
            auth && auth.isVerified ? 'btn-cancel' : 'btn--authen'
          }`}
          onClick={() => {
            if (auth && auth.isVerified) {
              handleDeleteService(service);
            } else {
              dispatch(setAuth());
            }
          }}
        >
          <span
            className={
              auth && auth.isVerified ? 'icon iClose bg-red' : 'icon iClose'
            }
          ></span>
        </button>
        <Modal
          show={serviceToDelete}
          onHide={handleCancelDelete}
          onEscapeKeyDown={handleCancelDelete}
          dialogClassName="wts-modal"
          backdropClassName="wts-modal-backdrop"
          contentClassName={`${
            theme.name || 'dark'
          } wts-modal-content wts-modal-content--sm2`}
          centered
          style={{ zIndex: 11114 }}
        >
          <div className="modal-partnership-service">
            <div className="modal-partnership-service__head">
              <div className="title">{t('partner.cancelService')}</div>
              <div className="right-section" onClick={handleCancelDelete}>
                <span className={'icon iClose'}></span>
              </div>
            </div>
            <div className="modal-partnership-service__body">
              <div className="partner">
                <div className="partner__item">
                  <div className="text text--sm text--light3">
                    {t('partner.referralCode')}
                  </div>
                  <div className="text text--sm text--light">
                    {serList4Cus.brokerId}
                  </div>
                </div>
                <div className="partner__item">
                  <div className="text text--sm text--light3">
                    {t('partner.referralName')}
                  </div>
                  <div className="text text--sm text--light">
                    {serList4Cus.brokerNm}
                  </div>
                </div>
              </div>
              <div className="service">
                <ViewFeeDefault t={t} listFee={defaultFee} />
              </div>
              <AuthenStep
                setOneTimeToken={setOneTimeToken}
                trackMixpanel
                isBorder={false}
              />
              <div className="action">
                <button
                  className={'btn btn--primary w-100 h-32'}
                  onClick={handleSubmit}
                >
                  {t('txt-confirm')}
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  }
);
export default memo(ModalCancelService);
