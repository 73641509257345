import React, { useEffect, useRef, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import ReactSelect from 'react-select';
import CheckboxButton from '../../../components/checkbox/checkBoxButtonMgCpt';
import PerfectScrollBar from 'react-perfect-scrollbar';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { registPinefolioAccount } from '../../pinefolio/action';
import {
  getCptRoundInfoRequest,
  getRegistCptStatRequest,
  registerCompetitonRequest,
  setRoundViewResult,
  unRegistCpt,
} from '../actions';
import { formatToNewDate, numberFormat } from '../../../util';
import { setAuth, setLogin } from '../../client/actions';
import Popup from '../../../components/modal/bookingMarginCall/popupCustom';
import PopupRegisterSuccess from '../../../components/modal/marginCompetition/popupRegisterSuccess';
import { constants } from '../../../util/constant';
import { Mixpanel } from '../../../lib/mixpanel';

const GeneralIntro = withNamespaces('translations')(({ t, setTabActive }) => {
  const { defaultAccount, listAccount } = useSelector((state) => state.socket);
  const { token, auth } = useSelector((state) => state.client);
  const { appsetting, setting } = useSelector((state) => state.client);
  const theme = useSelector((state) => state.client.currentTheme);
  const { cptRoundInfo, registCptStat, nickName } = useSelector(
    (state) => state.marginCompetition
  );
  const dispatch = useDispatch();
  const [accountReg, setAccountReg] = useState();
  const [check, setCheck] = useState(false);
  const lang = setting.lang || 'vi';
  const [showRoundInfo, setShowRoundInfo] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [showConfirmStop, setShowConfirmStop] = useState(false);

  const competitionInfor = appsetting?.find(
    (item) => item.key == 'COMPETITION_INFOR'
  )?.values;
  const link =
    competitionInfor &&
    JSON.parse(competitionInfor)?.find((item) => item.key == 'link').value;

  const ACCOUNT = [
    { key: 'N', value: 'txt-normal' },
    { key: 'M', value: 'txt-margin' },
  ];

  function hanldeSelectSubAccount(value) {
    setAccountReg(value);
  }

  function handleRegisterCompetition() {
    if (!token) {
      dispatch(setLogin());
      return;
    }
    if (!(auth && auth.isVerified)) {
      dispatch(setAuth());
      return;
    }
    const uuid = uuidv4();
    const params = {
      group: 'CORE',
      cmd: 'registCpt',
      user: token.user,
      session: token.session,
      type: auth.type,
      token: auth.token,
      rqId: uuid,
      channel: 'WTS',
      data: {
        cif: token?.cif,
        acntNo: defaultAccount?.acntNo,
        acntType: accountReg?.key,
        langTp: lang,
      },
    };

    dispatch(registerCompetitonRequest(params, () => handleRegistCptSuccess()));
  }

  function getCptRoundInfo() {
    const uuid = uuidv4();
    const params = {
      group: 'CORE',
      cmd: 'getCptRoundInfo',
      rqId: uuid,
      channel: 'WTS',
      data: {
        langTp: lang,
      },
    };

    dispatch(getCptRoundInfoRequest(params));
  }

  function handleRegistCptSuccess() {
    setShowModal(true);
    getRegistCptStat();

    Mixpanel.track('Register Trading Competition', {
      CIF: token?.cif,
      'Account Registed Type': accountReg
        ? accountReg.key == 'N'
          ? 'Normal'
          : 'Margin'
        : '',
      'Round Registered': '',
    });

    Mixpanel.people.set({ 'Competition Round Registered': [] });
  }

  useEffect(() => {
    getCptRoundInfo();
  }, []);

  useEffect(() => {
    if (appsetting) {
      const competitionInfor = appsetting?.find(
        (item) =>
          item.key == 'COMPETITION_INFOR' &&
          item.module == constants.modules.COMPETITION
      )?.values;

      const round =
        competitionInfor &&
        JSON.parse(competitionInfor)?.find((item) => item.key == 'round')
          ?.value;

      const _roundDefault =
        round && round?.find((item) => item.key == 'default')?.value;
      setShowRoundInfo(_roundDefault);
    }
  }, [appsetting]);

  useEffect(() => {
    if (showRoundInfo && cptRoundInfo && cptRoundInfo.length > 0) {
      let _currRound = cptRoundInfo.find((item) => item.rndCd == showRoundInfo);
      if (_currRound.stat == '3') {
        let ele = document.getElementById('time-remain');
        if (ele) {
          ele.innerHTML =
            "<div class='d-flex justify-content-center gap-12'><div class='text--light3'>" +
            t('marginCompetition.generalIntroduction.roundFinished', {
              round: _currRound.rndNo,
            }) +
            `</div><div class='f cursor-pointer'>${t(
              'marginCompetition.generalIntroduction.viewResult'
            )}</div></div>`;
          ele.addEventListener('click', () =>
            handleViewResult(_currRound.rndCd)
          );
        }
      } else if (_currRound.stat == '2') {
        let ele = document.getElementById('time-remain');
        if (ele) {
          ele.innerHTML =
            "<div class='d-flex justify-content-center gap-12'><div class='text--light3'>" +
            t('marginCompetition.generalIntroduction.roundCompeting', {
              round: _currRound.rndNo,
            });
        }
      } else {
        var countDownDate = formatToNewDate(_currRound.stDt)?.getTime();

        var _now = new Date().getTime();
        var _distance = countDownDate - _now;

        if (_distance > 0) {
          let itv = setInterval(() => {
            var now = new Date().getTime();
            var distance = countDownDate - now;
            var days = Math.floor(distance / (1000 * 60 * 60 * 24));
            var hours = Math.floor(
              (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
            );
            var minutes = Math.floor(
              (distance % (1000 * 60 * 60)) / (1000 * 60)
            );
            var seconds = Math.floor((distance % (1000 * 60)) / 1000);
            let ele = document.getElementById('time-remain');

            if (_distance <= 0) {
              getCptRoundInfo();
              return;
            }

            if (ele) {
              ele.innerHTML =
                t('marginCompetition.generalIntroduction.roundStartIn', {
                  round: _currRound?.rndNo,
                }) +
                ' ' +
                _formatNumber(days) +
                `${t('marginCompetition.generalIntroduction.days')} : ` +
                _formatNumber(hours) +
                `${t('marginCompetition.generalIntroduction.hours')} : ` +
                _formatNumber(minutes) +
                `${t('marginCompetition.generalIntroduction.min')} : ` +
                _formatNumber(seconds) +
                `${t('marginCompetition.generalIntroduction.sec')}`;
            }
          }, 1000);

          return () => {
            if (itv) {
              clearInterval(itv);
            }
          };
        }
      }
    }
  }, [showRoundInfo, lang, cptRoundInfo]);

  function handleUnregiter() {
    let callback = () => {
      getRegistCptStat();
      setCheck(false);
      Mixpanel.track('Cancel Trading Registration', {
        CIF: token?.cif,
        'Round Canceled': '',
      });
      Mixpanel.people.set({ 'Competition Round Registered': [] });
    };
    if (auth && auth.isVerified) {
      const uuid = uuidv4();
      const params = {
        group: 'CORE',
        cmd: 'unregistCpt',
        rqId: uuid,
        user: token?.user,
        session: token?.session,
        token: auth?.token,
        type: auth.type,
        channel: 'WTS',
        data: {
          cif: token?.cif,
          acntNo: defaultAccount?.acntNo,
          langTp: lang,
        },
      };
      dispatch(unRegistCpt(params, () => callback()));
    } else {
      dispatch(setAuth());
    }
  }

  function _formatNumber(number) {
    let len = (number + '').length;
    if (!len) {
      return '00';
    } else if (len == 1) {
      return '0' + number;
    } else {
      return number;
    }
  }

  function handleViewResult(roundCode) {
    dispatch(setRoundViewResult(roundCode));
    setTabActive(constants.marginCompetition.LEADER_BOARD);
  }

  function onConfirmUnregist() {
    handleUnregiter();
    setShowConfirmStop(false);
  }

  function handleShowConfirmStop() {
    if (auth && auth.isVerified) {
      setShowConfirmStop(true);
    } else {
      dispatch(setAuth());
    }
  }

  function getRegistCptStat() {
    const params = {
      group: 'CORE',
      cmd: 'getRegistCptStat',
      session: token?.session,
      user: token?.user,
      channel: 'WTS',
      data: {
        cif: token?.cif,
      },
    };

    dispatch(getRegistCptStatRequest(params));
  }

  return (
    <div className="layout-page__body layout__body">
      <PerfectScrollBar>
        <div className="general-intro">
          <div className="wrapper">
            <div className="banner">
              <div className="organizer">
                <span className="text--light3">
                  {t('marginCompetition.generalIntroduction.coHostedBy')}
                </span>
                <div className="organizer__logo">
                  <div
                    className="pinetree"
                    style={{
                      backgroundImage: `${
                        theme?.name == 'dark'
                          ? "url('/images/pinetreeDark.svg')"
                          : "url('/images/pinetreeLight.svg')"
                      }`,
                    }}
                  ></div>
                  <div
                    className="cafeF"
                    style={{
                      backgroundImage: `${
                        theme?.name == 'dark'
                          ? "url('/images/cafeFDark.svg')"
                          : "url('/images/cafeFLight.svg')"
                      }`,
                    }}
                  ></div>
                </div>
              </div>
              <div className="title">
                <span className="iMrBanner"></span>
              </div>
              <div className="overview">
                <div className="overview-item">
                  <span className="icon iTotalAward"></span>
                  <span className="text--light">
                    {t('marginCompetition.generalIntroduction.totalAward')}
                  </span>
                </div>
                <div className="overview-item">
                  <span className="icon iPrize"></span>
                  <span className="text--light">
                    {t('marginCompetition.generalIntroduction.superPrize')}
                  </span>
                </div>
                <div className="overview-item">
                  <span className="icon iZeroFee"></span>
                  <span className="text--light">
                    {t('marginCompetition.generalIntroduction.zeroFee')}
                  </span>
                </div>
                <div className="overview-item">
                  <span className="icon iMargin9ps"></span>
                  <span className="text--light">
                    {t('marginCompetition.generalIntroduction.fixedMargin')}
                  </span>
                </div>
              </div>
            </div>
            <div className="content  text--gray fw_400">
              <p>{t('marginCompetition.generalIntroduction.content')}</p>
            </div>
            <div className="round-infor">
              {cptRoundInfo &&
                cptRoundInfo.map((item, index) =>
                  item.rndCd == showRoundInfo ? (
                    <div className="round current-round" key={index}>
                      <div className="fz_24 text-center">
                        <div id="time-remain"></div>
                      </div>
                      <div className="d-flex gap-48 justify-content-center">
                        <div className="d-flex flex-column align-items-center gap-12">
                          <div className="text-center badge fw-400">
                            {t(
                              'marginCompetition.generalIntroduction.registrationPeriod'
                            )}
                          </div>
                          <div className="d-flex gap-12 align-items-center">
                            <div className="br-1 b-dashed b-gray d-flex flex-column p-2">
                              <span className="fz_20 fw-500 lh-32 text--light">
                                {item.regStDt.slice(6, 8) +
                                  '/' +
                                  item.regStDt.slice(4, 6)}
                              </span>
                              <span className="fz_13 fw-500 lh-20 text-center text-gray">
                                {item.regStDt.slice(0, 4)}
                              </span>
                            </div>
                            -
                            <div className="br-1 b-dashed b-gray d-flex flex-column p-2">
                              <span className="fz_20 fw-500 lh-32 text--light">
                                {item.regEnDt.slice(6, 8) +
                                  '/' +
                                  item.regEnDt.slice(4, 6)}
                              </span>
                              <span className="fz_13 fw-500 lh-20 text-center text-gray">
                                {item.regEnDt.slice(0, 4)}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex flex-column align-items-center gap-12">
                          <div className="text-center badge fw-400">
                            {t(
                              'marginCompetition.generalIntroduction.competingPeriod'
                            )}
                          </div>
                          <div className="d-flex gap-12 align-items-center">
                            <div className="br-1 b-dashed b-gray d-flex flex-column p-2">
                              <span className="fz_20 fw-500 lh-32 text--light">
                                {item.stDt.slice(6, 8) +
                                  '/' +
                                  item.stDt.slice(4, 6)}
                              </span>
                              <span className="fz_13 fw-500 lh-20 text-center text-gray">
                                {item.stDt.slice(0, 4)}
                              </span>
                            </div>
                            -
                            <div className="br-1 b-dashed b-gray d-flex flex-column p-2">
                              <span className="fz_20 fw-500 lh-32 text--light">
                                {item.enDt.slice(6, 8) +
                                  '/' +
                                  item.enDt.slice(4, 6)}
                              </span>
                              <span className="fz_13 fw-500 lh-20 text-center text-gray">
                                {item.enDt.slice(0, 4)}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="round" key={index}>
                      <div className="round-title">
                        {item.stat == '3'
                          ? t(
                              'marginCompetition.generalIntroduction.roundFinished',
                              {
                                round: item.rndNo,
                              }
                            )
                          : item.stat == '1'
                          ? t(
                              'marginCompetition.generalIntroduction.roundIncoming',
                              {
                                round: item.rndNo,
                              }
                            )
                          : t(
                              'marginCompetition.generalIntroduction.roundCompeting',
                              {
                                round: item.rndNo,
                              }
                            )}
                      </div>
                      <div
                        className="btn btn--cancel"
                        onClick={() => {
                          setShowRoundInfo(item.rndCd);
                          Mixpanel.track('Get More Info', {
                            'Screen Name': 'General Introduction',
                            'Info Group': 'Round Info',
                            'Info Detail': `Round ${item.rndNo}`,
                          });
                        }}
                      >
                        {t('marginCompetition.generalIntroduction.viewInfo')}
                      </div>
                    </div>
                  )
                )}
            </div>
            {registCptStat?.stat == 'C' ||
            registCptStat?.stat == 'N' ||
            !token ? (
              <div className="form-register">
                <span className="text--light3">
                  {t('marginCompetition.generalIntroduction.selectAccount')}
                </span>
                <div className="account-select">
                  <ReactSelect
                    defaultValue={accountReg}
                    className="filter-control-select"
                    classNamePrefix="filter-control-select"
                    options={ACCOUNT}
                    getOptionLabel={(option) => t(option.value)}
                    getOptionValue={(option) => option.key}
                    value={accountReg}
                    onChange={(value) => hanldeSelectSubAccount(value)}
                    isSearchable={false}
                    placeholder={t(
                      'marginCompetition.generalIntroduction.selectAccountForCompetition'
                    )}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                  />
                </div>
                <CheckboxButton
                  onClick={() => setCheck(!check)}
                  check={check}
                  description={`${t(
                    'marginCompetition.generalIntroduction.agreeWithTC',
                    {
                      link: link?.find((item) => item.key == 'gi')?.value,
                    }
                  )}`}
                />
                {check && accountReg ? (
                  <span
                    className={`btn btn--${
                      !token || (auth && auth.isVerified) ? 'primary' : 'authen'
                    } w-288`}
                    onClick={() => handleRegisterCompetition()}
                  >
                    {t('txt-register')}
                  </span>
                ) : (
                  <span className={`btn btn--primary w-288 disabled`}>
                    {t('txt-register')}
                  </span>
                )}
              </div>
            ) : (
              <div className="d-flex gap-12 w-100">
                <div className="btn btn--buy d-flex w-75 justify-content-center align-items-center">
                  <span className="icon iCheck"></span>
                  <span className="pl-2">
                    {`${t('marginCompetition.generalIntroduction.registed')}`}
                  </span>
                </div>
                <div
                  className={`btn w-25 ${
                    auth && auth.isVerified ? 'btn--cancel' : ' btn--authen'
                  }`}
                  onClick={() => handleShowConfirmStop()}
                >
                  {`${t('marginCompetition.generalIntroduction.stopRace')}`}
                </div>
              </div>
            )}
          </div>
        </div>
      </PerfectScrollBar>
      {showModal && (
        <PopupRegisterSuccess
          showModal={showModal}
          onClose={() => setShowModal(false)}
          contentClassName={`${
            theme?.name || 'dark'
          } wts-modal-content--sm custom-modal`}
          nickName={nickName?.nickNm}
          description={`${t(
            'marginCompetition.generalIntroduction.messageRegisCptSuccess',
            { nickName: nickName?.nickNm }
          )}`}
          className="z-index--lv2"
          setTabActive={setTabActive}
        />
      )}
      {showConfirmStop && (
        <Popup
          showModal={showConfirmStop}
          onClose={() => setShowConfirmStop(false)}
          dialogClassName="wts-modal h-75"
          backdropClassName="wts-modal-backdrop z-index--lv2"
          contentClassName={`${
            theme?.name || 'dark'
          } wts-modal-content--sm custom-modal`}
          className="z-index--lv2"
          headerText={t('marginCompetition.generalIntroduction.stopRace')}
          description={`${'marginCompetition.generalIntroduction.textConfirmUnReg'}`}
          txtConfirm={`${'txt-yes'}`}
          txtCancel={`${'txt-no'}`}
          type={2}
          onCancel={() => setShowConfirmStop(false)}
          onConfirm={() => onConfirmUnregist()}
          centered
        />
      )}
    </div>
  );
});

export default GeneralIntro;
