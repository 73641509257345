import React, { useEffect, useState } from 'react';
import { translate, withNamespaces } from 'react-i18next';
import PerfectScrollBar from 'react-perfect-scrollbar';
import {
  numberFormat,
  _convertActTypeToStr,
  _getOrdCondStatus,
  _getOrderStatus,
  _getOrdCondStr,
  _convertTpToPrice,
  _formatDate2,
  mapPfOrderDtlSt,
  mapPfOrderDtl,
} from '../../../../util';

const orderType = ['LO', 'ATO', 'ATC', 'MTL', 'MOK', 'MAK', 'PLO'];
const ExpandRow = ({
  index,
  ordSel,
  t,
  detail,
  colSpan = 16,
  onRowClicked,
  pinefolioOrderDtlSt,
}) => {
  const [indexLv2, setIndexLv2] = useState(-1);
  return (
    <>
      <tr key={`expandRow_${index}`} className="expand-row">
        <td className="no-hover" colSpan={colSpan}>
          <div>
            {
              <>
                <table className="table table-bordered tbl-detail">
                  <thead>
                    <tr className="bg-dark3">
                      <th className="text-center" rowSpan={2}>
                        {t('txt-symbol')}
                      </th>
                      <th className="text-center" rowSpan={2}>
                        {t('txt-order-type')}
                      </th>
                      <th className="text-right" rowSpan={2}>
                        {t('pinefolio.orderList.quantity')}
                      </th>
                      <th className="text-right" rowSpan={2}>
                        {t('pinefolio.orderList.matchedVol')}
                      </th>
                      <th className="text-right" rowSpan={2}>
                        {t('pinefolio.orderList.matchAvgPrice')}
                      </th>
                      <th className="text-right" rowSpan={2}>
                        {t('txt-label-tax')}
                      </th>
                      <th className="text-right" rowSpan={2}>
                        {t('txt-label-fee')}
                      </th>
                      <th className="text-right" rowSpan={2}>
                        {t('pinefolio.orderList.totalValueAfterTaxFee')}
                      </th>

                      <th className="text-right" rowSpan={2}>
                        {t('txt-status')}
                      </th>
                      <th
                        className="text-center"
                        style={{ width: '40px' }}
                        rowSpan={2}
                      ></th>
                    </tr>
                  </thead>
                  <tbody>
                    {ordSel &&
                      !!ordSel.length &&
                      ordSel.map((item, i) => (
                        <>
                          <tr key={i} className="bg-dark3">
                            <td className="text-center text--light fw-500">
                              {item.symbol}
                            </td>

                            {item.prchsSaleTp == 1 ? (
                              <td className="text-center i">{t('txt-buy')}</td>
                            ) : (
                              <td className="text-center d">{t('txt-sell')}</td>
                            )}
                            <td className="text-right">
                              {numberFormat(item.ordrVol, 0, '0')}
                            </td>
                            <td className="text-right ">
                              {numberFormat(item.matchVolume, 0, '0')}
                            </td>
                            <td className="text-right">
                              {numberFormat(item.matchAvgPrice, 0, '0')}
                            </td>
                            <td className="text-right">
                              {numberFormat(item.tax, 0, '0')}
                            </td>
                            <td className="text-right">
                              {numberFormat(item.fee, 0, '0')}
                            </td>
                            <td className="text-right">
                              {numberFormat(item.totalValue, 0, '0')}
                            </td>
                            <td
                              className={`text-right ${
                                mapPfOrderDtl(item.status)?.color
                              }`}
                            >
                              {t(mapPfOrderDtl(item.status)?.label)}
                            </td>
                            <td
                              className="text-center py-1"
                              key={`action_${1}`}
                              onClick={() => {
                                if (i === indexLv2) return setIndexLv2(-1);
                                setIndexLv2(i);
                                return onRowClicked(item);
                              }}
                            >
                              <div>
                                <span className="btn btn-icon btn--transparent">
                                  {i === indexLv2 ? (
                                    <span className="icon iUp2"></span>
                                  ) : (
                                    <span className="icon iDown2"></span>
                                  )}
                                </span>
                              </div>
                            </td>
                          </tr>
                          {i === indexLv2 && (
                            <ExpandRowLv2
                              colSpan={14}
                              index={i}
                              pinefolioOrderDtlSt={pinefolioOrderDtlSt}
                              t={t}
                            />
                          )}
                        </>
                      ))}
                  </tbody>
                </table>
                {(!ordSel || !ordSel.length) && (
                  <p className="text-center fz_14 mt-2 title-time">
                    {t('txt-no-data-found')}
                  </p>
                )}
              </>
            }
          </div>
        </td>
      </tr>
      <tr className="d-none"></tr>
    </>
  );
};

const ExpandRowLv2 = ({
  index,
  t,
  detail,
  colSpan = 14,
  pinefolioOrderDtlSt,
}) => {
  return (
    <>
      <tr key={`expandRow_${index}`} className="expand-row expand-row--lv2">
        <td className="" colSpan={colSpan}>
          <div>
            {
              <>
                <table className="table table-bordered tbl-detail table-fix">
                  <thead>
                    <tr className="bg-dark3">
                      <th className="text-center" rowSpan={2}>
                        {t('txt-label-date')}
                      </th>
                      <th className="text-center" rowSpan={2}>
                        {t('bond.tableLabel.orderID')}
                      </th>
                      <th className="text-center" rowSpan={2}>
                        {t('txt-order-type')}
                      </th>
                      <th className="text-center" rowSpan={2}>
                        {t('txt-price-order')}
                      </th>
                      <th className="text-right" rowSpan={2}>
                        {t('pinefolio.orderList.orderVolume')}
                      </th>
                      <th className="text-right" rowSpan={2}>
                        {t('pinefolio.orderList.matchAvgPrice')}
                      </th>
                      <th className="text-right" rowSpan={2}>
                        {t('pinefolio.orderList.matchedVol')}
                      </th>
                      <th className="text-right" rowSpan={2}>
                        {t('txt-label-tax')}
                      </th>
                      <th className="text-right" rowSpan={2}>
                        {t('txt-label-fee')}
                      </th>
                      <th className="text-right" rowSpan={2}>
                        {t('pinefolio.orderList.totalValueAfterTaxFee')}
                      </th>
                      <th className="text-right" rowSpan={2}>
                        {t('txt-status')}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {pinefolioOrderDtlSt &&
                      !!pinefolioOrderDtlSt.length &&
                      pinefolioOrderDtlSt.map((item, i) => (
                        <>
                          <tr key={i} className="bg-dark3">
                            <td className="text-center text--light3">
                              {_formatDate2(item.trdDate)}
                            </td>
                            <td className="text-center text--light3">
                              {item.ordrNo}
                            </td>
                            <td className="text-center text--light3">
                              {item?.ordrTrdTp
                                ? orderType[item.ordrTrdTp * 1 - 1]
                                : ''}
                            </td>
                            <td className="text-right text--light">
                              {[2, 3, 4, 5].findIndex(
                                (_item) => _item == item.ordrTrdTp
                              ) == -1
                                ? numberFormat(item.ordrPrice, 0, '0')
                                : 0}
                            </td>
                            <td className="text-right text--light">
                              {numberFormat(item.ordrQty, 0, '0')}
                            </td>
                            <td className="text-right text--light">
                              {numberFormat(item.matchAvgPrice, 0, '0')}
                            </td>
                            <td className="text-right text--light">
                              {numberFormat(item.matchVolume, 0, '0')}
                            </td>
                            <td className="text-right text--light">
                              {numberFormat(item.tax, 0, '0')}
                            </td>
                            <td className="text-right text--light">
                              {numberFormat(item.fee, 0, '0')}
                            </td>
                            <td className="text-right text--light">
                              {numberFormat(item.totalValue, 0, '0')}
                            </td>
                            <td className={`text-right ordStt_${item.status}`}>
                              {t(mapPfOrderDtlSt(item.status).desc)}
                            </td>
                          </tr>
                        </>
                      ))}
                  </tbody>
                </table>
                {(!pinefolioOrderDtlSt || !pinefolioOrderDtlSt.length) && (
                  <p className="text-center fz_14 mt-2 title-time">
                    {t('txt-no-data-found')}
                  </p>
                )}
              </>
            }
          </div>
        </td>
      </tr>
    </>
  );
};

const ScrollOrderList = withNamespaces('translations')(
  ({
    pinefolioOrderDtlSt,
    onRowClicked,
    divClassName,
    tblClassName,
    columns,
    defaultAccount,
    _handleGetPFOrderDtlSt,
    source,
    rowExpand = true,
    ordSel,
    auth,
    t,
    detail,
    handleDeleteOrder,
    handleAuthen,
  }) => {
    const [index, setIndex] = useState(-1);
    console.log('index', index);

    useEffect(() => {
      setIndex(-1);
    }, [source]);

    return (
      <div className={'d-flex flex-column ' + (divClassName || 'm-2')}>
        <table className={'table table-bordered ' + (tblClassName || '')}>
          <thead>
            <tr>
              {columns.map((column, index) => {
                let classText = column.sortable ? 'sortable ' : '',
                  width = column.width ? column.width : '',
                  align = column.align ? column.align : '',
                  columnStyle = {};
                classText += ' text-' + align;
                if (column.TrOnlyClassName)
                  classText += ' ' + column.TrOnlyClassName;

                return (
                  <th
                    key={column.key ? column.key : column.text}
                    className={classText}
                    width={width}
                    style={columnStyle}
                  >
                    {column.text}
                  </th>
                );
              })}
              <th style={{ width: '40px' }}></th>
            </tr>
          </thead>
          <tbody>
            {source &&
              source.map((item, i) => {
                return (
                  <>
                    <tr key={i} colSpan={6}>
                      {columns.map((column, colIndex) => {
                        const _className =
                          typeof column.className === 'function'
                            ? column.className(item)
                            : column.className;

                        if (column.cell && typeof column.cell === 'function') {
                          if (column.key === 'defaultAccount') {
                            return (
                              <td
                                className={_className}
                                key={
                                  column.key
                                    ? column.key
                                    : column.text + '_' + colIndex
                                }
                              >
                                {column.cell(defaultAccount)}
                              </td>
                            );
                          }
                          return (
                            <td
                              className={_className}
                              key={
                                column.key
                                  ? column.key
                                  : column.text + '_' + colIndex
                              }
                            >
                              {column.cell(item, i)}
                            </td>
                          );
                        } else if (item[column.key]) {
                          return (
                            <td
                              className={_className}
                              key={
                                column.key
                                  ? column.key
                                  : column.text + '_' + colIndex
                              }
                            >
                              {item[column.key]}
                            </td>
                          );
                        } else {
                          if (column.key === '_index') {
                            return (
                              <td
                                className={_className}
                                key={
                                  column.key
                                    ? column.key
                                    : column.text + '_' + colIndex
                                }
                              >
                                {i + 1}
                              </td>
                            );
                          }
                          return (
                            <td
                              className={_className}
                              key={
                                column.key
                                  ? column.key
                                  : column.text + '_' + colIndex
                              }
                            ></td>
                          );
                        }
                      })}
                      <td
                        className="text-center py-1"
                        key={`action_${1}`}
                        onClick={() => {
                          if (i === index) return setIndex(-1);
                          setIndex(i);
                          return onRowClicked(item);
                        }}
                      >
                        <div>
                          <span className="btn btn-icon btn--transparent">
                            {i === index ? (
                              <div className="icon iUp2" />
                            ) : (
                              <div className="icon iDown2" />
                            )}
                          </span>
                        </div>
                      </td>
                    </tr>
                    {rowExpand && i === index && (
                      <ExpandRow
                        index={i}
                        auth={auth}
                        detail={detail}
                        ordSel={ordSel}
                        onRowClicked={_handleGetPFOrderDtlSt}
                        pinefolioOrderDtlSt={pinefolioOrderDtlSt}
                        t={t}
                      />
                    )}
                  </>
                );
              })}
          </tbody>
        </table>
        {(!source || !source.length) && (
          <p className="text-center fz_14 mt-2 title-time">
            {t('txt-no-data-found')}
          </p>
        )}
      </div>
    );
  }
);

export default ScrollOrderList;
