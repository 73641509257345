import React, { useEffect, useMemo, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  makeDataForeign,
  makeGetIndexSummary,
  makeGetTotalGD,
} from '../../../lib/selector';
import { numberFormat } from '../../../util';
import PerfectScrollBar from 'react-perfect-scrollbar';
import { constants } from '../../../util/constant';
import {
  commodityRequest,
  indSumRequest,
  worldIndexRequest,
} from '../../banggia/actions';
import { sortBy } from 'lodash';

const MarketPanel = withNamespaces('translations')(({ t }) => {
  const dispatch = useDispatch();
  const [tabActive, setTabActive] = useState(constants.news.ALL_MARKET_TAB);
  const lang = useSelector((state) => state?.client?.setting?.lang || 'vi');

  useEffect(() => {
    let interval = setInterval(() => {
      dispatch(indSumRequest());
      dispatch(worldIndexRequest());
      dispatch(commodityRequest(lang == 'vi' ? 1 : 2));
    }, 5000);
    return () => {
      clearInterval(interval);
    };
  }, [lang]);

  useEffect(() => {
    dispatch(indSumRequest());
    dispatch(worldIndexRequest());
    dispatch(commodityRequest(lang == 'vi' ? 1 : 2));
  }, [lang]);
  return (
    <div className="indicator-index h-100 w-100">
      <div className="market-index">
        <div className="market-panel card-index-info w-100">
          <div className="card-panel-header">
            <div className="d-flex justify-content-between">
              <div className="d-flex card-panel-header__left">
                {TAB.map((item) => (
                  <span
                    className={`card-panel-header__title ${
                      item.id == tabActive ? 'active' : ''
                    }`}
                    onClick={() => setTabActive(item.id)}
                  >
                    {t(item.name)}
                  </span>
                ))}
              </div>
            </div>
          </div>
          <PerfectScrollBar className="w-100" style={{ height: 'calc(100%)' }}>
            {tabActive == constants.news.US_INDICES_TAB && <USIndices />}
            {tabActive == constants.news.ALL_MARKET_TAB && <AllMarket />}
            {tabActive == constants.news.COMMODITY_TAB && <Commodity />}
          </PerfectScrollBar>
        </div>
      </div>
    </div>
  );
});

const USIndices = withNamespaces('translations')(({ t }) => {
  const dataForeign = useSelector(makeDataForeign());
  return (
    <div className="card-index-info__body">
      {dataForeign.map((item, i) => (
        <div key={i} className="card-index-info-item">
          <span className="card-index-info-item__name fz_13">{item.name}</span>
          <span className={'card-index-info-item__value fz_13 ' + item.cl}>
            {item.value && <span className="fz_13">{item.value}</span>}
            {item.change && <span className="fz_13">{item.change}</span>}
            {item.changePc && <span className="fz_13">{item.changePc}</span>}
          </span>
        </div>
      ))}
    </div>
  );
});

const AllMarket = withNamespaces('translations')(({ t }) => {
  const totalGd = useSelector(makeGetTotalGD());
  const indSum = useSelector(makeGetIndexSummary());
  const summaryTrade = {
    data: [
      {
        name: t('txt-total-transaction'),
        value: `${numberFormat(totalGd / 1000, 0)} (${t('txt-label-billion')})`,
        cl: 'text-white',
      },
      {
        name: t('txt-total-putThrough'),
        value: `${
          indSum ? numberFormat(indSum.ptvalue / 1000000, 0, '-') : '-'
        } (${t('txt-label-billion')})`,
        cl: 'text-white',
      },
      {
        name: t('txt-total-foreign'),
        value: `${
          indSum ? numberFormat(indSum.frvalue / 1000000, 0, '-') : '-'
        } (${t('txt-label-billion')})`,
        cl: 'text-white',
      },
    ],
  };
  return (
    <div className="card-index-info__body">
      {summaryTrade.data.map((item, i) => (
        <div key={i} className="card-index-info-item">
          <span className="market-panel-header__name fz_13">{item.name}</span>
          {item.value && (
            <span className={`market-panel-header__index fz_13 ${item.cl}`}>
              {item.value}
            </span>
          )}
          {item.change && (
            <span className={`market-panel-header__change fz_13 ${item.cl}`}>
              {item.change}
            </span>
          )}
          {item.changePc && (
            <span
              className={`market-panel-header__changePercent fz_13 ${item.cl}`}
            >
              {item.changePc}
            </span>
          )}
        </div>
      ))}
    </div>
  );
});

const Commodity = withNamespaces('translations')(({ t }) => {
  const [commodities, setCommodities] = useState([]);
  const commodityList = useSelector(
    (state) => state?.priceBoard?.commodityList
  );

  useEffect(() => {
    if (commodityList && !!commodityList.length) {
      const _ = sortBy(commodityList, 'Order');
      setCommodities(_);
    }
  }, [commodityList]);

  return (
    <div className="card-index-info__body">
      {commodities.map((item, i) => (
        <div key={i} className="card-index-info-item">
          <span className="card-index-info-item__name fz_13">
            {item.SymbolName}
          </span>

          <span
            className={`card-index-info-item__value fz_13 + ${
              item.Change > 0 ? 'i' : item.Change < 0 ? 'd' : 'r'
            }`}
          >
            <span className="fz_13">{item.Last}</span>
            <span className="fz_13">{item.Change}</span>
            <span className="fz_13">{`${item.Percent}%`}</span>
          </span>
        </div>
      ))}
    </div>
  );
});

const TAB = [
  { id: '0', name: 'news.allMarket' },
  { id: '1', name: 'news.globalIndices' },
  { id: '2', name: 'news.commodity' },
];

export default MarketPanel;
