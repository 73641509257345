export const NAV_TOGGLE = 'NAV_TOGGLE';
export const TYPE_INDEX = 'TYPE_INDEX';

export const SHOW_USER_MODEL = 'SHOW_USER_MODEL';

export const STOCK_SET = 'STOCK_SET';
export const STOCK_UNSET = 'STOCK_UNSET';

export const SHOW_ASSET_PORTFOLIO = 'SHOW_ASSET_PORTFOLIO';

export const CHANGE_CHART_LAYOUT = 'CHANGE_CHART_LAYOUT';
