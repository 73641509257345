import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { Button } from 'react-bootstrap';

import { StringToInt, numberFormat } from '../../../util';
import RenderInputMask from '../../input/renderInputMask';
import RenderArea from '../../input/renderArea';
import {
  makeGetToken,
  makeGetSourceBalance,
  makeGetAuth,
} from '../../../lib/selector';
import { cashTransferRequest } from '../../../containers/cash/actions';

import { required } from '../../../util/validation';
import { v4 as uuidv4 } from 'uuid';
import { setAuth } from '../../../containers/client/actions';
import { translate } from 'react-i18next';

class FormChuyenTien extends Component {
  submit = (values) => {
    const { amount, content } = values;
    const { token, _defSource, _defTarget, matchAmount, auth } = this.props;

    if (!_defTarget || !matchAmount) return;

    const _amount = StringToInt(amount);
    if (_amount > 0) {
      const uuid = uuidv4();
      const resData = {
        group: 'CORE',
        user: token.user,
        session: token.session,
        cmd: 'createCashTransfer',
        rqId: uuid,
        channel: 'WTS',
        type: auth.type,
        token: auth.token,
        data: {
          acntNo: _defSource.acntNo,
          subAcntNo: _defSource.subAcntNo,
          toAcntNo: _defTarget.subAcntNo,
          trdAmt: _amount + '',
          feeType: 1, // bao gom phi
          desc: content,
          transType: 1, // chuyen khoan noi bo
        },
      };

      this.props.dispatch(cashTransferRequest(resData));

      this.props.reset();
    }
  };

  _handleAuthen = () => {
    this.props.dispatch(setAuth());
  };

  render() {
    const {
      handleSubmit,
      pristine,
      submitting,
      _defTarget,
      cashBalance,
      amount,
      matchAmount,
      auth,
      t,
    } = this.props;

    // console.log(cashBalance)

    return (
      <Fragment>
        <table className="table mt-2" style={{ maxHeight: '250px' }}>
          <tbody>
            <tr>
              <th className="text-left">
                {t('txt-asset-actual-balance-account')}
              </th>
              <th className="text-right">
                {cashBalance && numberFormat(cashBalance.balance, 0, '0')}
              </th>
            </tr>
            <tr>
              <th className="text-left">{t('txt-asset-cash-can-transfer')}</th>
              <th className="text-right">
                {cashBalance && numberFormat(cashBalance.wdrawAvail, 0, '0')}
              </th>
            </tr>
          </tbody>
        </table>
        <form className="form-trade" onSubmit={handleSubmit(this.submit)}>
          <table className="table table-no-bordered mt-2">
            <tbody>
              <tr>
                <td rowSpan="2" className="p-0">
                  <Field
                    name="content"
                    component={RenderArea}
                    className="form-control fz_14 content-read"
                    rows="3"
                    validate={required}
                  />
                </td>
                <td className="pr-0 pt-0 align-top" style={{ width: '215px' }}>
                  <Field
                    name="amount"
                    classname="form-control text-right form-input fz_14"
                    component={RenderInputMask}
                    validate={required}
                  />
                  {amount && !matchAmount && (
                    <div
                      style={{
                        color: 'rgb(255, 85, 85)',
                        margin: '0px 0px 10px',
                        fontSize: '0.75rem',
                      }}
                    >
                      {t('txt-valid-excess-amount')}
                    </div>
                  )}
                </td>
              </tr>
              <tr>
                <td className="pb-0 pr-0 align-bottom">
                  {auth && auth.isVerified ? (
                    <Button
                      type="submit"
                      variant="info"
                      disabled={pristine || submitting}
                      className="fz_14 fw-500"
                      style={{ width: '215px' }}
                    >
                      {`${t('txt-label-transfer-to')} ${
                        _defTarget.subAcntNo ? _defTarget.subAcntNo : ''
                      }`}
                    </Button>
                  ) : (
                    <Button
                      variant="warning"
                      className="fz_14 fw-500 text-white"
                      onClick={() => this._handleAuthen()}
                      style={{ width: '215px' }}
                    >
                      {t('txt-xacthuc')}
                    </Button>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </Fragment>
    );
  }
}

FormChuyenTien = reduxForm({
  form: 'chuyentien',
  enableReinitialize: true,
})(FormChuyenTien);

const selector = formValueSelector('chuyentien');

const makeMapStateToProps = () => {
  const getToken = makeGetToken();
  const getAuth = makeGetAuth();
  const getCashBal = makeGetSourceBalance();

  const mapStateToProps = (state, props) => {
    const { amount, content } = selector(state, 'amount', 'content');
    const { _defSource, _defTarget, t } = props;
    // console.log(_defSource, _defTarget)
    const token = getToken(state);
    const auth = getAuth(state);
    const cashBalance = getCashBal(state);
    const wdrawAvail = cashBalance ? cashBalance.wdrawAvail : 0;

    return {
      token,
      auth,
      amount,
      content,
      _defSource,
      _defTarget,
      cashBalance,
      matchAmount:
        selector(state, 'amount') && StringToInt(amount) <= wdrawAvail,
      initialValues: {
        amount: '0',
        content:
          t('txt-account-acronym') +
          ' ' +
          token.vsd +
          ' - ' +
          token.name +
          ' ' +
          t('txt-label-online-transfer') +
          ' ' +
          (_defSource && _defSource.subAcntNo ? _defSource.subAcntNo : '') +
          ' ' +
          t('txt-to') +
          ' ' +
          (_defTarget && _defTarget.subAcntNo ? _defTarget.subAcntNo : ''),
      },
    };
  };
  return mapStateToProps;
};

FormChuyenTien = connect(makeMapStateToProps)(FormChuyenTien);

export default translate('translations')(FormChuyenTien);
