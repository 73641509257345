import { setAuthen, setClient } from '../containers/client/actions';
import { getCookie } from '../lib/storages';

export function checkAuthorization(dispatch) {
  // attempt to grab the token from localstorage
  const token = getCookie('token');

  // check authen in session storage
  const authen = getCookie('authen');

  // return true
  // if it exists
  if (token) {
    // this just all works to compare the total seconds of the created
    // time of the token vs the ttl (time to live) seconds

    if (!token.session) return false;

    // const createdDate = new Date(token.created)
    // const created = Math.round(createdDate.getTime() / 1000)
    // const ttl = 1800 // 30 minutes
    // const expiry = created + ttl

    // // if the token has expired return false
    // if (created > expiry) return false

    // otherwise, dispatch the token to our setClient action
    // which will update our redux state with the token and return true
    dispatch(setClient(token));
    if (authen && authen.token) dispatch(setAuthen(authen));
    return true;
  }

  return false;
}

export function checkDefaultAuthorization({ dispatch, getState }) {
  const client = getState().client;

  if (client && client.token) return true;

  if (checkAuthorization(dispatch)) return true;

  return false;
}
