import React, { useState, useEffect } from 'react';
import { getWindowDimensions } from '../../../../util';

const CardInfo = ({ idata, typeIndex, t, isPagging = false, width }) => {
  const [dataShow, setDataShow] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  // const [pageSize, setPageSize] = useState(3);

  const updatePagesize = () => {
    const { width } = getWindowDimensions();
    if (width > 4000) return 8;
    else if (width > 2500) return 6;
    else if (width > 1440) return 5;
    else if (width > 1200) return 4;
    else if (width > 480) return 3;
    else return 2;
  };

  useEffect(() => {
    let pageSize = 3;
    if (typeIndex != 1) pageSize = updatePagesize();
    if (idata && idata.data && idata.data.length > 0) {
      const total = Math.ceil(idata.data.length / pageSize);
      setTotalPage(total);

      const _dataShow = idata.data
        .slice((currentPage - 1) * pageSize)
        .slice(0, pageSize);
      setDataShow(_dataShow);
    }
  }, [idata, currentPage]);

  const NextBackHandle = (isNext = true) => {
    if (isNext && currentPage < totalPage) {
      setCurrentPage(currentPage + 1);
    }
    if (!isNext && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  if (typeIndex == 1)
    return (
      <div
        className="market-panel card-index-info"
        style={{ width: width ? `${width}%` : 'auto' }}
      >
        <div className="card-index-info__header">
          <span>{`${idata.head} (${idata.subHead})`}</span>
          {isPagging && (
            <div className="d-flex px-1">
              <span
                onClick={() => NextBackHandle(false)}
                className="icon iPrevious"
              ></span>
              <span
                onClick={() => NextBackHandle()}
                className="icon iNext"
              ></span>
            </div>
          )}
        </div>
        <div className="card-index-info__body">
          {dataShow.map((item, i) => (
            <div key={i} className="card-index-info-item">
              <span className="card-index-info-item__name">{item.name}</span>
              <span className={'card-index-info-item__value ' + item.cl}>
                {item.value && <span>{item.value}</span>}
                {item.change && <span>{item.change}</span>}
                {item.changePc && <span>{item.changePc}</span>}
              </span>
            </div>
          ))}
        </div>
      </div>
    );
  else
    return (
      <>
        {dataShow.map((item, i) => (
          <div key={i} className="market-panel market-panel--small">
            <span className="market-panel-header__name">{item.name}</span>
            {item.value && (
              <span className={`market-panel-header__index ${item.cl}`}>
                {item.value}
              </span>
            )}
            {item.change && (
              <span className={`market-panel-header__change ${item.cl}`}>
                {item.change}
              </span>
            )}
            {item.changePc && (
              <span className={`market-panel-header__changePercent ${item.cl}`}>
                {item.changePc}
              </span>
            )}
          </div>
        ))}
      </>
    );
};

export default CardInfo;
