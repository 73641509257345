import React, { useEffect, useRef } from 'react';
import { withNamespaces } from 'react-i18next';
import Chat from '../../../lib/vchat';
import PerfectScrollBar from 'react-perfect-scrollbar';
import { useSelector } from 'react-redux';
import { constants } from '../../../util/constant';

const QA = withNamespaces('translations')(({ t }) => {
  const { appsetting } = useSelector((state) => state.client);
  const competitionInfor = appsetting?.find(
    (item) =>
      item.key == 'COMPETITION_INFOR' &&
      item.module == constants.modules.COMPETITION
  )?.values;

  const content =
    competitionInfor &&
    JSON.parse(competitionInfor)?.find((item) => item.key == 'qa').value;

  const seeMoreHandle = () => {
    const links =
      competitionInfor &&
      JSON.parse(competitionInfor)?.find((item) => item.key == 'link').value;

    const link = links?.find((x) => x.key == 'qa')?.value || '#';
    window.open(link);
  };

  return (
    <div className="layout-page__body layout__body qa-layout">
      <PerfectScrollBar>
        <div className="qa-page">
          <h1 className="qa-page__header text--xl text--light">
            {t('marginCompetition.QA.name')}
          </h1>
          <div
            className="qa-page__body"
            dangerouslySetInnerHTML={{
              __html: content,
            }}
          ></div>
          <div className="qa-page__action">
            <button
              className="btn btn--reset mt-3"
              onClick={() => seeMoreHandle()}
            >
              {t('txt-seemore')}
            </button>
          </div>
          <Chat />
        </div>
      </PerfectScrollBar>
    </div>
  );
});

export default QA;
