import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from '../../../components/datePicker2';

import PerfectScrollBar from 'react-perfect-scrollbar';
import { Col, Button } from 'react-bootstrap';

import {
  numberFormat,
  _convertTpToPrice,
  _getOrderStatus,
  StringToInt,
  _formatDate2,
  _diff2Date,
  _formatDate,
  mapOrderChanel,
} from '../../../util';
import { v4 as uuidv4 } from 'uuid';
import { sortBy, sumBy } from 'lodash';

import Page from '../../../components/print/Page';
import PrintButton from '../../../components/print/PrintButton';
import PagingCustom from '../../../components/common/paging/PagingCustom';

import { take, takeRight } from 'lodash';
import logo from '../../../assets/img/pinetreeSecurities.svg';

import { withNamespaces } from 'react-i18next';
import { useEffect } from 'react';
import { useState } from 'react';
import AssetHeader from '../components/header/assetHeader';
import OverviewMetric from '../components/header/overviewMetric';
import { summaryOrdRequest } from '../../summary/actions';
import { setToast } from '../../client/actions';
import moment from 'moment';
import ReactSelect from 'react-select';
import ExportOrderStatement from '../components/exports/orderStatementExcel';
import { BsCaretLeftFill, BsCaretRightFill } from 'react-icons/bs';
import { Mixpanel } from '../../../lib/mixpanel';
import { constants } from '../../../util/constant';

const PAGE_SIZES_OPTIONS = [
  { value: 15, label: '15' },
  { value: 30, label: '30' },
  { value: 50, label: '50' },
];

const OrderStatement = withNamespaces('translations')(({ t, params }) => {
  const { ordList } = useSelector((state) => state.summary);
  const { defaultAccount } = useSelector((state) => state.socket);
  const [refresh, setRefresh] = useState(false);
  const [tabActive, setTabActive] = useState(0);
  const [account, setAccount] = useState();

  useEffect(() => {
    setAccount(defaultAccount);
    setRefresh(!refresh);
  }, [defaultAccount]);

  return (
    <div className="card-panel-2 order-statement statement-container h-100">
      <AssetHeader
        account={account}
        setAccount={setAccount}
        params={params}
        setRefresh={setRefresh}
        tabActive={tabActive}
        refresh={refresh}
      />
      <Filter
        account={account}
        ordList={ordList}
        refresh={refresh}
        key={refresh}
      />
    </div>
  );
});

const Filter = withNamespaces('translations')(
  ({ t, account, ordList, refresh }) => {
    const dispatch = useDispatch();
    const currentDate = new Date();
    const [fromDate, setFromDate] = useState(
      new Date(currentDate.setDate(currentDate.getDate() - 90))
    );
    const [toDate, setToDate] = useState(new Date());
    const { token, auth } = useSelector((state) => state.client);
    const [txtSearch, setTxtSearch] = useState('');
    const [typeOrder, setTypeOrder] = useState();
    const [isMatchedQuantity, setIsMatchedQuantity] = useState(true);
    const [isTradeDate, setIsTradeDate] = useState(true);
    const [isTax, setIsTax] = useState(true);
    const [contentShow, setContentShow] = useState([]);
    const [page, setPage] = useState(1);
    const [continued, setContinued] = useState(false);

    const [pageSizeOption, setPageSizeOption] = useState(PAGE_SIZES_OPTIONS[0]);
    const [totalPage, setTotalPage] = useState(1);

    const handleNextPage = (step) => {
      handleQueryPage(page + step);
    };
    const onFilter = () => {
      getordListHandle(fromDate, toDate, txtSearch, typeOrder);
    };

    const getordListHandle = (fromDate, toDate, txtSearch, typeOrder) => {
      if (!token || !account) return;
      const maxDay = token && token['config'] ? token['config']['maxDay'] : 90;

      const _diff = _diff2Date(toDate, fromDate);
      if (_diff < 0) {
        handleToast(t('txt-valid-time'));
        return;
      }
      if (_diff > maxDay) {
        handleToast(`${t('txt-valid-range-time')} ${maxDay} ${t('txt-day')}`);
        return;
      }

      const uuid = uuidv4();

      const params = {
        group: 'CORE',
        user: token.user,
        session: token.session,
        cmd: 'OrderHis',
        rqId: uuid,
        channel: 'WTS',
        data: {
          acntNo: account ? account?.acntNo : '',
          subAcntNo: account ? account.subAcntNo : '',
          fromDate: fromDate,
          toDate: toDate,
          symbol: txtSearch,
          buySelTp: typeOrder ? typeOrder.value + '' : '',
          ordrNo: '',
          ordrTrdTp: '',
          ordrStatTp: '',
        },
      };

      dispatch(summaryOrdRequest(params));
    };

    useEffect(() => {
      if (!token) return;
      getordListHandle(
        moment(new Date(new Date().setDate(new Date().getDate() - 90))).format(
          'YYYYMMDD'
        ),
        moment(new Date()).format('YYYYMMDD')
      );
    }, [account, refresh]);

    const handleQueryPage = (page) => {
      const _pageSize = pageSizeOption?.value ?? PAGE_SIZES_OPTIONS[0].value;
      if (!ordList || !ordList.list) return;
      const _totalPage =
        _pageSize != 0
          ? ordList.list && ordList.list.length > 0
            ? Math.ceil(ordList.list.length / _pageSize)
            : 1
          : 1;

      const _takeContent = take(ordList.list, _pageSize * page);
      const _contentShow = takeRight(
        _takeContent,
        _takeContent.length - _pageSize * (page - 1)
      );
      if (_pageSize == 0) {
        setContentShow(ordList.list);
      } else {
        setContentShow(_contentShow);
      }
      setTotalPage(_totalPage);
      setPage(page);
      setContinued(page < _totalPage);
    };

    useEffect(() => {
      handleQueryPage(1);
    }, [ordList, pageSizeOption]);

    // useEffect(() => {
    //   if (!ordList || !ordList.list) return null;

    //   let _ordList = sortBy(ordList.list, ['tranDate']);

    //   let bgAmt = ordList.beginAmt || 0;

    //   _ordList.map((item) => {
    //     item.beginAmt = bgAmt;
    //     item.afterAmt = bgAmt + item.inAmt - item.outAmt;
    //     bgAmt = item.afterAmt;
    //   });
    //   ordList.list = [..._ordList];
    //   return ordList;
    // }, [ordList]);

    const handleToast = (msg) => {
      const toastMsg = {
        id: Math.random(),
        msg: msg,
        title: t('txt-notice'),
      };
      dispatch(setToast(toastMsg));
    };

    const filterOptions = [
      { value: 1, label: 'txt-buy' },
      { value: 2, label: 'txt-sell' },
      { value: 0, label: 'txt-all' },
    ];

    const pageSizeOptions = [
      ...PAGE_SIZES_OPTIONS,
      { value: 0, label: 'txt-all' },
    ];

    const _sumMatchVol =
      ordList && ordList.list && !!ordList.list.length
        ? sumBy(ordList.list, 'matchedQty')
        : 0;
    const _sumMatchVal =
      ordList && ordList.list && !!ordList.list.length
        ? sumBy(ordList.list, 'matchedAmt')
        : 0;

    const _sumFee =
      ordList && ordList.list && !!ordList.list.length
        ? sumBy(ordList.list, 'matchedFee')
        : 0;

    const _sumSelFee =
      ordList && ordList.list && !!ordList.list.length
        ? sumBy(ordList.list, 'selFee')
        : 0;

    const _sumTax =
      ordList && ordList.list && !!ordList.list.length
        ? sumBy(ordList.list, 'matchedTax')
        : 0;

    const _sumDivdDltx =
      ordList && ordList.list && !!ordList.list.length
        ? sumBy(ordList.list, 'divdDltx')
        : 0;

    const _sumGTTT =
      ordList && ordList.list && !!ordList.list.length
        ? sumBy(ordList.list, function (item) {
            if (item.buySelTp === '1')
              return item.matchedQty * item.matchedPrc + item.matchedFee;
            else
              return (
                item.matchedQty * item.matchedPrc -
                item.matchedTax -
                item.matchedFee -
                item.divdDltx -
                item.selFee
              );
          })
        : 0;
    return (
      <PerfectScrollBar className="mt-36 h-100">
        <Col className="d-flex flex-column h-100 ">
          <div className="d-flex justify-content-between align-items-center">
            <label className="mr-auto text-white text-title">
              {t('txt-label-order-history')}
            </label>
            <div className="d-flex align-items-center statement__filters">
              <div className="input-text-search d-flex align-items-center mr-1">
                <input
                  placeholder="Symbol"
                  onChange={(e) => setTxtSearch(e.target.value)}
                />
                <span className="icon iSearch"></span>
              </div>
              <div className="mr-1">
                <DatePicker
                  startDate={fromDate}
                  endDate={toDate}
                  callback={(from, to) => {
                    setFromDate(_formatDate(from));
                    setToDate(_formatDate(to));
                  }}
                />
              </div>
              <ReactSelect
                placeholder={t('txt-all')}
                className="filter-control-select"
                classNamePrefix="filter-control-select"
                options={[...filterOptions]}
                getOptionLabel={(option) => t(option.label)}
                getOptionValue={(option) => option.id}
                isSearchable={false}
                components={{
                  IndicatorSeparator: () => null,
                }}
                value={typeOrder}
                onChange={(value) => setTypeOrder(value)}
              />
              <button
                className="btn btn--primary h-24 mr-1"
                onClick={onFilter}
              >{`${t('txt-search')}`}</button>
            </div>
            <div className="d-flex align-items-center">
              <ExportOrderStatement
                csvData={ordList ? ordList.list : []}
                fileName="sao-ke-giao-dich-chung-khoan"
                fromDate={_formatDate2(fromDate)}
                toDate={_formatDate2(toDate)}
                accountCode={account && account.subAcntNo}
                accountName={account && account.vsdAcntNo}
                symbol={txtSearch ? txtSearch : t('txt-all')}
                typeOrder={
                  typeOrder &&
                  (typeOrder.value == '2'
                    ? t('txt-sell')
                    : typeOrder.value == '1'
                    ? t('txt-buy')
                    : t('txt-all'))
                }
              />
              <PrintButton
                id={'sao-ke-giao-dich'}
                callback={() =>
                  Mixpanel.track('Export Statement', {
                    'Statement Type': 'Order Statement',
                  })
                }
              />
            </div>
          </div>
          <Page id="sao-ke-so-lenh">
            <table className="table table-bordered tbl-list">
              <thead>
                <tr>
                  <th className="text-center text--light">{t('txt-stt')}</th>
                  <th className="text-center text--light">
                    {t('txt-order-no')}
                  </th>
                  <th className="text-center">
                    <div className="d-flex justify-content-between">
                      <BsCaretLeftFill
                        className="my-auto"
                        onClick={() => setIsTradeDate(!isTradeDate)}
                      />
                      <span style={{ maxWidth: '55px' }}>
                        {isTradeDate
                          ? t('txt-label-trade-date')
                          : t('txt-label-tran-date')}
                      </span>
                      <BsCaretRightFill
                        className="my-auto"
                        onClick={() => setIsTradeDate(!isTradeDate)}
                      />
                    </div>
                  </th>
                  <th className="text-center">{t('txt-trade-acronym')}</th>
                  <th className="text-center">{t('txt-symbol')}</th>
                  <th className="text-center">{t('txt-type')}</th>
                  <th className="text-center">{t('txt-price')}</th>
                  <th className="text-center">{t('txt-vol')}</th>
                  <th className="text-center">{t('txt-match-price')}</th>
                  <th className="text-center">
                    <div className="d-flex justify-content-between">
                      <BsCaretLeftFill
                        className="my-auto"
                        onClick={() => setIsMatchedQuantity(!isMatchedQuantity)}
                      />
                      <span style={{ maxWidth: '35px' }}>
                        {isMatchedQuantity
                          ? t('txt-match-vol')
                          : t('txt-dividend-match-vol')}
                      </span>
                      <BsCaretRightFill
                        className="my-auto"
                        onClick={() => setIsMatchedQuantity(!isMatchedQuantity)}
                      />
                    </div>
                  </th>
                  <th className="text-center">{t('txt-cancel-vol')}</th>
                  <th className="text-center">{t('txt-match-value')}</th>
                  <th className="text-center">{t('txt-label-fee')}</th>
                  <th className="text-center">{t('txt-label-sell-fee')}</th>
                  <th className="text-center">
                    <div className="d-flex justify-content-between">
                      <BsCaretLeftFill
                        className="my-auto"
                        onClick={() => setIsTax(!isTax)}
                      />
                      <span style={{ maxWidth: '35px' }}>
                        {isTax
                          ? t('txt-label-tax')
                          : t('txt-label-dividend-tax')}
                      </span>
                      <BsCaretRightFill
                        className="my-auto"
                        onClick={() => setIsTax(!isTax)}
                      />
                    </div>
                  </th>
                  <th className="text-center">{t('txt-label-real-amount')}</th>
                  <th className="text-center">{t('txt-status')}</th>
                  <th className="text-center">{t('txt-label-channel')}</th>
                </tr>
              </thead>
              <tbody>
                {contentShow &&
                  !!contentShow.length &&
                  contentShow.map((item, i) => {
                    if (item.modiCnclTp === '3') return null;
                    return (
                      <tr key={i}>
                        <td className="text-center text--light3">
                          {15 * (page - 1) + i + 1}
                        </td>
                        <td className="text-center text--light3">
                          {item.ordrNo}
                        </td>
                        <td className="text-center text--light3">
                          {isTradeDate
                            ? item.wrkTime
                            : _formatDate2(item.trdDate)}
                        </td>
                        <td className="text-center fw-500">
                          <div className={item.buySelTp === '1' ? 'i' : 'd'}>
                            {item.buySelTp === '1'
                              ? t('txt-buy')
                              : t('txt-sell')}
                          </div>
                        </td>
                        <td className="text-center fw-500 text--light">
                          {item.symbol}
                        </td>
                        <td className="text-center text--light">
                          {_convertTpToPrice(item.ordrTrdTp)}
                        </td>
                        <td className="text-right text--light fw-500">
                          {numberFormat(item.ordrUntprc, 0, '0')}
                        </td>
                        <td className="text-right text--light">
                          {numberFormat(item.ordrQty, 0, '0')}
                        </td>
                        <td className="text-right fw-500 text--light">
                          {numberFormat(item.matchedPrc, 0, '0')}
                        </td>
                        <td className="text-right text--light">
                          {isMatchedQuantity
                            ? numberFormat(item.matchedQty, 0, '0')
                            : numberFormat(item.divdQty, 0, '0')}
                        </td>
                        <td className="text-right text--light">
                          {numberFormat(item.ordrRemnQty, 0, '0')}
                        </td>
                        <td className="text-right fw-500 text--light">
                          {numberFormat(item.matchedAmt, 0, '0')}
                        </td>
                        <td className="text-right text--light3">
                          {numberFormat(item.matchedFee, 0, '0')}
                        </td>
                        <td className="text-right text--light33">
                          {numberFormat(item.selFee, 0, '0')}
                        </td>
                        <td className="text-right text--light3">
                          {isTax
                            ? numberFormat(item.matchedTax, 0, '0')
                            : numberFormat(item.divdDltx, 0, '0')}
                        </td>
                        <td className="text-right fw-500 text--light">
                          {account.subAcntClss ==
                          constants.subAccount.Derivative
                            ? '0'
                            : item.buySelTp === '1'
                            ? numberFormat(
                                item.matchedQty * item.matchedPrc +
                                  item.matchedFee,
                                0,
                                '0'
                              )
                            : numberFormat(
                                item.matchedQty * item.matchedPrc -
                                  item.matchedTax -
                                  item.matchedFee -
                                  item.divdDltx -
                                  item.selFee,
                                0,
                                '0'
                              )}
                        </td>
                        <td className={'text-center ordStt_' + item.ordrStatTp}>
                          {_getOrderStatus(
                            item.ordrStatTp,
                            item.ordrQty,
                            item.matchedQty
                          )}
                        </td>
                        <td className="text-center">
                          {mapOrderChanel(item.ordrChnl)}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            {(!contentShow || !contentShow.length) && (
              <p className="text-center fz_14 mt-2 title-time">
                {t('txt-no-data-found')}
              </p>
            )}
          </Page>
          {ordList && (
            <div className="pb-5">
              <PagingCustom
                handleNextPage={() => handleNextPage(1)}
                handlePrevPage={() => handleNextPage(-1)}
                page={page}
                totalPage={totalPage}
                continued={continued}
                t
                hasPageSize={true}
                pageSizeOption={pageSizeOption}
                _handleSetPageSize={setPageSizeOption}
                pageSizeOptions={pageSizeOptions}
              />
            </div>
          )}
        </Col>

        <Page id="sao-ke-giao-dich" style={{ display: 'none' }}>
          <div className="d-flex flex-column bg-light">
            <div
              className="position-relative d-flex justify-content-center align-items-center"
              style={{ height: '75px' }}
            >
              <img
                src={logo}
                alt="logo"
                className="position-absolute"
                style={{ top: '0', left: '0' }}
              />
              <span className="fz_16 fw-500 text-uppercase">
                {t('txt-label-order-statement')}
              </span>
            </div>
            <div className="d-flex mt-2">
              <Col className="d-flex flex-column">
                <div className="d-flex">
                  <span className="fz_14 w_125">{`${t(
                    'lbl-report-name'
                  )}:`}</span>
                  <span className="fz_14 f_i">{`${t(
                    'txt-label-order-statement'
                  )}`}</span>
                </div>
                <div className="d-flex">
                  <span className="fz_14 w_125">{`${t(
                    'lbl-subAccount'
                  )}:`}</span>
                  <span className="fz_14 f_i">
                    {account && account.subAcntNo}
                  </span>
                </div>
                <div className="d-flex">
                  <span className="fz_14 w_125">{`${t(
                    'txt-label-vsd-no'
                  )}:`}</span>
                  <span className="fz_14 f_i">
                    {account && account.vsdAcntNo}
                  </span>
                </div>
              </Col>
              <Col className="d-flex flex-column">
                <div className="d-flex">
                  <span className="fz_14 w_125">{`${t(
                    'txt-label-from'
                  )}:`}</span>
                  <span className="fz_14 f_i">
                    {fromDate && moment(fromDate).format('DD/MM/YYYY')}
                  </span>
                </div>
                <div className="d-flex">
                  <span className="fz_14 w_125">{`${t('txt-label-to')}:`}</span>
                  <span className="fz_14 f_i">
                    {toDate && moment(toDate).format('DD/MM/YYYY')}
                  </span>
                </div>
                <div className="d-flex">
                  <span className="fz_14 w_125">{t('txt-symbol')}</span>
                  <span className="fz_14 f_i">
                    {txtSearch && (txtSearch || t('txt-all'))}
                  </span>
                </div>
                <div className="d-flex">
                  <span className="fz_14 w_125">
                    {t('txt-label-tran-type')}
                  </span>
                  <span className="fz_14 f_i">
                    {typeOrder &&
                      (typeOrder.value == '2'
                        ? t('txt-sell')
                        : typeOrder.value == '1'
                        ? t('txt-buy')
                        : t('txt-all'))}
                  </span>
                </div>
              </Col>
            </div>
          </div>
          <div className="d-flex bg-light">
            <table className="table table-bordered mt-2 table-light">
              <thead>
                <tr>
                  <th className="text-center text-white">{t('txt-stt')}</th>
                  <th className="text-left text-white">
                    {t('txt-label-trade-date')}
                  </th>
                  {/* TODO: 02/12/2020 update trade date */}
                  <th className="text-center text-white">
                    {t('txt-label-tran-date')}
                  </th>
                  <th className="text-center text-white">
                    {t('txt-trade-acronym')}
                  </th>
                  <th className="text-left text-white">{t('txt-symbol')}</th>
                  <th className="text-right text-white">
                    {t('txt-vol-order')}
                  </th>
                  <th className="text-right text-white">
                    {t('txt-price-order')}
                  </th>
                  <th className="text-right text-white">
                    {t('txt-match-vol')}
                  </th>
                  <th className="text-right text-white">
                    {t('txt-dividend-match-vol')}
                  </th>
                  <th className="text-right text-white">
                    {t('txt-match-price')}
                  </th>
                  <th className="text-right text-white">
                    {t('txt-cancel-vol')}
                  </th>
                  <th className="text-right text-white">
                    {t('txt-total-match-value')}
                  </th>
                  {/* TODO: 02/12/2020 update phí, thuế */}
                  <th className="text-center text-white">
                    {t('txt-label-fee')}
                  </th>
                  <th className="text-center text-white">
                    {t('txt-label-sell-fee')}
                  </th>
                  <th className="text-center text-white">
                    {t('txt-label-tax')}
                  </th>
                  <th className="text-center text-white">
                    {t('txt-label-dividend-tax')}
                  </th>
                  <th className="text-center text-white">
                    {t('txt-label-real-amount')}
                  </th>
                  <th className="text-left text-white">{t('txt-status')}</th>
                  <th className="text-left text-white">
                    {t('txt-order-type')}
                  </th>
                  <th className="text-left text-white">{t('txt-order-no')}</th>
                  <th className="text-left text-white">
                    {t('txt-origin-order-no')}
                  </th>
                  <th className="text-left text-white">
                    {t('txt-label-channel')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {ordList &&
                  ordList.list &&
                  !!ordList.list.length &&
                  ordList.list.map((item, i) => {
                    if (item.modiCnclTp === '3') return null;
                    return (
                      <tr key={i} style={{ border: '1px solid #e8e8e8' }}>
                        <td className="text-center">{i + 1}</td>
                        <td className="text-left">{item.wrkTime}</td>
                        {/* TODO: 02/12/2020 update trade date */}
                        <td className="text-center">
                          {_formatDate2(item.trdDate)}
                        </td>
                        <td className="text-left">
                          <div className={'trade_side_' + item.buySelTp}>
                            {item.buySelTp === '1'
                              ? t('txt-buy')
                              : t('txt-sell')}
                          </div>
                        </td>
                        <td className="text-left">{item.symbol}</td>
                        <td className="text-left">
                          {numberFormat(item.ordrQty, 0, '0')}
                        </td>
                        <td className="text-right">
                          {numberFormat(item.ordrUntprc, 0, '0')}
                        </td>
                        <td className="text-right">
                          {numberFormat(item.matchedQty, 0, '0')}
                        </td>
                        <td className="text-right">
                          {numberFormat(item.divdQty, 0, '0')}
                        </td>
                        <td className="text-right">
                          {numberFormat(item.matchedPrc, 0, '0')}
                        </td>
                        <td className="text-right">
                          {numberFormat(item.ordrRemnQty, 0, '0')}
                        </td>
                        <td className="text-right">
                          {numberFormat(item.matchedAmt, 0, '0')}
                        </td>
                        {/* TODO: 02/12/2020 update phí, thuế */}
                        <td className="text-right">
                          {numberFormat(item.matchedFee, 0, '0')}
                        </td>
                        <td className="text-right">
                          {numberFormat(item.selFee, 0, '0')}
                        </td>
                        <td className="text-right">
                          {numberFormat(item.matchedTax, 0, '0')}
                        </td>
                        <td className="text-right">
                          {numberFormat(item.divdDltx, 0, '0')}
                        </td>
                        <td className="text-right">
                          {item.buySelTp === '1'
                            ? numberFormat(
                                item.matchedQty * item.matchedPrc +
                                  item.matchedFee,
                                0,
                                '0'
                              )
                            : numberFormat(
                                item.matchedQty * item.matchedPrc -
                                  item.matchedTax -
                                  item.matchedFee -
                                  item.divdDltx -
                                  item.selFee,
                                0,
                                '0'
                              )}
                        </td>
                        <td className={'text-left ordStt_' + item.ordrStatTp}>
                          {_getOrderStatus(
                            item.ordrStatTp,
                            item.ordrQty,
                            item.matchedQty
                          )}
                        </td>
                        <td className="text-left">
                          {_convertTpToPrice(item.ordrTrdTp)}
                        </td>
                        <td className="text-left">{item.ordrNo}</td>
                        <td className="text-left">{item.origOrdrNo}</td>
                        <td className="text-left">
                          {mapOrderChanel(item.ordrChnl)}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
              <tfoot>
                <tr style={{ border: '1px solid #e8e8e8' }}>
                  <td className="fw-500 text-center" colSpan="5">
                    {t('txt-label-total')}
                  </td>
                  <td colSpan="2"></td>
                  <td className="text-right fw-500">
                    {numberFormat(_sumMatchVol, 0, '0')}
                  </td>
                  <td colSpan="3"></td>
                  <td className="text-right fw-500">
                    {numberFormat(_sumMatchVal, 0, '0')}
                  </td>
                  <td className="text-right fw-500">
                    {numberFormat(_sumFee, 0, '0')}
                  </td>
                  <td className="text-right fw-500">
                    {numberFormat(_sumSelFee, 0, '0')}
                  </td>
                  <td className="text-right fw-500">
                    {numberFormat(_sumTax, 0, '0')}
                  </td>
                  <td className="text-right fw-500">
                    {numberFormat(_sumDivdDltx, 0, '0')}
                  </td>
                  <td className="text-right fw-500">
                    {numberFormat(_sumGTTT, 0, '0')}
                  </td>
                  <td colSpan="5"></td>
                </tr>
              </tfoot>
            </table>
          </div>
        </Page>
      </PerfectScrollBar>
    );
  }
);
export default OrderStatement;
