import React, { useState, useEffect, useContext } from 'react';
import { withNamespaces } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { filter, sumBy, isEqual, some } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import {
  stockExchangeHisRequest,
  stockExchangeRequest,
  stockExchangeReset,
  stockSourceRequest,
  stockTargetRequest,
} from '../../cash/actions';
import {
  numberFormat,
  StringToInt,
  formatDate,
  _formatDate,
  getStockTransferStatus,
  getMessageError,
} from '../../../util';
import { removeCookie } from '../../../lib/storages';
import { Logout, logoutRequest } from '../../../components/modal/login/actions';
import { setAuth, setToast, unsetClient } from '../../client/actions';
import PerfectScrollBar from 'react-perfect-scrollbar';
import PaginationTable from '../../../components/PaginationTable';
import RadioButton from '../../../components/radioButton';
import DatePicker from '../../../components/datePicker2';
import { ReactReduxContext } from 'react-redux';
import MaxInput from '../../../components/input/maxInput';
import ReactSelect from 'react-select';

const Index = withNamespaces('translations')(({ t }) => {
  const { token, auth } = useSelector((state) => state.client);
  const { listAccount, defaultAccount } = useSelector((state) => state.socket);
  const { stockSource, stockTarget, stockExchange, exHis } = useSelector(
    (state) => state.cash
  );
  const dispatch = useDispatch();
  const [listSource, setListSource] = useState([]);
  const [listTarget, setListTarget] = useState([]);
  const [_stockSource, setStockSource] = useState([]);
  const [_defSource, setDefSource] = useState();
  const [_defTarget, setDefTarget] = useState({});
  const [activeIndex, setActiveIndex] = useState(-1);
  const [activeAmount, setActiveAmount] = useState();
  const [activeSymbol, setActiveSymbol] = useState();
  const [refresh, setRefresh] = useState(false);
  const currentDate = new Date();
  const DEFAULT_TODATE = new Date();
  const DEFAULT_FROMDATE = new Date(
    currentDate.setDate(currentDate.getDate() - 30)
  );

  const [fromDate, setFromDate] = useState(new Date(DEFAULT_FROMDATE));
  const [toDate, setToDate] = useState(DEFAULT_TODATE);
  const enabled = StringToInt(activeAmount) > 0;
  const { store } = useContext(ReactReduxContext);

  useEffect(() => {
    if (listAccount && defaultAccount) {
      changeUserSourceHandle(defaultAccount);
    }
  }, [listAccount, defaultAccount]);

  useEffect(() => {
    let _stockSource = [];

    if (stockSource && stockSource.list) {
      stockSource.list.forEach((element) => {
        const _stock = {
          symbol: element.symbol,
          quantity: element.quantity,
          amount: '',
          content: '',
        };
        _stockSource.push(_stock);
      });
    }

    setStockSource(_stockSource);
  }, [stockSource]);

  useEffect(() => {
    if (stockExchange) {
      if (stockExchange.http !== 200) {
        if (stockExchange.http === 401) {
          if (token) dispatch(logoutRequest(token));

          dispatch(unsetClient());
          removeCookie('token');
          removeCookie('authen');

          dispatch(Logout());
        }

        // toast fail
        const { getState } = store;
        if (getState)
          toastHandle(getMessageError(stockExchange, getState), t('txt-fail'));
        else
          toastHandle(
            getMessageError(
              stockExchange?.message ?? t('txt-title-transfer-stock-fail'),
              getState
            ),
            t('txt-fail')
          );
      } else {
        // toast success
        toastHandle(t('txt-title-transfer-success'));
        loadDataSourceHandle();
        loadDataTargetHandle();
        getExchangeHisHandle();
        clearHandle();
      }

      dispatch(stockExchangeReset());
    }
  }, [stockExchange]);

  useEffect(() => {
    if (_defSource) {
      loadDataSourceHandle();
      getExchangeHisHandle();
      clearHandle();
    }
  }, [_defSource]);

  useEffect(() => {
    if (_defTarget) {
      loadDataTargetHandle();
    }
  }, [_defTarget]);

  const toastHandle = (msg, title = t('txt-notice')) => {
    const toastMsg = {
      id: Math.random(),
      msg: msg,
      title: title,
    };
    dispatch(setToast(toastMsg));
  };

  const loadDataSourceHandle = () => {
    if (!_defSource || !token) return;

    const uuid = uuidv4();
    const resData = {
      group: 'CORE',
      user: token.user,
      session: token.session,
      cmd: 'getAvailStockList',
      rqId: uuid,
      channel: 'WTS',
      data: {
        acntNo: _defSource.acntNo,
        subAcntNo: _defSource.subAcntNo,
      },
    };

    dispatch(stockSourceRequest(resData));
  };

  const loadDataTargetHandle = () => {
    if (!_defTarget || !token) return;

    const uuid = uuidv4();
    const resData = {
      group: 'CORE',
      user: token.user,
      session: token.session,
      cmd: 'getAvailStockList',
      rqId: uuid,
      channel: 'WTS',
      data: {
        acntNo: _defTarget.acntNo,
        subAcntNo: _defTarget.subAcntNo,
      },
    };

    dispatch(stockTargetRequest(resData));
  };

  const getExchangeHisHandle = (fromDate, endDate) => {
    if (!token) return;
    if (!fromDate) fromDate = formatDate(DEFAULT_FROMDATE, '');
    else fromDate = _formatDate(fromDate);

    if (!endDate) endDate = formatDate(DEFAULT_TODATE, '');
    else endDate = _formatDate(endDate);

    const uuid = uuidv4();
    const resData = {
      group: 'CORE',
      user: token.user,
      session: token.session,
      cmd: 'getStockTransferHist',
      rqId: uuid,
      channel: 'WTS',
      data: {
        acntNo: _defSource?.acntNo,
        subAcntNo: _defSource?.subAcntNo,
        fromDate: fromDate,
        toDate: endDate,
      },
    };

    dispatch(stockExchangeHisRequest(resData));
  };

  const changeUserTargetHandle = (user) => {
    setDefTarget(user);
  };

  const changeUserSourceHandle = (user = defaultAccount) => {
    const _listSource = filter(listAccount, (o) => o.subAcntStat === 1);
    const _listTarget = filter(
      listAccount,
      (o) => o.subAcntNo !== user.subAcntNo && o.subAcntStat === 1
    );

    if (_listSource && !!_listSource.length) {
      setListSource(_listSource);
      setDefSource(user);
    }

    if (_listTarget && !!_listTarget.length) {
      setListTarget(_listTarget);
      setDefTarget(_listTarget[0]);
    }
  };

  const clearHandle = () => {
    setActiveAmount();
    setActiveIndex(-1);
    setActiveSymbol();
  };

  const submitHandle = () => {
    if (!auth || !token || !_defTarget || !_defSource) return;
    const content =
      t('txt-label-transfer-to') + ' ' + _defTarget?.subAcntNo ?? '';

    const uuid = uuidv4();
    const resData = {
      group: 'CORE',
      user: token.user,
      session: token.session,
      cmd: 'StockTransferInternal',
      rqId: uuid,
      channel: 'WTS',
      type: auth.type,
      token: auth.token,
      data: {
        acntNo: _defSource.acntNo,
        subAcntNo: _defSource.subAcntNo,
        symbol: activeSymbol,
        trnsBalTp: '01',
        qty: StringToInt(activeAmount) + '',
        toSubAcntNo: _defTarget.subAcntNo,
        desc: content,
      },
    };

    dispatch(stockExchangeRequest(resData));
  };

  const authenHandle = () => {
    dispatch(setAuth());
  };

  const _targetQuantity =
    stockTarget && stockTarget.list ? sumBy(stockTarget.list, 'quantity') : 0;

  const _sourceQuantity =
    stockSource && stockSource.list ? sumBy(stockSource.list, 'quantity') : 0;

  const columns = [
    {
      key: 'defaultAccount',
      text: t('txt-label-source'),
      className: 'text-center text--light',
      align: 'center',
      cell: (record) => {
        return <span>{record?.subAcntNo || _defSource?.subAcntNo}</span>;
      },
    },
    {
      key: 'toSubAcntNo',
      text: t('txt-label-target'),
      className: 'text-center text--light',
      align: 'center',
    },
    {
      key: 'symbol',
      text: t('txt-symbol'),
      className: 'text-center fw-500 text--light',
      align: 'center',
      sortable: true,
    },
    {
      key: 'quantity',
      text: t('txt-vol'),
      className: 'text-center text--light',
      align: 'center',
      cell: (record) => {
        return numberFormat(record.quantity, 0, '0');
      },
      sortable: true,
    },
    {
      key: 'desc',
      text: t('txt-label-remark'),
      className: 'text-center text--light ',
      align: 'center',
    },
    {
      key: 'status',
      text: t('txt-status'),
      className: (record) => {
        return (
          'text-center ' +
          (record.status === '1' || record.status === '0' ? 'i' : 'd')
        );
      },
      align: 'center',
      cell: (record) => {
        return getStockTransferStatus(record.status);
      },
    },
    {
      key: 'trdDt',
      text: t('txt-label-create-date'),
      className: 'text-center text--light3',
      align: 'center',
      sortable: true,
    },
  ];
  return (
    <>
      <div className="personal-assets card-panel-2">
        <div className="personal-assets-body card-panel-2-body">
          <p className="text-title mt-24 text--light">
            {t('transaction.stockInternalTransfer.name')}
          </p>
          <div className="stock-internal-transfer">
            <div className="sit sit__left">
              <div className="sit-header">
                <div className="sit-header sit-header__left">
                  <span className="text-light">
                    {t('transaction.stockInternalTransfer.source')}
                  </span>
                  {listSource && !!listSource.length && (
                    <ReactSelect
                      defaultValue={_defSource || defaultAccount}
                      className="transaction-filters__select"
                      classNamePrefix="filter-control-select"
                      options={listSource}
                      isSearchable={false}
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      value={_defSource}
                      onChange={(value) => changeUserSourceHandle(value)}
                    />
                  )}
                </div>
                <span
                  className="d-flex reload-button btn--refresh"
                  onClick={() => loadDataSourceHandle()}
                >
                  <span className="icon iReload"></span>
                </span>
                <div className="sit-header sit-header__right">
                  <span className="sit-keyvalue">
                    <span>{t('txt-label-stock-holding')}</span>
                    <span className="text-light">
                      {stockSource && stockSource.list
                        ? stockSource.list.length
                        : ''}
                    </span>
                  </span>
                  <span className="sit-keyvalue">
                    <span>{t('txt-label-vol-holding')}</span>
                    <span className="text-light">
                      {numberFormat(_sourceQuantity, 0, '0')}
                    </span>
                  </span>
                </div>
              </div>
              <div className="sit-body">
                <PerfectScrollBar style={{ maxHeight: '200px', width: '100%' }}>
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th className="text-center w-32p"></th>
                        <th className="text-center w-32p">#</th>
                        <th
                          className="text-center position-relative"
                          style={{ minWidth: '100px' }}
                        >
                          {t('txt-symbol')}
                        </th>
                        <th
                          className="text-right position-relative"
                          style={{ minWidth: '120px' }}
                        >
                          {t('transaction.stockInternalTransfer.holdingVolume')}
                        </th>
                        <th
                          className="text-right position-relative"
                          style={{ minWidth: '120px' }}
                        >
                          {t('transaction.stockInternalTransfer.maxTransfer')}
                        </th>
                        <th className="text-center position-relative">
                          {t(
                            'transaction.stockInternalTransfer.transferVolume'
                          )}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {_stockSource &&
                        !!_stockSource.length &&
                        _stockSource.map((item, index) => (
                          <tr key={'target_' + index}>
                            <td className="text-center w-32p">
                              <RadioButton
                                isReadonly
                                isActive={
                                  activeIndex != -1 && index == activeIndex
                                }
                              />
                            </td>
                            <td className="text-center text--light3 w-32p">
                              {index + 1}
                            </td>
                            <td className="text-center fw-500 text--light">
                              {item.symbol}
                            </td>
                            <td className="text-right text--light">
                              {numberFormat(item.quantity, 0, '0')}
                            </td>
                            <td className="text-right text--light">
                              {numberFormat(item.quantity, 0, '0')}
                            </td>
                            <td className="text-right text--light">
                              <MaxInput
                                max={item.quantity}
                                initValue={
                                  index == activeIndex ? activeAmount : ''
                                }
                                index={index}
                                disabled={
                                  activeIndex != -1 && index != activeIndex
                                }
                                callback={(amount = 0, e = index) => {
                                  setActiveAmount(amount);
                                  setActiveIndex(e);
                                  setActiveSymbol(item.symbol);
                                }}
                              />
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </PerfectScrollBar>
              </div>
              <div className="sit-footer">
                {auth && auth.isVerified ? (
                  <>
                    <button
                      className="btn btn--reset"
                      onClick={() => clearHandle()}
                      disabled={!enabled}
                    >
                      {t('txt-reset')}
                    </button>
                    <button
                      className="btn btn--primary"
                      disabled={!enabled}
                      onClick={() => submitHandle()}
                    >{`${t('txt-label-transfer-to')} ${
                      _defTarget?.subAcntNo ?? ''
                    }`}</button>
                  </>
                ) : (
                  <button
                    className="btn btn--authen"
                    onClick={() => authenHandle()}
                  >
                    {`${t('txt-label-transfer-to')} ${
                      _defTarget?.subAcntNo ?? ''
                    }`}
                  </button>
                )}
              </div>
            </div>
            <span className="d-flex transfer-button">
              <span className="icon iTransfer"></span>
            </span>
            <div className="sit sit__right">
              <div className="sit-header">
                <div className="sit-header sit-header__left">
                  <span className="text--light">
                    {t('transaction.stockInternalTransfer.target')}
                  </span>
                  {listTarget && !!listTarget.length && (
                    <ReactSelect
                      defaultValue={_defTarget}
                      className="transaction-filters__select"
                      classNamePrefix="filter-control-select"
                      options={listTarget}
                      isSearchable={false}
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      value={_defTarget}
                      onChange={(value) => changeUserTargetHandle(value)}
                    />
                  )}
                </div>
                <span
                  className="d-flex reload-button btn--refresh"
                  onClick={() => loadDataTargetHandle()}
                >
                  <span className="icon iReload"></span>
                </span>
                <div className="sit-header sit-header__right">
                  <span>
                    <span className="text--light3">
                      {t('txt-label-stock-holding')}
                    </span>
                    <span className="text--light ml-1">
                      {stockTarget && stockTarget.list
                        ? stockTarget.list.length
                        : ''}
                    </span>
                  </span>
                  <span>
                    <span className="text--light3">
                      {t('txt-label-vol-holding')}
                    </span>
                    <span className="text--light ml-1">
                      {numberFormat(_targetQuantity, 0, '0')}
                    </span>
                  </span>
                </div>
              </div>
              <div className="sit-body">
                <PerfectScrollBar style={{ maxHeight: '200px', width: '100%' }}>
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th className="text-center w-32p">#</th>
                        <th className="text-center position-relative">
                          {t('txt-symbol')}
                        </th>
                        <th className="text-center position-relative">
                          {t('txt-label-vol-holding')}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {stockTarget &&
                        stockTarget.list &&
                        !!stockTarget.list.length &&
                        stockTarget.list.map((item, index) => (
                          <tr key={'target_' + index}>
                            <td className="text-center text--light3 ">
                              {index + 1}
                            </td>
                            <td className="text-center fw-500 text--light">
                              {item.symbol}
                            </td>
                            <td className="text-right text--light">
                              {numberFormat(item.quantity, 0, '0')}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </PerfectScrollBar>
              </div>
            </div>
          </div>

          <div className="stock-internal-transfer-history">
            <div className="transaction-content__header">
              <p className="text-title mt-24 mb-0 text--light">
                {t('txt-label-history')}
              </p>
              <div className="transaction-filters">
                <DatePicker
                  startDate={fromDate}
                  endDate={toDate}
                  callback={getExchangeHisHandle}
                  key={refresh}
                />
                <span
                  className="d-flex reload-button btn--refresh cursor-pointer"
                  onClick={() => {
                    setToDate(DEFAULT_TODATE);
                    setFromDate(DEFAULT_FROMDATE);
                    getExchangeHisHandle();
                    setRefresh(!refresh);
                  }}
                >
                  <span className="icon iReload"></span>
                </span>
              </div>
            </div>
            <div className="history-body">
              <PerfectScrollBar>
                <PaginationTable
                  t
                  columns={columns}
                  source={exHis && exHis.list ? exHis.list : []}
                  hasPaging
                  hasSearch
                  hasSwitch
                  searchField="symbol"
                  hasSort="true"
                />
              </PerfectScrollBar>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default Index;
