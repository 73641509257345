import React, { useContext, useEffect, useState } from 'react';
import CheckboxButton from '../../../components/checkbox/checkBoxButton';
import Modal from 'react-bootstrap/Modal';
import { ReactReduxContext, useDispatch, useSelector } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import PDFViewer from '../../bond/components/Contract/pdfViewer';
import { handleApiErrors } from '../../../lib/api-errors';
import { Logout, logoutRequest } from '../../../components/modal/login/actions';
import { setToast, unsetClient } from '../../client/actions';
import { v4 as uuidv4 } from 'uuid';
import { removeCookie } from '../../../lib/storages';
import { getMessageError } from '../../../util';

import PerfectScrollBar from 'react-perfect-scrollbar';
import { orderPF } from '../action';

const appUrl = `${process.env.REACT_APP_API_URL}`;

const Contract = withNamespaces('translations')(
  ({
    t,
    orderType,
    quantity,
    close,
    isCreatePlan,
    handleCreatePlan,
    _contracts,
  }) => {
    const dispatch = useDispatch();
    const [check, setCheck] = useState();
    const [contracts, setContracts] = useState([]);
    const [watched, setWatched] = useState([0]);
    const [currentContract, setCurrentContract] = useState(null); // first of list contracts for defaults
    const [showModal, setShowModal] = useState(false);
    const theme = useSelector((state) => state.client.currentTheme);
    const { token, auth } = useSelector((state) => state.client);
    const lang = useSelector((state) => state.client?.setting?.lang || 'vi');
    const { defaultAccount } = useSelector((state) => state.socket);
    const { currentPinefolio } = useSelector((state) => state.pinefolio);
    const { store } = useContext(ReactReduxContext);

    console.log('contracts ===> ', contracts);

    // Todos:
    // check icon green after click | default is gray
    // lighter icon while hover
    // disable confirm button if not check term and conditional

    const closeModal = () => {
      close();
    };

    const toastHandle = (msg) => {
      const toastMsg = {
        id: Math.random(),
        msg: msg,
        title: t('txt-notice'),
      };
      dispatch(setToast(toastMsg));
    };

    useEffect(() => {
      if (isCreatePlan) {
        if (_contracts && _contracts.length > 0) {
          setContracts(_contracts);
          setCurrentContract(_contracts[0]);
        }
        return;
      }
      getContracts();
    }, []);

    const getContracts = () => {
      const uuid = uuidv4();
      if (token && auth) {
        const params = {
          group: 'CORE',
          cmd: 'getPFOrdrCntr',
          rqId: uuid,
          channel: 'WTS',
          session: token.session,
          user: token.user,
          type: auth.type,
          token: auth.token,

          data: {
            cif: token.cif,
            acntNo: defaultAccount?.acntNo,
            subAcntNo: defaultAccount?.subAcntNo,
            pfCode: currentPinefolio?.pfCode,
            prchsSaleTp: orderType + '',
            pfBuyUnitQty: quantity + '',
            pfTradeTp: '1',
            langTp: lang,
          },
        };

        const url = `${appUrl}/PortfolioServlet.pt`;
        fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          },
          body: JSON.stringify(params),
        })
          .then(handleApiErrors)
          .then((response) => response.json())
          .then((res) => {
            if (res.http !== 200) {
              if (res.http === 401) {
                if (token) dispatch(logoutRequest(token));
                dispatch(unsetClient());
                removeCookie('token');
                removeCookie('authen');
                dispatch(Logout());
                toastHandle(t('txt-valid-loss-session'));
              } else {
                toastHandle(getMessageError(res, store.getState));
              }
              closeModal();
            } else {
              // success
              const data = res.data;
              if (data && !!data.length > 0) {
                setShowModal(true);
                setContracts(data);
                setCurrentContract(data[0]);
              }
            }
          })
          .catch((error) => {
            console.error('Error:', error);
          });
      }
    };

    const confirm = () => {
      if (token && !disableHandle()) {
        const uuid = uuidv4();

        const params = {
          group: 'CORE',
          cmd: 'placePFOrder',
          rqId: uuid,
          channel: 'WTS',
          session: token.session,
          user: token.user,
          type: auth.type,
          token: auth.token,
          data: {
            cif: token.cif,
            acntNo: defaultAccount?.acntNo,
            subAcntNo: defaultAccount?.subAcntNo,
            pfCode: currentPinefolio?.pfCode,
            prchsSaleTp: orderType + '',
            pfBuyUnitQty: quantity + '',
            pfTradeTp: '1',
          },
        };
        dispatch(orderPF(params, () => onOrderSuccess()));
      }
    };

    const watchContractHandle = (contract, index) => {
      let _watched = [...watched];

      if (!watched.includes(index)) {
        _watched.push(index);
        setWatched(_watched);
      }

      setCurrentContract(contract);
    };

    const disableHandle = () => {
      if (!check) return true;
      return false;
    };

    const onOrderSuccess = () => {
      closeModal();
    };

    return (
      <Modal
        show={showModal || isCreatePlan}
        dialogClassName="wts-modal"
        backdropClassName="wts-modal-backdrop pinefolio-order-confirm-backdrop"
        contentClassName={`${
          theme ? theme.name : 'dark'
        } pinefolio-order-confirm wts-modal-content wts-modal-content--lg2`}
        onEscapeKeyDown={() => closeModal()}
        centered
        onHide={() => closeModal()}
        style={{ zIndex: 11120 }}
      >
        <div className="pinefolio-order-confirm__header">
          <div className="pinefolio-order-confirm__action"></div>
          <div className="pinefolio-order-confirm__title text--lg text--light text-center">
            {isCreatePlan
              ? t('pinefolio.plan.createPlan.contract.name')
              : t('pinefolio.order.viewOrderTicket')}
          </div>
          <div
            className="pinefolio-order-confirm__action"
            onClick={() => closeModal()}
          >
            <span className="icon iClose"></span>
          </div>
        </div>
        <div className="pinefolio-order-confirm__body">
          <div className="pinefolio-order-confirm__left">
            <div className="pinefolio-order-confirm__orders">
              {!isCreatePlan && (
                <div className="pinefolio-order-confirm__order text-center pinefolio-order-confirm__order--title">
                  <span className="text--md">
                    {t('pinefolio.nav.orderList')}
                  </span>
                </div>
              )}
              <PerfectScrollBar style={{ maxHeight: '40vh' }}>
                {contracts &&
                  contracts.map((contract, i) => (
                    <div
                      className="pinefolio-order-confirm__order contract"
                      key={i}
                      onClick={() => watchContractHandle(contract, i)}
                    >
                      <span
                        className={`contract__name text--sm ${
                          contract.no == currentContract?.no ? 'active' : ''
                        }`}
                      >
                        {i + 1}. {contract.fileName}
                      </span>
                      <div className="contract__actions">
                        <span
                          className={`icon iCheck ${
                            watched.some((x) => x == i) ? 'i' : ''
                          }`}
                        ></span>
                        <span className="icon iDownload"></span>
                      </div>
                    </div>
                  ))}
                <div style={{ height: '15px' }}></div>
              </PerfectScrollBar>
            </div>
            <div>
              <CheckboxButton
                onClick={(e) => setCheck(e)}
                description={t('pinefolio.order.confirm')}
              />
              <div className="d-flex">
                <div className="text-nowrap mr-1">
                  <div className="fw-400 fz_12 text--gray font-italic">{`${t(
                    'pinefolio.order.note'
                  )}:`}</div>
                </div>

                {isCreatePlan == true ? (
                  <div>
                    <div className="fw-400 fz_12 text--gray font-italic">
                      {`${t('pinefolio.order.explain1')}`}
                    </div>
                  </div>
                ) : orderType == 1 ? (
                  <div>
                    <div className="fw-400 fz_12 text--gray font-italic">
                      {`${t('pinefolio.order.buyOrderExplain1')}`}
                    </div>
                    <div className="fw-400 fz_12 text--gray font-italic">
                      {`${t('pinefolio.order.buyOrderExplain2')}`}
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="fw-400 fz_12 text--gray font-italic">
                      {`${t('pinefolio.order.sellOrderExplain1')}`}
                    </div>
                    <div className="fw-400 fz_12 text--gray font-italic">
                      {`${t('pinefolio.order.sellOrderExplain2')}`}
                    </div>
                  </div>
                )}
              </div>
              <div className="pinefolio-order-confirm__actions">
                {isCreatePlan == true ? (
                  <button
                    className={`btn ${
                      orderType == 1 ? 'btn--buy' : 'btn--sell'
                    } ${disableHandle() ? 'disabled' : ''}`}
                    onClick={() => handleCreatePlan()}
                  >
                    {t('txt-confirm')}
                  </button>
                ) : (
                  <button
                    className={`btn ${
                      orderType == 1 ? 'btn--buy' : 'btn--sell'
                    } ${disableHandle() ? 'disabled' : ''}`}
                    onClick={() => confirm()}
                  >
                    {t('txt-confirm')}
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className="pinefolio-order-confirm__right">
            {currentContract && <PDFViewer src={currentContract.fileUrl} />}
          </div>
        </div>
      </Modal>
    );
  }
);

export default Contract;
