import React from 'react';
import { Button } from 'react-bootstrap';
import { translate } from 'react-i18next';
class Paging extends React.Component {
  state = {
    curPage: 1,
  };

  componentDidMount() {
    const { continued, totalCount, pageSize } = this.props;
    this.setState({ continued, totalCount, pageSize });
  }

  _handleNextPage = (step) => {
    const { curPage } = this.state;
    // this.setState({ curPage: curPage + step });
    this.props.handleQueryPage(curPage + step);
  };

  componentDidUpdate(preProps) {
    const { page } = this.props;
    if (page && page !== preProps.page) {
      this.setState({ curPage: page });
    }
  }

  render() {
    const { continued, totalCount, pageSize, t } = this.props;
    const { curPage } = this.state;

    const _totalPage = totalCount > 0 ? Math.ceil(totalCount / pageSize) : 1;

    return (
      <div className="d-flex flex-row-reverse align-items-center paging mt-2">
        <div className="d-flex align-items-center">
          <Button
            onClick={() => this._handleNextPage(-1)}
            disabled={curPage === 1}
            className="d-flex align-items-center justify-content-center"
          >
            {'<'}
          </Button>
          <Button
            onClick={() => this._handleNextPage(1)}
            disabled={!continued}
            className="d-flex align-items-center justify-content-center"
          >
            {'>'}
          </Button>
        </div>
        <span className="fz_14 text-white mr-2">{`${t('txt-label-page')} ${
          curPage + '/' + _totalPage
        }`}</span>
      </div>
    );
  }
}

export default translate('translations')(Paging);
