export const SET_HAD_PINE_ACCOUNT = 'SET_HAD_PINE_ACCOUNT';
export const SET_OPEN_PINE_ACCOUNT_POPUP = 'SET_OPEN_PINE_ACCOUNT_POPUP';
export const REGIST_PINEFOLIO_SUBACCOUNT = 'REGIST_PINEFOLIO_SUBACCOUNT';
export const REGIST_PINEFOLIO_SUBACCOUNT_SUCCESS =
  'REGIST_PINEFOLIO_SUBACCOUNT_SUCCESS';

export const REGIST_PINEFOLIO_SUBACCOUNT_CONTRACT =
  'REGIST_PINEFOLIO_SUBACCOUNT_CONTRACT';
export const REGIST_PINEFOLIO_SUBACCOUNT_CONTRACT_SUCCESS =
  'REGIST_PINEFOLIO_SUBACCOUNT_CONTRACT_SUCCESS';

export const GET_LIST_PINEFOLIO_REQUEST = 'GET_LIST_PINEFOLIO_REQUEST';
export const GET_LIST_PINEFOLIO_SUCCESS = 'GET_LIST_PINEFOLIO_SUCCESS';

export const GET_THEME_DETAIL = 'SET_THEME_DETAIL';
export const GET_THEME_DETAIL_SUCCESS = 'GET_THEME_DETAIL_SUCCESS';
export const RESET_THEME_DETAIL = 'RESET_THEME_DETAIL';

export const SET_CURRENT_PINEFOLIO = 'GET_PINEFOLIO_DETAIL';
export const RESET_CURRENT_PINEFOLIO = 'RESET_CURRENT_PINEFOLIO';

export const OPEN_PLAN_DETAIL_MODAL = 'OPEN_PLAN_DETAIL_MODAL';
export const HANDLE_DEPOSIT_PINEFOLIO = 'HANDLE_DEPOSIT_PINEFOLIO';

export const SET_PF_ORDER = 'SET_PF_ORDER';

export const GET_PINEFOLIO_ORDER_LIST_REQUEST =
  'GET_PINEFOLIO_ORDER_LIST_REQUEST';
export const GET_PINEFOLIO_ORDER_LIST_SUCCESS =
  'GET_PINEFOLIO_ORDER_LIST_SUCCESS';
export const GET_PINEFOLIO_ORDER_LIST_ERROR = 'GET_PINEFOLIO_ORDER_LIST_ERROR';

export const GET_PINEFOLIO_ORDER_DTL_REQUEST =
  'GET_PINEFOLIO_ORDER_DTL_REQUEST';
export const GET_PINEFOLIO_ORDER_DTL_SUCCESS =
  'GET_PINEFOLIO_ORDER_DTL_SUCCESS';
export const GET_PINEFOLIO_ORDER_DTL_ERROR = 'GET_PINEFOLIO_ORDER_DTL_ERROR';

export const GET_PINEFOLIO_ORDER_DTL_ST_REQUEST =
  'GET_PINEFOLIO_ORDER_DTL_ST_REQUEST';
export const GET_PINEFOLIO_ORDER_DTL_ST_SUCCESS =
  'GET_PINEFOLIO_ORDER_DTL_ST_SUCCESS';
export const GET_PINEFOLIO_ORDER_DTL_ST_ERROR =
  'GET_PINEFOLIO_ORDER_DTL_ST_ERROR';

export const CANCEL_PINEFOLIO_ORDER_REQUEST = 'CANCEL_PINEFOLIO_ORDER_REQUEST';
export const CANCEL_PINEFOLIO_ORDER_SUCCESS = 'CANCEL_PINEFOLIO_ORDER_SUCCESS';
export const CANCEL_PINEFOLIO_ORDER_ERROR = 'CANCEL_PINEFOLIO_ORDER_ERROR';

export const GET_PINEFOLIO_INVESTMENT_PLAN_REQUEST =
  'GET_PINEFOLIO_INVESTMENT_PLAN_REQUEST';
export const GET_PINEFOLIO_INVESTMENT_PLAN_SUCCESS =
  'GET_PINEFOLIO_INVESTMENT_PLAN_SUCCESS';
export const GET_PINEFOLIO_INVESTMENT_PLAN_ERROR =
  'GET_PINEFOLIO_INVESTMENT_PLAN_ERROR';

export const GET_PINEFOLIO_INVESTMENT_PLAN_HISTORY_REQUEST =
  'GET_PINEFOLIO_INVESTMENT_PLAN_HISTORY_REQUEST';
export const GET_PINEFOLIO_INVESTMENT_PLAN_HISTORY_SUCCESS =
  'GET_PINEFOLIO_INVESTMENT_PLAN_HISTORY_SUCCESS';
export const GET_PINEFOLIO_INVESTMENT_PLAN_HISTORY_ERROR =
  'GET_PINEFOLIO_INVESTMENT_PLAN_HISTORY_ERROR';

export const GET_PF_ANA_REPORT_REQUEST = 'GET_PF_ANA_REPORT_REQUEST';
export const GET_PF_ANA_REPORT_SUCCESS = 'GET_PF_ANA_REPORT_SUCCESS';
export const GET_PF_ANA_REPORT_ERROR = 'GET_PF_ANA_REPORT_ERROR';

export const GET_PF_ANA_REPORT_CAT_TP_REQUEST =
  'GET_PF_ANA_REPORT_CAT_TP_REQUEST';
export const GET_PF_ANA_REPORT_CAT_TP_SUCCESS =
  'GET_PF_ANA_REPORT_CAT_TP_SUCCESS';
export const GET_PF_ANA_REPORT_CAT_TP_ERROR = 'GET_PF_ANA_REPORT_CAT_TP_ERROR';

export const GET_PF_TD_LIST_REQUESTING = 'GET_PF_TD_LIST_REQUESTING';
export const GET_PF_TD_LIST_SUCCESS = 'GET_PF_TD_LIST_SUCCESS';
export const GET_PF_TD_LIST_ERROR = 'GET_PF_TD_LIST_ERROR';

export const GET_PF_IP_LIST_DTL_REQUESTING = 'GET_PF_IP_LIST_DTL_REQUESTING';
export const GET_PF_IP_LIST_DTL_SUCCESS = 'GET_PF_IP_LIST_DTL_SUCCESS';
export const GET_PF_IP_LIST_DTL_ERROR = 'GET_PF_IP_LIST_DTL_ERROR';

export const HANDLE_EDIT_PLAN_MONITOR = 'HANDLE_EDIT_PLAN_MONITOR';
export const HANDLE_CREATE_PLAN_MONITOR = 'HANDLE_CREATE_PLAN_MONITOR';
export const GET_PINEFOLIO_PURCHASE_POWER_REQUEST =
  'GET_PINEFOLIO_PURCHASE_POWER_REQUEST';
export const GET_PINEFOLIO_PURCHASE_POWER_REQUEST_SUCCESS =
  'GET_PINEFOLIO_PURCHASE_POWER_REQUEST_SUCCESS';

export const ORDER_PF_REQUEST = 'ORDER_PF_REQUEST';
export const ORDER_PF_SUCCESS = 'ORDER_PF_SUCCESS';

export const ORDER_PF_CONTRACT_REQUEST = 'ORDER_PF_CONTRACT_REQUEST';
export const ORDER_PF_CONTRACT_REQUEST_SUCCESS =
  'ORDER_PF_CONTRACT_REQUEST_SUCCESS';

export const GET_PF_IP_ONE_REQUESTING = 'GET_PF_IP_ONE_REQUESTING';
export const GET_PF_IP_ONE_SUCCESS = 'GET_PF_IP_ONE_SUCCESS';
export const GET_PF_IP_ONE_ERROR = 'GET_PF_IP_ONE_ERROR';

export const DEPOSIT_PF_IP_REQUESTING = 'DEPOSIT_PF_IP_REQUESTING';
export const DEPOSIT_PF_IP_SUCCESS = 'DEPOSIT_PF_IP_SUCCESS';
export const DEPOSIT_PF_IP_ERROR = 'DEPOSIT_PF_IP_ERROR';
export const GET_PF_PORTFOLIO_REQUEST = 'GET_PF_PORTFOLIO_REQUEST';
export const GET_PF_PORTFOLIO_REQUEST_SUCCESS =
  'GET_PF_PORTFOLIO_REQUEST_SUCCESS';

export const RESET_PF_PORTFOLIO_DETAIL_REQUEST =
  'RESET_PF_PORTFOLIO_DETAIL_REQUEST';
export const GET_PF_PORTFOLIO_DETAIL_REQUEST =
  'GET_PF_PORTFOLIO_DETAIL_REQUEST';
export const GET_PF_PORTFOLIO_DETAIL_REQUEST_SUCCESS =
  'GET_PF_PORTFOLIO_DETAIL_REQUEST_SUCCESS';
export const GET_PF_LIST_REQUEST = 'GET_PF_LIST_REQUEST';
export const GET_PF_LIST_REQUEST_SUCCESS = 'GET_PF_LIST_REQUEST_SUCCESS';
export const GET_PF_BY_CODE_REQUEST = 'GET_PF_BY_CODE_REQUEST';

export const GET_PF_CHART_REQUEST = 'GET_PF_CHART_REQUEST';
export const GET_PF_CHART_REQUEST_SUCCESS = 'GET_PF_CHART_REQUEST_SUCCESS';

export const GET_PF_INTERATE_MST_REQUESTING = 'GET_PF_INTERATE_MST_REQUESTING';
export const GET_PF_INTERATE_MST_SUCCESS = 'GET_PF_INTERATE_MST_SUCCESS';
export const GET_PF_INTERATE_MST_ERROR = 'GET_PF_INTERATE_MST_ERROR';

export const GET_EST_PF_PLAN_REQUESTING = 'GET_EST_PF_PLAN_REQUESTING';
export const GET_EST_PF_PLAN_SUCCESS = 'GET_EST_PF_PLAN_SUCCESS';
export const GET_EST_PF_PLAN_ERROR = 'GET_EST_PF_PLAN_ERROR';

export const GET_EST_PF_PLAN_OVER_REQUESTING =
  'GET_EST_PF_PLAN_OVER_REQUESTING';
export const GET_EST_PF_PLAN_OVER_SUCCESS = 'GET_EST_PF_PLAN_OVER_SUCCESS';
export const GET_EST_PF_PLAN_OVER_ERROR = 'GET_EST_PF_PLAN_OVER_ERROR';

export const SAVE_EST_PLAN = 'SAVE_EST_PLAN';

export const HANDLE_OPEN_CREATE_PLAN = 'HANDLE_OPEN_CREATE_PLAN';
export const SET_PF_REBALANCE = 'SET_PF_REBALANCE';

export const SELL_PF_ODD_ORDER = 'SELL_PF_ODD_ORDER';
export const SELL_PF_ODD_ORDER_SUCCESS = 'SELL_PF_ODD_ORDER_SUCCESS';

export const GET_POSITION_OUTSIDE_PF = 'GET_POSITION_OUTSIDE_PF';
export const GET_POSITION_OUTSIDE_PF_SUCCESS =
  'GET_POSITION_OUTSIDE_PF_SUCCESS';

export const RELOAD_POSITION_OUTSIDE_PF = 'RELOAD_POSITION_OUTSIDE_PF';

export const SET_PF_BOND_POPUP = 'SET_PF_BOND_POPUP';
export const SET_PF_BOND_DEMO = 'SET_PF_BOND_DEMO';
export const SET_PF_BOND_ORDER = 'SET_PF_BOND_ORDER';
export const UPDATE_PLAN_REMINDER = 'UPDATE_PLAN_REMINDER';
export const GET_UPDATE_PLAN_CONTRACT = 'GET_UPDATE_PLAN_CONTRACT';
export const GET_UPDATE_PLAN_CONTRACT_SUCCESS =
  'GET_UPDATE_PLAN_CONTRACT_SUCCESS';
export const GET_PLAN_MODIFY_INFO = 'GET_PLAN_MODIFY_INFO';
export const GET_PLAN_MODIFY_INFO_SUCCESS = 'GET_PLAN_MODIFY_INFO_SUCCESS';
export const UPDATE_PF_PLAN_REQUEST = 'UPDATE_PF_PLAN_REQUEST';
export const GET_PF_VALUE_INFO_REQUEST = 'GET_PF_VALUE_INFO_REQUEST';
export const GET_PF_VALUE_INFO_SUCCESS = 'GET_PF_VALUE_INFO_SUCCESS';
export const GET_PF_LIST_BOND_FOR_SELL_REQUEST =
  'GET_PF_LIST_BOND_FOR_SELL_REQUEST';
export const GET_PF_LIST_BOND_FOR_SELL_SUCCESS =
  'GET_PF_LIST_BOND_FOR_SELL_SUCCESS';
export const GET_PF_LIST_BOND_FOR_SELL_ERROR =
  'GET_PF_LIST_BOND_FOR_SELL_ERROR';
