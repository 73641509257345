import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { Form } from 'react-bootstrap';
import { formatDate, _diff2Date, _formatDate } from '../../../util';
import { makeGetToken } from '../../../lib/selector';
import { v4 as uuidv4 } from 'uuid';
import RenderDatePicker from '../../input/renderDatePicker';
import {
  gainLossRequest,
  resetGainLossRequest,
} from '../../../containers/cash/actions';
import { setToast } from '../../../containers/client/actions';
import { translate } from 'react-i18next';
import { isEqual } from 'lodash';
import moment from 'moment';

class FormSearchSKLL extends Component {
  submit = (values) => {
    const { startDate, endDate, symbol } = values;
    const { token, account, t } = this.props;

    if (!token) return;
    const maxDay = token && token['config'] ? token['config']['maxDay'] : 90;
    const minDate = new Date('2021-07-01 00:00:00');

    if (_diff2Date(startDate, minDate) < 0) {
      this._handleToast(
        t('txt-valid-time-2') + moment(minDate).format('DD/MM/YYYY')
      );
      return;
    }

    const _diff = _diff2Date(endDate, startDate);
    if (_diff < 0) {
      this._handleToast(t('txt-valid-time'));
      return;
    }
    if (_diff > maxDay) {
      this._handleToast(
        `${t('txt-valid-range-time')} ${maxDay} ${t('txt-day')}`
      );
      return;
    }
    const uuid = uuidv4();
    const params = {
      group: 'CORE',
      user: token.user,
      session: token.session,
      cmd: 'getProfitLossList',
      rqId: uuid,
      channel: 'WTS',
      data: {
        acntNo: account.acntNo,
        subAcntNo: account.subAcntNo,
        symbol: symbol?.toUpperCase(),
        fromDate: _formatDate(startDate),
        toDate: _formatDate(endDate),
        reqCount: 1000,
      },
    };

    this.props.dispatch(gainLossRequest(params));
    this.props.saveParams(params);
  };

  componentDidUpdate(preProps) {
    const { account, reset, refresh } = this.props;

    if (
      (account && !isEqual(account, preProps.account)) ||
      refresh !== preProps.refresh
    ) {
      reset();
      this.props.dispatch(resetGainLossRequest());
      setTimeout(() => {
        if (this.refSubmit) this.refSubmit.click();
      }, 100);
    }
  }

  _handleToast = (msg) => {
    const toastMsg = {
      id: Math.random(),
      msg: msg,
      title: this.props.t('txt-notice'),
    };
    this.props.dispatch(setToast(toastMsg));
  };

  render() {
    const { handleSubmit, submitting, startDate, endDate, t } = this.props;

    return (
      <form
        className="p-2 mt-2 form-search-head"
        onSubmit={handleSubmit(this.submit)}
      >
        <Form.Group className="d-flex mb-0 p-2" controlId="formPlaintextDate">
          <label className="text-white-50 fz_14" style={{ width: '20mm' }}>
            {t('txt-search')}
          </label>
          <div style={{ width: '50mm' }}>
            <Field
              name="symbol"
              component="input"
              maxLength="8"
              type="text"
              className="form-control form-input text-uppercase fz_14"
              placeholder={t('txt-symbol')}
            />
          </div>
          <label className="text-white-50 fz_14 mx-3">
            {t('txt-label-from')}
          </label>
          <div style={{ width: '50mm' }}>
            {startDate && (
              <Field
                classname="form-control form-input fz_14"
                name="startDate"
                type="start"
                startDate={startDate}
                endDate={endDate}
                component={RenderDatePicker}
              />
            )}
          </div>
          <label className="text-white-50 fz_14 mx-3">
            {t('txt-label-to')}
          </label>
          <div style={{ width: '50mm' }}>
            {endDate && (
              <Field
                classname="form-control form-input fz_14"
                name="endDate"
                type="end"
                startDate={startDate}
                endDate={endDate}
                component={RenderDatePicker}
              />
            )}
          </div>
          <button
            ref={(el) => (this.refSubmit = el)}
            type="submit"
            disabled={submitting}
            className="btn-reload fz_14 fw-500 text-white ml-3 w-auto px-3"
          >
            {t('txt-search')}
          </button>
        </Form.Group>
      </form>
    );
  }
}

FormSearchSKLL = reduxForm({
  form: 'saokelailo',
  enableReinitialize: true,
})(FormSearchSKLL);

const selector = formValueSelector('saokelailo');

const makeMapStateToProps = () => {
  const getToken = makeGetToken();

  const mapStateToProps = (state, props) => {
    const token = getToken(state);

    const { startDate, endDate } = selector(state, 'startDate', 'endDate');
    const numDay = token && token['config'] ? token['config']['numDay'] : 30;

    const d = new Date();
    d.setDate(d.getDate() - numDay);

    return {
      token,
      startDate,
      endDate,
      initialValues: {
        endDate: formatDate(new Date(), '/', 'dmy'),
        startDate: formatDate(d, '/', 'dmy'),
      },
    };
  };
  return mapStateToProps;
};

FormSearchSKLL = connect(makeMapStateToProps)(FormSearchSKLL);
export default translate('translations')(FormSearchSKLL);
