import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import { Button, Col, ListGroup } from 'react-bootstrap';
import {
  makeGetToken,
  makeGetListAccount,
  makeGetDefaultAccount,
  makeGetMarginList,
  makeGetAuth,
} from '../../lib/selector';

import {
  setAuth,
  setDblPrice,
  setLogin,
  setToast,
} from '../../containers/client/actions';
import { numberFormat, _formatDate2 } from '../../util';

import { v4 as uuidv4 } from 'uuid';
import { WebSocketContext } from '../../containers/socket/webSocket';

import { translate } from 'react-i18next';

import { takeRight, take, isEqual } from 'lodash';
import { marginListRequest } from '../../containers/cash/actions';
import icMagnifier from '../../assets/img/icons/header/Search.svg';
import { MixConstants, Mixpanel } from '../../lib/mixpanel';

class MarginList extends React.Component {
  state = {
    continued: false,
    totalCount: 0,
    page: 1,
    contentShow: [],
    _totalPage: 1,
    txtSymbol: '',
  };

  componentDidMount() {
    const { defaultAccount } = this.props;
    if (defaultAccount) {
      this._handleLoadMarginList();
    }
  }

  componentDidUpdate(preProps) {
    const { defaultAccount, marginList } = this.props;
    if (defaultAccount && !isEqual(defaultAccount, preProps.defaultAccount)) {
      this._handleLoadMarginList();
    }

    if (marginList && marginList !== preProps.marginList) {
      this.handleQueryPage();
    }
  }

  _handleToast = (msg) => {
    const toastMsg = {
      id: Math.random(),
      msg: msg,
      title: this.props.t('txt-notice'),
    };
    this.props.dispatch(setToast(toastMsg));
  };

  _handleLoadMarginList = () => {
    const { token, defaultAccount, setting } = this.props;
    const lang = setting.lang || 'vi';

    if (!defaultAccount) return;

    const uuid = uuidv4();

    const params = {
      group: 'CORE',
      user: token.user,
      session: token.session,
      cmd: 'GetMarginStockList',
      rqId: uuid,
      channel: 'WTS',
      data: {
        acntNo: defaultAccount.acntNo,
        subAcntNo: defaultAccount.subAcntNo,
        langTp: lang,
      },
    };
    this.props.dispatch(marginListRequest(params));
  };

  _handleNextPage = (step) => {
    const { page } = this.state;
    this.setState({ page: page + step }, () => {
      this.handleQueryPage();
    });
  };

  handleQueryPage = () => {
    const { txtSymbol, page } = this.state;
    const { marginList } = this.props;
    if (!marginList || (marginList.list && marginList.list.length === 0)) {
      this.setState({
        contentShow: [],
      });
      return;
    }

    let mList = marginList.list;
    if (txtSymbol && txtSymbol.length >= 1) {
      const _ = txtSymbol.toUpperCase();
      mList = mList.filter((x) => x.symbol.includes(_) || x.symbol == _);
    }

    const _pageSize = 15;
    const _totalPage =
      mList.length > 0 ? Math.ceil(mList.length / _pageSize) : 1;

    const _takeContent = take(mList, _pageSize * page);
    const _contentShow = takeRight(
      _takeContent,
      _takeContent.length - _pageSize * (page - 1)
    );

    this.setState({
      contentShow: _contentShow,
      page,
      continued: page < _totalPage,
      _totalPage,
    });
  };

  _handleAuthen = () => {
    this.props.dispatch(setAuth());
  };

  _handleTradeAction = (type, symbol) => {
    const { auth, token } = this.props;

    if (!token) {
      this.props.dispatch(setLogin());
      return false;
    }

    if (!auth || !auth.isVerified) {
      this.props.dispatch(setAuth());
      return false;
    }

    Mixpanel.order({
      Ticker: symbol,
      'Order Side': type,
      'Order Type': 'Normal Order',
      'Ticker Type': 'Stock',
      Location: MixConstants.LOCATIONS.MARGIN_LIST,
    });

    const newOrder = {
      symbol: symbol,
      side: type,
      price: '',
    };
    this.props.dispatch(setDblPrice(newOrder));
  };

  render() {
    const { marginList, auth, t } = this.props;
    const { page, continued, _totalPage, contentShow, txtSymbol } = this.state;

    return (
      <Fragment>
        <div className="d-flex justify-content-between align-items-center mt-3 mb-2">
          <span
            className="mr-auto ml-2 text-white"
            style={{ fontSize: '18px' }}
          >
            {`${t('txt-label-margin-list')}`}
          </span>
          <div className="d-flex mr-3">
            <input
              name="txtSymbol"
              type="search"
              className="form-control input-order text-uppercase w_195 ml-3 fz_14 ml-2 text-white"
              placeholder={t('txt-symbol-full')}
              value={txtSymbol}
              onChange={(e) => {
                this.setState({ txtSymbol: e.target.value }, () => {
                  this.handleQueryPage();
                });
              }}
              maxLength={10}
              autoComplete="off"
              style={{
                backgroundColor: '#1d1f2d',
                borderRadius: '25px',
                border: 0,
                paddingRight: '30px',
                backgroundImage: `url(${icMagnifier})`,
                backgroundPosition: '190px 5px',
                backgroundRepeat: 'no-repeat',
              }}
            />
          </div>
          {/* { TODO: SEARCH */
          /* <input type="search" className="pull-right ml-2" placeholder="Mã CK"/> */}
          {/* <FormMarginList /> */}
        </div>
        <div className="d-flex flex-column m-2">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th className="text-center">{t('txt-stock')}</th>
                <th className="text-center">
                  {t('transaction.marginList.symbolName')}
                </th>
                <th className="text-center">{t('txt-max-margin-rate')}</th>
                <th className="text-center">{t('txt-actions')}</th>
              </tr>
            </thead>
            <tbody>
              {contentShow &&
                !!contentShow.length &&
                contentShow.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td className="text-center">{item.symbol}</td>
                      <td className="text-left">{item.symbolNm}</td>
                      <td className="text-center">
                        {numberFormat((1 - item.marginRt) * 100, 2, '0')}%
                      </td>
                      <td className="text-center">
                        {auth && auth.isVerified ? (
                          <Fragment>
                            <button
                              className="btn fz_14 text-uppercase trade_side_1 cursor-pointer pr-2 pl-2 pt-0 pb-0"
                              onClick={() =>
                                this._handleTradeAction('BUY', item.symbol)
                              }
                            >
                              {t('txt-buy')}
                            </button>
                            <button
                              className="btn fz_14 text-uppercase trade_side_2 cursor-pointer pr-2 pl-2 pt-0 pb-0 ml-2"
                              onClick={() =>
                                this._handleTradeAction('SELL', item.symbol)
                              }
                            >
                              {t('txt-sell')}
                            </button>
                          </Fragment>
                        ) : (
                          <Button
                            variant="warning"
                            className="fz_10"
                            style={{ height: '20px', padding: '0 15px' }}
                            onClick={() => this._handleAuthen()}
                          >
                            {t('txt-xacthuc')}
                          </Button>
                        )}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          {(!marginList || !marginList.list.length) && (
            <p className="text-center fz_14 mt-2 title-time">
              {t('txt-no-data-found')}
            </p>
          )}
          {marginList && marginList.list.length > 0 && (
            <div className="d-flex flex-row-reverse align-items-center paging mt-3">
              <div className="d-flex align-items-center">
                <Button
                  onClick={() => this._handleNextPage(-1)}
                  disabled={page === 1}
                  className="d-flex align-items-center justify-content-center"
                >
                  {'<'}
                </Button>
                <Button
                  onClick={() => this._handleNextPage(1)}
                  disabled={!continued}
                  className="d-flex align-items-center justify-content-center"
                >
                  {'>'}
                </Button>
              </div>
              <span className="fz_14 text-white mr-2">{`${t(
                'txt-label-page'
              )} ${page + '/' + _totalPage}`}</span>
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

MarginList.contextType = WebSocketContext;

const makeMapStateToProps = () => {
  const getToken = makeGetToken();
  const getAuth = makeGetAuth();
  const getListAccount = makeGetListAccount();
  const getDefaultAccount = makeGetDefaultAccount();

  const getMarginList = makeGetMarginList();

  const mapStateToProps = (state) => {
    return {
      token: getToken(state),
      auth: getAuth(state),
      listAccount: getListAccount(state),
      defaultAccount: getDefaultAccount(state),
      setting: state.client.setting,
      marginList: getMarginList(state),
    };
  };
  return mapStateToProps;
};

export default connect(makeMapStateToProps)(
  translate('translations')(MarginList)
);
