import React, { useEffect, useState, useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import { ReactReduxContext, useDispatch, useSelector } from 'react-redux';
import PaginationTable from '../../../../components/PaginationTable';
import PerfectScrollBar from 'react-perfect-scrollbar';
import { withNamespaces } from 'react-i18next';
import PinefolioLevels from '../pinefolioLevels';
import {
  setOrder,
  resetThemeDetail,
  setCurrentPinefolio,
  openCreatePlan,
  getPfAnaRptRequest,
  openAccountPopup,
} from '../../action';
import PerformanceChart from './performance';
import {
  dragDiv,
  formatDate,
  getSymbolInfor,
  getTickerType,
  numberFormat,
  _formatDate2,
} from '../../../../util';
import BuyOrder from '../buyOrder';
import SellOrder from '../sellOrder';
import {
  setAuth,
  setAuthen,
  setLogin,
  setSymbolActive,
  setToast,
  unsetClient,
} from '../../../client/actions.js';
import { v4 as uuidv4 } from 'uuid';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import ViewReport from '../analysis/viewReport';
import CreateBondPlan from '../planManagement/component/createBondPlan';
import AnalysisReport from '../analysis';
import {
  Logout,
  logoutRequest,
} from '../../../../components/modal/login/actions';
import { removeCookie } from '../../../../lib/storages';
import { getMessage } from '../../../../lib/selector';
import { handleApiErrors } from '../../../../lib/api-errors';
import { Mixpanel } from '../../../../lib/mixpanel';
const appUrl = `${process.env.REACT_APP_API_URL}`;

const ProductDetail = withNamespaces('translations')(({ t }) => {
  const [show, setShow] = useState(false);
  const [tab, setTab] = useState(0);
  const [apiInterval, setApiInterval] = useState(true);

  const theme = useSelector((state) => state.client.currentTheme);
  const { pineThemeDetail, openCreatePlanData, pfOrder } = useSelector(
    (state) => state.pinefolio
  );

  const dispatch = useDispatch();
  useEffect(() => {
    if (pineThemeDetail) {
      setShow(true);
      setTab(0);
      if (pineThemeDetail.pfList && !!pineThemeDetail.pfList.length > 0) {
        dispatch(setCurrentPinefolio(pineThemeDetail.pfList[0]));
      }
    }
  }, [pineThemeDetail]);

  if (!pineThemeDetail) return '';

  return (
    <>
      <Modal
        show={show}
        dialogClassName="wts-modal"
        backdropClassName="pinefolio-theme-detail-backdrop"
        contentClassName={`${
          theme ? theme.name : 'dark'
        } pinefolio-theme-detail wts-modal-content wts-modal-content--lg2`}
        onEscapeKeyDown={() => {
          setShow(false);
          dispatch(resetThemeDetail());
          dispatch(setCurrentPinefolio(null));
        }}
        centered
        onHide={() => {
          setShow(false);
          dispatch(resetThemeDetail());
          dispatch(setCurrentPinefolio(null));
        }}
        style={{ zIndex: 2 }}
      >
        <div className="pinefolio-theme-detail__header cursor-pointer">
          <span className="text--xl text--light fw-500">
            {pineThemeDetail.thsNm}
          </span>
        </div>
        <div className="pinefolio-theme-detail__body h-100">
          <div className="theme-intro h-100">
            <div className="intro-tabs">
              <div
                className={`intro-tab text--sm ${
                  tab == 0 ? 'text--light active' : 'text--light3'
                }`}
                onClick={() => setTab(0)}
              >
                {t('txt-info')}
              </div>
              <div
                className={`intro-tab text--sm ${
                  tab == 1 ? 'text--light active' : 'text--light3'
                }`}
                onClick={() => setTab(1)}
              >
                {t('txt-analysis-report')}
              </div>
            </div>
            <div className="theme-intro__contents h-100">
              {tab == 0 && (
                <Infor
                  detail={pineThemeDetail}
                  setApiInterval={setApiInterval}
                  apiInterval={apiInterval}
                />
              )}
              {tab == 1 && <Report />}
            </div>
          </div>
        </div>
      </Modal>

      {pfOrder && pfOrder.orderType == 1 && (
        <BuyOrder callback={() => setApiInterval(true)} />
      )}
      {pfOrder && pfOrder.orderType == 2 && (
        <SellOrder callback={() => setApiInterval(true)} />
      )}
      {openCreatePlanData && openCreatePlanData.openModal && (
        <CreateBondPlan t={t} openCreatePlanData={openCreatePlanData} />
      )}
    </>
  );
});

const Infor = withNamespaces('translations')(
  ({ t, detail, setApiInterval, apiInterval }) => {
    const { token, auth } = useSelector((state) => state.client);
    const { hadPineAccount } = useSelector((state) => state.pinefolio);
    const [file, setFile] = useState('');

    const dispatch = useDispatch();
    const attachFileClick = (url) => {
      window.open(url);
    };

    const buyHandle = () => {
      if (token) {
        if (auth && auth.isVerified) {
          if (!hadPineAccount) {
            dispatch(openAccountPopup(true));
          } else {
            dispatch(
              setOrder({
                themeCode: detail.thsCode,
                orderType: 1,
              })
            );
            // setApiInterval(false);
          }
        } else {
          dispatch(setAuth());
        }
      } else {
        dispatch(setLogin());
      }
    };

    const sellHandle = () => {
      if (token) {
        if (auth && auth.isVerified) {
          if (!hadPineAccount) {
            dispatch(openAccountPopup(true));
          } else {
            dispatch(
              setOrder({
                themeCode: detail.thsCode,
                orderType: 2,
              })
            );
            // setApiInterval(false);
          }
        } else {
          dispatch(setAuth());
        }
      } else {
        dispatch(setLogin());
      }
    };

    const handleOpenCreatePlan = () => {
      if (token) {
        if (auth && auth.isVerified) {
          if (!hadPineAccount) {
            dispatch(openAccountPopup(true));
          } else {
            let data = {
              openModal: true,
            };
            dispatch(openCreatePlan(data));
          }
        } else {
          dispatch(setAuth());
        }
      } else {
        dispatch(setLogin());
      }
    };

    useEffect(() => {
      dragDiv('.drag-scroll');
    }, []);

    if (!detail) return <></>;
    return (
      <>
        <div className="theme-infors">
          <div className="theme-infors__desc text--light3 text--sm">
            <PerfectScrollBar style={{ maxHeight: '60px' }}>
              {detail.thsDesc}
            </PerfectScrollBar>
          </div>
          <div className="theme-infors__attachments drag-scroll">
            {detail.relatedFile &&
              !!detail.relatedFile.length > 0 &&
              detail.relatedFile.map((file, i) => (
                <div
                  key={i}
                  className="btn btn--primary2"
                  onClick={() =>
                    file.flFormatTp == 'pdf'
                      ? setFile(file)
                      : attachFileClick(file.flUrl)
                  }
                >
                  {file.flName}.{file.flFormatTp}
                </div>
              ))}
          </div>
          <div className="theme-infors__metrics">
            <div className="theme-infors__metric">
              <span className="text--sm text--gray">
                {t('txt-label-start-date')}
              </span>
              <span className="text--sm text--light fw-500">
                {_formatDate2(detail.startDt)}
              </span>
            </div>

            <div className="theme-infors__metric">
              <span className="text--sm text--gray">
                {t('txt-accumulation')}
              </span>
              <span className="text--sm text--light fw-500">
                {numberFormat(detail.totalBuy, 0, '0')}
              </span>
            </div>
          </div>
          <div className="theme-infors__pinefolios">
            <PF sources={detail.pfList} apiInterval={apiInterval} />
          </div>
        </div>
        <div className="pinefolio-actions">
          <div
            className="btn btn--primary2 pinefolio-action"
            onClick={() => handleOpenCreatePlan()}
          >
            {t('pinefolio.investPlan')}
          </div>

          <button
            className="btn btn--buy2 pinefolio-action"
            onClick={() => buyHandle()}
          >
            {t('txt-buy')}
          </button>

          <div
            className="btn btn--sell2 pinefolio-action"
            onClick={() => sellHandle()}
          >
            {t('txt-sell')}
          </div>
        </div>
        {file && file.flUrl && <ViewReport file={file} setFile={setFile} />}
      </>
    );
  }
);

const PF = withNamespaces('translations')(
  ({ t, sources, apiInterval = true }) => {
    const { currentPinefolio } = useSelector((state) => state.pinefolio);
    const { cwList } = useSelector((state) => state.priceBoard);
    const lang = useSelector((state) => state.client.setting.lang || 'vi');
    const { token } = useSelector((state) => state.client);

    const [showChart, setShowChart] = useState(false);
    const [animate, setAnimate] = useState(false);
    const { store } = useContext(ReactReduxContext);
    const [currentPF, setCurrentPF] = useState(currentPinefolio);
    let itv = null;

    const dispatch = useDispatch();
    const setCurrent = (current) => {
      if (current) {
        setCurrentPF(current);
        dispatch(setCurrentPinefolio(current));
        setAnimate(true);

        setTimeout(() => {
          setAnimate(false);
        }, 500);
      }
    };

    useEffect(() => {
      if (!apiInterval && itv) {
        clearInterval(itv);
      }
    }, [apiInterval]);

    useEffect(() => {
      if (!apiInterval) {
        if (itv) clearInterval(itv);
      } else {
        if (currentPinefolio) {
          getInforHandle(currentPinefolio);
          setCurrentPF(currentPinefolio);

          itv = setInterval(() => {
            getInforHandle(currentPinefolio);
          }, 5000);

          return () => {
            if (itv) clearInterval(itv);
          };
        }
      }
    }, [currentPinefolio, apiInterval]);

    const setColor = (a, b, min, max) => {
      if (a >= max) return 'c';
      if (a <= min) return 'f';
      if (a > b) return 'i';
      else if (a < b) return 'd';
      else return 'r';
    };

    const getInforHandle = (pf) => {
      if (token) {
        const uuid = uuidv4();
        const params = {
          group: 'CORE',
          cmd: 'getPFList',
          rqId: uuid,
          channel: 'WTS',
          data: {
            langTp: lang,
            pfCode: pf.pfCode,
          },
        };

        const url = `${appUrl}/PortfolioServlet.pt`;
        fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          },
          body: JSON.stringify(params),
        })
          .then(handleApiErrors)
          .then((response) => response.json())
          .then((res) => {
            if (res.http == 200) {
              // update currentPF
              const list = res.data?.list;
              const _pf = list[0];
              console.log('pf data ===> ', _pf);
              setCurrentPF(_pf);
            } else {
              if (res.http === 401) {
                if (token) dispatch(logoutRequest(token));
                dispatch(unsetClient());
                removeCookie('token');
                removeCookie('authen');
                dispatch(Logout());
                const toastMsg = {
                  id: Math.random(),
                  msg: t('txt-valid-loss-session'),
                  title: t('txt-notice'),
                };
                dispatch(setToast(toastMsg));
              } else {
                const msgErr = getMessage(res.data.message, store.getState);
                const toastMsg = {
                  id: Math.random(),
                  msg: msgErr,
                  title: t('txt-notice'),
                };
                dispatch(setToast(toastMsg));
              }
            }
          })
          .catch((error) => {
            console.error('Error:', error);
          });
      }
    };

    const openSymbolDetail = (symbol) => {
      Mixpanel.viewTicketInfo({
        Ticker: symbol,
        'Ticker Type': getTickerType(symbol, cwList),
        'Ticker Location': 'PF',
        'Ticker Location Detail': 'PF Detail',
      });
      dispatch(setSymbolActive(symbol));
    };

    if (!sources) return <></>;
    else
      return (
        <>
          <div className="pinefolio-navs">
            <PinefolioLevels
              sources={sources}
              onClickCallback={(current) => setCurrent(current)}
            />
          </div>
          <div className="pinefolio-overview d-flex flex-column">
            <div className="pinefolio-metrics">
              <div className="pinefolio-metric">
                <span className="d-flex  align-items-center">
                  <span className="text--sm text--light3">
                    {t('pinefolio.portfolio.volatility')}
                  </span>
                  <OverlayTrigger
                    trigger={['hover', 'focus']}
                    placement="top"
                    className="portfolio-overlay"
                    overlay={
                      <Tooltip style={{ zIndex: '99999' }}>
                        {t('pinefolio.theme.performanceExplain')}
                      </Tooltip>
                    }
                  >
                    <span className="icon iInfor iTooltip ml-1"></span>
                  </OverlayTrigger>
                </span>
                <div className="pinefolio-metric__performance">
                  <span
                    className={`text--md ${setColor(
                      currentPF?.currPrftPer ?? 0,
                      0,
                      -7,
                      7
                    )}
                `}
                  >
                    {currentPF?.currPrftPer} %
                  </span>
                  <div
                    className="btn btn--primary2 d-flex"
                    onClick={() => setShowChart(true)}
                  >
                    <span className="icon iPinefolioChart"></span>
                  </div>
                </div>
              </div>
              <div className="pinefolio-metric">
                <span className="text--sm text--light3">
                  {t('pinefolio.code')}
                </span>
                <span className="text--md text--light">
                  {currentPF?.pfCode}
                </span>
              </div>
              <div className="pinefolio-metric">
                <span className="text--sm text--light3">{t('txt-type')}</span>
                <span className="text--md">{currentPF?.pfTypeName}</span>
              </div>
              <div className="pinefolio-metric"></div>
            </div>
            <div className="pinefolio-metrics">
              <div className="pinefolio-metric">
                <span className="text--sm text--light3 d-flex align-items-center">
                  <span>{t('txt-label-market-value')}</span>
                  <OverlayTrigger
                    trigger={['hover', 'focus']}
                    placement="top"
                    className="portfolio-overlay"
                    overlay={
                      <Tooltip style={{ 'z-index': '99999' }}>
                        {t('pinefolio.theme.value.desc')}
                      </Tooltip>
                    }
                  >
                    <span className="icon iInfor iTooltip ml-1 mr-1"></span>
                  </OverlayTrigger>
                </span>
                <span
                  className={`text--md ${setColor(
                    currentPF?.currInvtAmt,
                    currentPF?.refValue,
                    currentPF?.flrVal,
                    currentPF?.celVal
                  )}`}
                >
                  {numberFormat(currentPF?.currInvtAmt, 0, '0')}
                </span>
              </div>
              <div className="pinefolio-metric justify-content-end">
                <span className="text--sm text--gray d-flex align-items-center">
                  <span className="text--sm text--light3"></span>
                </span>
                <div className="pinefolio-metric__performance">
                  <span className="text--md r">
                    {numberFormat(currentPF?.refValue, 0, '0')}
                  </span>
                </div>
              </div>
              <div className="pinefolio-metric justify-content-end">
                <span className="text--sm text--light3"> </span>
                <span className="text--md text--light">
                  <span className="text--md c">
                    {numberFormat(currentPF?.celVal, 0, '0')}
                  </span>
                </span>
              </div>
              <div className="pinefolio-metric justify-content-end">
                <span className="text--sm text--light3"></span>
                <span className="text--md f d-flex">
                  <span>{numberFormat(currentPF?.flrVal, 0, '0')}</span>
                  <span className="d-flex  align-items-center ml-4">
                    <OverlayTrigger
                      trigger={['hover', 'focus']}
                      placement="top"
                      className="portfolio-overlay"
                      overlay={
                        <Tooltip style={{ 'z-index': '99999' }}>
                          {t('pinefolio.order.referenceValues.desc')}
                        </Tooltip>
                      }
                    >
                      <span className="icon iInfor iTooltip ml-1"></span>
                    </OverlayTrigger>
                  </span>
                </span>
              </div>
            </div>
          </div>

          <div
            className={`pinefolio-allocations ${animate ? 'slide-right' : ''}`}
          >
            {currentPF?.symbols &&
              currentPF?.symbols.map((symbol, i) => (
                <div className="pinefolio-allocation" key={i}>
                  <OverlayTrigger
                    trigger={['hover', 'focus']}
                    placement="top"
                    className="portfolio-overlay"
                    overlay={
                      <Tooltip style={{ 'z-index': '99999' }}>
                        {symbol.symbolNm}
                      </Tooltip>
                    }
                  >
                    <span
                      className="text--sm text--light fw-500 cursor-pointer fit-content"
                      onClick={() => openSymbolDetail(symbol.symbol)}
                    >
                      {symbol.symbol}
                    </span>
                  </OverlayTrigger>

                  <span className="text--sm text--gray">{symbol.potn}%</span>
                </div>
              ))}
          </div>
          <span className="fw-400 fz_12 text--gray font-italic mt-3">
            {`${t('pinefolio.theme.allocationRate.desc')}`}
          </span>

          {showChart && (
            <PerformanceChart
              closeCallback={() => setShowChart(false)}
              state={showChart}
              sources={sources}
            />
          )}
        </>
      );
  }
);

const Report = withNamespaces('translations')(({ t }) => {
  const { pineThemeDetail, pfOrder, pfAnaRpt } = useSelector(
    (state) => state.pinefolio
  );
  const { token, auth } = useSelector((state) => state.client);
  const lang = useSelector((state) => state.client?.setting?.lang || 'vi');
  const { defaultAccount } = useSelector((state) => state.socket);
  const [file, setFile] = useState('');

  console.log('pfAnaRpt: ', pfAnaRpt);
  const dispatch = useDispatch();

  useEffect(() => {
    if (pineThemeDetail) {
      getPfAnaRpt();
    }
  }, [pineThemeDetail]);

  const getPfAnaRpt = () => {
    if (!token) return;
    const uuid = uuidv4();
    const params = {
      group: 'CORE',
      user: token.user,
      session: token.session,
      cmd: 'getPFAnaRpt',
      rqId: uuid,
      channel: 'WTS',
      data: {
        cif: token.cif,
        acntNo: defaultAccount?.acntNo,
        subAcntNo: defaultAccount?.subAcntNo,
        langTp: lang,
        pfThemeCd: pineThemeDetail?.thsCode,
        pfCode: '',
      },
    };

    dispatch(getPfAnaRptRequest(params));
  };

  return (
    <div className="theme-reports">
      <AnalysisReport />
    </div>
  );
});

export default ProductDetail;
