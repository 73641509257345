import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import ChooseSellType from './chooseSellType';
import PerfectScrollBar from 'react-perfect-scrollbar';
import { useHistory } from 'react-router';
import {
  numberFormat,
  _formatDate2,
  mapRightType,
  StringToDouble,
  StringToInt,
  getTickerType,
  getSymbolInfor,
} from '../../../util';
import { v4 as uuidv4 } from 'uuid';
import {
  getBondPortfolioRequest,
  getListPositionAllRequest,
  getInquiryRightListAllRequest,
} from '../action';

import { translate } from 'react-i18next';
import PaginationTable from '../../../components/PaginationTable';
import { setAuth, setDblPrice, setLogin } from '../../client/actions';
import { isEmpty, sumBy } from 'lodash';
import { MixConstants, Mixpanel } from '../../../lib/mixpanel';
import { getDvxCurrPosRequest } from '../../derivative/actions';
import { constants } from '../../../util/constant';
import { getBondCommonRequest } from '../../bond/action';
const Portfolio = withNamespaces('translations')(
  ({ t, cashBalance, refresh, account, tabActive }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { token, auth } = useSelector((state) => state.client);
    const { defaultAccount } = useSelector((state) => state.socket);
    const positions = useSelector((state) => state.personal.listPositionAll);
    const bondPortfolio = useSelector((state) => state.personal.bondPortfolio);
    const inquiryRightList = useSelector(
      (state) => state.personal.inquiryRightList.list
    );
    const { cwList, allStock } = useSelector((state) => state.priceBoard);
    const { dvxCurrPos } = useSelector((state) => state.derivative);
    const [showSellType, setShowSellType] = useState(false);
    const [pineBFPortfolio, setPineBFPortfolio] = useState([]); // portfolio for bond fix
    const [pineBVPortfolio, setPineBVPortfolio] = useState([]); // portfolio for bond var
    const [pineBGPortfolio, setPineBGPortfolio] = useState([]); // portfolio for bond growth
    const lang = useSelector((state) => state.client.setting.lang || 'vi');

    const getListPositionAll = (subAcntNo = '') => {
      if (!token) return;

      const accountNo = token.custInfo.normal[0].acntNo;
      const uuid = uuidv4();
      const params = {
        group: 'CORE',
        user: token.user,
        session: token.session,
        cmd: 'getPositionsAll',
        rqId: uuid,
        channel: 'WTS',
        data: {
          acntNo: !isEmpty(account) ? account.acntNo : accountNo,
          subAcntNo: subAcntNo,
          getBondQty: 'Y',
          AorN: 'S',
        },
      };

      dispatch(getListPositionAllRequest(params));
    };

    const getBondPortfolio = (subAcntNo = '') => {
      if (!token) return;
      const accountNo = token.custInfo.normal[0].acntNo;
      const uuid = uuidv4();
      const params = {
        group: 'CORE',
        user: token.user,
        session: token.session,
        cmd: 'getBondPortfolio',
        rqId: uuid,
        channel: 'WTS',
        data: {
          acntNo: !isEmpty(account) ? account.acntNo : accountNo,
          subAcntNo: subAcntNo,
          symbol: '',
          listTp: '2',
          langTp: lang,
        },
      };
      dispatch(getBondPortfolioRequest(params));
    };

    const getListInquiryRightAll = (subAcntNo = '') => {
      if (!token) return;
      const accountNo = token.custInfo.normal[0].acntNo;
      const uuid = uuidv4();
      const params = {
        group: 'CORE',
        user: token.user,
        session: token.session,
        cmd: 'getRightListAll',
        rqId: uuid,
        channel: 'WTS',
        data: {
          acntNo: !isEmpty(account) ? account.acntNo : accountNo,
          subAcntNo: subAcntNo,
        },
      };
      dispatch(getInquiryRightListAllRequest(params));
    };

    const getPineBPorfolio = (
      prdType = constants.bondProductType.Var,
      subAcntNo = ''
    ) => {
      if (!token) return;
      const accountNo = token.custInfo.normal[0].acntNo;
      const uuid = uuidv4();
      const params = {
        group: 'CORE',
        user: token.user,
        session: token.session,
        cmd: 'getBondPortfolio',
        rqId: uuid,
        channel: 'WTS',
        data: {
          acntNo: !isEmpty(account) ? account.acntNo : accountNo,
          subAcntNo: subAcntNo,
          prodTp: prdType,
          langTp: lang,
        },
      };

      const callback = (data) => {
        if (prdType == constants.bondProductType.Fix) {
          const _data = [
            ...data,
            {
              symbol: 'TOTAL',
              totBuyAmt: sumBy(data, (x) => x.investedAmount),
              totCurAmt: sumBy(data, (x) => x.currentAmount),
            },
          ];

          setPineBFPortfolio(_data);
        }

        if (prdType == constants.bondProductType.Var) {
          const _data = [
            ...data,
            {
              symbol: 'TOTAL',
              totBuyAmt: sumBy(data, (x) => x.investedAmount),
              totCurAmt: sumBy(data, (x) => x.currentAmount),
            },
          ];

          setPineBVPortfolio(_data);
        }

        if (prdType == constants.bondProductType.Growth) {
          const _data = [
            ...data,
            {
              symbol: 'TOTAL',
              totBuyAmt: sumBy(data, (x) => x.investedAmount),
              totCurAmt: sumBy(data, (x) => x.currentAmount),
            },
          ];

          setPineBGPortfolio(_data);
        }
      };

      dispatch(getBondCommonRequest(params, callback));
    };

    const getDvxCurrPos = (subAcntNo = '') => {
      if (token && defaultAccount) {
        let params = {
          group: 'CORE',
          cmd: 'getDvxCurrPos',
          user: token.user,
          session: token.session,
          channel: 'WTS',
          data: {
            cif: token.cif,
            acntNo: defaultAccount?.acntNo,
            subAcntNo: subAcntNo,
          },
        };

        dispatch(getDvxCurrPosRequest(params));
      }
    };

    useEffect(() => {
      if (!isEmpty(account) && account.subAcntNo) {
        getListPositionAll(account.subAcntNo);
        getBondPortfolio(account.subAcntNo);
        getListInquiryRightAll(account.subAcntNo);
        getPineBPorfolio(constants.bondProductType.Fix, account.subAcntNo);
        getPineBPorfolio(constants.bondProductType.Var, account.subAcntNo);
        getPineBPorfolio(constants.bondProductType.Growth, account.subAcntNo);
        getDvxCurrPos(account.subAcntNo);
      } else {
        getListInquiryRightAll();
        getListPositionAll();
        getBondPortfolio();
        getPineBPorfolio(constants.bondProductType.Fix);
        getPineBPorfolio(constants.bondProductType.Var);
        getPineBPorfolio(constants.bondProductType.Growth);
        getDvxCurrPos();
      }
    }, [refresh, lang, account]);

    const handleQuickOrder = (record) => {
      const newOrder = {
        symbol: record.symbol,
        side: 'SELL',
        price: '',
      };

      Mixpanel.order({
        Ticker: record.symbol,
        'Order Side': 'SELL',
        'Order Type': 'Normal Order',
        'Ticker Type': getTickerType(record.symbol, cwList),
        'Ticker Description': getSymbolInfor(record.symbol, allStock)?.name_vn,
        Location: MixConstants.LOCATIONS.ASSET_PORTFOLIO,
      });
      dispatch(setDblPrice(newOrder));
    };

    const handleSellBond = (record) => {
      history.push({
        pathname: '/home/bond',
        state: { tabActive: 2, symbol: record.symbol },
      });
    };

    const handleSell = (record) => {
      if (!token) {
        dispatch(setLogin());
        return false;
      }
      if (!auth || !auth.isVerified) {
        dispatch(setAuth());
        return false;
      }
      if (record.bndSelAvlQty > 0) {
        setShowSellType(record);
      } else {
        handleQuickOrder(record);
      }
    };

    const headerColumn = [
      {
        key: 'symbol',
        text: t('txt-symbol'),
        className: (record) => {
          return (
            'text-center fw-500 cursor-pointer ' +
            (record.gainLoss > 0 ? 'i' : record.gainLoss < 0 ? 'd' : 'r')
          );
        },
        cell: (record) => {
          return (
            <>
              <span
                onDoubleClick={() => {
                  handleSell(record);
                }}
              >
                {record.symbol}
              </span>
            </>
          );
        },
        rowSpan: '2',
        sortable: true,
      },
      {
        key: 'balQty',
        text: t('txt-total-vol'),
        className: 'text-right text--light',
        cell: (record) => {
          return numberFormat(record.balQty, 0, '0');
        },
        rowSpan: '2',
        sortable: true,
      },
      {
        key: 'trdAvailQty',
        text: t('txt-label-avail'),
        className: 'text-right text--light',
        cell: (record) => {
          return numberFormat(record.trdAvailQty, 0, '0');
        },
        rowSpan: '2',
        sortable: true,
      },
      {
        key: 'devidendQty',
        text: t('txt-total-dividend-vol'),
        className: 'text-right position-relative text--light',
        cell: (record) => {
          return numberFormat(record.devidendQty, 0, '0');
        },
        rowSpan: '2',
        sortable: true,
      },
      {
        key: 'sellTn',
        text: t('txt-label-pending-delivery'),
        className: 'text-right position-relative text--light',
        cell: (record) => {
          return numberFormat(record.sellTn, 0, '0');
        },
        rowSpan: '2',
        sortable: true,
      },
      {
        key: 'waitTrdQty',
        text: t('txt-label-pending-trading'),
        className: 'text-right position-relative text--light',
        cell: (record) => {
          return numberFormat(record.waitTrdQty, 0, '0');
        },
        rowSpan: '2',
        sortable: true,
      },
      {
        key: 'buyT2',
        text: `${t('txt-buy')} T2`,
        className: 'text-right position-relative text--light',
        cell: (record) => {
          return numberFormat(record.buyT2, 0, '0');
        },
        rowSpan: '2',
        sortable: true,
      },
      {
        key: 'buyT1',
        text: `${t('txt-buy')} T1`,
        className: 'text-right position-relative text--light',
        cell: (record) => {
          return numberFormat(record.buyT1, 0, '0');
        },
        rowSpan: '2',
        sortable: true,
      },
      {
        key: 'buyT0',
        text: `${t('txt-buy')} T0`,
        className: 'text-right position-relative text--light',
        cell: (record) => {
          return numberFormat(record.buyT0, 0, '0');
        },
        rowSpan: '2',
        sortable: true,
      },
      {
        key: 'avgPrice',
        text: t('txt-ave'),
        className: 'text-right position-relative text--light fw-500',
        cell: (record) => {
          return numberFormat(record.avgPrice, 3, '0');
        },
        rowSpan: '2',
        sortable: true,
      },
      {
        key: 'lastPrice',
        text: t('txt-market-price'),
        className: 'text-right position-relative text--light fw-500 ',
        cell: (record) => {
          return numberFormat(record.lastPrice, 2, '0');
        },
        rowSpan: '2',
        sortable: true,
      },
      {
        key: 'change',
        text: t('txt-change'),
        className: (record) => {
          return (
            'text-right ' +
            (StringToDouble(record.change) > 0
              ? 'i'
              : StringToDouble(record.change) < 0
              ? 'd'
              : 'r')
          );
        },
        cell: (record) => {
          return numberFormat(record.change / 1000, 2, '0');
        },
        sortable: true,
        rowSpan: 2,
      },

      {
        key: 'changePC',
        text: t('txt-change-per'),
        className: (record) => {
          return (
            'text-right fw-500 ' +
            (StringToDouble(record.changePC) > 0
              ? 'i'
              : StringToDouble(record.changePC) < 0
              ? 'd'
              : 'r')
          );
        },
        cell: (record) => {
          return numberFormat(record.changePC, 2, '0');
        },
        sortable: true,
        rowSpan: 2,
      },
      {
        key: 'totBuyAmt',
        text: t('txt-label-buy-amt'),
        className: 'text-right text--light fw-500 ',
        cell: (record) => {
          return numberFormat(record.totBuyAmt, 0, '0');
        },
        sortable: true,
      },
      {
        key: 'totCurAmt',
        text: t('txt-label-market-value'),
        className: 'text-right text--light fw-500 ',
        cell: (record) => {
          return numberFormat(record.totCurAmt, 0, '0');
        },
        sortable: true,
      },
      {
        key: 'gainLoss',
        text: t('txt-gain-loss'),
        className: (record) => {
          return (
            'text-right fw-500 ' +
            (record.gainLoss > 0 ? 'i' : record.gainLoss < 0 ? 'd' : 'r')
          );
        },
        cell: (record) => {
          return numberFormat(record.gainLoss, 0, '0');
        },
        sortable: true,
      },
      {
        key: 'gainLossPc',
        text: `% ${t('txt-gain-loss')}`,
        className: (record) => {
          return (
            'text-right fw-500 ' +
            (record.gainLoss > 0 ? 'i' : record.gainLoss < 0 ? 'd' : 'r')
          );
        },
        cell: (record) => {
          return numberFormat(record.gainLossPc, 2, '-') + '%';
        },
        sortable: true,
      },
    ];

    const totalColumns = [
      {
        key: 'totCurAmt',
        className: 'text-right fw-500',
        cell: ({ hideOddSymbol, _totalShow, _totalOddLot }) => {
          return numberFormat(
            !hideOddSymbol
              ? _totalShow.totBuyAmt
              : _totalShow.totBuyAmt - _totalOddLot.totBuyAmt,
            0,
            '0'
          );
        },
        rowSpan: '1',
      },
      {
        key: 'totBuyAmt',
        className: 'text-right fw-500',
        cell: ({ hideOddSymbol, _totalShow, _totalOddLot }) => {
          return numberFormat(
            !hideOddSymbol
              ? _totalShow.totCurAmt
              : _totalShow.totCurAmt - _totalOddLot.totCurAmt,
            0,
            '0'
          );
        },
        rowSpan: '1',
      },
      {
        key: 'gainLoss',
        className: ({ hideOddSymbol, _totalShow, _totalOddLot }) => {
          return (
            'text-right high-light fw-500 ' +
            ((!hideOddSymbol
              ? _totalShow.gainLoss
              : _totalShow.gainLoss -
                (_totalOddLot.totCurAmt - _totalOddLot.totBuyAmt)) > 0
              ? 'i'
              : (!hideOddSymbol
                  ? _totalShow.gainLoss
                  : _totalShow.gainLoss -
                    (_totalOddLot.totCurAmt - _totalOddLot.totBuyAmt)) < 0
              ? 'd'
              : 'r')
          );
        },
        cell: ({ hideOddSymbol, _totalShow, _totalOddLot }) => {
          return numberFormat(
            !hideOddSymbol
              ? _totalShow.gainLoss
              : _totalShow.gainLoss -
                  (_totalOddLot.totCurAmt - _totalOddLot.totBuyAmt),
            0,
            '0'
          );
        },
        rowSpan: '1',
      },
      {
        key: 'gainLossPc',
        className: ({ hideOddSymbol, _totalShow, _totalOddLot }) => {
          return (
            'text-right high-light fw-500 ' +
            ((!hideOddSymbol
              ? _totalShow.gainLoss
              : _totalShow.gainLoss -
                (_totalOddLot.totCurAmt - _totalOddLot.totBuyAmt)) > 0
              ? 'i'
              : (!hideOddSymbol
                  ? _totalShow.gainLoss
                  : _totalShow.gainLoss -
                    (_totalOddLot.totCurAmt - _totalOddLot.totBuyAmt)) < 0
              ? 'd'
              : 'r')
          );
        },
        cell: ({ hideOddSymbol, _totalShow, _totalOddLot }) => {
          return (
            numberFormat(
              !hideOddSymbol
                ? _totalShow.gainLossPc
                : ((_totalShow.gainLoss -
                    (_totalOddLot.totCurAmt - _totalOddLot.totBuyAmt)) *
                    100) /
                    _totalShow.totBuyAmt,
              2,
              '-'
            ) + '%'
          );
        },
        rowSpan: '1',
      },
    ];

    const bondHeaderColumns = [
      {
        key: 'subAcntNo',
        text: t('txt-sub-account'),
        className: 'text-center text--light fw-500',
      },
      {
        key: 'bondCode',
        text: t('txt-label-bond-code'),
        className: 'text-center text--light fw-500',
      },
      {
        key: 'balQty',
        text: t('txt-label-available-quantity'),
        className: 'text-right position-relative i',
        cell: (record) => {
          return numberFormat(record.balQty, 0, '0');
        },
      },
      {
        key: 'rcvBondQty',
        text: t('txt-label-receiving-quantity'),
        className: 'text-right position-relative r',
        cell: (record) => {
          return numberFormat(record.rcvBondQty, 0, '0');
        },
      },
      {
        key: 'blockQty',
        text: t('txt-label-block-quantity'),
        className: 'text-right position-relative d',
        cell: (record) => {
          return numberFormat(record.blockQty, 0, '0');
        },
      },
      {
        key: 'totalQty',
        text: t('txt-label-total-quantity'),
        className: 'text-right text--light fw-500',
        cell: (record) => {
          return numberFormat(record.totalQty, 0, '0');
        },
      },
      {
        key: 'investedAmount',
        text: t('txt-label-buy-value-total'),
        className: 'text-right text--light fw-500',
        cell: (record) => {
          return numberFormat(record.investedAmount, 0, '0');
        },
      },
      {
        key: 'currentAmount',
        text: t('txt-label-total-now'),
        className: 'text-right text--light fw-500',
        cell: (record) => {
          return numberFormat(record.currentAmount, 0, '0');
        },
      },
    ];

    const totalPineBColumns = [
      {
        className: ({ hideOddSymbol, _totalShow, _totalOddLot }) => {
          return `text-right fw-500 ${
            _totalShow?.totCurAmt > 0
              ? 'i'
              : _totalShow?.totCurAmt == 0
              ? 'r'
              : 'd'
          }`;
        },
        cell: ({ hideOddSymbol, _totalShow, _totalOddLot }) => {
          return numberFormat(_totalShow?.totCurAmt, 0);
        },
        rowSpan: '1',
      },
    ];

    const pineBFColumns = [
      {
        key: 'bondCode',
        text: t('txt-label-bond-code'),
        className: 'text-center text--light fw-500',
        rowSpan: '2',
        style: { width: '10%' },
        sortable: true,
      },
      {
        key: 'issuerNm',
        align: 'left',
        text: t('bond.issuer'),
        className: 'text-left text--light fw-500',
        rowSpan: '2',
        style: { width: '30%' },
        sortable: true,
      },
      {
        key: 'totalQty',
        align: 'right',
        text: t('txt-label-total-quantity'),
        className: 'text--light text-right position-relative',
        cell: (record) => {
          return numberFormat(record.totalQty, 0, '0');
        },
        rowSpan: '2',
        sortable: true,
        style: { width: '20%' },
      },
      {
        key: 'investedAmount',
        align: 'right',
        text: t('bond.totalValue.buy'),
        className: 'text--light text-right position-relative',
        cell: (record) => {
          return numberFormat(record.investedAmount, 0, '0');
        },
        rowSpan: '2',
        sortable: true,
        style: { width: '20%' },
      },
      {
        key: 'currentAmount',
        align: 'right',
        text: t('bond.totalProvisionalValue'),
        className: 'text-right position-relative r',
        cell: (record) => {
          return numberFormat(record.currentAmount, 0, '0');
        },
        sortable: true,
        style: { width: '20%' },
      },
    ];

    const pineBVColumns = [...pineBFColumns].map((item) => {
      if (item.key == 'investedAmount') {
        return {
          ...item,
          key: 'selAvgPrc',
          text: t('bond.selAvgPrc'),
          cell: (record) => {
            return numberFormat(record.selAvgPrc, 0, '0');
          },
        };
      }

      if (item.key == 'currentAmount')
        return { ...item, text: t('txt-total-value') };

      return item;
    });

    const pineBGColumns = pineBVColumns;

    const rightListHeaderColumn = [
      {
        key: 'symbol',
        text: t('txt-symbol'),
        className: 'text-center text--light fw-500',
        align: 'center',
        sortable: true,
      },
      {
        key: 'rghtSubTp',
        text: t('txt-type'),
        className: 'text-center text--light fw-500',
        align: 'center',
        cell: (record) => {
          return mapRightType(record.rghtSubTp);
        },
        sortable: true,
      },
      {
        key: 'basdt',
        text: t('txt-label-expected-date'),
        className: 'text-center text--light3 fw-500',
        align: 'center',
        cell: (record) => {
          return _formatDate2(record.basdt);
        },
        sortable: true,
      },
      {
        key: 'cashDate',
        text: t('txt-label-registration-date'),
        className: 'text-center text--light3 fw-500',
        align: 'center',
        cell: (record) => {
          if (record.rghtSubTp === '01') {
            if (record.dpstStockQty > 0) return _formatDate2(record.cashDate);

            if (record.dpstCashAmt > 0) return _formatDate2(record.cashDate);
          }

          return _formatDate2(record.stockDate);
        },
        sortable: true,
      },
      {
        key: 'balanceQuantity',
        text: t('txt-label-quantity-record-date'),
        className: 'text-right text--light fw-500',
        align: 'center',
        cell: (record) => {
          return numberFormat(record.balanceQuantity, 0, '0');
        },
        sortable: true,
      },
      {
        key: 'rightRate',
        text: t('txt-label-ratio'),
        className: 'text-right text--light fw-500',
        align: 'center',
        cell: (record) => {
          if (record.rghtSubTp === '01') {
            return numberFormat(StringToDouble(record.rightRate), 2, '0') + '%';
          }
          return record.rightRate;
        },
        sortable: true,
      },
      {
        key: 'dpstStockQty',
        text: t('txt-stock-waiting'),
        className: 'text-right text--light fw-500',
        align: 'center',
        cell: (record) => {
          return numberFormat(record.dpstStockQty, 0, '0');
        },
        sortable: true,
      },
      {
        key: 'dpstCashAmt',
        text: t('txt-asset-collateral'),
        className: 'text-right text--light fw-500',
        align: 'center',
        cell: (record) => {
          return numberFormat(record.dpstCashAmt, 0, '0');
        },
        sortable: true,
      },
    ];

    return (
      <>
        <div className="personal-assets card-panel-2">
          <PerfectScrollBar style={{ height: '100%' }}>
            <div className="personal-assets-body card-panel-2-body">
              <div className="section-overview">
                <div className="overview-metric">
                  <span className="overview-metric__key">
                    {t('personal.asset.totalOverview.totalAsset')}
                  </span>
                  <span className="overview-metric__value">
                    {cashBalance && numberFormat(cashBalance.totAsst, 0, '0')}
                  </span>
                </div>
                <div className="overview-metric">
                  <span className="overview-metric__key">
                    {t('personal.asset.totalOverview.netAssetValue')}
                  </span>
                  <span className="overview-metric__value">
                    {cashBalance && numberFormat(cashBalance.realAsst, 0, '0')}
                  </span>
                </div>
                <div className="overview-metric">
                  <span className="overview-metric__key">
                    {t('personal.asset.totalOverview.withdrawable')}
                  </span>
                  <span className="overview-metric__value">
                    {cashBalance &&
                      numberFormat(cashBalance.wdrawAvail, 0, '0')}
                  </span>
                </div>
                <div className="overview-metric">
                  <span className="overview-metric__key">
                    {t('personal.asset.totalOverview.availableAdvance')}
                  </span>
                  <span className="overview-metric__value">
                    {cashBalance &&
                      numberFormat(cashBalance.advanceAvail, 0, '0')}
                  </span>
                </div>
                <div className="overview-metric">
                  <span className="overview-metric__key">
                    {t('personal.asset.totalOverview.marginDebt')}
                  </span>
                  <span className="overview-metric__value">
                    {cashBalance && numberFormat(cashBalance.mgDebt, 0, '0')}
                  </span>
                </div>
                {tabActive != 0 && (
                  <>
                    <div className="overview-metric">
                      <span className="overview-metric__key">
                        {t('personal.asset.totalOverview.rTT')}
                      </span>
                      <span className="overview-metric__value">
                        {cashBalance &&
                          numberFormat(cashBalance.marginRatio, 2, '0') + '%'}
                      </span>
                    </div>
                    <div className="overview-metric">
                      <span className="overview-metric__key">
                        {t('personal.asset.totalOverview.pP')}
                      </span>
                      <span className="overview-metric__value">
                        {cashBalance && numberFormat(cashBalance.pp0, 0, '0')}
                      </span>
                    </div>
                  </>
                )}
              </div>
              <div className="section section-portfolio">
                <PaginationTable
                  t
                  pageSize={15}
                  columns={headerColumn}
                  source={positions ? positions : []}
                  hasPaging
                  hasSearch
                  hasSwitch
                  searchField="symbol"
                  title={t('txt-label-listed-security')}
                  totalColumns={totalColumns}
                  hasSort="true"
                  action={handleQuickOrder}
                />
              </div>
              {dvxCurrPos && dvxCurrPos.length > 0 && (
                <div className="section section-derivative">
                  <DerivativePosition data={dvxCurrPos} />
                </div>
              )}

              <div className="section section-bond">
                <PaginationTable
                  t
                  pageSize={15}
                  columns={bondHeaderColumns}
                  source={bondPortfolio ? bondPortfolio : []}
                  hasPaging
                  hasSearch
                  searchField="bondCode"
                  title={t('txt-label-bond-otc')}
                />
              </div>
              <div className="section section-bond">
                <PaginationTable
                  t
                  pageSize={15}
                  columns={pineBFColumns}
                  source={pineBFPortfolio}
                  hasPaging
                  hasSearch
                  searchField="bondCode"
                  title="PineB Fix"
                  totalColumns={totalPineBColumns}
                />
              </div>
              <div className="section section-bond">
                <PaginationTable
                  t
                  pageSize={15}
                  columns={pineBVColumns}
                  source={pineBVPortfolio}
                  hasPaging
                  hasSearch
                  searchField="bondCode"
                  title="PineB Flex"
                  totalColumns={totalPineBColumns}
                />
              </div>
              <div className="section section-bond">
                <PaginationTable
                  t
                  pageSize={15}
                  columns={pineBGColumns}
                  source={pineBGPortfolio}
                  hasPaging
                  hasSearch
                  searchField="bondCode"
                  title="PineB Growth"
                  totalColumns={totalPineBColumns}
                />
              </div>
              <div className="section section-right">
                <PaginationTable
                  t
                  pageSize={15}
                  hasPaging
                  hasSearch
                  searchField="symbol"
                  columns={rightListHeaderColumn}
                  source={inquiryRightList ? inquiryRightList : []}
                  title={t('txt-label-right')}
                />
              </div>
              <ChooseSellType
                setShowSellType={setShowSellType}
                state={showSellType}
                handleQuickOrder={handleQuickOrder}
                handleSellBond={handleSellBond}
              />
            </div>
          </PerfectScrollBar>
        </div>
      </>
    );
  }
);

const DerivativePosition = withNamespaces('translations')(({ t, data }) => {
  const source = data
    ? [
        ...data,
        {
          symbol: 'TOTAL',
          pnl: sumBy(data, (x) => x.pnl),
          roi:
            sumBy(data, (x) => x.openQty * x.entPrc) > 0
              ? sumBy(data, (x) => x.pnl) /
                sumBy(data, (x) => x.openQty * x.entPrc * 1000)
              : 0,
        },
      ]
    : [];

  const columns = [
    {
      key: 'symbol',
      text: t('derivative.label.contractCode'),
      className: (record) => {
        return (
          'text-center fw-500 cursor-pointer ' +
          (record.pnl > 0 ? 'i' : record.pnl < 0 ? 'd' : 'r')
        );
      },
      cell: (record) => {
        return (
          <>
            <span
              onDoubleClick={() => {
                handleSell(record);
              }}
            >
              {record.symbol}
            </span>
          </>
        );
      },
      rowSpan: '2',
      sortable: true,
    },
    {
      key: 'longShortTp',
      text: t('txt-label-position'),
      className: (record) => {
        return `text-right ${record.longShortTp == 1 ? 'i' : 'd'}`;
      },
      cell: (record) => {
        return record.longShortTp == 1 ? 'Long' : 'Short';
      },
      rowSpan: '2',
      sortable: true,
    },
    {
      key: 'openQty',
      text: t('derivative.position.openQuantity'),
      className: 'text-right text--light',
      cell: (record) => {
        return numberFormat(record.openQty, 0, 0);
      },
      rowSpan: '2',
      sortable: true,
    },
    {
      key: 'longOrdrQty',
      text: t('derivative.position.longPending'),
      className: 'text-right position-relative text--light',
      cell: (record) => {
        return numberFormat(record.longOrdrQty, 0, 0);
      },
      rowSpan: '2',
      sortable: true,
    },
    {
      key: 'shortOrdrQty',
      text: t('derivative.position.shortPending'),
      className: 'text-right position-relative text--light',
      cell: (record) => {
        return numberFormat(record.shortOrdrQty, 0, 0);
      },
      rowSpan: '2',
      sortable: true,
    },
    {
      key: 'entPrc',
      text: t('txt-price-ave'),
      className: 'text-right position-relative r',
      cell: (record) => {
        return numberFormat(record.entPrc, 2, 0);
      },
      rowSpan: '2',
      sortable: true,
    },
    {
      key: 'mktPrc',
      text: t('derivative.position.marketPrice'),
      className: 'text-right position-relative text--light',
      cell: (record) => {
        return numberFormat(record.mktPrc, 1, 0);
      },
      rowSpan: '2',
      sortable: true,
    },
    {
      key: 'pnl',
      text: t('derivative.position.profitPerLost'),
      className: (record) =>
        `text-right position-relative ${
          record.pnl > 0 ? 'i' : record.pnl < 0 ? 'd' : 'r'
        }`,
      cell: (record) => {
        return numberFormat(record.pnl, 0, 0);
      },
      rowSpan: '1',
      sortable: true,
    },
    {
      key: 'roi',
      text: '% ' + t('derivative.position.profitPerLost'),
      className: (record) =>
        `text-right position-relative ${
          record.roi > 0 ? 'i' : record.roi < 0 ? 'd' : 'r'
        }`,
      cell: (record) => {
        return numberFormat(record.roi, 2, 0) + '%';
      },
      sortable: true,
      rowSpan: '1',
    },
  ];

  const totalColumns = [
    {
      key: 'pnl',
      className: ({ hideOddSymbol, _totalShow, _totalOddLot }) => {
        return `text-right high-light fw-500 ${
          _totalShow.pnl > 0 ? 'i' : _totalShow.pnl < 0 ? 'd' : 'r'
        }`;
      },
      cell: ({ hideOddSymbol, _totalShow, _totalOddLot }) => {
        return numberFormat(_totalShow.pnl, 0, 0);
      },
      rowSpan: '1',
    },
    {
      key: 'roi',
      className: ({ hideOddSymbol, _totalShow, _totalOddLot }) => {
        return `text-right high-light fw-500 ${
          _totalShow.roi > 0 ? 'i' : _totalShow.roi < 0 ? 'd' : 'r'
        }`;
      },
      cell: ({ hideOddSymbol, _totalShow, _totalOddLot }) => {
        return numberFormat(_totalShow.roi, 2, '-') + '%';
      },
      rowSpan: '1',
    },
  ];

  const handleDerivativeOrder = () => {};
  const handleSell = () => {};

  if (!data) return <></>;

  return (
    <PaginationTable
      t
      pageSize={5}
      columns={columns}
      source={source}
      hasPaging
      hasSearch={false}
      hasSwitch={false}
      title={t('derivative.name')}
      hasSort="true"
      action={handleDerivativeOrder}
      totalColumns={totalColumns}
    />
  );
});

export default translate('translations')(Portfolio);
