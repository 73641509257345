import React from 'react';
import { Button } from 'react-bootstrap';
import { withNamespaces } from 'react-i18next';
import { translate } from 'react-i18next';
import { PagingSize } from '../../PagingSize';
import { useEffect, useState } from 'react';
import './pagingCustom.scss';

const PagingCustom = withNamespaces('translations')(
  ({
    handleNextPage,
    continued,
    handlePrevPage,
    page,
    totalPage,
    t,
    pageSizeOption,
    _handleSetPageSize,
    pageSizeOptions,
    hasPageSize = true,
  }) => {
    return (
      <div className="d-flex flex-row-reverse align-items-center paging-custom mt-2">
        <div className="d-flex align-items-center">
          {hasPageSize && (
            <PagingSize
              classNamePrefix="pagingSize-control-select"
              t
              getOptionLabel={(option) =>
                option.label !== 'txt-all'
                  ? option.label + '/' + t('txt-label-page')
                  : t(option.label)
              }
              value={pageSizeOption}
              onChange={_handleSetPageSize}
              options={pageSizeOptions}
            />
          )}
          <Button
            onClick={handlePrevPage}
            disabled={page === 1}
            className="d-flex align-items-center justify-content-center button-paging"
          >
            <span
              className={`${page === 1 ? 'disabled' : ''} icon iPrevious`}
            ></span>
          </Button>{' '}
          {
            <div className="text-white ml-2 page-count">{`${
              page + '/' + totalPage
            }`}</div>
          }
          <Button
            onClick={handleNextPage}
            disabled={!continued}
            className="d-flex align-items-center justify-content-center button-paging"
          >
            <span
              className={`${!continued ? 'disabled' : ''} icon iNext`}
            ></span>
          </Button>
        </div>
      </div>
    );
  }
);

export default translate('translations')(PagingCustom);
