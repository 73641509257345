import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import { makeGetSummaryCash, makeGetSummaryStock } from '../../lib/selector';

import PerfectScrollBar from 'react-perfect-scrollbar';
import { Col, Button } from 'react-bootstrap';

import {
  numberFormat,
  _convertTpToPrice,
  _getOrderStatus,
  StringToInt,
  _formatDate2,
} from '../../util';

import Page from '../print/Page';
import PrintButton from '../print/PrintButton';
import FormSearchSKCK from './layout/formSearchSKCK';

import { Link } from 'react-router-dom';
import { IoIosArrowBack } from 'react-icons/io';
import { take, takeRight } from 'lodash';
import logo from '../../assets/img/pinetreeSecurities.svg';
import ExportSaoKeChungKhoan from '../../lib/exports/SaoKeChungKhoan';

import { translate } from 'react-i18next';

class SaoKeChungKhoan extends React.PureComponent {
  state = {
    params: null,

    contentShow: [],
    page: 1,
    continued: false,
    totalPage: 1,
  };

  componentDidUpdate(preProps) {
    const { stockList } = this.props;
    if (stockList && stockList !== preProps.stockList) {
      this.setState({
        totalCount: stockList && stockList.list ? stockList.list.length : 0,
      });
      this.handleQueryPage(1);
    }
  }

  handleQueryPage = (page) => {
    const { stockList } = this.props;
    if (!stockList) return;
    const _pageSize = 15;
    const totalPage =
      stockList && stockList.list && stockList.list.length > 0
        ? Math.ceil(stockList.list.length / _pageSize)
        : 1;

    const _takeContent = take(stockList.list, _pageSize * page);
    const _contentShow = takeRight(
      _takeContent,
      _takeContent.length - _pageSize * (page - 1)
    );
    console.log(_contentShow);
    this.setState({
      contentShow: _contentShow,
      page,
      continued: page < totalPage,
      totalPage,
    });
  };

  _handleNextPage = (step) => {
    const { page } = this.state;
    this.handleQueryPage(page + step);
  };

  _saveParams = (params) => {
    // console.log(params)
    this.setState({ params });
  };

  render() {
    const { account, stockList, t } = this.props;
    console.log('stockList', stockList);
    const { contentShow, page, continued, totalPage, params } = this.state;

    return (
      <Fragment>
        <PerfectScrollBar style={{ height: 'calc( 100% - 105px )' }}>
          <div className="d-flex">
            <Col className="d-flex flex-column">
              <div className="d-flex mt-3 mb-2 position-relative">
                <Link
                  to="tai-san"
                  className="color-lightning fz_14 position-absolute"
                  style={{ top: '2px' }}
                >
                  <IoIosArrowBack />
                  {` ${t('txt-label-return-asset')}`}
                </Link>
                <span
                  className="text-white mx-auto"
                  style={{ fontSize: '18px' }}
                >
                  {t('txt-asset-stocks')}
                </span>
              </div>
              <FormSearchSKCK account={account} saveParams={this._saveParams} />
              <div className="d-flex justify-content-between align-items-center mt-3 mb-2">
                <label className="text-white ml-2 fz_14 text-uppercase">
                  {t('txt-detail')}
                </label>
                <div className="d-flex align-items-baseline">
                  <ExportSaoKeChungKhoan
                    csvData={stockList ? stockList.list : []}
                    fileName="sao-ke-giao-dich-chung-khoan"
                    fromDate={params && _formatDate2(params.data.fromDate)}
                    toDate={params && _formatDate2(params.data.toDate)}
                    symbol={params && (params.data.symbol || 'Tất cả')}
                    accountCode={account && account.subAcntNo}
                    accountName={account && account.vsdAcntNo}
                  />
                  <PrintButton id={'sao-ke-giao-dich-chung-khoan'} />
                </div>
              </div>
              <Page id="sao-ke-chung-khoan">
                <table className="table table-bordered tbl-list">
                  <thead>
                    <tr>
                      <th className="text-center">{t('txt-stt')}</th>
                      <th className="text-left">{t('txt-time')}</th>
                      <th className="text-left">{t('txt-symbol')}</th>
                      <th className="text-left">{t('txt-label-major')}</th>
                      <th className="text-right">
                        {t('txt-label-opening-balance')}
                      </th>
                      <th className="text-center high-light" colSpan="3">
                        {t('txt-label-increase')}
                      </th>
                      <th className="text-center high-light-down" colSpan="3">
                        {t('txt-label-decrease')}
                      </th>
                      <th className="text-right">
                        {t('txt-label-closing-balance')}
                      </th>
                    </tr>
                    <tr style={{ border: '1px solid #e8e8e8' }}>
                      <th colSpan="5"></th>
                      <th className="high-light">{t('txt-label-vol-up')}</th>
                      <th className="high-light">{t('txt-ave')}</th>
                      <th className="high-light">{t('txt-giatri')}</th>
                      <th className="high-light-down">
                        {t('txt-label-vol-down')}
                      </th>
                      <th className="high-light-down">{t('txt-ave')}</th>
                      <th className="high-light-down">{t('txt-giatri')}</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {contentShow &&
                      !!contentShow.length &&
                      contentShow.map((item, i) => (
                        <tr key={i}>
                          <td className="text-center">
                            {(page - 1) * 10 + i + 1}
                          </td>
                          <td className="text-left">
                            {_formatDate2(item.trdDate)}
                          </td>
                          <td className="text-left">{item.symbol}</td>
                          <td className="text-left">{item.descTranKnd}</td>
                          <td className="text-right">
                            {numberFormat(item.beginQty, 0, '0')}
                          </td>
                          <td className="text-right high-light">
                            {numberFormat(item.inQty, 0, '0')}
                          </td>
                          <td className="text-right high-light">
                            {item.inQty > 0
                              ? numberFormat(item.purchasePrc, 0, '-')
                              : '-'}
                          </td>
                          <td className="text-right high-light">
                            {item.inQty > 0
                              ? numberFormat(
                                  item.inQty * item.purchasePrc,
                                  0,
                                  '-'
                                )
                              : '-'}
                          </td>
                          <td className="text-right high-light-down">
                            {numberFormat(item.outQty, 0, '0')}
                          </td>
                          <td className="text-right high-light-down">
                            {item.outQty > 0
                              ? numberFormat(item.purchasePrc, 0, '-')
                              : '-'}
                          </td>
                          <td className="text-right high-light-down">
                            {item.outQty > 0
                              ? numberFormat(
                                  item.outQty * item.purchasePrc,
                                  0,
                                  '-'
                                )
                              : '-'}
                          </td>
                          <td className="text-right">
                            {numberFormat(item.endQty, 0, '0')}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                {(!stockList || !stockList.list || !stockList.list.length) && (
                  <p className="text-center fz_14 mt-2 title-time">
                    {t('txt-no-data-found')}
                  </p>
                )}
              </Page>
              {stockList && (
                <div className="d-flex flex-row-reverse align-items-center paging mt-2">
                  <div className="d-flex align-items-center">
                    <Button
                      onClick={() => this._handleNextPage(-1)}
                      disabled={page === 1}
                      className="d-flex align-items-center justify-content-center"
                    >
                      {'<'}
                    </Button>
                    <Button
                      onClick={() => this._handleNextPage(1)}
                      disabled={!continued}
                      className="d-flex align-items-center justify-content-center"
                    >
                      {'>'}
                    </Button>
                  </div>
                  <span className="fz_14 text-white mr-2">{`${t(
                    'txt-label-page'
                  )} ${page + '/' + totalPage}`}</span>
                </div>
              )}
              <Page
                id="sao-ke-giao-dich-chung-khoan"
                style={{ display: 'none' }}
              >
                <div className="d-flex flex-column bg-light">
                  <div
                    className="position-relative d-flex justify-content-center align-items-center"
                    style={{ height: '75px' }}
                  >
                    <img
                      src={logo}
                      alt="logo"
                      className="position-absolute"
                      style={{ top: '0', left: '0' }}
                    />
                    <span className="fz_16 fw-500 text-uppercase">
                      {'SAO KÊ GIAO DỊCH CHỨNG KHOÁN'}
                    </span>
                  </div>
                  <div className="d-flex mt-2">
                    <Col className="d-flex flex-column">
                      <div className="d-flex">
                        <span className="fz_14 w_125">{'Tên BC:'}</span>
                        <span className="fz_14 f_i">
                          {'Sao kê giao dịch chứng khoán'}
                        </span>
                      </div>
                      <div className="d-flex">
                        <span className="fz_14 w_125">{'Số tiểu khoản:'}</span>
                        <span className="fz_14 f_i">
                          {account && account.subAcntNo}
                        </span>
                      </div>
                      <div className="d-flex">
                        <span className="fz_14 w_125">{'Số VSD:'}</span>
                        <span className="fz_14 f_i">
                          {account && account.vsdAcntNo}
                        </span>
                      </div>
                    </Col>
                    <Col className="d-flex flex-column">
                      <div className="d-flex">
                        <span className="fz_14 w_125">{'Mã CK:'}</span>
                        <span className="fz_14 f_i">
                          {params && (params.data.symbol || 'Tất cả')}
                        </span>
                      </div>
                      <div className="d-flex">
                        <span className="fz_14 w_125">{'Từ ngày:'}</span>
                        <span className="fz_14 f_i">
                          {params && _formatDate2(params.data.fromDate)}
                        </span>
                      </div>
                      <div className="d-flex">
                        <span className="fz_14 w_125">{'Đến ngày:'}</span>
                        <span className="fz_14 f_i">
                          {params && _formatDate2(params.data.toDate)}
                        </span>
                      </div>
                    </Col>
                  </div>
                </div>
                <div className="d-flex bg-light">
                  <table className="table table-bordered mt-2 table-light">
                    <thead>
                      <tr style={{ border: '1px solid #e8e8e8' }}>
                        <th className="text-center text-white">{'STT'}</th>
                        <th className="text-left text-white">{'Thời gian'}</th>
                        <th className="text-left text-white">{'Mã CK'}</th>
                        <th className="text-left text-white">{'Nghiệp vụ'}</th>
                        <th className="text-right text-white">
                          {'Số dư đầu kỳ'}
                        </th>
                        <th
                          className="text-center high-light text-white"
                          colSpan="3"
                        >
                          {'Phát sinh tăng'}
                        </th>
                        <th
                          className="text-center high-light-down text-white"
                          colSpan="3"
                        >
                          {'Phát sinh giảm'}
                        </th>
                        <th className="text-right text-white">
                          {'Số dư cuối kỳ'}
                        </th>
                      </tr>
                      <tr style={{ border: '1px solid #e8e8e8' }}>
                        <th colSpan="5"></th>
                        <th className="high-light text-white">KL tăng</th>
                        <th className="high-light text-white">Giá TB</th>
                        <th className="high-light text-white">Tổng giá trị</th>
                        <th className="high-light-down text-white">KL giảm</th>
                        <th className="high-light-down text-white">Giá TB</th>
                        <th className="high-light-down text-white">
                          Tổng giá trị
                        </th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {stockList &&
                        stockList.list &&
                        !!stockList.list.length &&
                        stockList.list.map((item, i) => {
                          return (
                            <tr key={i} style={{ border: '1px solid #e8e8e8' }}>
                              <td className="text-center">{i + 1}</td>
                              <td className="text-left">
                                {_formatDate2(item.trdDate)}
                              </td>
                              <td className="text-left">{item.symbol}</td>
                              <td className="text-left">{item.descTranKnd}</td>
                              <td className="text-right">
                                {numberFormat(item.beginQty, 0, '0')}
                              </td>
                              <td className="text-right high-light">
                                {numberFormat(item.inQty, 0, '0')}
                              </td>
                              <td className="text-right high-light">
                                {item.inQty > 0
                                  ? numberFormat(item.purchasePrc, 0, '-')
                                  : '-'}
                              </td>
                              <td className="text-right high-light">
                                {item.inQty > 0
                                  ? numberFormat(
                                      item.inQty * item.purchasePrc,
                                      0,
                                      '-'
                                    )
                                  : '-'}
                              </td>
                              <td className="text-right high-light-down">
                                {numberFormat(item.outQty, 0, '0')}
                              </td>
                              <td className="text-right high-light-down">
                                {item.outQty > 0
                                  ? numberFormat(item.purchasePrc, 0, '-')
                                  : '-'}
                              </td>
                              <td className="text-right high-light-down">
                                {item.outQty > 0
                                  ? numberFormat(
                                      item.outQty * item.purchasePrc,
                                      0,
                                      '-'
                                    )
                                  : '-'}
                              </td>
                              <td className="text-right">
                                {numberFormat(item.endQty, 0, '0')}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </Page>
            </Col>
          </div>
        </PerfectScrollBar>
      </Fragment>
    );
  }
}

const makeMapStateToProps = () => {
  const getSummaryStock = makeGetSummaryStock();

  const mapStateToProps = (state) => {
    return {
      stockList: getSummaryStock(state),
    };
  };
  return mapStateToProps;
};

export default connect(makeMapStateToProps)(
  translate('translations')(SaoKeChungKhoan)
);
