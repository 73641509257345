import {
  call,
  put,
  takeLatest,
  all,
  takeEvery,
  select,
} from 'redux-saga/effects';
import { handleApiErrors } from '../../lib/api-errors';
import i18n from 'i18next';
import {
  AVAIL_STOCK_SOURCE_REQUESTING,
  AVAIL_STOCK_TARGET_REQUESTING,
  STOCK_EXCHANGE_REQUESTING,
  STOCK_EXCHANGE_HIS_REQUESTING,
  ADVANCE_REQUESTING,
  AVAIL_ADVANCE_REQUESTING,
  ADVANCE_LOAN_REQUESTING,
  ADVANCE_LIST_REQUESTING,
  CASH_TRANSFER_REQUESTING,
  HIS_TRANSFER_REQUESTING,
  BALANCE_SOURCE_REQUESTING,
  BALANCE_TARGET_REQUESTING,
  DEBT_REQUESTING,
  REPAY_LOAN_REQUESTING,
  DEBT_HIS_REQUESTING,
  ADVANCE_MATCH_REQUESTING,
  ADVANCE_FEE_REQUESTING,
  BANK_ACCOUNT_REQUESTING,
  CASH_OUT_REQUESTING,
  BANK_LIST_REQUESTING,
  CASH_IN_BANK_REQUESTING,
  RIGHT_LIST_REQUESTING,
  HOLD_BANK_REQUESTING,
  HOLD_LIST_REQUESTING,
  DEBT_HIS2_REQUESTING,
  MARGIN_LIST_REQUESTING,
  GAIN_LOSS_REQUESTING,
  BANK_INFOR_REQUESTING,
  BANK_INFOR_REQUEST_SUCCESS,
  BANK_INFOR_REQUEST_ERROR,
  DEBT_HIS_EXTEND_REQUESTING,
  DEBT_HIS_EXTEND_REQUEST_SUCCESS,
  DEBT_HIS_EXTEND_REQUEST_ERROR,
  DEBT_EXTEND_REQUESTING,
  DEBT_EXTEND_REQUEST_SUCCESS,
  DEBT_EXTEND_REQUEST_ERROR,
  GET_LIST_BANK_VA_SUCCESS,
  GET_LIST_BANK_VA_ERROR,
  GET_LIST_BANK_VA_REQUEST,
} from './constants';

import {
  stockSourceRequestSuccess,
  stockSourceRequestError,
  stockTargetRequestSuccess,
  stockTargetRequestError,
  stockExchangeRequestSuccess,
  stockExchangeRequestError,
  stockExchangeHisRequestSuccess,
  stockExchangeHisRequestError,
  advanceRequestSuccess,
  advanceRequestError,
  availAdvanceRequestSuccess,
  availAdvanceRequestError,
  advanceLoanRequestSuccess,
  advanceLoanRequestError,
  advanceListRequestSuccess,
  advanceListRequestError,
  cashTransferRequestError,
  cashTransferRequestSuccess,
  hisTransferRequestSuccess,
  hisTransferRequestError,
  cashBalanceSourceRequestSuccess,
  cashBalanceTargetRequestSuccess,
  cashBalanceTargetRequestError,
  cashBalanceSourceRequestError,
  debtHisRequestError,
  debtRequestSuccess,
  debtRequestError,
  repayLoanRequestSuccess,
  repayLoanRequestError,
  debtHisRequestSuccess,
  advanceMatchRequestSuccess,
  advanceFeeRequestSuccess,
  advanceFeeRequestError,
  bankAccRequestSuccess,
  bankAccRequestError,
  cashOutRequestSuccess,
  cashOutRequestError,
  bankListRequestSuccess,
  bankListRequestError,
  cashInBankRequestSuccess,
  cashInBankRequestError,
  rightListRequestSuccess,
  rightListRequestError,
  holdBankRequestSuccess,
  holdBankRequestError,
  holdListRequestSuccess,
  holdListRequestError,
  debtHis2RequestSuccess,
  debtHis2RequestError,
  marginListRequestSuccess,
  marginListRequestError,
  gainLossRequestSuccess,
  gainLossRequestError,
  cashOutReset,
} from './actions';
import { getMessage } from '../../lib/selector';
import { removeCookie } from '../../lib/storages';
import { resetCashOut, setToast } from '../client/actions';
import { LOCK_RESENT_OTP } from '../../components/modal/auth/constants';

const appUrl = `${process.env.REACT_APP_API_URL}`;
const appUrl2 = `${process.env.REACT_APP_API_URL_2}`;

function handleRequest(request) {
  return request
    .then(handleApiErrors)
    .then((response) => response.json())
    .then((json) => json)
    .catch((error) => {
      throw error;
    });
}

function cashRequestApi(data) {
  const url = `${appUrl}/CoreServlet.pt`;
  const request = fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
    body: JSON.stringify(data),
  });
  return handleRequest(request);
}

function bankInforApi(bankCode, language) {
  const url = `${appUrl2}/api/Market/GetBankInfor?language=${language}${
    !bankCode ? '' : `&bankCode=${bankCode}`
  }`;
  const request = fetch(url, {
    method: 'GET',
  });
  return handleRequest(request);
}

function listBankVAApi(data) {
  const url = `${appUrl}/CoreServlet.pt`;
  const request = fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
    body: JSON.stringify(data),
  });
  return handleRequest(request);
}

function toastHandle(message, title = 'txt-notice') {
  const toastMsg = {
    id: Math.random(),
    msg: message,
    time: new Date(),
    title: i18n.t(`${title}`),
  };
  return put(setToast(toastMsg));
}

function* stockSourceRequestFlow(action) {
  try {
    const { data } = action;
    const _res = yield call(cashRequestApi, data);

    yield put(stockSourceRequestSuccess(_res.data));
  } catch (error) {
    yield put(stockSourceRequestError(error));
  }
}

function* stockTargetRequestFlow(action) {
  try {
    const { data } = action;
    const _res = yield call(cashRequestApi, data);

    yield put(stockTargetRequestSuccess(_res.data));
  } catch (error) {
    yield put(stockTargetRequestError(error));
  }
}

function* stockExchangeRequestFlow(action) {
  try {
    const { data } = action;
    // console.log(data);
    const _res = yield call(cashRequestApi, data);
    _res.time = new Date().getTime();
    _res.symbol = data.data.symbol;
    _res.quantity = data.data.qty;
    // console.log(_res);
    yield put(stockExchangeRequestSuccess(_res));
  } catch (error) {
    yield put(stockExchangeRequestError(error));
  }
}

function* stockExchangeHisRequestFlow(action) {
  try {
    const { data } = action;
    const _res = yield call(cashRequestApi, data);

    yield put(stockExchangeHisRequestSuccess(_res.data));
  } catch (error) {
    yield put(stockExchangeHisRequestError(error));
  }
}

function* advanceRequestFlow(action) {
  try {
    const { data } = action;
    const _res = yield call(cashRequestApi, data);

    yield put(advanceRequestSuccess(_res.data));
  } catch (error) {
    yield put(advanceRequestError(error));
  }
}

function* availAdvanceRequestFlow(action) {
  try {
    const { data } = action;
    const _res = yield call(cashRequestApi, data);

    yield put(availAdvanceRequestSuccess(_res.data));
  } catch (error) {
    yield put(availAdvanceRequestError(error));
  }
}

function* advanceLoanRequestFlow(action) {
  try {
    const { data } = action;
    const _res = yield call(cashRequestApi, data);

    yield put(advanceLoanRequestSuccess(_res));
  } catch (error) {
    yield put(advanceLoanRequestError(error));
  }
}

function* advanceListRequestFlow(action) {
  try {
    const { data } = action;
    const _res = yield call(cashRequestApi, data);

    yield put(advanceListRequestSuccess(_res.data));
  } catch (error) {
    yield put(advanceListRequestError(error));
  }
}

function* advanceMatchRequestFlow(action) {
  try {
    const { data } = action;
    const _res = yield call(cashRequestApi, data);

    yield put(advanceMatchRequestSuccess(_res.data));
  } catch (error) {
    yield put(advanceMatchRequestSuccess(error));
  }
}

function* advanceFeeRequestFlow(action) {
  try {
    const { data } = action;
    const _res = yield call(cashRequestApi, data);

    yield put(advanceFeeRequestSuccess(_res.data));
  } catch (error) {
    yield put(advanceFeeRequestError(error));
  }
}

function* cashBalanceRequestFlow(action) {
  try {
    const { data } = action;
    const _res = yield call(cashRequestApi, data);

    yield put(cashBalanceSourceRequestSuccess(_res.data));
  } catch (error) {
    yield toastHandle(error?.toString(), 'txt-error');
    yield put(cashBalanceSourceRequestError(error));
  }
}

function* cashBalanceTargetRequestFlow(action) {
  try {
    const { data } = action;
    const _res = yield call(cashRequestApi, data);

    yield put(cashBalanceTargetRequestSuccess(_res.data));
  } catch (error) {
    yield put(cashBalanceTargetRequestError(error));
  }
}

function* cashTransferRequestFlow(action) {
  try {
    const { data, extendCallback } = action;
    const _res = yield call(cashRequestApi, data);
    _res.time = new Date().getTime();
    if (_res.http != 200) {
      if (_res.http === 401) {
        removeCookie('token');
        removeCookie('authen');
        yield put({ type: 'INVALID_SESSION', resData: _res.data.message });
      }
      const msgErr = yield select(getMessage, _res);
      yield toastHandle(msgErr, 'txt-error');
    } else {
      if (extendCallback && typeof extendCallback == 'function') {
        extendCallback();
      }
      yield put(cashTransferRequestSuccess(_res));
    }
  } catch (error) {
    yield put(cashTransferRequestError(error));
  }
}

function* hisTransferRequestFlow(action) {
  try {
    const { data } = action;
    const _res = yield call(cashRequestApi, data);

    yield put(hisTransferRequestSuccess(_res.data));
  } catch (error) {
    yield put(hisTransferRequestError(error));
  }
}

function* bankAccRequestFlow(action) {
  try {
    const { data } = action;
    const _res = yield call(cashRequestApi, data);
    yield put(bankAccRequestSuccess(_res.data));
  } catch (error) {
    yield put(bankAccRequestError(error));
  }
}

function* cashOutRequestFlow(action) {
  try {
    const { data } = action;
    const _res = yield call(cashRequestApi, data);
    _res.time = new Date().getTime();

    if (_res.http !== 200) {
      if (_res.data.messageNo === 99991) {
        yield put({ type: LOCK_RESENT_OTP });
      }

      if (_res.http === 401) {
        removeCookie('token');
        removeCookie('authen');
        yield put({ type: 'INVALID_SESSION', resData: _res.data.message });
      }

      const msgErr = yield select(getMessage, _res);
      const toastMsg = {
        id: Math.random(),
        msg: msgErr,
        title: i18n.t('txt-notice'),
      };
      yield put(setToast(toastMsg));
      yield put(cashOutRequestError(_res));
    } else {
      const toastMsg = {
        id: Math.random(),
        msg: i18n.t('txt-title-transfer-success'),
        title: i18n.t('txt-notice'),
      };
      yield put(setToast(toastMsg));
      yield put(cashOutRequestSuccess(_res));
      yield put(resetCashOut());
    }
  } catch (error) {
    yield put(cashOutRequestError(error));
  }
}

function* debtRequestFlow(action) {
  try {
    const { data } = action;
    const _res = yield call(cashRequestApi, data);
    // console.log(_res)
    yield put(debtRequestSuccess(_res.data));
  } catch (error) {
    // console.log(error)
    yield put(debtRequestError(error));
  }
}

function* repayRequestFlow(action) {
  try {
    const { data } = action;
    const _res = yield call(cashRequestApi, data);

    if (_res.http !== 200) {
      if (_res.http === 401) {
        removeCookie('token');
        removeCookie('authen');
        yield put({ type: 'INVALID_SESSION', resData: _res.data.message });
      }
      const msgErr = yield select(getMessage, _res);
      yield put(repayLoanRequestError(msgErr));
    }

    yield put(repayLoanRequestSuccess(_res));
  } catch (error) {
    yield put(repayLoanRequestError(error));
  }
}

function* debtExtendRequestFlow(action) {
  try {
    const { data } = action;
    const _res = yield call(cashRequestApi, data);

    if (_res.http !== 200) {
      if (_res.http === 401) {
        removeCookie('token');
        removeCookie('authen');
        yield put({ type: 'INVALID_SESSION', resData: _res.data.message });
      }
      const msgErr = yield select(getMessage, _res);
      yield put({ type: DEBT_EXTEND_REQUEST_ERROR, error: msgErr });
    } else {
      yield put({ type: DEBT_EXTEND_REQUEST_SUCCESS, resData: _res.data });
    }
  } catch (error) {
    yield put({ type: DEBT_EXTEND_REQUEST_ERROR, error: error });
  }
}

function* hisDebtRequestFlow(action) {
  try {
    const { data } = action;
    const _res = yield call(cashRequestApi, data);

    yield put(debtHisRequestSuccess(_res.data));
  } catch (error) {
    yield put(debtHisRequestError(error));
  }
}

function* hisDebt2RequestFlow(action) {
  try {
    const { data } = action;
    const _res = yield call(cashRequestApi, data);
    // console.log(_res)
    yield put(debtHis2RequestSuccess(_res.data));
  } catch (error) {
    yield put(debtHis2RequestError(error));
  }
}

function* hisDebtExtendRequestFlow(action) {
  try {
    const { data } = action;
    const _res = yield call(cashRequestApi, data);
    // console.log(_res)
    yield put({ type: DEBT_HIS_EXTEND_REQUEST_SUCCESS, resData: _res.data });
  } catch (error) {
    yield put({ type: DEBT_HIS_EXTEND_REQUEST_ERROR });
  }
}

function* bankListRequestFlow(action) {
  try {
    const { data } = action;
    const _res = yield call(cashRequestApi, data);
    console.log(_res.data);
    yield put(bankListRequestSuccess(_res.data));
  } catch (error) {
    yield put(bankListRequestError(error));
  }
}

function* cashInBankRequestFlow(action) {
  try {
    // yield put(cashInBankRequestSuccess({"bankCode":"BIDV","bankAcntNo":"13010000013591","totBal":1346337795,"avalBal":1322877795,"holdAmt":23460000}));
    // return;
    const { data } = action;
    const resData = yield call(cashRequestApi, data);
    console.log('cashInBankRequestFlow', resData.data);
    if (resData.http !== 200) {
      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');

        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }
      const msgErr = yield select(getMessage, resData);
      throw Error(msgErr);
    }

    yield put(cashInBankRequestSuccess(resData.data));
  } catch (error) {
    yield put(cashInBankRequestError(error));
  }
}

function* holdBankRequestFlow(action) {
  try {
    const { data } = action;
    const _res = yield call(cashRequestApi, data);
    console.log('holdBankRequestFlow', _res.data);
    // if (_res.http !== 200) {
    //   const msgErr = yield select(getMessage, _res);
    //   throw Error(msgErr);
    // }

    yield put(holdBankRequestSuccess(_res));
  } catch (error) {
    yield put(holdBankRequestError(error));
  }
}

function* holdListRequestFlow(action) {
  try {
    const { data } = action;
    const resData = yield call(cashRequestApi, data);
    console.log('holdListRequestFlow', resData.data);
    if (resData.http !== 200) {
      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');

        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }
      const msgErr = yield select(getMessage, resData);
      throw Error(msgErr);
    }

    yield put(holdListRequestSuccess(resData.data));
  } catch (error) {
    yield put(holdListRequestError(error));
  }
}

function* rightListRequestFlow(action) {
  try {
    const { data } = action;
    const resData = yield call(cashRequestApi, data);
    console.log('rightListRequestFlow', resData.data);
    if (resData.http !== 200) {
      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');

        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }
      const msgErr = yield select(getMessage, resData);
      throw Error(msgErr);
    }

    yield put(rightListRequestSuccess(resData.data));
  } catch (error) {
    yield put(rightListRequestError(error));
  }
}

function* marginListRequestFlow(action) {
  try {
    const { data } = action;
    const resData = yield call(cashRequestApi, data);
    console.log('marginListRequestFlow', resData.data);
    if (resData.http !== 200) {
      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');

        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }
      const msgErr = yield select(getMessage, resData);
      throw Error(msgErr);
    }

    yield put(marginListRequestSuccess(resData.data));
  } catch (error) {
    yield put(marginListRequestError(error));
  }
}

function* gainLossListRequestFlow(action) {
  try {
    const { data } = action;
    const resData = yield call(cashRequestApi, data);
    if (resData.http !== 200) {
      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');

        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }
      const msgErr = yield select(getMessage, resData);
      throw Error(msgErr);
    }

    yield put(gainLossRequestSuccess(resData.data));
  } catch (error) {
    yield put(gainLossRequestError(error));
  }
}

function* bankInforRequestFlow(action) {
  try {
    const { bankCode, language } = action;
    const resData = yield call(bankInforApi, bankCode, language);
    if (resData.http !== 200) {
      const msgErr = yield select(getMessage, resData);
      throw Error(msgErr);
    }

    yield put({ type: BANK_INFOR_REQUEST_SUCCESS, resData: resData.data });
  } catch (error) {
    yield put({ type: BANK_INFOR_REQUEST_ERROR, resData: error });
  }
}

function* getBankListVAFlow(action) {
  try {
    const params = action.data;
    const resData = yield call(listBankVAApi, params);
    if (resData.http !== 200) {
      const msgErr = yield select(getMessage, resData);
      throw Error(msgErr);
    }
    yield put({ type: GET_LIST_BANK_VA_SUCCESS, listBankVA: resData.data });
  } catch (error) {
    yield put({ type: GET_LIST_BANK_VA_ERROR, resData: error });
  }
}

function* stockDetailWatcher() {
  yield all([
    takeLatest(AVAIL_STOCK_SOURCE_REQUESTING, stockSourceRequestFlow),
    takeLatest(AVAIL_STOCK_TARGET_REQUESTING, stockTargetRequestFlow),
    takeEvery(STOCK_EXCHANGE_REQUESTING, stockExchangeRequestFlow),
    takeLatest(STOCK_EXCHANGE_HIS_REQUESTING, stockExchangeHisRequestFlow),

    takeLatest(ADVANCE_REQUESTING, advanceRequestFlow),
    takeLatest(AVAIL_ADVANCE_REQUESTING, availAdvanceRequestFlow),
    takeLatest(ADVANCE_LOAN_REQUESTING, advanceLoanRequestFlow),
    takeLatest(ADVANCE_LIST_REQUESTING, advanceListRequestFlow),
    takeLatest(ADVANCE_MATCH_REQUESTING, advanceMatchRequestFlow),
    takeEvery(ADVANCE_FEE_REQUESTING, advanceFeeRequestFlow),
    takeLatest(BALANCE_SOURCE_REQUESTING, cashBalanceRequestFlow),
    takeLatest(BALANCE_TARGET_REQUESTING, cashBalanceTargetRequestFlow),
    takeLatest(CASH_TRANSFER_REQUESTING, cashTransferRequestFlow),
    takeLatest(HIS_TRANSFER_REQUESTING, hisTransferRequestFlow),
    takeLatest(BANK_ACCOUNT_REQUESTING, bankAccRequestFlow),
    takeLatest(CASH_OUT_REQUESTING, cashOutRequestFlow),
    takeLatest(DEBT_REQUESTING, debtRequestFlow),
    takeLatest(REPAY_LOAN_REQUESTING, repayRequestFlow),
    takeLatest(DEBT_HIS_REQUESTING, hisDebtRequestFlow),
    takeLatest(DEBT_HIS2_REQUESTING, hisDebt2RequestFlow),
    takeLatest(DEBT_HIS_EXTEND_REQUESTING, hisDebtExtendRequestFlow),
    takeLatest(BANK_LIST_REQUESTING, bankListRequestFlow),
    takeLatest(CASH_IN_BANK_REQUESTING, cashInBankRequestFlow),
    takeLatest(HOLD_BANK_REQUESTING, holdBankRequestFlow),
    takeLatest(HOLD_LIST_REQUESTING, holdListRequestFlow),
    takeLatest(RIGHT_LIST_REQUESTING, rightListRequestFlow),
    takeLatest(MARGIN_LIST_REQUESTING, marginListRequestFlow),
    takeLatest(GAIN_LOSS_REQUESTING, gainLossListRequestFlow),
    takeLatest(BANK_INFOR_REQUESTING, bankInforRequestFlow),
    takeLatest(DEBT_EXTEND_REQUESTING, debtExtendRequestFlow),
    takeLatest(GET_LIST_BANK_VA_REQUEST, getBankListVAFlow),
  ]);
}

export default stockDetailWatcher;
