import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import ReactModal from 'react-modal';
import { Card } from 'react-bootstrap';
import { FaTimes } from 'react-icons/fa';
import { _getOrdCondStr, _convertActTypeToStr } from '../../../util';
import Modal from 'react-bootstrap/Modal';
import moment from 'moment';

const customStyles = {
  content: {
    top: '80px',
    left: '50%',
    width: '500px',
    transform: 'translate(-50%)',
    bottom: 'auto',
  },
  overlay: {
    backgroundColor: 'transparent',
    zIndex: '3',
  },
};

class FormDeleteOrdCond extends Component {
  submit = (values) => {
    const { order } = this.props;

    this.props.handleSubmitOrder(order);
    // this.props.reset();
  };

  render() {
    const {
      handleSubmit,
      submitting,
      showModal,
      handleCloseModal,
      order,
      themes,
    } = this.props;

    // console.log(order)

    return (
      <Modal
        show={showModal}
        dialogClassName="confirm-order-modal"
        backdropClassName="confirm-order-modal-backdrop"
        contentClassName={`${
          themes?.name || 'dark'
        } delete-order-modal-content`}
        onEscapeKeyDown={handleCloseModal}
        centered
        style={{ zIndex: 11115 }}
      >
        <Card className="delete-order">
          <Card.Header className="text-center fz_16 delete-order-header">
            <span>Xác nhận huỷ lệnh</span>
          </Card.Header>
          <Card.Body className="delete-order-body">
            <form className="form-trade" onSubmit={handleSubmit(this.submit)}>
              <table className="table table-no-bordered">
                <tbody>
                  <tr>
                    <td className="fz_14 content-read text--light3">
                      Mã chứng khoán
                    </td>
                    <td className="fz_14 content-read fw-500 text--light">
                      {order && order.symbol}
                    </td>
                  </tr>
                  <tr>
                    <td className="fz_14 content-read text--light3">
                      Loại lệnh
                    </td>
                    <td
                      className={
                        'fz_16 content-read fw-500 ' +
                        (order && (order.side === 'B' ? 'i' : 'd'))
                      }
                    >
                      {order && (order.side === 'B' ? 'MUA' : 'BÁN')}
                    </td>
                  </tr>
                  <tr>
                    <td className="fz_14 content-read text--light3">
                      Loại điều kiện
                    </td>
                    <td className="fz_14 content-read fw-500 text--light">
                      {order && _getOrdCondStr(order.orderType)}
                    </td>
                  </tr>
                  <tr>
                    <td className="fz_14 content-read text--light3">
                      Điều kiện đặt
                    </td>
                    <td className="fz_14 content-read fw-500 text--light">
                      {order && _convertActTypeToStr(order.activeType)}
                    </td>
                  </tr>
                  <tr>
                    <td className="fz_14 content-read text--light3">
                      Ngày đặt
                    </td>
                    <td className="fz_14 content-read fw-500 text--light">
                      {order && moment(order.createdDt).format('DD/MM/yyyy')}
                    </td>
                  </tr>
                  <tr>
                    <td className="fz_14 content-read text--light3">
                      Ngày hết hạn
                    </td>
                    <td className="fz_14 content-read fw-500 text--light">
                      {order && moment(order.expiredDt).format('DD/MM/yyyy')}
                    </td>
                  </tr>
                  <tr>
                    <td className="fz_14 content-read text--light3">
                      Số hiệu lệnh
                    </td>
                    <td className="fz_14 content-read fw-500 text--light">
                      {order && order.ordrNo}
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="d-flex justify-content-center mt-2">
                <button
                  type="submit"
                  disabled={submitting}
                  className="btn fw-500 fz_14 ml-2 btn-order order-buy"
                >
                  {`Chấp nhận`}
                </button>
                <button
                  type="button"
                  disabled={submitting}
                  onClick={handleCloseModal}
                  className="btn fw-500 fz_14 btn-order ml-2"
                >
                  {`Huỷ`}
                </button>
              </div>
            </form>
          </Card.Body>
        </Card>
      </Modal>
    );
  }
}

FormDeleteOrdCond = reduxForm({
  form: 'deleteOrdCond',
})(FormDeleteOrdCond);

const makeMapStateToProps = () => {
  const mapStateToProps = (state) => {
    return {
      themes: state.client.currentTheme,
    };
  };
  return mapStateToProps;
};

FormDeleteOrdCond = connect(makeMapStateToProps)(FormDeleteOrdCond);

export default FormDeleteOrdCond;
