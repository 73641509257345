import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { withNamespaces } from 'react-i18next';
import '../index.scss';
import { v4 as uuidv4 } from 'uuid';
import { handleApiErrors } from '../../../../lib/api-errors';
import AuthenStep from '../../../../components/common/auth';
import PDFViewer from '../../../bond/components/Contract/pdfViewer';
import {
  PARTNER_REQUEST_ADD_REMOVE_TYPE,
  PARTNER_REQUEST_APPROVE_REJECT_TYPE,
} from '../../../../util/constant';
import { getCommonRequest } from '../../../customer/actions';
import { PARTNER_REQUEST_TYPE_OPTION } from '../../../../util';

const ModalCancelRequest = withNamespaces('translations')(
  ({ t, showModal, handleClose, dataRequest, handleLoadData }) => {
    const theme = useSelector((state) => state.client.currentTheme);
    const { token, auth } = useSelector((state) => state.client);
    const [oneTimeToken, setOneTimeToken] = useState();
    const dispatch = useDispatch();
    const defaultAccount = useSelector((state) => state.socket.defaultAccount);

    const handleCancelRequest = (type) => {
      const uuid = uuidv4();
      const params = {
        group: 'CORE',
        user: token.user,
        session: token.session,
        cmd: 'cancelReqBrk',
        rqId: uuid,
        channel: 'WTS',
        data: {
          cif: token.cif,
          acntNo: defaultAccount.acntNo,
          reqDt: dataRequest.reqDate,
          reqSeq: dataRequest.reqSeq,
        },
      };

      const callback = (data) => {
        handleClose();
        handleLoadData();
      };

      dispatch(getCommonRequest(params, callback, true));
    };

    return (
      <Modal
        onHide={handleClose}
        onEscapeKeyDown={handleClose}
        show={showModal}
        dialogClassName="wts-modal"
        backdropClassName="wts-modal-backdrop"
        contentClassName={`${
          theme?.name || 'dark'
        } modal-cancel-request modal-add-customer wts-modal-content `}
        centered
        style={{ zIndex: 11114 }}
      >
        <div className={`modal-add-customer__header p-2 position-relative`}>
          <div className="text-center w-100">{t('partner.cancelRequest')}</div>
          <div
            className="position-absolute icon-close"
            onClick={() => {
              handleClose();
            }}
          >
            <span className={'icon iClose'}></span>
          </div>
        </div>
        <div className="page-partner-list p-4 w-100">
          <div className="text text-light text--sm">
            {t('partner.cancelRequest') +
              ' : ' +
              t(
                PARTNER_REQUEST_TYPE_OPTION.find(
                  (el) => el.value === dataRequest?.reqTp
                )?.label
              )}
          </div>
          <div className="text text-light text--sm mt-1">
            {t('partner.VSDAccount') + ' : ' + dataRequest?.vsdAcntNo}
          </div>
          <div className="text text-light text--sm mt-1">
            {t('partner.referralCode') + ' : ' + dataRequest?.brokerId}
          </div>
          <button
            onClick={() => {
              handleCancelRequest();
            }}
            className={'btn btn-danger-secondary d w-100 mt-4'}
          >
            {t('txt-confirm')}
          </button>
        </div>
      </Modal>
    );
  }
);

export default ModalCancelRequest;
