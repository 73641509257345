import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { withNamespaces } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import CheckboxButton from '../../../components/checkbox/checkBoxButton';
import {
  getOpenPFAccountContract,
  openAccountPopup,
  registPinefolioAccount,
} from '../action';
import { v4 as uuidv4 } from 'uuid';
import PDFViewer from '../../bond/components/Contract/pdfViewer';
import AuthenStep from '../../../components/common/auth';

const OpenPinefolioAccount = withNamespaces('translations')(({ t }) => {
  // const [show, setShow] = useState(false);
  const [authType, setAuthType] = useState(null);
  const [oneTimeToken, setOneTimeToken] = useState('');
  const [check, setCheck] = useState(false);

  const theme = useSelector((state) => state.client.currentTheme);
  const { token } = useSelector((state) => state.client);
  const { defaultAccount, listAccount } = useSelector((state) => state.socket);
  const { pineAccountOpenContract, hadPineAccount, openAccPopup } = useSelector(
    (state) => state.pinefolio
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (authType) {
      setOneTimeToken('');
    }
  }, [authType]);

  useEffect(() => {
    if (openAccPopup) {
      // setShow(true);
      getContract();
    }
  }, [openAccPopup]);

  const getContract = () => {
    if (token) {
      const uuid = uuidv4();
      const params = {
        group: 'CORE',
        user: token.user,
        session: token.session,
        cmd: 'getOpenSubAcntCntr',
        rqId: uuid,
        channel: 'WTS',
        data: {
          cif: token.cif,
          acntNo: defaultAccount?.acntNo,
          subAcntClss: 'P',
        },
      };

      dispatch(getOpenPFAccountContract(params));
    }
  };

  const closeModal = () => {
    // setShow(false);
    dispatch(openAccountPopup(false));
  };

  const disableHandle = () => {
    if (!oneTimeToken || !check) return true;
    else return false;
  };

  const confirm = () => {
    const uuid = uuidv4();
    const validate = disableHandle();

    if (!validate) {
      const params = {
        group: 'CORE',
        user: token.user,
        session: token.session,
        cmd: 'openSubAccount',
        rqId: uuid,
        channel: 'WTS',
        type: '6',
        token: oneTimeToken ?? '',
        data: {
          cif: token.cif,
          acntNo: defaultAccount.acntNo,
          subAcntClss: 'P',
        },
      };

      dispatch(registPinefolioAccount(params, listAccount));
    }
  };

  return (
    <Modal
      show={openAccPopup && !hadPineAccount}
      dialogClassName="wts-modal"
      backdropClassName="wts-modal-backdrop pinefolio-open-account-backdrop"
      contentClassName={`${
        theme ? theme.name : 'dark'
      } pinefolio-open-account wts-modal-content wts-modal-content--lg2`}
      onEscapeKeyDown={() => closeModal()}
      centered
      onHide={() => closeModal()}
      style={{ zIndex: 11115 }}
    >
      <div className="pinefolio-open-account__left">
        <div className="pinefolio-open-account__header">
          <span className="text--lg fw-500 pinefolio-open-account__title">
            {t('pinefolio.account.openNew')}
          </span>
          <CheckboxButton
            description={t('pinefolio.account.confirm')}
            onClick={() => setCheck(!check)}
          />
        </div>
        <div className="pinefolio-open-account__body">
          <div className="2fa-authentication">
            <AuthenStep
              setOneTimeToken={setOneTimeToken}
              setAuthType={setAuthType}
            />
          </div>
          <div className="pinefolio-open-account__actions">
            <button
              className="btn btn--buy pinefolio-open-account__action"
              onClick={() => confirm()}
              disabled={disableHandle()}
            >
              {t('txt-confirm')}
            </button>
            <button
              className="btn btn--cancel pinefolio-open-account__action"
              onClick={() => closeModal()}
            >
              {t('txt-cancel')}
            </button>
          </div>
        </div>
      </div>
      <div className="pinefolio-open-account__right">
        {pineAccountOpenContract && (
          <PDFViewer src={pineAccountOpenContract.fileUrl} />
        )}
      </div>
    </Modal>
  );
});

export default OpenPinefolioAccount;
