import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setBondDetailModalRequest,
  getBondDetail,
  setBondProductDetail,
  setProInvRequire,
  setRegistBondTradingRetailModal,
  getBondCommonRequest2,
} from '../../action';
import { withNamespaces } from 'react-i18next';
import { numberFormat, _formatDate2 } from '../../../../util';
import { setAuth, setLogin, setToast } from '../../../client/actions.js';
import { useHistory } from 'react-router-dom';
import { requiredProInvestor } from '../../utils';
import { Mixpanel } from '../../../../lib/mixpanel';
import PerfectScrollBar from 'react-perfect-scrollbar';
import { bondConstants, constants } from '../../../../util/constant';
import { uniq, sortBy } from 'lodash';
import { BsFillCaretDownFill, BsFillCaretUpFill } from 'react-icons/bs';
import Collaterals from './collaterals';

const GroupTable = withNamespaces('translations')(
  ({ t, data, setModalSize, limitInfor }) => {
    const {
      auth,
      token,
      setting: { lang = 'vi' },
    } = useSelector((state) => state.client);
    const [sortField, setSortField] = useState('');
    const [sortDirection, setSortDirection] = useState(
      constants.sortDirection.Asc
    );
    const dispatch = useDispatch();

    const groups = useMemo(() => {
      return uniq(data.map((x) => x.termName));
    }, [data]);

    const showSortIcon = (field) => {
      if (sortDirection === constants.sortDirection.Asc && sortField == field) {
        return (
          <span>
            <BsFillCaretUpFill
              className="fz_10 position-relative"
              style={{ marginLeft: '0.15rem' }}
            />
          </span>
        );
      }

      if (
        sortDirection === constants.sortDirection.Desc &&
        sortField == field
      ) {
        return (
          <span>
            <BsFillCaretDownFill
              className="fz_10 position-relative"
              style={{ marginLeft: '0.15rem' }}
            />
          </span>
        );
      }
    };

    const sortHandle = (field) => {
      setSortField(field);
      setSortDirection(
        sortDirection == constants.sortDirection.Asc
          ? constants.sortDirection.Desc
          : constants.sortDirection.Asc
      );
    };

    const colgroup = (
      <colgroup>
        <col width="10%"></col>
        <col></col>
        <col width="100px"></col>
        <col width="100px"></col>
        <col width="100px"></col>
        <col width="100px"></col>
        <col width="100px"></col>
        <col width="100px"></col>
        <col></col>
        <col width="155px"></col>
      </colgroup>
    );

    return (
      <div className="table-group" style={{ height: 'calc(100% - 66px)' }}>
        <table
          class="table table-bordered mt-4"
          style={{ tableLayout: 'fixed' }}
        >
          {colgroup}
          <thead style={{ height: '48px' }}>
            <th
              className="text text-left text--light3  fw-500 sortable filterIcon user-select-none"
              onClick={() => sortHandle('bondCode')}
            >
              {t('txt-label-bond-code')}
              {showSortIcon('bondCode')}
            </th>
            <th
              className="text text-left text--light3  fw-500 sortable filterIcon user-select-none"
              onClick={() => sortHandle('issuerNm')}
            >
              {t('bond.issuer')}
              {showSortIcon('issuerNm')}
            </th>
            <th
              className="text text--light3  fw-500 sortable filterIcon user-select-none"
              onClick={() => sortHandle('guaranteeYN')}
            >
              {t('bond.tableLabel.collateral')}
              {showSortIcon('guaranteeYN')}
            </th>
            <th
              className="text text--light3  fw-500 sortable filterIcon user-select-none"
              onClick={() => sortHandle('issueTpNm')}
            >
              {t('bond.formOfOffering')}
              {showSortIcon('issueTpNm')}
            </th>
            <th
              className="text text--light3  fw-500 sortable filterIcon user-select-none"
              onClick={() => sortHandle('intRate')}
            >
              {t('bond.tableLabel.rate')}
              {showSortIcon('intRate')}
            </th>
            <th
              className="text text-right text--light3  fw-500 sortable filterIcon user-select-none"
              onClick={() => sortHandle('leg1Prc')}
            >
              {t('bond.tableLabel.buyPrice')}
              {showSortIcon('leg1Prc')}
            </th>
            <th
              className="text text--light3  fw-500 sortable filterIcon user-select-none"
              onClick={() => sortHandle('minInvtQtyPerOrdr')}
            >
              {t('bond.tableLabel.minQuantityOrder')}
              {showSortIcon('minInvtQtyPerOrdr')}
            </th>
            <th
              className="text text--light3  fw-500 sortable filterIcon user-select-none"
              onClick={() => sortHandle('maxInvtQtyPerCust')}
            >
              {t('bond.tableLabel.maxInvtQtyPerCust')}
              {showSortIcon('maxInvtQtyPerCust')}
            </th>
            <th
              className="text text--light3  fw-500 sortable filterIcon user-select-none"
              onClick={() => sortHandle('selRemain')}
            >
              {t('bond.tableLabel.availQuantity')}
              {showSortIcon('selRemain')}
            </th>
            <th className="text text--light3  fw-500 user-select-none">
              {t('txt-actions')}
            </th>
          </thead>
        </table>
        <div
          style={{
            height: `calc(100% - 185px + ${token ? '0px' : '32px'} + ${
              data?.find((prod) => prod.proInvtYN == 'Y') ? '0px' : '32px'
            })`,
          }}
        >
          <PerfectScrollBar>
            {groups &&
              groups.map((group, i) => (
                <GroupTableRow
                  key={i}
                  colgroup={colgroup}
                  groupName={`${t('bond.terms')} ${group}`}
                  data={data.filter((x) => x.termName == group)}
                  sortField={sortField}
                  sortDirection={sortDirection}
                  setModalSize={setModalSize}
                />
              ))}
          </PerfectScrollBar>
        </div>
        <div class="investor-infors">
          {data && data?.find((prod) => prod.proInvtYN == 'Y') && (
            <div className="investor-infor investor-note">
              <span className="d-flex align-items-center">
                <span className="icon icon--sm iStar mr-2"></span>
                <span>{t('bond.productForProInvestor')}</span>
              </span>
            </div>
          )}
          {token && (
            <>
              <div className="line"></div>
              <div className="investor-infor d-flex">
                <span>
                  <span className="text text--light3 fw-400 mr-2">
                    {t('bond.maxBuy.value')}
                  </span>
                  <span className="text text--light fw-500">
                    {numberFormat(limitInfor?.buyLmt, 0, '-')}
                  </span>
                </span>
                <span>
                  <span className="text text--light3 fw-400 mr-2">
                    {t('bond.maxBuy.valueLeft')}
                  </span>
                  <span className="text text--light fw-500">
                    {numberFormat(limitInfor?.buyLmtRm, 0, '-')}
                  </span>
                </span>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
);

const GroupTableRow = withNamespaces('translations')(
  ({ t, groupName, data, sortField, sortDirection, colgroup }) => {
    const dispatch = useDispatch();
    const { auth, token, setting } = useSelector((state) => state.client);
    const lang = useSelector((state) => state.client.setting.lang || 'vi');

    const [expand, setExpand] = useState(true);
    const toogle = () => setExpand(!expand);
    const history = useHistory();

    const showData = useMemo(() => {
      if (sortField) {
        const sortData =
          sortDirection == constants.sortDirection.Asc
            ? sortBy(data, sortField)
            : sortBy(data, sortField).reverse();
        return sortData;
      }

      return data;
    }, [data, sortField, sortDirection]);

    const bondDetailModalTypeHandle = (state) => {
      dispatch(setBondDetailModalRequest(state));
    };

    const buyHandle = (product) => {
      if (token) {
        if (auth && auth.isVerified) {
          const isRegistedTradingRetailStatus = setting?.pcbMapYN;
          const proIns = requiredProInvestor(token, product);

          if (
            product?.listTp == '4' &&
            (!isRegistedTradingRetailStatus ||
              isRegistedTradingRetailStatus == 'N')
          ) {
            dispatch(setRegistBondTradingRetailModal(true));
            return;
          }

          if (proIns) {
            dispatch(setProInvRequire(true));
            return;
          }

          if (product?.listTp == '4' && isRegistedTradingRetailStatus == 'P') {
            const toastMsg = {
              id: Math.random(),
              msg: t('bond.retailTrading.pendingNoti'),
              title: t('txt-notice'),
            };

            dispatch(setToast(toastMsg));
            return;
          }

          bondDetailModalTypeHandle(bondConstants.modals.BUY_ORDER);
          dispatch(getBondDetail(product.bondCode, lang));
          dispatch(setBondProductDetail(product));

          Mixpanel.bond.startOrder(
            product.bondCode,
            'Buy',
            product.productTp,
            'Bond Product List'
          );
        } else {
          dispatch(setAuth());
        }
      } else {
        dispatch(setLogin());
      }
    };

    const detailHandle = (product) => {
      bondDetailModalTypeHandle(bondConstants.modals.INFOR);
      dispatch(getBondDetail(product.bondCode, lang));
      dispatch(setBondProductDetail(product));

      Mixpanel.bond.viewTickerInfo(
        product.bondCode,
        product.productTp,
        'Bond',
        'Bond Product List'
      );
    };

    const demoHandle = (product) => {
      dispatch(setBondProductDetail(product));
      if (product.productTp == constants.bondProductType.Fix) {
        history.push(
          `/home/bond/${product.bondCode}?type=${constants.bondProductType.Fix}&term=${product.term}`
        );
      } else {
        history.push(
          `/home/bond/${product.bondCode}?type=${constants.bondProductType.Var}`
        );
      }
      Mixpanel.bond.demo(product.bondCode, product.productTp);
    };

    return (
      <>
        <span
          className="d-flex align-items-center text--light gap-4 table-group__nav"
          onClick={toogle}
        >
          {groupName}
          <span className={`icon ${expand ? 'iUp2' : 'iDown2'}`}></span>
        </span>
        {expand && (
          <table
            class="table table-bordered border-top-0"
            style={{ tableLayout: 'fixed' }}
          >
            {colgroup}
            <tbody>
              {showData &&
                showData.map((item, i) => (
                  <tr key={i}>
                    <td onClick={() => detailHandle(item)}>
                      <span className="text text--light  fw-500 cursor-pointer d-flex align-items-center">
                        {item.bondCode}
                        {item.proInvtYN == 'Y' && (
                          <span className="iStar icon--sx ml-1"></span>
                        )}
                      </span>
                    </td>
                    <td className="text text--light  fw-500">
                      {item.issuerNm}
                    </td>
                    <td className="text text-center text--light3 fw-500">
                      {item.guaranteeYN == 'N' ? (
                        t('txt-no')
                      ) : (
                        <Collaterals bondCode={item.bondCode} />
                      )}
                    </td>
                    <td className="text text-center text--light3  fw-500">
                      {item.issueTpNm}
                    </td>
                    <td className="text text-center r  fw-500">
                      {numberFormat(item.intRate, 2, '0')}% /{' '}
                      {t('bond.bondProduct.year')}
                    </td>
                    <td className="text text-right i  fw-500">
                      {numberFormat(item.leg1Prc, 0)}
                    </td>
                    <td className="text text-right text--light3  fw-500">
                      {numberFormat(item.minInvtQtyPerOrdr)}
                    </td>
                    <td className="text text-right text--light3  fw-500">
                      {numberFormat(item.maxInvtQtyPerCust)}
                    </td>
                    <td className="text text-right text--light  fw-500">
                      {numberFormat(item.selRemain, 0)}
                    </td>
                    <td className="fw-500 text-center">
                      <>
                        <span
                          className="btn btn--demo2"
                          onClick={() => demoHandle(item)}
                        >
                          <span>{t('bond.bondDemo.name')}</span>
                        </span>

                        <button
                          className={`btn ${
                            !token || auth?.isVerified
                              ? 'btn--buy2'
                              : 'btn--authen'
                          }`}
                          onClick={() => buyHandle(item)}
                        >
                          {t('txt-buy')}
                        </button>
                      </>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        )}
      </>
    );
  }
);

export default GroupTable;
