import React, { useState, useEffect, useContext } from 'react';
import './index.scss';
import { withNamespaces } from 'react-i18next';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import MaskedInput from 'react-text-mask';
import { v4 as uuidv4 } from 'uuid';
import { numberFormat, StringToInt } from '../../util';

const MaxInput = withNamespaces('translations')(
  ({
    t,
    index,
    initValue,
    max,
    min,
    disabled,
    callback,
    failback,
    placeholder,
    className,
    maxLength = 18,
  }) => {
    const [value, setValue] = useState();
    const [error, setError] = useState();
    const uuid = uuidv4();

    useEffect(() => {
      if (initValue != null) setValue(initValue);
    }, [initValue]);

    const maxHandle = () => {
      setValue(max);
      if (callback && typeof callback == 'function') callback(max, index);
    };

    const onChangeHandle = (e) => {
      const _value = e.target.value;
      setValue(_value);
      setError(null);
      if (!_value) {
        callback(0, -1);
      } else {
        callback(_value, index);
      }
    };

    const validation = (value) => {
      const _value = StringToInt(value, 10);
      console.log('max validation:  ', max, _value, max && _value > max);
      if (max && _value > max) {
        setError(
          t('transaction.input.maxExceeded') + ': ' + numberFormat(max, 0, '0')
        );
        if (failback && typeof failback == 'function') failback(index);
        return;
      }

      if (min && _value < min) {
        setError(
          t('transaction.input.maxExceeded') + ': ' + numberFormat(min, 0, '0')
        );
        if (failback && typeof failback == 'function') failback(index);
        return;
      }

      setError(null);
    };

    return (
      <div className={`sit-input ${className || ''}`}>
        <div className={`sit-input__value ${error ? 'input-error' : ''}`}>
          <MaskedInput
            mask={createNumberMask({ prefix: '' })}
            max={max}
            min={0}
            placeholder={
              placeholder || t('transaction.stockInternalTransfer.inputAmount')
            }
            guide={false}
            id={`custom-input-${uuid}`}
            onBlur={(e) => validation(e.target.value)}
            onChange={(e) => onChangeHandle(e)}
            value={value}
            disabled={disabled}
            maxLength={maxLength}
          />

          <span
            className="sit-input__overlay"
            onClick={() => {
              if (!disabled) maxHandle();
            }}
          >
            {t('txt-title-max')}
          </span>
        </div>
        {error && (
          <div className="sit-input__error">
            <span className="d-block text-danger text-left">{error}</span>
          </div>
        )}
      </div>
    );
  }
);

export default MaxInput;
