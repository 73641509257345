import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { InputGroup } from 'react-bootstrap';
import { formatDate, _diff2Date, _formatDate } from '../../../util';
import { makeGetToken } from '../../../lib/selector';
import { required } from '../../../util/validation';
import RenderDatePicker from '../../input/renderDatePicker';
import { ReactComponent as SvgTimePicker } from '../../../assets/img/_timerange.svg';
import { ReloadButton } from '../../btnReload';
import { setToast } from '../../../containers/client/actions';
import { translate } from 'react-i18next';
import { isEqual } from 'lodash';

class formCongNo extends Component {
  componentDidMount() {
    setTimeout(() => {
      if (this.refSubmit) this.refSubmit.click();
    }, 200);
  }

  componentDidUpdate(preProps) {
    const { account } = this.props;

    if (account && !isEqual(account, preProps.account)) {
      if (this.refSubmit) this.refSubmit.click();
    }
  }

  submit = (values) => {
    const { status, startDate, endDate } = values;
    const { token, t } = this.props;
    // console.log(status, startDate, endDate)
    if (!token) return;

    // const maxDay = token && token['config'] ? token['config']['maxDay'] : 90;
    // const maxDay = 180;
    const _diff = _diff2Date(endDate, startDate);

    if (_diff < 0) {
      this._handleToast(t('txt-valid-time'));
      return;
    }

    // if (_diff > maxDay) {
    //   this._handleToast(
    //     `${t('txt-valid-range-time')} ${maxDay} ${t('txt-day')}`
    //   );
    //   return;
    // }

    this.props.handleQuery(
      _formatDate(startDate),
      _formatDate(endDate),
      status
    );
  };

  _handleReset = () => {
    const { reset } = this.props;

    reset();
    setTimeout(() => {
      if (this.refSubmit) this.refSubmit.click();
    }, 200);
  };

  _handleChange = (e) => {
    setTimeout(() => {
      if (this.refSubmit) this.refSubmit.click();
    }, 200);
  };

  _handleSubmit = () => {
    if (this.refSubmit) this.refSubmit.click();
  };

  _handleToast = (msg) => {
    const toastMsg = {
      id: Math.random(),
      msg: msg,
      title: this.props.t('txt-notice'),
    };
    this.props.dispatch(setToast(toastMsg));
  };

  render() {
    const { handleSubmit, submitting, startDate, endDate, t } = this.props;

    const _status = [
      { value: '1', label: t('txt-stt-in-date') },
      { value: '2', label: t('txt-stt-due-date') },
      { value: '3', label: t('txt-stt-out-date') },
    ];

    return (
      <form
        className="form-hisMargin d-flex"
        onSubmit={handleSubmit(this.submit)}
      >
        <InputGroup style={{ height: '28px', width: 'auto' }}>
          <InputGroup.Prepend>
            <InputGroup.Text>
              <SvgTimePicker />
            </InputGroup.Text>
          </InputGroup.Prepend>
          <Field
            className="form-control"
            name="startDate"
            type="start"
            startDate={startDate}
            endDate={endDate}
            component={RenderDatePicker}
            validate={required}
            onChange={this._handleChange}
          />
          <InputGroup.Append>
            <InputGroup.Text>-</InputGroup.Text>
          </InputGroup.Append>
          <Field
            className="form-control"
            name="endDate"
            type="end"
            startDate={startDate}
            endDate={endDate}
            component={RenderDatePicker}
            validate={required}
            onChange={this._handleChange}
          />
        </InputGroup>
        <Field
          name="status"
          component="select"
          className="ml-2 form-control form-input fz_14"
          style={{ width: '165px' }}
          onChange={this._handleChange}
        >
          <option value="0">{t('txt-all')}</option>
          {_status.map((statusOption) => (
            <option value={statusOption.value} key={statusOption.value}>
              {statusOption.label}
            </option>
          ))}
        </Field>
        <button
          ref={(el) => (this.refSubmit = el)}
          type="submit"
          style={{ display: 'none' }}
        ></button>
        <ReloadButton
          type="button"
          className="ml-2 btn-reload mr-2"
          disabled={submitting}
          onClick={this._handleReset}
        />
      </form>
    );
  }
}

formCongNo = reduxForm({
  form: 'congNo',
  enableReinitialize: true,
})(formCongNo);

const selector = formValueSelector('congNo');

const makeMapStateToProps = () => {
  const getToken = makeGetToken();

  const mapStateToProps = (state, props) => {
    const token = getToken(state);
    const { status, startDate, endDate } = selector(
      state,
      'status',
      'startDate',
      'endDate'
    );

    // const numDay = token && token['config'] ? token['config']['numDay'] : 30;
    const numDay = 180;

    const d = new Date();
    d.setDate(d.getDate() - numDay);

    return {
      token,
      status,
      startDate,
      endDate,
      initialValues: {
        status: '0',
        startDate: formatDate(d, '/', 'dmy'),
        endDate: formatDate(new Date(), '/', 'dmy'),
      },
    };
  };
  return mapStateToProps;
};

formCongNo = connect(makeMapStateToProps)(formCongNo);

export default translate('translations')(formCongNo);
