import React, { useState, useEffect } from 'react';
import './assetHeader.scss';
import { withNamespaces } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import './assetHeader.scss';
import { setDefaultAccount } from '../../../socket/actions';
import { accountTypeDetect } from '../../../../util';

const listHasAllAccount = ['assets', 'assets-portfolio'];
const hideDeActiveAccount = ['cash-advance'];
const AssetHeader = withNamespaces('translations')(
  ({ t, params, setAccount, setRefresh, refresh, additionRightActions }) => {
    const { typeId } = params;
    const [tabActive, setTabActive] = useState(0);
    const dispatch = useDispatch();

    const { listAccount, defaultAccount } = useSelector(
      (state) => state.socket
    );
    const showUserModel = useSelector(
      (state) => state.layoutReducer.showUserModel
    );

    let routes = [];

    const page = routes.find((x) => x.path == typeId);

    // paging
    const handleRefresh = () => {
      setRefresh(!refresh);
    };

    useEffect(() => {
      if (defaultAccount) {
        setAccount(defaultAccount);
        setTabActive(
          listAccount.findIndex(
            (account) => account.subAcntNo == defaultAccount.subAcntNo
          ) + 1
        );
      }
    }, [defaultAccount, typeId, showUserModel]);

    return (
      <div className="personal-assets-header card-panel-2-header">
        <span className="personal-assets-header__left">
          {listHasAllAccount.includes(typeId) && (
            <span
              className={`card-panel-2__tab ${tabActive == 0 ? 'active' : ''}`}
              onClick={() => {
                setTabActive(0);
                setAccount(null);
              }}
            >
              {t('personal.asset.totalAccount')}
            </span>
          )}
          {listAccount &&
            listAccount.map(
              (account, i) =>
                !(
                  hideDeActiveAccount.includes(typeId) &&
                  account.subAcntStat === 9
                ) && (
                  <span
                    key={i + 1}
                    className={`card-panel-2__tab ${
                      tabActive == i + 1 ? 'active' : ''
                    }`}
                    onClick={() => {
                      setTabActive(i + 1);
                      setAccount(account);
                      dispatch(setDefaultAccount(account));
                    }}
                  >
                    {`${account.subAcntNo} - ${t(accountTypeDetect(account))}`}
                  </span>
                )
            )}
        </span>
        <div className="personal-assets-header__right">
          <div className="refresh" onClick={handleRefresh}>
            <span className="icon iRefresh mr-1"></span>
            <span>{t('txt-label-refresh-data')}</span>
          </div>
          {!!additionRightActions?.length > 0 &&
            additionRightActions.map((action, i) =>
              React.createElement(action, { key: i }, null)
            )}
        </div>
      </div>
    );
  }
);

export default AssetHeader;
