import React, { useState, useEffect } from 'react';
import { withNamespaces } from 'react-i18next';
import OTPInput from '../../modal/auth/layout/OTPInput';
import MaskInput from './MaskInput';
import { v4 as uuidv4 } from 'uuid';
import { useSelector, useDispatch } from 'react-redux';
import { makeGetToken } from '../../../lib/selector';
import { handleApiErrors } from '../../../lib/api-errors';
import { setToast, unsetClient } from '../../../containers/client/actions';
import { removeCookie } from '../../../lib/storages';
import { Logout, logoutRequest } from '../../../components/modal/login/actions';

const appUrl = `${process.env.REACT_APP_API_URL}`;
const url = `${appUrl}/CoreServlet.pt`;
const numInputs = 6;
const SmartOTP = withNamespaces('translations')(
  ({ t, value = '', onChange, tempToken }) => {
    const dispatch = useDispatch();
    const [otp, setOtp] = useState(value);
    const token = useSelector(makeGetToken());

    useEffect(() => {
      if (otp.length === numInputs) {
        handleSmartOTP(otp);
      } else {
        onChange('');
      }
    }, [otp]);

    const handleSmartOTP = (value) => {
      const uuid = uuidv4();
      const _token = tempToken || token;
      if (_token) {
        const params = {
          group: 'CORE',
          user: _token.user,
          session: _token.session,
          cmd: 'getToken',
          rqId: uuid,
          channel: 'WTS',
          data: {
            cif: _token.cif,
            type: 5 + '',
            value,
            transType: '2',
          },
        };

        fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          },
          body: JSON.stringify(params),
        })
          .then(handleApiErrors)
          .then((response) => response.json())
          .then((res) => {
            if (res.http !== 200) {
              if (res.http === 401) {
                if (_token) dispatch(logoutRequest(_token));

                dispatch(unsetClient());
                removeCookie('token');
                removeCookie('authen');

                dispatch(Logout());
                toastHandle(t('txt-valid-loss-session'));
              } else {
                toastHandle(res.data.message || res.data.token);
              }
            } else {
              const __token = res.data.token;
              onChange(__token);
            }
          })
          .catch((error) => {
            console.error('Error:', error);
          });
      }
    };

    const toastHandle = (msg) => {
      const toastMsg = {
        id: Math.random(),
        msg: msg,
        title: t('txt-notice'),
      };
      dispatch(setToast(toastMsg));
    };

    return (
      <React.Fragment>
        <span>
          {t('account.authen.smartOTPDesc', {
            highlight: 'SmartOTP',
          })}
        </span>
        <div className="authen-input">
          <OTPInput
            value={otp}
            numInputs={6}
            shouldAutoFocus
            containerStyle={{
              display: 'flex',
              alignItems: 'center',
              gap: 8,
            }}
            onChange={(value) => {
              setOtp(value);
            }}
            skipDefaultStyles
            renderInput={({ ref, cell, ...props }) => (
              <span className="otp-section">
                <MaskInput {...props} inputRef={ref} />
              </span>
            )}
          />
        </div>
      </React.Fragment>
    );
  }
);

export default SmartOTP;
