import { BOND_TYPE } from '../../util';
import { constants } from '../../util/constant';

export const getBondListTpType = (type) => {
  return BOND_TYPE.find((x) => x.id == type)?.name || '';
};

export const getBondProductByType = (type) => {
  if (type == constants.bondProductType.Fix) return 'Fix';
  if (type == constants.bondProductType.Var) return 'Flex';
  if (type == constants.bondProductType.Growth) return 'Growth';
};

export const requiredProInvestor = (token, product) => {
  if (token && product.proInvtYN == 'Y') {
    if (token.isProInvt == 'N') return true;
    else return false;
  }
  return false;
};

export const retailTradingRegisted = (token, setting) => {
  if (token && setting?.pcbMapYN == 'Y') return true;
  else return false;
};
