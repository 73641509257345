export const CASH_BALANCE_REQUESTING = 'CASH_BALANCE_REQUESTING';
export const CASH_BALANCE_REQUEST_SUCCESS = 'CASH_BALANCE_REQUEST_SUCCESS';
export const CASH_BALANCE_REQUEST_ERROR = 'CASH_BALANCE_REQUEST_ERROR';

export const SHARE_BALANCE_REQUESTING = 'SHARE_BALANCE_REQUESTING';
export const SHARE_BALANCE_REQUEST_SUCCESS = 'SHARE_BALANCE_REQUEST_SUCCESS';
export const SHARE_BALANCE_REQUEST_ERROR = 'SHARE_BALANCE_REQUEST_ERROR';

export const PURCHASE_POWER_REQUESTING = 'PURCHASE_POWER_REQUESTING';
export const PURCHASE_POWER_REQUEST_SUCCESS = 'PURCHASE_POWER_REQUEST_SUCCESS';
export const PURCHASE_POWER_REQUEST_ERROR = 'PURCHASE_POWER_REQUEST_ERROR';

export const STOCK_BALANCE_REQUESTING = 'STOCK_BALANCE_REQUESTING';
export const STOCK_BALANCE_REQUEST_SUCCESS = 'STOCK_BALANCE_REQUEST_SUCCESS';
export const STOCK_BALANCE_REQUEST_ERROR = 'STOCK_BALANCE_REQUEST_ERROR';

export const POSITION_REQUESTING = 'POSITION_REQUESTING';
export const POSITION_REQUEST_SUCCESS = 'POSITION_REQUEST_SUCCESS';
export const POSITION_REQUEST_ERROR = 'POSITION_REQUEST_ERROR';

export const NEW_ORDER_REQUESTING = 'NEW_ORDER_REQUESTING';
export const NEW_ORDER_REQUEST_SUCCESS = 'NEW_ORDER_REQUEST_SUCCESS';
export const NEW_ORDER_REQUEST_ERROR = 'NEW_ORDER_REQUEST_ERROR';

export const EDIT_ORDER_REQUESTING = 'EDIT_ORDER_REQUESTING';
export const EDIT_ORDER_REQUEST_SUCCESS = 'EDIT_ORDER_REQUEST_SUCCESS';
export const EDIT_ORDER_REQUEST_ERROR = 'EDIT_ORDER_REQUEST_ERROR';

export const CANCEL_ORDER_REQUESTING = 'CANCEL_ORDER_REQUESTING';
export const CANCEL_ORDER_REQUEST_SUCCESS = 'CANCEL_ORDER_REQUEST_SUCCESS';
export const CANCEL_ORDER_REQUEST_ERROR = 'CANCEL_ORDER_REQUEST_ERROR';

export const SYMBOL_INFO_REQUESTING = 'SYMBOL_INFO_REQUESTING';
export const SYMBOL_INFO_RESETING = 'SYMBOL_INFO_RESETING';
export const SYMBOL_INFO_REQUEST_SUCCESS = 'SYMBOL_INFO_REQUEST_SUCCESS';
export const SYMBOL_INFO_REQUEST_ERROR = 'SYMBOL_INFO_REQUEST_ERROR';

export const MATCH_ORDER_REQUESTING = 'MATCH_ORDER_REQUESTING';
export const MATCH_ORDER_REQUEST_SUCCESS = 'MATCH_ORDER_REQUEST_SUCCESS';
export const MATCH_ORDER_REQUEST_ERROR = 'MATCH_ORDER_REQUEST_ERROR';

export const SET_ORDER_SYMBOL = 'SET_ORDER_SYMBOL';
export const SET_ORDER_TYPE = 'SET_ORDER_TYPE';
export const SET_ORDER_PRICE = 'SET_ORDER_PRICE';
export const SET_ORDER_QUANTITY = 'SET_ORDER_QUANTITY';

export const LIST_PUT_THROUGH_REQUESTING = 'LIST_PUT_THROUGH_REQUESTING';
export const LIST_PUT_THROUGH_REQUEST_SUCCESS =
  'LIST_PUT_THROUGH_REQUEST_SUCCESS';
export const LIST_PUT_THROUGH_REQUEST_ERROR = 'LIST_PUT_THROUGH_REQUEST_ERROR';

export const SET_ORDER_G = 'SET_ORDER_G';
