import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { InputGroup } from 'react-bootstrap';

import { formatDate, _diff2Date } from '../../../util';

import { makeGetToken } from '../../../lib/selector';

import { required } from '../../../util/validation';

import RenderDatePicker from '../../input/renderDatePicker';

import { ReactComponent as SvgTimePicker } from '../../../assets/img/_timerange.svg';
import { ReloadButton } from '../../btnReload';
import { setToast } from '../../../containers/client/actions';
import { translate } from 'react-i18next';
class FormHisStockEx extends Component {
  submit = (values) => {
    const { startDate, endDate } = values;
    const { token, t } = this.props;
    console.log(startDate, endDate);
    if (!token) return;

    const maxDay = token && token['config'] ? token['config']['maxDay'] : 90;

    const _diff = _diff2Date(endDate, startDate);
    if (_diff < 0) {
      this._handleToast(t('txt-valid-time'));
      return;
    }
    if (_diff > maxDay) {
      this._handleToast(
        t('txt-valid-range-time') + ' ' + maxDay + ' ' + t('txt-day')
      );
      return;
    }

    this.props.handleQuery(startDate, endDate);
  };

  _handleReset = () => {
    const { reset } = this.props;

    reset();
    setTimeout(() => {
      if (this.refSubmit) this.refSubmit.click();
    }, 100);
  };

  _handleSubmit = () => {
    if (this.refSubmit) this.refSubmit.click();
  };

  _handleChange = (e) => {
    setTimeout(() => {
      if (this.refSubmit) this.refSubmit.click();
    }, 100);
  };

  _handleToast = (msg) => {
    const toastMsg = {
      id: Math.random(),
      msg: msg,
      title: this.props.t('txt-notice'),
    };
    this.props.dispatch(setToast(toastMsg));
  };

  render() {
    const {
      handleSubmit,
      // pristine,
      submitting,
      // reset,
      startDate,
      endDate,
    } = this.props;
    console.log(startDate, endDate);
    return (
      <form
        style={{ zIndex: '0' }}
        className="form-hisMargin d-flex"
        onSubmit={handleSubmit(this.submit)}
      >
        <InputGroup style={{ height: '28px', width: 'auto' }}>
          <InputGroup.Prepend>
            <InputGroup.Text>
              <SvgTimePicker />
            </InputGroup.Text>
          </InputGroup.Prepend>
          {startDate && (
            <Field
              className="form-control"
              name="startDate"
              type="start"
              startDate={startDate}
              endDate={endDate}
              component={RenderDatePicker}
              validate={required}
              onChange={this._handleChange}
            />
          )}
          <InputGroup.Append>
            <InputGroup.Text>-</InputGroup.Text>
          </InputGroup.Append>
          {endDate && (
            <Field
              containerClassName="datepicker-right"
              className="form-control"
              name="endDate"
              type="end"
              startDate={startDate}
              endDate={endDate}
              component={RenderDatePicker}
              validate={required}
              onChange={this._handleChange}
            />
          )}
        </InputGroup>
        <button
          ref={(el) => (this.refSubmit = el)}
          type="submit"
          style={{ display: 'none' }}
        ></button>
        <ReloadButton
          type="button"
          className="ml-2 btn-reload mr-2"
          disabled={submitting}
          onClick={() => this._handleReset()}
        />
      </form>
    );
  }
}

FormHisStockEx = reduxForm({
  form: 'hisStockEx',
  enableReinitialize: true,
})(FormHisStockEx);

const selector = formValueSelector('hisStockEx');

const makeMapStateToProps = () => {
  const getToken = makeGetToken();

  const mapStateToProps = (state, props) => {
    const token = getToken(state);
    const { startDate, endDate } = selector(state, 'startDate', 'endDate');

    const numDay = token && token['config'] ? token['config']['numDay'] : 30;
    const d = new Date();
    d.setDate(d.getDate() - numDay);

    return {
      token,
      startDate,
      endDate,
      initialValues: {
        startDate: formatDate(d, '/', 'dmy'),
        endDate: formatDate(new Date(), '/', 'dmy'),
      },
    };
  };
  return mapStateToProps;
};

FormHisStockEx = connect(makeMapStateToProps)(FormHisStockEx);

export default translate('translations')(FormHisStockEx);
