import React, { useContext, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import { ReactReduxContext, useDispatch, useSelector } from 'react-redux';
import ReactSelect from 'react-select';
import Popup from './confirmDepositModal';
import CheckboxButton from '../../../../components/checkbox/checkBoxButton';
import { v4 as uuidv4 } from 'uuid';
import { handleApiErrors } from '../../../../lib/api-errors';
import {
  Logout,
  logoutRequest,
} from '../../../../components/modal/login/actions';
import { setAuth, setToast, unsetClient } from '../../../client/actions';
import { removeCookie } from '../../../../lib/storages';
import {
  getMessageError,
  mapPfOrderStat,
  numberFormat,
  packDeposit,
} from '../../../../util';
import { getPinefolioInvestmentPlanRequest } from '../../action';

const appUrl = `${process.env.REACT_APP_API_URL}`;
const StopPlanModal = withNamespaces('translations')(
  ({ t, showModal, setShowModal, _account, pfPlan, onCloseEditModal }) => {
    const dispatch = useDispatch();
    const { store } = useContext(ReactReduxContext);
    const theme = useSelector((state) => state.client?.currentTheme);
    const { token, auth } = useSelector((state) => state.client);
    const listAccounts = token?.custInfo.normal;
    const account = listAccounts?.find(
      (item) => item.subAcntNo.startsWith('P') && item.subAcntStat == 1
    );

    const _packDeposited = packDeposit(pfPlan);

    const handleStopPlan = () => {
      if (!token || !auth || !auth.isVerified) {
        dispatch(setAuth());
        return;
      }
      callStopPlan();
    };

    const handleStopSuccess = () => {
      if (!token || (!auth && !auth.isVerified)) return;
      const uuid = uuidv4();
      const params = {
        group: 'CORE',
        user: token.user,
        session: token.session,
        cmd: 'getPFIPList',
        rqId: uuid,
        channel: 'WTS',
        data: {
          cif: token.cif,
          acntNo: account?.acntNo,
          subAcntNo: account?.subAcntNo,
          packNoYN: 'Y',
          planStat: '90',
        },
      };
      dispatch(getPinefolioInvestmentPlanRequest(params));
      onCloseEditModal();
      setShowModal(false);
    };

    const handleClose = () => {
      setShowModal(false);
    };

    const callStopPlan = () => {
      const uuid = uuidv4();
      const params = {
        group: 'CORE',
        user: token.user,
        session: token.session,
        type: auth.type,
        token: auth.token,
        cmd: 'breakPFIP',
        rqId: uuid,
        channel: 'WTS',
        data: {
          cif: token.cif,
          acntNo: _account.acntNo,
          subAcntNo: _account.subAcntNo,
          planId: pfPlan.planId,
        },
      };

      const url = `${appUrl}/PortfolioServlet.pt`;
      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
        body: JSON.stringify(params),
      })
        .then(handleApiErrors)
        .then((response) => response.json())
        .then((res) => {
          if (res.http == 200) {
            const toastMsg = {
              id: Math.random(),
              msg: t('pinefolio.plan.stopPlanSuccess'),
              title: t('txt-notice'),
            };
            dispatch(setToast(toastMsg));
            handleStopSuccess();
          } else {
            if (res.http === 401) {
              if (token) dispatch(logoutRequest(token));
              dispatch(unsetClient());
              removeCookie('token');
              removeCookie('authen');
              dispatch(Logout());
              const toastMsg = {
                id: Math.random(),
                msg: t('txt-valid-loss-session'),
                title: t('txt-notice'),
              };
              dispatch(setToast(toastMsg));
            } else {
              const msgErr = getMessageError(res, store.getState);
              const toastMsg = {
                id: Math.random(),
                msg: msgErr,
                title: t('txt-notice'),
              };
              dispatch(setToast(toastMsg));
            }
          }
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    };

    return (
      <div>
        <Modal
          show={showModal}
          onHide={handleClose}
          onEscapeKeyDown={handleClose}
          dialogClassName="wts-modal"
          backdropClassName="wts-modal-backdrop pinefolio-order-confirm-backdrop"
          contentClassName={`${
            theme.name || 'dark'
          } wts-modal-content custom-modal ${'wts-modal-content--sm'}`}
          centered
          style={{ zIndex: 11120 }}
        >
          <div className="form-withdraw modal-form depositToPortfolio-modal">
            <div className="text-center modal-header">
              <div className="d-flex"></div>
              <div className="">
                <span className="">{`${t('pinefolio.plan.stopPlan')} ${
                  pfPlan.pfName
                }`}</span>
              </div>
              <div></div>
            </div>
            <div className="modal-body popup-body d-flex">
              <div className="modal-body__icon popup-body__icon icon--warning">
                <span className={`icon iInfor `}></span>
              </div>
              <div className="modal-body__content">
                <span className="modal-body__description text--light3 d-flex justify-content-center align-items-center">
                  {t('pinefolio.plan.stopPlanNotice')}
                </span>
              </div>
              <div className="modal-body__content">
                <span className="modal-body__description text--light3 d-flex justify-content-center align-items-center">
                  {/* <span
                    className={`ml-1 mr-1 fw-500 ${
                      mapPfOrderStat(_packDeposited.status)?.color
                    }`}
                  >{`${t(mapPfOrderStat(_packDeposited.status)?.label)}`}</span> */}
                  <span className="ml-1 mr-1 text--light3">
                    {t('pinefolio.plan.depositedValue')}
                  </span>
                  <span className="i fw-500">{`${numberFormat(
                    pfPlan?.depoBndAmt,
                    0,
                    0
                  )}`}</span>
                </span>
              </div>
            </div>
            <div className="modal--footer">
              <div
                className="btn btn--primary w-70"
                onClick={() => handleStopPlan()}
              >
                {t('pinefolio.plan.yesStopPlan')}
              </div>
              <div
                className="btn btn--cancel w-25"
                onClick={() => handleClose()}
              >
                {t('txt-cancel')}
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
);

export default StopPlanModal;
