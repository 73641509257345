import React, { useEffect, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import SwitchCustom from '../../../../components/common/switch';
import {
  mapRightType,
  numberFormat,
  StringToDouble,
  StringToInt,
  _formatDate2,
} from '../../../../util';
import { setAuth, setDblPrice, setLogin } from '../../../client/actions';
import PinefolioAllocations from '../allocations';
import RebalancePortfolioModal from './rebalancePortfolioModal';
import PerfectScrollBar from 'react-perfect-scrollbar';
import RightSection from './rightSection';
import StockOutSection from './stockOutSection';
import SellModal from './sellModal';
import { v4 as uuidv4 } from 'uuid';
import {
  getPFByCode,
  getPFPortfolio,
  getPFPortfolioDetail,
  resetPFPortfolioDetail,
  setOrder,
} from '../../action';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useHistory } from 'react-router';

const Portfolio = withNamespaces('translations')(({ t }) => {
  const history = useHistory();
  const { token } = useSelector((state) => state.client);
  useEffect(() => {
    if (!token) {
      history.push('/');
    }
  }, [token]);
  return (
    <div className="pinefolio-porfolio pinefolio-panel">
      <PerfectScrollBar style={{ height: '100%' }}>
        <PinefolioSection />
        <StockOutSection />
        <RightSection />
      </PerfectScrollBar>
    </div>
  );
});

const PinefolioSection = withNamespaces('translations')(({ t }) => {
  const { defaultAccount } = useSelector((state) => state.socket);
  const { token, auth } = useSelector((state) => state.client);
  const { pfPortfolio, pfPortfolioDetail, pfOrder } = useSelector(
    (state) => state.pinefolio
  );
  const lang = useSelector((state) => state.client?.setting?.lang || 'vi');
  const [datas, setDatas] = useState([]);
  const [index, setIndex] = useState(-1);
  const [sellOpen, setSellOpen] = useState(false);
  const [rebalancePFCode, setRebalancePFCode] = useState(null);
  // const [listLoading, setListLoading] = useState(false);
  // const [detailLoading, setDetailLoading] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    if (defaultAccount) {
      getList();
    }
  }, [defaultAccount]);

  useEffect(() => {
    if (pfPortfolio) {
      setDatas(pfPortfolio);
    }
  }, [pfPortfolio]);

  const getList = () => {
    if (token) {
      const uuid = uuidv4();

      const params = {
        group: 'CORE',
        cmd: 'getPFPortfolioMst',
        rqId: uuid,
        channel: 'WTS',
        session: token.session,
        user: token.user,

        data: {
          cif: token.cif,
          acntNo: defaultAccount?.acntNo,
          subAcntNo: defaultAccount?.subAcntNo,
          langTp: lang,
        },
      };

      dispatch(getPFPortfolio(params));
    }
  };

  const getDetail = (pfcode, i) => {
    if (i == index) {
      dispatch(resetPFPortfolioDetail());
      setIndex(-1);
    } else {
      if (token) {
        const uuid = uuidv4();

        const params = {
          group: 'CORE',
          cmd: 'getPFPortfolioDtl',
          rqId: uuid,
          channel: 'WTS',
          session: token.session,
          user: token.user,

          data: {
            cif: token.cif,
            acntNo: defaultAccount?.acntNo,
            subAcntNo: defaultAccount?.subAcntNo,
            pfCode: pfcode,
          },
        };

        dispatch(getPFPortfolioDetail(params));
        setIndex(i);
      }
    }
  };

  const sellHandle = (pfCode) => {
    if (token) {
      if (auth && auth.isVerified) {
        dispatch(
          setOrder({
            themeCode: pfCode,
            orderType: 2,
          })
        );

        dispatch(getPFByCode(pfCode));
        setSellOpen(true);
      } else {
        dispatch(setAuth());
      }
    } else {
      dispatch(setLogin());
    }
  };

  const rebalanceHandle = (pfCode) => {
    if (token) {
      if (auth && auth.isVerified) {
        dispatch(getPFByCode(pfCode));
        setRebalancePFCode(pfCode);
      } else {
        dispatch(setAuth());
      }
    } else {
      dispatch(setLogin());
    }
  };

  return (
    <>
      <div className="portfolio-section">
        <div className="portfolio-section__header">
          <span className="portfolio-section__title text--md text--light">
            {t('pinefolio.name')}
          </span>
          <div className="portfolio-section__actions">
            {/* <SwitchCustom
              title={t('pinefolio.portfolio.hideSoldOutPinefolio')}
              // onChange={this._handleHideOddSymbol}
              // checked={hideOddSymbol}
            /> */}
          </div>
        </div>
        <div className="portfolio-section__body">
          <table className="table table-bordered ">
            <colgroup>
              {/** name */}
              <col width="16%"></col>
              {/** avg value / set */}
              <col width="13.5%"></col>
              {/** market value / set */}
              <col width="8.5%"></col>
              {/** performance */}
              <col width="8%"></col>
              {/** avail quantity */}
              <col width="11.5%"></col>
              {/** total quantity */}
              <col width="9.5%"></col>
              {/** Allocation */}
              <col width="26%"></col>
              {/** action */}
              <col width="6.5%"></col>
              {/** # */}
              <col width="30px"></col>
            </colgroup>
            <thead>
              <th className="text-left">{t('pinefolio.portfolio.name')}</th>
              <th className="text-right">
                <span className="d-flex justify-content-end">
                  <span>{t('pinefolio.portfolio.avgValue')}</span>
                  <OverlayTrigger
                    trigger={['hover', 'focus']}
                    placement="top"
                    className="portfolio-overlay"
                    overlay={
                      <Tooltip style={{ 'z-index': '99999' }}>
                        {t('pinefolio.portfolio.avgValueExplain')}
                      </Tooltip>
                    }
                  >
                    <span className="icon iInfor iTooltip ml-1 mr-1"></span>
                  </OverlayTrigger>
                </span>
              </th>
              <th className="text-right">
                {t('pinefolio.portfolio.mrkValue')}
              </th>
              <th className="text-right">
                <span className="d-flex justify-content-end">
                  <span>{t('pinefolio.portfolio.performance.name')}</span>
                  <OverlayTrigger
                    trigger={['hover', 'focus']}
                    placement="top"
                    className="portfolio-overlay"
                    overlay={
                      <Tooltip style={{ 'z-index': '99999' }}>
                        {t('pinefolio.portfolio.performance.explain')}
                      </Tooltip>
                    }
                  >
                    <span className="icon iInfor iTooltip ml-1 mr-1"></span>
                  </OverlayTrigger>
                </span>
              </th>
              <th className="text-right">
                <span className="d-flex justify-content-end">
                  <span>{t('pinefolio.portfolio.availQty')}</span>
                  <OverlayTrigger
                    trigger={['hover', 'focus']}
                    placement="top"
                    className="portfolio-overlay"
                    overlay={
                      <Tooltip style={{ 'z-index': '99999' }}>
                        {t('pinefolio.portfolio.availExplain')}
                      </Tooltip>
                    }
                  >
                    <span className="icon iInfor iTooltip ml-1 mr-1"></span>
                  </OverlayTrigger>
                </span>
              </th>
              <th className="text-right">
                <span className="d-flex justify-content-end">
                  <span> {t('pinefolio.portfolio.totalQ.name')}</span>
                  <OverlayTrigger
                    trigger={['hover', 'focus']}
                    placement="top"
                    className="portfolio-overlay"
                    overlay={
                      <Tooltip style={{ 'z-index': '99999' }}>
                        {t('pinefolio.portfolio.totalQ.explain')}
                      </Tooltip>
                    }
                  >
                    <span className="icon iInfor iTooltip ml-1 mr-1"></span>
                  </OverlayTrigger>
                </span>
              </th>
              <th className="text-left">
                {t('pinefolio.portfolio.allocation')}
              </th>
              <th className="text-center">{t('pinefolio.portfolio.action')}</th>
              <th></th>
            </thead>
            <tbody>
              {datas &&
                !!datas.length > 0 &&
                datas.map((data, i) => {
                  const syms = data.symList.map((s) => ({
                    symbol: s.symbol,
                    qty: s.totalQty,
                  }));

                  return (
                    <>
                      <tr key={i}>
                        <td>
                          <span className="d-flex flex-column px-1">
                            <span className="text--light text--sm">
                              {data.pfCode}
                            </span>
                            <span className="text--light3 text--sm">
                              {data.pfNm}
                            </span>
                          </span>
                        </td>
                        <td className="text-right text--light">
                          {numberFormat(data.avgValue, 0, '0')}
                        </td>
                        <td className="text-right text--light">
                          {numberFormat(data.marketValue, 0, '0')}
                        </td>
                        <td className="text-right r">
                          {numberFormat(data.performanceRate, 2, '0')} %
                        </td>
                        <td className="text-right text--light">
                          {numberFormat(data.availQtySet, 0, '0')}
                        </td>
                        <td className="text-right text--light">
                          {numberFormat(data.totalQtySet, 0, '0')}
                        </td>
                        <td>
                          <PinefolioAllocations
                            symbols={syms}
                            hiddenNoVol={false}
                          />
                        </td>
                        <td>
                          <div className="d-flex flex-column gap-4">
                            {data.rebalance != 0 && (
                              <button
                                className={`btn ${
                                  token && auth.isVerified
                                    ? 'btn--primary2'
                                    : ' btn--authen'
                                }`}
                                onClick={() => {
                                  if (data.rebalance != 0)
                                    rebalanceHandle(data.pfCode);
                                }}
                              >
                                {t('pinefolio.rebalance.name')}
                              </button>
                            )}

                            <button
                              className={`btn ${
                                token && auth.isVerified
                                  ? 'btn--sell2'
                                  : 'btn--authen'
                              }`}
                              onClick={() => sellHandle(data.pfCode)}
                            >
                              {t('txt-sell')}
                            </button>
                          </div>
                        </td>
                        <td>
                          <div className="text-center">
                            <span
                              className="btn-icon btn--transparent"
                              onClick={() => getDetail(data.pfCode, i)}
                            >
                              <span className="icon iDown2"></span>
                            </span>
                          </div>
                        </td>
                      </tr>

                      {i === index && (
                        <ExpandRow
                          index={i}
                          auth={auth}
                          detail={pfPortfolioDetail}
                          key={-1}
                        />
                      )}
                    </>
                  );
                })}
            </tbody>
          </table>
          {(!datas || !datas.length) && (
            <p className="text-center fz_14 mt-2 title-time">
              {t('txt-no-data-found')}
            </p>
          )}
        </div>
      </div>

      <SellModal
        show={sellOpen}
        closeHandle={() => {
          dispatch(setOrder(null));
          setSellOpen(false);
        }}
      />

      <RebalancePortfolioModal
        show={rebalancePFCode != null}
        closeHandle={() => {
          setRebalancePFCode(null);
        }}
        pfCode={rebalancePFCode}
      />
    </>
  );
});

const ExpandRow = withNamespaces('translations')(
  ({ index, t, detail, colSpan = 10 }) => {
    const setColor = (a, b) => {
      if (a > b) return 'i';
      else if (a < b) return 'd';
      else return 'r';
    };

    const sum = (items, prop) => {
      return items.reduce(function (a, b) {
        return a + b[prop];
      }, 0);
    };

    let buyAmount = 0,
      marketValue = 0,
      pl = 0,
      plPercent = 0;

    if (detail) {
      buyAmount = sum(detail, 'buyAmount');
      marketValue = sum(detail, 'marketValue');
      pl = sum(detail, 'pl');
      if (buyAmount != 0) plPercent = (pl * 100) / buyAmount;
    }

    return (
      <tr key={`expandRow_${index}`} className="expand-row">
        <td className="no-hover" colSpan={colSpan}>
          <div className="expand-row__content">
            <table className="table table-bordered tbl-detail">
              <thead>
                <tr className="bg-dark3">
                  <th className="text-center" rowSpan={2}>
                    {t('txt-symbol')}
                  </th>
                  <th className="text-right" rowSpan={2}>
                    {t('txt-total-vol')}
                  </th>
                  <th className="text-center" rowSpan={1} colSpan={2}>
                    {t('pinefolio.portfolio.availQty')}
                  </th>
                  <th className="text-right" rowSpan={2}>
                    {t('txt-total-dividend-vol')}
                  </th>
                  <th className="text-right" rowSpan={2}>
                    {t('txt-label-pending-delivery')}
                  </th>
                  <th className="text-right" rowSpan={2}>
                    {t('txt-label-pending-trading')}
                  </th>
                  <th className="text-right" rowSpan={2}>
                    {t('txt-buy')} T2
                  </th>
                  <th className="text-right" rowSpan={2}>
                    {t('txt-buy')} T1
                  </th>
                  <th className="text-right" rowSpan={2}>
                    {t('txt-buy')} T0
                  </th>
                  <th className="text-right" rowSpan={2}>
                    {t('txt-ave')}
                  </th>
                  <th className="text-right" rowSpan={2}>
                    {t('txt-market-price')}
                  </th>
                  <th className="text-right" rowSpan={2}>
                    {t('txt-change-in-day')}
                  </th>
                  <th className="text-right" rowSpan={2}>
                    % {t('txt-change-in-day')}
                  </th>
                  <th className="text-right">{t('txt-label-buy-amt')}</th>
                  <th className="text-right">{t('txt-label-market-value')}</th>
                  <th className="text-right">{t('txt-gain-loss')}</th>
                  <th className="text-center">% G/L</th>
                </tr>
                <tr>
                  <th className="text-center" rowSpan={1} colSpan={1}>
                    <span className="d-flex justify-content-center">
                      <span> {t('pinefolio.portfolio.set')}</span>
                      <OverlayTrigger
                        trigger={['hover', 'focus']}
                        placement="top"
                        className="portfolio-overlay"
                        overlay={
                          <Tooltip style={{ 'z-index': '99999' }}>
                            {t('pinefolio.portfolio.setExplain')}
                          </Tooltip>
                        }
                      >
                        <span className="icon iInfor iTooltip ml-1 mr-1"></span>
                      </OverlayTrigger>
                    </span>
                  </th>
                  <th className="text-center" rowSpan={1} colSpan={1}>
                    <span className="d-flex justify-content-center">
                      <span> {t('pinefolio.portfolio.odd')}</span>
                      <OverlayTrigger
                        trigger={['hover', 'focus']}
                        placement="top"
                        className="portfolio-overlay"
                        overlay={
                          <Tooltip style={{ 'z-index': '99999' }}>
                            {t('pinefolio.portfolio.oddExplain')}
                          </Tooltip>
                        }
                      >
                        <span className="icon iInfor iTooltip ml-1 mr-1"></span>
                      </OverlayTrigger>
                    </span>
                  </th>
                  <th className="text-right text--light">
                    {numberFormat(buyAmount, 0, '0')}
                  </th>
                  <th className="text-right text--light">
                    {numberFormat(marketValue, 0, '0')}
                  </th>
                  <th className={`text-right ${setColor(pl, 0)}`}>
                    {numberFormat(pl, 0, '0')}
                  </th>
                  <th className={`text-center ${setColor(plPercent, 0)}`}>
                    {numberFormat(plPercent, 2, '0')} %
                  </th>
                </tr>
              </thead>

              <tbody>
                {detail &&
                  !!detail.length &&
                  detail.map((item, i) => (
                    <tr key={i} className="bg-dark3">
                      <td>
                        <div className="d-flex gap-4">
                          <span
                            className={`icon ${
                              item?.rebalance == 1
                                ? 'iIn i'
                                : item?.rebalance == 2
                                ? 'iOut d'
                                : 'invisible'
                            }`}
                          ></span>
                          <span className="fw-500 text--light">
                            {item.symBol}
                          </span>
                        </div>
                      </td>
                      <td className="text-right text--light">
                        {numberFormat(item.totalQty, 0, '0')}
                      </td>
                      <td className="text-right text--light">
                        {numberFormat(item.availInSet, 0, '0')}
                      </td>
                      <td className="text-right text--light">
                        {numberFormat(item.availOddSet, 0, '0')}
                      </td>
                      <td className="text-right text--light">
                        {numberFormat(item.devidendQty, 0, '0')}
                      </td>
                      <td className="text-right text--light">
                        {numberFormat(item.sellTn, 0, '0')}
                      </td>
                      <td className="text-right text--light">
                        {numberFormat(item.waitTrdQty, 0, '0')}
                      </td>
                      <td className="text-right text--light">
                        {numberFormat(item.stkT2, 0, '0')}
                      </td>
                      <td className="text-right text--light">
                        {numberFormat(item.stkT1, 0, '0')}
                      </td>
                      <td className="text-right text--light">
                        {numberFormat(item.stkT0, 0, '0')}
                      </td>
                      <td className="text-right fw-500">
                        {numberFormat(item.avgPrice / 1000, 3, '0')}
                      </td>
                      <td className={`text-right fw-500`}>
                        {numberFormat(item.marketPrice / 1000, 2, '0')}
                      </td>
                      <td
                        className={`text-right ${setColor(item.chgInday, 0)}`}
                      >
                        {numberFormat(item.chgInday / 1000, 2, '0')}
                      </td>
                      <td
                        className={`text-right ${setColor(item.chgInday, 0)}`}
                      >
                        {item.chgIndayPercent} %
                      </td>
                      <td className="text-right text--light">
                        {numberFormat(item.buyAmount, 0, '0')}
                      </td>
                      <td className="text-right text--light">
                        {numberFormat(item.marketValue, 0, '0')}
                      </td>
                      <td className={`text-right ${setColor(item.pl, 0)}`}>
                        {numberFormat(item.pl, 0, '0')}
                      </td>
                      <td
                        className={`text-center ${
                          item.plPercent > 0
                            ? 'i'
                            : item.plPercent < 0
                            ? 'd'
                            : 'r'
                        }`}
                      >
                        {`${item.plPercent} %`}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            {(!detail || !detail.length) && (
              <p className="text-center fz_14 mt-2 title-time">
                {t('txt-no-data-found')}
              </p>
            )}
          </div>
        </td>
      </tr>
    );
  }
);

export default Portfolio;
