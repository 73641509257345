import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import client from '../containers/client/reducer';
import socket from '../containers/socket/reducer';
import priceBoard from '../containers/banggia/reducer';
import cash from '../containers/cash/reducer';
import summary from '../containers/summary/reducer';
import customer from '../containers/customer/reducer';
import privData from '../containers/privData/reducer';

import login from '../components/modal/login/reducer';
import authen from '../components/modal/auth/reducer';
import resetPass from '../components/modal/resetPass/reducer';
import ordCond from '../components/modal/ordCond/reducer';
import stockDetail from '../components/modal/stockDetail/reducer';
import personal from '../containers/personal/reducer';
import layoutReducer from '../containers/defaultLayout/reducer';
import bond from '../containers/bond/reducer';
import news from '../containers/news/reducer';
import pinefolio from '../containers/pinefolio/reducer';
import marginCompetition from '../containers/marginCompetition/reducer';
import derivative from '../containers/derivative/reducer';
import notifications from '../components/modal/notification/reducer';

const IndexReducer = combineReducers({
  login,
  authen,
  resetPass,
  ordCond,
  stockDetail,
  client,
  form,
  socket,
  priceBoard,
  cash,
  summary,
  customer,
  privData,
  personal,
  layoutReducer,
  bond,
  news,
  pinefolio,
  marginCompetition,
  derivative,
  notifications,
});

export default IndexReducer;
