import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { connect } from 'react-redux';
import DatePicker from '../../../components/datePicker2';
import {
  makeGetCashBalance,
  makeGetSummaryCash,
  makeGetSummaryStock,
} from '../../../lib/selector';

import PerfectScrollBar from 'react-perfect-scrollbar';
import { Col, Button } from 'react-bootstrap';

import {
  numberFormat,
  _convertTpToPrice,
  _getOrderStatus,
  StringToInt,
  _formatDate2,
  _diff2Date,
  _formatDate,
} from '../../../util';
import { v4 as uuidv4 } from 'uuid';
import { sortBy } from 'lodash';

import Page from '../../../components/print/Page';
import PrintButton from '../../../components/print/PrintButton';

import logo from '../../../assets/img/pinetreeSecurities.svg';
import ExportSecuritiesStatement from '../components/exports/securitiesStatementExcel';

import { withNamespaces } from 'react-i18next';
import { useEffect } from 'react';
import { useState } from 'react';
import AssetHeader from '../components/header/assetHeader';
import OverviewMetric from '../components/header/overviewMetric';
import PaginationTable from '../../../components/PaginationTable';
import { summaryCashRequest } from '../../summary/actions';
import { setToast } from '../../client/actions';
import moment from 'moment';
import CashStatementExcel from '../components/exports/cashStatementExcel';
import { Mixpanel } from '../../../lib/mixpanel';

const CashStatement = withNamespaces('translations')(
  ({ t, loadBalanceHandle, params }) => {
    const { cashList } = useSelector((state) => state.summary);
    const { defaultAccount } = useSelector((state) => state.socket);

    const [refresh, setRefresh] = useState(false);
    const [tabActive, setTabActive] = useState(0);
    const [account, setAccount] = useState();

    useEffect(() => {
      setAccount(defaultAccount);
      setRefresh(!refresh);
    }, [defaultAccount]);

    useEffect(() => {
      mapCashList(cashList);
    }, [cashList]);

    const mapCashList = (cashList) => {
      if (!cashList || !cashList.list) return null;

      let _cashList = sortBy(cashList.list, ['tranDate']);

      let bgAmt = cashList.beginAmt || 0;

      _cashList.map((item) => {
        item.beginAmt = bgAmt;
        item.afterAmt = bgAmt + item.inAmt - item.outAmt;
        bgAmt = item.afterAmt;
      });
      cashList.list = [..._cashList].reverse();
      return cashList;
    };

    return (
      <div className="card-panel-2 statement-container cash-statement h-100">
        <AssetHeader
          account={account}
          setAccount={setAccount}
          params={params}
          setRefresh={setRefresh}
          tabActive={tabActive}
          refresh={refresh}
        />
        <div className="card-panel-2-body">
          <OverviewMetric
            key={refresh}
            loadBalanceHandle={loadBalanceHandle}
            account={account}
          />
        </div>
        <Filter
          refresh={refresh}
          account={account}
          cashList={cashList}
          key={refresh}
        />
      </div>
    );
  }
);

const Filter = withNamespaces('translations')(
  ({ t, account, cashList, refresh }) => {
    const dispatch = useDispatch();
    const currentDate = new Date();
    const [fromDate, setFromDate] = useState(
      new Date(currentDate.setDate(currentDate.getDate() - 90))
    );
    const [toDate, setToDate] = useState(new Date());
    const { token } = useSelector((state) => state.client);

    const onFilter = () => {
      getCashListHandle(fromDate, toDate);
    };

    const getCashListHandle = (fromDate, toDate) => {
      if (!token || !account) return;
      const maxDay = token && token['config'] ? token['config']['maxDay'] : 90;

      const _diff = _diff2Date(toDate, fromDate);
      if (_diff < 0) {
        handleToast(t('txt-valid-time'));
        return;
      }
      if (_diff > maxDay) {
        handleToast(`${t('txt-valid-range-time')} ${maxDay} ${t('txt-day')}`);
        return;
      }

      const uuid = uuidv4();

      const params = {
        group: 'CORE',
        user: token.user,
        session: token.session,
        cmd: 'GetCashstatement',
        rqId: uuid,
        channel: 'WTS',
        data: {
          acntNo: account ? account?.acntNo : '',
          subAcntNo: account ? account.subAcntNo : '',
          fromDate: fromDate,
          toDate: toDate,
          nextKey: '1',
          reqCount: 1000,
        },
      };

      dispatch(summaryCashRequest(params));
    };

    useEffect(() => {
      if (!token) return;
      getCashListHandle(
        moment(new Date(new Date().setDate(new Date().getDate() - 90))).format(
          'YYYYMMDD'
        ),
        moment(new Date()).format('YYYYMMDD')
      );
    }, [account, refresh]);

    const handleToast = (msg) => {
      const toastMsg = {
        id: Math.random(),
        msg: msg,
        title: t('txt-notice'),
      };
      dispatch(setToast(toastMsg));
    };

    const columns = [
      {
        key: '_index',
        text: t('txt-stt'),
        className: 'text-center text--light3',
        align: 'center',
      },
      {
        key: 'tranDate',
        text: t('txt-time'),
        className: 'text-left text--light3 text-center',
        align: 'center',
        cell: (record) => {
          return _formatDate2(record.tranDate);
        },
      },
      {
        key: 'tranKndNm',
        text: t('txt-label-major'),
        className: 'text-left text--light3',
        align: 'left',
      },
      {
        key: 'beginAmt',
        text: t('txt-label-opening-balance'),
        className: 'text-right fw-500 text--light',
        align: 'right',
        cell: (record) => {
          return numberFormat(record.beginAmt, 0, '-');
        },
      },
      {
        key: 'inAmt',
        text: t('txt-change'),
        className: (record) => {
          if (record.inAmt > 0) return 'text-right fw-500 i';
          else return 'text-right fw-500 d';
        },
        align: 'right',
        cell: (record) => {
          if (record.inAmt > 0) return numberFormat(record.inAmt, 0, '-');
          else return '-' + numberFormat(record.outAmt, 0, '-');
        },
      },
      {
        key: 'afterAmt',
        text: t('txt-label-closing-balance'),
        className: 'text-right text--light  fw-500',
        align: 'right',
        cell: (record) => {
          return numberFormat(record.afterAmt, 0, '-');
        },
      },
      {
        key: 'rmrk',
        text: t('txt-label-remark'),
        className: 'text-left text--light3',
        align: 'left',
      },
    ];
    return (
      <PerfectScrollBar className="mt-36 h-100">
        <Col className="d-flex flex-column h-100">
          <div className="d-flex justify-content-between align-items-center statement__filters">
            <label className="mr-auto text-white text-title">
              {t('txt-label-cash-statement')}
            </label>
            <div className="d-flex">
              <div className="mr-1">
                <DatePicker
                  startDate={fromDate}
                  endDate={toDate}
                  callback={(from, to) => {
                    setFromDate(_formatDate(from));
                    setToDate(_formatDate(to));
                  }}
                />
              </div>
              <button
                className="btn btn--primary h-24 mr-1"
                onClick={onFilter}
              >{`${t('txt-search')}`}</button>
            </div>
            <div className="d-flex align-items-center">
              <CashStatementExcel
                csvData={cashList ? cashList.list : []}
                fileName="sao-ke-giao-dich-chung-khoan"
                fromDate={_formatDate2(fromDate)}
                toDate={_formatDate2(toDate)}
                accountCode={account && account.subAcntNo}
                accountName={account && account.vsdAcntNo}
              />
              <PrintButton
                id={'sao-ke-giao-dich-tien'}
                callback={() =>
                  Mixpanel.track('Export Statement', {
                    'Statement Type': 'Cash Statement',
                  })
                }
              />
            </div>
          </div>
          <PaginationTable
            tblClassName="tbl-list"
            divClassName=" "
            columns={columns}
            source={cashList && cashList.list ? cashList.list : []}
            hasPaging
          />
          <div className="pb-5"></div>
        </Col>
        <Page id="sao-ke-giao-dich-tien" style={{ display: 'none' }}>
          <div className="d-flex flex-column bg-light">
            <div
              className="position-relative d-flex justify-content-center align-items-center"
              style={{ height: '75px' }}
            >
              <img
                src={logo}
                alt="logo"
                className="position-absolute"
                style={{ top: '0', left: '0' }}
              />
              <span className="fz_16 fw-500 text-uppercase">
                {'SAO KÊ GIAO DỊCH TIỀN'}
              </span>
            </div>
            <div className="d-flex mt-2">
              <Col className="d-flex flex-column">
                <div className="d-flex">
                  <span className="fz_14 w_125">{'Tên BC:'}</span>
                  <span className="fz_14 f_i">{'Sao kê giao dịch tiền'}</span>
                </div>
                <div className="d-flex">
                  <span className="fz_14 w_125">{'Số tiểu khoản:'}</span>
                  <span className="fz_14 f_i">
                    {account && account.subAcntNo}
                  </span>
                </div>
                <div className="d-flex">
                  <span className="fz_14 w_125">{'Số VSD:'}</span>
                  <span className="fz_14 f_i">
                    {account && account.vsdAcntNo}
                  </span>
                </div>
              </Col>
              <Col className="d-flex flex-column">
                <div className="d-flex">
                  <span className="fz_14 w_125">{'Từ ngày:'}</span>
                  <span className="fz_14 f_i">
                    {fromDate && moment(fromDate).format('DD/MM/YYYY')}
                  </span>
                </div>
                <div className="d-flex">
                  <span className="fz_14 w_125">{'Đến ngày:'}</span>
                  <span className="fz_14 f_i">
                    {toDate && moment(toDate).format('DD/MM/YYYY')}
                  </span>
                </div>
              </Col>
            </div>
          </div>
          <div className="d-flex bg-light">
            <table className="table table-bordered mt-2 table-light">
              <thead>
                <tr>
                  <th className="text-center">{'STT'}</th>
                  <th className="text-left">{'Thời gian'}</th>
                  <th className="text-left">{'Nghiệp vụ'}</th>
                  <th className="text-right">{'Số dư đầu kỳ'}</th>
                  <th className="text-right">{'Phát sinh tăng'}</th>
                  <th className="text-right">{'Phát sinh giảm'}</th>
                  <th className="text-right">{'Số dư cuối kỳ'}</th>
                  <th className="text-left">{'Mô tả'}</th>
                </tr>
              </thead>
              <tbody>
                {cashList &&
                  cashList.list &&
                  !!cashList.list.length &&
                  cashList.list.map((item, i) => {
                    return (
                      <tr key={i}>
                        <td className="text-center">{i + 1}</td>
                        <td className="text-left">
                          {_formatDate2(item.tranDate)}
                        </td>
                        <td className="text-left">{item.tranKndNm}</td>
                        <td className="text-right">
                          {numberFormat(item.beginAmt, 0, '0')}
                        </td>
                        <td className="text-right">
                          {numberFormat(item.inAmt, 0, '0')}
                        </td>
                        <td className="text-right">
                          {numberFormat(item.outAmt, 0, '0')}
                        </td>
                        <td className="text-right">
                          {numberFormat(item.afterAmt, 0, '0')}
                        </td>
                        <td className="text-left word-beak-all">{item.rmrk}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </Page>
      </PerfectScrollBar>
    );
  }
);
export default CashStatement;
