import { filter, find, groupBy, isArray, result, sortBy, sumBy } from 'lodash';
import { createSelector } from 'reselect';
import { Ser4Cust } from '../util/constant';

export const getMessage = (state, props) => {
  const { setting } = state.client;
  const { listMessage } = state.priceBoard;

  const lang = setting?.lang || 'vi';

  const msg = listMessage[props.data.messageNo];
  if (msg) {
    return msg[lang];
  }

  return (
    props.data.message ||
    props.data.errorMessage ||
    'Có lỗi trong quá trình xử lý. Hãy thử lại sau!'
  );
};

const getSetting = (state) => state.client.setting;
const getCurrentTheme = (state) => state.client.currentTheme;
const getToken = (state) => state.client.token;
const getAuth = (state) => state.client.auth;
const getCategory = (state) => state.client.category;
const getToast = (state) => state.client.toast;
const getConfig = (state) => state.client.config;
const getListAccount = (state) => state.socket.listAccount;
const getDefaultAccount = (state) => state.socket.defaultAccount;
const getOrderList = (state) => state.socket.orderList;
const getListOrder = (state) => state.socket.listOrder;
const getEventOrder = (state) => state.socket.event;

const getSocketStatus = (state) => state.socket.serverStatus;
const getSocketReady = (state) => state.socket.socketReady;

const getNewOrder = (state) => {
  return {
    newOrder: state.privData.newOrder ? state.privData.newOrder.data : null,
    newOrderSuccess: state.privData.newOrderSuccess,
    newOrderErrors: state.privData.newOrderErrors,
  };
};
const getEditOrder = (state) => {
  return {
    editOrder: state.privData.editOrder ? state.privData.editOrder.data : null,
    editOrderSuccess: state.privData.editOrderSuccess,
    editOrderErrors: state.privData.editOrderErrors,
  };
};
const getCancelOrder = (state) => {
  return {
    cancelOrder: state.privData.cancelOrder
      ? state.privData.cancelOrder.data
      : null,
    cancelOrderSuccess: state.privData.cancelOrderSuccess,
    cancelOrderErrors: state.privData.cancelOrderErrors,
  };
};

const getCashBalance = (state) => {
  if (state.privData.cashBalance) return state.privData.cashBalance.data;

  return null;
};
const getStockBalance = (state) => {
  if (state.privData.stockBalance) return state.privData.stockBalance.data;

  return null;
};
const getPositions = (state) => {
  if (state.privData.positions) return state.privData.positions.data;

  return null;
};
const getPurchasePower = (state) => {
  if (state.privData.purchasePower) return state.privData.purchasePower.data;

  return null;
};
const getSymbolInfo = (state) => {
  if (state.privData.symbolInfo) return state.privData.symbolInfo.data.list[0];

  return null;
};

const getSymbolMargin = (state) => {
  if (state.privData.symbolInfo) return state.privData.symbolInfo.dataMargin;

  return null;
};

const getMatchOrder = (state) => {
  if (state.privData.matchOrder) return state.privData.matchOrder.data;

  return null;
};

const getTradingSymbol = (state) => {
  return state.privData.tradingSymbol;
};

const getStockDetail = (state) => state.stockDetail.stockDetail;
const getStockTrade = (state) => {
  const { stockTrade } = state.stockDetail;
  if (isArray(stockTrade)) {
    const _stockTrade = sortBy(stockTrade, ['time']);
    return _stockTrade.reverse();
  }
  return [];
};

const getBondInfor = (state) => {
  const module = 'BOND';
  const infor = state.client.appsetting?.find(
    (x) => x.module == module && x.key == 'BOND_INFOR'
  );
  return infor?.values ? JSON.parse(infor?.values) : infor?.value;
};

const getBondToolTip = (state) => {
  const module = 'BOND';
  const infor = state.client.appsetting?.find(
    (x) => x.module == module && x.key == 'BOND_TOOLTIP'
  );
  return infor?.values ? JSON.parse(infor?.values) : infor?.value;
};

const getStockCst = (state) => state.stockDetail.cst;
const getStockKQKD = (state) => state.stockDetail.kqkd;
const getStockCDKT = (state) => state.stockDetail.cdkt;
const getStockHis = (state) => state.stockDetail.stockHis;
const getStockNews = (state) => state.stockDetail.stockNews;
const getNewsDetail = (state) => state.stockDetail.newsDetail;
const getStockEvents = (state) => state.stockDetail.stockEvents;
const getStockManage = (state) => state.stockDetail.stockManage;
const getCompanyInfo = (state) => state.stockDetail.companyInfo;

const getStockSource = (state) => state.cash.stockSource;
const getStockTarget = (state) => state.cash.stockTarget;
const getSourceBalance = (state) => state.cash.sourceBalance;
const getTargetBalance = (state) => state.cash.targetBalance;
const getCashTransfer = (state) => state.cash.cashTransfer;
const getCashOut = (state) => state.cash.cashOut;
const getListTransfer = (state) => state.cash.hisTransfer;
const getBankAccount = (state) => state.cash.bankAcc;
const getBankList = (state) => state.cash.bankList;
const getDebt = (state) => state.cash.debt;
const getRepayLoan = (state) => state.cash.repayLoan;
const getDebtHis = (state) => state.cash.hisDebt;
const getDebtHis2 = (state) => state.cash.hisDebt2;
const getCashInBank = (state) => state.cash.cashInBank;
const getHoldBank = (state) => state.cash.holdBank;
const getHoldList = (state) => state.cash.holdList;
const getRightList = (state) => state.cash.rightList;
const getMarginList = (state) => state.cash.marginList;
const getGainLossList = (state) => state.cash.gainLossList;

const indexList = (state) => state.priceBoard.indexList;
const worldList = (state) => state.priceBoard.worldList;
const sortField = (state) => state.priceBoard.sortField;
const isAsc = (state) => state.priceBoard.isAsc;
const getPart = (state) => {
  if (!state.priceBoard.partId) return null;
  return state.priceBoard.partId;

  // return sortBy(state.priceReducer.part, ['sym']);
};
const getAllStock = (state) => state.priceBoard.allStock;
const getSnapShot = (state) => state.priceBoard.snapshot;
const getAllOrder = (state) => state.priceBoard.allOrder;
const getCWList = (state) => state.priceBoard.cwList;
const getList30 = (state) => state.priceBoard.list30;
const getIndustry = (state) => state.client.industry;

const getPriceTableSymbols = (state) => state.priceBoard.currentSymbols;
const getIndexSummary = (state) => state.priceBoard.indSum;
const getNoiCap = (state, props) => {
  const { profile } = state.customer;
  const { noicap } = state.priceBoard;
  if (!profile) return null;

  if (!noicap) return profile.custIdIssuLoca;

  const _noicap = find(noicap, (o) => o.orderno === profile.custIdIssuLoca);
  return _noicap ? _noicap.name : profile.custIdIssuLoca;
};
const getListBank = (state) => {
  const { branchList } = state.priceBoard;
  if (!branchList) return null;

  const _allBankName = groupBy(branchList, 'org_abbr_nm');
  const _result = Object.keys(_allBankName).map((key) => ({
    bank: key,
    branch: _allBankName[key],
  }));
  return _result;
};

const getSummaryOrder = (state) => state.summary.ordList;
const getSummaryOrderCond = (state) => {
  return state.summary.ordCondList;
};
const getSummaryCash = (state) => {
  let { cashList } = state.summary;
  if (!cashList || !cashList.list) return null;

  let _cashList = sortBy(cashList.list, ['tranDate']);

  let bgAmt = cashList.beginAmt || 0;

  _cashList.map((item) => {
    item.beginAmt = bgAmt;
    item.afterAmt = bgAmt + item.inAmt - item.outAmt;
    bgAmt = item.afterAmt;
  });
  cashList.list = [..._cashList];
  return cashList;
};
const getSummaryStock = (state) => state.summary.stockList;
const getSummaryEvent = (state) => state.summary.event;
const getSummaryRightList = (state) => state.summary.rightList;
const getSummaryNotification = (state) => state.summary.notification;

const getListOrderCond = (state) => {
  if (!state.ordCond.listCond || !state.ordCond.listCond.length) return [];

  return sortBy(state.ordCond.listCond, ['createddt', 'ordrNo']).reverse();
};
const getOrderCondition = (state) => ({
  cond: state.ordCond.cond,
  requesting: state.ordCond.requesting,
  success: state.ordCond.successful,
  errors: state.ordCond.errors,
});
const getOrderCondDetail = (state) => ({
  cond: state.ordCond.condDetail,
  errors: state.ordCond.condDetailErrors,
});

const getPutThroughByGroup = (state, props) => {
  const grName = props.ptGr;
  const grCode =
    grName === 'HSX'
      ? '10'
      : grName === 'HNX'
      ? '02'
      : grName === 'UPCOM'
      ? '03'
      : '10';
  const ptList = filter(state.priceBoard.ptList, (o) => o.marketID === grCode);
  return ptList;
};

const getSymbol = (state, props) => {
  const sym = props.record.sym;
  const symbol = find(
    state.priceBoard.allStock,
    (item, i) => item.stock_code === sym
  );
  return symbol;
};

const getSymbolByStock = (state, props) => {
  const symbol = find(
    state.priceBoard.allStock,
    (item, i) => item.stock_code === props.symbol
  );
  return symbol;
};

const getPinnedRow = (state, props) => {
  const { categoryId } = props;
  // if (!state.client.category) return null;
  const _category = getCategory(state);

  const _filter = result(
    find(_category, (o) => o && o.path && o.path.endsWith(categoryId)),
    'pinnedRow'
  );
  return _filter;
};

const getCatalogSelected = (state, props) => {
  const { categoryId } = props;
  // if (!state.client.category) return null;
  const _category = getCategory(state);

  const _filter = find(
    _category,
    (o) => o && o.path && o.path.endsWith(categoryId)
  );

  return _filter;
};

const getTypeIndex = (state) => {
  const { setting } = state.client;
  return (setting && setting.typeIndex) || 1;
};

const getTotalGD = (state) => {
  const { indexList } = state.priceBoard;
  return sumBy(
    indexList,
    (o) => o.value * (['10', '02'].includes(o.mc) ? 1 : 0)
  );
};

const getDataForeign = (state) => {
  let dataForeign = [];
  const { worldList } = state.priceBoard;

  if (worldList && !!worldList.length) {
    worldList.forEach((element) => {
      // if (['Dow Jones', 'S&P 500', 'Nasdaq'].indexOf(element.index) > -1) {
      dataForeign.push({
        name: element.index,
        value: element.last,
        change:
          element.change && element.change.startsWith('+')
            ? element.change.substring(1)
            : element.change,
        changePc:
          element.changePc && element.changePc.startsWith('+')
            ? element.changePc.substring(1)
            : element.changePc,
        cl: element.change.startsWith('+')
          ? 'i'
          : element.change.startsWith('-')
          ? 'd'
          : 'r',
      });
    });
  }

  return dataForeign;
};

const getProfile = (state) => state.customer.profile;
const getCustomerBankAccount = (state) => state.customer.bankAcc;
const getAuthen = (state) => state.authen;

export const makeGetAuthen = createSelector([getAuthen], (authen) => authen);

export const makeGetSymbolByStock = () =>
  createSelector([getSymbolByStock], (symbol) => symbol);

export const makeGetSymbolState = () =>
  createSelector([getSymbol], (symbol) => symbol);

export const makeGetPinnedRow = () =>
  createSelector([getPinnedRow], (symbol) => symbol);

export const makeGetCategorySelected = () =>
  createSelector([getCatalogSelected], (symbol) => symbol);

export const makeGetTypeIndex = () =>
  createSelector([getTypeIndex], (typeIndex) => typeIndex);

export const makeDataForeign = () =>
  createSelector([getDataForeign], (dataForeign) => dataForeign);

export const makeGetTotalGD = () =>
  createSelector([getTotalGD], (totalGd) => totalGd);

export const makeGetIndexList = () =>
  createSelector([indexList], (list) => list);

export const makeGetSortField = () =>
  createSelector([sortField], (sortField) => sortField);

export const makeGetIsAsc = () => createSelector([isAsc], (isAsc) => isAsc);

export const makeGetSetting = () =>
  createSelector([getSetting], (setting) => setting);

export const makeGetStockDetail = () =>
  createSelector([getStockDetail], (stockDetail) => stockDetail);

export const makeGetStockTrade = () =>
  createSelector([getStockTrade], (stockTrade) => stockTrade);

export const makeGetStockCST = () =>
  createSelector([getStockCst], (cst) => cst);

export const makeGetStockKQKD = () =>
  createSelector([getStockKQKD], (kqkd) => kqkd);

export const makeGetStockCDKT = () =>
  createSelector([getStockCDKT], (cdkt) => cdkt);

export const makeGetStockHis = () =>
  createSelector([getStockHis], (stockHis) => stockHis);

export const makeGetStockNews = () =>
  createSelector([getStockNews], (stockNews) => stockNews);

export const makeGetStockEvents = () =>
  createSelector([getStockEvents], (stockEvents) => stockEvents);

export const makeGetStockManage = () =>
  createSelector([getStockManage], (stockManage) => stockManage);

export const makeGetCompanyInfo = () =>
  createSelector([getCompanyInfo], (companyInfo) => companyInfo);

export const makeGetToken = () => createSelector([getToken], (token) => token);
export const makeGetAuth = () => createSelector([getAuth], (auth) => auth);

export const makeGetToast = () => createSelector([getToast], (toast) => toast);

export const makeGetConfig = () =>
  createSelector([getConfig], (config) => config);

export const makeGetListAccount = () =>
  createSelector([getListAccount], (listAccount) => listAccount);

export const makeGetDefaultAccount = () =>
  createSelector([getDefaultAccount], (defaultAccount) => defaultAccount);

export const makeGetAllStock = () =>
  createSelector([getAllStock], (allStock) => allStock);

export const makeGetPutThroughByGroup = () =>
  createSelector([getPutThroughByGroup], (ptList) => ptList);

export const makeGetCWList = () =>
  createSelector([getCWList], (cwList) => cwList);

export const makeGetList30 = () =>
  createSelector([getList30], (list30) => list30);

export const makeGetIndustry = () =>
  createSelector([getIndustry], (industry) => industry);

export const makeGetPriceTableSymbols = () =>
  createSelector([getPriceTableSymbols], (currentSymbols) => currentSymbols);

export const makeGetIndexSummary = () =>
  createSelector([getIndexSummary], (indSum) => indSum);

export const makeGetListBankName = () =>
  createSelector([getListBank], (bankName) => bankName);

export const makeGetNoiCap = () =>
  createSelector([getNoiCap], (noicap) => noicap);

export const makeGetCategory = () =>
  createSelector([getCategory], (category) => category);

export const makeGetOrderList = () =>
  createSelector([getOrderList], (orderList) => orderList);

export const makeGetListOrder = () =>
  createSelector([getListOrder], (listOrder) => listOrder);

export const makeGetCashBalance = () =>
  createSelector([getCashBalance], (cashBalance) => cashBalance);

export const makeGetStockBalance = () =>
  createSelector([getStockBalance], (stockBalance) => stockBalance);

export const makeGetPositions = () =>
  createSelector([getPositions], (positions) => positions);

export const makeGetPurchasePower = () =>
  createSelector([getPurchasePower], (purchasePower) => purchasePower);

export const makeGetNewOrder = () =>
  createSelector([getNewOrder], (newOrder) => newOrder);

export const makeGetCancelOrder = () =>
  createSelector([getCancelOrder], (cancelOrder) => cancelOrder);

export const makeGetEditOrder = () =>
  createSelector([getEditOrder], (editOrder) => editOrder);

export const makeGetEventOrder = () =>
  createSelector([getEventOrder], (event) => event);

// TODO: 02/12/2020 update get socket status
export const makeGetSocketStatus = () =>
  createSelector([getSocketStatus], (serverStatus) => serverStatus);

export const makeGetSocketReady = () =>
  createSelector([getSocketReady], (ready) => ready);

export const makeGetSymbolInfo = () =>
  createSelector([getSymbolInfo], (symbolInfo) => symbolInfo);

export const makeGetMatchOrder = () =>
  createSelector([getMatchOrder], (matchOrder) => matchOrder);

export const makeGetTradingSymbol = () =>
  createSelector([getTradingSymbol], (tradingSymbol) => tradingSymbol);

export const makeGetSymbolMargin = () =>
  createSelector([getSymbolMargin], (symbolMargin) => symbolMargin);

export const makeGetPart = () => createSelector([getPart], (part) => part);

export const makeGetSnapShot = () =>
  createSelector([getSnapShot], (snapshot) => snapshot);

export const makeGetAllOrder = () =>
  createSelector([getAllOrder], (allOrder) => allOrder);

export const makeGetStockSource = () =>
  createSelector([getStockSource], (stockSource) => stockSource);

export const makeGetStockTarget = () =>
  createSelector([getStockTarget], (stockTarget) => stockTarget);

export const makeGetSourceBalance = () =>
  createSelector([getSourceBalance], (sourceBalance) => sourceBalance);

export const makeGetTargetBalance = () =>
  createSelector([getTargetBalance], (targetBalance) => targetBalance);

export const makeGetCashTransfer = () =>
  createSelector([getCashTransfer], (cashTransfer) => cashTransfer);

export const makeGetCashOut = () =>
  createSelector([getCashOut], (cashOut) => cashOut);

export const makeGetHisTransfer = () =>
  createSelector([getListTransfer], (hisTransfer) => hisTransfer);

export const makeGetBankAccount = () =>
  createSelector([getBankAccount], (bankAcc) => bankAcc);

export const makeGetBankList = () =>
  createSelector([getBankList], (bankList) => bankList);

export const makeGetDebtMargin = () =>
  createSelector([getDebt], (debt) => debt);

export const makeGetRepayLoan = () =>
  createSelector([getRepayLoan], (repayLoan) => repayLoan);

export const makeGetHisDebtMargin = () =>
  createSelector([getDebtHis], (hisDebt) => hisDebt);

export const makeGetHis2DebtMargin = () =>
  createSelector([getDebtHis2], (hisDebt2) => hisDebt2);

export const makeGetCashInBank = () =>
  createSelector([getCashInBank], (cashInBank) => cashInBank);

export const makeGetHoldBank = () =>
  createSelector([getHoldBank], (holdBank) => holdBank);

export const makeGetHoldList = () =>
  createSelector([getHoldList], (holdList) => holdList);

export const makeGetRightList = () =>
  createSelector([getRightList], (rightList) => rightList);

export const makeGetMarginList = () =>
  createSelector([getMarginList], (marginList) => marginList);

export const makeGetOrderCondition = () =>
  createSelector([getOrderCondition], (ord) => ord);

export const makeGetListOrderCond = () =>
  createSelector([getListOrderCond], (listCond) => listCond);

export const makeGetCondDetail = () =>
  createSelector([getOrderCondDetail], (condDetail) => condDetail);

export const makeGetSummaryOrder = () =>
  createSelector([getSummaryOrder], (ordList) => ordList);

export const makeGetSummaryOrderCond = () =>
  createSelector([getSummaryOrderCond], (ordCondList) => ordCondList);

export const makeGetSummaryCash = () =>
  createSelector([getSummaryCash], (cashList) => cashList);

export const makeGetSummaryStock = () =>
  createSelector([getSummaryStock], (stockList) => stockList);

export const makeGetSummaryEvent = () =>
  createSelector([getSummaryEvent], (event) => event);

export const makeGetSummaryRightList = () =>
  createSelector([getSummaryRightList], (rightList) => rightList);

export const makeGetProfile = () =>
  createSelector([getProfile], (profile) => profile);

export const makeGetCustomerBankAccount = () =>
  createSelector([getCustomerBankAccount], (bankAcc) => bankAcc);

export const makeGetGainLossList = () =>
  createSelector([getGainLossList], (gainLossList) => gainLossList);

export const makeGetSummaryNotification = () =>
  createSelector([getSummaryNotification], (noti) => noti);

export const makeGetBondInfor = () =>
  createSelector([getBondInfor], (state) => state);

export const makeGetBondToolTip = () =>
  createSelector([getBondToolTip], (state) => state);

export const makeUnreadNotification = createSelector(
  [getSummaryNotification],
  (notification) =>
    notification.filter(
      (item) =>
        item.disFstTime === 'Y' && item.isRead !== 'Y' && item.msgType != '2'
    )
);

export const makeTradingviewConfig = () =>
  createSelector(
    [getToken, getSetting, getCurrentTheme],
    (token, setting, currentTheme) => {
      return {
        tv: token?.tv,
        user: token?.user,
        lang: setting?.lang || 'vi',
        theme: currentTheme?.name || 'dark',
      };
    }
  );

const selectCustomer = (state) => state.customer;

export const makeGetSerList4Cust = () =>
  createSelector(
    [selectCustomer, getListAccount],
    (
      { serList4CusRequesting, serList4Cus: services = [] },
      listAccount = []
    ) => {
      const filteredServices = [];
      return {
        serList4Cus: filteredServices?.sort(
          (pre, next) => pre.serTp - next.serTp
        ),
        serList4CusRequesting,
      };
    }
  );

const selectSerList4Cus = (state) => state.customer.serList4Cus;

export const makeGetBrokerInfo = createSelector([], (serList4Cus) => {
  return serList4Cus;
});

export const makeGetListAccountAvailable = () =>
  createSelector([getListAccount], (listAccount) => listAccount);
