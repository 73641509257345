import { call, put, takeLatest, all, select } from 'redux-saga/effects';
import { handleApiErrors } from '../../lib/api-errors';
import {
  GET_LIST_POSITION_ALL,
  GET_LIST_POSITION_ALL_SUCCESS,
  GET_LIST_POSITION_ALL_ERROR,
  GET_BOND_PORTFOLIO,
  GET_BOND_PORTFOLIO_SUCCESS,
  GET_BOND_PORTFOLIO_ERROR,
  GET_INQUIRY_RIGHTLIST_ALL,
  GET_INQUIRY_RIGHTLIST_ALL_SUCCESS,
  GET_INQUIRY_RIGHTLIST_ALL_ERROR,
} from './constants';

import {
  getListPositionAllRequest,
  getListPositionAllSuccess,
  getListPositionAllError,
  getBondPortfolioRequest,
  getBondPortfolioSuccess,
  getBondPortfolioError,
  getInquiryRightListAllRequest,
  getInquiryRightListAllSuccess,
  getInquiryRightListAllError,
} from './action';

import { getMessage } from '../../lib/selector';
import { removeCookie } from '../../lib/storages';

const appUrl = `${process.env.REACT_APP_API_URL}`;

function handleRequest(request) {
  return request
    .then(handleApiErrors)
    .then((response) => response.json())
    .then((json) => json)
    .catch((error) => {
      throw error;
    });
}

function personalApi(data) {
  const url = `${appUrl}/CoreServlet.pt`;
  const request = fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
    body: JSON.stringify(data),
  });
  return handleRequest(request);
}

function getRightListAllApi(data) {
  const url = `${appUrl}/CoreServlet.pt`;
  const request = fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
    body: JSON.stringify(data),
  });
  return handleRequest(request);
}

function bondPortfolioApi(data) {
  const url = `${appUrl}/BondServlet.pt`;
  const request = fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
    body: JSON.stringify(data),
  });
  return handleRequest(request);
}

function* getListPositionAllFlow({ params }) {
  try {
    const resData = yield call(personalApi, params);
    if (resData.http !== 200) {
      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');
        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }
      const msgErr = yield select(getMessage, resData);
      throw Error(msgErr);
    }
    yield put(getListPositionAllSuccess(resData.data));
  } catch (error) {
    // console.log(error)
    yield put(getListPositionAllError(error));
  }
}

function* getInquiryRightAllFlow({ params }) {
  try {
    const resData = yield call(getRightListAllApi, params);
    console.log('getInquiryRightAllFlow', resData);
    if (resData.http !== 200) {
      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');
        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }
      const msgErr = yield select(getMessage, resData);
      throw Error(msgErr);
    }
    yield put(getInquiryRightListAllSuccess(resData.data));
  } catch (error) {
    // console.log(error)
    yield put(getInquiryRightListAllError(error));
  }
}

function* getBondPortfolioFlow({ params }) {
  try {
    const resData = yield call(bondPortfolioApi, params);
    console.log('getBondPortfolioFlow', resData);
    if (resData.http !== 200) {
      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');
        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }
      const msgErr = yield select(getMessage, resData);
      throw Error(msgErr);
    }
    yield put(getBondPortfolioSuccess(resData.data));
  } catch (error) {
    // console.log(error)
    yield put(getBondPortfolioError(error));
  }
}

function* personalWatcher() {
  yield all([takeLatest(GET_LIST_POSITION_ALL, getListPositionAllFlow)]);
  yield all([takeLatest(GET_BOND_PORTFOLIO, getBondPortfolioFlow)]);
  yield all([takeLatest(GET_INQUIRY_RIGHTLIST_ALL, getInquiryRightAllFlow)]);
}

export default personalWatcher;
