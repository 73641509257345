import { call, put, all, takeLatest, select } from 'redux-saga/effects';

import { handleApiErrors } from '../../../lib/api-errors';
import { getMessage } from '../../../lib/selector';
import { removeCookie } from '../../../lib/storages';

import {
  ORD_COND_REQUESTING,
  ORD_COND_REQUEST_SUCCESS,
  ORD_COND_REQUEST_ERROR,
  LIST_COND_REQUESTING,
  LIST_COND_REQUEST_SUCCESS,
  LIST_COND_REQUEST_ERROR,
  ORD_COND_DETAIL_REQUESTING,
  ORD_COND_DETAIL_REQUEST_SUCCESS,
  ORD_COND_DETAIL_REQUEST_ERROR,
  ORD_COND_CANCEL_REQUESTING,
  ORD_COND_CANCEL_REQUEST_SUCCESS,
  ORD_COND_CANCEL_REQUEST_ERROR,
} from './constants';

const appUrl = `${process.env.REACT_APP_API_URL}`;

function handleRequest(request) {
  return request
    .then(handleApiErrors)
    .then((response) => response.json())
    .then((json) => json)
    .catch((error) => {
      throw error;
    });
}

function ordCondApi(data) {
  const url = `${appUrl}/CoreServlet.pt`;
  const request = fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
    body: JSON.stringify(data),
  });
  return handleRequest(request);
}

function* ordCondRequestFlow({ params }) {
  try {
    const resData = yield call(ordCondApi, params);

    // console.log(resData);

    if (resData.http !== 200) {
      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');

        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }
      const msgErr = yield select(getMessage, resData);
      throw Error(msgErr);
    }

    yield put({ type: ORD_COND_REQUEST_SUCCESS, data: resData.data });
  } catch (error) {
    yield put({ type: ORD_COND_REQUEST_ERROR, error });
  }
}

function* listCondRequestFlow({ params }) {
  try {
    const resData = yield call(ordCondApi, params);

    console.log(resData);
    if (resData.http !== 200) {
      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');

        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }
      const msgErr = yield select(getMessage, resData);
      throw Error(msgErr);
    }

    yield put({ type: LIST_COND_REQUEST_SUCCESS, data: resData.data });
  } catch (error) {
    yield put({ type: LIST_COND_REQUEST_ERROR, error });
  }
}

function* condDetailRequestFlow({ params }) {
  try {
    const resData = yield call(ordCondApi, params);

    console.log(resData);
    if (resData.http !== 200) {
      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');

        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }
      const msgErr = yield select(getMessage, resData);
      throw Error(msgErr);
    }

    yield put({ type: ORD_COND_DETAIL_REQUEST_SUCCESS, data: resData.data });
  } catch (error) {
    yield put({ type: ORD_COND_DETAIL_REQUEST_ERROR, error });
  }
}

function* condCancelRequestFlow({ params }) {
  try {
    const resData = yield call(ordCondApi, params);

    console.log(resData);
    if (resData.http !== 200) {
      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');

        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }
      const msgErr = yield select(getMessage, resData);
      throw Error(msgErr);
    }

    yield put({ type: ORD_COND_CANCEL_REQUEST_SUCCESS });
  } catch (error) {
    yield put({ type: ORD_COND_CANCEL_REQUEST_ERROR, error });
  }
}

function* ordCondWatcher() {
  yield all([
    takeLatest(ORD_COND_REQUESTING, ordCondRequestFlow),
    takeLatest(LIST_COND_REQUESTING, listCondRequestFlow),
    takeLatest(ORD_COND_DETAIL_REQUESTING, condDetailRequestFlow),
    takeLatest(ORD_COND_CANCEL_REQUESTING, condCancelRequestFlow),
  ]);
}

export default ordCondWatcher;
