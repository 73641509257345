import React, { useState, useEffect } from 'react';
import PerfectScrollBar from 'react-perfect-scrollbar';
import AddressChangeModal from '../components/profile/addressChangeModal';
import EmailPhoneChangeModal from '../components/profile/emailPhoneChangeModal';
import PersonalIDChangeModal from '../components/profile/personalIDChangeModal';
import { connect, useSelector, useDispatch } from 'react-redux';
import {
  makeGetAuth,
  makeGetDefaultAccount,
  makeGetNoiCap,
  makeGetProfile,
  makeGetToken,
} from '../../../lib/selector';
import { setAuth } from '../../../containers/client/actions';
import { _formatDate2, formatPhoneNumber } from '../../../util';
import { withNamespaces } from 'react-i18next';
import { MdVerifiedUser } from 'react-icons/md';
import { GoUnverified } from 'react-icons/go';
import { setToast } from '../../../containers/client/actions';
import { v4 as uuidv4 } from 'uuid';
import { getProfileState, profileRequest } from '../../customer/actions';
import { noicapRequest } from '../../banggia/actions';

const Index = withNamespaces('translations')(({ t }) => {
  const [openEmailPhoneChangeModal, setEmailPhoneChangeModal] = useState(false);
  const [openAddressChangeModal, setAddressChangeModal] = useState(false);
  const [openPersonalIDChangeModal, setPersonalIDChangeModal] = useState(false);

  const token = useSelector(makeGetToken());
  const auth = useSelector(makeGetAuth());
  const defaultAccount = useSelector(makeGetDefaultAccount());
  const profile = useSelector(makeGetProfile());
  const noicap = useSelector(makeGetNoiCap());
  const state = useSelector((state) => state.customer?.profileUpdateState);
  const dispatch = useDispatch();

  const authenHandle = () => {
    dispatch(setAuth());
  };

  const handleLoadProfile = () => {
    if (!token) return;

    const uuid = uuidv4();
    const params = {
      group: 'CORE',
      user: token.user,
      session: token.session,
      cmd: 'GetProfiles',
      rqId: uuid,
      channel: 'WTS',
      data: {
        cif: token.cif,
      },
    };

    dispatch(profileRequest(params));
  };

  const getProfileInforStateHandle = () => {
    if (!token) return;
    const uuid = uuidv4();
    const params = {
      group: 'CORE',
      user: token.user,
      session: token.session,
      cmd: 'getChangeCustInfoPending',
      rqId: uuid,
      channel: 'WTS',
      data: {
        cif: token.cif,
      },
    };

    dispatch(getProfileState(params));
  };

  useEffect(() => {
    getProfileInforStateHandle();
    dispatch(noicapRequest());
  }, []);

  const toastHandle = (msg, title = t('txt-notice')) => {
    const toastMsg = {
      id: Math.random(),
      msg: msg,
      title: title,
    };
    dispatch(setToast(toastMsg));
  };

  const emailPhoneChangeHandle = () => {
    if (state && state > 0) {
      toastHandle(t('account.updateProcessing'));
    } else {
      setEmailPhoneChangeModal(true);
    }
  };

  const addressChangeHandle = () => {
    if (state && state > 0) {
      toastHandle(t('account.updateProcessing'));
    } else {
      setAddressChangeModal(true);
    }
  };

  const personalChangeHandle = () => {
    if (state && state > 0) {
      toastHandle(t('account.updateProcessing'));
    } else {
      setPersonalIDChangeModal(true);
    }
  };

  return (
    <>
      <PerfectScrollBar>
        <div className="account-profile mb-3">
          <div className="col-md-8 px-0 profile-state">
            <span className="float-left">
              {state && state > 0 ? t('account.updateProcessing') : ''}
            </span>
            <span
              className={`d-flex float-right justify-content-center align-items-center ${
                auth.isVerified ? 'i' : 'd'
              }`}
            >
              {auth && auth.isVerified ? (
                <>
                  <MdVerifiedUser className="mr-2" />
                  {t('txt-da-xacthuc')}
                </>
              ) : (
                <>
                  <GoUnverified className="mr-2" />
                  {t('txt-chua-xacthuc')}
                </>
              )}
            </span>
          </div>

          <div className="account-panel account-infor col-md-8">
            <div className="account-panel-header">
              <span className="account-panel-header__title">
                {t('txt-label-account-info')}
              </span>
            </div>
            <div className="account-panel-body">
              <div className="account-panel-items">
                <div className="account-panel-item">
                  <span className="account-panel-item__name">
                    {t('txt-label-phone-num')}
                  </span>
                  <span className="account-panel-item__value">
                    {profile && formatPhoneNumber(profile.cphnNo)}
                  </span>
                  <span className="account-panel-item__action">
                    {auth && auth.isVerified ? (
                      <span
                        className="btn btn--light fw-500 br-5"
                        onClick={() => emailPhoneChangeHandle()}
                      >
                        {t('txt-change')}
                      </span>
                    ) : (
                      <span
                        className="btn btn--authen fw-500 br-5"
                        onClick={() => authenHandle()}
                      >
                        {t('txt-change')}
                      </span>
                    )}
                  </span>
                </div>
                <div className="account-panel-item">
                  <span className="account-panel-item__name">Email</span>
                  <span className="account-panel-item__value">
                    {profile && profile.email}
                  </span>
                  <span className="account-panel-item__action"></span>
                </div>
              </div>
            </div>
          </div>
          <div className="account-panel account-infor col-md-8">
            <div className="account-panel-header">
              <span className="account-panel-header__title">
                {t('txt-label-general-info')}
              </span>
            </div>
            <div className="account-panel-body">
              <div className="account-panel-items">
                <div className="account-panel-item">
                  <span className="account-panel-item__name">
                    {t('txt-label-address')}
                  </span>
                  <span className="account-panel-item__value">
                    {profile && profile.cttAddr}
                  </span>
                  <span className="account-panel-item__action">
                    {auth && auth.isVerified ? (
                      <span
                        className="btn btn--light fw-500 br-5"
                        onClick={() => addressChangeHandle()}
                      >
                        {t('txt-change')}
                      </span>
                    ) : (
                      <span
                        className="btn btn--authen fw-500 br-5"
                        onClick={() => authenHandle()}
                      >
                        {t('txt-change')}
                      </span>
                    )}
                  </span>
                </div>
              </div>
              <div className="account-panel-items">
                <div className="account-panel-item">
                  <span className="account-panel-item__name">
                    {t('txt-label-gender')}
                  </span>
                  <span className="account-panel-item__value">
                    {profile &&
                      (profile.gend === 'M'
                        ? t('txt-label-male')
                        : profile.gend === 'F'
                        ? t('txt-label-female')
                        : '-')}
                  </span>
                  <span className="account-panel-item__action"></span>
                </div>
                <div className="account-panel-item">
                  <span className="account-panel-item__name">
                    {t('txt-label-nationality')}
                  </span>
                  <span className="account-panel-item__value">
                    {profile && profile.natnlNm}
                  </span>
                  <span className="account-panel-item__action"></span>
                </div>
                <div className="account-panel-item">
                  <span className="account-panel-item__name">
                    {t('txt-label-cif-no')}
                  </span>
                  <span className="account-panel-item__value">
                    {token && token.cif}
                  </span>
                  <span className="account-panel-item__action"></span>
                </div>
                <div className="account-panel-item">
                  <span className="account-panel-item__name">
                    {t('txt-label-vsd-no')}
                  </span>
                  <span className="account-panel-item__value">
                    {defaultAccount && defaultAccount.vsdAcntNo}
                  </span>
                  <span className="account-panel-item__action"></span>
                </div>
              </div>
            </div>
          </div>
          <div className="account-panel personal-ID col-md-8">
            <div className="account-panel-header">
              <span className="account-panel-header__title">
                {t('txt-PersonalID')}
              </span>
              <span className="account-panel-header__action">
                {auth && auth.isVerified ? (
                  <span
                    className="btn btn--light fw-500 br-5"
                    onClick={() => personalChangeHandle()}
                  >
                    {t('txt-change')}
                  </span>
                ) : (
                  <span
                    className="btn btn--authen fw-500 br-5"
                    onClick={() => authenHandle()}
                  >
                    {t('txt-change')}
                  </span>
                )}
              </span>
            </div>
            <div className="account-panel-body">
              <div className="account-panel-items">
                <div className="account-panel-item">
                  <span className="account-panel-item__name">
                    {t('txt-personalID-type')}
                  </span>
                  <span className="account-panel-item__value">
                    {profile && profile.custIdKnd == '1'
                      ? t('txt-personalID-1')
                      : t('txt-personalID-2')}
                  </span>
                  <span className="account-panel-item__action"></span>
                </div>
                <div className="account-panel-item">
                  <span className="account-panel-item__name">
                    {t('txt-personalID-number')}
                  </span>
                  <span className="account-panel-item__value">
                    {profile && profile.custId}
                  </span>
                  <span className="account-panel-item__action"></span>
                </div>
                <div className="account-panel-item">
                  <span className="account-panel-item__name">
                    {t('txt-label-fullname')}
                  </span>
                  <span className="account-panel-item__value">
                    {profile && profile.custNm}
                  </span>
                  <span className="account-panel-item__action"></span>
                </div>
                <div className="account-panel-item">
                  <span className="account-panel-item__name">
                    {t('txt-residence-addr')}
                  </span>
                  <span className="account-panel-item__value">
                    {profile && profile.perdomAddr}
                  </span>
                  <span className="account-panel-item__action"></span>
                </div>
                <div className="account-panel-item">
                  <span className="account-panel-item__name">
                    {t('txt-personalID-IssuedPlace')}
                  </span>
                  <span className="account-panel-item__value">
                    {profile && noicap}
                  </span>
                  <span className="account-panel-item__action"></span>
                </div>
                <div className="account-panel-item">
                  <span className="account-panel-item__name">
                    {t('txt-personalID-IssuedDate')}
                  </span>
                  <span className="account-panel-item__value">
                    {profile && _formatDate2(profile.custIdIssuDate)}
                  </span>
                  <span className="account-panel-item__action"></span>
                </div>
                {profile?.custIdExprDate && (
                  <div className="account-panel-item">
                    <span className="account-panel-item__name">
                      {t('txt-personalID-ExpireDate')}
                    </span>
                    <span className="account-panel-item__value">
                      {profile && _formatDate2(profile.custIdExprDate)}
                    </span>
                    <span className="account-panel-item__action"></span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </PerfectScrollBar>
      {openAddressChangeModal && (
        <AddressChangeModal
          getProfileInforState={getProfileInforStateHandle}
          handleLoadProfile={handleLoadProfile}
          profile={profile}
          toastHandle={toastHandle}
          showModal={openAddressChangeModal}
          handleCloseModal={() => setAddressChangeModal(false)}
        />
      )}

      {openEmailPhoneChangeModal && (
        <EmailPhoneChangeModal
          getProfileInforState={getProfileInforStateHandle}
          handleLoadProfile={handleLoadProfile}
          profile={profile}
          toastHandle={toastHandle}
          showModal={openEmailPhoneChangeModal}
          handleCloseModal={() => setEmailPhoneChangeModal(false)}
        />
      )}

      {openPersonalIDChangeModal && (
        <PersonalIDChangeModal
          getProfileInforState={getProfileInforStateHandle}
          handleLoadProfile={handleLoadProfile}
          toastHandle={toastHandle}
          profile={profile}
          noicap={noicap}
          showModal={openPersonalIDChangeModal}
          handleCloseModal={() => setPersonalIDChangeModal(false)}
        />
      )}
    </>
  );
});

export default Index;
