import React, { useEffect } from 'react';
import { withNamespaces } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import IndexIndicator from '../../components/banggia/indexIndicator';
import { makeGetToken } from '../../lib/selector';
import { getCategory } from '../../lib/storages';
import PriceTable from './components/priceboard';
import { getListDvxRequest } from './actions';
import { setDefaultAccount } from '../socket/actions';
import { constants } from '../../util/constant';

const Index = withNamespaces('translations')(({ history }) => {
  const dispatch = useDispatch();
  const { listAccount } = useSelector((state) => state.socket);
  const token = useSelector(makeGetToken());

  useEffect(() => {
    dispatch(getListDvxRequest());
    if (!token) {
      getCategory({ dispatch });
    }
  }, []);

  useEffect(() => {
    if (listAccount?.length > 0) {
      let _defaultAccount = listAccount.find(
        (item) =>
          item.subAcntStat == 1 &&
          item.subAcntNo.startsWith(constants.subAccount.Derivative)
      );
      if (_defaultAccount) {
        dispatch(setDefaultAccount(_defaultAccount));
      }
    }
  }, [listAccount]);

  return (
    <div className="derivative">
      <IndexIndicator />
      <PriceTable history={history} />
    </div>
  );
});

export default Index;
