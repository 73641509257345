import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import ReactSelect from 'react-select';
import PerfectScrollbar from 'react-perfect-scrollbar';
import moment from 'moment';
import DatePicker, { registerLocale } from 'react-datepicker';
import vi from 'date-fns/locale/vi';
import uk from 'date-fns/locale/uk';
import { Portal } from 'react-overlays';
import { filter, find, map, set, sum, sumBy } from 'lodash';

import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { withNamespaces, translate } from 'react-i18next';
import PaginationTable from '../../../../components/PaginationTable';
import noImage from '../../../../assets/img/noImage.svg';
import noImageLight from '../../../../assets/img/noImageLight.svg';
import { useHistory } from 'react-router';
import {
  canDeleteOrder,
  formatDate,
  mapPfOrderStat,
  numberFormat,
  _formatDate,
  _formatDate2,
  _getOrderStatus,
} from '../../../../util';
import { getPfAnaRptCatTpRequest, getPfAnaRptRequest } from '../../action';
import ViewReport from './viewReport';
import { setToast } from '../../../client/actions';
import ViewVideoModal from './videoDetailModal';
import { toogleVideoModal } from '../../../news/action';

registerLocale('vi', vi);
registerLocale('en', uk);
const CalendarContainer = ({ children }) => {
  const el = document.getElementById('calendar-portal');

  return <Portal container={el}>{children}</Portal>;
};

const AnalysisReport = withNamespaces('translations')(
  ({ t, hasFilter = false }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { token, auth } = useSelector((state) => state.client);
    const theme = useSelector((state) => state?.client?.theme);
    const setting = useSelector((state) => state.client.setting);
    const account = useSelector((state) => state.socket.defaultAccount);
    const { pfAnaRptCatTp, pfAnaRpt } = useSelector((state) => state.pinefolio);
    const _pfAnaRptCatTp = [{ value: '', label: 'txt-all' }, ...pfAnaRptCatTp];
    const numDay = token && token['config'] ? token['config']['numDay'] : 30;
    const d = new Date();
    d.setDate(d.getDate() - numDay);

    const [from, setFrom] = useState(d);
    const [to, setTo] = useState(new Date());
    const [txtSearch, setTxtSearch] = useState('');
    const [refresh, setRefresh] = useState(false);
    const [reportType, setReportType] = useState(_pfAnaRptCatTp[0]);
    const [listDocument, setListDocument] = useState([]);
    const [listVideo, setListVideo] = useState([]);
    const [file, setFile] = useState('');
    const [video, setVideo] = useState(null);
    const lang = setting.lang || 'vi';

    const getPfAnaRpt = () => {
      if (!token) return;
      const _fromDate = _formatDate(moment(from).format('DD/MM/YYYY'));
      const _toDate = _formatDate(moment(to).format('DD/MM/YYYY'));
      const uuid = uuidv4();
      const params = {
        group: 'CORE',
        user: token.user,
        session: token.session,
        cmd: 'getPFAnaRpt',
        rqId: uuid,
        channel: 'WTS',
        data: {
          cif: token.cif,
          acntNo: account?.acntNo,
          subAcntNo: account?.subAcntNo,
          frDt: _fromDate,
          toDt: _toDate,
          fileNm: txtSearch,
          categoryTp: reportType?.value,
          pfCode: '',
          langTp: lang,
        },
      };

      dispatch(getPfAnaRptRequest(params));
    };

    const getPfAnaRptCatTp = () => {
      if (!token) return;
      const uuid = uuidv4();
      const params = {
        group: 'CORE',
        user: token.user,
        session: token.session,
        cmd: 'getPFAnaRptCatTp',
        rqId: uuid,
        channel: 'WTS',
        data: {
          langTp: lang,
        },
      };

      dispatch(getPfAnaRptCatTpRequest(params));
    };

    const handleRefresh = () => {
      setTxtSearch('');
      setFrom(d);
      setTo(new Date());
      setReportType(_pfAnaRptCatTp[0]);
      setRefresh(!refresh);
    };

    const handleInputTextSeatch = (txt) => {
      setTxtSearch(txt);
    };

    const hanldeSelectAnaRptType = (value) => {
      setReportType(value);
    };

    useEffect(() => {
      getPfAnaRpt();
      getPfAnaRptCatTp();
    }, []);

    useEffect(() => {
      getPfAnaRpt();
    }, [refresh, account]);

    useEffect(() => {
      if (!token) {
        history.push('/');
      }
    }, [token]);

    useEffect(() => {
      getPfAnaRpt();
      getPfAnaRptCatTp();
    }, [lang]);

    useEffect(() => {
      if (!pfAnaRpt) {
        setListDocument([]);
        setListVideo([]);
      } else {
        const _listVideo = pfAnaRpt.filter(
          (item) => item.flFormatTp == 'youtube'
        );
        const _listDocument = pfAnaRpt.filter(
          (item) => item.flFormatTp != 'youtube'
        );

        setListDocument(_listDocument);
        setListVideo(_listVideo);
      }
    }, [pfAnaRpt]);

    function selectVideoHandle(video) {
      dispatch(toogleVideoModal(true));
      setVideo(video);
    }

    function selectFileHandle(file) {
      if (file.canView == 'N') {
        toastHandle(t('pinefolio.analysisReport.note'));
        return;
      }
      setFile(file);
    }

    const toastHandle = (msg) => {
      const toastMsg = {
        id: Math.random(),
        msg: msg,
        title: t('txt-notice'),
      };
      dispatch(setToast(toastMsg));
    };

    return (
      <div className="layout__body layout-page__body analysis-report">
        {hasFilter && (
          <div className="layout__filters">
            <div className="layout__filter">
              <label>{t('txt-search')}</label>
              <div className="input-text-search d-flex align-items-center mr-1">
                <input
                  placeholder={t('pinefolio.orderList.inputFileName')}
                  onChange={(e) => handleInputTextSeatch(e.target.value)}
                />
                <span className="icon iSearch"></span>
              </div>
            </div>

            <div className="layout__filter">
              <label>{t('pinefolio.orderList.dateFrom')}</label>
              <div className="filter-control-calendar">
                <DatePicker
                  name="from"
                  selected={from}
                  onChange={(date) => setFrom(date)}
                  dateFormat="dd/MM/yyyy"
                  popperContainer={CalendarContainer}
                  locale={lang}
                />
                <span className="icon iCalendar"></span>
              </div>
            </div>
            <div className="layout__filter">
              <label>{t('pinefolio.orderList.dateTo')}</label>
              <div className="filter-control-calendar">
                <DatePicker
                  name="to"
                  selected={to}
                  onChange={(date) => setTo(date)}
                  dateFormat="dd/MM/yyyy"
                  popperContainer={CalendarContainer}
                  locale={lang}
                />
                <span className="icon iCalendar"></span>
              </div>
            </div>
            <div className="layout__filter">
              <label>{t('pinefolio.theme.report.type')}</label>
              <ReactSelect
                defaultValue={reportType}
                className="filter-control-select"
                classNamePrefix="filter-control-select"
                options={_pfAnaRptCatTp}
                getOptionLabel={(option) => t(option.label)}
                getOptionValue={(option) => option.value}
                value={reportType}
                onChange={(value) => hanldeSelectAnaRptType(value)}
                isSearchable={false}
                components={{
                  IndicatorSeparator: () => null,
                }}
              />
            </div>
            <div className="layout__filter button-group">
              <div onClick={getPfAnaRpt} className="iBtn iBtn--primary">
                <span>{t('bond.orderList.label.filter')}</span>
              </div>
              <span
                onClick={handleRefresh}
                className="iBtn reload-button iBtn--secondary"
              >
                <span className="icon iRefresh"></span>
              </span>
            </div>
          </div>
        )}
        <div className="content-container">
          <div className="video-section">
            <PerfectScrollbar style={{ width: '100%' }}>
              <div className="video-list">
                {listVideo &&
                  listVideo.length > 0 &&
                  listVideo.map((video) => (
                    <div
                      className="video"
                      onClick={() => selectVideoHandle(video)}
                    >
                      <div
                        className="video__thumbnail"
                        style={{
                          backgroundImage: video?.flCode
                            ? `url('https://img.youtube.com/vi/${video?.flCode}/mqdefault.jpg') `
                            : theme == 'dark'
                            ? noImage
                            : noImageLight,
                        }}
                      ></div>
                      <div className="video__title text--light">
                        {video.flName}
                      </div>
                      <div className="video__desc">
                        <span className="video__desc--category">
                          {video.categoryNm}
                        </span>
                        <span className="video__desc--date text--gray">
                          {_formatDate2(video.pbStDt)}
                        </span>
                      </div>
                    </div>
                  ))}
              </div>
            </PerfectScrollbar>
          </div>
          <div className="document-section">
            <PerfectScrollbar>
              <div className="event-list">
                {listDocument &&
                  listDocument.length > 0 &&
                  listDocument.map((report) => (
                    <div
                      className="event-item fz_13"
                      onClick={() => selectFileHandle(report)}
                    >
                      <span className="event-time text--gray">
                        <span className="icon iCalendar"></span>
                        <span className="text--gray">
                          {_formatDate2(report.pbStDt)}
                        </span>
                      </span>
                      <div className="event__content">
                        <div className="content__title">
                          <span className="symbol fw-500 fz_13 text--light cursor-pointer">
                            {report.categoryNm}
                          </span>
                          <span className="text--light fw-500 cursor-pointer d-flex align-items-center">
                            {report.flName}
                            {report.prvtYN == 'Y' && (
                              <div className="d-flex">
                                <span class="icon icon--tiny iStar ml-1 bg--active"></span>
                              </div>
                            )}
                          </span>
                        </div>
                        <span className="content__desc text--light3 cursor-pointer">
                          {report.note}
                        </span>
                      </div>
                    </div>
                  ))}
              </div>
            </PerfectScrollbar>
          </div>
        </div>
        <div className="layout-page__footer justify-content-end">
          <div className="note--star justify-content-start">
            <span className="icon icon--tiny iStar mr-1 bg--active fz_13"></span>
            <span className="fz_13">{t('pinefolio.analysisReport.note')}</span>
          </div>
        </div>
        {video && <ViewVideoModal video={video} setVideo={setVideo} />}
        {file && file.flUrl && <ViewReport file={file} setFile={setFile} />}
      </div>
    );
  }
);

export default AnalysisReport;
