import React, { useEffect } from 'react';
import { withNamespaces } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import SwitchCustom from '../../../../components/common/switch';
import PaginationTable from '../../../../components/PaginationTable';
import {
  mapRightType,
  numberFormat,
  StringToDouble,
  _formatDate2,
} from '../../../../util';
import { getInquiryRightListAllRequest } from '../../../personal/action';
import { v4 as uuidv4 } from 'uuid';

const RightSection = withNamespaces('translations')(({ t }) => {
  const dispatch = useDispatch();
  const inquiryRightList = useSelector(
    (state) => state.personal?.inquiryRightList?.list
  );
  const { token, auth } = useSelector((state) => state.client);
  const { defaultAccount, listAccount } = useSelector((state) => state.socket);

  const rightListHeaderColumn = [
    {
      key: 'symbol',
      text: t('txt-symbol'),
      className: 'text-center text--light fw-500',
      align: 'center',
    },
    {
      key: 'rghtSubTp',
      text: t('txt-type'),
      className: 'text-center text--light fw-500',
      align: 'center',
      cell: (record) => {
        return mapRightType(record.rghtSubTp);
      },
    },
    {
      key: 'basdt',
      text: t('txt-label-expected-date'),
      className: 'text-center text--light3 fw-500',
      align: 'center',
      cell: (record) => {
        return _formatDate2(record.basdt);
      },
    },
    {
      key: 'cashDate',
      text: t('txt-label-registration-date'),
      className: 'text-center text--light3 fw-500',
      align: 'center',
      cell: (record) => {
        if (record.rghtSubTp === '01') {
          if (record.dpstStockQty > 0) return _formatDate2(record.cashDate);

          if (record.dpstCashAmt > 0) return _formatDate2(record.cashDate);
        }

        return _formatDate2(record.stockDate);
      },
    },
    {
      key: 'balanceQuantity',
      text: t('txt-label-quantity-record-date'),
      className: 'text-right text--light fw-500',
      align: 'center',
      cell: (record) => {
        return numberFormat(record.balanceQuantity, 0, '0');
      },
    },
    {
      key: 'rightRate',
      text: t('txt-label-ratio'),
      className: 'text-right text--light fw-500',
      align: 'center',
      cell: (record) => {
        if (record.rghtSubTp === '01') {
          return numberFormat(StringToDouble(record.rightRate), 2, '0') + '%';
        }
        return record.rightRate;
      },
    },
    {
      key: 'dpstStockQty',
      text: t('txt-stock-waiting'),
      className: 'text-right text--light fw-500',
      align: 'center',
      cell: (record) => {
        return numberFormat(record.dpstStockQty, 0, '0');
      },
    },
    {
      key: 'dpstCashAmt',
      text: t('txt-asset-collateral'),
      className: 'text-right text--light fw-500',
      align: 'center',
      cell: (record) => {
        return numberFormat(record.dpstCashAmt, 0, '0');
      },
    },
  ];

  useEffect(() => {
    if (defaultAccount) getListInquiryRightAll();
  }, [defaultAccount]);

  const getListInquiryRightAll = () => {
    if (token) {
      if (!defaultAccount?.subAcntNo.startsWith('P')) return;
      const uuid = uuidv4();
      const params = {
        group: 'CORE',
        user: token.user,
        session: token.session,
        cmd: 'getRightListAll',
        rqId: uuid,
        channel: 'WTS',
        data: {
          acntNo: defaultAccount?.acntNo,
          subAcntNo: defaultAccount?.subAcntNo,
        },
      };
      dispatch(getInquiryRightListAllRequest(params));
    }
  };

  return (
    <div className="portfolio-section section-right">
      <div className="portfolio-section__header">
        <span className="portfolio-section__title text--md text--light">
          {t('txt-label-right')}
        </span>
        <div className="portfolio-section__actions"></div>
      </div>
      <div className="portfolio-section__body">
        <PaginationTable
          t
          pageSize={15}
          columns={rightListHeaderColumn}
          hasPaging
          source={
            inquiryRightList && defaultAccount?.subAcntNo.startsWith('P')
              ? inquiryRightList
              : []
          }
        />
      </div>
    </div>
  );
});
export default RightSection;
