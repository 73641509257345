import 'react-app-polyfill/stable';
import 'core-js/features/array/find';
import 'core-js/features/array/includes';
import 'core-js/features/number/is-nan';
import 'core-js/features/global-this';
import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { Provider } from 'react-redux';
import {
  Redirect,
  Route,
  HashRouter as Router,
  Switch,
} from 'react-router-dom';
import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import packageJson from '../package.json';
import App from './App';
import Loading from './components/loading';
import DefaultLayout from './containers/defaultLayout/index';
import WebSocketProvider from './containers/socket/webSocket';
import i18n from './i18n/index';
// Import the index reducer and sagas
import IndexReducer from './reducers/index';
import './index.css';
import './assets/scss/main.scss';
import IndexSagas from './sagas/index';
import mixpanel from 'mixpanel-browser';
// Setup the middleware to watch between the Reducers and the Actions
const sagaMiddleware = createSagaMiddleware();

/*eslint-disable */
const composeSetup =
  process.env.NODE_ENV !== 'production' &&
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;
/*eslint-enable */

/* prod */
const store = createStore(
  IndexReducer,
  composeSetup(applyMiddleware(sagaMiddleware))
);

sagaMiddleware.run(IndexSagas);

/* disable console log when production build */
let consoleHolder = console;
function debug(bool) {
  if (!bool) {
    consoleHolder = console;
    console = {};
    Object.keys(consoleHolder).forEach(function (key) {
      console[key] = function () {};
    });
  } else {
    console = consoleHolder;
  }
}

debug(process.env.NODE_ENV !== 'production');
/* end disable console log */

/* Mixpanel integration */
mixpanel.init(process.env.REACT_APP_MIXPANEL, {
  persistence: 'localStorage',
  track_pageview: true,
  disable_persistence: false, // Vô hiệu hóa lưu trữ dữ liệu cục bộ nếu không cần thiết
  cookie_expiration: 365, // Thay đổi thời gian hết hạn của cookie
  cross_subdomain_cookie: true, // Sử dụng cookie trên toàn bộ miền phụ
  secure_cookie: true, // Chỉ sử dụng cookie bảo mật (https)
  api_host: process.env.REACT_APP_MIXPANEL_PROXY,
  loaded: function () {
    var distinct_id = mixpanel.get_distinct_id();
  },
});

mixpanel.hasOwnProperty('get_distinct_id');
/* End mixpanel */

ReactDOM.render(
  <Provider store={store}>
    <WebSocketProvider>
      <I18nextProvider i18n={i18n}>
        <Router basename={process.env.PUBLIC_URL}>
          <React.Suspense fallback={<Loading />}>
            <Route path="/" component={App}>
              <Switch>
                {/**
                <Route exact path="/login" name="Login Page" component={Login} />
                */}
                <Route path="/home">
                  <DefaultLayout store={store} i18n={i18n} />
                </Route>
                <Redirect from="/" to="/home/bang-gia" />
              </Switch>
            </Route>
          </React.Suspense>
        </Router>
        {/* <DevTools /> */}
      </I18nextProvider>
    </WebSocketProvider>
  </Provider>,
  document.getElementById('root')
);
