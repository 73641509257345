import {
  ORD_COND_REQUESTING,
  ORD_COND_REQUEST_SUCCESS,
  ORD_COND_REQUEST_ERROR,
  LIST_COND_REQUESTING,
  LIST_COND_REQUEST_SUCCESS,
  LIST_COND_REQUEST_ERROR,
  ORD_COND_DETAIL_REQUESTING,
  ORD_COND_DETAIL_REQUEST_ERROR,
  ORD_COND_DETAIL_REQUEST_SUCCESS,
  ORD_COND_CANCEL_REQUESTING,
  ORD_COND_CANCEL_REQUEST_SUCCESS,
  ORD_COND_CANCEL_REQUEST_ERROR,
} from './constants';

const initialState = {
  cond: null,
  requesting: false,
  successful: false,
  errors: [],

  listCond: null,

  condDetail: null,
  condDetailErrors: [],

  condCancelSuccessful: false,
  condCancelErrors: [],
};

const reducer = function ordCondReducer(state = initialState, action) {
  switch (action.type) {
    case ORD_COND_REQUESTING:
      return {
        ...state,
        requesting: true,
        successful: false,
        errors: [],
      };

    case ORD_COND_REQUEST_SUCCESS:
      return {
        ...state,
        cond: action.data,
        requesting: false,
        successful: true,
        errors: [],
      };

    case ORD_COND_REQUEST_ERROR:
      return {
        ...state,
        requesting: false,
        successful: false,
        errors: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };

    case LIST_COND_REQUESTING:
      return {
        ...state,
        listCond: null,
      };

    case LIST_COND_REQUEST_SUCCESS:
      return {
        ...state,
        listCond: action.data,
      };

    case LIST_COND_REQUEST_ERROR:
      return {
        ...state,
        listCond: null,
      };

    case ORD_COND_DETAIL_REQUESTING:
      return {
        ...state,
        condDetail: null,
        condDetailErrors: [],
      };

    case ORD_COND_DETAIL_REQUEST_SUCCESS:
      return {
        ...state,
        condDetail: action.data,
        condDetailErrors: [],
      };

    case ORD_COND_DETAIL_REQUEST_ERROR:
      return {
        ...state,
        condDetail: null,
        condDetailErrors: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };

    case ORD_COND_CANCEL_REQUESTING:
      return {
        ...state,
        condCancelSuccessful: false,
        condCancelErrors: [],
      };

    case ORD_COND_CANCEL_REQUEST_SUCCESS:
      return {
        ...state,
        condCancelSuccessful: true,
        condCancelErrors: [],
      };

    case ORD_COND_CANCEL_REQUEST_ERROR:
      return {
        ...state,
        condCancelSuccessful: false,
        condCancelErrors: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };

    default:
      return state;
  }
};

export default reducer;
