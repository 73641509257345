import React, { useEffect, useRef, useState } from 'react';
import { numberFormat, StringToInt } from '../../../util';
import {
  cashBalanceSourceRequest,
  cashTransferRequest,
} from '../../cash/actions';
import ReactSelect from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import TextMask from 'react-text-mask';
import { createNumberMask } from 'text-mask-addons';

const CashInternalTransfer = withNamespaces('translations')(
  ({ t, missing, target, callback, reloadCallback, className }) => {
    const dispatch = useDispatch();
    const { defaultAccount, listAccount } = useSelector(
      (state) => state.socket
    );
    const { token, auth } = useSelector((state) => state.client);
    const { sourceBalance } = useSelector((state) => state.cash);

    const _activeAccount = listAccount?.filter((item) => item.subAcntStat == 1);

    const [sources, setSources] = useState([]);
    const [source, setSource] = useState();
    const [value, setValue] = useState(0);
    const [isEdit, setIsEdit] = useState(false);
    const [error, setError] = useState();
    const prevValue = useRef(0);

    useEffect(() => {
      if (token && listAccount && !!listAccount.length > 0 && target) {
        let defAcc = null;
        if (token && token.custInfo && token.custInfo.normal) {
          defAcc = token.custInfo.normal.find((x) => x.isDefSubAcnt == 1);
        }

        const _sources = listAccount.filter(
          (x) => x.subAcntStat == 1 && x != target
        );

        if (_sources && !!_sources.length > 0) {
          setSources(_sources);
          if (defAcc && defAcc.subAcntNo != target.subAcntNo) setSource(defAcc);
          else setSource(_sources[0]);
        }
      }
    }, [listAccount, token]);

    useEffect(() => {
      if (sourceBalance) {
        let _value = sourceBalance?.wdrawAvail;
        if (_value > missing) _value = missing;
        setValue(_value);
        prevValue.current = _value;
      }
    }, [sourceBalance, missing]);

    useEffect(() => {
      if (source) loadCashBalanceSourceHandle(source);
    }, [source]);

    const loadCashBalanceSourceHandle = (source) => {
      const uuid = uuidv4();
      const resData = {
        group: 'CORE',
        user: token.user,
        session: token.session,
        cmd: 'getTotalAssetAll',
        rqId: uuid,
        channel: 'WTS',
        data: {
          acntNo: source.acntNo,
          subAcntNo: source.subAcntNo,
        },
      };
      dispatch(cashBalanceSourceRequest(resData));
    };

    const extendCallBack = () => {
      callback();
      loadCashBalanceSourceHandle(source);
    };

    const changeUserSourceHandle = (value) => {
      setSource(value);
    };

    const reloadHandle = () => {
      if (reloadCallback && typeof reloadCallback == 'function') {
        reloadCallback();
      }
    };

    const editHandle = () => {
      prevValue.current = value;
      setIsEdit(true);
    };

    const cancelEditHandle = () => {
      setIsEdit(false);
      setValue(prevValue.current);
    };

    const updateEditHandle = (e) => {
      setIsEdit(false);
    };

    const onchangeHandle = (e) => {
      const _value = StringToInt(e.target.value + '');
      setValue(_value);
      if (_value <= 0) {
        setError('Vui lòng nhập số tiền chuyển');
      } else if (_value > sourceBalance?.wdrawAvail) {
        setError('Số tiền chuyển lớn hơn số dư khả dụng');
      } else setError('');
    };

    const submit = () => {
      const _value = StringToInt(value);
      if (!error && _value > 0) {
        const uuid = uuidv4();
        const resData = {
          group: 'CORE',
          user: token.user,
          session: token.session,
          cmd: 'createCashTransfer',
          rqId: uuid,
          channel: 'WTS',
          type: auth.type,
          token: auth.token,
          data: {
            acntNo: source.acntNo,
            subAcntNo: source.subAcntNo,
            toAcntNo: target.subAcntNo,
            trdAmt: _value + '',
            feeType: 1, // bao gom phi
            desc: `Cash internal transfer from ${source.subAcntNo} to ${target.subAcntNo} in pinefolio page`,
            transType: 1, // chuyen khoan noi bo
          },
        };

        dispatch(cashTransferRequest(resData, () => extendCallBack()));
      }
    };
    // todos: get available cash, able to transfer like cash internal transfer

    return (
      <>
        {_activeAccount && _activeAccount.length > 1 && (
          <div className={`${className} pinefolio-icash-transfer`}>
            <div className="pinefolio-icash-transfer__header">
              <span className="pinefolio-icash-transfer__title text--sm fw-500">
                {t('txt-cash-internal-transfer')}
              </span>
              <button
                className="btn-icon btn--light"
                onClick={() => reloadHandle()}
              >
                <span className="icon iReload"></span>
              </button>
            </div>
            <div className="pinefolio-icash-transfer__body">
              <div className="pinefolio-icash-transfer__items">
                <div className="pinefolio-icash-transfer__item">
                  <span className="pinefolio-icash-transfer__key text--light3 text--sm">
                    {t('txt-label-source')}
                  </span>
                  <span className="pinefolio-icash-transfer__value  text--light text--sm p-0">
                    {/* {source} */}
                    {sources && !!sources.length && (
                      <ReactSelect
                        onChange={(value) => changeUserSourceHandle(value)}
                        options={[...sources]}
                        className="pinefolio-icash-transfer__select"
                        classNamePrefix="pinefolio-icash-transfer__select"
                        getOptionLabel={(option) => option.subAcntNo}
                        getOptionValue={(option) => option.subAcntNo}
                        defaultValue={defaultAccount}
                        value={source}
                        isSearchable={false}
                      />
                    )}
                  </span>
                </div>
                <div className="pinefolio-icash-transfer__item">
                  <span className="pinefolio-icash-transfer__key text--light3 text--sm">
                    {t('txt-available-balance')}
                  </span>
                  <span className="pinefolio-icash-transfer__value text--light text--sm">
                    {numberFormat(sourceBalance?.wdrawAvail, 0, '0')}
                  </span>
                </div>
                <div className="pinefolio-icash-transfer__item">
                  <span className="pinefolio-icash-transfer__key text--light3 text--sm">
                    {t('transaction.cashInternalTransfer.transferAmount')}
                  </span>
                  <span
                    className={`pinefolio-icash-transfer__value text--sm ${
                      isEdit ? 'p-0' : ''
                    }`}
                  >
                    <div className="transfer-value">
                      {!isEdit && (
                        <>
                          <span>{numberFormat(value, 0, '0')}</span>
                          <button
                            className="btn-icon btn--transparent"
                            onClick={() => editHandle()}
                          >
                            <span className="icon iEdit"></span>
                          </button>
                        </>
                      )}

                      {isEdit && (
                        <>
                          <TextMask
                            type="text"
                            className={`${
                              error ? 'input-error' : ''
                            } input-text-search`}
                            maxLength={15}
                            value={value}
                            placeholder={t('bond.bondQuantity.placeHolder')}
                            mask={createNumberMask({
                              prefix: '',
                              allowNegative: false,
                            })}
                            onFocus={(e) => e.currentTarget.select()}
                            onChange={(e) => onchangeHandle(e)}
                          />

                          <div className="transfer-value__actions">
                            <span
                              className="transfer-value__action"
                              onClick={() => updateEditHandle()}
                            >
                              <span className="icon iCheck"></span>
                            </span>
                            <span
                              className="transfer-value__action"
                              onClick={() => cancelEditHandle()}
                            >
                              <span className="icon iClose"></span>
                            </span>
                          </div>
                        </>
                      )}
                    </div>
                  </span>
                </div>
              </div>
              {error && (
                <span className="text-error text-center">{t(error)}</span>
              )}

              <div className="pinefolio-icash-transfer__actions">
                <button
                  className="btn btn--primary2"
                  disabled={error || value <= 0}
                  onClick={() => submit()}
                >
                  {t('txt-transfer-money')} {target.subAcntNo}
                </button>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
);

export default CashInternalTransfer;
