import React, { useState, useEffect, useContext } from 'react';
import { withNamespaces } from 'react-i18next';
import { ReactReduxContext, useDispatch, useSelector } from 'react-redux';
import { filter, sumBy, isEqual, some } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import PerfectScrollBar from 'react-perfect-scrollbar';
import {
  accountTypeDetect,
  checkRightEndDate,
  formatDate,
  getMessageError,
  mapRightType,
  numberFormat,
  StringToDouble,
  StringToInt,
  _formatDate2,
} from '../../../util';
import PaginationTable from '../../../components/PaginationTable';
import { Logout, logoutRequest } from '../../../components/modal/login/actions';
import { setAuth, setToast, unsetClient } from '../../client/actions';
import { removeCookie } from '../../../lib/storages';
import { makeGetCashBalance } from '../../../lib/selector';
import { rightListRequest } from '../../cash/actions';
import { rightEventRequest } from '../../summary/actions';
import { cashBalanceRequest } from '../../privData/actions';
import { handleApiErrors } from '../../../lib/api-errors';
import { setDefaultAccount } from '../../socket/actions';
import MaxInput from '../../../components/input/maxInput';
import ReactSelect from 'react-select';
import AssetHeader from '../components/header/assetHeader';

const appUrl = `${process.env.REACT_APP_API_URL}`;

const waitFor = (ms) => new Promise((r) => setTimeout(r, ms));
const asyncForEach = async (array, callback) => {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
};

const Index = withNamespaces('translations')(({ t, params }) => {
  const { listAccount, defaultAccount } = useSelector((state) => state.socket);
  const rightList = useSelector((state) => state.summary.rightList);
  const { token, auth } = useSelector((state) => state.client);
  const cashBalance = useSelector(makeGetCashBalance());
  const [refresh, setRefresh] = useState(false);
  const [rights, setRights] = useState([]);
  const [history, setHistory] = useState([]);
  const [totalAdv, setTotalAdv] = useState(0);
  const [types, setTypes] = useState([{ value: '', label: 'txt-all' }]);
  const dispatch = useDispatch();
  const { store } = useContext(ReactReduxContext);
  const _chkErr = some(rights, (o) => o.err && o.err.length > 0);

  useEffect(() => {
    if (defaultAccount) {
      reloadHandle();
    }
  }, [defaultAccount, refresh]);

  useEffect(() => {
    if (rightList && !!rightList.list?.length > 0) {
      setHistory(rightList.list);
      let _types = [{ value: '', label: 'txt-all' }];
      rightList.list.map((x) => {
        if (_types.some((x) => x.value != x.rghtSubTp)) {
          _types.push({
            value: x.rghtSubTp,
            label: 'txt-stt-right-' + x.rghtSubTp,
          });
        }
      });
      setTypes(_types);

      let _right = filter(
        rightList.list,
        (o) => o.rghtSubTp === '03' && checkRightEndDate(o.scrEndt)
      );

      if (_right) {
        _right.map((item) => {
          const uuid = uuidv4();
          item.key = uuid;
          item.amt = '';
          item.err = '';
          item.maxVol = item.stockQuantityRemain;

          return item;
        });
      }

      setRights(_right);
    } else {
      setRights([]);
    }
  }, [rightList, refresh]);

  useEffect(() => {
    if (rights && !!rights.length) {
      const _totalAdv = sumBy(
        rights,
        (o) => StringToInt(o.amt) * o.subscriptionPrice
      );
      setTotalAdv(_totalAdv);
    }
  }, [rights]);

  const getRightListHandle = () => {
    if (!token || !defaultAccount) return;

    const uuid = uuidv4();
    const params = {
      group: 'CORE',
      user: token.user,
      session: token.session,
      cmd: 'getRightList',
      rqId: uuid,
      channel: 'WTS',
      data: {
        acntNo: defaultAccount.acntNo,
        subAcntNo: defaultAccount.subAcntNo,
      },
    };

    dispatch(rightEventRequest(params));
  };

  const getRightHisListHandle = () => {
    if (!defaultAccount || !token) return;

    const numDay = token && token['config'] ? token['config']['numDay'] : 30;
    const d = new Date();
    d.setDate(d.getDate() - numDay);
    const uuid = uuidv4();

    const resData = {
      group: 'CORE',
      user: token.user,
      session: token.session,
      cmd: 'GetRegRightList',
      rqId: uuid,
      channel: 'WTS',
      data: {
        acntNo: defaultAccount.acntNo,
        subAcntNo: defaultAccount.subAcntNo,
        fromDate: formatDate(d, ''),
        toDate: formatDate(new Date(), ''),
      },
    };

    dispatch(rightListRequest(resData));
  };

  const loadCashBalanceHandle = () => {
    if (!defaultAccount || !token) return;
    const uuid = uuidv4();

    const resData = {
      group: 'CORE',
      user: token.user,
      session: token.session,
      cmd: 'getTotalAssetAll',
      rqId: uuid,
      channel: 'WTS',
      data: {
        acntNo: defaultAccount.acntNo,
        subAcntNo: defaultAccount.subAcntNo,
      },
    };
    dispatch(cashBalanceRequest(resData));
  };

  const advanceFeeHandle = async () => {
    if (!rights) return;
    try {
      await asyncForEach(rights, async (element) => {
        if (StringToInt(element.amt) > 0) {
          await waitFor(200);

          const uuid = uuidv4();
          const params = {
            group: 'CORE',
            user: token.user,
            session: token.session,
            cmd: 'RegistrationRight',
            rqId: uuid,
            channel: 'WTS',
            type: auth.type,
            token: auth.token,
            data: {
              acntNo: defaultAccount.acntNo,
              subAcntNo: defaultAccount.subAcntNo,
              rghtCode: element.rghtSubTp,
              basdt: element.basdt,
              symbol: element.symbol + '',
              qty: StringToInt(element.amt),
              price: element.subscriptionPrice,
            },
          };

          newRightHandle(params);
        }
      });

      await waitFor(200);
      reloadHandle();
    } catch (error) {
      console.log(error);
    }
  };

  const newRightHandle = (params) => {
    const url = `${appUrl}/CoreServlet.pt`;
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
      body: JSON.stringify(params),
    })
      .then(handleApiErrors)
      .then((response) => response.json())
      .then((res) => {
        console.log(res, params);
        if (res.http !== 200) {
          if (res.http === 401) {
            if (token) dispatch(logoutRequest(token));

            dispatch(unsetClient());
            removeCookie('token');
            removeCookie('authen');

            dispatch(Logout());
            toastHandle(t('txt-valid-loss-session'));
          } else {
            toastHandle(getMessageError(res, store.getState));
          }
        } else {
          toastHandle(t('txt-success'));
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const toastHandle = (msg) => {
    const toastMsg = {
      id: Math.random(),
      msg: msg,
      title: t('txt-notice'),
    };
    dispatch(setToast(toastMsg));
  };

  const reloadHandle = () => {
    loadCashBalanceHandle();
    getRightListHandle();
    getRightHisListHandle();
  };

  const changeUserHandle = (user) => {
    dispatch(setDefaultAccount(user));
  };

  const authenHandle = () => {
    dispatch(setAuth());
  };

  const procFeeHandle = () => {
    if (!some(rights, (o) => o.err && o.err.length > 0)) advanceFeeHandle();
  };

  const changeHandle = (value, index) => {
    const _rights = [...rights];
    if (!_rights || !_rights.length) return;

    _rights.map((item, i) => {
      if (i !== index) return item;

      item.amt = value;
      item.err =
        StringToInt(item.amt) > item.maxVol
          ? numberFormat(item.maxVol, 0, '0')
          : '';
      return item;
    });
    setRights(_rights);
  };

  const historyQuery = (symbol = '', type = '') => {
    if (rightList && !!rightList.list.length) {
      const _history = rightList.list.filter(
        (x) =>
          (symbol != '' ? x.symbol.includes(symbol.toUpperCase()) : true) &&
          (type?.value ? x.rghtSubTp == type.value : true)
      );

      console.log('_history: ', _history);

      setHistory(_history);
    }
  };

  const column = [
    {
      key: 'symbol',
      text: t('txt-symbol'),
      className: 'text-center text--light fw-500',
      align: 'center',
    },
    {
      text: t('txt-label-start-date'),
      className: 'text-center',
      align: 'center',
      cell: (record) => {
        return _formatDate2(record.scrStdt);
      },
    },
    {
      text: t('txt-label-base-date'),
      className: 'text-center',
      align: 'center',
      cell: (record) => {
        return _formatDate2(record.scrEndt);
      },
    },
    {
      text: t('txt-price'),
      className: 'text-center w-100p text--light',
      align: 'center',
      cell: (record) => {
        return numberFormat(record.subscriptionPrice, 0, '0');
      },
    },
    {
      text: t('txt-label-remaining-quantity'),
      className: 'text-center text--light',
      align: 'center',
      cell: (record) => {
        return numberFormat(
          record.dpstStockQty + record.stockQuantityRemain,
          0,
          '0'
        );
      },
    },
    {
      text: t('txt-label-purchased-quantity'),
      className: 'text-center text--light',
      align: 'center',
      cell: (record) => {
        return numberFormat(record.dpstStockQty, 0, '0');
      },
    },
    {
      text: t('txt-label-owned-quantity'),
      className: 'text-center text--light',
      align: 'center',
      cell: (record) => {
        return numberFormat(record.balanceQuantity, 0, '0');
      },
    },
    {
      text: t('txt-label-maximum-quantity'),
      className: 'text-center',
      align: 'center',
      cell: (record) => {
        return numberFormat(record.maxVol, 0, '0');
      },
    },
    {
      text: t('txt-vol'),
      className: 'w-150p',
      align: 'center',
      cell: (record, action, rowIndex) => {
        return (
          <MaxInput
            max={record.maxVol}
            className="px-0"
            index={rowIndex}
            callback={(value, index) => changeHandle(value, rowIndex)}
            placeholder={`${record.maxVol} ${t('txt-title-max')}`}
          />
        );
      },
    },
  ];

  const historyColumn = [
    {
      key: 'symbol',
      text: t('txt-symbol'),
      className: 'text-center text--light fw-500',
      align: 'center',
    },
    {
      text: t('txt-type'),
      className: 'text-center text--light fw-500',
      align: 'center',
      cell: (record) => {
        return mapRightType(record.rghtSubTp);
      },
    },
    {
      key: 'basdt',
      text: t('txt-label-expected-date'),
      className: 'text-center text--light',
      align: 'center',
      cell: (record) => {
        return _formatDate2(record.basdt);
      },
    },
    {
      text: t('txt-label-registration-date'),
      className: 'text-center text--light',
      align: 'center',
      cell: (record) => {
        if (record.rghtSubTp === '01') {
          if (record.dpstStockQty > 0) return _formatDate2(record.stockDate);

          if (record.dpstCashAmt > 0) return _formatDate2(record.cashDate);
        }

        return _formatDate2(record.stockDate);
      },
    },
    {
      key: 'balanceQuantity',
      text: t('txt-label-quantity-record-date'),
      className: 'text-right text--light',
      align: 'center',
      cell: (record) => {
        return numberFormat(record.balanceQuantity, 0, '0');
      },
    },
    {
      key: 'rightRate',
      text: t('txt-label-ratio'),
      className: 'text-center text--light',
      align: 'center',
      cell: (record) => {
        if (record.rghtSubTp === '01') {
          return numberFormat(StringToDouble(record.rightRate), 2, '0') + '%';
        }
        return record.rightRate;
      },
    },
    {
      text: t('txt-stock-waiting'),
      className: 'text-right text--light',
      align: 'center',
      cell: (record) => {
        return numberFormat(record.dpstStockQty, 0, '0');
      },
    },
    {
      text: t('txt-asset-collateral'),
      className: 'text-right text--light',
      align: 'center',
      cell: (record) => {
        return numberFormat(record.dpstCashAmt, 0, '0');
      },
    },
  ];

  return (
    <>
      <div className="personal-assets card-panel-2">
        <AssetHeader
          account={defaultAccount}
          setAccount={(acc) => changeUserHandle(acc)}
          params={params}
          setRefresh={() => setRefresh(!refresh)}
          refresh={refresh}
        />
        <div className="personal-assets-body card-panel-2-body">
          <PerfectScrollBar>
            <div className="right-infor">
              <div className="right-infor__header mt-24 ">
                <span className="text-title text--light">
                  {t('transaction.right.name')}
                </span>
                <span>
                  <span className="text-name text--light3">
                    {t('txt-label-max-subscription')}
                  </span>
                  <span className="text-name text--light ml-2">
                    {cashBalance &&
                      numberFormat(cashBalance.wdrawAvail, 0, '0')}
                  </span>
                </span>
              </div>
              <div className="right-infor__body">
                <PaginationTable
                  t
                  key={refresh}
                  pageSize={5}
                  columns={column}
                  source={rights || []}
                  searchField="symbol"
                  hasSort="true"
                />
              </div>
              <div className="right-infor__footer">
                <span className="mr-1">
                  <span className="text-name text--light3">
                    {t('txt-label-total-amount')}:
                  </span>
                  <span className="text-name text--light ml-2">
                    {numberFormat(totalAdv, 0)}
                  </span>
                </span>
                {auth && auth.isVerified ? (
                  <>
                    <button
                      className="btn btn--reset"
                      onClick={() => setRefresh(!refresh)}
                      disabled={!totalAdv || totalAdv < 1 || _chkErr}
                    >
                      {t('txt-clear')}
                    </button>
                    <button
                      className="btn btn--primary"
                      onClick={() => procFeeHandle()}
                      disabled={!totalAdv || totalAdv < 1 || _chkErr}
                    >
                      {t('txt-registration')}
                    </button>
                  </>
                ) : (
                  <button
                    className="btn btn--authen"
                    onClick={() => authenHandle()}
                  >
                    {t('txt-registration')}
                  </button>
                )}
              </div>
            </div>
            <div className="right-infor-history">
              <HistoryFilter
                key={refresh}
                callback={historyQuery}
                sourceType={types}
              />
              <PaginationTable
                t
                pageSize={5}
                columns={historyColumn}
                source={history || []}
                hasPaging
                searchField="symbol"
                hasSort="true"
              />
            </div>
          </PerfectScrollBar>
        </div>
      </div>
    </>
  );
});

const HistoryFilter = withNamespaces('translations')(
  ({ t, callback, sourceType }) => {
    const [symbol, setSymbol] = useState('');
    const [type, setType] = useState('');

    console.log('sourceType', sourceType);
    useEffect(() => {
      if (callback && typeof callback == 'function') {
        callback(symbol, type);
      }
    }, [symbol, type]);

    return (
      <div className="transaction-filters">
        <span className="text-title mt-24 text--light">
          {t('transaction.right.history')}
        </span>

        <div className="d-flex gap-4">
          <div className="input-text-search d-flex align-items-center">
            <input
              placeholder={t('txt-symbol')}
              onChange={(e) => setSymbol(e.target.value)}
              maxLength={12}
            />
            <span className="icon iSearch"></span>
          </div>
          <ReactSelect
            placeholder={t('txt-all')}
            className="transaction-filters__select"
            classNamePrefix="filter-control-select"
            options={sourceType}
            isSearchable={false}
            components={{
              IndicatorSeparator: () => null,
            }}
            value={type}
            onChange={(value) => setType(value)}
            getOptionLabel={(option) => t(option.label)}
            getOptionValue={(option) => option.value}
          />
        </div>
      </div>
    );
  }
);

export default Index;
