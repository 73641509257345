import React, { Fragment } from 'react';
import { GiAnticlockwiseRotation } from 'react-icons/gi';

import PerfectScrollBar from 'react-perfect-scrollbar';
import { Col, Card } from 'react-bootstrap';

import PieChart from '../../components/hightchart/pieChart';
import { accountTypeDetect, numberFormat } from '../../util';
import { Link } from 'react-router-dom';
import { FaChevronRight } from 'react-icons/fa';
import { translate } from 'react-i18next';
class BaoCaoTaiSan extends React.PureComponent {
  render() {
    const { cashBalance, account, t } = this.props;
    const _perStock =
      cashBalance && cashBalance.totAsst
        ? Math.round(cashBalance.stockValue * 100) / cashBalance.totAsst
        : 0;
    const _perDebt =
      cashBalance && cashBalance.totAsst
        ? Math.round(cashBalance.debt * 100) / cashBalance.totAsst
        : 0;
    const asset1 = {
      name: t('txt-label-cash'),
      value: 100 - _perStock,
      color: '#589dc0',
    };
    const asset2 = {
      name: t('txt-label-position'),
      value: _perStock,
      color: '#ffb613',
    };
    const debt1 = {
      name: t('txt-nav'),
      value: 100 - _perDebt,
      color: '#8fcf4a',
    };
    const debt2 = { name: t('txt-debt'), value: _perDebt, color: '#44d7b6' };
    console.log(asset1, asset2);
    return (
      <Fragment>
        <div className="d-flex flex-row mt-3 mb-2">
          <span className="ml-2 text-white" style={{ fontSize: '18px' }}>
            {t('txt-title-asset-manage')}
          </span>
          &nbsp;
          <span
            className="mr-auto fw-500 text-white"
            style={{ fontSize: '18px' }}
          >
            {account &&
              account.value &&
              account.value + ' - ' + t(accountTypeDetect(account))}
          </span>
          <a
            className="mr-3 btn-reload"
            onClick={() => {
              this.props.reload();
            }}
          >
            <GiAnticlockwiseRotation className="fz_14 text-white" />
          </a>
        </div>
        <PerfectScrollBar style={{ height: 'calc(100% - 155px)' }}>
          <div className="d-flex flex-row">
            <Col className="d-flex flex-column pr-0">
              <Card>
                <Card.Header className="pl-2">{t('txt-asset')}</Card.Header>
                <Card.Body>
                  {cashBalance && <PieChart data1={asset1} data2={asset2} />}
                </Card.Body>
              </Card>
              <table className="table table-bordered mt-2">
                <thead>
                  <tr>
                    <th className="text-right">{t('txt-asset-total')}</th>
                    <th className="text-right">{t('txt-asset-nav')}</th>
                    <th className="text-right">{t('txt-asset-withdrawal')}</th>
                    <th className="text-right">
                      {t('txt-asset-receivable-1')}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-right text-white">
                      {cashBalance && numberFormat(cashBalance.totAsst, 0, '0')}
                    </td>
                    <td className="text-right text-white">
                      {cashBalance &&
                        numberFormat(cashBalance.realAsst, 0, '0')}
                    </td>
                    <td className="text-right text-white">
                      {cashBalance &&
                        numberFormat(cashBalance.wdrawAvail, 0, '0')}
                    </td>
                    <td className="text-right text-white">
                      {cashBalance &&
                        numberFormat(cashBalance.advanceAvail, 0, '0')}
                    </td>
                  </tr>
                </tbody>
              </table>
              <table className="table table-bordered mt-2">
                <thead>
                  <tr>
                    <th className="text-left" colSpan="2">
                      <span className="text-uppercase">
                        {'I. ' +
                          t('txt-asset-balance') +
                          ' = (1) + (2) + (3) - (4)'}
                      </span>
                      <Link
                        to="sao-ke-tien"
                        className="float-right font_14 color-lightning"
                      >
                        <span className="float-right font_14 color-lightning">
                          {cashBalance &&
                            numberFormat(cashBalance.cash, 0, '0')}
                        </span>
                      </Link>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-left w-60">
                      1. {t('txt-asset-cash-balance')}
                    </td>
                    <td className="text-right text-white">
                      {cashBalance && numberFormat(cashBalance.balance, 0, '0')}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-left">
                      2. {t('txt-asset-receivable-1')}
                    </td>
                    <td className="text-right text-white">
                      {cashBalance &&
                        numberFormat(cashBalance.advanceAvail, 0, '0')}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-left">
                      <span className="ml-3">2.1. {t('txt-advanceAvail')}</span>
                    </td>
                    <td className="text-right text-white">
                      {cashBalance &&
                        numberFormat(cashBalance.receiveAmt, 0, '0')}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-left">
                      <span className="ml-3">2.2. {t('txt-AdvanceDebt')}</span>
                    </td>
                    <td className="text-right text-white">
                      {cashBalance &&
                        numberFormat(cashBalance.advanceLoan, 0, '0')}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-left">3. {t('txt-CashDividence')}</td>
                    <td className="text-right text-white">
                      {cashBalance && numberFormat(cashBalance.cashDiv, 0, '0')}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-left">4. {t('txt-BoughtT0')}</td>
                    <td className="text-right text-white">
                      {cashBalance && numberFormat(cashBalance.buyT0, 0, '0')}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-left">
                      5. {t('txt-UnMatchedBoughtT0')}
                    </td>
                    <td className="text-right text-white">
                      {cashBalance &&
                        numberFormat(cashBalance.tdtBuyAmtNotMatch, 0, '0')}
                    </td>
                  </tr>
                </tbody>
              </table>
              <table className="table table-bordered mt-2">
                <thead>
                  <tr>
                    <th className="text-left" colSpan="2">
                      <span className="text-uppercase">
                        {'II. ' + t('txt-asset-stocks') + ' = (1) + (2)'}
                      </span>
                      <Link
                        to="sao-ke-chung-khoan"
                        className="float-right font_14 color-lightning"
                      >
                        <span className="float-right font_14 color-lightning">
                          {cashBalance &&
                            numberFormat(cashBalance.stockValue, 0, '0')}
                        </span>
                      </Link>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-left w-60">
                      1. {t('txt-TotalStocks')}
                    </td>
                    <td className="text-right text-white">
                      {cashBalance &&
                        numberFormat(cashBalance.totalStock, 0, '0')}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-left">
                      <span className="ml-3">
                        1.1. {t('txt-AvailTradingStock')}
                      </span>
                    </td>
                    <td className="text-right text-white">
                      {cashBalance &&
                        numberFormat(cashBalance.tavlStockValue, 0, '0')}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-left">
                      <span className="ml-3">1.2. {t('txt-PTAStock')}</span>
                    </td>
                    <td className="text-right text-white">
                      {cashBalance &&
                        numberFormat(cashBalance.ptavlStockValue, 0, '0')}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-left">
                      <span className="ml-3">1.3. {t('txt-TARestric')}</span>
                    </td>
                    <td className="text-right text-white">
                      {cashBalance &&
                        numberFormat(cashBalance.tartStockValue, 0, '0')}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-left">
                      <span className="ml-3">1.4. {t('txt-PTARestric')}</span>
                    </td>
                    <td className="text-right text-white">
                      {cashBalance &&
                        numberFormat(cashBalance.ptartStockValue, 0, '0')}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-left">
                      <span className="ml-3">1.5. {t('txt-RightStock')}</span>
                    </td>
                    <td className="text-right text-white">
                      {cashBalance &&
                        numberFormat(cashBalance.righStockValue, 0, '0')}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-left">
                      2. {t('txt-ReceivingStockQtty')}
                    </td>
                    <td className="text-right text-white">
                      {cashBalance &&
                        numberFormat(cashBalance.rcvStockValue, 0, '0')}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-left">3. {t('txt-SoldT0')}</td>
                    <td className="text-right text-white">
                      {cashBalance && numberFormat(cashBalance.sellT0, 0, '0')}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Col>
            <Col className="d-flex flex-column">
              <Card>
                <Card.Header className="pl-2">{t('txt-debt')}</Card.Header>
                <Card.Body>
                  {cashBalance && <PieChart data1={debt1} data2={debt2} />}
                </Card.Body>
              </Card>
              <table className="table table-bordered mt-2">
                <thead>
                  <tr>
                    <th className="text-right">{t('txt-MarginDebt')}</th>
                    <th className="text-right">{'RTT'}</th>
                    <th className="text-right">{t('txt-pp')}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-right text-white">
                      {cashBalance && numberFormat(cashBalance.mgDebt, 0, '0')}
                    </td>
                    <td className="text-right text-white">
                      {cashBalance &&
                        numberFormat(cashBalance.marginRatio, 2, '0') + '%'}
                    </td>
                    <td className="text-right text-white">
                      {cashBalance && numberFormat(cashBalance.pp0, 0, '0')}
                    </td>
                  </tr>
                </tbody>
              </table>
              <table className="table table-bordered mt-2">
                <thead>
                  <tr>
                    <th className="text-left" colSpan="2">
                      <span className="text-uppercase">
                        III. {t('txt-TotalDebt')} = (1) + (2) + (3)
                      </span>
                      <Link
                        to="bao-cao-no"
                        className="float-right font_14 color-lightning"
                      >
                        <span className="float-right font_14 color-lightning">
                          {cashBalance &&
                            numberFormat(cashBalance.debt, 0, '0')}
                        </span>
                      </Link>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-left w-50">1. {t('txt-MarginDebt')}</td>
                    <td className="text-right text-white">
                      {cashBalance && numberFormat(cashBalance.mgDebt, 0, '0')}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-left">
                      <span className="ml-3">1.1. {t('txt-Principal')}</span>
                    </td>
                    <td className="text-right text-white">
                      {cashBalance &&
                        numberFormat(cashBalance.prinDebt, 0, '0')}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-left">
                      <span className="ml-3">1.2. {t('txt-Interest')}</span>
                    </td>
                    <td className="text-right text-white">
                      {cashBalance && numberFormat(cashBalance.intDebt, 0, '0')}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-left">
                      2. {t('txt-ExpectedDisbursement')}
                    </td>
                    <td className="text-right text-white">
                      {cashBalance &&
                        numberFormat(cashBalance.exptDisbm, 0, '0')}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-left">3. {t('txt-label-fee')}</td>
                    <td className="text-right text-white">
                      {cashBalance && numberFormat(cashBalance.fee, 0, '0')}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-left">
                      <span className="ml-3">3.1. {t('txt-depoFee')}</span>
                    </td>
                    <td className="text-right text-white">
                      {cashBalance && numberFormat(cashBalance.depoFee, 0, '0')}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-left">
                      <span className="ml-3">
                        3.2. {t('txt-asset-SMS-fee')}
                      </span>
                    </td>
                    <td className="text-right text-white">
                      {cashBalance && numberFormat(cashBalance.smsFee, 0, '0')}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-left">
                      <span className="ml-3">
                        3.3. {t('txt-asset-other-fee')}
                      </span>
                    </td>
                    <td className="text-right text-white">
                      {cashBalance && numberFormat(cashBalance.othrFee, 0, '0')}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Col>
          </div>
          <div className="mt-1"></div>
        </PerfectScrollBar>
      </Fragment>
    );
  }
}

export default translate('translations')(BaoCaoTaiSan);
