import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { withNamespaces } from 'react-i18next';
import '../index.scss';
import TextMask from 'react-text-mask';
import { createNumberMask } from 'text-mask-addons';
import { v4 as uuidv4 } from 'uuid';
import AuthenStep from '../../../../components/common/auth';
import {
  PARTNER_FEE_POLICY,
  PARTNER_SERVICE_TYPE,
  constants,
} from '../../../../util/constant';
import {
  getCommonRequest,
  submitRegistPartnership,
} from '../../../customer/actions';
import {
  makeGetBrokerInfo,
  makeGetSerList4Cust,
} from '../../../../lib/selector';
import { isArrayNullOrEmpty, numberFormat } from '../../../../util';
import ReactSelect from 'react-select';
import { uniq, toNumber, isNaN } from 'lodash';
import { setToast } from '../../../client/actions';

/**
 * This component try to aim 3 scenarios in both customer and partner side:
 * 1 - Only view a change fee request
 * 2 - View and Approve/Reject
 * 3 - Edit and Submit change (create new change fee request)
 * props:
 * isCustomer: true if requester is customer || false if requester is broker
 * isEdit: editable (using scenario 3, but available for scen 2 also)
 * isConfirm: true for scenario 2
 * isCompare: using for scenario 1, 2. True to display old and new value column (readonly).
 *            This time the systen is not support storage old fee in each request, so just only display old, new column in pending changeFee request
 * todos:
 * 1. Consider to merge isCompare with isConfirm purpose
 * 2. Align new, old columns tobe right and set width of new column alway max width
 */

const ModalChangeFee = withNamespaces('translations')(
  ({
    t,
    showModal,
    handleClose,
    isCustomer,
    dataCustomer,
    isEdit = false,
    isConfirm = false,
    isCompare = false,
    submitApproveCallback,
  }) => {
    // isCustomer: vai trò hiện tại của người dùng, nếu là ctv thì là false và ngược lại
    const theme = useSelector((state) => state.client.currentTheme);
    const { token, auth } = useSelector((state) => state.client);
    const [oneTimeToken, setOneTimeToken] = useState();
    const [dataChange, setDataChange] = useState();

    const dispatch = useDispatch();
    const defaultAccount = useSelector((state) => state.socket.defaultAccount);
    const { serList4Cus } = useSelector((state) => state.customer);
    // const brokerInfo = useSelector(makeGetBrokerInfo);

    const submitChange = () => {
      if (!token) return;
      if (isArrayNullOrEmpty(dataChange)) {
        // Todos: Toast alert
        const toastMsg = {
          id: Math.random(),
          msg: t('txt-no-change-note'),
          title: t('txt-notice'),
        };
        dispatch(setToast(toastMsg));
        return;
      }

      const checkFee = (value, unit = null) => {
        let convertValue = value;
        if (unit) {
          convertValue = (convertValue + '')
            .replace(unit, '')
            .replace(/,/g, '');
        }

        const parseValue = toNumber(convertValue);
        if (!isNaN(parseValue)) return parseValue;
        else return convertValue;
      };

      const uuid = uuidv4();
      const params = {
        group: 'CORE',
        user: token.user,
        session: token.session,
        cmd: 'chngFee',
        rqId: uuid,
        channel: 'WTS',
        type: '6',
        token: oneTimeToken ?? '',
        data: {
          cif: token.cif,
          acntNo: defaultAccount.acntNo,
          side: isCustomer ? '2' : '1',
          brkId: isCustomer ? serList4Cus?.brokerId : dataCustomer?.brokerId,
          vsdAcntNo: isCustomer
            ? defaultAccount?.vsdAcntNo
            : dataCustomer?.vsdAcntNo,
          subAcntNo: defaultAccount.subAcntNo,
          feePolicy: dataChange.map((x) => {
            return {
              serTp: x.serTp,
              feePolicy: x.policies.map((p) => ({
                frVal: p.frVal,
                toVal: p.toVal,
                fee: checkFee(p.fee, p.levyTp),
              })),
            };
          }),
        },
      };

      const callback = (data) => {
        const toastMsg = {
          id: Math.random(),
          msg: t('txt-request-change-sent'),
          title: t('txt-notice'),
        };
        dispatch(setToast(toastMsg));

        if (typeof handleClose == 'function') handleClose();
      };
      dispatch(submitRegistPartnership(params, callback, true));
    };

    const SubmitConfirm = (isApprove = false) => {
      if (!token) return;

      const uuid = uuidv4();
      const params = {
        group: 'CORE',
        user: token.user,
        session: token.session,
        cmd: 'confRejChngFeeReq',
        rqId: uuid,
        channel: 'WTS',
        type: '6',
        token: oneTimeToken ?? '',
        data: {
          cif: token.cif,
          acntNo: defaultAccount?.acntNo,
          side: isCustomer ? '2' : '1',
          brkId: isCustomer ? serList4Cus?.brokerId : dataCustomer?.brokerId,
          vsdAcntNo: isCustomer
            ? defaultAccount?.vsdAcntNo
            : dataCustomer?.vsdAcntNo,
          subAcntNo: isCustomer
            ? defaultAccount?.subAcntNo
            : dataCustomer?.vsdAcntNo,
          reqDt: dataCustomer?.reqDate,
          reqSeq: dataCustomer?.reqSeq,
          cfrmTp: isApprove ? '1' : '2',
        },
      };

      const callback = (data) => {
        if (data) {
          const toastMsg = {
            id: Math.random(),
            msg: isApprove
              ? t('partner.approve') + ' ' + t('txt-success').toLowerCase()
              : t('partner.reject') + ' ' + t('txt-success').toLowerCase(),
            title: t('txt-notice'),
          };
          dispatch(setToast(toastMsg));

          if (
            submitApproveCallback &&
            typeof submitApproveCallback == 'function'
          ) {
            submitApproveCallback(isApprove ? 'approve' : 'reject');
          }
        } else {
          const toastMsg = {
            id: Math.random(),
            msg: isApprove
              ? t('partner.approve') + ' ' + t('txt-unsuccessful').toLowerCase()
              : t('partner.reject') + ' ' + t('txt-unsuccessful').toLowerCase(),
            title: t('txt-notice'),
          };
          dispatch(setToast(toastMsg));
        }
      };
      dispatch(submitRegistPartnership(params, callback, true));
    };

    return (
      <Modal
        onHide={handleClose}
        onEscapeKeyDown={handleClose}
        show={showModal}
        dialogClassName="wts-modal"
        backdropClassName="wts-modal-backdrop"
        contentClassName={`${
          theme?.name || 'dark'
        } wts-modal-content change-fee-modal`}
        centered
        style={{ zIndex: 11114 }}
      >
        <div className="wts-modal__header change-fee-modal__header">
          <span className="icon iNone"></span>
          <span className="text text--light text--md">
            {t('partner.changeFee')}
          </span>
          <button
            className="icon iClose i-3 m-1"
            onClick={() => handleClose()}
          ></button>
        </div>

        <div className="wts-modal__body change-fee-modal__body">
          <div className="d-flex flex-column gap-12 w-100 general-infor">
            {isCustomer
              ? [
                  {
                    label: t('partner.referralCode'),
                    value: serList4Cus?.brokerId,
                  },
                  {
                    label: t('partner.referralName'),
                    value: serList4Cus?.brokerNm,
                  },
                ].map((item, i) => (
                  <div className="d-flex gap-12" key={i}>
                    <div className="text text--sm text--light flex-1">
                      {item?.label}
                    </div>
                    <div className="text text--sm text--light fw-500 flex-3">
                      {item?.value}
                    </div>
                  </div>
                ))
              : [
                  {
                    label: t('txt-vsd-number'),
                    value: dataCustomer?.vsdAcntNo,
                  },
                  {
                    label: t('txt-label-customer-name'),
                    value: dataCustomer?.custNm,
                  },
                ].map((item, i) => (
                  <div className="d-flex gap-12" key={i}>
                    <div className="text text--sm text--light flex-1">
                      {item?.label}
                    </div>
                    <div className="text text--sm text--light fw-500 flex-3">
                      {item?.value}
                    </div>
                  </div>
                ))}
          </div>

          <div className="service-groups d-flex flex-column gap-6 w-100">
            <GroupServiceType
              isEdit={isEdit}
              isCompare={isCompare}
              isCustomer={isCustomer}
              dataCustomer={dataCustomer}
              onChangeCallback={setDataChange}
            />
          </div>
          <div className="service-groups d-flex flex-column gap-6 w-100">
            {(isEdit || isConfirm) && (
              <div className="mt-1">
                <AuthenStep
                  setOneTimeToken={setOneTimeToken}
                  trackMixpanel
                  isBorder={false}
                />
              </div>
            )}

            {isEdit && !isConfirm && (
              <div className="service-actions">
                <div className="service-action">
                  <button
                    className={'btn btn--primary w-100'}
                    onClick={() => submitChange()}
                    disabled={!oneTimeToken}
                  >
                    {t('txt-confirm')}
                  </button>
                </div>
              </div>
            )}

            {!isEdit && isConfirm && (
              <div className="service-actions">
                <div className="service-action">
                  <button
                    className={'btn btn--primary w-100'}
                    onClick={() => SubmitConfirm(true)}
                    disabled={!oneTimeToken}
                  >
                    {t('partner.approve')}
                  </button>
                </div>
                <div className="service-action">
                  <button
                    className={'btn btn-danger-secondary w-100'}
                    onClick={() => SubmitConfirm(false)}
                    disabled={!oneTimeToken}
                  >
                    {t('partner.reject')}
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </Modal>
    );
  }
);

const GroupServiceType = withNamespaces('translations')(
  ({ t, isCustomer, onChangeCallback, dataCustomer, isEdit, isCompare }) => {
    const {
      token,
      setting: { lang = 'vi' },
    } = useSelector((state) => state.client);
    const [data, setData] = useState([]);
    const [maxOldWidth, setMaxOldWidth] = useState();
    const [maxNewWidth, setMaxNewWidth] = useState();
    const [dataChange, setDataChange] = useState([]);
    const { defaultAccount } = useSelector((state) => state.socket);
    const containerRef = useRef(null);

    const dispatch = useDispatch();

    const getFeePolicyHandle = (
      langTp,
      customerAccountNo = defaultAccount?.acntNo
    ) => {
      if (!token) return;
      const uuid = uuidv4();
      const resData = {
        group: 'CORE',
        user: token.user,
        session: token.session,
        cmd: 'getFeePolicy',
        rqId: uuid,
        channel: 'WTS',
        data: {
          langTp: langTp,
          cif: token.cif,
          acntNo: isCustomer ? defaultAccount?.acntNo : customerAccountNo,
          grpAcctTp: PARTNER_FEE_POLICY.currentForCustomer + '',
          serTp: PARTNER_SERVICE_TYPE.all,
        },
      };

      const callback = (serviceTypes) => {
        const _data = serviceTypes?.map((x) => ({
          serTp: x.serTp,
          serviceType: x.serTpNm,
          serviceTypeDesc: x.serTpDesc,
          subAcntClss: x.subAcntClss,
          policies: x.policy,
        }));

        if (!isEdit && !!_data?.length) {
          const _oldData = JSON.parse(dataCustomer.feePolicyDtl)?.map((x) => ({
            serTp: x.serTp,
            serviceType: _data.find((d) => d.serTp == x.serTp)?.serviceType,
            serviceTypeDesc: _data.find((d) => d.serTp == x.serTp)
              ?.serviceTypeDesc,
            subAcntClss: _data.find((d) => d.serTp == x.serTp)?.subAcntClss,
            policies: x.feePolicy,
          }));

          const _mergeData = _oldData.map((x) => ({
            serTp: x.serTp,
            serviceType: x.serviceType,
            serviceTypeDesc: x.serviceTypeDesc,
            subAcntClss: x.subAcntClss,
            policies: x.policies.map((p) => ({
              frVal: p.frVal,
              toVal: p.toVal,
              oldFee: _data
                .find((_d) => _d.serTp == x.serTp)
                ?.policies.find((f) => f.frVal == p.frVal && f.toVal == p.toVal)
                ?.fee,
              levyTp: _data
                .find((_d) => _d.serTp == x.serTp)
                ?.policies.find((f) => f.frVal == p.frVal && f.toVal == p.toVal)
                ?.levyTp,
              fee: p.fee,
              steps:
                _data
                  .find((_d) => _d.serTp == x.serTp)
                  ?.policies.find(
                    (f) => f.frVal == p.frVal && f.toVal == p.toVal
                  )?.steps || [],
            })),
          }));

          setData(_mergeData);
          setDataChange(_mergeData);
        } else {
          setData(JSON.parse(JSON.stringify(_data)));
          setDataChange(JSON.parse(JSON.stringify(_data)));
        }
      };

      dispatch(getCommonRequest(resData, callback, true));
    };

    const getCustomerInfor = (vsdCustomer, callback) => {
      if (!token) return;

      const uuid = uuidv4();
      const resData = {
        group: 'CORE',
        user: token.user,
        session: token.session,
        cmd: 'getCustRefList',
        rqId: uuid,
        channel: 'WTS',
        data: {
          cif: token.cif,
          acntNo: defaultAccount.acntNo,
          vsdAcntNo: vsdCustomer,
          serTp: '',
        },
      };

      dispatch(getCommonRequest(resData, callback, true));
    };

    // using for editable case
    const feeChangeHandle = (serviceTypeIndex, policyIndex, newFee) => {
      setDataChange((prevData) => {
        const newData = [...prevData];
        newData[serviceTypeIndex].policies[policyIndex].fee = newFee;
        if (typeof onChangeCallback == 'function') {
          const _dataChange = findDifferentFees(data, newData);
          onChangeCallback(_dataChange);
        }
        return newData;
      });
    };

    const findDifferentFees = (A, B) => {
      return B.filter((bItem) => {
        const aItem = A.find((aItem) => aItem.serTp === bItem.serTp);
        if (!aItem) return false;
        return bItem.policies.some((bPolicy, index) => {
          const aPolicy = aItem.policies[index];
          return aPolicy && aPolicy.fee !== bPolicy.fee;
        });
      });
    };

    useEffect(() => {
      if (containerRef.current) {
        const calculateMaxWidth = () => {
          const newElements =
            containerRef.current.querySelectorAll('.new-price');
          const oldElements =
            containerRef.current.querySelectorAll('.old-price');
          let newMax = 0,
            oldMax = 0;

          newElements.forEach((element) => {
            const width = element.getBoundingClientRect().width;
            if (width > newMax) {
              newMax = width;
            }
          });
          setMaxNewWidth(newMax);

          oldElements.forEach((element) => {
            const width = element.getBoundingClientRect().width;
            if (width > oldMax) {
              oldMax = width;
            }
          });

          setMaxOldWidth(oldMax);
        };

        calculateMaxWidth();
      }
    }, [data, containerRef.current]);

    useEffect(() => {
      // if isCustomer is false then getFeePolicy need to pass customer accounNo to get FeePolicy
      if (lang) {
        if (!isCustomer) {
          getCustomerInfor(dataCustomer.vsdAcntNo, (data) => {
            if (!!data?.length) getFeePolicyHandle(lang, data[0].acntNo);
            else getFeePolicyHandle(lang, data[0].acntNo);
          });
        } else {
          getFeePolicyHandle(lang);
        }
      }
    }, [isCustomer, lang]);

    console.log('maxNewWidth', maxNewWidth);
    console.log('maxOldWidth', maxOldWidth);

    return (
      <div ref={containerRef}>
        {data.map((group, i) => (
          <div className="service-group" key={i}>
            <div className="text text--md text--light d-flex justify-content-between">
              <span className="flex-1">
                {group?.serviceType}
                {group?.serviceTypeDesc && isEdit && (
                  <span className="text text--gray text--sm ml-1 fw-500">
                    ({group?.serviceTypeDesc})
                  </span>
                )}
              </span>
              {isCompare && i == 0 && (
                <div className="d-flex gap-12 service-group__note flex-1 pt-1 justify-content-end">
                  <span
                    className="text text--gray text--sm fw-500 text-center"
                    style={{ width: `${maxOldWidth || 50}px` }}
                  >
                    {t('txt-old')}
                  </span>
                  <span
                    className="text text--gray text--sm fw-500 text-center"
                    style={{ width: `${maxNewWidth || 50}px` }}
                  >
                    {t('txt-new')}
                  </span>
                </div>
              )}
            </div>
            {!!group?.policies?.length &&
              group?.policies.map((policy, j) => (
                <ServiceType
                  key={j}
                  policy={policy}
                  isEdit={isEdit}
                  isCompare={isCompare}
                  oldFee={policy?.oldFee}
                  subAcntClss={group.subAcntClss}
                  onChangeCallback={(value) => feeChangeHandle(i, j, value)}
                  showFullDesc={group?.policies?.length > 1}
                />
              ))}
            {group.subAcntClss === 'D' && (
              <div className="text text--sm r">
                *{t('partner.derivativeFeeNote')}
              </div>
            )}
          </div>
        ))}
      </div>
    );
  }
);

const ServiceType = withNamespaces('translations')(
  ({
    policy,
    oldFee,
    t,
    isEdit,
    isCompare,
    subAcntClss,
    onChangeCallback,
    showFullDesc,
  }) => {
    const [stepIndex, setStepIndex] = useState();
    const [newValue, setNewValue] = useState({
      value: policy?.fee,
      label:
        policy?.levyTp == 'VND'
          ? numberFormat(policy.fee, 0, '')
          : policy.fee + policy.levyTp,
    });

    const steps = !isEdit
      ? []
      : policy.steps.map((x) => ({
          value: x,
          label:
            policy.levyTp == 'VND' ? numberFormat(x, 0, '') : x + policy.levyTp,
        }));

    const unit = policy.levyTp == 'VND' ? '' : ' ' + policy.levyTp;

    const onChangeFeeHandle = (e) => {
      setNewValue((prev) => ({ ...prev, value: e.target.value }));
    };

    const minusHandle = () => {
      if (stepIndex > 0) setStepIndex(stepIndex - 1);
    };

    const plusHandle = () => {
      if (!!steps?.length && stepIndex < steps.length - 1)
        setStepIndex(stepIndex + 1);
    };

    useEffect(() => {
      onChangeCallback(newValue?.value);
    }, [newValue]);

    useEffect(() => {
      if (!!policy.steps?.length) {
        const _steps = policy.steps;
        const _step = _steps[stepIndex];
        setNewValue((prev) => ({ ...prev, value: _step }));
      }
    }, [stepIndex]);

    useEffect(() => {
      if (policy && !!policy?.steps?.length) {
        const _stepIndex = policy?.steps.lastIndexOf(policy?.fee);
        setStepIndex(_stepIndex);
      }
    }, [policy]);

    const currencyMask = (rawValue) => {
      const numberMask = createNumberMask({
        prefix: '',
        allowDecimal: true,
        includeThousandsSeparator: true,
        thousandsSeparatorSymbol: ',',
        decimalSymbol: '.',
        allowNegative: false,
      })(rawValue);

      return [...numberMask, unit];
    };

    if (!policy) return <></>;
    return (
      <div className="d-flex justify-content-between align-items-center service-type">
        {!showFullDesc ? (
          <span className="text text--sm text--light text-right">
            {t('partner.rateUsed' + subAcntClss)}
          </span>
        ) : (
          <span className="text text--sm text--light text-right">
            {t('partner.rateUsed' + subAcntClss) +
              ' ' +
              numberFormat(policy?.frVal, 0, '0') +
              (policy?.toVal
                ? '   ->   ' + numberFormat(policy?.toVal, 0, '0')
                : '') +
              ` (${
                subAcntClss === constants.subAccount.Derivative
                  ? t('contract.short.c')
                  : 'VND'
              }) `}
          </span>
        )}

        {!isEdit ? (
          !isCompare ? (
            <span className="text text--sm text--light new-price text-right">
              {policy.levyTp == 'VND'
                ? numberFormat(policy.fee, 0, '')
                : policy.fee}
              {policy.levyTp == 'VND' ? '' : policy.levyTp}
            </span>
          ) : (
            <div className="d-flex align-items-center gap-12">
              <span className="text text--light text--sm text-right">
                {policy.levyTp == 'VND' ? numberFormat(oldFee, 0, '') : oldFee}
                {policy.levyTp == 'VND' ? '' : policy.levyTp}
              </span>
              <span className="text text--sm text--light new-price text-right">
                {policy.levyTp == 'VND'
                  ? numberFormat(policy.fee, 0, '')
                  : policy.fee}
                {unit}
              </span>
            </div>
          )
        ) : (
          <div className="d-flex align-items-center gap-12">
            <span className="text text--sm text--light old-price">
              {policy.levyTp == 'VND'
                ? numberFormat(policy.fee, 0, '')
                : policy.fee}
              {unit}
            </span>
            <div class="group-control new-price">
              <span
                class="d-flex align-items-center btn-icon btn--transparent2"
                onClick={() => minusHandle()}
              >
                <span class="icon iMinus"></span>
              </span>
              <TextMask
                type="text"
                maxLength={15}
                value={newValue?.value}
                mask={currencyMask}
                onFocus={(e) => e.currentTarget.select()}
                onChange={(e) => {
                  onChangeFeeHandle(e);
                }}
              />

              <span
                class="d-flex align-items-center btn-icon btn--transparent2"
                onClick={() => plusHandle()}
              >
                <span class="icon iPlus"></span>
              </span>
            </div>
          </div>
        )}
      </div>
    );
  }
);

export default ModalChangeFee;
