import React, { useContext, useEffect } from 'react';
import noImage from '../../../../assets/img/noImage.svg';
import PerfectScrollBar from 'react-perfect-scrollbar';
import ProductDetail from './productDetail';
import Contract from '../contract';
import {
  getPFList,
  getPThemes,
  getThemeDetail,
  openAccountPopup,
} from '../../action';
import { ReactReduxContext, useDispatch, useSelector } from 'react-redux';
import OpenPinefolioAccount from '../openPinefolioAccount';
import { v4 as uuidv4 } from 'uuid';
import { getMessageError, numberFormat, _formatDate2 } from '../../../../util';
import { withNamespaces } from 'react-i18next';
import { setLogin, setToast, unsetClient } from '../../../client/actions.js';
import { useState } from 'react';
import { InputGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Mixpanel } from '../../../../lib/mixpanel';
import { handleApiErrors } from '../../../../lib/api-errors';
import {
  Logout,
  logoutRequest,
} from '../../../../components/modal/login/actions';
import { removeCookie } from '../../../../lib/storages';

const appUrl = `${process.env.REACT_APP_API_URL}`;

const Theme = withNamespaces('translations')(
  ({ t, data, themeList, index }) => {
    const dispatch = useDispatch();
    const lang = useSelector((state) => state.client?.setting?.lang || 'vi');
    const { token, auth } = useSelector((state) => state.client);
    const { hadPineAccount } = useSelector((state) => state.pinefolio);

    const getDetail = (data) => {
      if (token) {
        // 1 - if user hadn't pinefolio account already then open account popup
        // 2 - else get theme detail data and open popup theme detail

        const uuid = uuidv4();
        const params = {
          group: 'CORE',
          cmd: 'getPFThemeDtl',
          rqId: uuid,
          channel: 'WTS',
          data: {
            langTp: lang,
            thsCode: data.thsCode,
          },
        };

        Mixpanel.viewTicketInfo({
          Ticker: data.thsCode,
          'Ticker Type': 'PF',
          'Ticker Location': 'Pinefolio',
          'Ticker Location Detail': 'Pinefolio Theme List',
        });

        dispatch(getThemeDetail(params));
      } else {
        dispatch(setLogin());
      }
    };

    const setColor = (a, b, min, max) => {
      if (a >= max) return 'c';
      if (a <= min) return 'f';
      if (a > b) return 'i';
      else if (a < b) return 'd';
      else return 'r';
    };

    return (
      <div className="pinefolio-card">
        <div className="theme">
          <div
            className="pinefolio-card__header theme-header"
            style={{ backgroundImage: `url('${data.thsPic}')` }}
            onClick={() => getDetail(data)}
          >
            <span className="theme-header__name">{data.thsNm}</span>
          </div>
          <div className="theme-body pinefolio-card__body">
            <div className="theme-infors">
              <div className="theme-infor w-25">
                <span className="theme-infor__key text--sm text--gray">
                  {t('pinefolio.portfolio.marketPrice')}
                </span>
                <span
                  className={`theme-infor__value text--md ${setColor(
                    themeList.length > 0
                      ? themeList[index]?.performance
                      : data.performance,
                    0,
                    -7,
                    7
                  )}`}
                >
                  {numberFormat(
                    themeList.length > 0
                      ? themeList[index]?.currAmt
                      : data.currAmt,
                    0,
                    '0'
                  )}
                </span>
              </div>

              <div className="theme-infor w-25">
                <span className="theme-infor__key text--sm text--gray">
                  {t('txt-accumulation')}
                </span>
                <span className="theme-infor__value text--md text--light">
                  {numberFormat(data.totalBuy, 0, '0')}
                </span>
              </div>

              <div className="theme-infor">
                <span className="theme-infor__key text--sm text--gray">
                  <span className="d-flex align-items-center">
                    {t('pinefolio.portfolio.volatility')}
                    <OverlayTrigger
                      trigger={['hover', 'focus']}
                      placement="top"
                      className="portfolio-overlay"
                      overlay={
                        <Tooltip style={{ zIndex: '99999' }}>
                          {t('pinefolio.theme.performanceExplain')}
                        </Tooltip>
                      }
                    >
                      <span className="icon iInfor iTooltip ml-2"></span>
                    </OverlayTrigger>
                  </span>
                </span>
                <span
                  className={`theme-infor__value text--md ${setColor(
                    data.performance,
                    0,
                    -7,
                    7
                  )}`}
                >
                  {numberFormat(data.performance, 2, '0')} %
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

const Index = withNamespaces('translations')(({ t }) => {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.client?.setting?.lang || 'vi');
  const { pineThemes, pfOrder, hadPineAccount } = useSelector(
    (state) => state.pinefolio
  );
  const { defaultAccount } = useSelector((state) => state.socket);
  const { token } = useSelector((state) => state.client);
  const { store } = useContext(ReactReduxContext);
  const [themeList, setThemeList] = useState([]);
  let itv = null;

  useEffect(() => {
    if (lang) {
      getPineThemes();
      getPf();
    }
  }, [lang]);

  useEffect(() => {
    if (defaultAccount) {
    }
  }, [defaultAccount]);

  useEffect(() => {
    itv = setInterval(() => {
      getThemeList();
    }, 5000);

    return () => {
      if (itv) {
        clearInterval(itv);
      }
    };
  }, []);

  const getPineThemes = () => {
    const uuid = uuidv4();

    const params = {
      group: 'CORE',
      cmd: 'getPFTheme',
      rqId: uuid,
      channel: 'WTS',

      data: {
        langTp: lang,
      },
    };

    dispatch(getPThemes(params));
  };

  const getPf = () => {
    const uuid = uuidv4();

    const params = {
      group: 'CORE',
      cmd: 'getPFList',
      rqId: uuid,
      channel: 'WTS',
      data: {
        langTp: lang,
      },
    };

    dispatch(getPFList(params));
  };

  function getThemeList() {
    if (token) {
      const uuid = uuidv4();
      const params = {
        group: 'CORE',
        cmd: 'getPFTheme',
        rqId: uuid,
        channel: 'WTS',
        data: {
          langTp: lang,
        },
      };

      const url = `${appUrl}/PortfolioServlet.pt`;
      try {
        fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          },
          body: JSON.stringify(params),
        })
          .then(handleApiErrors)
          .then((response) => response.json())
          .then((res) => {
            if (res.http !== 200) {
              if (res.http === 401) {
                if (token) dispatch(logoutRequest(token));
                dispatch(unsetClient());
                removeCookie('token');
                removeCookie('authen');
                dispatch(Logout());
                toastHandle(t('txt-valid-loss-session'));
              } else {
                toastHandle(getMessageError(res, store.getState));
              }
            } else {
              // success
              const data = res.data;
              if (data && data.list) {
                setThemeList(data.list);
              }
            }
          })
          .catch((error) => {
            console.error('Error:', error);
          });
      } catch (error) {}
    }
  }

  const toastHandle = (msg) => {
    const toastMsg = {
      id: Math.random(),
      msg: msg,
      title: t('txt-notice'),
    };
    dispatch(setToast(toastMsg));
  };

  return (
    <div className="pinefolio-product">
      <PerfectScrollBar>
        <div className="pinefolio-product-header">
          <div className="pinefolio-product__title text--lg text--light">
            {t('pinefolio.list')}
          </div>
        </div>
        <div className="pinefolio-product-body">
          <div className="pinefolio-cards">
            {pineThemes &&
              !!pineThemes.length > 0 &&
              pineThemes.map((theme, i) => (
                <Theme key={i} data={theme} themeList={themeList} index={i} />
              ))}
          </div>
        </div>
      </PerfectScrollBar>

      <ProductDetail />
    </div>
  );
});

export default Index;
