export const STOCK_DETAIL_REQUESTING = 'STOCK_DETAIL_REQUESTING';
export const STOCK_DETAIL_REQUEST_SUCCESS = 'STOCK_DETAIL_REQUEST_SUCCESS';
export const STOCK_DETAIL_REQUEST_ERROR = 'STOCK_DETAIL_REQUEST_ERROR';
export const STOCK_DETAIL_REQUEST_RESET = 'STOCK_DETAIL_REQUEST_RESET';

export const STOCK_TRADE_REQUESTING = 'STOCK_TRADE_REQUESTING';
export const STOCK_TRADE_REQUEST_SUCCESS = 'STOCK_TRADE_REQUEST_SUCCESS';
export const STOCK_TRADE_REQUEST_ERROR = 'STOCK_TRADE_REQUEST_ERROR';

export const STOCK_HIS_REQUESTING = 'STOCK_HIS_REQUESTING';
export const STOCK_HIS_REQUEST_SUCCESS = 'STOCK_HIS_REQUEST_SUCCESS';
export const STOCK_HIS_REQUEST_ERROR = 'STOCK_HIS_REQUEST_ERROR';

export const STOCK_NEWS_REQUESTING = 'STOCK_NEWS_REQUESTING';
export const STOCK_NEWS_REQUEST_SUCCESS = 'STOCK_NEWS_REQUEST_SUCCESS';
export const STOCK_NEWS_REQUEST_ERROR = 'STOCK_NEWS_REQUEST_ERROR';

export const STOCK_EVENTS_REQUESTING = 'STOCK_EVENTS_REQUESTING';
export const STOCK_EVENTS_REQUEST_SUCCESS = 'STOCK_EVENTS_REQUEST_SUCCESS';
export const STOCK_EVENTS_REQUEST_ERROR = 'STOCK_EVENTS_REQUEST_ERROR';

export const CST_REQUESTING = 'CST_REQUESTING';
export const CST_REQUEST_SUCCESS = 'CST_REQUEST_SUCCESS';
export const CST_REQUEST_ERROR = 'CST_REQUEST_ERROR';

export const KQKD_REQUESTING = 'KQKD_REQUESTING';
export const KQKD_REQUEST_SUCCESS = 'KQKD_REQUEST_SUCCESS';
export const KQKD_REQUEST_ERROR = 'KQKD_REQUEST_ERROR';

export const CDKT_REQUESTING = 'CDKT_REQUESTING';
export const CDKT_REQUEST_SUCCESS = 'CDKT_REQUEST_SUCCESS';
export const CDKT_REQUEST_ERROR = 'CDKT_REQUEST_ERROR';

export const STOCK_MANAGEMENT_REQUESTING = 'STOCK_MANAGEMENT_REQUESTING';
export const STOCK_MANAGEMENT_REQUEST_SUCCESS =
  'STOCK_MANAGEMENT_REQUEST_SUCCESS';
export const STOCK_MANAGEMENT_REQUEST_ERROR = 'STOCK_MANAGEMENT_REQUEST_ERROR';

export const COMPANY_INFO_REQUESTING = 'COMPANY_INFO_REQUESTING';
export const COMPANY_INFO_REQUEST_SUCCESS = 'COMPANY_INFO_REQUEST_SUCCESS';
export const COMPANY_INFO_REQUEST_ERROR = 'COMPANY_INFO_REQUEST_ERROR';

export const GET_CW_DETAIL_REQUEST = 'GET_CW_DETAIL_REQUEST';
export const GET_TRADE_HIS_COMMON_REQUEST = 'GET_TRADE_HIS_COMMON_REQUEST';
