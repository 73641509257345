import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import './stockSuggest.scss';

const StockSuggest = ({ suggestionData, selectStock, inputProps }) => {
  const [inputVal, setInputVal] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const ref = useRef(null);

  const getSuggestions = (inputVal) => {
    const escapedValue = inputVal.trim().replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    if (escapedValue === '') return [];
    const regex = new RegExp('^' + escapedValue, 'i');

    return suggestionData.filter((item) => regex.test(item.stock_code));
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value));
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  /* https://github.com/moroshko/react-autosuggest#onsuggestionselected-optional */
  const onSuggestionSelected = (event, { suggestion }) => {
    selectStock(suggestion);
    _resetInputs();
  };

  /* https://github.com/moroshko/react-autosuggest#inputpropsonchange-required */
  const onInputChange = (event, { newValue }) => {
    setInputVal(newValue);
  };

  const onPressEnter = (event) => {
    /* Auto select when there is only one suggestion on 'Enter' pressed */
    if (event.key === 'Enter' && suggestions.length === 1) {
      selectStock(suggestions[0]);
      _resetInputs();
      // To prevent Autosuggest bug: suggestion tray will not render when input changes,
      // unless we re-focus the input by clicking it
      ref.current.input.blur();
    }
  };

  const _resetInputs = () => {
    setInputVal('');
    setSuggestions([]);
  };

  return (
    <Autosuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      getSuggestionValue={getSuggestionValue}
      onSuggestionSelected={onSuggestionSelected}
      renderSuggestion={renderSuggestion}
      inputProps={{
        ...inputProps,
        value: inputVal,
        onChange: onInputChange,
        onKeyDown: onPressEnter,
      }}
      ref={ref}
    />
  );
};

const getSuggestionValue = (suggestion) => {
  return suggestion.stock_code;
};

const renderSuggestion = (suggestion) => (
  <div className="stockSuggest">
    <span className="stockSuggest__left">
      <span className="stockSuggest__symbol">{suggestion.stock_code}</span>
      <span className="stockSuggest__exchange">
        {getStockIndexName(suggestion.post_to)}
      </span>
    </span>
    <span className="stockSuggest__right">
      {/* TODO show name_en in case of en language */}
      {suggestion.name_vn}
    </span>
  </div>
);

const getStockIndexName = (indexSymbol) => {
  const indexesMap = {
    STO: 'HOSE',
    STX: 'HNX',
    UPX: 'UPCOM',
  };

  return indexesMap[indexSymbol] ? indexesMap[indexSymbol] : indexSymbol;
};

StockSuggest.propsType = {
  suggestionData: PropTypes.array.isRequired,
  inputProps: PropTypes.any,
  selectStock: PropTypes.func,
};

export default StockSuggest;
