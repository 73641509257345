import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Nav, Button } from 'react-bootstrap';
import { Link, Redirect } from 'react-router-dom';

import PerfectScrollBar from 'react-perfect-scrollbar';

import TaiSan from '../../components/asset/TaiSan';
import BaoCaoNo from '../../components/asset/BaoCaoNo';
import BaoCaoTaiSan from '../../components/asset/BaoCaoTaiSan';
import SaoKeTien from '../../components/asset/SaoKeTien';
import SaoKeChungKhoan from '../../components/asset/SaoKeChungKhoan';
import SaoKeLaiLo from '../../components/asset/SaoKeLaiLo';

import { setCashOut, setToast, unsetClient, setAuth } from '../client/actions';
import { WebSocketContext } from '../socket/webSocket';
import {
  makeGetToken,
  makeGetDefaultAccount,
  makeGetCashBalance,
  makeGetStockBalance,
  makeGetListAccount,
  makeGetPositions,
  makeGetCashOut,
  makeGetAuth,
} from '../../lib/selector';

import { find, isEqual } from 'lodash';
import { getMessageError, numberFormat } from '../../util';
import { v4 as uuidv4 } from 'uuid';
import { setDefaultAccount } from '../socket/actions';
import { cashBalanceRequest, positionsRequest } from '../privData/actions';
import { Logout, logoutRequest } from '../../components/modal/login/actions';
import { translate } from 'react-i18next';
import { removeCookie } from '../../lib/storages';

class Asset extends Component {
  constructor(props) {
    super(props);

    this.state = {
      account: {},
    };
  }

  componentDidMount() {
    const { defaultAccount } = this.props;
    if (defaultAccount) {
      this._handleLoadCashBalance();
      this._handleLoadPositions();
      this.setState({ account: defaultAccount });
    }
  }

  componentDidUpdate(preProps) {
    const { defaultAccount, cashOut, token, t } = this.props;
    const { account } = this.state;
    if (defaultAccount && !isEqual(defaultAccount, preProps.defaultAccount)) {
      this._handleLoadCashBalance();
      this._handleLoadPositions();
      this.setState({ account: defaultAccount });
    }

    if (cashOut && !isEqual(cashOut, preProps.cashOut)) {
      if (cashOut.http !== 200) {
        if (cashOut.http === 401) {
          if (token) this.props.dispatch(logoutRequest(token));

          this.props.dispatch(unsetClient());
          removeCookie('token');
          removeCookie('authen');

          this.props.dispatch(Logout());
          this._handleToast(t('txt-valid-loss-session'));
        } else {
          this._handleToast(
            getMessageError(cashOut, this.props.store.getState)
          );
        }
      } else {
        // success
        this._handleToast('Chuyển tiền thành công!');
        this.props.dispatch(setCashOut(false));
        this._handleLoadCashBalance();
      }
    }
  }

  _handleToast = (msg) => {
    const toastMsg = {
      id: Math.random(),
      msg: msg,
      title: 'Thông báo',
    };
    this.props.dispatch(setToast(toastMsg));
  };

  _setKey = (e) => {
    const { listAccount } = this.props;
    const _accSel = find(listAccount, (o) => o.value === e);
    if (!_accSel) return;

    this.setState({ account: _accSel }, () => {
      this.props.dispatch(setDefaultAccount(_accSel));
    });
  };

  _handleLoadCashBalance = () => {
    const { token, defaultAccount } = this.props;

    if (defaultAccount) {
      const uuid = uuidv4();

      const resData = {
        group: 'CORE',
        user: token.user,
        session: token.session,
        cmd: 'getTotalAssetAll',
        rqId: uuid,
        channel: 'WTS',
        data: {
          acntNo: defaultAccount.acntNo,
          subAcntNo: defaultAccount.subAcntNo,
        },
      };
      this.props.dispatch(cashBalanceRequest(resData));
    }
  };

  _handleLoadPositions = () => {
    // let ws = this.context;
    const { token, defaultAccount } = this.props;

    if (defaultAccount) {
      const uuid = uuidv4();

      const params = {
        group: 'CORE',
        user: token.user,
        session: token.session,
        cmd: 'getPositions',
        rqId: uuid,
        channel: 'WTS',
        data: {
          acntNo: defaultAccount.acntNo,
          subAcntNo: defaultAccount.subAcntNo,
        },
      };
      this.props.dispatch(positionsRequest(params));
      // ws.sendMessage({ action: 'getPositions', mode: 'async', data: params });
    }
  };

  _handleReloadTS = () => {
    this._handleLoadCashBalance();
    this._handleLoadPositions();
  };

  _handleAuthen = () => {
    this.props.dispatch(setAuth());
  };

  render() {
    const {
      match: { params },
      listAccount,
      defaultAccount,
      token,
      cashBalance,
      positions,
      t,
      auth,
    } = this.props;

    const { account } = this.state;

    if (!token) return <Redirect to="/" />;
    return (
      <div className="page-asset">
        <Nav as="ul" className="align-items-center asset">
          <Nav.Item as="li" className="ml-4 mr-3">
            <Link
              to="tai-san"
              className={params.typeId === 'tai-san' ? 'active' : ''}
            >
              {t('txt-asset')}
            </Link>
          </Nav.Item>
          <Nav.Item as="li" className="mr-3">
            <Link
              to="bao-cao-tai-san"
              className={params.typeId === 'bao-cao-tai-san' ? 'active' : ''}
            >
              {t('txt-title-asset-manage')}
            </Link>
          </Nav.Item>
          <Nav.Item as="li" className="mr-3">
            <Link
              to="bao-cao-no"
              className={params.typeId === 'bao-cao-no' ? 'active' : ''}
            >
              {t('txt-margin-debt')}
            </Link>
          </Nav.Item>
          <Nav.Item as="li" className="mr-3">
            <Link
              to="sao-ke-tien"
              className={params.typeId === 'sao-ke-tien' ? 'active' : ''}
            >
              {t('txt-label-cash-statement')}
            </Link>
          </Nav.Item>
          <Nav.Item as="li" className="mr-3">
            <Link
              to="sao-ke-chung-khoan"
              className={params.typeId === 'sao-ke-chung-khoan' ? 'active' : ''}
            >
              {t('txt-label-securities-statement')}
            </Link>
          </Nav.Item>
          <Nav.Item as="li" className="mr-3">
            <Link
              to="sao-ke-lai-lo"
              className={params.typeId === 'sao-ke-lai-lo' ? 'active' : ''}
            >
              {t('txt-label-gain-loss')}
            </Link>
          </Nav.Item>
        </Nav>
        <div className="asset-header d-flex align-items-center">
          <div className="d-flex mr-auto">
            <div
              className="header-title d-flex align-items-center br-1"
              style={{ width: '320px' }}
            >
              <span className="ml-3 fz_14 text-uppercase">
                {t('txt-account')}
              </span>
            </div>
            <div
              className="header-title d-flex flex-column br-1"
              style={{ width: '150px' }}
            >
              <span className="ml-3">{t('txt-total-asset')}</span>
              <span className="i fz_14 ml-3">
                {cashBalance && numberFormat(cashBalance.totAsst, 0, '0')}
              </span>
            </div>
            <div
              className="header-title d-flex flex-column br-1"
              style={{ width: '150px' }}
            >
              <span className="ml-3">{t('txt-nav')}</span>
              <span className="i fz_14 ml-3">
                {cashBalance && numberFormat(cashBalance.realAsst, 0, '0')}
              </span>
            </div>
            <div
              className="header-title d-flex flex-column"
              style={{ width: '150px' }}
            >
              <span className="ml-3">{t('txt-wdrawAvail')}</span>
              <span className="i fz_14 ml-3">
                {cashBalance && numberFormat(cashBalance.wdrawAvail, 0, '0')}
              </span>
            </div>
          </div>

          {auth && auth.isVerified ? (
            <a
              className="btn btn--primary fw-500"
              onClick={() => this.props.dispatch(setCashOut(true))}
            >
              {t('txt-label-withdrawal')}
            </a>
          ) : (
            <a
              className="btn btn--authen fw-500"
              onClick={() => this._handleAuthen()}
            >
              {t('txt-label-withdrawal')}
            </a>
          )}
        </div>
        <div className="page-content d-flex flex-row">
          <PerfectScrollBar>
            <Nav
              as="ul"
              className="d-flex flex-column"
              onSelect={(e) => this._setKey(e)}
            >
              {defaultAccount &&
                listAccount &&
                !!listAccount.length &&
                listAccount.map((item, index) => {
                  if (
                    params.typeId === 'sao-ke-tien' ||
                    params.typeId === 'sao-ke-chung-khoan'
                  ) {
                    return (
                      <Nav.Link
                        key={index}
                        eventKey={item.value}
                        active={isEqual(account.value, item.value)}
                        className={
                          !isEqual(account.value, item.value) ? '' : 'active'
                        }
                      >
                        <div className="d-flex justify-content-between">
                          <span>{item.label}</span>
                          {item.subAcntStat == 9 && (
                            <span
                              className={
                                account.value === item.value
                                  ? 'text-warning border border-warning rounded px-2'
                                  : 'text-secondary border border-secondary rounded px-2'
                              }
                            >
                              {' '}
                              {t('txt-account-closed')}{' '}
                            </span>
                          )}
                        </div>
                      </Nav.Link>
                    );
                  } else {
                    if (item.subAcntStat == 9) return null;
                    return (
                      <Nav.Link
                        key={index}
                        eventKey={item.value}
                        className={
                          defaultAccount.value === item.value ? 'active' : ''
                        }
                      >
                        {item.label}
                      </Nav.Link>
                    );
                  }
                })}
            </Nav>
          </PerfectScrollBar>
          <div
            className="d-flex flex-column"
            style={{ width: 'calc( 100% - 320px )', height: '100%' }}
          >
            {params.typeId === 'bao-cao-no' ? (
              <BaoCaoNo account={account} store={this.props.store} />
            ) : params.typeId === 'bao-cao-tai-san' ? (
              <BaoCaoTaiSan
                cashBalance={cashBalance}
                account={account}
                reload={this._handleReloadTS}
              />
            ) : params.typeId === 'sao-ke-tien' ? (
              <SaoKeTien account={account} />
            ) : params.typeId === 'sao-ke-chung-khoan' ? (
              <SaoKeChungKhoan account={account} />
            ) : params.typeId === 'sao-ke-lai-lo' ? (
              <SaoKeLaiLo account={account} />
            ) : (
              <TaiSan
                cashBalance={cashBalance}
                positions={positions}
                account={account}
                reload={this._handleReloadTS}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

Asset.contextType = WebSocketContext;

const makeMapStateToProps = () => {
  const getToken = makeGetToken();
  const getListAccount = makeGetListAccount();
  const getDefaultAccount = makeGetDefaultAccount();
  const getCashBalance = makeGetCashBalance();
  const getPositions = makeGetPositions();
  const getCashOut = makeGetCashOut();
  const getAuth = makeGetAuth();

  const mapStateToProps = (state, props) => {
    return {
      token: getToken(state),
      defaultAccount: getDefaultAccount(state),
      listAccount: getListAccount(state),
      cashBalance: getCashBalance(state),
      positions: getPositions(state),
      cashOut: getCashOut(state),
      auth: getAuth(state),
    };
  };
  return mapStateToProps;
};

export default connect(makeMapStateToProps)(translate('translations')(Asset));
