import React, { useContext, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import { ReactReduxContext, useDispatch, useSelector } from 'react-redux';
import CheckboxButton from '../../../../components/checkbox/checkBoxButton';
import { v4 as uuidv4 } from 'uuid';
import { handleApiErrors } from '../../../../lib/api-errors';
import {
  Logout,
  logoutRequest,
} from '../../../../components/modal/login/actions';
import { setToast, unsetClient } from '../../../client/actions';
import { removeCookie } from '../../../../lib/storages';
import { getMessageError, _formatDate2 } from '../../../../util';
import {
  getPinefolioInvestmentPlanRequest,
  handleDepositPinefolio,
  handleEditPlanMonitor,
} from '../../action';

const appUrl = `${process.env.REACT_APP_API_URL}`;
const ConfirmDepositToPortfolio = withNamespaces('translations')(
  ({
    t,
    showModal,
    setShowModal,
    pfPlan,
    packDeposit,
    handleCloseDepositModal,
  }) => {
    const dispatch = useDispatch();
    const { store } = useContext(ReactReduxContext);
    const [replanYN, setReplanYN] = useState(false);
    const theme = useSelector((state) => state.client?.currentTheme);
    const { token, auth } = useSelector((state) => state.client);
    const listAccounts = token?.custInfo.normal;
    const _account = listAccounts?.find(
      (item) => item.subAcntNo.startsWith('P') && item.subAcntStat == 1
    );

    const handleCloseModal = () => {
      setShowModal(false);
    };

    const handleBuySuccess = () => {
      dispatch(handleDepositPinefolio(false));
      const params = {
        showEditPlanModal: false,
        planData: {},
      };
      dispatch(handleEditPlanMonitor(params));
      getPinefolioInvestmentPlan();
    };

    const handleBuyNow = () => {
      callBuyNow();
    };

    const handleBuyFollowPlan = () => {
      handleCloseDepositModal();
      handleCloseModal();
    };

    const getPinefolioInvestmentPlan = () => {
      if (!token || (!auth && !auth.isVerified)) return;
      const uuid = uuidv4();
      const params = {
        group: 'CORE',
        user: token.user,
        session: token.session,
        cmd: 'getPFIPList',
        rqId: uuid,
        channel: 'WTS',
        data: {
          cif: token.cif,
          acntNo: _account?.acntNo,
          subAcntNo: _account?.subAcntNo,
          packNoYN: 'Y',
          planStat: '90',
        },
      };
      dispatch(getPinefolioInvestmentPlanRequest(params));
    };
    const callBuyNow = () => {
      if (!token) return;

      const uuid = uuidv4();
      const params = {
        group: 'CORE',
        user: token.user,
        session: token.session,
        type: auth.type,
        token: auth.token,
        cmd: 'placePFOrderByIP',
        rqId: uuid,
        channel: 'WTS',
        data: {
          cif: token.cif,
          acntNo: _account.acntNo,
          subAcntNo: _account.subAcntNo,
          planId: pfPlan.planId,
          packNo: packDeposit.packNo,
          replanYN: replanYN ? 'Y' : 'N',
        },
      };

      const url = `${appUrl}/PortfolioServlet.pt`;
      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
        body: JSON.stringify(params),
      })
        .then(handleApiErrors)
        .then((response) => response.json())
        .then((res) => {
          if (res.http == 200) {
            handleBuySuccess();
            const toastMsg = {
              id: Math.random(),
              msg: t('bond.order.success'),
              title: t('txt-notice'),
            };
            dispatch(setToast(toastMsg));
            handleCloseModal();
          } else {
            if (res.http === 401) {
              if (token) dispatch(logoutRequest(token));
              dispatch(unsetClient());
              removeCookie('token');
              removeCookie('authen');
              dispatch(Logout());
              const toastMsg = {
                id: Math.random(),
                msg: t('txt-valid-loss-session'),
                title: t('txt-notice'),
              };
              dispatch(setToast(toastMsg));
            } else {
              const msgErr = getMessageError(res, store.getState);
              const toastMsg = {
                id: Math.random(),
                msg: msgErr,
                title: t('txt-notice'),
              };
              dispatch(setToast(toastMsg));
            }
          }
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    };

    return (
      <div>
        <Modal
          show={showModal}
          onHide={handleBuyFollowPlan}
          onEscapeKeyDown={handleBuyFollowPlan}
          dialogClassName="wts-modal"
          backdropClassName="wts-modal-backdrop pinefolio-order-confirm-backdrop"
          contentClassName={`${
            theme.name || 'dark'
          } wts-modal-content ${'wts-modal-content--sm'}`}
          centered
          style={{ zIndex: 11120 }}
        >
          <div className="form-withdraw modal-form depositToPortfolio-modal">
            <div className="text-center modal-header">
              <div className="d-flex"></div>
              <div className="">
                <span className="">{`${t('pinefolio.plan.depositTo')} ${
                  pfPlan?.pfName
                } - ${
                  packDeposit?.packNo == 0
                    ? t('pinefolio.plan.fullSet')
                    : t('pinefolio.plan.pack') + ' ' + packDeposit?.packNo
                }`}</span>
              </div>
              <div></div>
            </div>
            <div className="modal-body d-flex">
              <div className="modal-body__icon icon--success">
                <span className={`icon iSuccess`}></span>
              </div>
              <div className="modal-body__content">
                <span className="modal-body__title text--light fw-500">
                  {t('pinefolio.plan.messageComplete')}
                </span>
                <span className="modal-body__description text--light3 d-flex justify-content-center align-items-center">
                  <div className="checkbox-group ml-3 d-flex">
                    <CheckboxButton onClick={(e) => setReplanYN(e)} />
                  </div>
                  {t('pinefolio.plan.messageCompleteDesc')}
                </span>
              </div>
            </div>
            <div className="modal--footer">
              <div className="btn btn--buy w-50" onClick={() => handleBuyNow()}>
                {t('pinefolio.plan.orderBuyNow')}
              </div>
              <div
                className="btn btn--cancel w-50"
                onClick={() => handleBuyFollowPlan()}
              >
                {`${t('pinefolio.plan.buyFollowPlanAt')} ${_formatDate2(
                  packDeposit.enDt
                )}`}
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
);

export default ConfirmDepositToPortfolio;
