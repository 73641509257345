import React, { Fragment, useContext, useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import { Col, ListGroup } from 'react-bootstrap';
import PerfectScrollBar from 'react-perfect-scrollbar';

import {
  cashInBankRequest,
  holdListRequest,
} from '../../../containers/cash/actions';

import { setToast, unsetClient } from '../../../containers/client/actions';
import {
  numberFormat,
  formatDate,
  _formatDate2,
  getMessageError,
} from '../../../util';

import { v4 as uuidv4 } from 'uuid';
import FormUnHoldBank from '../../../components/cash/form/formUnHoldBank';
import PaginationTable from '../../../components/PaginationTable';
import { withNamespaces } from 'react-i18next';
import { Logout, logoutRequest } from '../../../components/modal/login/actions';
import { removeCookie } from '../../../lib/storages';
import { filter } from 'lodash';
import ReactSelect from 'react-select';
import { ReactReduxContext } from 'react-redux';

const TRANSACTION_TYPE = [
  { type: 'Hold', id: '1' },
  { type: 'UnHold', id: '2' },
];
const BankHold = withNamespaces('translations')(({ t }) => {
  const dispatch = useDispatch();
  const { token, auth } = useSelector((state) => state.client);
  const { listAccount, defaultAccount } = useSelector((state) => state.socket);
  const { cashInBank, holdBank, holdList } = useSelector((state) => state.cash);
  const { store } = useContext(ReactReduxContext);

  const handleToast = (msg) => {
    const toastMsg = {
      id: Math.random(),
      msg: msg,
      title: t('txt-notice'),
    };
    dispatch(setToast(toastMsg));
  };

  const handleLoadCashInBank = () => {
    if (!defaultAccount) return;

    const uuid = uuidv4();
    const params = {
      group: 'CORE',
      user: token.user,
      session: token.session,
      cmd: 'InquiryCashInCoreBank',
      rqId: uuid,
      channel: 'WTS',
      data: {
        acntNo: defaultAccount.acntNo,
      },
    };

    dispatch(cashInBankRequest(params));
  };

  const handleLoadHoldList = () => {
    if (!defaultAccount) return;

    const uuid = uuidv4();

    const d = new Date();
    d.setDate(d.getDate() - 30);

    const params = {
      group: 'CORE',
      user: token.user,
      session: token.session,
      cmd: 'GetHoldList',
      rqId: uuid,
      channel: 'WTS',
      data: {
        acntNo: defaultAccount.acntNo,
        subAcntNo: defaultAccount.subAcntNo,
        fromDate: formatDate(d, ''),
        toDate: formatDate(new Date(), ''),
      },
    };
    dispatch(holdListRequest(params));
  };

  useEffect(() => {
    if (defaultAccount) {
      handleLoadCashInBank();
      handleLoadHoldList();
    }
  }, [defaultAccount]);

  useEffect(() => {
    if (holdBank) {
      if (holdBank.http !== 200) {
        if (holdBank.http === 401) {
          if (token) dispatch(logoutRequest(token));

          dispatch(unsetClient());
          removeCookie('token');
          removeCookie('authen');

          dispatch(Logout());
          handleToast(t('txt-valid-loss-session'));
        } else {
          handleToast(getMessageError(holdBank, store.getState));
        }
      } else {
        handleToast(t('txt-title-hold-success'));
        handleLoadCashInBank();
        handleLoadHoldList();
      }
    }
  }, [holdBank]);

  const columns = [
    {
      key: 'subAcntNo',
      text: t('txt-account'),
      className: 'text-center',
      style: { width: '15%' },
      align: 'center',
    },
    {
      key: 'tranDate',
      text: t('txt-label-tran-date'),
      className: 'text-left',
      style: { width: '15%' },
      align: 'center',
      cell: (record) => {
        return _formatDate2(record.tranDate);
      },
    },
    {
      key: 'tranAmt',
      text: t('txt-label-tran-amount'),
      className: 'text-center',
      style: { width: '15%' },
      align: 'right',
      cell: (record) => {
        return numberFormat(record.tranAmt, 0, '-');
      },
    },
    {
      key: 'trdType',
      text: t('txt-label-tran-type'),
      className: 'text-right',
      style: { width: '10%' },
      align: 'center',
      cell: (record) => {
        return record.trdType === '1'
          ? t('txt-label-deposit')
          : t('txt-label-withdrawal');
      },
    },
    {
      text: t('txt-status'),
      className: 'text-right',
      align: 'right',
      style: { width: '10%' },
      cell: (record) => {
        return t('txt-success');
      },
    },
    {
      key: 'desc',
      text: t('txt-note'),
      className: 'text-left',
      align: 'left',
    },
  ];
  return (
    <div className="personal-assets card-panel-2 ">
      <div className="bank-hold-container">
        <PerfectScrollBar>
          <div className="bank-hold">
            <Fragment>
              <div className="d-flex flex-row bank-hold-content">
                <Col className="d-flex flex-column">
                  <Fragment>
                    <ListGroup>
                      <ListGroup.Item className="d-flex justify-content-between">
                        <span className="fz_13 text-content text--light fw-500">
                          {t('txt-label-hold-bank')}
                        </span>
                        <span className="fz_13 text-content fw-500">
                          <span
                            className="d-flex btn-icon btn--refresh"
                            onClick={() => handleLoadCashInBank()}
                          >
                            <span className="icon iRefresh"></span>
                          </span>
                        </span>
                      </ListGroup.Item>
                      {!cashInBank ? (
                        <p className="text-center text-white fz_14 mt-3">
                          {t('txt-no-data-found')}
                        </p>
                      ) : (
                        <>
                          <ListGroup.Item className="d-flex justify-content-between">
                            <span className="fz_13 text-content font-weight-normal text--light3">
                              {t('txt-label-bank-link')}
                            </span>
                            <span className="fz_13 text-content fw-500 text--light">
                              {cashInBank.bankCode}
                            </span>
                          </ListGroup.Item>
                          <ListGroup.Item className="d-flex justify-content-between">
                            <span className="fz_13 text-content font-weight-normal text--light3">
                              {t('txt-label-account-num')}
                            </span>
                            <span className="fz_13 text-content fw-500 text--light">
                              {cashInBank.bankAcntNo}
                            </span>
                          </ListGroup.Item>
                          <ListGroup.Item className="d-flex justify-content-between">
                            <span className="fz_13 text-content font-weight-normal text--light3">
                              {t('transaction.holdUnholdBank.currentBalance')}
                            </span>
                            <span className="fz_13 text-content fw-500 text--light">
                              {numberFormat(cashInBank.totBal, 0, '0')}
                            </span>
                          </ListGroup.Item>
                          <ListGroup.Item className="d-flex justify-content-between">
                            <span className="fz_13 text-content font-weight-normal text--light3">
                              {t('transaction.holdUnholdBank.amountOnHold')}
                            </span>
                            <span className="fz_13 text-content fw-500 text--light">
                              {numberFormat(cashInBank.holdAmt, 0, '0')}
                            </span>
                          </ListGroup.Item>
                          <ListGroup.Item className="d-flex justify-content-between">
                            <span className="fz_13 text-content w-100">
                              <FormUnHoldBank
                                holdType="1"
                                handleToast={handleToast}
                              />
                            </span>
                          </ListGroup.Item>
                        </>
                      )}
                    </ListGroup>
                  </Fragment>
                </Col>
                <Col
                  className="d-flex flex-column"
                  style={{ paddingLeft: '48px' }}
                >
                  <Fragment>
                    <ListGroup.Item className="d-flex justify-content-between">
                      <span className="fz_13 text-content text--light fw-500">
                        {t('txt-label-withdrawal-bank')}
                      </span>
                      <span className="fz_13 text-content fw-500">
                        <span
                          className="d-flex btn-icon btn--refresh"
                          onClick={() => handleLoadCashInBank()}
                        >
                          <span className="icon iRefresh"></span>
                        </span>
                      </span>
                    </ListGroup.Item>
                    {!cashInBank ? (
                      <p className="text-center text-white fz_14 mt-3">
                        {t('txt-no-data-found')}
                      </p>
                    ) : (
                      <ListGroup>
                        <ListGroup.Item className="d-flex justify-content-between">
                          <span className="fz_13 text-content font-weight-normal text--light3">
                            {t('txt-label-bank-link')}
                          </span>
                          <span className="fz_13 text-content fw-500 text--light">
                            {cashInBank.bankCode}
                          </span>
                        </ListGroup.Item>
                        <ListGroup.Item className="d-flex justify-content-between">
                          <span className="fz_13 text-content font-weight-normal text--light3">
                            {t('txt-label-account-num')}
                          </span>
                          <span className="fz_13 text-content fw-500 text--light">
                            {cashInBank.bankAcntNo}
                          </span>
                        </ListGroup.Item>
                        <ListGroup.Item className="d-flex justify-content-between">
                          <span className="fz_13 text-content font-weight-normal text--light3">
                            {t('transaction.holdUnholdBank.currentBalance')}
                          </span>
                          <span className="fz_13 text-content fw-500 text--light">
                            {numberFormat(cashInBank.totBal, 0, '0')}
                          </span>
                        </ListGroup.Item>
                        <ListGroup.Item className="d-flex justify-content-between">
                          <span className="fz_13 text-content font-weight-normal text--light3">
                            {t('transaction.holdUnholdBank.amountOnHold')}
                          </span>
                          <span className="fz_13 text-content fw-500 text--light">
                            {numberFormat(cashInBank.holdAmt, 0, '0')}
                          </span>
                        </ListGroup.Item>
                        <ListGroup.Item className="d-flex justify-content-between">
                          <span className="fz_13 text-content justify-content-between w-100 ">
                            <FormUnHoldBank
                              holdType="2"
                              handleToast={handleToast}
                            />
                          </span>
                        </ListGroup.Item>
                      </ListGroup>
                    )}
                  </Fragment>
                </Col>
              </div>
            </Fragment>
          </div>
          <div className="d-flex justify-content-between align-items-center mt-4">
            <label className="text-white ml-2 text-title">
              {t('txt-label-history')}
            </label>
            <div className="d-flex">
              <div
                className="d-flex btn-icon btn--refresh"
                onClick={() => handleLoadHoldList()}
              >
                <span className="icon iRefresh "></span>
              </div>
            </div>
          </div>
          <PaginationTable
            defaultAccount={defaultAccount}
            pageSize={15}
            columns={columns}
            source={holdList && holdList.list ? holdList.list : []}
          />
        </PerfectScrollBar>
      </div>
    </div>
  );
});

export default BankHold;
