import React, { useEffect, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import Product from './components/product';
import OrderList from './components/orderList';
import Portfolio from './components/portfolio';
import PlanManagement from './components/planManagement';
import AnalysisReport from './components/analysis';
import { constants } from '../../util/constant';
import { useDispatch, useSelector } from 'react-redux';
import { setDefaultAccount } from '../socket/actions';
import { useLocation } from 'react-router';
import OpenPinefolioAccount from './components/openPinefolioAccount';
import { openAccountPopup, setHadPineAccount } from './action';
import { setLogin } from '../client/actions';
import { v4 as uuidv4 } from 'uuid';
import { cashBalanceRequest } from '../privData/actions';
import { numberFormat } from '../../util';

const Index = withNamespaces('translations')(({ t }) => {
  const [tabActive, setTabActive] = useState(constants.pinefolio.PRODUCT_LIST);
  const { listAccount, defaultAccount } = useSelector((state) => state.socket);
  const { hadPineAccount } = useSelector((state) => state.pinefolio);
  const { token, auth } = useSelector((state) => state.client);
  const cashBalance = useSelector((state) => state.privData.cashBalance?.data);

  const dispatch = useDispatch();
  const location = useLocation();
  const lState = location.state;

  useEffect(() => {
    if (listAccount && !!listAccount.length > 0) {
      const pfAccount = listAccount.find(
        (x) => x.subAcntNo.startsWith('P') && x.subAcntStat == 1
      );
      if (pfAccount) {
        // default account should be switch to pinefolio subaccount (if existed) while open pinefolio screen
        // setTimeout to auto switch to pinefolio user after 500ms to avoid conflict to existed set default account follow
        dispatch(setHadPineAccount(true));
        setTimeout(() => {
          dispatch(setDefaultAccount(pfAccount));
        }, 500);
      }
    }
  }, [listAccount]);

  useEffect(() => {
    if (defaultAccount) {
      loadCashBalanceHandle();
    }
  }, [defaultAccount]);

  useEffect(() => {
    if (lState) {
      setTabActive(lState.tabActive);
    }
  }, [lState]);

  const goHandle = (tab) => {
    if (token) {
      setTabActive(tab);
    } else {
      dispatch(setLogin());
    }
  };

  const loadCashBalanceHandle = () => {
    if (token && defaultAccount) {
      const uuid = uuidv4();

      const resData = {
        group: 'CORE',
        user: token.user,
        session: token.session,
        cmd: 'getTotalAssetAll',
        rqId: uuid,
        channel: 'WTS',
        data: {
          acntNo: defaultAccount.acntNo,
          subAcntNo: defaultAccount.subAcntNo,
        },
      };
      dispatch(cashBalanceRequest(resData));
    }
  };

  return (
    <div className={`pinefolio-page layout-page`}>
      <div className="pinefolio-content">
        <div className="pinefolio card-panel-2">
          <div className="card-panel-2-header pinefolio-header">
            <div className="pinefolio-header__left">
              <span
                className={`card-panel-2__tab ${
                  tabActive == constants.pinefolio.PRODUCT_LIST ? 'active' : ''
                }`}
                onClick={() => setTabActive(constants.pinefolio.PRODUCT_LIST)}
              >
                {t('pinefolio.nav.productList')}
              </span>
              <span
                className={`card-panel-2__tab ${
                  tabActive == constants.pinefolio.ORDER_LIST ? 'active' : ''
                }`}
                onClick={() => goHandle(constants.pinefolio.ORDER_LIST)}
              >
                {t('pinefolio.nav.orderList')}
              </span>
              <span
                className={`card-panel-2__tab ${
                  tabActive == constants.pinefolio.PORTFOLIO ? 'active' : ''
                }`}
                onClick={() => goHandle(constants.pinefolio.PORTFOLIO)}
              >
                {t('pinefolio.nav.portfolio')}
              </span>
              <span
                className={`card-panel-2__tab ${
                  tabActive == constants.pinefolio.PLAN ? 'active' : ''
                }`}
                onClick={() => goHandle(constants.pinefolio.PLAN)}
              >
                {t('pinefolio.nav.planManagement')}
              </span>
              <span
                className={`card-panel-2__tab ${
                  tabActive == constants.pinefolio.ANALYSIS ? 'active' : ''
                }`}
                onClick={() => goHandle(constants.pinefolio.ANALYSIS)}
              >
                {t('pinefolio.nav.analysisReport')}
              </span>
            </div>
            <div className="pinefolio-header__right">
              <span
                className="btn--transparent btn-icon "
                onClick={() => loadCashBalanceHandle()}
              >
                <span className="icon iRefresh cursor-pointer"></span>
              </span>
              <span className="mr-2 text--light3 text--sm">
                {t('personal.asset.totalOverview.withdrawable')}
              </span>
              <span className="text--light text--sm fw-500">
                {numberFormat(cashBalance?.wdrawAvail, 0, '0')}
              </span>
            </div>
          </div>
          <div
            className="card-panel-2-body p-0"
            style={{ height: 'calc(100vh - 160px)' }}
          >
            {tabActive == constants.pinefolio.PRODUCT_LIST && <Product />}
            {tabActive == constants.pinefolio.ORDER_LIST && <OrderList />}
            {tabActive == constants.pinefolio.PORTFOLIO && <Portfolio />}
            {tabActive == constants.pinefolio.PLAN && <PlanManagement />}
            {tabActive == constants.pinefolio.ANALYSIS && (
              <AnalysisReport hasFilter={true} />
            )}
          </div>
        </div>
      </div>
      {!hadPineAccount && <OpenPinefolioAccount />}
    </div>
  );
});

export default Index;
