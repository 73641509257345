import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import PerfectScrollBar from 'react-perfect-scrollbar';
import { Card, Col, Button } from 'react-bootstrap';

import {
  makeGetToken,
  makeGetListAccount,
  makeGetDefaultAccount,
  makeGetAuth,
} from '../../lib/selector';

import {
  numberFormat,
  formatDate,
  StringToInt,
  _formatDate2,
  _formatDate,
  getMessageError,
} from '../../util';
import SelectUser from '../select/selectUserCash';
import {
  advanceListRequest,
  advanceMatchRequest,
} from '../../containers/cash/actions';
import CashAdvance from '../modal/cashAdvance';
import { sumBy, some, isArray, isEqual } from 'lodash';
import InputMask from '../input';
import { v4 as uuidv4 } from 'uuid';
import { handleApiErrors } from '../../lib/api-errors';
import {
  setAuth,
  setToast,
  unsetClient,
} from '../../containers/client/actions';

import PaginationTable from '../PaginationTable';
import { ReloadButton } from '../btnReload';
import { Logout, logoutRequest } from '../modal/login/actions';
import { removeCookie } from '../../lib/storages';
import { translate } from 'react-i18next';

const appUrl = `${process.env.REACT_APP_API_URL}`;

const waitFor = (ms) => new Promise((r) => setTimeout(r, ms));
const asyncForEach = async (array, callback) => {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
};

class UngTruocTienBan extends React.Component {
  state = {
    showModal: false,
    advMatch: [],
    account: null,
    minAdv: 10000,
  };

  _handleReloadCashAdvance = () => {
    const { token } = this.props;
    const { account } = this.state;

    const d = new Date();
    d.setDate(d.getDate() - 30);

    const uuid = uuidv4();
    const resData = {
      group: 'CORE',
      user: token.user,
      session: token.session,
      cmd: 'getAdvanceLoanList',
      rqId: uuid,
      channel: 'WTS',
      data: {
        acntNo: account.acntNo,
        subAcntNo: account.subAcntNo,
        fromDate: formatDate(d, '', 'ymd'),
        toDate: formatDate(new Date(), '', 'ymd'),
      },
    };

    this.props.dispatch(advanceListRequest(resData));
  };

  _handleLoadAdvanceMatch = () => {
    const { token } = this.props;
    const { account } = this.state;

    const uuid = uuidv4();
    const resData = {
      group: 'CORE',
      user: token.user,
      session: token.session,
      cmd: 'getAdvanceFromMatch',
      rqId: uuid,
      channel: 'WTS',
      data: {
        acntNo: account.acntNo,
        subAcntNo: account.subAcntNo,
      },
    };
    // console.log('advanceMatchRequest');
    this.props.dispatch(advanceMatchRequest(resData));
  };

  _handleNewAdvLoan = (params) => {
    const url = `${appUrl}/CoreServlet.pt`;
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
      body: JSON.stringify(params),
    })
      .then(handleApiErrors)
      .then((response) => response.json())
      .then((res) => {
        console.log(res, params);
        if (res.http !== 200) {
          if (res.http === 401) {
            const { token } = this.props;
            if (token) this.props.dispatch(logoutRequest(token));

            this.props.dispatch(unsetClient());
            removeCookie('token');
            removeCookie('authen');

            this.props.dispatch(Logout());
            this._handleToast(this.props.t('txt-valid-loss-session'));
          } else {
            this._handleToast(getMessageError(res, this.props.store.getState));
          }
        } else {
          this._handleToast('Ứng tiền thành công!');
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  _handleProcFee = () => {
    this.setState({ showModal: true });
  };

  componentDidMount() {
    const { token, defaultAccount } = this.props;
    if (!token) return;

    const minAdv =
      token && token['config']
        ? token['config']['minAdv']
          ? StringToInt(token['config']['minAdv'])
          : 10000
        : 10000;
    this.setState({ minAdv });

    if (defaultAccount) {
      this.setState({ account: defaultAccount }, () => {
        this._handleReloadCashAdvance();
        this._handleLoadAdvanceMatch();
      });
    }
  }

  componentDidUpdate(preProps) {
    const { defaultAccount, advanceMatch } = this.props;

    if (!isEqual(defaultAccount, preProps.defaultAccount)) {
      this.setState({ account: defaultAccount }, () => {
        this._handleReloadCashAdvance();
        this._handleLoadAdvanceMatch();
      });
    }

    if (
      advanceMatch &&
      isArray(advanceMatch) &&
      advanceMatch !== preProps.advanceMatch
    ) {
      // let advMatch = []
      // console.log(advanceMatch);
      const _advMatch = [...advanceMatch];
      if (!!_advMatch.length) {
        _advMatch.map((item) => {
          const uuid = uuidv4();
          item.amt = '';
          item._calcInt = 0;
          item.key = uuid;
        });
      }
      this.setState({ advMatch: _advMatch });
    }
  }

  _handleChangeUser = (user) => {
    // console.log(user);
    this.setState({ account: user }, () => {
      this._handleReloadCashAdvance();
      this._handleLoadAdvanceMatch();
    });
  };

  _handleCloseModal = () => {
    this.setState({ showModal: false });
  };

  _handleAcceptLoan = async () => {
    const { advMatch, account } = this.state;
    const { token, auth } = this.props;

    await asyncForEach(advMatch, async (element) => {
      if (StringToInt(element.amt) > 0) {
        await waitFor(200);

        const uuid = uuidv4();
        const params = {
          group: 'CORE',
          user: token.user,
          session: token.session,
          cmd: 'newAdvanceLoan',
          rqId: uuid,
          channel: 'WTS',
          type: auth.type,
          token: auth.token,
          data: {
            acntNo: account.acntNo,
            subAcntNo: account.subAcntNo,
            ordrDt: _formatDate(element.ordrDt),
            amt: StringToInt(element.amt) + '',
            type: '2', // 1.include 2.exclude
          },
        };

        this._handleNewAdvLoan(params);
      }
    });

    await waitFor(100);
    this._handleCloseModal();

    this._handleReloadCashAdvance();
    this._handleLoadAdvanceMatch();
  };

  _handleChange = (e) => {
    const { value, name } = e.target;
    // console.log(value, name);
    let { advMatch } = this.state;
    if (!advMatch) return;

    advMatch.map((item) => {
      if (item.key !== name) return item;

      item.amt = value;
      item.err = '';
      item._calcInt = 0;
      return item;
    });
    // console.log(advMatch);
    this.setState({ advMatch });
  };

  _handleBlur = (name) => {
    console.log(name);
    let { advMatch, minAdv } = this.state;
    if (!advMatch) return;

    advMatch.map((item) => {
      if (item.key !== name) return item;
      const _max = item.advanceAvail - item.exptInt;
      item.err =
        StringToInt(item.amt) > _max
          ? this.props.t('txt-valid-max-amount') +
            ': ' +
            numberFormat(_max, 0, '0')
          : StringToInt(item.amt) < minAdv
          ? this.props.t('txt-valid-min-amount') +
            ': ' +
            numberFormat(minAdv, 0, '0')
          : '';
      return item;
    });
    this.setState({ advMatch });
  };

  _handleClear = () => {
    let { advMatch } = this.state;
    advMatch.map((item) => {
      item.amt = '';
      item.err = '';
      item._calcInt = 0;
      return item;
    });
    this.setState({ advMatch });
  };

  _handleToast = (msg) => {
    const toastMsg = {
      id: Math.random(),
      msg: msg,
      title: this.props.t('txt-notice'),
    };
    this.props.dispatch(setToast(toastMsg));
  };

  _handleAuthen = () => {
    this.props.dispatch(setAuth());
  };

  render() {
    const { listAccount, defaultAccount, advanceList, auth, t } = this.props;
    const { showModal, advMatch, account } = this.state;

    const _totalAdv = sumBy(advMatch, (o) => StringToInt(o.amt));
    const _chkErr = some(advMatch, (o) => o.err && o.err.length > 0);
    // console.log('advMatch', advMatch);

    const columns = [
      {
        key: 'defaultAccount',
        text: t('txt-sub-account'),
        className: 'text-center',
        align: 'center',
        cell: (record) => {
          return <span>{record && record.subAcntNo}</span>;
        },
      },
      {
        key: 'loanAmt',
        text: t('txt-label-amount-advance'),
        className: 'text-right',
        align: 'center',
        cell: (record) => {
          return numberFormat(record.loanAmt, 0, '0');
        },
      },
      {
        key: 'loanAmtFee',
        text: t('txt-label-amount-deducted'),
        className: 'text-right',
        align: 'center',
        cell: (record) => {
          return numberFormat(
            StringToInt(record.loanAmt) + StringToInt(record.loanInt),
            0,
            '0'
          );
        },
      },
      {
        key: 'loanInt',
        text: t('txt-label-fee-service'),
        className: 'text-right',
        align: 'center',
        cell: (record) => {
          return numberFormat(record.loanInt, 0, '0');
        },
      },
      {
        text: t('txt-label-fee-management'),
        className: 'text-right',
        align: 'center',
      },
      {
        key: 'exprDt',
        text: t('txt-label-expired-date'),
        className: 'text-center',
        align: 'center',
      },
      {
        key: 'status',
        text: t('txt-status'),
        className: (record) => {
          return 'text-center ' + (record.status === '1' ? 'i' : 'd');
        },
        align: 'center',
        cell: (record) => {
          return record.status === '1'
            ? t('txt-label-repay')
            : t('txt-label-unrepay');
        },
      },
      {
        key: 'loanDt',
        text: t('txt-label-create-date'),
        className: 'text-center',
        align: 'center',
      },
    ];

    return (
      <Fragment>
        <div className="d-flex flex-row mt-3 mb-3">
          <span
            className="mr-auto ml-2 text-white"
            style={{ fontSize: '18px' }}
          >
            {t('txt-cash-advance')}
          </span>
        </div>
        <div className="d-flex flex-column mt-2 mr-3 ml-3">
          <Card className="p-0">
            <Card.Body
              style={{ borderRadius: '5px' }}
              className="d-flex align-items-center"
            >
              <Col className="p-3 d-flex flex-column">
                <span className="text-content fz_14">
                  {t('txt-label-source-advance')}
                </span>
                <SelectUser
                  clsContainer="mt-1"
                  defVal={defaultAccount}
                  grOptions={listAccount}
                  handleChangeUser={this._handleChangeUser}
                />
              </Col>
              <Col md="auto" className="p-3">
                <ReloadButton
                  type="button"
                  className="btn-reload"
                  onClick={this._handleReloadCashAdvance}
                />
              </Col>
            </Card.Body>
          </Card>
          <table className="table table-bordered mt-3">
            <thead>
              <tr>
                <th className="text-center">{t('txt-label-sell-date')}</th>
                <th className="text-center">
                  {t('txt-label-settlement-date')}
                </th>
                <th className="text-center">{t('txt-advanceAvail')}</th>
                <th className="text-center">
                  {t('txt-label-trade-amount-af-fee')}
                </th>
                <th className="text-center">{t('txt-label-amount-advance')}</th>
              </tr>
            </thead>
            <tbody>
              {advMatch &&
                !!advMatch.length &&
                advMatch.map((item) => (
                  <tr key={item.key}>
                    <td className="text-center">{item.ordrDt}</td>
                    <td className="text-center">{_formatDate2(item.exprDt)}</td>
                    <td className="text-right">
                      {numberFormat(item.trdAmt, 0, '0')}
                    </td>
                    <td className="text-right">
                      {numberFormat(item.advanceAvail, 0, '0')}
                    </td>
                    <td style={{ width: '200px' }}>
                      <InputMask
                        maxVal={item.advanceAvail - item.exptInt}
                        name={item.key}
                        value={item.amt}
                        handleChange={this._handleChange}
                        handleBlur={this._handleBlur}
                      />
                      {item.err && (
                        <div
                          style={{
                            color: 'rgb(255, 85, 85)',
                            fontSize: '0.75rem',
                          }}
                        >
                          {item.err}
                        </div>
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          <div className="mt-2 d-flex flex-row-reverse text-white">
            <span className="fz_14 fw-500">
              {numberFormat(_totalAdv, '0', '0')}
            </span>
            <span className="fz_14">{`${t('txt-label-total')}: `}&nbsp;</span>
          </div>
          <div className="mt-2 d-flex flex-row-reverse text-white">
            {auth && auth.isVerified ? (
              <Fragment>
                <Button
                  variant="info"
                  className="fz_14 fw-500"
                  style={{ padding: '2px 28px' }}
                  disabled={!_totalAdv || _totalAdv < 1 || _chkErr}
                  onClick={() => this._handleProcFee()}
                >
                  {t('txt-label-calculator-fee')}
                </Button>
                <Button
                  variant="secondary"
                  onClick={() => this._handleClear()}
                  className="fz_14 fw-500 mr-2"
                  style={{ padding: '2px 28px', backgroundColor: '#6a7094' }}
                >
                  {t('txt-clear')}
                </Button>
              </Fragment>
            ) : (
              <Button
                variant="warning"
                className="fz_14 fw-500 text-white w-140"
                onClick={() => this._handleAuthen()}
              >
                {t('txt-xacthuc')}
              </Button>
            )}
          </div>
        </div>
        <label className="mt-3 mb-1 text-white text-uppercase ml-3">
          {t('txt-label-history')}
        </label>
        <PerfectScrollBar>
          <PaginationTable
            defaultAccount={account}
            pageSize={15}
            columns={columns}
            source={advanceList && advanceList.list ? advanceList.list : []}
          />
        </PerfectScrollBar>
        {showModal && (
          <CashAdvance
            account={account}
            advMatch={advMatch}
            showModal={showModal}
            handleCloseModal={this._handleCloseModal}
            handleAcceptLoan={this._handleAcceptLoan}
          />
        )}
      </Fragment>
    );
  }
}

const makeMapStateToProps = () => {
  const getToken = makeGetToken();
  const getAuth = makeGetAuth();
  const getListAccount = makeGetListAccount();
  const getDefaultAccount = makeGetDefaultAccount();

  const mapStateToProps = (state) => {
    return {
      token: getToken(state),
      auth: getAuth(state),
      listAccount: getListAccount(state),
      defaultAccount: getDefaultAccount(state),
      advanceList: state.cash.advanceList,
      advanceMatch: state.cash.advanceMatch,
    };
  };
  return mapStateToProps;
};

export default connect(makeMapStateToProps)(
  translate('translations')(UngTruocTienBan)
);
