import { format, set } from 'date-fns';
import React, { useContext, useEffect, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import PerfectScrollBar from 'react-perfect-scrollbar';
import { ReactReduxContext, useDispatch, useSelector } from 'react-redux';
import ReactSelect from 'react-select';
import {
  getNewsCategoriesRequest,
  getNewsSortRequest,
  setUpView,
} from '../action';
import { handleApiErrors } from '../../../lib/api-errors';
import { getMessage } from '../../../lib/selector';
import { setToast } from '../../client/actions';
import { SORT_NEWS, useDebounce } from '../../../util';
import { filter, debounce } from 'lodash';
import noImage from '../../../assets/img/noImage.svg';
import noImageLight from '../../../assets/img/noImageLight.svg';
import { Mixpanel } from '../../../lib/mixpanel';

const appUrl = `${process.env.REACT_APP_API_URL_2}`;

const Newsfeed = withNamespaces('translations')(({ t }) => {
  const dispatch = useDispatch();
  const { listNewsCategory, newsSort } = useSelector((state) => state?.news);
  const category = useSelector((state) => state.client.category);
  const _categoryWatchList = filter(category, (o) => o.type === 'watchlist');

  const categoryWatchList = [
    { name: t('news.allWatchlist'), value: '' },
    ..._categoryWatchList,
  ];
  const _listNewsCategory = [
    { categoryName: t('news.allCategory'), id: '' },
    ...listNewsCategory,
  ];
  const lang = useSelector((state) => state?.client?.setting?.lang || 'vi');
  const theme = useSelector((state) => state?.client?.theme);
  const [listNews, setListNews] = useState([]);
  const [keyword, setKeyword] = useState('');
  const [offset, setOffset] = useState(0);
  const [watchlist, setWatchlist] = useState([]);
  const [catId, setCatId] = useState(-1);
  const keywordDebounce = useDebounce(keyword, 1000);
  const [sortType, setSortType] = useState();
  const { store } = useContext(ReactReduxContext);

  const handleGetNews = (isRecall) => {
    const url = `${appUrl}/api/News/GetNews?lang=${
      lang == 'vi' ? 1 : 2
    }&offset=${isRecall ? 0 : offset}&sort=${sortType || 0}&top=36&catId=${
      catId ? catId : -1
    }&keyword=${keywordDebounce}&syms=${watchlist}`;
    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
    })
      .then(handleApiErrors)
      .then((response) => response.json())
      .then((res) => {
        if (res.http == 200) {
          const news = res.data;
          if (isRecall == true) {
            setListNews(news);
          } else {
            let _listNews = [...listNews];
            setListNews(_listNews.concat(news));
          }
          setOffset(offset + news.length);
        } else {
          const msgErr = getMessage(res.data.message, store.getState);
          const toastMsg = {
            id: Math.random(),
            msg: msgErr,
            title: t('txt-notice'),
          };
          dispatch(setToast(toastMsg));
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  useEffect(() => {
    const params = {
      lang: lang == 'vi' ? 1 : 2,
    };
    dispatch(getNewsCategoriesRequest(params));
    dispatch(getNewsSortRequest(params));
  }, []);

  useEffect(() => {
    setOffset(0);
    setSortType(0);
    setCatId(-1);
    setWatchlist('');
    setKeyword('');
    const params = {
      lang: lang == 'vi' ? 1 : 2,
    };
    dispatch(getNewsCategoriesRequest(params));
    dispatch(getNewsSortRequest(params));
    handleGetNews(true);
  }, [lang]);

  useEffect(() => {
    setOffset(0);
    handleGetNews(true);
  }, [keywordDebounce, catId, watchlist, sortType]);

  const loadMore = debounce(() => {
    handleGetNews();
  }, 2000);

  return (
    <div className="card-panel tabs h-100 w-100">
      <div className="card-panel-header">
        <div className="card-panel-header__bound">
          <div className="d-flex card-panel-header__left">
            <span className="card-panel-header__title  active">
              {t('news.newsfeed')}
            </span>
          </div>
          <div className="d-flex card-panel-header__right" key={lang}>
            <div className="input-text-search d-flex align-items-center">
              <input
                placeholder="Symbol"
                onChange={(e) => setKeyword(e.target.value)}
              />
              <span class="icon iSearch"></span>
            </div>
            {newsSort && newsSort.length && (
              <ReactSelect
                defaultValue={newsSort[0]}
                options={newsSort}
                className="filter-control-select"
                classNamePrefix="filter-control-select"
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.value}
                onChange={(value) => setSortType(value.value)}
                isSearchable={false}
                components={{
                  IndicatorSeparator: () => null,
                }}
              />
            )}
            {_listNewsCategory && listNewsCategory.length && (
              <ReactSelect
                defaultValue={_listNewsCategory[0]}
                options={_listNewsCategory}
                className="filter-control-select"
                classNamePrefix="filter-control-select"
                getOptionLabel={(option) => option.categoryName}
                getOptionValue={(option) => option.id}
                onChange={(value) => setCatId(value?.id)}
                isSearchable={false}
                components={{
                  IndicatorSeparator: () => null,
                }}
              />
            )}
            {categoryWatchList && categoryWatchList.length && (
              <ReactSelect
                defaultValue={categoryWatchList[0]}
                options={categoryWatchList}
                className="filter-control-select"
                classNamePrefix="filter-control-select"
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.value}
                onChange={(value) => setWatchlist(value.value)}
                isSearchable={false}
                components={{
                  IndicatorSeparator: () => null,
                }}
              />
            )}
          </div>
        </div>
      </div>
      <div className="card-panel-body">
        <PerfectScrollBar className="w-100" style={{ height: 'calc(100%)' }}>
          <div className="newsfeed-container">
            {listNews &&
              listNews.length &&
              listNews.map((news, i) => (
                <div
                  key={i}
                  className="news cursor-pointer"
                  title={news.title}
                  onClick={() => {
                    Mixpanel.news.readNews(
                      news?.source,
                      'News',
                      'News',
                      news?.title,
                      news?.url,
                      news?.publishTime
                    );

                    dispatch(setUpView(news.id));
                    window.open(news?.url);
                  }}
                >
                  <span
                    className="news__img"
                    style={{
                      backgroundImage: `url('${
                        news?.thumbnail
                          ? news?.thumbnail
                          : theme == 'dark'
                          ? noImage
                          : noImageLight
                      }')`,
                    }}
                  ></span>
                  <div className="news__content">
                    <div className="content__title d-flex justify-content-between">
                      <span className="fz_13 text--light3">
                        {news?.source || news?.author}
                      </span>
                      <span className="text--gray">
                        {format(
                          new Date(
                            news?.publishTime && news.publishTime?.slice(0, 10)
                          ),
                          'dd/MM/yyyy'
                        )}
                      </span>
                    </div>
                    <span className="content__desc text--light fz_16 fw-500">
                      {news.title}
                    </span>
                  </div>
                </div>
              ))}
          </div>
          <div className="btn--loadMore text--light" onClick={() => loadMore()}>
            <span>{t('news.loadmore')}</span>
          </div>
        </PerfectScrollBar>
      </div>
    </div>
  );
});

export default Newsfeed;
