import React, { useContext, useEffect, useState } from 'react';
import { ReactReduxContext, useDispatch, useSelector } from 'react-redux';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import DatePicker, { registerLocale } from 'react-datepicker';
import { getMessage } from '../../../../lib/selector';
import { Portal } from 'react-overlays';
import {
  formatDate,
  numberFormat,
  StringToInt,
  _formatDate2,
  formatToNewDate,
} from '../../../../util';
import { v4 as uuidv4 } from 'uuid';
import { withNamespaces } from 'react-i18next';
import { setAuth, setLogin, setToast } from '../../../client/actions';

import {
  getBondCommonRequest,
  getBondDetail,
  setBondDetailModalRequest,
  setBondProductDetail,
  setProInvRequire,
  setRegistBondTradingRetailModal,
} from '../../action';
import { handleApiErrors } from '../../../../lib/api-errors';
import ReactSelect from 'react-select';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import TextMask from 'react-text-mask';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Modal from 'react-bootstrap/Modal';
import { useHistory, useParams, useLocation } from 'react-router';
import { requiredProInvestor } from '../../utils';
import moment from 'moment/moment';
import { setPFBondDemo, setPFBondOrder } from '../../../pinefolio/action';
import { bondConstants, constants } from '../../../../util/constant';
import _ from 'lodash';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { Mixpanel } from '../../../../lib/mixpanel';

/**
 * Use bondProductDetail for both var and fix bond case
 * bond var products is declared in bondProducts and bond fix products is declared in bondFixProducts
 */

const appUrl = `${process.env.REACT_APP_API_URL}`;
const CalendarContainer = ({ children }) => {
  const el = document.getElementById('calendar-portal');
  return <Portal container={el}>{children}</Portal>;
};

const BondDemo = withNamespaces('translations')(({ t }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();
  const location = useLocation();
  const typeBond =
    new URLSearchParams(location.search).get('type') ??
    constants.bondProductType.Var;
  const term = new URLSearchParams(location.search).get('term');

  const { token, auth, setting } = useSelector((state) => state.client);
  const bondAccounts = token?.custInfo.normal.filter(
    (x) => x.isBondOTCTrade == 'Y'
  );
  const lang = useSelector((state) => state.client.setting.lang || 'vi');
  const { bondProductDetail, bondProducts, bondFixProducts } = useSelector(
    (state) => state.bond
  );
  const theme = useSelector((state) => state.client.currentTheme);
  const { pfBondDemoId, planData } = useSelector((state) => state.pinefolio);

  const [product, setProduct] = useState(bondProductDetail);
  const [saleDate, setSellDate] = useState(
    formatToNewDate(bondProductDetail?.dueDate)
  );
  const [investmentMoney, setInvestmentMoney] = useState('');
  const [moneyFlow, setMoneyFlow] = useState();
  const [error, setError] = useState('');
  const [show, setShow] = useState(false);
  const [bondRateChart, setBondRateChart] = useState();
  const [productOptions, setProductOptions] = useState([]);
  const { store } = useContext(ReactReduxContext);

  const dataBondRate = bondRateChart?.bondRate?.map((item) => {
    return [item.term, item.rate];
  });

  const dataBankRate = bondRateChart?.bankRate?.map((item) => {
    return [item.term, item.rate];
  });

  const totalPointX = bondRateChart?.bankRate
    ?.concat(bondRateChart?.bondRate)
    ?.sort((a, b) => b.term - a.term);

  const { defaultAccount } = useSelector((state) => state.socket);
  const [account, setAccount] = useState(null);

  useEffect(() => {
    if (defaultAccount && defaultAccount.isBondOTCTrade == 'Y') {
      setAccount(defaultAccount);
    } else {
      if (bondAccounts && !!bondAccounts.length > 0) {
        setAccount(bondAccounts[0]);
      }
    }
  }, [defaultAccount, bondAccounts]);

  const getBondRateChart = () => {
    const prdCode = product?.productCode;
    const uuid = uuidv4();
    let params = {
      group: 'CORE',
      cmd: 'getBondRateChart',
      rqId: uuid,
      channel: 'WTS',
      data: {
        prdCode: prdCode,
        tranDt: '',
      },
    };

    if (token && account) {
      params.data = {
        ...params.data,
        acntNo: account.acntNo,
        subAcntNo: account.subAcntNo,
      };
    }

    const callback = (data) => {
      setBondRateChart(data);
    };

    dispatch(getBondCommonRequest(params, callback));
  };

  const handlePreviewMoneyFlow = () => {
    if (StringToInt(investmentMoney) < product?.leg1Prc) {
      setError('bond.maxBuy.minMoneyError');
      return;
    }
    const uuid = uuidv4();
    const prdCode = product?.productCode;
    const prdType = product?.productTp;
    const _tradeDate = formatDate(new Date(), '');
    const _transtDate = formatDate(saleDate, '');

    let params = {
      group: 'CORE',
      user: '',
      session: '',
      cmd: 'getProdIncomeFlow',
      rqId: uuid,
      channel: 'WTS',
      data: {
        prdCode: prdCode,
        investAmt: StringToInt(investmentMoney) + '',
        tranDt: _tradeDate,
        xpctDueDate: _transtDate,
      },
    };
    if (account && token) {
      params = {
        ...params,
        user: token?.user,
        session: token?.user,
        data: {
          ...params.data,
          cif: token?.cif,
          acntNo: account?.acntNo,
          subAcntNo: account?.subAcntNo,
        },
      };
    }

    const url = `${appUrl}/BondServlet.pt`;
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
      body: JSON.stringify(params),
    })
      .then(handleApiErrors)
      .then((response) => response.json())
      .then((res) => {
        if (res.http == 200) {
          const _moneyFlow = res.data;
          setMoneyFlow(_moneyFlow);
        } else {
          const msgErr = getMessage(res.data.message, store.getState);
          const toastMsg = {
            id: Math.random(),
            msg: msgErr,
            title: t('txt-notice'),
          };
          dispatch(setToast(toastMsg));
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });

    Mixpanel.bond.previewMoneyFlow(prdCode, prdType);
  };

  const handleChangeProduct = (value) => {
    setProduct(value);
    setSellDate(formatToNewDate(value.dueDate));
  };

  const handleOrder = () => {
    if (token) {
      if (auth && auth.isVerified) {
        const isRegistedTradingRetailStatus = setting?.pcbMapYN;
        const proIns = requiredProInvestor(token, product);

        if (
          product?.listTp == '4' &&
          (!isRegistedTradingRetailStatus ||
            isRegistedTradingRetailStatus == 'N')
        ) {
          dispatch(setRegistBondTradingRetailModal(true));
          return;
        }

        if (proIns) {
          dispatch(setProInvRequire(true));
          return;
        }

        if (product?.listTp == '4' && isRegistedTradingRetailStatus == 'P') {
          const toastMsg = {
            id: Math.random(),
            msg: t('bond.retailTrading.pendingNoti'),
            title: t('txt-notice'),
          };

          dispatch(setToast(toastMsg));
          return;
        }

        dispatch(setBondDetailModalRequest(bondConstants.modals.BUY_ORDER));
        dispatch(getBondDetail(product.bondCode, lang));
        dispatch(setBondProductDetail(product));

        // case for pinefolio
        if (pfBondDemoId) {
          const pfBondOrder = {
            bondCode: product.bondCode,
            productCode: product.productCode,
            planId: planData.id,
            callback: () => {
              dispatch(setPFBondOrder(null));
            },
          };
          dispatch(setPFBondOrder(pfBondOrder));
        }
      } else {
        dispatch(setAuth());
      }
    } else {
      dispatch(setLogin());
    }
  };

  const handleClose = () => {
    setShow(false);
    if (pfBondDemoId) dispatch(setPFBondDemo(null));
    else history.push('/home/bond');
  };

  useEffect(() => {
    debugger;
    // Bond var case
    if (
      ((id && !!bondProducts?.length > 0) || pfBondDemoId) &&
      (typeBond == constants.bondProductType.Var ||
        typeBond == constants.bondProductType.Growth)
    ) {
      // old flow
      let defaultProduct = null;

      if (!pfBondDemoId) {
        defaultProduct = bondProducts?.find((el) => el.bondCode == id);
      } else {
        defaultProduct = bondProducts?.find((x) => x.bondCode == pfBondDemoId); // pinefolio demo case
      }

      if (!!bondProducts?.length > 0 && defaultProduct) {
        if (!bondProductDetail) {
          setProduct(defaultProduct);
        } else {
          setProduct(bondProductDetail);
        }
        setProductOptions(_.sortBy(bondProducts, (o) => o.bondCode));
        setShow(true);
        setMoneyFlow('');
        setInvestmentMoney('');
        setSellDate(formatToNewDate(defaultProduct?.dueDate));
      }

      return;
    }

    // Bond fix case
    if (
      id &&
      !!bondFixProducts?.length > 0 &&
      typeBond == constants.bondProductType.Fix &&
      term
    ) {
      const defaultProduct = bondFixProducts.find(
        (el) => el.bondCode == id && term == el.term
      );
      if (!bondProductDetail) setProduct(defaultProduct);
      else setProduct(bondProductDetail);
      setProductOptions(_.sortBy(bondFixProducts, (o) => o.bondCode));
      setShow(true);
      setMoneyFlow('');
      setInvestmentMoney('');
      setSellDate(formatToNewDate(defaultProduct?.dueDate));
    }
  }, [id, bondProductDetail, pfBondDemoId, bondFixProducts, bondProducts]);

  useEffect(() => {
    if (product != null && product != undefined) getBondRateChart();
  }, [product]);

  useEffect(() => {
    if (bondProductDetail)
      setSellDate(formatToNewDate(bondProductDetail?.dueDate));
  }, [bondProductDetail]);

  const xAxisFixBond = {
    max: totalPointX?.length > 0 ? totalPointX?.[0]?.term ?? 1 : 1,
    min: 0,
    gridLineWidth: 1,
    tickInterval: 1,
    labels: {
      enabled: true,
      formatter: function () {
        const isvisible = totalPointX?.find((el) => el.term == this.value);
        if (!!isvisible || this.value == 0) {
          return this.value;
        }
        return '';
      },
    },
  };

  const xAxisVarBond = {
    max: 12.5,
    min: -0.5,
    gridLineWidth: 1,
    tickInterval: 1,
    labels: {
      enabled: true,
      formatter: function () {
        return this.value === 0 ? t('bond.today') : this.value;
      },
    },
  };

  const options = {
    chart: {
      type: 'line',
      height: 400,
      marginTop: 40,
      marginBottom: 60,
    },
    title: {
      text: '',
    },
    credits: { enabled: false },
    tooltip: { enabled: false },

    xAxis:
      typeBond == constants.bondProductType.Fix ? xAxisFixBond : xAxisVarBond,

    yAxis: {
      tickInterval: 0.1,
      gridLineWidth: 0,
      visible: false,
    },

    tooltip: {
      enabled: false,
    },

    legend: {
      y: 10,
      labelFormatter: function () {
        return (
          '<span style="color:' +
          this.color +
          '; font-size: 11px; font-weight: 400;">' +
          this.name +
          '</span>'
        );
      },
    },

    series: [
      {
        name:
          typeBond == constants.bondProductType.Fix
            ? t('bond.bondDemo.fix.bondRateLegend')
            : t('bond.bondDemo.var.bondRateLegend'),
        enableMouseTracking: false,
        color: theme.name == 'dark' ? '#FFE70B' : '#F0AE03',
        dataLabels: {
          enabled: true,
          inside: false,
          crop: true,
          backgroundColor: theme.name == 'dark' ? '#434648' : '#F2F2F3',
          color: theme.name == 'dark' ? '#FFE70B' : '#F0AE03',
          borderWidth: 0,
          borderRadius: 4,
          y: -10,

          formatter: function () {
            return (
              '<strong>' +
              Highcharts.numberFormat(this.y, 2, '.', '') +
              '%' +
              '</strong>'
            );
          },
        },
        marker: {
          symbol: 'circle',
        },
        data: dataBondRate ?? [],
      },
      {
        name:
          typeBond == constants.bondProductType.Fix
            ? t('bond.bondDemo.fix.bankRateLegend')
            : t('bond.bondDemo.var.bankRateLegend'),
        enableMouseTracking: false,
        color: theme.name == 'dark' ? '#22F2FF' : '#11B5D9',
        data: dataBankRate ?? [],
        dataLabels: {
          enabled: true,
          inside: false,
          crop: true,
          backgroundColor: theme.name == 'dark' ? '#434648' : '#F2F2F3',
          color: theme.name == 'dark' ? '#22F2FF' : '#11B5D9',
          borderWidth: 0,
          borderRadius: 4,
          y: -10,
          formatter: function () {
            if (this.x) {
              return (
                '<strong>' +
                Highcharts.numberFormat(this.y, 2, '.', '') +
                '%' +
                '</strong>'
              );
            }
          },
        },
      },
    ],
  };

  if (!product) return <></>;
  return (
    <Modal
      show={show}
      dialogClassName="wts-modal"
      backdropClassName="wts-modal-backdrop bond-demo-backdrop"
      contentClassName={`${
        theme ? theme.name : 'dark'
      } bond-detail bond-demo wts-modal-content wts-modal-content--xl`}
      onEscapeKeyDown={() => handleClose()}
      centered
      onHide={() => handleClose()}
      style={{ zIndex: 11116 }}
    >
      <div className="bond-detail-panel">
        <div className="bond-detail-body">
          <div className="bond-demo-overview">
            <div className="bond-chart">
              <div className="chart-header">
                <div className="bond-layout__filters">
                  <div className="bond-layout__filter">
                    <ReactSelect
                      defaultValue={product}
                      options={productOptions}
                      className={
                        'filter-control-select ' + theme
                          ? theme.name + '-select'
                          : 'dark-select'
                      }
                      classNamePrefix="filter-control-select"
                      getOptionLabel={(option) =>
                        option.bondCode + '-' + option.term
                      }
                      getOptionValue={(option) => option.id}
                      onChange={(value) => handleChangeProduct(value)}
                      isSearchable={true}
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                    />
                  </div>
                </div>
                <div className="chart-title">
                  <span className="fw-500 text--light">
                    {typeBond == constants.bondProductType.Fix
                      ? t('bond.bondDemo.titleChartFBond')
                      : t('bond.bondDemo.titleChart')}
                  </span>
                </div>
              </div>
              <div className="chart-content">
                <HighchartsReact highcharts={Highcharts} options={options} />
              </div>
              <div className="chart-footer w-100 d-flex justify-content-center">
                <div className="w-100 text-center mt-3 text--light3 font-italic">
                  {t('bond.bondDemo.note')}
                </div>
              </div>
            </div>
            <div className="bond-money-flow">
              <div className="row p-0">
                <div className="buy-price">
                  <label className="text--light3">
                    {t('bond.tableLabel.buyPrice')}
                  </label>
                  <span className="i">
                    {numberFormat(product.leg1Prc, '0', 0)}
                  </span>
                </div>
                <div className="minimum-quantity">
                  <label className="text--light3">
                    {t('bond.tableLabel.minQuantity')}
                  </label>
                  <span className="text--light fw-500">
                    {numberFormat(product.minInvtQtyPerOrdr, '0', 0)}
                  </span>
                </div>
              </div>
              <div className="row">
                <label className="text--light3">
                  {t('bond.bondDemo.investmentMoney')}
                </label>
                <TextMask
                  type="text"
                  maxLength={15}
                  className={`input-text-search ${error ? 'input-error' : ''}`}
                  value={investmentMoney}
                  mask={createNumberMask({
                    prefix: '',
                    allowNegative: false,
                  })}
                  onFocus={(e) => e.currentTarget.select()}
                  onChange={(e) => {
                    setInvestmentMoney(e.target.value);
                    if (e.target.value) {
                      if (
                        StringToInt(e.target.value) <
                        StringToInt(product?.leg1Prc)
                      )
                        setError('bond.maxBuy.minMoneyError');
                      else {
                        setError('');
                      }
                    } else {
                      setError('');
                    }
                  }}
                />
                {error && <span className="text-error mt-2">{t(error)}</span>}
              </div>
              <div>
                <div className="d-flex flex-column flex-nowrap">
                  <div className="d-flex buy-price w-100">
                    <label className="text--light3 w-50">
                      {t('bond.bondDemo.purchaseDate')}
                    </label>
                    <label className="text--light3">
                      {t('bond.bondDemo.chooseExptSellDate')}
                    </label>
                  </div>
                  <div className="d-flex w-100">
                    <div className="filter-control-calendar flex-1 w-50 mr-2">
                      <span className="purchase-date w-100 text--gray">
                        {moment(new Date()).format('DD/MM/YYYY')}
                      </span>
                    </div>
                    <div className="filter-control-calendar flex-1 w-50">
                      <DatePicker
                        selected={saleDate}
                        onChange={(date) => setSellDate(date)}
                        dateFormat="dd/MM/yyyy"
                        popperContainer={CalendarContainer}
                        placeholderText="Intended to sell"
                        maxDate={formatToNewDate(bondProductDetail?.dueDate)}
                        readOnly={
                          bondProductDetail?.listTp == '2' ||
                          typeBond == constants.bondProductType.Fix
                            ? true
                            : false
                        }
                        disabled={
                          bondProductDetail?.listTp == '2' ? true : false
                        }
                      />
                      <span className="icon iCalendar"></span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <button
                    className="btn--primary cursor-pointer"
                    onClick={handlePreviewMoneyFlow}
                  >
                    {t('bond.previewMoneyFlow')}
                  </button>
                </div>
              </div>
              <PerfectScrollbar
                style={{ height: 'calc(100% - 275px)', paddingTop: '4px' }}
              >
                <table className="table table-bordered">
                  <tbody>
                    <tr>
                      <td>
                        <label className="text--light3">
                          {t('bond.investAmount')}
                        </label>
                      </td>
                      <td className="text-right" colSpan={2}>
                        <label className="text--light fw-500">
                          {numberFormat(moneyFlow?.invtAmt, '0', 0)}
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label className="text--light3">
                          {t('bond.bondQuantity.name')}
                        </label>
                      </td>
                      <td className="text-right" colSpan={2}>
                        <label className="text--light fw-500">
                          {numberFormat(moneyFlow?.quantity, '0', 0)}
                        </label>
                      </td>
                    </tr>
                    {moneyFlow &&
                      moneyFlow.couponAmt &&
                      moneyFlow.couponAmt.map((coupon) => (
                        <tr>
                          <td>
                            {coupon.times < 999 ? (
                              <label className="text--light3">
                                {t('bond.coupon')}
                              </label>
                            ) : coupon.times == 999 ? (
                              <label className="text--light3">
                                {t('bond.bondTotalSellMoney')}
                              </label>
                            ) : (
                              <label className="text--light3">
                                {t('bond.principal')}
                              </label>
                            )}
                          </td>
                          <td>
                            <label className="text--light3">
                              {_formatDate2(coupon.paydate)}
                            </label>
                          </td>
                          <td className="text-right text--light fw-500">
                            <span>{numberFormat(coupon.amount, 0, '0')}</span>
                          </td>
                        </tr>
                      ))}
                    <tr>
                      <td>
                        <label className="text--light3">
                          {t('bond.netProfit')}
                        </label>
                      </td>
                      <td colSpan={2} className="text-right text--light fw-500">
                        <span>{numberFormat(moneyFlow?.profit, '0', 0)}</span>
                      </td>
                    </tr>
                    {typeBond == constants.bondProductType.Fix && (
                      <tr>
                        <td>
                          <label className="text--light3">
                            {t('bond.totalValue.proRate')}
                          </label>
                        </td>
                        <td colSpan={2} className="text-right">
                          <span className="text--light fw-500">
                            {moneyFlow &&
                              numberFormat(moneyFlow.proRt, 2, '0') + '%'}
                          </span>
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td className="text-left d-flex ">
                        <span className="text--light3">{t('bond.rate')}</span>
                        <OverlayTrigger
                          trigger={['hover', 'focus']}
                          placement="top"
                          rootClose
                          overlay={
                            <Popover style={{ maxWidth: '440px' }}>
                              <Popover.Content className="d-flex">
                                {t('bond.bondDemo.suggest')}
                              </Popover.Content>
                            </Popover>
                          }
                        >
                          <span className="tool-tip d-flex align-items-center cursor-pointer">
                            <span className="icon iWarming ml-1 mr-1"></span>
                          </span>
                        </OverlayTrigger>
                      </td>
                      <td colSpan={2} className="text-right r fw-500">
                        <span>
                          {numberFormat(moneyFlow?.realRt, '2', 0) + '%'}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label className="text--light3">
                          {t('bond.totalAmountReceive')}
                        </label>
                      </td>
                      <td colSpan={2} className="text-right i fw-500">
                        <span>
                          {numberFormat(moneyFlow?.totRecvAmt, '0', 0)}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </PerfectScrollbar>
              <div className="bond-detail-order__orderPlace mt-0">
                <div className="order-actions">
                  <button
                    className={
                      auth && auth.isVerified
                        ? 'btn btn--buy'
                        : 'btn btn--buy btn--authen'
                    }
                    onClick={handleOrder}
                  >
                    {t('txt-buy')}
                  </button>
                  <button className="btn btn--cancel" onClick={handleClose}>
                    {t('bond.cancel')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
});

export default BondDemo;
