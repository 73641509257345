import { bondConstants, constants } from '../../util/constant';
import {
  GET_BOND_ORDERLIST_REQUEST,
  GET_BOND_ORDERLIST_SUCCESS,
  GET_BOND_ORDERLIST_ERROR,
  SET_BOND_DETAIL_MODAL,
  GET_BOND_DEALLIST_REQUEST,
  GET_BOND_PORTFOLIO_REQUEST,
  GET_BOND_PORTFOLIO_SUCCESS,
  GET_BOND_PORTFOLIO_ERROR,
  GET_BOND_PRODUCTS_REQUEST,
  GET_BOND_PRODUCTS_REQUEST_SUCCESS,
  GET_BOND_PRODUCTS_REQUEST_ERROR,
  GET_BOND_ISSUERS_REQUEST,
  GET_BOND_ISSUERS_REQUEST_SUCCESS,
  GET_BOND_ISSUERS_REQUEST_ERROR,
  GET_BOND_DETAIL_REQUEST,
  GET_BOND_DETAIL_GUARANTEE_MODAL,
  GET_BOND_DETAIL_REQUEST_ERROR,
  GET_BOND_DETAIL_REQUEST_SUCCESS,
  SET_BOND_PRODUCT_DETAIL,
  GET_BOND_PREORDER_REQUEST,
  GET_PROD_IMCOME_FLOW_REQUEST,
  GET_PROD_IMCOME_FLOW_SUCCESS,
  GET_PROD_IMCOME_FLOW_ERROR,
  GET_BOND_PREORDER_REQUEST_SUCCESS,
  GET_BOND_PREORDER_REQUEST_ERROR,
  GET_DEAL4SALE_REQUEST,
  GET_DEAL4SALE_REQUEST_ERROR,
  GET_DEAL4SALE_REQUEST_SUCCESS,
  GET_BOND_RATE_CHART_REQUEST,
  GET_BOND_RATE_CHART_REQUEST_SUCCESS,
  GET_BOND_RATE_CHART_REQUEST_ERROR,
  SELL_ORDER_REQUEST,
  SELL_ORDER_REQUEST_SUCCESS,
  SELL_ORDER_REQUEST_ERROR,
  SET_BOND_ORDER,
  SELL_ORDER_REQUEST_RESET,
  BUY_ORDER_REQUEST,
  BUY_ORDER_REQUEST_ERROR,
  BUY_ORDER_REQUEST_SUCCESS,
  PRO_INVESTOR_REQUIRED,
  SET_DEAL_DETAIL,
  GET_BOND_FIX_REQUEST_SUCCESS,
  GET_BOND_FIX_REQUEST,
  GET_BOND_DETAIL_GUARANTEE_MODAL_SUCCESS,
  SET_BOND_TAB,
  SET_BOND_REGIST_RETAIL_TRADING_MODAL,
  SET_BOND_REGIST_RETAIL_TRADING_SUCCESS_MODAL,
} from './constants';

const initialState = {
  tabActive: bondConstants.tabs.PRODUCT_LIST,
  listBondOrderList: [],
  listBondOrderListRequesting: false,
  listBondOrderListError: [],

  bondProducts: [],
  bondProductRequest: false,
  bondProductRequestSuccess: false,
  bondProductRequestError: [],
  bondProductDetail: null,

  listBondDealList: [],

  listBondPortfolioList: [],
  listBondPortfolioListRequesting: false,
  listBondPortfolioListError: [],

  bondDetail: null,
  bondDetailRequest: false,
  bondDetailRequestSuccess: false,
  bondDetailRequestError: [],
  bondDetailModalType: bondConstants.modals.CLOSE, // -1: close | 0: infor | 1: buy | 2: sell | 3: demo | 4: contract
  bondDetailSubAccount: null, // used for buy/sell from deallist bond or pinefolio ...

  bondIssuers: [],
  bondIssuersRequest: false,
  bondIssuersRequestSuccess: false,
  bondIssuersRequestError: [],

  proIncomeFlow: [],
  proIncomeFlowRequest: false,
  proIncomeFlowSuccess: false,
  proIncomeFlowError: [],

  bondPreOrder: null,
  bondPreOrderRequest: false,
  bondPreOrderRequestSuccess: false,
  bondPreOrderRequestError: [],

  deals4sale: [],
  deals4saleRequest: false,
  deals4saleRequestSuccess: false,
  deals4saleRequestError: [],

  bondOrder: {},
  sellOrderRequest: false,
  sellOrderRequestSuccess: false,
  sellOrderRequestError: [],

  bondRateChart: [],
  bondRateChartRequest: false,
  bondRateChartRequestSuccess: false,
  bondRateChartRequestError: false,

  buyOrderRequest: false,
  buyOrderRequestSuccess: false,
  buyOrderRequestError: [],

  bondDemo: {},
  proInvRequire: false,
  deal: {},

  bondFixProducts: [],
  bondFixDetail: null,

  registBondRetailShow: false,
  registBondRetailSuccessShow: false,
  limitInfor: {},
};

const reducer = function bondReducer(state = initialState, action) {
  switch (action.type) {
    case 'INVALID_SESSION':
    case 'LOG_OUT':
    case 'CLIENT_UNSET':
      return initialState;
    case SET_BOND_TAB: {
      return {
        ...state,
        tabActive: action.tabActive,
      };
    }
    case GET_BOND_ORDERLIST_REQUEST: {
      return {
        ...state,
        listBondOrderList: [],
        listBondOrderListRequesting: true,
        listBondOrderListError: [],
      };
    }
    case GET_BOND_ORDERLIST_SUCCESS: {
      return {
        ...state,
        listBondOrderList: action.listBondOrderList,
        listBondOrderListRequesting: false,
        listBondOrderListError: [],
      };
    }

    case GET_BOND_ORDERLIST_ERROR: {
      return {
        ...state,
        listBondOrderList: [],
        listBondOrderListRequesting: false,
        listBondOrderListError: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };
    }

    case SET_BOND_DETAIL_MODAL: {
      // const _detail = bondList.find((x) => x.bondCode == action.bondCode);
      const _modalState = action.state;
      const _subAccountNo = action.subAccountNo;
      const _bondDetail = state?.bondDetail;
      const _bondProductDetail = state?.bondProductDetail;
      return {
        ...state,
        listBondOrderList: [],
        listBondOrderListRequesting: true,
        listBondOrderListError: [],
        bondDetailModalType: _modalState,
        bondDetail:
          _modalState == bondConstants.modals.CLOSE ? null : _bondDetail,
        bondProductDetail:
          _modalState == bondConstants.modals.CLOSE ? null : _bondProductDetail,
        bondDetailSubAccount: _subAccountNo,
      };
    }

    case GET_BOND_PRODUCTS_REQUEST: {
      return {
        ...state,
        bondProducts: [],
        bondProductRequest: true,
        bondProductRequestSuccess: false,
        bondProductRequestError: [],
      };
    }

    case GET_BOND_PRODUCTS_REQUEST_SUCCESS: {
      return {
        ...state,
        bondProducts: action.data,
        bondProductRequest: false,
        bondProductRequestSuccess: true,
        bondProductRequestError: [],
      };
    }

    case GET_BOND_PRODUCTS_REQUEST_ERROR: {
      return {
        ...state,
        bondProducts: action.data,
        bondProductRequest: false,
        bondProductRequestSuccess: false,
        bondProductRequestError: [
          ...[
            {
              body: action.error?.toString(),
              time: new Date(),
            },
          ],
        ],
      };
    }

    case SET_BOND_PRODUCT_DETAIL: {
      return {
        ...state,
        bondProductDetail: action.data,
      };
    }

    case GET_BOND_ISSUERS_REQUEST: {
      return {
        ...state,
        bondIssuers: [],
        bondIssuersRequest: true,
        bondIssuersRequestSuccess: false,
        bondIssuersRequestError: [],
      };
    }

    case GET_BOND_ISSUERS_REQUEST_SUCCESS: {
      return {
        ...state,
        bondIssuers: action.data?.list || [],
        bondIssuersRequest: true,
        bondIssuersRequestSuccess: true,
        bondIssuersRequestError: [],
      };
    }

    case GET_BOND_ISSUERS_REQUEST_ERROR: {
      return {
        ...state,
        bondIssuers: [],
        bondIssuersRequest: false,
        bondIssuersRequestSuccess: false,
        bondIssuersRequestError: [
          ...[
            {
              body: action.error?.toString(),
              time: new Date(),
            },
          ],
        ],
      };
    }

    case GET_BOND_DETAIL_REQUEST: {
      return {
        ...state,
        bondDetail: null,
        bondDetailRequest: true,
        bondDetailRequestSuccess: false,
        bondDetailRequestError: [],
      };
    }

    case GET_BOND_DETAIL_GUARANTEE_MODAL: {
      return {
        ...state,
        collateralDetail: [],
      };
    }

    case GET_BOND_DETAIL_GUARANTEE_MODAL_SUCCESS: {
      return {
        ...state,
        collateralDetail: action.data,
      };
    }

    case GET_BOND_DETAIL_REQUEST_SUCCESS: {
      const data = action.data;
      let _bondDetail = null;
      if (data && data.length > 0) {
        _bondDetail = data[0];
      }
      return {
        ...state,
        bondDetail: _bondDetail,
        bondDetailRequest: false,
        bondDetailRequestSuccess: true,
        bondDetailRequestError: [],
      };
    }

    case GET_BOND_DETAIL_REQUEST_ERROR: {
      return {
        ...state,
        bondDetail: null,
        bondDetailRequest: false,
        bondDetailRequestSuccess: false,
        bondDetailRequestError: [
          ...[
            {
              body: action.error?.toString(),
              time: new Date(),
            },
          ],
        ],
      };
    }

    case GET_BOND_DEALLIST_REQUEST: {
      return {
        ...state,
        listBondDealList: [],
      };
    }

    case GET_BOND_PORTFOLIO_REQUEST: {
      return {
        ...state,
        listBondPortfolioList: [],
        listBondPortfolioListRequesting: true,
        listBondPortfolioListError: [],
      };
    }
    case GET_BOND_PORTFOLIO_SUCCESS: {
      return {
        ...state,
        listBondPortfolioList: action.listBondPortfolio,
        listBondPortfolioListRequesting: false,
        listBondPortfolioListError: [],
      };
    }

    case GET_BOND_PORTFOLIO_ERROR: {
      return {
        ...state,
        listBondPortfolioList: [],
        listBondPortfolioListRequesting: false,
        listBondPortfolioListError: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };
    }

    case GET_BOND_PREORDER_REQUEST: {
      return {
        ...state,
        bondPreOrder: null,
        bondPreOrderRequest: true,
        bondPreOrderRequestSuccess: false,
        bondPreOrderRequestError: [],
      };
    }

    case GET_BOND_PREORDER_REQUEST_SUCCESS: {
      return {
        ...state,
        bondPreOrder: action.data,
        bondPreOrderRequest: false,
        bondPreOrderRequestSuccess: true,
        bondPreOrderRequestError: [],
      };
    }

    case GET_BOND_PREORDER_REQUEST_ERROR: {
      return {
        ...state,
        bondPreOrder: null,
        bondPreOrderRequest: false,
        bondPreOrderRequestSuccess: false,
        bondPreOrderRequestError: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };
    }

    case GET_PROD_IMCOME_FLOW_REQUEST: {
      return {
        ...state,
        proIncomeFlow: [],
        proIncomeFlowRequest: true,
        proIncomeFlowSuccess: false,
        proIncomeFlowError: [],
      };
    }

    case GET_PROD_IMCOME_FLOW_SUCCESS: {
      return {
        ...state,
        proIncomeFlow: action.proIncomeFlow,
        proIncomeFlowRequest: false,
        proIncomeFlowSuccess: true,
        proIncomeFlowError: [],
      };
    }

    case GET_PROD_IMCOME_FLOW_ERROR: {
      return {
        ...state,
        proIncomeFlow: action.proIncomeFlow,
        proIncomeFlowRequest: false,
        proIncomeFlowSuccess: false,
        proIncomeFlowError: [
          ...[
            {
              body: action.error?.toString(),
              time: new Date(),
            },
          ],
        ],
      };
    }

    case GET_DEAL4SALE_REQUEST: {
      return {
        ...state,
        deals4sale: [],
        deals4saleRequest: true,
        deals4saleRequestSuccess: false,
        deals4saleRequestError: [],
      };
    }

    case GET_DEAL4SALE_REQUEST_SUCCESS: {
      return {
        ...state,
        deals4sale: action.data,
        deals4saleRequest: false,
        deals4saleRequestSuccess: true,
        deals4saleRequestError: [],
      };
    }

    case GET_DEAL4SALE_REQUEST_ERROR: {
      return {
        ...state,
        deals4sale: [],
        deals4saleRequest: false,
        deals4saleRequestSuccess: false,
        deals4saleRequestError: [
          ...[
            {
              body: action.error?.toString(),
              time: new Date(),
            },
          ],
        ],
      };
    }

    case GET_BOND_RATE_CHART_REQUEST: {
      return {
        ...state,
        bondRateChart: [],
        bondRateChartRequest: true,
        bondRateChartRequestSuccess: false,
        bondRateChartRequestError: [],
      };
    }

    case GET_BOND_RATE_CHART_REQUEST_SUCCESS: {
      return {
        ...state,
        bondRateChart: action.data,
        bondRateChartRequest: false,
        bondRateChartRequestSuccess: true,
        bondRateChartRequestError: [],
      };
    }

    case GET_BOND_RATE_CHART_REQUEST_ERROR: {
      return {
        ...state,
        bondRateChart: [],
        bondRateChartRequest: false,
        bondRateChartRequestSuccess: false,
        bondRateChartRequestError: [
          ...[
            {
              body: action.error?.toString(),
              time: new Date(),
            },
          ],
        ],
      };
    }

    case SELL_ORDER_REQUEST: {
      return {
        ...state,
        sellOrderRequest: true,
        sellOrderRequestSuccess: false,
        sellOrderRequestError: [],
      };
    }

    case SELL_ORDER_REQUEST_SUCCESS: {
      return {
        ...state,
        sellOrderRequest: false,
        sellOrderRequestSuccess: true,
        sellOrderRequestError: [],
      };
    }

    case SELL_ORDER_REQUEST_ERROR: {
      return {
        ...state,
        sellOrderRequest: false,
        sellOrderRequestSuccess: false,
        sellOrderRequestError: [
          ...[
            {
              body: action.error?.toString(),
              time: new Date(),
            },
          ],
        ],
      };
    }

    case SET_BOND_ORDER: {
      return {
        ...state,
        bondOrder: action.data,
        bondDetailModalType: bondConstants.modals.CONTRACT,
      };
    }

    case SELL_ORDER_REQUEST_RESET: {
      return {
        ...state,
        sellOrderRequest: false,
        sellOrderRequestSuccess: false,
        sellOrderRequestError: [],
        buyOrderRequest: false,
        buyOrderRequestSuccess: false,
        buyOrderRequestError: [],
      };
    }

    case BUY_ORDER_REQUEST: {
      return {
        ...state,
        buyOrderRequest: true,
        buyOrderRequestSuccess: false,
        buyOrderRequestError: [],
      };
    }

    case BUY_ORDER_REQUEST_SUCCESS: {
      return {
        ...state,
        buyOrderRequest: false,
        buyOrderRequestSuccess: true,
        buyOrderRequestError: [],
      };
    }

    case BUY_ORDER_REQUEST_ERROR: {
      return {
        ...state,
        buyOrderRequest: false,
        buyOrderRequestSuccess: false,
        buyOrderRequestError: [
          ...[
            {
              body: action.error?.toString(),
              time: new Date(),
            },
          ],
        ],
      };
    }

    case PRO_INVESTOR_REQUIRED: {
      return {
        ...state,
        proInvRequire: action.state,
      };
    }

    case SET_DEAL_DETAIL: {
      return {
        ...state,
        deal: action.deal,
      };
    }

    case GET_BOND_FIX_REQUEST: {
      return {
        ...state,
        bondFixProducts: [],
      };
    }

    case GET_BOND_FIX_REQUEST_SUCCESS: {
      return {
        ...state,
        bondFixProducts: action.data,
      };
    }

    case SET_BOND_REGIST_RETAIL_TRADING_MODAL: {
      return {
        ...state,
        registBondRetailShow: action.show,
      };
    }

    case SET_BOND_REGIST_RETAIL_TRADING_SUCCESS_MODAL: {
      return {
        ...state,
        registBondRetailSuccessShow: action.show,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
