import React, { useEffect, useState, useRef } from 'react';
const isStyleObject = (obj) => typeof obj === 'object' && obj !== null;

const OTPInput = ({
  value = '',
  numInputs = 4,
  onChange,
  onPaste,
  renderInput,
  shouldAutoFocus = false,
  containerStyle,
  inputStyle,
  skipDefaultStyles = false,
}) => {
  const [activeInput, setActiveInput] = useState(0);
  const inputRefs = useRef([]);

  const getOTPValue = () => (value ? value.toString().split('') : []);

  useEffect(() => {
    inputRefs.current = inputRefs.current.slice(0, numInputs);
  }, [numInputs]);

  useEffect(() => {
    if (shouldAutoFocus) {
      inputRefs.current[0]?.focus();
    }
  }, [shouldAutoFocus]);

  const isInputValueValid = (value) => {
    const isTypeValid = typeof value === 'string';
    return isTypeValid && value.trim().length === 1;
  };

  const handleChange = (event) => {
    const { value } = event.target;
    const otp = getOTPValue();
    if (isInputValueValid(value)) {
      changeCodeAtFocus(value);
      focusInput(activeInput + 1);
    }
  };

  const handleFocus = (event) => (index) => {
    setActiveInput(index);
    event.target.select();
  };

  const handleBlur = () => {
    setActiveInput(activeInput - 1);
  };

  const handleKeyDown = (event) => {
    const otp = getOTPValue();
    if ([event.code, event.key].includes('Backspace')) {
      event.preventDefault();
      if (!otp[activeInput]) {
        focusInput(activeInput - 1);
      } else {
        clearCodeAfterFocus();
      }
    } else if ([event.code, event.key].includes('Delete')) {
      event.preventDefault();
      clearCodeAfterFocus();
    } else if ([event.code, event.key].includes('ArrowLeft')) {
      event.preventDefault();
      focusInput(activeInput - 1);
    } else if ([event.code, event.key].includes('ArrowRight')) {
      event.preventDefault();
      focusInput(activeInput + 1);
    } else if (event.key === otp[activeInput]) {
      event.preventDefault();
      focusInput(activeInput + 1);
    }
  };

  const focusInput = (index) => {
    const activeInput = Math.max(Math.min(numInputs - 1, index), 0);

    if (inputRefs.current[activeInput]) {
      inputRefs.current[activeInput]?.focus();
      inputRefs.current[activeInput]?.select();
      setActiveInput(activeInput);
    }
  };

  const changeCodeAtFocus = (value) => {
    const otp = getOTPValue();
    if (!otp[activeInput]) {
      otp[activeInput] = value[0];
      handleOTPChange(otp);
    } else {
      otp[activeInput] = value[0];
      otp.splice(activeInput + 1);
      handleOTPChange(otp);
    }
  };

  const clearCodeAfterFocus = (step = 0) => {
    const otp = getOTPValue();
    if (activeInput !== -1) {
      otp.splice(activeInput + step);
    }
    console.log('clearCodeAfterFocus', otp, step);
    handleOTPChange(otp);
  };

  const handleOTPChange = (otp) => {
    const otpValue = otp.join('');
    onChange(otpValue);
  };

  const handlePaste = (event) => {
    event.preventDefault();

    const otp = getOTPValue();
    let nextActiveInput = activeInput;

    const pastedData = event.clipboardData
      .getData('text/plain')
      .slice(0, numInputs - activeInput)
      .split('');

    for (let pos = 0; pos < numInputs; ++pos) {
      if (pos >= activeInput && pastedData.length > 0) {
        otp[pos] = pastedData.shift() ?? '';
        nextActiveInput++;
      }
    }

    focusInput(nextActiveInput);
    handleOTPChange(otp);
  };

  console.log(getOTPValue());

  return (
    <div
      style={Object.assign(
        {
          display: 'flex',
          alignItems: 'center',
        },
        isStyleObject(containerStyle) && containerStyle
      )}
      className={
        typeof containerStyle === 'string' ? containerStyle : undefined
      }
      onPaste={onPaste}
    >
      {Array.from({ length: numInputs }, (_, index) => index).map((index) => (
        <React.Fragment key={index}>
          {renderInput(
            {
              cell: index,
              value: getOTPValue()[index] ?? '',
              ref: (element) => (inputRefs.current[index] = element),
              onChange: handleChange,
              onFocus: (event) => handleFocus(event)(index),
              onBlur: handleBlur,
              onKeyDown: handleKeyDown,
              onPaste: handlePaste,
              autoComplete: 'off',
              'aria-label': `Please enter OTP character ${index + 1}`,
              style: Object.assign(
                !skipDefaultStyles ? { width: '1em', textAlign: 'center' } : {},
                isStyleObject(inputStyle) ? inputStyle : {}
              ),
              className:
                typeof inputStyle === 'string' ? inputStyle : undefined,
              type: 'text',
            },
            index
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default OTPInput;
