import React, { useEffect, useState } from 'react';
import { constants } from '../../util/constant';
import { withNamespaces } from 'react-i18next';
import GeneralIntro from './components/generalIntro';
import RuleAndAwards from './components/ruleAndAward';
import Performance from './components/performance';
import LeaderBoard from './components/leaderBoard';
import QA from './components/qa';
import { useDispatch, useSelector } from 'react-redux';
import { setLogin } from '../client/actions';
import { Mixpanel } from '../../lib/mixpanel';
import { v4 as uuidv4 } from 'uuid';
import { getRegistCptStatRequest } from './actions';

const MarginCompetiton = withNamespaces('translations')(({ t }) => {
  const [tabActive, setTabActive] = useState(null);
  const { token, setting } = useSelector((state) => state.client);
  const { registCptStat } = useSelector((state) => state.marginCompetition);
  const lang = setting.lang || 'vi';
  const [refresh, setRefresh] = useState(false);
  const dispatch = useDispatch();

  const handleSetTabActive = () => {
    if (!token) {
      dispatch(setLogin());
      return false;
    } else {
      setTabActive(constants.marginCompetition.PERFORMANCE);
    }
  };

  function getRegistCptStat() {
    const params = {
      group: 'CORE',
      cmd: 'getRegistCptStat',
      session: token?.session,
      user: token?.user,
      channel: 'WTS',
      data: {
        cif: token?.cif,
      },
    };

    dispatch(getRegistCptStatRequest(params));
  }

  useEffect(() => {
    if (token) {
      if (
        tabActive == constants.marginCompetition.GENERAL_INTRO_TAB ||
        tabActive == null
      ) {
        getRegistCptStat();
      }
    } else if (
      tabActive == constants.marginCompetition.PERFORMANCE ||
      tabActive == null
    ) {
      setTabActive(constants.marginCompetition.GENERAL_INTRO_TAB);
    }
  }, [token]);

  useEffect(() => {
    if (token) getRegistCptStat();
  }, []);

  useEffect(() => {
    if (
      registCptStat &&
      registCptStat?.stat == 'A' &&
      tabActive != constants.marginCompetition.GENERAL_INTRO_TAB
    ) {
      setTabActive(constants.marginCompetition.PERFORMANCE);
    }
    return () => {
      if ((registCptStat && registCptStat?.stat != 'A') || !registCptStat) {
        setTabActive(constants.marginCompetition.GENERAL_INTRO_TAB);
      }
    };
  }, [registCptStat]);

  return (
    <div className={`pinefolio-page layout-page`}>
      <div className="pinefolio-content margin-competition">
        <div className="card-panel-2">
          <div className="card-panel-2-header justify-content-between">
            <div className="pinefolio-header__left">
              <span
                className={`card-panel-2__tab ${
                  tabActive == constants.marginCompetition.GENERAL_INTRO_TAB
                    ? 'active'
                    : ''
                }`}
                onClick={() => {
                  setTabActive(constants.marginCompetition.GENERAL_INTRO_TAB);
                  Mixpanel.track('View Competition Detail', {
                    'Competition Detail Screen': 'General Introduction',
                    CIF: token?.cif,
                  });
                }}
              >
                {t('marginCompetition.generalIntroduction.name')}
              </span>
              <span
                className={`card-panel-2__tab ${
                  tabActive == constants.marginCompetition.RULE_AND_AWARD
                    ? 'active'
                    : ''
                }`}
                onClick={() => {
                  setTabActive(constants.marginCompetition.RULE_AND_AWARD);
                  Mixpanel.track('View Competition Detail', {
                    'Competition Detail Screen': 'Rule & Award',
                    CIF: token?.cif,
                  });
                }}
              >
                {t('marginCompetition.ruleAndAwards.name')}
              </span>
              <span
                className={`card-panel-2__tab ${
                  tabActive == constants.marginCompetition.PERFORMANCE
                    ? 'active'
                    : ''
                }`}
                onClick={() => {
                  handleSetTabActive();
                  Mixpanel.track('View Competition Detail', {
                    'Competition Detail Screen': 'Performance',
                    CIF: token?.cif,
                  });
                }}
              >
                {t('marginCompetition.performance.name')}
              </span>
              <span
                className={`card-panel-2__tab ${
                  tabActive == constants.marginCompetition.LEADER_BOARD
                    ? 'active'
                    : ''
                }`}
                onClick={() => {
                  setTabActive(constants.marginCompetition.LEADER_BOARD);
                  Mixpanel.track('View Competition Detail', {
                    'Competition Detail Screen': 'Leaderboard',
                    CIF: token?.cif,
                  });
                }}
              >
                {t('marginCompetition.leaderBoard.name')}
              </span>
              <span
                className={`card-panel-2__tab ${
                  tabActive == constants.marginCompetition.QA ? 'active' : ''
                }`}
                onClick={() => {
                  setTabActive(constants.marginCompetition.QA);
                  Mixpanel.track('View Competition Detail', {
                    'Competition Detail Screen': 'Q&A',
                    CIF: token?.cif,
                  });
                }}
              >
                {t('marginCompetition.QA.name')}
              </span>
            </div>
            <div className="pinefolio-header__right">
              {(tabActive == constants.marginCompetition.PERFORMANCE ||
                tabActive == constants.marginCompetition.LEADER_BOARD) && (
                <div
                  className="btn-icon btn--transparent"
                  onClick={() => {
                    setRefresh(!refresh);

                    Mixpanel.track('Refresh Data', {
                      CIF: token?.cif || '',
                      'Competition Detail Screen':
                        tabActive === constants.marginCompetition.PERFORMANCE
                          ? 'Performance'
                          : tabActive ===
                            constants.marginCompetition.LEADER_BOARD
                          ? 'Leaderboard'
                          : '',
                    });
                  }}
                >
                  <span className="icon iRefresh"></span>
                  <span className="text--light3">
                    {t('txt-label-refresh-data')}
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className="card-panel-2-body ">
            {tabActive == constants.marginCompetition.GENERAL_INTRO_TAB && (
              <GeneralIntro setTabActive={setTabActive} />
            )}
            {tabActive == constants.marginCompetition.RULE_AND_AWARD && (
              <RuleAndAwards />
            )}
            {tabActive == constants.marginCompetition.PERFORMANCE && (
              <Performance refresh={refresh} setTabActive={setTabActive} />
            )}
            {tabActive == constants.marginCompetition.LEADER_BOARD && (
              <LeaderBoard refresh={refresh} />
            )}
            {tabActive == constants.marginCompetition.QA && <QA />}
          </div>
        </div>
      </div>
    </div>
  );
});

export default MarginCompetiton;
