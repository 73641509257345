import React, { useContext, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import { ReactReduxContext, useDispatch, useSelector } from 'react-redux';

const ConfirmEditBuyingDate = withNamespaces('translations')(
  ({
    t,
    showModal,
    setShowModal,
    clearChange,
    onConfirmSwitchPack,
    packEditing,
  }) => {
    const theme = useSelector((state) => state.client?.currentTheme);
    const handleClose = () => {
      setShowModal(false);
      clearChange();
    };

    function handleChangeDate() {
      onConfirmSwitchPack();
    }

    return (
      <Modal
        show={showModal}
        onHide={handleClose}
        onEscapeKeyDown={handleClose}
        dialogClassName="wts-modal"
        backdropClassName="wts-modal-backdrop pinefolio-order-confirm-backdrop z-index--lv3"
        contentClassName={`${
          theme.name || 'dark'
        } wts-modal-content custom-modal ${'wts-modal-content--sm'}`}
        centered
        style={{ zIndex: 11113 }}
      >
        <div className="form-withdraw modal-form depositToPortfolio-modal">
          <div className="modal-body popup-body d-flex">
            <div className="modal-body__content">
              <span className="modal-body__description text--light3 d-flex justify-content-center align-items-center">
                {t('pinefolio.plan.noticeChangePack', {
                  packEditing: packEditing,
                })}
              </span>
            </div>
            <div className="modal-body__content">
              <span className="modal-body__description text--light3 d-flex justify-content-center align-items-center">
                <span className="fz_13 fw-500 text--light"></span>
              </span>
            </div>
          </div>
          <div className="modal--footer">
            <div
              className="btn btn--primary w-50"
              onClick={() => handleChangeDate()}
            >
              {t('lbl-finish')}
            </div>
            <div className="btn btn--cancel w-50" onClick={() => handleClose()}>
              {t('txt-cancel')}
            </div>
          </div>
        </div>
      </Modal>
    );
  }
);

export default ConfirmEditBuyingDate;
