import React, { useEffect } from 'react';
import { withNamespaces } from 'react-i18next';
import PerfectScrollBar from 'react-perfect-scrollbar';
import { useDispatch, useSelector } from 'react-redux';
import { getCptBoardInfoRequest } from '../actions';
import { v4 as uuidv4 } from 'uuid';
import { numberFormat } from '../../../util';
import { Mixpanel } from '../../../lib/mixpanel';

const RuleAndAwards = withNamespaces('translations')(({ t }) => {
  const dispatch = useDispatch();
  const { appsetting, setting } = useSelector((state) => state.client);
  const lang = setting.lang || 'vi';
  const { cptBoardInfo } = useSelector((state) => state.marginCompetition);

  function getCptBoardInfo() {
    const uuid = uuidv4();
    const params = {
      group: 'CORE',
      cmd: 'getCptBoardInfo',
      rqId: uuid,
      channel: 'WTS',
      data: {
        langTp: lang,
      },
    };

    dispatch(getCptBoardInfoRequest(params));
  }

  function handleSeeMore(key) {
    if (appsetting && appsetting.length > 0) {
      const competitionInfor = appsetting?.find(
        (item) => item.key == 'COMPETITION_INFOR'
      )?.values;
      const links =
        competitionInfor &&
        JSON.parse(competitionInfor)?.find((item) => item.key == 'link').value;
      const link = links?.find((item) => item.key == key)?.value;
      if (link) {
        window.open(link);
      }
    }
  }

  useEffect(() => {
    getCptBoardInfo();
  }, [lang]);

  useEffect(() => {
    getCptBoardInfo();
  }, []);
  return (
    <div className="layout-page__body layout__body">
      <PerfectScrollBar>
        <div className="rule-award d-flex flex-column align-items-center gap-24">
          <div className="competing-rule d-flex flex-column align-items-center gap-24 w-100">
            <div className="text--light fz_24 lh-36 fw-500 ">
              {t('marginCompetition.ruleAndAwards.competingRule')}
            </div>
            <div className="d-flex flex-column align-items-start gap-24 w-50">
              <div className="fz_13 lh-20">
                <span className="fw-500 text--light">
                  {`1. ${t(
                    'marginCompetition.ruleAndAwards.competitionAccountRule.title'
                  )}`}
                </span>
                <ul>
                  <li>
                    {`${t(
                      'marginCompetition.ruleAndAwards.competitionAccountRule.rule1'
                    )}`}
                  </li>
                  <li>
                    {`${t(
                      'marginCompetition.ruleAndAwards.competitionAccountRule.rule2'
                    )}`}
                  </li>
                </ul>
              </div>
              <div className="fz_13 lh-20">
                <span className="fw-500 text--light">
                  {`2. ${t(
                    'marginCompetition.ruleAndAwards.conditionsForConsideration.title'
                  )}`}
                </span>
                <ul>
                  <li>
                    {`${t(
                      'marginCompetition.ruleAndAwards.conditionsForConsideration.rule1'
                    )}`}
                  </li>
                  <li>
                    {`${t(
                      'marginCompetition.ruleAndAwards.conditionsForConsideration.rule2'
                    )}`}
                  </li>
                  <li>
                    {`${t(
                      'marginCompetition.ruleAndAwards.conditionsForConsideration.rule3'
                    )}`}
                  </li>
                </ul>
              </div>
              <div className="fz_13 lh-20">
                <span className="fw-500 text--light">
                  {`3. ${t(
                    'marginCompetition.ruleAndAwards.tableDivision.title'
                  )}`}
                </span>
                <div className="ml-3 pl-1">
                  {`${t(
                    'marginCompetition.ruleAndAwards.tableDivision.rule1'
                  )}`}
                </div>
                <ul>
                  <li>
                    {`${t(
                      'marginCompetition.ruleAndAwards.tableDivision.carpGroup'
                    )}`}
                  </li>
                  <li>
                    {`${t(
                      'marginCompetition.ruleAndAwards.tableDivision.koiGroup'
                    )}`}
                  </li>
                  <li>
                    {`${t(
                      'marginCompetition.ruleAndAwards.tableDivision.whaleGroup'
                    )}`}
                  </li>
                </ul>
              </div>
              <div className="fz_13 lh-20">
                <span className="fw-500 text--light">
                  {`4. ${t(
                    'marginCompetition.ruleAndAwards.ruleForCompetitionSecurities.title'
                  )}`}
                </span>
                <ul>
                  <li>
                    {`${t(
                      'marginCompetition.ruleAndAwards.ruleForCompetitionSecurities.rule1'
                    )}`}
                  </li>
                  <li>
                    {`${t(
                      'marginCompetition.ruleAndAwards.ruleForCompetitionSecurities.rule2'
                    )}`}
                  </li>
                </ul>
              </div>
              <div className="fz_13 lh-20">
                <span className="fw-500 text--light">
                  {`5. ${t(
                    'marginCompetition.ruleAndAwards.actsOfViolatingTheRule.title'
                  )}`}
                </span>
                <ul>
                  <li>
                    {`${t(
                      'marginCompetition.ruleAndAwards.actsOfViolatingTheRule.rule1'
                    )}`}
                  </li>
                  <li>
                    {`${t(
                      'marginCompetition.ruleAndAwards.actsOfViolatingTheRule.rule2'
                    )}`}
                  </li>
                  <li>
                    {`${t(
                      'marginCompetition.ruleAndAwards.actsOfViolatingTheRule.rule3'
                    )}`}
                  </li>
                </ul>
              </div>
              <div className="fz_13 lh-20">
                <span className="fw-500 text--light">
                  {`6. ${t(
                    'marginCompetition.ruleAndAwards.caculationFomula.title'
                  )}`}
                </span>
                <ul>
                  <li>
                    {`${t(
                      'marginCompetition.ruleAndAwards.caculationFomula.fomula'
                    )}`}
                  </li>
                </ul>
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <div
                className="btn btn--cancel"
                onClick={() => handleSeeMore('ra1')}
              >
                {t('marginCompetition.ruleAndAwards.seeMore')}
              </div>
            </div>
          </div>
          <div className="award">
            <div className="text-title text-center">
              {t('marginCompetition.ruleAndAwards.award.title')}
            </div>
            <div className="super-prize">
              <span className="icon iPrize2"></span>
              <div className="d-flex flex-column align-items-center justify-content-center">
                <span className="fw-500 fz_13 lh-20 text--light3">
                  {t('marginCompetition.ruleAndAwards.award.superPrize')}
                </span>
                <span className="fz_20 fw-500 lh-32">
                  {t('marginCompetition.ruleAndAwards.award.masterInvestor')}
                </span>
                <span className="fz_40 fw-500 lh-56 text--light">
                  500,000,000 VND
                </span>
              </div>
              <span className="icon iPrize2"></span>
            </div>
            <div className="groups-prize">
              {cptBoardInfo &&
                cptBoardInfo.length > 0 &&
                cptBoardInfo.map((item) => (
                  <div
                    className={`group-prize ${
                      item.brdCode == 'BRD01'
                        ? 'whale-group'
                        : item.brdCode == 'BRD02'
                        ? 'koi-group'
                        : 'carp-group'
                    }`}
                  >
                    <div className="group-infor">
                      <span className="d-flex icon">
                        <span
                          style={{ backgroundImage: `url(${item.imgUrl})` }}
                        ></span>
                      </span>
                      <span className="groupName">{item.brdNm}</span>
                      <span>{`NAV ≥ ${
                        item.frNav < 1000000000
                          ? numberFormat(item.frNav / 1000000, 0, '0') +
                            ' ' +
                            t('marginCompetition.ruleAndAwards.award.million')
                          : numberFormat(item.frNav / 1000000000, 0, '0') +
                            ' ' +
                            t('marginCompetition.ruleAndAwards.award.billion')
                      } `}</span>
                    </div>
                    <div className="prize-infor">
                      <div className="d-flex flex-column justify-content-center align-items-center">
                        <span className="fw-500 fz_13 lh-20 text--light3">
                          {`${t('marginCompetition.ruleAndAwards.rank')} 1`}
                        </span>
                        <span className="fw-500 fz_24 lh-36 text--light">
                          {numberFormat(item.rank1, 0) + ' VND'}
                        </span>
                      </div>
                      <div className="d-flex flex-column justify-content-center align-items-center">
                        <span className="fw-500 fz_13 lh-20 text--light3">
                          {`${t('marginCompetition.ruleAndAwards.rank')} 2`}
                        </span>
                        <span className="fw-500 fz_24 lh-36 text--light">
                          {numberFormat(item.rank2, 0) + ' VND'}
                        </span>
                      </div>
                      <div className="d-flex flex-column justify-content-center align-items-center">
                        <span className="fw-500 fz_13 lh-20 text--light3">
                          {`${t('marginCompetition.ruleAndAwards.rank')} 3`}
                        </span>
                        <span className="fw-500 fz_24 lh-36 text--light">
                          {numberFormat(item.rank3, 0) + ' VND'}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <div className="other-endow w-50">
            <div className="text-title">{`${t(
              'marginCompetition.ruleAndAwards.otherGifts.title'
            )}`}</div>
            <div className="d-flex gap-24 w-100">
              <div className="endow-item w-50">
                <span className="w-65 text-center">
                  {`${t('marginCompetition.ruleAndAwards.otherGifts.gift1')}`}
                </span>
              </div>
              <div className="endow-item w-50">
                <span className="w-65 text-center">
                  {`${t('marginCompetition.ruleAndAwards.otherGifts.gift2')}`}
                </span>
              </div>
            </div>
            <div className="d-flex gap-24 w-100">
              <div className="endow-item w-100">
                <span className="w-75 text-center">
                  {`${t('marginCompetition.ruleAndAwards.otherGifts.gift3')}`}
                </span>
              </div>
              <div className="endow-item w-100">
                <span className="w-75 text-center">
                  {`${t('marginCompetition.ruleAndAwards.otherGifts.gift4')}`}
                </span>
              </div>
              <div className="endow-item w-100">
                <span className="w-65 text-center">
                  {`${t('marginCompetition.ruleAndAwards.otherGifts.gift5')}`}
                </span>
              </div>
            </div>
          </div>
          <div className="award-rule w-50">
            <div className="text-title text-center">{`${t(
              'marginCompetition.ruleAndAwards.awardRule.title'
            )}`}</div>
            <div className="d-flex flex-column">
              <ul>
                <li>
                  {`${t('marginCompetition.ruleAndAwards.awardRule.rule1')}`}
                </li>
                <li>
                  {`${t('marginCompetition.ruleAndAwards.awardRule.rule2')}`}
                </li>
              </ul>
              <span className="ml-3 pl-1">
                {`${t('marginCompetition.ruleAndAwards.awardRule.rule3')}`}
              </span>
            </div>
          </div>
          <div className="recept-award w-50 ">
            <div className="text-title text-center">{`${t(
              'marginCompetition.ruleAndAwards.receptAward.title'
            )}`}</div>
            <ul>
              <li>
                {`${t('marginCompetition.ruleAndAwards.receptAward.rule1')}`}
              </li>
              <li>
                {`${t('marginCompetition.ruleAndAwards.receptAward.rule2')}`}
              </li>
              <li>
                {`${t('marginCompetition.ruleAndAwards.receptAward.rule3')}`}
              </li>
              <li>
                {`${t('marginCompetition.ruleAndAwards.receptAward.rule4')}`}
              </li>
            </ul>
            <div className="d-flex justify-content-center">
              <div
                className="btn btn--cancel"
                onClick={() => {
                  handleSeeMore('ra2');
                }}
              >{`${t(
                'marginCompetition.ruleAndAwards.seeMoreRuleDetail'
              )}`}</div>
            </div>
          </div>
        </div>
      </PerfectScrollBar>
    </div>
  );
});

export default RuleAndAwards;
