import {
  AVAIL_STOCK_SOURCE_REQUESTING,
  AVAIL_STOCK_SOURCE_REQUEST_SUCCESS,
  AVAIL_STOCK_SOURCE_REQUEST_ERROR,
  AVAIL_STOCK_TARGET_REQUESTING,
  AVAIL_STOCK_TARGET_REQUEST_SUCCESS,
  AVAIL_STOCK_TARGET_REQUEST_ERROR,
  STOCK_EXCHANGE_REQUESTING,
  STOCK_EXCHANGE_REQUEST_SUCCESS,
  STOCK_EXCHANGE_REQUEST_ERROR,
  STOCK_EXCHANGE_HIS_REQUESTING,
  STOCK_EXCHANGE_HIS_REQUEST_SUCCESS,
  STOCK_EXCHANGE_HIS_REQUEST_ERROR,
  ADVANCE_REQUESTING,
  ADVANCE_REQUEST_SUCCESS,
  ADVANCE_REQUEST_ERROR,
  AVAIL_ADVANCE_REQUESTING,
  AVAIL_ADVANCE_REQUEST_SUCCESS,
  AVAIL_ADVANCE_REQUEST_ERROR,
  ADVANCE_LOAN_REQUESTING,
  ADVANCE_LOAN_REQUEST_SUCCESS,
  ADVANCE_LOAN_REQUEST_ERROR,
  ADVANCE_LIST_REQUESTING,
  ADVANCE_LIST_REQUEST_SUCCESS,
  ADVANCE_LIST_REQUEST_ERROR,
  CASH_TRANSFER_REQUESTING,
  CASH_TRANSFER_REQUEST_SUCCESS,
  CASH_TRANSFER_REQUEST_ERROR,
  HIS_TRANSFER_REQUESTING,
  HIS_TRANSFER_REQUEST_SUCCESS,
  HIS_TRANSFER_REQUEST_ERROR,
  BALANCE_SOURCE_REQUESTING,
  BALANCE_SOURCE_REQUEST_SUCCESS,
  BALANCE_SOURCE_REQUEST_ERROR,
  BALANCE_TARGET_REQUESTING,
  BALANCE_TARGET_REQUEST_SUCCESS,
  BALANCE_TARGET_REQUEST_ERROR,
  DEBT_REQUESTING,
  DEBT_REQUEST_SUCCESS,
  DEBT_REQUEST_ERROR,
  REPAY_LOAN_REQUEST_ERROR,
  REPAY_LOAN_REQUEST_SUCCESS,
  REPAY_LOAN_REQUESTING,
  DEBT_HIS_REQUEST_ERROR,
  DEBT_HIS_REQUEST_SUCCESS,
  DEBT_HIS_REQUESTING,
  ADVANCE_MATCH_REQUESTING,
  ADVANCE_MATCH_REQUEST_SUCCESS,
  ADVANCE_MATCH_REQUEST_ERROR,
  ADVANCE_FEE_REQUESTING,
  ADVANCE_FEE_REQUEST_SUCCESS,
  ADVANCE_FEE_REQUEST_ERROR,
  BANK_ACCOUNT_REQUESTING,
  BANK_ACCOUNT_REQUEST_SUCCESS,
  BANK_ACCOUNT_REQUEST_ERROR,
  CASH_OUT_REQUESTING,
  CASH_OUT_REQUEST_SUCCESS,
  CASH_OUT_REQUEST_ERROR,
  BANK_LIST_REQUESTING,
  BANK_LIST_REQUEST_SUCCESS,
  BANK_LIST_REQUEST_ERROR,
  CASH_IN_BANK_REQUESTING,
  CASH_IN_BANK_REQUEST_SUCCESS,
  CASH_IN_BANK_REQUEST_ERROR,
  RIGHT_LIST_REQUESTING,
  RIGHT_LIST_REQUEST_SUCCESS,
  RIGHT_LIST_REQUEST_ERROR,
  HOLD_BANK_REQUESTING,
  HOLD_BANK_REQUEST_ERROR,
  HOLD_BANK_REQUEST_SUCCESS,
  HOLD_LIST_REQUESTING,
  HOLD_LIST_REQUEST_ERROR,
  HOLD_LIST_REQUEST_SUCCESS,
  DEBT_HIS2_REQUESTING,
  DEBT_HIS2_REQUEST_ERROR,
  DEBT_HIS2_REQUEST_SUCCESS,
  MARGIN_LIST_REQUESTING,
  MARGIN_LIST_REQUEST_ERROR,
  MARGIN_LIST_REQUEST_SUCCESS,
  GAIN_LOSS_RESET,
  GAIN_LOSS_REQUESTING,
  GAIN_LOSS_REQUEST_SUCCESS,
  GAIN_LOSS_REQUEST_ERROR,
  BANK_INFOR_REQUESTING,
  BANK_INFOR_REQUEST_ERROR,
  BANK_INFOR_REQUEST_SUCCESS,
  CASH_OUT_REQUEST_RESET,
  DEBT_HIS_EXTEND_REQUESTING,
  DEBT_HIS_EXTEND_REQUEST_SUCCESS,
  DEBT_HIS_EXTEND_REQUEST_ERROR,
  DEBT_EXTEND_REQUESTING,
  DEBT_EXTEND_REQUEST_SUCCESS,
  DEBT_EXTEND_REQUEST_ERROR,
  DEBT_EXTEND_REQUEST_RESET,
  STOCK_EXCHANGE_REQUESTING_RESET,
  GET_LIST_BANK_VA_REQUEST,
  GET_LIST_BANK_VA_SUCCESS,
  GET_LIST_BANK_VA_ERROR,
} from './constants';

const initialState = {
  stockSource: null,
  stockTarget: null,
  stockExchange: null,
  exHis: [],

  advance: null,
  availAdvance: null,
  advanceLoan: null,
  advanceList: [],
  advanceMatch: [],
  advanceFee: [],

  sourceBalance: null,
  targetBalance: null,
  cashTransfer: null,
  cashOutRequesting: false,
  cashOutRequestSuccess: false,
  cashOut: null,
  hisTransfer: [],
  bankAcc: null,

  debt: null,
  repayLoan: null,
  hisDebt: [],
  hisDebt2: null,
  hisExtend: [],

  extendRequesting: false,
  extendRequestSuccess: false,
  extendRequestError: [],

  rightList: null,
  marginList: null,

  bankList: null,
  bankInfor: [],

  cashInBank: null,
  holdBank: null,
  holdList: null,
  gainLossList: null,

  listBankVA: [],
  listBankVARequesting: false,
  listBankVASuccess: false,
  listBankVAError: [],
};

const reducer = function cashReducer(state = initialState, action) {
  switch (action.type) {
    case 'CLIENT_UNSET':
    case 'INVALID_SESSION':
      return initialState;

    case AVAIL_STOCK_SOURCE_REQUESTING:
    case AVAIL_STOCK_SOURCE_REQUEST_ERROR:
      return {
        ...state,
        stockSource: null,
      };

    case AVAIL_STOCK_SOURCE_REQUEST_SUCCESS:
      return {
        ...state,
        stockSource: action.resData,
      };

    case AVAIL_STOCK_TARGET_REQUESTING:
    case AVAIL_STOCK_TARGET_REQUEST_ERROR:
      return {
        ...state,
        stockTarget: null,
      };

    case AVAIL_STOCK_TARGET_REQUEST_SUCCESS:
      return {
        ...state,
        stockTarget: action.resData,
      };

    case STOCK_EXCHANGE_REQUESTING:
    case STOCK_EXCHANGE_REQUEST_ERROR:
    case STOCK_EXCHANGE_REQUESTING_RESET:
      return {
        ...state,
        stockExchange: null,
      };

    case STOCK_EXCHANGE_REQUEST_SUCCESS:
      return {
        ...state,
        stockExchange: action.resData,
      };

    case STOCK_EXCHANGE_HIS_REQUESTING:
    case STOCK_EXCHANGE_HIS_REQUEST_ERROR:
      return {
        ...state,
        exHis: null,
      };

    case STOCK_EXCHANGE_HIS_REQUEST_SUCCESS:
      return {
        ...state,
        exHis: action.resData,
      };

    case ADVANCE_REQUESTING:
    case ADVANCE_REQUEST_ERROR:
      return {
        ...state,
        advance: [],
      };

    case ADVANCE_REQUEST_SUCCESS:
      return {
        ...state,
        advance: action.resData,
      };

    case AVAIL_ADVANCE_REQUESTING:
    case AVAIL_ADVANCE_REQUEST_ERROR:
      return {
        ...state,
        availAdvance: [],
      };

    case AVAIL_ADVANCE_REQUEST_SUCCESS:
      return {
        ...state,
        availAdvance: action.resData,
      };

    case ADVANCE_LOAN_REQUESTING:
    case ADVANCE_LOAN_REQUEST_ERROR:
      return {
        ...state,
        advanceLoan: [],
      };

    case ADVANCE_LOAN_REQUEST_SUCCESS:
      return {
        ...state,
        advanceLoan: action.resData,
      };

    case ADVANCE_LIST_REQUESTING:
    case ADVANCE_LIST_REQUEST_ERROR:
      return {
        ...state,
        advanceList: [],
      };

    case ADVANCE_LIST_REQUEST_SUCCESS:
      return {
        ...state,
        advanceList: action.resData,
      };

    case ADVANCE_MATCH_REQUESTING:
    case ADVANCE_MATCH_REQUEST_ERROR:
      return {
        ...state,
        advanceMatch: [],
      };

    case ADVANCE_MATCH_REQUEST_SUCCESS:
      return {
        ...state,
        advanceMatch: action.resData,
      };

    case ADVANCE_FEE_REQUESTING:
    case ADVANCE_FEE_REQUEST_ERROR:
      return {
        ...state,
        advanceFee: [],
      };

    case ADVANCE_FEE_REQUEST_SUCCESS:
      return {
        ...state,
        advanceFee: action.resData,
      };

    case BALANCE_SOURCE_REQUESTING:
    case BALANCE_SOURCE_REQUEST_ERROR:
      return {
        ...state,
        sourceBalance: null,
      };

    case BALANCE_SOURCE_REQUEST_SUCCESS:
      return {
        ...state,
        sourceBalance: action.resData,
      };

    case BALANCE_TARGET_REQUESTING:
    case BALANCE_TARGET_REQUEST_ERROR:
      return {
        ...state,
        targetBalance: null,
      };

    case BALANCE_TARGET_REQUEST_SUCCESS:
      return {
        ...state,
        targetBalance: action.resData,
      };

    case CASH_TRANSFER_REQUESTING:
    case CASH_TRANSFER_REQUEST_ERROR:
      return {
        ...state,
        cashTransfer: null,
      };

    case CASH_TRANSFER_REQUEST_SUCCESS:
      return {
        ...state,
        cashTransfer: action.resData,
      };

    case CASH_OUT_REQUESTING:
      return {
        ...state,
        cashOut: null,
        cashOutRequesting: true,
        cashOutRequestSuccess: false,
      };

    case CASH_OUT_REQUEST_ERROR:
      return {
        ...state,
        cashOut: null,
        cashOutRequesting: false,
        cashOutRequestSuccess: false,
      };

    case CASH_OUT_REQUEST_SUCCESS:
      return {
        ...state,
        cashOut: action.resData,
        cashOutRequesting: false,
        cashOutRequestSuccess: true,
      };

    case CASH_OUT_REQUEST_RESET:
      return {
        ...state,
        cashOut: null,
        cashOutRequesting: false,
        cashOutRequestSuccess: false,
      };

    case HIS_TRANSFER_REQUESTING:
    case HIS_TRANSFER_REQUEST_ERROR:
      return {
        ...state,
        hisTransfer: null,
      };

    case HIS_TRANSFER_REQUEST_SUCCESS:
      return {
        ...state,
        hisTransfer: action.resData,
      };

    case BANK_ACCOUNT_REQUESTING:
    case BANK_ACCOUNT_REQUEST_ERROR:
      return {
        ...state,
        bankAcc: null,
      };

    case BANK_ACCOUNT_REQUEST_SUCCESS:
      return {
        ...state,
        bankAcc: action.resData,
      };

    case DEBT_REQUESTING:
    case DEBT_REQUEST_ERROR:
      return {
        ...state,
        debt: null,
      };

    case DEBT_REQUEST_SUCCESS:
      return {
        ...state,
        debt: action.resData,
      };

    case REPAY_LOAN_REQUESTING:
    case REPAY_LOAN_REQUEST_ERROR:
      return {
        ...state,
        repayLoan: null,
      };

    case REPAY_LOAN_REQUEST_SUCCESS:
      return {
        ...state,
        repayLoan: action.resData,
      };

    case DEBT_HIS_REQUESTING:
    case DEBT_HIS_REQUEST_ERROR:
      return {
        ...state,
        hisDebt: null,
      };

    case DEBT_HIS_REQUEST_SUCCESS:
      return {
        ...state,
        hisDebt: action.resData,
      };

    case DEBT_HIS2_REQUESTING:
    case DEBT_HIS2_REQUEST_ERROR:
      return {
        ...state,
        hisDebt2: null,
      };

    case DEBT_HIS2_REQUEST_SUCCESS:
      return {
        ...state,
        hisDebt2: action.resData,
      };

    case DEBT_HIS_EXTEND_REQUESTING:
    case DEBT_HIS_EXTEND_REQUEST_ERROR:
      return {
        ...state,
        hisExtend: [],
      };

    case DEBT_HIS_EXTEND_REQUEST_SUCCESS: {
      return {
        ...state,
        hisExtend: action.resData,
      };
    }
    case DEBT_EXTEND_REQUESTING: {
      return {
        ...state,
        extendRequesting: true,
        extendRequestSuccess: false,
        extendRequestError: [],
      };
    }

    case DEBT_EXTEND_REQUEST_SUCCESS: {
      return {
        ...state,
        extendRequesting: false,
        extendRequestSuccess: true,
        extendRequestError: [],
      };
    }

    case DEBT_EXTEND_REQUEST_ERROR: {
      return {
        ...state,
        extendRequesting: false,
        extendRequestSuccess: false,
        extendRequestError: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };
    }

    case DEBT_EXTEND_REQUEST_RESET: {
      return {
        ...state,
        extendRequesting: false,
        extendRequestSuccess: false,
        extendRequestError: [],
      };
    }

    case BANK_LIST_REQUESTING:
    case BANK_LIST_REQUEST_ERROR:
      return {
        ...state,
        bankList: null,
      };

    case BANK_LIST_REQUEST_SUCCESS:
      return {
        ...state,
        bankList: action.resData,
      };

    case BANK_INFOR_REQUESTING:
    case BANK_INFOR_REQUEST_ERROR:
      return {
        ...state,
        bankInfor: [],
      };

    case BANK_INFOR_REQUEST_SUCCESS:
      return {
        ...state,
        bankInfor: action.resData,
      };

    case CASH_IN_BANK_REQUESTING:
    case CASH_IN_BANK_REQUEST_ERROR:
      return {
        ...state,
        cashInBank: null,
      };

    case CASH_IN_BANK_REQUEST_SUCCESS:
      return {
        ...state,
        cashInBank: action.resData,
      };

    case HOLD_BANK_REQUESTING:
    case HOLD_BANK_REQUEST_ERROR:
      return {
        ...state,
        holdBank: null,
      };

    case HOLD_BANK_REQUEST_SUCCESS:
      return {
        ...state,
        holdBank: action.resData,
      };

    case HOLD_LIST_REQUESTING:
    case HOLD_LIST_REQUEST_ERROR:
      return {
        ...state,
        holdList: null,
      };

    case HOLD_LIST_REQUEST_SUCCESS:
      return {
        ...state,
        holdList: action.resData,
      };

    case RIGHT_LIST_REQUESTING:
    case RIGHT_LIST_REQUEST_ERROR:
      return {
        ...state,
        rightList: null,
      };

    case RIGHT_LIST_REQUEST_SUCCESS:
      return {
        ...state,
        rightList: action.resData,
      };

    case MARGIN_LIST_REQUESTING:
    case MARGIN_LIST_REQUEST_ERROR:
      return {
        ...state,
        marginList: null,
      };

    case MARGIN_LIST_REQUEST_SUCCESS:
      return {
        ...state,
        marginList: action.resData,
      };

    case GAIN_LOSS_RESET:
    case GAIN_LOSS_REQUESTING:
    case GAIN_LOSS_REQUEST_ERROR:
      return {
        ...state,
        gainLossList: null,
      };

    case GAIN_LOSS_REQUEST_SUCCESS:
      return {
        ...state,
        gainLossList: action.resData,
      };
    case GET_LIST_BANK_VA_REQUEST:
      return {
        ...state,
        listBankVA: [],
        listBankVARequesting: true,
        listBankVASuccess: false,
        listBankVAError: [],
      };

    case GET_LIST_BANK_VA_SUCCESS:
      return {
        ...state,
        listBankVA: action.listBankVA,
        listBankVARequesting: false,
        listBankVASuccess: true,
        listBankVAError: [],
      };

    case GET_LIST_BANK_VA_ERROR:
      return {
        ...state,
        listBankVA: [],
        listBankVARequesting: false,
        listBankVASuccess: false,
        listBankVAError: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };

    default:
      return state;
  }
};

export default reducer;
