import { color } from 'highcharts';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import ReactSelect from 'react-select';
import DatePicker from '../../../../components/datePicker2';
import {
  getAllDaysInMonth,
  getListMonth,
  INVESTMENT_PLAN_STATUS,
  mapPfOrderStat,
  numberFormat,
  numberToDayOfWeek,
  _diff2Date,
  _formatDate,
  _formatDate2,
  _convertformatDate2,
  mapDepoTp,
  mapPlanTp,
  mapStatus,
  useDebounce,
  LIST_BOND_DEAL_STATUS,
} from '../../../../util';
import { withNamespaces } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import {
  getPFByCode,
  getPfTdListRequest,
  getPinefolioInvestmentPlanHistoryRequest,
  getPinefolioInvestmentPlanRequest,
  handleEditPlanMonitor,
  setPFBondOrder,
} from '../../action';
import { bondConstants, constants } from '../../../../util/constant';
import ScrollTable from '../../../../components/ScrollTable';
import moment from 'moment';
import { setAuth, setLogin } from '../../../client/actions';
import PerfectScrollBar from 'react-perfect-scrollbar';
import { useHistory } from 'react-router';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import BondList from '../bond/bondList';
import BondDemo from '../../../bond/components/Order/bondDemo';
import BondDetail from '../../../bond/components/bondDetail';
import {
  getBondDealListRequest,
  getBondDetail,
  getBondOrderListRequest,
  getBondProducts,
  setBondDetailModalRequest,
  setBondProductDetail,
} from '../../../bond/action';
import ViewPlanDetail from './component/viewPlanDetail';
import RebalancePortfolioModal from '../portfolio/rebalancePortfolioModal';
import { LIST_BOND_ORDER_STATUS } from '../../../../util';
import { getBondListTpType } from '../../../bond/utils';

const PlanManagement = withNamespaces('translations')(({ t }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { token, auth } = useSelector((state) => state.client);
  const listAccounts = token?.custInfo.normal;
  const { pinefolioInvestmentPlan, planData } = useSelector(
    (state) => state.pinefolio
  );
  const account = useSelector((state) => state.socket.defaultAccount);
  const { bondDetailModalType } = useSelector((state) => state.bond);
  const investmentPlanStatusOpt = [
    { value: '', label: 'txt-all' },
    ...INVESTMENT_PLAN_STATUS,
  ];
  const [viewOption, setViewOption] = useState(1);
  const showModal = useSelector((state) => state.pinefolio.showEditPlanModal);
  const [tabActive, setTabActive] = useState(
    constants.pinefolio.planManagement.BOND_ORDER_LIST_TAB
  );
  const [investmentPlanStatus, setInvestmentPlanStatus] = useState(
    investmentPlanStatusOpt[0]
  );
  const currentDate = new Date();
  const [fromDate, setFromDate] = useState(
    new Date(currentDate.setDate(currentDate.getDate() - 90))
  );
  const [toDate, setToDate] = useState(new Date());
  const [planId, setPlanId] = useState();
  const [orderListStatusSrcParams, setOrderListStatusSrcParams] = useState(
    LIST_BOND_ORDER_STATUS[0]
  );
  const [dealStat, setDealStat] = useState(LIST_BOND_DEAL_STATUS[0]);
  const [currentTimeFromView, setCurrentTimeFromView] = useState(new Date());
  const [modalSize, setModalSize] = useState('');
  const [rebalancePFCode, setRebalancePFCode] = useState(null);
  const [currentPlan, setCurrentPlan] = useState({});

  const listDayView = getAllDaysInMonth(
    currentTimeFromView.getMonth() + 1,
    currentTimeFromView.getFullYear()
  );

  const listMonthView = getListMonth(
    currentTimeFromView.getMonth() + 1,
    viewOption
  );

  const handleChangeMonth = (params) => {
    const _newDate = new Date(currentTimeFromView);
    _newDate.setMonth(currentTimeFromView.getMonth() + params);
    setCurrentTimeFromView(_newDate);
  };

  const _nextNMonth = (nMonth) => {
    const _dateTo = new Date(currentTimeFromView);
    _dateTo.setMonth(currentTimeFromView.getMonth() + nMonth);
    return _dateTo;
  };

  const getPinefolioInvestmentPlan = () => {
    if (!token || (!auth && !auth.isVerified)) return;
    const uuid = uuidv4();
    const params = {
      group: 'CORE',
      user: token.user,
      session: token.session,
      cmd: 'getPFIPList',
      rqId: uuid,
      channel: 'WTS',
      data: {
        cif: token.cif,
        acntNo: account?.acntNo,
        subAcntNo: account?.subAcntNo,
        packNoYN: 'Y',
        planStat: '90',
      },
    };
    dispatch(getPinefolioInvestmentPlanRequest(params));
  };

  /**
   * start hanlde planpack proccess
   */

  const _formatDate = (str, slack = '-') => {
    if (!str || str.length !== 8) return str;

    const year = str.substring(0, 4);
    const month = str.substring(4, 6);
    const day = str.slice(-2);

    return year + slack + month + slack + day;
  };

  /**
   * Get fisrt day of timeframe
   */
  const _timeFrameStart = moment(
    new Date(
      currentTimeFromView.getFullYear(),
      currentTimeFromView.getMonth(),
      1
    )
  ).format('YYYYMMDD');
  /**
   * get last day of timeframe
   */
  const _timeFrameEnd = moment(
    new Date(
      currentTimeFromView.getFullYear(),
      currentTimeFromView.getMonth() + viewOption,
      0
    )
  ).format('YYYYMMDD');

  /**
   * Calculate durations between 2 days
   */
  const durationFrom2Dates = (_startDate, _endDate) => {
    const _date1 = new Date(_formatDate(_startDate));
    const _date2 = new Date(_formatDate(_endDate));

    var difference_In_Time = _date2.getTime() - _date1.getTime();
    var difference_In_Days = difference_In_Time / (1000 * 3600 * 24) + 1;
    return difference_In_Days;
  };

  /** Calculate width element */
  const calculateWidth = (_startDate, _endDate) => {
    let _divider = durationFrom2Dates(_timeFrameStart, _timeFrameEnd);
    if (!_startDate || !_endDate) {
      return 0;
    }
    /**start before startdate and end before endate*/
    if (_startDate < _timeFrameStart && _endDate <= _timeFrameEnd) {
      let timeDuration = durationFrom2Dates(_timeFrameStart, _endDate);
      let percent = (timeDuration / _divider) * 100;
      return `calc(${percent}% - ${Math.round((64 * percent) / 100)}px)`;
    }
    /**start before startdate and end after endate*/
    if (_startDate < _timeFrameStart && _endDate > _timeFrameEnd) {
      return `calc(100% - 64px)`;
    }
    /** start after startdate and end before endate */
    if (_startDate >= _timeFrameStart && _endDate <= _timeFrameEnd) {
      let timeDuration = durationFrom2Dates(_startDate, _endDate);
      let percent = (timeDuration / _divider) * 100;
      return `calc(${percent}% - ${Math.round((64 * percent) / 100)}px)`;
    }
    /**start after startdate and end after endate*/
    if (_startDate >= _timeFrameStart && _endDate > _timeFrameEnd) {
      let timeDuration = durationFrom2Dates(_startDate, _timeFrameEnd);
      let percent = (timeDuration / _divider) * 100;
      return `calc(${percent}% - ${Math.round((64 * percent) / 100)}px)`;
    }

    /**end before startdate or start after endate*/
    if (_startDate > _timeFrameEnd || _endDate < _timeFrameStart) {
      return 0;
    }
    return 0;
  };

  const packStyle = (_startDate, _endDate, packStatus = 1) => {
    if (!_startDate || !_endDate) {
      return 0;
    }

    /**start before startdate and end before endate*/
    let _className = '';
    if (packStatus == '1') {
      _className = _className.concat(' pack-progress--new ');
    }
    if (packStatus == '2') {
      _className = _className.concat(' pack-progress--executing ');
    }
    if (packStatus == '3') {
      _className = _className.concat(' pack-progress--completed ');
    }
    if (_startDate < _timeFrameStart && _endDate < _timeFrameEnd) {
      _className = _className.concat(' pack-progress--started ');
    }
    /**start before startdate and end after endate*/
    if (_startDate < _timeFrameStart && _endDate > _timeFrameEnd) {
      _className = _className.concat(' pack-progress--x ');
    }
    /** start after startdate and end before endate */
    if (
      _startDate > _timeFrameStart &&
      _endDate > _timeFrameEnd &&
      _startDate < _timeFrameEnd
    ) {
      _className = _className.concat(' pack-progress--unfinished ');
    }
    /**start after startdate and end after endate*/
    if (_startDate >= _timeFrameStart && _endDate <= _timeFrameEnd) {
      _className = _className.concat(' pack-progress--inprocess ');
    }
    if (_endDate < _timeFrameStart || _startDate > _timeFrameEnd) {
      return ' pack-progress--hidden';
    }
    return _className;
  };

  /** Calculate width element */
  const calculateMargin = (_startDate, _endDate) => {
    let _divider = durationFrom2Dates(_timeFrameStart, _timeFrameEnd);
    if (!_startDate || !_endDate) {
      return 0;
    }
    /**start after startdate*/
    if (_startDate > _timeFrameStart) {
      let timeDuration = durationFrom2Dates(_timeFrameStart, _startDate) - 1;
      let percent = (timeDuration / _divider) * 100;
      return `calc(${percent}% - ${Math.round((64 * percent) / 100) - 32}px)`;
    }
    return `32px`;
  };

  /**
   * Buffer
   */
  const _bufferTimeFrameEnd = moment(
    new Date(
      currentTimeFromView.getFullYear(),
      currentTimeFromView.getMonth() + viewOption,
      1
    )
  ).format('YYYYMMDD');

  const _bufferTimeFrameStart = moment(
    new Date(
      currentTimeFromView.getFullYear(),
      currentTimeFromView.getMonth(),
      0
    )
  ).format('YYYYMMDD');

  const calculateBuffer = (_startDate, _endDate, status = 1, indi) => {
    let _bufferStyle = '';
    if (!_startDate || !_endDate) {
      return 0;
    }

    if (indi == 0) {
      _bufferStyle = _bufferStyle.concat(' buffer-start ');
      if (_endDate == _bufferTimeFrameStart) {
        _bufferStyle = _bufferStyle.concat(
          ' d-block planProgress-item__buffer--started '
        );
        switch (status) {
          case '1':
            _bufferStyle = _bufferStyle.concat(
              ' planProgress-item__buffer--new '
            );
            break;
          case '2':
            _bufferStyle = _bufferStyle.concat(
              ' planProgress-item__buffer--executing '
            );
            break;
          case '3':
            _bufferStyle = _bufferStyle.concat(
              ' planProgress-item__buffer--completed '
            );
            break;
          default:
            break;
        }
      }
      if (
        _startDate <= _bufferTimeFrameStart &&
        _endDate > _bufferTimeFrameStart
      ) {
        _bufferStyle = _bufferStyle.concat(
          'd-block  planProgress-item__buffer--process '
        );
        switch (status) {
          case '1':
            _bufferStyle = _bufferStyle.concat(
              ' planProgress-item__buffer--new '
            );
            break;
          case '2':
            _bufferStyle = _bufferStyle.concat(
              ' planProgress-item__buffer--executing '
            );
            break;
          case '3':
            _bufferStyle = _bufferStyle.concat(
              ' planProgress-item__buffer--completed '
            );
            break;
          default:
            break;
        }
      }
    }
    if (indi == 1) {
      _bufferStyle = _bufferStyle.concat(' buffer-end ');
      if (_startDate == _bufferTimeFrameEnd) {
        _bufferStyle = _bufferStyle.concat(
          ' d-block  planProgress-item__buffer--unfinished'
        );
        switch (status) {
          case '1':
            _bufferStyle = _bufferStyle.concat(
              ' planProgress-item__buffer--new '
            );
            break;
          case '2':
            _bufferStyle = _bufferStyle.concat(
              ' planProgress-item__buffer--executing '
            );
            break;
          case '3':
            _bufferStyle = _bufferStyle.concat(
              ' planProgress-item__buffer--completed '
            );
            break;
          default:
            break;
        }
      }
      if (_endDate >= _bufferTimeFrameEnd && _startDate < _bufferTimeFrameEnd) {
        _bufferStyle = _bufferStyle.concat(
          ' d-block  planProgress-item__buffer--process'
        );
        switch (status) {
          case '1':
            _bufferStyle = _bufferStyle.concat(
              ' planProgress-item__buffer--new '
            );
            break;
          case '2':
            _bufferStyle = _bufferStyle.concat(
              ' planProgress-item__buffer--executing '
            );
            break;
          case '3':
            _bufferStyle = _bufferStyle.concat(
              ' planProgress-item__buffer--completed '
            );
            break;
          default:
            break;
        }
      }
    }

    return _bufferStyle;
  };

  /**
   * handle ended
   */

  const handleOpenEditPlan = (plan) => {
    const params = {
      showEditPlanModal: true,
      planData: plan,
    };
    dispatch(handleEditPlanMonitor(params));
  };

  function rebalanceHandle(pfCode) {
    dispatch(getPFByCode(pfCode));
    setRebalancePFCode(pfCode);
  }

  function handleClickPlan(plan) {
    if (token) {
      if (auth && auth.isVerified) {
        if (plan.rblStat == 1) {
          setCurrentPlan(plan);
          rebalanceHandle(plan.pfCode);
        } else {
          handleOpenEditPlan(plan);
        }
      } else {
        dispatch(setAuth());
      }
    } else {
      dispatch(setLogin());
    }
  }

  function handleCancelRebalance() {
    setRebalancePFCode(null);
    handleOpenEditPlan(currentPlan);
  }

  useEffect(() => {
    getPinefolioInvestmentPlan();
  }, []);

  useEffect(() => {
    getPinefolioInvestmentPlan();
  }, [account]);

  useEffect(() => {
    if (!token) {
      history.push('/');
    }
  }, [token]);

  useEffect(() => {
    if (bondDetailModalType == 0) setModalSize('wts-modal-content--sm');
    else setModalSize('wts-modal-content--xl2');
  }, [bondDetailModalType]);

  return (
    <>
      <div
        className="planManagement-page layout-page "
        style={{ height: 'calc(100vh - 160px)' }}
      >
        <div className="planProgress">
          <div className="planProgress__header">
            <div className="packInfo">
              <div className="packInfo-item">
                <span className="packInfo-item__name">
                  {t('pinefolio.plan.planRunning')}
                </span>
                <span className="packInfo-item__value d">
                  {pinefolioInvestmentPlan.totalCount}
                </span>
              </div>
              <div className="packInfo-item">
                <span className="packInfo-item__name">
                  {t('pinefolio.plan.deposited')}
                </span>
                <span className="packInfo-item__value i">
                  {numberFormat(pinefolioInvestmentPlan.ipCash, 0, '0')}
                </span>
              </div>
              <div className="packInfo-item">
                <span className="packInfo-item__name">
                  {t('pinefolio.plan.depositRemain')}
                </span>
                <span className="packInfo-item__value r">
                  {numberFormat(pinefolioInvestmentPlan.planAmt, 0, '0')}
                </span>
              </div>
            </div>
            <div className="planCalendar">
              <div className="planCalendar-viewPicker">
                <div className="planCalendar-viewPicker--left">
                  {(viewOption ==
                    constants.pinefolio.planManagement.VIEW_1_MONTH && (
                    <span className="fz_20 text--light">{`${
                      currentTimeFromView.getMonth() + 1
                    }/${currentTimeFromView.getFullYear()}`}</span>
                  )) || (
                    <span className="fz_20 text--light">{`${
                      currentTimeFromView.getMonth() + 1
                    }/${currentTimeFromView.getFullYear()} - ${
                      _nextNMonth(viewOption - 1).getMonth() + 1
                    }/${_nextNMonth(viewOption - 1).getFullYear()}`}</span>
                  )}
                </div>
                <div className="planCalendar-viewPicker--right">
                  <div
                    className={`planCalendar-viewPicker__tab ${
                      viewOption ==
                      constants.pinefolio.planManagement.VIEW_1_MONTH
                        ? 'active'
                        : ''
                    }`}
                    onClick={() =>
                      setViewOption(
                        constants.pinefolio.planManagement.VIEW_1_MONTH
                      )
                    }
                  >
                    <span>{`1 ${t('txt-label-month')}`}</span>
                  </div>
                  <div
                    className={`planCalendar-viewPicker__tab ${
                      viewOption ==
                      constants.pinefolio.planManagement.VIEW_3_MONTHS
                        ? 'active'
                        : ''
                    }`}
                    onClick={() =>
                      setViewOption(
                        constants.pinefolio.planManagement.VIEW_3_MONTHS
                      )
                    }
                  >
                    <span>{`3 ${t('txt-label-months')}`}</span>
                  </div>
                  <div
                    className={`planCalendar-viewPicker__tab ${
                      viewOption ==
                      constants.pinefolio.planManagement.VIEW_6_MONTHS
                        ? 'active'
                        : ''
                    }`}
                    onClick={() =>
                      setViewOption(
                        constants.pinefolio.planManagement.VIEW_6_MONTHS
                      )
                    }
                  >
                    <span>{`6 ${t('txt-label-months')}`}</span>
                  </div>
                  <div
                    className={`planCalendar-viewPicker__tab ${
                      viewOption ==
                      constants.pinefolio.planManagement.VIEW_9_MONTHS
                        ? 'active'
                        : ''
                    }`}
                    onClick={() =>
                      setViewOption(
                        constants.pinefolio.planManagement.VIEW_9_MONTHS
                      )
                    }
                  >
                    <span>{`9 ${t('txt-label-months')}`}</span>
                  </div>
                  <div
                    className={`planCalendar-viewPicker__tab ${
                      viewOption ==
                      constants.pinefolio.planManagement.VIEW_12_MONTHS
                        ? 'active'
                        : ''
                    }`}
                    onClick={() =>
                      setViewOption(
                        constants.pinefolio.planManagement.VIEW_12_MONTHS
                      )
                    }
                  >
                    <span>{`12 ${t('txt-label-months')}`}</span>
                  </div>
                </div>
              </div>
              <div className="planCalendar-timeFrame">
                <div className="btn">
                  <span
                    className="icon iPrevious"
                    onClick={() => handleChangeMonth(-1)}
                  ></span>
                </div>
                {viewOption ==
                  constants.pinefolio.planManagement.VIEW_1_MONTH && (
                  <div className="list-day">
                    {listDayView &&
                      listDayView.length > 0 &&
                      listDayView.map((item, index) => (
                        <div className="list-day__item" key={index}>
                          <div className="fw-500 fz_13 list-day__item--day text--gray">
                            <span>{t(numberToDayOfWeek(item.getDay()))}</span>
                          </div>
                          <div
                            className={`fw-500 fz_13 list-day__item--date text--light ${
                              new Date(new Date().setHours(0, 0, -1)) > item
                                ? 'disabled'
                                : new Date(new Date().setHours(0, 0, 0)) < item
                                ? ''
                                : 'active'
                            }`}
                          >
                            <span>{item.getDate()}</span>
                          </div>
                        </div>
                      ))}
                  </div>
                )}
                {viewOption !=
                  constants.pinefolio.planManagement.VIEW_1_MONTH && (
                  <div className="list-month">
                    {listMonthView &&
                      listMonthView.length > 0 &&
                      listMonthView.map((item) => (
                        <div
                          className={`list-month__item list-month__item--${viewOption}`}
                        >
                          {t(item)}
                        </div>
                      ))}
                  </div>
                )}
                <div className="btn">
                  <span
                    className="icon iNext"
                    onClick={() => handleChangeMonth(1)}
                  ></span>
                </div>
              </div>
            </div>
          </div>
          <PerfectScrollBar>
            <div className="planProgress__body">
              {pinefolioInvestmentPlan &&
                pinefolioInvestmentPlan.list &&
                pinefolioInvestmentPlan.list.length > 0 &&
                pinefolioInvestmentPlan.list.map(
                  (plan) =>
                    plan.status != 5 && (
                      <div
                        className="planProgress-item cursor-pointer"
                        onClick={() => handleClickPlan(plan)}
                      >
                        <div
                          className={`portfolio-name ${
                            plan.rblStat == 0 ? '' : 'portfolio-name--rebalance'
                          }`}
                        >
                          <span>{`${plan.pfCode} - ${plan.pfName}`}</span>
                          {plan.rblStat == 1 && (
                            <span className="icon iRefresh"></span>
                          )}
                        </div>
                        <div className="portfolio-progress">
                          {plan.packs &&
                            plan.packs.length > 0 &&
                            plan.packs.map(
                              (pack, index) =>
                                pack.status != 3 && (
                                  <>
                                    {
                                      <div
                                        className={`planProgress-item__buffer ${calculateBuffer(
                                          pack.stDt,
                                          pack.enDt,
                                          pack.status,
                                          0
                                        )}`}
                                      ></div>
                                    }
                                    <div
                                      className={`pack-progress ${packStyle(
                                        pack.stDt,
                                        pack.enDt,
                                        pack.status
                                      )}`}
                                      style={{
                                        width: calculateWidth(
                                          pack.stDt,
                                          pack.enDt
                                        ),
                                        left: calculateMargin(
                                          pack.stDt,
                                          pack.enDt
                                        ),
                                      }}
                                    >
                                      <span className="pack-name">
                                        {`${
                                          pack.packNo == 0
                                            ? t('pinefolio.plan.fullSet')
                                            : t('pinefolio.plan.pack') +
                                              '-' +
                                              pack.packNo
                                        } - ${t(
                                          mapPfOrderStat(pack.status).label
                                        )}`}
                                      </span>
                                    </div>
                                    {
                                      <div
                                        className={`planProgress-item__buffer ${calculateBuffer(
                                          pack.stDt,
                                          pack.enDt,
                                          pack.status,
                                          1
                                        )}`}
                                      ></div>
                                    }
                                  </>
                                )
                            )}
                        </div>
                      </div>
                    )
                )}
            </div>
          </PerfectScrollBar>
        </div>
        <div className="planHistory">
          <div className="planHistory__header">
            <div className="planHistory__header--left">
              <div
                className={`header-tab cursor-pointer ${
                  tabActive ==
                  constants.pinefolio.planManagement.BOND_ORDER_LIST_TAB
                    ? 'r'
                    : ''
                }`}
                onClick={() =>
                  setTabActive(
                    constants.pinefolio.planManagement.BOND_ORDER_LIST_TAB
                  )
                }
              >
                <span className="">
                  {t('pinefolio.plan.bondOrderList.name')}
                </span>
              </div>
              <div
                className={`header-tab cursor-pointer ${
                  tabActive ==
                  constants.pinefolio.planManagement.BOND_DEAL_LIST_TAB
                    ? 'r'
                    : ''
                }`}
                onClick={() =>
                  setTabActive(
                    constants.pinefolio.planManagement.BOND_DEAL_LIST_TAB
                  )
                }
              >
                <span className="">
                  {t('pinefolio.plan.bondDealList.name')}
                </span>
              </div>
              <div
                className={`header-tab cursor-pointer ${
                  tabActive ==
                  constants.pinefolio.planManagement.ACCUMULATED_TAB
                    ? 'r'
                    : ''
                }`}
                onClick={() =>
                  setTabActive(
                    constants.pinefolio.planManagement.ACCUMULATED_TAB
                  )
                }
              >
                <span className="">{t('pinefolio.plan.depositHistory')}</span>
              </div>
              <div
                className={`header-tab cursor-pointer ${
                  tabActive ==
                  constants.pinefolio.planManagement.PLAN_HISTORY_TAB
                    ? 'r'
                    : ''
                }`}
                onClick={() =>
                  setTabActive(
                    constants.pinefolio.planManagement.PLAN_HISTORY_TAB
                  )
                }
              >
                <span className="d-flex justify-content-center align-items-center">
                  <span>{t('pinefolio.plan.planHistory.name')}</span>
                  <OverlayTrigger
                    trigger={['hover', 'focus']}
                    placement="top"
                    className="portfolio-overlay"
                    overlay={
                      <Tooltip style={{ 'z-index': '99999' }}>
                        {t('pinefolio.plan.planHistory.explain')}
                      </Tooltip>
                    }
                  >
                    <span className="icon iInfor iTooltip ml-1 mr-1"></span>
                  </OverlayTrigger>
                </span>
              </div>
            </div>
            <div className="planHistory__header--right">
              {(tabActive ==
                constants.pinefolio.planManagement.BOND_ORDER_LIST_TAB ||
                tabActive ==
                  constants.pinefolio.planManagement.BOND_DEAL_LIST_TAB) && (
                <div class="layout__filter d-flex flex-row justify-content-end">
                  <div class="input-text-search d-flex align-items-center mr-1">
                    <input
                      placeholder={t('pinefolio.plan.planId')}
                      value={planId}
                      onChange={(e) => setPlanId(e.target.value)}
                    />
                    <span class="icon iSearch"></span>
                  </div>
                </div>
              )}
              {tabActive ==
                constants.pinefolio.planManagement.BOND_ORDER_LIST_TAB && (
                <div class="layout__filter d-flex flex-row justify-content-end">
                  <ReactSelect
                    defaultValue={orderListStatusSrcParams}
                    options={LIST_BOND_ORDER_STATUS}
                    className="filter-control-select  mr-2"
                    classNamePrefix="filter-control-select"
                    getOptionLabel={(option) => t(option.value)}
                    getOptionValue={(option) => option.key}
                    onChange={(value) => setOrderListStatusSrcParams(value)}
                    isSearchable={false}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                  />
                </div>
              )}
              {tabActive ==
                constants.pinefolio.planManagement.BOND_DEAL_LIST_TAB && (
                <div class="layout__filter d-flex flex-row justify-content-end">
                  <ReactSelect
                    defaultValue={dealStat}
                    options={LIST_BOND_DEAL_STATUS}
                    className="filter-control-select  mr-2"
                    classNamePrefix="filter-control-select"
                    getOptionLabel={(option) => t(option.value)}
                    getOptionValue={(option) => option.key}
                    onChange={(value) => setDealStat(value)}
                    isSearchable={false}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                  />
                </div>
              )}
              {tabActive ==
                constants.pinefolio.planManagement.PLAN_HISTORY_TAB && (
                <div class="layout__filter d-flex flex-row justify-content-end">
                  <ReactSelect
                    defaultValue={investmentPlanStatus}
                    options={investmentPlanStatusOpt}
                    className="filter-control-select  mr-2"
                    classNamePrefix="filter-control-select"
                    getOptionLabel={(option) => t(option.label)}
                    getOptionValue={(option) => option.value}
                    onChange={(value) => setInvestmentPlanStatus(value)}
                    isSearchable={false}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                  />
                </div>
              )}
              <div class="d-flex flex-row justify-content-end">
                <DatePicker
                  startDate={fromDate}
                  endDate={toDate}
                  callback={(from, to) => {
                    setFromDate(
                      new Date(_formatDate(_convertformatDate2(from)))
                    );
                    setToDate(new Date(_formatDate(_convertformatDate2(to))));
                  }}
                />
              </div>
            </div>
          </div>
          {tabActive == constants.pinefolio.planManagement.PLAN_HISTORY_TAB && (
            <div className="planHistory__body">
              <PlanInvestmentHistoryTab
                t={t}
                token={token}
                auth={auth}
                account={account}
                investmentPlanStatus={investmentPlanStatus}
                fromDate={fromDate}
                toDate={toDate}
              />
            </div>
          )}
          {tabActive == constants.pinefolio.planManagement.ACCUMULATED_TAB && (
            <div className="planHistory__body">
              <InterestRateTab
                t={t}
                token={token}
                auth={auth}
                account={account}
                investmentPlanStatus={investmentPlanStatus}
                fromDate={fromDate}
                toDate={toDate}
              />
            </div>
          )}
          {tabActive ==
            constants.pinefolio.planManagement.BOND_ORDER_LIST_TAB && (
            <div className="planHistory__body">
              <BondOrderListTab
                orderListStatusSrcParams={orderListStatusSrcParams}
                t={t}
                token={token}
                auth={auth}
                account={account}
                fromDate={fromDate}
                toDate={toDate}
                planId={planId}
              />
            </div>
          )}
          {tabActive ==
            constants.pinefolio.planManagement.BOND_DEAL_LIST_TAB && (
            <div className="planHistory__body">
              <BondDealListTab
                t={t}
                token={token}
                auth={auth}
                account={account}
                fromDate={fromDate}
                toDate={toDate}
                planId={planId}
                dealStat={dealStat}
              />
            </div>
          )}
        </div>
      </div>
      {showModal && (
        <ViewPlanDetail
          t={t}
          getPinefolioInvestmentPlan={getPinefolioInvestmentPlan}
        />
      )}
      {/* <DepositToPortfolio /> */}
      {planData && (
        <>
          <BondList />
          <BondDemo />
          <RebalancePortfolioModal
            show={rebalancePFCode != null}
            closeHandle={() => {
              handleCancelRebalance();
            }}
            pfCode={rebalancePFCode}
          />
          <BondDetail
            modalSize={modalSize}
            closeHandle={() => {
              dispatch(setBondDetailModalRequest(bondConstants.modals.CLOSE));
              dispatch(setPFBondOrder(null));
            }}
            resizeModal={setModalSize}
          />
        </>
      )}
    </>
  );
});

const PlanInvestmentHistoryTab = ({
  t,
  token,
  fromDate,
  toDate,
  account,
  investmentPlanStatus,
}) => {
  const dispatch = useDispatch();
  const pinefolioInvestmentPlanHis = useSelector(
    (state) => state?.pinefolio?.pinefolioInvestmentPlanHistory
  );
  const handleGetPinefolioInvestmentPlanHistoryRequest = () => {
    if (!token) return;
    const uuid = uuidv4();
    const params = {
      group: 'CORE',
      user: token.user,
      session: token.session,
      cmd: 'getPFIPListHist',
      rqId: uuid,
      channel: 'WTS',
      data: {
        cif: token.cif,
        acntNo: account?.acntNo,
        subAcntNo: account?.subAcntNo,
        planStat: investmentPlanStatus?.value,
        frDt: moment(fromDate).format('YYYYMMDD'),
        toDt: moment(toDate).format('YYYYMMDD'),
      },
    };
    dispatch(getPinefolioInvestmentPlanHistoryRequest(params));
  };

  useEffect(() => {
    handleGetPinefolioInvestmentPlanHistoryRequest();
  }, []);

  useEffect(() => {
    handleGetPinefolioInvestmentPlanHistoryRequest();
  }, [investmentPlanStatus, fromDate, toDate, account]);

  const columns = [
    {
      key: 'planId',
      text: t('pinefolio.plan.planId'),
      className: 'text-center position-relative text--light1 fw-500',
    },
    {
      key: 'pfCode',
      text: t('pinefolio.plan.portfolioCode'),
      className: 'text-center position-relative fw-500 text--light1',
    },
    {
      key: 'planTp',
      text: t('pinefolio.plan.planType'),
      className: 'text-left position-relative text--light1 fw-400',
      align: 'left',
      cell: (record) => {
        return t(mapPlanTp(record.planTp).label);
      },
    },
    {
      key: 'stDt',
      text: t('pinefolio.plan.depositStartDate'),
      className: 'text-center position-relative text--light3 fw-500',
      cell: (record) => {
        return _formatDate2(record.stDt);
      },
    },
    {
      key: 'enDt',
      text: (
        <span className="d-flex justify-content-center">
          <span> {t('pinefolio.plan.buyingOrderDate.name')}</span>
          <OverlayTrigger
            trigger={['hover', 'focus']}
            placement="top"
            className="portfolio-overlay"
            overlay={
              <Tooltip style={{ 'z-index': '99999' }}>
                {t('pinefolio.plan.buyingOrderDate.explain')}
              </Tooltip>
            }
          >
            <span className="icon iInfor iTooltip ml-1 mr-1"></span>
          </OverlayTrigger>
        </span>
      ),
      className: 'text-center position-relative text--light3 fw-500',
      cell: (record) => {
        return _formatDate2(record.enDt);
      },
    },
    {
      key: 'trgtAmt',
      text: t('pinefolio.plan.totalAmountOfPlan'),
      className: 'text-right position-relative text--light1 fw-400',
      align: 'right',
      cell: (record) => {
        return numberFormat(record.trgtAmt, 0, '0');
      },
    },
    {
      key: 'depoAmt',
      text: t('pinefolio.plan.deposited'),
      className: 'text-right position-relative text--light1 fw-400',
      align: 'right',
      cell: (record) => {
        return numberFormat(record.depoAmt, 0, '0');
      },
    },
    {
      key: 'remain',
      text: t('pinefolio.plan.remain'),
      className: 'text-right position-relative text--light1 fw-500',
      align: 'right',
      cell: (record) => {
        return numberFormat(record.trgtAmt - record.depoAmt, 0, '0');
      },
    },
    {
      key: 'stpDt',
      text: (
        <span className="d-flex justify-content-center">
          <span> {t('pinefolio.plan.stopPlanDate.name')}</span>
          <OverlayTrigger
            trigger={['hover', 'focus']}
            placement="top"
            className="portfolio-overlay"
            overlay={
              <Tooltip style={{ 'z-index': '99999' }}>
                {t('pinefolio.plan.stopPlanDate.explain')}
              </Tooltip>
            }
          >
            <span className="icon iInfor iTooltip ml-1 mr-1"></span>
          </OverlayTrigger>
        </span>
      ),

      className: 'text-center position-relative text--light3 fw-400',
      cell: (record) => {
        return _formatDate2(record.stpDt);
      },
    },
    {
      key: 'recvInt',
      text: (
        <span className="d-flex justify-content-center">
          <span> {t('pinefolio.plan.receptedInterest.name')}</span>
          <OverlayTrigger
            trigger={['hover', 'focus']}
            placement="top"
            className="portfolio-overlay"
            overlay={
              <Tooltip style={{ 'z-index': '99999' }}>
                {t('pinefolio.plan.receptedInterest.explain')}
              </Tooltip>
            }
          >
            <span className="icon iInfor iTooltip ml-1 mr-1"></span>
          </OverlayTrigger>
        </span>
      ),
      className: 'text-right position-relative text--light1 fw-500',
      align: 'right',
      cell: (record) => {
        return numberFormat(record.recvInt, 0, '0');
      },
    },
    {
      key: 'status',
      text: t('txt-status'),
      align: 'right',
      className: (record) => {
        return 'text-right ordStt_' + record.status;
      },
      cell: (record) => {
        return t(mapPfOrderStat(record.status).label);
      },
    },
  ];

  return (
    <>
      <ScrollTable columns={columns} source={pinefolioInvestmentPlanHis.list} />
    </>
  );
};
const InterestRateTab = ({
  t,
  token,
  fromDate,
  toDate,
  account,
  investmentPlanStatus,
}) => {
  const dispatch = useDispatch();
  const pfTdList = useSelector((state) => state?.pinefolio?.pfTdList);

  const handleGetPinefolioInvestmentPlanHistoryRequest = () => {
    if (!token) return;
    const uuid = uuidv4();
    const params = {
      group: 'CORE',
      user: token.user,
      session: token.session,
      cmd: 'getPFTDList',
      rqId: uuid,
      channel: 'WTS',
      data: {
        cif: token.cif,
        acntNo: account?.acntNo,
        subAcntNo: account?.subAcntNo,
        planStat: investmentPlanStatus?.value,
        packNo: '',
        frDt: moment(fromDate).format('YYYYMMDD'),
        toDt: moment(toDate).format('YYYYMMDD'),
        savStatus: '',
        langTp: '',
      },
    };
    dispatch(getPfTdListRequest(params));
  };

  useEffect(() => {
    handleGetPinefolioInvestmentPlanHistoryRequest();
  }, []);

  useEffect(() => {
    handleGetPinefolioInvestmentPlanHistoryRequest();
  }, [investmentPlanStatus, fromDate, toDate, account]);

  const columns = [
    {
      key: 'planID',
      text: t('pinefolio.plan.planId'),
      className: 'text-center position-relative text--light3',
    },
    {
      key: 'pfCode',
      text: t('pinefolio.plan.portfolioCode'),
      className: 'text-center position-relative fw-500 text--light',
    },

    {
      key: 'savStdt',
      text: t('pinefolio.plan.depositDate'),
      className: 'text-center position-relative text--light3 fw-400',
      cell: (record) => {
        return _formatDate2(record.savStdt);
      },
      align: 'center',
    },

    {
      key: 'planEndt',
      text: (
        <span className="d-flex justify-content-center">
          <span> {t('txt-label-end-date')}</span>
          <OverlayTrigger
            trigger={['hover', 'focus']}
            placement="top"
            className="portfolio-overlay"
            overlay={
              <Tooltip style={{ 'z-index': '99999' }}>
                {t('pinefolio.plan.endDateExplain')}
              </Tooltip>
            }
          >
            <span className="icon iInfor iTooltip ml-1 mr-1"></span>
          </OverlayTrigger>
        </span>
      ),
      className: 'text-center position-relative text--light3 fw-400',
      cell: (record) => {
        return _formatDate2(record.planEndt);
      },
      align: 'center',
    },

    {
      key: 'depoAmt',
      text: t('pinefolio.plan.depositAmount'),
      className: 'text-right position-relative text--light fw-500',
      cell: (record) => {
        return numberFormat(record.depoAmt, 0, '-');
      },
      align: 'right',
    },
    {
      key: 'savDay',
      text: (
        <span className="d-flex justify-content-end w-100">
          <span>{t('pinefolio.plan.dayOfInterestRate')}</span>
          <OverlayTrigger
            trigger={['hover', 'focus']}
            placement="top"
            className="portfolio-overlay"
            overlay={
              <Tooltip style={{ 'z-index': '99999' }}>
                {t('pinefolio.plan.dayOfInterestRateDesc')}
              </Tooltip>
            }
          >
            <span className="icon iInfor iTooltip ml-1 mr-1"></span>
          </OverlayTrigger>
        </span>
      ),
      className: 'text-right position-relative text--light fw-500',
      align: 'right',
      cell: (record) => {
        return _formatDate2(record.savDay);
      },
    },
    {
      key: 'expInterest',
      text: t('txt-label-interest-rate'),
      className: 'text-right position-relative text--light fw-400',
      align: 'right',
      cell: (record) => {
        if (record.savStatus == 1) {
          return numberFormat(record.expInterest, 2, '0') + '%';
        } else {
          return numberFormat(record.rtInterest, 2, '0') + '%';
        }
      },
    },
    {
      key: 'expIntAmt',
      text: (
        <span className="d-flex justify-content-end w-100">
          <span>{t('pinefolio.plan.estimateInterest')}</span>
          <OverlayTrigger
            trigger={['hover', 'focus']}
            placement="top"
            className="portfolio-overlay"
            overlay={
              <Tooltip style={{ 'z-index': '99999' }}>
                {t('pinefolio.plan.estimateInterestDesc')}
              </Tooltip>
            }
          >
            <span className="icon iInfor iTooltip ml-1 mr-1"></span>
          </OverlayTrigger>
        </span>
      ),
      className: 'text-right position-relative text--light fw-400',
      align: 'right',
      cell: (record) => {
        if (record.savStatus == 1) {
          return numberFormat(record.expIntAmt, 0, '0');
        } else {
          return numberFormat(record.rtIntAmt, 0, '0');
        }
      },
    },
    {
      key: 'rtIntAmt',

      text: (
        <span className="d-flex justify-content-end w-100">
          <span>{t('pinefolio.plan.receptedInterest.name')}</span>
          <OverlayTrigger
            trigger={['hover', 'focus']}
            placement="top"
            className="portfolio-overlay"
            overlay={
              <Tooltip style={{ 'z-index': '99999' }}>
                {t('pinefolio.plan.receptedInterest.explain')}
              </Tooltip>
            }
          >
            <span className="icon iInfor iTooltip ml-1 mr-1"></span>
          </OverlayTrigger>
        </span>
      ),
      className: 'text-right position-relative text--light fw-500',
      align: 'right',
      cell: (record) => {
        return numberFormat(record.rtIntAmt, 0, '0');
      },
    },
  ];

  return (
    <>
      <ScrollTable columns={columns} source={pfTdList?.list} />
    </>
  );
};
const BondOrderListTab = ({
  t,
  token,
  fromDate,
  toDate,
  account,
  planId,
  orderListStatusSrcParams,
}) => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const { listBondOrderList } = useSelector((state) => state.bond);
  const _planId = useDebounce(planId, 500);
  const getBondOrderList = () => {
    if (!token) return;
    const uuid = uuidv4();
    const params = {
      group: 'CORE',
      user: token.user,
      session: token.session,
      cmd: 'getBondOrderList',
      rqId: uuid,
      channel: 'WTS',
      data: {
        cif: token.cif,
        acntNo: account?.acntNo,
        subAcntNo: account?.subAcntNo,
        fromDate: moment(fromDate).format('YYYYMMDD'),
        toDate: moment(toDate).format('YYYYMMDD'),
        pfFilter: 'D',
        planId: planId,
        orderStat: orderListStatusSrcParams?.key,
      },
    };

    dispatch(getBondOrderListRequest(params));
  };

  useEffect(() => {
    getBondOrderList();
  }, []);

  useEffect(() => {
    if (listBondOrderList && listBondOrderList.list)
      setData(listBondOrderList.list.reverse());
  }, [listBondOrderList]);

  useEffect(() => {
    if (account) getBondOrderList();
  }, [fromDate, toDate, account, _planId, orderListStatusSrcParams]);

  const columns = [
    {
      key: 'pfPlanId',
      text: t('pinefolio.plan.planId'),
      className: 'text-center position-relative text--light fw-500',
      cell: (record) => <span>{record?.pfPlanId}</span>,

      sortable: true,
    },
    {
      key: 'dealId',
      text: t('bond.tableLabel.dealID'),
      className: 'text-center position-relative text--light3 fw-500',
      sortable: true,
    },
    {
      key: 'orderId',
      text: t('bond.tableLabel.orderID'),
      className: 'text-center position-relative fw-500 text--light3',
    },
    {
      key: 'bdProdCode',
      text: t('bond.tableLabel.productCode'),
      className: 'text-left position-relative text--light fw-400',
      align: 'left',

      sortable: true,
    },
    {
      key: ['bondNm', 'bondCode'],
      text: [t('bond.tableLabel.bondName'), t('bond.tableLabel.bondCode')],
      className: 'text-left position-relative text--light fw-500',
      sortable: true,
    },
    {
      key: 'listTp',
      text: t('bond.tableLabel.bondType'),
      className: 'text-center position-relative text--light3 fw-500',
      cell: (record) => {
        return t(getBondListTpType(record?.listTp));
      },
      sortable: true,
    },
    {
      key: 'tranDate',
      className: 'text-center position-relative',
      text: t('bond.tableLabel.tradeDate'),
      cell: (record) => {
        return _formatDate2(record.tranDate);
      },
      sortable: true,
    },
    {
      key: 'dueDate',
      text: t('bond.tableLabel.maturityDate'),
      className: 'text-center position-relative',
      cell: (record) => {
        return _formatDate2(record.dueDate);
      },
      sortable: true,
    },
    {
      key: 'cpnrt',
      text: t('bond.tableLabel.yieldToMaturity'),
      className: 'text-right position-relative r fw-500',
      cell: (record) => {
        return (
          numberFormat(record.cpnrt, 2, '-') + `%/${t('bond.bondProduct.year')}`
        );
      },
      sortable: true,
    },
    {
      key: 'dlQty',
      className: 'text-right position-relative text--light',
      text: t('bond.tableLabel.quantity'),
      cell: (record) => {
        return numberFormat(record.dlQty, 0, '-');
      },
      sortable: true,
    },
    {
      key: 'bsSide',
      text: t('bond.tableLabel.order'),
      className: (record) => {
        return 'text-center fw-500 ' + (record.bsSide == 1 ? 'd' : 'i');
      },
      cell: (record) => {
        return record.bsSide == 1 ? t('txt-sell') : t('txt-buy');
      },
      sortable: true,
    },
    {
      key: 'buyPrc',
      text: t('bond.tableLabel.price'),
      className: 'text-right position-relative text--light fw-500',
      cell: (record) => {
        return numberFormat(
          record.bsSide == 2 ? record.buyPrc : record.sellPrc,
          0,
          '0'
        );
      },
      sortable: true,
    },
    {
      key: 'buyAmt',
      text: t('bond.totalValue.name'),
      className: 'text-right position-relative text--light fw-500',
      cell: (record) => {
        return numberFormat(
          record.bsSide == 2 ? record.buyAmt : record.sellAmt,
          0,
          '0'
        );
      },
      sortable: true,
    },
    {
      key: 'tax',
      text: t('bond.tableLabel.tax'),
      className: 'text-right position-relative text--light3 fw-500',
      cell: (record) => {
        return numberFormat(record.tax, 0, '0');
      },
      sortable: true,
    },
    {
      key: 'sellFee',
      text: t('bond.fee'),
      className: 'text-right position-relative text--light3 fw-500',
      cell: (record) => {
        return numberFormat(
          record.bsSide == 2 ? record.buyFee : record.sellFee,
          0,
          '0'
        );
      },
      sortable: true,
    },
    {
      key: 'orderStat',
      text: t('bond.tableLabel.status'),
      className: (record) => {
        return 'text-center ' + mapStatus(record.orderStat)?.color;
      },
      cell: (record) => {
        return t(mapStatus(record.orderStat)?.value);
      },
      sortable: true,
    },
  ];

  const colgroup = [
    <colgroup>
      <col width=""></col>
      <col width="7%"></col>
      <col width="7%"></col>
      <col width="8%"></col>
      <col width="8%"></col>
      <col width=""></col>
      <col width="7%"></col>
      <col width="7%"></col>
    </colgroup>,
  ];
  return (
    <>
      <ScrollTable columns={columns} source={data} colgroup={colgroup} />
    </>
  );
};
const BondDealListTab = ({
  t,
  fromDate,
  toDate,
  account,
  planId,
  dealStat,
}) => {
  const { token, auth, setting } = useSelector((state) => state.client);
  const dispatch = useDispatch();
  const { bondProducts } = useSelector((state) => state?.bond);
  const [listBondDealList, setListBondDealList] = useState([]);
  const _planId = useDebounce(planId, 500);
  const lang = setting?.lang || 'vi';

  const getBondDealList = () => {
    if (!token) return;
    const uuid = uuidv4();
    const params = {
      group: 'CORE',
      user: token.user,
      session: token.session,
      cmd: 'getBondDealList',
      rqId: uuid,
      channel: 'WTS',
      data: {
        cif: token.cif,
        acntNo: account?.acntNo,
        subAcntNo: account?.subAcntNo,
        fromDate: moment(fromDate).format('YYYYMMDD'),
        toDate: moment(toDate).format('YYYYMMDD'),
        pfFilter: 'D',
        planId: planId,
        dealStat: dealStat?.key,
        prodTp: constants.bondProductType.Var,
      },
    };
    const callback = (data) => setListBondDealList(data);

    dispatch(getBondDealListRequest(params, callback));
  };

  const handleSellDeal = (product) => {
    const callback = () => {
      dispatch(setPFBondOrder(null));
      getBondDealList();
    };

    const pfBondOrder = {
      bondCode: product.bondCode,
      productCode: product.prodCode,
      planId: product.pfPlanId,
      quantity: product.availQty || 0,
      hideManual: false,
      disableQuantity: false,
      pfPlanBreakYN: product.pfPlanBreakYN,
      dealDefault: product.dealId,
      callback: callback,
    };

    const productDetail = bondProducts?.find(
      (item) => item.productCode == product.prodCode
    );

    dispatch(setPFBondOrder(pfBondOrder));
    dispatch(getBondDetail(product.bondCode, lang));
    dispatch(setBondProductDetail(productDetail));
    dispatch(
      setBondDetailModalRequest(
        bondConstants.modals.SELL_ORDER,
        product.subAcntNo
      )
    );
  };

  useEffect(() => {
    getBondDealList();
    dispatch(getBondProducts('', '', '', '', '', lang));
  }, []);

  useEffect(() => {
    getBondDealList();
  }, [fromDate, toDate, account, _planId, dealStat]);

  const columns = [
    {
      key: 'pfPlanId',
      text: t('pinefolio.plan.planId'),
      className: 'text-center position-relative text--light fw-500',
      cell: (record) => <span>{record?.pfPlanId}</span>,

      sortable: true,
    },
    {
      key: 'dealId',
      text: t('bond.tableLabel.dealID'),
      className: 'text-center position-relative text--light3 fw-500',
      cell: (record) => <span>{record?.dealId}</span>,

      sortable: true,
    },
    {
      key: 'prodCode',
      text: t('bond.tableLabel.productCode'),
      className: 'text-center position-relative text--light fw-500',
      sortable: true,
    },

    {
      key: ['bondNm', 'bondCode'],
      text: [t('bond.tableLabel.bondName'), t('bond.tableLabel.bondCode')],
      className: 'text-center position-relative text--light fw-500',
      sortable: true,
    },

    {
      key: 'tranDate',
      className: 'text-center position-relative text--light3',
      text: t('bond.tableLabel.tradeDate'),
      cell: (record) => {
        return _formatDate2(record.tranDate);
      },
      sortable: true,
    },
    {
      key: 'dueDate',
      text: t('bond.tableLabel.maturityDate'),
      className: 'text-center position-relative text--light3',
      cell: (record) => {
        return _formatDate2(record.dueDate);
      },
      sortable: true,
    },
    {
      key: 'cpnrt',
      text: t('bond.tableLabel.yieldToMaturity'),
      className: 'text-right position-relative r fw-500',
      cell: (record) => {
        return numberFormat(record.cpnrt, 2, '-') + '%';
      },
      sortable: true,
    },
    {
      key: 'dlQty',
      className: 'text-right position-relative text--light',
      text: t('bond.tableLabel.quantity'),
      sortable: true,
    },
    {
      key: 'buyPrc',
      text: t('bond.tableLabel.price'),
      className: 'text-right position-relative i fw-500',
      cell: (record) => {
        return numberFormat(record.buyPrc, 0, '0');
      },
      sortable: true,
    },
    {
      key: 'buyAmt',
      text: t('bond.tableLabel.totalValue.includedFee'),
      className: 'text-right position-relative text--light fw-500',
      cell: (record) => {
        return numberFormat(record.buyAmt + record.buyFee, 0, '0');
      },
      sortable: true,
    },

    {
      key: 'sellQty',
      text: t('bond.tableLabel.soldQuantity'),
      className: 'text-right position-relative text--light3',
      cell: (record) => {
        return numberFormat(record.sellQty, 0, '0');
      },
      sortable: true,
    },

    {
      key: 'sellPrc',
      text: t('bond.tableLabel.sellPrc'),
      className: 'text-right position-relative d fw-500',
      cell: (record) => {
        return numberFormat(record.sellPrc, 0, '0');
      },
      sortable: true,
    },

    {
      key: 'sellAmt',
      text: t('bond.tableLabel.sellAmt'),
      className: 'text-right position-relative text--light fw-500',
      cell: (record) => {
        return numberFormat(record.sellAmt, 0, '0');
      },
      sortable: true,
    },

    {
      key: 'tax',
      text: t('bond.tableLabel.tax'),
      className: 'text-right position-relative fw-500 text--light3',
      cell: (record) => {
        return numberFormat(record.tax, 0, '0');
      },
      sortable: true,
    },
    {
      key: 'sellFee',
      text: t('bond.tableLabel.fee'),
      className: 'text-right position-relative fw-500 text--light3',
      cell: (record) => {
        return numberFormat(record.sellFee, 0, '0');
      },
      sortable: true,
    },
    {
      key: 'dealStat',
      text: t('bond.tableLabel.status'),
      className: (record) => {
        if (!record.dealStat) return;
        return 'text-center ' + mapDealStatus(record?.dealStat)?.color;
      },
      cell: (record) => {
        return t(mapDealStatus(record.dealStat)?.value);
      },
      sortable: true,
    },

    {
      text: (
        <span className="d-flex justify-content-end w-100">
          <span>{t('pinefolio.plan.bondDealList.pfYN.name')}</span>
          <OverlayTrigger
            trigger={['hover', 'focus']}
            placement="top"
            className="portfolio-overlay"
            overlay={
              <Tooltip style={{ 'z-index': '99999' }}>
                {t('pinefolio.plan.bondDealList.pfYN.desc')}
              </Tooltip>
            }
          >
            <span className="icon iInfor iTooltip ml-1 mr-1"></span>
          </OverlayTrigger>
        </span>
      ),
      className: 'text-center ',
      key: 'pfPlanBreakYN',
    },
    {
      text: t('bond.tableLabel.actions'),
      className: 'text-center ',
      cell: (record) => {
        return (
          record.selAblYN == 'Y' && (
            <>
              {auth && auth.isVerified ? (
                <span
                  className="btn btn--sell2"
                  onClick={() => handleSellDeal(record)}
                >
                  {t('txt-sell')}
                </span>
              ) : (
                <span
                  className="btn btn--authen"
                  onClick={() => dispatch(setAuth())}
                >
                  {t('txt-sell')}
                </span>
              )}
            </>
          )
        );
      },
    },
  ];

  const colgroup = [
    <colgroup>
      <col width=""></col>
      <col width="7%"></col>
      <col width="7%"></col>
      <col width="7%"></col>
      <col width="7%"></col>
      <col width="7%"></col>
    </colgroup>,
  ];

  const listStatus = [
    { key: '1', value: t('bond.dealList.statusValue.dealed'), color: 'i' },
    { key: '2', value: t('bond.dealList.statusValue.paid'), color: 'i' },
    { key: '3', value: t('bond.dealList.statusValue.leg1'), color: 'i' },
    { key: '4', value: t('bond.dealList.statusValue.leg2'), color: 'i' },
    {
      key: '5',
      value: t('bond.dealList.statusValue.done'),
      color: 'i',
    },
  ];

  const mapDealStatus = (statusCode) => {
    return listStatus.find((item) => item.key === statusCode);
  };

  return (
    <>
      <ScrollTable
        columns={columns}
        source={listBondDealList?.list || []}
        colgroup={colgroup}
      />
    </>
  );
};

export default PlanManagement;
