import React from 'react';
import * as FileSaver from 'file-saver';
import * as Excel from 'exceljs';

import { numberFormat, _formatDate2 } from '../../../../util';
import { translate } from 'react-i18next';
import { Mixpanel } from '../../../../lib/mixpanel';
function nextColumn(current) {
  let alphabet = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
  ];
  return alphabet[alphabet.indexOf(current) + 1];
}

const ExportSecuritiesStatement = ({
  csvData,
  fileName,
  fromDate,
  toDate,
  accountCode,
  accountName,
  symbol,
  t,
}) => {
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const headers = [
    'STT',
    'Thời gian',
    'Mã CK',
    'Nghiệp vụ',
    'Số dư đầu kỳ',
    'Phát sinh tăng',
    'Phát sinh giảm',
    'Số dư cuối kỳ',
  ];
  const subHeaders = [
    'KL tăng',
    'Giá TB',
    'Tổng giá trị',
    'KL giảm',
    'Giá TB',
    'Tổng giá trị',
  ];
  const border = {
    top: { style: 'thin' },
    left: { style: 'thin' },
    bottom: { style: 'thin' },
    right: { style: 'thin' },
  };
  const font = { size: 11, bold: true, name: 'Times New Roman' };

  const exportToCSV = async (
    csvData,
    fileName,
    fromDate,
    toDate,
    accountCode,
    accountName,
    symbol
  ) => {
    const workbook = new Excel.Workbook();
    workbook.creator = 'KhanhNX';
    workbook.lastModifiedBy = '';
    workbook.created = new Date();
    workbook.modified = new Date();

    const worksheet = workbook.addWorksheet('Sao Ke Chung Khoan', {
      pageSetup: { paperSize: 9, orientation: 'landscape' },
    });

    worksheet.getCell('A1').value = 'CÔNG TY CỔ PHẦN CHỨNG KHOÁN PINETREE';
    worksheet.getCell('A1').font = {
      size: 13,
      bold: true,
      name: 'Times New Roman',
    };
    worksheet.getCell('A2').value =
      'Tầng 20, Tòa ROX Tower, 54A Nguyễn Chí Thanh, Đống Đa, Hà Nội';
    worksheet.getCell('A3').value =
      'Tel: 024 6282 3535 | Fax: 024 6275 0077 | Email: contact@pinetree.vn';

    worksheet.mergeCells('A5:K5');
    worksheet.getCell('A5').value = 'SAO KÊ GIAO DỊCH CHỨNG KHOÁN';
    worksheet.getCell('A5').font = {
      size: 14,
      bold: true,
      name: 'Times New Roman',
    };
    worksheet.getCell('A5').alignment = {
      vertical: 'middle',
      horizontal: 'center',
    };

    worksheet.mergeCells('A6:K6');
    worksheet.getCell('A6').value = `Từ ngày ${fromDate} đến ngày ${toDate}`;
    worksheet.getCell('A6').alignment = {
      vertical: 'middle',
      horizontal: 'center',
    };

    worksheet.getCell('A7').value = 'Tài khoản';
    worksheet.getCell('B7').value = accountCode;
    worksheet.getCell('B7').font = {
      size: 13,
      bold: true,
      name: 'Times New Roman',
    };
    worksheet.getCell('F7').value = 'Mã CK';
    worksheet.getCell('G7').value = symbol;
    worksheet.getCell('G7').font = {
      size: 13,
      bold: true,
      name: 'Times New Roman',
    };
    worksheet.getCell('A8').value = 'Số VSD';
    worksheet.getCell('B8').value = accountName;
    worksheet.getCell('B8').font = {
      size: 13,
      bold: true,
      name: 'Times New Roman',
    };

    // worksheet.addRow({});

    let startCol = 'A';
    let col = startCol.toUpperCase();
    let rowNum = 10;

    let len = 5; //headers.length;
    for (let i = 0; i < len; i++) {
      let cell = worksheet.getCell(col + rowNum);
      cell.value = headers[i];
      cell.border = border;
      cell.font = font;
      cell.alignment = { horizontal: 'center' };
      cell.fill = {
        type: 'pattern',
        pattern: 'darkTrellis',
        fgColor: { argb: 'FF7ABBFF' },
        bgColor: { argb: 'FF7ABBFF' },
      };
      col = nextColumn(col);
    }

    worksheet.mergeCells('F10:H10');
    let cellF = worksheet.getCell('F' + rowNum);
    cellF.value = 'Phát sinh tăng';
    cellF.border = border;
    cellF.font = font;
    cellF.alignment = { horizontal: 'center' };
    cellF.fill = {
      type: 'pattern',
      pattern: 'darkTrellis',
      fgColor: { argb: 'FF7ABBFF' },
      bgColor: { argb: 'FF7ABBFF' },
    };
    worksheet.mergeCells('I10:K10');
    let cellI = worksheet.getCell('I' + rowNum);
    cellI.value = 'Phát sinh giảm';
    cellI.border = border;
    cellI.font = font;
    cellI.alignment = { horizontal: 'center' };
    cellI.fill = {
      type: 'pattern',
      pattern: 'darkTrellis',
      fgColor: { argb: 'FF7ABBFF' },
      bgColor: { argb: 'FF7ABBFF' },
    };

    let cellL = worksheet.getCell('L' + rowNum);
    cellL.value = 'Số dư cuối kỳ';
    cellL.border = border;
    cellL.font = font;
    cellL.alignment = { horizontal: 'center' };
    cellL.fill = {
      type: 'pattern',
      pattern: 'darkTrellis',
      fgColor: { argb: 'FF7ABBFF' },
      bgColor: { argb: 'FF7ABBFF' },
    };

    // subheader
    worksheet.mergeCells('A11:E11');
    let cellA11 = worksheet.getCell('A11');
    cellA11.border = border;
    cellA11.fill = {
      type: 'pattern',
      pattern: 'darkTrellis',
      fgColor: { argb: 'FF7ABBFF' },
      bgColor: { argb: 'FF7ABBFF' },
    };
    startCol = 'F';
    col = startCol.toUpperCase();
    rowNum = 11;

    len = subHeaders.length;
    for (let i = 0; i < len; i++) {
      let cell11 = worksheet.getCell(col + rowNum);
      cell11.value = subHeaders[i];
      cell11.border = border;
      cell11.font = font;
      cell11.alignment = { horizontal: 'center' };
      cell11.fill = {
        type: 'pattern',
        pattern: 'darkTrellis',
        fgColor: { argb: 'FF7ABBFF' },
        bgColor: { argb: 'FF7ABBFF' },
      };
      col = nextColumn(col);
    }

    let cellL11 = worksheet.getCell('L11');
    cellL11.border = border;
    cellL11.fill = {
      type: 'pattern',
      pattern: 'darkTrellis',
      fgColor: { argb: 'FF7ABBFF' },
      bgColor: { argb: 'FF7ABBFF' },
    };

    startCol = 'A';

    let _arr = [];
    console.log(csvData);
    csvData.forEach((element, index) => {
      _arr.push({
        A: index + 1,
        B: _formatDate2(element.trdDate),
        C: element.symbol,
        D: element.descTranKnd,
        E: numberFormat(element.beginQty, 0, '0'),
        F: numberFormat(element.inQty, 0, '0'),
        G: element.inQty > 0 ? numberFormat(element.purchasePrc, 0, '0') : '-',
        H:
          element.inQty > 0
            ? numberFormat(element.inQty * element.purchasePrc, 0, '0')
            : '-',
        I: numberFormat(element.outQty, 0, '0'),
        J: element.outQty > 0 ? numberFormat(element.purchasePrc, 0, '0') : '-',
        K:
          element.outQty > 0
            ? numberFormat(element.outQty * element.purchasePrc, 0, '0')
            : '-',
        L: numberFormat(element.endQty, 0, '0'),
      });
    });

    _arr.forEach((element, index) => {
      rowNum++;
      col = startCol;
      for (let key in element) {
        let cell = worksheet.getCell(col + rowNum);
        cell.value = element[key];
        cell.border = border;
        if (['A', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L'].indexOf(key) > -1) {
          cell.alignment = { vertical: 'middle', horizontal: 'right' };
        }
        if (['B', 'C', 'D'].indexOf(key) > -1) {
          cell.alignment = { vertical: 'middle', horizontal: 'left' };
        }
        col = nextColumn(col);
      }
    });

    for (let i = 0; i < worksheet.columns.length; i += 1) {
      let dataMax = 0;
      const column = worksheet.columns[i];
      if (column) {
        for (let j = 10; j < column.values.length; j += 1) {
          const columnLength = column.values[j] ? column.values[j].length : 0;
          if (columnLength > dataMax) {
            dataMax = columnLength;
          }
        }
        column.width = (dataMax < 10 ? 10 : dataMax) + 2;
      }
    }

    // save file
    const excelBuffer = await workbook.xlsx.writeBuffer({ useStyles: true });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <div
      className="text-white cursor-pointer"
      onClick={(e) => {
        Mixpanel.track('Export Statement', {
          'Statement Type': 'Securities Statement',
        });

        exportToCSV(
          csvData,
          fileName,
          fromDate,
          toDate,
          accountCode,
          accountName,
          symbol
        );
      }}
    >
      <div className="btn-icon btn-icon--light cursor-pointer mr-1">
        <span className="icon iDownload"></span>
        <span className="fw-500">{t('txt-export-excel')}</span>
      </div>
    </div>
  );
};

export default translate('translations')(ExportSecuritiesStatement);
