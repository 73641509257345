export const START_CHANNEL = 'START_CHANNEL';
export const STOP_CHANNEL = 'STOP_CHANNEL';
export const CHANNEL_ON = 'CHANNEL_ON';
export const CHANNEL_OFF = 'CHANNEL_OFF';
export const SERVER_ON = 'SERVER_ON';
export const SERVER_OFF = 'SERVER_OFF';

export const REGISTER_REQUESTING = 'REGISTER_REQUESTING';
export const REGISTER_PRIVATE_DONE = 'REGISTER_PRIVATE_DONE';

export const REGISTER_OK = 'REGISTER_OK';
export const REGISTER_NOT_OK = 'REGISTER_NOT_OK';

export const SET_REG_SYMBOL = 'SET_REG_SYMBOL';
export const UNSET_REG_SYMBOL = 'UNSET_REG_SYMBOL';
export const REGISTER_SOCKET_DATA = 'REGISTER_SOCKET_DATA';
export const LEAVE_SOCKET_DATA = 'LEAVE_SOCKET_DATA';
export const SET_LIST_ACCOUNT = 'SET_LIST_ACCOUNT';
export const DefaultAccount = 'DefaultAccount';
export const NewOrder = 'NewOrder';
export const PurchasePower = 'PurchasePower';
export const StockBalance = 'StockBalance';
export const OrderList = 'OrderList';
export const ListOrder = 'ListOrder';
export const GetMatchOrder = 'GetMatchOrder';
export const CancelOrder = 'CancelOrder';
export const EditOrder = 'EditOrder';
export const CashBalance = 'CashBalance';
export const ShareBalance = 'ShareBalance';
export const getPositions = 'getPositions';
export const getAvailStockList = 'getAvailStockList';
export const getSymbolInfo = 'getSymbolInfo';

export const Event = 'Event';
// G1: default | G3: HNX post close | G4: Oddlot | G7: Buyin
export const SET_CURRENT_G = 'SET_CURRENT_G';
export const GET_PRIV_DATA_REQUEST = 'GET_PRIV_DATA_REQUEST';
export const SET_SID = 'SET_SID';
export const FORCE_PRIVATE_REGISTER = 'FORCE_PRIVATE_REGISTER';
