import { call, put, takeLatest, all, select } from 'redux-saga/effects';
import { handleApiErrors } from '../../lib/api-errors';
import {
  SUMMARY_ORD_REQUESTING,
  SUMMARY_CASH_REQUESTING,
  SUMMARY_STOCK_REQUESTING,
  SUMMARY_EVENT_REQUESTING,
  SUMMARY_RIGHT_REQUESTING,
  SUMMARY_ORD_COND_REQUESTING,
  SUMMARY_NOTIFICATION_REQUESTING,
  NOTIFICATION_UPDATE_REQUEST,
} from './constants';

import {
  summaryOrdRequestSuccess,
  summaryOrdRequestError,
  summaryCashRequestSuccess,
  summaryCashRequestError,
  summaryStockRequestSuccess,
  summaryStockRequestError,
  summaryEventRequestSuccess,
  summaryEventRequestError,
  rightEventRequestSuccess,
  rightEventRequestError,
  summaryOrdCondRequestSuccess,
  summaryOrdCondRequestError,
  summaryNotificationRequest,
  summaryNotificationRequestSuccess,
  summaryNotificationRequestError,
  notificationUpdateRequestSuccess,
} from './actions';
import { getMessage } from '../../lib/selector';
import { removeCookie } from '../../lib/storages';

const appUrl = `${process.env.REACT_APP_API_URL}`;

function handleRequest(request) {
  return request
    .then(handleApiErrors)
    .then((response) => response.json())
    .then((json) => json)
    .catch((error) => {
      throw error;
    });
}

function summaryApi(data) {
  const url = `${appUrl}/CoreServlet.pt`;
  const request = fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
    body: JSON.stringify(data),
  });
  return handleRequest(request);
}

function* summaryOrdRequestFlow({ params }) {
  try {
    const resData = yield call(summaryApi, params);
    if (resData.http !== 200) {
      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');

        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }
      const msgErr = yield select(getMessage, resData);
      throw Error(msgErr);
    }

    yield put(summaryOrdRequestSuccess(resData.data));
  } catch (error) {
    // console.log(error)
    yield put(summaryOrdRequestError(error));
  }
}

function* summaryOrdCondRequestFlow({ params }) {
  try {
    const resData = yield call(summaryApi, params);
    if (resData.http !== 200) {
      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');

        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }
      const msgErr = yield select(getMessage, resData);
      throw Error(msgErr);
    }

    yield put(summaryOrdCondRequestSuccess(resData.data));
  } catch (error) {
    // console.log(error)
    yield put(summaryOrdCondRequestError(error));
  }
}

function* summaryCashRequestFlow({ params }) {
  try {
    const resData = yield call(summaryApi, params);
    if (resData.http !== 200) {
      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');

        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }
      const msgErr = yield select(getMessage, resData);
      throw Error(msgErr);
    }

    yield put(summaryCashRequestSuccess(resData.data));
  } catch (error) {
    // console.log(error)
    yield put(summaryCashRequestError(error));
  }
}

function* summaryStockRequestFlow({ params }) {
  try {
    const resData = yield call(summaryApi, params);
    if (resData.http !== 200) {
      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');

        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }
      const msgErr = yield select(getMessage, resData);
      throw Error(msgErr);
    }

    yield put(summaryStockRequestSuccess(resData.data));
  } catch (error) {
    // console.log(error)
    yield put(summaryStockRequestError(error));
  }
}

function* summaryEventRequestFlow({ params }) {
  try {
    const resData = yield call(summaryApi, params);
    if (resData.http !== 200) {
      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');

        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }
      const msgErr = yield select(getMessage, resData);
      throw Error(msgErr);
    }

    yield put(summaryEventRequestSuccess(resData.data));
  } catch (error) {
    // console.log(error)
    yield put(summaryEventRequestError(error));
  }
}

function* summaryRightRequestFlow({ params }) {
  try {
    const resData = yield call(summaryApi, params);
    if (resData.http !== 200) {
      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');

        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }
      const msgErr = yield select(getMessage, resData);
      throw Error(msgErr);
    }

    yield put(rightEventRequestSuccess(resData.data));
  } catch (error) {
    // console.log(error)
    yield put(rightEventRequestError(error));
  }
}

function* summaryNotificationRequestFlow({ params }) {
  try {
    const resData = yield call(summaryApi, params);
    if (resData.http !== 200) {
      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');

        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }
      const msgErr = yield select(getMessage, resData);
      throw Error(msgErr);
    }

    yield put(summaryNotificationRequestSuccess(resData.data));
  } catch (error) {
    // console.log(error)
    yield put(summaryNotificationRequestError(error));
  }
}

function* notificationUpdateRequestFlow({ params }) {
  try {
    const resData = yield call(summaryApi, params);
    if (resData.http !== 200) {
      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');

        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }
      const msgErr = yield select(getMessage, resData);
      throw Error(msgErr);
    }

    yield put(notificationUpdateRequestSuccess(params));
  } catch (error) {
    console.log('Error when update read status of notication: ' + error);
  }
}

function* tradingWatcher() {
  yield all([
    takeLatest(SUMMARY_ORD_REQUESTING, summaryOrdRequestFlow),
    takeLatest(SUMMARY_ORD_COND_REQUESTING, summaryOrdCondRequestFlow),
    takeLatest(SUMMARY_CASH_REQUESTING, summaryCashRequestFlow),
    takeLatest(SUMMARY_STOCK_REQUESTING, summaryStockRequestFlow),
    takeLatest(SUMMARY_EVENT_REQUESTING, summaryEventRequestFlow),
    takeLatest(SUMMARY_RIGHT_REQUESTING, summaryRightRequestFlow),
    takeLatest(SUMMARY_NOTIFICATION_REQUESTING, summaryNotificationRequestFlow),
    takeLatest(NOTIFICATION_UPDATE_REQUEST, notificationUpdateRequestFlow),
  ]);
}

export default tradingWatcher;
