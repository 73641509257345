import {
  CLIENT_SET,
  CLIENT_UNSET,
  AUTHEN_SET,
  CATEGORY_SET,
  SETTING_SET,
  LOGIN_SET,
  LOGIN_UNSET,
  AUTH_SET,
  AUTH_UNSET,
  SYMBOL_ACTIVE_SET,
  SYMBOL_MINIMIZE_SET,
  TYPE_INDEX_SET,
  CASH_OUT_SET,
  CASH_OUT_RESET,
  ORD_COND_SET,
  CONFIG_REQUESTING,
  CONFIG_REQUEST_SUCCESS,
  CONFIG_REQUEST_ERROR,
  CATEGORY_REQUESTING,
  CATEGORY_REQUEST_SUCCESS,
  CATEGORY_REQUEST_ERROR,
  SAVE_CATEGORY_REQUESTING,
  SAVE_CATEGORY_REQUEST_ERROR,
  SAVE_CATEGORY_REQUEST_SUCCESS,
  RESET_SET,
  RESET_UNSET,
  CHANGE_PASS_SET,
  CHANGE_PASS_UNSET,
  INDEX_HIDE_SET,
  DBL_PRICE_SET,
  CTLG_ID_EDIT_SET,
  SYMBOL_ADD,
  SYMBOL_SCROLL,
  CHANGE_USER_SET,
  CHANGE_USER_UNSET,
  FULL_TRADING_SET,
  FULL_TRADING_UNSET,
  OBJ_TRADING_UNSET,
  OBJ_TRADING_SET,
  CHANGE_BANK_SET,
  CHANGE_BANK_UNSET,
  CHANGE_TELPIN_SET,
  CHANGE_TELPIN_UNSET,
  INDUSTRY_CATEGORY_REQUESTING,
  INDUSTRY_CATEGORY_REQUEST_SUCCESS,
  INDUSTRY_CATEGORY_REQUEST_ERROR,
  INDUSTRY_CATEGORY_APPEND,
  ADVERTISE_REQUESTING,
  ADVERTISE_REQUESTING_RESET,
  APPSETTING_REQUESTING,
  SET_THEME,
  SET_NOTI_POPUP,
  SET_CATEGORY_SELECTED,
  USER_SETTING_REQUEST,
  GET_CHART_IN_MEM_DATA,
  SET_FEEDBACK_POPUP_SHOW,
  GET_FEEDBACK_TYPE_REQUEST,
  CLIENT_COMMON_REQUEST,
} from './constants';

export function setClient(token) {
  return {
    type: CLIENT_SET,
    token,
  };
}

export function setAuthen(auth) {
  return {
    type: AUTHEN_SET,
    auth,
  };
}

export function unsetClient() {
  return {
    type: CLIENT_UNSET,
  };
}

export function setCategory(category) {
  return {
    type: CATEGORY_SET,
    category,
  };
}

export function setSetting(setting) {
  return {
    type: SETTING_SET,
    setting,
  };
}

export function setLogin() {
  return {
    type: LOGIN_SET,
  };
}

export function unsetLogin() {
  return {
    type: LOGIN_UNSET,
  };
}

export function setAuth() {
  return {
    type: AUTH_SET,
  };
}

export function unsetAuth() {
  return {
    type: AUTH_UNSET,
  };
}
export function setReset() {
  return {
    type: RESET_SET,
  };
}

export function unsetReset() {
  return {
    type: RESET_UNSET,
  };
}

export function setChangePass() {
  return {
    type: CHANGE_PASS_SET,
  };
}

export function unsetChangePass() {
  return {
    type: CHANGE_PASS_UNSET,
  };
}

export function setChangeTelpin() {
  return {
    type: CHANGE_TELPIN_SET,
  };
}

export function unsetChangeTelpin() {
  return {
    type: CHANGE_TELPIN_UNSET,
  };
}

export function setChangeUser() {
  return {
    type: CHANGE_USER_SET,
  };
}

export function unsetChangeUser() {
  return {
    type: CHANGE_USER_UNSET,
  };
}
export function setChangeBank() {
  return {
    type: CHANGE_BANK_SET,
  };
}

export function unsetChangeBank() {
  return {
    type: CHANGE_BANK_UNSET,
  };
}
export function setFullTrading() {
  return {
    type: FULL_TRADING_SET,
  };
}

export function unsetFullTrading() {
  return {
    type: FULL_TRADING_UNSET,
  };
}

export function unsetLostSession() {
  return {
    type: 'CLEAR_LOST_SESSION',
  };
}
export function setObjTrading(data) {
  return {
    type: OBJ_TRADING_SET,
    data,
  };
}

export function unsetObjTrading() {
  return {
    type: OBJ_TRADING_UNSET,
  };
}

export const setToast = function setToast(resData) {
  return {
    type: 'SHOW_TOAST',
    resData,
  };
};

export const setSymbolMinimize = function setSymbolMinimize(data) {
  return {
    type: SYMBOL_MINIMIZE_SET,
    data,
  };
};

export const setTypeIndex = function setTypeIndex(data) {
  return {
    type: TYPE_INDEX_SET,
    data,
  };
};

export const setIndexHide = function setIndexHide(data) {
  return {
    type: INDEX_HIDE_SET,
    data,
  };
};

export const setCashOut = function setCashOut(data) {
  return {
    type: CASH_OUT_SET,
    data,
  };
};

export const resetCashOut = () => {
  return {
    type: CASH_OUT_RESET,
  };
};

export const setOrdCond = function setOrdCond(data) {
  return {
    type: ORD_COND_SET,
    data,
  };
};

export const setDblPrice = function setDblPrice(data) {
  return {
    type: DBL_PRICE_SET,
    data,
  };
};

export const setCtlgIdEdit = function setCtlgIdEdit(data) {
  return {
    type: CTLG_ID_EDIT_SET,
    data,
  };
};

export const setSymbolActive = function setSymbolActive(data) {
  return {
    type: SYMBOL_ACTIVE_SET,
    data,
  };
};

export const setSymbolAdd = function setSymbolAdd(symbol) {
  return {
    type: SYMBOL_ADD,
    symbol,
  };
};

export const setSymbolScroll = function setSymbolScroll(symbol) {
  return {
    type: SYMBOL_SCROLL,
    symbol,
  };
};

export const configRequest = function configRequest(data) {
  return {
    type: CONFIG_REQUESTING,
    data,
  };
};

export const configRequestSuccess = function configRequestSuccess(config) {
  return {
    type: CONFIG_REQUEST_SUCCESS,
    config,
  };
};

export const configRequestError = function configRequestError(error) {
  return {
    type: CONFIG_REQUEST_ERROR,
    error,
  };
};

export const categoryRequest = function categoryRequest(data) {
  return {
    type: CATEGORY_REQUESTING,
    data,
  };
};

export const categoryRequestSuccess = function categoryRequestSuccess(
  category
) {
  // console.log('categoryRequestSuccess', category)
  return {
    type: CATEGORY_REQUEST_SUCCESS,
    category,
  };
};

export const categoryRequestError = function categoryRequestError(error) {
  return {
    type: CATEGORY_REQUEST_ERROR,
    error,
  };
};

export const saveCategoryRequest = function saveCategoryRequest(data) {
  return {
    type: SAVE_CATEGORY_REQUESTING,
    data,
  };
};

export const saveCategoryRequestSuccess = function saveCategoryRequestSuccess(
  category
) {
  return {
    type: SAVE_CATEGORY_REQUEST_SUCCESS,
  };
};

export const saveCategoryRequestError = function saveCategoryRequestError(
  error
) {
  return {
    type: SAVE_CATEGORY_REQUEST_ERROR,
    error,
  };
};

export const industryRequest = (lang = 1) => {
  return {
    type: INDUSTRY_CATEGORY_REQUESTING,
    lang,
  };
};

export const industryRequestSuccess = (data) => {
  return { type: INDUSTRY_CATEGORY_REQUEST_SUCCESS, data };
};

export const industryRequestError = (error) => {
  return { type: INDUSTRY_CATEGORY_REQUEST_ERROR };
};

export const industryAppend = () => {
  return {
    type: INDUSTRY_CATEGORY_APPEND,
  };
};

export const advertiseRequest = (lang = 1, ver = 2) => {
  return {
    type: ADVERTISE_REQUESTING,
    lang,
    ver,
  };
};

export const advertiseReset = () => {
  return {
    type: ADVERTISE_REQUESTING_RESET,
  };
};

export const appsettingRequest = (data) => {
  return {
    type: APPSETTING_REQUESTING,
    data,
  };
};

export const setTheme = (id) => {
  return {
    type: SET_THEME,
    id,
  };
};

export const setNotiPopup = (data) => {
  return {
    type: SET_NOTI_POPUP,
    data,
  };
};

export const setCategorySelected = (data) => {
  return {
    type: SET_CATEGORY_SELECTED,
    data,
  };
};

export const userSettingRequest = (params, callback, isProtect = false) => {
  return {
    type: USER_SETTING_REQUEST,
    params,
    callback,
    isProtect,
  };
};

export const getDataInMemRequest = (mc, callback = null) => {
  return {
    type: GET_CHART_IN_MEM_DATA,
    mc,
    callback,
  };
};

export const setFeedbackPopupShow = (show) => {
  return {
    type: SET_FEEDBACK_POPUP_SHOW,
    show,
  };
};

export const getFeedbackTypeRequest = (lang, callback = null) => {
  return {
    type: GET_FEEDBACK_TYPE_REQUEST,
    lang,
    callback,
  };
};

export const clientCommonRequest = (params, callback, isProtect = false) => {
  return {
    type: CLIENT_COMMON_REQUEST,
    params,
    callback,
    isProtect,
  };
};
