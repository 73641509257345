import React, { useContext } from 'react';
import { Modal } from 'react-bootstrap';
import { withNamespaces } from 'react-i18next';
import { ReactReduxContext, useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { setToast, unsetClient } from '../../../containers/client/actions';
import { cancelPinefolioOrderRequest } from '../../../containers/pinefolio/action';
import { handleApiErrors } from '../../../lib/api-errors';
import { removeCookie } from '../../../lib/storages';
import { getMessageError } from '../../../util';
import { Logout, logoutRequest } from '../login/actions';
const appUrl = `${process.env.REACT_APP_API_URL}`;

const DeletePineOrder = withNamespaces('translations')(
  ({
    t,
    themes,
    showModal,
    handleCloseModal,
    token,
    auth,
    order,
    handleConfirmDel,
  }) => {
    const dispatch = useDispatch();
    const { store } = useContext(ReactReduxContext);
    const handleConfirmDelOrder = () => {
      if (!token || !auth?.isVerified) return;
      const uuid = uuidv4();
      const params = {
        group: 'CORE',
        user: token.user,
        session: token.session,
        type: auth.type,
        token: auth.token,
        cmd: 'cancelPFOrder',
        rqId: uuid,
        channel: 'WTS',
        data: {
          cif: token.cif,
          acntNo: order?.acntNo,
          subAcntNo: order?.subAcntNo,
          aplcDate: order?.aplcDate,
          aplcNo: order?.aplcNo,
        },
      };
      const url = `${appUrl}/PortfolioServlet.pt`;
      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
        body: JSON.stringify(params),
      })
        .then(handleApiErrors)
        .then((response) => response.json())
        .then((res) => {
          if (res.http == 200) {
            handleConfirmDel();
            const toastMsg = {
              id: Math.random(),
              msg: t('txt-title-delorder-success'),
              title: t('txt-notice'),
            };
            dispatch(setToast(toastMsg));
          } else {
            if (res.http === 401) {
              if (token) dispatch(logoutRequest(token));
              dispatch(unsetClient());
              removeCookie('token');
              removeCookie('authen');
              dispatch(Logout());
              const toastMsg = {
                id: Math.random(),
                msg: t('txt-valid-loss-session'),
                title: t('txt-notice'),
              };
              dispatch(setToast(toastMsg));
            } else {
              const msgErr = getMessageError(res, store.getState);
              const toastMsg = {
                id: Math.random(),
                msg: msgErr,
                title: t('txt-notice'),
              };
              dispatch(setToast(toastMsg));
            }
          }
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    };

    return (
      <Modal
        show={showModal}
        dialogClassName="delete-order-modal"
        backdropClassName="delete-order-modal-backdrop"
        contentClassName={`${
          themes?.name || 'dark'
        } delete-order-modal-content`}
        onEscapeKeyDown={handleCloseModal}
        centered
        style={{ zIndex: 11115 }}
      >
        <div className="delete-order">
          <div className="delete-order-header">
            {t('txt-title-delete-confirm')}
          </div>
          <div className="delete-order-body">
            <div className="delete-order-body__infor">
              <span className="delete-order-body__infor-title">
                <p>{t('pinefolio.pfCode')}</p>
                <p>{t('txt-type')}</p>
                <p>{t('txt-orderNo')}</p>
              </span>
              <span className="delete-order-body__infor-value">
                <p>{order && order.pfCode}</p>
                <p
                  className={`${
                    order && (order.prchsSaleTp === '1' ? 'i' : 'd')
                  }`}
                >
                  {order &&
                    (order.prchsSaleTp === '1' ? t('txt-buy') : t('txt-sell'))}
                </p>
                <p>{order && order.aplcNo}</p>
              </span>
            </div>

            <div className="delete-order-body__action">
              <button className="btn-confirm" onClick={handleConfirmDelOrder}>
                {t('txt-accept')}
              </button>
              <button className="btn-cancel" onClick={handleCloseModal}>
                {t('txt-cancel')}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
);

export default DeletePineOrder;
