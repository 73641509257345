import React from 'react';
import { connect } from 'react-redux';
import DatagridRow from './DatagridRow';

import {
  setSymbolActive,
  setSymbolScroll,
} from '../../containers/client/actions';

class Datagrid extends React.Component {
  constructor(props) {
    super(props);

    // this.itemRefs = {};
  }

  scrollTo(id) {
    let _rootRow = document.getElementById(id + 'row');
    if (_rootRow) {
      _rootRow.scrollIntoView();
      _rootRow.classList.add('bg-active');
      setTimeout(() => {
        if (_rootRow) _rootRow.classList.remove('bg-active');
      }, 3000);
    }
  }

  handleOpenModal = (sym) => {
    this.props.dispatch(setSymbolActive(sym));
  };

  _handleChange = (e) => {
    if (e.target.checked && e.currentTarget.dataset.tag)
      this.props.handlePinStock(e.currentTarget.dataset.tag);
    else if (!e.target.checked && e.currentTarget.dataset.tag)
      this.props.handleUnPinStock(e.currentTarget.dataset.tag);
  };

  componentDidUpdate(preProps) {
    const { symScroll } = this.props;

    if (symScroll && symScroll !== preProps.symScroll) {
      this.scrollTo(symScroll);
      this.props.dispatch(setSymbolScroll(null));
    }
  }

  render() {
    const { resource, children, classname, type, isPc } = this.props;
    return (
      <table className={classname + ''}>
        <colgroup>
          <col width="3.3%"></col>
          {/** pin */}
          <col width="5.3%"></col>
          {/** sym */}
          <col width="3.5%"></col>
          {/** tc */}
          <col width="3.5%"></col>
          {/** tran */}
          <col width="3.5%"></col>
          {/** san */}
          <col width="3.5%"></col>
          {/** gia 3 */}
          <col width="3.9%"></col>
          {/** kl 3 */}
          <col width="3.5%"></col>
          {/** giá 2 */}
          <col width="3.9%"></col>
          {/** kl 2 */}
          <col width="3.5%"></col>
          {/** gia 1 */}
          <col width="3.9%"></col>
          {/** kl 1 */}
          <col width="3.5%"></col>
          {/** gia khop */}
          <col width="4.1%"></col>
          {/** kl khop */}
          <col width="3.5%"></col>
          {/** +/- */}
          <col width="3.5%"></col>
          {/** gia 1 */}
          <col width="3.9%"></col>
          {/** kl 1 */}
          <col width="3.5%"></col>
          {/** gia 2 */}
          <col width="3.9%"></col>
          {/** kl 2 */}
          <col width="3.5%"></col>
          {/** gia 3 */}
          <col width="3.9%"></col>
          {/** kl 3 */}
          <col width="6.0%"></col>
          {/** tong kl */}
          <col width="3.5%"></col>
          {/** cao */}
          <col width="3.5%"></col>
          {/** thap */}
          <col width="3.5%"></col>
          {/** tb */}
          <col width="4.4%"></col>
          {/** nn mua */}
          <col width="4.4%"></col>
          {/** nn ban */}
        </colgroup>
        {children && <thead>{children}</thead>}
        <tbody>
          {resource &&
            !!resource.length &&
            resource.map((item, index) => (
              <DatagridRow
                record={item}
                key={`${item.sym}-${index}`}
                // forwardRef={(el) => (this.itemRefs[item.sym] = el)}
                checked={type === 'unpin' ? false : true}
                isPc={isPc}
                handleOpenModal={this.handleOpenModal}
                handleChange={this._handleChange}
              />
            ))}
        </tbody>
      </table>
    );
  }
}

const mapStateToProps = (state) => {
  // console.log(state.client)
  return {
    symScroll: state.client.symbolScroll,
  };
};

export default connect(mapStateToProps)(Datagrid);
