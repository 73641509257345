import {
  AVAIL_STOCK_SOURCE_REQUESTING,
  AVAIL_STOCK_SOURCE_REQUEST_SUCCESS,
  AVAIL_STOCK_SOURCE_REQUEST_ERROR,
  AVAIL_STOCK_TARGET_REQUESTING,
  AVAIL_STOCK_TARGET_REQUEST_SUCCESS,
  AVAIL_STOCK_TARGET_REQUEST_ERROR,
  STOCK_EXCHANGE_REQUESTING,
  STOCK_EXCHANGE_REQUEST_SUCCESS,
  STOCK_EXCHANGE_REQUEST_ERROR,
  STOCK_EXCHANGE_HIS_REQUESTING,
  STOCK_EXCHANGE_HIS_REQUEST_SUCCESS,
  STOCK_EXCHANGE_HIS_REQUEST_ERROR,
  ADVANCE_REQUESTING,
  ADVANCE_REQUEST_SUCCESS,
  ADVANCE_REQUEST_ERROR,
  AVAIL_ADVANCE_REQUESTING,
  AVAIL_ADVANCE_REQUEST_SUCCESS,
  AVAIL_ADVANCE_REQUEST_ERROR,
  ADVANCE_LOAN_REQUESTING,
  ADVANCE_LOAN_REQUEST_SUCCESS,
  ADVANCE_LOAN_REQUEST_ERROR,
  ADVANCE_LIST_REQUESTING,
  ADVANCE_LIST_REQUEST_SUCCESS,
  ADVANCE_LIST_REQUEST_ERROR,
  ADVANCE_MATCH_REQUESTING,
  ADVANCE_MATCH_REQUEST_SUCCESS,
  ADVANCE_MATCH_REQUEST_ERROR,
  CASH_TRANSFER_REQUESTING,
  CASH_TRANSFER_REQUEST_SUCCESS,
  CASH_TRANSFER_REQUEST_ERROR,
  HIS_TRANSFER_REQUESTING,
  HIS_TRANSFER_REQUEST_SUCCESS,
  HIS_TRANSFER_REQUEST_ERROR,
  BALANCE_SOURCE_REQUESTING,
  BALANCE_SOURCE_REQUEST_SUCCESS,
  BALANCE_SOURCE_REQUEST_ERROR,
  BALANCE_TARGET_REQUESTING,
  BALANCE_TARGET_REQUEST_SUCCESS,
  BALANCE_TARGET_REQUEST_ERROR,
  DEBT_REQUESTING,
  DEBT_REQUEST_SUCCESS,
  DEBT_REQUEST_ERROR,
  REPAY_LOAN_REQUESTING,
  REPAY_LOAN_REQUEST_SUCCESS,
  REPAY_LOAN_REQUEST_ERROR,
  DEBT_HIS_REQUESTING,
  DEBT_HIS_REQUEST_SUCCESS,
  DEBT_HIS_REQUEST_ERROR,
  ADVANCE_FEE_REQUESTING,
  ADVANCE_FEE_REQUEST_SUCCESS,
  ADVANCE_FEE_REQUEST_ERROR,
  BANK_ACCOUNT_REQUESTING,
  BANK_ACCOUNT_REQUEST_SUCCESS,
  BANK_ACCOUNT_REQUEST_ERROR,
  CASH_OUT_REQUESTING,
  CASH_OUT_REQUEST_SUCCESS,
  CASH_OUT_REQUEST_ERROR,
  BANK_LIST_REQUESTING,
  BANK_LIST_REQUEST_SUCCESS,
  BANK_LIST_REQUEST_ERROR,
  CASH_IN_BANK_REQUESTING,
  CASH_IN_BANK_REQUEST_SUCCESS,
  CASH_IN_BANK_REQUEST_ERROR,
  RIGHT_LIST_REQUESTING,
  RIGHT_LIST_REQUEST_SUCCESS,
  RIGHT_LIST_REQUEST_ERROR,
  HOLD_BANK_REQUESTING,
  HOLD_BANK_REQUEST_SUCCESS,
  HOLD_BANK_REQUEST_ERROR,
  HOLD_LIST_REQUESTING,
  HOLD_LIST_REQUEST_SUCCESS,
  HOLD_LIST_REQUEST_ERROR,
  DEBT_HIS2_REQUEST_SUCCESS,
  DEBT_HIS2_REQUEST_ERROR,
  DEBT_HIS2_REQUESTING,
  MARGIN_LIST_REQUESTING,
  MARGIN_LIST_REQUEST_SUCCESS,
  MARGIN_LIST_REQUEST_ERROR,
  GAIN_LOSS_RESET,
  GAIN_LOSS_REQUESTING,
  GAIN_LOSS_REQUEST_SUCCESS,
  GAIN_LOSS_REQUEST_ERROR,
  BANK_INFOR_REQUESTING,
  CASH_OUT_REQUEST_RESET,
  STOCK_EXCHANGE_REQUESTING_RESET,
  DEBT_HIS_EXTEND_REQUESTING,
  DEBT_EXTEND_REQUESTING,
  DEBT_EXTEND_REQUEST_RESET,
  GET_LIST_BANK_VA_REQUEST,
  GET_LIST_BANK_VA_SUCCESS,
  GET_LIST_BANK_VA_ERROR,
} from './constants';

export const stockSourceRequest = function stockSourceRequest(data) {
  return {
    type: AVAIL_STOCK_SOURCE_REQUESTING,
    data,
  };
};

export const stockSourceRequestSuccess = function stockSourceRequestSuccess(
  resData
) {
  return {
    type: AVAIL_STOCK_SOURCE_REQUEST_SUCCESS,
    resData,
  };
};

export const stockSourceRequestError = function stockSourceRequestError(error) {
  return {
    type: AVAIL_STOCK_SOURCE_REQUEST_ERROR,
    error,
  };
};

export const stockTargetRequest = function stockTargetRequest(data) {
  return {
    type: AVAIL_STOCK_TARGET_REQUESTING,
    data,
  };
};

export const stockTargetRequestSuccess = function stockTargetRequestSuccess(
  resData
) {
  return {
    type: AVAIL_STOCK_TARGET_REQUEST_SUCCESS,
    resData,
  };
};

export const stockTargetRequestError = function stockTargetRequestError(error) {
  return {
    type: AVAIL_STOCK_TARGET_REQUEST_ERROR,
    error,
  };
};

export const stockExchangeRequest = function stockExchangeRequest(data) {
  // console.log(data);
  return {
    type: STOCK_EXCHANGE_REQUESTING,
    data,
  };
};

export const stockExchangeReset = () => {
  return {
    type: STOCK_EXCHANGE_REQUESTING_RESET,
  };
};

export const stockExchangeRequestSuccess = function stockExchangeRequestSuccess(
  resData
) {
  return {
    type: STOCK_EXCHANGE_REQUEST_SUCCESS,
    resData,
  };
};

export const stockExchangeRequestError = function stockExchangeRequestError(
  error
) {
  return {
    type: STOCK_EXCHANGE_REQUEST_ERROR,
    error,
  };
};

export const stockExchangeHisRequest = function stockExchangeHisRequest(data) {
  return {
    type: STOCK_EXCHANGE_HIS_REQUESTING,
    data,
  };
};

export const stockExchangeHisRequestSuccess =
  function stockExchangeHisRequestSuccess(resData) {
    return {
      type: STOCK_EXCHANGE_HIS_REQUEST_SUCCESS,
      resData,
    };
  };

export const stockExchangeHisRequestError =
  function stockExchangeHisRequestError(error) {
    return {
      type: STOCK_EXCHANGE_HIS_REQUEST_ERROR,
      error,
    };
  };

export const advanceRequest = function advanceRequest(data) {
  return {
    type: ADVANCE_REQUESTING,
    data,
  };
};

export const advanceRequestSuccess = function advanceRequestSuccess(resData) {
  return {
    type: ADVANCE_REQUEST_SUCCESS,
    resData,
  };
};

export const advanceRequestError = function advanceRequestError(error) {
  return {
    type: ADVANCE_REQUEST_ERROR,
    error,
  };
};

export const availAdvanceRequest = function availAdvanceRequest(data) {
  return {
    type: AVAIL_ADVANCE_REQUESTING,
    data,
  };
};

export const availAdvanceRequestSuccess = function availAdvanceRequestSuccess(
  resData
) {
  return {
    type: AVAIL_ADVANCE_REQUEST_SUCCESS,
    resData,
  };
};

export const availAdvanceRequestError = function availAdvanceRequestError(
  error
) {
  return {
    type: AVAIL_ADVANCE_REQUEST_ERROR,
    error,
  };
};

export const advanceLoanRequest = function advanceLoanRequest(data) {
  return {
    type: ADVANCE_LOAN_REQUESTING,
    data,
  };
};

export const advanceLoanRequestSuccess = function advanceLoanRequestSuccess(
  resData
) {
  return {
    type: ADVANCE_LOAN_REQUEST_SUCCESS,
    resData,
  };
};

export const advanceLoanRequestError = function advanceLoanRequestError(error) {
  return {
    type: ADVANCE_LOAN_REQUEST_ERROR,
    error,
  };
};

export const advanceListRequest = function advanceListRequest(data) {
  return {
    type: ADVANCE_LIST_REQUESTING,
    data,
  };
};

export const advanceListRequestSuccess = function advanceListRequestSuccess(
  resData
) {
  return {
    type: ADVANCE_LIST_REQUEST_SUCCESS,
    resData,
  };
};

export const advanceListRequestError = function advanceListRequestError(error) {
  return {
    type: ADVANCE_LIST_REQUEST_ERROR,
    error,
  };
};

export const advanceMatchRequest = function advanceMatchRequest(data) {
  return {
    type: ADVANCE_MATCH_REQUESTING,
    data,
  };
};

export const advanceMatchRequestSuccess = function advanceMatchRequestSuccess(
  resData
) {
  return {
    type: ADVANCE_MATCH_REQUEST_SUCCESS,
    resData,
  };
};

export const advanceMatchRequestError = function advanceMatchRequestError(
  error
) {
  return {
    type: ADVANCE_MATCH_REQUEST_ERROR,
    error,
  };
};

export const advanceFeeRequest = function advanceFeeRequest(data) {
  return {
    type: ADVANCE_FEE_REQUESTING,
    data,
  };
};

export const advanceFeeRequestSuccess = function advanceFeeRequestSuccess(
  resData
) {
  return {
    type: ADVANCE_FEE_REQUEST_SUCCESS,
    resData,
  };
};

export const advanceFeeRequestError = function advanceFeeRequestError(error) {
  return {
    type: ADVANCE_FEE_REQUEST_ERROR,
    error,
  };
};

export const cashBalanceSourceRequest = function cashBalanceSourceRequest(
  data
) {
  return {
    type: BALANCE_SOURCE_REQUESTING,
    data,
  };
};

export const cashBalanceSourceRequestSuccess =
  function cashBalanceSourceRequestSuccess(resData) {
    return {
      type: BALANCE_SOURCE_REQUEST_SUCCESS,
      resData,
    };
  };

export const cashBalanceSourceRequestError =
  function cashBalanceSourceRequestError(error) {
    return {
      type: BALANCE_SOURCE_REQUEST_ERROR,
      error,
    };
  };

export const cashBalanceTargetRequest = function cashBalanceTargetRequest(
  data
) {
  return {
    type: BALANCE_TARGET_REQUESTING,
    data,
  };
};

export const cashBalanceTargetRequestSuccess =
  function cashBalanceTargetRequestSuccess(resData) {
    return {
      type: BALANCE_TARGET_REQUEST_SUCCESS,
      resData,
    };
  };

export const cashBalanceTargetRequestError =
  function cashBalanceTargetRequestError(error) {
    return {
      type: BALANCE_TARGET_REQUEST_ERROR,
      error,
    };
  };

export const cashTransferRequest = function cashTransferRequest(
  data,
  extendCallback = null
) {
  return {
    type: CASH_TRANSFER_REQUESTING,
    data,
    extendCallback,
  };
};

export const cashTransferRequestSuccess = function cashTransferRequestSuccess(
  resData
) {
  return {
    type: CASH_TRANSFER_REQUEST_SUCCESS,
    resData,
  };
};

export const cashTransferRequestError = function cashTransferRequestError(
  error
) {
  return {
    type: CASH_TRANSFER_REQUEST_ERROR,
    error,
  };
};

export const bankAccRequest = function bankAccRequest(data) {
  return {
    type: BANK_ACCOUNT_REQUESTING,
    data,
  };
};

export const bankAccRequestSuccess = function bankAccRequestSuccess(resData) {
  return {
    type: BANK_ACCOUNT_REQUEST_SUCCESS,
    resData,
  };
};

export const bankAccRequestError = function bankAccRequestError(error) {
  return {
    type: BANK_ACCOUNT_REQUEST_ERROR,
    error,
  };
};

export const cashOutRequest = function cashOutRequest(data) {
  return {
    type: CASH_OUT_REQUESTING,
    data,
  };
};

export const cashOutRequestSuccess = function cashOutRequestSuccess(resData) {
  return {
    type: CASH_OUT_REQUEST_SUCCESS,
    resData,
  };
};

export const cashOutRequestError = function cashOutRequestError(error) {
  return {
    type: CASH_OUT_REQUEST_ERROR,
    error,
  };
};

export const cashOutReset = function cashOutReset() {
  return {
    type: CASH_OUT_REQUEST_RESET,
  };
};

export const hisTransferRequest = function hisTransferRequest(data) {
  return {
    type: HIS_TRANSFER_REQUESTING,
    data,
  };
};

export const hisTransferRequestSuccess = function hisTransferRequestSuccess(
  resData
) {
  return {
    type: HIS_TRANSFER_REQUEST_SUCCESS,
    resData,
  };
};

export const hisTransferRequestError = function hisTransferRequestError(error) {
  return {
    type: HIS_TRANSFER_REQUEST_ERROR,
    error,
  };
};

export const bankListRequest = function bankListRequest(data) {
  return {
    type: BANK_LIST_REQUESTING,
    data,
  };
};

export const bankListRequestSuccess = function bankListRequestSuccess(resData) {
  return {
    type: BANK_LIST_REQUEST_SUCCESS,
    resData,
  };
};

export const bankListRequestError = function bankListRequestError(error) {
  return {
    type: BANK_LIST_REQUEST_ERROR,
    error,
  };
};

/** debt */

export const debtRequest = function debtRequest(data) {
  return {
    type: DEBT_REQUESTING,
    data,
  };
};

export const debtRequestSuccess = function debtRequestSuccess(resData) {
  return {
    type: DEBT_REQUEST_SUCCESS,
    resData,
  };
};

export const debtRequestError = function debtRequestError(error) {
  return {
    type: DEBT_REQUEST_ERROR,
    error,
  };
};

export const repayLoanRequest = function repayLoanRequest(data) {
  return {
    type: REPAY_LOAN_REQUESTING,
    data,
  };
};

export const repayLoanRequestSuccess = function repayLoanRequestSuccess(
  resData
) {
  return {
    type: REPAY_LOAN_REQUEST_SUCCESS,
    resData,
  };
};

export const repayLoanRequestError = function debtRequestError(error) {
  return {
    type: REPAY_LOAN_REQUEST_ERROR,
    error,
  };
};

export const debtHisRequest = function debtHisRequest(data) {
  return {
    type: DEBT_HIS_REQUESTING,
    data,
  };
};

export const debtHisExtendRequest = function debtHisExtendRequest(data) {
  return {
    type: DEBT_HIS_EXTEND_REQUESTING,
    data,
  };
};

export const debtExtendRequest = (data) => {
  return {
    type: DEBT_EXTEND_REQUESTING,
    data,
  };
};

export const resetDebtExtendRequest = () => {
  return {
    type: DEBT_EXTEND_REQUEST_RESET,
  };
};

export const debtHisRequestSuccess = function debtHisRequestSuccess(resData) {
  return {
    type: DEBT_HIS_REQUEST_SUCCESS,
    resData,
  };
};

export const debtHisRequestError = function debtHisRequestError(error) {
  return {
    type: DEBT_HIS_REQUEST_ERROR,
    error,
  };
};

export const debtHis2Request = function debtHis2Request(data) {
  return {
    type: DEBT_HIS2_REQUESTING,
    data,
  };
};

export const debtHis2RequestSuccess = function debtHis2RequestSuccess(resData) {
  return {
    type: DEBT_HIS2_REQUEST_SUCCESS,
    resData,
  };
};

export const debtHis2RequestError = function debtHis2RequestError(error) {
  return {
    type: DEBT_HIS2_REQUEST_ERROR,
    error,
  };
};

/** bank hold */
export const cashInBankRequest = function cashInBankRequest(data) {
  return {
    type: CASH_IN_BANK_REQUESTING,
    data,
  };
};

export const cashInBankRequestSuccess = function cashInBankRequestSuccess(
  resData
) {
  return {
    type: CASH_IN_BANK_REQUEST_SUCCESS,
    resData,
  };
};

export const cashInBankRequestError = function cashInBankRequestError(error) {
  return {
    type: CASH_IN_BANK_REQUEST_ERROR,
    error,
  };
};

export const holdBankRequest = function holdBankRequest(data) {
  return {
    type: HOLD_BANK_REQUESTING,
    data,
  };
};

export const holdBankRequestSuccess = function holdBankRequestSuccess(resData) {
  return {
    type: HOLD_BANK_REQUEST_SUCCESS,
    resData,
  };
};

export const holdBankRequestError = function holdBankRequestError(error) {
  return {
    type: HOLD_BANK_REQUEST_ERROR,
    error,
  };
};

export const holdListRequest = function holdListRequest(data) {
  return {
    type: HOLD_LIST_REQUESTING,
    data,
  };
};

export const holdListRequestSuccess = function holdListRequestSuccess(resData) {
  return {
    type: HOLD_LIST_REQUEST_SUCCESS,
    resData,
  };
};

export const holdListRequestError = function holdListRequestError(error) {
  return {
    type: HOLD_LIST_REQUEST_ERROR,
    error,
  };
};

/** Right */
export const rightListRequest = function rightListRequest(data) {
  return {
    type: RIGHT_LIST_REQUESTING,
    data,
  };
};

export const rightListRequestSuccess = function rightListRequestSuccess(
  resData
) {
  return {
    type: RIGHT_LIST_REQUEST_SUCCESS,
    resData,
  };
};

export const rightListRequestError = function rightListRequestError(error) {
  return {
    type: RIGHT_LIST_REQUEST_ERROR,
    error,
  };
};

/** Margin list */
export const marginListRequest = function marginListRequest(data) {
  return {
    type: MARGIN_LIST_REQUESTING,
    data,
  };
};

export const marginListRequestSuccess = function marginListRequestSuccess(
  resData
) {
  return {
    type: MARGIN_LIST_REQUEST_SUCCESS,
    resData,
  };
};

export const marginListRequestError = function marginListRequestError(error) {
  return {
    type: MARGIN_LIST_REQUEST_ERROR,
    error,
  };
};

/** gain/loss */

export const resetGainLossRequest = function resetGainLossRequest() {
  return {
    type: GAIN_LOSS_RESET,
  };
};

export const gainLossRequest = function gainLossRequest(data) {
  return {
    type: GAIN_LOSS_REQUESTING,
    data,
  };
};

export const gainLossRequestSuccess = function debtRequestSuccess(resData) {
  return {
    type: GAIN_LOSS_REQUEST_SUCCESS,
    resData,
  };
};

export const gainLossRequestError = function debtRequestError(error) {
  return {
    type: GAIN_LOSS_REQUEST_ERROR,
    error,
  };
};

export const bankInforRequest = function bankInforRequest(
  bankCode = null,
  language = 1
) {
  return {
    type: BANK_INFOR_REQUESTING,
    bankCode,
    language,
  };
};

export const listBankVARequest = function listBankVARequest(params) {
  return {
    type: GET_LIST_BANK_VA_REQUEST,
    data: params,
  };
};

export const listBankVARequestSuccess = function listBankVARequestSuccess(
  listBankVA
) {
  return {
    type: GET_LIST_BANK_VA_SUCCESS,
    listBankVA: listBankVA,
  };
};

export const listBankVARequestError = function listBankVARequestError(error) {
  return {
    type: GET_LIST_BANK_VA_ERROR,
    error: error,
  };
};
