import { CANCEL_ORDER_REQUESTING } from '../privData/constants';
import {
  GET_LIST_PINEFOLIO_REQUEST,
  OPEN_PLAN_DETAIL_MODAL,
  HANDLE_DEPOSIT_PINEFOLIO,
  GET_PINEFOLIO_ORDER_LIST_REQUEST,
  GET_PINEFOLIO_ORDER_LIST_ERROR,
  GET_PINEFOLIO_ORDER_LIST_SUCCESS,
  GET_PINEFOLIO_ORDER_DTL_REQUEST,
  GET_PINEFOLIO_ORDER_DTL_SUCCESS,
  GET_PINEFOLIO_ORDER_DTL_ERROR,
  GET_PINEFOLIO_ORDER_DTL_ST_REQUEST,
  GET_PINEFOLIO_ORDER_DTL_ST_SUCCESS,
  GET_PINEFOLIO_ORDER_DTL_ST_ERROR,
  CANCEL_PINEFOLIO_ORDER_REQUEST,
  CANCEL_PINEFOLIO_ORDER_SUCCESS,
  CANCEL_PINEFOLIO_ORDER_ERROR,
  GET_PINEFOLIO_INVESTMENT_PLAN_REQUEST,
  GET_PINEFOLIO_INVESTMENT_PLAN_SUCCESS,
  GET_PINEFOLIO_INVESTMENT_PLAN_ERROR,
  GET_PINEFOLIO_INVESTMENT_PLAN_HISTORY_REQUEST,
  GET_PINEFOLIO_INVESTMENT_PLAN_HISTORY_SUCCESS,
  GET_PINEFOLIO_INVESTMENT_PLAN_HISTORY_ERROR,
  GET_PF_ANA_REPORT_REQUEST,
  GET_PF_ANA_REPORT_SUCCESS,
  GET_PF_ANA_REPORT_ERROR,
  GET_PF_ANA_REPORT_CAT_TP_REQUEST,
  GET_PF_ANA_REPORT_CAT_TP_SUCCESS,
  GET_PF_ANA_REPORT_CAT_TP_ERROR,
  GET_PF_TD_LIST_REQUESTING,
  GET_PF_TD_LIST_SUCCESS,
  GET_PF_TD_LIST_ERROR,
  GET_PF_IP_LIST_DTL_REQUESTING,
  GET_PF_IP_LIST_DTL_SUCCESS,
  GET_PF_IP_LIST_DTL_ERROR,
  HANDLE_EDIT_PLAN_MONITOR,
  HANDLE_CREATE_PLAN_MONITOR,
  GET_LIST_PINEFOLIO_SUCCESS,
  GET_THEME_DETAIL,
  GET_THEME_DETAIL_SUCCESS,
  RESET_THEME_DETAIL,
  SET_CURRENT_PINEFOLIO,
  RESET_CURRENT_PINEFOLIO,
  GET_PINEFOLIO_PURCHASE_POWER_REQUEST,
  GET_PINEFOLIO_PURCHASE_POWER_REQUEST_SUCCESS,
  SET_PF_ORDER,
  REGIST_PINEFOLIO_SUBACCOUNT_CONTRACT_SUCCESS,
  ORDER_PF_REQUEST,
  ORDER_PF_SUCCESS,
  GET_PF_IP_ONE_REQUESTING,
  GET_PF_IP_ONE_SUCCESS,
  GET_PF_IP_ONE_ERROR,
  DEPOSIT_PF_IP_REQUESTING,
  GET_PF_PORTFOLIO_REQUEST,
  GET_PF_PORTFOLIO_REQUEST_SUCCESS,
  GET_PF_PORTFOLIO_DETAIL_REQUEST,
  GET_PF_PORTFOLIO_DETAIL_REQUEST_SUCCESS,
  RESET_PF_PORTFOLIO_DETAIL_REQUEST,
  GET_PF_LIST_REQUEST,
  GET_PF_LIST_REQUEST_SUCCESS,
  GET_PF_BY_CODE_REQUEST,
  GET_PF_CHART_REQUEST_SUCCESS,
  GET_PF_CHART_REQUEST,
  GET_PF_INTERATE_MST_REQUESTING,
  GET_PF_INTERATE_MST_SUCCESS,
  GET_PF_INTERATE_MST_ERROR,
  GET_EST_PF_PLAN_REQUESTING,
  GET_EST_PF_PLAN_SUCCESS,
  GET_EST_PF_PLAN_ERROR,
  GET_EST_PF_PLAN_OVER_REQUESTING,
  GET_EST_PF_PLAN_OVER_SUCCESS,
  GET_EST_PF_PLAN_OVER_ERROR,
  SAVE_EST_PLAN,
  HANDLE_OPEN_CREATE_PLAN,
  SET_PF_REBALANCE,
  SET_HAD_PINE_ACCOUNT,
  REGIST_PINEFOLIO_SUBACCOUNT_SUCCESS,
  SET_OPEN_PINE_ACCOUNT_POPUP,
  GET_POSITION_OUTSIDE_PF_SUCCESS,
  GET_POSITION_OUTSIDE_PF,
  RELOAD_POSITION_OUTSIDE_PF,
  SET_PF_BOND_DEMO,
  SET_PF_BOND_ORDER,
  GET_UPDATE_PLAN_CONTRACT,
  GET_UPDATE_PLAN_CONTRACT_SUCCESS,
  GET_PLAN_MODIFY_INFO,
  GET_PLAN_MODIFY_INFO_SUCCESS,
  GET_PF_VALUE_INFO_REQUEST,
  GET_PF_VALUE_INFO_SUCCESS,
  GET_PF_LIST_BOND_FOR_SELL_REQUEST,
  GET_PF_LIST_BOND_FOR_SELL_SUCCESS,
  GET_PF_LIST_BOND_FOR_SELL_ERROR,
  SET_PF_BOND_POPUP,
  DEPOSIT_PF_IP_SUCCESS,
  DEPOSIT_PF_IP_ERROR,
} from './constants';

const initialState = {
  pineAccountOpenContract: null,
  hadPineAccount: false,
  openAccPopup: false,

  pineThemes: [],
  pineThemesRequesting: false,

  pineThemeDetail: null,
  currentPinefolio: null,
  currentPinefolioChart: [],

  buyOrder: null,
  sellOrder: null,

  planDetailModalData: [],
  showDepositPinefolioModal: false,
  depositPinefilioInfor: [],

  pinefolioOrderListRequesting: false,
  pinefolioOrderList: [],
  pinefolioOrderListSuccess: false,
  pinefolioOrderListError: [],

  pinefolioOrderDtlRequesting: false,
  pinefolioOrderDtl: [],
  pinefolioOrderDtlSuccess: false,
  pinefolioOrderDtlError: [],

  pinefolioOrderDtlStRequesting: false,
  pinefolioOrderDtlSt: [],
  pinefolioOrderDtlStSuccess: false,
  pinefolioOrderDtlStError: [],

  cancelOrder: null,
  cancelOrderSuccess: false,
  cancelOrderErrors: [],
  purchasePower: null,
  pfOrder: null,
  pfOrderRequest: false,

  pinefolioInvestmentPlan: [],
  pinefolioInvestmentRequesting: false,
  pinefolioInvestmentPlanSuccess: false,
  pinefolioInvestmentPlanError: [],

  pinefolioInvestmentPlanHistory: [],
  pinefolioInvestmentPlanHistoryRequesting: false,
  pinefolioInvestmentPlanHistorySuccess: false,
  pinefolioInvestmentPlanHistoryError: [],

  pfAnaRpt: [],
  pfAnaRptRequesting: false,
  pfAnaRptSuccess: false,
  pfAnaRptError: [],

  pfAnaRptCatTp: [],
  pfAnaRptCatTpRequesting: false,
  pfAnaRptCatTpSuccess: false,
  pfAnaRptCatTpError: [],

  pfTdList: [],
  pfTdListRequesting: false,
  pfTdListSuccess: false,
  pfTdListError: [],

  pfIpDtlList: [],
  pfIpDtlListRequesting: false,
  pfIpDtlListSuccess: false,
  pfIpDtlListError: [],

  showEditPlanModal: false,
  planData: {},

  showCreatePlanModal: false,
  pfInfor: {},

  pfIpOne: [],
  pfIpOneRequesting: false,
  pfIpOneSuccess: false,
  pfIpOneError: [],

  isDepositSuccess: false,
  depositPFIPRequesting: false,
  depositPFIPSuccess: false,
  depositPFIPError: [],
  pfPortfolio: [],
  pfPortfolioDetail: [],

  pfInterateList: [],
  pfInterateListRequest: false,
  pfInterateListSuccess: false,
  pfInterateListError: [],

  estPFPlan: [],
  estPFPlanRequesting: false,
  estPFPlanSuccess: false,
  estPFPlanError: [],

  estPFPlanOver: [],
  estPFPlanOverRequesting: false,
  estPFPlanOverSuccess: false,
  estPFPlanOverError: [],

  openCreatePlanData: [],

  dataSaveEstPlan: [],

  pflist: [],
  pfRebalance: null,

  positionOutSidePF: [],
  positionOutSidePFReload: false,

  pfBondDemoId: null,
  pfBondOrder: null,

  planUpdateContract: [],
  planModifyInfo: [],
  pfValueInfo: [],
  pfValueInfoRequesting: false,
  pfValueInfoSuccess: false,

  pfListBondForSellRequesting: false,
  pfListBondForSell: [],
  pfListBondForSellSuccess: false,
  pfListBondForSellError: [],
  openPFBondListPopup: false,
};

const reducer = function pinefolioReducer(state = initialState, action) {
  switch (action.type) {
    case 'LOG_OUT': {
      return { ...state, hadPineAccount: false, openAccPopup: false };
    }
    case GET_LIST_PINEFOLIO_REQUEST: {
      return {
        ...state,
        pineThemes: [],
        pineThemesRequesting: true,
      };
    }

    case GET_LIST_PINEFOLIO_SUCCESS: {
      return {
        ...state,
        pineThemes: action.data?.list ?? [],
        pineThemesRequesting: false,
      };
    }

    case GET_THEME_DETAIL: {
      return {
        ...state,
        pineThemeDetail: null,
      };
    }

    case GET_THEME_DETAIL_SUCCESS: {
      return {
        ...state,
        pineThemeDetail: action.data,
      };
    }

    case RESET_THEME_DETAIL: {
      return {
        ...state,
        pineThemeDetail: null,
      };
    }

    case SET_CURRENT_PINEFOLIO: {
      return {
        ...state,
        currentPinefolio: action.data,
      };
    }

    case RESET_CURRENT_PINEFOLIO: {
      return {
        ...state,
        currentPinefolio: null,
      };
    }

    case OPEN_PLAN_DETAIL_MODAL: {
      return {
        ...state,
        planDetailModalData: action.data,
      };
    }
    case HANDLE_DEPOSIT_PINEFOLIO: {
      return {
        ...state,
        showDepositPinefolioModal: action.data,
      };
    }

    case GET_PINEFOLIO_ORDER_LIST_REQUEST: {
      return {
        ...state,
        pinefolioOrderListRequesting: true,
        pinefolioOrderList: [],
        pinefolioOrderListSuccess: false,
        pinefolioOrderListError: [],
      };
    }

    case GET_PINEFOLIO_ORDER_LIST_SUCCESS: {
      return {
        ...state,
        pinefolioOrderListRequesting: false,
        pinefolioOrderList: action.pinefolioOrderList.list,
        pinefolioOrderListSuccess: true,
        pinefolioOrderListError: [],
      };
    }

    case GET_PINEFOLIO_ORDER_LIST_ERROR: {
      return {
        ...state,
        pinefolioOrderListRequesting: false,
        pinefolioOrderList: [],
        pinefolioOrderListSuccess: false,
        pinefolioOrderListError: [],
      };
    }

    case GET_PINEFOLIO_ORDER_DTL_REQUEST: {
      return {
        ...state,
        pinefolioOrderDtlRequesting: true,
        pinefolioOrderDtl: [],
        pinefolioOrderDtlSuccess: false,
        pinefolioOrderDtlError: [],
      };
    }

    case GET_PINEFOLIO_ORDER_DTL_SUCCESS: {
      return {
        ...state,
        pinefolioOrderDtlRequesting: false,
        pinefolioOrderDtl: action.pinefolioOrderDtl.list,
        pinefolioOrderDtlSuccess: true,
        pinefolioOrderDtlError: [],
      };
    }

    case GET_PINEFOLIO_ORDER_DTL_ERROR: {
      return {
        ...state,
        pinefolioOrderDtlRequesting: false,
        pinefolioOrderDtl: [],
        pinefolioOrderDtlSuccess: false,
        pinefolioOrderDtlError: [],
      };
    }

    case GET_PINEFOLIO_ORDER_DTL_ST_REQUEST: {
      return {
        ...state,
        pinefolioOrderDtlStRequesting: true,
        pinefolioOrderDtlSt: [],
        pinefolioOrderDtlStSuccess: false,
        pinefolioOrderDtlStError: [],
      };
    }

    case GET_PINEFOLIO_ORDER_DTL_ST_SUCCESS: {
      return {
        ...state,
        pinefolioOrderDtlStRequesting: false,
        pinefolioOrderDtlSt: action.pinefolioOrderDtlSt.list,
        pinefolioOrderDtlStSuccess: true,
        pinefolioOrderDtlStError: [],
      };
    }

    case GET_PINEFOLIO_ORDER_DTL_ST_ERROR: {
      return {
        ...state,
        pinefolioOrderDtlStRequesting: false,
        pinefolioOrderDtlSt: [],
        pinefolioOrderDtlStSuccess: false,
        pinefolioOrderDtlStError: [],
      };
    }

    case CANCEL_PINEFOLIO_ORDER_REQUEST:
      return {
        ...state,
        cancelOrder: null,
        cancelOrderSuccess: false,
        cancelOrderErrors: [],
      };

    case CANCEL_PINEFOLIO_ORDER_SUCCESS:
      return {
        ...state,
        cancelOrder: action.cancelOrder,
        cancelOrderSuccess: true,
        cancelOrderErrors: [],
      };

    case CANCEL_PINEFOLIO_ORDER_ERROR:
      return {
        ...state,
        cancelOrder: null,
        cancelOrderSuccess: false,
        cancelOrderErrors: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };

    case GET_PINEFOLIO_INVESTMENT_PLAN_REQUEST:
      return {
        ...state,
        pinefolioInvestmentRequesting: true,
        pinefolioInvestmentPlan: [],
        pinefolioInvestmentPlanSuccess: false,
        pinefolioInvestmentPlanError: [],
      };

    case GET_PINEFOLIO_INVESTMENT_PLAN_SUCCESS: {
      return {
        ...state,
        pinefolioInvestmentRequesting: false,
        pinefolioInvestmentPlan: action.investmentPlan,
        pinefolioInvestmentPlanSuccess: true,
        pinefolioInvestmentPlanError: [],
      };
    }

    case GET_PINEFOLIO_INVESTMENT_PLAN_ERROR: {
      return {
        ...state,
        pinefolioInvestmentRequesting: false,
        pinefolioInvestmentPlan: [],
        pinefolioInvestmentPlanSuccess: false,
        pinefolioInvestmentPlanError: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };
    }

    case GET_PINEFOLIO_INVESTMENT_PLAN_HISTORY_REQUEST:
      return {
        ...state,
        pinefolioInvestmentPlanHistoryRequesting: true,
        pinefolioInvestmentPlanHistory: [],
        pinefolioInvestmentPlanHistorySuccess: false,
        pinefolioInvestmentPlanHistoryError: [],
      };

    case GET_PINEFOLIO_INVESTMENT_PLAN_HISTORY_SUCCESS: {
      return {
        ...state,
        pinefolioInvestmentPlanHistoryRequesting: false,
        pinefolioInvestmentPlanHistory: action.investmentPlanHistory,
        pinefolioInvestmentPlanHistorySuccess: true,
        pinefolioInvestmentPlanHistoryError: [],
      };
    }

    case GET_PINEFOLIO_INVESTMENT_PLAN_HISTORY_ERROR: {
      return {
        ...state,
        pinefolioInvestmentPlanHistoryRequesting: false,
        pinefolioInvestmentPlanHistory: [],
        pinefolioInvestmentPlanHistorySuccess: false,
        pinefolioInvestmentPlanHistoryError: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };
    }

    case GET_PF_ANA_REPORT_REQUEST: {
      return {
        ...state,
        pfAnaRptRequesting: true,
        pfAnaRpt: [],
        pfAnaRptSuccess: false,
        pfAnaRptError: [],
      };
    }
    case GET_PF_ANA_REPORT_SUCCESS: {
      return {
        ...state,
        pfAnaRptRequesting: false,
        pfAnaRpt: action.pfAnaRpt,
        pfAnaRptSuccess: true,
        pfAnaRptError: [],
      };
    }
    case GET_PF_ANA_REPORT_ERROR: {
      return {
        ...state,
        pfAnaRpt: [],
        pfAnaRptRequesting: false,
        pfAnaRptSuccess: false,
        pfAnaRptError: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };
    }

    case GET_PF_ANA_REPORT_CAT_TP_REQUEST: {
      return {
        ...state,
        pfAnaRptCatTpRequesting: true,
        pfAnaRptCatTp: [],
        pfAnaRptCatTpSuccess: false,
        pfAnaRptCatTpError: [],
      };
    }
    case GET_PF_ANA_REPORT_CAT_TP_SUCCESS: {
      return {
        ...state,
        pfAnaRptCatTpRequesting: false,
        pfAnaRptCatTp: action.pfAnaRptCatTp,
        pfAnaRptCatTpSuccess: true,
        pfAnaRptCatTpError: [],
      };
    }
    case GET_PF_ANA_REPORT_CAT_TP_ERROR: {
      return {
        ...state,
        pfAnaRptCatTp: [],
        pfAnaRptCatTpRequesting: false,
        pfAnaRptCatTpSuccess: false,
        pfAnaRptCatTpError: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };
    }

    case GET_PF_TD_LIST_REQUESTING: {
      return {
        ...state,
        pfTdList: [],
        pfTdListRequesting: true,
        pfTdListSuccess: false,
        pfTdListError: [],
      };
    }
    case GET_PF_TD_LIST_SUCCESS: {
      return {
        ...state,
        pfTdList: action.pfTdList,
        pfTdListRequesting: false,
        pfTdListSuccess: true,
        pfTdListError: [],
      };
    }
    case GET_PF_TD_LIST_ERROR: {
      return {
        ...state,
        pfTdList: [],
        pfTdListRequesting: false,
        pfTdListSuccess: false,
        pfTdListError: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };
    }
    case GET_PF_IP_LIST_DTL_REQUESTING: {
      return {
        ...state,
        pfIpDtlList: [],
        pfIpDtlListRequesting: true,
        pfIpDtlListSuccess: false,
        pfIpDtlListError: [],
      };
    }
    case GET_PF_IP_LIST_DTL_SUCCESS: {
      return {
        ...state,
        pfIpDtlList: action.pfIpDtlList,
        pfIpDtlListRequesting: false,
        pfIpDtlListSuccess: true,
        pfIpDtlListError: [],
      };
    }
    case GET_PF_IP_LIST_DTL_ERROR: {
      return {
        ...state,
        pfIpDtlList: [],
        pfIpDtlListRequesting: false,
        pfIpDtlListSuccess: false,
        pfIpDtlListError: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };
    }

    case HANDLE_EDIT_PLAN_MONITOR: {
      return {
        ...state,
        showEditPlanModal: action.data?.showEditPlanModal,
        planData: action.data?.planData,
      };
    }

    case HANDLE_CREATE_PLAN_MONITOR: {
      return {
        ...state,
        showCreatePlanModal: action.data?.showCreatePlanModal,
        pfInfor: action.data?.pfInfor,
      };
    }
    case GET_PINEFOLIO_PURCHASE_POWER_REQUEST: {
      return {
        ...state,
        purchasePower: null,
      };
    }

    case GET_PINEFOLIO_PURCHASE_POWER_REQUEST_SUCCESS: {
      return {
        ...state,
        purchasePower: action.purchasePower,
      };
    }

    case REGIST_PINEFOLIO_SUBACCOUNT_CONTRACT_SUCCESS: {
      return {
        ...state,
        pineAccountOpenContract: action.data,
      };
    }

    case SET_PF_ORDER: {
      return {
        ...state,
        pfOrder: action.data,
      };
    }

    case ORDER_PF_REQUEST: {
      return {
        ...state,
        pfOrderRequest: true,
      };
    }

    case ORDER_PF_SUCCESS: {
      return {
        ...state,
        pfOrderRequest: false,
        pfOrder: null,
      };
    }

    case GET_PF_IP_ONE_REQUESTING: {
      return {
        ...state,
        pfIpOne: [],
        pfIpOneRequesting: true,
        pfIpOneSuccess: false,
        pfIpOneError: [],
      };
    }
    case GET_PF_IP_ONE_SUCCESS: {
      return {
        ...state,
        pfIpOne: action.pfIpOne,
        pfIpOneRequesting: false,
        pfIpOneSuccess: true,
        pfIpOneError: [],
      };
    }
    case GET_PF_IP_ONE_ERROR: {
      return {
        ...state,
        pfIpOne: [],
        pfIpOneRequesting: false,
        pfIpOneSuccess: false,
        pfIpOneError: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };
    }

    case DEPOSIT_PF_IP_REQUESTING: {
      return {
        ...state,
        isDepositSuccess: false,
        depositPFIPRequesting: true,
        depositPFIPSuccess: false,
        depositPFIPError: [],
      };
    }
    case DEPOSIT_PF_IP_SUCCESS: {
      return {
        ...state,
        isDepositSuccess: action.isDepositSuccess,
        depositPFIPRequesting: false,
        depositPFIPSuccess: true,
        depositPFIPError: [],
      };
    }
    case DEPOSIT_PF_IP_ERROR: {
      return {
        ...state,
        isDepositSuccess: false,
        depositPFIPRequesting: false,
        depositPFIPSuccess: false,
        depositPFIPError: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };
    }

    case GET_PF_PORTFOLIO_REQUEST: {
      return {
        ...state,
        pfPortfolio: [],
      };
    }

    case GET_PF_PORTFOLIO_REQUEST_SUCCESS: {
      return {
        ...state,
        pfPortfolio: action.data?.list || [],
      };
    }

    case GET_PF_PORTFOLIO_DETAIL_REQUEST: {
      return {
        ...state,
        pfPortfolioDetail: null,
      };
    }

    case GET_PF_PORTFOLIO_DETAIL_REQUEST_SUCCESS: {
      return {
        ...state,
        pfPortfolioDetail: action.data?.list || [],
      };
    }

    case RESET_PF_PORTFOLIO_DETAIL_REQUEST: {
      return {
        ...state,
        pfPortfolioDetail: null,
      };
    }

    case GET_PF_LIST_REQUEST: {
      return {
        ...state,
        pflist: [],
      };
    }

    case GET_PF_LIST_REQUEST_SUCCESS: {
      return {
        ...state,
        pflist: action.data?.list || [],
      };
    }

    case GET_PF_BY_CODE_REQUEST: {
      const _pflist = [...state.pflist];
      const _currentPinefolio = _pflist.find((x) => x.pfCode == action.pfCode);
      return {
        ...state,
        currentPinefolio: _currentPinefolio,
      };
    }

    case GET_PF_CHART_REQUEST: {
      return {
        ...state,
        currentPinefolioChart: [],
      };
    }

    case GET_PF_CHART_REQUEST_SUCCESS: {
      return {
        ...state,
        currentPinefolioChart: action.data,
      };
    }

    case GET_PF_INTERATE_MST_REQUESTING: {
      return {
        ...state,
        pfInterateList: [],
        pfInterateListError: [],
      };
    }

    case GET_PF_INTERATE_MST_SUCCESS: {
      return {
        ...state,
        pfInterateList: action.pfInterateList,
        pfInterateListError: [],
      };
    }

    case GET_PF_INTERATE_MST_ERROR: {
      return {
        ...state,
        pfInterateList: [],
        pfInterateListError: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };
    }

    case GET_EST_PF_PLAN_REQUESTING: {
      return {
        ...state,
        estPFPlan: [],
        estPFPlanRequesting: true,
        estPFPlanSuccess: false,
        estPFPlanError: [],
      };
    }

    case GET_EST_PF_PLAN_SUCCESS: {
      return {
        ...state,
        estPFPlan: action.estPFPlan,
        estPFPlanRequesting: false,
        estPFPlanSuccess: true,
        estPFPlanError: [],
      };
    }

    case GET_EST_PF_PLAN_ERROR: {
      return {
        ...state,
        estPFPlan: [],
        estPFPlanRequesting: false,
        estPFPlanSuccess: false,
        estPFPlanError: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };
    }

    case GET_EST_PF_PLAN_OVER_REQUESTING: {
      return {
        ...state,
        estPFPlanOver: [],
        estPFPlanOverRequesting: true,
        estPFPlanOverSuccess: false,
        estPFPlanOverError: [],
      };
    }

    case GET_EST_PF_PLAN_OVER_SUCCESS: {
      return {
        ...state,
        estPFPlanOver: action.estPFPlanOver,
        estPFPlanOverRequesting: false,
        estPFPlanOverSuccess: true,
        estPFPlanOverError: [],
      };
    }

    case GET_EST_PF_PLAN_OVER_ERROR: {
      return {
        ...state,
        estPFPlanOver: [],
        estPFPlanOverRequesting: false,
        estPFPlanOverSuccess: false,
        estPFPlanOverError: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };
    }

    case SAVE_EST_PLAN: {
      return {
        ...state,
        dataSaveEstPlan: action.data,
      };
    }

    case HANDLE_OPEN_CREATE_PLAN: {
      return {
        ...state,
        openCreatePlanData: action.openCreatePlanData,
      };
    }

    case SET_PF_REBALANCE: {
      return {
        ...state,
        pfRebalance: action.pfCode,
      };
    }

    case SET_HAD_PINE_ACCOUNT: {
      return {
        ...state,
        hadPineAccount: action.value,
      };
    }

    case REGIST_PINEFOLIO_SUBACCOUNT_SUCCESS: {
      return {
        ...state,
        hadPineAccount: true,
      };
    }

    case SET_OPEN_PINE_ACCOUNT_POPUP: {
      return {
        ...state,
        openAccPopup: action.value,
      };
    }

    case GET_POSITION_OUTSIDE_PF: {
      return {
        ...state,
        positionOutSidePF: [],
      };
    }

    case GET_POSITION_OUTSIDE_PF_SUCCESS: {
      return {
        ...state,
        positionOutSidePF: action.data,
      };
    }

    case RELOAD_POSITION_OUTSIDE_PF: {
      return {
        ...state,
        positionOutSidePFReload: action.value,
      };
    }

    case SET_PF_BOND_DEMO: {
      return {
        ...state,
        pfBondDemoId: action.data,
      };
    }

    case SET_PF_BOND_ORDER: {
      return {
        ...state,
        pfBondOrder: action.data,
      };
    }

    case GET_UPDATE_PLAN_CONTRACT: {
      return {
        ...state,
        planUpdateContract: [],
      };
    }

    case GET_UPDATE_PLAN_CONTRACT_SUCCESS: {
      return {
        ...state,
        planUpdateContract: action.data,
      };
    }

    case GET_PLAN_MODIFY_INFO: {
      return {
        ...state,
        planModifyInfo: [],
      };
    }

    case GET_PLAN_MODIFY_INFO_SUCCESS: {
      return {
        ...state,
        planModifyInfo: action.data,
      };
    }

    case GET_PF_VALUE_INFO_REQUEST: {
      return {
        ...state,
        pfValueInfo: [],
        pfValueInfoRequesting: true,
        pfValueInfoSuccess: false,
      };
    }

    case GET_PF_VALUE_INFO_SUCCESS: {
      return {
        ...state,
        pfValueInfo: action.data,
        pfValueInfoRequesting: false,
        pfValueInfoSuccess: true,
      };
    }

    case GET_PF_LIST_BOND_FOR_SELL_REQUEST: {
      return {
        ...state,
        pfListBondForSellRequesting: true,
        pfListBondForSell: [],
        pfListBondForSellSuccess: false,
        pfListBondForSellError: [],
      };
    }

    case GET_PF_LIST_BOND_FOR_SELL_SUCCESS: {
      return {
        ...state,
        pfListBondForSellRequesting: false,
        pfListBondForSell: action.data,
        pfListBondForSellSuccess: true,
        pfListBondForSellError: [],
      };
    }

    case GET_PF_LIST_BOND_FOR_SELL_ERROR: {
      return {
        ...state,
        pfListBondForSellRequesting: false,
        pfListBondForSell: [],
        pfListBondForSellSuccess: false,
        pfListBondForSellError: [
          ...[
            {
              body: action.error.toString(),
              time: new Date(),
            },
          ],
        ],
      };
    }

    case SET_PF_BOND_POPUP: {
      return {
        ...state,
        openPFBondListPopup: action.data,
      };
    }
    default:
      return state;
  }
};

export default reducer;
