import React, { Fragment, useContext } from 'react';
import { ReactReduxContext, connect, useSelector } from 'react-redux';
import { withNamespaces } from 'react-i18next';

import PerfectScrollBar from 'react-perfect-scrollbar';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import MaxInput from '../../../components/input/maxInput';
import {
  numberFormat,
  formatDate,
  StringToInt,
  _formatDate2,
  _formatDate,
  getMessageError,
} from '../../../util';
import {
  advanceListRequest,
  advanceMatchRequest,
} from '../../../containers/cash/actions';
import { sumBy, some, isArray } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import {
  setAuth,
  setToast,
  unsetClient,
} from '../../../containers/client/actions';

import PaginationTable from '../../../components/PaginationTable';
import { Logout, logoutRequest } from '../../../components/modal/login/actions';
import { handleApiErrors } from '../../../lib/api-errors';
import { removeCookie } from '../../../lib/storages';
import CashAdvance from '../../../components/modal/cashAdvance';
import AssetHeader from './header/assetHeader';
import OverviewMetric from './header/overviewMetric';

const appUrl = `${process.env.REACT_APP_API_URL}`;

const waitFor = (ms) => new Promise((r) => setTimeout(r, ms));
const asyncForEach = async (array, callback) => {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
};

const UngTruocTienBan = withNamespaces('translations')(
  ({ t, params, loadBalanceHandle }) => {
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    const [advMatch, setAdvMatch] = useState([]);
    const [minAdv, setMinAdv] = useState(10000);
    const [account, setAccount] = useState({});

    const { token, auth } = useSelector((state) => state.client);
    const defaultAccount = useSelector((state) => state.socket.defaultAccount);
    const [refresh, setRefresh] = useState(false);
    const [tabActive, setTabActive] = useState(0);
    const [activeAmount, setActiveAmount] = useState();
    const enabled = StringToInt(activeAmount) > 0;
    const [totalAdv, setTotalAdv] = useState();
    const [chkErr, setChkErr] = useState();
    const { advanceMatch, advanceList } = useSelector((state) => state.cash);
    const { store } = useContext(ReactReduxContext);

    const handleReloadCashAdvance = () => {
      const d = new Date();
      d.setDate(d.getDate() - 30);
      const uuid = uuidv4();
      const resData = {
        group: 'CORE',
        user: token.user,
        session: token.session,
        cmd: 'getAdvanceLoanList',
        rqId: uuid,
        channel: 'WTS',
        data: {
          acntNo: account.acntNo,
          subAcntNo: account.subAcntNo,
          fromDate: formatDate(d, '', 'ymd'),
          toDate: formatDate(new Date(), '', 'ymd'),
        },
      };

      dispatch(advanceListRequest(resData));
    };

    const handleLoadAdvanceMatch = () => {
      const uuid = uuidv4();
      const resData = {
        group: 'CORE',
        user: token.user,
        session: token.session,
        cmd: 'getAdvanceFromMatch',
        rqId: uuid,
        channel: 'WTS',
        data: {
          acntNo: account.acntNo,
          subAcntNo: account.subAcntNo,
        },
      };
      dispatch(advanceMatchRequest(resData));
      setTotalAdv();
    };

    const handleNewAdvLoan = (params) => {
      const url = `${appUrl}/CoreServlet.pt`;
      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
        body: JSON.stringify(params),
      })
        .then(handleApiErrors)
        .then((response) => response.json())
        .then((res) => {
          if (res.http !== 200) {
            if (res.http === 401) {
              if (token) dispatch(logoutRequest(token));

              dispatch(unsetClient());
              removeCookie('token');
              removeCookie('authen');

              dispatch(Logout());
              handleToast(t('txt-valid-loss-session'));
            } else {
              handleToast(getMessageError(res, store.getState));
            }
          } else {
            handleToast('Ứng tiền thành công!');
          }
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    };

    const handleProcFee = () => {
      setShowModal(true);
    };

    useEffect(() => {
      if (!token) return;
      const minAdv =
        token && token['config']
          ? token['config']['minAdv']
            ? StringToInt(token['config']['minAdv'])
            : 10000
          : 10000;
      setMinAdv(minAdv);
      if (defaultAccount) {
        handleReloadCashAdvance();
        handleLoadAdvanceMatch();
      }
    }, []);

    useEffect(() => {
      if (advanceMatch && isArray(advanceMatch)) {
        const _advMatch = [...advanceMatch];
        if (!!_advMatch.length) {
          _advMatch.map((item) => {
            const uuid = uuidv4();
            item.amt = '';
            item._calcInt = 0;
            item.key = uuid;
          });
        }

        setAdvMatch(_advMatch);
      }
    }, [defaultAccount, advanceMatch]);

    useEffect(() => {
      handleReloadCashAdvance();
      handleLoadAdvanceMatch();
      setTotalAdv();
    }, [account]);

    useEffect(() => {
      handleReloadCashAdvance();
      handleLoadAdvanceMatch();
      setChkErr([]);
      setTotalAdv();
    }, [refresh]);

    const handleCloseModal = () => {
      setShowModal(false);
    };

    const handleAcceptLoan = async () => {
      await asyncForEach(advMatch, async (element) => {
        if (StringToInt(element.amt) > 0) {
          await waitFor(200);
          const uuid = uuidv4();
          const params = {
            group: 'CORE',
            user: token.user,
            session: token.session,
            cmd: 'newAdvanceLoan',
            rqId: uuid,
            channel: 'WTS',
            type: auth.type,
            token: auth.token,
            data: {
              acntNo: account.acntNo,
              subAcntNo: account.subAcntNo,
              ordrDt: _formatDate(element.ordrDt),
              amt: StringToInt(element.amt) + '',
              type: '2', // 1.include 2.exclude
            },
          };

          handleNewAdvLoan(params);
        }
      });
      await waitFor(100);
      handleCloseModal();

      handleReloadCashAdvance();
      handleLoadAdvanceMatch();
    };

    const handleChange = (value, name) => {
      setActiveAmount(value);
      console.log('advMatch', value);
      if (!advMatch) return;
      advMatch.map((item) => {
        if (item.key !== name) return item;
        item.amt = value;
        item.err = '';
        item._calcInt = 0;
        console.log('advMatchitem', item);
        return item;
      });
      setChkErr(some(advMatch, (o) => o.err && o.err.length > 0));
      setTotalAdv(sumBy(advMatch, (o) => StringToInt(o.amt)));
      setAdvMatch(advMatch);
    };

    const handleBlur = (name) => {
      if (!advMatch) return;

      advMatch.map((item) => {
        if (item.key !== name) return item;
        const _max = item.advanceAvail - item.exptInt;
        item.err =
          StringToInt(item.amt) > _max
            ? t('txt-valid-max-amount') + ':' + numberFormat(_max, 0, '0')
            : StringToInt(item.amt) < minAdv
            ? t('txt-valid-min-amount') + ': ' + numberFormat(minAdv, 0, '0')
            : '';
        return item;
      });
      setChkErr(some(advMatch, (o) => o.err && o.err.length > 0));
      setTotalAdv(sumBy(advMatch, (o) => StringToInt(o.amt)));
      setAdvMatch(advMatch);
    };

    const handleClear = () => {
      setActiveAmount();
      advMatch.map((item) => {
        item.amt = '';
        item.err = '';
        item._calcInt = 0;
        return item;
      });

      console.log('advMatch', advMatch);

      setChkErr(some(advMatch, (o) => o.err && o.err.length > 0));
      setTotalAdv(sumBy(advMatch, (o) => StringToInt(o.amt)));
      setAdvMatch(advMatch);
    };

    const handleToast = (msg) => {
      const toastMsg = {
        id: Math.random(),
        msg: msg,
        title: t('txt-notice'),
      };
      dispatch(setToast(toastMsg));
    };

    const handleAuthen = () => {
      dispatch(setAuth());
    };

    const columns = [
      {
        key: 'defaultAccount',
        text: t('txt-sub-account'),
        className: 'text-center',
        align: 'center',
        cell: (record) => {
          return <span>{record && record.subAcntNo}</span>;
        },
      },
      {
        key: 'loanAmt',
        text: t('txt-label-amount-advance'),
        className: 'text-right text--light fw-500',
        align: 'center',
        cell: (record) => {
          return numberFormat(record.loanAmt, 0, '0');
        },
      },
      {
        key: 'loanAmtFee',
        text: t('txt-label-amount-deducted'),
        className: 'text-right text--light fw-500',
        align: 'center',
        cell: (record) => {
          return numberFormat(
            StringToInt(record.loanAmt) + StringToInt(record.loanInt),
            0,
            '0'
          );
        },
      },
      {
        key: 'loanInt',
        text: t('txt-label-fee-service'),
        className: 'text-right text--light fw-500',
        align: 'center',
        cell: (record) => {
          return numberFormat(record.loanInt, 0, '0');
        },
      },
      {
        text: t('txt-label-fee-management'),
        className: 'text-right fw-500 text--light',
        align: 'center',
      },
      {
        key: 'exprDt',
        text: t('txt-label-expired-date'),
        className: 'text-center',
        align: 'center',
      },
      {
        key: 'loanDt',
        text: t('txt-label-create-date'),
        className: 'text-center',
        align: 'center',
      },
      {
        key: 'status',
        text: t('txt-status'),
        className: (record) => {
          return 'text-center ' + (record.status === '1' ? 'i' : 'd');
        },
        align: 'center',
        cell: (record) => {
          return record.status === '1'
            ? t('txt-label-repay')
            : t('txt-label-unrepay');
        },
      },
    ];

    return (
      <div className="personal-assets card-panel-2 cash-advance">
        <AssetHeader
          account={account}
          setAccount={setAccount}
          params={params}
          setRefresh={setRefresh}
          tabActive={tabActive}
          refresh={refresh}
        />
        <div className="personal-assets-body card-panel-2-body">
          <OverviewMetric
            loadBalanceHandle={loadBalanceHandle}
            account={account}
          />
          <Fragment>
            <div className="d-flex flex-row" style={{ marginTop: '36px' }}>
              <span className="mr-auto text-white text-title">
                {t('txt-cash-advance')}
              </span>
            </div>
            <div className="d-flex flex-column ">
              <table className="table table-bordered mt-3 table-fix">
                <thead>
                  <tr>
                    <th rowSpan={2} className="text-center">
                      {t('txt-label-sell-date')}
                    </th>
                    <th rowSpan={2} className="text-center">
                      {t('txt-label-settlement-date')}
                    </th>
                    <th rowSpan={2} className="text-center">
                      {t('cashAdvance.settlementAmount')}
                    </th>
                    <th rowSpan={2} className="text-center">
                      {t('cashAdvance.advanceMaxAmount')}
                    </th>
                    <th className="text-center">
                      {t('txt-label-amount-advance')}
                    </th>
                  </tr>
                  <tr>
                    <th className="text-center">
                      {numberFormat(totalAdv, 0, 0)}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {advMatch &&
                    !!advMatch.length &&
                    advMatch.map((item, index) => (
                      <tr key={item.key}>
                        <td className="text-center">{item.ordrDt}</td>
                        <td className="text-center">
                          {_formatDate2(item.exprDt)}
                        </td>
                        <td className="text-right text--light fw-500">
                          {numberFormat(item.trdAmt, 0, '0')}
                        </td>
                        <td className="text-right text--light fw-500">
                          {numberFormat(item.advanceAvail, 0, '0')}
                        </td>
                        <td style={{ width: '100%' }}>
                          <MaxInput
                            className="px-0"
                            max={item.advanceAvail - item.exptInt}
                            initValue={item.amt}
                            index={item.key}
                            callback={(amount = 0) => {
                              handleChange(amount, item.key);
                              handleBlur(item.key);
                            }}
                            placeholder={`${numberFormat(
                              item.advanceAvail - item.exptInt
                            )}`}
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              <div className="mt-2 d-flex flex-row-reverse text-white">
                {auth && auth.isVerified ? (
                  <>
                    <button
                      className="btn btn--primary fw-500"
                      disabled={
                        !StringToInt(activeAmount) > 0 ||
                        !totalAdv ||
                        totalAdv < 1 ||
                        chkErr
                      }
                      onClick={handleProcFee}
                    >
                      {t('txt-label-calculator-fee')}
                    </button>
                    <button
                      onClick={handleClear}
                      className="btn btn--light mr-2"
                    >
                      {t('txt-clear')}
                    </button>
                  </>
                ) : (
                  <button
                    variant="warning"
                    className="btn btn--authen fw-500 w-140"
                    onClick={() => handleAuthen()}
                  >
                    {t('txt-xacthuc')}
                  </button>
                )}
              </div>
            </div>
            <div
              className="d-flex flex-column high-label"
              style={{ height: 'calc(100% - 315px)' }}
            >
              <span className="mr-auto text-white text-title">
                {t('txt-label-history')}
              </span>
              <PerfectScrollBar>
                <PaginationTable
                  defaultAccount={account}
                  pageSize={15}
                  columns={columns}
                  source={
                    advanceList && advanceList.list ? advanceList.list : []
                  }
                  hasPaging={true}
                />
              </PerfectScrollBar>
            </div>
            {showModal && (
              <CashAdvance
                account={account}
                advMatch={advMatch}
                showModal={showModal}
                handleCloseModal={handleCloseModal}
                handleAcceptLoan={handleAcceptLoan}
              />
            )}
          </Fragment>
        </div>
      </div>
    );
  }
);

export default UngTruocTienBan;
