import React, { useState, useEffect } from 'react';
import { isWeekend } from 'date-fns';
import { remove } from 'lodash';
import { translate } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { connect, useDispatch, useSelector } from 'react-redux';
import {
  indSumRequest,
  indexRequest,
  indexRequestSuccess,
  worldIndexRequest,
} from '../../../containers/banggia/actions';
import { setSetting, setTypeIndex } from '../../../containers/client/actions';
import {
  makeDataForeign,
  makeGetIndexList,
  makeGetIndexSummary,
  makeGetSetting,
  makeGetTotalGD,
  makeGetTypeIndex,
  makeGetSocketStatus,
} from '../../../lib/selector';
import { saveState } from '../../../lib/storages';
import {
  MARKET,
  isMarketOpen,
  numberFormat,
  getWindowDimensions,
} from '../../../util';
import CardChart from './layout/cardChart';
import CardInfo from './layout/cardInfo';
// import '../../../assets/scss/components/priceboard/IndexIndicator.scss';
import { withNamespaces } from 'react-i18next';
import ExchangeModal from './layout/exchangeModal';

const INTERVAL_RELOAD_TIME = 1000 * 60; // 1 minute
const TODAY = new Date();

const IndexIndicator = withNamespaces('translations')(({ t }) => {
  const dispatch = useDispatch();
  const serverStatus = useSelector(makeGetSocketStatus());
  const setting = useSelector(makeGetSetting());
  const dataForeign = useSelector(makeDataForeign());
  const totalGd = useSelector(makeGetTotalGD());
  // const indexList = useSelector(makeGetIndexList());
  const indSum = useSelector(makeGetIndexSummary());
  const typeIndex = useSelector(makeGetTypeIndex());
  const successful = useSelector((state) => state.priceBoard.successful);
  const worldListSuccess = useSelector(
    (state) => state.priceBoard.worldListSuccess
  );
  const { indHid } = setting;

  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [pageSize, setPageSize] = useState(4);
  const [data, setData] = useState([]);
  const [exchangeModal, setExchangeModal] = useState(false);
  const [exchangeCode, setExchangeCode] = useState();
  const [indexList, setIndexList] = useState([]);

  const summaryTrade = {
    head: t('txt-overview'),
    subHead: t('txt-all-market'),
    data: [
      {
        name: t('txt-total-transaction'),
        value: `${numberFormat(totalGd / 1000, 0)} (${t('txt-label-billion')})`,
        cl: 'text-white',
      },
      {
        name: t('txt-total-putThrough'),
        value: `${
          indSum ? numberFormat(indSum.ptvalue / 1000000, 0, '-') : '-'
        } (${t('txt-label-billion')})`,
        cl: 'text-white',
      },
      {
        name: t('txt-total-foreign'),
        value: `${
          indSum ? numberFormat(indSum.frvalue / 1000000, 0, '-') : '-'
        } (${t('txt-label-billion')})`,
        cl: 'text-white',
      },
    ],
  };

  const summaryForeign = {
    head: t('txt-international'),
    subHead: 'US Indices',
    data: dataForeign,
  };

  useEffect(() => {
    const { width } = getWindowDimensions();
    if (width > 4000) setPageSize(6);
    else if (width > 2500) setPageSize(5);
    else if (width > 1440) setPageSize(4);
    else if (width > 1200) setPageSize(3);
    else if (width > 480) setPageSize(2);
    else setPageSize(1);

    fetchDomesticIndex();
    fetchWorldIndex();

    const interval = setInterval(() => {
      if (isMarketOpen() && !isWeekend(TODAY)) {
        fetchDomesticIndex();
      }
      if (!isWeekend(TODAY)) {
        fetchWorldIndex();
      }
    }, INTERVAL_RELOAD_TIME);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (serverStatus == 'on') {
      fetchDomesticIndex();
      fetchWorldIndex();
    }
  }, [serverStatus]);

  useEffect(() => {
    if (successful && worldListSuccess) {
      let _data = [];
      if (indexList && indexList.length > 0)
        indexList.forEach((item, i) => {
          if (item) {
            _data.push({
              type: 1,
              item: (
                <CardChart
                  key={i}
                  idata={item}
                  typeIndex={typeIndex}
                  closeIndex={setIndexHideHandle}
                  width={100 / pageSize}
                  setExchangeCode={setExchangeCode}
                  setExchangeModal={setExchangeModal}
                />
              ),
            });
          }
        });

      _data.push({
        type: 2,
        item: (
          <CardInfo
            idata={summaryTrade}
            typeIndex={typeIndex}
            width={100 / pageSize}
          />
        ),
      });

      // if (dataForeign && dataForeign.length > 0)
      _data.push({
        type: 3,
        item: (
          <CardInfo
            idata={summaryForeign}
            typeIndex={typeIndex}
            isPagging={true}
            width={100 / pageSize}
          />
        ),
      });

      setData(_data);
      setTotalPage(
        _data.length % pageSize == 0
          ? _data.length / pageSize
          : Math.floor(_data.length / pageSize) + 1
      );
    }
  }, [successful, worldListSuccess, typeIndex, indexList]);

  const fetchDomesticIndex = () => {
    fetchIndexList();
    fetchIndexSummary();
  };

  const fetchIndexList = () => {
    const mcs = '10,11,02,03';
    const callback = (data) => {
      console.log('data', data);

      let sortIndexInDerivative = ['10', '11', '02', '03'],
        sortIndexList = data;
      if (!!data?.length && window.location.hash == '#/home/derivative') {
        const _data = [];

        sortIndexInDerivative.forEach((a) => {
          const _ = data.find((x) => x.mc == a);
          _data.push(_);
        });

        if (!!_data.length) sortIndexList = _data;
        console.log('_sortIndexList ', _data);
      }

      setIndexList(sortIndexList);
    };
    return dispatch(indexRequest(mcs, callback));
  };

  const fetchWorldIndex = () => {
    return dispatch(worldIndexRequest());
  };

  const fetchIndexSummary = () => {
    return dispatch(indSumRequest());
  };

  const setTypeIndexHandle = (typeIndex) => {
    dispatch(setTypeIndex(typeIndex));
    saveState('setting', { ...setting, typeIndex: typeIndex });
  };

  const setIndexHideHandle = (indexCode) => {
    let _setting = Object.assign({}, setting);

    let { indHid } = _setting;
    if (!indHid) indHid = [];
    indHid.push(indexCode);
    _setting.indHid = indHid;
    dispatch(setSetting(_setting));
    saveState('setting', _setting);
  };

  const indexIndicatorSizeHandle = (isLarge = true) => {
    setTypeIndexHandle(isLarge ? 1 : 2);
  };

  const changePage = (isBack = true) => {
    if (isBack) {
      if (page == 1) setPage(totalPage);
      else setPage(page - 1);
    } else {
      if (page == totalPage) setPage(1);
      else setPage(page + 1);
    }
  };

  const renderIndexes = () => {
    const _data = data.slice(
      (page - 1) * pageSize,
      (page - 1) * pageSize + pageSize
    );
    return (
      <div className="paging">
        {_data.map((idata, i) => (
          <>{idata.item}</>
        ))}
      </div>
    );
  };

  return (
    <>
      <div className="indicator-index">
        {typeIndex == 1 && (
          <>
            <div className="market-index">{renderIndexes()}</div>

            <div className="switch-view">
              <div className="d-flex align-items-center switch-view-button">
                <span
                  className="icon iNext"
                  onClick={() => changePage(false)}
                ></span>
              </div>
              <div
                className="d-flex align-items-center switch-view-button"
                onClick={() => indexIndicatorSizeHandle(false)}
              >
                <span className="icon iSmall"></span>
              </div>
              <div className="d-flex align-items-center switch-view-button">
                <span
                  className="icon iPrevious"
                  onClick={() => changePage(true)}
                ></span>
              </div>
            </div>
          </>
        )}
        {typeIndex != 1 && (
          <>
            <div className="market-index market-index--small">
              <div className="d-flex align-items-center switch-view-button--left">
                <span
                  className="icon iPrevious"
                  onClick={() => changePage(false)}
                ></span>
              </div>

              {page == 1 && (
                <div className="paging">
                  <div className="d-flex align-items-center ">
                    {indexList &&
                      !!indexList.length &&
                      indexList.map((idata, i) => {
                        if (idata) {
                          if (indHid && indHid.indexOf(idata.mc) > -1)
                            return null;
                          return (
                            <CardChart
                              key={idata.mc + i}
                              idata={idata}
                              typeIndex={typeIndex}
                              closeIndex={setIndexHideHandle}
                            />
                          );
                        }
                      })}
                    {<CardInfo idata={summaryTrade} typeIndex={typeIndex} />}
                    {/* {<CardInfo idata={summaryForeign} typeIndex={typeIndex} />} */}
                  </div>
                </div>
              )}
              {page > 1 && (
                <div className="paging">
                  <div className="d-flex align-items-center ">
                    {<CardInfo idata={summaryForeign} typeIndex={typeIndex} />}
                  </div>
                </div>
              )}
              <div className="d-flex align-items-center switch-view-button--right">
                <span
                  className="icon iNext"
                  onClick={() => changePage(true)}
                ></span>
              </div>
            </div>
            <div className="switch-view">
              <div
                className="d-flex align-items-center switch-view-button"
                onClick={() => indexIndicatorSizeHandle(true)}
              >
                <span className="icon iLarge"></span>
              </div>
            </div>
          </>
        )}
      </div>
      <ExchangeModal
        symbol={exchangeCode}
        exchangeModal={exchangeModal}
        setExchangeModal={setExchangeModal}
      />
    </>
  );
});

export default IndexIndicator;
