import React, { useEffect, useRef, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ReactSelect from 'react-select';
import { filter, find, map, sum, sumBy } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import {
  allOrdRequest,
  getPriceboardCommonRequest,
} from '../../banggia/actions';
// import DatePicker from '../../../components/datePicker2';
import DatePicker from '../../../components/datePicker3';
import {
  _convertTpToPrice,
  _getOrderStatus,
  numberFormat,
  ordStatus,
  canEditOrder,
  canDeleteOrder,
  _formatDate2,
  getCurrentDateMinusDays,
  _convertformatDate2,
} from '../../../util';
import {
  cancelOrderRequest,
  editOrderRequest,
  getPrivDataRequest,
  matchOrderRequest,
  symbolInfoRequest,
} from '../../privData/actions';
import { setAuth } from '../../client/actions';
import { stockDetailRequest } from '../../../components/modal/stockDetail/actions';
import FormDeleteOrder from '../../../components/modal/order/deleteOrder';
import FormEditOrder from '../../../components/modal/order/editOrder';
import ScrollSKL from '../../../components/trading/components/ScrollSKL';
import PerfectScrollBar from 'react-perfect-scrollbar';
import { useMemo } from 'react';
import moment from 'moment';
import { constants } from '../../../util/constant';

// Normal Order History
const NormalOrderHistory = withNamespaces('translations')(
  ({ t, hasAction = true }) => {
    const dispatch = useDispatch();
    // const listOrder = useSelector((state) => state.priceBoard?.allOrder);
    // const matchOrder = useSelector((state) => state.privData.matchOrder?.data);
    const {
      auth,
      token,
      setting: { lang } = 'vi',
    } = useSelector((state) => state.client);

    const { listAccount, defaultAccount } = useSelector(
      (state) => state.socket
    );
    const [account, setAccount] = useState(defaultAccount);
    const [showModalDel, setShowModalDel] = useState();
    const [showModal, setShowModal] = useState();
    const [order, setOrder] = useState();
    const [ordSel, setOrdSel] = useState();
    const [orderList, setOrderList] = useState();
    const [matchOrder, setMatchOrder] = useState();

    const sumOrderVol = useMemo(() => {
      return orderList ? sumBy(orderList, 'ordrQty') : 0;
    }, [orderList]);

    const sumOrderMatchVol = useMemo(() => {
      return orderList ? sumBy(orderList, 'matchedQty') : 0;
    }, [orderList]);

    const sumDivdQty = useMemo(() => {
      return orderList ? sumBy(orderList, 'divdQty') : 0;
    }, [orderList]);

    const sumVol = useMemo(() => {
      return matchOrder && !!matchOrder.list
        ? sum(map(matchOrder.list, (o) => o.matchQty))
        : 0;
    }, [matchOrder]);

    const sumPrice = useMemo(() => {
      return !ordSel
        ? 0
        : ordSel.buySelTp === '1'
        ? matchOrder && !!matchOrder.list
          ? sum(map(matchOrder.list, (o) => o.matchQty * o.matchPrc + o.feeAmt))
          : 0
        : matchOrder && !!matchOrder.list
        ? sum(
            map(
              matchOrder.list,
              (o) => o.matchQty * o.matchPrc - o.tax - o.feeAmt - o.divdDltx
            )
          )
        : 0;
    }, [matchOrder, ordSel]);

    const sumValAfterTaxFee = useMemo(() => {
      return orderList ? sumBy(orderList, 'valAfterTaxFee') : 0;
    }, [orderList]);

    useEffect(() => {
      if (account) console.log('change account to ', account?.subAcntNo);
    }, [account]);

    const getOrderListHandle = (
      account,
      symbol,
      orderType,
      ordrStatTp,
      frDt,
      toDt
    ) => {
      if (!account || !token) return;
      const uuid = uuidv4();
      const params = {
        group: 'CORE',
        cmd: 'getAllOrderList',
        user: token.user,
        session: token.session,
        rqId: uuid,
        channel: 'WTS',
        data: {
          custNo: token.cif,
          subAcntNo: account.subAcntNo,
          symbol: symbol,
          frDt: frDt,
          toDt: toDt,
          ordrStatTp: ordrStatTp,
          ordrTp: orderType,
        },
      };

      setAccount(account);
      const callback = (data) => {
        if (data?.list && Array.isArray(data?.list)) {
          setOrderList(data.list);
        }
      };

      dispatch(getPriceboardCommonRequest(params, callback, true));
    };

    const _handleOrderDetail = (record) => {
      if (!record || !record.ordrNo) return;
      if (account) {
        const uuid = uuidv4();
        const currentDate = new Date();

        const resData = {
          group: 'CORE',
          cmd: 'GetMatchOrder',
          user: token.user,
          session: token.session,
          rqId: uuid,
          channel: 'WTS',
          data: {
            acntNo: account.acntNo,
            subAcntNo: account.subAcntNo,
            ordrNo: record.ordrNo + '',
            trdDt:
              record.trdDate ||
              _convertformatDate2(moment(currentDate).format('DD/MM/YYYY')),
          },
        };

        const callback = (order) => {
          console.log('order ===> ', order);
          setMatchOrder(order);
        };
        dispatch(getPrivDataRequest(resData, callback, true));
      }

      setOrdSel(record);
    };

    const _handleDeleteOrder = (orderNo) => {
      const _order = find(orderList.list, (o) => o.ordrNo === orderNo);
      if (_order) {
        dispatch(stockDetailRequest(_order.symbol));
        setShowModalDel(true);
        setOrder(_order);
      }
    };

    const _handleAuthen = () => {
      dispatch(setAuth());
    };

    const _handleEditOrder = (orderNo) => {
      const _order = find(orderList.list, (o) => o.ordrNo === orderNo);
      if (_order) {
        _handleLoadSymbolInfo(_order.symbol);
        dispatch(stockDetailRequest(_order.symbol));
        setShowModal(true);
        setOrder(_order);
      }
    };

    const _handleLoadSymbolInfo = (symbol) => {
      if (account) {
        const uuid = uuidv4();

        const params = {
          group: 'CORE',
          user: token.user,
          session: token.session,
          cmd: 'getSymbolInfo',
          rqId: uuid,
          channel: 'WTS',
          data: {
            acntNo: account.acntNo,
            subAcntNo: account.subAcntNo,
            symbol: symbol,
          },
        };
        dispatch(symbolInfoRequest(params));
      }
    };

    const _handleCloseModal = () => {
      setShowModal(false);
      setOrder(null);
    };

    const _handleCloseModalDel = () => {
      setShowModalDel(false);
      setOrder(null);
    };

    const _handleSubmitEditOrder = (params) => {
      dispatch(editOrderRequest(params));
      setShowModal(false);
      setOrder(null);
    };

    const _handleSubmitDeleteOrder = (params) => {
      dispatch(cancelOrderRequest(params));
      setShowModalDel(false);
      setOrder(null);
    };

    let columns = [
      {
        key: 'ordrNo',
        text: t('txt-order-no'),
        className: 'text-center text--light3',
        align: 'center',
      },
      {
        key: 'pfOrderID',
        text: 'PF ' + t('txt-order-no'),
        columnStyle: account?.subAcntNo?.startsWith(
          constants.subAccount.Pinefolio
        )
          ? {}
          : { display: 'none' },
        className: `text-center text--light3 ${
          account?.subAcntNo?.startsWith(constants.subAccount.Pinefolio)
            ? ''
            : 'd-none'
        }`,
        align: 'center',
      },
      {
        key: 'origOrdrNo',
        text: t('txt-origin-order-no'),
        className: 'text-center text--light3',
        align: 'center',
      },
      {
        key: 'trdDate',
        text: t('derivative.orderHistory.tradeDate'),
        className: 'text-center text--light3',
        align: 'center',
        cell: (record) => {
          return _formatDate2(record.trdDate);
        },
      },
      {
        key: 'wrkTime',
        text: t('derivative.orderHistory.orderTime'),
        className: 'text-center text--light3',
        align: 'center',
        cell: (record) => {
          return record.wrkTime
            ? record.wrkTime.split(' ').length > 1
              ? record.wrkTime.split(' ')[1]
              : record.wrkTime.split(' ')
            : '';
        },
      },
      {
        key: 'buySelTp',
        text: t('txt-trade-acronym'),
        className: 'text-center w_75',
        align: 'center',
        cell: (record) => {
          return (
            <div className={record.buySelTp === '1' ? 'i' : 'd'}>
              {record.buySelTp === '1' ? t('txt-buy') : t('txt-sell')}
            </div>
          );
        },
      },
      {
        key: 'symbol',
        text: t('txt-symbol'),
        className: 'text-center text-light fw-500',
        align: 'center',
      },
      {
        key: 'ordrTrdTp',
        text: t('txt-type'),
        className: 'text-center text--light3',
        align: 'center',
        cell: (record) => {
          return _convertTpToPrice(record.ordrTrdTp);
        },
      },
      {
        key: 'ordrUntprc',
        text: t('txt-price'),
        className: 'text-right text-light fw-500',
        align: 'center',
        cell: (record) => {
          return numberFormat(
            record.ordrUntprc ? record.ordrUntprc / 1000 : 0,
            2
          );
        },
      },
      {
        key: 'ordrQty',
        text: t('txt-vol'),
        className: 'text-right text-light',
        align: 'center',
        cell: (record) => {
          return numberFormat(record.ordrQty);
        },
      },
      {
        key: 'matchedQty',
        text: t('txt-match-vol'),
        className: 'text-right text-light',
        align: 'center',
        cell: (record) => {
          return numberFormat(record.matchedQty, 0, '0');
        },
      },
      {
        key: 'valAfterTaxFee',
        text: t('txt-val-after-tax-fee'),
        className: 'text-right text-light',
        align: 'center',
        cell: (record) => {
          return numberFormat(record.valAfterTaxFee, 0, '0');
        },
      },
      {
        key: 'divdQty',
        text: t('txt-dividend-match-vol'),
        className: 'text-right text-light',
        align: 'center',
        cell: (record) => {
          return numberFormat(record.divdQty, 0, '0');
        },
      },
      {
        key: 'ordrStatTp',
        text: t('txt-status'),
        className: (record) => {
          return 'text-center ordStt_' + record.ordrStatTp;
        },
        align: 'center',
        cell: (record) => {
          return _getOrderStatus(
            record.ordrStatTp,
            record.ordrQty,
            record.matchedQty
          );
        },
      },
    ];

    if (hasAction)
      columns = [
        ...columns,
        {
          key: 'action',
          text: t('txt-actions'),
          cell: (record) => {
            return auth && auth.isVerified ? (
              <div className="d-flex align-items-center justify-content-center">
                {canEditOrder(
                  record.ordrStatTp,
                  record.ordrQty,
                  record.matchedQty,
                  record.ordrTrdTp
                ) ? (
                  <span
                    className="d-flex align-items-center justify-content-center btn btn--edit mr-1"
                    onClick={() => _handleEditOrder(record.ordrNo)}
                  >
                    <span className="icon iEdit text-muted"></span>
                  </span>
                ) : (
                  <span className="d-flex align-items-center justify-content-center btn btn--edit disabled mr-1">
                    <span className="icon iEdit text-muted"></span>
                  </span>
                )}
                {canDeleteOrder(
                  record.ordrStatTp,
                  record.ordrQty,
                  record.matchedQty
                ) ? (
                  <span
                    className="d-flex align-items-center justify-content-center btn btn--delete ml-1"
                    onClick={() => _handleDeleteOrder(record.ordrNo)}
                  >
                    <span className="icon iCancel text-muted d "></span>
                  </span>
                ) : (
                  <span className="d-flex align-items-center justify-content-center btn btn--delete disabled ml-1">
                    <span className="icon iCancel text-muted d "></span>
                  </span>
                )}
              </div>
            ) : (
              <div className="d-flex align-items-center justify-content-center">
                {canEditOrder(
                  record.ordrStatTp,
                  record.ordrQty,
                  record.matchedQty,
                  record.ordrTrdTp
                ) ? (
                  <span
                    className="d-flex align-items-center justify-content-center btn btn--authen mr-1"
                    onClick={() => _handleAuthen()}
                  >
                    <span className="icon iEdit text-muted"></span>
                  </span>
                ) : (
                  <span className="d-flex align-items-center justify-content-center btn btn--edit disabled ml-1">
                    <span className="icon iEdit text-muted"></span>
                  </span>
                )}
                {canDeleteOrder(
                  record.ordrStatTp,
                  record.ordrQty,
                  record.matchedQty
                ) ? (
                  <span
                    className="d-flex align-items-center justify-content-center  btn btn--authen ml-1"
                    onClick={() => _handleAuthen()}
                  >
                    <span className="icon iCancel text-muted"></span>
                  </span>
                ) : (
                  <span className="d-flex align-items-center justify-content-center  btn btn--delete disabled ml-1">
                    <span className="icon iCancel text-muted"></span>
                  </span>
                )}
              </div>
            );
          },
        },
      ];

    return (
      <>
        <Filter filterCallback={getOrderListHandle} />
        <PerfectScrollBar style={{ height: 'calc(100% - 50px)' }}>
          <ScrollSKL
            tblClassName="tbl-list"
            divClassName="mt-0 h-100"
            onRowClicked={_handleOrderDetail}
            pageSize={15}
            columns={columns}
            source={orderList}
            ordSel={ordSel}
            auth={auth}
            detail={matchOrder}
            handleDeleteOrder={_handleDeleteOrder}
            handleAuthen={_handleAuthen}
            expandColSpan={
              account?.subAcntNo?.startsWith(constants.subAccount.Pinefolio)
                ? 14
                : 13
            }
            handleEditOrder={_handleEditOrder}
            sum={{
              vol: sumOrderVol,
              matchVol: sumOrderMatchVol,
              sumDivdselqty: sumDivdQty,
              sumValAfterTaxFee: sumValAfterTaxFee.current,
            }}
            sumDetail={{
              sumVol: sumVol,
              sumPrice: sumPrice,
            }}
            totalColSpan={
              account?.subAcntNo?.startsWith(constants.subAccount.Pinefolio)
                ? 5
                : 4
            }
          />
        </PerfectScrollBar>
        {showModal && (
          <FormEditOrder
            order={order}
            showModal={showModal}
            handleCloseModal={_handleCloseModal}
            handleSubmitOrder={_handleSubmitEditOrder}
          />
        )}
        {showModalDel && (
          <FormDeleteOrder
            order={order}
            showModal={showModalDel}
            handleCloseModal={_handleCloseModalDel}
            handleSubmitOrder={_handleSubmitDeleteOrder}
          />
        )}
      </>
    );
  }
);

const Filter = withNamespaces('translations')(({ t, filterCallback }) => {
  const { listAccount, defaultAccount } = useSelector((state) => state.socket);
  const { token, auth } = useSelector((state) => state.client);
  const numDay = token && token['config'] ? token['config']['numDay'] : 30;

  const [account, setAccount] = useState(defaultAccount);
  const [symbol, setSymbol] = useState('');
  const [orderType, setOrderType] = useState('');
  const [orderStatus, setOrderStatus] = useState('');
  const [fromDate, setFromDate] = useState(
    new Date(getCurrentDateMinusDays(7))
  );
  const [toDate, setToDate] = useState(new Date());

  const _orderTypes = [
    { id: '', value: 'transaction.conditionalOrderStatement.allOrderType' },
    { id: '1', value: 'txt-buy' },
    { id: '2', value: 'txt-sell' },
  ];

  const _ordStatus = filter(ordStatus, (o) => o.id !== '8');
  const _orderStatus = [{ id: '', desc: 'trading.allStatus' }, ..._ordStatus];
  const _accounts = listAccount?.filter((x) => x.subAcntStat != 9) || [];

  const datePickerChangeCallback = (from, to) => {
    setFromDate(from);
    setToDate(to);
  };

  useEffect(() => {
    if (account)
      filterCallback(
        account,
        symbol,
        orderType?.id,
        orderStatus?.id,
        _convertformatDate2(moment(fromDate).format('DD/MM/YYYY')),
        _convertformatDate2(moment(toDate).format('DD/MM/YYYY'))
      );
  }, [account, symbol, orderType, orderStatus, fromDate, toDate]);

  useEffect(() => {
    if (defaultAccount) setAccount(defaultAccount);
  }, [defaultAccount]);

  return (
    <div className="filter">
      <div className="filter-control d-flex justify-content-between align-items-center">
        <div>
          <ReactSelect
            placeholder={t('txt-all')}
            className="mw-150 fz_14"
            classNamePrefix="filter-control-select"
            options={_accounts}
            isSearchable={false}
            components={{
              IndicatorSeparator: () => null,
            }}
            value={account}
            onChange={(value) => setAccount(value)}
            getOptionLabel={(option) => t(option.value)}
            getOptionValue={(option) => option.value}
          />
        </div>
        <div className="d-flex justify-content-between align-items-center gap-8">
          <div className="input-text-search d-flex align-items-center fz_13">
            <input
              name="txtSearch"
              maxLength={8}
              type="text"
              className="fz_13 shadow-none"
              placeholder={t('txt-symbol')}
              autoComplete="off"
              value={symbol}
              onChange={(e) => {
                if (e.target.value.length > 2)
                  setSymbol(e.target.value.toUpperCase());
                else setSymbol(e.target.value);
              }}
            />
            <span className="icon iSearch"></span>
          </div>

          <DatePicker
            startDate={fromDate}
            endDate={toDate}
            callback={datePickerChangeCallback}
          />
          <ReactSelect
            name="orderType"
            className="mw-150 fz_13"
            placeholder={t(
              'transaction.conditionalOrderStatement.allOrderType'
            )}
            classNamePrefix="filter-control-select"
            options={_orderTypes}
            isSearchable={false}
            components={{
              IndicatorSeparator: () => null,
            }}
            value={orderType}
            onChange={(value) => setOrderType(value)}
            getOptionLabel={(option) => t(option.value)}
            getOptionValue={(option) => option.id}
          />
          <ReactSelect
            name="orderStatus"
            className="mw-150 fz_13"
            placeholder={t('trading.allStatus')}
            classNamePrefix="filter-control-select"
            options={_orderStatus}
            isSearchable={false}
            components={{
              IndicatorSeparator: () => null,
            }}
            value={orderStatus}
            onChange={(value) => setOrderStatus(value)}
            getOptionLabel={(option) => t(option.desc)}
            getOptionValue={(option) => option.id}
          />
        </div>
      </div>
    </div>
  );
});

export default NormalOrderHistory;
