import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { withNamespaces } from 'react-i18next';
import '../index.scss';
import TextMask from 'react-text-mask';
import { createNumberMask } from 'text-mask-addons';
import {
  DERIVATIVE_ORDER_TYPE,
  numberFormat,
  StringToInt,
  useDebounce,
} from '../../../../util';
import { v4 as uuidv4 } from 'uuid';
import { handleApiErrors } from '../../../../lib/api-errors';
import {
  Logout,
  logoutRequest,
} from '../../../../components/modal/login/actions';
import { setAuth, setToast, unsetClient } from '../../../client/actions';
import { removeCookie } from '../../../../lib/storages';
import { getMessage } from '../../../../lib/selector';
import Select from 'react-select';
import ReactSelect from 'react-select';
import AuthenStep from '../../../../components/common/auth';
import Checkbox from '../../../../components/common/checkbox';
import PDFViewer from '../../../bond/components/Contract/pdfViewer';
import {
  PARTNER_FEE_POLICY,
  PARTNER_REQUEST_TYPE,
  PARTNER_SERVICE_TYPE,
  PARTNER_SIDE_REQUEST,
} from '../../../../util/constant';
import { getCommonRequest } from '../../../customer/actions';
import ViewFeeDefault from '../partnership/viewFeeDefault';

const AddNewCustomerBroker = withNamespaces('translations')(
  ({ t, showModal, handleClose, isChange, handleLoadData }) => {
    const theme = useSelector((state) => state.client.currentTheme);
    const {
      token,
      setting: { lang = 'vi' },
    } = useSelector((state) => state.client);
    const defaultAccount = useSelector((state) => state.socket.defaultAccount);
    const [oneTimeToken, setOneTimeToken] = useState();
    const dispatch = useDispatch();
    const [brkId, setBrkId] = useState('');
    const [confirmContract, setConfirmContract] = useState();
    const [listFee, setListFee] = useState();
    const [dataContract, setDataContract] = useState();
    const brkIdSearch = useDebounce(brkId, 500);
    const { auth } = useSelector((state) => state.client);

    const toastHandle = (msg, title = t('txt-notice')) => {
      const toastMsg = {
        id: Math.random(),
        msg: msg,
        title: title,
      };
      dispatch(setToast(toastMsg));
    };
    const _handleGetFeeDefault = () => {
      if (!token) return;
      const uuid = uuidv4();
      const resData = {
        group: 'CORE',
        user: token.user,
        session: token.session,
        cmd: 'getFeePolicy',
        rqId: uuid,
        channel: 'WTS',
        data: {
          cif: token.cif,
          acntNo: defaultAccount.acntNo,
          grpAcctTp: String(PARTNER_FEE_POLICY.defaultForCustomer),
          serTp: PARTNER_SERVICE_TYPE.all,
        },
      };

      const callback = (data) => {
        setListFee(data);
      };

      dispatch(getCommonRequest(resData, callback, true));
    };

    const _handleContract = () => {
      if (!token) return;
      const uuid = uuidv4();
      const resData = {
        group: 'CORE',
        user: token.user,
        session: token.session,
        cmd: 'getCustBrkCtrFile',
        rqId: uuid,
        channel: 'WTS',
        data: {
          cif: token.cif,
          acntNo: defaultAccount.acntNo,
          langTp: lang,
          brokerId: brkId,
        },
      };

      const callback = (data) => {
        setDataContract(data);
      };

      dispatch(getCommonRequest(resData, callback, true));
    };

    const handleCreateRequest = () => {
      const uuid = uuidv4();
      const params = {
        group: 'CORE',
        user: token.user,
        session: token.session,
        cmd: 'regUnregRef',
        rqId: uuid,
        channel: 'WTS',
        token: oneTimeToken,
        type: '6',
        data: {
          cif: token.cif,
          acntNo: defaultAccount.acntNo,
          vsdAcntNo: defaultAccount.vsdAcntNo,
          side: PARTNER_SIDE_REQUEST.customer + '',
          brkId: brkId,
          reqTp:
            (isChange
              ? PARTNER_REQUEST_TYPE.changeBroker
              : PARTNER_REQUEST_TYPE.addBroker) + '',
          custPhone: token.cellPhone,
        },
      };

      const callback = (data) => {
        toastHandle(t('partner.createRequestSuccess'));
        handleLoadData();
      };

      dispatch(getCommonRequest(params, callback, true));
    };

    useEffect(() => {
      _handleGetFeeDefault();
    }, []);

    useEffect(() => {
      _handleContract();
    }, [brkIdSearch]);

    return (
      <div className={'d-flex w-100 h-100'}>
        <div className={`${theme?.name || 'dark'} modal-add-customer`}>
          <div className={`p-2`}>
            <div className="text-center w-100">{t('partner.addNewBroker')}</div>
          </div>
          <div className="modal-add-customer__body none-modal">
            <div className="d-flex gap-16 px-3">
              <div className="w-100">
                <div className="text text--sm text--light3">
                  {t('partner.referralCode')}
                </div>
                <input
                  className="_input"
                  value={brkId}
                  maxLength={10}
                  onChange={(e) => {
                    setBrkId(e.target.value);
                  }}
                />
              </div>
              <div className="w-100">
                <div className="text text--sm text--light3">
                  {t('partner.referralName')}
                </div>
                <div className="text text--sm text--light">
                  {!dataContract?.brokerNm ? '-' : dataContract?.brokerNm}
                </div>
              </div>
            </div>
            <div className={'px-3'}>
              <ViewFeeDefault t={t} listFee={listFee} />
              <Checkbox
                onChange={(e) => {
                  setConfirmContract(e.target.checked);
                }}
                title={t('partner.textConfirmAddBroker')}
                type="checkbox"
                checked={confirmContract}
                className="checkbox-group partner-checkbox mt-3"
              />
              <div className="mt-4">
                <AuthenStep setOneTimeToken={setOneTimeToken} trackMixpanel />
              </div>
              <div className="d-flex w-100 gap-16">
                <button
                  disabled={!oneTimeToken || !confirmContract}
                  className={'btn btn--primary w-100 h-32 mt-4'}
                  onClick={() => {
                    handleCreateRequest();
                  }}
                >
                  {t('txt-confirm')}
                </button>
                {!!handleClose && (
                  <button
                    className={'btn btn--cancel w-100 h-32 mt-4'}
                    onClick={handleClose}
                  >
                    {t('txt-cancel')}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="w-100 pr-3 h-100">
          {dataContract && (
            <PDFViewer src={dataContract?.fileUrl || ''} scale={1.3} />
          )}
        </div>
      </div>
    );
  }
);

export default AddNewCustomerBroker;
