import {
  call,
  put,
  takeLatest,
  all,
  select,
  takeLeading,
} from 'redux-saga/effects';
import { handleApiErrors } from '../../lib/api-errors';
import {
  CANCEL_ORDER_REQUESTING,
  CASH_BALANCE_REQUESTING,
  EDIT_ORDER_REQUESTING,
  LIST_PUT_THROUGH_REQUESTING,
  MATCH_ORDER_REQUESTING,
  NEW_ORDER_REQUESTING,
  POSITION_REQUESTING,
  PURCHASE_POWER_REQUESTING,
  SHARE_BALANCE_REQUESTING,
  STOCK_BALANCE_REQUESTING,
  SYMBOL_INFO_REQUESTING,
} from './constants';

import {
  cancelOrderRequestError,
  cancelOrderRequestSuccess,
  cashBalanceRequestError,
  cashBalanceRequestSuccess,
  editOrderRequestError,
  editOrderRequestSuccess,
  listPutThroughRequestError,
  listPutThroughRequestSucsess,
  matchOrderRequestError,
  matchOrderRequestSuccess,
  newOrderRequestError,
  newOrderRequestSuccess,
  positionsRequestError,
  positionsRequestSuccess,
  purchasePowerRequestError,
  purchasePowerRequestSuccess,
  shareBalanceRequestError,
  shareBalanceRequestSuccess,
  stockBalanceRequestError,
  stockBalanceRequestSuccess,
  symbolInfoRequestError,
  symbolInfoRequestSuccess,
} from './actions';
import { getMessage } from '../../lib/selector';
import { removeCookie } from '../../lib/storages';
import { reloadPosOutSidePF } from '../pinefolio/action';
import {
  GET_PRIV_DATA_REQUEST,
  REGISTER_REQUESTING,
} from '../socket/constants';
import i18n from '../../i18n';
import { setToast, unsetClient } from '../client/actions';
import { Logout, logoutRequest } from '../../components/modal/login/actions';
import { Mixpanel } from '../../lib/mixpanel';

const apiUrl = `${process.env.REACT_APP_API_URL}`;

function handleRequest(request) {
  return request
    .then(handleApiErrors)
    .then((response) => response.json())
    .then((json) => json)
    .catch((error) => {
      throw error;
    });
}

function postRequestApi(data) {
  const url = `${apiUrl}/CoreServlet.pt`;
  const request = fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
    body: JSON.stringify(data),
  });

  return handleRequest(request);
}

function* cashBalanceRequestFlow(action) {
  try {
    const resData = yield call(postRequestApi, action.data);
    if (resData.http !== 200) {
      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');

        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }
      const msgErr = yield select(getMessage, resData);
      throw Error(msgErr);
    }
    yield put(cashBalanceRequestSuccess(resData));
  } catch (error) {
    yield put(cashBalanceRequestError(error));
  }
}

function* shareBalanceRequestFlow(action) {
  try {
    const resData = yield call(postRequestApi, action.data);
    if (resData.http !== 200) {
      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');

        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }
      const msgErr = yield select(getMessage, resData);
      throw Error(msgErr);
    }
    yield put(shareBalanceRequestSuccess(resData));
  } catch (error) {
    yield put(shareBalanceRequestError(error));
  }
}

function* purchasePowerRequestFlow(action) {
  try {
    const resData = yield call(postRequestApi, action.data);
    if (resData.http !== 200) {
      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');

        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }
      const msgErr = yield select(getMessage, resData);
      throw Error(msgErr);
    }
    yield put(purchasePowerRequestSuccess(resData));
  } catch (error) {
    yield put(purchasePowerRequestError(error));
  }
}

function* stockBalanceRequestFlow(action) {
  try {
    const resData = yield call(postRequestApi, action.data);
    if (resData.http !== 200) {
      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');

        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }
      const msgErr = yield select(getMessage, resData);
      throw Error(msgErr);
    }
    yield put(stockBalanceRequestSuccess(resData));
  } catch (error) {
    yield put(stockBalanceRequestError(error));
  }
}

function* positionsRequestFlow(action) {
  try {
    const resData = yield call(postRequestApi, action.data);
    if (resData.http !== 200) {
      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');

        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }
      const msgErr = yield select(getMessage, resData);
      throw Error(msgErr);
    }
    yield put(positionsRequestSuccess(resData));
  } catch (error) {
    yield put(positionsRequestError(error));
  }
}

function* newOrderRequestFlow(action) {
  try {
    const resData = yield call(postRequestApi, action.data);
    if (resData.http !== 200) {
      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');

        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }
      const msgErr = yield select(getMessage, resData);
      throw Error(msgErr);
    }
    yield put(newOrderRequestSuccess(resData));

    // successCallback like reload asset portfolio & symbols outside PF
    const subAccountNo = action.data.data?.subAcntNo;
    if (subAccountNo && subAccountNo.startsWith('P')) {
      yield put(reloadPosOutSidePF(true));
    }
  } catch (error) {
    yield put(newOrderRequestError(error));
  }
}

function* editOrderRequestFlow(action) {
  try {
    const resData = yield call(postRequestApi, action.data);
    if (resData.http !== 200) {
      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');

        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }
      const msgErr = yield select(getMessage, resData);
      throw Error(msgErr);
    }
    yield put(editOrderRequestSuccess(resData));
  } catch (error) {
    yield put(editOrderRequestError(error));
  }
}

function* cancelOrderRequestFlow(action) {
  try {
    const resData = yield call(postRequestApi, action.data);
    if (resData.http !== 200) {
      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');

        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }
      const msgErr = yield select(getMessage, resData);
      throw Error(msgErr);
    }
    yield put(cancelOrderRequestSuccess(resData));
  } catch (error) {
    yield put(cancelOrderRequestError(error));
  }
}

function* symbolInfoRequestFlow(action) {
  try {
    const resData = yield call(postRequestApi, action.data);
    if (resData.http !== 200) {
      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');

        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }
      const msgErr = yield select(getMessage, resData);
      throw Error(msgErr);
    }
    yield put(symbolInfoRequestSuccess(resData));
  } catch (error) {
    yield put(symbolInfoRequestError(error));
  }
}

function* matchOrderRequestFlow(action) {
  try {
    const resData = yield call(postRequestApi, action.data);
    if (resData.http !== 200) {
      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');

        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }
      const msgErr = yield select(getMessage, resData);
      throw Error(msgErr);
    }
    yield put(matchOrderRequestSuccess(resData));
  } catch (error) {
    yield put(matchOrderRequestError(error));
  }
}

function* requestListPutThroughFlow(action) {
  try {
    const resData = yield call(postRequestApi, action.data);
    if (resData.http !== 200) {
      if (resData.http === 401) {
        removeCookie('token');
        removeCookie('authen');
        yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
      }
      const msgErr = yield select(getMessage, resData);
      throw Error(msgErr);
    }
    yield put(listPutThroughRequestSucsess(resData));
  } catch (error) {
    yield put(listPutThroughRequestError(error));
  }
}

function* privateRegisterFlow(action) {
  try {
    const { fnc } = action;
    if (fnc && typeof fnc == 'function') {
      console.log('excuted function', fnc);
      fnc();
    }
  } catch (error) {
    throw error;
  }
}

function toastHandle(message, title = i18n.t('txt-notice')) {
  const toastMsg = {
    id: Math.random(),
    msg: message,
    title: title,
  };
  return put(setToast(toastMsg));
}

/**
 * This func try to aim for covering almost call privData api cases
 * @param {isProtect} param0 // this param help to protect private api (user data - must to authentication)
 */
function* getPrivDataCommonRequestFlow({ params, callback, isProtect }) {
  try {
    const resData = yield call(postRequestApi, params);
    const token = yield select((state) => state.client.token);

    if (resData.http !== 200) {
      let toastMsg = '';

      if (resData.http === 401) {
        if (isProtect) {
          if (token) {
            put(logoutRequest(token));
            put(unsetClient());
            put(Logout());
          }
          removeCookie('token');
          removeCookie('authen');
          yield put({ type: 'INVALID_SESSION', resData: resData.data.message });
        }

        toastMsg = i18n.t('txt-valid-loss-session');
        yield toastHandle(toastMsg);
      } else {
        const msgErr = yield select(getMessage, resData);
        yield toastHandle(msgErr);
      }
    } else {
      if (callback && typeof callback == 'function') {
        callback(resData.data);
      }
    }
  } catch (error) {
    console.error(error);
  }
}

function* clientWatcher() {
  yield all([
    takeLatest(CASH_BALANCE_REQUESTING, cashBalanceRequestFlow),
    takeLatest(SHARE_BALANCE_REQUESTING, shareBalanceRequestFlow),
    takeLatest(PURCHASE_POWER_REQUESTING, purchasePowerRequestFlow),
    takeLatest(STOCK_BALANCE_REQUESTING, stockBalanceRequestFlow),
    takeLatest(POSITION_REQUESTING, positionsRequestFlow),
    takeLatest(NEW_ORDER_REQUESTING, newOrderRequestFlow),
    takeLatest(EDIT_ORDER_REQUESTING, editOrderRequestFlow),
    takeLatest(CANCEL_ORDER_REQUESTING, cancelOrderRequestFlow),
    takeLatest(SYMBOL_INFO_REQUESTING, symbolInfoRequestFlow),
    takeLatest(MATCH_ORDER_REQUESTING, matchOrderRequestFlow),
    takeLatest(LIST_PUT_THROUGH_REQUESTING, requestListPutThroughFlow),
    // takeLeading(REGISTER_REQUESTING, privateRegisterFlow),
    takeLatest(GET_PRIV_DATA_REQUEST, getPrivDataCommonRequestFlow),
  ]);
}

export default clientWatcher;
